import { useDashboard } from "context/controlTower/controlTowerContext";
import { setConfigModal } from "context/controlTower/controlTowerActions";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";

function MessageModal() {
  const {
    state: { configModal },
    dispatch,
  } = useDashboard();

  const handleCloseModal = () => {
    dispatch(setConfigModal({ isOpen: false, subtitle: "", title: "" }));
  };
  return (
    <CustomCenteredModal
      withLogo
      showCloseButton
      title={configModal.title}
      subtitle={configModal.subtitle}
      open={configModal.isOpen}
      onClose={handleCloseModal}
    />
  );
}

export default MessageModal;
