import { useHistory } from "react-router-dom";

import Searchbar from "Components/Admin/UIComponents/molecules/Searchbar";
import { setFilters } from "context/demandScheduledVisitsContext/demandScheduledActions";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";

function SearchVisit() {
  const history = useHistory();
  const { dispatch, state } = useDemandScheduled();

  const handleSearch = (val) => {
    dispatch(
      setFilters({
        ...state.filters,
        search: val,
      })
    );
  };

  return (
    <Searchbar
      options={state?.visits}
      value={undefined}
      loading={state.isLoading}
      onInputChange={(e, val: any) => handleSearch(val)}
      placeholder="Búsqueda ID Visita, ID Propiedad"
      onChange={(e, val: any) => history.push(`/admin/properties/${val?.prop_id?.uid}`)}
      getOptionLabel={(option: any) =>
        `ID: ${option?.id}, Visita: ${option?.prop_id?.id}, Houmer: ${option?.houmer?.name}, Contacto: ${option?.contact?.name} ${option?.contact?.last_name} `
      }
    />
  );
}

export default SearchVisit;
