import LeadActivities from "../components/LeadActivities";
import LeadQualificationItem from "../components/LeadQualification";
import ToggleBox from "../components/ToggleBox";
import Spinner from "Components/Shared/partials/Spinner";
import { useLead } from "context/leadContext/leadContext";
import { TableProvider } from "context/tableContext/tableContext";
import { getChipText } from "../utils/getChipText";

function LeadProfile() {
  const { state } = useLead();

  if (!state?.lead) return <Spinner />;

  return (
    <>
      <ToggleBox title="Lead" titleChip={getChipText(state.lead?.category)}>
        <LeadQualificationItem />
      </ToggleBox>

      <TableProvider>
        <LeadActivities />
      </TableProvider>
    </>
  );
}

export default LeadProfile;
