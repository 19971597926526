import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  cardRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1.25, 2),
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  amenityBox: {
    borderRadius: theme.spacing(2),
    maxWidth: "fit-content",
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  amenityContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: theme.spacing(4),
  },
}));
