import { AxiosResponse } from "axios";
import qs from "query-string";

import { Locale } from "models/Countries";
import { Subscription } from "models/Subscription";
import { BACKEND_URL } from "env";

import { createHttp } from "../helper";
import { ActivateSubData } from "./types";

const qsConfig = { skipEmptyString: true, skipNull: true };

const getPagination = (pageSize, page) =>
  pageSize && page ? `page_size=${pageSize}&page=${page}` : "";

const urlencoded = "application/x-www-form-urlencoded";

export default {
  getByPropertyId(propertyId: number, token: string) {
    return createHttp(token).get(`/subscriptions/v2/${propertyId}/`);
  },

  getAllByPropertyId(
    propertyId: number,
    token: string,
    params = {}
  ): Promise<AxiosResponse<Subscription[]>> {
    return createHttp(token).get(
      `/subscriptions/v2/${propertyId}/?${qs.stringify(params, qsConfig)}`
    );
  },

  getById(subscriptionId: number, token: string, cancelToken = undefined) {
    return createHttp(token, BACKEND_URL, urlencoded, cancelToken).get(
      `/subscriptions/v2/get_by_id/${subscriptionId}/`
    );
  },

  getByContractId(contractId: number, token: string): Promise<AxiosResponse<Subscription>> {
    return createHttp(token).get(`/subscriptions/v2/get_by_contract/${contractId}/`);
  },

  search(value: string, token: string) {
    return createHttp(token)
      .get(`/subscriptions/v2/search/?search=${value}`)
      .catch((error) => {
        console.error(error);
        return false;
      });
  },

  activateSubscription(data: ActivateSubData, token: string) {
    return createHttp(token).post(`/subscriptions/v2/activate_sub`, data);
  },

  unsubscribeSubscription(
    data: {
      subscription_id: number;
      need_republish: boolean;
      finalized_reason_code?: string;
    },
    token: string
  ) {
    return createHttp(token).put(`/subscriptions/v2/cancel`, data);
  },

  updateSubscription(subscriptionId: number, data, token: string) {
    return createHttp(token).put(`/subscriptions/v2/get_by_id/${subscriptionId}/`, data);
  },

  getLandlordsVip(
    pageSize: number = 10,
    page: number = 1,
    country: string = "Chile",
    filter: string | null,
    token: string
  ) {
    const filters = filter ? `&executive_email=${filter}` : "";
    return createHttp(token).get(
      `/subscriptions/v2/get_vip_clients/?country=${country}&${getPagination(
        pageSize,
        page
      )}${filters}`
    );
  },

  getFlagLandlordsVipProperty(id: number, token: string) {
    return createHttp(token).get(`/subscriptions/v2/is_vip_client/?property_id=${id}`);
  },

  getFlagLandlordsVipSubscription(
    id: number,
    token: string
  ): Promise<
    AxiosResponse<{
      is_vip: boolean;
      managed_properties: number;
    }>
  > {
    return createHttp(token).get(`/subscriptions/v2/is_vip_client/?subscription_id=${id}`);
  },
  getAdjustment(
    country: Locale,
    scope: string = "closing_payment",
    roleScope: "tenant" | "landlord" | "both",
    token: string
  ): Promise<
    AxiosResponse<
      {
        id: string;
        label: string;
        description: string;
        score: string;
        type: string;
      }[]
    >
  > {
    const roleScopeQuery = roleScope === "both" ? "" : `&role_scope=${roleScope}`;
    return createHttp(token).get(
      `/v2/adjustments/reasons?country=${country}&scope=${scope}${roleScopeQuery}`
    );
  },
};
