import { useState, useEffect, useRef } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import momentTz from "moment-timezone";
import { Box, Grid, Typography, CircularProgress, ThemeProvider } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Swal from "sweetalert2";

import scheduleService from "services/schedulesService";

import { parseScheduleAvailability } from "utils/datetime";
import SelectHour from "./SelectHour";
import {
  NextButton,
  Selectable,
} from "Components/Admin/administrator/properties/components/FormElements";
import schedulersStyles from "./SchedulersStyle";
import { IOption } from "Components/Admin/administrator/properties/components/FormElements/Selectable";
import { useGetToken } from "hooks";
import CustomThemePicker from "./customDatePickerStyles";
import { getTimezone } from "utils";
import { PropertyData } from "models/Property";

interface IAvailability {
  [k: string]: string[];
}
enum scheduleTypesPhoto {
  RENT = "Photo",
  SALE = "SalesPhoto",
}
const optionButtons: IOption[] = [
  { label: "Fotos arriendo", value: scheduleTypesPhoto.RENT },
  { label: "Fotos venta", value: scheduleTypesPhoto.SALE },
];

function ScheduleHC({ property }: { property: PropertyData }) {
  const { getToken } = useGetToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingReq, setLoadingReq] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [type, setType] = useState<scheduleTypesPhoto>(
    property.for_rental ? scheduleTypesPhoto.RENT : scheduleTypesPhoto.SALE
  );
  const [allowedDays, setAllowedDays] = useState<moment.Moment[]>([]);
  const [availability, setAvailability] = useState<IAvailability | undefined>();
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
  const [aviliableHours, setAviliableHours] = useState<string[]>([]);
  const [availabilityParsed, setAvailabilityParsed] = useState<IAvailability | undefined>();

  const selectHourRef = useRef(null);
  const typeRef = useRef(null);

  useEffect(() => {
    momentTz.tz.setDefault();
    typeRef.current?.setValue(type);
  }, []);
  useEffect(() => {
    const getAvailability = async () => {
      if (!type) return;
      setLoading(true);
      const authToken = await getToken();
      const availabilityResponse = (await scheduleService.getAvailableHours(
        type,
        property.uid,
        authToken
      )) as AxiosResponse<any>;
      setLoading(false);
      setAvailability(availabilityResponse?.data);
    };
    getAvailability();
  }, [type]);

  useEffect(() => {
    const parsedAvailability = parseScheduleAvailability(
      availability,
      getTimezone(property?.country, property?.comuna)
    );
    setAvailabilityParsed(parsedAvailability);

    if (Object.keys(parsedAvailability).length > 0) {
      setIsEmpty(false);
      setAllowedDays(Object.keys(parsedAvailability).map((value) => moment(value)));
      setSelectedDay(moment(Object.keys(parsedAvailability)[0]));
      const filteredHours = parsedAvailability[Object.keys(parsedAvailability)[0]];

      setAviliableHours(filteredHours);
      selectHourRef.current?.setValue(filteredHours[0]);
    } else {
      setIsEmpty(true);
    }
  }, [availability]);

  const handleChangeDay = (day) => {
    if (day.isValid() && availability) {
      setSelectedDay(day);
      const newDay = Object.keys(availabilityParsed).find((item) =>
        moment(item).isSame(day, "date")
      );
      const filteredHours = availabilityParsed[newDay];
      setAviliableHours(filteredHours);
    }
  };

  const handleSubmit = async () => {
    if (selectHourRef.current?.validate()) {
      setLoadingReq(true);
      const selectedHour = selectHourRef.current.getValue().hour;
      const begin = moment
        .utc(selectedHour)
        .clone()
        .tz(getTimezone(property?.country, property?.comuna));
      const end = moment
        .utc(selectedHour)
        .clone()
        .add(2, "hours")
        .tz(getTimezone(property?.country, property?.comuna));
      const authToken = await getToken();
      const res = await scheduleService.createPhotoSchedule(
        {
          begin_hour: begin,
          end_hour: end,
          prop: property.uid,
          date: begin,
          type,
          origin: "Admin - Propiedad",
        },
        authToken
      );
      setLoadingReq(false);
      if (res) {
        Swal.fire({
          title: "Se ha agendado la visita con exito",
        });
      }
    }
  };
  const classes = schedulersStyles();
  return (
    <Box>
      {loading && <CircularProgress />}
      {property.for_rental && property.for_sale && (
        <Box p={1} mb={3}>
          <Selectable
            required
            ref={typeRef}
            onChange={setType}
            compressed
            wired
            options={optionButtons}
            id="type"
            label="Tipo de visita"
          />
        </Box>
      )}
      {isEmpty && !loading && (
        <Typography variant="h6">No se ha encontrado agenda disponible</Typography>
      )}
      {!isEmpty && (
        <Grid container spacing={4} justifyContent="center">
          <Grid item sm={12} className={classes.tabs}>
            <ThemeProvider theme={(theme) => CustomThemePicker(theme)}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  shouldDisableDate={(date) => !allowedDays.find((day) => day.isSame(date, "date"))}
                  value={selectedDay}
                  variant="static"
                  disableToolbar
                  disablePast
                  onChange={handleChangeDay}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Grid>
          <Grid item sm={12}>
            <SelectHour
              id="hour"
              hours={aviliableHours}
              day={selectedDay.format("YYYY-MM-DD")}
              country={property?.country || "Chile"}
              commune={property?.comuna}
              ref={selectHourRef}
            />
          </Grid>
          <Box justifyContent="flex-end" display="flex" width="100%">
            <NextButton disabled={loadingReq} onClick={handleSubmit}>
              {loadingReq ? <CircularProgress color="secondary" size={20} /> : "Agendar visita HC"}{" "}
            </NextButton>
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default ScheduleHC;
