import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  text: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
  },
  card: {
    width: 500,
    margin: "auto",
    marginTop: theme.spacing(2.5),
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  container: {
    marginTop: theme.spacing(1.25),
  },
  dataContainer: {
    marginLeft: theme.spacing(2.5),
  },
  avatar: {
    width: 70,
    height: 70,
  },
  select: {
    minWidth: 300,
  },
  customButton: {
    textTransform: "none",
  },
  closeButton: {
    zIndex: 1,
    color: "black",
  },
}));
