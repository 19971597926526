import { useEffect } from "react";

import LeadProperties from "../components/LeadProperties";
import LeadQualificationItem from "../components/LeadQualification";
import ToggleBox from "../components/ToggleBox";
import { useLead } from "context/leadContext/leadContext";
import { getLeadProperties } from "context/leadContext/leadActions";
import { useTable } from "context/tableContext/tableContext";
import { useGetToken } from "hooks";
import { getChipText } from "../utils/getChipText";

function LeadPropertiesTab() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;

  useEffect(() => {
    getToken().then((authToken) => {
      if (state?.lead)
        getLeadProperties(pageSize, currentPage, state?.lead?.email, dispatch, authToken);
    });
  }, [state?.lead, pageSize, currentPage]);

  return (
    <>
      <ToggleBox title="Lead" open={false} titleChip={getChipText(state.lead?.category)}>
        <LeadQualificationItem />
      </ToggleBox>
      <LeadProperties />
    </>
  );
}

export default LeadPropertiesTab;
