import axios, { AxiosResponse } from "axios";
import httpClient from "@houm-com/http-client";
import qs from "query-string";
import { REV_URL, BACKEND_URL, PROPERTY_DUES_URL } from "env";
import { getToken, createHttp, createNoAuthHttp, clientConfig } from "../helper";
import { IProperty, HomeCheckerDetailsSnakeCase } from "models";
import { Paginate } from "models/ResponseWithPaginate";
import { SimilarHistoricPropertyResponse } from "models/Calculator";
import { Comment, PropertyData } from "models/Property";
import { CountryUpperCase, Locale } from "models/Countries";
import { PropertyInspectionReportsTableFilters } from "context/tableContext/tableTypes";
import {
  InspectionReportIndexResponse,
  InspectionReportShowResponse,
  InspectionReportUpdateData,
  UpdateHCParams,
  UpdateHCDetailsBody,
  ILandlordSummaryResponse,
  BasicServicesResponseV1,
  PropertyBasicServicesResponseV1,
  PropertyBasicServicesResponse,
  INewBasicService,
  BasicServicesResponseV2,
  ICompaniesResponse,
  CreateCommonExpenseResponse,
  CitiesResponse,
  KeysInfoResponse,
  ContractFormResponse,
} from "./types";
import { IScheduleResponse } from "hooks/useGetSchedules/utils/types";

interface IBasicServiceParams {
  country: string;
  ordering?: string;
}

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  index(token) {
    return createHttp(token).get("/propietary/v2/properties/");
  },

  newIndexRequest(
    filters: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<Paginate<PropertyData>>> {
    return createHttp(token).get(`/properties/v2/admin/?${qs.stringify(filters)}`);
  },

  createProperty(property, token): Promise<AxiosResponse<PropertyData>> {
    return createHttp(token).post(`/admin/v2/properties/`, property);
  },

  updateProperty(propertyId, property, token) {
    return createHttp(token).put(`/admin/v2/properties/${propertyId}/`, property);
  },

  update_keys(data, propertyUid, token) {
    return createHttp(token).post(`/propietary/v2/properties/${propertyUid}/keys_info/`, data);
  },

  setHomechecker(homecheckerId, propertyUid, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http
      .post(`/propietary/v2/properties/${propertyUid}/set_homechecker/`, {
        homechecker: homecheckerId,
      })
      .catch((error) => false);
  },

  setHomecheckerV2(homecheckerId: string, propertyUid: string, token: string) {
    return createHttp(token).post(`/propietary/v2/properties/${propertyUid}/set_homechecker/`, {
      homechecker: homecheckerId,
    });
  },

  getPropertyComments(propertyUid: string, token: string) {
    return createHttp(token).get(`/propietary/v2/properties/${propertyUid}/comments/`);
  },

  createPropertyComment(data: Comment, token: string) {
    return createHttp(token).post("/properties/v2/comments/", data);
  },

  getPropertyBasicServices(
    id: number,
    token: string
  ): Promise<AxiosResponse<PropertyBasicServicesResponse>> {
    return createHttp(token, PROPERTY_DUES_URL).get(`/bill/${id}/detail`);
  },

  addPropertyBasicService(
    id: number,
    token: string,
    data: INewBasicService
  ): Promise<AxiosResponse> {
    return createHttp(token, PROPERTY_DUES_URL).post(`/bill/${id}/info`, data);
  },

  updatePropertyBasicService(
    propertyId: number,
    token: string,
    data: INewBasicService
  ): Promise<AxiosResponse> {
    return createHttp(token, PROPERTY_DUES_URL).patch(`/bill/${propertyId}/info`, data);
  },

  updatePropertyBasicServices(id: number, token: string): Promise<AxiosResponse> {
    return createHttp(token, PROPERTY_DUES_URL).post(`/bill/${id}`);
  },

  deleteBasicServiceIntegrations(
    propertyId: number,
    company: string,
    token: string
  ): Promise<AxiosResponse> {
    return createHttp(token, PROPERTY_DUES_URL).delete(`/bill/${propertyId}/info/${company}`);
  },

  getBasicServicesIntegrations(
    country = "Chile",
    token: string
  ): Promise<AxiosResponse<BasicServicesResponseV2[]>> {
    return createHttp(token, PROPERTY_DUES_URL).get(`/company/?country=${country}`);
  },

  getCommonExpenseCompanies(
    country: CountryUpperCase = "Chile",
    token: string
  ): Promise<AxiosResponse<ICompaniesResponse[]>> {
    return createHttp(token, PROPERTY_DUES_URL).get(
      `/company/?country=${country}&company_type=common_expenses`
    );
  },

  getPropertyBasicServicesV1(
    id: number,
    token: string
  ): Promise<AxiosResponse<Paginate<PropertyBasicServicesResponseV1>>> {
    return createHttp(token, BACKEND_URL, "application/x-www-form-urlencoded").get(
      `/properties/v2/property_basic_services/?property_id=${id}`
    );
  },

  updatePropertyBasicServiceV1(
    propertyId: string,
    serviceId: number,
    clientNumber: string,
    token: string,
    overdue: string
  ) {
    return createHttp(token, BACKEND_URL).put(
      `/properties/v2/property_basic_services/${serviceId}/?property_id=${propertyId}`,
      {
        overdue,
        client_number: clientNumber,
      }
    );
  },

  getBasicServices(
    token: string,
    country = "Chile",
    ordering = ""
  ): Promise<AxiosResponse<Paginate<BasicServicesResponseV1>>> {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const params: IBasicServiceParams = { country };
    if (ordering !== "") params.ordering = ordering;
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
      params,
    });
    return http.get("/properties/v2/basic_services");
  },

  deleteBasicService(token: string, serviceId: number, propertyId: number) {
    return createHttp(token).delete(
      `/properties/v2/property_basic_services/${serviceId}/?property_id=${propertyId}`
    );
  },

  attachService(
    data: {
      service_id?: number;
      client_number?: string;
      prop_id?: number;
    },
    token: string
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.post("/properties/v2/property_basic_services/", data);
  },

  async adminIndex(pageSize, page, { ...filters }, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(
      `/admin/v2/properties/?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`
    );
  },

  async getPropertiesStatus(pageSize, page, { ...filters }, token) {
    return createHttp(token).get(
      `/properties/v2/admin/?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`
    );
  },

  async supplyLeads(pageSize, page, { ...filters }, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/leads/v2/?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`);
  },

  supplyActivities(pageSize, page, { ...filters }, token) {
    return createHttp(token).get(
      `/leads/v2/lead-activities/?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`
    );
  },

  indexPropertyInspectionReportsRequest(
    pageSize: number,
    page: number,
    propertyId: number,
    { ...filters }: PropertyInspectionReportsTableFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<InspectionReportIndexResponse>>> {
    return createHttp(token).get(
      `/properties/v2/${propertyId}/inspection_reports/?page_size=${pageSize}&page=${page}&${qs.stringify(
        filters
      )}`
    );
  },

  showPropertyInspectionReportRequest(
    id: number | "last_inspection_report",
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<InspectionReportShowResponse>> {
    return createHttp(token).get(`/properties/v2/${propertyId}/inspection_reports/${id}`);
  },

  updatePropertyInspectionReportRequest(
    id: number,
    propertyId: number,
    data: InspectionReportUpdateData,
    token: string
  ): Promise<AxiosResponse<InspectionReportShowResponse>> {
    return createHttp(token).put(`/properties/v2/${propertyId}/inspection_reports/${id}`, data);
  },

  getExternalPortalLinks(pageSize, page, { ...filters }, token) {
    return createHttp(token).get(
      `/admin/v2/properties_links?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`
    );
  },

  get(id: string, token: string): Promise<AxiosResponse<PropertyData>> {
    return createHttp(token).get(`/propietary/v2/properties/${id}/`);
  },

  getByID(id, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/properties/v2/get_by_id/${id}/`).catch((error) => error);
  },

  getProperty(id: number | string, token: string) {
    return createHttp(token).get(`/admin/v2/properties/${id}/`);
  },

  getPropertyDetails(property_id, token) {
    return createHttp(token).get(`/schedules/v2/get_property_details/${property_id}`);
  },

  getAvailablePropertyHours(propertyId, token) {
    return createHttp(token).get(`/schedules/v2/property-availability/get?property=${propertyId}`);
  },

  updateAvailablePropertyHours(propertyId, schedule, properties = null, token) {
    const client = httpClient(clientConfig(token));
    return client.put("/schedules/v2/property-availability/set", {
      property_id: propertyId,
      availability: schedule,
      ...(properties && { property_ids: properties }),
    });
  },

  search(value, view_name = undefined, token, cancelToken = undefined, params = {}) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const searchUrl = (view_name) => {
      switch (view_name) {
        case "published_properties":
        case "done_photos":
        case "scheduled_photos":
          return `/admin/v2/properties/?search=${value}&view_name=${view_name}`;
        case "leads":
          return `/leads/v2/?search=${value}`;
        default:
          return `/admin/v2/properties/?search=${value}&${qs.stringify(params)}`;
      }
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers, cancelToken });
    return http.get(searchUrl(view_name)).catch(() => false);
  },

  update(uid: string, data: IProperty | PropertyData, token: string) {
    return createHttp(token).put(`/propietary/v2/properties/${uid}/`, data);
  },
  rePublishForRent(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/republish_for_rent/`, { id });
  },
  publishForRent(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/publish_for_rent/`, { id });
  },
  publishForSale(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/publish_for_sale/`);
  },
  setLostForSale(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/set_lost_for_sale/`, { id });
  },
  setLostForRent(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/set_lost_for_rent/`, { id });
  },

  updateDetails(id, data, token) {
    return createHttp(token).put(`/propietary/v2/property_details/${id}/`, data);
  },

  updateSalesDetails(id, data, token) {
    return createHttp(token).patch(`/properties/v2/sales_details/${id}/`, data);
  },

  async homeCheckerDetails(
    propertyId: string,
    token: string
  ): Promise<AxiosResponse<HomeCheckerDetailsSnakeCase>> {
    return createHttp(token).get(`/properties/v2/${propertyId}/home-checker-details`);
  },

  async updateHomeCheckerDetails(
    propertyId: number,
    data: HomeCheckerDetailsSnakeCase,
    token: string
  ) {
    return createHttp(token).put(`/properties/v2/${propertyId}/home-checker-details`, data);
  },

  async getScheduledPhotos(propertyUid, token) {
    return createHttp(token).get(`/propietary/v2/properties/${propertyUid}/photo_scheduled/`);
  },

  pendingSchedules(propertyId: number, token: string) {
    return createHttp(token).get(`/properties/v2/${propertyId}/pending-visits`);
  },

  newMarketplaceStatistics(propertyUid: string, token: string) {
    return createHttp(token).get(`/propietary/v2/properties/${propertyUid}/marketplace_stats/`);
  },

  async getAvailableNeighborhoods(country: Locale, token: string, search?: string) {
    const filters = {
      country_code: country,
      search,
    };
    return createHttp(token).get(`/properties/v2/locations/?${qs.stringify(filters)}`);
  },

  async getLocations(countryCode, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    try {
      const locations = await http.get(
        `/properties/v2/get_country_locations?country_code=${countryCode}`
      );
      return locations.data;
    } catch (error) {
      return [];
    }
  },

  getOrCreateLocation(data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post("/properties/v2/get_or_create_location", data).catch((error) => false);
  },

  async reserve(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.post(`/properties/v2/${id}/reserve/`).catch((error) => false);
  },

  async sold(id: number, token: string) {
    return createHttp(token).post(`/properties/v2/${id}/set_sold/`);
  },

  async updateDocuments(id, token, data) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.put(`/properties/v2/${id}/documents`, data);
  },

  getDocuments(id: number, token: string) {
    return createHttp(token).get(`/properties/v2/${id}/documents`);
  },

  async valuate(params) {
    const http = axios.create({ baseURL: REV_URL });
    return http.post("valuation/", params, {
      timeout: 1000 * 10,
    });
  },

  async listProperties({ lang, lat, lng, mode, type, pageSize = 500, bounds, token }) {
    const http = axios.create({
      baseURL: `${BACKEND_URL}`,
    });
    const params: any = {
      page_size: pageSize,
    };
    if (lat && lng) {
      params.coordinates = `${lat},${lng}`;
    }
    if (type === "forSale" || type === "sale") {
      params.for_sale = true;
      params.mode = "sale";
    }
    if (type === "forRent" || type === "rent") {
      params.for_rental = true;
      params.mode = "rent";
    }
    if (mode && ["departamento", "casa", "apartemento"].includes(mode)) {
      params.type = mode;
    }
    if (bounds?.length > 1) {
      const sw = bounds[0];
      const ne = bounds[1];
      params.boundings = `[[${sw[0]},${sw[1]}],[${ne[0]}, ${ne[1]}]]`;
    }
    if (lang) {
      params.country = lang;
    }
    return http.get("properties/marketplace/", { params });
  },

  async getDetailsById(id, mode = "rent", token) {
    if (mode === "forRent") mode = "rent";
    else if (mode === "forSale") mode = "sale";
    return createHttp(token).get(`/properties/v2/get_by_id/${id}/?mode=${mode}`);
  },

  async getContractPropertyInfo(
    id: number | string,
    token: string
  ): Promise<AxiosResponse<ContractFormResponse>> {
    return createHttp(token).get(`/propietary/v2/properties/${id}/contract_form`);
  },

  async updateContractPropertyInfo(id: number, data, token: string) {
    return createHttp(token).put(`/propietary/v2/properties/${id}/contract_form`, data);
  },
  similarHistoricProperty(
    id: number,
    mode: "rental" | "sale",
    n_similar: number,
    token: string
  ): Promise<AxiosResponse<SimilarHistoricPropertyResponse[]>> {
    return createHttp(token).get(
      `/properties/v2/${id}/similar-closed-properties?mode=${mode}&n_similar=${n_similar}`
    );
  },

  getCitiesByCountry(countryCode: string): Promise<any> {
    return createNoAuthHttp(BACKEND_URL).get(`/marketplace-cities/?country_code=${countryCode}`);
  },

  getCitiesByCountryV2(countryCode: string): Promise<AxiosResponse<Paginate<CitiesResponse>>> {
    return createNoAuthHttp(BACKEND_URL).get(`/marketplace-cities/?country_code=${countryCode}`);
  },

  getPropertiesByIds(
    ids: number[],
    authToken: string,
    params?: Record<string, string | boolean | number>
  ) {
    return createHttp(authToken).get(
      `/admin/v2/properties/?id__in=${ids.join(",")}&${qs.stringify(params, {
        skipEmptyString: true,
      })}`
    );
  },

  // NEW ENDPOINT TO GET PROPERTY INFO
  getPropertyInfo(id: number, token: string, view_name: string | null = null) {
    if (view_name)
      return createHttp(token).get(`/properties/v2/admin/${id}/?view_name=${view_name}`);
    return createHttp(token).get(`/properties/v2/admin/${id}/`);
  },

  // NEW ENDPOINT TO EDIT PROPERTY INFO
  editPropertyInfo(id: number, data: Partial<PropertyData>, token: string) {
    return createHttp(token).put(`/properties/v2/admin/${id}/`, data);
  },
  updateHCinfo(id: number, data: UpdateHCDetailsBody, params: UpdateHCParams, token: string) {
    const qparams = qs.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return createHttp(token).put(`/schedules/v2/coordinate-visit/${id}/?${qparams}`, data);
  },
  // get landlord property
  getLandlordProperty(id: number, token: string) {
    return createHttp(token).get(`/properties/v2/${id}/stats`);
  },
  // get landlord similar properties
  getLandlordSimilarProperties(id: number, type: "rent" | "sale", token: string) {
    //Query params type: rent or sale, max_distance_treshold: max number of similar properties, top_k: max distance to consider similar properties
    const filters = {
      type,
      top_k: 4,
      max_distance_treshold: 25,
    };
    return createHttp(token).get(
      `/properties/v2/${id}/similar-properties-prices?${qs.stringify(filters, qsConfig)}`
    );
  },
  // get landlord property acts
  getLandlordPropertyActs(id: number, token: string) {
    return createHttp(token).get(`/properties/v2/${id}/inspection_reports/comments`);
  },
  // Get property inspection reports and requests
  getPropertyInspectionReports(
    id: number,
    token: string,
    filters: Record<string, number | string> = {}
  ) {
    return createHttp(token).get(
      `/properties/v2/${id}/inspection_report_request/?${qs.stringify(filters)}`
    );
  },
  getLandlordSummaryProperties(
    id: number,
    token: string,
    params = {}
  ): Promise<AxiosResponse<ILandlordSummaryResponse>> {
    return createHttp(token).get(
      `/admin/v2/landlord_summary/${id}/properties/?${qs.stringify(params, qsConfig)}`
    );
  },

  createCommonExpenseOther(
    propertyId: number,
    data: {
      property_id?: number;
      company?: string;
      total_debt?: number;
      expired_debt?: number;
      administration_email?: string;
    },
    token: string
  ): Promise<AxiosResponse<CreateCommonExpenseResponse>> {
    return createHttp(token, PROPERTY_DUES_URL).post(`/bill/${propertyId}/detail`, data);
  },

  createCommonExpense(
    propertyId: number,
    data: {
      property_id?: number;
      company?: string;
      client_bill_id?: string;
    },
    token: string
  ): Promise<AxiosResponse<any[]>> {
    return createHttp(token, PROPERTY_DUES_URL).post(`/bill/${propertyId}/info`, data);
  },
  uploadVoucher(url: string, file: File): Promise<AxiosResponse> {
    return axios.put(url, file, {
      headers: {
        "Content-Type": "",
      },
    });
  },
  getPropertyKeys(id: number, token: string): Promise<AxiosResponse<KeysInfoResponse>> {
    return createHttp(token).get(`/properties/v2/${id}/keys/last/`);
  },
  getPropertySchedules(
    id: number,
    token: string
  ): Promise<AxiosResponse<{ results: IScheduleResponse[] }>> {
    return createHttp(token).get(`admin/v2/properties/${id}/schedules`);
  },
};
