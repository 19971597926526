import { FormProvider, useForm } from "react-hook-form";

import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import { FiltersProps, initialFilters } from "../../hooks/useFilters";
import { ModalText, ModalTitle } from "../typography";
import useFilterModalStyles from "./FilterModalStyles";
import FilterField from "./components/FilterField";

interface FilterModalProps {
  openModal: boolean;
  onClose: () => void;
  filters: FiltersProps;
  setFilters: (filter: FiltersProps) => void;
}

function FilterModal({ openModal, onClose, filters, setFilters }: FilterModalProps) {
  const classes = useFilterModalStyles();

  const methods = useForm<FiltersProps>({ reValidateMode: "onBlur" });

  const onSubmit = (data) => {
    setFilters(data);
    onClose();
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    methods.reset(initialFilters);
  };

  return (
    <DSModal
      open={openModal}
      onClose={onClose}
      showCloseButton
      classes={{ paper: classes.gridRoot }}>
      <Box className={classes.boxRoot}>
        <ModalTitle>Filtros</ModalTitle>
        <ModalText className={classes.dialogSubtitle}>
          Selecciona los filtros para facilitar tu busqueda
        </ModalText>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FilterField id="propertyId" label="ID propiedad" defaultValue={filters.propertyId} />
            <FilterField
              id="subscriptionId"
              label="ID suscripción"
              defaultValue={filters.subscriptionId}
            />
            <FilterField id="tenant" label="Arrendatario" defaultValue={filters.tenant} />
            <Box className={classes.buttonsContainer}>
              <Typography className={classes.linkButton} onClick={clearFilters}>
                Borrar filtros
              </Typography>
              <DesignSystemButton label="Aceptar" variant="primary" size="medium" type="submit" />
            </Box>
          </form>
        </FormProvider>
      </Box>
    </DSModal>
  );
}

export default FilterModal;
