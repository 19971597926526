import { FormControl, Typography } from "@material-ui/core";

import DropArea from "Components/Admin/UIComponents/ControlledForm/DropArea";
import useCustomFileDropzone from "./CustomFileDropzoneStyles";

export interface IFile {
  preview: string;
  [x: string]: any;
}

interface Props {
  allowedMimeTypes?: string;
  maxFiles?: number;
  id: string;
  label?: string;
  onChange: (files: (File | IFile)[]) => void;
}

const CustomFileDropzone = ({
  allowedMimeTypes = "image/*",
  id,
  label = "",
  maxFiles = 1,
  onChange,
}: Props) => {
  const classes = useCustomFileDropzone();

  return (
    <FormControl className={classes.wrapper}>
      {label && (
        <Typography id={id} className={classes.label}>
          {label}
        </Typography>
      )}
      <DropArea id={id} onChange={onChange} accepted={allowedMimeTypes} maxFiles={maxFiles} />
    </FormControl>
  );
};

export default CustomFileDropzone;
