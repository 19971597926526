import { makeStyles, Theme } from "@material-ui/core/";

const defaultBreakpoint = "md";

const useStyles = makeStyles<Theme>((theme) => ({
  anchor: {
    color: "black",
  },
  attachIconContainer: {
    flex: 1,
  },
  attachLabel: {
    fontSize: theme.typography.pxToRem(10),
  },
  documentContainer: {
    width: 70,
    height: 70,
    padding: theme.spacing(1),
    border: `1.5px dashed ${theme.palette.grey[200]}`,
    borderRadius: 5,
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  filenameContainer: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,
    textAlign: "center",
  },
  entryText: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  gridValue: {
    display: "flex",
    alignItems: "flex-end",
  },
  noRegistry: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  noData: {
    paddingLeft: theme.spacing(1),
  },
}));

export default useStyles;
