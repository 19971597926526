import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";

function ReviewInformation() {
  const {
    state: { isOpenReviewInformationModal },
  } = useDemandLead();
  const {
    state: { isOpenLoadingModal },
  } = useDemandSchedule();
  return (
    <CustomCenteredModal
      open={isOpenReviewInformationModal || isOpenLoadingModal}
      onClose={() => null}
      title="¡Estamos revisando la información!"
      subtitle="Este proceso puede tardar unos segundos"
      showCloseButton={false}
      withLogo
    />
  );
}
export default ReviewInformation;
