import { makeStyles, Theme } from "@material-ui/core";

const useSubscriptionNavStyles = makeStyles<Theme>((theme) => ({
  chip: {
    background: "#FEF1F0",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    cursor: "default",
    "&:hover": {
      background: "#FEF1F0 !important",
    },
  },
  container: {
    marginBottom: theme.spacing(4),
  },
}));

export default useSubscriptionNavStyles;
