import { makeStyles, Theme } from "@material-ui/core";

const useModalButtonsStyles = makeStyles<Theme>((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3),
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export default useModalButtonsStyles;
