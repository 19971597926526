import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import newEvaluationService from "services/newEvaluationService";

interface IFormData {
  status: string;
  updated_by: number;
  brokerage?: boolean;
}

const useUpdateEvaluationBrokerage = (
  evaluation_id: number,
  country: string,
  onMutateSuccess: () => void
) => {
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      await newEvaluationService.update(evaluation_id, formData, authToken, country as Locale);
    },
    {
      onSuccess: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "La evaluación se editó correctamente",
            type: "success",
          })
        );
        onMutateSuccess();
      },
      onError: (e: AxiosError<{ detail: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: `No se pudo editar la evaluación`,
            type: "error",
            body: `Error: ${reason ? reason?.data?.detail : e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useUpdateEvaluationBrokerage;
