import { Box } from "@material-ui/core";
import {
  KingBedOutlined,
  BathtubOutlined,
  CenterFocusStrong,
  HouseOutlined,
} from "@material-ui/icons";

import { Root, MultipleItem, FeaturesContainer, IconContainer } from "./CharacteristicsStyles";

interface Props {
  bedrooms?: number;
  bathrooms?: number;
  area?: number;
  propertyId?: number;
}

function Characteristics({ bedrooms = 0, bathrooms = 0, area = 0, propertyId = 0 }: Props) {
  return (
    <Root>
      <IconContainer>
        ID {propertyId}
        <HouseOutlined />
      </IconContainer>
      <FeaturesContainer>
        <MultipleItem>
          <Box>
            <KingBedOutlined fontSize="small" /> {bedrooms}
          </Box>
          <Box>
            <BathtubOutlined fontSize="small" /> {bathrooms}
          </Box>
        </MultipleItem>
        <Box>
          <CenterFocusStrong fontSize="small" /> {area}mts2
        </Box>
      </FeaturesContainer>
    </Root>
  );
}

export default Characteristics;
