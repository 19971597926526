import RevTab from "Components/Admin/administrator/properties/show/tabs/rev/RevTab";
import { CalculatorProvider } from "context/calculatorContext/calculatorContext";
import { SimilarPropertiesProvider } from "context/similarProperties/similarPropertiesContext";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}
function RevTabIndex({ property }: Props) {
  return (
    <CalculatorProvider>
      <SimilarPropertiesProvider>
        <RevTab property={property} />
      </SimilarPropertiesProvider>
    </CalculatorProvider>
  );
}

export default RevTabIndex;
