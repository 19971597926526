import { makeStyles, Theme } from "@material-ui/core";

const useTableStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "100%",
  },
  label: {
    textTransform: "none",
    color: theme.palette.grey[200],
  },
}));

export default useTableStyles;
