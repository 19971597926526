import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { IoTrashOutline as DeleteIcon } from "@houm-com/ui-icons";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";
import IconButton from "@houm-com/ui/IconButton";
import Button from "@houm-com/ui/Button";

import { IDebtCollectionInsuranceStatus } from "hooks/useGetDebtCollectionInsurances/utils/types";
import { IDebtCollectionFilters } from "hooks/useGetDebtCollections/utils/types";
import { IPlanCategory } from "models/Plan";
import { Locale } from "models/Countries";

import useFilterOptions from "../../hooks/useFilterOptions";
import FiltersDialog from "../FiltersDialog/FiltersDialog";
import FiltersPreview from "../FiltersPreview";
import Accordion from "../Accordion/Accordion";
import FilterFormType from "./utils/types";
import { labels } from "./utils/constants";
import classes from "./FilterStyles";

export interface FiltersProps {
  setFilters: (value: IDebtCollectionFilters) => void;
  filters: IDebtCollectionFilters;
  insurances: IDebtCollectionInsuranceStatus[];
  country: Locale;
  planCategories: IPlanCategory[];
  isLoading: boolean;
}

const defaultFilterValues = {
  status: null,
  insurance_status: null,
  insurance_code: null,
  plan_category_id: null,
};

export default function Filters({
  setFilters,
  filters,
  insurances = [],
  planCategories = [],
  country,
  isLoading,
}: FiltersProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const methods = useForm<FilterFormType>({
    defaultValues: defaultFilterValues,
  });
  const insuranceCode = methods.watch("insurance_code");

  const filterOptions = useFilterOptions({
    insuranceCode,
    insurances,
    country,
    planCategories,
  });

  const onSubmit = (data: FilterFormType) => {
    setFilters({ ...filters, ...data });
    handleCloseModal();
  };

  const handleClearFilters = () => {
    methods.reset();
    setFilters({
      ...filters,
      ...defaultFilterValues,
    });
  };

  const handleRemoveFilter = (filters: IDebtCollectionFilters, key: keyof FilterFormType) => {
    methods.setValue(key, "");
    setFilters(filters);
  };

  useEffect(() => {
    handleClearFilters();
    methods.setValue("status", "ONGOING");
  }, [country]);

  useEffect(() => {
    if (insuranceCode?.toLocaleLowerCase().includes("houm")) {
      methods.resetField("insurance_status");
    }
  }, [insuranceCode]);

  return (
    <div className={classes.container}>
      <FiltersDialog
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        isOpen={isModalOpen}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={classes.buttonContainer}>
              <Button variant="primary" type="submit" size="sm" className={classes.button}>
                Filtrar
              </Button>
            </div>
            <Accordion title={labels.status}>
              <FieldRadioButton
                id="status"
                options={filterOptions.status}
                {...methods.register("status")}
              />
            </Accordion>
            {!!filterOptions.insurance_status?.length && (
              <Accordion title={labels.insurance_status}>
                <FieldRadioButton
                  id="insurance_status"
                  options={filterOptions.insurance_status}
                  {...methods.register("insurance_status")}
                />
              </Accordion>
            )}
            <Accordion title={labels.insurance_code}>
              <FieldRadioButton
                id="insurance"
                options={filterOptions.insurance_code}
                {...methods.register("insurance_code")}
              />
            </Accordion>
            <Accordion title={labels.plan_category_id}>
              <FieldRadioButton
                id="plan_category_id"
                options={filterOptions.plan_category_id}
                {...methods.register("plan_category_id")}
              />
            </Accordion>
            <IconButton
              icon={<DeleteIcon className={classes.deleteFiltersButtonIcon} />}
              type="button"
              position="end"
              variant="tertiary"
              size="md"
              className={classes.deleteFiltersButton}
              onClick={handleClearFilters}>
              <span className={classes.deleteFiltersButtonText}>Limpiar filtros</span>
            </IconButton>
          </form>
        </FormProvider>
      </FiltersDialog>
      <FiltersPreview
        filters={filters}
        onRemoveFilters={handleRemoveFilter}
        filterOptions={filterOptions}
        labels={labels}
      />
    </div>
  );
}
