import * as yup from "yup";

export const requiredText = "Obligatorio";

export const addressSchema = yup.object({
  city: yup.string().required(requiredText),
  colonia: yup.string().required(requiredText),
  exteriorNumber: yup.string().required(requiredText),
  interiorNumber: yup.string().required(requiredText),
  state: yup.string().required(requiredText),
  street: yup.string().required(requiredText),
  zip: yup.string().required(requiredText),
});
