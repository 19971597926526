import { IProperty } from "models/createProperty";
import { ContractPropertyProps } from "../../utils/types";

export const parseBackendProperty = (data: ContractPropertyProps): IProperty => ({
  address: data.street,
  street_number: data.streetNumber?.toString(),
  commune: data.commune,
  numero_depto: data.flatNumber,
});

export const parseBackendPropertyDetails = (data: ContractPropertyProps) => ({
  valor: data.rentValue,
  gc: data.commonExpenses || 0,
  parking_numbers: data.parkingNumber,
  warehouse_number: data.warehouseNumber,
});
