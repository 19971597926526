import { Grid } from "@material-ui/core";

import HeaderPromotion from "./components/HeaderPromotion";
import PromotionsList from "./components/PromotionsList";
import CallToActions from "./components/CallToActions";
import usePromotions from "./hooks/usePromotions";

const Promotions = () => {
  const { handleStatus, handleCountry, status, country, leaseType, setLeaseType } = usePromotions();

  return (
    <Grid container>
      <HeaderPromotion handleCountry={handleCountry} country={country} />
      <CallToActions handleStatus={handleStatus} setLeaseType={setLeaseType} country={country} />
      <PromotionsList status={status} leaseType={leaseType} country={country} />
    </Grid>
  );
};

export default Promotions;
