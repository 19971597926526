import { makeStyles, Theme } from "@material-ui/core";

const useValidarDataCreditoStyles = makeStyles((theme: Theme) => ({
  selectDataCredito: {
    border: "2px solid #008280",
    outline: "none",
    borderRadius: 25,
    color: "#214b5d",
    transition: "0.4s linear",
    borderBottom: "2px solid #008280",
    textAlign: "left",
    width: 500,
    margin: "20px auto",
    display: "block",
    padding: 0,
  },
  formContainer: {
    textAlign: "center",
  },
  DniLastnameInput: {
    width: 500,
  },
  icon: {
    color: theme.palette.grey[900],
    pointerEvents: "none",
  },
  item: {
    width: "100%",
  },
}));

export default useValidarDataCreditoStyles;
