import { useMutation } from "react-query";

import { useGetToken } from "hooks";
import insuranceService from "services/insuranceService";

const parseContractInsuranceCompanyData = (
  insuranceCompanyId: string,
  contractId: string,
  objectId: number,
  guaranteedMonths?: number
) => ({
  insurance_company: parseInt(insuranceCompanyId),
  contract: parseInt(contractId),
  guaranteed_months: guaranteedMonths,
  id: objectId,
});

interface IPostInsuranceCompanyContract {
  insuranceCompanyId: string;
  contractId: string;
  guaranteedMonths?: number;
  objectId?: number;
}

const usePostInsuranceCompanyContract = () => {
  const { getToken } = useGetToken();

  const { mutate: postInsuranceCompanyContract, isLoading: submittingInsuranceCompanyContract } =
    useMutation(
      async ({
        insuranceCompanyId,
        contractId,
        guaranteedMonths,
        objectId,
      }: IPostInsuranceCompanyContract) => {
        const authToken = await getToken();
        const data = parseContractInsuranceCompanyData(
          insuranceCompanyId,
          contractId,
          objectId,
          guaranteedMonths
        );
        if (objectId) {
          const response = await insuranceService.patchInsuranceCompanyContract(data, authToken);
          return response.data;
        }
        const response = await insuranceService.postInsuranceCompanyContract(data, authToken);
        return response.data;
      }
    );
  return {
    postInsuranceCompanyContract,
    submittingInsuranceCompanyContract,
  };
};

export default usePostInsuranceCompanyContract;
