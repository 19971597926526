import { useQueryClient } from "react-query";

import IconButton from "@houm-com/ui/IconButton";
import { IoReloadOutline } from "@houm-com/ui-icons";

import HeaderWithCountries from "domains/common/components/HeaderWithCountries";
import { Locale } from "models/Countries";

interface Props {
  onChangeCountry: (c: Locale) => void;
  country: Locale;
}

const evaluationsSteps = [
  {
    label: "Evaluator",
  },
  {
    label: "Evaluaciones",
    link: "/admin/evaluationsv2",
  },
];

const EvaluationsListHeader = ({ onChangeCountry, country }: Props) => {
  const queryClient = useQueryClient();

  const onRefresh = () => {
    queryClient.invalidateQueries("all_evaluations", { exact: false });
    queryClient.invalidateQueries("evaluation_statistics", { exact: false });
  };

  return (
    <HeaderWithCountries
      breadcrumbsSteps={evaluationsSteps}
      onChangeCountry={onChangeCountry}
      country={country}>
      <div className="flex items-center justify-between">
        <p className="text-[24px] font-bold">Evaluaciones</p>
        <IconButton
          size="md"
          variant="tertiary"
          position="start"
          icon={<IoReloadOutline />}
          onClick={onRefresh}>
          Recargar
        </IconButton>
      </div>
    </HeaderWithCountries>
  );
};

export default EvaluationsListHeader;
