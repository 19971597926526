import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "react-hook-form";

import { Typography } from "@material-ui/core";
import { houmboxSchema } from "./validationSchema";
import { useGetToken } from "hooks";
import { useProperty } from "context/propertyContext/propertyContext";
import { getProperty, updateHCDetails } from "context/propertyContext/propertyActions";

import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";
import houmBoxFields from "./fields";
import { IKeys } from "../../utils/types";

interface Props {
  showKeyCard: (val: boolean) => void;
  keysData: IKeys;
}

function InhabitedCard({ showKeyCard, keysData }: Props) {
  const { state, dispatch } = useProperty();
  const { getToken } = useGetToken();

  const hasHoumbox =
    keysData?.location === "Houmbox" || state.property?.home_checker_details?.[0].has_houm_box;
  const hcId = state.property?.home_checker_details?.[0].id;

  const { methods, goNextStep, goBackStep, label, currentStep, checked, toggleCheck } =
    useVisitCard({
      defaultChecked: hasHoumbox,
      resolver: yupResolver(houmboxSchema),
    });

  useEffect(() => {
    if (checked) {
      goBackStep();
    }
    showKeyCard(!checked || Boolean(keysData));
  }, [checked, keysData]);

  const fields = houmBoxFields({
    checked,
    currentStep,
    houmBoxNumber:
      keysData?.houmboxLockNumber ?? state.property.home_checker_details?.[0].houm_box_number,
  });

  const handleSubmit = async (data) => {
    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      { ...data, has_houm_box: true, key_manager: "" },
      hcId,
      dispatch,
      authToken
    );

    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  const buttonLabel = checked ? label : "Actualizar";
  const showButton = checked;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          id="houmbox"
          items={fields}
          title={<TitleCard title="Tiene candado - Houmbox " />}
          checkedOptions={{
            checked,
            onChange: toggleCheck,
          }}
          buttonOptions={
            showButton && {
              buttonLabel,
              buttonCallback: goNextStep,
              buttonPosition: "right",
              isSubmit: checked && currentStep === "editable",
            }
          }
          content={
            !checked && (
              <Typography component="p" variant="caption">
                Si esta propiedad no tiene houmbox se debe completar la informacion de “Responsable
                de las llaves"
              </Typography>
            )
          }
          isEditable={!keysData}
        />
      </form>
    </FormProvider>
  );
}

export default InhabitedCard;
