/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { useState, useEffect } from "react";
import Button from "./Buttons/CustomButton";
import { Country } from "models/Countries";

import useStyles from "./HeaderWithCountriesStyles";

interface Props {
  title: string;
  country?: Country | "";
  showCountry?: boolean;
  onCountryChange: (c: Country) => void;
}

function Header({ title, country = "chile", onCountryChange, showCountry = true }: Props) {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState<Country | "">(country);
  useEffect(() => {
    setSelectedCountry(country);
  }, [country]);

  const onClickHandle = (c: Country) => {
    setSelectedCountry(c);
    onCountryChange(c);
  };

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.title}>{title}</p>
      </div>
      {showCountry && (
        <div className={classes.buttonContainer}>
          <Button
            kind="blue"
            active={selectedCountry === "chile"}
            onClick={() => onClickHandle("chile")}>
            Chile
          </Button>
          <Button
            kind="blue"
            active={selectedCountry === "colombia"}
            onClick={() => onClickHandle("colombia")}>
            Colombia
          </Button>
          <Button
            kind="blue"
            active={selectedCountry === "mexico"}
            onClick={() => onClickHandle("mexico")}>
            México
          </Button>
        </div>
      )}
    </div>
  );
}

export default Header;
