import { makeStyles, Theme } from "@material-ui/core";

const useProgressCardStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  state: {
    minWidth: "28%",
    maxWidth: "30%",
    minHeight: 300,
    backgroundColor: "#fff",
    borderRadius: 10,
    border: "1px solid #FF866A",
    padding: theme.spacing(1),
  },
  valueInfo: {
    fontWeight: "bold",
  },
}));

export default useProgressCardStyles;
