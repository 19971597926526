export const Countries = [
  { label: "Chile", value: "Chile" },
  { label: "México", value: "Mexico" },
  { label: "Colombia", value: "Colombia" },
  { label: "Todos", value: "" },
];

export const Roles = [
  { label: "Mostrador Arriendo", value: "RentGuide" },
  { label: "Fotógrafo Arriendo", value: "RentPhotographer" },
  { label: "Mostrador Venta", value: "SalesGuide" },
  { label: "Fotógrafo Venta", value: "SalesPhotographer" },
  { label: "Asesor", value: "Advisor" },
  { label: "Dummy", value: "Dummy" },
  { label: "Interno", value: "Intern" },
  { label: "Todos", value: "" },
];
