import { useHistory } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  getDocumentType,
  parseDocumentType,
  removeRepeatedNames,
} from "Components/Admin/administrator/subscriptions/utils/utils";
import { UserModel } from "models/User";

import useUserStyles from "./UserStyles";

interface Props {
  user: UserModel;
}

const User = ({ user }: Props) => {
  const history = useHistory();
  const classes = useUserStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.container}>
          <Typography className={classes.infoText}>
            {removeRepeatedNames(`${user?.name} ${user?.last_name}`)}
          </Typography>
          <Typography className={classes.infoText}>
            {` ${
              user?.document_type ? `${getDocumentType(parseDocumentType(user))}: ` : "Documento: "
            }`}
            {` ${user?.document ?? "-"}`}
          </Typography>
          <Typography className={classes.infoText}>{user.email}</Typography>
          <Typography className={classes.infoText}>{user.phone}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DesignSystemButton
          variant="primary"
          label="Modificar"
          size="small"
          onClick={() => history.push(`/admin/users/${user.id}/edit`)}
        />
      </Grid>
    </Grid>
  );
};

export default User;
