import { makeStyles, Theme } from "@material-ui/core/styles";

const useLayoutIndexStyles = makeStyles((theme: Theme) => ({
  features: {
    display: "flex",
    gap: 12,
  },
  actionButtonRounded: {
    borderRadius: 200,
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  layoutPhoto: {
    height: 64,
    width: 64,
    objectFit: "cover",
    borderRadius: 8,
  },
  icon: {
    fontSize: 20,
    marginLeft: theme.spacing(1),
  },
  buttonIcon: {
    fill: "currentColor",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2),
  },
  divider: {
    height: 0,
    borderTop: "1px dashed rgba(0, 0, 0, 0.87)",
  },
  confirmButtonRounded: {
    borderRadius: 200,
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  cancelButton: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    padding: theme.spacing(1, 3),
    border: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
  },
  spanText: {
    fontSize: theme.typography.pxToRem(14),
  },
  buttonText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    gap: theme.spacing(2),
  },
}));

export default useLayoutIndexStyles;
