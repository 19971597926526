import { makeStyles } from "@material-ui/core";

const useBankAccountStyles = makeStyles((theme) => ({
  rootCard: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  addAccount: {
    marginTop: theme.spacing(2),
  },
  collapse: {
    padding: theme.spacing(3),
  },
}));

export default useBankAccountStyles;
