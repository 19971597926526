import { Grid, Tooltip, Typography } from "@material-ui/core";

import useGridItemStyles from "./GridItemStyles";

interface Props {
  text: string;
  tooltip: string;
}

function GridItemTooltip({ text, tooltip }: Props) {
  const classes = useGridItemStyles();

  return (
    <Grid item xs={4}>
      <Tooltip title={tooltip}>
        <Typography className={classes.text}>{text}</Typography>
      </Tooltip>
    </Grid>
  );
}

export default GridItemTooltip;
