import { SnackbarProvider } from "notistack";

import { Grid, Typography } from "@material-ui/core";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import { IPlanMovement, ISimulationData, ContractModel } from "models/Contract";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import PaymentsPerMonth from "./PaymentsPerMonth";
import PlanMovements from "./PlanMovements";
import useStyles from "./PaymentsInfoStyles";
import { checkStatus } from "../utils";

interface Props {
  type: "lessee" | "lessor";
  contract: ContractModel;
  planMovement: IPlanMovement[];
  loadingPlan: boolean;
  simulationData: ISimulationData;
  loadingSimulation: boolean;
  getSimulation: () => void;
  setPlanMovement: (movement: IPlanMovement[]) => void;
}

function PaymentsInfo({
  type,
  contract,
  planMovement,
  loadingPlan,
  simulationData,
  loadingSimulation,
  getSimulation,
  setPlanMovement,
}: Props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Typography component="p" className={classes.title}>
          Descuentos y abonos del cierre
        </Typography>
        <Loading loading={loadingPlan}>
          <SnackbarProvider>
            <PlanMovements
              type={type}
              planMovement={planMovement}
              contract={contract}
              setPlanMovement={setPlanMovement}
            />
          </SnackbarProvider>
        </Loading>
        {!!contract?.plan && contract?.plan.months_duration !== 0 && (
          <Loading loading={loadingSimulation}>
            <PaymentsPerMonth type={type} simulationData={simulationData} />
          </Loading>
        )}
        {!checkStatus(["Boton de pago enviado", "Pagado", "Finalizado"], contract.status) && (
          <Grid item xs={12}>
            <div className={classes.ctaContent}>
              <CustomLoadingButton
                text="Simular"
                loading={loadingSimulation}
                onClick={() => getSimulation()}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default PaymentsInfo;
