import { RISK_URL } from "../env";
import { createHttp } from "./helper";

export interface IData {
  doc_type: string;
  doc_number: string;
  doc_issue_date: string;
}

export default {
  async get_reports(token: string, data: IData) {
    return createHttp(token, RISK_URL).get("/co/report", { params: data });
  },
};
