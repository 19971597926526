import { Box, Container, Typography } from "@material-ui/core";

import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import { ServiceType } from "../../utils/types";
import useDeleteServiceModalStyles from "./DeleteServiceModalStyles";

const translateService = (type: ServiceType) =>
  type === "basicService" ? "servicio básico" : "gasto común";

interface Props {
  handleClose: () => void;
  open: boolean;
  onDeleteService: () => void;
  loading: boolean;
  type?: ServiceType;
}

function DeleteServiceModal({
  handleClose,
  open,
  onDeleteService,
  loading,
  type = "basicService",
}: Props) {
  const classes = useDeleteServiceModalStyles();

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <Box className={classes.deleteBasicServiceRoot}>
        <Container className={classes.titleContainer}>
          <WarningIcon />
          <Typography className={classes.title}>
            ¿Estás seguro que quieres eliminar este {translateService(type)}?
          </Typography>
        </Container>
        <Box className={classes.buttons}>
          <DesignSystemButton
            variant="secondary"
            size="small"
            label="Cancelar"
            onClick={handleClose}
            buttonClassName={classes.cancelButton}
          />
          <DesignSystemButton
            variant="primary"
            size="small"
            label="Confirmar"
            type="submit"
            onClick={onDeleteService}
            isLoading={loading}
          />
        </Box>
      </Box>
    </DSModal>
  );
}

export default DeleteServiceModal;
