import { AxiosResponse } from "axios";

import { CountryUpperCase } from "models/Countries";
import { Paginate } from "models/ResponseWithPaginate";

import { createHttp } from "../helper";
import { DocumentTypeResponse } from "./types";

export default {
  getList: async (
    country: CountryUpperCase | string,
    token: string
  ): Promise<AxiosResponse<Paginate<DocumentTypeResponse>>> =>
    createHttp(token).get(`/v2/document_type/?country=${country}`),
  getDocumentList: async (country: CountryUpperCase, token: string) =>
    createHttp(token).get(`/v2/document_type/?country=${country}`),
};
