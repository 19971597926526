import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import useSpinnerStyles from "./SpinnerStyles";

interface Props {
  className?: string;
  iconClassName?: string;
  size?: number;
}

function Spinner({ className, iconClassName, size }: Props) {
  const classes = useSpinnerStyles();

  return (
    <div
      className={classNames(classes.root, className)}
      aria-valuetext="Loading…"
      aria-busy="true"
      aria-live="assertive">
      <CircularProgress className={classNames(classes.icon, iconClassName)} size={size} />
    </div>
  );
}

export default Spinner;
