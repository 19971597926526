import CircularProgress from "@material-ui/core/CircularProgress";
import { GridToolbarContainer } from "@material-ui/x-grid";

import useStyles from "./ToolbarStyles";
import ExcelExport from "./ExcelExport";
import { IAssing } from "../utils";

interface Props {
  loadingAssignation: boolean;
  assignation: IAssing[];
}

function Toolbar({ loadingAssignation, assignation }: Props) {
  const classes = useStyles();

  return (
    <GridToolbarContainer>
      <div className={classes.container}>
        {loadingAssignation ? <CircularProgress size={25} /> : <ExcelExport data={assignation} />}
      </div>
    </GridToolbarContainer>
  );
}

export default Toolbar;
