import { UseQueryResult, useQuery } from "react-query";

import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import {
  EvaluationsFilter,
  EvaluationsList,
  EvaluationsListResponseSchema,
} from "domains/evaluations/models/Evaluations";

const useGetEvaluations = (country: Locale, filters: Partial<EvaluationsFilter>) => {
  const { getToken } = useGetToken();

  const query: UseQueryResult<EvaluationsList, Error> = useQuery<EvaluationsList, Error>(
    ["all_evaluations", country, filters],
    async () => {
      const authToken = await getToken();
      const parseFilters = {
        page_size: filters.size,
        page: filters.page,
        property_id: [filters.propertyId],
        evaluation_ids: [filters.evaluationId],
        member_status: filters.memberStatus,
        applicant_name: filters.applicantName,
        sort: filters.sort,
        order: filters.order,
      };
      const { data } = await service.getEvaluationsList(country, parseFilters, authToken);
      return EvaluationsListResponseSchema.parse(data);
    },
    {
      enabled: !!country,
      retry: false,
      retryOnMount: true,
      keepPreviousData: false,
    }
  );

  return {
    ...query,
    data: {
      evaluations: query?.data?.evaluations ?? [],
      totalCount: query?.data?.totalCount ?? 0,
    },
  };
};

export default useGetEvaluations;
