/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from "react";

import { GridColDef, GridColumns } from "@material-ui/data-grid";
import { setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";

import { CustomDataTable } from "Components/Admin/UIComponents";
import useTenantTableStyles from "./TenantTableStyles";
import { useUsers } from "../../context/usersContext";

function TenantTable() {
  const classes = useTenantTableStyles();
  const { state: userState } = useUsers();
  const { state, dispatch } = useTable();
  const { pageSize } = state;
  const { user, loading } = userState;
  const [rows, setRows] = useState([]);

  const createEmptyArray = (len: number) => {
    const emptyObject = {
      propertyId: "-",
      date: "-",
      status: "-",
      houmer: "-",
    };
    return new Array(len).fill(null).map((_, i) => ({ id: i, ...emptyObject }));
  };

  useEffect(() => {
    dispatch(setPageSize(5));
  }, []);

  useEffect(() => {
    const emptyRows = pageSize - (user.applications.length % pageSize);
    const emptyRowsArray = user.applications.length !== pageSize ? createEmptyArray(emptyRows) : [];
    setRows([...user.applications, ...emptyRowsArray]);
  }, [user, pageSize]);

  return (
    <div className={classes.container}>
      <CustomDataTable
        rowCount={rows.length}
        columns={columns}
        rows={rows}
        rowsPerPageOptions={[pageSize]}
        loading={loading}
        pageSize={pageSize}
        paginationMode="client"
        sortingMode="client"
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
        }}
      />
    </div>
  );
}

export default TenantTable;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
};

const columns: GridColumns = [
  {
    ...columnsConfig,
    field: "propertyId",
    headerName: "ID propiedad",
    width: 100,
    type: "equalStr",
  },
  {
    ...columnsConfig,
    field: "date",
    headerName: "Fecha",
    width: 100,
    type: "equalStr",
  },
  {
    ...columnsConfig,
    field: "status",
    headerName: "Estado",
    width: 100,
    type: "equalStr",
  },
  {
    ...columnsConfig,
    field: "houmer",
    headerName: "Houmer",
    width: 100,
    type: "equalStr",
  },
];
