import { ReactNode } from "react";
import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";
import BaseField from "../../../BaseField";
import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";

export interface PriorityFieldProps {
  symbol: ReactNode;
  tagType: TagType;
  value: number;
}

function PriorityField({ symbol, tagType, value }: PriorityFieldProps) {
  return (
    <BaseField requiredData={value}>
      <DSTag type={tagType} icon={symbol} text={value.toString()} />
    </BaseField>
  );
}

export default PriorityField;
