import { ReactElement, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldTextArea from "@houm-com/ui/FieldTextArea";
import ModalDialog from "@houm-com/ui/ModalDialog";

import { Locale } from "models/Countries";
import statuses from "../../utils/insuranceStatuses";
import useChangeDebtCollectionInsuranceStatus from "hooks/useChangeDebtCollectionInsuranceStatus/useChangeDebtCollectionInsuranceStatus";

import { validationSchema } from "./validationSchema";
import classes from "./InsuranceStatusModalStyles";

export interface StatusModalData {
  contractId: number;
  companyCode: string;
}

interface InsuranceStatusModalProps {
  statusData: StatusModalData;
  country: Locale;
  isOpen: boolean;
  handleClose: () => void;
}

type FormProps = {
  status: string;
  comment: string;
};

type Status = {
  name: string;
  tag: ReactElement;
};

export default function InsuranceStatusModal({
  statusData,
  country,
  isOpen,
  handleClose,
}: InsuranceStatusModalProps) {
  const { mutate, isLoading } = useChangeDebtCollectionInsuranceStatus(handleClose);
  const methods = useForm<FormProps>({ resolver: yupResolver(validationSchema) });
  const onSubmit = (data: FormProps) => {
    if (isLoading) return;
    mutate({ ...data, ...statusData });
  };
  const { errors } = methods.formState;
  const statusOptions = useMemo(() => {
    if (country) {
      return Object.entries<Status>(statuses[country]({})).map(
        ([key, statusItem]: [string, Status]) => ({
          value: key,
          label: statusItem.name,
        })
      );
    } else {
      return [];
    }
  }, [country]);
  useEffect(() => {
    if (!isOpen) {
      methods.reset();
    }
  }, [isOpen]);
  return (
    <ModalDialog isOpen={isOpen} title="Actualización de estado" hasCloseBtn onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FieldSelect
            id="status"
            placeholder="Seleccionar"
            label="Estado"
            options={statusOptions}
            errorMessage={errors.status?.message}
            {...methods.register("status")}
          />
          <div className={classes.commentInputContainer}>
            <FieldTextArea
              id="comment"
              placeholder="Escriba aquí su texto"
              label="Comentario"
              className={classes.commentInput}
              errorMessage={errors.comment?.message}
              {...methods.register("comment")}
            />
          </div>
          <ModalDialog.Actions
            submitButtonLabel="Guardar"
            cancelButtonLabel="Cancelar"
            submitButtonProps={{ type: "submit" }}
            onClose={handleClose}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
}
