import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import FieldTextArea from "@houm-com/ui/FieldTextArea";
import Button from "@houm-com/ui/Button";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import Form from "../Form";

const OtherLiquidationForm = () => {
  const {
    setValue,
    getValues,
    register,
    formState: { errors, defaultValues },

    watch,
  } = useFormContext<MemberInformation>();
  const [otherLiquidationCount, setOtherLiquidationCount] = useState<0 | 1 | 2 | 3>(0);

  useEffect(() => {
    const filteredData = Object.entries(defaultValues)
      .filter(([key, value]) => key.startsWith("otherIncomeAmount"))
      .map(([key, value]) => ({ [key]: value }));
    setOtherLiquidationCount(filteredData.length as 1 | 2 | 3);
  }, [defaultValues]);

  const [income1, income2, income3] = watch([
    "salaryIncomeAmount1",
    "salaryIncomeAmount2",
    "salaryIncomeAmount3",
  ]);

  const addOtherLiquidation = () => {
    if (otherLiquidationCount < 3) {
      const liquidationId = (otherLiquidationCount + 1) as 1 | 2 | 3;
      setValue(`otherIncomeMonth${liquidationId}`, getValues(`salaryIncomeMonth${liquidationId}`));
      setOtherLiquidationCount((prev) => (prev + 1) as 1 | 2 | 3);
    }
  };

  const deleteOtherLiquidation = () => {
    if (otherLiquidationCount > 0) {
      const liquidationId = otherLiquidationCount as 1 | 2 | 3;
      setValue(`otherIncomeDescription${liquidationId}`, undefined);
      setValue(`otherIncomeAmount${liquidationId}`, undefined);
      setValue(`otherIncomeMonth${liquidationId}`, undefined);
      setOtherLiquidationCount((prev) => (prev - 1) as 0 | 1 | 2);
    }
  };

  return (
    <div>
      {otherLiquidationCount > 0 && (
        <h2 className="text-h20 font-bold mt-5 mb-2">Otras Liquidaciones</h2>
      )}
      {otherLiquidationCount > 0 &&
        [...Array(otherLiquidationCount).keys()].map((liquidationId) => (
          <div key={liquidationId} className="flex gap-2 flex-col items-end">
            <Form liquidationId={(liquidationId + 1) as 1 | 2 | 3} type="other" />
            <div className="w-full">
              <FieldTextArea
                label="Descripción"
                id={`otherIncomeDescription${(liquidationId + 1) as 1 | 2 | 3}`}
                errorMessage={errors.comment?.message}
                placeholder="Escribe una descripción"
                form="novalidateform"
                {...register(`otherIncomeDescription${(liquidationId + 1) as 1 | 2 | 3}`)}
              />
            </div>
            {liquidationId + 1 === otherLiquidationCount && (
              <Button size="sm" variant="secondary" type="button" onClick={deleteOtherLiquidation}>
                Eliminar
              </Button>
            )}
          </div>
        ))}
      {otherLiquidationCount < 3 && !!income1 && !!income2 && !!income3 && (
        <Button
          type="button"
          size="sm"
          variant="primary"
          onClick={addOtherLiquidation}
          className="mt-2">
          Agregar
        </Button>
      )}
    </div>
  );
};

export default OtherLiquidationForm;
