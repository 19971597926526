import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  mapWrapper: {
    width: "100% !important",
    height: "100% !important",
    "& .mapboxgl-map": {
      width: "100% !important",
      height: "100% !important",
    },
    "& .marker": {
      backgroundImage: "url('/static/images/map-icon.png')",
      backgroundSize: "cover",
      width: 30,
      height: 30,
      borderRadius: "50%",
      cursor: "pointer",
    },
    "& .mapboxgl-popup": {
      padding: "5px",
    },
    "& .mapboxgl-popup-content": {
      textAlign: "center",
      fontFamily: "'Open Sans', sans-serif",
      fontSize: "15px",
      padding: "15px,",
    },
  },
}));
