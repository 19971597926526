import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { useEvent } from "context/eventContext/eventContext";
import {
  ControlledAutocomplete,
  ControlledTextField,
  ControlledPhoneInput,
  ControlledList,
} from "Components/Admin/UIComponents/ControlledForm";

import { useDocumentTypeList } from "hooks";
import { formatPhoneInput } from "utils";
import { CountryUpperCase } from "models/Countries";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import UserSearchBar from "./UserSearchBar";
import TimeFields from "./TimeFields";

import useLeftColumnFormStyles from "./LeftColumnFormStyles";
import { setUserCountry } from "context/eventContext/eventActions";

function LeftColumnForm() {
  const { state, dispatch } = useEvent();
  const { events, appraisers, user, beginDate, endDate, propertyId } = state;

  const classes = useLeftColumnFormStyles();

  const { watch } = useFormContext();

  const phoneValue = watch("phone");
  const nameValue = watch("name");
  const lastNameValue = watch("last_name");
  const documentValue = watch("document");

  const parsedNumber = formatPhoneInput(user?.phone);
  const defaultControlledPhoneValue = {
    country: parsedNumber.country,
    phone: parsedNumber.phone,
  };

  const { documentTypeList, loading } = useDocumentTypeList(
    (phoneValue?.country?.countryName as CountryUpperCase) ||
      (defaultControlledPhoneValue.country.countryName as CountryUpperCase)
  );

  const controlledInputProps = {
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.outline,
      },
    },
  };
  const textFieldProps = {
    ...controlledInputProps,
    InputProps: {
      ...controlledInputProps.InputProps,
      placeholder: "Ingresa los datos",
    },
    disabled: !!user?.name,
  };
  const phoneFieldProps = {
    ...controlledInputProps,
    InputProps: {
      ...controlledInputProps.InputProps,
      placeholder: "0000000000",
    },
    disabled: !!user?.phone,
  };

  useEffect(() => {
    dispatch(setUserCountry(phoneValue?.country?.countryName as CountryUpperCase));
  }, [phoneValue?.country?.countryName]);

  return (
    <>
      <ControlledAutocomplete
        id="event_type"
        label="Tipo de Agendamiento"
        options={events}
        textFieldProps={{
          disabled: !!propertyId,
        }}
      />
      <ControlledAutocomplete id="appraiser" label="Mostrador/Fotógrafo" options={appraisers} />
      {["Visit", "SalesVisit", "Advisory"].includes(watch("event_type")?.value) && (
        <>
          {!phoneValue?.phone && !nameValue && !lastNameValue && !documentValue && (
            /* I need to use beginDate as a disable condition since is the only state 
              i have available to use while keeping the UX consistent
              before the schedule example: 2022-07-02, the string is less than 10 characters
              after the schedule example: 2022-07-02T12:00:00-06:00, so i can use it */
            <UserSearchBar id="user" defaultValue={user} disabled={beginDate.length > 10} />
          )}
          {(phoneValue?.phone || nameValue || lastNameValue || documentValue) && (
            <ControlledTextField
              defaultValue={user?.email}
              id="email"
              label="Correo del usuario a agendar"
              textFieldProps={textFieldProps}
            />
          )}
          {!user?.email && (
            <>
              <ControlledTextField
                defaultValue={user?.name}
                id="name"
                label="Nombre"
                textFieldProps={textFieldProps}
              />
              <ControlledTextField
                defaultValue={user?.last_name}
                id="last_name"
                label="Apellido"
                textFieldProps={textFieldProps}
              />
              <ControlledPhoneInput
                id="phone"
                phoneLabel="Teléfono"
                defaultValue={defaultControlledPhoneValue}
                countryInputProps={phoneFieldProps}
                numberInputProps={phoneFieldProps}
              />
              <Loading loading={loading}>
                <>
                  <ControlledList
                    disableUnderline
                    label="Tipo de documento"
                    id="documentType"
                    options={documentTypeList}
                  />
                  <ControlledTextField
                    label={
                      phoneValue?.country?.countryName === "Chile"
                        ? "RUT"
                        : "Documento de identidad"
                    }
                    id="document"
                    textFieldProps={textFieldProps}
                    defaultValue={user?.document ?? ""}
                  />
                </>
              </Loading>
            </>
          )}
        </>
      )}
      <TimeFields beginDate={beginDate} endDate={endDate} />
    </>
  );
}

export default LeftColumnForm;
