import { makeStyles, Theme } from "@material-ui/core";

const useLabelStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontHeight: 1.6,
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
}));

export default useLabelStyles;
