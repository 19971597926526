import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface IEditPaymentOrder {
  movementId: string;
  country: Locale;
  status?: string;
  agreedDate?: string;
}

const useEditPaymentOrder = (onCloseModal?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess } = useMutation(
    async (data: IEditPaymentOrder) => {
      const authToken = await getToken();
      const response = await newPaymentService.changePayMovementData(
        data.country,
        data.movementId,
        {
          ...(data.status && { status: data.status }),
          ...(data.agreedDate && {
            agreed_date: format(new Date(data.agreedDate), "yyyy-MM-dd"),
          }),
        },
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        onCloseModal?.();
        alert.success({
          message: "Orden de pago actualizada",
          disclaimer: "La orden de pago se ha actualizado correctamente",
        });
      },
      onError: (e: AxiosError) => {
        const error = e as AxiosError;
        const reason = error.response?.data?.message;
        alert.error({
          message: "Error al actualizar orden de pago",
          disclaimer: `No se pudo actualizar la orden de pago, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useEditPaymentOrder;
