import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Box } from "@material-ui/core";

import { useGetToken } from "hooks";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import {
  getLetterOfIntent,
  getLetterOfIntentPerson,
  setLoiId,
  setCountry,
  setPersonId,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import useLetterOfIntentPersonDetailIndexStyles from "./LetterOfIntentPersonDetailStyles";
import { Locale } from "models/Countries";
import Header from "./components/Header";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import PersonCard from "./components/PersonCard";
import DocumentsCard from "./components/DocumentsCard";
import BankDataCard from "./components/BankDataCard";
import { PersonType, PersonTypes } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";

const useQuery = () => new URLSearchParams(useLocation().search);

function LetterOfIntentPersonDetailIndex(props) {
  const { dispatch, state } = useLetterOfIntent();
  const { person, loading } = state;
  const { getToken } = useGetToken();
  const classes = useLetterOfIntentPersonDetailIndexStyles();
  const params = useParams<{ id: string; person_id: string }>();
  const { id: loiId, person_id: personId } = params;

  const country: Locale = useQuery().get("country") as Locale;
  const type = useQuery().get("type") as PersonType;

  useEffect(() => {
    dispatch(setLoiId(loiId));
    dispatch(setCountry(country));
    dispatch(setPersonId(personId));
    getToken().then(async (token) => {
      await getLetterOfIntent({ country, loiId, token, dispatch });
    });
  }, []);

  useEffect(() => {
    getToken().then(async () => {
      await getLetterOfIntentPerson({ personId, type, state, dispatch });
    });
  }, [state.bidders, state.owners]);

  if (!person) return null;
  return (
    <Box className={classes.container}>
      <Header type={type} person={person} />
      <Loading loading={loading} className={classes.loading}>
        <>
          <Box className={classes.ownerContainer}>
            <PersonCard />
          </Box>
          {type === PersonTypes.OWNER && <BankDataCard />}
          <DocumentsCard />
        </>
      </Loading>
    </Box>
  );
}

export default LetterOfIntentPersonDetailIndex;
