import axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import { CreateLandlordEvaluationResponse, EvaluationDetailsModel } from "models/Evaluation";
import { Locale } from "models/Countries";
import { EVALUATIONS_URL } from "env";

import { getToken } from "./helper";

const evaluations = (token: string, country = "cl", version: string = "v2") =>
  axios.create({
    baseURL: `${EVALUATIONS_URL}/${country}/${version}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    },
  });

const getVersion = (country: Locale) => (country === "cl" ? "v2" : "v1");

export default {
  getPage: (
    {
      size = null,
      page = null,
      sort = null,
      order = null,
      mainApplicant = null,
      codebtor = null,
      status = null,
      property = null,
      email = null,
      relatedApplicant = null,
      token = null,
    },
    country = "cl" as Locale
  ) => {
    const version = getVersion(country);
    let query = `size=${size || 10}&page=${page || 1}`;
    if (sort) query = query.concat("&sort=", sort);
    if (order) query = query.concat("&order=", order);
    if (mainApplicant) query = query.concat("&main=", mainApplicant);
    if (codebtor) query = query.concat("&codebtor=", codebtor);
    if (status) query = query.concat("&status=", status);
    if (email) query = query.concat("&email=", email);
    if (property) query = query.concat("&property_id=", property);
    if (relatedApplicant) query = query.concat("&related_applicant=", relatedApplicant);
    return evaluations(token, country, version)
      .get(`/evaluations?${query}`)
      .catch((err) => console.error(err));
  },
  getEvaluationPage: (
    {
      size = null,
      page = null,
      sort = null,
      order = null,
      mainApplicant = null,
      codebtor = null,
      status = null,
      property = null,
      email = null,
      relatedApplicant = null,
      token = null,
    },
    country = "cl" as Locale
  ) => {
    const version = getVersion(country);
    let query = `size=${size || 10}&page=${page || 1}`;
    if (sort) query = query.concat("&sort=", sort);
    if (order) query = query.concat("&order=", order);
    if (mainApplicant) query = query.concat("&main=", mainApplicant);
    if (codebtor) query = query.concat("&codebtor=", codebtor);
    if (status) query = query.concat("&status=", status);
    if (email) query = query.concat("&email=", email);
    if (property) query = query.concat("&property_id=", property);
    if (relatedApplicant) query = query.concat("&related_applicant=", relatedApplicant);
    return evaluations(token, country, version).get(`/evaluations?${query}`);
  },

  getByMain: (id: string, country: string = "cl", token: string) => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version)
      .get(`/evaluations?main_applicant=${id}`)
      .catch((err) => console.error(err));
  },

  update: (id: number, data, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .put(`/evaluations/${id}`, data)
      .catch((err) => {
        console.error(err);
        Swal.fire({
          type: "error",
          text: "No se ha podido cambiar el estado de la evaluación.",
        });
      });
  },

  comment: (evaluationId: number, data, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .post(`/comments/${evaluationId}`, data)
      .catch((err) => console.error(err));
  },

  getComments: (evaluationId: number, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .get(`/comments/${evaluationId}`)
      .catch((err) => console.error(err));
  },

  updateProperty: (
    mainId: number,
    propertyId: number,
    propertyUid: string,
    updatedBy: number,
    relatedApplicantEmail: string,
    token: string,
    country: Locale = "cl"
  ) => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .put(`/evaluations/property/${mainId}`, {
        property_id: propertyId,
        property_uid: propertyUid,
        updated_by: updatedBy,
        user_email: relatedApplicantEmail,
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          type: "error",
          text: "No se ha podido cambiar la propiedad.",
        });
      });
  },

  requestDocuments: (
    evaluation: number,
    message: string,
    sender: number,
    houmerEmail: string,
    houmerPhone: string,
    propertyId: number,
    token: string,
    country: Locale = "cl"
  ) => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .post(`/email/request_documents`, {
        evaluation,
        message,
        sender,
        houmer_email: houmerEmail,
        houmer_phone: houmerPhone,
        property_id: propertyId,
      })
      .catch((err) => console.error(err));
  },

  createRejectOrDesist: (
    evaluationId: number,
    status: string,
    reason: string,
    comment: string,
    author: string,
    token: string,
    country: Locale = "cl"
  ) => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .post(`/reject_or_desist/${evaluationId}`, {
        status,
        reason,
        comment,
        author,
      })
      .catch((err) => console.error(err));
  },

  getRejectOrDesist: (evaluationId: number, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .get(`/reject_or_desist/${evaluationId}`)
      .catch((err) => console.error(err));
  },

  getStatistics: (token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version).get(`/evaluations/statistics`);
  },

  getEvaluationDetails: (evaluationId: number, token: string, country: string = "cl") => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version)
      .get(`/evaluation_details/${evaluationId}`)
      .catch((err) => console.error(err));
  },

  getMultipleEvaluationDetails: (
    evaluationIds: number[],
    token: string,
    country: Locale = "cl"
  ): Promise<AxiosResponse<{ evaluation_details: EvaluationDetailsModel[] }>> => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version).get(
      `/evaluation_details/multiple?${evaluationIds.map((id) => `evaluations[]=${id}`).join("&")}`
    );
  },

  getByIds: (ids: number[], token: string, country: Locale = "cl") => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version)
      .get(`/evaluations/get_by_ids?${ids.map((id) => `ids[]=${id}`).join("&")}`)
      .catch((err) => console.error(err));
  },

  createEvaluationDetails: (evaluationId: number, data, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .post(`/evaluation_details/${evaluationId}`, data)
      .catch((err) => console.error(err));
  },

  updateEvaluationDetails: (evaluationId: number, data, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .put(`/evaluation_details/${evaluationId}`, data)
      .catch((err) => console.error(err));
  },

  changeMain: (
    mainId: number,
    newMainId: number,
    userId: number,
    token: string,
    country: Locale = "cl"
  ) => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .put(`/evaluations/change_main/${mainId}/${newMainId}`, {
        updated_by: userId,
      })
      .catch((err) => console.error(err));
  },

  applicantEvaluationToken: (mainId: string, token: string, country: string = "cl") => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version)
      .get(`/evaluations/token/${mainId}`)
      .catch((err) => console.error(err));
  },

  addContract: (
    mainId: number,
    contractId: number,
    userId: number,
    token: string,
    country: Locale = "cl"
  ) => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version)
      .put(`/evaluations/add_contract/${mainId}`, {
        contract_id: contractId,
        updated_by: userId,
      })
      .catch((err) => console.error(err));
  },

  delete: async (evaluationId: number, userId: number, token: string, country: Locale = "cl") => {
    const version = getVersion(country);
    return evaluations(token, country, version)
      .put(`/evaluations/delete/${evaluationId}`, {
        updated_by: userId,
      })
      .catch((err) => console.error(err));
  },

  verifyBuroDocumentation: async (
    evaluationId: number,
    token: string,
    country: Locale = "mx",
    data
  ) => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version).post(
      `/verification/${evaluationId}/create_buro_record`,
      data
    );
  },

  getBuroScore: async (evaluationId: number, token: string, country: Locale = "mx", data) => {
    const version = getVersion(country as Locale);
    return evaluations(token, country, version).post(
      `/verification/${evaluationId}/get_buro_record`,
      {
        applicant_rfc: data,
      }
    );
  },

  createLandlordEvaluation: async (
    country: Locale,
    data,
    token: string
  ): Promise<AxiosResponse<CreateLandlordEvaluationResponse>> =>
    evaluations(token, country).post(`/proprietary/evaluation`, data),

  updateLandlordEvaluation: async (
    mainApplicantId: number,
    country: Locale,
    data: { status: string; message?: string },
    token: string
  ) => evaluations(token, country).put(`/proprietary/evaluation/${mainApplicantId}`, data),

  getDocuments: (
    evaluationId: number,
    country: Locale,
    token: string
  ): Promise<
    AxiosResponse<{
      link: string;
    }>
  > => evaluations(token, country, "v3").get(`/evaluations/${evaluationId}/documents`),

  deleteEvaluationFile: (
    country: Locale,
    evaluationId: number,
    documentId: number,
    token: string
  ) =>
    evaluations(token, country, "v3").delete(
      `/rentals/admin/evaluation_members/${evaluationId}/documents/${documentId}`
    ),
};
