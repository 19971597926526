import axios, { AxiosResponse } from "axios";

import { IndexExternalPortalPublicationsFilters } from "services/integrationService/types";
import { createHttp, getToken } from "services/helper";
import { BACKEND_URL } from "env";

import { ICreateCodebor, IHoumerResponse, SearchByEmailAndNameResponse } from "./types";

export default {
  get(id, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/v2/applicants/${id}/`);
  },

  getApplicant(
    id: string,
    token: string
  ): Promise<AxiosResponse<IndexExternalPortalPublicationsFilters>> {
    return createHttp(token).get(`/v2/applicants/${id}/`);
  },

  store(data, token) {
    return createHttp(token)
      .post("/v2/applicants/", data)
      .catch((error) => {
        console.error(error);
        return false;
      });
  },

  searchByEmailAndName(
    search: string,
    token: string
  ): Promise<AxiosResponse<SearchByEmailAndNameResponse>> {
    return createHttp(token, BACKEND_URL).get(`/admin/v2/applicants/search?search=${search}`);
  },

  createApplicant(data, token) {
    return createHttp(token).post("/v2/applicants/", data);
  },

  codebtor(data: ICreateCodebor, token: string) {
    return createHttp(token).post(`/v2/applicants/${data.applicant_id}/codebtors/`, data);
  },

  generatePaymentButton(data, token: string) {
    return createHttp(token).post("/admin/v2/applicants/generate_payment_button/", data);
  },

  generateUpdateQuotation(data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.post("/admin/v2/applicants/generate_payment_button_from_contract/", data);
  },

  getAppraiser(applicantId, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/v2/applicants/${applicantId}/get_appraiser/`).catch((error) => null);
  },

  getHoumer(applicantId: string, token: string): Promise<AxiosResponse<IHoumerResponse>> {
    return createHttp(token, BACKEND_URL).get(`/v2/applicants/${applicantId}/get_appraiser/`);
  },

  changeAppraiser(applicantId, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http
      .post(`/v2/applicants/${applicantId}/change_appraiser/`, data)
      .catch((error) => false);
  },

  changeHoumer(applicantId: string, data, token: string) {
    return createHttp(token).post(`/v2/applicants/${applicantId}/change_appraiser/`, data);
  },

  getDocument(id: string, token: string) {
    return createHttp(token).get(`/admin/v2/documents/${id}/`);
  },
};
