/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from "react-router-dom";

import { Activity } from "models/DemandLead";

import { ReactComponent as Confirm } from "assets/images/Confirm.svg";
import { ReactComponent as Waiting } from "assets/images/Waiting.svg";
import { ReactComponent as Expired } from "assets/images/Expired.svg";
import { ReactComponent as Incoming } from "assets/images/Incoming.svg";

import { emptyString, formatedDate } from "Components/Admin/administrator/demand/utils/cells";

import useCellStyles from "./CellsStyle";

import { states } from "Components/Admin/administrator/demand/constants";

interface Props {
  row: Activity;
}

const originValues = {
  Call: "Llamada",
  Message: "Mensaje",
  Mail: "Email",
};

export function OriginCell({ row }: Props) {
  return <>{originValues[row.origin] || row.origin || emptyString}</>;
}

export function StatusCell({ row }: Props) {
  if (states[row.state]) return <>{states[row.state]}</>;
  return <>{emptyString}</>;
}

export function PropertyCell({ row }: Props) {
  const classes = useCellStyles();
  return row.property_uid ? (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={`/admin/properties/${row.property_uid}`}
      className={classes.blackUnderline}>
      {row.property_id}
    </Link>
  ) : (
    <>{emptyString}</>
  );
}

export function Symbol({ row }: Props) {
  switch (row.color) {
    case "green":
      //completed
      return <Confirm />;
    case "yellow":
      //expiration_date > fecha actual
      return <Waiting />;
    case "red":
      //expiration_date <= fecha actual
      return <Expired />;
    case "blue":
      //expiration_date <= fecha actual
      return <Incoming />;
    default:
      return <>{emptyString}</>;
  }
}

export function SymbollCell({ row }: Props) {
  const classes = useCellStyles();

  return (
    <div className={classes.symbolContainer}>
      <Symbol row={row} />
      {row.color === "green" && "Realizada"}
      {row.color === "blue" && "Entrante"}
      {row.color === "red" && "Vencida"}
      {row.color === "yellow" && "Por realizar"}
    </div>
  );
}

export function ContactCell({ row }: Props) {
  return <>{row?.contact ? formatedDate(row.contact) : emptyString}</>;
}
