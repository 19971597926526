import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: "1.5rem",
    fontWeight: theme.typography.fontWeightBold as number,
    marginBottom: theme.spacing(1),
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  rows: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(1),
  },
  searchInputContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "60%",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  columns: {
    width: "50%",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  keypadButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold as number,
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
  },
  downloadLabel: {
    fontWeight: 700,
    marginRight: theme.spacing(5),
  },
  downloadButton: {
    fontSize: "0.875rem",
    border: "1px dashed",
    textTransform: "none",
    borderRadius: 8,
    padding: theme.spacing(0.5, 1.5),
  },
}));

export default useStyles;
