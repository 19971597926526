import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  isTitle: boolean;
  isItemRow: boolean;
  isEdit: boolean;
}

export default makeStyles<Theme, Props>((theme) => ({
  root: {
    backgroundColor: (props) => (props?.isTitle ? theme.palette.grey[50] : "white"),
    border: "1px solid",
    borderColor: (props) => (props?.isTitle ? "transparent" : theme.palette.grey[50]),
    display: "grid",
    gridTemplateColumns: ({ isItemRow, isEdit }) =>
      isItemRow && isEdit
        ? "1fr 0.5fr 1.5fr 3fr 1.5fr 1.5fr"
        : isItemRow
        ? "1fr 0.5fr 1.5fr 3fr 1.5fr"
        : isEdit
        ? "1fr 1.5fr 3fr 1fr 1fr"
        : "1fr 1.5fr 3fr 1.5fr",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1, 0),
    borderRadius: "0.25rem",
    marginBottom: theme.spacing(1),
  },
  cell: {
    width: "100%",
    "&:not(:first-child)": {
      borderLeft: `2px solid ${theme.palette.grey[200]}`,
    },
    padding: theme.spacing(0, 1),
  },
}));
