import { Box } from "@material-ui/core";
import { MonetizationOnOutlined } from "@material-ui/icons";

import houmLogo from "assets/images/houmLogo.png";

import { HeaderRoot, HeaderContent, HeaderLogo, HeaderTitle, HeaderText } from "./HeaderStyles";

function Header() {
  return (
    <HeaderRoot>
      <HeaderContent>
        <MonetizationOnOutlined />
        <Box>
          <HeaderTitle>Informe de comparación de precio</HeaderTitle>
          <HeaderText>
            Te ayudamos a encontrar <span>el precio ideal para tu propiedad</span> ajustalo para
            poder rentar tu propiedad más rápido.
          </HeaderText>
          <HeaderText>
            Aquí encontrarás una <span>lista comparativa de precios con propiedades similares</span>{" "}
            a la tuya, de esta forma puedes evaluar si tu precio es adecuado.
          </HeaderText>
        </Box>
      </HeaderContent>
      <HeaderLogo>
        <img src={houmLogo} alt="Houm Logo" />
      </HeaderLogo>
    </HeaderRoot>
  );
}

export default Header;
