import { makeStyles } from "@material-ui/core";

const useFilterModalStyles = makeStyles((theme) => ({
  formContainer: {
    height: "90%",
    backgroundColor: "#FFF",
    alignSelf: "center",
    borderRadius: 10,
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  formWrapper: {
    width: "100%",
  },
  listWrap: {
    minWidth: 500,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  subtitle: {
    overflow: "hidden",
    textOverflow: "elipsis",
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    maxWidth: 400,
  },
}));

export default useFilterModalStyles;
