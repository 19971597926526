import { Divider, Grid, Link, Typography } from "@material-ui/core";
import clsx from "clsx";

import { ContractTemplateProps } from "context/contractTemplatesContext/contractTemplatesTypes";

import useTableItemStyles from "./TableItemStyles";

interface Props extends ContractTemplateProps {
  handleClick: () => void;
}

const TableItem: React.FC<Props> = ({
  contractLink,
  description,
  id,
  mandateLink,
  name,
  handleClick,
}) => {
  const classes = useTableItemStyles();

  return (
    <Grid container item xs={12} key={`${name}-templates`} className={classes.itemRoot}>
      <Grid
        item
        xs={1}
        className={clsx(classes.itemColumn, classes.clickable)}
        onClick={handleClick}>
        <Typography className={classes.itemFont}>{id}</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid
        item
        xs={2}
        className={clsx(classes.itemColumn, classes.clickable)}
        onClick={handleClick}>
        <Typography className={classes.itemFont}>{name}</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid
        item
        xs={5}
        className={clsx(classes.itemColumn, classes.clickable)}
        onClick={handleClick}>
        <Typography className={classes.itemFont}>{description}</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={2} className={classes.itemColumn}>
        <Link href={mandateLink} className={classes.itemFont}>
          Ver
        </Link>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={2} className={classes.itemColumn}>
        <Link href={contractLink} className={classes.itemFont}>
          Ver
        </Link>
      </Grid>
    </Grid>
  );
};

export default TableItem;
