import parseCurrency from "@houm-com/formats/currency";
import clsx from "clsx";

import { Divider, ListItem, ListItemText, Typography } from "@material-ui/core";

import useClosingSpecificMovementStyles from "./ClosingSpecificMovementStyles";

interface Props {
  amount: number;
  currency: string;
  description: string;
  type: string;
}

const ClosingSpecificMovement = ({ amount, currency, description, type }: Props) => {
  const classes = useClosingSpecificMovementStyles();

  const displaySign = (type: string): string => {
    if (type === "total") return "";
    if (type === "negative") return "-";
    return "+";
  };

  return (
    <ListItem alignItems="flex-start">
      <ListItemText>
        <div className={classes.listContainer}>
          <Typography
            component="span"
            variant="body2"
            className={clsx(
              classes.description,
              { [classes.inlineTotal]: type === "total" },
              { [classes.inline]: type !== "total" }
            )}
            color="textPrimary">
            {description}
          </Typography>
          <span
            className={clsx(
              { [classes.inlineTotal]: type === "total" },
              { [classes.negative]: type === "negative" },
              { [classes.positive]: type === "positive" },
              classes.inline
            )}>
            {`${displaySign(type)} ${parseCurrency(amount, currency)}`}
          </span>
        </div>
        {type !== "total" && <Divider variant="fullWidth" component="ul" />}
      </ListItemText>
    </ListItem>
  );
};

export default ClosingSpecificMovement;
