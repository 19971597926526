import {
  State,
  Action,
  SET_LOADING_DATA,
  CHANGE_COUNTRY,
  SET_EVALUATION,
  SET_EVALUATION_FILTER,
  SET_STATISTICS,
  SET_SINGLE_EVALUATION,
  SET_EVALUATION_TOKEN,
  SET_APPLICANT,
  SET_HOUMER,
  SET_PROPERTY_3X,
  SET_PROPERTY_IS_IN_UF,
  SET_CAN_PAY,
} from "./evaluationTypes";

export const initialState: State = {
  loadingData: true,
  country: "chile",
  evaluation: [],
  numberEvaluations: 0,
  filters: {
    size: 0,
    page: 0,
    sort: "updated_at",
    order: "desc",
    mainApplicant: null,
    codebtor: null,
    status: null,
    property: null,
    email: null,
    relatedApplicant: null,
  },
  statistics: {
    start: 0,
    noDocs: 0,
    pendingDocs: 0,
    readyForEvaluation: 0,
    newDocs: 0,
    commerciallyApproved: 0,
    rejected: 0,
    desisted: 0,
  },
  singleEvaluation: {
    codebtorName: null,
    codebtorId: null,
    evaluations: [],
    propertyId: null,
    propertyUid: null,
    commerciallyApproved: [],
    rents: null,
    rentsSum: 0,
    bankAccount: undefined,
  },
  token: null,
  loadingSingleEvaluation: true,
  applicant: null,
  houmer: null,
  loadingHoumer: true,
  property3x: 0,
  propertyIsInUF: false,
  canPay: false,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING_DATA: {
      return { ...state, loadingData: action.loadingData };
    }
    case CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_EVALUATION: {
      return {
        ...state,
        loadingData: false,
        evaluation: action.payload.evaluation,
        numberEvaluations: action.payload.count,
      };
    }
    case SET_EVALUATION_FILTER: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case SET_STATISTICS: {
      return {
        ...state,
        statistics: action.statistics,
      };
    }
    case SET_SINGLE_EVALUATION: {
      return {
        ...state,
        loadingSingleEvaluation: false,
        singleEvaluation: action.singleEvaluation,
      };
    }
    case SET_EVALUATION_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }
    case SET_APPLICANT: {
      return {
        ...state,
        applicant: action.applicant,
      };
    }
    case SET_HOUMER: {
      return {
        ...state,
        loadingHoumer: false,
        houmer: action.houmer,
      };
    }
    case SET_PROPERTY_3X: {
      return {
        ...state,
        property3x: action.property3x,
      };
    }
    case SET_PROPERTY_IS_IN_UF: {
      return {
        ...state,
        propertyIsInUF: action.propertyIsInUF,
      };
    }
    case SET_CAN_PAY: {
      return {
        ...state,
        canPay: action.canPay,
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
