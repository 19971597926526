import { Grid } from "@material-ui/core";

import Characteristics from "./components/Characteristics";
import CurrentPrice from "./components/CurrentPrice";
import Indicators from "./components/Indicators";
import RecommendedPrice from "./components/RecommendedPrice";
import { Root, AddressText, PublisherText } from "./PropertyInfoStyles";
import { MainProperty } from "Components/Admin/administrator/properties/show/tabs/rev/Price/utils/types";

interface Props {
  property?: MainProperty;
  currentPrice?: string;
  recommendedPrice?: string;
  isOwnerProperty?: boolean;
}

function PropertyInfo({
  property = null,
  isOwnerProperty = false,
  currentPrice = "",
  recommendedPrice = "",
}: Props) {
  return (
    <Root container spacing={3}>
      <Grid item xs={6} md={3}>
        <Characteristics
          propertyId={property?.id}
          bedrooms={property?.property_details[0]?.dormitorios}
          bathrooms={property?.property_details[0]?.banos}
          area={property?.property_details[0]?.m_terreno}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        {isOwnerProperty && <AddressText>{property?.address}</AddressText>}
        <PublisherText>Publicado por Houm</PublisherText>
        <CurrentPrice price={currentPrice} />
      </Grid>
      <Grid item xs={12} md={6}>
        {isOwnerProperty && <RecommendedPrice price={recommendedPrice} />}
        {!isOwnerProperty && <AddressText>{property?.address}</AddressText>}
        <Indicators
          views={property?.marketplace_stats?.pageviews}
          visits={property?.marketplace_stats?.all_visits}
          favorites={property?.marketplace_stats?.like_users}
        />
      </Grid>
    </Root>
  );
}

export default PropertyInfo;
