import { IoPeopleCircleOutline as ClientIcon } from "@houm-com/ui-icons";
import SubscriptionSearch from "Components/Admin/administrator/subscriptionSearch";
import Subscription from "Components/Admin/administrator/subscriptions";
import RepairsRequestPage from "Components/Admin/administrator/subscriptions/components/RepairsRequestPage";
// import PaymentIndex from "Components/Admin/administrator/Payment/indexPayments";
import ExecutivesIndex from "Components/Admin/administrator/executives/ExecutivesIndex";
import Landlord from "Components/Admin/administrator/clients/Landlord";
import LandlordVipIndex from "Components/Admin/administrator/landlordVip/indexLandlordVip";
import LandlordSearch from "Components/Admin/administrator/clients/LandlordSearch";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Clients",
  icon: ClientIcon,
  children: [
    {
      to: "/admin/clients",
      path: "/admin/clients",
      component: SubscriptionSearch,
      label: "Buscar",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "control_tower_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/clients/:id",
      component: Subscription,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "control_tower_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/clients/:id/repairs",
      component: RepairsRequestPage,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "control_tower_admin",
        "super_admin",
      ],
    },
    // {
    //   to: "/admin/payments",
    //   path: "/admin/payments",
    //   component: PaymentIndex,
    //   label: "Cobranzas",
    //   scope: "roles",
    //   strategy: "any",
    //   require: ["management", "management_admin", "super_admin"],
    // },
    {
      to: "/admin/executives",
      path: "/admin/executives",
      component: ExecutivesIndex,
      label: "Ejecutivas administración",
      scope: "roles",
      strategy: "any",
      require: ["management", "management_admin", "super_admin"],
    },
    {
      to: "/admin/landlord",
      path: "/admin/landlord",
      component: LandlordSearch,
      label: "Propietarios",
      scope: "roles",
      strategy: "any",
      require: [
        "management",
        "management_admin",
        "super_admin",
        "sales_admin",
        "sales",
        "super_admin",
      ],
    },
    {
      path: "/admin/landlord/:id",
      component: Landlord,
      scope: "roles",
      strategy: "any",
      require: [
        "management",
        "management_admin",
        "super_admin",
        "sales_admin",
        "sales",
        "super_admin",
      ],
    },
    {
      to: "/admin/landlord_vip",
      path: "/admin/landlord_vip",
      component: LandlordVipIndex,
      label: "Propietarios VIP",
      scope: "roles",
      strategy: "any",
      require: ["management", "management_admin", "super_admin"],
    },
  ],
});
