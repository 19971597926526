const filterImagesByName = <T>(
  name: T,
  images: {
    name: T;
    images: string[];
  }[]
): string[] => {
  if (!images?.length) return [];
  const filteredImages = images.find((image) => image.name === name);
  return filteredImages?.images;
};

export default filterImagesByName;
