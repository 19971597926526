import { Container } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import ProjectForm from "./ProjectForm";
import { ProjectProvider } from "context/projectContext/projectContext";
import SuccessDialog from "Components/Admin/UIComponents/ProjectForm/SuccessDialog";
import LoadingDialog from "Components/Admin/UIComponents/ProjectForm/LoadingDialog";

export default function NewProjects() {
  return (
    <Container maxWidth="md">
      <SnackbarProvider>
        <ProjectProvider>
          <ProjectForm />
          <SuccessDialog />
          <LoadingDialog />
        </ProjectProvider>
      </SnackbarProvider>
    </Container>
  );
}
