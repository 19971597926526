import { useMemo } from "react";

import { Button, Grid } from "@material-ui/core";

import { Country } from "models/PhoneInput";
import { useList } from "context/demandLeadContext/list/listContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { fromToOptions } from "utils/fromToOptions";

import {
  ControlledAutocomplete,
  ControlledTextField,
  ControlledPhoneInput,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";

import useLeadFormStyles from "./LeadFormStyles";
import { CountryCallingCode } from "libphonenumber-js";

function LeadForm() {
  const classes = useLeadFormStyles();

  const {
    state: { executives },
  } = useList();
  const {
    state: { lead },
  } = useDemandLead();

  const defaultCountry: Country = {
    callingCode: "57" as CountryCallingCode,
    countryCode: "CO",
    countryName: "Colombia",
  };

  const textFieldProps = useMemo(
    () => ({
      InputProps: {
        disableUnderline: false,
        classes: {
          underline: classes.underline,
        },
      },
    }),
    []
  );

  return (
    <Grid container>
      <Grid item md={11}>
        <Grid container spacing={5}>
          <Grid item md={3}>
            <ControlledTextField
              defaultValue=""
              id="name"
              label="Nombre"
              textFieldProps={textFieldProps}
            />
          </Grid>
          <Grid item md={3}>
            <ControlledTextField
              defaultValue=""
              id="lastName"
              label="Apellido"
              textFieldProps={textFieldProps}
            />
          </Grid>
          <Grid item md={3}>
            <ControlledTextField
              defaultValue={lead.lead.email || ""}
              id="email"
              label="Correo"
              textFieldProps={textFieldProps}
            />
          </Grid>
          <Grid item md={3}>
            <ControlledSelection
              label="Nota"
              options={fromToOptions(1, 5)}
              round
              id="qualification"
              compressed
            />
          </Grid>
        </Grid>
        <Grid container className={classes.secondRow}>
          <Grid item md={3}>
            <ControlledPhoneInput
              id="phone"
              phoneLabel="Celular"
              defaultValue={{ country: defaultCountry, phone: "" }}
              countryInputProps={textFieldProps}
              numberInputProps={textFieldProps}
            />
          </Grid>
          <Grid item md={3}>
            {executives.length > 0 && (
              <ControlledAutocomplete
                id="executive"
                label={
                  lead?.status?.business_type === "rentals"
                    ? "Lead Qualifier"
                    : "Lead Buyer Qualifier"
                }
                options={executives}
                textFieldProps={textFieldProps}
              />
            )}
          </Grid>
          <Grid item md={5}>
            <ControlledTextField
              defaultValue={lead.comment || ""}
              textFieldProps={{
                multiline: true,
                minRows: 2,
                InputProps: {
                  className: classes.textArea,
                },
              }}
              id="comment"
              label={<div className={classes.textAreaLabel}>¿Qué busca en la propiedad?</div>}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={1}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.saveButton}
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            disabled={!lead}
            disableElevation>
            Guardar
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
export default LeadForm;
