import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Box } from "@material-ui/core";

import { useGetToken } from "hooks";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import {
  getLetterOfIntent,
  getLetterOfIntentNegotiationProperty,
  setCountry,
  setLoiId,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import Header from "./components/Header";
import useLetterOfIntentPropertyDetailIndexStyles from "./LetterOfIntentPropertyDetailIndexStyles";
import NegotiationCards from "./components/DetailsCards";
import { Locale } from "models/Countries";
import DocumentsCard from "./components/DocumentsCard";

const useQuery = () => new URLSearchParams(useLocation().search);

function LetterOfIntentPropertyDetailIndex() {
  const { dispatch, state } = useLetterOfIntent();
  const { negotiation } = state;

  const { getToken } = useGetToken();
  const classes = useLetterOfIntentPropertyDetailIndexStyles();

  const params = useParams<{ id: string }>();
  const { id: loiId } = params;
  const country: Locale = useQuery().get("country") as Locale;

  useEffect(() => {
    dispatch(setCountry(country));
    dispatch(setLoiId(loiId));
    getToken().then((authToken) => {
      getLetterOfIntent({ country, loiId, token: authToken, dispatch });
    });
  }, []);

  useEffect(() => {
    getToken().then(async (authToken) => {
      if (negotiation?.id)
        await getLetterOfIntentNegotiationProperty({
          country,
          negotiationId: negotiation?.id,
          token: authToken,
          dispatch,
        });
    });
  }, [negotiation?.id]);

  return (
    <Box className={classes.container}>
      <Header />
      <NegotiationCards />
      <DocumentsCard />
    </Box>
  );
}

export default LetterOfIntentPropertyDetailIndex;
