import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import multifamilyService from "services/multifamilyService";
import {
  ActionType,
  Action,
  Dispatch,
  Typologies,
  UTImages,
  photosDeleteBody,
} from "./typologyTypes";
import { sortedPhotos } from "../multifamily/multifamilyTypes";
import { uploadToS3 } from "utils/fileStreaming";

import { Paginate } from "models/ResponseWithPaginate";

export const setTypology = (typology: Typologies): Action => ({
  type: ActionType.SET_TYPOLOGY,
  typology,
});

export const setTypologies = (typologies: Typologies[]): Action => ({
  type: ActionType.SET_TYPOLOGIES,
  typologies,
});

export const setLoadingDataTable = (isLoadingDataTable: boolean): Action => ({
  type: ActionType.SET_IS_LOADING_DATA_TABLE,
  isLoadingDataTable,
});

export const setUTImages = (UTImages: UTImages[]): Action => ({
  type: ActionType.SET_UT_IMAGES,
  UTImages,
});

export const setCurrentId = (currentId: number): Action => ({
  type: ActionType.SET_CURRENT_ID,
  currentId,
});

export const getTypology = async (dispatch: Dispatch, id: number, authToken: string) => {
  try {
    const res = (await multifamilyService.getTypology(id, authToken)) as AxiosResponse<Typologies>;
    const typology = res.data;
    dispatch(setTypology(typology));
  } catch {
    Swal.fire("Error", "No se ha podido consultar la informacion para esta tipologia.", "error");
    dispatch(setTypology({}));
  }
};

export const getTypologyPhotos = async (dispatch: Dispatch, id: number, authToken: string) => {
  try {
    const res = (await multifamilyService.getTypologyPhotos(id, authToken)) as AxiosResponse<
      UTImages[]
    >;
    const photos = res.data;
    dispatch(setUTImages(photos));
  } catch {
    Swal.fire("Error", "No se han podido consultar las imagenes para esta tipologia.", "error");
    dispatch(setUTImages([]));
  }
};

export const deleteTypologyPhoto = async (
  id: number,
  body: photosDeleteBody,
  authToken: string
) => {
  try {
    const res = await multifamilyService.deleteTypologyPhoto(id, body, authToken);
    return res;
  } catch {
    Swal.fire("Error", "No se ha podido eliminar la imagen para esta tipologia.", "error");
  }
};

export const updateTypologyPhotosOrder = async (
  UTid: number,
  sortedPhotoArray: sortedPhotos,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    await multifamilyService.typologyPhotosOrder(UTid, sortedPhotoArray, authToken);
    Swal.fire(
      "Actualizado",
      "Se ha actualizado el orden de las fotos para esta tipologia",
      "success"
    );
    getTypologyPhotos(dispatch, UTid, authToken);
  } catch (err) {
    Swal.fire(
      "Error",
      "No se han podido actualizar el orden de las imagenes para esta tipologia.",
      "error"
    );
  }
};

export const getTypologies = async (
  dispatch: Dispatch,
  MultiFamilyID: number,
  authToken: string
) => {
  try {
    dispatch(setLoadingDataTable(true));
    const res = (await multifamilyService.getTypologies(MultiFamilyID, authToken)) as AxiosResponse<
      Paginate<Typologies>
    >;
    const arrayOfTypologies = res.data.results;
    dispatch(setTypologies(arrayOfTypologies));
  } catch {
    Swal.fire("Error", "No se han podido obtener las tipologias.", "error");
    dispatch(setTypologies([]));
  } finally {
    dispatch(setLoadingDataTable(false));
  }
};

export const createTypology = async (dispatch: Dispatch, body: Typologies, authToken: string) => {
  try {
    const res = await multifamilyService.createTypology(body, authToken);
    dispatch(setCurrentId(res.data.id));
  } catch {
    Swal.fire("Error", "Ha ocurrido un error al guardar los datos.", "error");
  }
};

export const editTypology = async (id: any, body: Typologies, authToken: string) => {
  try {
    await multifamilyService.editTypology(id, body, authToken);
  } catch {
    Swal.fire("Error", "Ha ocurrido un error al guardar los datos.", "error");
  }
};

export const uploadLayoutImage = async (UTid: number, image: File, authToken: string) => {
  try {
    let filePath = `${UTid}-${image.name}`;
    filePath = filePath.replaceAll(" ", "");
    const presignedUrl = await multifamilyService.getTypologyLayoutUrl(UTid, filePath, authToken);
    const uploadImage = await uploadToS3({
      fileContents: image,
      presignedUrl: presignedUrl.data,
    });
    if (!uploadImage) {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo guardar el plano",
      });
    }
    return uploadImage;
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo guardar el plano",
    });
  }
};
