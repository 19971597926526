import { Box, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useUserBankAccount from "hooks/useUserBankAccount";
import { Locale } from "models/Countries";
import { accountTypesName } from "utils/accountTypes";
import { localeToCountry } from "utils/countryCode";

import useBankAccountStyles from "./BankAccountStyles";

interface Props {
  title: string;
  userId: number;
  propertyId: number;
  countryCode: Locale;
}

const BankAccount = ({ title, userId, propertyId, countryCode }: Props) => {
  const classes = useBankAccountStyles();
  const { dataBankAccount, isErrorBankAccount, isLoadingBankAccount, errorBankAccount } =
    useUserBankAccount(countryCode, userId, propertyId);

  const showMessageError = () => {
    if (errorBankAccount.response?.status === 404) {
      return <Typography>No hay cuenta bancaria registrada</Typography>;
    }
    return <Typography>Hubo un error al obtener la cuenta bancaria</Typography>;
  };

  return (
    <AdminCard
      title={title}
      withSpace={false}
      showTitleBackground={true}
      className={classes.card}
      cardContentClassName={classes.cardContent}>
      <Loading loading={isLoadingBankAccount}>
        <>
          {isErrorBankAccount && showMessageError()}
          {dataBankAccount && (
            <Box className={classes.bankAccountContainer}>
              <p>
                Nombre: <span>{dataBankAccount.holder}</span>
              </p>
              <p>
                Número de cuenta: <span>{dataBankAccount.account_number}</span>
              </p>
              <p>
                Banco: <span>{dataBankAccount.bank_name}</span>
              </p>
              <p>
                Documento: <span>{dataBankAccount.document}</span>
              </p>
              {countryCode !== "mx" && (
                <p>
                  Tipo de cuenta:{" "}
                  <span>
                    {accountTypesName[localeToCountry[countryCode]][dataBankAccount.account_type]}
                  </span>
                </p>
              )}
              {dataBankAccount.clabe_number && (
                <p>
                  CLABE: <span>{dataBankAccount.clabe_number}</span>
                </p>
              )}
            </Box>
          )}
        </>
      </Loading>
    </AdminCard>
  );
};

export default BankAccount;
