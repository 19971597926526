import { makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
  isXs: boolean;
}

const useCardDetailsStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  titleContainer: {
    background: theme.palette.grey[75],
    borderRadius: 2,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    padding: theme.spacing(0.5),
    fontWeight: 700,
  },
  detailContainer: {
    padding: theme.spacing(0, 0.75),
  },
  textField: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    marginBottom: theme.spacing(0.5),
  },
  switchButton: {
    width: 52,
  },
  switchLabel: {
    fontSize: theme.typography.pxToRem(12),
  },
  selection: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(0.5),
    width: "100%",
  },
  selectionLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    fontWeight: "bold",
    transform: "translate(0px, -6px)",
  },
  select: {
    borderRadius: 4,
    background: "#EFF2F3",
    marginTop: theme.spacing(0.31),
    maxWidth: 160,
  },
  inputSelect: {
    padding: theme.spacing(0.75, 1),
    fontSize: theme.typography.pxToRem(12),
  },
  groupContainer: {
    height: 31,
  },
  subtitleContainer: {
    margin: theme.spacing(1, 0),
  },
  subtitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
  brokerageFields: {
    marginTop: ({ isXs }) => (!isXs ? theme.spacing(3.5) : 0),
  },
}));

export default useCardDetailsStyles;
