import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    minHeight: 60,
    maxHeight: 60,
  },
  title: {
    fontSize: theme.typography.pxToRem(33),
    fontWeight: "bold",
    marginTop: theme.spacing(1.25),
  },
}));

export default useHeaderStyles;
