import { ISimplePromotion } from "models/Promotions";
import { ISimplePromotionResponse } from "domains/promotion/service/types";

export const parserPromotions = (promotion: ISimplePromotionResponse): ISimplePromotion => ({
  id: promotion.id,
  initDate: promotion.start_date,
  endDate: promotion.end_date,
  promotionName: promotion.name,
  active: promotion.active,
  reviewStatus: promotion.review_status,
});
