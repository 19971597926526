import SimulateTextfield from "Components/Admin/administrator/multifamily/Components/SimulateTextfield/SimulateTextfield";
import DisabledCheckbox from "Components/Admin/administrator/multifamily/Components/DisabledCheckbox";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import {
  translatedClosetType,
  translatedDepartmentType,
} from "Components/Admin/administrator/multifamily/utils/constants";

import useStyles from "./SubHeaderStyles";

function SubHeader() {
  const classes = useStyles();
  const {
    state: { typology },
  } = useTypology();
  return (
    <div className={classes.container}>
      <div className={classes.videos}>
        <SimulateTextfield title="URL video" value={typology?.video_url} />
        <SimulateTextfield title="URL tour 360" value={typology?.video360_url} />
      </div>
      <SimulateTextfield
        title="Habitaciones en suite"
        value={typology?.suite_rooms?.toString()}
        isHorizontal
      />
      <DisabledCheckbox
        id="serviceRoom"
        label="Habitación de servicio"
        value={typology?.service_rooms}
      />
      <DisabledCheckbox id="terrace" label="Terraza" value={!!typology?.terrace_area} />
      {typology?.terrace_area > 0 && (
        <SimulateTextfield value={`${typology?.terrace_area?.toString()} mts2`} isHorizontal />
      )}
      <DisabledCheckbox
        id="dinningRoom"
        label="Comedor diario"
        value={typology?.has_kitchen_daily_dining_room}
      />
      <DisabledCheckbox
        id="equippedKitchen"
        label="Cocina equipada"
        value={typology?.kitchen_is_furnished}
      />
      <DisabledCheckbox id="laundry" label="Logia" value={typology?.has_laundry_room} />
      {typology?.has_laundry_room && (
        <SimulateTextfield value={`${typology?.laundry_room_area?.toString()} mts2`} isHorizontal />
      )}
      <SimulateTextfield
        title="Tipo de departamento"
        value={translatedDepartmentType(typology?.apartment_type)}
        isHorizontal
      />
      <SimulateTextfield
        title="Closet"
        value={translatedClosetType(typology?.closet)}
        isHorizontal
      />
      <SimulateTextfield
        title="Ambientes"
        value={typology?.environments?.toString()}
        isHorizontal
      />
    </div>
  );
}
export default SubHeader;
