import { makeStyles, Theme } from "@material-ui/core/";

const defaultBreakpoint = "md";

const useStyles = makeStyles<Theme>((theme) => ({
  componentContainer: {
    padding: theme.spacing(0, 5, 4.75, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 13, 7, 13),
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(33),
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
  accordion: {
    width: "100%",
    margin: 0,
    border: "none",
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionIcon: {
    marginRight: theme.spacing(3.5),
  },
  accordionSummary: {
    borderTop: `1px solid ${theme.palette.grey[50]}`,
    width: "100%",
    minHeight: theme.spacing(9),
    "&.Mui-expanded": {
      minHeight: theme.spacing(9),
    },
  },
  accordionDetails: {
    marginLeft: 0,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginLeft: theme.spacing(6.5),
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  expandLabel: {
    fontWeight: "bold",
  },
  editLink: {
    position: "relative",
    top: -20,
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  loading: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(5),
    },
  },
}));

export default useStyles;
