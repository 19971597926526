import { IoSpeedometerOutline as ControltowerIcon } from "@houm-com/ui-icons";
import ControlTowerDashboard from "Components/Admin/administrator/controlTower";
import NewAppraiser from "Components/Admin/administrator/controlTower/newAppraiser";
import Macrozones from "Components/Admin/administrator/controlTower/macrozones";
import Profile from "Components/Admin/administrator/controlTower/profile";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Control tower",
  icon: ControltowerIcon,
  children: [
    {
      to: "/control-tower/dashboard",
      path: "/control-tower/dashboard",
      component: ControlTowerDashboard,
      label: "Dashboard",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "super_admin",
        "sales",
        "sales_admin",
      ],
    },
    {
      to: "/control-tower/new-appraiser",
      path: "/control-tower/new-appraiser",
      component: NewAppraiser,
      label: "Crear un houmer",
      scope: "roles",
      strategy: "any",
      require: ["super_admin", "sales_admin", "control_tower_admin"],
    },
    {
      to: "/control-tower/macrozones",
      path: "/control-tower/macrozones",
      component: Macrozones,
      label: "Macrozonas",
      scope: "roles",
      strategy: "any",
      require: ["super_admin", "sales_admin", "control_tower_admin"],
    },
    {
      path: "/control-tower/profile/:id",
      component: Profile,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "super_admin",
        "sales",
        "sales_admin",
      ],
    },
  ],
});
