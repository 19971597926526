import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
    minHeight: 60,
    maxHeight: 60,
    marginBottom: theme.spacing(2.5),
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  country: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

export default useHeaderStyles;
