import { RawAddress } from "models/Address";
import { Neighborhood } from "models/Property";
import { Country as Phone } from "models/PhoneInput";

export enum ActionType {
  SET_MULTIFAMILY,
  SET_TOTAL_MF,
  SET_MULTIFAMILIES,
  SET_IS_LOADING_DATA_TABLE,
  SET_FILTERS,
  SET_FORM_DATA,
  SET_MF_IMAGES,
  SET_CURRENT_ID,
  SET_NEIGHBORHOOD_OPTIONS,
  SET_IS_OPEN_FILTER_MODAL,
}

export interface sortedPhotos {
  photos: [
    {
      created_at: string;
      filename: string;
      height: number;
      id: number;
      order: number;
      url: string;
      width: number;
    }
  ];
}

export interface Filters {
  search?: string;
  published?: IStatusFilter;
  name?: string;
  id?: number;
  houm_executive?: string;
  landlord?: string;
}

export interface IStatusFilter {
  label: string;
  value: boolean;
}

export interface Checkboxes {
  association_name?: string;
  floors?: number;
  guest_parking_spaces?: number;
  has_all_day_vigilance?: boolean;
  has_bbq_area?: boolean;
  has_cinema?: boolean;
  has_concierge?: boolean;
  has_elevator?: boolean;
  has_gourmet_space?: boolean;
  has_gym?: boolean;
  has_laundry?: boolean;
  has_party_room?: boolean;
  has_patio?: boolean;
  has_pet_area?: boolean;
  has_playground?: boolean;
  has_playroom?: boolean;
  has_games_room?: boolean;
  has_roof_garden?: boolean;
  has_sauna?: boolean;
  has_steam_room?: boolean;
  has_swimming_pool?: boolean;
  is_condo?: boolean;
}

export interface Landlord {
  id: number;
  name: string;
  last_name: string;
  phone: string;
  email: string;
  second_last_name: string;
}

export interface Multifamilies {
  id?: number;
  macrozone?: number;
  address?: string;
  houm_executive?: any;
  latitude?: number;
  longitude?: number;
  neighborhood?: Neighborhood;
  region?: string;
  commune?: string;
  country?: string;
  name?: string;
  street_number?: number;
  year_built?: string;
  administration_email?: string;
  administration_phone?: string;
  concierge_name?: string;
  concierge_email?: string;
  concierge_phone?: string;
  landlord?: Landlord | number;
  association_amenities?: Checkboxes;
  published?: boolean;
}

export interface MultifamilyFormData {
  buildingName?: string;
  country?: string;
  address?: RawAddress;
  executiveOfBuilding?: string;
  constructionYear?: string;
  landlord?: string;
  emailOfAdministrator?: string;
  phoneOfAdministrator?: Phone;
  conciergeName?: string;
  emailOfConcierge?: string;
  phoneOfConcierge?: Phone;
}

export interface MFImages {
  id: number;
  created_at: string;
  filename: string;
  order: number;
  url: string;
}

export interface photosDeleteBody {
  photos_id: number[];
}

export interface Options {
  value: number;
  label: string;
}

export interface SetMultifamily {
  type: typeof ActionType.SET_MULTIFAMILY;
  multifamily: Multifamilies;
}

export interface SetTotalMF {
  type: typeof ActionType.SET_TOTAL_MF;
  totalMF: number;
}

export interface SetMultifamilies {
  type: typeof ActionType.SET_MULTIFAMILIES;
  multifamilies: Multifamilies[];
}

export interface SetIsLoadingDataTable {
  type: typeof ActionType.SET_IS_LOADING_DATA_TABLE;
  isLoadingDataTable: boolean;
}

export interface SetFilters {
  type: typeof ActionType.SET_FILTERS;
  filters: Filters;
}

export interface SetMFImages {
  type: typeof ActionType.SET_MF_IMAGES;
  MFImages: MFImages[];
}

export interface SetCurrentId {
  type: typeof ActionType.SET_CURRENT_ID;
  currentId: number;
}

export interface SetNeighborhoodOptions {
  type: typeof ActionType.SET_NEIGHBORHOOD_OPTIONS;
  neighborhoodOptions: Options[];
}

export interface SetIsOpenFilterModal {
  type: typeof ActionType.SET_IS_OPEN_FILTER_MODAL;
  isOpenFilterModal: boolean;
}

export interface State {
  multifamily: Multifamilies;
  totalMF: number;
  multifamilies: Multifamilies[];
  isLoadingDataTable: boolean;
  filters: Filters;
  MFImages: MFImages[];
  currentId: number;
  neighborhoodOptions: Options[];
  isOpenFilterModal: boolean;
}

export type Action =
  | SetMultifamily
  | SetTotalMF
  | SetMultifamilies
  | SetIsLoadingDataTable
  | SetFilters
  | SetMFImages
  | SetCurrentId
  | SetNeighborhoodOptions
  | SetIsOpenFilterModal;

export type Dispatch = (action: Action) => void;
