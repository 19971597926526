import { useGetToken } from "hooks";
import { useMutation, useQueryClient } from "react-query";
import contractService from "services/contractService";
import { alert } from "libs/alerts";

interface Props {
  handleOpenModal?: () => void;
  custom?: boolean;
}

function useSetContractData({ handleOpenModal, custom = false }: Props) {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { mutate, isLoading } = useMutation(
    async (formData: FormData) => {
      const authToken = await getToken();
      const requestApi = custom
        ? contractService.setCustomContractData
        : contractService.setContractData;

      await requestApi(authToken, formData);
    },
    {
      onSuccess: () => {
        alert.success({
          message:
            "El contrato y mandato han sido generados correctamente. Ya los puedes descargar en la sección Archivos actuales.",
        });
        handleOpenModal?.();
        queryClient.invalidateQueries("document_sections_links");
        queryClient.invalidateQueries("contract_documents_link");
      },
      onError: (e) => {
        alert.error({
          message: `Error al generar los documentos ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
  };
}

export default useSetContractData;
