import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import DesignSystemTabs from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/DesignSystemTabs";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";
import { countryCode } from "utils";
import { AUTH0_ROLES } from "env";

import SubscriptionHead from "./components/SubscriptionHead";
import SubscriptionNav from "./components/SubscriptionNav";
import useGetExitProcess from "./hooks/useGetExitProcess";
import ExitProcess from "./components/ExitProcess";
import MandateRefund from "./components/MandateRefund";
import RenewalView from "./components/RenewalView";
import SignRenewal from "./components/SignRenewal";
import useQuotation from "./hooks/useQuotation";
import Requests from "./components/Requests";
import Contract from "./components/Contract";
import Payments from "./components/Payments";
import Summary from "./components/Summary";
import useGetDesertionProcessInfo from "./components/MandateRefund/hooks/useGetDesertionProcessInfo";

const shouldShowRenewal = (roles: string[], propertyCountryCode: Locale) =>
  (roles.includes("management_admin") || roles.includes("super_admin")) &&
  propertyCountryCode !== "cl";
const shouldShowSignRenewal = (propertyCountryCode: Locale) => propertyCountryCode === "mx";
const shouldShowExitProcess = (roles: string[], propertyCountryCode: Locale) =>
  (roles.includes("exit_cl") || roles.includes("super_admin")) && propertyCountryCode !== "co";

const SubscriptionDetail = () => {
  const [showRenewal, setShowRenewal] = useState(false);
  const [showSignRenewal, setShowSignRenewal] = useState(false);
  const [showExitProcess, setShowExitProcess] = useState(false);
  const { isLoading, quotation, subscription } = useQuotation();
  const { exitProcess, isLoading: isLoadingExitProcess } = useGetExitProcess(
    subscription?.contract.id
  );
  const { isSuccess: desertionProcessInfoSuccess } = useGetDesertionProcessInfo(
    subscription?.contract.id
  );
  const { user } = useAuth0();
  const roles = user[AUTH0_ROLES];

  useEffect(() => {
    if (subscription?.property.country && roles) {
      const propertyCountryCode = countryCode(subscription?.property.country);
      const updatedShowRenewal = shouldShowRenewal(roles, propertyCountryCode);
      setShowRenewal(updatedShowRenewal);
      if (updatedShowRenewal) {
        setShowSignRenewal(shouldShowSignRenewal(propertyCountryCode));
      }
      setShowExitProcess(shouldShowExitProcess(roles, propertyCountryCode));
    }
  }, [
    subscription?.property,
    subscription?.contract?.online_process_status,
    subscription?.contract?.renewal_at,
    roles,
  ]);

  const tabOptions = [
    { label: "Resumen", value: "summary" },
    { label: "Contrato", value: "contract" },
    { label: "Pagos", value: "payments" },
    { label: "Solicitudes", value: "requests" },
    ...(showSignRenewal ? [{ label: "Firmar Renovación", value: "signRenewal" }] : []),
    ...(showRenewal ? [{ label: "Renovaciones", value: "renewal" }] : []),
    ...(exitProcess?.status !== "NOT_STARTED" && showExitProcess
      ? [{ label: "Salida", value: "exitProcess" }]
      : []),
    ...(desertionProcessInfoSuccess ? [{ label: "Término de mandato", value: "refund" }] : []),
  ];

  if (isLoading || isLoadingExitProcess) return <Spinner />;

  return (
    <>
      <SubscriptionNav quotationId={quotation?.id} country={subscription?.property.country} />
      <SubscriptionHead quotationId={quotation?.id} subscription={subscription} />
      <DesignSystemTabs options={tabOptions} defaultValue="summary" expanded />
      <Summary quotation={quotation} subscription={subscription} />
      <Contract contract={subscription?.contract} />
      <Payments plan={quotation?.plan} subscription={subscription} />
      {showSignRenewal && <SignRenewal contract={subscription?.contract} />}
      {showRenewal && <RenewalView subscription={subscription} />}
      <Requests />
      {showExitProcess && (
        <ExitProcess
          contract={subscription?.contract}
          subscriptionId={subscription?.id}
          tenantId={subscription?.tenant?.id}
        />
      )}
      {desertionProcessInfoSuccess && (
        <MandateRefund
          contract={subscription?.contract}
          subscriptionId={subscription?.id}
          tenantId={subscription?.tenant?.id}
          country={countryCode(subscription?.property.country)}
        />
      )}
    </>
  );
};

export default SubscriptionDetail;
