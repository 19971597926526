import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import contractService from "services/contractService/contractService";
import { useGetToken } from "hooks";

import { IUseEditContractConditions } from "./utils/types";
import { parserFormData } from "./utils/parsers";
import { alert } from "libs/alerts";

const useEditContractConditions = (contractId: number, handleCloseModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess } = useMutation(
    async (data: IUseEditContractConditions) => {
      const authToken = await getToken();
      const response = await contractService.changeContractConditions(
        contractId,
        parserFormData(data),
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subscription");
        handleCloseModal();
        alert.success({
          message: "El contrato se ha actualizado correctamente",
        });
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: "Error al cambiar las condiciones del contrato",
          disclaimer: `El contrato no se ha podido actualizar, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useEditContractConditions;
