import { useEffect } from "react";
import capitalize from "lodash/capitalize";

import { getLandlordVipList } from "context/landlordVipContext/landlordVipActions";
import { useLandlordVip } from "context/landlordVipContext/landlordVipContext";
import { useTable } from "context/tableContext/tableContext";
import { TableLayout } from "Components/Admin/UIComponents";
import FilterOptions from "./components/FilterOptions";
import Header from "./components/Header";
import Table from "./components/Table";
import { useGetToken } from "hooks";

function LandlordVip() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useLandlordVip();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;

  useEffect(() => {
    getToken().then((authToken) => {
      getLandlordVipList(
        pageSize,
        currentPage,
        capitalize(state.country),
        tableStates.tableFilters,
        authToken,
        dispatch
      );
    });
  }, [pageSize, currentPage, state.country, tableStates.tableFilters]);

  return (
    <TableLayout>
      <Header />
      <TableLayout.Table>
        <Table />
      </TableLayout.Table>
      <FilterOptions />
    </TableLayout>
  );
}

export default LandlordVip;
