import { makeStyles, Theme } from "@material-ui/core";

const useBreakdownModalStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    minWidth: 624,
  },
  gridRoot: {
    width: "100%",
    padding: theme.spacing(1, 4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

export default useBreakdownModalStyles;
