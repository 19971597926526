import { useEffect, useRef, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import { Document as DocumentReactPdf, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { IDocument } from "../../utils/types";

interface Props {
  document: IDocument | null;
  onSetFileErrorFlag: () => void;
  pdfPage: number;
  onSetPdfNumberPages: (numPages: number) => void;
}

const Document = ({ document, onSetFileErrorFlag, pdfPage, onSetPdfNumberPages }: Props) => {
  const refDocument = useRef(null);
  const [documentPdfWidth, setDocumentPdfWidth] = useState(0);
  const [hasPassword, setHasPassword] = useState(false);

  useEffect(() => {
    setDocumentPdfWidth(refDocument.current ? refDocument.current.offsetWidth - 50 : 0);
  }, [document]);

  const onHandleCallback = (callback: (p: string) => void, password: string) => {
    // Cancel button handler
    if (password === null) throw new Error("file with password");
    callback(password);
  };

  const onPassword = (callback: (p: string) => void, reason: string) => {
    setHasPassword(true);
    const { PasswordResponses } = pdfjs;
    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt("Ingresa la contraseña para abrir este PDF");
        onHandleCallback(callback, password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt("Contraseña invalida. Por favor intenta nuevamente");
        onHandleCallback(callback, password);
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className="p-4 overflow-visible" ref={refDocument}>
      {document.content_type === "application/pdf" ? (
        <DocumentReactPdf
          file={document.path}
          onLoadSuccess={({ numPages }) => onSetPdfNumberPages(numPages)}
          onPassword={onPassword}
          onError={onSetFileErrorFlag}
          error={
            hasPassword
              ? `El documento '${document.filename}' está protegido con contraseña.`
              : "No se pudo cargar el documento"
          }>
          <Page pageNumber={pdfPage} renderAnnotationLayer={false} width={documentPdfWidth} />
        </DocumentReactPdf>
      ) : (
        <InnerImageZoom
          src={document.path}
          srcSet={`${document.path} 480w, ${document.path} 800w`}
          sizes="(max-width: 600px) 480px, 800px"
        />
      )}
    </div>
  );
};

export default Document;
