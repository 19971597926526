import { useState } from "react";
import { SnackbarProvider } from "notistack";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ModalDialog from "@houm-com/ui/ModalDialog";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useContractDetailCardStyles from "./ContractDetailCardStyles";
import { checkStatus, renderDataContract } from "../utils";
import FormEditContract from "./FormEditContract";
import { ContractModel } from "models/Contract";

interface Props {
  contract: ContractModel;
}

function ContractDetailCard({ contract }: Props) {
  const classes = useContractDetailCardStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const openEditContractModel = () => {
    setOpenModal(true);
  };

  const closeEditContractModel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <CollapseCard
        title="Detalles contrato"
        editData={
          !checkStatus(
            [
              "Generar boton de pago",
              "Desistido",
              "Rechazado",
              "Boton de pago enviado",
              "Pagado",
              "Finalizado",
            ],
            contract.status
          )
        }
        onEdit={openEditContractModel}>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-end">
                {renderDataContract(contract).map((data) => (
                  <Grid item xs={data.size} key={`${data.label}`}>
                    <Typography component="h6" className={classes.textLabel}>
                      {data.label}
                    </Typography>
                    <Typography component="h6" className={classes.text}>
                      {data.text}
                    </Typography>
                    <Divider className={classes.divider} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </div>
        </Card>
      </CollapseCard>
      <ModalDialog
        isOpen={openModal}
        onClose={closeEditContractModel}
        title="Detalles contrato"
        subtitle="Editar información">
        <SnackbarProvider>
          <FormEditContract contract={contract} handleClose={closeEditContractModel} />
        </SnackbarProvider>
      </ModalDialog>
    </>
  );
}

export default ContractDetailCard;
