import { useMutation } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { ResponseError } from "@houm-com/http-client";

import contractService from "services/contractService";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface ISimulationData {
  contractId: number;
  save: boolean;
}

const useSimulation = () => {
  const { t } = useTranslation(["errors"]);
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess, error, data } = useMutation(
    async (data: ISimulationData) => {
      const authToken = await getToken();
      const response = await contractService.getSimulation(data.contractId, data.save, authToken);
      return response?.data;
    },
    {
      onError: (
        e: ResponseError<{
          user_id: number;
          email: string;
          contract_id: number;
          person: string;
          month_number: number;
          detail_id: string;
          person_id: number;
          identifier: string;
          movement_id: number;
        }>
      ) => {
        alert.error({
          message: "Error al simular monto de pago",
          disclaimer: (
            <Trans
              i18nKey={t(e.message)}
              values={{ ...e?.extra }}
              components={{
                1: <strong />,
              }}
            />
          ),
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    error,
    data,
  };
};

export default useSimulation;
