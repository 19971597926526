import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { IPromotion } from "models/Promotions";
import promotionService from "domains/promotion/service";

import { parserPromotions } from "../../../utils/parsers";
import { getOrderedInfo } from "../utils/utils";

const usePromotionById = (promotionId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = null,
  }: UseQueryResult<IPromotion, Error> = useQuery<IPromotion, Error>(
    ["promotion_by_id", promotionId],
    async () => {
      const authToken = await getToken();
      const response = await promotionService.getPromotionById(promotionId, authToken);
      const promotion = response.data;
      const parsedPromotion = parserPromotions(promotion);
      return parsedPromotion;
    },
    {
      enabled: !!promotionId,
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    data: data && getOrderedInfo(data),
  };
};

export default usePromotionById;
