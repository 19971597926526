import { Box, Typography } from "@material-ui/core";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ReactComponent as BackIcon } from "assets/icons/arrows/chevron-back.svg";
import {
  Bidder,
  Owner,
  PersonType,
  PersonTypes,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { useMemo } from "react";

import useHeaderStyles from "./HeaderStyles";

interface HeaderProps {
  person: Owner | Bidder;
  type: PersonType;
}

function Header({ person, type }: HeaderProps) {
  const classes = useHeaderStyles();
  const title = useMemo(
    () =>
      `${type === PersonTypes.BIDDER ? "Comprador" : "Propietario"} ${
        person?.isMainUser ? "principal" : "secundario"
      }`,
    []
  );
  const goToBack = () => window.history.back();

  return (
    <Box className={classes.headerContainer}>
      <DesignSystemButton
        size="small"
        variant="tertiaryB"
        onClick={goToBack}
        label="Volver"
        Icon={<BackIcon />}
        buttonClassName={classes.backButton}
      />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{person.fullname}</Typography>
    </Box>
  );
}

export default Header;
