import { Container, Typography } from "@material-ui/core";

import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";
import useErrorMessageStyles from "./ErrorMessageStyles";

interface Props {
  userType: "arrendatario" | "propietario";
}

const ErrorMessage = ({ userType }: Props) => {
  const classes = useErrorMessageStyles();

  return (
    <Container className={classes.titleContainer}>
      <WarningIcon />
      <Typography className={classes.title}>
        {`Ocurrió un error al consultar los pagos del ${userType}`}
      </Typography>
    </Container>
  );
};

export default ErrorMessage;
