import Checkbox from "@material-ui/core/Checkbox";
import { GridColDef } from "@material-ui/x-grid";
import StarIcon from "@material-ui/icons/Star";
import Chip from "@material-ui/core/Chip";

import { CustomDataGrid } from "Components/Admin/UIComponents";
import { useTable } from "context/tableContext/tableContext";
import { IAssing, IExecutives } from "../utils";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import useTableStyles from "./TableStyles";
import Toolbar from "./Toolbar";

interface Props {
  numberOfCells: number;
  cells: IExecutives[];
  loading: boolean;
  rolUser: any;
  loadingAssignation: boolean;
  assignation: IAssing[];
  onExecutiveActivation: (event: any) => void;
}

const userIsAllow = (userRoles = [], allowRoles = []) =>
  allowRoles.some((role) => userRoles.includes(role));

function Table({
  numberOfCells,
  cells,
  loading,
  loadingAssignation,
  assignation,
  rolUser,
  onExecutiveActivation,
}: Props) {
  const classes = useTableStyles();
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
  };

  const idConfig: GridColDef = {
    field: "",
    resizable: false,
    flex: 1,
    filterable: false,
    hide: true,
  };

  const TableColumns = [
    {
      ...idConfig,
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      ...columnsConfig,
      field: "is_vip",
      headerName: "Tipo",
      width: 100,
      renderCell: ({ value }) => (
        <Chip
          icon={value && <StarIcon className={classes.vipIcon} />}
          label={value ? "VIP" : "Normal"}
          className={`${classes.status} ${value ? classes.vipExecutive : classes.normalExecutive}`}
          variant="outlined"
        />
      ),
    },
    {
      ...columnsConfig,
      field: "name",
      headerName: "Nombre",
      width: 100,
    },
    {
      ...columnsConfig,
      field: "email",
      headerName: "Correo",
      width: 100,
    },
    {
      ...columnsConfig,
      field: "weight",
      headerName: "Peso",
      width: 100,
      valueFormatter: ({ value }) => {
        const valueFormatted = Number(value * 100).toLocaleString();
        return `${valueFormatted} %`;
      },
    },
    {
      ...columnsConfig,
      field: "active",
      headerName: "Activa",
      sortable: false,
      width: 100,
      renderCell: ({ value, id }) => (
        <Checkbox
          id={id.toString()}
          checked={value}
          disabled={!rolUser.some((r) => ["super_admin", "management_admin"].indexOf(r) >= 0)}
          onChange={onExecutiveActivation}
          color="primary"
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={numberOfCells}
        columns={TableColumns}
        rows={cells}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          ...(userIsAllow(rolUser, ["super_admin"]) && { Toolbar }),
        }}
        componentsProps={{
          toolbar: { loadingAssignation, assignation },
        }}
      />
    </div>
  );
}

export default Table;
