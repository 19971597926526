import { useEffect, useState, memo } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formatPhoneInput } from "utils";
import { ModifyLeadForm } from "models/DemandLead";
import { leadSchema } from "./forms/validationSchema";

import { useGetToken } from "hooks";
import { modifyConctactLead, getLead } from "context/demandLeadContext/lead/demandLeadActions";
import { useTab } from "context/tabContext/tabContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Accordion from "Components/Admin/UIComponents/Accordion/Accordion";
import Title from "./Title";
import LeadForm from "./forms/LeadForm";
import useLeadStyles from "./LeadStyle";

function Lead() {
  const classes = useLeadStyles();
  const { getToken } = useGetToken();
  const [accordionState, setAccordionState] = useState(true);

  const methods = useForm<ModifyLeadForm>({
    resolver: yupResolver(leadSchema),
  });

  const {
    state: { lead },
    dispatch,
  } = useDemandLead();
  const {
    state: { currentTabValue },
  } = useTab();

  useEffect(() => {
    if (currentTabValue === "agendar") setAccordionState(false);
  }, [currentTabValue]);

  useEffect(() => {
    if (lead) {
      const {
        lead: { name, qualification },
        executive: { id, name: executiveName, last_name },
      } = lead;
      const [firstName, ...rest] = (name || "").split(" ");

      methods.setValue("name", firstName);
      methods.setValue("lastName", rest.join(" "));
      methods.setValue("qualification", qualification);
      methods.setValue("executive", {
        label: `${executiveName} ${last_name}`,
        value: id,
      });
      const parsedNumber = formatPhoneInput(lead.lead.phone);
      methods.setValue("phone", {
        country: parsedNumber.country,
        phone: parsedNumber.phone,
      });
    }
  }, [lead]);

  const handleSubmit = (data) => {
    getToken().then(async (authToken) => {
      await modifyConctactLead(dispatch, data, lead.lead.id, authToken);
      getLead(dispatch, `${lead.id}`, authToken);
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={classes.titleContainer}>
          <Title>Información Lead</Title>
        </div>
        <Accordion
          changeState={() => setAccordionState(!accordionState)}
          expanded={accordionState}
          title="Datos del contacto">
          <Loading loading={!lead}>
            <LeadForm />
          </Loading>
        </Accordion>
      </form>
    </FormProvider>
  );
}

export default memo(Lead);
