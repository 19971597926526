import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { SetIsOpenAsignSuccessModal } from "context/demandScheduledVisitsContext/demandScheduledActions";

function Success() {
  const {
    state: { isOpenAsignSuccessModal },
    dispatch,
  } = useDemandScheduled();
  return (
    <CustomCenteredModal
      open={isOpenAsignSuccessModal}
      onClose={() => dispatch(SetIsOpenAsignSuccessModal(false))}
      title="¡Se han guardado los datos con exito!"
      subtitle="Si no ves reflejados los cambios prueba volver a cargar la tabla."
      showCloseButton
      withLogo
    />
  );
}
export default Success;
