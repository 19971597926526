import { makeStyles, Theme } from "@material-ui/core";

const useRightColumnFormStyles = makeStyles((theme: Theme) => ({
  timezoneText: {
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 0),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

export default useRightColumnFormStyles;
