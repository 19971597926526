import { format } from "rut.js";

import { getCountryPhone } from "utils/verifyPhoneNumber";
import { Country, PhoneInput } from "models/PhoneInput";
import { CountryUpperCase, Locale } from "models/Countries";
import { countryCode, formatPhoneInput } from "utils";
import { UserModel } from "models/User";
import { UpdateFormData } from "./types";

const getDefaultPhone = (phone: string, country: Locale): PhoneInput => {
  const defaultCountry: Country = getCountryPhone(country);
  const defaultPhone: PhoneInput = { country: defaultCountry, phone: "" };
  const thirdPartyCoordinationPhone = phone;
  return thirdPartyCoordinationPhone ? formatPhoneInput(thirdPartyCoordinationPhone) : defaultPhone;
};

export const getUserDefaultData = (user: UserModel, country: CountryUpperCase | "Unknown") => ({
  name: user?.name ?? "",
  lastName: user?.last_name ?? "",
  secondLastName: user?.second_last_name ?? "",
  documentType: user?.document_type ? (user?.document_type as number).toString() : "",
  document: user?.document ?? "",
  email: user?.email ?? "",
  phone: getDefaultPhone(user?.phone, countryCode(country)),
});

export const parseFormData = (data: UpdateFormData) => ({
  email: data.email.toLowerCase(),
  phone: data.phone.parsedPhone,
  name: data.name,
  last_name: data.lastName,
  second_last_name: data.secondLastName,
  rut: data.country === "cl" ? format(data.document) : data.document,
  document: data.country === "cl" ? format(data.document) : data.document,
  document_type: Number(data.documentType),
  role: data.role,
});
