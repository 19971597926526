import { useState } from "react";
import { Grid } from "@material-ui/core";

import {
  GeneralComments,
  Observations,
} from "Components/Admin/administrator/properties/show/tabs/general/components";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import Spinner from "Components/Shared/partials/Spinner";
import PropertyServices from "Components/Admin/UIComponents/PropertyServices";
import { PropertyData } from "models/Property";
import { countryCode } from "utils";

import usePropertyGeneralTabStyles from "./PropertyGeneralTabStyles";
import ThirdPartyCoordCard from "./components/ThirdPartyCoordCard";
import CharacteristicsCard from "./components/CharacteristicsCard";
import KeysLocationCard from "./components/KeysLocationCard";
import AmenitiesCard from "./components/AmenitiesCard";
import InhabitedCard from "./components/InhabitedCard";
import LocationCard from "./components/LocationCard";
import HandoverCard from "./components/HandoverCard";
import HoumboxCard from "./components/HoumboxCard";
import PriceCard from "./components/PriceCard";
import KeysCard from "./components/KeysCard";
import useKeys from "./utils/useKeys";
import QuestionsCard from "./components/QuestionsCard";

interface Props {
  property: PropertyData;
}

function PropertyGeneralTab({ property }: Props) {
  const [showKeyCard, setShowKeyCard] = useState(false);
  const { data: keysData, isLoading } = useKeys(property.id);
  const classes = usePropertyGeneralTabStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PriceCard property={property} />
        </Grid>
        <Grid item xs={12}>
          <CharacteristicsCard property={property} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmenitiesCard property={property} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocationCard property={property} />
        </Grid>
      </Grid>
      <div className={classes.collapsesContainer}>
        <CollapseCard title="Descripción">
          <div className={classes.collapse}>
            <Observations property={property} />
          </div>
        </CollapseCard>
        <CollapseCard title="Información coordinación de la visita">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className={classes.collapse}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <HandoverCard />
                    </Grid>
                    <Grid item xs={12}>
                      <ThirdPartyCoordCard />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <InhabitedCard />
                    </Grid>
                    <Grid item xs={12}>
                      <HoumboxCard showKeyCard={setShowKeyCard} keysData={keysData} />
                    </Grid>
                    {showKeyCard && (
                      <Grid item xs={12}>
                        <KeysCard keysData={keysData} />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <KeysLocationCard />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </CollapseCard>
        <CollapseCard title="Servicios básicos y gastos comunes">
          <div className={classes.collapse}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <PropertyServices
                  propertyId={property.id}
                  country={countryCode(property.country)}
                  variant="basicServices"
                />
              </Grid>
              {property.country !== "Mexico" && (
                <Grid item xs={6}>
                  <PropertyServices
                    propertyId={property.id}
                    country={countryCode(property.country)}
                    variant="commonExpenses"
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </CollapseCard>
        <CollapseCard title="Preguntas y comentarios">
          <div className={classes.collapse}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <QuestionsCard propertyId={property.id} />
              </Grid>
              <Grid item xs={6}>
                <GeneralComments property={property} />
              </Grid>
            </Grid>
          </div>
        </CollapseCard>
      </div>
    </>
  );
}

export default PropertyGeneralTab;
