import { ActionType, State, Action } from "./demandScheduleypes";

export const initialState: State = {
  property: null,
  similarProperties: [],
  isScheduling: false,
  availability: {},
  isOpenLoadingModal: false,
  isOpenErrorModal: false,
  isOpenSuccessScheduled: false,
  isOpenNoAvailabilityModal: false,
  appraiserSelectedId: null,
  isOpenScheduleAdvisoryModal: false,
  advisoryMessage: false,
  advisoryResponseData: {
    appraiserId: "",
    appraiserName: "",
    scheduleId: null,
    daySchedule: "",
    leadEmail: "",
  },
};

const demandLeadReducer = (state, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PROPERTY:
      return {
        ...state,
        property: action.property,
      };

    case ActionType.SET_SIMILAR_PROPERTIES:
      return {
        ...state,
        similarProperties: action.similarProperties,
      };

    case ActionType.SET_IS_SCHEDULING:
      return {
        ...state,
        isScheduling: action.isScheduling,
      };

    case ActionType.SET_IS_OPEN_SUCCESS_SCHEDULED:
      return {
        ...state,
        isOpenSuccessScheduled: action.isOpenSuccessScheduled,
      };

    case ActionType.SET_PROPERTY_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
      };

    case ActionType.SET_IS_OPEN_NO_AVAILABILITY_MODAL:
      return {
        ...state,
        isOpenNoAvailabilityModal: action.isOpenNoAvailabilityModal,
      };

    case ActionType.SET_APPRAISER_SELECTED_ID:
      return {
        ...state,
        appraiserSelectedId: action.appraiserSelectedId,
      };

    case ActionType.SET_IS_OPEN_ERROR_MODAL:
      return {
        ...state,
        isOpenErrorModal: action.isOpenErrorModal,
      };

    case ActionType.SET_IS_OPEN_LOADING_MODAL:
      return {
        ...state,
        isOpenLoadingModal: action.isOpenLoadingModal,
      };

    case ActionType.SET_IS_OPEN_SCHEDULE_ADVISORY_MODAL:
      return {
        ...state,
        isOpenScheduleAdvisoryModal: action.isOpenScheduleAdvisoryModal,
      };

    case ActionType.SET_ADVISORY_MESSAGE:
      return {
        ...state,
        advisoryMessage: action.advisoryMessage,
      };

    case ActionType.SET_ADVISORY_RESPONSE_DATA:
      return {
        ...state,
        advisoryResponseData: action.advisoryResponseData,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
