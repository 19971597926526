import { Grid, Typography } from "@material-ui/core";

import { getCountry } from "Components/Admin/administrator/contracts/ContractTemplates/utils";
import { Locale } from "models/Countries";

import useTemplateTitleStyles from "./TemplateTitleStyles";
import Title from "../../../typographies/Title";

interface Props {
  country: Locale;
  text: string;
}

function TemplateTitle({ country, text }: Props) {
  const classes = useTemplateTitleStyles();

  return (
    <>
      <Grid item xs={12}>
        <Title>{text}</Title>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>Aplica para: {getCountry(country)}</Typography>
      </Grid>
    </>
  );
}

export default TemplateTitle;
