import { useState, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

import {
  ControlledCheckbox,
  ControlledPhoneInput,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { useFormNotifications, useGetToken } from "hooks";
import { useProperty } from "context/propertyContext/propertyContext";
import { IHomeCheckerDetails } from "models/createProperty";
import { updateHomeCheckerDetails } from "context/propertyContext/propertyActions";
import validationSchema, { requiredText } from "./conciergeDataValidationSchema";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useStyles from "./ConciergeDataStyles";
import useHomeCheckerDetails from "../hooks/useHomeCheckerDetails";

function ConciergeData(props) {
  const { getToken } = useGetToken();
  const { dispatch } = useProperty();
  const { property } = props;
  const { data: homeCheckerDetails, isLoading } = useHomeCheckerDetails(property.id);

  const classes = useStyles();

  const methods = useForm<IHomeCheckerDetails>({
    resolver: yupResolver(validationSchema),
  });

  const { errors } = methods.formState;

  const onSubmit: SubmitHandler<IHomeCheckerDetails> = async (data, e) => {
    try {
      const authToken = await getToken();
      await updateHomeCheckerDetails(property.id, data, dispatch, authToken);
    } catch {
      console.error("error");
    }
  };

  useFormNotifications<IHomeCheckerDetails>({ errors, requiredText });

  const [isConciergeOpen, setIsConciergeOpen] = useState(false);
  const [isInhabitedPropertyOpen, setIsInhabitedPropertyOpen] = useState(false);
  const [isThirdPartyCoordinationOpen, setIsThirdPartyCoordinationOpen] = useState(false);

  const handleOnClick = (e, type) => {
    switch (type) {
      case "concierge":
        setIsConciergeOpen(e.target.checked);
        break;
      case "inhabitedProperty":
        setIsInhabitedPropertyOpen(e.target.checked);
        break;
      case "thirdPartyCoordination":
        setIsThirdPartyCoordinationOpen(e.target.checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIsConciergeOpen(homeCheckerDetails?.concierge);
    setIsInhabitedPropertyOpen(homeCheckerDetails?.inhabitedProperty);
    setIsThirdPartyCoordinationOpen(homeCheckerDetails?.thirdPartyCoordination);
  }, [homeCheckerDetails]);

  return (
    <Loading loading={isLoading}>
      <FormProvider {...methods} key="ConciergeData">
        <form key="ConciergeData" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container justifyContent="space-between" className={classes?.formContainer}>
            <Grid item className={classes?.gridItemContainer} xs={12}>
              <h3>Administración</h3>
            </Grid>
            <Grid item className={classes?.gridItemContainer} xs={6}>
              <ControlledTextField
                id="administrationEmail"
                label="Email"
                defaultValue={homeCheckerDetails?.administrationEmail}
                textFieldProps={{
                  InputProps: { className: classes?.formInput },
                }}
              />
            </Grid>
            <Grid item className={classes?.gridItemContainer} xs={6}>
              <ControlledPhoneInput
                id="administrationPhone"
                phoneLabel="Teléfono"
                defaultValue={homeCheckerDetails?.administrationPhone}
                fieldContainerLabel="Teléfono Administración"
                fieldContainerLabelClassName={classes.label}
                classNameInputContainer={classes.phoneInputContainer}
              />
            </Grid>
            <Divider className={classes?.divider} />
            <ControlledCheckbox
              id="concierge"
              label="Conserje"
              defaultChecked={homeCheckerDetails?.concierge}
              justifyContent="flex-end"
              checkboxProps={{
                onClick: (e) => handleOnClick(e, "concierge"),
              }}
            />
            <Collapse
              in={isConciergeOpen}
              timeout="auto"
              unmountOnExit
              className={classes?.collapseContainer}>
              <Grid item className={classes?.gridItemContainer} xs={12}>
                <ControlledTextField
                  id="conciergeEmail"
                  label="Correo de conserje"
                  defaultValue={homeCheckerDetails?.conciergeEmail}
                  textFieldProps={{
                    InputProps: { className: classes?.formInput },
                  }}
                />
              </Grid>
              <Grid item className={classes?.gridItemContainer} xs={12}>
                <ControlledTextField
                  id="conciergeName"
                  label="Nombre conserje"
                  defaultValue={homeCheckerDetails?.conciergeName}
                  textFieldProps={{
                    InputProps: { className: classes?.formInput },
                  }}
                />
              </Grid>
              <Grid item className={classes?.gridItemContainer} xs={12}>
                <ControlledPhoneInput
                  id="conciergePhone"
                  phoneLabel="Teléfono"
                  defaultValue={homeCheckerDetails?.conciergePhone}
                  fieldContainerLabel="Teléfono"
                  fieldContainerLabelClassName={classes.label}
                  classNameInputContainer={classes.phoneInputContainer}
                />
              </Grid>
            </Collapse>
            <Divider className={classes?.divider} />
            <Grid item className={classes?.gridItemContainer} xs={12}>
              <ControlledTextField
                id="moveInPolicy"
                label="Política de mudanza"
                defaultValue={homeCheckerDetails?.moveInPolicy}
                textFieldProps={{
                  InputProps: { className: classes?.formInput },
                }}
              />
            </Grid>
            <Grid item className={classes?.gridItemContainer} xs={12}>
              <ControlledTextField
                id="moveInPolicyPhoto"
                label="Foto politica de mudanza"
                defaultValue={homeCheckerDetails?.moveInPolicyPhoto}
                textFieldProps={{
                  InputProps: { className: classes?.formInput },
                }}
              />
            </Grid>
            <Divider className={classes?.divider} />
            <ControlledCheckbox
              id="inhabitedProperty"
              label="Propiedad habitada"
              defaultChecked={homeCheckerDetails?.inhabitedProperty}
              justifyContent="flex-end"
              checkboxProps={{
                onClick: (e) => handleOnClick(e, "inhabitedProperty"),
              }}
            />
            <Collapse
              in={isInhabitedPropertyOpen}
              timeout="auto"
              unmountOnExit
              className={classes?.collapseContainer}>
              <Grid item className={classes?.gridItemContainer} xs={12}>
                <ControlledTextField
                  id="inhabitedPropertyDescription"
                  label="Descripción"
                  defaultValue={homeCheckerDetails?.inhabitedPropertyDescription}
                  textFieldProps={{
                    InputProps: { className: classes?.formInput },
                  }}
                />
              </Grid>
            </Collapse>
            <Divider className={classes?.divider} />
            <ControlledCheckbox
              id="thirdPartyCoordination"
              label="Requiere coordinación con terceros"
              defaultChecked={homeCheckerDetails?.thirdPartyCoordination}
              justifyContent="flex-end"
              checkboxProps={{
                onClick: (e) => handleOnClick(e, "thirdPartyCoordination"),
              }}
            />
            <Collapse
              in={isThirdPartyCoordinationOpen}
              timeout="auto"
              unmountOnExit
              className={classes?.collapseContainer}>
              <Grid item className={classes?.gridItemContainer} xs={12}>
                <ControlledTextField
                  id="thirdPartyCoordinationDescription"
                  label="Descripción"
                  defaultValue={homeCheckerDetails?.thirdPartyCoordinationDescription}
                  textFieldProps={{
                    InputProps: { className: classes?.formInput },
                  }}
                />
              </Grid>
            </Collapse>
            <Divider className={classes?.divider} />

            <Grid item xs={3}>
              <CustomButton active type="submit">
                Actualizar datos de Conserje
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Loading>
  );
}

export default ConciergeData;
