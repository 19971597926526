import { ActionType, Action, Dispatch } from "./demandScheduleypes";

import { AxiosResponse } from "axios";
import moment from "moment";

import applicantService from "services/applicantService";
import rentorService from "services/rentruvioService";
import leadService from "services/leadService";
import scheduleService from "services/schedulesService";
import propertyService from "services/propertyService";

import { localeToCountry } from "utils/countryCode";
import { Lead } from "models/DemandLead";
import { IProperty } from "models";
import { Paginate } from "models/ResponseWithPaginate";
import { CountryUpperCase } from "models/Countries";

export const setProperty = (property): Action => ({
  type: ActionType.SET_PROPERTY,
  property,
});

export const setAvailability = (availability): Action => ({
  type: ActionType.SET_PROPERTY_AVAILABILITY,
  availability,
});

export const setAppraiserId = (appraiserSelectedId: boolean): Action => ({
  type: ActionType.SET_APPRAISER_SELECTED_ID,
  appraiserSelectedId,
});

export const setIsScheduling = (isScheduling: boolean): Action => ({
  type: ActionType.SET_IS_SCHEDULING,
  isScheduling,
});

export const setIsOpenNoAvModal = (isOpenNoAvailabilityModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_NO_AVAILABILITY_MODAL,
  isOpenNoAvailabilityModal,
});

export const setIsOpenSuccessScheduled = (isOpenSuccessScheduled: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_SUCCESS_SCHEDULED,
  isOpenSuccessScheduled,
});

export const setIsOpenErrorModal = (isOpenErrorModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ERROR_MODAL,
  isOpenErrorModal,
});

export const setIsOpenLoadingModal = (isOpenLoadingModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_LOADING_MODAL,
  isOpenLoadingModal,
});

export const setIsOpenScheduleAdvisoryModal = (isOpenScheduleAdvisoryModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_SCHEDULE_ADVISORY_MODAL,
  isOpenScheduleAdvisoryModal,
});

export const setSimilarProperties = (similarProperties): Action => ({
  type: ActionType.SET_SIMILAR_PROPERTIES,
  similarProperties,
});

export const setAdvisoryMessage = (advisoryMessage): Action => ({
  type: ActionType.SET_ADVISORY_MESSAGE,
  advisoryMessage,
});

export const setAdvisoryResponseData = (advisoryResponseData): Action => ({
  type: ActionType.SET_ADVISORY_RESPONSE_DATA,
  advisoryResponseData,
});
/**
 * update the selected property with availability and similar properties
 * @param property property id or object property
 * @param businessType required for schedule availability and similar property endpoint
 * @param dispatch schedule dispatch
 * @param authToken auth0 token
 * @param appraiserId optional for schedule availability, this get the appraiser disponibility hours with the same property
 * @param getSimProps optional, this is only to avoid calling multiple times the similar properties endpoint
 *
 */
export const updateCurrentProperty = async (
  property: IProperty | number,
  businessType: "rentals" | "sales",
  dispatch: Dispatch,
  authToken: string,
  appraiserId: number | undefined = undefined,
  getSimProps: boolean = true
) => {
  let newProperty = null;
  if (typeof property === "number") {
    const propertyRes = (await propertyService.getPropertiesByIds(
      [property],
      authToken
    )) as AxiosResponse<Paginate<IProperty>>;
    [newProperty] = propertyRes.data.results;
  }

  newProperty = newProperty || property;

  if (newProperty) {
    dispatch(setProperty(newProperty));
    if (getSimProps) getSimilarProperties(newProperty?.id, businessType, dispatch);
    const typeVisit = businessType === "sales" ? "SalesVisit" : "Visit";
    getAvailability(newProperty?.id, typeVisit, appraiserId, authToken, dispatch);
  }
};

export const getAvailability = async (
  propertyId: number | "",
  type: "Visit" | "SalesVisit" | "Advisory" = "SalesVisit",
  appraiserId: number = undefined,
  authToken: string,
  dispatch: Dispatch,
  country: CountryUpperCase = null,
  cityId: number = undefined
) => {
  try {
    dispatch(setIsOpenLoadingModal(true));
    const availability = (await scheduleService.getAvailableHours(
      type,
      propertyId,
      authToken,
      appraiserId,
      country,
      cityId
    )) as AxiosResponse;
    dispatch(setAvailability(availability.data));
  } catch (error) {
    dispatch(setAvailability({}));
    return error;
  } finally {
    dispatch(setIsOpenLoadingModal(false));
  }
};

export const getSimilarProperties = async (
  id: number,
  deal: "rentals" | "sales",
  dispatch: Dispatch
) => {
  try {
    const singularDeal = deal.slice(0, -1) as "rental" | "sale";
    const res = (await rentorService.getSimilarProperties(
      id,
      singularDeal,
      1,
      30
    )) as AxiosResponse;
    const { data: similarProperties } = res;
    if (res.status === 200) {
      dispatch(setSimilarProperties(similarProperties));
    }
  } catch (error) {
    return error;
  }
};

export const getApplicant = async (lead: Lead, propertyId: number, authToken: string) => {
  let user;
  if (!lead.lead.user) {
    try {
      const userData = {
        role: "applicant",
        email: lead.lead.email,
        name: lead.lead.name,
        phone: lead.lead.phone || "-",
        country: localeToCountry[lead.lead.country],
      };
      const userRes = await leadService.postLead(userData, authToken);
      user = userRes.data;
    } catch (error) {
      return error;
    }
  }
  user = lead.lead.user || user;
  if (user) {
    const res = (await applicantService.store(
      {
        ...user,
        property_id: propertyId,
        occupation: "-",
        last_name: user.last_name || "-",
      },
      authToken
    )) as AxiosResponse;

    return res.data.id;
  }
};

interface ScheduleParams {
  timeStamp: string;
  lead: Lead;
  propertyId: number;
  authToken: string;
  isStreaming?: boolean;
  type?: "SalesVisit" | "Visit";
  dispatch: Dispatch;
  appraiser_id?: string;
}

export const scheduleVisit = async ({
  lead,
  dispatch,
  timeStamp,
  authToken,
  propertyId,
  type = "SalesVisit",
  isStreaming = false,
  appraiser_id = undefined,
}: ScheduleParams) => {
  try {
    dispatch(setIsOpenLoadingModal(true));
    const applicantId = await getApplicant(lead, propertyId, authToken);

    const beginHour = moment.utc(timeStamp);
    const endHour = beginHour.clone().add(2, "hours");
    const props = {
      prop: propertyId,
      begin_hour: beginHour,
      end_hour: endHour,
      date: beginHour,
      applicant: applicantId,
      origin: "Admin - Demand",
      is_streaming: isStreaming,
    };

    if (applicantId && !appraiser_id) {
      const resVisit = (await scheduleService.createVisitSchedule(
        { type, ...props },
        authToken
      )) as AxiosResponse;
      if (resVisit.status === 200) {
        dispatch(setIsOpenSuccessScheduled(true));
      } else {
        dispatch(setIsOpenErrorModal(true));
      }
    }

    if (applicantId && appraiser_id) {
      const resVisit = (await scheduleService.createSchedule(
        {
          schedule_type: type,
          appraiser: appraiser_id,
          not_emails: false,
          applicant_email: lead.lead.email,
          ...props,
        },
        authToken
      )) as AxiosResponse;
      if (resVisit.status === 201) {
        dispatch(setIsOpenSuccessScheduled(true));
      } else {
        dispatch(setIsOpenErrorModal(true));
      }
    }
  } catch (error) {
    return error;
  } finally {
    dispatch(setAppraiserId(undefined));
    dispatch(setIsOpenLoadingModal(false));
  }
};

interface AdvisoryParams {
  lead: Lead;
  dispatch: Dispatch;
  authToken: string;
  timeStamp: string;
  propertyId: number;
  cityId: number;
  country: CountryUpperCase;
  type?: "Advisory";
  daySchedule?: string;
  leadEmail?: string;
}

export const scheduleAdvisory = async ({
  lead,
  dispatch,
  authToken,
  timeStamp,
  type = "Advisory",
  propertyId,
  cityId,
  country,
  daySchedule = null,
  leadEmail = null,
}: AdvisoryParams) => {
  try {
    dispatch(setIsOpenLoadingModal(true));
    const applicantId = await getApplicant(lead, propertyId, authToken);
    const beginHour = moment.utc(timeStamp);
    const endHour = beginHour.clone().add(60, "minutes");
    const body = {
      type,
      begin_hour: beginHour,
      end_hour: endHour,
      applicant: applicantId,
      city_id: cityId,
      country,
      origin: "Admin - Demand",
    };
    const resVisit = (await scheduleService.createVisitSchedule(body, authToken)) as AxiosResponse;
    if (resVisit.status === 200) {
      dispatch(setIsOpenSuccessScheduled(true));
      dispatch(setAdvisoryMessage(true));
      dispatch(
        setAdvisoryResponseData({
          appraiserId: resVisit.data.appraiser_id,
          appraiserName: resVisit.data.appraiser_name,
          scheduleId: resVisit.data.schedule_id,
          daySchedule,
          leadEmail,
        })
      );
    }
  } catch (error) {
    dispatch(setIsOpenErrorModal(true));
    dispatch(
      setAdvisoryResponseData({
        appraiserId: "",
        appraiserName: "",
        scheduleId: null,
        daySchedule: null,
        leadEmail: null,
      })
    );
    return error;
  } finally {
    dispatch(setIsOpenLoadingModal(false));
  }
};
