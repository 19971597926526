import { useQuery, UseQueryResult } from "react-query";

import userService from "services/userService";
import useGetToken from "hooks/useGetToken";
import { UserModel } from "models/User";

const useGetCurrentUser = () => {
  const { getToken } = useGetToken();

  const { isLoading = true, data }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    ["currentUser"],
    async () => {
      const authToken = await getToken();
      const result = await userService.getUser(authToken);
      return result.data;
    }
  );

  return {
    user: data,
    loading: isLoading,
  };
};
export default useGetCurrentUser;
