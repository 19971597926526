import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { CountryUpperCase } from "models/Countries";
import useContract from "hooks/useContract";

import countryCurrency from "./countryCurrency";
import numberWithCommas from "./numberWithCommas";

const formatPrice = (
  propertyPrice: number | null,
  typeMoney: string,
  country: CountryUpperCase
) => {
  if (propertyPrice || propertyPrice === 0) {
    return typeMoney === "Pesos"
      ? `$${numberWithCommas(propertyPrice)} ${countryCurrency(country).currency}`
      : `${numberWithCommas(propertyPrice)} ${typeMoney}`;
  } else {
    return "N/A";
  }
};

const getPrice = (propDetailsPrice: number, contractPrice: number): number | null => {
  if (contractPrice) return contractPrice;
  if (propDetailsPrice) return propDetailsPrice;
  return null;
};

const renderPriceProperty = (data, propertyType: string) => {
  const { contractData, contractLoading } = useContract(data?.uid);
  const contractPrice = contractData?.amount;

  if (contractLoading) return <Loading loading size={20} />;

  try {
    const propDetails = data.property_details[0];
    if (propertyType !== "forSale") {
      return formatPrice(
        getPrice(propDetails?.valor, contractPrice),
        propDetails?.tipo_moneda,
        data.country
      );
    }
    return formatPrice(propDetails?.valor_venta, propDetails?.tipo_moneda_venta, data.country);
  } catch {
    return "N/A";
  }
};

export default renderPriceProperty;
