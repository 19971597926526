import { Box, Typography } from "@material-ui/core";
import { CustomLink } from "Components/Admin/UIComponents";

import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "utils";
import useNegotiationCardStyles from "./NegotiationCardStyles";

function NegotiationCard() {
  const classes = useNegotiationCardStyles();
  const { state } = useLetterOfIntent();
  const { negotiation, country } = state;
  const params = useParams<{ id: string }>();
  const { id: loiId } = params;

  const withSign = (currency) => (currency !== "UF" ? "$" : "");
  if (!negotiation) return null;

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Datos del negocio</Typography>
      <Box className={classes.textContainer}>
        <Box className={classes.fieldContainer}>
          <Typography className={classes.label}>ID Propiedad:</Typography>
          <Typography className={classes.value}>{negotiation.propertyId}</Typography>
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography className={classes.label}>Dirección depropiedad:</Typography>
          <Typography className={classes.value}>{negotiation.address}</Typography>
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography className={classes.label}>Valor acordado:</Typography>
          <Typography className={classes.value}>{`${withSign(
            negotiation.currency
          )}${numberWithCommas(negotiation.amount)} ${negotiation.currency}`}</Typography>
        </Box>
      </Box>
      <Box className={classes.moreInfoContainer}>
        <CustomLink
          to={`/buyers/letterOfIntent/${loiId}/detail?country=${country}`}
          text="Ver información >"
          align="end"
        />
      </Box>
    </Box>
  );
}

export default NegotiationCard;
