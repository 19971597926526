import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import newPaymentService from "services/newPaymentService";
import useGetToken from "hooks/useGetToken";
import { Locale } from "models/Countries";
import { alert } from "libs/alerts";

import { parseWarrantyCondition } from "./utils/parsers";
import { FormData } from "./utils/types";

const useChangeWarrantyCondition = (
  contractId: number,
  country: Locale,
  handleModal: () => void
) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: FormData) => {
      const authToken = await getToken();
      const response = await newPaymentService.changeWarrantyCondition(
        country,
        contractId,
        parseWarrantyCondition(formData),
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contract_warranty");
        handleModal();
        alert.success({
          message: "Los cambios de garantía se han hecho exitosamente",
        });
      },
      onError: (e: AxiosError<{ message: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo cambiar las condiciones de la garantía",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useChangeWarrantyCondition;
