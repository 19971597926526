import { Box, IconButton, Typography } from "@material-ui/core";

import { ReactComponent as TrashSvg } from "assets/icons/trash-bold.svg";
import useSelectedCityStyles from "./SelectedCityStyles";

interface Props {
  cityName: string;
  onDeleteCity: () => void;
}

const SelectedCity = ({ cityName, onDeleteCity }: Props) => {
  const classes = useSelectedCityStyles();

  return (
    <Box className={classes.cityContainer}>
      <Typography className={classes.cityName}>{cityName}</Typography>
      <IconButton aria-label="Quitar}" className={classes.icon} onClick={onDeleteCity}>
        <TrashSvg />
      </IconButton>
    </Box>
  );
};

export default SelectedCity;
