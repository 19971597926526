import { makeStyles, Theme } from "@material-ui/core";

const useDocumentViewStyles = makeStyles<Theme>((theme) => ({
  pdfOptions: {
    backgroundColor: "#FFCBBF",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  pdfOptionsBoxes: {
    display: "flex",
    margin: theme.spacing(0, 1),
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  buttons: {
    margin: theme.spacing(0, 4),
    display: "flex",
    justifyContent: "space-between",
  },
  validatorButtons: {
    display: "flex",
    gap: theme.spacing(2),
  },
  spotlightImage: {
    display: "inline-block",
    minWidth: "100%",
    "& img": {
      minWidth: "100%",
    },
  },
  pageInput: {
    margin: theme.spacing(0, 1),
    width: 50,
  },
  secondaryButtons: {
    backgroundColor: "transparent",
    border: `1px solid #4C6977`,
    alignSelf: "flex-end",
  },
}));

export default useDocumentViewStyles;
