import { makeStyles, Theme } from "@material-ui/core";

const useSwitchSelectorStyles = makeStyles<Theme>((theme) => ({
  group: {
    borderRadius: 8,
    height: 32,
    border: `1px solid #A0B1B9`,
  },
  container: {
    width: "100%",
  },
  titleSelected: {
    color: "white",
  },
  titleNotSelected: {
    color: "#BFCBD1",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  button: {
    border: "none",
    textTransform: "unset",
    borderRadius: "8px !important",
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.grey[900]}`,
  },
}));

export default useSwitchSelectorStyles;
