import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@houm-com/ui/Button";
import LightButton from "@houm-com/ui/LightButton";
import { Grid } from "@material-ui/core";

import CollapsibleContent from "Components/Admin/UIComponents/CollapsibleContent";

import useSubmitLandlordInfo from "../../hooks/useSubmitLandlordInfo";
import { ContractLandlordProps } from "../../utils/types";
import DataWrapper from "../DataWrapper";
import useLandlordDataStyles from "./LandlordDataStyles";
import { DocumentTypeProps } from "../../hooks/useGetDocumentTypes/types";

interface Props {
  contractLandlordData: ContractLandlordProps;
  documentTypes: DocumentTypeProps[];
  propertyId: number;
}

const LandlordData = ({ contractLandlordData, documentTypes, propertyId }: Props) => {
  const classes = useLandlordDataStyles();
  const [editMode, setEditMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const data = contractLandlordData;
  const docTypeLabel = documentTypes.find(({ value }) => value === data.documentType)?.label;

  const methods = useForm({ defaultValues: data });

  const { mutate, submitting, submittingError } = useSubmitLandlordInfo(propertyId, setEditMode);
  const onSubmit = (formData: ContractLandlordProps) => {
    mutate(formData);
  };

  if (submittingError) {
    enqueueSnackbar("Ha ocurrido un error actualizando los datos", {
      variant: "error",
    });
  }

  return (
    <CollapsibleContent
      classNames={{
        content: classes.landlordDataWrapper,
        root: classes.landlordDataRoot,
      }}
      title="Datos propietario"
      size="small">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <DataWrapper
              id="fullName"
              label="Nombre y apellido:"
              value={data.fullName}
              editMode={editMode}
            />
            <DataWrapper id="phone" label="Teléfono:" value={data.phone} editMode={editMode} />
            <DataWrapper id="document" label="RUT:" value={data.document} editMode={editMode} />
            <DataWrapper
              id="email"
              label="Correo electrónico:"
              value={data.email}
              editMode={editMode}
              type="email"
            />
            <DataWrapper
              id="documentType"
              label="Tipo de documento:"
              value={docTypeLabel}
              editMode={editMode}
              type="number"
              disabled
            />
            <DataWrapper
              id="address"
              label="Dirección actual:"
              value={data.address}
              editMode={editMode}
            />
          </Grid>
          {editMode && (
            <div className={classes.formButtons}>
              <Button variant="tertiary" size="md" onClick={() => setEditMode(false)}>
                Cancelar
              </Button>
              <Button variant="primary" size="md" type="submit" isLoading={submitting}>
                Guardar
              </Button>
            </div>
          )}
          {!editMode && (
            <LightButton
              variant="primary"
              size="md"
              onClick={() => setEditMode(true)}
              className={classes.lightButton}>
              Editar información
            </LightButton>
          )}
        </form>
      </FormProvider>
    </CollapsibleContent>
  );
};

export default LandlordData;
