import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parserEvaluationInformation } from "./utils/parsers";

interface Props {
  country: Locale;
  memberId: string;
}

const useEvaluateForm = ({ country, memberId }: Props) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async (formData: MemberInformation) => {
    const authToken = await getToken();
    const parsedData = parserEvaluationInformation(formData);
    await service.editPersonInformation(parsedData, memberId, country, authToken);
  });

  useEffect(() => {
    if (query.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      alert.success({
        message: "El formulario se ha sido enviado correctamente",
      });
    }
  }, [query.isSuccess, queryClient]);

  useEffect(() => {
    if (query.isError) {
      alert.error({
        message: "Error al enviar el formulario",
      });
    }
  }, [query.isError]);

  return {
    ...query,
  };
};

export default useEvaluateForm;
