import { makeStyles, Theme } from "@material-ui/core";

const useFilterModalStyles = makeStyles((theme: Theme) => ({
  gridRoot: {
    minWidth: 624,
  },
  boxRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(1, 4),
  },
  dialogSubtitle: {
    marginBottom: theme.spacing(3),
  },
  linkButton: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 700,
    textDecoration: "underline",
    cursor: "pointer",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    alignItems: "center",
  },
}));

export default useFilterModalStyles;
