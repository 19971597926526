import { SnackbarProvider } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { Dialog } from "@material-ui/core";

import ScheduleTag from "./components/ScheduleTag";

import { EventProvider } from "context/eventContext/eventContext";
import NewEventForm from "./NewEventForm";
import useStyles from "./NewEventFormStyles";

interface Props {
  appraiserId: string;
  beginDate: string;
  endDate?: string;
  type?: string;
  propId?: number;
  email?: string;
  eventID?: number;
  comment?: string;
  setShow?: Dispatch<SetStateAction<boolean>>;
  country?: string;
  status?: string;
  address?: string;
}

function CreateEventForm({
  appraiserId,
  beginDate = "",
  endDate = "",
  type = "Block",
  propId = undefined,
  email = "",
  eventID = undefined,
  comment = "",
  setShow = null,
  country = null,
  status = "",
  address = null,
}: Props) {
  const classes = useStyles();

  return (
    <Dialog
      open
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}>
      <ScheduleTag setShow={setShow} />
      <SnackbarProvider>
        <EventProvider
          initialState={{
            beginDate,
            endDate,
            cancelRescheduleModal: { isOpen: false },
            cancelReasons: [],
            configModal: { isOpen: false },
            user: null,
            scheduled: false,
            availability: null,
            events: [],
            loading: false,
            eventType: type,
            appraiserId,
            appraisers: [],
            documentTypes: [],
            appraiserEmail: email,
            propertyId: propId,
            userType: "old",
          }}>
          <NewEventForm
            appraiserId={appraiserId}
            type={type}
            propId={propId}
            email={email}
            eventID={eventID}
          />
        </EventProvider>
      </SnackbarProvider>
    </Dialog>
  );
}

export default CreateEventForm;
