import { useCallback, useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return {
    open,
    handleOpenModal,
  };
};

export default useModal;
