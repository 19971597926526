import { ContractProps } from "../hooks/useGetContractInfo/types";
import { PropertyProps } from "../hooks/useGetPropertyInfo/types";
import { ContractLandlordProps, ContractPropertyProps } from "./types";

export const parseContractProperty = (
  propertyData?: PropertyProps,
  contractData?: ContractProps
): ContractPropertyProps => ({
  commonExpenses: propertyData?.commonExpenses,
  commune: propertyData?.commune,
  currencyCode: propertyData?.currencyCode,
  detailsId: propertyData?.detailsId,
  flatNumber: propertyData?.flatNumber,
  id: propertyData?.id,
  rentValue: propertyData?.rentValue,
  parkingNumber: propertyData?.parkingNumber,
  street: propertyData?.street,
  streetNumber: propertyData?.streetNumber,
  warehouseNumber: propertyData?.warehouseNumber,
  inhabitantsAmount: contractData?.inhabitantsAmount,
});

export const parseContractLandlord = (
  propertyData?: PropertyProps,
  contractData?: ContractProps
): ContractLandlordProps => ({
  fullName: contractData?.owner?.name || propertyData?.user.fullName || "",
  document: contractData?.owner?.rut || propertyData?.user.document || "",
  documentType: contractData?.owner?.documentType || propertyData?.user?.documentType,
  phone: contractData?.owner?.phone || propertyData?.user.phone || "",
  email: contractData?.owner?.email || propertyData?.user.email || "",
  address: contractData?.owner?.address || propertyData?.user.address || "",
});
