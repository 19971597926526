import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { add } from "date-fns";

import { Grid, InputAdornment } from "@material-ui/core";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import {
  ControlledList,
  ControlledNumber,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import { Locale } from "models/Countries";

import useFormStyles from "./FormStyles";
import { OPTION_LIST } from "./utils";

interface Props {
  type: "lessee" | "lessor";
  country: Locale;
}

function Form({ type, country }: Props) {
  const { setValue } = useFormContext();
  const classes = useFormStyles();

  useEffect(() => {
    setValue(`${type}Date`, type === "lessee" ? new Date() : add(new Date(), { days: 1 }));
  }, [type]);

  return (
    <>
      <Grid item xs={12}>
        <ControlledList
          label="Motivo"
          id={`${type}Description`}
          defaultValue=""
          disableUnderline
          options={OPTION_LIST.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          classes={{
            select: classes.select,
          }}
          disabled={type === "lessor"}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledNumber
          label="Monto"
          id={`${type}Amount`}
          defaultValue=""
          textFieldProps={{
            InputProps: {
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            },
            type: "number",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledKeyboardDatePicker
          id={`${type}Date`}
          label="Fecha"
          defaultValue={add(new Date(), { days: 1 })}
          dataPickerProps={{
            format: "dd/MM/yyyy",
            minDate: type === "lessee" ? new Date() : add(new Date(), { days: 1 }),
          }}
        />
      </Grid>
      {country === "cl" && type === "lessee" && (
        <Grid item xs={12}>
          <ControlledSelection
            id={`${type}Currency`}
            label="Tipo de moneda"
            defaultValue="CLP"
            options={[
              { value: "CLP", label: "Pesos" },
              { value: "UF", label: "UF" },
            ]}
            classes={{
              label: classes.selectionLabel,
              container: classes.selectionContainer,
            }}
            acceptNull={false}
          />
        </Grid>
      )}
    </>
  );
}

export default Form;
