import { useState } from "react";
import { SnackbarProvider } from "notistack";
import { Link } from "react-router-dom";
import moment from "moment";

import Grid, { GridSize } from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Card from "@material-ui/core/Card";
import ModalDialog from "@houm-com/ui/ModalDialog";
import currency from "@houm-com/formats/currency";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import PhotoPlaceholder from "assets/images/vacant_image.png";

import usePropertyCardStyles from "./PropertyCardStyles";
import { renderAddress } from "utils";
import { Photos, PropertyData } from "models/Property";
import { ContractModel } from "models/Contract";
import PropertyForm from "./PropertyForm";
import { checkStatus } from "../utils";

interface Props {
  property: PropertyData;
  contract: ContractModel;
}

interface RenderData {
  size: GridSize;
  label: string;
  text: string | number | React.ReactNode;
}

function PropertyCard({ property, contract }: Props) {
  const [openEditPropertyModal, setOpenEditPropertyModal] = useState<boolean>(false);

  const classes = usePropertyCardStyles();

  const propertyPhotos = (uid: string, photos: Photos[]) => {
    if (photos.length > 0) {
      return `https://s3.amazonaws.com/properties-media/thumbnail/${uid}${photos[0].filename}`;
    } else {
      return PhotoPlaceholder;
    }
  };

  const renderData: RenderData[] = [
    {
      size: 3,
      label: "Id Propiedad",
      text: (
        <Link
          className={classes.linkColor}
          to={`/admin/properties/${property?.uid}`}
          target="_blank">
          {property?.id}
        </Link>
      ),
    },
    {
      size: 3,
      label: "Fecha publicación",
      text: property?.last_publication_date
        ? moment(property?.last_publication_date).format("DD-MM-yyyy")
        : "-",
    },
    {
      size: 6,
      label: "Dirección",
      text: renderAddress(property),
    },
    {
      size: 3,
      label: "Houmechecker",
      text: property?.homechecker?.name ?? "-",
    },
    {
      size: 3,
      label: "Ejecutivo propietario",
      text: `${property?.houm_owner?.name} ${property?.houm_owner?.last_name ?? ""}`,
    },
    {
      size: 3,
      label: "Canon de arriendo",
      text: property?.property_details[0]?.valor
        ? currency(
            property?.property_details[0]?.valor,
            property?.property_details[0]?.currency_code,
            {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }
          )
        : "",
    },
    {
      size: 3,
      label: "Pueden vivir máximo",
      text:
        contract && contract?.max_people_quantity
          ? `${contract?.max_people_quantity} personas`
          : "-",
    },
    {
      size: 3,
      label: "¿Acepta mascotas?",
      text: property?.property_details[0]?.mascotas ? "Si" : "No",
    },
    {
      size: 3,
      label: "Número/s de Estacionamientos",
      text: contract?.parking_number ? contract?.parking_number : "",
    },
    {
      size: 3,
      label: "Número/s de Bodega",
      text: contract?.warehouse_number ? contract?.warehouse_number : "",
    },
  ];

  const handleCloseModal = () => setOpenEditPropertyModal(false);

  return (
    <>
      <CollapseCard
        title="Datos propiedad"
        editData={
          !checkStatus(
            [
              "Generar boton de pago",
              "Desistido",
              "Rechazado",
              "Boton de pago enviado",
              "Pagado",
              "Finalizado",
            ],
            contract.status
          )
        }
        onEdit={() => setOpenEditPropertyModal(true)}>
        <Card className={classes.root}>
          <Hidden smDown>
            <CardMedia
              className={classes.cover}
              image={propertyPhotos(property.uid, property.photos)}
            />
          </Hidden>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-end">
                {renderData.map((item: RenderData) => (
                  <Grid item xs={5} lg={item.size} key={item.label}>
                    <Typography component="h6" className={classes.textLabel}>
                      {item.label}
                    </Typography>
                    <Typography component="h6" className={classes.text}>
                      {item.text}
                    </Typography>
                    <Divider className={classes.divider} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </div>
        </Card>
      </CollapseCard>
      <ModalDialog
        isOpen={openEditPropertyModal}
        onClose={handleCloseModal}
        title="Datos propiedad"
        subtitle="Editar información">
        <SnackbarProvider>
          <PropertyForm
            property={property}
            contract={contract}
            onSubmitProperty={handleCloseModal}
          />
        </SnackbarProvider>
      </ModalDialog>
    </>
  );
}

export default PropertyCard;
