import { ReactNode } from "react";

import { Dialog, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";

import useStyles from "./BaseModalStyles";

interface Props {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  children: ReactNode;
}

const BaseModal = ({ open, onClose, showCloseButton = false, children }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}>
      {showCloseButton && (
        <div className={classes.closeButtoContainer}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <GridCloseIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.container}>{children}</div>
    </Dialog>
  );
};
export default BaseModal;
