import { AxiosResponse } from "axios";

import { CountryUpperCase } from "models/Countries";
import { PolygonAllResponse } from "./types";
import { createHttp } from "../helper";

export default {
  getMacrozones(
    token: string,
    country: CountryUpperCase,
    cityId?: number
  ): Promise<AxiosResponse<PolygonAllResponse>> {
    return createHttp(token).get("/schedules/v2/macrozone-polygon-all", {
      params: {
        country,
        city_id: cityId,
      },
    });
  },
};
