import { Box, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";
import { AvailableCompanies, ClientInfo } from "../../../utils/types";

import useCommonExpensesForm from "../../hooks/useCommonExpensesForm";
import { GGCCInfo } from "../../utils/types";
import CompanyForm from "../forms/CompanyForm";
import ComunidadFelizCompanyForm from "../forms/ComunidadFelizCompanyForm";
import KastorCompanyForm from "../forms/KastorCompanyForm";
import OtherCompanyForm from "../forms/OtherCompanyForm";
import useCommonExpensesModalStyles from "./CommonExpensesModalStyles";

interface Props {
  country: Locale;
  company?: AvailableCompanies;
  data?: ClientInfo | GGCCInfo;
  handleClose: () => void;
  open: boolean;
  propertyId: number;
}

const CommonExpensesModal = ({
  country,
  company: propsCompany,
  propertyId,
  open,
  handleClose,
  data,
}: Props) => {
  const classes = useCommonExpensesModalStyles();
  const { company, setCompany, isLoading, companies } = useCommonExpensesForm(
    country,
    propsCompany
  );

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <Box className={classes.commonExpensesModalRoot}>
        <Typography component="h1" className={classes.title}>
          Información de gasto común
        </Typography>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <CompanyForm
              availableCompanies={companies}
              company={company}
              setCompany={setCompany}
              disabled={!!propsCompany}
            />
            {company === "other" && (
              <OtherCompanyForm
                propertyId={propertyId}
                handleClose={handleClose}
                previousData={data as GGCCInfo}
              />
            )}
            {company === "kastor" && (
              <KastorCompanyForm
                propertyId={propertyId}
                handleClose={handleClose}
                previousData={data as ClientInfo}
              />
            )}
            {company === "comunidad_feliz" && (
              <ComunidadFelizCompanyForm
                propertyId={propertyId}
                handleClose={handleClose}
                previousData={data as ClientInfo}
              />
            )}
          </>
        )}
      </Box>
    </DSModal>
  );
};

export default CommonExpensesModal;
