import { BaseOption } from "@houm-com/ui/FieldSelect/FieldSelect";

import { IoCloseOutline } from "@houm-com/ui-icons";
import IconButton from "@houm-com/ui/IconButton";

import { IDebtCollectionFilters } from "hooks/useGetDebtCollections/utils/types";
import classes from "./FiltersPreviewStyles";
import FilterFormType from "../Filters/utils/types";

interface Props {
  filters: IDebtCollectionFilters;
  filterOptions: { [key in keyof Partial<IDebtCollectionFilters>]: BaseOption[] };
  labels: { [key in keyof Partial<IDebtCollectionFilters>]: string };
  onRemoveFilters: (filters: IDebtCollectionFilters, key: keyof FilterFormType) => void;
}

const FiltersPreview = ({ filters, onRemoveFilters, filterOptions, labels }: Props) => {
  const handleRemoveFilter = (key: keyof FilterFormType) => {
    onRemoveFilters({ ...filters, [key]: null }, key);
  };
  return (
    <div className={classes.container}>
      {Object.keys(filters)
        .filter((key) => Object.keys(labels).includes(key) && filters[key])
        .map((key) => (
          <IconButton
            key={key}
            icon={<IoCloseOutline />}
            type="button"
            position="end"
            variant="tertiary"
            size="sm"
            onClick={() => handleRemoveFilter(key as keyof FilterFormType)}>
            {labels[key]}:{" "}
            {filterOptions[key].find((option: BaseOption) => option.value === filters[key])?.label}
          </IconButton>
        ))}
    </div>
  );
};

export default FiltersPreview;
