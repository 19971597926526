import { Theme, makeStyles } from "@material-ui/core/styles";

const usePlanCardStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1.4),
  },
  text: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  buttonContainer: {
    textAlign: "end",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: 50,
    margin: theme.spacing(3, 0),
  },
  gridContainer: {
    width: "100%",
  },
  selected: {
    color: "#239C00",
    backgroundColor: "#239C0010",
    borderColor: "#239C00",
  },
}));

export default usePlanCardStyles;
