import { Controller, useFormContext } from "react-hook-form";

import CustomSwitch, { HelperText } from "Components/Admin/UIComponents/CustomSwitch";

interface Props {
  id: string;
  label: string;
  helperText?: "" | HelperText;
  defaultValue?: boolean;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  switchProps?: Partial<React.ComponentProps<typeof CustomSwitch>>;
  labelClassName?: string;
  wrapperClassName?: string;
}

function ControlledSwitch({
  id,
  label,
  helperText = "",
  defaultValue,
  controllerProps,
  switchProps,
  labelClassName,
  wrapperClassName,
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CustomSwitch
          label={label}
          helperText={helperText}
          onChange={onChange}
          value={value}
          labelClassName={labelClassName}
          wrapperClassName={wrapperClassName}
          {...switchProps}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledSwitch;
