import * as yup from "yup";

const requiredText = "Campo obligatorio";

const validationSchema = yup.object({
  overall_qualification: yup.string().required(requiredText),
  overall_observation: yup.string().nullable(),
});

export default validationSchema;
