import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { setIsOpenErrorModal as setIsOpenErrorModalLead } from "context/demandLeadContext/lead/demandLeadActions";
import { setIsOpenErrorModal as setIsOpenErrorModalSchedule } from "context/demandLeadContext/schedule/demandScheduleActions";

function Success() {
  const {
    state: { isOpenErrorModal: isOpenErrorModalLead },
    dispatch,
  } = useDemandLead();
  const {
    state: { isOpenErrorModal: isopenErrorModalSchedule },
    dispatch: dispatchSchedule,
  } = useDemandSchedule();

  const handleClose = () => {
    dispatch(setIsOpenErrorModalLead(false));
    dispatchSchedule(setIsOpenErrorModalSchedule(false));
  };

  return (
    <CustomCenteredModal
      open={isOpenErrorModalLead || isopenErrorModalSchedule}
      onClose={handleClose}
      title="¡No se han podido guardar los datos!"
      subtitle="Por favor comuníquese con soporte"
      showCloseButton={true}
      withLogo
    />
  );
}
export default Success;
