import { ReactNode } from "react";

import { Typography } from "@material-ui/core";
import useUserItemStyles from "./UserItemStyles";

interface Props {
  variant: "vertical" | "horizontal";
  title: string;
  content?: string;
  children?: ReactNode;
}

function UserItem({ variant, title, content = "", children = null }: Props) {
  const classes = useUserItemStyles();
  return (
    <div className={classes[variant]}>
      <Typography className={classes.title}>{title}</Typography>
      {content && <Typography className={classes.content}>{content}</Typography>}
      {children}
    </div>
  );
}

export default UserItem;
