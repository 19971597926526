import {
  State,
  Action,
  SET_CURRENT_COUNTRY,
  SET_EXTERNAL_PORTAL_PUBLICATIONS,
  SET_TOTAL_QUOTA_DATA,
  SET_TOTAL_QUOTA_FILTERS,
  SET_QUOTA_BY_MACROZONE_DATA,
  SET_QUOTA_BY_MACROZONE_FILTERS,
  SET_PLAN_EDITION_DATA,
  SET_PLAN_EDITION_FILTERS,
  REFETCH_PLAN_EDITION_DATA,
  SET_PLAN_EDITION_STATES,
  RESET_PLAN_EDITION_FILTERS,
  RESET_CURRENT_PLAN_EDITION_ROW,
} from "./integrationTypes";

export const initialState: State = {
  country: "chile",
  portalPublications: {
    data: [],
    loading: false,
    count: null,
    refetch: false,
  },
  totalQuota: {
    data: [],
    loading: false,
    count: null,
    refetch: false,
  },
  quotaByMacrozone: {
    data: [],
    loading: false,
    count: null,
    refetch: false,
  },
  planEdition: {
    data: [],
    loading: false,
    count: null,
    refetch: false,
  },
  totalQuotaFilters: {
    businessType: "",
    portal: null,
    portalPlan: null,
  },
  quotaByMacrozoneFilters: {
    businessType: "",
    portal: null,
    portalPlan: null,
    city: null,
    macrozone: null,
  },
  planEditionFilters: {
    businessType: "",
    portal: null,
    portalPlan: null,
  },
  planEditionStates: {
    isActiveSearch: false,
    isEditing: false,
    isUpdating: false,
    quotasOnEdition: [],
    quotasWithError: [],
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_CURRENT_COUNTRY: {
      return {
        ...state,
        country: action.country,
      };
    }
    case SET_EXTERNAL_PORTAL_PUBLICATIONS: {
      return {
        ...state,
        portalPublications: {
          ...state.portalPublications,
          ...action.externalPortalPublicationsInfo,
        },
      };
    }
    case SET_TOTAL_QUOTA_DATA: {
      return {
        ...state,
        totalQuota: {
          ...state.totalQuota,
          ...action.totalQuotaData,
        },
      };
    }
    case SET_TOTAL_QUOTA_FILTERS: {
      return {
        ...state,
        totalQuotaFilters: {
          ...state.totalQuotaFilters,
          ...action.totalQuotaFilters,
        },
      };
    }
    case SET_QUOTA_BY_MACROZONE_DATA: {
      return {
        ...state,
        quotaByMacrozone: {
          ...state.quotaByMacrozone,
          ...action.quotaByMacrozoneData,
        },
      };
    }
    case SET_QUOTA_BY_MACROZONE_FILTERS: {
      return {
        ...state,
        quotaByMacrozoneFilters: {
          ...state.quotaByMacrozoneFilters,
          ...action.quotaByMacrozoneFilters,
        },
      };
    }
    case SET_PLAN_EDITION_DATA: {
      return {
        ...state,
        planEdition: {
          ...state.planEdition,
          ...action.planEditionData,
        },
      };
    }
    case SET_PLAN_EDITION_FILTERS: {
      return {
        ...state,
        planEditionFilters: {
          ...state.planEditionFilters,
          ...action.planEditionFilters,
        },
      };
    }
    case RESET_PLAN_EDITION_FILTERS: {
      return {
        ...state,
        planEditionFilters: {
          businessType: "",
          portal: null,
          portalPlan: null,
        },
      };
    }
    case REFETCH_PLAN_EDITION_DATA: {
      return {
        ...state,
        planEdition: {
          ...state.planEdition,
          refetch: !state.planEdition.refetch,
        },
      };
    }
    case SET_PLAN_EDITION_STATES: {
      return {
        ...state,
        planEditionStates: {
          ...state.planEditionStates,
          ...action.planEditionStates,
        },
      };
    }
    case RESET_CURRENT_PLAN_EDITION_ROW: {
      return {
        ...state,
        planEditionStates: {
          ...state.planEditionStates,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
