import { styled, Theme, Box } from "@material-ui/core";

export const Root = styled(Box)<Theme>(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const FeaturesContainer = styled(Box)<Theme>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(0, 2),
}));

export const IconContainer = styled(Box)<Theme>(({ theme }) => ({
  width: 90,
  height: 80,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#EFF2F3",
  borderRadius: theme.spacing(2),
  "& svg": {
    fontSize: "2rem",
  },
}));

export const MultipleItem = styled(Box)<Theme>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(1),
  },
}));
