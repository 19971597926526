import { useState } from "react";

import { Grid } from "@material-ui/core";

import EvaluationsList from "Components/Admin/administrator/applicants/components/EvaluationsList";
import ContractNoEvaluation from "Components/Admin/administrator/applicants/components/ContractNoEvaluation";
import LinkEvaluationsForm from "Components/Admin/administrator/applicants/components/LinkEvaluationsForm";
import CreateCodebtorModal from "Components/Admin/administrator/applicants/components/CreateCodebtorModal";
import DocumentsColombia from "Components/Admin/administrator/applicants/components/DocumentsColombia";
import PropertyCard from "Components/Admin/administrator/applicants/components/PropertyCard";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Codebtors from "Components/Admin/administrator/applicants/components/Codebtors";
import ApplicantBankAccount from "Components/Admin/UIComponents/ApplicantBankAccount";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Reservation from "Components/Admin/UIComponents/Reservation";
import User from "Components/Admin/UIComponents/User";
import { ApplicantModel } from "models/Applicant";
import useUserById from "hooks/useUserById";
import countryCode from "utils/countryCode";

import useEvaluationApplicant from "./hooks/useEvaluationApplicant";

interface Props {
  applicant: ApplicantModel;
}

const Applicant = ({ applicant }: Props) => {
  const { evaluationData, evaluationLoading } = useEvaluationApplicant();
  const { isLoadingUser, dataUser } = useUserById(applicant?.user_id);
  const [addCodebtorVisible, setAddCodebtorVisible] = useState(false);
  const country = countryCode(applicant?.property?.country);

  const handleModal = () => setAddCodebtorVisible((prev) => !prev);

  return (
    <Loading loading={isLoadingUser || evaluationLoading}>
      <>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <User
                actions={
                  <DesignSystemButton
                    label="Agregar Coarrendatario/Codeudor"
                    size="small"
                    variant="tertiaryB"
                    onClick={() => setAddCodebtorVisible(true)}
                  />
                }
                type="Arrendatario"
                user={dataUser}
              />
            </Grid>
            {applicant.codebtors.filter((item) => item.type !== "aval").length > 0 && (
              <Grid item xs={12}>
                <Codebtors
                  codebtors={applicant.codebtors.filter((item) => item.type !== "aval")}
                  title={applicant.property.country === "Colombia" ? "Coarrendatario" : "Codeudor"}
                />
              </Grid>
            )}
            {applicant.codebtors.filter((item) => item.type === "aval").length > 0 && (
              <Grid item xs={12}>
                <Codebtors
                  codebtors={applicant.codebtors.filter((item) => item.type === "aval")}
                  title={applicant.property.country === "Colombia" ? "Codeudor" : "Aval"}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ApplicantBankAccount
                userId={applicant.user_id}
                propertyId={applicant.property.id}
                country={country}
              />
            </Grid>
            <Grid item xs={12}>
              <Reservation
                applicantId={applicant.id}
                userId={applicant.user_id}
                propertyId={applicant?.property.id}
                country={country}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <PropertyCard property={applicant.property} />
            </Grid>
            <Grid item xs={12}>
              {evaluationData.length > 0 && <EvaluationsList evaluations={evaluationData} />}
              {evaluationData.length == 0 && applicant.property.country === "Colombia" && (
                <ContractNoEvaluation applicant={applicant} user={dataUser} />
              )}
            </Grid>
            <Grid item xs={12}>
              {applicant.property.country !== "Colombia" ? (
                <LinkEvaluationsForm applicant={applicant} />
              ) : (
                <DocumentsColombia uid={applicant.id} documents={applicant.documents} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateCodebtorModal
          country={countryCode(applicant.property.country)}
          handleModal={handleModal}
          openModal={addCodebtorVisible}
          applicantId={applicant?.id}
          propertyId={applicant?.property?.id}
        />
      </>
    </Loading>
  );
};

export default Applicant;
