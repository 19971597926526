import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import currency from "@houm-com/formats/currency";
import LightButton from "@houm-com/ui/LightButton";
import Button from "@houm-com/ui/Button";
import { Grid } from "@material-ui/core";

import CollapsibleContent from "Components/Admin/UIComponents/CollapsibleContent";

import useSubmitProperty from "../../hooks/useSubmitProperty";
import { ContractPropertyProps } from "../../utils/types";
import DataWrapper from "../DataWrapper";
import usePropertyDataStyles from "./PropertyDataStyles";
import { parseFormData } from "./utils/parsers";

interface Props {
  contractPropertyData: ContractPropertyProps;
  propertyId: string;
}

const PropertyData = ({ contractPropertyData, propertyId }: Props) => {
  const classes = usePropertyDataStyles();
  const [editMode, setEditMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, submitting, submittingError } = useSubmitProperty(propertyId, setEditMode);
  const data = contractPropertyData;

  const methods = useForm({ defaultValues: data });

  const onSubmit = (formData: ContractPropertyProps) => {
    formData.id = data.id;
    formData.detailsId = data.detailsId;
    mutate(parseFormData(formData));
  };

  if (submittingError) {
    enqueueSnackbar("Ha ocurrido un error actualizando los datos", {
      variant: "error",
    });
  }

  return (
    <CollapsibleContent
      classNames={{ content: classes.propertyDataWrapper }}
      title="Datos de la propiedad"
      size="small">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container>
            <DataWrapper id="street" label="Calle:" value={data.street} editMode={editMode} />
            <DataWrapper
              id="parkingNumber"
              label="Número de estacionamiento:"
              value={data.parkingNumber}
              editMode={editMode}
              type="number"
            />
            <DataWrapper
              id="streetNumber"
              label="Número:"
              value={data.streetNumber}
              editMode={editMode}
              type="number"
            />
            <DataWrapper
              formatter={(value) => currency(Number(value), data.currencyCode)}
              id="commonExpenses"
              label="Gastos comunes:"
              value={data.commonExpenses}
              editMode={editMode}
              type="number"
            />
            <DataWrapper id="commune" label="Comuna:" value={data.commune} editMode={editMode} />
            <DataWrapper
              id="warehouseNumber"
              label="Número de bodega(s):"
              value={data.warehouseNumber}
              editMode={editMode}
              type="number"
            />
            <DataWrapper
              formatter={(value) => currency(Number(value), data.currencyCode)}
              id="rentValue"
              label="Valor de arriendo:"
              value={data.rentValue}
              editMode={editMode}
              type="number"
            />
            <DataWrapper
              id="inhabitantsAmount"
              label="Número de personas que pueden habitar:"
              value={data.inhabitantsAmount}
              editMode={editMode}
              type="number"
            />
            <DataWrapper
              id="flatNumber"
              label="Número de departamento:"
              value={data.flatNumber}
              editMode={editMode}
            />
          </Grid>
          {editMode && (
            <div className={classes.formButtons}>
              <Button variant="tertiary" size="md" onClick={() => setEditMode(false)}>
                Cancelar
              </Button>
              <Button variant="primary" size="md" type="submit" isLoading={submitting}>
                Guardar
              </Button>
            </div>
          )}
          {!editMode && (
            <LightButton
              variant="primary"
              size="md"
              onClick={() => setEditMode(true)}
              className={classes.lightButton}>
              Editar información
            </LightButton>
          )}
        </form>
      </FormProvider>
    </CollapsibleContent>
  );
};

export default PropertyData;
