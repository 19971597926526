export const reportTypeTranslations = {
  request: "Solicitud de arreglos",
  damage_report: "Reporte de daños",
};

export const visitTypesTranslations = {
  Keys: "Llaves",
  Visit: "Visita renta",
  Advisory: "Asesoria",
  Photo: "Fotos renta",
  SalesPhoto: "Fotos venta",
  SalesVisit: "Visita venta",
  ManualVisit: "Visita manual",
  Block: "Bloqueo",
};
