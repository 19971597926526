import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import { EMPLOYMENT_CONTRACT_TYPES, WORKER_TYPE } from "../../utils/constants";

const EmploymentForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MemberInformation>();

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-h20 font-bold mt-5 mb-2">Trabajo</h2>
      <FieldInput
        id="occupation"
        placeholder="Ingresar ocupación"
        label="Ocupación"
        errorMessage={errors.occupation?.message}
        required
        form="novalidateform"
        {...register("occupation")}
      />
      <FieldInput
        id="employer"
        placeholder="Ingresar empleador"
        label="Empleador"
        errorMessage={errors.employer?.message}
        required
        form="novalidateform"
        {...register("employer")}
      />
      <FieldSelect
        label="Tipo de contrato"
        id="employmentContractType"
        placeholder="Selecciona un tipo de contrato"
        options={EMPLOYMENT_CONTRACT_TYPES}
        form="novalidateform"
        errorMessage={errors.employmentContractType?.message}
        required
        {...register("employmentContractType")}
      />
      <FieldSelect
        label="Tipo de trabajador"
        id="incomeSourceType"
        placeholder="Selecciona un tipo de trabajador"
        options={WORKER_TYPE}
        form="novalidateform"
        errorMessage={errors.incomeSourceType?.message}
        required
        {...register("incomeSourceType")}
      />
    </div>
  );
};

export default EmploymentForm;
