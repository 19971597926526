import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { add } from "date-fns";

import ModalDialog from "@houm-com/ui/ModalDialog";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { isWeekendDay } from "utils/datetime";
import { Locale } from "models/Countries";

import { editRefundDateSchema } from "./utils/validationSchema";
import useEditRefundDate from "../../hooks/useEditRefundDate";

interface Props {
  openModal: boolean;
  handleModal: () => void;
  country: Locale;
  agreedDate: string;
  movementId: string;
}

interface FormData {
  newDate: string;
}

const EditRefundDate = ({ openModal, handleModal, country, agreedDate, movementId }: Props) => {
  const { mutate, isLoading } = useEditRefundDate(country, movementId, handleModal);
  const methods = useForm<FormData>({
    resolver: yupResolver(editRefundDateSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      newDate: agreedDate,
    },
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => mutate(dataForm);

  return (
    <ModalDialog
      title="Editar fecha de reembolso"
      isOpen={openModal}
      hasCloseBtn
      size="lg"
      onClose={handleModal}>
      <FormProvider {...methods}>
        <form key="propertyScheduleForm">
          <ModalDialog.Content>
            <ControlledKeyboardDatePicker
              id="newDate"
              label="Fecha del reembolso"
              dataPickerProps={{
                format: "dd/MM/yyyy",
                minDate: add(new Date(), { days: 1 }),
                shouldDisableDate: (e) => isWeekendDay(e),
              }}
            />
          </ModalDialog.Content>
          <ModalDialog.Actions
            onSubmit={methods.handleSubmit(onSubmit)}
            buttonsPosition="center"
            submitButtonLabel="Editar"
            submitButtonProps={{
              isLoading,
            }}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default EditRefundDate;
