import { useMutation } from "react-query";

import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

interface IHoumOwner {
  homecheckerId: string;
}

const useUpdateChecker = (propertyUid: string) => {
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess, isError } = useMutation(async (formData: IHoumOwner) => {
    const authToken = await getToken();
    const res = await propertyService.setHomecheckerV2(
      formData.homecheckerId,
      propertyUid,
      authToken
    );
    return res;
  });

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useUpdateChecker;
