import PlanEdition from "./PlanEdition";
import { IntegrationProvider } from "context/integrationContext/integrationContext";
import { TableProvider } from "context/tableContext/tableContext";

function PortalQuotasIndex() {
  return (
    <IntegrationProvider>
      <TableProvider>
        <PlanEdition />
      </TableProvider>
    </IntegrationProvider>
  );
}

export default PortalQuotasIndex;
