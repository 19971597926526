import { ContractPersonType, ICodebtorData, IPersonData } from "./types";

import { format } from "rut.js";
import { Locale } from "models/Countries";

export const typeLabel = {
  tenant: "arrendatario",
  owner: "propietario",
  codebtor: "codeudor",
  lessee_legal_representative: "representante legal del arrendatario",
  lessor_legal_representative: "representante legal del propietario",
};

export const parseCodebtorForm = (data: {
  codebtorForm: IPersonData;
  propertyId: number;
  type: ContractPersonType;
  country: Locale;
  personId: number;
}): ICodebtorData => {
  const formData: ICodebtorData = {
    email: data.codebtorForm.email,
    phone: data.codebtorForm.phone,
    name: data.codebtorForm.name,
    address: data.codebtorForm.address,
    civil_status: data.codebtorForm.civil_status,
    property_id: data.propertyId,
    role: data.type,
    rut: data.country === "cl" ? format(data.codebtorForm.document) : data.codebtorForm.document,
    document:
      data.country === "cl" ? format(data.codebtorForm.document) : data.codebtorForm.document,
    document_type: parseInt(data.codebtorForm.document_type),
  };
  if (data.type === "owner")
    formData.tributary_type = data.codebtorForm.tributaryType === "yes" ? "corporative" : "natural";
  if (data.type !== "owner") formData.id = data.personId;
  if (data.country === "mx") formData.rfc_number = data.codebtorForm.rfcNumber.toUpperCase();
  if (data.type === "tenant" || data.type === "codebtor") {
    formData.occupation = data.codebtorForm.occupation;
    formData.civil_status = data.codebtorForm.civil_status;
    formData.nationality = data.codebtorForm.nationality;
    formData.average_salary = data.codebtorForm.salaryAverage;
    formData.employer_name = data.codebtorForm.employer;
  }
  return formData;
};
