import { makeStyles, Theme } from "@material-ui/core/styles";

const useUnitsUploadFormStyles = makeStyles((theme: Theme) => ({
  form: {},
  buttons: {
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
  },
}));

export default useUnitsUploadFormStyles;
