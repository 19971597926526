import { useState } from "react";

import Collapse from "@material-ui/core/Collapse";
import { IoChevronUpOutline, IoChevronDownOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import Button from "@houm-com/ui/Button";

import { EvaluationComment, EvaluationStatus } from "domains/evaluations/models/Evaluations";
import ProtectComponent from "apps/evaluator-admin/components/ProtectComponent";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { Permissions as P } from "domains/evaluations/utils/constants";
import useModal from "hooks/useModal";

import { getSortedComments } from "../../utils/utils";
import CommentModal from "../CommentModal";
import { classes } from "./CommentsStyles";
import Comment from "../Comment";

interface Props {
  comments: EvaluationComment[];
  evaluationMemberId: string;
  evaluationStatus: EvaluationStatus;
}

const Comments = ({ comments, evaluationMemberId, evaluationStatus }: Props) => {
  const { handleOpenModal, open } = useModal();
  const [expanded, setExpanded] = useState(false);
  const hasAComment = comments.length > 0;
  const hasMoreComments = comments.length > 1;
  const sortedComments = getSortedComments(comments);

  return (
    <>
      <AdminCard title="Comentarios" className={classes.commentsCardContainer} withSpace={false}>
        {!hasAComment && <p className={classes.noCommentsText}>No hay comentarios</p>}
        <div className={classes.commentsInfoContainer}>
          {hasAComment && (
            <div>
              <Comment comment={sortedComments[0]} />
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <div className={classes.commentsList}>
                  {sortedComments.slice(1).map((comment) => (
                    <Comment key={comment.id} comment={comment} />
                  ))}
                </div>
              </Collapse>
              {hasMoreComments && (
                <LightButton
                  icon={expanded ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                  size="sm"
                  variant="primary"
                  onClick={() => setExpanded((prev) => !prev)}>
                  {expanded ? "Mostrar menos" : "Mostrar todos"}
                </LightButton>
              )}
            </div>
          )}
          <ProtectComponent
            evaluationStatus={evaluationStatus}
            requiredPermissions={[P.CREATE_COMMENT]}>
            <div className={classes.buttonContainer}>
              <Button size="sm" variant="tertiary" onClick={handleOpenModal}>
                Escribir comentario
              </Button>
            </div>
          </ProtectComponent>
        </div>
      </AdminCard>
      <CommentModal
        evaluationMemberId={evaluationMemberId}
        handleOpenModal={handleOpenModal}
        open={open}
      />
    </>
  );
};

export default Comments;
