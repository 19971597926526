import { withStyles } from "@material-ui/core/styles";
import { GridToolbarContainer, GridToolbarExport } from "@material-ui/x-grid";
import useStyles from "./ToolbarStyles";

const ENABLE_EXPORT = false;
const Export = withStyles({})(GridToolbarExport);

function Toolbar() {
  const classes = useStyles();
  return (
    <GridToolbarContainer>
      <div className={classes.container}>{ENABLE_EXPORT && <Export />}</div>
    </GridToolbarContainer>
  );
}

export default Toolbar;
