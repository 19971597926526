import { Alert, AlertTitle } from "@material-ui/lab";

function IncompleteInformation() {
  return (
    <Alert severity="warning">
      <AlertTitle>Advertencia</AlertTitle>
      Para poder agendar una visita es necesario que el lead tenga un correo y un número de celular
      asignado
    </Alert>
  );
}
export default IncompleteInformation;
