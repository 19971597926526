import moment from "moment";

import { Country } from "models/Countries";
import { Dispatch, Action, SET_LOADING, SET_RESERVATIONS, SET_COUNTRY } from "./reservationsTypes";
import newPaymentService from "services/newPaymentService";
import { countryCode } from "utils";

export const getReservations = async (
  country,
  pageSize,
  currentPage,
  dispatch: Dispatch,
  token: string
) => {
  dispatch({ type: SET_LOADING, loading: true });

  try {
    const response = await newPaymentService.reservationsIndex(
      countryCode(country),
      "paid",
      pageSize,
      currentPage,
      token
    );
    if (response) {
      const { movements, count } = response.data;
      const reservations = movements.map((reservation) => {
        const reconstructed_legacy_id = reservation.legacy_id
          ? [
              reservation.legacy_id.slice(0, 8),
              "-",
              reservation.legacy_id.slice(8, 12),
              "-",
              reservation.legacy_id.slice(12, 16),
              "-",
              reservation.legacy_id.slice(16, 20),
              "-",
              reservation.legacy_id.slice(20),
            ].join("")
          : null;
        const result = {
          id: reservation.movement_id,
          name: reservation.person_data?.full_name,
          property: reservation.property_id,
          amount: reservation.total_amount,
          paymentDate: moment(reservation.date).format("lll"),
          action: `/admin/applicants/${reconstructed_legacy_id}`,
          evaluator: reconstructed_legacy_id,
        };
        return result;
      });

      dispatch({ type: SET_RESERVATIONS, payload: { reservations, count } });
    }
  } catch (e) {
    dispatch({ type: SET_LOADING, loading: false });
    throw e;
  }
};

export const setCountry = (country: Country): Action => ({
  type: SET_COUNTRY,
  country,
});
