import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  backIcon: {
    fontSize: "1rem",
  },
  backText: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "none",
  },
}));

export default useStyles;
