// @ts-nocheck
import { GridColDef, GridColumns, esES, GridSortModel } from "@material-ui/x-grid";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { capitalizeString } from "utils";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  SetIsOpenAsignCtAgentModal,
  SetIsOpenAsignPropietaryModal,
  setIsOpenInfoModal,
  setIsOpenNewsModal,
  setScheduleId,
  setAppraiserId,
  setInfo,
  setNews,
} from "context/demandScheduledVisitsContext/demandScheduledActions";
import { getTimezones } from "utils/scheduleTimezone";
import CustomCell from "./Cells";
import useStyles from "./TableStyles";
import { Visit } from "../utils/parseVisits";

import { ReactComponent as Confirm } from "assets/images/Confirm.svg";
import { ReactComponent as Waiting } from "assets/images/Waiting.svg";
import { ReactComponent as Cancel } from "assets/images/Cancel.svg";
import { noCoordText, statusMap } from "./utils";

function Table() {
  const classes = useStyles();
  const {
    state: { visits, totalVisits, isLoading, deal, country },
    dispatch,
  } = useDemandScheduled();

  const { state: tableStates, dispatch: tableDispatch } = useTable();
  const { currentPage, pageSize, ordering } = tableStates;
  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    filterable: false,
    flex: 1,
    hideSortIcons: true,
    cellClassName: classes.centerCell,
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "id",
        headerName: "ID Visita",
        flex: 0.5,
        headerClassName: classes.firstHeaderCell,
        cellClassName: classes.leftCell,
        renderCell: ({ value, row }) => (
          <CustomCell row={row} direction="left">
            {value}
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "begin_date",
        headerName: "Visita",
        type: "date",
        flex: 0.6,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            <div className={classes.downValue}>
              {capitalizeString(
                moment(value.begin_date)
                  .tz(getTimezones(value.country, value.region))
                  .format("MMMM DD")
              )}
            </div>
            <div className={classes.downValue}>
              {moment(value.begin_date)
                .tz(getTimezones(value.country, value.region))
                .format("HH:mm")}
            </div>
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "commune",
        headerName: country === "Chile" ? "Comuna" : "Ciudad",
        flex: 0.775,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row} direction="right">
            {value}
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "prop_id",
        headerName: "Propiedad",
        flex: 0.64,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/admin/properties/${value.uid}`}
              className={classes.id_link}>
              <div>{value.id}</div>
            </Link>
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "ct_agent",
        headerName: "Agente CT",
        flex: 0.8,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            {value !== null ? (
              <span onClick={() => handleAsignAgent(row.id)}>
                {value?.name} {value?.last_name}
              </span>
            ) : (
              <span className={classes.asign} onClick={() => handleAsignAgent(row.id)}>
                Asignar
              </span>
            )}
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "coord",
        headerName: "Coordinación",
        flex: 0.8,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            {!value.coordination
              ? "No"
              : value.role === "property_broker"
              ? "B2B (Canje)"
              : "B2C (Particular)"}
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "houmer",
        headerName: `${deal === "SalesVisit" ? "Houm Advisor" : "Houmer"}`,
        flex: 1.2,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            <div className={classes.containerFlex}>
              {value.status && <div className={classes.icon}>{showIcons(value.status)}</div>}
              <div>
                <div className={classes.name}>
                  {value.name} {value.last_name}
                </div>
                <div className={classes.downValue}>{value.phone}</div>
              </div>
            </div>
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "contact",
        headerName: "Contacto de Coordinación",
        flex: 1.2,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            {deal === "keys" && (
              <div className={classes.containerFlex}>
                <div className={classes.clickIcon} onClick={() => handleConfirmationProp(row)}>
                  {showIcons(value.status)}
                </div>
                {value?.coordination || noCoordText}
              </div>
            )}
            {deal !== "keys" && value.coordination && (
              <div className={classes.containerFlex}>
                {value.status && (
                  <div className={classes.clickIcon} onClick={() => handleConfirmationProp(row)}>
                    {showIcons(value.status)}
                  </div>
                )}
                <div
                  className={classes.pointer}
                  onClick={() => handleDisplayInfo(value.description)}>
                  <div> {value.description}</div>
                </div>
              </div>
            )}
            {deal !== "keys" && !value.coordination && noCoordText}
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "status_visit",
        headerName: deal === "keys" ? "Estado Entrega" : "Estado visita",
        flex: 0.775,
        sortable: false,
        renderCell: ({ value, row }) => (
          <CustomCell row={row} direction="right">
            <div className={classes.containerFlex}>
              <div className={classes.icon}>{showIcons(value)}</div>
              <div>{statusMap[value]}</div>
            </div>
          </CustomCell>
        ),
      },
      {
        ...columnsConfig,
        field: "comment",
        headerName: "Novedades",
        flex: 0.68,
        sortable: false,
        headerClassName: classes.lastRenderedHeaderCell,
        cellClassName: classes.rightCell,
        renderCell: ({ value, row }) => (
          <CustomCell row={row}>
            <div className={classes.pointer} onClick={() => handleDisplayComment(row.id, value)}>
              {value || "Sin novedades"}
            </div>
          </CustomCell>
        ),
      },
    ],
    [country]
  );

  const showIcons = (value: string) => {
    switch (value) {
      case "Scheduled":
        return <Waiting />;
      case "Done":
        return <Confirm />;
      case "Confirmed":
        return <Confirm />;
      case "Waiting":
        return <Waiting />;
      case "Cancelled":
        return <Cancel />;
      default:
        return null;
    }
  };

  const handleSort = (model: GridSortModel) => {
    let newOrdering: string | null = null;
    if (model.length > 0) {
      newOrdering = `${model[0].sort === "asc" ? "" : "-"}${model[0].field}`;
    }
    if (newOrdering !== ordering) {
      tableDispatch(setSorting(model));
    }
  };

  const handleAsignAgent = (id: number) => {
    dispatch(setScheduleId(id));
    dispatch(SetIsOpenAsignCtAgentModal(true));
  };

  const handleConfirmationProp = (row: Visit) => {
    dispatch(setScheduleId(row.id));
    dispatch(setAppraiserId(row.houmer.id));
    dispatch(SetIsOpenAsignPropietaryModal(true));
  };

  const handleDisplayInfo = (value: string) => {
    dispatch(setIsOpenInfoModal(true));
    dispatch(setInfo(value));
  };

  const handleDisplayComment = (id: number, value: string) => {
    dispatch(setScheduleId(id));
    dispatch(setIsOpenNewsModal(true));
    dispatch(setNews(value));
  };

  return (
    <div className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        rowCount={totalVisits}
        columns={columns}
        rows={visits}
        loading={isLoading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => tableDispatch(setCurrentPage(params + 1))}
        onSortModelChange={handleSort}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={(params) => {
          tableDispatch(setPageSize(params));
          tableDispatch(setCurrentPage(1));
        }}
        localeText={esES.props.MuiDataGrid.localeText}
      />
    </div>
  );
}

export default Table;
