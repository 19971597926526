import { Button } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { useGetToken } from "hooks";

import { useEvent } from "context/eventContext/eventContext";
import { setCancelRescheduleModal, deleteSchedule } from "context/eventContext/eventActions";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import {
  ControlledAutocomplete,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";

import { titles, subtitle, buttonText } from "./CancelModalConstants";
import useCancelRescheduleModalStyles from "./CancelRescheduleModalStyles";

interface Props {
  eventId: number;
}

function CancelationProp({ eventId }: Props) {
  const classes = useCancelRescheduleModalStyles();
  const { watch, getValues } = useFormContext();
  const { getToken } = useGetToken();

  const {
    dispatch,
    state: { cancelRescheduleModal, cancelReasons },
  } = useEvent();

  const handleClose = () => {
    dispatch(setCancelRescheduleModal({ isOpen: false }));
  };

  const handleCancel = () => {
    const [comment, cancelReason] = getValues(["comment", "cancelReason.value"]);

    getToken().then((authToken) =>
      deleteSchedule(eventId, cancelReason, comment, dispatch, authToken)
    );
    handleClose();
  };

  return (
    <CustomCenteredModal
      open={cancelRescheduleModal.isOpen}
      title={titles[cancelRescheduleModal.type]}
      subtitle="">
      <div className={classes.container}>
        <div className={classes.autocompleteContainer}>
          <div className={classes.labelAutocomplete}>
            Elige el <b>{subtitle[cancelRescheduleModal.type]}</b>
          </div>
          <ControlledAutocomplete id="cancelReason" options={cancelReasons} label="" />
        </div>
        <div className={classes.openReasonContainer}>
          <ControlledTextField
            id="comment"
            label={<div className={classes.textAreaLabel}>Comentario</div>}
            textFieldProps={{
              multiline: true,
              minRows: 2,
              InputProps: {
                className: classes.textArea,
              },
              inputProps: {
                maxlength: 255,
              },
            }}
          />
        </div>
      </div>
      <div className={classes.buttonContainers}>
        <Button
          size="small"
          color="primary"
          className={classes.actionButton}
          onClick={handleClose}
          disableElevation>
          Cerrar
        </Button>
        <Button
          disableElevation
          size="small"
          variant="contained"
          color="primary"
          onClick={handleCancel}
          type={cancelRescheduleModal.type === "cancelation" ? "button" : "submit"}
          className={classes.actionButton}
          disabled={!watch("cancelReason")?.value}
          form="NewEventForm">
          {buttonText[cancelRescheduleModal.type]}
        </Button>
      </div>
    </CustomCenteredModal>
  );
}

export default CancelationProp;
