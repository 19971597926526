import { MouseEvent, useState } from "react";

import { alert } from "libs/alerts";
import useModal from "hooks/useModal";

const useHandleModals = (dataBankAccount) => {
  const { open: openRefund, handleOpenModal: handleOpenRefundModal } = useModal();
  const { open: openExchangeModal, handleOpenModal: handleExchangeModal } = useModal();
  const { open: openDetailsModal, handleOpenModal: handleDetailsModal } = useModal();
  const { open: openEditDate, handleOpenModal: handleEditDateModal } = useModal();
  const { open: openMarkPaidModal, handleOpenModal: handleMarkPaidModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openRefundModal = () => {
    handleClose();
    if (dataBankAccount) return handleOpenRefundModal();
    alert.warning({
      message: "No se puede relizar el rembolso",
      disclaimer: "El postulante debe tener asignada una cuenta bancaria",
    });
  };
  const openExchangeReservationModal = () => {
    handleClose();
    handleExchangeModal();
  };
  const openDetailsReservationModal = () => {
    handleClose();
    handleDetailsModal();
  };
  const openEditDateModal = () => {
    handleClose();
    handleEditDateModal();
  };

  return {
    openMenu: open,
    handleClick,
    openRefund,
    openRefundModal,
    openExchangeModal,
    openExchangeReservationModal,
    openDetailsModal,
    openDetailsReservationModal,
    openMarkPaidModal,
    handleMarkPaidModal,
    openEditDate,
    openEditDateModal,
    handleClose,
    anchorEl,
  };
};

export default useHandleModals;
