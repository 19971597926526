import { makeStyles } from "@material-ui/core";

const useTagsStyle = makeStyles((theme) => ({
  tag: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(5 / 8, 1),
    minWidth: 100,
    fontSize: "0.8125rem",
    borderRadius: 100,
    borderWidth: "1px",
    borderColor: theme.palette.grey["900"],
    border: "1px solid",
    justifyContent: "space-between",
    height: "fit-content",
  },
  button: {
    padding: 0,
  },
  icon: {
    width: "0.5em",
    height: "0.5em",
  },
}));
export default useTagsStyle;
