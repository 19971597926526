import { useEffect, useState, memo } from "react";

import whiteBackground from "assets/images/white-price-background.png";
import Marker from "Components/Admin/UIComponents/atoms/Marker";
import PopUpMap from "Components/Admin/UIComponents/atoms/PopUpMap";
import Pin from "Components/Admin/UIComponents/atoms/Pin";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { updateCurrentProperty } from "context/demandLeadContext/schedule/demandScheduleActions";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useGetToken } from "hooks";
import useMap from "hooks/useMap";

import { createOrUpdateSource, createLayers, loadImage, sourceNames } from "./SchedulerMapUtils";
import useSchedulerMapStyle from "./SchedulerMapStyle";

function SchedulerMap() {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [other, setOther] = useState(false);
  const [hoveredProperty, setHoveredProperty] = useState<any>(null);
  const { getToken } = useGetToken();
  const {
    state: { property, similarProperties },
    dispatch,
  } = useDemandSchedule();
  const {
    state: { viewedProperties },
  } = useDemandLead();

  const classes = useSchedulerMapStyle();
  const { mapContainer, map } = useMap(
    {
      lat: property?.property_details[0]?.latitud,
      lng: property?.property_details[0]?.longitud,
      style: "mapbox://styles/mapbox/light-v10",
    },
    []
  );

  useEffect(() => {
    map.current.on("load", () => {
      loadImage(map, whiteBackground, "price-background");
    });
    map.current.on("styledata", () => {
      createOrUpdateSource(map, similarProperties, property.id);
      createLayers(map);
      setMapLoaded(true);
    });

    map.current.on("mouseenter", `${sourceNames.similarProperty}-item`, () => {
      map.current.getCanvas().style.cursor = "pointer";
    });
    map.current.on("mouseleave", `${sourceNames.similarProperty}-item`, () => {
      map.current.getCanvas().style.cursor = "";
    });

    map.current.on("mouseenter", `${sourceNames.similarProperty}-cluster`, () => {
      map.current.getCanvas().style.cursor = "pointer";
    });
    map.current.on("mouseleave", `${sourceNames.similarProperty}-cluster`, () => {
      map.current.getCanvas().style.cursor = "";
    });
    map.current.on("mouseenter", `${sourceNames.similarProperty}-item`, handleHoverItem);

    map.current.on("click", `${sourceNames.similarProperty}-item`, handleClickItem);
    map.current.on("mouseleave", `${sourceNames.similarProperty}-item`, () =>
      setHoveredProperty(null)
    );

    //center on cluster click
    map.current.on("click", `${sourceNames.similarProperty}-cluster`, (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: [`${sourceNames.similarProperty}-cluster`],
      });
      const clusterId = features[0].properties.cluster_id;
      map.current
        .getSource(sourceNames.similarProperty)
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.current.easeTo({
            zoom,
            center: features[0].geometry.coordinates,
          });
        });
    });
  }, []);

  useEffect(() => {
    if (similarProperties.length > 0 && map.current?.isStyleLoaded())
      createOrUpdateSource(map, similarProperties, property.id);
  }, [similarProperties]);

  useEffect(() => {
    if (map.current && property)
      map.current.flyTo({
        center: [property.property_details[0].longitud, property.property_details[0].latitud],
        bearing: 0,
        essential: true,
        speed: 0.6, // make the flying slow
      });
  }, [property]);
  const handleClickVisitedProperty = (property) => {
    getToken().then((authToken) => {
      updateCurrentProperty(property.id, "sales", dispatch, authToken);
    });
  };

  const handleClickItem = (e) => {
    const query = map.current.queryRenderedFeatures(e.point, {
      layers: [`${sourceNames.similarProperty}-item`],
    })[0];
    getToken().then((authToken) => {
      updateCurrentProperty(query.properties.propertyId, "sales", dispatch, authToken);
    });
  };

  const handleHoverItem = (e) => {
    const { coordinates } = e.features[0].geometry;
    const property = e.features[0].properties;
    if (!property || property?.id !== hoveredProperty?.id) {
      setHoveredProperty({
        ...property,
        lat: coordinates[1],
        lng: coordinates[0],
      });
    }
  };
  return (
    <div className={classes.mapContainer}>
      <div className={classes.mapRoot}>
        <div
          className={classes["mapbox-map-wrapper"]}
          ref={(el) => {
            mapContainer.current = el;
          }}
        />
        {hoveredProperty && (
          <PopUpMap
            popUpClassName={classes.popup}
            mapRef={map}
            lat={hoveredProperty?.lat}
            lng={hoveredProperty?.lng}>
            <div className={classes.popupItem}>
              <img
                className={classes.popupImage}
                src={hoveredProperty.coverPhoto}
                alt="property-view"
              />
              <div className={classes.popupdescriptionContainer}>
                <span>{hoveredProperty.totalArea} m²</span>
                <span>{hoveredProperty.beds} dorm</span>
                <span>{hoveredProperty.baths} baño</span>
              </div>
            </div>
          </PopUpMap>
        )}

        {mapLoaded && (
          <Marker
            mkClassName={classes.mainMarker}
            map={map}
            lat={property?.property_details[0]?.latitud}
            lng={property?.property_details[0]?.longitud}>
            <Pin selected>{property.id}</Pin>
          </Marker>
        )}

        {mapLoaded &&
          viewedProperties.map((propertyVisited) => {
            if (property.id === propertyVisited.id) return null;

            return (
              <Marker
                map={map}
                key={propertyVisited.id}
                lat={propertyVisited.property_details[0]?.latitud}
                lng={propertyVisited.property_details[0]?.longitud}>
                <Pin
                  onClick={() => {
                    setOther(!other);
                    handleClickVisitedProperty(propertyVisited);
                  }}
                  clickleable>
                  {propertyVisited.id}
                </Pin>
              </Marker>
            );
          })}
      </div>
    </div>
  );
}
export default memo(SchedulerMap);
