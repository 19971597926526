import { FieldError } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePickerProps, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

import useStyles from "./InputDatePickerStyles";

interface InputDatePickerProps extends DatePickerProps {
  formError?: FieldError;
}

export default function InputDatePicker({ formError, ...props }: InputDatePickerProps) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        error={Boolean(formError)}
        helperText={formError?.message}
        InputProps={{
          classes: {
            root: classes.input,
            underline: classes.underline,
          },
        }}
        InputLabelProps={{
          className: classes.label,
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          },
        }}
        FormHelperTextProps={{ className: classes.helper }}
        disableToolbar
        variant="inline"
        format="DD-MM-yyyy"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
