import { useState } from "react";
import classNames from "classnames";
import copy from "copy-to-clipboard";

import { Divider, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useLessorBankAccountInfoStyles from "./LessorBankAccountInfoStyles";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { BankAccountModel } from "models/BankAccount";
import { contractHasManagement } from "../../utils";
import { ContractModel } from "models/Contract";

interface Props {
  contract: ContractModel;
  loadingBank: boolean;
  lessorBankAccount: BankAccountModel;
}

function LessorBankAccountInfo({ contract, loadingBank, lessorBankAccount }: Props) {
  const classes = useLessorBankAccountInfoStyles();
  const [copied, setCopied] = useState(false);

  return (
    <Loading loading={loadingBank}>
      {(!contractHasManagement(contract) || contract.status === "Generar boton de pago") && (
        <Grid container>
          <Divider className={classes.divider} />
          <Grid item xs={12}>
            <Typography className={classes.title}>Cuenta bancaria</Typography>
          </Grid>
          {lessorBankAccount ? (
            <Grid item xs={12}>
              <Typography className={classes.textValue}>
                {`${lessorBankAccount.bank} #${lessorBankAccount.account}, ${lessorBankAccount.email}`}
              </Typography>
            </Grid>
          ) : (
            <>
              <Typography className={classes.textValue}>No hay información</Typography>
              <Typography className={classes.textValue}>
                Pedir a propietario que rellene información de cuenta bancaria
              </Typography>
              <DesignSystemButton
                label={copied ? "Enlace copiado" : "Copiar enlace"}
                variant="secondary"
                size="small"
                onClick={() => {
                  setCopied(true);
                  copy("https://me.houm.com/landlord/bank_accounts");
                }}
                buttonClassName={classNames(classes.cgdButton, {
                  [classes.cgdButtonBlue]: !copied,
                  [classes.cgdButtonGreen]: copied,
                })}
              />
            </>
          )}
        </Grid>
      )}
    </Loading>
  );
}

export default LessorBankAccountInfo;
