import { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { Typography } from "@material-ui/core";
import {
  ControlledAddressText,
  ControlledPhoneInput,
  ControlledTextField,
  ControlledAutocomplete,
} from "Components/Admin/UIComponents/ControlledForm";
import ControlledSelectionStyled from "Components/Admin/administrator/demand/lead/components/forms/profiling/Inputs/ControlledSelectionStyled";
import { getDefaultLocation } from "utils/map";
import { countryCode, formatPhoneInput } from "utils";
import { countryOptions } from "Components/Admin/administrator/multifamily/CreateMF/utils/constants";
import { Multifamilies } from "context/multifamilyContext/multifamily/multifamilyTypes";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { getNeighborhoodOptions } from "context/multifamilyContext/multifamily/multifamilyActions";
import { User } from "Components/Admin/UIComponents/PropertyForm/components/index";
import { useGetToken } from "hooks";

import useStyles from "./InputsStyles";
import Map from "../Map";

interface Props {
  hasId: boolean;
  multifamily: Multifamilies;
}

function Inputs({ multifamily, hasId }: Props) {
  const classes = useStyles();
  const { watch } = useFormContext();
  const country = watch("country");
  const locale = countryCode(country?.value || "chile");
  const { lat, lng } = getDefaultLocation(locale);
  const { getToken } = useGetToken();
  const {
    state: { neighborhoodOptions },
    dispatch,
  } = useMultifamily();

  const textFieldProps = {
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.background,
      },
      placeholder: "Ingresar",
    },
  };

  useEffect(() => {
    if (country) {
      getToken().then(async (authToken) => {
        await getNeighborhoodOptions(country, authToken, dispatch);
      });
    }
  }, [country]);

  if (!multifamily?.name && hasId) return null;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <ControlledTextField
          label="Nombre"
          id="buildingName"
          defaultValue={multifamily?.name}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledSelectionStyled
          label="Pais"
          id="country"
          emptyValue={null}
          defaultValue={multifamily?.country}
          options={Object.values(countryOptions)}
        />
        <ControlledAddressText
          label="Dirección"
          id="address"
          defaultValue={
            multifamily?.address &&
            `${multifamily?.address} ${multifamily?.street_number}, ${multifamily?.commune}, ${multifamily?.region}, ${multifamily?.country}`
          }
          country={country || "chile"}
          placeholder="Ingresar"
          customClassname={classes.background}
        />
        <ControlledAutocomplete
          id="neighborhood"
          label="Barrio"
          defaultValue={{
            value: multifamily?.neighborhood?.id,
            label: multifamily?.neighborhood?.neighborhood
              ? `${multifamily?.neighborhood?.neighborhood}, ${multifamily?.commune}, ${multifamily?.region}`
              : "",
          }}
          options={neighborhoodOptions || []}
          textFieldProps={{ ...textFieldProps }}
        />
        <User
          label="Ejecutivo del edificio"
          id="executiveOfBuilding"
          currentUser={multifamily?.houm_executive}
        />
        <ControlledTextField
          label="Año de construcción"
          id="constructionYear"
          defaultValue={multifamily?.year_built}
          textFieldProps={{ ...textFieldProps }}
        />
        <User id="landlord" currentUser={multifamily?.landlord} />
        <Typography className={classes.subtitle}>Administración</Typography>
        <ControlledTextField
          label="Email"
          id="emailOfAdministrator"
          defaultValue={multifamily?.administration_email}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledPhoneInput
          id="phoneOfAdministrator"
          phoneLabel="Teléfono"
          defaultValue={formatPhoneInput(multifamily?.administration_phone)}
          countryInputProps={textFieldProps}
          numberInputProps={textFieldProps}
        />
      </div>
      <div className={classes.rightColumn}>
        <Typography className={classes.mapTitle}>
          ¿Está el marcador en la ubicación correcta?
        </Typography>
        <Typography>
          En caso que no puedes volver al paso anterior para editar los datos.
        </Typography>
        <Map
          defaultLat={multifamily.latitude || lat}
          defaultLng={multifamily.longitude || lng}
          locale={locale}
        />
        <Typography className={classes.subtitle}>Conserjería</Typography>
        <ControlledTextField
          label="Nombre"
          id="conciergeName"
          defaultValue={multifamily?.concierge_name}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextField
          label="Email"
          id="emailOfConcierge"
          defaultValue={multifamily?.administration_email}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledPhoneInput
          id="phoneOfConcierge"
          phoneLabel="Teléfono"
          defaultValue={formatPhoneInput(multifamily?.concierge_phone)}
          countryInputProps={textFieldProps}
          numberInputProps={textFieldProps}
        />
      </div>
    </div>
  );
}

export default Inputs;
