import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

const useDeletePenalty = (onCloseModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: { country: Locale; movementId: string }) => {
      const authToken = await getToken();
      const response = await newPaymentService.deletePenalty(
        formData.country,
        formData.movementId,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        onCloseModal();
        alert.success({
          message: "Multa eliminada",
          disclaimer: "La multa ha sido eliminada exitosamente",
        });
      },
      onError: (e: AxiosError) => {
        const error = e as AxiosError;
        const reason = error.response?.data?.message;
        alert.error({
          message: "Error al eliminar multa",
          disclaimer: `La multa no ha podido ser eliminada, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useDeletePenalty;
