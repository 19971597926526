import { makeStyles, Theme } from "@material-ui/core";

const useDataTextStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    maxWidth: 245,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export default useDataTextStyles;
