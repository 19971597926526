import { useEffect } from "react";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { setIsOpenSuccessModal } from "context/demandLeadContext/lead/demandLeadActions";

function Success() {
  const {
    state: { isOpenSuccessModal },
    dispatch,
  } = useDemandLead();

  useEffect(() => {
    if (isOpenSuccessModal) {
      setTimeout(() => dispatch(setIsOpenSuccessModal(false)), 1000);
    }
  }, [isOpenSuccessModal]);

  return (
    <CustomCenteredModal
      open={isOpenSuccessModal}
      onClose={() => dispatch(setIsOpenSuccessModal(false))}
      title="¡Se han guardado los datos con éxito!"
      subtitle=""
      showCloseButton={true}
      withLogo
    />
  );
}
export default Success;
