import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  closeButton: {
    zIndex: 1,
    color: "black",
  },
  leftMargin: {
    marginLeft: theme.spacing(1.25),
  },
}));
