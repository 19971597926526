import * as React from "react";
import { Grid, Divider } from "@material-ui/core";
import useNewAppraiserStyles from "../useNewAppraiserStyles";

const FormBlock: React.FC = ({ children }) => {
  const classes = useNewAppraiserStyles();
  return (
    <>
      <Grid item className={classes.container}>
        {children}
      </Grid>
      <Divider />
    </>
  );
};

export default FormBlock;
