/* eslint-disable react/require-default-props */
import { ReactNode } from "react";

import { Controller, useFormContext } from "react-hook-form";

import {
  InputBaseProps,
  FormLabel,
  FormControl,
  SelectProps,
  FormHelperText,
} from "@material-ui/core";

import clx from "classnames";

import SelectionOptions from "./SelectionOptions";
import Label from "./Label";
import useStyles from "./ControlledSelectionStyles";

export interface OptionTypes {
  value: string | number | boolean | null;
  label: string;
}

interface Props {
  defaultValue?: string | number | boolean | null;
  options: OptionTypes[];
  id: string;
  label?: ReactNode | string;
  optionWidth?: string;
  controllerProps?: React.ComponentProps<typeof Controller>;
  compressed?: boolean;
  simple?: boolean;
  classes?: {
    label?: string;
    container?: string;
    buttonList?: string;
    select?: SelectProps["classes"];
    input?: InputBaseProps["classes"];
  };
  round?: boolean;
  rectangleRounded?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  formFullwidth?: boolean;
  emptyValue?: string | number | null;
  buttonClassName?: string;
  useFormLabel?: boolean;
  acceptNull?: boolean;
}

function ControlledSelection({
  id,
  defaultValue,
  label = "",
  options,
  optionWidth,
  controllerProps,
  compressed = false,
  simple = false,
  round = false,
  rectangleRounded = false,
  fullWidth = false,
  formFullwidth = true,
  classes: propClasses,
  disabled = false,
  emptyValue = "0",
  buttonClassName,
  useFormLabel = false,
  acceptNull = true,
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles({ useFormLabel });

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState: { error, invalid } }) => (
        <FormControl
          error={invalid}
          className={clx(classes.container, propClasses?.container)}
          fullWidth={formFullwidth}
          ref={ref}>
          {!useFormLabel ? (
            <Label id={id} className={clx(classes.label, propClasses?.label)}>
              {label}
            </Label>
          ) : (
            <FormLabel className={clx(classes.formLabel, propClasses?.label)}>{label}</FormLabel>
          )}
          <div className={clx(classes.buttonsList, propClasses?.buttonList)}>
            <SelectionOptions
              emptyValue={emptyValue}
              value={value ?? ""}
              fullWidth={fullWidth}
              defaultValue={defaultValue}
              options={options}
              onChange={onChange}
              id={id}
              width={optionWidth}
              compressed={compressed}
              simple={simple}
              round={round}
              rectangleRounded={rectangleRounded}
              disabled={disabled}
              classes={propClasses?.select}
              buttonClassName={buttonClassName}
              inputBaseClasses={propClasses?.input}
              acceptNull={acceptNull}
            />
          </div>
          <FormHelperText>{error && error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}

export default ControlledSelection;
