import { Divider, makeStyles } from "@material-ui/core";

interface Props {}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

function CustomDivider(props: Props) {
  const classes = useStyles();
  return <Divider className={classes.divider} />;
}

export default CustomDivider;
