import { makeStyles, Theme } from "@material-ui/core";

export const usePageStyles = makeStyles((theme: Theme) => ({
  groupedButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
  googleMapContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: "10px",
  },

  bottomContentBox: {
    minHeight: "120px",
  },
  contentPage: {
    position: "relative",
    minHeight: "100vh",
  },
  contentMap: {
    marginTop: theme.spacing(3),
    width: "100%",
    height: "430px",
  },
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "500px",
    width: "100%",
  },
  updateButton: {
    maxHeight: "50px",
    textTransform: "none",
  },
  revPriceInfoContainer: {
    position: "relative",
  },
  hiddenContainer: {
    height: 0,
    overflow: "hidden",
  },
  downloadButton: {
    marginTop: theme.spacing(2),
  },
}));
