import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import { EvaluationMemberStatusEnum } from "domains/evaluations/models/Evaluations";
import { Paginate } from "models/ResponseWithPaginate";
import { createHttp } from "services/helper";
import { Locale } from "models/Countries";
import { EVALUATIONS_URL } from "env";
import { IProperty } from "models";

import {
  ChangeStatusEvaluationMemberRequest,
  CreateCommentRequest,
  CreateCommentResponse,
  EditEvaluationRequest,
  EditPersonInformationRequest,
  EvaluationsListResponse,
  StatisticsResponse,
  EvaluationResponse,
} from "./types";

const evaluations = (token: string, country = "cl", version: string = "v3") =>
  axios.create({
    baseURL: `${EVALUATIONS_URL}/${country}/${version}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  getStatistics: (
    token: string,
    country: Locale = "cl"
  ): Promise<AxiosResponse<StatisticsResponse>> => {
    const getVersion = (country: Locale) => (country === "cl" ? "v2" : "v1");
    const version = getVersion(country);
    return evaluations(token, country, version).get(`/evaluations/statistics`);
  },

  getEvaluationsList: (
    country: Locale = "cl",
    filters: Partial<{
      page_size: number;
      page: number;
      property_id?: number[];
      evaluation_ids?: number[];
      member_status?: (typeof EvaluationMemberStatusEnum)[keyof typeof EvaluationMemberStatusEnum];
      applicant_name?: string;
      sort?: string;
      order?: string;
    }> = {
      page_size: 10,
      page: 1,
      order: "desc",
      sort: "update_at",
    },
    token: string
  ): Promise<AxiosResponse<Paginate<EvaluationsListResponse>>> =>
    createHttp(token).get(
      `/admin/v2/evaluations?country_code=${country}&${qs.stringify(filters, qsConfig)}`
    ),

  getEvaluation: (
    evaluationId: string,
    country: Locale,
    token: string
  ): Promise<AxiosResponse<EvaluationResponse>> =>
    evaluations(token, country).get(`/rentals/admin/evaluations/${evaluationId}`),

  changeProperty: (
    evaluationId: string,
    country: Locale,
    data: {
      property_id: number;
      property_uid: string;
    },
    token: string
  ): Promise<AxiosResponse<{ id: string }>> =>
    evaluations(token, country).post(
      `/rentals/admin/evaluations/${evaluationId}/change_property`,
      data
    ),

  searchProperties: (
    value: string,
    token: string,
    params = {}
  ): Promise<AxiosResponse<Paginate<IProperty>>> =>
    createHttp(token).get(`/propietary/v2/properties/?search=${value}&${qs.stringify(params)}`),

  editPersonInformation: (
    data: EditPersonInformationRequest,
    memberId: string,
    country: Locale,
    token: string
  ) =>
    evaluations(token, country).put(
      `/rentals/admin/evaluation_members/${memberId}/person_information`,
      data
    ),

  editEvaluation: (
    evaluationId: string,
    country: Locale,
    data: Partial<EditEvaluationRequest>,
    token: string
  ): Promise<AxiosResponse<any>> =>
    evaluations(token, country).patch(`/rentals/admin/evaluations/${evaluationId}`, data),

  createComment: (
    evaluationMemberId: string,
    data: CreateCommentRequest,
    country: Locale,
    token: string
  ): Promise<AxiosResponse<CreateCommentResponse>> =>
    evaluations(token, country).post(
      `rentals/admin/evaluation_members/${evaluationMemberId}/comments`,
      data
    ),

  changeEvaluationMemberStatus: (
    evaluationMemberId: string,
    data: ChangeStatusEvaluationMemberRequest,
    country: Locale,
    token: string
  ) =>
    evaluations(token, country).put(`rentals/admin/evaluation_members/${evaluationMemberId}`, data),

  convertToMain: (evaluationMemberId: string, country: Locale, token: string) =>
    evaluations(token, country).put(
      `rentals/admin/evaluation_members/${evaluationMemberId}/set_main`
    ),

  deleteMember: (evaluationMemberId: string, country: Locale, token: string) =>
    evaluations(token, country).delete(`rentals/admin/evaluation_members/${evaluationMemberId}`),

  deleteEvaluationFile: (
    country: Locale,
    evaluationId: string,
    documentId: string,
    token: string
  ) =>
    evaluations(token, country).delete(
      `/rentals/admin/evaluation_members/${evaluationId}/documents/${documentId}`
    ),
};
