export const SET_LOADING_DATA = "SET_LOADING_DATA";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const SET_EVALUATION = "SET_EVALUATION";
export const SET_EVALUATION_FILTER = "SET_EVALUATION_FILTER";
export const SET_STATISTICS = "SET_STATISTICS";
export const SET_SINGLE_EVALUATION = "SET_SINGLE_EVALUATION";
export const SET_EVALUATION_TOKEN = "SET_EVALUATION_TOKEN";
export const SET_LOADING_SINGLE_EVALUATION = "SET_LOADING_SINGLE_EVALUATION";
export const SET_APPLICANT = "SET_APPLICANT";
export const SET_HOUMER = "SET_HOUMER";
export const SET_LOADING_HOUMER = "SET_LOADING_HOUMER";
export const SET_PROPERTY_3X = "SET_PROPERTY_3X";
export const SET_PROPERTY_IS_IN_UF = "SET_PROPERTY_IS_IN_UF";
export const SET_CAN_PAY = "SET_CAN_PAY";

export interface State {
  loadingData: boolean;
  country: Country;
  evaluation: Evaluation[];
  numberEvaluations: number | null;
  filters: EvaluationFilter;
  statistics: Statistics;
  singleEvaluation: SingleEvaluation;
  token: string;
  loadingSingleEvaluation: boolean;
  applicant;
  houmer;
  loadingHoumer: boolean;
  property3x: number;
  propertyIsInUF: boolean;
  canPay: boolean;
}

interface setLoadingData {
  type: typeof SET_LOADING_DATA;
  loadingData: boolean;
}
interface setLoadingHoumer {
  type: typeof SET_LOADING_HOUMER;
  loadingHoumer: boolean;
}

interface setLoadingSingleEvaluation {
  type: typeof SET_LOADING_SINGLE_EVALUATION;
  loadingSingleEvaluation: boolean;
}

interface changeCountry {
  type: typeof CHANGE_COUNTRY;
  country: Country;
}

interface setEvaluation {
  type: typeof SET_EVALUATION;
  payload: { evaluation; count: number };
}

interface setEvaluationFilter {
  type: typeof SET_EVALUATION_FILTER;
  filters: EvaluationFilter;
}

interface setStatistics {
  type: typeof SET_STATISTICS;
  statistics: Statistics;
}

interface setSingleEvaluation {
  type: typeof SET_SINGLE_EVALUATION;
  singleEvaluation: SingleEvaluation;
}

interface setEvaluationToken {
  type: typeof SET_EVALUATION_TOKEN;
  token: string;
}

interface setApplicant {
  type: typeof SET_APPLICANT;
  applicant;
}

interface setHoumer {
  type: typeof SET_HOUMER;
  houmer;
}

interface setProperty3X {
  type: typeof SET_PROPERTY_3X;
  property3x: number;
}

interface setPropertyIsInUF {
  type: typeof SET_PROPERTY_IS_IN_UF;
  propertyIsInUF: boolean;
}

interface setCanPay {
  type: typeof SET_CAN_PAY;
  canPay: boolean;
}

export interface IRents {
  [key: number]: string;
}

export interface SingleEvaluation {
  codebtorName: string;
  codebtorId: number;
  evaluations;
  propertyId: number;
  propertyUid: string;
  commerciallyApproved;
  rents: IRents[];
  bankAccount;
  rentsSum?: number;
}

export interface Statistics {
  start: number;
  noDocs: number;
  pendingDocs: number;
  readyForEvaluation: number;
  newDocs: number;
  commerciallyApproved: number;
  rejected: number;
  desisted: number;
}

interface Evaluation {
  id: number;
  relatedApplicantId: number;
  propertyId: number;
  mainApplicant: string;
  codebtor: string;
  propertyUid: string;
  mainApplicantId: string;
  codebtorId: number;
  status: string;
  cratedDate: string;
  updatedDate: string;
}

export interface EvaluationFilter {
  size: number;
  page: number;
  sort: string;
  order: string;
  mainApplicant: string | null;
  codebtor: string | null;
  status: string | null;
  property: number | null;
  email: string;
  relatedApplicant: string;
}

export type Country = "chile" | "colombia" | "mexico";

export type Dispatch = (action: Action) => void;

export type Action =
  | setLoadingData
  | changeCountry
  | setEvaluation
  | setEvaluationFilter
  | setStatistics
  | setSingleEvaluation
  | setEvaluationToken
  | setLoadingSingleEvaluation
  | setApplicant
  | setHoumer
  | setLoadingHoumer
  | setProperty3X
  | setPropertyIsInUF
  | setCanPay;
