import React, { useMemo } from "react";
import Divider from "./CustomDivider";
import {
  ControlledNumberField,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { useProperty } from "context/propertyContext/propertyContext";
import { useFormContext, useWatch } from "react-hook-form";
import { yesNoBoolean } from "utils/createProperty";
import { fromToOptions } from "utils/fromToOptions";

import { emptyPropertyDetails } from "Components/Admin/UIComponents/Cards/emptyDetails";
import moment from "moment";
import useUserFormRole from "hooks/useUserFormRole";

function PropertyDetails() {
  const { state } = useProperty();
  const { control } = useFormContext();

  const role = useUserFormRole();

  const propertyDetails = useMemo(
    () => state.property?.property_details[0] || emptyPropertyDetails,
    [state.property?.property_details[0]]
  );

  const defaultYear = useMemo(() => {
    if (state.property?.year_built) {
      return state.property?.year_built;
    } else if (state.property?.antiquity) {
      return moment().year() - Number(state.property?.antiquity);
    }
  }, [state.property]);

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.property?.country,
  });
  return (
    <FormBlock>
      <h3>Características principales</h3>
      <ControlledNumberField
        id="m_terreno"
        defaultValue={propertyDetails?.m_terreno}
        label="Superficie total en m²"
        placeholder="231 m2"
        numberFieldProps={{
          suffix: " m²",
        }}
      />
      <ControlledNumberField
        id="m_construidos"
        defaultValue={propertyDetails?.m_construidos}
        label="Superficie interior construida en m²"
        placeholder="100 m²"
        numberFieldProps={{
          suffix: " m²",
        }}
      />
      {role !== "real_estate_developer" && (
        <>
          <ControlledNumberField
            id="terrace_size"
            defaultValue={propertyDetails?.terrace_size}
            label="Superficie de la terraza en m²"
            placeholder="25 m²"
            numberFieldProps={{
              suffix: " m²",
            }}
          />
          <ControlledNumberField
            id="loggia_size"
            defaultValue={propertyDetails?.loggia_size}
            label="Superficie de la loggia en m²"
            placeholder="10 m²"
            numberFieldProps={{
              suffix: " m²",
            }}
          />
        </>
      )}
      <ControlledNumberField
        id="year_built"
        label="Año de construcción"
        defaultValue={defaultYear as number}
        placeholder="1984"
        numberFieldProps={{
          thousandSeparator: false,
          maxLength: 4,
        }}
      />
      <Divider />
      {country === "Colombia" && (
        <>
          <ControlledSelection
            id="stratus"
            defaultValue={state.property?.stratus}
            label="Estrato"
            optionWidth="30px"
            options={fromToOptions(1, 6)}
          />
          <Divider />
        </>
      )}
      <ControlledSelection
        id="estacionamientos"
        defaultValue={propertyDetails.estacionamientos}
        label="Número de estacionamientos"
        optionWidth="30px"
        options={fromToOptions(0, 4)}
      />
      <Divider />
      <ControlledSelection
        id="bodega"
        optionWidth="40px"
        defaultValue={propertyDetails.bodega}
        label="Bodega"
        options={yesNoBoolean}
      />
      <Divider />
      {country === "Chile" && (
        <>
          <ControlledSelection
            id="is_new"
            optionWidth="40px"
            defaultValue={null}
            label="Propiedad nueva, ¿La propiedad ha sido habitada antes?"
            options={yesNoBoolean}
          />
          <Divider />
        </>
      )}
    </FormBlock>
  );
}

export default React.memo(PropertyDetails);
