import FieldSelect from "@houm-com/ui/FieldSelect";
import IconButton from "@houm-com/ui/IconButton";
import { IoAddCircleOutline, IoCloseCircleOutline } from "@houm-com/ui-icons";

import { setPlanData } from "context/plansContext/plansActions";
import { usePlans } from "context/plansContext/plansContext";

import classes from "./CreatePlanDialogStyles";
import { CityProps } from "models/Plan";

interface Props {
  availableCities: CityProps[];
}

export default function CitySelection({ availableCities }: Props) {
  const { state, dispatch } = usePlans();

  const setCity = () => {
    dispatch(
      setPlanData({
        cities: [...state.planData.cities, availableCities[0].id],
      })
    );
  };

  const cityOptions = [
    { value: "*", label: "Todos" },
    ...availableCities.map((item) => ({ label: item.name, value: String(item.id) })),
  ];

  return (
    <div className={classes.inputContainer}>
      <p className={classes.helperText}>Selecciona la(s) ciudad(es)</p>

      {state.planData.cities.map((_, index) => (
        <div key={`cities-${index}`}>
          <div className={classes.cityContainer}>
            <div className={index === 0 ? classes.firstSelect : classes.select}>
              <FieldSelect
                id="city-selection"
                value={state.planData.cities[index] || ""}
                onChange={(e) => {
                  let copy = [...state.planData.cities];
                  if (e.target.value === "*") {
                    copy = availableCities.map((city) => city.id);
                  } else {
                    copy[index] = Number(e.target.value);
                  }
                  dispatch(setPlanData({ cities: copy }));
                }}
                options={cityOptions}
              />
            </div>

            {index != 0 && (
              <IoCloseCircleOutline
                size={18}
                className={classes.deleteCityButton}
                onClick={() => {
                  const copy = [...state.planData.cities];
                  copy.splice(index, 1);
                  dispatch(setPlanData({ cities: copy }));
                }}
              />
            )}
          </div>

          {index === state.planData.cities.length - 1 &&
            state.planData.cities.length !== availableCities.length && (
              <div className={classes.addCityButtonContainer}>
                <IconButton
                  onClick={() => setCity()}
                  variant="tertiary"
                  size="md"
                  icon={<IoAddCircleOutline size={18} />}
                  position="start"
                  className={classes.addCityButton}>
                  Agregar otra ciudad
                </IconButton>
              </div>
            )}
        </div>
      ))}
    </div>
  );
}
