/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import HeaderWithCountries from "../../../UIComponents/HeaderWithCountries";
import { useProperties } from "../context/propertiesContext";
import { CHANGE_COUNTRY, Country } from "../context/propertiesTypes";

function Header() {
  const { dispatch, state } = useProperties();

  const onClickHandle = (country: Country) => {
    dispatch({ type: CHANGE_COUNTRY, country });
  };

  return (
    <HeaderWithCountries
      title="Propiedades"
      country={state.country}
      onCountryChange={onClickHandle}
    />
  );
}

export default Header;
