import { PromotionTarget, PromotionUser } from "./types";

export const parseSummaryData = (data: string | null, suffix?: string) => {
  if (data && suffix) return `${data}${suffix}`;
  if (data) return data;
  return "N/A";
};

export const generalInfoReady = ({
  name,
  goal,
  cities,
  startDate,
  endDate,
}: {
  name: string;
  goal: string;
  cities: string[];
  startDate: string;
  endDate: string;
}) =>
  name &&
  goal &&
  cities.length > 0 &&
  !startDate.includes("undefined") &&
  !endDate.includes("undefined");

export const showLandlordManagementDiscount = (target: PromotionTarget) =>
  ["management_fee", "both"].includes(target);

export const showLandlordBrokerageDiscount = (target: PromotionTarget, user: PromotionUser) =>
  target === "both" || (target === "brokerage" && ["landlord", "both"].includes(user));

export const showTenantBrokerageDiscount = (target: PromotionTarget, user: PromotionUser) =>
  ["brokerage", "both"].includes(target) && ["tenant", "both"].includes(user);
