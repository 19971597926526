import { makeStyles, Theme } from "@material-ui/core";

interface OptionPropsTypes {
  disabled?: boolean;
  compressed?: boolean;
  width: string;
  round?: boolean;
  rectangleRounded?: boolean;
}

export default makeStyles<Theme, OptionPropsTypes>((theme) => ({
  contract: {
    minWidth: theme.spacing(2),
  },
  optionButtonWrap: {
    textTransform: "none",
    fontWeight: 600,
    marginRight: "10px",
    border: "1px solid",
    borderRadius: ({ round, rectangleRounded }) =>
      round ? "50%" : rectangleRounded ? "100px" : "6px",

    minWidth: ({ width }) => width,
    height: ({ width }) => width,

    padding: ({ compressed }) => (compressed ? "3px 11px" : ""),
    borderColor: ({ disabled }) =>
      !disabled ? theme.palette.secondary.main : theme.palette.secondary.light,
    color: ({ disabled }) =>
      !disabled ? theme.palette.primary.main : theme.palette.secondary.light,
    fontSize: ({ compressed }) => (compressed ? "0.875rem" : "1rem"),
    backgroundColor: ({ disabled }) => (!disabled ? theme.palette.secondary.main : "white"),

    "&:hover": {
      backgroundColor: ({ disabled }) => (!disabled ? theme.palette.primary.light : "#f5f5f5"),
      color: ({ disabled }) => (!disabled ? "white" : null),
    },
  },
}));
