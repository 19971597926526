// Hotfix 25-06-2021

export const emptyPropertyDetails = {
  id: null,
  uid: "",
  amoblado: null,
  apartment_type: "",
  banos: null,
  bodega: false,
  calefaccion: false,
  closet: "",
  condominio: false,
  currency_code: "",
  curtain_hanger: false,
  dormitorios: null,
  en_suite: 0,
  environments: null,
  estacionamientos: 0,
  external_description: "",
  first_available_handover_date: null,
  furnished: "",
  gc: 0,
  gym: false,
  has_air_conditioning: false,
  half_baths: null,
  has_balcony: false,
  has_clothing_area: false,
  has_laundry_room: false,
  has_roof_garden: false,
  hot_water_system: "",
  has_water_tank: false,
  house_extension: "",
  is_common_expenses_included: false,
  is_construction_in_gray: false,
  is_renewed: false,
  kitchen_daily_dining_room: false,
  kitchen_is_furnished: false,
  latitud: null,
  laundry_capacity: false,
  last_status_change: null,
  loggia_size: null,
  longitud: null,
  mascotas: false,
  m_construidos: null,
  m_terreno: null,
  minisplits: 0,
  neighborhood_id: null,
  observaciones: "",
  observaciones_venta: "",
  orientacion: null,
  parking: false,
  parking_numbers: "",
  parking_type: "",
  piscina: false,
  promotion_months: null,
  promotional_price: null,
  public_services_expenses: null,
  servicios: 0,
  status: "unknown",
  terraza: 0,
  terrace_size: null,
  tipo_moneda: "Pesos",
  tipo_moneda_venta: "Pesos",
  ubicacion: null,
  valor: null,
  valor_venta: 0,
  vende_id: 0,
  warehouse_number: "",
};

export const emptySalesDetails = {
  property_id: "",
  brokerage_commission: "",
  currency: "",
  currency_code: "",
  description: "",
  external_description: "",
  first_available_handover_date: "",
  first_publication_date: "",
  last_publication_date: "",
  last_status_change: "",
  lost_reason: "",
  price: 0,
  priority: 0,
  status: "unknown",
};
