import { Typography, TypographyProps } from "@material-ui/core";
import clsx from "clsx";
import useCardBoldStyles from "./CardBoldStyles";

interface Props extends Omit<TypographyProps, "className"> {
  className?: string;
  subtitle?: boolean;
}

function CardBold({ children, className, subtitle }: Props) {
  const classes = useCardBoldStyles({ subtitle });

  return <Typography className={clsx(classes.cardSubtitleRoot, className)}>{children}</Typography>;
}

export default CardBold;
