import { useQuery, UseQueryResult } from "react-query";

import { ICity } from "Components/Admin/administrator/promotions/utils/types";
import propertyService from "services/propertyService";
import { Locale } from "models/Countries";

const useCities = (country: Locale) => {
  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<ICity[], Error> = useQuery<ICity[], Error>(
    ["citiesByCountry", country],
    async () => {
      const response = await propertyService.getCitiesByCountryV2(country);
      return response.data.results;
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useCities;
