import { ComponentProps } from "react";

import Tag from "@houm-com/ui/Tag";

import { EvaluationComment, EvaluationMemberStatus } from "domains/evaluations/models/Evaluations";

export const statusVariant: {
  [locale in EvaluationMemberStatus]: ComponentProps<typeof Tag>["variant"];
} = {
  commercially_approved: "success",
  desisted: "error",
  pending_docs: "warning",
  ready_for_evaluation: "info",
  new_docs: "warning",
  approved: "success",
  rejected: "error",
  start: "default",
  no_docs: "default",
};

const sortByDate = (a: EvaluationComment, b: EvaluationComment) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

export const getSortedComments = (comments: EvaluationComment[]) => comments.sort(sortByDate);
