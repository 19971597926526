import Swal from "sweetalert2";

import templatesService from "services/templatesService";

import {
  Action,
  ContractTemplateProps,
  Dispatch,
  Filters,
  SET_FETCHING_TEMPLATES,
  SET_NEW_TEMPLATE,
  SET_SUBMITTING_TEMPLATE,
  SET_TEMPLATES,
  SET_VIEW,
  SET_FILTERS,
  SET_SUBMIT_RESPONSE,
  SubmitResponseType,
  View,
} from "./contractTemplatesTypes";
import { backendParseTemplate, frontendParseTemplates } from "./utils/parsers";

export const setFetchingTemplates = (fetchingTemplates: boolean): Action => ({
  type: SET_FETCHING_TEMPLATES,
  fetchingTemplates,
});

export const setFilters = (filters: Partial<Filters>): Action => ({
  type: SET_FILTERS,
  filters,
});

export const setNewTemplate = (newTemplate: Partial<ContractTemplateProps>): Action => ({
  type: SET_NEW_TEMPLATE,
  newTemplate,
});

export const setSubmittingTemplate = (submittingTemplate: boolean): Action => ({
  type: SET_SUBMITTING_TEMPLATE,
  submittingTemplate,
});

export const setSubmitResponse = (submitResponse: SubmitResponseType): Action => ({
  type: SET_SUBMIT_RESPONSE,
  submitResponse,
});

export const setTemplates = (templates: ContractTemplateProps[]): Action => ({
  type: SET_TEMPLATES,
  templates,
});

export const setView = (view: View): Action => ({
  type: SET_VIEW,
  view,
});

export const fetchTemplates = async (authToken: string, dispatch: Dispatch, id: null | number) => {
  try {
    dispatch(setFetchingTemplates(true));
    const templatesResponse = await templatesService.getTemplates(authToken);
    const templates = frontendParseTemplates(templatesResponse.data);
    dispatch(setTemplates(templates));
    if (id) {
      dispatch(setNewTemplate(templates.find((template) => template.id === id)));
      dispatch(setView("templateForm"));
    }
  } catch (err) {
    Swal.fire({
      type: "error",
      title: "¡Ups!",
      text: `Hubo un problema cargando ${id ? "el template" : "los templates"}`,
    });
  } finally {
    dispatch(setFetchingTemplates(false));
  }
};

export const submitTemplate = async (
  isNewTemplate: boolean,
  data: ContractTemplateProps,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    dispatch(setSubmittingTemplate(true));
    if (isNewTemplate) {
      await templatesService.postTemplate(backendParseTemplate(data), authToken);
    } else {
      await templatesService.patchTemplate(data.id, backendParseTemplate(data), authToken);
    }
    dispatch(setSubmitResponse("success"));
  } catch (err) {
    dispatch(setSubmitResponse("error"));
  } finally {
    dispatch(setSubmittingTemplate(false));
  }
};
