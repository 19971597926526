import { AxiosResponse } from "axios";
import { BACKEND_URL } from "env";

import { IContractTemplate } from "models/ContractTemplate";

import { createHttp } from "./helper";

export default {
  postTemplate(data: FormData, token: string) {
    return createHttp(token, BACKEND_URL, "multipart/form-data").post(
      "v2/contract_templates/",
      data
    );
  },
  patchTemplate(templateId: number, data: FormData, token: string) {
    return createHttp(token, BACKEND_URL, "multipart/form-data").patch(
      `v2/contract_templates/${templateId}/`,
      data
    );
  },
  getTemplates(token: string): Promise<AxiosResponse<IContractTemplate[]>> {
    return createHttp(token, BACKEND_URL).get("v2/contract_templates/");
  },
};
