import * as yup from "yup";

import { phoneValidator } from "utils/yupValidators/phone";

const requiredText = "Campo obligatorio";
const emailText = "Debe ser un email";

export const profileSchema = yup.object({
  name: yup.string().required(requiredText),
  email: yup.string().email(emailText).typeError(requiredText),
  phone: phoneValidator.required(requiredText),
  contract_type: yup.string().required(requiredText).typeError(requiredText),
  active: yup.boolean().required(requiredText).typeError(requiredText),
});
