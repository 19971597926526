import { makeStyles, Theme } from "@material-ui/core";

const useBackToPropertyStyles = makeStyles((theme: Theme) => ({
  navigationWrapper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  arrow: {
    marginRight: theme.spacing(0.5),
    "& path": {
      fill: theme.palette.primary.main,
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

export default useBackToPropertyStyles;
