import { formatDistance } from "date-fns";
import es from "date-fns/locale/es";

import { IDebtCollectionDetail } from "hooks/useGetDebtCollectionDetail/utils/types";
import { Locale } from "models/Countries";
import statuses from "../../utils/insuranceStatuses";
import classes from "./CommentHistoryStyles";

interface CommentHistoryProps {
  country: Locale;
  debtCollectionDetail: IDebtCollectionDetail;
}

const CommentHistory = ({ debtCollectionDetail, country }: CommentHistoryProps) => {
  const comments = debtCollectionDetail?.insurance?.comments;
  if (!comments?.length) {
    return null;
  }
  return (
    <>
      <div className={classes.titleContainer}>
        <h2 className={classes.title}>Historial de comentarios</h2>
      </div>
      <div>
        {comments.map((comment) => (
          <div key={comment.status + comment.timestamp} className={classes.comment}>
            <div>
              <p className={classes.primaryText}>
                <span className={classes.secondaryText}>Estado: </span>
                {statuses[country]?.()[comment.status]?.name}
              </p>
              <div className={classes.commentHeadInfo}>
                <p className={classes.primaryText}>
                  <span className={classes.secondaryText}>Enviado por: </span>
                  {comment.creatorEmail}
                </p>
                <p className={classes.secondaryText}>
                  {formatDistance(new Date(comment.timestamp), new Date(), {
                    addSuffix: true,
                    locale: es,
                  })}
                </p>
              </div>
            </div>
            <p className={classes.commentContent}>{comment.comment}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default CommentHistory;
