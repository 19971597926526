import { useState } from "react";
import Swal from "sweetalert2";

import { DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import UserSuggester from "Components/Admin/UIComponents/UserSuggester";
import { HoumOwner } from "models/Property";

import useHoumOwnerModalStyles from "./HoumOwnerModalStyles";
import useUpdateProperty from "../../../hooks/useUpdateProperty";

interface Props {
  open: boolean;
  handleClose: () => void;
  propertyId: number | null;
  actualHoumOwner: HoumOwner | null;
}

const HoumOwnerModal = ({ open, handleClose, propertyId, actualHoumOwner }: Props) => {
  const classes = useHoumOwnerModalStyles();
  const { mutate, isLoading, isSuccess, isError } = useUpdateProperty(propertyId);
  const [houmOwner, setHoumOwner] = useState<HoumOwner>();

  if (isSuccess) {
    window.location.reload();
  }

  if (isError) {
    Swal.fire("Error", "No se pudo cambiar al houmowner", "error");
  }

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <DialogTitle>
        <Typography className={classes.title}>Ejecutivo</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserSuggester
              defaultValue={actualHoumOwner}
              value={houmOwner}
              onChange={(e: HoumOwner) => setHoumOwner(e)}
              id="houmOwner"
              label="Cambiar ejecutivo"
              qParams={{ role: "seller" }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <DesignSystemButton
              size="small"
              label="Cambiar"
              variant="primary"
              isLoading={isLoading}
              onClick={() => mutate(houmOwner)}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DSModal>
  );
};

export default HoumOwnerModal;
