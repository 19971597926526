import { ReactNode } from "react";
import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import useInfoItemLabelStyles from "./InfoItemLabelStyles";

interface Props {
  labelText: string;
  children: ReactNode;
  labelClassName?: string;
}

const InfoItemLabel = ({ labelText, children, labelClassName }: Props) => {
  const classes = useInfoItemLabelStyles();

  return (
    <Box>
      <Typography className={clx(classes.label, labelClassName)}>{labelText}</Typography>
      {children}
    </Box>
  );
};

export default InfoItemLabel;
