import { useState } from "react";
import { Button } from "@material-ui/core";

import { useGetToken } from "hooks";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  setIsOpenCancelModal,
  setCancelation,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

import Autocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import useCancelationPropStyles from "./CancelationPropStyles";

function CancelationProp() {
  const [reason, setReason] = useState(null);
  const [reasonTxt, setReasonTxt] = useState<string>("");
  const { getToken } = useGetToken();
  const classes = useCancelationPropStyles();
  const {
    state: { isOpenCancelModal, scheduleId, cancelReasons },
    dispatch,
  } = useDemandScheduled();
  const handleCancelationProp = (scheduleId, reason, reasonTxt) => {
    getToken().then((authToken) => {
      dispatch(setIsOpenCancelModal(false));
      setCancelation(dispatch, authToken, scheduleId, reason, reasonTxt);
      setReasonTxt("");
      setReason(null);
    });
  };

  return (
    <CustomCenteredModal
      open={isOpenCancelModal}
      onClose={() => dispatch(setIsOpenCancelModal(false))}
      title="Cancelación del contacto de coordinación"
      subtitle={`Elige el motivo de cancelación de la visita: ${scheduleId}`}
      showCloseButton>
      <div className={classes.reasonsContainer}>
        <Autocomplete
          onChange={setReason}
          value={reason}
          options={cancelReasons}
          label="Motivo de cancelación:"
        />
      </div>
      <div className={classes.openReasonContainer}>
        <textarea
          className={classes.openReasonTextarea}
          value={reasonTxt}
          onChange={(e) => setReasonTxt(e.target.value)}
          disabled={reason?.value === undefined}
        />
      </div>
      <div className={classes.buttonContainers}>
        <Button
          size="small"
          color="primary"
          className={classes.actionButton}
          onClick={() => dispatch(setIsOpenCancelModal(false))}>
          Cerrar
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={() => handleCancelationProp(scheduleId, reason, reasonTxt)}
          disabled={reason?.value === undefined}>
          Cancelar visita
        </Button>
      </div>
    </CustomCenteredModal>
  );
}

export default CancelationProp;
