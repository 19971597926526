import { Typography } from "@material-ui/core";

import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import useStyles from "./TableTitleStyles";

interface Props {
  titlesArr: string[];
  isItemRow?: boolean;
  isEdit?: boolean;
}

function TableTitle({ titlesArr, isItemRow = false, isEdit = false }: Props) {
  const classes = useStyles();

  return (
    <InspectionReportTableRow isTitle isItemRow={isItemRow} isEdit={isEdit}>
      {titlesArr.map((title) => (
        <Typography key={title} className={classes.headerCellText}>
          {title}
        </Typography>
      ))}
    </InspectionReportTableRow>
  );
}

export default TableTitle;
