import { IoArrowUpOutline as ArrowIcon } from "@houm-com/ui-icons";
import Button from "@houm-com/ui/Button";
import { cn } from "@houm-com/ui/utils";

import { Locale } from "models/Countries";
import { IDebtCollection } from "models/IDebtCollection";

import { formatMoney } from "../../utils/utils";
import classes from "./TableRowStyle";
import InsuranceDataForm from "../InsuranceDataForm";
import getInsuranceTag from "./utils/utils";

interface TableRowProps {
  item: IDebtCollection;
  country: Locale;
  onStatusClick: (value: { contractId: number; companyCode: string }) => void;
  openMoreInfoModal: (value: number) => void;
}

export default function TableRow({
  item,
  country,
  onStatusClick,
  openMoreInfoModal,
}: TableRowProps) {
  const isDisabled = item.collectionProcessStatus === "FINISHED";
  const tag =
    country !== "mx" &&
    !item.insurance?.companyCode?.includes("HOUM") &&
    getInsuranceTag(country, item, onStatusClick);
  return (
    <tr
      className={cn(classes.container, {
        [classes.disabledText]: isDisabled,
      })}>
      <td className={cn(classes.column, classes.normalColumn)}>
        {item.legacyId && (
          <div>
            <span className={classes.grayText(isDisabled)}>Suscripcion: </span>
            <a href={`/admin/clients/${item.legacyId}`} target="_blank" className={classes.anchor}>
              {item.legacyId}
            </a>
          </div>
        )}
        {item.subscriptionId && (
          <div>
            <span className={classes.grayText(isDisabled)}>Nuevo ID suscripcion: </span>
            <a href={`/admin/clients/${item.legacyId}`} target="_blank" className={classes.anchor}>
              {item.subscriptionId}
            </a>
          </div>
        )}
        {item.contractId && (
          <div>
            <span className={classes.grayText(isDisabled)}>Contrato: </span>
            <a
              href={`/admin/contracts/${item.contractId}`}
              target="_blank"
              className={classes.anchor}>
              {item.contractId}
            </a>
          </div>
        )}
        <div>
          <span className={classes.grayText(isDisabled)}>Cobranza: </span>
          {item.collectionProcessId}
        </div>
      </td>
      <td className={cn(classes.column, classes.bigColumn, classes.tenantInfo)}>
        <p>{item.tenant.name}</p>
        <p>{item.tenant.documentNumber}</p>
        <p className="break-words">{item.tenant.email}</p>
        <p>{item.tenant.phoneNumber}</p>
        <p>Dirección: {item.tenant.propertyAddress ?? "-"}</p>
      </td>
      <td className={cn(classes.column, classes.normalColumn)}>
        <p className={classes.grayText(isDisabled)}>1er Impago:</p>
        <p>{item.collectionDates.initDate}</p>
        <p className={classes.grayText(isDisabled)}>Inicio Cobranza:</p>
        <p>{item.collectionDates.initDate}</p>
        <p className={classes.grayText(isDisabled)}>Mora: {item.collectionDates.lateDays} dias</p>
      </td>
      <td className={cn(classes.column, classes.normalColumn)}>
        <p>{item.plan.description ?? "Sin información del plan"}</p>
      </td>
      <td className={cn(classes.column, classes.normalColumn)}>
        <p>
          <span className={classes.grayText(isDisabled)}>Totales: </span>
          {item.guaranteedLives.total}
        </p>
        <p>
          <span className={classes.grayText(isDisabled)}>Restantes: </span>
          {item.guaranteedLives.remaining}
        </p>
        <p>
          <span className={classes.grayText(isDisabled)}>Utilizados: </span>
          {item.guaranteedLives.used}
        </p>
      </td>
      <td className={cn(classes.column, classes.normalColumn)}>
        <p>
          <span className={classes.grayText(isDisabled)}>Deuda total: </span>
          {formatMoney(item.debt.lease, country)}
        </p>
        <p>
          <span className={classes.grayText(isDisabled)}>Multas: </span>
          {formatMoney(item.debt.penalty, country)}
        </p>
      </td>
      {country !== "mx" && (
        <>
          <td className={cn(classes.column, classes.bigColumn)}>
            {item.insurance.companyCode ? (
              <>
                <p className={classes.status}>{item.insurance.companyCode}</p>
                {tag}
                <InsuranceDataForm isDisabled={isDisabled} item={item} />
              </>
            ) : (
              <div className={classes.status}>Sin aseguradora</div>
            )}
          </td>
          <td className={cn(classes.lastColumn, classes.normalColumn)}>
            <Button
              onClick={() => openMoreInfoModal(item.collectionProcessId)}
              className={classes.moreInfo}
              variant="primary"
              size="md">
              <span>Mas Info</span>
              <ArrowIcon className={classes.moreInfoIcon} />
            </Button>
          </td>
        </>
      )}
    </tr>
  );
}
