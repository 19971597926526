import { Box } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  aria: string;
  className?: string;
}

function TabPanel({ children, index, value, aria, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${aria}-tabpanel-${index}`}
      aria-labelledby={`${aria}-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default TabPanel;
