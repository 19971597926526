import z from "zod";

export const UserProfileSchema = z.object({
  id: z.number(),
  name: z.string(),
  last_name: z.string(),
  phone: z.string().nullable(),
  phone_verified: z.string().transform((value) => value === "True"),
  email: z.string(),
  document: z.string().nullable(),
  document_type: z.number().nullable(),
  country: z.string().nullable(),
  created_at: z.string(),
});

export type UserProfile = z.infer<typeof UserProfileSchema>;
