import { format } from "date-fns";

import { CountryUpperCase } from "models/Countries";
import { PromotionForm } from "domains/promotion/service/types";

import { ICity } from "../../../utils/types";
import { FormData } from "./types";

const getMonths = (data: FormData, keyValue: string) => {
  const result = {};
  for (let i = 1; i <= data[keyValue]; i++) {
    const key = `${keyValue}${i}`;
    result[`month_${i}`] = data[key];
  }
  return result;
};

export const parsePromotionData = (
  data: FormData,
  cities: ICity[],
  country: CountryUpperCase,
  userId: number
): PromotionForm => ({
  start_date: format(new Date(data.initDate), "yyyy-MM-dd"),
  end_date: format(new Date(data.endDate), "yyyy-MM-dd"),
  name: data.promotionName,
  objective: data.target,
  comment: data.description,
  ...(data.link !== "" && { term_and_conditions_link: data.link }),
  incharge: userId,
  cities: cities.map((city) => city.id),
  country,
  total_promotions: data.promotionStock,
  ...(data.promotionCode !== "" && { code: data.promotionCode }),
  active: true,
  discount_type: data.discountType,
  management_fee: {
    active: data.adminCommissionFlag === "yes",
    ...(data.adminCommissionFlag === "yes" && { months: getMonths(data, "adminCommissionMonth") }),
  },
  landlord_lease: {
    active: data.leaseLandlordFlag === "yes",
    ...(data.leaseLandlordFlag === "yes" && { months: getMonths(data, "leaseLandlordMonth") }),
  },
  tenant_lease: {
    active: data.leaseTenantFlag === "yes",
    ...(data.leaseTenantFlag === "yes" && { months: getMonths(data, "leaseTenantMonth") }),
  },
  tenant_brokerage: {
    active: data.tenantBrokerageFlag === "yes",
    ...(data.tenantBrokerageFlag === "yes" && {
      ...(data.tenantBrokerageMonth !== 0 && {
        months: getMonths(data, "tenantBrokerageMonth"),
      }),
      brokerage: data.tenantBrokerage,
      closure: data.tenantBrokerageClosing,
    }),
  },
  landlord_brokerage: {
    active: data.landlordBrokerageFlag === "yes",
    ...(data.landlordBrokerageFlag === "yes" && {
      ...(data.landlordBrokerageMonth !== 0 && {
        months: getMonths(data, "landlordBrokerageMonth"),
      }),
      brokerage: data.landlordBrokerage,
      closure: data.landlordBrokerageClosing,
    }),
  },
  landlord_observation: data.landlordObservation,
  tenant_observation: data.tenantObservation,
});
