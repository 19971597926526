import {
  ControlledTextField,
  ControlledTextArea,
} from "Components/Admin/UIComponents/ControlledForm";
import { Typologies } from "context/multifamilyContext/typology/typologyTypes";

import useStyles from "./InputsStyles";

interface Props {
  typology: Typologies;
  hasId: boolean;
}

function Inputs({
  typology: {
    name,
    bedrooms,
    bathrooms,
    built_area,
    description,
    total_area,
    external_description,
  },
  hasId,
}: Props) {
  const classes = useStyles();
  const textFieldProps = {
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.background,
      },
      placeholder: "Ingresar",
    },
  };
  if (!name && hasId) return null;

  return (
    <div className={classes.container}>
      <div className={classes.leftColumn}>
        <ControlledTextField
          label="Nombre de la tipología"
          id="name"
          defaultValue={name}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextField
          label="Cantidad de habitaciones"
          id="bedrooms"
          defaultValue={bedrooms}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextField
          label="M2 construidos"
          id="built_area"
          defaultValue={built_area}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextArea
          label="Descripción Houm"
          id="description"
          defaultValue={description}
          placeholder="Escribe aquí tu texto"
          isGrey
        />
      </div>
      <div className={classes.rightColumn}>
        <ControlledTextField
          label="Cantidad de baños"
          id="bathrooms"
          defaultValue={bathrooms}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextField
          label="M2 totales"
          id="total_area"
          defaultValue={total_area}
          textFieldProps={{ ...textFieldProps }}
        />
        <ControlledTextArea
          label="Descripción portales externos"
          id="external_description"
          defaultValue={external_description}
          placeholder="Escribe aquí tu texto"
          isGrey
        />
      </div>
    </div>
  );
}

export default Inputs;
