import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import contractService from "services/contractService";
import { useGetToken } from "hooks";

interface IPromotionData {
  contract: number;
  promotion: number;
}

const useApplyPromotion = (
  onFetchSimulation: ({ contractId, save }) => void,
  contractId: number
) => {
  const queryClient = useQueryClient();
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess, error } = useMutation(
    async (data: IPromotionData) => {
      const authToken = await getToken();
      const response = await contractService.applyPromotion(data, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "La promoción se ha aplicado",
            type: "success",
          })
        );
        queryClient.invalidateQueries("promotiones_applied_by_contract_id");
        onFetchSimulation({
          contractId,
          save: false,
        });
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error al aplicar promoción",
            type: "error",
            body: `La promoción no se pudo aplicar, error: ${reason ?? e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useApplyPromotion;
