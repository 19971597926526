import { useState } from "react";
import Loading from "../molecules/Loading";
import { Grid } from "@material-ui/core";
import Swal from "sweetalert2";

import propertyService from "services/propertyService";
import { useGetToken } from "hooks";
import useProperty360VideoStyles from "./Property360VideoStyles";
import CustomTextField from "../CustomTextField";
import CustomButton from "../Buttons/CustomButton";

interface Props {
  property: any;
  property_uid: string;
}

function Property360Video({ property, property_uid }: Props) {
  const { getToken } = useGetToken();
  const [videoUrls, setVideoUrls] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useProperty360VideoStyles();

  const setVideoUrl = async () => {
    const authToken = await getToken();
    let videoUrl = videoUrls;
    [, videoUrl] = videoUrl.split("=");
    const myData = property;
    myData.video360_url = `https://my.matterport.com/show/?m=${videoUrl}`;
    const response = await propertyService.update(property_uid, myData, authToken);
    if (response) {
      setLoading(false);
      Swal.fire({
        type: "success",
        text: "El video ha sido publicado",
      }).then(() => {
        window.location.reload();
      });
      return response;
    }
    return undefined;
  };

  const deleteVideoUrl = async () => {
    const authToken = await getToken();
    const myData = property;
    myData.video360_url = null;
    const response = await propertyService.update(property_uid, myData, authToken);
    if (response) {
      setLoading(loading);
      Swal.fire({
        type: "success",
        text: "El video ha sido eliminado",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Loading loading={loading}>
      <div className={classes.container}>
        <h2>Tour 360 de la propiedad</h2>
        <p>(El nombre no puede tener espacios)</p>
        {property.video360_url && (
          <>
            <iframe
              title="propertyvideo"
              frameBorder="0"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              width={400}
              height={300}
              src={property.video360_url}
            />
            <br />
            <br />
            <CustomButton
              className={classes.deleteButton}
              active
              kind="secondary"
              onClick={() => {
                deleteVideoUrl();
              }}>
              Eliminar Video
            </CustomButton>
            <br />
            <br />
          </>
        )}
        <Grid xs={6}>
          <CustomTextField
            label="Url de video"
            onChange={(e) => {
              setVideoUrls(e.target.value);
            }}
            InputProps={{ placeholder: "example.mp4", disableUnderline: false }}
          />
        </Grid>
        <CustomButton
          active
          className={classes.mainButton}
          kind="blue"
          onClick={() => {
            setVideoUrl();
          }}>
          Subir video
        </CustomButton>
      </div>
    </Loading>
  );
}

export default Property360Video;
