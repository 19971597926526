import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { useProject } from "context/projectContext/projectContext";
import { createProject, editProject } from "context/projectContext/projectActions";
import { useFormNotifications, useGetToken } from "hooks";
import { requiredText } from "utils/yupValidators/property";
import validationSchema from "./validationSchema";
import {
  User,
  FormContainer,
  Address,
  RealEstate,
  ExpandedButton,
  Amenities,
  Price,
  Country,
  ProjectName,
  DeliveryStatus,
  Investment,
} from "Components/Admin/UIComponents/ProjectForm";
import { CreateProjectForm } from "models/Projects";
import { parseCountryCurrencyCode } from "utils";

interface Props {
  toggleEditModal?: (show: boolean) => any;
}

function ProjectForm({ toggleEditModal }: Props) {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProject();

  const resolver = yupResolver(validationSchema);
  const methods = useForm<CreateProjectForm>({
    resolver,
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<CreateProjectForm> = (data, e) => {
    const requestData = {
      ...data,
      for_sale: true,
      for_rental: false,
      currency: data.tipo_moneda_venta,
      association_amenities: {
        ...data.association_amenities,
        association_name: data.association_name,
        floors: data.floors,
      },
      sales_details: {
        currency_code: parseCountryCurrencyCode(data.country, data.tipo_moneda_venta),
        price: data.valor_venta,
      },
      property_details: {
        ...data.property_details,
        price: data.valor_venta,
        currency: data.currency,
      },
      ...(data.first_deposit && { first_deposit: data.first_deposit / 100 }),
      ...(data.notarial_costs && { notarial_costs: data.notarial_costs / 100 }),
    };
    if (state.project) {
      getToken()
        .then((authToken) => editProject(state?.project?.id, requestData, dispatch, authToken))
        .then(toggleEditModal(false));
    } else {
      getToken().then((authToken) => createProject(requestData, dispatch, authToken));
    }
  };

  const { errors } = methods.formState;
  useFormNotifications<CreateProjectForm>({ errors, requiredText });
  return (
    <FormProvider {...methods}>
      <Typography align="center" variant="h4">
        {state.project ? "Editar proyecto" : "Ingresar un nuevo proyecto"}
      </Typography>
      <FormContainer>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Country />
          <User />
          <ProjectName />
          <Address />
          <DeliveryStatus />
          <Price />
          <Investment />
          <RealEstate />
          {!state.isShorterForm && <Amenities />}
          <ExpandedButton />
        </form>
      </FormContainer>
    </FormProvider>
  );
}

export default ProjectForm;
