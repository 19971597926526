import { Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { parseField } from "context/letterOfIntent/letterOfIntent/utils";

import useInputFieldStyles from "./InputFieldStyles";

interface DocumentsDialogProps {
  field: string;
  value: string;
  type?: string;
  extraInfo?: string;
}

function InputField({ field, value, type, extraInfo }: DocumentsDialogProps) {
  const classes = useInputFieldStyles();

  if (type === "percentage") {
    return (
      <Grid container>
        <Grid item xs={6} md={6} lg={4} xl={2}>
          <ControlledTextField
            label=""
            id={field}
            key={field}
            defaultValue={parseField(field, value) || ""}
            textFieldProps={{
              textFieldClassName: classNames(classes.textField, classes.percentageField),
              InputProps: {
                endAdornment: "%",
                type: "number",
                classes: {
                  input: classes.inputField,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={8} xl={10}>
          <Typography className={classes.inputExtraInfo}>{extraInfo}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <ControlledTextField
        label=""
        disabled
        id={field}
        key={field}
        defaultValue={parseField(field, value) || ""}
        textFieldProps={{
          textFieldClassName: classes.textField,
          InputProps: {
            classes: {
              input: classes.inputField,
            },
          },
        }}
      />
    );
  }
}

export default InputField;
