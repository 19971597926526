import { makeStyles, Theme } from "@material-ui/core";

const useHeaderWithIconStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    alignItems: "center",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginLeft: 12,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "2em",
    margin: "0px 0px 20px 0px",
  },
}));

export default useHeaderWithIconStyles;
