import { v4 as uuidv4 } from "uuid";
import { parse } from "date-fns";

import { IRepairRequestBody, IRepairsRequestResponse } from "services/requestsService/types";
import requestsService from "services/requestsService/requestsService";
import { uploadToS3 } from "utils/fileStreaming";

import { IRepairsRequest, IRequestForm, IVisitHour } from "./types";

export interface IRepairRequest {
  requestForm: IRequestForm;
  visitHours?: IVisitHour[];
}

export const encodeRepairRequest = (data: IRepairRequest, filenames): IRepairRequestBody => {
  const { repairType, observation, requestorRole, responsibleTeam } = data.requestForm;
  const availabilities = data.visitHours.reduce((acc, { checked, hours }, index) => {
    if (!checked) return acc;

    const [start, end] = hours;
    for (let hour = start; hour < end; hour++) {
      acc.push({ day: index + 1, begin_hour: hour, end_hour: hour + 1 });
    }

    return acc;
  }, []);
  const photos = filenames.map((filename) => ({ photo_filename: filename }));
  return {
    repairment_type: repairType,
    responsible_team: responsibleTeam,
    observation,
    photos,
    availabilities,
    requestor_role: requestorRole,
    origin: "admin_houm",
  };
};

const getPhotoFileName = (name: string) => {
  const fileExtension = name.split(".").pop();
  const uuid = uuidv4();
  const fileName = `repairment_requests_${uuid}.${fileExtension}`;
  return fileName;
};

export const uploadRepairPhotos = async (token: string, photos: File[]): Promise<string[]> => {
  try {
    const fileNames = photos.map((file) => getPhotoFileName(file.name));
    const presignedUrls = await requestsService.getPresignedUrl(token, {
      file_names: fileNames,
    });
    Object.entries(presignedUrls.data).forEach(async ([fileName, presignedUrl], index) => {
      await uploadToS3({
        fileContents: photos[index],
        presignedUrl: { ...presignedUrl, filePath: fileName },
      });
    });
    return fileNames;
  } catch (error) {
    throw new Error("Error al subir las fotos");
  }
};

export const parseRepairsRequests = (data: IRepairsRequestResponse[]): IRepairsRequest[] =>
  data.map((request) => ({
    id: request.pk,
    observation: request.observation,
    repairmentType: request.repairment_type,
    createdAt: parse(request.created_at, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'", new Date()),
    requestorRole: request.requestor_role,
    photos: request.photos.map((photo) => ({ url: photo.photo_link })),
  }));
