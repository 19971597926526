import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  selected?: boolean;
}
const useCountryItemStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: (props) => (props.selected ? theme.palette.primary.light : null),
    padding: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: (props) =>
        props.selected ? theme.palette.grey[400] : theme.palette.grey[100],
    },
  },
  flagContainer: {
    maxWidth: "100px",
    overflow: "hidden",
    borderRadius: "100%",
  },
  svgFlagNameContainer: {
    paddingLeft: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(2),
  },
  nameContainer: {
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontSize: "0.9375rem",
  },
  numberCode: {
    paddingRight: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "0.9375rem",
  },
}));
export default useCountryItemStyles;
