import { makeStyles } from "@material-ui/core";

const useTableStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
}));

export default useTableStyles;
