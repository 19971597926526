import * as React from "react";
import { Grid, Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormBlock: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item className={classes.container}>
        {children}
      </Grid>
      <Divider />
    </>
  );
};

export default FormBlock;
