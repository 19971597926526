import { useEffect, useState } from "react";
import { useProperty } from "context/propertyContext/propertyContext";
import { getLocationOptions, createNeighborhood } from "context/propertyContext/propertyActions";
import { useFormContext, useWatch } from "react-hook-form";
import { useGetToken } from "hooks";
import {
  ControlledSelection,
  ControlledAddressText,
  ControlledTextField,
  ControlledAutocomplete,
  ControlledMap,
} from "Components/Admin/UIComponents/ControlledForm";
import { countryCode } from "utils";

import FormBlock from "./FormBlock";
import useUserFormRole from "hooks/useUserFormRole";
import { AxiosResponse } from "axios";

function Address() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProperty();
  const { property, address: stateAddress } = state;

  //const { control, reset, getValues, setValue } = useFormContext();
  const { control, setValue } = useFormContext();

  const role = useUserFormRole();

  const type = useWatch({
    control,
    name: "type",
    defaultValue: state.property?.type,
  });
  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.property?.country,
  });
  const address = useWatch({ control, name: "address_object" });

  const [coordinates, setCoordinates] = useState<[lng: number, lat: number]>([
    property?.property_details[0]?.longitud,
    property?.property_details[0]?.latitud,
  ]);

  useEffect(() => {
    getToken().then(async (authToken) => {
      const neighborhood = (await createNeighborhood(
        address,
        dispatch,
        authToken
      )) as AxiosResponse<any>;
      if (neighborhood?.data) {
        setValue("neighborhood", {
          value: neighborhood.data.id,
          label: `${neighborhood.data?.neighborhood}, ${neighborhood.data?.commune}, ${neighborhood.data?.region} `,
        });
        getLocationOptions(country, dispatch, authToken);
      }
    });
  }, [address]);

  useEffect(() => {
    if (stateAddress) {
      setValue("street_name", stateAddress?.street);
      setValue("street_commune", stateAddress?.commune);
      setValue("street_number", stateAddress?.streetNumber);
      setValue("coordinates", [stateAddress.lng, stateAddress.lat]);
      setValue("neighborhood", null);
      setCoordinates([stateAddress.lng, stateAddress.lat]);
    }
  }, [stateAddress]);

  useEffect(() => {
    if (country) {
      getToken().then((authToken) => getLocationOptions(country, dispatch, authToken));
    }
  }, [country]);

  return (
    <FormBlock>
      <ControlledSelection
        id="country"
        label="País"
        defaultValue={property?.country}
        options={[
          { value: "Chile", label: "Chile" },
          { value: "Colombia", label: "Colombia" },
          { value: "Mexico", label: "México" },
        ]}
      />
      <ControlledAddressText
        id="address_object"
        label="Dirección Completa (Nombre calle y número)"
        placeholder="Dirección..."
        defaultValue={
          property &&
          `${property?.address} ${property?.street_number}, ${property?.comuna}, ${property?.region}, ${property?.country}`
        }
        country={country}
      />

      <ControlledTextField
        id="street_number"
        label="Número calle"
        defaultValue={property?.street_number}
      />
      {Boolean(stateAddress?.street) && !stateAddress?.commune && (
        <ControlledAutocomplete id="custom_commune" label="Comuna" options={state?.communes} />
      )}
      <ControlledTextField
        id="second_line_address"
        label="Nro.Torre/Condominio o referencia (opcional)"
        defaultValue={property?.second_line_address}
      />
      <ControlledAutocomplete
        id="neighborhood"
        label="Barrio (opcional)"
        defaultValue={{
          value: state.property?.neighborhood?.id,
          label: state.property?.neighborhood
            ? `${state.property.neighborhood?.neighborhood}, ${state.property.neighborhood?.commune}, ${state.property.neighborhood?.region} `
            : "",
        }}
        options={state.neighborhoods}
      />

      {coordinates[0] && coordinates[1] && (
        <ControlledMap
          id="coordinates"
          label="Ubicación, puedes mover el pin para mejorar la precisión"
          country={property?.country && countryCode(property?.country)}
          defaultValue={coordinates}
        />
      )}

      <ControlledSelection
        id="type"
        label="Tipo de vivienda"
        defaultValue={property?.type}
        options={[
          { value: "departamento", label: "Departamento" },
          { value: "casa", label: "Casa" },
        ]}
      />
      {type === "departamento" && role !== "real_estate_developer" && (
        <ControlledTextField
          id="numero_depto"
          label="Número de departamento"
          defaultValue={property?.numero_depto ? property?.numero_depto.toString() : ""}
        />
      )}
    </FormBlock>
  );
}

export default Address;
