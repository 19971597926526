import NavbarMobile from "../Navbar/NavbarMobile";

export default function MainLayout({ children }: { children?: React.ReactNode }) {
  return (
    <>
      <NavbarMobile />
      <main className="py-10 lg:pl-72">
        <div className="w-full h-full overflow-y-auto px-6 py-8">{children}</div>
      </main>
    </>
  );
}
