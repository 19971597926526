import { Grid, Button } from "@material-ui/core";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import Title from "./Title";
import ControlledTextfieldStyled from "./Inputs/ControlledTextfieldStyled";
import ControlledSelectionStyled from "./Inputs/ControlledSelectionStyled";

import useScoreStyles from "./ScoreStyle";

import { ocupationType, rateType } from "./options";

function Score() {
  const classes = useScoreStyles();
  const {
    state: { profiling },
  } = useDemandLead();

  return (
    <>
      <Title text="Score Financiero" />
      <Grid container spacing={3}>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.dividend_min || ""}
            type="number"
            placeholder="2"
            label="Dividendo Min. "
            id="dividend_min"
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.dividend_max || ""}
            type="number"
            placeholder="2"
            label="Dividendo Max. "
            id="dividend_max"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.rate_type || 0}
            compressed
            fullWidth
            simple
            options={rateType}
            placeholder="fija"
            label="Tipo de renta / tásas"
            id="rate_type"
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.current_debt || ""}
            type="number"
            placeholder="2"
            label="Deuda actual"
            id="current_debt"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.type_of_occupation || 0}
            fullWidth
            simple
            options={ocupationType}
            placeholder="fija"
            label="Tipo de contrato"
            id="type_of_occupation"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <ControlledTextfieldStyled
            label="Profesión"
            id="profession"
            defaultValue={profiling?.profession || ""}
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.savings_capacity || ""}
            type="number"
            placeholder="0"
            startAdornment="$"
            grayLine
            label="Capacidad de ahorro"
            id="savings_capacity"
          />
        </Grid>
        <Button
          className={classes.submitButton}
          color="primary"
          variant="contained"
          size="small"
          type="submit"
          disableElevation>
          Guardar
        </Button>
      </Grid>
    </>
  );
}

export default Score;
