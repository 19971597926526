import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

import { classes } from "./RequestInfoStyles";
import { IDesertionProcessInfo } from "../../utils/types";
import { add, format } from "date-fns";

interface Props {
  desertionProcessInfo: IDesertionProcessInfo | null;
  liberationDays: number;
}
const RequestInfo = ({ desertionProcessInfo, liberationDays }: Props) => (
  <AdminCard title="Información de término de mandato" withSpace={false} showTitleBackground={true}>
    {!desertionProcessInfo && <span>No se ha iniciado el proceso de término de contrato</span>}
    {desertionProcessInfo && (
      <div className="flex flex-col gap-4">
        <div className="flex gap-6">
          <div className="flex flex-col">
            <span className={classes.label}>Fecha solicitud de salida: </span>
            <span className={classes.value}>
              {desertionProcessInfo ? format(desertionProcessInfo.startDate, "dd/MM/yyyy") : "-"}
            </span>
          </div>
          <div className="flex flex-col">
            <span className={classes.label}>Fecha de liberación de contrato: </span>
            <span className={classes.value}>
              {format(add(desertionProcessInfo.startDate, { days: liberationDays }), "dd/MM/yyyy")}
            </span>
          </div>
        </div>
      </div>
    )}
  </AdminCard>
);

export default RequestInfo;
