import { ClosureFormProvider } from "context/closureFormContext/closureFormContext";
import ContractInfo, { ContractInfoProps } from "./ContractInfo";

export default function ContractInfoController({ headless, MFUnitId }: ContractInfoProps) {
  return (
    <ClosureFormProvider>
      <ContractInfo headless={headless} MFUnitId={MFUnitId} />
    </ClosureFormProvider>
  );
}
