import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 15,
  },
  newPropertyButton: { width: 211, height: 36 },
  button: { height: 36 },
}));

export default useStyles;
