import { makeStyles } from "@material-ui/core";

const useSaveInformationStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: "none",
    borderRadius: 20,
    minWidth: 150,
    fontWeight: 700,
  },
  linkToCT: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  buttonContainers: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
}));

export default useSaveInformationStyles;
