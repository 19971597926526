import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { ContractModel } from "models/Contract";

interface Props {
  contract: ContractModel;
}

function SendProfileForm({ contract }: Props) {
  return (
    <>
      <ControlledTextField
        label="Nombre propietario"
        id="landlordName"
        textFieldProps={{ placeholder: "Nombre y apellido del propietario" }}
        defaultValue={
          contract.owner?.name ||
          `${contract.property?.user?.name} ${contract.property?.user?.last_name}`
        }
        disabled={true}
      />
      <ControlledTextField
        label="E-mail propietario"
        id="landlordEmail"
        textFieldProps={{ placeholder: "Nombre y apellido del propietario" }}
        defaultValue={contract.owner?.email || contract.property.user.email}
        disabled={true}
      />
      <ControlledKeyboardDatePicker
        id="initDate"
        label="Fecha inicio de contrato propuesto"
        defaultValue={new Date(`${contract?.init_date}T00:00:00`)}
        dataPickerProps={{
          format: "dd/MM/yyyy",
          disabled: true,
        }}
      />
    </>
  );
}

export default SendProfileForm;
