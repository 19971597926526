import { useCallback, useState } from "react";

import { InputLabel } from "@material-ui/core";

import { RemoteSuggester } from "Components/Admin/UIComponents";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import useStyles from "./FilterOptionsStyles";
import propertyService from "services/propertyService";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import {
  ExternalPortalPublicationsTableFilterTypes,
  filterInitialValues,
} from "Components/Admin/administrator/integrations/pages/externalPortalPublications/utils/filters";
import { businessTypesOptions } from "Components/Admin/administrator/integrations/utils/commonFilterOptions";
import { capitalize } from "lodash";
import userService from "services/userService";
import integrationService from "services/integrationService";
import {
  companiesResponseToSelectorParser,
  propertyResponseToSelectorParser,
  publicationTypeResponseToSelectorParser,
  standardParser,
  userResponseToSelectorParser,
} from "Components/Admin/administrator/integrations/utils/parsers";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import companyService from "services/companyService";

function FilterOptions() {
  const classes = useStyles();
  const { state } = useIntegration();
  const { state: tableState, dispatch } = useTable();
  const [filters, setFilters] = useState(filterInitialValues);

  const handleCloseModal = () => dispatch(setShowModal(false));

  const handleSubmit = useCallback(() => {
    const tableFilters: ExternalPortalPublicationsTableFilterTypes = {
      ...tableState.tableFilters,
      businessType: filters.businessType,
      company: filters.company?.id,
      executive: filters.executive?.id,
      error: filters.error,
      property: filters.property?.id,
      portal: filters.portal?.id,
      portalPlan: filters.portalPlan?.id,
      status: filters.status?.id,
    };
    dispatch(setTableFilters(tableFilters));
    handleCloseModal();
  }, [dispatch, filters]);

  const handleDelete = () => {
    setFilters(filterInitialValues);
    dispatch(setTableFilters({}));
    handleCloseModal();
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      fullWidth
      maxWidth="md">
      <div className={classes.contentRoot}>
        <RemoteSuggester
          label="ID de propiedad"
          value={null}
          onChange={(property) => setFilters({ ...filters, property })}
          indexService={propertyService.newIndexRequest}
          filters={{ country: capitalize(state.country) }}
          parser={propertyResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Selecciona una o más propiedades",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <div className={classes.selectorContainer}>
          <InputLabel className={classes.selectorLabel} id="business-type-selector-label">
            Línea de negocio
          </InputLabel>
          <SelectionOptions
            id="businessType"
            simple
            compressed={false}
            value={filters.businessType}
            onChange={(businessType) => setFilters({ ...filters, businessType })}
            options={businessTypesOptions}
            classes={{
              root: classes.selectorRoot,
            }}
          />
        </div>
        <RemoteSuggester
          defaultValue={null}
          value={filters.executive}
          onChange={(executive) => setFilters({ ...filters, executive })}
          indexService={userService.getUsers}
          filters={{ role: "seller", country: capitalize(state.country) }}
          label="Ejecutivo"
          parser={userResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Selecciona un ejecutivo",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Portal"
          value={filters.portal}
          onChange={(portal) => setFilters({ ...filters, portal })}
          indexService={integrationService.indexPortalNamesRequest}
          filters={{ country: state.country }}
          parser={standardParser}
          textFieldProps={{
            placeholder: "Selecciona un portal",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Destaque"
          value={filters.portalPlan}
          onChange={(portalPlan) => setFilters({ ...filters, portalPlan })}
          indexService={integrationService.indexPublicationTypesNamesRequest}
          filters={{
            country: state.country,
            portal: filters.portal?.id as string,
            business: filters.businessType,
          }}
          parser={publicationTypeResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Selecciona un destaque",
          }}
          classes={{ label: classes.suggesterLabel }}
          disabled={!filters.portal}
        />
        <RemoteSuggester
          label="Estado"
          value={filters.status}
          onChange={(status) => setFilters({ ...filters, status })}
          indexService={integrationService.indexStatusesNamesRequest}
          textFieldProps={{
            placeholder: "Selecciona un estado",
          }}
          filters={{}}
          parser={standardParser}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Compañía"
          value={filters.company}
          onChange={(company) => setFilters({ ...filters, company })}
          indexService={companyService.indexCompaniesRequest}
          textFieldProps={{
            placeholder: "Selecciona una compañía",
          }}
          filters={{}}
          parser={companiesResponseToSelectorParser}
          classes={{ label: classes.suggesterLabel }}
        />
        <div className={classes.selectorContainer}>
          <CustomTextField
            label="Error"
            bordered
            value={filters.error}
            onChange={(e) => setFilters({ ...filters, error: e.target.value })}
            placeholder="Filtrar por error"
            labelClassName={classes.suggesterLabel}
            textFieldClassName={classes.textfieldPadding}
          />
        </div>
      </div>
      <FilterDialog.Actions
        applyTitle="Filtrar"
        onDelete={handleDelete}
        onApply={handleSubmit}
        classes={{ applyButton: classes.applyFiltersButton }}
      />
    </FilterDialog>
  );
}

export default FilterOptions;
