import { Theme, makeStyles } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

const useChangeDiscountFormStyles = makeStyles((theme: Theme) => ({
  formContent: {
    width: "100%",
  },
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  selectionLabel: {
    transform: "none",
    position: "relative",
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(1),
  },
  selectionContainer: {
    margin: 0,
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useChangeDiscountFormStyles;
