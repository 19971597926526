import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export enum STEPS {
  Selection = 0,
  Visit = 1,
}

const useRepairsRequestSteps = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.Selection);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const handleNextStep = async () => {
    switch (currentStep) {
      case STEPS.Selection:
        setCurrentStep(STEPS.Visit);
        return null;
      default:
        return null;
    }
  };

  const handleBackStep = async () => {
    switch (currentStep) {
      case STEPS.Visit:
        setCurrentStep(STEPS.Selection);
        return null;
      default:
        return null;
    }
  };

  const handleError = () => {
    setCurrentStep(STEPS.Selection);
  };

  const handleClose = () => {
    history.push(`/admin/clients/${id}`);
  };

  return {
    currentStep,
    handleNextStep,
    handleBackStep,
    handleError,
    handleClose,
  };
};

export default useRepairsRequestSteps;
