import { Polygon, SourceFeature } from "models/Mapbox";
import { StringOption } from "models/Option";
import { PropertyData } from "models/Property";

export enum ActionType {
  SET_CITIES,
  SET_MC_BY_CITY,
  SET_MACROZONES,
  SET_MODAL_MESSAGE,
  SET_LOADER,
}

export interface MacrozoneSource {
  id: string;
  source: SourceFeature;
  color: string;
}

export interface ModalMessage {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
}

export interface State {
  cities: StringOption[];
  macrozoneList: MacrozoneSource[];
  macrozonesByCity: Record<string, MacrozoneByCity[]>;
  property: PropertyData;
  modalMessage: ModalMessage;
  loaders: Loaders;
}

type SetCities = {
  type: typeof ActionType.SET_CITIES;
  cities: StringOption[];
};

type SetModalMessage = {
  type: typeof ActionType.SET_MODAL_MESSAGE;
  modalMessage: ModalMessage;
};

type SetMacrozonesByCity = {
  type: typeof ActionType.SET_MC_BY_CITY;
  macrozonesByCity: Record<string, MacrozoneByCity[]>;
};

type SetMacrozoneSources = {
  type: typeof ActionType.SET_MACROZONES;
  macrozoneList: MacrozoneSource[];
};

type setLoaders = {
  type: typeof ActionType.SET_LOADER;
  loaders: Loaders;
};

export type Action =
  | SetCities
  | SetMacrozonesByCity
  | SetMacrozoneSources
  | SetModalMessage
  | setLoaders;

export type Dispatch = (action: Action) => void;

export enum LoaderType {
  GET_MC,
  UPDATED,
}

export type Loaders = {
  [key in LoaderType]?: boolean;
};

export interface MacrozoneByCity {
  city: string;
  name: string;
  id: number;
  polygon: Polygon;
}
