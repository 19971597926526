import { makeStyles, Theme } from "@material-ui/core";

const useObservationModalStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
  },
}));

export default useObservationModalStyles;
