import { State, Action, SET_OPEN_ALERT, SET_ALERT_INFO, CLEAR_ALERT_INFO } from "./alertTypes";

export const initialState: State = {
  open: false,
  alertInfo: {
    open: false,
    head: null,
    body: null,
    type: null,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_OPEN_ALERT: {
      return { ...state, open: action.open };
    }
    case SET_ALERT_INFO: {
      return { ...state, alertInfo: action.alertInfo };
    }
    case CLEAR_ALERT_INFO: {
      return { ...state, alertInfo: action.alertInfo };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
