import { useRef, useImperativeHandle, forwardRef, useEffect, useState } from "react";
import * as React from "react";

import { Select, MenuItem, FormControl, SelectProps } from "@material-ui/core";
import { useSelectStyles } from "./SelectStyle";

import HelperText from "./HelperText";
import Label from "./Label";

interface IPropsSelect extends SelectProps {
  id: string;
  label: string;
  required?: boolean;
  options: string[];
  value?: string;
  onChangeValue?: Function;
}
function DefaultSelect(
  { id, label = "default", required, options, onChangeValue, value, ...props }: IPropsSelect,
  ref
) {
  const [current, setCurrent] = useState<string | number | null>("");
  const [error, setError] = useState<boolean>(false);
  const [txtError, setTxtError] = useState<string>("");

  useEffect(() => {
    if (value) {
      deleteErrors();
    }
  }, [value]);

  const deleteErrors = () => {
    setError(false);
    setTxtError("");
  };
  const divRef = useRef<HTMLDivElement>();
  useImperativeHandle(ref, () => ({
    setValue(val) {
      setCurrent(val);
    },
    getValue() {
      return { [id]: value || current };
    },
    scrollToMe() {
      divRef?.current.scrollIntoView();
    },
    validate() {
      if (required && !value && !current) {
        divRef?.current.scrollIntoView();
        setError(true);
        setTxtError("Este campo es necesario");
        return false;
      }
      return true;
    },
  }));

  const classes = useSelectStyles();
  return (
    <FormControl error={error} required={required} ref={divRef}>
      <Label id={`${label}-select`}>{label}</Label>

      <Select
        MenuProps={{
          classes: {
            list: classes.menu,
          },
        }}
        classes={{
          selectMenu: classes.menuItem,
        }}
        className={classes.select}
        labelId={`${label}-select`}
        value={value || current}
        onChange={(e: React.ChangeEvent<any>) => {
          if (onChangeValue) {
            onChangeValue(e);
          }
          setCurrent(e.target.value);
          deleteErrors();
        }}
        {...props}>
        <MenuItem
          key=""
          value=""
          classes={{
            root: classes.menuItem,
          }}
        />
        {options.map((val) => (
          <MenuItem
            key={val}
            value={val}
            classes={{
              root: classes.menuItem,
            }}>
            {val}
          </MenuItem>
        ))}
      </Select>
      <HelperText hidden={!error}>{txtError}</HelperText>
    </FormControl>
  );
}
export default forwardRef(DefaultSelect);
