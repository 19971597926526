import { useState } from "react";

import { Grid, Typography } from "@material-ui/core";

import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import ButtonSelect from "Components/Admin/UIComponents/Buttons/ButtonSelect";
import { useTable } from "context/tableContext/tableContext";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import { TableFilters } from "context/tableContext/tableTypes";
import { handleSetMultiFilter } from "context/tableContext/tableUtils";
import { inspectionReportsTypes } from "./options";

function FilterOptions() {
  const [creationDateStart, setCreationDateStart] = useState<string | null>(null);
  const [creationDateEnd, setCreationDateEnd] = useState<string | null>(null);
  const [typeArrayFilter, setTypeArrayFilter] = useState<string[]>([]);

  const { state: tableState, dispatch: tableDispatch } = useTable();

  const handleFilterModal = () => tableDispatch(setShowModal(false));

  const handleSubmit = () => {
    const filters: TableFilters = {
      ...tableState.tableFilters,
      type__in: typeArrayFilter.join(", "),
      date_after: creationDateStart,
      date_before: creationDateEnd,
    };
    tableDispatch(setTableFilters(filters));
    tableDispatch(setShowModal(false));
  };

  const handleDelete = () => {
    setCreationDateStart(null);
    setCreationDateEnd(null);
    setTypeArrayFilter([]);
    tableDispatch(setTableFilters({}));
    tableDispatch(setShowModal(false));
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleFilterModal}
      onEscapeKeyDown={handleFilterModal}>
      <FilterDialog.Content>
        <FilterDialog.Item label="Tipo de acta">
          <Grid container spacing={1}>
            {inspectionReportsTypes.map((item) => (
              <Grid item key={item.label}>
                <ButtonSelect
                  label={item.label}
                  isActive={typeArrayFilter.includes(item.value)}
                  onClick={() => {
                    handleSetMultiFilter(item.value, typeArrayFilter, setTypeArrayFilter);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </FilterDialog.Item>
        <FilterDialog.Item label="Fecha Creación">
          <div className="mr-2">
            <Typography variant="body2">Desde</Typography>
            <FilterDialog.TextField
              type="date"
              value={creationDateStart}
              onChange={(e) => setCreationDateStart(e.target.value)}
            />
          </div>
          <div className="mr-2">
            <Typography variant="body2">Hasta</Typography>
            <FilterDialog.TextField
              type="date"
              value={creationDateEnd}
              onChange={(e) => setCreationDateEnd(e.target.value)}
            />
          </div>
        </FilterDialog.Item>
      </FilterDialog.Content>
      <FilterDialog.Actions onDelete={handleDelete} onApply={handleSubmit} />
    </FilterDialog>
  );
}

export default FilterOptions;
