import { SnackbarProvider } from "notistack";

import TagsForm from "./TagsForm";

export default function TagForm() {
  return (
    <SnackbarProvider>
      <TagsForm />
    </SnackbarProvider>
  );
}
