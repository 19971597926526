import * as yup from "yup";

import { validate } from "utils/yupValidators/project";

const {
  country,
  user,
  address_object,
  street_number,
  type,
  floors,
  valor_venta,
  custom_commune,
  association_name,
  project_delivery_month,
  tipo_moneda_venta,
  project_delivery_year,
  development_status,
} = validate;

export default yup.object({
  country,
  user,
  address_object,
  association_name,
  street_number,
  custom_commune,
  type,
  tipo_moneda_venta,
  valor_venta,
  floors,
  project_delivery_month,
  project_delivery_year,
  development_status,
});
