export default {
  container: "h-full overflow-y-scroll pr-4",
  titleContainer: "w-full bg-secondary-10 my-2",
  title: "font-bold text-h4 p-1",
  subtitle: "font-bold text-h4 my-1 mt-4",
  commentHead: "flex items-center justify-between",
  text: "px-1 text-secondary-80",
  infoContainer: "flex items-center justify-left my-2",
  tag: "ml-2 py-1 px-2 font-bold w-fit italic rounded",
  contractTag: "text-black-100 bg-secondary-20",
  publishedTag: "text-black-100 bg-success-20",
  unpublishedTag: "text-black-100 bg-error-20",
};
