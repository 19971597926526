import { Container, Typography } from "@material-ui/core";

import useQueryDisclaimerStyles from "./QueryDisclaimerStyles";
import ServiceDetails from "../ServiceDetails";

function QueryDisclaimer() {
  const classes = useQueryDisclaimerStyles();

  return (
    <Container className={classes.disclaimerContainer}>
      <Typography className={classes.text}>
        Estamos obteniendo la siguiente información, por favor espera un poco hasta que obtengamos
        la información para actualizar la página
      </Typography>
      <ServiceDetails disabled />
    </Container>
  );
}

export default QueryDisclaimer;
