import { useState, useMemo, useCallback, useEffect } from "react";

import { GOOGLE_MAPS_API_KEY } from "env";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest/";
import { StandardTextFieldProps } from "@material-ui/core";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { RawAddress } from "models";

import useStyles from "./AddressSuggesterStyles";

export interface Props {
  id: string;
  label: string;
  value: RawAddress;
  locale?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (a: any) => void;
  noResultMessage?: string;
  textFieldProps?: StandardTextFieldProps;
}

const localeMapper = {
  Colombia: "co",
  Chile: "cl",
  México: "mx",
  Mexico: "mx",
};

function AddressSuggester({
  id,
  label,
  value,
  onChange,
  defaultValue,
  placeholder,
  locale = "Chile",
  noResultMessage = "No se encontraron resultados",
  textFieldProps,
}: Props) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [inputValue, setInputValue] = useState(value?.formatted_address || "");

  const country = useMemo(() => localeMapper[locale] || "", [locale]);

  const handleSelectSuggest = (geocodedPrediction) => {
    setSearch("");
    setInputValue(geocodedPrediction.formatted_address);
    onChange(geocodedPrediction);
  };

  const handleNoResults = () => {
    setSearch("");
    setInputValue("");
    onChange("");
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setInputValue(value?.formatted_address);
    }
  }, [value]);

  const Prediction = useCallback(
    (prediction) => (
      <div className={classes.list}>{prediction ? prediction.description : noResultMessage}</div>
    ),
    [noResultMessage]
  );

  return (
    <ReactGoogleMapLoader
      params={{
        key: GOOGLE_MAPS_API_KEY,
        libraries: "places,geocode",
        language: "es",
      }}
      render={(googleMaps) =>
        googleMaps && (
          <ReactGooglePlacesSuggest
            googleMaps={googleMaps}
            autocompletionRequest={{
              input: search,
              componentRestrictions: {
                country,
              },
            }}
            onSelectSuggest={handleSelectSuggest}
            onNoResult={handleNoResults}
            customRender={Prediction}
            textNoResults={noResultMessage}>
            <CustomTextField
              label={label}
              error
              autoComplete="off"
              className={classes.textFieldRoot}
              id={id}
              defaultValue={defaultValue}
              placeholder={placeholder}
              value={inputValue}
              onChange={(d) => {
                setInputValue(d.target.value);
                setSearch(d.target.value);
              }}
              variant="standard"
              {...textFieldProps}
            />
          </ReactGooglePlacesSuggest>
        )
      }
    />
  );
}

export default AddressSuggester;
