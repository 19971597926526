import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import PlanFormProps from "Components/Admin/administrator/plans/pages/PlansForm/types";
import { parseMonthlyData, parsePostPlanData } from "context/plansContext/utils";
import { CountryUpperCase } from "models/Countries";
import planService from "services/planService";
import useGetToken from "hooks/useGetToken";

type CreatePlanProps = {
  data: PlanFormProps;
  cities: number[];
  country: CountryUpperCase;
};

const useCreatePlan = () => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const history = useHistory();

  const {
    mutate: createPlan,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useMutation(
    async ({ data, country, cities }: CreatePlanProps) => {
      const authToken = await getToken();
      const planData = {
        ...data,
        monthlyData: parseMonthlyData(data),
      };
      const response = await planService.postPlan(
        parsePostPlanData(planData, country, cities),
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Plan creado exitosamente",
        });
        queryClient.invalidateQueries(["getPlans"]);
        setTimeout(() => {
          history.push("/admin/plans");
        }, 1000);
      },
      onError: () => {
        alert.error({
          message: "El plan no pudo ser creado",
        });
      },
    }
  );

  return {
    createPlan,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useCreatePlan;
