import { createHttp } from "services/helper";
import qs from "query-string";
import { InspectionReportService, InspectionReportServiceResponse } from "./types";

const inspectionReportService: InspectionReportService = (token: string) => {
  const httpClient = createHttp(token);
  const getInspectionsReport: InspectionReportServiceResponse["getInspectionsReport"] = ({
    propertyId,
    pageSize = 15,
    page = 1,
    view = "filtered",
    filters = {},
  }) =>
    httpClient.get(
      `/properties/v2/${propertyId}/inspection_forms/?page_size=${pageSize}&page=${page}&view=${view}&ordering=-created_at${qs.stringify(
        filters
      )}`
    );
  return {
    getInspectionsReport,
  };
};

export default inspectionReportService;
