import { Hidden } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import houmLogo from "assets/images/houmLogo.png";

import useStyles from "./CustomNavbarStyles";

interface Props {
  onClose: () => void;
}

export default function CustomNavbar({ onClose }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <a href="/propietary/properties">
        <img src={houmLogo} alt="logo" className={classes.navbarImg} />
      </a>
      <Hidden smUp>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Hidden>
    </div>
  );
}
