import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import TabPanel from "Components/Admin/UIComponents/Tabs/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import MarketplaceTags from "./MarketplaceTags/MarketplaceTags";
import TagForm from "./TagForm";

const tabOptions = [
  { label: "Tags creados", value: "tags" },
  { label: "Crear Tag", value: "create" },
];

function Tags() {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <>
      <TabMenu options={tabOptions} defaultValue="tags" />
      <TabPanel value={currentTabValue} index="tags">
        <MarketplaceTags />
      </TabPanel>
      <TabPanel value={currentTabValue} index="create">
        <TagForm />
      </TabPanel>
    </>
  );
}

export default Tags;
