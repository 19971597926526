import ModalDialog from "@houm-com/ui/ModalDialog";

import { Locale } from "models/Countries";

import SpotPaymentForm from "./components/SpotPaymentForm";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  propertyId: number;
  landlordId: number;
  subscriptionId: number;
  country: Locale;
}

function CreateSpotPaymentModal({
  open,
  handleOnClose,
  propertyId,
  landlordId,
  subscriptionId,
  country,
}: Props) {
  return (
    <ModalDialog
      isOpen={open}
      title="Pago spot"
      subtitle={`Restituir dinero a suscripción ${subscriptionId}`}
      onClose={handleOnClose}
      hasCloseBtn
      size="lg">
      <SpotPaymentForm
        propertyId={propertyId}
        userId={landlordId}
        subscriptionId={subscriptionId}
        country={country}
        handleOnCloseModal={handleOnClose}
      />
    </ModalDialog>
  );
}

export default CreateSpotPaymentModal;
