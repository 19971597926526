import { UseQueryResult, useQuery } from "react-query";

import useGetToken from "hooks/useGetToken";
import propertyService from "services/propertyService";

import { parseSchedules } from "./utils/parsers";
import { ISchedule } from "./utils/types";

const useGetSchedules = (propertyId: number) => {
  const { getToken } = useGetToken();

  const {
    data: schedules,
    isLoading,
    isError,
  }: UseQueryResult<ISchedule[], Error> = useQuery<ISchedule[], Error>(
    ["getSchedules", propertyId],
    async () => {
      const token = await getToken();
      const response = await propertyService.getPropertySchedules(propertyId, token);
      return parseSchedules(response.data.results);
    },
    {
      enabled: !!propertyId,
    }
  );

  return {
    schedules,
    isLoading,
    isError,
  };
};

export default useGetSchedules;
