import { SimilarExternalProperty, SimilarExternalPropertiesResponse } from "models/Calculator";
import { SimilarProperty } from "models/createProperty";
import { slightlyModifyCoordinates } from "utils/coordinates";
import { formatCurrency } from "utils/formatCurrency";
import { normalizeCommuneName, normalizeRegionName } from "utils/normalizer";

export const similarPricesDataset = (data: SimilarExternalPropertiesResponse) => ({
  for_sale: toMapboxDataset(data.for_sale),
  for_rental: toMapboxDataset(data.for_rental),
});

const toMapboxDataset = (data: SimilarExternalProperty[]) => {
  const positions = data.map((d) => ({
    geometry: {
      type: "Feature",
      coordinates: slightlyModifyCoordinates([d.coordinates.longitude, d.coordinates.latitude]),
    },
    properties: {
      price: formatCurrency(d.price.currency, d.price.amount),
      beds: d.specs.bedrooms,
      baths: d.specs.bathrooms,
      totalArea: d.specs.total_area,
      link: d.link,
    },
  }));
  return { type: "FeatureCollection", features: positions };
};

export const similarPropertiesDataset = (data: SimilarProperty[]) => {
  const positions = data.map((d, i) => ({
    geometry: {
      type: "Feature",
      coordinates: slightlyModifyCoordinates([
        d.property_details[0].longitud,
        d.property_details[0].latitud,
      ]),
    },
    properties: {
      price: formatCurrency(d.price[0].currency, d.price[0].value),
      coverPhoto: d.photos[0] ? d.photos[0].url : "",
      propertyUid: d.uid,
      propertyId: d.id,
      id: i,
      type: d.type,
      region: normalizeRegionName(d.neighborhood?.region ?? ""),
      commune: normalizeCommuneName(d.neighborhood?.commune ?? ""),
      coverPhotoSrc: d.photos?.[0] ? d.photos[0].url : "",
      beds: d.property_details[0].dormitorios,
      baths: d.property_details[0].banos,
      totalArea: d.property_details[0].m_terreno,
    },
  }));
  return { type: "FeatureCollection", features: positions };
};
