import { useEffect, useState } from "react";
import { Upload } from "@progress/kendo-react-upload";
import { useGetToken } from "hooks";
import useStyles from "./PhotosGalleryStyles";

function PhotoUploader(props) {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    getToken().then((authToken) => setToken(authToken));
  }, []);

  return (
    <div>
      <Upload
        className={classes.uploader}
        batch={false}
        restrictions={{
          allowedExtensions: [".jpg"],
        }}
        multiple
        saveHeaders={{
          Authorization: `Bearer ${token}`,
        }}
        defaultFiles={[]}
        withCredentials={false}
        saveUrl={`${props.url}/${props.uid}/${props.post}`}
        removeUrl={`${props.url}/${props.uid}/${props.delete}`}
      />
    </div>
  );
}

export default PhotoUploader;
