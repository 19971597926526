import { Grid } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";

import CustomTabs from "Components/Admin/UIComponents/CustomTabs";
import { usePlans } from "context/plansContext/plansContext";
import MonthlyTable from "../../layouts/monthlyTable";
import useStyles from "./createPlanStyles";

interface Props {
  adminCommission: string;
}

export default function MonthlyData({ adminCommission }: Props) {
  const { state } = usePlans();
  const { control } = useFormContext();
  const monthsNumber = useWatch({
    control,
    name: "duration",
    defaultValue: state.planData.duration,
  });

  const tabs = [
    {
      index: 0,
      header: "Cuotas de administración",
      component: (
        <MonthlyTable
          id="managementFee"
          monthsNumber={Math.min(monthsNumber, 12)}
          defaultValue={adminCommission}
        />
      ),
    },
    {
      index: 1,
      header: "Cuotas pago arriendo propietario",
      component: (
        <MonthlyTable
          id="lessorPayment"
          monthsNumber={Math.min(monthsNumber, 12)}
          defaultValue="100"
        />
      ),
    },
    {
      index: 2,
      header: "Cuotas pago arrendatario",
      component: (
        <MonthlyTable
          id="lesseePayment"
          monthsNumber={Math.min(monthsNumber, 12)}
          defaultValue="100"
        />
      ),
    },
    {
      index: 3,
      header: "Corretaje arrendatario",
      component: (
        <MonthlyTable
          id="lesseeBrokerage"
          monthsNumber={Math.min(monthsNumber, 12)}
          defaultValue="0"
        />
      ),
    },
    {
      index: 4,
      header: "Corretaje propietario",
      component: (
        <MonthlyTable
          id="lessorBrokerage"
          monthsNumber={Math.min(monthsNumber, 12)}
          defaultValue="0"
        />
      ),
    },
  ];

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <CustomTabs
        tabsContent={tabs}
        aria="plans"
        tabsProps={{ classes: { root: classes.tabs } }}
        customIndex={state.currentTab}
      />
    </Grid>
  );
}
