import { useMutation } from "react-query";

import { useGetToken } from "hooks";
import QuestionsService from "services/questionsService";
import { useAlert } from "context/alertContext/alertContext";
import { setAlertInfo } from "context/alertContext/alertActions";

interface IUseRepairRequestAction {
  onSuccessHandler?: () => void;
}

interface AnswerData {
  answer: string;
  questionId: number;
}

const useAnswerQuestion = ({ onSuccessHandler }: IUseRepairRequestAction) => {
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();

  const repairRequestMutation = useMutation(
    async (data: AnswerData) => {
      const { answer, questionId } = data;
      const authToken = await getToken();
      const questionsService = QuestionsService(authToken);
      return questionsService.answerPropertyQuestion({
        questionId,
        answer,
      });
    },
    {
      onSuccess: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "La respuesta ha sido enviada correctamente",
            type: "success",
          })
        );
        onSuccessHandler();
      },
      onError: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "Ha habido un problema al enviar la respuesta",
            type: "error",
          })
        );
      },
    }
  );

  return {
    answerQuestion: repairRequestMutation.mutate,
    isLoading: repairRequestMutation.isLoading,
  };
};

export default useAnswerQuestion;
