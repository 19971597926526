import { useMutation } from "react-query";
import { SweetAlertResult } from "sweetalert2";

import { useGetToken } from "hooks";
import contractService from "services/contractService";

import { parsePlanForm } from "../utils/utils";
import { CountryUpperCase } from "models/Countries";
import { PlanProps } from "../utils/types";
import { alert } from "libs/alerts";

interface PostPlan {
  data: PlanProps;
  result: SweetAlertResult;
  country: CountryUpperCase;
  validationText: string;
  brokerage: boolean;
  contractId: number;
}

const usePostPlan = () => {
  const { getToken } = useGetToken();

  const {
    mutate: postPlan,
    mutateAsync: postPlanAsync,
    isLoading: submittingPlan,
  } = useMutation(
    async ({ data, result, country, validationText, brokerage, contractId }: PostPlan) => {
      const isChile = country === "Chile";
      if ((isChile && result.value === validationText) || (!isChile && result.value)) {
        const authToken = await getToken();
        const formData = parsePlanForm(data, brokerage);
        await contractService.setPlan(contractId, formData, authToken);
      } else if (isChile && result.value !== validationText) {
        alert.error({
          message: "Error",
          disclaimer: "Escriba el texto de validación correctamente",
        });
      }
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Contrato Actualizado",
          disclaimer: "El plan se ha asignado al contrato exitosamente",
          onClose: () => window.location.reload(),
        });
      },
      onError: () => {
        alert.error({
          message: "Error al asignar plan",
          disclaimer: "No se pudo asignar plan al contrato, contactarse con soporte",
        });
      },
    }
  );
  return { postPlan, postPlanAsync, submittingPlan };
};

export default usePostPlan;
