import { Theme, makeStyles } from "@material-ui/core/styles";

const useClosingDetailsStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1.4),
  },
  text: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  buttonContainer: {
    textAlign: "end",
  },
  divider: {
    width: "95%",
  },
  gridContainer: {
    width: "100%",
  },
  rootList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  inline: {
    display: "inline",
  },
  listHead: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
  },
  positive: {
    color: "#00982D",
  },
  negative: {
    color: "#F02C2C",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useClosingDetailsStyles;
