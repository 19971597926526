import { makeStyles, Theme } from "@material-ui/core";

const useReadjustmentDetailStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: theme.spacing(0, 0.25),
  },
  period: {
    padding: theme.spacing(1, 0.5),
  },
  descriptionTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  valueText: {
    fontSize: theme.typography.pxToRem(12),
  },
  periodTitle: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 700,
  },
  periodDate: {
    fontSize: theme.typography.pxToRem(12),
    width: "100%",
  },
  variation: {
    fontSize: theme.typography.pxToRem(12),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export default useReadjustmentDetailStyles;
