import { makeStyles, Theme } from "@material-ui/core";

const useComunidadFelizCompanyFormStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttons: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  textInput: {
    background: theme.palette.grey[50],
    border: 4,
    paddingLeft: theme.spacing(1),
  },
  disclaimerText: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    marginLeft: theme.spacing(1),
  },
  disclaimerContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useComunidadFelizCompanyFormStyles;
