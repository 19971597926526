import clx from "classnames";

import { Typography } from "@material-ui/core";
import { IoCloseOutline as NoPhotosIcon, IoCameraOutline as PhotosIcon } from "@houm-com/ui-icons";

import usePhotosButtonStyles from "./PhotosButtonStyles";

interface Props {
  onClick: () => void;
  hasPhotos: boolean;
}

const PhotosButton = ({ onClick, hasPhotos }: Props) => {
  const classes = usePhotosButtonStyles();
  const label = hasPhotos ? "Ver Fotos" : "Fotos";
  return (
    <Typography
      className={clx(classes.label, {
        [classes.button]: hasPhotos,
      })}
      onClick={hasPhotos ? onClick : null}>
      {!hasPhotos && <NoPhotosIcon size="20" />}
      {label}
      {hasPhotos && <PhotosIcon size="18" />}
    </Typography>
  );
};

export default PhotosButton;
