import { makeStyles, Theme } from "@material-ui/core";

const usePropertyDetailsStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  button: {
    borderRadius: "50%",
    minWidth: 40,
    marginTop: theme.spacing(1),
  },
  selectionLabel: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  selectionButtonLabel: {
    transform: "none",
    position: "relative",
  },
  selectionContainer: {
    marginTop: theme.spacing(1),
  },
  formInput: {
    marginTop: theme.spacing(1),
    borderBottom: "1px solid black",
    "&.Mui-disabled": {
      borderBottom: "none",
    },
  },
  checkbox: {
    marginLeft: theme.spacing(0),
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  backButton: {
    minWidth: 0,
  },
}));

export default usePropertyDetailsStyles;
