import { useEffect, useState } from "react";
import { Upload } from "@progress/kendo-react-upload";
import { useGetToken } from "hooks";
import "./photouploader.css";
import { BACKEND_WORKER_URL } from "env";

function PhotoUploader({ photos, property }) {
  const { getToken } = useGetToken();
  const [token, setToken] = useState();

  useEffect(() => {
    getToken().then((authToken) => setToken(authToken));
  }, []);

  return (
    <div>
      <Upload
        batch={false}
        restrictions={{
          allowedExtensions: [".jpg"],
        }}
        multiple
        saveHeaders={{
          Authorization: `Bearer ${token}`,
        }}
        defaultFiles={[]}
        withCredentials={false}
        saveUrl={`${BACKEND_WORKER_URL}propietary/v2/properties/${property.uid}/photos/`}
        removeUrl={`${BACKEND_WORKER_URL}propietary/v2/properties/${property.uid}/photos/remove`}
      />
    </div>
  );
}

export default PhotoUploader;
