import { StepProps } from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { CountryUpperCase } from "models/Countries";
import PlanProps, { MonthlyDataProps } from "models/Plan";

export interface State {
  steps: StepProps[];
  selectedCountry: CountryUpperCase;
  planData: PlanProps;
  readyToSubmit: boolean;
  currentTab: {
    id: number;
    isActive: boolean;
  };
}

export const SET_MONTHLY_DATA = "SET_MONTHLY_DATA";
export const SET_PLAN_DATA = "SET_PLAN_DATA";
export const SET_STEPS = "SET_STEPS";
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";

interface setMonthlyData {
  type: typeof SET_MONTHLY_DATA;
  monthlyData: MonthlyDataProps[];
}

interface setPlanData {
  type: typeof SET_PLAN_DATA;
  planData: Partial<PlanProps>;
}

interface setCurrentTabValue {
  type: typeof SET_CURRENT_TAB;
  currentTab: {
    id: number;
    isActive: boolean;
  };
}

interface setSteps {
  type: typeof SET_STEPS;
  steps: StepProps[];
}

export type Action = setPlanData | setMonthlyData | setSteps | setCurrentTabValue;

export type Dispatch = (action: Action) => void;
