export const SET_PROPERTY_INFO = "SET_PROPERTY_INFO";
export const SET_PROPERTY_ID = "SET_PROPERTY_ID";
export const CLEAR_PROPERTY = "CLEAR_PROPERTY";
export const SET_LOADING_DATA = "SET_LOADING_DATA";
export const SET_PROPERTY_SCHEDULE = "SET_PROPERTY_SCHEDULE";
export const SET_LOADING_SCHEDULE = "SET_LOADING_SCHEDULE";
export const SET_MULTIPROPERTY_ID = "SET_MULTIPROPERTY_ID";

interface setPropertyInfo {
  type: typeof SET_PROPERTY_INFO;
  propertyData: PropertyData;
}

interface setPropertyId {
  type: typeof SET_PROPERTY_ID;
  propertyId: number;
}

interface clearProperty {
  type: typeof CLEAR_PROPERTY;
}

interface setLoadingData {
  type: typeof SET_LOADING_DATA;
  loadingData: boolean;
}

interface setLoadingSchedule {
  type: typeof SET_LOADING_SCHEDULE;
  loadingSchedule: boolean;
}

interface setPropertySchedule {
  type: typeof SET_PROPERTY_SCHEDULE;
  propertyAvailability: TimeBlock[];
}
interface setMultiproperyId {
  type: typeof SET_MULTIPROPERTY_ID;
  multiPropertyId: number[];
}

export interface State {
  propertyId: number;
  propertyInfo: PropertyData;
  availableDays: TimeBlock[];
  propertyAvailability: TimeBlock[];
  loadingData: boolean;
  loadingSchedule: boolean;
  multiPropertyId: number[];
}

export type Dispatch = (action: Action) => void;

export interface PropertyData {
  country?: string;
  keysState?: string;
  location?: string;
  address?: string;
  houmbox?: boolean;
}

export interface AvailabilityForm {
  [x: string]: boolean;
}

export interface PropertyForm {
  id: number;
}

export interface TimeBlock {
  beginHour: number;
  endHour: number;
  day: number;
}

export type Action =
  | setPropertyInfo
  | setPropertyId
  | clearProperty
  | setLoadingData
  | setPropertySchedule
  | setLoadingSchedule
  | setMultiproperyId;
