import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  button: {
    borderRadius: 50,
    minWidth: 140,
    padding: theme.spacing(1, 2),
    maxHeight: 40,
    textAlign: "center",
    textTransform: "none",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
  },
  formContainer: {
    marginBottom: theme.spacing(2.5),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  loading: {
    fontSize: "10px !important",
  },
}));
