import useProperty from "../useProperty";
import useUFValue from "../useUFValue";

const usePropertyPriceRequired = ({ propertyId }) => {
  const { data, isLoading, isError: isPropertyError, isSuccess } = useProperty({ propertyId });
  const {
    data: dataUF,
    isLoading: isLoadingUF,
    isError,
    isSuccess: isSuccessUF,
  } = useUFValue({
    currency: data?.propertyDetails?.currency,
  });

  const getPropertyPrice = () => {
    if (dataUF) {
      return data.propertyDetails.price * dataUF.rate;
    }
    return data.propertyDetails.price;
  };

  return {
    getPropertyPrice,
    isLoading: isLoadingUF || isLoading,
    isError: isError || isPropertyError,
    isSuccess: isSuccess || isSuccessUF,
  };
};

export default usePropertyPriceRequired;
