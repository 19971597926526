import { makeStyles, Theme } from "@material-ui/core";

const useLandlordTableStyles = makeStyles((theme: Theme) => {
  const defaultBorder = `1px solid  ${theme.palette.grey[50]} !important`;
  const defaultBorderRadius = "8px";
  return {
    container: { width: "100%", height: "100%", marginTop: theme.spacing(1) },
    root: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "white !important",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderWidth: 0,
        height: "40px !important",
      },
      "& .MuiDataGrid-row": {
        fontSize: theme.typography.pxToRem(12),
        outline: defaultBorder,
        borderRadius: defaultBorderRadius,
        outlineOffset: -4,
        "&:hover": {
          backgroundColor: "white",
        },
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "1.66 !important",
        whiteSpace: "normal",
        padding: theme.spacing(0, 1),
        "& span": {
          display: "block",
        },
      },
      "& .MuiDataGrid-columnHeaderWrapper": {
        marginBottom: theme.spacing(0.5),
      },
    },
    headerCell: {
      backgroundColor: `${theme.palette.grey["50"]} !important`,
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 700,
        color: theme.palette.grey[500],
      },
    },
    firstHeaderCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
    },
    lastRenderedHeaderCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
    },
  };
});

export default useLandlordTableStyles;
