import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  filtersContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 250px)",
    gap: theme.spacing(1),
  },
  selectorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectorRoot: {
    height: 28,
    padding: 0,
    borderBottom: "0.8px solid #ECEFF1",
  },
  suggesterLabel: {
    color: theme.palette.grey[500],
  },
  selectorLabel: {
    color: theme.palette.grey[500],
    fontWeight: 700,
    marginBottom: 0,
    padding: theme.spacing(0.5, 0),
  },
  quotaSummary: {
    borderRadius: 16,
    padding: theme.spacing(2, 3),
    maxWidth: 300,
  },
  quotaSummaryText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
  },
  searchButton: {
    borderRadius: 200,
  },
  buttonContainer: {
    marginLeft: theme.spacing(3),
  },
  editionButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  buttonSpinner: {
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
