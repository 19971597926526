import { usePagination } from "@material-ui/lab/Pagination";
import PageNumber from "./components/PageNumber";
import PaginationArrow from "./components/PaginationArrow";

import usePageSelectorStyles from "./PageSelectorStyles";

interface Props {
  pageCount: number;
  page: number;
  setCurrentPage: (n: number) => void;
}

function PageSelector({ pageCount, page, setCurrentPage }: Props) {
  const classes = usePageSelectorStyles();

  const { items } = usePagination({
    count: pageCount,
    page,
    onChange: (e, n) => setCurrentPage(n),
    siblingCount: 0,
  });

  return (
    <ul className={classes.ul}>
      {items.map(({ page, type, selected, ...item }, index) => {
        if (type === "start-ellipsis" || type === "end-ellipsis") return <li key={index}>…</li>;

        if (type === "page")
          return (
            <li key={index}>
              <PageNumber selected={selected} page={page} {...item} />
            </li>
          );
        if (type === "previous")
          return (
            <li key={index}>
              <PaginationArrow type="previous" {...item} />
            </li>
          );
        return (
          <li key={index}>
            <PaginationArrow type="next" {...item} />
          </li>
        );
      })}
    </ul>
  );
}

export default PageSelector;
