import { FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useProperty } from "context/propertyContext/propertyContext";
import { keysInfoValidationSchema } from "./validationSchema";
import { useGetToken } from "hooks";
import { getProperty, updateKeysInfo } from "context/propertyContext/propertyActions";
import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";
import keyField from "./fields";

function KeysLocationCard() {
  const { state, dispatch } = useProperty();

  const { goNextStep, label, methods, currentStep, goBackStep } = useVisitCard({
    resolver: yupResolver(keysInfoValidationSchema),
  });

  const keysInfo = state.property?.keys_info;

  const fields = keyField({ content: keysInfo, currentStep });
  const { getToken } = useGetToken();

  const handleSubmit = async (data) => {
    const authToken = await getToken();
    const isUpdated = await updateKeysInfo(state.property.uid, data.keysInfo, authToken, dispatch);
    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          id="keys_info"
          items={fields}
          title={<TitleCard title="Ubicación de llaves (comentarios)" />}
          buttonOptions={{
            buttonLabel: label,
            buttonCallback: goNextStep,
            buttonPosition: "right",
            isSubmit: currentStep === "editable",
          }}
          // HOTFIX: to allow CT to edit the key fields
          isEditable={true}
          // isEditable={!keysData}
        />
      </form>
    </FormProvider>
  );
}

export default KeysLocationCard;
