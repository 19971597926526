export default {
  title: "text-h24 font-bold mb-8 mt-8",
  tag: "max-w-max ml-4",
  tagContainer: "flex items-center",
  head: "flex justify-between",
  item: "mb-6 mt-6",
  itemText: "font-bold mb-2",
  citiesContainer: "grid grid-cols-5 gap-4 text-left",
  backButton: "cursor-pointer font-bold text-primary-cta flex items-center",
};
