import { Locale } from "models/Countries";
import { IPrevData } from "./EvaluateType";
import { format } from "rut.js";

export interface EvaluationProps {
  street?: string;
  city?: string;
  name: string;
  lastName: string;
  secondLastName: string;
  trials: boolean;
  salary1: string;
  salary2: string;
  salary3: string;
  salaryOthers: string;
  salaryAvg: number;
  avla: true;
  comment: string;
  confirmedSalary: string;
  contractType: string;
  dependent: boolean;
  document: string;
  employer: string;
  nationality: string;
  occupation: string;
  position: string;
  salaryMonths: string;
  salaryOthersDescription: string;
  trialsComment: string;
  visa: string;
  documentType: string;
  scoreBuro: number;
  rfcNumber: string;
}

export const parseData = (prevData: IPrevData, dataForm: EvaluationProps, country: Locale) => ({
  ...prevData,
  name: dataForm.name,
  last_name: dataForm.lastName,
  second_last_name: dataForm.secondLastName,
  last_names: `${dataForm.lastName} ${dataForm.secondLastName}`, // we keep last_names together for legacy support and for the next steps that assume last_names as one entity
  document: country === "cl" ? format(dataForm.document) : dataForm.document,
  documentType: dataForm?.documentType,
  trials: dataForm.trials,
  trials_comment: dataForm.trialsComment,
  occupation: dataForm.occupation,
  employer: dataForm.employer,
  position: dataForm.position,
  contract_type: dataForm.contractType,
  salary_months: dataForm.salaryMonths,
  salary_1: dataForm.salary1,
  salary_2: dataForm.salary2,
  salary_3: dataForm.salary3,
  salary_others: dataForm.salaryOthers,
  salary_others_description: dataForm.salaryOthersDescription,
  salary_avg: dataForm.salaryAvg,
  dependent: dataForm.dependent,
  confirmed_salary: dataForm.confirmedSalary,
  comment: dataForm.comment,
  nationality: dataForm.nationality,
  visa: dataForm.visa,
  avla: dataForm.avla,
  scoreBuro: dataForm.scoreBuro,
  rfc_number: dataForm?.rfcNumber,
  ...(country === "cl" && {
    address: {
      city: dataForm.city,
      street: dataForm.street,
    },
  }),
});

export const getAverageSalary = (firstSalary, secondSalary, thirdSalary, otherSalary): number => {
  const salaries = [];
  if (firstSalary && firstSalary > 0) {
    const salaryAux1 = parseFloat(firstSalary);
    salaries.push(salaryAux1);
  }
  if (secondSalary && secondSalary > 0) {
    const salaryAux2 = parseFloat(secondSalary);
    salaries.push(salaryAux2);
  }
  if (thirdSalary && thirdSalary > 0) {
    const salaryAux3 = parseFloat(thirdSalary);
    salaries.push(salaryAux3);
  }
  if (otherSalary && otherSalary > 0) {
    const salaryAuxOthers = parseFloat(otherSalary);
    salaries.push(salaryAuxOthers);
  }
  if (salaries.length)
    return Math.trunc(salaries.reduce((acc, current) => acc + current, 0) / salaries.length);
};
