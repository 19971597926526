import { makeStyles, Theme } from "@material-ui/core/styles";

const useDeleteModalStyles = makeStyles((theme: Theme) => ({
  content: {
    fontSize: theme.typography.pxToRem(18),
    maxWidth: "fit-content",
    marginTop: theme.spacing(1),
  },
  modal: {
    textAlign: "center",
    padding: theme.spacing(0, 3),
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(3),
    width: "100%",
    justifyContent: "center",
  },
  cancelButton: {
    fontSize: "1rem",
  },
}));

export default useDeleteModalStyles;
