import { useEffect, useState } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";
import { BACKEND_URL } from "env";

import { useGetToken } from "hooks";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import {
  createMultifamily,
  editMultiFamily,
  getMultifamily,
  getMultifamilyPhotos,
  setCurrentId,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import { parseBody } from "Components/Admin/administrator/multifamily/CreateMF/utils/parseBody";
import { MultifamilyFormData } from "context/multifamilyContext/multifamily/multifamilyTypes";
import validationSchema from "./validationSchema";
import Checkboxs from "../Checkboxs/Checkboxs";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Inputs from "../Inputs/Inputs";
import PhotosGallery from "../ImageUploader/PhotosGallery";

import useStyles from "./FormStyles";

function Form() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getToken } = useGetToken();
  const {
    dispatch,
    state: { currentId, multifamily, MFImages },
  } = useMultifamily();

  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(setCurrentId(parseInt(id)));
      getToken().then((authToken) => {
        getMultifamily(dispatch, parseInt(id), authToken);
        getMultifamilyPhotos(dispatch, parseInt(id), authToken);
      });
    }
  }, [id]);

  const methods = useForm<MultifamilyFormData>({
    resolver: yupResolver(validationSchema(!!id)),
    reValidateMode: "onSubmit",
  });
  const onSubmit: SubmitHandler<MultifamilyFormData> = async (data) => {
    const body = parseBody(data);

    if (!id && !isCreated) {
      getToken().then((authToken) => {
        createMultifamily(dispatch, body, authToken);
      });
    } else {
      getToken().then((authToken) => {
        editMultiFamily(currentId, body, authToken);
      });
    }

    if (isCreated) history.push(`/admin/multifamily/${currentId}`);
    setIsCreated(!isCreated);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {!isCreated && <Inputs multifamily={multifamily} hasId={!!id} />}
        {isCreated && (
          <>
            {currentId && (
              <PhotosGallery
                photos={MFImages}
                type="multifamily"
                uid={currentId.toString()}
                url={`${BACKEND_URL}properties/v2/multifamily`}
                post="photos/"
                delete="delete_photo/"
                extensions={[".jpg"]}
                headerName="Imágenes del multifamily"
              />
            )}
            <Checkboxs amenities={multifamily?.association_amenities} />
          </>
        )}
        <div className={isCreated ? classes.rightButton : null}>
          <DesignSystemButton
            label={isCreated ? "Guardar" : "Guardar y continuar"}
            type="submit"
            size="medium"
            variant="primary"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default Form;
