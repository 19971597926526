import { useEffect, useMemo } from "react";
import { useGetToken } from "hooks";
import { useParams, useLocation } from "react-router-dom";

import { useLead } from "context/leadContext/leadContext";
import { useTab } from "context/tabContext/tabContext";
import { getLead } from "context/leadContext/leadActions";
import { Filters as LeadFilters } from "context/leadContext/leadTypes";
import { LeadProfileTab, LeadPropertiesTab } from "./tabs";
import BackButton from "Components/Admin/UIComponents/Buttons/BackButton";
import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import TabPanel from "Components/Admin/UIComponents/Tabs/TabPanel";
import LeadVisitsTab from "./tabs/LeadVisitsTab";
import { tabOptions } from "./components/LeadConsts";

function LeadShow() {
  const { id } = useParams<{ id: string }>();
  const { dispatch } = useLead();
  const { getToken } = useGetToken();
  const location = useLocation<{ defaultTab: any }>();

  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  const mappedFilters = useMemo(() => {
    const filters: LeadFilters = {
      tab: currentTabValue,
    };
    return filters;
  }, [currentTabValue]);

  useEffect(() => {
    getToken().then((authToken) => {
      if (currentTabValue) getLead(id, mappedFilters, dispatch, authToken);
    });
  }, [id, getLead, mappedFilters]);

  return (
    <>
      <BackButton arrow backTo="/admin/supply/leads/">
        Volver
      </BackButton>
      <TabMenu
        options={tabOptions}
        defaultValue={location?.state?.defaultTab ? location?.state?.defaultTab : "lead"}
      />
      <TabPanel value={currentTabValue} index="lead">
        <LeadProfileTab />
      </TabPanel>
      <TabPanel value={currentTabValue} index="properties">
        <LeadPropertiesTab />
      </TabPanel>
      <TabPanel value={currentTabValue} index="visits">
        <LeadVisitsTab />
      </TabPanel>
    </>
  );
}

export default LeadShow;
