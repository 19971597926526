export const departmentType = [
  { label: "Loft", value: "loft" },
  { label: "Estudio", value: "studio" },
  { label: "Duplex", value: "duplex" },
  { label: "Triplex", value: "triplex" },
  { label: "Penthouse", value: "penthouse" },
  { label: "Mariposa", value: "butterfly" },
];

export const closetValues = [
  { label: "Closet", value: "closet" },
  { label: "Walk in closet", value: "walk_in" },
];

export const translatedDepartmentType = (typeValue: string) => {
  const type = departmentType.find((type) => type.value === typeValue);
  return type ? type.label : "";
};

export const translatedClosetType = (typeValue: string) => {
  const type = closetValues.find((type) => type.value === typeValue);
  return type ? type.label : "";
};
