import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    transition: "0.3s",
    textDecoration: "none",
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(0.5),
    "&:not([href]):not([tabindex]), &:not([href]):not([tabindex]):hover": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      color: theme.palette.primary.main,
      filter: "brightness(1.05)",
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
}));
