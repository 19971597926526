import { useCallback, useMemo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import { GridColumns } from "@material-ui/x-grid";

import { CustomDataGrid } from "Components/Admin/UIComponents";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useLead } from "context/leadContext/leadContext";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import {
  getPropertyDetails,
  setMultiproperyId,
} from "context/availabilityContext/availabilityActions";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import DefaultPicture from "assets/images/defaultPropertyPhoto.png";
import { useGetToken } from "hooks";
import useLeadsVisitsStyles from "./LeadVisitsStyles";
import useTableStyles from "Components/Admin/administrator/supply/utils/TableStyles";

function LeadVisits() {
  const classes = useLeadsVisitsStyles();
  const tableClasses = useTableStyles();
  const { state } = useLead();
  const { dispatch } = useAvailability();
  const { getToken } = useGetToken();
  const [leadSelection, setLeadSelection] = useState<number[]>([]);
  const { state: tableState, dispatch: tableDispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const selectMultiProperties = () => {
    dispatch(setMultiproperyId(leadSelection));
    getToken().then((authToken) => getPropertyDetails(leadSelection[0], dispatch, authToken));
  };

  const renderData = useCallback(() => state.leadProperties, [state.leadProperties]);

  const columnsConfig = {
    sortable: false,
    filterable: false,
    flex: 1,
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "photos",
        headerName: "Foto",
        align: "center",
        renderCell: (params) => (
          <img
            alt="property"
            className={classes.tablePropertyPhoto}
            src={params.row.photoUrl || DefaultPicture}
          />
        ),
      },
      {
        ...columnsConfig,
        field: "id",
        headerName: "ID",
        align: "center",
        flex: 0.7,
        renderCell: (params) => <Typography component="span">{params.row.id}</Typography>,
      },

      {
        ...columnsConfig,
        field: "address",
        flex: 2,
        headerName: "Dirección",
        renderCell: (params) => <Typography component="span">{params.row.address}</Typography>,
      },
      {
        ...columnsConfig,
        field: "user",
        headerName: "Propietario",
        flex: 1.5,
        renderCell: (params) => <Typography component="span">{params.row.user}</Typography>,
      },
      {
        ...columnsConfig,
        field: "owner",
        headerName: "Ejecutivo propietario",
        flex: 1,
        renderCell: (params) => <Typography component="span">{params.row.owner}</Typography>,
      },
      {
        ...columnsConfig,
        field: "formatedPrice",
        headerName: "Precio",
        flex: 1,
        renderCell: (params) => (
          <Typography component="span">{params.row.formatedPrice}</Typography>
        ),
      },
      {
        ...columnsConfig,
        field: "showProperty",
        flex: 1.3,
        headerName: " ",
        renderCell: (params) => (
          <LinkButton
            onClick={() => {
              dispatch(setMultiproperyId([params.row.id]));
              getToken().then((authToken) =>
                getPropertyDetails(params.row.id, dispatch, authToken)
              );
            }}>
            Ver horarios disponibles
          </LinkButton>
        ),
        headerClassName: "hideColumnLine",
      },
    ],
    [state.leadProperties]
  );

  return (
    <>
      <Typography className={classes.title}>Visita propiedades</Typography>
      {leadSelection.length > 0 && (
        <Grid container className={classes.createButtonContainer} justifyContent="flex-end">
          <CustomButton
            active
            onClick={() => selectMultiProperties()}
            className={classes.createButton}>
            {`Modificar ${leadSelection.length}`}
          </CustomButton>
        </Grid>
      )}
      <div className={tableClasses.container}>
        <CustomDataGrid
          className={classNames(tableClasses.table, classes.visitsTable)}
          rowCount={state.leadPropertiesCount}
          columns={columns}
          rows={renderData()}
          loading={state.loading}
          page={currentPage - 1}
          pageSize={pageSize}
          onPageChange={(params) => tableDispatch(setCurrentPage(params + 1))}
          onSortModelChange={(model) => tableDispatch(setSorting(model))}
          onPageSizeChange={(params) => {
            tableDispatch(setPageSize(params));
            tableDispatch(setCurrentPage(1));
          }}
          disableMultipleSelection={false}
          checkboxSelection={true}
          onSelectionModelChange={(e) => {
            setLeadSelection(e as number[]);
          }}
          selectionModel={leadSelection}
          getRowClassName={() => "testing"}
        />
      </div>
    </>
  );
}

export default LeadVisits;
