import { UseQueryResult, useQuery } from "react-query";

import useGetToken from "hooks/useGetToken";
import { IPaymentOption } from "models/Plan";
import planService from "services/planService";

const useGetMandateTypes = (country) => {
  const { getToken } = useGetToken();

  const {
    data: mandateTypes,
    isLoading,
    isError,
  }: UseQueryResult<IPaymentOption[], Error> = useQuery<IPaymentOption[], Error>(
    ["getMandateTypes"],
    async () => {
      const token = await getToken();
      const newCountry = country === "México" ? "Mexico" : country;
      const response = await planService.getOptionList(newCountry, token);
      return response.data;
    }
  );

  return {
    mandateTypes,
    isLoading,
    isError,
  };
};

export default useGetMandateTypes;
