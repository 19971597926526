import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flex: "1",
    alignItems: "flex-end",
  },
  textFieldContainer: { flex: 2 },
  bold: { fontWeight: "bold" },
}));

export default useStyles;
