import { CountryUpperCase, Regions } from "models/Countries";

export const options = [
  { label: "", value: "" },
  { label: "Perdido", value: "Perdido" },
  { label: "Seguimiento", value: "Seguimiento" },
];

export const originOptions = [
  { label: "Whatsapp", value: "Whatsapp" },
  { label: "Llamada", value: "Call" },
];

//Options for city in advisory
export interface AdvisoryCity {
  label: string;
  value: number;
  country: CountryUpperCase;
  region?: Regions;
}

export const cityOptions = [
  { label: "Bogotá", value: 1, country: "Colombia" },
  { label: "Medellín", value: 2, country: "Colombia" },

  { label: "Santiago", value: 3, country: "Chile" },
  { label: "Valparaíso", value: 4, country: "Chile" },
  { label: "Concepción", value: 7, country: "Chile" },

  { label: "CDMX", value: 14, country: "Mexico" },
  { label: "Guadalajara", value: 15, country: "Mexico" },
  { label: "Monterrey", value: 38, country: "Mexico" },
  { label: "Tijuana", value: 39, country: "Mexico", region: "Baja California" },
];
