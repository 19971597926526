import { IoChevronForwardOutline as ExpandIcon } from "@houm-com/ui-icons";
import { cn } from "@houm-com/ui/utils";

import classes from "./TablePaginationStyles";
import usePagination from "../../hooks/usePagination";

interface TablePaginationProps {
  limit: number;
  total: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  pageNumbersLimit?: number;
}

interface PaginationItemProps {
  page: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

function PaginationItem({ page, currentPage, setCurrentPage }: PaginationItemProps) {
  return (
    <span
      key={page}
      onClick={() => setCurrentPage(page - 1)}
      className={cn(classes.item, { [classes.currentItem]: page - 1 === currentPage })}>
      {page}
    </span>
  );
}

export default function TablePagination({
  limit,
  total,
  currentPage,
  setCurrentPage,
  pageNumbersLimit = 10,
}: TablePaginationProps) {
  const { totalPages, pageNumbers, showFirstItem, showLastItem } = usePagination({
    limit,
    total,
    currentPage,
    pageNumbersLimit,
  });
  return (
    <div className={classes.container}>
      {currentPage > 0 && (
        <ExpandIcon onClick={() => setCurrentPage(currentPage - 1)} className={classes.leftIcon} />
      )}
      {showFirstItem && (
        <>
          <PaginationItem page={1} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          <span className={classes.ellipsis}>...</span>
        </>
      )}
      {pageNumbers.map((n) => (
        <PaginationItem
          key={n}
          page={n}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ))}
      {showLastItem && (
        <>
          <span className={classes.ellipsis}>...</span>
          <PaginationItem
            page={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
      {currentPage < totalPages - 1 && (
        <ExpandIcon onClick={() => setCurrentPage(currentPage + 1)} className={classes.rightIcon} />
      )}
    </div>
  );
}
