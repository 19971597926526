import { useMemo, memo } from "react";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { setIsScheduling } from "context/demandLeadContext/schedule/demandScheduleActions";
import { SimilarProperty } from "models/createProperty";

import MarketplacePropertyCard from "Components/Admin/administrator/properties/show/partials/MarketplacePropertyCard";

import SchedulerMap from "./SchedulerMap";
import ScheduleButton from "./ScheduleButton";
import useSelecetedPropertyStyles from "./SelectedPropertyStyles";

function SelectedProperty() {
  const classes = useSelecetedPropertyStyles();
  const {
    state: { lead },
  } = useDemandLead();

  const {
    state: { property },
    dispatch,
  } = useDemandSchedule();

  const deal = useMemo(() => {
    if (lead) {
      const singularDeal = lead?.status?.business_type?.slice(0, -1) as "rental" | "sale";
      return singularDeal;
    }
  }, [lead]);
  const handleSchedule = () => {
    dispatch(setIsScheduling(true));
  };

  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        <MarketplacePropertyCard
          property={
            {
              ...property,
              price: property.price || [
                {
                  value: property?.sales_details?.price,
                  currency: property?.sales_details?.currency_code,
                },
              ],
            } as unknown as SimilarProperty
          }
          deal={deal}
          locale={property?.neighborhood?.country_code}
        />
        <div className={classes.buttonContainer}>
          <ScheduleButton id="scheduleButton" onClick={handleSchedule}>
            Agendamiento
          </ScheduleButton>
        </div>
      </div>
      <SchedulerMap />
    </div>
  );
}

export default memo(SelectedProperty);
