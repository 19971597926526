import { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";

import { GridColumns, GridColDef } from "@material-ui/x-grid";
import { getCodelessNumber, getCountryPhone } from "utils/verifyPhoneNumber";

import { Activity } from "models/DemandLead";

import {
  emptyString,
  empytCell,
  formatedDate,
} from "Components/Admin/administrator/demand/utils/cells";

import {
  setIsOpenActivityForm,
  setCompleteActivityConfig,
} from "context/demandLeadContext/lead/demandLeadActions";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";

import { useTable } from "context/tableContext/tableContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { OriginCell, StatusCell, SymbollCell, ContactCell, PropertyCell } from "./Cells";

import TokyButton from "./TokyButton";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();
  const {
    state: { isLoading, activities, lead },
    dispatch: demandDispatch,
  } = useDemandLead();

  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize } = tableStates;

  useEffect(() => dispatch(setPageSize(50)), []);

  const handleEditActivity = (row) => {
    demandDispatch(setIsOpenActivityForm(true));
    demandDispatch(setCompleteActivityConfig(true, row as Activity));
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "Actividades",
      field: "color",
      flex: 0.9,
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ row }) => <SymbollCell row={row as Activity} />,
    },
    {
      ...columnsConfig,
      field: "contact",
      cellClassName: classes.centerCell,
      type: "date",
      headerName: "Fecha de contacto",
      renderCell: ({ row }) => <ContactCell row={row as Activity} />,
    },
    {
      ...columnsConfig,
      field: "expiration",
      type: "date",
      headerName: "Vencimiento",
      cellClassName: classes.centerCell,
      sortable: false,
      renderCell: ({ value }) => formatedDate(value),
    },
    {
      ...columnsConfig,
      field: "property",
      headerName: "ID Propiedad",
      cellClassName: classes.centerCell,
      sortable: false,
      renderCell: ({ row }) => <PropertyCell row={row as Activity} />,
    },
    {
      ...columnsConfig,
      field: "origin",
      headerName: "Origen / Medio",
      cellClassName: classes.centerCell,

      sortable: false,
      renderCell: ({ row }) => <OriginCell row={row as Activity} />,
    },
    {
      ...columnsConfig,
      field: "state",
      cellClassName: classes.centerCell,

      headerName: "Estado",
      sortable: false,
      renderCell: ({ row }) => <StatusCell row={row as Activity} />,
    },
    {
      ...columnsConfig,
      field: "comments",
      cellClassName: classes.rightCell,
      headerName: "Observaciones",
      headerClassName: classes.lastRenderedHeaderCell,
      sortable: false,
      renderCell: ({ value }) => empytCell(value),
    },
    {
      ...columnsConfig,
      field: "toComplete",
      headerClassName: classes.lastHeaderCell,
      headerName: "Completar",
      resizable: false,
      flex: 2,
      sortable: false,
      renderCell: ({ row }) =>
        !row?.toComplete ? (
          { emptyString }
        ) : (
          <a
            className={classes.link}
            onClick={() => {
              demandDispatch(setIsOpenActivityForm(true));
              demandDispatch(setCompleteActivityConfig(true, row as Activity));
            }}>
            Completar
          </a>
        ),
    },
    {
      ...columnsConfig,
      field: "toComplete",
      resizable: false,
      headerName: "",
      flex: 0.5,
      headerClassName: classes.lastHeaderCell,
      sortable: false,
      renderCell: ({ row }) =>
        !row?.toComplete && (
          <>
            <IconButton id="editActivity" color="primary" onClick={() => handleEditActivity(row)}>
              <CreateIcon fontSize="small" />
            </IconButton>
            <TokyButton
              activity={row as Activity}
              targetDialNumber={{
                country: getCountryPhone(lead.lead.country),
                phone: getCodelessNumber(lead?.lead.phone, lead?.lead.country),
              }}
            />
          </>
        ),
    },
  ];

  return (
    <div className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        rowCount={activities?.length || 0}
        columns={columns}
        rows={
          activities?.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
          ) || []
        }
        loading={isLoading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
};

export default Table;
