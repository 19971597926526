import * as yup from "yup";

import { intNumber } from "utils/yupValidators";

const requiredText = "Campo obligatorio";

const validationSchema = yup.object({
  qualification: yup.string().required(requiredText),
  observation: yup.string().nullable(),
  quantity: intNumber.typeError(requiredText).required(requiredText),
});

export default validationSchema;
