import { styled, Theme, Box, Typography } from "@material-ui/core";

export const HeaderRoot = styled(Box)<Theme>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
  justifyContent: "space-between",
}));

export const HeaderContent = styled(Box)<Theme>(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(3),
  "& > *:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

export const HeaderLogo = styled(Box)<Theme>(({ theme }) => ({
  maxHeight: 50,
  "& img": {
    maxHeight: 40,
    objectFit: "contain",
  },
}));

export const HeaderTitle = styled(Typography)<Theme>(({ theme }) => ({
  fontWeight: 700,
  maringBottom: theme.spacing(1),
}));

export const HeaderText = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: "0.875rem",
  maringBottom: theme.spacing(1),
  "& span": {
    fontWeight: 700,
  },
}));
