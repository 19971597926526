import { makeStyles, Theme } from "@material-ui/core";

const useProfileIndexStyles = makeStyles((theme: Theme) => ({
  topContainer: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  calendar: {
    flexBasis: "68%",
    flexGrow: 0,
    flexShrink: 0,
  },
}));

export default useProfileIndexStyles;
