import { ActionType, Action, State } from "./demandScheduledTypes";

export const initialState: State = {
  country: "Chile",
  filters: {
    beginDate: null,
    endDate: null,
    houmer: null,
    agent: null,
    search: null,
    status: null,
    thirdCoord: null,
  },
  isLoading: false,
  isOpenFilterModal: false,
  isOpenInfoModal: false,
  isOpenNewsModal: false,
  isOpenCancelModal: false,
  isOpenAsignCtAgentModal: false,
  isOpenAsignPropietaryModal: false,
  isOpenAsignFailModal: false,
  isOpenAsignSuccessModal: false,
  isOpenAsignProcessingModal: false,
  contactInfo: null,
  news: null,
  errorMsg: null,
  roles: null,
  ctAgent: null,
  scheduleId: null,
  appraiserId: null,
  visits: [],
  houmers: [],
  cancelReasons: [],
  agents: [],
  totalVisits: 10,
  deal: null,
};

const demandLeadReducer = (state, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_COUNTRY:
      return {
        ...state,
        country: action.country,
      };

    case ActionType.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case ActionType.SET_IS_OPEN_FILTER_MODAL:
      return { ...state, isOpenFilterModal: action.isOpenFilterModal };

    case ActionType.SET_IS_OPEN_INFO_MODAL:
      return { ...state, isOpenInfoModal: action.isOpenInfoModal };

    case ActionType.SET_IS_OPEN_NEWS_MODAL:
      return { ...state, isOpenNewsModal: action.isOpenNewsModal };

    case ActionType.SET_IS_OPEN_CANCEL_MODAL:
      return { ...state, isOpenCancelModal: action.isOpenCancelModal };

    case ActionType.SET_IS_OPEN_ASIGN_CT_AGENT_MODAL:
      return {
        ...state,
        isOpenAsignCtAgentModal: action.isOpenAsignCtAgentModal,
      };

    case ActionType.SET_IS_OPEN_ASIGN_PROPIETARY_MODAL:
      return {
        ...state,
        isOpenAsignPropietaryModal: action.isOpenAsignPropietaryModal,
      };

    case ActionType.SET_IS_OPEN_ASIGN_FAIL_MODAL:
      return {
        ...state,
        isOpenAsignFailModal: action.isOpenAsignFailModal,
      };

    case ActionType.SET_IS_OPEN_ASIGN_SUCCESS_MODAL:
      return {
        ...state,
        isOpenAsignSuccessModal: action.isOpenAsignSuccessModal,
      };

    case ActionType.SET_IS_OPEN_ASIGN_PROCESSING_MODAL:
      return {
        ...state,
        isOpenAsignProcessingModal: action.isOpenAsignProcessingModal,
      };

    case ActionType.SET_VISITS:
      return {
        ...state,
        visits: action.visits,
      };

    case ActionType.SET_HOUMERS:
      return {
        ...state,
        houmers: action.houmers,
      };

    case ActionType.SET_SCHEDULE_ID:
      return {
        ...state,
        scheduleId: action.scheduleId,
      };

    case ActionType.SET_APPRAISER_ID:
      return {
        ...state,
        appraiserId: action.appraiserId,
      };

    case ActionType.SET_AGENTS:
      return {
        ...state,
        agents: action.agents,
      };

    case ActionType.SET_CANCEL_REASONS:
      return {
        ...state,
        cancelReasons: action.cancelReasons,
      };

    case ActionType.SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.errorMsg,
      };

    case ActionType.SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case ActionType.SET_AGENT:
      return {
        ...state,
        ctAgent: action.ctAgent,
      };

    case ActionType.SET_INFO:
      return {
        ...state,
        contactInfo: action.contactInfo,
      };

    case ActionType.SET_NEWS:
      return {
        ...state,
        news: action.news,
      };

    case ActionType.SET_DEAL:
      return {
        ...state,
        deal: action.deal,
      };

    case ActionType.SET_TOTAL_VISITS:
      return {
        ...state,
        totalVisits: action.totalVisits,
      };

    case ActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
