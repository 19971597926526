import { makeStyles, Theme } from "@material-ui/core";

const useTicketsFieldStyles = makeStyles((theme: Theme) => ({
  link: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
    fontWeight: 700,
    color: theme.palette.grey[900],
    transition: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      "& svg": {
        "& path": {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
}));

export default useTicketsFieldStyles;
