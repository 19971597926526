import { Theme, makeStyles } from "@material-ui/core/styles";

const useLessorBankAccountInfoStyles = makeStyles((theme: Theme) => ({
  textValue: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    marginBottom: theme.spacing(1.4),
  },
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginTop: theme.spacing(2),
  },
  cgdButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: "white",
  },
  cgdButtonBlue: {
    backgroundColor: "#1890FF",
    "&:hover": {
      backgroundColor: "#6eb9ff",
    },
  },
  cgdButtonRed: {
    backgroundColor: "#FF4D4F",
    "&:hover": {
      backgroundColor: "#ff8a8b",
    },
  },
  cgdButtonGreen: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
    width: "100%",
  },
}));

export default useLessorBankAccountInfoStyles;
