import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "libs/alerts";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { IExchangeReservation } from "../utils/types";

const useExchange = (country: Locale, movementId: string, handleModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: IExchangeReservation) => {
      const authToken = await getToken();
      const response = await newPaymentService.createExchangeV2(
        country,
        movementId,
        formData,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reservationUserById");
        handleModal();
        alert.success({
          message: "Canje generado",
          disclaimer: "El canje se ha creado correctamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "La reserva no se pudo marcar como pagada",
          disclaimer: reason?.data?.message ?? "Error al marcar como pagada",
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useExchange;
