/**
 * Use this hook if you need to detect that the user is clicking outside your div.
 * Mark your div with a ref, and define what do you want to happen on the handleClick function
 **/

import { RefObject, useEffect } from "react";

export default function useClickOutside(ref: RefObject<HTMLElement>, handleClick: () => void) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick?.();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleClick]);
}
