import { Card, CardContent, Typography } from "@material-ui/core";

import { ChartCardProps } from "context/controlTower/controlTowerTypes";
import useBarChartCardStyles from "./ChartCardStyles";

function ChartCard({ title = "", caption = "", children = null }: ChartCardProps) {
  const classes = useBarChartCardStyles();

  return (
    <Card className={classes.state} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography className={classes.title} color="textSecondary">
          {caption}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
}

export default ChartCard;
