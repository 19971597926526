import { Menu, MenuItem, makeStyles, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const useStatusMenuStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
}));

export const StyledMenuItem = withStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#FEF1F0",
    },
  },
}))(MenuItem);

export const StyledMenu = withStyles({
  paper: {
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: 9,
  },
})(Menu);
