import { makeStyles, Theme } from "@material-ui/core";

const useReservationStyles = makeStyles<Theme>((theme) => ({
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
  },
  noReservations: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));

export default useReservationStyles;
