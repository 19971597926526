import { createTheme } from "@material-ui/core";

const CustomThemePicker = (theme) =>
  createTheme({
    ...theme,
    // @ts-ignore
    overrides: {
      ...theme.overrides,
      MuiPickersBasePicker: {
        pickerView: {
          width: "100%",
          maxWidth: "none",
          overflowX: "inherit",
        },
      },
    },
  });

export default CustomThemePicker;
