import { ReactNode } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import useCustomAccordionStyles from "./AccordionStyles";
import { ReactComponent as CloseSvg } from "assets/img/downCircle.svg";

interface Props {
  children?: ReactNode;
  title: string;
  expanded: boolean;
  changeState: (state: boolean) => void;
}
/**
 * How to use:
 * expanded is the current state of the accordion, true or false
 * changeState is the setState function that will change the state of the accordion
 *    example of prop: changeState = {() => setAccordionState(!accordionState)}
 */
function CustomAccordion({ children, title = "", expanded = false, changeState }: Props) {
  const classes = useCustomAccordionStyles();
  return (
    <Accordion
      expanded={expanded}
      square
      className={classes.accordion}
      classes={{
        expanded: classes.accordionExpanded,
      }}>
      <AccordionSummary
        expandIcon={<CloseSvg />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.summary}
        onClick={() => changeState(expanded)}
        classes={{
          expanded: classes.summaryExpanded,
          expandIcon: classes.iconButton,
          content: classes.summaryContent,
        }}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
export default CustomAccordion;
