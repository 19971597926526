export default (regions) => {
  const neighborhoods = [];
  Object.entries(regions || {}).forEach(([region, communes]: [string, any]) => {
    Object.entries(communes?.communes || {}).forEach(([commune, neighbors]: [string, any]) => {
      Object.entries(neighbors?.neighborhoods || {}).forEach(
        ([neighborhoodName, neighborValue]: [string, any]) => {
          neighborhoods.push({
            label: `${neighborValue.name}, ${commune}, ${region}`,
            value: neighborValue.id,
          });
        }
      );
    });
  });
  return neighborhoods;
};
