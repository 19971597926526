import { useMemo, useEffect } from "react";

import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import {
  setIsScheduling,
  setIsOpenNoAvModal,
} from "context/demandLeadContext/schedule/demandScheduleActions";
import { Typography } from "@material-ui/core";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import useNoAvailabilityStyle from "./NoAvailabilityStyles";

function Success() {
  const {
    state: { isScheduling, availability, isOpenNoAvailabilityModal },
    dispatch,
  } = useDemandSchedule();

  const {
    state: { schedules },
  } = useDemandLead();

  useEffect(() => {
    if (!isScheduling) return;

    if (!availability || Object.keys(availability).length === 0) {
      dispatch(setIsOpenNoAvModal(true));
    }
  }, [isScheduling, availability]);

  const hasHoumer = useMemo(() => schedules.find((item) => item.appraiser !== "N/A"), [schedules]);

  const handleClose = () => {
    dispatch(setIsOpenNoAvModal(false));
    if (!hasHoumer) {
      dispatch(setIsScheduling(false));
    }
  };

  const classes = useNoAvailabilityStyle();
  return (
    <CustomCenteredModal
      open={isOpenNoAvailabilityModal}
      onClose={handleClose}
      title="Propiedad sin disponibilidad"
      showCloseButton={true}
      withLogo>
      <Typography variant="body1" className={classes.subtitle}>
        Lo sentimos, esta propiedad no cuenta con agenda disponible, pero te recomendamos otra
        propiedades.
      </Typography>
    </CustomCenteredModal>
  );
}
export default Success;
