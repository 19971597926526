import ReactDOM from "react-dom";
import Main from "apps/main";
import {
  SENTRY_DSN,
  HOTJAR_ID,
  ANALITYCS_ID,
  FREATURE_MANAGER_BASE_URL,
  FEATURE_MANAGER_APP_ID,
  DATADOG_APPLICATIONID,
  DATADOG_CLIENTTOKEN,
  DATADOG_SERVICE,
} from "env";
import hotjar from "libs/hotjar";
import tagManager from "libs/tag-manager";
import sentry from "libs/sentry";
import datadog from "libs/datadog";
import featureManager from "@houm-com/feature-manager";

import "./i18n";

if (SENTRY_DSN) {
  sentry.initialize(SENTRY_DSN);
}

if (HOTJAR_ID) {
  hotjar.initialize(HOTJAR_ID);
}

if (ANALITYCS_ID) {
  tagManager.init({ analyticsId: ANALITYCS_ID });
}

if (DATADOG_APPLICATIONID && DATADOG_CLIENTTOKEN && DATADOG_SERVICE) {
  datadog.init({
    applicationId: DATADOG_APPLICATIONID,
    clientToken: DATADOG_CLIENTTOKEN,
    service: DATADOG_SERVICE,
  });
}

featureManager.init({
  baseURL: FREATURE_MANAGER_BASE_URL,
  appId: FEATURE_MANAGER_APP_ID,
});

ReactDOM.render(<Main />, document.getElementById("root"));
