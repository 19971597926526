import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";
import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";
import BaseField from "../../../BaseField";

export interface StatusFieldProps {
  tagType: TagType;
  value: string;
}

function StatusField({ tagType, value }: StatusFieldProps) {
  return (
    <BaseField requiredData={value}>
      <DSTag type={tagType} text={value} />
    </BaseField>
  );
}

export default StatusField;
