import { SnackbarProvider } from "notistack";
import { ProjectProvider } from "context/projectContext/projectContext";
import LayoutIndex from "./LayoutIndex";

interface Props {
  property: any;
}

export default function Layouts({ property }: Props) {
  return (
    <ProjectProvider>
      <SnackbarProvider>
        <LayoutIndex
          propId={property.id}
          currency={property.sales_details.currency}
          floors={property.association_amenities.floors}
          country={property.country}
        />
      </SnackbarProvider>
    </ProjectProvider>
  );
}
