import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 6),
    },
  },
  closeButtoContainer: {
    position: "absolute",
    right: theme.spacing(-2),
    top: theme.spacing(-2),
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: "black",
  },
  paper: {
    borderRadius: 16,
    padding: theme.spacing(1, 0),
    minWidth: 300,
    display: "flex",
    overflowY: "inherit",
    [theme.breakpoints.up("md")]: {
      minWidth: 810,
      padding: theme.spacing(3, 0),
    },
  },
}));

export default useStyles;
