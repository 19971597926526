import { makeStyles, Theme } from "@material-ui/core";

const useDeleteServiceStyles = makeStyles((theme: Theme) => ({
  deleteServiceRoot: {
    marginBottom: theme.spacing(1),
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EFF2F3",
  },
  trashIcon: {
    height: 16,
    cursor: "pointer",
  },
}));

export default useDeleteServiceStyles;
