import { makeStyles, Theme } from "@material-ui/core";

const useProgressCardStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  valueInfo: {
    fontWeight: "bold",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "1.5em",
    margin: theme.spacing(0, 1, 1, 0),
  },
}));

export default useProgressCardStyles;
