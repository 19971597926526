import { Grid } from "@material-ui/core";
import { useEvent } from "context/eventContext/eventContext";

import { getWeekDayName } from "utils/datetime";
import useScheduleRangeStyles from "./ScheduleRangeStyles";

import ScheduleRangeItem from "./ScheduleRangeItem";

function ScheduleRange() {
  const {
    state: { availability },
  } = useEvent();
  const classes = useScheduleRangeStyles();
  return (
    <Grid
      className={classes.rangeContainer}
      container
      spacing={3}
      justifyContent="space-between"
      alignItems="flex-end">
      <Grid item>
        {availability &&
          [...Array(5).keys()].slice(1).map((value) => {
            const fullWkeyDay = getWeekDayName(value % 7);
            const shortName = `${fullWkeyDay.slice(0, 2)}:`;

            return <ScheduleRangeItem nameDay={shortName} hours={availability[value] || []} />;
          })}
      </Grid>
      <Grid item>
        {availability &&
          [...Array(8).keys()].slice(5).map((value) => {
            const fullWkeyDay = getWeekDayName(value % 7);
            const shortName = `${fullWkeyDay.slice(0, 2)}: `;

            return (
              <ScheduleRangeItem
                key={`${value}-range-${shortName}`}
                nameDay={shortName}
                hours={availability[value] || []}
              />
            );
          })}
      </Grid>
    </Grid>
  );
}

export default ScheduleRange;
