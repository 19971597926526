import { useFormContext } from "react-hook-form";

import { AppraiserRole } from "models/Appraiser";

import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";
import { NewAppraiserForm } from "context/controlTower/controlTowerTypes";
import { contracts, haContracts } from "context/controlTower/controlTowerConsts";

function ContractFields() {
  const { watch } = useFormContext<NewAppraiserForm>();

  const roles = watch("roles");

  const contractOptions = roles?.includes(AppraiserRole.SALE_GUIDE) ? haContracts : contracts;

  return (
    <ControlledSelection
      useFormLabel
      compressed
      label="Tipo de Contrato"
      id="contract_type"
      options={contractOptions}
    />
  );
}
export default ContractFields;
