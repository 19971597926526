/* eslint-disable @typescript-eslint/no-use-before-define */
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CHANGE_MARKET, Market } from "../context/propertiesTypes";
import { useProperties } from "../context/propertiesContext";

import Button from "../../../UIComponents/Buttons/CustomButton";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";

function TableHeader() {
  const { dispatch, state } = useProperties();
  const [market, setMarket] = useState<Market>(state.market);

  const onClickHandle = (m: Market) => {
    setMarket(m);
    dispatch({ type: CHANGE_MARKET, market: m });
  };

  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.container}>
      <div>
        <Button
          kind="secondary"
          rootstyle={classes.button}
          active={market === "sales"}
          onClick={() => onClickHandle("sales")}>
          Ventas
        </Button>
        <Button
          kind="secondary"
          rootstyle={classes.button}
          active={market === "rental"}
          onClick={() => onClickHandle("rental")}>
          Arriendo
        </Button>
      </div>
      <ProtectedComponent
        // FIXME: change protected condition to use a permission not a role
        // permission suggested: create:property:all
        scope="roles"
        strategy="any"
        require={[
          "control_tower",
          "control_tower_agent",
          "control_tower_analyst",
          "control_tower_admin",
          "contract",
          "contract_admin",
          "evaluation",
          "evaluation_admin",
          "management",
          "management_admin",
          "sales",
          "sales_admin",
          "super_admin",
          "houmer_group_leader",
        ]}>
        <Button
          id="create-property"
          active
          rootstyle={classes.newPropertyButton}
          onClick={() => history.push("/admin/properties/create")}>
          Crear Propiedad
        </Button>
      </ProtectedComponent>
    </div>
  );
}

export default TableHeader;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 42,
  },
  newPropertyButton: { width: 211, height: 36 },
  button: { height: 36 },
});
