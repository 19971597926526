import { useState } from "react";
import { useParams } from "react-router-dom";

import TextField from "@material-ui/core/TextField";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import newEvaluationService from "services/newEvaluationService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import useStyles from "./WriteCommentStyles";

interface Props {
  buttonText?: string;
  onSave?: (comment: string) => void;
  evaluationId?: number;
  handleCloseCommentDialog?: (value: boolean) => void;
  fetchComments?: (value: string) => void;
  defaultValue?: string;
}

function WriteComment({
  onSave,
  evaluationId,
  handleCloseCommentDialog,
  fetchComments,
  buttonText = "Guardar",
  defaultValue = "",
}: Props) {
  const classes = useStyles();
  const params = useParams<{ country: Locale }>();
  const { getToken } = useGetToken();
  const [comment, setComment] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const handleSaveComment = async (comment) => {
    setLoading(true);
    const authToken = await getToken();
    const userData = JSON.parse(localStorage.getItem("user"));
    const res = await newEvaluationService.comment(
      evaluationId,
      {
        comment,
        commenter_id: userData.id,
      },
      authToken,
      params.country
    );
    if (res) {
      fetchComments(authToken);
    }
    setLoading(false);
    handleCloseCommentDialog(false);
  };

  return (
    <div className={classes.root}>
      <TextField
        required
        variant="outlined"
        size="small"
        multiline
        rows={3}
        placeholder="Escribir comentario..."
        defaultValue={defaultValue}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <div className={classes.buttonContainer}>
        <CustomLoadingButton
          text={buttonText}
          onClick={() => (evaluationId ? handleSaveComment(comment) : onSave(comment))}
          type="contained"
          loading={loading}
        />
      </div>
    </div>
  );
}

export default WriteComment;
