import {
  State,
  Action,
  SET_LOCATION_OPTIONS,
  SET_LOADING,
  SET_PROPERTY,
  REFETCH_PROPERTY_INFO,
  SET_ADDRESS,
  SET_USER,
  SET_USER_MODAL,
  SET_CREATED_PROPERTY,
  SET_SUCCESS_MODAL,
  SET_IS_SHORTER_FORM,
  SET_HOME_CHECKER_DETAILS,
  SET_PROPERTY_INSPECTION_REPORTS_STATE,
  REFETCH_INSPECTION_REPORTS,
  SET_PROPERTY_INSPECTION_REPORT_STATE,
  REFETCH_INSPECTION_REPORT,
  SET_INSPECTION_REPORTS_CURRENT_VIEW,
  SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER,
  SET_MODAL_MESSAGE,
} from "./propertyTypes";

export const initialState: State = {
  loading: false,
  property: null,
  refetchPropertyInfo: false,
  neighborhoods: [],
  address: null,
  user: null,
  openUserModal: false,
  openSuccessModal: false,
  isShorterForm: true,
  homeCheckerDetails: null,
  inspectionReports: {
    loading: false,
    refetch: false,
    count: null,
    results: [],
  },
  inspectionReport: {
    loading: false,
    refetch: false,
    data: {
      id: null,
      createdAt: null,
      appraiserInfo: null,
      generalSpacesSummary: [],
      generalObservation: null,
      spaces: [],
    },
    currentSpaceName: null,
  },
  inspectionReportsCurrentView: "main",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOCATION_OPTIONS: {
      return {
        ...state,
        neighborhoods: action.neighborhoods,
        communes: action.communes,
      };
    }
    case SET_IS_SHORTER_FORM: {
      return { ...state, isShorterForm: action.isShorterForm };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_SUCCESS_MODAL: {
      return { ...state, openSuccessModal: action.openSuccessModal };
    }
    case SET_PROPERTY: {
      return { ...state, property: action.property, loading: false };
    }
    case SET_MODAL_MESSAGE: {
      return { ...state, modalMessage: action.modalMessage };
    }
    case REFETCH_PROPERTY_INFO: {
      return { ...state, refetchPropertyInfo: !state.refetchPropertyInfo };
    }
    case SET_ADDRESS: {
      return { ...state, address: action.address };
    }
    case SET_USER_MODAL: {
      return { ...state, openUserModal: action.openUserModal };
    }
    case SET_USER: {
      return { ...state, user: action.user, openUserModal: false };
    }
    case SET_HOME_CHECKER_DETAILS: {
      return {
        ...state,
        homeCheckerDetails: action.homeCheckerDetails,
        loading: false,
      };
    }
    case SET_CREATED_PROPERTY: {
      return {
        ...state,
        property: action.property,
        loading: false,
        openSuccessModal: true,
      };
    }
    case SET_PROPERTY_INSPECTION_REPORTS_STATE: {
      return {
        ...state,
        inspectionReports: {
          results: action.inspectionReportsInfo?.results ?? state.inspectionReports.results,
          count: action.inspectionReportsInfo?.count ?? state.inspectionReports.count,
          loading: action.inspectionReportsInfo?.loading ?? state.inspectionReports.loading,
          refetch: action.inspectionReportsInfo?.refetch ?? state.inspectionReports.refetch,
        },
      };
    }
    case REFETCH_INSPECTION_REPORTS: {
      return {
        ...state,
        inspectionReports: {
          ...state.inspectionReports,
          refetch: !state.inspectionReports.refetch,
        },
      };
    }
    case SET_PROPERTY_INSPECTION_REPORT_STATE: {
      return {
        ...state,
        inspectionReport: {
          data: action.inspectionReportInfo?.data ?? state.inspectionReport.data,
          loading: action.inspectionReportInfo?.loading ?? state.inspectionReport.loading,
          refetch: action.inspectionReportInfo?.refetch ?? state.inspectionReport.refetch,
        },
      };
    }
    case REFETCH_INSPECTION_REPORT: {
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          refetch: !state.inspectionReport.refetch,
        },
      };
    }
    case SET_INSPECTION_REPORTS_CURRENT_VIEW: {
      return {
        ...state,
        inspectionReportsCurrentView: action.view,
      };
    }
    case SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER: {
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          currentSpaceName: action.spaceName,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
