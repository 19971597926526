import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useRepairVisitStyles from "./RepairVisitStyles";
import INTERVALS_OPTIONS from "./constants";
import HoursIntervals from "../HoursInterval";
import { IVisitHour } from "Components/Admin/administrator/subscriptions/hooks/requests/types";

interface Props {
  handleNext: () => void;
  handleBack: () => void;
  handleClose: () => void;
  visitHours: IVisitHour[];
  setVisitHours: (value: IVisitHour[]) => void;
}

const RepairVisit = ({ handleNext, handleBack, handleClose, setVisitHours, visitHours }: Props) => {
  const classes = useRepairVisitStyles();
  const allDaysHasHours = visitHours
    .filter((day) => day.checked)
    .every((day) => day.hours.every((hour) => hour > 0));
  return (
    <Box className={classes.container}>
      <HoursIntervals
        onChange={setVisitHours}
        value={visitHours}
        intervalOptions={INTERVALS_OPTIONS}
      />

      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          onClick={handleClose}
          variant="tertiaryB"
          size="small"
          label="Cancelar"
        />
        <Box className={classes.spacer}>
          <DesignSystemButton
            onClick={handleBack}
            variant="tertiaryB"
            size="small"
            label="Volver"
          />
          <DesignSystemButton
            disabled={!allDaysHasHours}
            onClick={handleNext}
            variant="primary"
            size="small"
            label="Siguiente"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RepairVisit;
