import { capitalize, startCase, toLower } from "lodash";

import { AddressEvaluationDetails, EvaluationDetailsModel } from "models/Evaluation";
import { statesMx } from "constants/mexicoStates";
import { ContractModel } from "models/Contract";
import { Locale } from "models/Countries";

export const parseCodebtorData = (contract: ContractModel) => {
  let codebtors = [];
  if (contract.property.country === "Colombia") {
    codebtors = [
      { ...contract.main_tenant, isMainTenant: true },
      ...contract.codebtors
        .filter((item) => item?.rut !== contract.lessee_aval?.rut)
        .filter((item) => item?.rut !== contract.main_tenant?.rut),
    ];
  } else {
    codebtors = [{ ...contract.main_tenant, isMainTenant: true }, ...contract.codebtors];
  }

  return codebtors.map((codebtor) => ({
    id: codebtor?.id,
    name: codebtor?.name,
    identification: codebtor?.document || codebtor?.rut,
    email: codebtor?.email,
    phone: codebtor?.phone,
    address: codebtor?.address,
    nationality: codebtor?.nationality,
    civil_status: codebtor?.civil_status,
    occupation: codebtor?.occupation,
    rfcNumber: codebtor?.rfc_number,
    salaryAverage: codebtor?.average_salary,
    isCodebtor: !codebtor.isMainTenant,
    document_type: codebtor?.document_type,
    document: codebtor?.document,
    employer: codebtor?.employer_name,
    tributaryType: codebtor?.tributary_type,
  }));
};

const capitalizeWord = (word: string) => startCase(toLower(word));

const parseAddressData = (addressData: AddressEvaluationDetails, country: Locale) => {
  if (!addressData) return "";
  if (country === "mx")
    return `${capitalizeWord(addressData.street)} ${addressData.interiorNumber} ${
      addressData.exteriorNumber
    }, ${capitalize(addressData.colonia)}, ${
      statesMx.find((state) => state.value === addressData.state).label
    }, CP ${addressData.zip}, ${capitalize(addressData.city)}`;
  return `${capitalizeWord(addressData.street)}, ${capitalize(addressData.city)}`;
};

export const parseProfileData = (
  data: EvaluationDetailsModel,
  isCodebtor: boolean,
  country: Locale
) => ({
  name: `${data.details.name} ${data.details.last_names}`,
  document: data.details.document,
  identification: data.details.document,
  nationality: data.details.nationality,
  occupation: data.details.occupation,
  employer: data.details.employer,
  salaryAverage: data.details.salary_avg,
  evaluationId: data.evaluation_id,
  documentType: data.details.documentType,
  address: parseAddressData(data.details?.address, country),
  isCodebtor,
});

export const contractHasManagement = (contract?: ContractModel): boolean => {
  if (!contract) {
    return false;
  }
  if (contract.plan?.category) {
    return contract.plan.category?.has_management;
  }
  return contract.management;
};
