import { makeStyles, Theme } from "@material-ui/core";

const useNegotiationCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(2, 3),
    borderRadius: 20,
    marginBottom: theme.spacing(2),
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    width: "100%",
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  moreInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));
export default useNegotiationCardStyles;
