import { useEffect } from "react";

import { Link } from "react-router-dom";
import { GridColumns, GridColDef } from "@material-ui/x-grid";

import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();

  const { state: tableStates, dispatch } = useTable();
  const { state } = useMultifamily();
  const { multifamilies, isLoadingDataTable, totalMF } = state;
  const { currentPage, pageSize } = tableStates;

  useEffect(() => dispatch(setPageSize(10)), []);

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "ID Multifamily",
      field: "id",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Nombre de multifamily",
      field: "name",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Cantidad tipologías",
      field: "unit_types_number",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Cantidad unidades",
      field: "units_number",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Nombre del asesor",
      field: "houm_executive",
      cellClassName: classes.centerCell,
      //@ts-ignore fix this later
      renderCell: ({ value }) => value?.name || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Nombre del contacto",
      field: "landlord",
      cellClassName: classes.centerCell,
      //@ts-ignore                                    FIX THIS
      renderCell: ({ value }) => (value ? `${value?.name} ${value?.last_name}` : "---------"),
    },
    {
      ...columnsConfig,
      headerName: "Estado",
      field: "published",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => (value ? "Activo" : "Inactivo"),
    },
    {
      ...columnsConfig,
      field: "actions",
      headerName: "",
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: classes.lastHeaderCell,
      cellClassName: classes.actionCell,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Link to={`/admin/multifamily/${row.id}`} className={classes.link}>
          Ver
        </Link>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        rowCount={totalMF || 0}
        columns={columns}
        rows={multifamilies || []}
        loading={isLoadingDataTable}
        page={currentPage - 1}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
