import { makeStyles, Theme } from "@material-ui/core";

const useSubscriptionStatusStyles = makeStyles((theme: Theme) => ({
  chipGreen: {
    fontWeight: "bold",
    color: "#00982D",
    border: "1px solid #00982D",
    background: "transparent",
  },
  chipRed: {
    fontWeight: "bold",
    color: "red",
    border: "1px solid red",
    background: "transparent",
  },
  chipYellow: {
    fontWeight: "bold",
    color: "#FAD202",
    border: "1px solid #FAD202",
    background: "transparent",
  },
}));

export default useSubscriptionStatusStyles;
