import { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";

import { Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import whiteBackground from "assets/images/white-price-background.png";
import orangeBackground from "assets/images/orange-price-background.png";
import {
  SimilarProperties,
  MainProperty,
} from "Components/Admin/administrator/properties/show/tabs/rev/Price/utils/types";
import { useMapEffect } from "hooks";
import useMap from "hooks/useMap";
import { Locale } from "models/Countries";
import { slightlyModifyCoordinates } from "utils/coordinates";
import { marketplaceLink } from "utils/marketplaceLink";
import { formatCurrency } from "utils/formatCurrency";

import useStyles from "./MapStyles";

export const similarPropertiesDataset = (data: SimilarProperties[], deal: "rental" | "sale") => {
  const positions = data.map((d, i) => ({
    geometry: {
      type: "Feature",
      coordinates: slightlyModifyCoordinates([
        d?.property_details[0]?.longitud,
        d?.property_details[0]?.latitud,
      ]),
    },
    properties: {
      price:
        deal === "rental"
          ? formatCurrency(d?.property_details[0]?.currency_code, d?.property_details[0]?.valor)
          : formatCurrency(
              d?.property_details[0]?.tipo_moneda_venta,
              d?.property_details[0]?.valor_venta
            ),
      propertyId: d?.id,
      id: i,
    },
  }));
  return { type: "FeatureCollection", features: positions };
};

// --------------------------------------------

interface Props {
  lat: number;
  lng: number;
  deal: "rental" | "sale";
  locale: Locale;
  ownerProperty?: MainProperty;
  similarProperties?: SimilarProperties[];
}

function Map({ lat, lng, deal, locale, similarProperties = [], ownerProperty = null }: Props) {
  const classes = useStyles();

  const theme = useTheme<Theme>();

  const marker = useRef(null);

  const { map, mapContainer } = useMap(
    {
      lat,
      lng,
      zoom: 14,
      style: "mapbox://styles/mapbox/light-v10",
    },
    [lat, lng]
  );

  useMapEffect(
    map,
    () => {
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
      });

      const showPopup = (e) => {
        const { coordinates } = e.features[0].geometry;
        const { coverPhoto, price, beds, baths, totalArea, propertyId, region, commune, type } =
          e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML(
            `<div class="mapbox-popup-prop-wrap" id=map-prop${propertyId}>
            ${
              coverPhoto
                ? `<img src=${coverPhoto} alt="property-preview" class="popup-mapbox-prop-image" />`
                : `<p class="mapbox-no-prop-text">Propiedad Externa</p>`
            }
            <div class="mapbox-prop-card-description">
                 <div class="mapbox-prop-card-features">
                <span>${totalArea || ""}m²</span>
                <span>${beds || ""} dorm</span>
                <span>${baths || ""} baño</span>
              </div> 
              <p class=mapbox-prop-card-price>${price}</p>
            </div>
          </div>`
          )
          .addTo(map.current)
          .getElement()
          .addEventListener(
            "click",
            propertyId
              ? () => {
                  window.open(
                    marketplaceLink.property(locale, deal, {
                      type,
                      commune,
                      region,
                      id: propertyId,
                    }),
                    "_blank"
                  );
                  // @ts-expect-error no-implicit-any
                  // eslint-disable-next-line react/no-this-in-sfc
                  this.remove();
                }
              : () => {}
          );
      };

      if (map.current.getSource("similar-properties")) {
        map.current
          .getSource("similar-properties")
          .setData(similarPropertiesDataset(similarProperties, deal));
      }

      map.current.on("load", () => {
        if (map.current.getSource("similar-properties")) {
          map.current
            .getSource("similar-properties")
            .setData(similarPropertiesDataset(similarProperties, deal));
        }
        if (!map.current.getSource("similar-properties")) {
          map.current.addSource("similar-properties", {
            type: "geojson",
            data: similarPropertiesDataset(similarProperties, deal),
            cluster: true,
            clusterMaxZoom: 25,
            clusterRadius: 50,
            tolerance: 0,
          });
          map.current.addLayer({
            id: "similar-properties-cluster",
            type: "circle",
            source: "similar-properties",
            filter: ["has", "point_count"],
            paint: {
              "circle-stroke-width": 2,
              "circle-stroke-color": "#fff",
              "circle-color": theme.palette.primary.main,

              "circle-radius": 16,
            },
          });
          map.current.addLayer({
            id: "similar-properties-cluster-count",
            type: "symbol",
            source: "similar-properties",
            filter: ["has", "point_count"],
            layout: {
              "text-allow-overlap": true,
              "text-field": "{point_count_abbreviated}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 13,
            },
            paint: { "text-color": "white" },
          });
          map.current.addLayer({
            id: "similar-property-item",
            type: "symbol",
            source: "similar-properties",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-allow-overlap": false,
              "icon-ignore-placement": true,
              "icon-image": "price-background-orange",
              "icon-padding": 0,
              "icon-size": 0.4,
              "icon-text-fit": "width",
              "icon-text-fit-padding": [0, 60, 0, 60],
              "text-allow-overlap": false,
              "text-field": "{price}",
              "text-padding": 0,
              "text-size": 11.5,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            },
            paint: { "text-color": "#ffffff" },
          });
          map.current.on("click", "similar-property-item", (e) => {
            const propertyData = e.features[0].properties;
            window.open(
              marketplaceLink.property(locale, deal, {
                id: propertyData.propertyId,
                region: propertyData.region,
                commune: propertyData.commune,
                type: propertyData.type,
              }),
              "_blank"
            );
          });

          map.current.on("mouseenter", "similar-property-item", showPopup);
          map.current.on("mouseleave", "similar-property-item", () => {
            map.current.getCanvas().style.cursor = "";
            popup.remove();
          });
          map.current.on("mouseenter", "similar-properties-cluster", () => {
            map.current.getCanvas().style.cursor = "pointer";
          });
          map.current.on("mouseleave", "similar-properties-cluster", () => {
            map.current.getCanvas().style.cursor = "";
          });
        }
        if (!map.current.getSource("similar-prices")) {
          map.current.addSource("similar-prices", {
            type: "geojson",
            data: similarPropertiesDataset(similarProperties, deal),
            cluster: true,
            clusterMaxZoom: 25,
            clusterRadius: 50,
            tolerance: 0,
          });
          map.current.addLayer({
            id: "similar-prices-cluster",
            type: "circle",
            source: "similar-prices",
            filter: ["has", "point_count"],
            paint: {
              "circle-stroke-width": 2,
              "circle-stroke-color": "#fff",
              "circle-color": "#fff",
              "circle-radius": 16,
            },
          });
          map.current.addLayer({
            id: "similar-prices-cluster-count",
            type: "symbol",
            source: "similar-prices",
            filter: ["has", "point_count"],
            layout: {
              "text-allow-overlap": true,
              "text-field": "{point_count_abbreviated}",
              "text-size": 13,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            },
            paint: { "text-color": "#000" },
          });
          map.current.addLayer({
            id: "similar-prices-item",
            type: "symbol",
            source: "similar-prices",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-allow-overlap": false,
              "icon-ignore-placement": true,
              "icon-image": "price-background",
              "icon-padding": 0,
              "icon-size": 0.4,
              "icon-text-fit": "width",
              "icon-text-fit-padding": [0, 55, 0, 55],
              "text-allow-overlap": false,
              "text-field": "{price}",
              "text-padding": 0,
              "text-size": 11.5,
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            },
            paint: {
              "text-color": "#000",
            },
          });

          map.current.on("click", "similar-prices-item", (e) => {
            const propertyData = e.features[0].properties;
            window.open(propertyData.link, "_blank");
          });

          map.current.on("mouseenter", "similar-prices-item", showPopup);
          map.current.on("mouseleave", "similar-prices-item", () => {
            map.current.getCanvas().style.cursor = "";
            popup.remove();
          });
        }
      });
    },
    [deal, similarProperties]
  );

  useEffect(
    () => () => {
      if (map.current.getSource("similar-prices")) {
        map.current.removeLayer("similar-prices-cluster");
        map.current.removeLayer("similar-prices-cluster-count");
        map.current.removeLayer("similar-prices-item");
        map.current.removeSource("similar-prices");
      }
      if (map.current.getSource("similar-properties")) {
        map.current.removeLayer("similar-properties-cluster");
        map.current.removeLayer("similar-properties-cluster-count");
        map.current.removeLayer("similar-property-item");
        map.current.removeSource("similar-properties");
      }
    },
    []
  );

  useMapEffect(
    map,
    () => {
      if (marker.current) marker.current.remove();

      map.current.loadImage(whiteBackground, (err, image) => {
        if (err) throw err;
        if (!map.current.hasImage("price-background"))
          map.current.addImage("price-background", image);
      });

      map.current.loadImage(orangeBackground, (err, image) => {
        if (err) throw err;
        if (!map.current.hasImage("price-background-orange"))
          map.current.addImage("price-background-orange", image);
      });

      const priceContainer = document.createElement("div");
      priceContainer.className = "priceContainer";
      const p1 = document.createElement("p");
      const p2 = document.createElement("p");
      const yourProperty = document.createTextNode(`Tu propiedad`);
      const price = document.createTextNode(
        deal === "rental"
          ? formatCurrency(
              ownerProperty?.property_details[0]?.currency_code,
              ownerProperty?.property_details[0]?.valor
            )
          : formatCurrency(
              ownerProperty?.sales_details?.currency_code,
              ownerProperty?.sales_details?.price
            )
      );

      p1.appendChild(yourProperty);
      p2.appendChild(price);
      priceContainer.appendChild(p1);
      priceContainer.appendChild(p2);

      const el = document.createElement("div");
      el.className = "marker";

      const container = document.createElement("div");
      container.className = "mainPropContainer";
      container.appendChild(priceContainer);

      const root = document.createElement("div");
      root.className = "root";

      root.appendChild(container);
      root.appendChild(el);

      marker.current = new mapboxgl.Marker(root, { offset: [10, -15] })
        .setLngLat([lng, lat])
        .addTo(map.current);
    },
    [ownerProperty, deal, lng, lat]
  );

  return (
    <div className={classes.mapRoot}>
      <div
        className={classes["mapbox-map-wrapper"]}
        ref={(el) => {
          mapContainer.current = el;
        }}
      />
    </div>
  );
}

export default Map;
