import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import applicantService from "services/applicantService";
import { AccountCircle } from "@material-ui/icons";
import { useGetToken } from "hooks";
import { useTextFieldStyles } from "./InputStyle";
import { useAutocompletedStyles } from "./UserAutocompleteStyle";
import { IApplicant } from "services/applicantService/types";

const MIN_LENGTH_SEARCH = 3;

function Option({ name, last_name, email, id }: IApplicant) {
  const classes = useAutocompletedStyles();

  return (
    <div style={{ display: "flex", flexDirection: "column" }} className={classes.text}>
      <Typography variant="body2" classes={{ root: classes.text }}>
        {name} {last_name}
      </Typography>
      <Typography variant="caption" classes={{ root: classes.text }}>
        {email} - id: {id}
      </Typography>
    </div>
  );
}

function Applicantcompleted({ label, ...props }, ref) {
  const classes = useTextFieldStyles();
  const { getToken } = useGetToken();
  const [inputQuery, setInputQuery] = useState("");
  const [applicants, setApplicants] = useState<IApplicant[]>([]);
  const [selectedApplicant, setSelectedApplicant] = useState<IApplicant | null>(null);

  useEffect(() => {
    const getApplicantsBySearchQuery = async (value: string, token: string) => {
      try {
        const { data } = await applicantService.searchByEmailAndName(value, token);
        setApplicants(data.results);
      } catch (e) {
        console.error(e);
      }
    };
    if (inputQuery.length >= MIN_LENGTH_SEARCH) {
      getToken().then((authToken) => getApplicantsBySearchQuery(inputQuery, authToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputQuery]);

  useImperativeHandle(ref, () => ({
    setValue(applicant) {
      setSelectedApplicant(applicant);
    },
    getValue() {
      return { user: selectedApplicant };
    },
    validate() {
      return true;
    },
  }));

  const handleApplicantChange = (applicant: NonNullable<string | IApplicant>) => {
    if (applicant && typeof applicant !== "string") {
      setSelectedApplicant(applicant);
    }
  };

  return (
    <Autocomplete<IApplicant>
      noOptionsText="Buscar por nombre o mail"
      value={selectedApplicant}
      options={applicants || []}
      onChange={(_, applicant) => handleApplicantChange(applicant)}
      onInputChange={(e, val) => setInputQuery(val)}
      getOptionLabel={(option) => `${option.name} ${option.last_name},  ${option.email} `}
      renderOption={(option) => <Option {...option} />}
      classes={{
        root: classes.input,
      }}
      renderInput={(props) => (
        <TextField
          fullWidth
          label={label}
          {...props}
          InputProps={{
            ...props.InputProps,
            classes: {
              root: classes.input,
              underline: classes.underline,
            },
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            className: classes.label,
            classes: {
              focused: classes.labelFocused,
            },
          }}
          FormHelperTextProps={{ className: classes.helper }}
          id="user"
        />
      )}
    />
  );
}
export default forwardRef(Applicantcompleted);
