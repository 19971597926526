import * as yup from "yup";
import "yup-phone";

const requiredText = "Obligatorio";

export default yup.object({
  country: yup.string().required(requiredText),
  name: yup.string().required(requiredText),
  last_name: yup.string().required(requiredText),
  email: yup.string().email().typeError("Por favor ingresa un email válido").required(requiredText),
  phone_number: yup
    .string()
    .nullable()
    .phone(undefined, false, "Por favor ingresa un teléfono válido"),
  role: yup.string().required(requiredText),
});
