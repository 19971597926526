import { Divider, Grid, Typography } from "@material-ui/core";
import useTableHeaderStyles from "./TableHeaderStyles";

function TableHeader() {
  const classes = useTableHeaderStyles();

  return (
    <Grid container item xs={12} className={classes.headerRoot}>
      <Grid item xs={1} className={classes.headerColumn}>
        <Typography className={classes.headerFont}>ID</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={2} className={classes.headerColumn}>
        <Typography className={classes.headerFont}>Nombre</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={5} className={classes.headerColumn}>
        <Typography className={classes.headerFont}>Descripción</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={2} className={classes.headerColumn}>
        <Typography className={classes.headerFont}>Mandato</Typography>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={2} className={classes.headerColumn}>
        <Typography className={classes.headerFont}>Contrato</Typography>
      </Grid>
    </Grid>
  );
}

export default TableHeader;
