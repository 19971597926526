import { makeStyles, Theme } from "@material-ui/core";

const useInfoDisplayStyles = makeStyles((theme: Theme) => ({
  paragraph: {
    margin: "12px 0px",
  },
  valueInfo: {
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
  },
}));

export default useInfoDisplayStyles;
