import { makeStyles, Theme } from "@material-ui/core";

const usePropertyVideoStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  mainButton: {
    height: 35,
    width: "auto",
    marginLeft: 0,
  },
  deleteButton: {
    height: 35,
    width: "auto",
    marginLeft: 0,
  },
}));

export default usePropertyVideoStyles;
