import { useCallback, useState } from "react";

import { CountryUpperCase } from "models/Countries";

const useCountry = () => {
  const [country, setCountry] = useState<CountryUpperCase>("Chile");

  const handleCountry = useCallback((countryLabel: CountryUpperCase) => {
    setCountry(countryLabel);
  }, []);

  return {
    country,
    handleCountry,
  };
};

export default useCountry;
