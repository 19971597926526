import { Locale } from "models/Countries";
import { IDebtCollection } from "models/IDebtCollection";
import statuses from "../../../utils/insuranceStatuses";

const getInsuranceTag = (country: Locale, item: IDebtCollection, onClick: (value: any) => void) => {
  const handleClick = () =>
    onClick({ contractId: item.contractId, companyCode: item.insurance.companyCode });
  if (!item?.insurance?.insuranceStatus) {
    return statuses.noStatus({ onClick: handleClick }).tag;
  }
  return statuses[country]({
    hasIcon: true,
    onClick: handleClick,
  })[item.insurance.insuranceStatus]?.tag;
};

export default getInsuranceTag;
