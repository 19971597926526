import { SnackbarProvider } from "notistack";

import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import usePlans from "hooks/usePlans";
import useGetInsuranceCompanies from "domains/insuranceCompanies/hooks/useGetInsuranceCompanies";
import { ContractModel } from "models/Contract";
import { PropertyData } from "models/Property";
import { countryCode as toCountryCode } from "utils";

import useSimulation from "../../../hooks/useSimulation";
import PlanPaymentsDetails from "../PlanPaymentsDetails";
import usePlanCardStyles from "./PlanCardStyles";
import { checkStatus } from "../../utils";
import Promotion from "../../Promotion";
import PlanForm from "./components/PlanForm/PlanForm";
import useGetInsuranceCompany from "../../../../../../../domains/insuranceCompanies/hooks/useGetInsuranceCompany";
import useGetExternalCollection from "./hooks/useGetExternalCollection";
import useGetCurrentContractTenantPlan from "./hooks/useGetCurrentContractTenantPlan";

interface Props {
  contract: ContractModel;
  property?: Partial<PropertyData>;
  brokerage?: boolean;
}

function PlanCard({ contract, property, brokerage = true }: Props) {
  const { data: simulationData, isLoading: loadingSimulation, mutate } = useSimulation();
  const classes = usePlanCardStyles();
  const countryCode = toCountryCode(contract.property.country);
  const { data: plans, isLoading: plansLoading } = usePlans(contract.property.country, false, {
    active: true,
  });
  const { insuranceCompanies, insuranceCompaniesLoading } = useGetInsuranceCompanies(countryCode);
  const { insuranceCompany, insuranceCompanyLoading } = useGetInsuranceCompany(
    String(contract?.id)
  );
  const { data: externalCollection, isLoading: isLoadingExternalCollection } =
    useGetExternalCollection(contract.id, countryCode);
  const { data: defaultContractTenantPlan, isLoading: isLoadingDefaultTenantPlan } =
    useGetCurrentContractTenantPlan(contract.id, countryCode);

  const isLoading =
    plansLoading ||
    insuranceCompaniesLoading ||
    insuranceCompanyLoading ||
    isLoadingExternalCollection ||
    isLoadingDefaultTenantPlan;

  const getStatus = () => {
    if (property?.rental_details?.plan || contract?.plan) return "Plan seleccionado";
    return null;
  };

  return (
    <CollapseCard title="Detalles del plan" status={getStatus()} chipClassName={classes.selected}>
      <Loading loading={isLoading}>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item container className={classes.gridContainer}>
                  <SnackbarProvider>
                    <PlanForm
                      contract={contract}
                      rentalDetails={property?.rental_details}
                      plans={plans}
                      externalCollection={externalCollection}
                      defaultContractTenantPlan={defaultContractTenantPlan}
                      isDisabled={
                        !!contract.is_payment_simulation ||
                        checkStatus(
                          [
                            "Generar boton de pago",
                            "Pagado",
                            "Boton de pago enviado",
                            "Finalizado",
                          ],
                          contract?.status
                        )
                      }
                      brokerage={brokerage}
                      insuranceCompany={insuranceCompany}
                      insuranceCompanies={insuranceCompanies}
                    />
                  </SnackbarProvider>
                  {contract.plan && (
                    <>
                      <Divider variant="middle" className={classes.divider} />
                      <Promotion
                        country={contract.property.country}
                        contractId={contract.id}
                        onFetchSimulation={mutate}
                        contractStatus={contract.status}
                      />
                      <PlanPaymentsDetails
                        contract={contract}
                        simulationData={simulationData}
                        loadingSimulation={loadingSimulation}
                        onFetchSimulation={mutate}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Card>
      </Loading>
    </CollapseCard>
  );
}

export default PlanCard;
