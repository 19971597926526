import { ReportBox, Title, Text } from "./EnvironmentStyles";

interface Props {
  title?: string;
  text?: string;
}

function Environment({ title = "", text = "" }: Props) {
  return (
    <ReportBox>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </ReportBox>
  );
}

export default Environment;
