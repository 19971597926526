interface Option {
  label: string;
  value: string;
}

export const orientationOptions: Option[] = [
  { label: "Norte", value: "north" },
  { label: "Sur", value: "south" },
  { label: "Poniente", value: "west" },
  { label: "Oriente", value: "east" },
  { label: "Norponiente", value: "northwest" },
  { label: "Nororiente", value: "northeast" },
  { label: "Surponiente", value: "southwest" },
  { label: "Suroriente", value: "southeast" },
];

export type OrientationOptionsTypes =
  | "Poniente"
  | "Oriente"
  | "Norte"
  | "Sur"
  | "Nororiente"
  | "Norponiente"
  | "Surponiente"
  | "Suroriente"
  | "north"
  | "south"
  | "west"
  | "east"
  | "northwest"
  | "northeast"
  | "southwest"
  | "southeast";
