import { saveAs } from "file-saver";

import { IoDownloadOutline, IoTrashOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import IconButton from "@houm-com/ui/IconButton";

import { Document as IDocument } from "domains/evaluations/models/Document";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { Permissions as P } from "domains/evaluations/utils/constants";
import { classes } from "./ControlPanelStyles";

interface Props {
  document: IDocument | null;
  pdfPage: number;
  onChangePage: (page: number) => void;
  fileError: boolean;
  pdfNumPages: number;
  handleOpenModal: () => void;
}

const ControlPanel = ({
  document,
  pdfPage,
  onChangePage,
  fileError,
  pdfNumPages,
  handleOpenModal,
}: Props) => {
  const onDownloadDocument = () => saveAs(document.url, document.filename);

  const handlePdfPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      const val = parseInt(e.target.value);
      if (val > 0 && val <= pdfNumPages) onChangePage(val);
    } else onChangePage(null);
  };

  return (
    <div className={classes.controlPanelRoot}>
      <IconButton
        icon={<IoDownloadOutline size="18" />}
        position="end"
        size="sm"
        variant="primary"
        onClick={onDownloadDocument}>
        Descargar
      </IconButton>
      {document?.contentType === "application/pdf" && !fileError && (
        <div className={classes.pdfPanelControl}>
          <LightButton
            variant="secondary"
            size="lg"
            disabled={pdfPage === 1}
            borderBottom
            className={classes.button}
            onClick={() => onChangePage(pdfPage - 1)}>
            Anterior
          </LightButton>
          <div className="contents">
            <p className={classes.text}>Página </p>
            <input
              aria-label="input-page"
              type="num"
              value={pdfPage}
              className={classes.input}
              onChange={handlePdfPage}
              disabled={pdfPage === pdfNumPages || fileError}
            />
            <p className={classes.text}> de {pdfNumPages}</p>
          </div>
          <LightButton
            variant="secondary"
            size="lg"
            borderBottom
            className={classes.button}
            disabled={pdfPage === pdfNumPages || fileError}
            onClick={() => onChangePage(pdfPage + 1)}>
            Siguiente
          </LightButton>
        </div>
      )}
      <ProtectedComponent scope="permissions" strategy="all" require={[P.DELETE_FILE]}>
        <IconButton
          icon={<IoTrashOutline size="18" />}
          position="start"
          size="sm"
          variant="tertiary"
          onClick={handleOpenModal}
          className={classes.deleteButton}>
          Eliminar
        </IconButton>
      </ProtectedComponent>
    </div>
  );
};

export default ControlPanel;
