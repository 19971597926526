import { Grid, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { parsePrice } from "utils";

interface Props {
  plan: {
    description: string;
    country: string;
    discount: number;
    commission: string | number;
    typeMoney: string;
    planType: string | number;
    totalPlan: number;
    monthlyPlan: number;
  };
}

const PlanCard = ({ plan }: Props) => (
  <Grid item xs={12}>
    <AdminCard title="Plan" withSpace={false} showTitleBackground={true}>
      <Typography component="h2">
        {plan.description}, {plan.country}
      </Typography>
      {plan?.discount > 0 && <Typography component="p">Descuento: {plan.discount}%</Typography>}
      {plan?.planType === "Anual" && (
        <Typography component="p">
          Cuota:
          {parsePrice(plan.totalPlan, plan.typeMoney, true)} {plan.planType}
        </Typography>
      )}
      {plan?.planType === "Mensual" && (
        <Typography component="p">
          Cuota:
          {parsePrice(plan.monthlyPlan, plan.typeMoney, true)} {plan.planType}
        </Typography>
      )}
      <Typography component="p">Comisión original del plan: {plan.commission}%</Typography>
    </AdminCard>
  </Grid>
);

export default PlanCard;
