import { useState, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { InitialFilters } from "context/demandScheduledVisitsContext/demandScheduledTypes";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  setIsIsOpenFilterModal,
  setFilters,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

import Autocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import { filterModalStatusOptions, filterModalThirdCoordOptions } from "./constants";
import useFilterModalStyles from "./FilterModalStyles";

function FilterModal() {
  const [houmer, setHoumer] = useState<string | null>(null);
  const [agent, setAgent] = useState<string | null>(null);
  const [status, setStatus] = useState<InitialFilters | null>(null);
  const [thirdCoord, setThirdCoord] = useState<InitialFilters | null>(null);
  const [beginDate, setBeginDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useEffect(() => {
    setStatus(filters.status);
    setThirdCoord(filters.thirdCoord);
  }, []);

  const {
    state: { isOpenFilterModal, houmers, agents, filters },
    dispatch,
  } = useDemandScheduled();
  const classes = useFilterModalStyles();
  const updateFilters = () => {
    dispatch(
      setFilters({
        ...filters,
        houmer,
        agent,
        beginDate,
        endDate,
        status,
        thirdCoord,
      })
    );
    closeModal();
  };
  const cleanFilters = () => {
    setHoumer(null);
    setAgent(null);
    setBeginDate(null);
    setEndDate(null);
    setStatus(null);
    setThirdCoord(null);
  };
  const closeModal = () => dispatch(setIsIsOpenFilterModal(false));

  return (
    <CustomDialog
      open={isOpenFilterModal}
      onClose={closeModal}
      subtitle={
        <div className={classes.subtitle}>Selecciona los filtros para facilitar tu busqueda</div>
      }
      title="Filtros">
      <div className={classes.filtersContainer}>
        <FilterDialog.Item label="Fecha inicial">
          <FilterDialog.TextField
            type="date"
            value={beginDate}
            onChange={(e) => {
              setBeginDate(e.target.value);
            }}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Fecha final">
          <FilterDialog.TextField
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </FilterDialog.Item>
        <Autocomplete onChange={setHoumer} value={houmer} options={houmers} label="Houmer" />
        <Divider />
        <Autocomplete onChange={setAgent} value={agent} options={agents} label="Agente CT" />
        <Divider />
        <Autocomplete
          onChange={setStatus}
          value={status}
          options={filterModalStatusOptions}
          label="Estado"
        />
        <Divider />
        <Autocomplete
          onChange={setThirdCoord}
          value={thirdCoord}
          options={filterModalThirdCoordOptions}
          label="Coordinación"
        />
      </div>
      <div className={classes.footer}>
        <Button
          autoFocus
          onClick={cleanFilters}
          className={classes.removeButton}
          variant="text"
          color="primary">
          Borrar filtros
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={updateFilters}>
          Aplicar filtros
        </Button>
      </div>
    </CustomDialog>
  );
}
export default FilterModal;
