import { useState } from "react";

import { Permissions as P } from "domains/evaluations/utils/constants";
import { MemberRoles } from "domains/evaluations/models/Evaluations";

import useProtectedMenuItem from "../useProtectedMenuItem";
import { ModalType } from "../../utils/types";

const useMenuOptions = ({ memberRole }: { memberRole: MemberRoles }) => {
  const { getMenuItem } = useProtectedMenuItem();
  const [modal, setModal] = useState<ModalType>("");

  const handleOpenModal = (modal: ModalType) => () => setModal(modal);
  const handleCloseModal = () => setModal("");

  const menuList = [
    ...getMenuItem(
      [P.UPDATE_EVALUATION_MEMBER],
      [
        {
          label: "Aprobar",
          onClick: handleOpenModal("markAsApproved"),
        },
        {
          label: "Desistir",
          onClick: handleOpenModal("markAsDesisted"),
        },
      ]
    ),
    ...(memberRole === "codebtor"
      ? [
          ...getMenuItem(
            [P.UPDATE_MAIN],
            [
              {
                label: "Convertir en principal",
                onClick: handleOpenModal("changeMain"),
              },
            ]
          ),
          ...getMenuItem(
            [P.DELETE_MEMBER],
            [
              {
                label: "Eliminar",
                onClick: handleOpenModal("deleteCodebtor"),
              },
            ]
          ),
        ]
      : []),
    ...getMenuItem(
      [P.READ_MEMBER_INFORMATION, P.READ_MEMBER_INFORMATION],
      [
        {
          label: "Evaluar",
          onClick: handleOpenModal("evaluate"),
        },
      ]
    ),
    ...getMenuItem(
      [P.UPDATE_EVALUATION_MEMBER],
      [
        {
          label: "Marcar como listo para evaluar",
          onClick: handleOpenModal("readyForEvaluation"),
        },
      ]
    ),
    ...getMenuItem(
      [P.CREATE_COMMENT],
      [
        {
          label: "Pedir documentación",
          onClick: handleOpenModal("askForDocumentation"),
        },
      ]
    ),
    ...getMenuItem(
      [P.UPDATE_EVALUATION_MEMBER],
      [
        {
          label: "Rechazar",
          onClick: handleOpenModal("markAsRejected"),
        },
      ]
    ),
  ];

  return {
    menuList,
    modal,
    handleOpenModal,
    handleCloseModal,
  };
};

export default useMenuOptions;
