import { useMemo } from "react";

import useGuard from "domains/auth/hooks/useGuard";
import { MovementType } from "models/Payments";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
  movementType: MovementType;
}

const useTransferOptions = ({ country, movementType }: Props) => {
  const { guard } = useGuard();

  const canSetInsuranceStatus =
    guard.permissions.any(["update:payment_movement_status:all"]) && movementType === "INCOME";

  const typeTransferOptions = useMemo(
    () => ({
      cl: [
        { label: "Arriendo mensual a Insurtech", value: "ADMIN_LEASE" },
        { label: "Cuota de suscripción a Insurtech", value: "ADMIN_SUBSCRIPTION" },
        ...(canSetInsuranceStatus
          ? [{ label: "Pagado por aseguradora", value: "INSURANCE_PAYMENT" }]
          : []),
      ],
      co: [
        { label: "Arriendo mensual a Houm Colombia Sas", value: "ADMIN_LEASE" },
        {
          label: "Cuota de suscripción a Houm Colombia Sas",
          value: "ADMIN_SUBSCRIPTION",
        },
        ...(canSetInsuranceStatus
          ? [{ label: "Pagado por aseguradora", value: "INSURANCE_PAYMENT" }]
          : []),
      ],
      mx: [
        { label: "Arriendo mensual a Houm Tech Mexico", value: "ADMIN_LEASE" },
        {
          label: "Cuota de suscripción a Houm Tech Mexico",
          value: "ADMIN_SUBSCRIPTION",
        },
      ],
    }),
    [canSetInsuranceStatus]
  );

  return {
    transferOptions: country ? typeTransferOptions[country] : [],
  };
};

export default useTransferOptions;
