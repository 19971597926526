import useGuard from "domains/auth/hooks/useGuard";
import { MenuItem } from "../../utils/types";

const useProtectedMenuItem = () => {
  const { guard } = useGuard();

  const getMenuItem = (permissions: string[], menuItems: MenuItem[]) =>
    guard.permissions.any(permissions) ? menuItems : [];

  return {
    getMenuItem,
  };
};

export default useProtectedMenuItem;
