import { FormattedAddress } from "models/Address";
import {
  CalculatorResponse,
  BasicPropertyDetails,
  PropertyAmenities,
  Dataset,
  SimilarExternalPropertiesResponse,
  MetricsByLocationResponse,
} from "models/Calculator";
import { SimilarProperty } from "models/createProperty";

export enum ActionType {
  SET_PROPERTY_DETAILS,
  RESET_PROPERTY_DETAILS,
  RESET_PROPERTY_DETAILS_TO_ORIGINAL,
  SET_PROPERTY_AMENITIES,
  SET_CALCULATOR_RESULTS,
  SET_SIMILAR_PROPERTIES,
  SET_SIMILAR_EXTERNAL_PROPERTIES,
  CHANGE_STEP,
  SET_LOADING,
  SET_NO_RESULTS,
  ERROR,
  SET_CITY_ERROR,
  RESET,
  SET_DATASET,
  CHANGE_DEAL,
  SET_BY_QUERY_PARAMS,
  SET_NEW_PROPERTY_DETAILS,
  SET_LOADING_NEW_CALCULATION,
  SET_FORMATTED_ADDRESS,
  SET_SQUARE_METER_PRICES,
}

export type Dispatch = (action: Action) => void;

export interface State {
  loading: boolean;
  /** Only valid when loading is true */
  noResults: boolean;
  calculatorResponse?: CalculatorResponse;
  formattedAddress?: FormattedAddress;
  error?: { status: number; message: string };
  deal: "rental" | "sale";
  similarProperties: SimilarProperty[];
  similarExternalProperties: SimilarExternalPropertiesResponse;
  squareMeterPrices: MetricsByLocationResponse;
}

export interface setPropertyDetails {
  type: typeof ActionType.SET_PROPERTY_DETAILS;
  basicPropertyDetails: BasicPropertyDetails;
}

export interface setFormattedAddress {
  type: typeof ActionType.SET_FORMATTED_ADDRESS;
  formattedAddress: FormattedAddress;
}
export interface resetPropertyDetails {
  type: typeof ActionType.RESET_PROPERTY_DETAILS;
  reset?: boolean;
}
export interface resetPropertyDetailsToOriginal {
  type: typeof ActionType.RESET_PROPERTY_DETAILS_TO_ORIGINAL;
}

export interface setNewPropertyDetails {
  type: typeof ActionType.SET_NEW_PROPERTY_DETAILS;
  basicPropertyDetails: Partial<BasicPropertyDetails>;
}
export interface setPropertyAmenities {
  type: typeof ActionType.SET_PROPERTY_AMENITIES;
  propertyAmenities: PropertyAmenities;
}

export interface changeStep {
  type: typeof ActionType.CHANGE_STEP;
  step: number;
}

export interface setLoading {
  type: typeof ActionType.SET_LOADING;
  loading: boolean;
}

export interface setNoResults {
  type: typeof ActionType.SET_NO_RESULTS;
  noResults: boolean;
}
export interface setError {
  type: typeof ActionType.ERROR;
  status: number;
  message: string;
}
export interface setCityError {
  type: typeof ActionType.SET_CITY_ERROR;
  error: boolean;
}
export interface setCalculatorResponse {
  type: typeof ActionType.SET_CALCULATOR_RESULTS;
  calculatorResponse: CalculatorResponse;
}

export interface setSimilarProperties {
  type: typeof ActionType.SET_SIMILAR_PROPERTIES;
  similarProperties: SimilarProperty[] | undefined;
}
export interface setSimilarExternalPrices {
  type: typeof ActionType.SET_SIMILAR_EXTERNAL_PROPERTIES;
  similarExternalProperties: SimilarExternalPropertiesResponse | undefined;
}

export interface setByQueryParams {
  type: typeof ActionType.SET_BY_QUERY_PARAMS;
  state: State;
}
export interface reset {
  type: typeof ActionType.RESET;
}

export interface setDataset {
  type: typeof ActionType.SET_DATASET;
  dataset: Dataset;
}
export interface changeDeal {
  type: typeof ActionType.CHANGE_DEAL;
  deal: "rental" | "sale";
}

export interface setSquareMeterPrices {
  type: typeof ActionType.SET_SQUARE_METER_PRICES;
  squareMeterPrices: MetricsByLocationResponse;
}

export type Action =
  | setPropertyDetails
  | resetPropertyDetails
  | resetPropertyDetailsToOriginal
  | setPropertyAmenities
  | setSimilarProperties
  | setSimilarExternalPrices
  | setByQueryParams
  | changeStep
  | changeDeal
  | setLoading
  | setCalculatorResponse
  | setNewPropertyDetails
  | setCityError
  | setNoResults
  | setError
  | reset
  | setDataset
  | setFormattedAddress
  | setSquareMeterPrices;
