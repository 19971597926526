import { zonedTimeToUtc } from "date-fns-tz";
import esLocale from "date-fns/locale/es";
import { format } from "date-fns";

import { Box, Typography } from "@material-ui/core";

import { useFeatureManager } from "@houm-com/feature-manager";

import ServiceDetails from "Components/Admin/UIComponents/PropertyServices/components/ServiceDetails";
import QueryDisclaimer from "Components/Admin/UIComponents/PropertyServices/components/QueryDisclaimer";
import { numberWithCommas } from "utils";
import { Locale } from "models/Countries";

import { BasicService } from "../../../../../utils/types";
import CardBold from "../../../typography/CardBold";
import useServiceItemsStyles from "./ServiceItemsStyles";

interface Props extends BasicService {
  country?: Locale;
}

function ServiceItems({
  client,
  clientInfo,
  updatedAt,
  currentDebt,
  overdueDate,
  aggregateDebt,
  status,
  country,
}: Props) {
  const { data: servicesFlagData, status: servicesFlagStatus } = useFeatureManager(
    "show_services_details",
    { marketCode: country }
  );
  const showServiceDetails = servicesFlagStatus === "success" && servicesFlagData?.enabled;

  const classes = useServiceItemsStyles();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <>
      <Box className={classes.serviceItem}>
        <CardBold>Número de cliente :</CardBold>
        <Typography className={classes.serviceValue}>{client}</Typography>
      </Box>
      {showServiceDetails && (
        <>
          <Box className={classes.serviceItem}>
            <CardBold>Última consulta :</CardBold>
            <Typography className={classes.serviceValue}>
              {format(zonedTimeToUtc(updatedAt, timeZone), "Pp", {
                locale: esLocale,
              })}
            </Typography>
          </Box>
          {!clientInfo.isValid && (
            <Typography className={classes.notValid}>
              Ha ocurrido un error obteniendo la información. Por favor validar el código ingresado
            </Typography>
          )}
          {!["in_process", "pending"].includes(status) && clientInfo.isValid ? (
            <ServiceDetails
              currentDebt={` $${numberWithCommas(currentDebt)}`}
              overdueDate={format(overdueDate, "dd/MM/yyyy")}
              aggregateDebt={` $${numberWithCommas(aggregateDebt)}`}
            />
          ) : (
            <QueryDisclaimer />
          )}
        </>
      )}
    </>
  );
}

export default ServiceItems;
