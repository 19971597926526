import { useState } from "react";
import Swal from "sweetalert2";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useFinalizeSignStepStyles from "./FinalizeSignStepStyles";
import { changeStatusCreatePaymentButton } from "./utils";
import { ContractModel } from "models/Contract";
import { useGetToken } from "hooks";

interface Props {
  contract: ContractModel;
}

function FinalizeSignStep({ contract }: Props) {
  const { getToken } = useGetToken();
  const classes = useFinalizeSignStepStyles();
  const [loading, setLoading] = useState(false);

  const toPaymentButton = async () => {
    setLoading(true);
    Swal.fire({
      title: "Atención",
      text: "El contrato será pasado a generación de botón de pago, ¿Está seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar",
    }).then(async (result) => {
      const authToken = await getToken();
      if (result.value) {
        try {
          await changeStatusCreatePaymentButton(contract, authToken);
        } catch (e) {
          Swal.fire({
            type: "error",
            text: "No se pudo pasar a generación botón de pago",
          });
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <CollapseCard title="Pasar a generar botón">
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.textLabel}>
                Cuando esté todo listo y revisado <strong>presiona aquí</strong> para el siguiente
                paso
              </Typography>
            </Grid>
          </Grid>
          <DesignSystemButton
            label="Pasar a generar botón de pago"
            variant="primary"
            type="button"
            size="medium"
            onClick={() => toPaymentButton()}
            isLoading={loading}
            disabled={loading}
            buttonClassName={classes.button}
          />
        </CardContent>
      </Card>
    </CollapseCard>
  );
}

export default FinalizeSignStep;
