export interface MinMaxProps {
  min?: number;
  max: number;
}

export const rentalMinMaxValues = {
  Chile: {
    "U.F.": { min: 6, max: 100 },
    Pesos: { min: 200000, max: 3000000 },
  },
  Colombia: {
    Pesos: { min: 350000, max: 50000000 },
  },
  Mexico: {
    Pesos: { min: 3000, max: 300000 },
  },
};

export const salesMinMaxValues = {
  Chile: {
    "U.F.": { min: 720, max: 5500 },
    Pesos: { min: 23000000, max: 160000000 },
  },
  Colombia: {
    Pesos: { min: 50000000, max: 5500000000 },
  },
  Mexico: {
    Pesos: { min: 500000, max: 700000000 },
  },
};
