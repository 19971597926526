import { useCallback, useMemo, useState } from "react";

import CollapsibleTable from "Components/Admin/UIComponents/CollapsibleTable/CollapsibleTable";
import { StatusMovementType } from "services/newPaymentService/types";
import { MovementBreakdownRecord } from "models/Payments";
import useGuard from "domains/auth/hooks/useGuard";
import { CurrencyCode } from "models/Currencies";
import useModal from "hooks/useModal";

import CreateDiscountChargeModal from "../../../modals/CreateDiscountChargeModal";
import { defaultModalData, defaultMovementData, getTableColumns } from "./utils/utils";
import DeletePenaltyFormModal from "../../../modals/DeletePenaltyFormModal";
import { getIfChangeIsAllowed, parseMovementsRows } from "../../../utils";
import EditPaymentDateModal from "../../../modals/EditPaymentDateModal";
import MarkAsPaidModal from "../../../modals/MarkAsPaidModal";
import { IMovementData, IModals } from "./utils/types";
import ModalDisclaimer from "../ModalDisclaimer";
import Detail from "../Detail";

interface TableProps {
  movements: MovementBreakdownRecord[];
  loading: boolean;
  subscriptionId: number;
  contractCurrency: CurrencyCode;
}

function MovementTable({ movements, loading, subscriptionId, contractCurrency }: TableProps) {
  const { guard } = useGuard();
  const [modals, setModals] = useState(defaultModalData);
  const { open: openHourDisclaimerModal, handleOpenModal: handleOpenHourDisclaimerModal } =
    useModal();
  const [movement, setMovement] = useState<IMovementData>(defaultMovementData);

  const handleCloseModal = () => {
    setModals(defaultModalData);
    setMovement(defaultMovementData);
  };

  const handleShowMovementDetail = (movementId) => {
    const movement = movements.find((item) => item.movement_id === movementId);
    return <Detail movement={movement} />;
  };

  const handleSetMovement = useCallback((movementValues, modalToOpen: Partial<IModals>) => {
    setMovement({
      ...movementValues,
      date: movementValues.agreedDate,
      concept: movementValues.concept === "CONTRACT" ? "closing_payment" : "monthly_payment",
      movementType: movementValues.movementType,
    });
    setModals((prev) => ({ ...prev, ...modalToOpen }));
  }, []);

  const handleModals = useCallback(
    (values, modalToOpen: Partial<IModals>) => {
      const flag = getIfChangeIsAllowed(values.agreedDate, values.movementType);
      if (!flag) return handleOpenHourDisclaimerModal();
      handleSetMovement(values, modalToOpen);
    },
    [handleOpenHourDisclaimerModal, handleSetMovement]
  );

  const availableActions = (status: StatusMovementType) => [
    ...(status !== "INSURANCE_MANAGEMENT" && status !== "CANCELED"
      ? [
          {
            label: "Descontar o abonar",
            onClick: (e) => handleModals(e, { openModifyOrderModal: true }),
          },
        ]
      : []),
    ...(status !== "UNSCHEDULED"
      ? [
          {
            label: "Editar fecha de pago",
            onClick: (e) => {
              if (status === "CANCELED" && e.movementType === "OUTFLOW")
                handleSetMovement(e, { openEditPaymentDateModal: true });
              else handleModals(e, { openEditPaymentDateModal: true });
            },
          },
        ]
      : []),
    ...(status !== "CANCELED"
      ? [
          {
            label: "Marcar como pagado",
            onClick: (e) => handleSetMovement(e, { openMarkAsPaidModal: true }),
          },
        ]
      : []),
  ];

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        guard.roles.any,
        (e) => handleModals(e, { openDeletePenaltyModal: true }),
        availableActions
      ),
    []
  );
  const rows = useMemo(() => parseMovementsRows(movements), [movements]);

  return (
    <>
      <CollapsibleTable
        rows={rows}
        paginated={true}
        headers={tableColumns}
        loading={loading && movements.length == 0}
        createSubRowComponent={handleShowMovementDetail}
      />
      <CreateDiscountChargeModal
        open={modals.openModifyOrderModal}
        onHandleClose={handleCloseModal}
        movementId={movement.movementId}
        country={movement.country}
        handleCloseCreateMovement={handleCloseModal}
        date={movement.date}
        adjustmentType={movement.concept}
        movementType={movement.movementType}
        contractCurrency={contractCurrency}
      />
      <MarkAsPaidModal
        open={modals.openMarkAsPaidModal}
        handleOnClose={handleCloseModal}
        movementId={movement.movementId}
        country={movement.country}
        totalAmount={movement.totalAmount}
        subscriptionId={subscriptionId}
        movementType={movement.movementType}
      />
      <EditPaymentDateModal
        open={modals.openEditPaymentDateModal}
        handleOnClose={handleCloseModal}
        subscriptionId={subscriptionId}
        movementId={movement.movementId}
        date={movement.date}
        country={movement.country}
      />
      <DeletePenaltyFormModal
        open={modals.openDeletePenaltyModal}
        onHandleClose={handleCloseModal}
        country={movement.country}
        movementId={movement.movementId}
        penalty={movement.penalty}
        movementCurrency={movement.movementCurrency}
      />
      <ModalDisclaimer
        isOpen={openHourDisclaimerModal}
        onHandleModel={handleOpenHourDisclaimerModal}
      />
    </>
  );
}
export default MovementTable;
