import { useState } from "react";
import Gallery from "react-photo-gallery";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import PhotoUploader from "Components/Shared/partials/PhotoUploader";
// import Photo from "./partials/Photo";
import { useProperty } from "context/propertyContext/propertyContext";
import { updatePropertyPhotosOrder } from "context/propertyContext/propertyActions";
import { useGetToken } from "hooks";
import CustomButton from "../Buttons/CustomButton";
import usePropertyPhotosStyles from "./PropertyPhotosStyles";
import Photo from "./components/Photo";

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery photos={items} renderImage={(props) => <SortablePhoto {...props} />} />
));

function PropertyPhotos(props) {
  const { getToken } = useGetToken();
  const { dispatch } = useProperty();
  const classes = usePropertyPhotosStyles();
  const [items, setItems] = useState(props.photos);

  const getSorted = () => {
    const arr = [];
    for (let index = 0; index < items.length; index++) {
      const element = { ...items[index], order: index };
      arr.push(element);
    }
    return arr;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMove(items, oldIndex, newIndex);
    const arr = [];
    for (let index = 0; index < sorted.length; index++) {
      const element = { ...sorted[index], order: index };
      arr.push(element);
    }
    setItems(arr);
  };

  const handleUpdatePhotosOrder = async () => {
    const authToken = await getToken();
    await updatePropertyPhotosOrder(props.property.uid, getSorted(), dispatch, authToken);
  };

  return (
    <div className={classes.container}>
      <h2>Fotos de la propiedad</h2>
      <p>Puedes reorganizarlas arrastrando las imágenes</p>
      <PhotoUploader {...props} />
      <SortableGallery items={items} onSortEnd={onSortEnd} axis="xy" />
      <CustomButton
        kind="blue"
        active
        className={classes.mainButton}
        onClick={handleUpdatePhotosOrder}>
        Actualizar orden
      </CustomButton>
    </div>
  );
}

export default PropertyPhotos;
