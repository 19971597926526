import { LandlordSummary } from "models/AdminModels/Landlord";
import { ILandlordSummaryResponse } from "models/User";

export const parseLandlordSummary = (data: ILandlordSummaryResponse): LandlordSummary => ({
  id: data.id,
  fullName: data.full_name,
  email: data.email,
  phone: data.phone,
  document: data.document,
  userType: data.user_type,
  propertiesCount: data.properties_count,
  activeProperties: data.active_properties,
});

export const parseLandlordSearch = (data: ILandlordSummaryResponse[]): LandlordSummary[] =>
  data?.length > 0 && data.map((landlord) => parseLandlordSummary(landlord));
