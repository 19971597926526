import { withStyles } from "@material-ui/core/styles";
import { GridToolbarContainer, GridToolbarExport } from "@material-ui/x-grid";

import { useTable } from "context/tableContext/tableContext";
import { setShowModal } from "context/tableContext/tableActions";
import SearchBar from "Components/Admin/administrator/dashboard/partials/SearchBar";
import FilterButton from "Components/Admin/UIComponents/Buttons/FilterButton";
import useStyles from "./ToolbarStyles";

const ENABLE_EXPORT = false;

const Export = withStyles({})(GridToolbarExport);

function Toolbar() {
  const classes = useStyles();
  const { dispatch } = useTable();
  const handleFilterClick = () => {
    dispatch(setShowModal(true));
  };

  return (
    <GridToolbarContainer>
      <div className={classes.container}>
        <SearchBar viewName="projects" />
        <div>
          <FilterButton onClick={handleFilterClick} />
          {ENABLE_EXPORT && <Export />}
        </div>
      </div>
    </GridToolbarContainer>
  );
}

export default Toolbar;
