import { Theme, makeStyles } from "@material-ui/core/styles";

const useCreatePaymentFormStyles = makeStyles((theme: Theme) => ({
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
  },
  formContent: {
    width: "100%",
  },
  descriptionInput: {
    top: theme.spacing(0.75),
    minHeight: 120,
    padding: theme.spacing(2),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
  },
  textInput: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
}));

export default useCreatePaymentFormStyles;
