import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useCollapseCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.1)",
      padding: 0,
      width: "100%",
    },
    head: {
      backgroundColor: "#FEF1F0",
      borderRadius: "8px 8px 0 0",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "#FEF1F0",
      },
    },
    title: {
      fontWeight: "bold",
    },
    icon: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      color: theme.palette.primary.main,
      cursor: "pointer",
      fontSize: theme.typography.pxToRem(16),
    },
    editText: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      fontSize: theme.typography.pxToRem(14),
      textDecoration: "underline",
      marginRight: theme.spacing(3),
    },
    status: {
      background: theme.palette.grey[5],
      color: theme.palette.grey[500],
      border: `0.5px solid ${theme.palette.grey[500]}`,
      marginRight: theme.spacing(3),
    },
  })
);

export default useCollapseCardStyles;
