import Button from "@houm-com/ui/Button";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { ILegalRepresentative } from "models/Contract";
import { CountryUpperCase } from "models/Countries";

import useLegalRepresentativeCardStyles from "./LegalRepresentativeCardStyles";
import { renderDataUsers } from "../utils";
import InfoPerson from "./InfoPerson";

interface Props {
  country: CountryUpperCase;
  title: string;
  legalRepresentative: ILegalRepresentative;
  onCreateLegalRepresentative: () => void;
  onEditLegalRepresentative: () => void;
  onDeleteLegalRepresentative: (contractPersonId: number) => void;
}

function IndividualLegalRepresentative({
  country,
  title,
  legalRepresentative,
  onCreateLegalRepresentative,
  onEditLegalRepresentative,
  onDeleteLegalRepresentative,
}: Props) {
  const classes = useLegalRepresentativeCardStyles();

  return (
    <Grid item container xs={12} md={6} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      {legalRepresentative ? (
        <>
          {renderDataUsers(legalRepresentative, country).map((item) => (
            <InfoPerson
              size={item.size}
              label={item.label}
              text={item.text}
              key={`${item.label}`}
            />
          ))}
          <Grid container item xs={12} spacing={2}>
            <Grid item>
              <Button variant="secondary" size="md" onClick={() => onEditLegalRepresentative()}>
                Editar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="tertiary"
                size="md"
                onClick={() => onDeleteLegalRepresentative(legalRepresentative.id)}>
                Eliminar
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Button variant="primary" size="md" onClick={() => onCreateLegalRepresentative()}>
          Agregar
        </Button>
      )}
    </Grid>
  );
}

export default IndividualLegalRepresentative;
