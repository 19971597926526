import { useEffect, useState, useMemo, memo } from "react";
import { GridColumns, GridColDef, esES } from "@material-ui/x-grid";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import propertyService from "services/propertyService";
import { IProperty } from "models/createProperty";
import { Paginate } from "models/ResponseWithPaginate";
import { useGetToken } from "hooks";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { Schedule } from "models/DemandLead";
import { updateCurrentProperty } from "context/demandLeadContext/schedule/demandScheduleActions";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import {
  PropertyCell,
  DirectionAddressCell,
  CommuneCell,
  DateCell,
  HourCell,
  StatusCell,
  TypeCell,
  AdvisorCell,
} from "./Cells";

import useStyles from "./TableStyles";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

function Table() {
  const [loading, setLoading] = useState(false);
  const [propertyToAgend, setPropertyToAgend] = useState<IProperty[]>([]);
  const { getToken } = useGetToken();

  const {
    state: { isLoading, schedules, lead },
  } = useDemandLead();

  const { dispatch: scheduleDispatch } = useDemandSchedule();
  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize } = tableStates;
  const classes = useStyles();

  const handleSchedule = (row: Schedule) => {
    setLoading(true);
    getToken().then(async (authToken) => {
      try {
        const propertiesResponse = (await propertyService.search(
          row.property_id,
          "",
          authToken,
          undefined,
          {
            published: true,
            for_sale: lead.status.business_type === "sales" || "",
            for_rental: lead.status.business_type === "rentals" || "",
          }
        )) as AxiosResponse<Paginate<IProperty>>;
        if (propertiesResponse.data) {
          if (propertiesResponse.data.results.length === 0) {
            Swal.fire("Esta propiedad ya no esta disponible para la actividad solicitada.");
            setLoading(false);
          }
          setPropertyToAgend(propertiesResponse.data.results);
          setLoading(false);
        }
      } catch (error) {
        setPropertyToAgend([]);
        Swal.fire("Ha ocurrido un error");
        setLoading(false);
      }
    });
  };

  const handleSelect = (property) => {
    getToken().then((authToken) => {
      updateCurrentProperty(property, "sales", scheduleDispatch, authToken);
    });
  };

  useEffect(() => dispatch(setPageSize(10)), []);

  useEffect(() => {
    handleSelect(propertyToAgend[0]);
  }, [propertyToAgend]);

  const columns = useMemo(
    () => [
      {
        ...columnsConfig,
        headerName: "ID Propiedad",
        field: "property",
        flex: 0.7,
        headerClassName: classes.firstHeaderCell,
        cellClassName: classes.leftCell,
        renderCell: ({ row }) => <PropertyCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Direccion",
        field: "visit_address",
        flex: 0.9,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <DirectionAddressCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Comuna",
        field: "commune",
        flex: 0.9,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <CommuneCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Fecha",
        field: "visit_date",
        flex: 0.9,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <DateCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Hora",
        field: "visit_hour",
        flex: 0.7,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <HourCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Status",
        field: "visit_state",
        flex: 0.7,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <StatusCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Tipo de visita",
        field: "visit_type",
        flex: 0.9,
        cellClassName: classes.centerCell,
        renderCell: ({ row }) => <TypeCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        headerName: "Houm Advisor",
        field: "houm_advisor",
        flex: 0.9,
        headerClassName: classes.lastRenderedHeaderCell,
        cellClassName: classes.rightCell,
        renderCell: ({ row }) => <AdvisorCell row={row as Schedule} />,
      },
      {
        ...columnsConfig,
        field: "action",
        resizable: false,
        headerName: "",
        flex: 0.5,
        headerClassName: classes.lastHeaderCell,
        sortable: false,
        renderCell: ({ row }) =>
          row?.toAgend && (
            <a className={classes.link} onClick={() => handleSchedule(row as Schedule)}>
              Agendar
            </a>
          ),
      },
    ],
    []
  ) as GridColumns;

  return (
    <Loading loading={loading}>
      <div className={classes.container}>
        <CustomDataGrid
          classes={{
            root: classes.root,
            columnHeader: classes.headerCell,
          }}
          rowCount={schedules?.length || 0}
          columns={columns}
          rows={schedules}
          loading={isLoading}
          page={currentPage - 1}
          pageSize={pageSize}
          onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
          onPageSizeChange={(params) => {
            dispatch(setPageSize(params));
            dispatch(setCurrentPage(1));
          }}
          //@ts-ignore
          localeText={esES.props.MuiDataGrid.localeText}
        />
      </div>
    </Loading>
  );
}

export default memo(Table);
