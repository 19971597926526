import { Grid } from "@material-ui/core";
import { clearForm, setTagTypes } from "context/tagContext/tagActions";
import { useTag } from "context/tagContext/tagContext";
import { useEffect } from "react";

import FiltersBar from "./components/FiltersBar";
import SearchBar from "./components/SearchBar";
import TagsTable from "./components/TagsTable";
import useStyles from "./useMarketplaceTagsStyles";

function MarketplaceTags() {
  const { dispatch } = useTag();
  useEffect(() => {
    dispatch(clearForm());
    dispatch(setTagTypes([]));
  }, []);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item container className={classes.filtersContainer}>
        <Grid item xs={12}>
          <SearchBar />
        </Grid>
        <Grid item xs={4}>
          <FiltersBar />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.tableContainer}>
        <TagsTable />
      </Grid>
    </Grid>
  );
}

export default MarketplaceTags;
