import { makeStyles, Theme } from "@material-ui/core";

export const useActionsContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  buttonsContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(1),
    maxWidth: "fit-content",
  },
}));
