import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { ContractModel } from "models/Contract";
import contractService from "services/contractService";

const useContract = (propertyUid: string) => {
  const { getToken } = useGetToken();

  const {
    isLoading: contractLoading,
    error: contractError,
    data: contractData,
  }: UseQueryResult<ContractModel, Error> = useQuery(["contract", propertyUid], async () => {
    const authToken = await getToken();
    const response = await contractService.getContractByProperty(propertyUid, authToken);
    return response.data;
  });

  return { contractLoading, contractError, contractData };
};

export default useContract;
