import { propertyFurnishedOptions } from "utils/createProperty";

const getFurnishedLabel = (furnished: string) => {
  const option = propertyFurnishedOptions.find((option) => option.value === furnished);
  return option ? `${option.label} 🛋` : "";
};

const getCloseLabel = (close: string) => {
  const closetOptions = { closet: "Closet", walk_in: "Walk-in closet" };
  return `${closetOptions[close]} 🚪`;
};

export const amenities = {
  getLaundryLabel: (value: boolean) => (value ? "Conexión de lavadora 🧼" : ""),
  getFurnishedLabel: (value: string) => getFurnishedLabel(value),
  getClosetLabel: (value: string) => getCloseLabel(value),
  getAirConditioningLabel: (value: boolean) => (value ? "Aire acondicionado ❄️" : ""),
  getHeatingLabel: (value: boolean) => (value ? "Calefacción 🥵" : ""),
  getPetfriendlyLabel: (value: boolean) => (value ? "Petfriendly 🐶" : ""),
  getWaterLabel: (value: boolean) => (value ? "Agua caliente 🚿" : ""),
  getWarehouseLabel: (value: boolean) => (value ? "Bodega 📦" : ""),
  getGardenLabel: (value: boolean) => (value ? "Jardín privado 🌸" : ""),
  getCurtainHangerLabel: (value: boolean) => (value ? "Colgadores para cortinas 🪟" : ""),
  getKitchenFurnishedLabel: (value: boolean) => (value ? "Cocina equipada 🍽" : ""),
  getClothingAreaLabel: (value: boolean) => (value ? "Área de ropas 🧺" : ""),
  getBalconyLabel: (value: boolean) => (value ? "Balcón 🏙" : ""),
};
