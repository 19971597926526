import { makeStyles, Theme } from "@material-ui/core/styles";

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      width: "auto",
    },
  },
  hidden: {
    display: "none",
  },
  button: {
    borderRadius: 50,
    minWidth: 140,
    padding: theme.spacing(1, 2),
    minHeight: 40,
    textAlign: "center",
    textTransform: "none",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[900],
    color: "white",
    "&:hover": {
      backgroundColor: "black",
    },
    "&$disabled": {
      backgroundColor: theme.palette.grey[200],
      color: "white",
    },
  },
  disabled: {},
}));

export default useFileUploadStyles;
