import { cn } from "@houm-com/ui/utils";
import { Popover } from "@headlessui/react";
import { IoEllipsisVerticalCircleOutline as MoreIcon } from "@houm-com/ui-icons";
import useUser from "domains/user/hooks/useUser";
import { useAuth } from "libs/auth";
import { usePopper } from "react-popper";
import { useState } from "react";

function UserMenuPopover() {
  const { logout } = useAuth();
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="ml-auto">
      {/** @ts-ignore */}
      <Popover.Button className="focus:outline-none" ref={setReferenceElement}>
        <MoreIcon className="w-6 h-6" />
      </Popover.Button>
      {/** @ts-ignore */}
      <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        <div className="flex flex-col bg-white p-2 border-black-20 rounded border text-p16 w-40">
          <button className="text-left" onClick={() => logout()}>
            Cerrar sesión
          </button>
        </div>
      </Popover.Panel>
    </Popover>
  );
}

export default function NavbarUser() {
  const { user } = useUser();
  if (!user) return null;
  return (
    <div
      className={cn(
        "flex items-center px-6 py-3 text-sm font-semibold",
        "items-center w-full group flex gap-x-3"
      )}>
      {user.picture && (
        <img className="h-8 w-8 rounded-full bg-gray-50" src={user.picture} alt="" />
      )}
      <span className="sr-only">Your profile</span>
      <div className="flex flex-col">
        <span aria-hidden="true">{user.name}</span>
        <span aria-hidden="true" className="text-p14">
          {user.email}
        </span>
      </div>
      <UserMenuPopover />
    </div>
  );
}
