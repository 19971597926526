import { Typography } from "@material-ui/core";
import { GridOverlay } from "@material-ui/x-grid";

function NoRowsOverlayError() {
  return (
    <GridOverlay>
      <Typography>Error en la carga de subscripciones</Typography>
    </GridOverlay>
  );
}

export default NoRowsOverlayError;
