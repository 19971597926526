/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import CustomTextField from "Components/Admin/UIComponents/CustomTextField";

interface Props {
  label: string;
  id: string;
  disabled?: boolean;
  defaultValue?: string | number;
  controllerProps?: React.ComponentProps<typeof Controller>;
  textFieldProps?: React.ComponentProps<typeof CustomTextField>;
  labelClassName?: string;
  type?: string;
}

function ControlledTextField({
  label,
  id,
  disabled,
  defaultValue,
  controllerProps,
  textFieldProps,
  type = "time",
  labelClassName,
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <CustomTextField
          id={id}
          label={label}
          disabled={disabled}
          value={value}
          type={type}
          onChange={onChange}
          error={invalid}
          errorMessage={error?.message}
          labelClassName={labelClassName}
          {...textFieldProps}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledTextField;
