import { makeStyles, Theme } from "@material-ui/core/styles";

const useErrorsListStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: "center",
    margin: theme.spacing(2, 0, 0, 0),
  },
  listContainer: {
    maxHeight: 250,
    overflowY: "scroll",
    padding: theme.spacing(2, 3),
    border: "2px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: 8,
    margin: theme.spacing(2, 0, 0, 0),
    width: "100%",
    textAlign: "left",
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.error.dark,
  },
  errorIcon: {
    color: "inherit",
    marginLeft: theme.spacing(1),
  },
  linesList: {
    listStylePosition: "inside",
    marginBottom: 0,
  },
  errorList: {
    paddingLeft: theme.spacing(2),
    marginBottom: 0,
  },
}));

export default useErrorsListStyles;
