import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import isEqual from "lodash/isEqual";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  FormGroupProps,
} from "@material-ui/core";
import { GeneralOption } from "models/Option";
import useControlledGroupCheckboxStyles from "./ControlledGroupCheckboxStyles";

interface Props<T> {
  label: string;
  id: string;
  defaultValues?: T[];
  controllerProps?: React.ComponentProps<typeof Controller>;
  checkboxProps?: React.ComponentProps<typeof Checkbox>;
  placement?: "end" | "start" | "top" | "bottom";
  justifyContent?: string;
  size?: "small" | "medium";
  checkboxLabelClassName?: string;
  formGroupProps?: FormGroupProps;
  options: GeneralOption<T>[];
}

function ControlledGroupCheckbox<T>({
  id,
  options = [],
  defaultValues = [],
  checkboxProps,
  label,
  controllerProps,
  checkboxLabelClassName,
  placement = "end",
  justifyContent = "space-between",
  size = "medium",
  formGroupProps,
}: Props<T>) {
  const { control } = useFormContext();
  const classes = useControlledGroupCheckboxStyles({ justifyContent, size });

  return (
    <Controller
      name={id}
      defaultValue={defaultValues}
      control={control}
      render={({ field: { value: arrayValues, onChange }, fieldState: { error, invalid } }) => {
        const isChecked = (value: T): boolean =>
          arrayValues.some((valueItem) => isEqual(value, valueItem));

        const handleChange = (value: T) => {
          const checked = isChecked(value);
          if (checked) {
            const newArray = arrayValues.filter((item) => !isEqual(value, item));
            onChange(newArray);
            return;
          }
          onChange([...arrayValues, value]);
        };

        return (
          <FormControl error={invalid}>
            <FormLabel focused={invalid} className={classes.label}>
              {label}
            </FormLabel>
            <FormGroup {...formGroupProps}>
              {options.map((item) => (
                <FormControlLabel
                  key={item.label}
                  labelPlacement={placement}
                  label={item.label}
                  classes={{
                    label: checkboxLabelClassName,
                  }}
                  control={
                    <Checkbox
                      size={size}
                      color="primary"
                      checked={isChecked(item.value)}
                      onChange={() => handleChange(item.value)}
                      {...checkboxProps}
                    />
                  }
                />
              ))}
            </FormGroup>
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
      {...controllerProps}
    />
  );
}

export default ControlledGroupCheckbox;
