import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@houm-com/ui/Button";
import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";

import UserFormFields from "Components/Admin/UIComponents/UserFormFields";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useDocumentTypeList, useFormNotifications } from "hooks";
import { localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";

import useOnSubmitApplicantForm from "./hooks/useOnSubmitApplicantForm";
import { userFormSchema, requiredText } from "./validationSchema";
import { IPersonData } from "./utils";
import classes from "./ApplicantFormStyles";

interface Props {
  country: Locale;
  propertyUid: string;
  propertyId: number;
  closeModal: () => void;
}

const ApplicantsForm = ({ country, propertyUid, propertyId, closeModal }: Props) => {
  const onSuccess = () => closeModal();
  const { mutate, isLoading } = useOnSubmitApplicantForm({
    propertyUid,
    propertyId,
    onSuccess,
  });
  const { documentTypeList, loading: loadingDocumentType } = useDocumentTypeList(
    localeToCountry[country]
  );

  const methods = useForm<IPersonData>({
    resolver: yupResolver(userFormSchema(documentTypeList, country)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IPersonData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IPersonData> = (data) => {
    if (isLoading) return;
    mutate({ data, country });
  };

  return (
    <Loading loading={loadingDocumentType}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContainer}>
          <UserFormFields country={country} documentTypeList={documentTypeList} />
          <FieldSwitchSelector
            id="occupation"
            label="Ocupación"
            options={[
              { value: "Dependiente", label: "Dependiente" },
              { value: "Independiente", label: "Independiente" },
            ]}
            errorMessage={errors.occupation?.message}
            {...methods.register("occupation")}
          />
          <div className={classes.saveButtonContainer}>
            <Button variant="primary" size="lg" type="submit" isLoading={isLoading}>
              Guardar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Loading>
  );
};

export default ApplicantsForm;
