import { useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";

import { usePageStyles } from "Components/Admin/administrator/properties/components/FormElements/Page";
import RecommendPriceChart from "./RecommendPriceChart";
import RevMap from "./RevMap";
import SquareMeterPrice from "./SquareMeterPrice";
import DealSwitcher from "Components/Admin/administrator/properties/show/tabs/rev/DealSwitcher";
import { useCalculator } from "context/calculatorContext/calculatorContext";
import { changeDeal } from "context/calculatorContext/calculatorActions";
import { countryCode, countryCurrency } from "utils";
import { formatCurrency } from "utils/formatCurrency";
import { PropertyData } from "models/Property";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ReactComponent as DownloadIcon } from "assets/icons/downloadFile.svg";

import PriceToPDF from "./Price";

interface Props {
  property: PropertyData;
}
function PriceAndMap({ property }: Props) {
  const [isDownloading, setIsDownloading] = useState(false);

  const classes = usePageStyles();

  const { state, dispatch } = useCalculator();

  const { calculatorResponse: recommendation, deal } = state;

  const setDeal = (deal: "rental" | "sale") => dispatch(changeDeal(deal));

  const actualSalesCurrency = useMemo<string>(() => {
    if (property.property_details[0].tipo_moneda_venta === "Pesos")
      return countryCurrency(property.country)?.currency;
    if (property.country === "Chile") return "CLF";
  }, []);

  const actualRentalCurrency = useMemo<string>(() => {
    if (property.property_details[0].tipo_moneda === "Pesos")
      return countryCurrency(property.country)?.currency;
    if (property.country === "Chile") return "CLF";
  }, []);

  useEffect(() => {
    let defaultDeal: "rental" | "sale";
    if (property.for_sale) defaultDeal = "sale";
    else defaultDeal = "rental";
    dispatch(changeDeal(defaultDeal));
  }, [property]);

  const printToPdf = () => {
    setIsDownloading(true);
    html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
      const data = canvas.toDataURL();
      const pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      };
      pdfMake.createPdf(pdfExportSetting).download("informe_de_precio_propiedad.pdf", null, {
        progressCallback: () => setIsDownloading(false),
      });
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <DesignSystemButton
        size="small"
        variant="primary"
        isLoading={isDownloading}
        buttonClassName={classes.downloadButton}
        Icon={<DownloadIcon fontSize="medium" />}
        label="Descargar informe de la propiedad"
        onClick={printToPdf}
      />
      <div className={classes.hiddenContainer}>
        <span id="print_to_pdf">
          <PriceToPDF deal={deal} property={property} propertyId={property?.id} />
        </span>
      </div>
      <Grid className={classes.revPriceInfoContainer} item md={6} container spacing={1}>
        {deal === "rental" && (
          <Grid item md={12}>
            <Typography variant="h5">Precio actual arriendo</Typography>
            <div>
              <Typography>
                {formatCurrency(actualRentalCurrency, property?.property_details[0]?.valor)}
              </Typography>
            </div>
          </Grid>
        )}
        {deal === "sale" && (
          <Grid item md={12}>
            <Typography variant="h5">Precio actual venta</Typography>
            <div>
              <Typography>
                {formatCurrency(actualSalesCurrency, property?.property_details[0]?.valor_venta)}
              </Typography>
            </div>
          </Grid>
        )}
        <Grid item md={12}>
          {deal === "rental" && (
            <RecommendPriceChart
              prices={recommendation?.for_rental}
              title="Rango de precios arriendo"
              showChart
            />
          )}
          {deal === "sale" && (
            <RecommendPriceChart
              showChart
              prices={recommendation?.for_sale}
              title="Rango de precios venta"
            />
          )}
        </Grid>
        <SquareMeterPrice property={property} />
      </Grid>
      <Grid item md={6} className={classes.mapContainer}>
        <DealSwitcher deal={deal} onDealChange={setDeal} />
        <RevMap
          deal={deal}
          lat={property?.property_details[0]?.latitud}
          lng={property?.property_details[0]?.longitud}
          locale={countryCode(property?.country)}
        />
      </Grid>
    </Grid>
  );
}
export default PriceAndMap;
