import { useEffect, useState } from "react";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import newEvaluationService from "services/newEvaluationService";
import { useGetToken } from "hooks";
import applicantService from "services/applicantService";
import useStyles from "./EvaluatorLinkStyles";
import { EVALUATION_FORM_URL } from "env";

interface Props {
  applicant: string;
}

function EvaluatorLink({ applicant }: Props) {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const [link, setLink] = useState(null);
  const [linkText, setLinkText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (applicant) {
      setLoading(true);
      (async () => {
        const authToken = await getToken();

        const applicantResponse = await applicantService.get(applicant, authToken);

        if (applicantResponse) {
          const { data } = applicantResponse;

          setLink(
            `${EVALUATION_FORM_URL}/cl/form?property=${data.property?.uid}&applicant=${applicant}&brokerage=true`
          );
          setLinkText("Sin Registro");

          const evaluations = await newEvaluationService.getPage({
            size: 1,
            page: null,
            sort: null,
            order: null,
            mainApplicant: null,
            codebtor: null,
            status: null,
            property: null,
            email: null,
            relatedApplicant: applicant,
            token: authToken,
          });

          if (evaluations) {
            const evaluationsData = evaluations.data.evaluations;
            if (evaluationsData.length > 0) {
              setLink(
                `/admin/evaluations/cl/${evaluationsData[0].main_applicant.id}/${evaluationsData[0].user.id}`
              );
              setLinkText("Ver Evaluación");
            }
          }
        }
        setLoading(false);
      })();
    }
  }, [applicant]);

  if (applicant) {
    return (
      <Loading loading={loading}>
        <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
          {linkText}
        </a>
      </Loading>
    );
  }

  return <>-</>;
}

export default EvaluatorLink;
