import React from "react";

import WarningIcon from "@material-ui/icons/Warning";
import { Chip } from "@material-ui/core";

import { UnitsUploadError } from "context/projectContext/projectTypes";
import useStyles from "./useErrorsListStyles";

type Props = {
  errors: UnitsUploadError[];
};

function ErrorsList({ errors }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Chip
        icon={<WarningIcon />}
        label="Errores encontrados en el archivo"
        classes={{
          icon: classes.errorIcon,
        }}
        className={classes.chip}
      />
      <div className={classes.listContainer}>
        <ul className={classes.linesList}>
          {errors.map((line) => (
            <React.Fragment key={line.line}>
              <li>{`Linea ${line.line}:`}</li>
              <ul className={classes.errorList}>
                {line.errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ErrorsList;
