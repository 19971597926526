import { makeStyles, Theme } from "@material-ui/core";

const useTitleStyles = makeStyles<Theme>((theme) => ({
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default useTitleStyles;
