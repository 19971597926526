import { Action, SET_OPEN_ALERT, SET_ALERT_INFO, IAlertInfo, CLEAR_ALERT_INFO } from "./alertTypes";

export const setOpenAlert = (openFlag: boolean): Action => ({
  type: SET_OPEN_ALERT,
  open: openFlag,
});

export const setAlertInfo = (alertInfo: IAlertInfo): Action => ({
  type: SET_ALERT_INFO,
  alertInfo,
});

export const clearAlertInfo = (): Action => ({
  type: CLEAR_ALERT_INFO,
  alertInfo: {
    open: false,
    head: null,
    type: null,
    body: null,
  },
});
