import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";
import { getStatusTagProps } from "../../utils/utils";
import BaseField from "../../../BaseField";
import { MainText } from "../../../typography";
import usePropertyFieldStyles from "./PropertyFieldStyles";

interface Props {
  address: string;
  status: string;
}

function PropertyField({ address, status }: Props) {
  const classes = usePropertyFieldStyles();

  return (
    <BaseField className={classes.addressContainer} requiredData={address}>
      <MainText className={classes.address}>{address}</MainText>
      {status && <DSTag {...getStatusTagProps(status)} />}
    </BaseField>
  );
}

export default PropertyField;
