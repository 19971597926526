import { useCallback, useState } from "react";
import Swal from "sweetalert2";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import { RejectDesistModal } from "Components/Admin/UIComponents/Modals";
import useDismissContractStyles from "./DismissContractStyles";
import { contractDetailDiscardInfo } from "utils/discardInfo";
import contractService from "services/contractService";
import { ContractModel } from "models/Contract";
import { useGetToken } from "hooks";

interface Props {
  contract: ContractModel;
  updateContract: (contract: ContractModel) => void;
}

function DismissContract({ contract, updateContract }: Props) {
  const { getToken } = useGetToken();
  const classes = useDismissContractStyles();
  const [openRejectDesist, setOpenRejectDesist] = useState<boolean>(false);

  const saveDiscard = useCallback(
    (reason: string, author: string, type: string) => async (comment: string) => {
      const status = type === "desist" ? "Desistido" : "Rechazado";
      const authToken = await getToken();
      const newStatusContract = {
        ...contract,
        status,
        discard_reason: reason,
        discard_author: author,
        discard_comment: comment,
      };
      try {
        const res = await contractService.update(
          newStatusContract.id,
          newStatusContract,
          authToken
        );
        Swal.fire("Success", "El contrato ha sido descartado correctamente", "success").then(() =>
          updateContract(res.data)
        );
      } catch {
        Swal.fire(
          "Error",
          "No pudimos actualizar totalmente el contrato, por favor intenta nuevamente",
          "error"
        );
      }
    },
    []
  );

  return (
    <>
      <CollapseCard title="Descartar contrato">
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textLabel}>
                    Si por algún motivo el propietario decide rechazar esta solicitud :( debes
                    presionar aquí:
                  </Typography>
                </Grid>
              </Grid>
              <CustomLoadingButton
                text="Descartar contrato"
                onClick={() => setOpenRejectDesist((open) => !open)}
                type="outlined"
                buttonClassName={classes.buttonColor}
              />
            </CardContent>
          </div>
        </Card>
      </CollapseCard>
      <RejectDesistModal
        open={openRejectDesist}
        onClose={() => setOpenRejectDesist((open) => !open)}
        onSave={saveDiscard}
        possibleAuthors={contractDetailDiscardInfo.authors}
        possibleReasons={{
          desist: contractDetailDiscardInfo.desistReasons,
          reject: contractDetailDiscardInfo.rejectReasons,
        }}
        defaultComment={contract.discard_comment}
      />
    </>
  );
}

export default DismissContract;
