import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "@houm-com/ui/Alert";

import subscriptionService from "services/subscriptionService";
import { useGetToken } from "hooks";

interface IFormData {
  subscriptionId: number;
  finalizedReasonCode?: string;
}

const useDeactivateSubscription = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const mutation = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      const data = {
        subscription_id: formData.subscriptionId,
        need_republish: false,
        ...(formData.finalizedReasonCode && {
          finalized_reason_code: formData.finalizedReasonCode,
        }),
      };
      const response = await subscriptionService.unsubscribeSubscription(data, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subscription");
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        alert.success({
          message: "La subscripción se dio de baja correctamente",
        });
        onClose?.();
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo dar de baja la subscripción",
          disclaimer: `Error: ${reason ? reason?.data?.detail : e}`,
        });
      },
    }
  );

  return {
    ...mutation,
  };
};

export default useDeactivateSubscription;
