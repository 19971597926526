import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { Grid } from "@material-ui/core";

import { setProperty as setGlobalProperty } from "context/propertyContext/propertyActions";
import LayoutsIndex from "Components/Admin/administrator/projects/layouts";
import { ProjectProvider } from "context/projectContext/projectContext";
import { useProperty } from "context/propertyContext/propertyContext";
import ContractInfo from "Components/Admin/UIComponents/ContractInfo";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CustomTabs from "Components/Admin/UIComponents/CustomTabs";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

import {
  ConciergeTab,
  GeneralTab,
  InspectionReportTab,
  NegotiationsTab,
  RevTab,
  VisitsTab,
} from "./tabs";
import PropertyStatisticsSummary from "./tabs/brokerage/PropertyStatisticsSummary";
import PropertyModalMessage from "./tabs/general/components/PropertyModalMessage";
import InterestedPeople from "./tabs/interestedPeople/InterestedPeople";
import PropertyApplicants from "./tabs/applicants/PropertyApplicants";
import BankAccountsPropietary from "./partials/BankAccountsPropietary";
import SubscriptionsInfo from "./tabs/subscriptions/SubscriptionsInfo";
import useAdminPropertyShowStyles from "./AdminPropertyShowStyles";
import ProjectForm from "../../projects/newProjects/ProjectForm";
import BasicPropertyInfo from "./partials/BasicPropertyInfo";
import PropertyMedia from "./tabs/media/PropertyMedia";
import UserLandlord from "./partials/UserLandlord";
import Schedule from "./tabs/scheduler/Schedule";
import AvailabilitySchedule from "./tabs/availabilitySchedule/AvailabilitySchedule";
import useGuard from "domains/auth/hooks/useGuard";

function AdminPropertyShow() {
  const { getToken } = useGetToken();
  const classes = useAdminPropertyShowStyles();
  const [loadingProperty, setLoadingProperty] = useState(true);
  const [showProjectModal, setShowProjectModal] = useState(false);

  const {
    state: { property, ...state },
    dispatch,
  } = useProperty();
  const { guard } = useGuard();
  const showOwnerDetails =
    guard.roles.any([
      "control_tower_agent",
      "sales",
      "control_tower_admin",
      "admin",
      "evaluation",
      "property_advisor",
      "management",
    ]) || guard.permissions.any(["read:property_owner:all"]);

  const { id } = useParams<{ id: string }>();

  const loadProperty = async (authToken: string) => {
    try {
      const result = await propertyService.get(id, authToken);
      dispatch(setGlobalProperty(result.data));
    } catch (e) {
      Swal.fire("Error", `No se pudo obtener los datos de la propiedad. Error: ${e}`, "error");
    } finally {
      setLoadingProperty(false);
    }
  };

  useEffect(() => {
    getToken().then((authToken) => loadProperty(authToken));
  }, [state.refetchPropertyInfo]);

  const tabs = useMemo(
    () => [
      {
        header: "General",
        component: <GeneralTab property={property} />,
      },
      {
        header: "Fotos",
        component: <PropertyMedia property={property} />,
      },
      {
        header: "Corretaje",
        component: <PropertyStatisticsSummary property={property} />,
      },
      {
        header: "Agenda",
        component: <Schedule property={property} />,
      },
      {
        header: "Horarios de visita",
        component: <AvailabilitySchedule id={property?.id} />,
      },
      {
        header: "Interesados",
        component: <InterestedPeople property={property} />,
      },
      {
        header: "Postulantes",
        component: <PropertyApplicants property={property} />,
      },
      ...(showOwnerDetails
        ? [
            {
              header: "Propietario",
              component: (
                <Grid container spacing={3}>
                  <UserLandlord user={property?.user} />
                  <BankAccountsPropietary property={property} />
                </Grid>
              ),
            },
          ]
        : []),
      {
        header: "Conserje",
        component: <ConciergeTab property={property} />,
      },
      {
        header: "Solicitudes",
        component: <SubscriptionsInfo property={property} />,
      },
      {
        header: "ReV",
        component: <RevTab property={property} />,
      },
      ...(showOwnerDetails
        ? [
            {
              header: "Contrato",
              component: <ContractInfo headless={true} />,
            },
          ]
        : []),
      {
        header: "Visitas",
        component: <VisitsTab property={property} />,
      },
      {
        header: "Actas",
        component: <InspectionReportTab property={property} />,
      },
      {
        header: "Negociación",
        component: <NegotiationsTab property={property} />,
      },
      ...(property?.user?.role === "real_estate_developer"
        ? [
            {
              header: "Tipologías/Unidades",
              component: <LayoutsIndex property={property} />,
            },
          ]
        : []),
    ],
    [property, showOwnerDetails]
  );

  return (
    <>
      <PropertyModalMessage />
      <ProjectProvider>
        <Loading loading={loadingProperty}>
          <>
            <BasicPropertyInfo property={property} toogleEditProject={setShowProjectModal} />
            <CustomTabs tabsContent={tabs} aria="plans" scrollClasses={classes.customTabsStyle} />
          </>
        </Loading>
        <CustomDialog onClose={() => setShowProjectModal(false)} open={showProjectModal}>
          <div className={classes.wrapper}>
            <ProjectForm toggleEditModal={setShowProjectModal} />
          </div>
        </CustomDialog>
      </ProjectProvider>
    </>
  );
}

export default AdminPropertyShow;
