import { SnackbarProvider } from "notistack";

import { DialogContent, DialogTitle, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import { Locale } from "models/Countries";

import useCreateCodebtorModalStyles from "./CreateCodebtorModalStyles";
import CodebtorForm from "./components/CodebtorForm";

interface Props {
  openModal: boolean;
  handleModal: () => void;
  country: Locale;
  applicantId: string;
  propertyId: number;
}

const CreateCodebtorModal = ({
  openModal,
  handleModal,
  country,
  applicantId,
  propertyId,
}: Props) => {
  const classes = useCreateCodebtorModalStyles();

  return (
    <DSModal open={openModal} onClose={handleModal} maxWidth="xs" showCloseButton scroll="paper">
      <DialogTitle>
        <Typography className={classes.title}>Agregar coarrendatario/codeudor</Typography>
      </DialogTitle>
      <DialogContent>
        <SnackbarProvider>
          <CodebtorForm
            country={country}
            applicantId={applicantId}
            propertyId={propertyId}
            handleModal={handleModal}
          />
        </SnackbarProvider>
      </DialogContent>
    </DSModal>
  );
};

export default CreateCodebtorModal;
