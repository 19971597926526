import { CountryUpperCase, Regions, Timezones } from "models/Countries";

export const getTimezones = (country: CountryUpperCase, region: Regions): Timezones => {
  const getTimezoneMexico = () => {
    if (region === "Baja California") return "America/Tijuana";
    return "America/Mexico_City";
  };

  switch (country) {
    case "Chile":
      return "America/Santiago";

    case "Colombia":
      return "America/Bogota";

    case "Mexico":
    case "México":
      return getTimezoneMexico();

    default:
      return null;
  }
};
