import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    marginTop: theme.spacing(2),
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  status: {
    marginLeft: theme.spacing(1.25),
  },
  commentsContainer: {
    width: "60%",
    paddingBottom: theme.spacing(0),
  },
  greenStatus: {
    color: "green",
    borderColor: "green",
  },
  orangeStatus: {
    color: "orange",
    borderColor: "orange",
  },
  redStatus: {
    color: "red",
    borderColor: "red",
  },
  readyStatus: {
    color: "white",
    borderColor: "green",
    backgroundColor: "green",
  },
  rejectedStatus: {
    color: "white",
    borderColor: "red",
    backgroundColor: "red",
  },
  neutralStatus: {
    color: "black",
    borderColor: "#ccc",
    backgroundColor: "#ccc",
  },
  select: {
    width: 250,
  },
  editLink: {
    marginLeft: theme.spacing(3.75),
  },
  statusData: {
    marginLeft: theme.spacing(3.75),
    display: "flex",
    alignItems: "center",
  },
  editBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  h6: {
    marginTop: theme.spacing(0.5),
    fontWeight: "bold",
  },
  editButton: {
    marginLeft: theme.spacing(2),
    textTransform: "none",
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    margin: theme.spacing(1),
  },
  closeButton: {
    zIndex: theme.zIndex.tooltip,
    color: "black",
  },
  actions: {
    textTransform: "none",
    color: "blue",
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  dialogTitleContainer: {
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));
