import { IoAdd, IoSearchOutline } from "@houm-com/ui-icons";
import FieldInput from "@houm-com/ui/FieldInput";
import { useQueryOrganizations } from "../queries/organizationsQueries";
import Button from "@houm-com/ui/Button";
import { useHistory } from "react-router-dom";
import LoadingScreen from "domains/common/components/LoadingScreen";
import TablePagination from "Components/Admin/UIComponents/DesignSystem/TablePagination";
import { useState } from "react";

export default function Organizations() {
  const { data } = useQueryOrganizations();
  const history = useHistory();
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState<number>(5);

  return (
    <div className="mx-20">
      <div className="flex items-center justify-between">
        <p className="text-h40 text-secondary-cta">Organizaciones</p>
        <Button variant="primary" size="lg">
          Crear organizacion
          <IoAdd />
        </Button>
      </div>
      {data ? (
        <div className="mt-8 rounded-md border border-secondary-15">
          <FieldInput
            type="search"
            classNameInput="bg-transparent border-b border-secondary-20 py-3 rounded-md shadow"
            startAdornment={IoSearchOutline}
            placeholder="Buscar..."
          />
          <ul className="divide-y divide-secondary-10">
            <div className="p-3 grid grid-cols-4 font-bold uppercase text-[14px] text-secondary-80">
              <div>Nombre</div>
              <div>Tipo</div>
              <div>Creacion</div>
              <div>Estado</div>
            </div>
            {data.results?.map((org: any) => (
              <div
                onClick={() => history.push(`/organizations/${org.id}`)}
                className="p-3 grid grid-cols-4 text-secondary-100 cursor-pointer hover:bg-secondary-10"
                key={org.id}>
                <div className="font-semibold text-secondary-120 capitalize">{org.name}</div>
                <div className="capitalize">{org.type}</div>
                <div>{new Date(org.created_at).toLocaleDateString()}</div>
                <div>
                  <span className="px-2 rounded-full bg-success-10 text-success-120">Activo</span>
                </div>
              </div>
            ))}
          </ul>

          <TablePagination
            pageCount={Math.ceil(data.count / pageSize)}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={page}
            setCurrentPage={setPage}
            pageSizeOptions={[5, 10, 20]}
            loading={false}
          />
        </div>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}
