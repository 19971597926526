import { useState, useRef, useEffect } from "react";

import { useHistory } from "react-router-dom";

import axios, { AxiosResponse } from "axios";
import demandService from "services/demandService";

import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import { useGetToken } from "hooks";
import Searchbar from "Components/Admin/UIComponents/molecules/Searchbar";

function SearchLead() {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { getToken } = useGetToken();
  const {
    state: { deal },
  } = useDemandLead();
  const source = useRef(null);

  useEffect(() => {
    getSource();
  }, []);

  function getSource() {
    source.current = null;
    const { CancelToken } = axios;
    source.current = CancelToken.source();
    return source.current;
  }
  const handleSearch = (val) => {
    source.current?.cancel();
    const sourceCancelation = getSource();
    setLoading(true);
    getToken().then(async (authToken) => {
      try {
        const leadsResponse = (await demandService.searchLead(
          val,
          authToken,
          sourceCancelation.token,
          20,
          deal === "venta" ? "sales" : "rentals"
        )) as AxiosResponse;
        if (leadsResponse.data) {
          setLeads(leadsResponse.data.results);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    });
  };

  return (
    <Searchbar
      options={leads}
      value={undefined}
      loading={loading}
      onChange={(e, val: any) => history.push(`/demand/lead/${val.id}`)}
      onInputChange={(e, val) => handleSearch(val)}
      getOptionLabel={(option: any) =>
        `${option?.id}, ${option?.lead?.name}, ${option?.lead?.email}, ${option?.lead?.phone}`
      }
    />
  );
}

export default SearchLead;
