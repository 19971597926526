/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button } from "@material-ui/core";
import classNames from "classnames";

import useStyles from "./ButtonSelectStyles";

interface ButtonSelectProps {
  // eslint-disable-next-line react/require-default-props
  label?: string | number;
  isActive: Boolean;
  onClick: () => void;
}

export function ButtonSelect({ label = "", isActive, onClick }: ButtonSelectProps) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      className={classNames(classes.buttonSelectorRoot, isActive && classes.buttonSelectorActive)}
      onClick={onClick}>
      {label}
    </Button>
  );
}

export default ButtonSelect;
