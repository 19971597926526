import { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import Searchbar from "Components/Admin/UIComponents/molecules/Searchbar";
import { Subscription } from "models/Subscription";

import useSearchBarSubscriptions from "../hooks/useSearchBarSubscriptions";
import Option from "./components/Option";
import ScreenHeader from "domains/common/components/ScreenHeader";

function SearchBarSubscriptions() {
  const history = useHistory();
  const [val, setValue] = useState("");
  const { isLoading, error, data, setSearchQuery } = useSearchBarSubscriptions();

  if (error) {
    Swal.fire({
      type: "error",
      text: `Ha ocurrido el siguiente error: ${error}`,
    });
  }

  return (
    <>
      <ScreenHeader
        title="Buscar clientes"
        description="Buscar por nombre, correo o subscription id"
      />
      <Searchbar
        filterOptions={(options, _) => options}
        color="primary"
        options={(data as Subscription[]) || []}
        loading={isLoading}
        placeholder="Buscar Subscripción"
        onInputChange={(_, val) => {
          setSearchQuery(val);
          setValue(val);
        }}
        onChange={(_, val: any) => history.push(`/admin/clients/${val.legacy_id}`)}
        getOptionLabel={(option: any) => option.legacy_id}
        renderOption={(option: any) => <Option option={option as Subscription} value={val} />}
      />
    </>
  );
}

export default SearchBarSubscriptions;
