import { ActionType, Dispatch, Action } from "context/similarProperties/similarPropertiesTypes";
import {
  CalculatorResponse,
  SimilarHistoricProperties,
  SimilarHistoricPropertyResponse,
} from "models/Calculator";
import { CountryUpperCase } from "models/Countries";
import propertyService from "services/propertyService";
import { getTimezone } from "utils";
import { parseDateToString } from "utils/datetime";
import { formatCurrency } from "utils/formatCurrency";
import rentorService from "services/rentruvioService";
import { PropertyData } from "models/Property";

export const setLoading = (loading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const getSimilarHistoricProperties = async (
  id: number,
  country: CountryUpperCase,
  mode: "rental" | "sale",
  callSize: number,
  commune: string,
  dispatch: Dispatch,
  token: string
) => {
  try {
    const res = await propertyService.similarHistoricProperty(id, mode, callSize, token);
    if (res.data) {
      dispatch({
        type: ActionType.SET_SIMILAR_HISTORIC_PROPERTIES,
        similarHistoricProperties: formattedRow(res.data, country, commune),
      });
    }
  } catch (e) {
    console.error(e);
  }
};

const formattedRow = (
  rows: SimilarHistoricPropertyResponse[],
  country: CountryUpperCase,
  commune: string
): SimilarHistoricProperties[] =>
  rows.map((r) => ({
    id: r.id,
    link: `/admin/properties/${r.uid}`,
    publicationDate: parseDateToString(
      r.times.last_publication_date,
      getTimezone(country, commune)
    ),
    reservationDate: parseDateToString(r.times.reservation_date, getTimezone(country, commune)),
    publishedTime: Math.floor(parseFloat(r.times.published_time) / (60 * 60 * 24)),
    address: r.address,
    hasHoumBox: r.has_houm_box ? "Si" : "No",
    visits: r.visits.done,
    price: formatCurrency(r.price.currency, r.price.value),
  }));

export const setSimilarProperties = async (
  property: PropertyData,
  calculatorResponse: CalculatorResponse,
  deal,
  dispatch: Dispatch
) => {
  try {
    dispatch(setLoading(true));
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties: [],
    });

    const res = await rentorService.getSimilarProperties(property.id, deal, 1, 100);

    if (res.status === 204) throw new Error();

    const { data: similarProperties } = res;
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties,
    });
    dispatch(setLoading(false));
  } catch (err) {
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties: [],
    });
    dispatch(setLoading(false));
  }
};
