import { Button, ButtonProps } from "@material-ui/core";
import useActionButtonStyle from "./ActionButtonStyle";

function ActionButton(props: ButtonProps) {
  const classes = useActionButtonStyle();
  return (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      className={classes.actionButton}
      {...props}
    />
  );
}

export default ActionButton;
