import { SnackbarProvider } from "notistack";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { GridCloseIcon } from "@material-ui/x-grid";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

import { ContractModel } from "models/Contract";

import useSendProfileModalStyles from "./SendProfileModalStyles";
import SendProfile from "./components/SendProfile";

interface Props {
  contract: ContractModel;
  handleCloseModal: () => void;
  openModal: boolean;
  reevaluationFlag?: boolean;
  mainApplicantId?: number;
}

function SendProfileModal({
  contract,
  handleCloseModal,
  openModal,
  reevaluationFlag = false,
  mainApplicantId,
}: Props) {
  const classes = useSendProfileModalStyles();

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      scroll="paper"
      transitionDuration={500}
      PaperProps={{
        className: classes.paper,
      }}>
      <Grid container justifyContent="space-between">
        <DialogTitle>Mandar perfil al propietario</DialogTitle>
        <DialogActions>
          <IconButton onClick={handleCloseModal}>
            <GridCloseIcon />
          </IconButton>
        </DialogActions>
      </Grid>
      <DialogContent>
        <SnackbarProvider>
          <SendProfile
            contract={contract}
            mainApplicantId={mainApplicantId}
            handleCloseModal={handleCloseModal}
            reevaluateFlag={reevaluationFlag}
          />
        </SnackbarProvider>
      </DialogContent>
    </Dialog>
  );
}

export default SendProfileModal;
