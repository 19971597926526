import classNames from "classnames";

import { Locale } from "models/Countries";

import useStyles from "./CountrySelectorStyles";
import CountryChip from "../CountryChip";

interface Props {
  stateCountry: Locale | "";
  onChange: (country: Locale) => void;
  rootClassName?: string;
  countriesList?: Locale[];
}

const CountrySelector = ({
  stateCountry,
  onChange,
  countriesList = ["cl", "mx", "co"],
  rootClassName,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, rootClassName)} data-testid="country-selector">
      {countriesList.map((country) => (
        <CountryChip
          key={country}
          country={country}
          onClick={() => onChange(country)}
          selected={country === stateCountry}
        />
      ))}
    </div>
  );
};

export default CountrySelector;
