import { format } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { useGetToken } from "hooks";
import { ILandlordBreakdown } from "models/AdminModels/Payments";
import { Locale } from "models/Countries";
import newPaymentService from "services/newPaymentService";

import { parseLandlordBreakdown } from "../utils/parsers";

interface Props {
  page?: number;
  pageSize?: number;
  propertyId: number;
  country: Locale;
}

const useLandlordPayments = ({ page, pageSize, propertyId, country }: Props) => {
  const { getToken } = useGetToken();
  const { id } = useParams<{ id: string }>();
  const { isLoading, error, data }: UseQueryResult<ILandlordBreakdown[], Error> = useQuery<
    ILandlordBreakdown[],
    Error
  >(["landlordBreakdown", id, country, page, pageSize, propertyId], async () => {
    const authToken = await getToken();
    const response = await newPaymentService.getLandlordBreakdown(country, id, authToken, {
      date: format(new Date(), "yyyy-MM-dd"),
      per_page: pageSize,
      page,
      properties_ids: propertyId,
    });
    return parseLandlordBreakdown(response.data);
  });
  return { isLoading, error, data };
};

export default useLandlordPayments;
