import { useQuery, UseQueryResult } from "react-query";

import rentApplicationService from "services/rentApplicationService";
import { useGetToken } from "hooks";

const useContractCreated = (applicantId: string) => {
  const { getToken } = useGetToken();

  const {
    isLoading: contractFlagLoading,
    error: contractFlagError,
    data: contractFlagData,
  }: UseQueryResult<boolean, Error> = useQuery(
    ["contractCreatedFlag"],
    async () => {
      const authToken = await getToken();
      const response = await rentApplicationService.getByApplicant(applicantId, authToken);
      return response.data.count > 0;
    },
    {
      enabled: !!applicantId,
    }
  );

  return { contractFlagLoading, contractFlagError, contractFlagData };
};

export default useContractCreated;
