import React from "react";
import { Grid } from "@material-ui/core";

import { ControlledFileUpload } from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "./FormBlock";

function PhotoField() {
  return (
    <FormBlock>
      <Grid container>
        <Grid item xs={12}>
          <ControlledFileUpload allowedMimeTypes={["png", "jpeg"]} id="photo" label="Foto" />
        </Grid>
      </Grid>
    </FormBlock>
  );
}

export default React.memo(PhotoField);
