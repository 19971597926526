import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { validate, format } from "rut.js";

import useStyles from "./ContractPersonEditStyles";
import { DocumentTypeProps } from "context/contractPersonContext/contractPersonTypes";
import { useContractPerson } from "context/contractPersonContext/contractPersonContext";
import { setSearchParams } from "context/contractPersonContext/contractPersonActions";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

export default function DocumentForm() {
  const { state, dispatch } = useContractPerson();
  const classes = useStyles();
  const [document, setDocument] = useState<string>("");
  const [documentType, setDocumentType] = useState<DocumentTypeProps>();
  const [documentValid, setDocumentValid] = useState(true);

  useEffect(() => {
    if (state.documentTypes.length && state.country === "Chile") {
      setDocumentType({ ...state.documentTypes[0] });
    }
  }, [state.country, state.documentTypes]);

  const handleDocumentTypeChange = (e) => {
    const doc = state.documentTypes.find((doc) => doc.id === e.target.value);
    setDocumentType({ ...doc });
  };

  const handleDocumentChange = (e) => {
    if (e.target.value.length === 0 || e.target.value === "-") {
      setDocument("");
      setDocumentValid(false);
    } else if (state.country === "Chile") {
      setDocument(format(e.target.value));
      setDocumentValid(validate(format(e.target.value)));
    } else {
      setDocument(e.target.value);
      if (e.target.value.match(documentType.regex)) {
        setDocumentValid(true);
      } else {
        setDocumentValid(false);
      }
    }
  };

  const handleDocumentSubmit = async () => {
    dispatch(setSearchParams({ document, documentType }));
  };

  return (
    <Loading loading={!state.country}>
      <div className={classes.ContractPersonEditWrapper}>
        {state.country !== "Chile" && (
          <div className={classes.ContractPersonRutForm}>
            <FormControl className={classes.formControl}>
              <InputLabel id="document-type-select-label">Tipo de documento</InputLabel>
              <Select
                labelId="document-type-select-label"
                value={documentType?.id || ""}
                onChange={handleDocumentTypeChange}>
                {state.documentTypes.map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    {doc.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div className={classes.ContractPersonRutForm}>
          <TextField
            id="document-input"
            variant="outlined"
            className={classes.ContractPersonEditTextField}
            placeholder={`Número de documento del ${state.title || "involucrado"}`}
            value={document}
            onChange={handleDocumentChange}
          />
          <Button
            variant="contained"
            className={classes.ContractPersonSearchRutButton}
            color="primary"
            onClick={handleDocumentSubmit}>
            {state.fetchingPerson ? <CircularProgress size={22} color="secondary" /> : "Buscar"}
          </Button>
        </div>
        {!documentValid && (
          <Typography className={classes.ContractPersonWarning}>Formato inválido</Typography>
        )}
      </div>
    </Loading>
  );
}
