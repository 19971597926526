import { useEffect } from "react";

import { useMap } from "context/map/mapContext";
import { setLoaded, setMap } from "context/map/mapActions";
import useMapHook from "hooks/useMap";

import useMapHandlerStyles from "./MapHandlerStyles";

interface Props {
  lat: number;
  lng: number;
}

function MapHandler({ lat, lng }: Props) {
  const classes = useMapHandlerStyles();
  const { dispatch, state } = useMap();
  const { map, mapContainer } = useMapHook({
    lat,
    lng,
    style: "mapbox://styles/mapbox/light-v10",
    minZoom: 0,
    maxZoom: 20,
  });

  useEffect(() => {
    if (map.current && !state.map) {
      dispatch(setMap(map.current));
      map.current.on("load", () => {
        dispatch(setLoaded(true));
      });
    }
  }, [map.current]);

  return (
    <div className={classes.mapContainer}>
      <div className={classes.mapRoot}>
        <div
          className={classes["mapbox-map-wrapper"]}
          ref={(el) => {
            mapContainer.current = el;
          }}
        />
      </div>
    </div>
  );
}

export default MapHandler;
