import { makeStyles, Theme } from "@material-ui/core";

const useProfilingFormStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    background: theme.palette.primary.main,
    margin: theme.spacing(4, 0),
  },
}));

export default useProfilingFormStyles;
