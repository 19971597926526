import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "grid",
    placeItems: "center",
    height: "50vh",
    width: "100%",
  },
}));

export default useStyles;
