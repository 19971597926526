import { makeStyles, Theme } from "@material-ui/core";

const useBankDataCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 20,
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(1, 2, 2),
  },
  labelContainer: {
    width: "50%",
  },
}));
export default useBankDataCardStyles;
