import { ActionType, State, Action } from "./typologyTypes";

export const initialState: State = {
  typology: {},
  typologies: [],
  isLoadingDataTable: false,
  UTImages: [],
  currentId: null,
};

const demandLeadReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_TYPOLOGY:
      return {
        ...state,
        typology: action.typology,
      };

    case ActionType.SET_TYPOLOGIES:
      return {
        ...state,
        typologies: action.typologies,
      };

    case ActionType.SET_IS_LOADING_DATA_TABLE:
      return {
        ...state,
        isLoadingDataTable: action.isLoadingDataTable,
      };

    case ActionType.SET_UT_IMAGES:
      return {
        ...state,
        UTImages: action.UTImages,
      };

    case ActionType.SET_CURRENT_ID:
      return {
        ...state,
        currentId: action.currentId,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
