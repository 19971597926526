import { makeStyles, Theme } from "@material-ui/core";

const useCommonExpensesModalStyles = makeStyles((theme: Theme) => ({
  commonExpensesModalRoot: {
    padding: theme.spacing(0, 4),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
  },
  selectLabel: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  selectContainer: {
    display: "inline-grid",
  },
  input: {
    width: "100%",
  },
  select: {
    width: "100%",
  },
}));

export default useCommonExpensesModalStyles;
