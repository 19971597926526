/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridColDef, GridColumns } from "@material-ui/data-grid";

import { GridCellParams } from "@material-ui/x-grid";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import CustomDataTable from "../../../UIComponents/CustomDataGrid";
import CustomLink from "../../../UIComponents/CustomLink";
import { useUsers } from "../context/usersContext";
import Toolbar from "./Toolbar";
import useStyles from "./TableStyles";

function Table() {
  const { state: userState } = useUsers();
  const { state, dispatch } = useTable();
  const { pageSize, currentPage } = state;
  const { resultsCount, users, loading } = userState;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CustomDataTable
        disableColumnFilter
        rowCount={resultsCount}
        columns={columns}
        rows={users}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{ Toolbar }}
      />
    </div>
  );
}

export default Table;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  sortable: false,
};

const columns: GridColumns = [
  { ...columnsConfig, field: "id", headerName: "User ID", sortable: true },
  {
    ...columnsConfig,
    field: "createdAt",
    headerName: "Fecha creación",
  },
  { ...columnsConfig, field: "firstName", headerName: "Nombre", width: 130 },
  { ...columnsConfig, field: "lastNames", headerName: "Apellidos", width: 200 },
  { ...columnsConfig, field: "email", headerName: "Correo", width: 200 },
  { ...columnsConfig, field: "phone", headerName: "Teléfono", width: 130 },
  {
    ...columnsConfig,
    field: "link",
    headerName: "Acciones",
    width: 130,
    renderCell: ({ getValue, id }: GridCellParams) => (
      <CustomLink to={`users/${getValue(id, "id")}`} />
    ),
  },
];
