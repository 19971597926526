import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import contractService from "services/contractService";
import useGetToken from "hooks/useGetToken";
import { IPlanMovement } from "models/Contract";

type EditPlanMovementProps = {
  formData: IPlanMovement;
};

const useEditPlanMovement = (
  movementId: number,
  typeMovement: string,
  onSuccess: (formData: IPlanMovement) => void
) => {
  const { getToken } = useGetToken();

  const {
    mutate: editPlanMovement,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useMutation(
    async ({ formData }: EditPlanMovementProps) => {
      const authToken = await getToken();
      const response = await contractService.editPlanMovements(formData, movementId, authToken);
      return response.data;
    },
    {
      onSuccess: (response: IPlanMovement) => {
        alert.success({
          message: `${typeMovement} actualizado`,
          disclaimer: `El ${typeMovement} ha sido modificado exitosamente`,
        });
        onSuccess(response);
      },
      onError: (e) => {
        alert.error({
          message: `Error al editar datos`,
          disclaimer: `Los datos del ${typeMovement} no han podido ser editados, error: ${e}`,
        });
      },
    }
  );

  return {
    editPlanMovement,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useEditPlanMovement;
