import { CountryUpperCase } from "models/Countries";
import { CurrencyCode } from "models/Currencies";
import { DocumentType } from "models/Document";
import { EvaluationModel } from "models/Evaluation";
import { HoumerModel } from "models/Houmer";
import { IPlan } from "models/Plan";
import { UserModel } from "models/User";

export type PropertySaleStatus =
  | "incomplete"
  | "photo_scheduled"
  | "photo_cancelled"
  | "photo_done"
  | "complete"
  | "published"
  | "sold"
  | "unpublished"
  | "lost"
  | "archived"
  | "out_of_coverage"
  | "unpublished_by_import"
  | "negotiation"
  | "unknown";

export type PropertyRentalStatus =
  | "incomplete"
  | "photo_scheduled"
  | "photo_cancelled"
  | "photo_done"
  | "complete"
  | "published"
  | "reserved"
  | "republished"
  | "rented"
  | "unpublished"
  | "lost"
  | "archived"
  | "out_of_coverage"
  | "unpublished_by_import"
  | "unknown";

export interface AssociationAmenities {
  association_name?: string;
  floors?: number;
  guest_parking_spaces?: number;
  has_all_day_vigilance?: boolean;
  has_bbq_area?: boolean;
  has_cinema?: boolean;
  has_concierge?: boolean;
  has_elevator?: boolean;
  has_games_room?: boolean;
  has_gourmet_space?: boolean;
  has_gym?: boolean;
  has_laundry?: boolean;
  has_party_room?: boolean;
  has_patio?: boolean;
  has_pet_area?: boolean;
  has_playground?: boolean;
  has_playroom?: boolean;
  has_roof_garden?: boolean;
  has_sauna?: boolean;
  has_steam_room?: boolean;
  has_swimming_pool?: boolean;
}

export interface HomeCheckerDetails {
  id: number;
  key_manager?: string;
  gourmet_space?: boolean;
  laundry?: boolean;
  observations?: string;
  party_room?: boolean;
  play_room?: boolean;
  quincho?: boolean;
  apartment_floor?: number;
  inhabited_property_description?: string;
  inhabited_property?: boolean;
  has_houm_box?: boolean;
  third_party_coordination?: boolean;
  administration_email?: string;
  administration_phone?: string;
  concierge_email?: string;
  concierge_name?: string;
  concierge_phone?: string;
  houm_box_number: string;
  houm_box_password: string;
  third_party_coordination_description?: string;
  third_party_coordination_phone?: string;
  third_party_coordination_email: string;
  third_party_coordination_full_name: string;
  third_party_coordination_person: string;
}

export interface HomeChecker {
  id?: string;
  name?: string;
  photo?: string;
}

export interface HoumOwner {
  id?: number;
  name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  second_last_name?: string;
}

export interface Neighborhood {
  id?: number;
  code?: string;
  neighborhood?: string;
  redirect_to?: string;
  commune?: string;
  region?: string;
  country?: string;
  polygon?: string;
  longitude?: number;
  latitude?: number;
  coordinates?: string;
  operative?: boolean;
  country_code?: string;
}

export interface Photos {
  id?: string;
  created_at?: string;
  updated_at?: string;
  filename?: string;
  featured?: boolean;
  order?: number;
  property?: number;
  url?: string;
}

export interface PropertyPrice {
  value?: number;
  promotional_value?: number;
  recommended_value?: number;
  currency?: string;
  default?: boolean;
}

export interface Macrozone {
  id?: number;
  created_at?: string;
  updated_at?: string;
  country?: string;
  city?: string;
  name?: string;
  description?: string;
  polygon?: string;
  city_marketplace?: number;
}

export type Status =
  | "complete"
  | "incomplete"
  | "lost"
  | "photo_cancelled"
  | "photo_done"
  | "photo_scheduled"
  | "published"
  | "republished"
  | "reserved"
  | "rented"
  | "unpublished"
  | "rented"
  | "sold"
  | "archived"
  | "out_of_coverage"
  | "unpublished_by_import"
  | "unknown";

export interface PropertyDetails {
  id: number;
  apartment_type?: string;
  banos?: number;
  created_at?: string;
  closet?: string;
  currency_code?: CurrencyCode;
  dormitorios?: number;
  external_description?: string;
  en_suite?: boolean;
  environments?: number;
  half_baths?: number;
  hot_water_system?: "thermal_store" | "boiler" | "califont" | "undefined";
  has_water_tank?: boolean;
  loggia_size?: number;
  m_construidos?: number;
  m_terreno?: number;
  terrace_size?: number;
  updated_at?: string;
  orientacion?: string;
  gc?: number;
  public_services_expenses?: number;
  piscina?: boolean;
  condominio?: boolean;
  calefaccion?: boolean;
  bodega?: boolean;
  mascotas?: boolean;
  minisplits?: number;
  warehouse_number?: number;
  curtain_hanger?: boolean;
  has_clothing_area?: boolean;
  kitchen_daily_dining_room?: boolean;
  kitchen_is_furnished?: boolean;
  laundry_capacity?: boolean;
  terraza?: number;
  has_laundry_room?: boolean;
  has_balcony?: boolean;
  has_air_conditioning?: boolean;
  has_roof_garden?: boolean;
  is_renewed?: boolean;
  is_construction_in_gray?: boolean;
  is_common_expenses_included?: boolean;
  parking_numbers?: number;
  parking_type?: string;
  furnished?: string;
  house_extension?: string;
  status?: Status;
  lost_reason?: string;
  last_status_change: string;
  amoblado?: number;
  vende_id?: number;
  estacionamientos?: number;
  parking?: boolean;
  servicios?: number;
  gym?: boolean;
  tipo_moneda?: string;
  observaciones?: string;
  valor?: number;
  promotional_price?: number;
  promotion_months?: number;
  first_available_handover_date?: string;
  tipo_moneda_venta?: string;
  observaciones_venta?: string;
  valor_venta?: number;
  location?: string;
  latitud?: number;
  longitud?: number;
  rol?: string;
  objetivo?: string;
  ubicacion?: string;
  m_terreno_casa?: number;
  has_houm_cleaning?: boolean;
  brokerage_commission?: number;
  property: number;
}

export interface SalesDetails {
  first_available_handover_date?: string;
  external_description: string;
  property: number;
  brokerage_commission: number;
  currency_code: CurrencyCode;
  description: string;
  first_publication_date: string;
  last_publication_date: string;
  lost_reason: string;
  price: number;
  recommended_price: number;
  recommended_price_max: number;
  recommended_price_min: number;
  last_status_change: string;
  updated_at: string;
  sold_date: string;
  status: Status;
  currency: string;
  priority: string | number;
}

export interface IControlTowerAgent {
  id: number;
  last_login: string;
  created_at: string;
  updated_at: string;
  name: string;
  last_name: string;
  second_last_name: string;
  rut: string;
  document: string;
  phone: string;
  self_registered_lessor: string;
  email: string;
  remember_token: string;
  role: string;
  occupation?: string;
  state: string;
  url_photo: string;
  nationality: string;
  first_time_user: number;
  first_deal_id: number;
  owner_id: number;
  declared_income: number;
  referral_id?: number;
  manager: string;
  gclick_id?: number;
  fbclid_id?: number;
  address: string;
  country: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  referred_url: string;
  datacredito_consent: boolean;
  document_type: number;
  company: string;
}

export interface ISchedules {
  id: number;
  created_at: string;
  updated_at: string;
  completed: boolean;
  date: string;
  begin_date: string;
  end_date: string;
  google_calendar_id: number;
  comment: string;
  status: string;
  uid: string;
  cancel_reason: string;
  state_progress: string;
  action_source: string;
  is_streaming: boolean;
  origin: string;
  appraiser_confirmed: boolean;
  third_party_confirmed: boolean;
  schedule_type: {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    hour_step: number;
    slots: number;
  };
  appraiser: HoumerModel;
  selected_cancel_reason: {
    comment: string;
    type: string;
  };
  cancel_reason_item: string;
  modifier: IControlTowerAgent;
  ct_agent: IControlTowerAgent;
}

interface IPortalApplicant {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  phone: string;
  rut: string;
  fecha: string;
  email: string;
  status: string;
  origin: string;
  state_notified_time: string;
  state_acknowledged_time: string;
  state_contacted_time: string;
  close_reason: string;
  country: string;
  for_sale: boolean;
  for_rental: boolean;
  need_contact: boolean;
  deal_id: number;
  appraiser: HoumerModel;
}

export interface Quotation {
  id: number;
  created_at: string;
  updated_at: string;
  monthly_price: number;
  type_money: string;
  rent_tenant: number | string;
  has_endorsement: boolean;
  conditions: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  rut: string;
  propietary_info: string;
  propietary_pay: string | number;
  insurance_payment_type: string | number;
  status: string;
  has_contract: boolean;
  occupation_aval: string;
  nationality_aval: string;
  aval_rut: string;
  aval_name: string;
  comment: string;
  discount: number;
  corretaje: boolean;
  user: number | UserModel;
  lessor: number | UserModel;
  contract: number;
  plan: number;
  property: number;
  mostrador: string;
}

export interface IApplicant {
  id: string;
  deleted_at: string;
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  name: string;
  last_name: string;
  second_last_name: string;
  declared_income: number;
  rut: string;
  document: string;
  origin: string;
  email: string;
  phone: string;
  description: string;
  occupation: string;
  deal_id: number;
  stage_id: number;
  status: string;
  approved: boolean;
  brokerage: boolean;
  timestamp: string;
  private_comment: string;
  waiting_line: boolean;
  country: CountryUpperCase;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  document_type: DocumentType;
  property: PropertyData;
  user: UserModel;
  quotation: Quotation;
  evaluation: EvaluationModel[];
  aval: any[];
}

export interface PropertyData {
  id: number;
  uid: string;
  address?: string;
  apartment_floor?: number;
  applicants?: IApplicant[];
  association_amenities?: AssociationAmenities;
  can_schedule?: boolean;
  comuna?: string;
  country?: CountryUpperCase;
  created_at?: string;
  deal_id: number | string;
  first_publication_date?: string;
  for_rental?: boolean;
  for_sale?: boolean;
  half_baths?: boolean;
  home_checker_details?: HomeCheckerDetails[];
  homechecker?: HomeChecker;
  houm_owner?: HoumOwner;
  investment_type?: InvesmentType;
  is_new?: boolean;
  last_publication_date?: string;
  neighborhood?: Neighborhood;
  numero_depto?: string;
  photos?: Photos[];
  pipedrive_owner?: any;
  plan?: string | number;
  price?: PropertyPrice[];
  priority?: number;
  property_details?: PropertyDetails[];
  propietary_comment?: string;
  portal_applicants?: IPortalApplicant[];
  published?: boolean;
  quotations: Quotation[];
  region?: string;
  sales_details?: SalesDetails;
  second_line_address?: string;
  rental_details?: { plan: IPlan; readjust: string };
  status?: string;
  stratus?: number;
  street_number?: number;
  schedules: ISchedules[];
  type?: string;
  taxes?: number;
  taxes_currency?: string;
  updated_at?: string;
  unit_types?: any;
  user?: UserModel;
  keys_info?: string;
  video_url?: string;
  video360_url?: string;
  vitruvio_max?: number;
  vitruvio_min?: number;
  vitruvio_suggest?: number;
  antiquity?: string;
  state?: string;
  year_built: number;
  pin?: string;
  lessor_brokerage_commission?: number;
}

export interface Comment {
  id?: number;
  comment?: string;
  commenter?: string;
  prop_id?: number;
  created_at?: string;
}

export type InvesmentType = "regular" | "multifamily" | "real_estate_development";

export enum RentStatus {
  RENEWAL = "renovada",
  CLOSURE = "cierre",
  PUBLISHED = "publicada",
  TO_RENEW = "por renovar",
  EXIT = "salida",
  TO_PUBLISH = "por publicar",
  RENTED = "en arriendo",
  RESERVED = "reservada",
}
