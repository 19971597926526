import { Theme, makeStyles } from "@material-ui/core/styles";

const useCreatePaymentButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
  },
}));

export default useCreatePaymentButtonStyles;
