import { IParsePlan } from "hooks/usePlans/utils/types";

import CardActions from "./CardActions";
import classes from "./PlanCardStyles";

interface Props {
  plan: IParsePlan;
}

export default function PlanCard({ plan }: Props) {
  return (
    <div className={classes.card}>
      <div title={plan.name} className={classes.cardHeader}>
        <span className={classes.cardTitle}>{plan.name}</span>
        <CardActions plan={plan} />
      </div>
      <div className={classes.cardContent}>{plan.description}</div>
      <div className={classes.cardFooter}>
        <span
          className={classes.paymentOption}>{`${plan.paymentOption.name} | ID ${plan.id}`}</span>
        <span className={classes.duration}>{plan.monthsDuration} meses</span>
      </div>
    </div>
  );
}
