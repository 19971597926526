import { styled, Theme, Box, Divider, Typography } from "@material-ui/core";

export const PriceRoot = styled(Box)<Theme>(({ theme }) => ({
  width: "100%",
  border: "1px solid #DFE5E8",
  padding: theme.spacing(3, 4),
  borderRadius: theme.spacing(2),
}));

export const SimilarPropertyContainer = styled(Box)<Theme>(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const DividerStyled = styled(Divider)<Theme>(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(0.5, 0),
}));

export const Subtitle = styled(Typography)<Theme>(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

export const MapContainer = styled(Box)<Theme>(({ theme }) => ({
  flex: 1,
  height: 220,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  margin: theme.spacing(3, 0),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));
