import { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";

import pipedriveService from "services/pipedriveService";
import { useGetToken } from "hooks";

import useSellerStyles from "./SellerStyles";

interface ISeller {
  country: string;
  email: string;
  id: number;
  is_qualifier_manager: false;
  name: string;
  pipedrive_id: string;
  role: string;
  user: number;
  weight_propietary_executive: number;
  weight_qualifier: number;
}

const Seller = ({ sellerId }: { sellerId: number }) => {
  const classes = useSellerStyles();
  const { getToken } = useGetToken();
  const [seller, setSeller] = useState<ISeller | null>(null);
  const [isError, setIsError] = useState(false);

  const fetchSeller = async () => {
    try {
      const authToken = await getToken();
      const res = await pipedriveService.get_seller(sellerId, authToken);
      setSeller(res.data);
    } catch {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (sellerId) fetchSeller();
  }, [sellerId]);

  if (isError) return <>Error cargando ejecutivo</>;

  return (
    <Typography className={classes.seller}>
      Ejecutivo encargado: {seller ? seller?.name : "No tiene"}
    </Typography>
  );
};

export default Seller;
