import { Typography } from "@material-ui/core";

import useStyles from "./PropertyInfoStyles";
import { getPropertyStatusText } from "./utils";
import PropertyInfoItem from "./PropertyInfoItem";
import { useProperty } from "context/propertyContext/propertyContext";

function PropertyInfo() {
  const classes = useStyles();
  const { state } = useProperty();

  const propertyStatusText = getPropertyStatusText(state.property);
  const {
    inspectionReport: { data: inspectionReport },
  } = state;
  const { appraiserInfo } = inspectionReport;

  const isOnMain = state.inspectionReportsCurrentView === "main";

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Propiedad ID {`[${state.property?.id}]`}</Typography>
      <div className={classes.infoBoxRoot}>
        <div>
          <PropertyInfoItem
            label="Ejecutivo Propietario"
            value={`${state.property?.houm_owner?.name} ${state.property?.houm_owner?.last_name}`}
          />
          <PropertyInfoItem label="Estado" value={propertyStatusText} />
          {!isOnMain && inspectionReport?.generalObservation && (
            <PropertyInfoItem
              label="Observación general"
              value={inspectionReport?.generalObservation}
            />
          )}
        </div>
        {!isOnMain && (
          <div>
            <PropertyInfoItem
              label="Fecha de realización"
              value={inspectionReport?.createdAt ?? ""}
            />
            <PropertyInfoItem
              label="Realizado por"
              value={`${appraiserInfo?.name ?? ""} ${
                appraiserInfo?.email ? `(${appraiserInfo?.email})` : ""
              } `}
            />
            <PropertyInfoItem
              label="Limpieza"
              value={inspectionReport?.cleaningObservation ?? "No requiere"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyInfo;
