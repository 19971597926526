import { Grid } from "@material-ui/core";

import { IPromotionDetails } from "../../../utils/types";
import CardDetails from "../../CardDetails";

interface Props {
  promotionDetails?: IPromotionDetails;
  active?: boolean;
}

const DetailsForm = ({ promotionDetails = null, active = true }: Props) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <CardDetails
        title="Comisión Houm"
        type="adminCommission"
        promotionDetail={promotionDetails?.adminCommission}
        enabledEditFlag={active}
      />
    </Grid>
    <Grid item xs={12}>
      <CardDetails
        title="Arriendo propietario"
        type="leaseLandlord"
        promotionDetail={promotionDetails?.leaseLandlord}
        enabledEditFlag={active}
      />
    </Grid>
    <Grid item xs={12}>
      <CardDetails
        title="Arriendo arrendatario"
        type="leaseTenant"
        promotionDetail={promotionDetails?.leaseTenant}
        enabledEditFlag={active}
      />
    </Grid>
    <Grid item xs={12}>
      <CardDetails
        title="Corretaje propietario"
        type="landlordBrokerage"
        promotionDetail={promotionDetails?.landlordBrokerage}
        enabledEditFlag={active}
      />
    </Grid>
    <Grid item xs={12}>
      <CardDetails
        title="Corretaje arrendatario"
        type="tenantBrokerage"
        promotionDetail={promotionDetails?.tenantBrokerage}
        enabledEditFlag={active}
      />
    </Grid>
  </Grid>
);

export default DetailsForm;
