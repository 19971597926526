import { Container, Typography } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { PropertyProvider } from "context/propertyContext/propertyContext";
import PropertyForm from "Components/Admin/UIComponents/PropertyForm/CreatePropertyForm/PropertyForm";
import SuccessDialog from "Components/Admin/UIComponents/PropertyForm/CreatePropertyForm/SuccessDialog";
import LoadingDialog from "Components/Admin/UIComponents/PropertyForm/CreatePropertyForm/LoadingDialog";

export default function PropertyCreatePage() {
  return (
    <Container maxWidth="md">
      <Typography variant="h4">Crear propiedad</Typography>
      <SnackbarProvider>
        <PropertyProvider>
          <PropertyForm />
          <SuccessDialog />
          <LoadingDialog />
        </PropertyProvider>
      </SnackbarProvider>
    </Container>
  );
}
