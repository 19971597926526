import { makeStyles, Theme } from "@material-ui/core";

const useUserItemStyles = makeStyles((theme: Theme) => ({
  horizontal: {
    display: "flex",
    alignItems: "flex-end",
    gap: theme.spacing(1),
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.4),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grey[500],
  },
  content: {
    color: theme.palette.grey[500],
  },
}));

export default useUserItemStyles;
