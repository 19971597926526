import { phoneToString } from "utils/yupValidators/phone";
import * as yup from "yup";

const REQUIRED_TEXT = "Este campo es obligatorio";
const PHONE_ERROR = "Número de celular inválido";
const USER_ERROR = "Por favor, seleccione un usuario de la lista disponible";

export const MultifamilyFormSchema = (isEditing: boolean) =>
  yup.object({
    buildingName: yup.string().required(REQUIRED_TEXT),
    country: yup.string().required(REQUIRED_TEXT).typeError(REQUIRED_TEXT),
    address: isEditing ? yup.mixed() : yup.object().required(REQUIRED_TEXT),
    neighborhood: yup
      .object({
        value: yup.number().required(REQUIRED_TEXT),
      })
      .required(REQUIRED_TEXT)
      .typeError(REQUIRED_TEXT),
    executiveOfBuilding: yup.object().required(REQUIRED_TEXT).typeError(USER_ERROR),
    constructionYear: yup.string().required(REQUIRED_TEXT),
    landlord: yup.object().required(REQUIRED_TEXT).typeError(USER_ERROR),
    emailOfAdministrator: yup.string().required(REQUIRED_TEXT),
    phoneOfAdministrator: yup
      .object({
        phone: yup.string().required(),
        parsedPhone: yup.string().required(),
        country: yup.object({
          countryName: yup.string(),
          countryCode: yup.string().required(),
          callingCode: yup.string().required(),
        }),
      })
      .transform(phoneToString)
      .typeError(PHONE_ERROR)
      .required(REQUIRED_TEXT),
    conciergeName: yup.string().required(REQUIRED_TEXT),
    emailOfConcierge: yup.string().required(REQUIRED_TEXT),
    phoneOfConcierge: yup
      .object({
        phone: yup.string().required(),
        parsedPhone: yup.string().required(),
        country: yup.object({
          countryName: yup.string(),
          countryCode: yup.string().required(),
          callingCode: yup.string().required(),
        }),
      })
      .transform(phoneToString)
      .typeError(PHONE_ERROR)
      .required(REQUIRED_TEXT),
  });

export default MultifamilyFormSchema;
