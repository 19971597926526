import { useQueryClient } from "react-query";
import ModalDialog from "@houm-com/ui/ModalDialog";

import UserForm from "domains/common/components/UserForm";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  memberId: number;
}

const EditUserModal = ({ open, handleOpenModal, memberId }: Props) => {
  const queryClient = useQueryClient();

  const onSuccessEditUser = () => {
    queryClient.invalidateQueries("evaluation", { exact: false });
    handleOpenModal();
  };

  return (
    <ModalDialog title="Editar usuario" isOpen={open} onClose={handleOpenModal}>
      <UserForm userId={memberId} onSuccessEditUser={onSuccessEditUser} />
    </ModalDialog>
  );
};

export default EditUserModal;
