import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useCodebtorDataCardStyles from "./CodebtorDataCardStyles";
import { numberWithCommas } from "utils";
import { UserResponse } from "models/User";

interface Props {
  evaluation: {
    user: UserResponse;
    address: {
      street: string;
      interiorNumber: number;
      exteriorNumber: number;
      colonia: string;
      city: string;
      state: string;
      zip: number;
    };
  };
  country: string;
  liquidSalary: number;
}

function CodebtorDataCard({ evaluation, country, liquidSalary }: Props) {
  const classes = useCodebtorDataCardStyles();

  return (
    <Box p={2} className={classes.dataBox}>
      <Card className={classes.card}>
        <CardContent>
          {country === "cl" ? (
            <Typography>
              <strong>Rut:</strong> {evaluation.user.rut}
            </Typography>
          ) : (
            <Typography>
              <strong>RFC:</strong> {evaluation.user.document}
            </Typography>
          )}
          <Typography>
            <strong>Email:</strong> {evaluation.user.email}
          </Typography>
          <Typography>
            <strong>Teléfono:</strong> {evaluation.user.phone}
          </Typography>
          {country === "mx" && evaluation.address && (
            <>
              <Typography>
                <strong>Calle:</strong>{" "}
                {`${evaluation.address.street} ${evaluation.address.interiorNumber} ${evaluation.address.exteriorNumber}`}
              </Typography>
              <Typography>
                <strong>Colonia:</strong> {evaluation.address.colonia}
              </Typography>
              <Typography>
                <strong>Ciudad:</strong> {evaluation.address.city}
              </Typography>
              <Typography>
                <strong>Estado:</strong> {evaluation.address.state}
              </Typography>
              <Typography>
                <strong>Código Postal:</strong> {evaluation.address.zip}
              </Typography>
            </>
          )}
          {country === "cl" && (
            <Box mt={3}>
              <Loading loading={!liquidSalary && liquidSalary !== 0}>
                <Typography variant="h6">Renta: CLP$ {numberWithCommas(liquidSalary)}</Typography>
              </Loading>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default CodebtorDataCard;
