import { makeStyles, Theme } from "@material-ui/core";

const useCurrentFilesStyles = makeStyles<Theme>((theme) => ({
  textLabelTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  noFiles: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
  },
  rootContainer: {
    display: "flex",
    flexDirection: "column",
  },
  linksContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export default useCurrentFilesStyles;
