import { useMap } from "context/map/mapContext";
import { useMacrozones } from "context/macrozones/macrozonesContext";

import Source from "Components/Admin/UIComponents/atoms/Source";
import Layer from "Components/Admin/UIComponents/atoms/Layer";
import { textLayout } from "./constants";

function MacrozoneMap() {
  const {
    state: { loaded },
  } = useMap();

  const {
    state: { macrozoneList },
  } = useMacrozones();

  if (!loaded) return null;
  return (
    <>
      {macrozoneList.map((source) => (
        <Source key={source.id} id={source.id} sourceData={source.source}>
          <Layer
            id={`${source.id}-fill-layout`}
            layerData={{
              type: "fill",
              paint: {
                "fill-color": source.color,
                "fill-opacity": 0.345,
              },
            }}
          />
          <Layer
            id={`${source.id}-line`}
            layerData={{
              type: "line",
              paint: {
                "line-color": source.color,
                "line-width": 1.5,
              },
            }}
          />
          <Layer
            id={`${source.id}-text`}
            layerData={{
              type: "symbol",
              layout: textLayout,
              paint: { "text-color": "#000000" },
            }}
          />
        </Source>
      ))}
    </>
  );
}

export default MacrozoneMap;
