import {
  number,
  positiveNumber,
  intNumber,
  positiveIntNumber,
} from "./controlledNumberValidations";

export { default as floatWithComma } from "./floatWithComma";
export { default as notNumberToNull } from "./notNumberToNull";
export { number, positiveNumber, intNumber, positiveIntNumber };

export const requiredText = "Obligatorio";
export const invalidFormat = "Formato incorrecto";
