import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import DesignCardContent from "Components/Admin/administrator/subscriptions/components/DesignCardContent";
import ModalLayout from "Components/Admin/administrator/subscriptions/components/ModalLayout";
import { ContractModel } from "models/Contract";
import { useFormNotifications } from "hooks";
import useModal from "hooks/useModal";
import { countryCode } from "utils";

import useEditContractConditions from "../../../../hooks/useEditContractConditions";
import { contractFormSchema, requiredText } from "./utils/validationSchema";
import ContractFormButtons from "./components/ContractFormButtons";
import ContractDetails from "./components/ContractDetails";
import PaymentDetails from "./components/PaymentDetails";
import ModalButtons from "./components/ModalButtons";
import PlanDetails from "./components/PlanDetails";
import { currencyParse } from "../../utils/utils";
import { FormData } from "./utils/types";

interface Props {
  contract: ContractModel;
  handleIsEdit: () => void;
}

const FormCard = ({ contract, handleIsEdit }: Props) => {
  const { mutate, isLoading } = useEditContractConditions(contract.id, handleIsEdit);
  const { handleOpenModal, open } = useModal();
  const methods = useForm<FormData>({
    resolver: yupResolver(contractFormSchema(countryCode(contract?.property?.country))),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<FormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<FormData> = () => {
    handleOpenModal();
  };

  const handleConfirmConditions = () => {
    const repeatedElements: Partial<FormData> = Object.keys(methods.formState.dirtyFields).reduce(
      (result, key) => {
        if (key in methods.getValues()) {
          result[key] = methods.getValues()[key];
        }
        return result;
      },
      {}
    );

    mutate(repeatedElements);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <DesignCardContent title="Detalles generales">
              <ContractDetails contract={contract} country={contract?.property?.country} />
            </DesignCardContent>
          </Grid>
          <Grid item xs={12} md={4}>
            <DesignCardContent title="Detalles de pago">
              <PaymentDetails
                amount={contract.amount}
                typeMoney={currencyParse[contract.property.country][contract.type_money]}
                commonExpenses={contract?.common_expenses}
                country={contract.property.country}
              />
            </DesignCardContent>
          </Grid>
          <Grid item xs={12} md={4}>
            <DesignCardContent title="Detalles de plan">
              <PlanDetails country={contract?.property?.country} planId={contract.plan?.id} />
            </DesignCardContent>
          </Grid>
        </Grid>
        <ContractFormButtons
          disabled={Object.keys(methods.formState.dirtyFields).length === 0}
          handleIsEdit={handleIsEdit}
        />
        <ModalLayout
          handleOnClose={handleOpenModal}
          open={open}
          subtitle="¿Estás seguro que deseas actualizar la información del contrato de los siguientes campos?"
          title="Confirmación de cambios">
          <ModalButtons
            onHandleClick={handleConfirmConditions}
            handleIsEdit={handleIsEdit}
            methods={methods}
            isLoading={isLoading}
          />
        </ModalLayout>
      </form>
    </FormProvider>
  );
};

export default FormCard;
