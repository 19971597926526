import { styled, Theme, Box } from "@material-ui/core";

export const Root = styled(Box)<Theme>(({ theme }) => ({
  height: 40,
  width: "100%",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F7F8FC",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(1),
  justifyContent: "space-between",
}));
