import { ApplicantModel } from "models/Applicant";
import { Locale } from "models/Countries";
import { PhoneInput } from "models/PhoneInput";
import { localeToCountry } from "utils/countryCode";

const { v4: uuidv4 } = require("uuid");
const { format } = require("rut.js");

export interface IFormData {
  email: string;
  phone: string;
  name: string;
  document: string;
  document_type: number;
  last_name: string;
  second_last_name: string;
  rut: string;
}

export interface ILeaseData {
  userId: number;
  propertyId: number;
}

export interface IPersonData {
  document: string;
  documentType: string;
  email: string;
  lastName: string;
  name: string;
  phone: PhoneInput;
  secondLastName: string;
  occupation: string;
}

export const parseFormData = (data: IPersonData, country: Locale) => ({
  email: data.email,
  phone: data.phone.parsedPhone,
  name: data.name,
  last_name: data.lastName,
  second_last_name: data.secondLastName,
  rut: country === "cl" ? format(data.document) : data.document,
  document: country === "cl" ? format(data.document) : data.document,
  document_type: parseInt(data.documentType),
});

export const parseDataApplicant = (data: IFormData, country: Locale, propertyUid: string) => ({
  id: uuidv4(),
  name: data.name,
  last_name: data.last_name,
  second_last_name: data.second_last_name,
  rut: data.document === "" ? "-" : data.document,
  document: data.document === "" ? "-" : data.document,
  document_type: data.document_type,
  email: data.email,
  phone: data.phone,
  role: "applicant",
  country: localeToCountry[country],
  status: "Esperando evaluación",
  description: "placeholder",
  property_id: propertyUid,
  origin: "Subido por Administrador",
});

export const parseApplicantResponse = (applicant: ApplicantModel) => ({
  ...applicant,
  userId: applicant.user_id,
});

export const parseDataLease = (userId: number, propertyId: number) => ({
  application_user: userId,
  property: propertyId,
});
