import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";

import { IUserFilters } from "models/User";
import userService from "services/userService";
import { IUserAutoComplete } from "models";

const useUsers = (query: string, qParams: IUserFilters, options = { enabled: true }) => {
  const { getToken } = useGetToken();
  const {
    data: users,
    isLoading,
    isError,
  }: UseQueryResult<IUserAutoComplete[], Error> = useQuery<IUserAutoComplete[], Error>(
    ["usersAutocomplete", query, qParams],
    async () => {
      const authToken = await getToken();
      const response = await userService.getUsers({ email: query, ...qParams }, authToken);
      return response.data;
    },
    {
      onError: () => [],
      enabled: options.enabled,
    }
  );

  return { users, isLoading, isError };
};

export default useUsers;
