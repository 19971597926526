import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import QuestionsService from "services/questionsService";
import { useAlert } from "context/alertContext/alertContext";
import { setAlertInfo } from "context/alertContext/alertActions";

import { QuestionsData } from "./types";
import { parseQuestions } from "./parser";

interface IUseGetQuestions {
  propertyId: number;
  page: number;
  pageSize: number;
  ordering: string;
}

const useGetQuestions = ({ propertyId, page, pageSize, ordering }: IUseGetQuestions) => {
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();
  const { data, isLoading, isError, refetch }: UseQueryResult<QuestionsData, Error> = useQuery<
    QuestionsData,
    Error
  >(
    ["propertyQuestions", propertyId, page, pageSize, ordering],
    async () => {
      const authToken = await getToken();
      const questionsService = QuestionsService(authToken);
      const response = await questionsService.getPropertyQuestions({
        propertyId,
        page,
        pageSize,
        ordering,
      });
      return { questions: parseQuestions(response.data.results), count: response.data.count };
    },
    {
      enabled: !!propertyId,
      onError: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "No se han podido obtener las preguntas de la propiedad",
            type: "error",
          })
        );
      },
    }
  );

  return {
    data: data?.questions,
    count: data?.count,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetQuestions;
