import { camelCase, mapKeys } from "lodash";
import qs from "query-string";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export interface FiltersProps {
  propertyId?: string;
  subscriptionId?: string;
  tenant?: string;
}

export const initialFilters = {
  propertyId: "",
  subscriptionId: "",
  tenant: "",
};

const useFilters = () => {
  const location = useLocation();
  const parsedSearch = qs.parse(location.search);
  const [filters, setFilters] = useState<FiltersProps>({
    ...initialFilters,
    ...mapKeys(parsedSearch, (v, k) => camelCase(k)),
  });

  return {
    filters,
    setFilters,
  };
};

export default useFilters;
