import { makeStyles } from "@material-ui/core";

const useLeadQualificationStyles = makeStyles((theme) => ({
  rootCard: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  saveButton: {
    borderRadius: "100rem",
  },
  selector: {
    marginTop: theme.spacing(0.3),
  },
  commentsContainer: {
    marginTop: theme.spacing(0),
  },
  selectorLabel: {
    width: "100%",
    fontWeight: 700,
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  formInput: {
    marginTop: theme.spacing(1),
    borderBottom: "1px solid black",
    "&.Mui-disabled": {
      borderBottom: "none",
    },
  },
  formContainer: {
    padding: theme.spacing(1),
  },
  gridItemContainer: {
    padding: theme.spacing(1),
    paddingTop: "0",
  },
  selectionButtonLabel: {
    transform: "none",
    position: "relative",
  },
  selectionButtonContainer: {
    margin: 0,
  },
}));
export default useLeadQualificationStyles;
