import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ArrowBack from "@material-ui/icons/ArrowBack";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  ControlledFileUpload,
  ControlledTextArea,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import { ContractTemplateProps } from "context/contractTemplatesContext/contractTemplatesTypes";
import { useFormNotifications } from "hooks";

import useTemplateFormStyles from "./TemplateFormStyles";
import { templateValidationSchema, requiredText } from "./validationSchema";
import { Box, Link } from "@material-ui/core";

interface Props {
  handleSubmit: (data: Partial<ContractTemplateProps>) => void;
  handleBack: () => void;
}

function TemplateForm({ handleSubmit, handleBack }: Props) {
  const classes = useTemplateFormStyles();
  const { state } = useContractTemplates();
  const { newTemplate, submittingTemplate } = state;

  const methods = useForm<Partial<ContractTemplateProps>>({
    resolver: yupResolver(templateValidationSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<Partial<ContractTemplateProps>>({
    errors,
    requiredText,
  });

  const onSubmit: SubmitHandler<Partial<ContractTemplateProps>> = (data) => handleSubmit(data);

  return (
    <FormProvider {...methods}>
      <form className={classes.templateFormRoot} onSubmit={methods.handleSubmit(onSubmit)}>
        <ControlledTextField
          label="Nombre del template"
          id="name"
          textFieldProps={{ placeholder: "Ingresar" }}
          defaultValue={newTemplate.name}
        />
        <ControlledTextArea
          label="Descripción"
          id="description"
          defaultValue={newTemplate.description}
        />
        <Box className={classes.fileBox}>
          <ControlledFileUpload
            allowedMimeTypes={[
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ]}
            id="contractFile"
            label="Template de contrato"
            text="Subir archivo"
          />
          {newTemplate.contractLink && (
            <Link href={newTemplate.contractLink} className={classes.downloadFile}>
              Descargar archivo actual
            </Link>
          )}
        </Box>
        <Box className={classes.fileBox}>
          <ControlledFileUpload
            allowedMimeTypes={[
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ]}
            id="mandateFile"
            label="Template de mandato"
            text="Subir archivo"
          />
          {newTemplate.mandateLink && (
            <Link href={newTemplate.mandateLink} className={classes.downloadFile}>
              Descargar archivo actual
            </Link>
          )}
        </Box>
        <div className={classes.footer}>
          <div className={classes.backButton} onClick={handleBack} id="backButton">
            <ArrowBack className={classes.arrowBack} />
          </div>
          <DesignSystemButton
            label="Guardar"
            variant="primary"
            size="small"
            type="submit"
            onClick={() => {}}
            isLoading={submittingTemplate}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default TemplateForm;
