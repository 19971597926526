import {
  Snackbar,
  Typography,
  SnackbarProps,
  Theme,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import SnackbarIcon from "./SnackbarIcon";
import useDesignSystemSnackbarStyles from "./DesignSystemSnackbarStyles";
import { getColor, SnackbarType } from "./utils";

interface Props extends SnackbarProps {
  open: boolean;
  onClose: () => void;
  head: string;
  body?: string;
  type: SnackbarType;
}

function DesignSystemSnackbar({ open, onClose, head, body, type, ...props }: Props) {
  const theme: Theme = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("sm"));

  const classes = useDesignSystemSnackbarStyles({ color: getColor(type) });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        horizontal: "center",
        vertical: isDesktop ? "bottom" : "top",
      }}
      onClose={handleClose}
      className={classes.dsSnackbarRoot}
      {...props}>
      <div className={classes.wrapper}>
        <div className={classes.textContainer}>
          <SnackbarIcon className={classes.icon} type={type} />
          <div>
            <Typography className={classes.text}>
              <strong>{head}</strong>
            </Typography>
            {body && <Typography className={classes.text}>{body}</Typography>}
          </div>
        </div>
        {isDesktop && (
          <div
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            className={classes.closeIcon}>
            <CloseIcon fontSize="small" />
          </div>
        )}
      </div>
    </Snackbar>
  );
}

export default DesignSystemSnackbar;
