import { makeStyles, Theme } from "@material-ui/core";

const useInfoCardStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    borderRadius: 10,
  },
  header: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  state: {
    backgroundColor: "#FEE9E8",
    borderRadius: 10,
    border: "1px solid #FF866A",
    color: "#FF866A",
  },
  valueInfo: {
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
  },
}));

export default useInfoCardStyles;
