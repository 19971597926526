import { Box, Container, Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";

import ControlledSwitchSelector from "Components/Admin/UIComponents/ControlledForm/ControlledSwitchSelector";
import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";

import ControlledCustomTextField from "../ControlledCustomTextField";
import useCardDetailsStyles from "./CardDetailsStyles";
import useFormMonth from "../Forms/hook/useFormMonth";
import { OptionType } from "../../utils/types";

interface Props {
  title: string;
  type: OptionType;
  promotionDetail?: {
    [key: `${string}Value`]: number;
    [key: `${string}Flag`]: "yes" | "no";
    [key: `${string}Month`]: {
      [key: `${string}Month${number}`]: number;
    } | null;
    [key: `${string}MonthQuantity`]: number;
    [key: `${string}Closing`]: number;
  };
  enabledEditFlag?: boolean;
}

const CardDetails = ({ title, type, promotionDetail = null, enabledEditFlag = true }: Props) => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const classes = useCardDetailsStyles({ isXs });
  const { quantityMonth, applyFlag, monthOptions } = useFormMonth(
    type,
    promotionDetail ? promotionDetail[`${type}Flag`] : null
  );

  return (
    <>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Container className={classes.detailContainer}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent="flex-start"
          alignItems="flex-start">
          <Grid item>
            <ControlledSwitchSelector
              label="Aplica"
              id={`${type}Flag`}
              options={[
                { label: "Si", key: "yes" },
                { label: "No", key: "no" },
              ]}
              defaultValue={promotionDetail ? promotionDetail[`${type}Flag`] : "no"}
              disabled={!enabledEditFlag}
              labelClassName={classes.textField}
              buttonClassname={classes.switchButton}
              switchLabelClassname={classes.switchLabel}
              groupClassname={classes.groupContainer}
            />
          </Grid>
          {applyFlag === "yes" && (
            <Grid item>
              <ControlledSelection
                label="Meses"
                id={`${type}Month`}
                options={monthOptions}
                disabled={!enabledEditFlag}
                defaultValue={promotionDetail ? promotionDetail[`${type}MonthQuantity`] : ""}
                simple={true}
                classes={{
                  container: classes.selection,
                  label: classes.selectionLabel,
                  input: {
                    root: classes.select,
                    input: classes.inputSelect,
                  },
                }}
                fullWidth
                emptyValue={null}
              />
            </Grid>
          )}
          {applyFlag === "yes" && ["landlordBrokerage", "tenantBrokerage"].includes(type) && (
            <>
              <Grid item className={classes.brokerageFields}>
                <ControlledCustomTextField
                  id={type}
                  label="Corretaje"
                  defaultValue={promotionDetail ? promotionDetail[`${type}Value`] : ""}
                  disabled={!enabledEditFlag}
                />
              </Grid>
              <Grid item className={classes.brokerageFields}>
                <ControlledCustomTextField
                  id={`${type}Closing`}
                  label="Cierre"
                  defaultValue={promotionDetail ? promotionDetail[`${type}Closing`] : ""}
                  disabled={!enabledEditFlag}
                />
              </Grid>
            </>
          )}
        </Grid>
        {quantityMonth && applyFlag === "yes" && Number(quantityMonth) !== 0 ? (
          <>
            <Box className={classes.subtitleContainer}>
              <Typography className={classes.subtitle}>Cuotas que aplican por mes</Typography>
            </Box>
            <Grid container spacing={1} justifyContent="flex-start">
              {[...Array(Number(quantityMonth)).keys()].map((i) => (
                <Grid item key={`${type}-month-${i + 1}`}>
                  <ControlledCustomTextField
                    id={`${type}Month${i + 1}`}
                    label={`Mes ${i + 1}`}
                    disabled={!enabledEditFlag}
                    defaultValue={
                      promotionDetail ? promotionDetail[`${type}Month`][`${type}Month${i + 1}`] : ""
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default CardDetails;
