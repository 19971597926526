import { useState } from "react";

import { Grid } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

import BasicTextSearchBar from "Components/Admin/UIComponents/molecules/BasicTextSearchBar";
import { useTag } from "context/tagContext/tagContext";
import { getTags, setFilters } from "context/tagContext/tagActions";
import { useGetToken } from "hooks";

function SearchBar() {
  const [query, setQuery] = useState<string>("");
  const { state, dispatch } = useTag();
  const { getToken } = useGetToken();

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key == "Enter") {
      setQuery("");
      dispatch(setFilters({ ...state.filters, id: query }));
      const authToken = await getToken();
      await getTags(query, dispatch, authToken);
    }
  };

  return (
    <Grid item xs={4}>
      <BasicTextSearchBar
        type="number"
        value={query}
        onKeyPress={(e) => handleKeyPress(e)}
        onChange={(i) => setQuery(i.target.value)}
        InputProps={{
          startAdornment: <SearchOutlined color="primary" />,
          placeholder: "Buscar por ID",
        }}
      />
    </Grid>
  );
}

export default SearchBar;
