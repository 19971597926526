import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import applicantService from "services/applicantService";
import { useGetToken } from "hooks";
import { ApplicantModel } from "models/Applicant";

const useApplicant = () => {
  const params = useParams<{ id: string }>();

  const { getToken } = useGetToken();

  const {
    isLoading: applicantLoading,
    error: applicantError,
    data: applicantData = null,
  }: UseQueryResult<ApplicantModel, Error> = useQuery(["applicant_by_id", params?.id], async () => {
    const authToken = await getToken();
    const response = await applicantService.getApplicant(params.id, authToken);
    return response.data;
  });

  return { applicantLoading, applicantError, applicantData };
};

export default useApplicant;
