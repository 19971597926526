import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Avatar, Button, Chip, Grid } from "@material-ui/core";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useUserLandlordStyles from "./UserLandlordStyles";
import userService from "services/userService";
import { UserModel } from "models/User";
import { useGetToken } from "hooks";

interface IProps {
  user: UserModel;
}

function UserLandlord({ user }: IProps) {
  const { getToken } = useGetToken();
  const classes = useUserLandlordStyles();
  const [resetPassword, setResetPassword] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const authToken = await getToken();
      const res = await userService.resetLandlordPassword(user.email, authToken);
      if (!res) return;
      setResetPassword(res.data === "true");
    })();
  }, []);

  const onClickSendEmail = () => {
    Swal.fire({
      type: "warning",
      title: "¿Está seguro que desea enviar un mail al propietario para crear una contraseña?",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (!result.dismiss) {
        sendEmail();
      }
    });
  };

  const sendEmail = async () => {
    const authToken = await getToken();
    try {
      const response = await userService.sendResetPasswordMail(user.email, authToken);
      if (response) {
        Swal.fire({
          type: "success",
          title: "Mail enviado",
          text: "El email para crear nueva contraseña se ha enviado correctamente",
        });
      }
    } catch {
      Swal.fire({
        type: "error",
        title: "Mail no enviado",
        text: "El mail no se ha sido enviado, por favor contactar a soporte",
      });
    }
  };

  return (
    <Grid item xs={12}>
      <CollapseCard title="Propietario">
        <Grid container>
          <Grid container item lg={5} md={5} xs={5} justifyContent="center" alignItems="center">
            <Grid item lg={5}>
              <div className={classes.avatar}>
                <Avatar />
              </div>
              {user.role !== "admin" && (
                <div className={classes.avatar}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push(`/admin/users/${user.id}/edit`)}
                    className={classes.button}>
                    Modificar Usuario
                  </Button>
                </div>
              )}
            </Grid>
            <Grid item lg={7}>
              <span>
                {user.name} {user.last_name}
              </span>
              <br />
              <span>{user.email}</span>
              <br />
              <span>{user.phone}</span>
              <br />
              {user.rut ? (
                <span>
                  {`${user.rut} `}
                  <CheckCircleOutlineIcon className={classes.icon} />
                </span>
              ) : (
                <Chip size="small" label="Falta RUT" color="primary" />
              )}
            </Grid>
          </Grid>
          <Grid container item lg={7} md={7} xs={7} justifyContent="flex-end" alignItems="center">
            <Grid item lg={5}>
              {resetPassword && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onClickSendEmail()}
                  className={classes.button}>
                  Enviar mail cambio de contraseña
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CollapseCard>
    </Grid>
  );
}

export default UserLandlord;
