import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme, { toggle: boolean }>((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "1.25rem",
    padding: 0,
    overflow: "hidden",
    marginBottom: theme.spacing(4),
  },
  titleContainer: {
    display: "flex",
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    transition: "0.3s",
    backgroundColor: ({ toggle }) => (toggle ? theme.palette.secondary.main : "#fff"),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  chip: {
    transition: "0.3s",
    backgroundColor: ({ toggle }) => (toggle ? theme.palette.primary.main : "white"),
    color: ({ toggle }) => (toggle ? "white" : theme.palette.primary.main),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginLeft: theme.spacing(2),
    border: "1px solid transparent",
    borderColor: ({ toggle }) => (toggle ? "transparent" : theme.palette.primary.main),
  },
  toggleContainer: {
    paddingTop: ({ toggle }) => (toggle ? theme.spacing(2) : 0),
    paddingBottom: ({ toggle }) => (toggle ? theme.spacing(2) : 0),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    overflow: "hidden",
    transition: "0.3s",
    minHeight: ({ toggle }) => (toggle ? 380 : 0),
    maxHeight: ({ toggle }) => (toggle ? 400 : 0),
    opacity: ({ toggle }) => (toggle ? 1 : 0),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
  },
}));

export default useStyles;
