import { UseQueryResult, useQuery } from "react-query";

import { localeToCountry } from "utils/countryCode";
import useGetToken from "hooks/useGetToken";
import { Locale } from "models/Countries";
import { IPlanCategory } from "models/Plan";
import planService from "services/planService";

import { parsePlanCategories } from "./utils/parsers";

const useGetPlansCategories = (country: Locale) => {
  const { getToken } = useGetToken();

  const {
    data: planCategories,
    isLoading,
    isError,
  }: UseQueryResult<IPlanCategory[], Error> = useQuery<IPlanCategory[], Error>(
    "planCategories",
    async () => {
      const token = await getToken();
      const response = await planService.getPlanCategories(localeToCountry[country], token);
      return parsePlanCategories(response.data.results);
    }
  );

  return {
    planCategories,
    isLoading,
    isError,
  };
};

export default useGetPlansCategories;
