import { Typography, IconButton, TextFieldProps } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

import { Country } from "models/PhoneInput";

import CustomDialog from "./CustomDialogCountry";
import useDialogCountrySelectSyles from "./CountryDialogStyle";
import CountryList from "./CountryList";

interface Props {
  isOpen?: boolean;
  selected?: Country;
  onChange?: (country: Country) => void;
  onClose?: () => void;
  searchInputProps?: TextFieldProps;
}
function DialogCountrySelect({ isOpen, selected, searchInputProps, onChange, onClose }: Props) {
  const classes = useDialogCountrySelectSyles();
  return (
    <CustomDialog isOpen={isOpen} showNavbar={false}>
      <div className={classes.header}>
        <Typography variant="body1">Selecciona el país</Typography>
        <IconButton
          onClick={onClose}
          className={classes.closeButton}
          classes={{ root: classes.closeButton }}>
          <CloseOutlined />
        </IconButton>
      </div>
      <div className={classes.container}>
        <CountryList searchInputProps={searchInputProps} selected={selected} onSelect={onChange} />
      </div>
    </CustomDialog>
  );
}

export default DialogCountrySelect;
