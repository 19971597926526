import React from "react";
import { useParams } from "react-router-dom";
import { useGetCurrentUser } from "hooks";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";
import { cn } from "@houm-com/ui/utils";

import {
  EvaluationStatus,
  SwitchBrokerageLabels,
  SwitchBrokerageTypes,
} from "domains/evaluations/models/Evaluations";
import useUpdateEvaluationBrokerage from "domains/evaluations/hooks/useUpdateEvaluationBrokerage";
import SwitchSelector from "Components/Admin/UIComponents/DesignSystem/SwitchSelector";
import { getBrokerageLabel } from "domains/evaluations/utils/brokerage";
import useGuard from "domains/auth/hooks/useGuard";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

interface Props {
  brokerage: boolean;
  evaluationStatus: EvaluationStatus;
}

const BrokerageSwitchSelector = ({ brokerage, evaluationStatus }: Props) => {
  const { guard } = useGuard();
  const { handleOpenModal, open } = useModal();
  const { evaluationId, country } = useParams<{ evaluationId: string; country: Locale }>();
  const { user } = useGetCurrentUser();

  const { mutate: updateEvaluationBrokerage, isLoading } = useUpdateEvaluationBrokerage({
    evaluationId,
    country,
    onMutateSuccess: handleOpenModal,
  });

  const onModalSubmit = () => {
    if (isLoading) return;
    updateEvaluationBrokerage({
      updated_by: user.id,
      brokerage: !brokerage,
    });
  };

  const switchValue = brokerage
    ? SwitchBrokerageTypes.BROKERAGE
    : SwitchBrokerageTypes.NO_BROKERAGE;

  const isSwitchDisabled =
    !guard.permissions.all(["update:evaluations:all", "update:evaluation_brokerage:all"]) ||
    ["approved", "rejected"].includes(evaluationStatus);

  return (
    <>
      <SwitchSelector
        handleChange={handleOpenModal}
        options={[
          { label: SwitchBrokerageLabels.BROKERAGE, key: SwitchBrokerageTypes.BROKERAGE },
          { label: SwitchBrokerageLabels.NO_BROKERAGE, key: SwitchBrokerageTypes.NO_BROKERAGE },
        ]}
        buttonSelectClassName={cn({
          "!bg-primary-cta !text-white": switchValue === SwitchBrokerageTypes.NO_BROKERAGE,
        })}
        switchContainerClassName="!w-fit"
        defaultValue={switchValue}
        preventChange
        disabled={isSwitchDisabled}
      />
      <ConfirmDialog
        title={`¿Estás seguro que quieres cambiar la evaluación a: '${getBrokerageLabel(
          !brokerage
        ).toLowerCase()}'?`}
        isOpen={open}
        onClose={handleOpenModal}
        onSubmit={onModalSubmit}
        submitButtonProps={{
          isLoading,
        }}
        submitButtonLabel="Sí, cambiar"
      />
    </>
  );
};

export default React.memo(BrokerageSwitchSelector);
