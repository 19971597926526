import { useQuery, UseQueryResult } from "react-query";

import userService from "services/userService";
import { UserModel } from "models/User";

import useGetToken from "./useGetToken";

const useUserById = (userId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading: isLoadingUser,
    isError: isErrorUser,
    data: dataUser,
  }: UseQueryResult<UserModel, Error> = useQuery<UserModel, Error>(
    ["userById", userId],
    async () => {
      const authToken = await getToken();
      const response = await userService.get(userId, authToken);
      return response.data;
    },
    { enabled: !!userId }
  );

  return {
    isLoadingUser,
    isErrorUser,
    dataUser,
  };
};

export default useUserById;
