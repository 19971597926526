import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardContent from "@material-ui/core/CardContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { GridCloseIcon } from "@material-ui/x-grid";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import newEvaluationService from "services/newEvaluationService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import WriteComment from "./WriteComment";
import useStyles from "./CommentsStyles";
import Comment from "./Comment";

interface Props {
  evaluation: {
    id: number;
  };
}

function Comments({ evaluation }: Props) {
  const { getToken } = useGetToken();
  const classes = useStyles();
  const params = useParams<{ country: Locale }>();

  const [comments, setComments] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [writingComment, setWritingComment] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseCommentDialog = () => {
    setWritingComment(false);
  };

  const fetchComments = useCallback(
    async (authToken) => {
      setLoading(true);
      const res = await newEvaluationService.getComments(evaluation.id, authToken, params.country);
      if (res) {
        setComments(res.data.comments);
      }
      setLoading(false);
    },
    [evaluation.id]
  );

  useEffect(() => {
    getToken().then((authToken) => fetchComments(authToken));
  }, [fetchComments]);

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="subtitle1">Comentarios</Typography>
              {comments.length > 1 && (
                <Box display="flex" flexDirection="row" alignItems="center" ml={4}>
                  <Typography>Mostrar todos</Typography>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="mostrar todos">
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
            <CustomLoadingButton
              text="Escribir comentario"
              onClick={() => setWritingComment(true)}
              type="outlined"
            />
          </Box>
          {loading ? (
            <CircularProgress />
          ) : comments.length > 0 ? (
            <>
              <Box px={2}>
                <Comment
                  comment={comments[0].comment}
                  commenter={comments[0].commenter}
                  date={comments[0].created_at}
                  commentType={comments[0].comment_type}
                />
              </Box>
              {comments.length > 1 && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    {comments.slice(1).map((comment) => (
                      <Box my={2} key={comment.comment}>
                        <Comment
                          comment={comment.comment}
                          commenter={comment.commenter}
                          date={comment.created_at}
                          commentType={comment.comment_type}
                        />
                      </Box>
                    ))}
                  </CardContent>
                </Collapse>
              )}
            </>
          ) : (
            <Box>
              <Typography>No hay comentarios.</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={writingComment}
        onClose={handleCloseCommentDialog}
        scroll="paper"
        transitionDuration={500}
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}>
        <Grid container justifyContent="space-between">
          <DialogTitle>Escribir comentario</DialogTitle>
          <DialogActions>
            <IconButton className={classes.closeButton} onClick={handleCloseCommentDialog}>
              <GridCloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
        <DialogContent>
          <WriteComment
            evaluationId={evaluation.id}
            handleCloseCommentDialog={handleCloseCommentDialog}
            fetchComments={fetchComments}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Comments;
