import { ChangePropertyStatusOperationType } from "services/propertyVisibilityService/types";

export const SUCCESS_MESSAGE_BODY_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]: "Hemos despublicado la propiedad de forma exitosa",
  [ChangePropertyStatusOperationType.PUBLISH]: "Hemos publicado la propiedad de forma exitosa",
  [ChangePropertyStatusOperationType.SET_AS_LOST]:
    "Hemos marcado la propiedad como perdida de forma exitosa",
};

export const ERROR_MESSAGE_BODY_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]:
    "No se pudo despublicar la propiedad, por favor vuelve a intentarlo.",
  [ChangePropertyStatusOperationType.PUBLISH]:
    "No se pudo publicar la propiedad, por favor vuelve a intentarlo.",
  [ChangePropertyStatusOperationType.SET_AS_LOST]:
    "No se pudo marcar la propiedad como perdida, por favor vuelve a intentarlo.",
};

export const HANDLED_ERROR_TYPES = {
  PROPERTY_HAS_ACTIVE_SUBSCRIPTION:
    "No es posible dar por perdida una propiedad con una suscripción activa",
  UNHANDLED_ERROR: "No se pudo realizar la operación, por favor vuelve a intentarlo.",
};
