import { useState } from "react";
import moment from "moment";

import {
  Button,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import { pastDayMidday, removeToday, removeHourBeforeToday } from "utils/availabilityTime";

import { useGetToken, useAvailability } from "hooks";

import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import {
  setIsScheduling,
  scheduleVisit,
} from "context/demandLeadContext/schedule/demandScheduleActions";

import CustomDatepicker from "Components/Admin/UIComponents/molecules/DatePicker";
import HourButton from "Components/Admin/UIComponents/atoms/HourButton";

import useSchedulingFormStyles from "./SchedulingFormStyle";

function ShedulingForm() {
  const classes = useSchedulingFormStyles();
  const [typeVisit, setTypeVisit] = useState("presencial");

  const { getToken } = useGetToken();
  const {
    state: { availability, property, appraiserSelectedId },
    dispatch,
  } = useDemandSchedule();
  const {
    state: { lead },
  } = useDemandLead();

  const { allowedDays, allowedHours, currentDay, currentHour, setCurrentDay, setCurrentHour } =
    useAvailability({
      availability,
      firstDayByDefault: true,
      country: property.country,
      commune: property.comuna,
      removeConditions: [pastDayMidday, removeToday, removeHourBeforeToday],
    });

  const handleSchedule = () => {
    getToken().then((authToken) => {
      scheduleVisit({
        lead,
        authToken,
        dispatch,
        timeStamp: currentHour,
        propertyId: property.id as number,
        isStreaming: typeVisit === "streaming",
        appraiser_id: appraiserSelectedId,
      });
    });
  };

  return (
    <>
      <div className={classes.paper}>
        <div className={classes.fisrtColumn}>
          <Typography className={classes.title}>Tipo de agendamiento</Typography>
          <RadioGroup
            aria-label="type_schedule"
            name="type"
            value={typeVisit}
            onChange={(e) => setTypeVisit(e.target.value)}>
            <FormControlLabel
              disabled={allowedDays.length === 0}
              value="presencial"
              labelPlacement="end"
              control={<Radio color="primary" />}
              label="Visita Presencial"
            />
            <FormControlLabel
              disabled={allowedDays.length === 0}
              value="streaming"
              labelPlacement="end"
              control={<Radio color="primary" />}
              label="Visita Virtual"
            />
          </RadioGroup>
        </div>
        <Divider flexItem orientation="vertical" />
        <div className={classes.calendarColumn}>
          <CustomDatepicker
            value={currentDay}
            onChange={(date) => setCurrentDay(moment(date))}
            shouldDisableDate={(date) => !allowedDays.find((day) => day.isSame(date, "date"))}
          />
        </div>
        <Divider flexItem orientation="vertical" />
        <div className={classes.hourColumn}>
          <Typography className={classes.title}>Selecciona el horario</Typography>
          <div className={classes.hours}>
            {(allowedHours || []).map(({ formatted, timestamp }) => (
              <HourButton
                selected={currentHour === timestamp}
                onClick={() => setCurrentHour(timestamp)}>
                {formatted}
              </HourButton>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="text"
          className={classes.cancelButton}
          onClick={() => dispatch(setIsScheduling(false))}>
          Cancelar
        </Button>
        <Button
          className={classes.scheduleButton}
          disableElevation
          disabled={!currentHour || !lead.lead.email}
          variant="contained"
          color="primary"
          onClick={handleSchedule}>
          Continuar
        </Button>
      </div>
    </>
  );
}

export default ShedulingForm;
