import { UseQueryResult, useQuery } from "react-query";

import newPaymentService from "services/newPaymentService/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { IWarrantyReasons } from "./utils/types";

const useDiscountReasons = (country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, isError, data }: UseQueryResult<IWarrantyReasons[], Error> = useQuery<
    IWarrantyReasons[],
    Error
  >(
    ["warranty_discount_reasons", country],
    async () => {
      const authToken = await getToken();
      const response = await newPaymentService.getWarrantyReasons(country, authToken);
      return response.data;
    },
    {
      enabled: !!country,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useDiscountReasons;
