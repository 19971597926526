import { useState, useEffect } from "react";
import moment from "moment";
import { CountryUpperCase } from "models/Countries";
import CountryHeader from "Components/Admin/UIComponents/molecules/CountrySelector";

import useStyles from "./NewHeaderWithCountriesStyles";

interface Props {
  title: string;
  country?: CountryUpperCase | "";
  showCountry?: boolean;
  onCountryChange: (c: CountryUpperCase | "") => void;
}

function Header({ title, country = "Chile", onCountryChange, showCountry = true }: Props) {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState<CountryUpperCase | "">(country);
  useEffect(() => {
    if (country) {
      const countryCaptilized = `${country[0].toUpperCase()}${country.slice(
        1
      )}` as CountryUpperCase;
      setSelectedCountry(countryCaptilized);
    }
  }, [country]);

  const onClickHandle = (c: CountryUpperCase) => {
    const normalizedCountry = c
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") as CountryUpperCase;
    setSelectedCountry(c);
    onCountryChange(normalizedCountry);
  };
  const actualDate = Date.now();

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.date}>{moment(actualDate).format("DD-MM-YYYY")}</p>
      </div>
      {showCountry && <CountryHeader stateCountry={selectedCountry} onChange={onClickHandle} />}
    </div>
  );
}

export default Header;
