import { debounce } from "lodash";
import { useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) =>
  `ID [${suggestion.id}] ${suggestion.address}, ${suggestion.street_number}`;

function SearchBarSelectProperty({ onSelect, viewName = undefined }) {
  const { getToken } = useGetToken();
  // Autosuggest is a controlled component.
  // This means that you need to provide an input value
  // and an onChange handler that updates this value (see below).
  // Suggestions also need to be provided to the Autosuggest,
  // and they are initially empty because the Autosuggest is closed.
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const loadSuggestions = (value) => {
    getToken().then((authToken) => {
      propertyService.search(value, viewName, authToken).then((item) => {
        setSuggestions(item.data.results);
      });
    });
  };

  const debouncedLoadSuggestions = useRef(debounce(loadSuggestions, 1000));

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions.current(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Buscar Propiedad",
    value,
    onChange,
  };

  const renderSuggestion = (suggestion, value) => (
    <div onClick={() => onSelect(suggestion.id, suggestion.uid)}>
      <Highlighter
        searchWords={[value.query]}
        autoEscape
        textToHighlight={`ID ${suggestion.id}: ${suggestion.address} ${suggestion.street_number}`}
      />

      <br />
      <p style={{ fontSize: "11px", fontWeight: "bold", marginBottom: "0px" }}>
        <Highlighter
          searchWords={[value.query]}
          autoEscape
          textToHighlight={`${suggestion.user.name} ${suggestion.user.last_name} ${suggestion.user.email}`}
        />
      </p>
      <p style={{ fontSize: "9px" }}>[{suggestion.status ? suggestion.status.name : ""}]</p>
    </div>
  );

  // Finally, render it!
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
}

SearchBarSelectProperty.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default SearchBarSelectProperty;
