import { ReactNode } from "react";

import { Box, CardContent, CardContentProps, Typography } from "@material-ui/core";

import useDesignCardContentStyles from "./DesignCardContentStyles";

interface Props extends Omit<CardContentProps, "className" | "children"> {
  title: string;
  className?: string;
  children: ReactNode;
}

const DesignCardContent = ({ children, className, title, ...props }: Props) => {
  const classes = useDesignCardContentStyles();
  return (
    <CardContent {...props} className={classes.cardContent}>
      {title && (
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}
      <Box className={classes.boxContainer}>{children}</Box>
    </CardContent>
  );
};

export default DesignCardContent;
