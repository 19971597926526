import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "0.5rem",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(3),
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));
