import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme, { active: boolean }>((theme) => ({
  buttonContainer: {
    backgroundColor: (props) => (props.active ? theme.palette.primary.main : "#ECEFF1"),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 10,
    minWidth: 102,
    width: "fit-content",
    "&:hover": {
      backgroundColor: "#FEE9E8",
      borderColor: "#FEE9E8",
    },
  },
  mainColor: {
    backgroundColor: (props) => (props.active ? theme.palette.primary.main : "#ECEFF1"),
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#FEE9E8",
      borderColor: "#FEE9E8",
    },
  },
  blueColor: {
    backgroundColor: (props) =>
      props.active ? theme.palette.secondary.dark : theme.palette.grey[50],
    borderColor: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      borderColor: theme.palette.grey[200],
    },
  },
  blackColor: {
    backgroundColor: (props) => (props.active ? "#263238" : "#4C6977"),
    "&:hover": {
      backgroundColor: "#4C6977",
      borderColor: "#4C6977",
    },
    borderWidth: 0,
  },
  secondaryColor: {
    backgroundColor: (props) => (props.active ? "#FEE9E8" : "#F7F8FC"),
    borderWidth: 0,
  },
  buttonLabel: {
    textTransform: "none",
    fontWeight: 700,
    fontSize: "1.125rem",
  },
  mainLabel: {
    color: (props) => (props.active ? "#FFF" : theme.palette.primary.main),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  blueLabel: {
    color: (props) => (props.active ? "#FFF" : theme.palette.secondary.dark),
    "&:hover": {
      color: "#FFF",
    },
  },
  blackLabel: {
    color: "#FFF",
  },
  secondaryLabel: {
    color: (props) => (props.active ? theme.palette.primary.main : theme.palette.grey[200]),
  },
}));
