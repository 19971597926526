import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useFormNotifications, useGetCurrentUser } from "hooks";
import { CountryUpperCase } from "models/Countries";

import { promotionValidationSchema, requiredText } from "./utils/validationSchema";
import { FormData, IGeneralInfo, IPromotionDetails } from "./utils/types";
import useCreatePromotion from "../../hooks/useCreatePromotion";
import useDeletePromotion from "../../hooks/useDeletePromotion";
import useEditPromotion from "../../hooks/useEditPromotion";
import usePromotionFormStyles from "./PromotionFormStyles";
import DetailsForm from "./components/Forms/DetailsForm";
import GeneralForm from "./components/Forms/GeneralForm";
import { parsePromotionData } from "./utils/parsers";
import { ICity } from "../../utils/types";

interface Props {
  country: CountryUpperCase;
  cities: ICity[];
  generalInfo?: IGeneralInfo;
  promotionDetails?: IPromotionDetails;
  isEdit?: boolean;
}

const PromotionForm = ({
  cities,
  country,
  generalInfo = null,
  promotionDetails = null,
  isEdit = false,
}: Props) => {
  const classes = usePromotionFormStyles();
  const { user, loading } = useGetCurrentUser();
  const { mutate, isLoading } = useCreatePromotion();
  const { editPromotion, isEditLoading } = useEditPromotion();
  const { deletePromotion, isLoadingDelete } = useDeletePromotion(generalInfo?.id);

  const methods = useForm<FormData>({
    resolver: yupResolver(promotionValidationSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<FormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const parsedData = parsePromotionData(data, cities, country, user.id);
    if (isEdit) editPromotion(parsedData);
    else mutate(parsedData);
  };

  return (
    <Loading loading={loading}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContainer}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <GeneralForm generalInfo={generalInfo} enabledEditFlag={!isEdit} />
            </Grid>
            <Grid item xs={12} md={8}>
              <AdminCard withSpace={false}>
                <DetailsForm promotionDetails={promotionDetails} active={!isEdit} />
                <Grid container item xs={12} justifyContent="flex-end" spacing={2}>
                  {!isEdit && (
                    <Grid item>
                      <DesignSystemButton
                        label="Guardar promoción"
                        variant="primary"
                        size="small"
                        type="submit"
                        isLoading={isLoading || isEditLoading}
                      />
                    </Grid>
                  )}
                  {isEdit && (
                    <Grid item>
                      <DesignSystemButton
                        label="Eliminar promoción"
                        variant="tertiaryB"
                        size="small"
                        isLoading={isLoadingDelete}
                        onClick={() => deletePromotion()}
                      />
                    </Grid>
                  )}
                </Grid>
              </AdminCard>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Loading>
  );
};

export default PromotionForm;
