/* eslint-disable react/require-default-props */
import classnames from "classnames";

import {
  TextField,
  FormControl,
  FormControlLabel,
  OutlinedTextFieldProps,
} from "@material-ui/core";
import useStyles from "./CustomTextFieldOutlineStyles";
import { ReactNode } from "react";

interface Props extends Partial<OutlinedTextFieldProps> {
  error: boolean;
  errorMessage?: string;
  labelClassName?: string;
  bordered?: boolean;
  warning?: boolean;
  warningMessage?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
  label: ReactNode;
}

function CustomTextField({
  error,
  label,
  errorMessage = "Error",
  InputProps,
  disabled = false,
  labelClassName = "",
  bordered = false,
  warning,
  warningMessage,
  labelPlacement = "top",
  ...props
}: Props) {
  const classes = useStyles({ warning });

  return (
    <FormControl className={classes.wrapper} error={error}>
      <FormControlLabel
        classes={{
          label: classnames(classes.label, labelClassName),
          root: classes.wrapper,
        }}
        control={
          <TextField
            variant="outlined"
            disabled={disabled}
            error={error}
            helperText={(error && errorMessage) || (warning && warningMessage)}
            InputProps={{
              ...InputProps,
              classes: {
                notchedOutline: classes.outline,
                ...InputProps?.classes,
              },
            }}
            {...props}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormControl>
  );
}

export default CustomTextField;
