import { makeStyles, Theme } from "@material-ui/core";

const useReservationStatusStyles = makeStyles((theme: Theme) => ({
  chip: {
    color: "#00982D",
    background: "#E9FFDA",
    border: "1px solid #00982D",
  },
}));

export default useReservationStatusStyles;
