import { useState } from "react";

import { DialogActions, FormControlLabel, RadioGroup, Typography } from "@material-ui/core";

import { countryCode, numberWithCommas } from "utils";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import StyledRadio from "Components/Admin/UIComponents/atoms/CustomStyleRadio";
import { CURRENCIES, priceReason, rejectedReasons } from "context/negotiationsContext/constants";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import useStatusOfferStyles from "./StatusOfferStyles";
import { useGetToken } from "hooks";
import { rejectOfferAdmin } from "context/negotiationsContext/negotiationsActions";
import { ResponseAnswer, OfferStatus } from "context/negotiationsContext/negotiationsTypes";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";

interface RejectOfferProps {
  offerStatus: string;
  offerId: string;
  country: string;
  handleRefresh: () => void;
}

export function RejectOfferOffer({
  offerStatus,
  offerId,
  country,
  handleRefresh,
}: RejectOfferProps) {
  const { getToken } = useGetToken();
  const code = countryCode(country);
  const classes = useStatusOfferStyles();

  const [showModal, setShowModal] = useState(null);
  const [step, setStep] = useState(0);

  const [selectedReason, setSelectedReason] = useState(null);
  const [lastAmount, setLastAmount] = useState(null);
  const [currency, setCurrency] = useState(null);

  const needCounteroffer = step === 0 && selectedReason === priceReason;

  const rejectOffer = async () => {
    const response = {
      reason: selectedReason,
      lastAmount,
      currency,
      response: ResponseAnswer.REJECTED,
    };
    getToken()
      .then((authToken) => rejectOfferAdmin({ country: code, offerId, response, authToken }))
      .then(() => handleRefresh());
  };

  const handleCloseModal = () => {
    setStep(0);
    setSelectedReason(null);
    setLastAmount(null);
    setCurrency(null);
    setShowModal(false);
  };
  const handleBackClick = () => {
    if (step === 1) {
      setStep(0);
    } else {
      handleCloseModal();
    }
  };
  const handleClick = () => {
    if (needCounteroffer) {
      setStep(1);
    } else {
      rejectOffer();
      handleCloseModal();
    }
  };

  const format = (value) => numberWithCommas(value?.toString().replaceAll(".", "")) || "";

  const unformat = (value) => value?.toString().replaceAll(".", "");

  return (
    <>
      {offerStatus === OfferStatus.OPEN && (
        <LinkButton onClick={() => setShowModal(true)}>Rechazar</LinkButton>
      )}
      <CustomDialog
        open={showModal}
        onClose={handleCloseModal}
        title="¿Por qué se está rechazando la oferta?"
        subtitle="Selecciona un motivo por el cuál se está cancelando esta negociación. En caso de que no aparezca, por favor especifica."
        classes={{
          titleContainer: classes.titleContainer,
          title: classes.title,
          subtitle: classes.subtitle,
          content: classes.contentDialog,
        }}>
        {step === 0 && (
          <RadioGroup
            value={selectedReason}
            onChange={(e) => {
              setSelectedReason(e.target.value);
            }}>
            {rejectedReasons.map((option) => (
              <FormControlLabel
                key={`${option.value}-${option.label}`}
                value={option.value}
                control={<StyledRadio />}
                label={option.label}
                classes={{
                  root: classes.optionRoot,
                  label: classes.optionLabel,
                }}
              />
            ))}
          </RadioGroup>
        )}
        {step === 1 && (
          <>
            <CustomTextField
              label="Último precio por la propiedad"
              placeholder="Ingresar"
              wrapperClassName={classes.wrapperField}
              textFieldClassName={classes.textField}
              value={format(lastAmount)}
              onChange={(e) => setLastAmount(unformat(e.target.value))}
            />
            <Typography className={classes.label}>Tipo de moneda</Typography>
            <SelectionOptions
              id="currency"
              value={currency}
              onChange={(val) => setCurrency(val)}
              options={CURRENCIES[country]}
              compressed={true}
              fullWidth={true}
              simple={false}
            />
          </>
        )}
        <Typography className={classes.text}>
          <Typography>
            Esta acción no puede deshacerse. Por favor verifica que es correcto que se quiere
            rechazar la oferta.
          </Typography>
          {` Una vez rechazada le enviaremos un correo al comprador para
          notificarle que su oferta fue rechazada.`}
        </Typography>
        <DialogActions>
          <DesignSystemButton
            label={step === 0 ? "Cancelar" : "Atrás"}
            size="medium"
            variant="tertiaryB"
            onClick={handleBackClick}
          />
          <DesignSystemButton
            label={needCounteroffer ? "Continuar" : "Rechazar"}
            size="medium"
            variant="primary"
            disabled={!selectedReason}
            onClick={handleClick}
          />
        </DialogActions>
      </CustomDialog>
    </>
  );
}
