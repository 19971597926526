import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { CreatePropertyForm as CreatePropertyFormModel } from "models/createProperty";
import { useProperty } from "context/propertyContext/propertyContext";
import { createProperty } from "context/propertyContext/propertyActions";
import { useFormNotifications, useGetToken } from "hooks";

import { requiredText } from "utils/yupValidators/property";

import validationSchema, { shortValidation } from "./validationSchema";

import useYupValidationResolver from "./useSchemaResolver";
import {
  Address,
  Amenities,
  AmenitiesDetails,
  Brokerage,
  DealType,
  ExpandedButton,
  InvesmentType,
  Price,
  PropertyDetails,
  Pipedrive,
  ShorterDetails,
  User,
} from "../components";

export default function CreatePropertyForm() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProperty();

  const resolver = useYupValidationResolver(validationSchema, shortValidation, state.isShorterForm);
  const methods = useForm<CreatePropertyFormModel>({
    resolver,
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<CreatePropertyFormModel> = (data, e) => {
    const sendData: CreatePropertyFormModel = {
      ...data,
      code: "admin_flow_2.0",
    };
    getToken().then((authToken) => createProperty(sendData, dispatch, authToken));
  };
  const { errors } = methods.formState;

  useFormNotifications<CreatePropertyFormModel>({ errors, requiredText });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <User />
        <DealType />
        <InvesmentType />
        <Pipedrive />
        <Address />
        {state.isShorterForm ? (
          <ShorterDetails />
        ) : (
          <>
            <Price />
            <Brokerage />
            <PropertyDetails />
            <AmenitiesDetails />
            <Amenities />
          </>
        )}
        <ExpandedButton />
      </form>
    </FormProvider>
  );
}
