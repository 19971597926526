import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  dialogTitleContainer: {
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  rootGridForm: {
    "& .MuiGrid-item": {
      marginLeft: theme.spacing(4),
    },
  },
}));
