import { makeStyles, Theme } from "@material-ui/core";

const useSchedulerMapStyles = makeStyles((theme: Theme) => ({
  bottomArrow: {
    color: theme.palette.primary.main,
    width: 30,
    height: 10,
    top: 120,
  },
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 360,
    width: "100%",
  },

  mapRoot: {
    width: "100%",
    height: "100%",
    borderRadius: "0",
  },

  "mapbox-map-wrapper": {
    width: "100%",
    height: "100%",
    "& .mapboxgl-canvas-container": {
      overflow: "hidden",
    },
    borderRadius: 10,
  },

  mainMarker: {
    zIndex: 10,
  },

  popup: {
    top: -40,
    "& .mapboxgl-popup-content": {
      width: "fit-content",
      height: "fit-content",
      padding: "0 !important",
    },
    "& .mapboxgl-popup-tip": {
      display: "none",
    },
  },
  popupItem: {
    color: "#6D6D6D",
    backgroundColor: "white",
    zIndex: 11,
    margin: "-10px -10px -15px", //Remove parent padding
  },
  popupImage: {
    width: 200,
    height: 130,
    objectFit: "cover",
    marginBottom: theme.spacing(1),
  },
  popupdescriptionContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: theme.spacing(1),
  },
}));

export default useSchedulerMapStyles;
