import { Box, Typography } from "@material-ui/core";
import { ErrorProps } from "Components/Admin/administrator/evaluations/hooks/useInitContract";

import useErrorsListStyles from "./ErrorsListStyles";

interface Props {
  errors: ErrorProps[];
}

function ErrorsList({ errors }: Props) {
  const classes = useErrorsListStyles();

  return (
    <Box className={classes.errorsWrapper}>
      <Typography variant="h6">Contrato con errores:</Typography>
      {errors.map((error) => (
        <Typography key={`error-${error.id}`} className={classes.errorItem}>
          {error.text}
        </Typography>
      ))}
    </Box>
  );
}

export default ErrorsList;
