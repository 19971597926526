import { makeStyles, Theme } from "@material-ui/core";

const useExchangeReservationModalStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
    marginBottom: theme.spacing(2),
  },
  textInput: {
    border: `1px solid ${theme.palette.grey[100]}`,
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
  },
}));

export default useExchangeReservationModalStyles;
