import { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";

import schedulerService from "services/schedulerService";
import { useGetToken } from "hooks";

interface Props {
  scheduleId: number;
}

function ScheduleComment({ scheduleId }: Props) {
  const { getToken } = useGetToken();
  const [feedback, setFeedback] = useState<string | null>(null);

  const fetchFeedback = async () => {
    const authToken = await getToken();
    const fetchedFeedback = await schedulerService.getFeedback(scheduleId, authToken);
    if (fetchedFeedback && fetchedFeedback.data.results[0]) {
      setFeedback(fetchedFeedback.data.results[0].feedback);
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, [scheduleId]);

  if (feedback) {
    return <Typography>{feedback}</Typography>;
  }
  return null;
}

export default ScheduleComment;
