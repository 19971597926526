import { makeStyles, Theme } from "@material-ui/core/styles";

const usePropertyInformationStyles = makeStyles((theme: Theme) => ({
  logoImage: {
    height: 90,
    width: 90,
    backgroundColor: "white",
    borderRadius: theme.spacing(6),
  },
  propertyInformationCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    minHeight: 100,
    overflow: "auto",
  },
  id: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 800,
  },
  content: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    fontSize: 14,
    fontWeight: 400,
  },
  daily: {
    width: "auto",
  },
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginLeft: 12,
  },
  icon: {
    fontSize: "2rem",
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  image: {
    width: theme.spacing(16),
  },
  searchButton: {
    width: "100%",
    minHeight: theme.spacing(4),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  divider: {
    color: "black",
  },
  cancelButton: {
    width: "100%",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
}));

export const buttonContainerStyles = "flex justify-center mt-2";

export default usePropertyInformationStyles;
