import { styled, Theme, Box, Typography } from "@material-ui/core";

export const Root = styled(Box)<Theme>(({ theme }) => ({
  height: 40,
  width: "100%",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#F7F8FC",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(2),
  justifyContent: "space-around",
  marginBottom: theme.spacing(2),
}));

export const PriceLabel = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "0.875rem",
}));

export const PriceValue = styled(Typography)(() => ({
  fontWeight: 700,
  color: "#26810A",
  textAlign: "center",
  fontSize: "0.875rem",
}));
