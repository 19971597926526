import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@houm-com/ui/Button";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { setPlanData, setSteps } from "context/plansContext/plansActions";
import { usePlans } from "context/plansContext/plansContext";
import { useFormNotifications, useQueryParams } from "hooks";
import useGetPlansCategories from "hooks/usePlans/useGetPlansCategories";
import { CountryUpperCase } from "models/Countries";
import { countryCode } from "utils";
import useGetMandateTypes from "hooks/usePlans/useGetMandateTypes";
import useGetTemplates from "hooks/usePlans/useGetTemplates";
import useGetCities from "hooks/usePlans/useGetCities";
import useCreatePlan from "hooks/usePlans/useCreatePlan";
import { IPlanCategory } from "models/Plan";

import useStyles from "./createPlanStyles";
import PlansHeader from "../../layouts/plansHeader";
import PlanData from "../../layouts/PlanData/PlanData";
import { planValidationSchema, requiredText } from "./validationSchema";
import FormContainer from "../../layouts/formContainer";
import MonthlyData from "./monthlyData";
import PlanFormProps from "./types";

export default function PlansForm() {
  const classes = useStyles();
  const [selectedPlanCategory, setSelectedPlanCategory] = useState<IPlanCategory | null>(null);
  const { state, dispatch } = usePlans();
  const query = useQueryParams();
  const cities = query.get("cities").split(",");
  const country = query.get("country") as CountryUpperCase;
  const { planCategories, isLoading: categoriesLoading } = useGetPlansCategories(
    countryCode(country)
  );
  const { isLoading: isMandateTypesLoading } = useGetMandateTypes(country);
  const { isLoading: isTemplatesLoading } = useGetTemplates();
  const { availableCities, isLoading: isCitiesLoading } = useGetCities(country);
  const { isLoading: isSubmitting, createPlan } = useCreatePlan();
  const isLoading =
    categoriesLoading || isMandateTypesLoading || isTemplatesLoading || isCitiesLoading;
  const hasManagement = selectedPlanCategory?.hasManagement;

  useEffect(() => {
    dispatch(setPlanData({ country }));

    if (!state.steps.find((step) => step.label === "Crear Plan")) {
      dispatch(
        setSteps([
          {
            link: `/admin/plans/create?country=${country}&cities=${cities.join(",")}`,
            label: "Crear Plan",
          },
        ])
      );
    }
  }, []);

  useEffect(() => {
    if (availableCities) {
      const idCities = cities.map(
        (city) => availableCities.find((cityName) => city === cityName.name)?.id
      );
      dispatch(
        setPlanData({
          cities: idCities,
        })
      );
    }
  }, [availableCities]);

  const methods = useForm<PlanFormProps>({
    resolver: yupResolver(planValidationSchema(countryCode(country), hasManagement)),
    reValidateMode: "onBlur",
    defaultValues: {
      isActive: "yes",
    },
  });
  const [adminCommission, planCategory] = methods.watch(["adminCommission", "category"]);
  const { errors } = methods.formState;
  useFormNotifications<PlanFormProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<PlanFormProps> = (data) => {
    createPlan({
      data,
      country,
      cities: state.planData.cities,
    });
  };

  useEffect(() => {
    if (planCategories?.length && planCategory) {
      setSelectedPlanCategory(
        planCategories.find((category: IPlanCategory) => category.id === Number(planCategory))
      );
    }
  }, [planCategory, planCategories]);

  return (
    <FormProvider {...methods}>
      <div className={classes.root}>
        <Loading loading={isLoading}>
          <>
            <PlansHeader steps={state.steps} />
            <FormContainer>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="p-4">
                  <PlanData
                    hasManagement={hasManagement}
                    country={countryCode(country)}
                    planCategories={planCategories}
                  />
                  {hasManagement && <MonthlyData adminCommission={adminCommission} />}
                </div>
                <div className="flex justify-center mb-4">
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}>
                    Guardar plan
                  </Button>
                </div>
              </form>
            </FormContainer>
          </>
        </Loading>
      </div>
    </FormProvider>
  );
}
