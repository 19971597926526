import useScheduleCancel from "./ScheduleCancelStyles";
import { CancelReasons, ISchedule } from "hooks/useGetSchedules/utils/types";

interface Props {
  reasons: CancelReasons;
  schedule: ISchedule;
}

function ScheduleCancel({ reasons, schedule }: Props) {
  const classes = useScheduleCancel();
  return (
    <div className={classes.container}>
      <div className={classes.reason}>{reasons[schedule.selectedCancelReason?.type]}</div>
      <div className={classes.comment}>
        {[schedule.selectedCancelReason?.comment, schedule.selectedCancelReason?.typeText]
          .filter((val) => !!val)
          .join(", ")}
      </div>
    </div>
  );
}

export default ScheduleCancel;
