import { Box } from "@material-ui/core";

import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Spinner from "Components/Shared/partials/Spinner";
import DeleteServiceModal from "./components/DeleteServiceModal";

import useDeleteServiceStyles from "./DeleteServiceStyles";
import useDeleteService from "./hooks/useDeleteService";
import { ServiceType } from "./utils/types";

interface Props {
  propertyId: number;
  company: string;
  type?: ServiceType;
}

function DeleteService({ propertyId, company, type }: Props) {
  const classes = useDeleteServiceStyles();

  const { deleteService, isLoading, openModal, handleModal } = useDeleteService();

  return (
    <>
      <DeleteServiceModal
        open={openModal}
        handleClose={handleModal}
        onDeleteService={() =>
          deleteService({
            propertyId,
            company,
          })
        }
        loading={isLoading}
        type={type}
      />
      <Box className={classes.deleteServiceRoot}>
        {isLoading ? (
          <Spinner size={16} />
        ) : (
          <TrashIcon
            onClick={() => handleModal()}
            aria-label="Eliminar"
            className={classes.trashIcon}
          />
        )}
      </Box>
    </>
  );
}

export default DeleteService;
