import { makeStyles, Theme } from "@material-ui/core";

const useSubscriptionInfoStyles = makeStyles<Theme>((theme) => ({
  infoText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.33,
  },
}));

export default useSubscriptionInfoStyles;
