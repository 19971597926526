import { useEffect, useMemo, useState } from "react";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useGetDebtCollectionsInsurances from "hooks/useGetDebtCollectionInsurances/useGetDebtCollectionsInsurances";
import { IDebtCollectionFilters } from "hooks/useGetDebtCollections/utils/types";
import useGetDebtCollections from "hooks/useGetDebtCollections/useGetDebtCollections";
import useGetPlansCategories from "hooks/usePlans/useGetPlansCategories";
import { CountryUpperCase, Locale } from "models/Countries";
import countryCode, { localeIntoCountryUpperCase } from "utils/countryCode";

import InsuranceStatusModal, {
  StatusModalData,
} from "./components/InsuranceStatusModal/InsuranceStatusModal";
import MoreInfoModal from "./components/MoreInfoModal/MoreInfoModal";
import TableHead from "./components/Tablehead/TableHead";
import TableRow from "./components/TableRow/TableRow";
import TablePagination from "./components/TablePagination/TablePagination";
import Filters from "./components/Filters";
import classes from "./DebtCollectionsTableStyles";
import Search from "./components/Search";

const pageLimit = 5;

export default function DebtCollectionsTable() {
  const [country, setCountry] = useState<Locale>("cl");
  const defaultFilters = {
    country,
    skip: 0,
    limit: pageLimit,
    include_total: true,
    status: "ONGOING",
  };
  const [filters, setFilters] = useState<IDebtCollectionFilters>(defaultFilters);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false);
  const [isInsuranceStatusModalOpen, setIsInsuranceStatusModalOpen] = useState(false);
  const [selectedStatusData, setSelectedStatusData] = useState<StatusModalData | null>(null);
  const [selectedCollectionProcessId, setSelectedCollectionProcessId] = useState<number | null>(
    null
  );
  const { debtCollections, totalDebtCollections, isDebtCollectionsLoading } =
    useGetDebtCollections(filters);
  const { insurances: allInsurances, isInsurancesLoading } = useGetDebtCollectionsInsurances();
  const { planCategories, isLoading: planCategoriesLoading } = useGetPlansCategories(country);
  const isLoading = isDebtCollectionsLoading || isInsurancesLoading || planCategoriesLoading;

  const insurances = useMemo(
    () => allInsurances && allInsurances[country],
    [allInsurances, country]
  );
  useEffect(() => {
    setFilters({ ...defaultFilters });
    setCurrentPage(0);
  }, [country]);
  useEffect(() => {
    setFilters((prevFilters) => ({ ...prevFilters, skip: currentPage * pageLimit }));
  }, [currentPage]);

  const handleOpenMoreInfoModal = (id: number) => {
    setIsMoreInfoModalOpen(true);
    setSelectedCollectionProcessId(id);
  };
  const handleCloseMoreInfoModal = () => {
    setIsMoreInfoModalOpen(false);
    setSelectedCollectionProcessId(null);
  };
  const handleOnStatusClick = (data: StatusModalData) => {
    setIsInsuranceStatusModalOpen(true);
    setSelectedStatusData(data);
  };
  const handleCloseInsuranceStatusModal = () => {
    setIsInsuranceStatusModalOpen(false);
    setSelectedStatusData(null);
  };

  const handleChangeCountry = (country: CountryUpperCase) => {
    setCountry(countryCode(country));
  };

  const handleChangeSearch = (value: Partial<IDebtCollectionFilters>) => {
    setFilters({ ...filters, ...value });
  };

  return (
    <>
      <h1 className={classes.title}>Cobranzas</h1>
      <CountrySelector
        stateCountry={localeIntoCountryUpperCase[country]}
        onChange={handleChangeCountry}
      />
      <div className={classes.head}>
        <Search country={country} handleChangeFilters={handleChangeSearch} />
        <Filters
          setFilters={setFilters}
          filters={filters}
          insurances={insurances}
          planCategories={planCategories}
          country={country}
          isLoading={isLoading}
        />
      </div>
      <Loading loading={isLoading}>
        {debtCollections?.length ? (
          <>
            <table className="table-fixed">
              <TableHead country={country} />
              <tbody>
                {debtCollections.map((item) => (
                  <TableRow
                    key={item.collectionProcessId}
                    item={item}
                    openMoreInfoModal={handleOpenMoreInfoModal}
                    country={country}
                    onStatusClick={handleOnStatusClick}
                  />
                ))}
              </tbody>
            </table>
            <TablePagination
              limit={pageLimit}
              total={totalDebtCollections}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <h2 className={classes.noItem}>No hay items para mostrar</h2>
        )}
      </Loading>
      {country !== "mx" && (
        <>
          <MoreInfoModal
            isOpen={isMoreInfoModalOpen}
            handleClose={handleCloseMoreInfoModal}
            id={selectedCollectionProcessId}
            country={country}
          />
          <InsuranceStatusModal
            isOpen={isInsuranceStatusModalOpen}
            handleClose={handleCloseInsuranceStatusModal}
            country={country}
            statusData={selectedStatusData}
          />
        </>
      )}
    </>
  );
}
