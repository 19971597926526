// eslint-disable-next-line no-unused-vars
import { PropertyTableFilters } from "context/tableContext/tableTypes";
import { TokyCallInfo } from "context/tokyContext/tokyTypes";

export type Dispatch = (action: Action) => void;

export const SET_LEAD = "SET_LEAD";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_ACTIVITIES = "SET_LOADING_ACTIVITIES";
export const SHOW_ACTIVITY_MODAL_FORM = "SHOW_ACTIVITY_MODAL_FORM";
export const HIDE_ACTIVITY_MODAL_FORM = "HIDE_ACTIVITY_MODAL_FORM";
export const SET_SELECTED_ACTIVITY = "SET_SELECTED_ACTIVITY";
export const SET_LEAD_ACTIVITIES = "SET_LEAD_ACTIVITIES";
export const SET_SELECTED_LEAD = "SET_SELECTED_LEAD";
export const SET_LEAD_PROPERTIES = "SET_LEAD_PROPERTIES";
export const SET_LEAD_PROPERTIES_COUNT = "SET_LEAD_PROPERTIES_COUNT";
export const SET_LEAD_AVAILABILITY = "SET_LEAD_AVAILABILITY";

export interface State {
  loading: boolean;
  lead?: any;
  showActivityFormModal: boolean;
  activityFormAction?: ActivityModalActionType;
  selectedActivity?: IActivity;
  leadActivitiesInfo?: ILeadActivitiesInfo;
  selectedLeadId: number;
  leadProperties: LeadProperty[];
  leadPropertiesCount: number | null;
  selectedAvailability?: number;
}

export type ActivityModalActionType = "new" | "edit" | "new_from_activity_index";

export interface ILead {
  id: string;
  country?: string;
  name: string;
  email?: string;
  qualification?: number;
  leadQualifier: string;
  phone: string;
  status: string;
  lostReason: string;
  createdAt: string;
  followUpDate: string;
  properties: LeadPropertyInfo[];
  campaign: string;
  category: string;
}

export interface IActivity {
  id?: string;
  lead?: number;
  createdAt?: string;
  endDate?: string;
  property?: number;
  propId?: number;
  contactMedium?: string;
  subject?: string;
  otherSubject?: string;
  status?: string;
  detail?: string;
  isCompleted?: boolean;
  leadName?: string;
  leadQualification?: number | string;
  leadPhone?: string;
  tokyCall?: TokyCallInfo;
  tokyCallId?: string;
}

export interface LeadPropertyInfo {
  id: string;
  address: string;
  status: string;
}

export interface LeadProperty {
  id: string;
  forSale: boolean;
  forRental: boolean;
  photoUrl: string;
  adress: string;
  formatedPrice: string;
  status: string;
  showUrl: string;
  country?: string;
  location?: string;
}
export type WeekOptions = Record<string, boolean>;

export type HourOption = Record<number, boolean>;

export interface ILeadActivitiesInfo {
  count: number;
  results: IActivity[];
  isLoadingActivities: boolean;
}

interface setLead {
  type: typeof SET_LEAD;
  lead: ILead;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setLoadingActivities {
  type: typeof SET_LOADING_ACTIVITIES;
  loadingActivities: boolean;
}

interface showActivityModalForm {
  type: typeof SHOW_ACTIVITY_MODAL_FORM;
  modalAction: ActivityModalActionType;
}

interface hideActivityModalForm {
  type: typeof HIDE_ACTIVITY_MODAL_FORM;
}

interface setSelectedActivity {
  type: typeof SET_SELECTED_ACTIVITY;
  activity: IActivity;
}

interface setLeadActivities {
  type: typeof SET_LEAD_ACTIVITIES;
  leadActivitiesInfo: ILeadActivitiesInfo;
}

interface setSelectedLeadId {
  type: typeof SET_SELECTED_LEAD;
  selectedLeadId: number;
}

interface setLeadProperties {
  type: typeof SET_LEAD_PROPERTIES;
  properties: LeadProperty[];
}

interface setLeadPropertiesCount {
  type: typeof SET_LEAD_PROPERTIES_COUNT;
  count: number;
}

interface setLeadAvailability {
  type: typeof SET_LEAD_AVAILABILITY;
  selectedAvailability: number;
}

export type Action =
  | setLoading
  | setLead
  | showActivityModalForm
  | hideActivityModalForm
  | setSelectedActivity
  | setLeadActivities
  | setSelectedLeadId
  | setLoadingActivities
  | setLeadProperties
  | setLeadPropertiesCount
  | setLeadAvailability;

export interface Filters {
  tab?: string;
}

export interface QualificationForm {
  leadQualifier: string;
  leadQualifierStatus: string;
  lastContact: string;
  followUpDate: string;
  score: number;
  lostReason: string;
  comments: string;
  email: string;
  phone: string;
  status: string;
}

export interface LeadActivitiesFilters extends PropertyTableFilters {
  ordering?: string;
  page?: string | number;
  page_size?: string | number;
}
