import Button from "@houm-com/ui/Button";
import { useHistory } from "react-router-dom";

interface Props {
  title: string;
  description: string;
  showBackButton?: boolean;
  backToLabel?: string;
  showHomeButton?: boolean;
  homeLabel?: string;
  jsonErrorData?: any;
}

export default function ErrorScreen({
  title,
  description,
  showBackButton = true,
  backToLabel = "Volver atrás",
  showHomeButton = true,
  homeLabel = "Ir al inicio",
  jsonErrorData,
}: Props) {
  const history = useHistory();
  return (
    <>
      <p className="text-base font-semibold text-h40 text-primary-cta">Opps.</p>
      <h1 className="mt-1 text-h28 font-bold tracking-tight">{title}</h1>
      <p className="mt-2 text-p16">{description}</p>
      {jsonErrorData && (
        <>
          <p className="mt-4 text-p16">Información adicional para rastrear el error:</p>
          <pre className="text-p14 p-4 border bg-container border-black-40 rounded">
            {JSON.stringify(jsonErrorData, null, 2)}
          </pre>
        </>
      )}
      <div className="mt-6">
        {showBackButton && (
          <Button variant="primary" size="md" onClick={() => history.goBack()}>
            {backToLabel}
          </Button>
        )}
        {showHomeButton && (
          <Button variant="tertiary" size="md" className="ml-4" onClick={() => history.push("/")}>
            {homeLabel}
          </Button>
        )}
      </div>
    </>
  );
}
