import { ActionType, Action, State } from "./demandLeadTypes";

export const initialState: State = {
  country: "",
  filters: {
    date: null,
    leadQualifier: null,
    origin: null,
    state: [{ label: "Seguimiento LBQ" }, { label: "Sin contactar" }],
  },
  isLoading: false,
  isOpenFilterModal: false,
  leads: [],
  deal: "",
  profiled: false,
  totalLeads: 10,
  isOpenSelectedModal: false,
  selectedLeads: {},
};

const demandLeadReducer = (state, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DEAL:
      return {
        ...state,
        deal: action.deal,
      };

    case ActionType.SET_PROFILED:
      return {
        ...state,
        profiled: action.profiled,
      };

    case ActionType.SET_COUNTRY:
      return {
        ...state,
        country: action.country,
      };

    case ActionType.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case ActionType.SET_IS_OPEN_FILTER_MODAL:
      return { ...state, isOpenFilterModal: action.isOpenFilterModal };
    case ActionType.SET_LEADS:
      return {
        ...state,
        leads: action.leads,
      };
    case ActionType.SET_TOTAL_LEADS:
      return {
        ...state,
        totalLeads: action.totalLeads,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case ActionType.SET_SELECTED_LEADS:
      return {
        ...state,
        selectedLeads: action.selectedLeads,
      };

    case ActionType.SET_IS_OPEN_SELECTED_MODAL:
      return {
        ...state,
        isOpenSelectedModal: action.isOpenSelectedModal,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
