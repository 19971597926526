import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useCalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowForward: {
      marginLeft: theme.spacing(2),
    },
    arrowBack: {
      marginRight: theme.spacing(2),
    },
    calendar: {
      "&.fc .fc-toolbar.fc-header-toolbar": {
        display: "none !important",
      },
    },
  })
);

export default useCalendarStyles;
