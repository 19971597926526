import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 0,
    display: "flex",
    flexDirection: "row",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    alignItems: "center",
    alignContent: "center",
    "& .MuiSvgIcon-root": {
      margin: theme.spacing(0, 1.5),
      fontSize: "0.9rem",
      color: theme.palette.primary.main,
    },
  },
  past: {
    color: theme.palette.grey[200],
  },
  active: {
    color: theme.palette.grey[500],
    fontWeight: "bold",
  },
  hover: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  arrow: {
    margin: theme.spacing(0, 0.25),
    height: 16,
  },
  label: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  labelText: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useStyles;
