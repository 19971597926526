import { makeStyles } from "@material-ui/core";

const useSectionTitleStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    justifyContent: "space-around",
    marginTop: theme.spacing(1),
  },
  gap: {
    minWidth: 245,
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0, 3, 1, 3),
  },
}));

export default useSectionTitleStyles;
