import useUserHeadersStyles from "./UserHeaderStyles";

interface Props {
  title: string;
  country?: string;
}

function Header({ title, country }: Props) {
  const classes = useUserHeadersStyles();

  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      {country && <p className={classes.country}>{country}</p>}
    </div>
  );
}

Header.defaultProps = {
  country: null,
};

export default Header;
