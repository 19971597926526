import { Grid, Typography } from "@material-ui/core";
import { Locale } from "models/Countries";
import { IUserBankAccountResponse } from "services/bankAccountService/types";
import { accountTypes } from "utils";
import { localeToCountry } from "utils/countryCode";

interface Props {
  bankAccount: IUserBankAccountResponse;
  country: Locale;
}

const BankAccountInfo = ({ bankAccount, country }: Props) => (
  <Grid container>
    <Grid item>
      <Typography>Titular: {bankAccount.holder}</Typography>
      <Typography>Documento: {bankAccount.document}</Typography>
      <Typography>Banco: {bankAccount.bank_name}</Typography>
      <Typography>
        Tipo de cuenta:
        {
          accountTypes[localeToCountry[country]].find((tp) => tp.code === bankAccount.account_type)
            ?.name
        }
      </Typography>
      <Typography>Número de cuenta: {bankAccount.account_number}</Typography>
      <Typography>Email: {bankAccount.email}</Typography>
    </Grid>
  </Grid>
);

export default BankAccountInfo;
