import { useFeatureManager } from "@houm-com/feature-manager";

import Spinner from "@houm-com/ui/Spinner";

import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { ContractModel } from "models/Contract";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

import Warranty from "../ExitProcess/components/Warranty";
import RequestInfo from "./components/RequestInfo";
import useGetDesertionProcessInfo from "./hooks/useGetDesertionProcessInfo";

interface Props {
  contract: ContractModel;
  subscriptionId: number;
  tenantId: number;
  country: Locale;
}

const MandateRefund = ({ contract, subscriptionId, tenantId, country }: Props) => {
  const { desertionProcessInfo, isLoading: desertionProcessInfoLoading } =
    useGetDesertionProcessInfo(contract.id);
  const { data, status } = useFeatureManager("contract_liberation_days_dictionary");
  const { state: tabState } = useTab();
  const DEFAULT_LIBERATION_DAYS = 90;
  const contractLiberationDays =
    status === "success" && data?.enabled ? data.content[country] : DEFAULT_LIBERATION_DAYS;

  if (desertionProcessInfoLoading)
    return (
      <TabPanel value={tabState?.currentTabValue} index="refund">
        <Spinner size="xl" variant="primary" />
      </TabPanel>
    );

  return (
    <TabPanel value={tabState?.currentTabValue} index="refund">
      <div className="flex flex-col gap-6">
        <RequestInfo
          desertionProcessInfo={desertionProcessInfo}
          liberationDays={contractLiberationDays}
        />
        <Warranty
          contractId={contract.id}
          countryCode={countryCode(contract?.property.country)}
          propertyId={contract.property.id}
          landlordId={contract.property.user as unknown as number}
          tenantId={tenantId}
          subscriptionId={subscriptionId}
        />
      </div>
    </TabPanel>
  );
};

export default MandateRefund;
