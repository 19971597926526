import { useHistory } from "react-router-dom";
import Button from "@houm-com/ui/Button";

import { useNewSubscriptionStyles } from "./NewSubscriptionStyles";

interface Props {
  newQuotationId: number;
}

const NewSubscription = ({ newQuotationId }: Props) => {
  const classes = useNewSubscriptionStyles();
  const history = useHistory();
  const onClick = () => {
    history.push(`/admin/clients/${newQuotationId}`);
  };
  return (
    <Button variant="primary" size="lg" onClick={onClick} className={classes.button}>
      Ir a nueva suscripción
    </Button>
  );
};

export default NewSubscription;
