import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { Subscription as ISubscription } from "models/Subscription";
import { countryCode } from "utils";

import { useActionsContainerStyles } from "./ActionsContainerStyles";
import NewSubscriptionDisclaimer from "../NewSubscriptionDisclaimer";
import DeactivateSubscription from "../DeactivateSubscription";
import ActivateSubscription from "../ActivateSubscription";
import NewSubscription from "../NewSubscription";

interface Props {
  subscription: ISubscription | null;
}
type NotActiveStatus = "FINISHED_PROPERLY" | "CANCELLED" | "NO_TENANT";

const ActionsContainer = ({ subscription }: Props) => {
  const classes = useActionsContainerStyles();
  const hasNewQuotation = Boolean(subscription?.new_quotation_id);
  const isActive = subscription?.status === "LEASED";

  return (
    <div className={classes.container}>
      <div className={classes.buttonsContainer}>
        {!isActive && (
          <ProtectedComponent scope="permissions" require={["activate:subscriptions:all"]}>
            <ActivateSubscription
              subscriptionStatus={subscription?.status as NotActiveStatus}
              contractId={subscription?.contract?.id}
            />
          </ProtectedComponent>
        )}
        {isActive && (
          <ProtectedComponent scope="permissions" require={["cancel:subscriptions:all"]}>
            <DeactivateSubscription
              country={countryCode(subscription.property.country)}
              subscriptionId={subscription?.id}
            />
          </ProtectedComponent>
        )}
        {hasNewQuotation && <NewSubscription newQuotationId={subscription.new_quotation_id} />}
      </div>
      {hasNewQuotation && <NewSubscriptionDisclaimer />}
    </div>
  );
};

export default ActionsContainer;
