import { useQuery, UseQueryResult } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";

import { parseAppliedPromotions } from "./utils/parsers";
import { IAppliedPromotions } from "../../utils/types";

const useAppliedPromotions = (contractId: number | null) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IAppliedPromotions[], Error> = useQuery(
    ["promotiones_applied_by_contract_id"],
    async () => {
      const authToken = await getToken();
      const response = await contractService.appliedPromotionById(contractId, authToken);
      const parsedData = parseAppliedPromotions(response.data);
      return parsedData;
    },
    {
      enabled: !!contractId,
    }
  );

  return { isLoading, isError, data };
};

export default useAppliedPromotions;
