import { makeStyles } from "@material-ui/core";

const useSaveInformationStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: "none",
    borderRadius: 20,
    minWidth: 150,
    fontWeight: 700,
    marginTop: theme.spacing(4),
  },
  buttonContainers: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  textAsign: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  autocompleteContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },
  autocomplete: {
    minWidth: 300,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 100,
    padding: theme.spacing(0.5, 2),
  },
}));

export default useSaveInformationStyles;
