import { makeStyles, Theme } from "@material-ui/core";

const useCustomCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    border: `1px solid #A0B1B9`,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  cardHeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardHeader: {
    borderBottom: `1px solid #A0B1B9`,
    padding: theme.spacing(1),
  },
  cardHeaderTitle: {
    fontSize: theme.typography.pxToRem(16),
  },
  cardHeaderAction: {
    alignSelf: "center",
    marginTop: 0,
    marginRight: 0,
  },
}));

export default useCustomCardStyles;
