import { Container, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import { setNewTemplate, setView } from "context/contractTemplatesContext/contractTemplatesActions";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import { getCountry } from "Components/Admin/administrator/contracts/ContractTemplates/utils";
import { CountryUpperCase } from "models/Countries";
import { countryCode } from "utils";

import useCreateTemplateDialogStyles from "./CreateTemplateDialogStyles";

interface Props {
  open: boolean;
  onClose: () => void;
}

function CreateTemplateDialog({ open, onClose }: Props) {
  const classes = useCreateTemplateDialogStyles();
  const { state, dispatch } = useContractTemplates();
  const { newTemplate } = state;

  const handleCreateTemplate = () => {
    onClose();
    dispatch(setView("templateForm"));
  };

  return (
    <CustomDialog
      title="Nuevo template"
      subtitle="¿En qué país y/o ciudad se aplicará?"
      open={open}
      onClose={onClose}
      minWidth={500}
      classes={classes}>
      <Container className={classes.mainContainer}>
        <Typography className={classes.selectCountry}>Selecciona el país</Typography>
        <CountrySelector
          stateCountry={getCountry(newTemplate.country)}
          onChange={(country: CountryUpperCase) =>
            dispatch(setNewTemplate({ country: countryCode(country) }))
          }
        />
        <Container className={classes.buttonContainer}>
          <DesignSystemButton
            label="Continuar"
            size="small"
            variant="primary"
            onClick={handleCreateTemplate}
          />
        </Container>
      </Container>
    </CustomDialog>
  );
}

export default CreateTemplateDialog;
