import { useCallback, useState, MouseEvent } from "react";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import useGuard from "domains/auth/hooks/useGuard";
import { MovementType } from "models/Payments";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import useEditPaymentOrder from "../../../../../hooks/useEditPaymentOrder";
import { StyledMenu, StyledMenuItem } from "./StatusMenuStyles";
import { getIfChangeIsAllowed } from "../../../utils";
import ModalDisclaimer from "../ModalDisclaimer";
import Status from "./components/Status";
import { getStatusData } from "./utils";

interface Props {
  status: string;
  id: string;
  country: Locale;
  agreedDate: string;
  movementType: MovementType;
}

function StatusMenu({ status, id, country, agreedDate, movementType }: Props) {
  const { open: openHourDisclaimerModal, handleOpenModal: handleOpenHourDisclaimerModal } =
    useModal();
  const { guard } = useGuard();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState({
    flagOpen: false,
    status: null,
    movementId: null,
  });

  const handleCloseModal = () =>
    setOpenModal((prev) => ({
      ...prev,
      flagOpen: false,
    }));

  const { mutate, isLoading } = useEditPaymentOrder(handleCloseModal);

  const handleClick = useCallback((currentTarget: HTMLButtonElement) => {
    const flag = getIfChangeIsAllowed(agreedDate, movementType);
    if (!flag) return handleOpenHourDisclaimerModal();
    setAnchorEl(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeStatus = useCallback((event: MouseEvent<HTMLElement>, movementId: string) => {
    const status = (event.target as HTMLInputElement).id;
    setOpenModal({
      flagOpen: true,
      status,
      movementId,
    });
    setAnchorEl(null);
  }, []);

  const submit = async (status: string, movementId: string) => {
    if (isLoading) return;
    mutate({ status, movementId, country });
  };

  return (
    <>
      <Status
        status={status}
        onClick={
          guard.roles.any(["super_admin", "management_admin", "collection_management_admin"])
            ? handleClick
            : null
        }
        open={Boolean(anchorEl) && id === anchorEl?.id}
        id={id}
      />
      <StyledMenu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && id === anchorEl?.id}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        {status !== "UNPAID" && (
          <StyledMenuItem onClick={(e) => handleChangeStatus(e, id)} id="UNPAID">
            No pagado
          </StyledMenuItem>
        )}
        {!["PAID", "CANCELED"].includes(status) && (
          <StyledMenuItem onClick={(e) => handleChangeStatus(e, id)} id="CANCELED">
            Cancelado
          </StyledMenuItem>
        )}
        {guard.permissions.any(["update:payment_movement_status:all"]) &&
          status === "UNPAID" &&
          movementType === "INCOME" && (
            <StyledMenuItem onClick={(e) => handleChangeStatus(e, id)} id="LOST">
              Perdido
            </StyledMenuItem>
          )}
        {guard.permissions.any(["update:payment_movement_status:all"]) &&
          ["UNPAID", "CANCELED"].includes(status) &&
          country === "co" &&
          movementType === "INCOME" && (
            <StyledMenuItem onClick={(e) => handleChangeStatus(e, id)} id="INSURANCE_MANAGEMENT">
              Gestión externa
            </StyledMenuItem>
          )}
      </StyledMenu>
      <ConfirmDialog
        title={`¿Seguro que quieres ${
          getStatusData(openModal.status).actionLabel
        } la orden de pago?`}
        isOpen={openModal.flagOpen}
        onClose={() => handleCloseModal()}
        onSubmit={() => submit(openModal.status, openModal.movementId)}
        submitButtonProps={{
          isLoading,
        }}
      />
      <ModalDisclaimer
        isOpen={openHourDisclaimerModal}
        onHandleModel={handleOpenHourDisclaimerModal}
      />
    </>
  );
}

export default StatusMenu;
