import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme: Theme) => ({
  wrapper: {
    display: "flex",
    margin: "0px",
    width: "100%",
    padding: theme.spacing(0.3),
  },
  control: {
    width: "100%",
  },
  picker: {
    width: "100%",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "0.875rem",
    marginBottom: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));
