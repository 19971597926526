import { useMemo } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./OptionButtonStyles";
import classNames from "classnames";

interface Props extends React.ComponentProps<typeof Button> {
  disabled: boolean;
  compressed: boolean;
  width?: string;
  round?: boolean;
  rectangleRounded?: boolean;
  buttonClassName?: string;
}

const OptionButton: React.FC<Props> = ({
  children,
  disabled = false,
  compressed = false,
  round = false,
  rectangleRounded = false,
  width = "",
  buttonClassName,
  ...props
}: Props) => {
  const classes = useStyles({
    disabled,
    compressed,
    width,
    round,
    rectangleRounded,
  });
  const optionButtonClasses = useMemo(
    () => (compressed ? { root: classes.contract } : null),
    [compressed]
  );
  return (
    <Button
      {...props}
      className={classNames(classes.optionButtonWrap, buttonClassName)}
      size="small"
      classes={optionButtonClasses}>
      {children}
    </Button>
  );
};

export default OptionButton;
