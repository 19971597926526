import { es } from "date-fns/locale";
import { format } from "date-fns";

import { Container, Typography } from "@material-ui/core";

import { IReservation } from "services/newPaymentService/types";
import { parsePrice } from "utils";

import useProfitReservationDataStyles from "./ProfitReservationDataStyles";

interface Props {
  reservation: IReservation;
}

const ProfitReservationData = ({ reservation }: Props) => {
  const classes = useProfitReservationDataStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.label}>
        <b>Fecha pactada de pago: </b>
        {format(new Date(reservation.agreed_date), "dd/MM/yyyy", {
          locale: es,
        })}
      </Typography>
      <Typography className={classes.label}>
        <b>Fecha de pago: </b>
        {format(new Date(reservation.date), "dd/MM/yyyy", {
          locale: es,
        })}
      </Typography>
      <Typography className={classes.label}>
        <b>Monto a pagar: </b>
        {parsePrice(reservation.total_amount, reservation.currency)}
      </Typography>
      <Typography className={classes.label}>
        <b>Monto pagado: </b>
        {parsePrice(reservation.payment, reservation.currency)}
      </Typography>
    </Container>
  );
};

export default ProfitReservationData;
