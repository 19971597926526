import * as yup from "yup";

import { Locale } from "models/Countries";

export const requiredText = "Obligatorio";

export const contractFormSchema = (country: Locale) =>
  yup.object({
    planId: yup.number().required(requiredText),
    currencyCode: country === "cl" && yup.string().required(requiredText),
    amount: yup
      .number()
      .when("currencyCode", {
        is: "CLF",
        then: yup
          .number()
          .min(0, "El valor mínimo debe ser mayor a 0")
          .max(100, "El valor de arriendo es muy alto")
          .required(requiredText),
        otherwise: yup.number().required(requiredText),
      })
      .required(requiredText),
    commonExpenses: country !== "cl" && yup.string().required(requiredText),
    readjust: country === "cl" && yup.string().required(requiredText),
  });
