import { Typography } from "@material-ui/core";
import { AcceptOffer } from "./AcceptOffer";
import { RejectOfferOffer } from "./RejectOffer";

interface StatusOfferProps {
  negotiationStatus: string;
  offerStatus: string;
  offerId: string;
  country: string;
  handleRefresh: () => void;
}

export function StatusOffer({
  negotiationStatus,
  offerStatus,
  offerId,
  country,
  handleRefresh,
}: StatusOfferProps) {
  return (
    <>
      <Typography>{negotiationStatus}</Typography>
      <AcceptOffer
        offerStatus={offerStatus}
        offerId={offerId}
        country={country}
        handleRefresh={handleRefresh}
      />
      <RejectOfferOffer
        offerStatus={offerStatus}
        offerId={offerId}
        country={country}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
