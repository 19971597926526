import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";

import schedulerService from "services/schedulerService";
import { ISchedules } from "models/Property";
import { useGetToken } from "hooks";

interface IApplicant {
  id: string;
  name: string;
}

interface Props {
  scheduleId: number;
  schedule: ISchedules;
}

function ScheduleApplicant({ scheduleId, schedule }: Props) {
  const { getToken } = useGetToken();
  const [applicant, setApplicant] = useState<IApplicant | null>(null);

  useEffect(() => {
    const fetchApplicant = async () => {
      const authToken = await getToken();
      try {
        const fetchedApplicant = await schedulerService.getApplicant(scheduleId, authToken);
        if (fetchedApplicant.data[0]) {
          setApplicant(fetchedApplicant.data[0]);
        }
      } catch {
        console.error("error");
      }
    };
    fetchApplicant();
  }, [scheduleId]);

  return (
    <>
      {applicant && (
        <Link to={`/admin/applicants/${applicant.id}`} target="_blank">
          {applicant?.name}
        </Link>
      )}
      <Typography>{schedule?.comment}</Typography>
    </>
  );
}
export default ScheduleApplicant;
