import { UseQueryResult, useQuery } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";
import { parseDocumentSectionsLinks } from "./parsers";
import { IDocumentSectionsLinks } from "./types";

const useDocumentSectionsLinks = (contractId: number) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data, isSuccess }: UseQueryResult<IDocumentSectionsLinks, Error> =
    useQuery(
      ["document_sections_links", contractId],
      async () => {
        const authToken = await getToken();
        const response = await contractService.getDocumentSectionsLinks(contractId, authToken);
        return parseDocumentSectionsLinks(response.data);
      },
      {
        enabled: !!contractId,
      }
    );

  return { isLoading, error, data, isSuccess };
};

export default useDocumentSectionsLinks;
