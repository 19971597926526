/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import useStyles from "./ControlledCheckboxStyles";
import clx from "classnames";

interface Props {
  label: string;
  id: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  controllerProps?: React.ComponentProps<typeof Controller>;
  checkboxProps?: React.ComponentProps<typeof Checkbox>;
  placement?: "end" | "start" | "top" | "bottom";
  justifyContent?: string;
  size?: string;
  labelProps?: string;
}

function ControlledCheckbox({
  id,
  defaultChecked,
  defaultValue,
  checkboxProps,
  label,
  controllerProps,
  labelProps,
  placement = "start",
  justifyContent = "space-between",
  size = "md",
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles({ justifyContent, size });

  return (
    <Controller
      name={id}
      defaultValue={defaultValue ?? defaultChecked}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error, invalid } }) => (
        <FormControl className={classes.wrapper} error={invalid}>
          <FormControlLabel
            classes={{
              label: clx(classes.label, labelProps),
              root: classes.wrapper,
            }}
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                defaultValue={defaultValue}
                classes={{
                  root: classes.box,
                }}
                color="primary"
                {...checkboxProps}
              />
            }
            label={label}
            labelPlacement={placement}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}

export default ControlledCheckbox;
