import { parse } from "date-fns";

import { QuestionResponse } from "services/questionsService/types";
import { IQuestion } from "./types";

export const parseQuestions = (data: QuestionResponse[]): IQuestion[] =>
  data.map((question) => ({
    id: question.id,
    property: question.prop,
    question: question.question,
    answer: question.answer,
    createdAt: parse(question.created_at, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'", new Date()),
    updatedAt: parse(question.updated_at, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'", new Date()),
    authorName: question.author_name,
    photos: question.photos.map((photo) => ({
      url: photo.photo_link,
    })),
  }));
