import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldTextArea from "@houm-com/ui/FieldTextArea";
import FieldInput from "@houm-com/ui/FieldInput";
import Spinner from "@houm-com/ui/Spinner";
import Button from "@houm-com/ui/Button";

import { MemberPersonalInformation, UserProfile } from "domains/evaluations/models/Evaluations";
import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import useEvaluateForm from "domains/evaluations/hooks/useEvaluateForm";
import useGetDocumentTypes from "hooks/useDocumentTypeList";
import useReactHookForm from "hooks/useReactHookForm";
import { localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";

import { evaluateFormSchema } from "./utils/validationSchema";
import PersonInformationForm from "../PersonInformationForm";
import EmploymentForm from "./components/EmploymentForm";
import LiquidationForm from "../LiquidationForm";
import { getDefaultData } from "./utils/utils";

interface EvaluateFormProps {
  country: Locale;
  personInformation: MemberPersonalInformation;
  userInformation: UserProfile;
  memberId: string;
}

const EvaluateForm = ({
  country,
  personInformation,
  userInformation,
  memberId,
}: EvaluateFormProps) => {
  const { mutate, isLoading } = useEvaluateForm({ country, memberId });
  const { documentTypeList, loading } = useGetDocumentTypes(localeToCountry[country]);
  const { methods, errors } = useReactHookForm<MemberInformation>({
    formSchema: evaluateFormSchema(country, documentTypeList),
  });

  useEffect(() => {
    methods.reset(getDefaultData(personInformation, userInformation));
  }, [personInformation, userInformation]);

  const onSubmit: SubmitHandler<MemberInformation> = (dataForm) => {
    if (isLoading) return;
    mutate(dataForm);
  };

  if (loading)
    return (
      <Spinner
        classNameContainer="flex justify-center items-center h-full"
        variant="primary"
        size="2xl"
      />
    );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PersonInformationForm
          documentTypes={documentTypeList.map((document) => ({
            label: document.label,
            value: document.value,
          }))}
        />
        <h2 className="text-h20 font-bold mt-5 mb-2">Dirección</h2>
        <FieldInput
          id="fullAddress"
          placeholder="Ingresar dirección"
          label="Dirección"
          required
          errorMessage={errors.fullAddress?.message}
          form="novalidateform"
          {...methods.register("fullAddress")}
        />
        {/* <AddressField defaultValue={defaultValues.fullAddress} country={country} /> */}
        <EmploymentForm />
        <LiquidationForm country={country} />
        <h2 className="text-h20 font-bold mt-5 mb-2">Resumen</h2>
        <FieldTextArea
          label="Comentario"
          id="comment"
          errorMessage={errors.comment?.message}
          placeholder="Escribe un comentario"
          form="novalidateform"
          {...methods.register("comment")}
        />
        <div className="flex justify-end">
          <Button size="md" variant="primary" className="mt-2" isLoading={isLoading}>
            Guardar
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EvaluateForm;
