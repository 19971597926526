import { Locale } from "models/Countries";

export const marketplaceLink = {
  property: (
    locale: Locale,
    deal: "rental" | "sale",
    property: {
      type: "departamento" | "casa";
      region: string;
      commune: string;
      id: number;
    }
  ) =>
    `https://houm.com/${locale}/propiedades/${deal === "rental" ? "arriendo" : "venta"}/${
      property.type
    }/${property.region}/${property.commune}/${property.id}`,
};
