import ConfirmDialog from "@houm-com/ui/ConfirmDialog";
import Button from "@houm-com/ui/Button";

import useModal from "hooks/useModal";

import useSetContractData from "../../hooks/useSetContractData";

interface Props {
  contractId: number;
}

export default function GenerateDocuments({ contractId }: Props) {
  const { handleOpenModal, open } = useModal();
  const { mutate, isLoading } = useSetContractData({ handleOpenModal });

  const formData = new FormData();
  formData.append("contract_id", JSON.stringify(contractId));

  const handleConfirm = () => mutate(formData);

  return (
    <>
      <Button variant="primary" size="md" type="button" onClick={handleOpenModal}>
        Generar documentos
      </Button>
      <ConfirmDialog
        title="Generar documentos"
        subtitle="Para generar los documentos de manera correcta, debes haber llenado todas las secciones previas. Si realizas esta acción, se eliminará cualquier documento ingresado de manera manual."
        submitButtonLabel="Generar"
        cancelButtonLabel="Cancelar"
        isOpen={open}
        onSubmit={handleConfirm}
        onClose={handleOpenModal}
        submitButtonProps={{
          isLoading,
        }}
      />
    </>
  );
}
