import { Typography } from "@material-ui/core";

import PropertyInspectionReportsMainIndex from "./main";
import PropertyInspectionReportViewIndex from "./shared/components/PropertyInspectionReportView";
import { PropertyInfo } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components";
import BackButton from "Components/Admin/UIComponents/Buttons/BackButton";
import { useProperty } from "context/propertyContext/propertyContext";
import {
  setInspectionReportsCurrentView,
  setProperty,
} from "context/propertyContext/propertyActions";
import { PropertyData } from "models/Property";
import { useEffect } from "react";

interface Props {
  property: PropertyData;
}

function PropertyInspectionReportsTabIndex({ property }: Props) {
  const { state, dispatch } = useProperty();

  const handleGoBackToInspectionReportsList = () =>
    dispatch(setInspectionReportsCurrentView("main"));

  useEffect(() => {
    dispatch(setProperty(property));
  }, []);

  return (
    <>
      <Typography className="mb-3" variant="h4" component="h6">
        <strong>Actas</strong>
      </Typography>
      {state.inspectionReportsCurrentView !== "main" && (
        <BackButton arrow onClick={handleGoBackToInspectionReportsList}>
          Volver
        </BackButton>
      )}
      <PropertyInfo />
      {state.inspectionReportsCurrentView === "main" ? (
        <PropertyInspectionReportsMainIndex />
      ) : (
        <PropertyInspectionReportViewIndex />
      )}
    </>
  );
}

export default PropertyInspectionReportsTabIndex;
