import Swal from "sweetalert2";
import { AxiosResponse } from "axios";
import demandService from "services/demandService";
import { parseLeads } from "Components/Admin/administrator/demand/leads/utils/parseLeads";
import { Action, ActionType, SelectedLeads } from "./demandLeadTypes";

export const setIsIsOpenFilterModal = (isOpenFilterModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_FILTER_MODAL,
  isOpenFilterModal,
});
export const setDeal = (deal): Action => ({
  type: ActionType.SET_DEAL,
  deal,
});
export const setProfiled = (profiled): Action => ({
  type: ActionType.SET_PROFILED,
  profiled,
});
export const setCountry = (country): Action => ({
  type: ActionType.SET_COUNTRY,
  country,
});
export const setLeads = (leads): Action => ({
  type: ActionType.SET_LEADS,
  leads,
});
export const setTotalLeads = (totalLeads): Action => ({
  type: ActionType.SET_TOTAL_LEADS,
  totalLeads,
});

export const setFilters = (filters): Action => ({
  type: ActionType.SET_FILTERS,
  filters,
});

export const setIsLoading = (isLoading): Action => ({
  type: ActionType.SET_LOADING,
  isLoading,
});

export const setSelectedLeads = (selectedLeads: SelectedLeads): Action => ({
  type: ActionType.SET_SELECTED_LEADS,
  selectedLeads,
});

export const setIsOpenSelectedModal = (isOpenSelectedModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_SELECTED_MODAL,
  isOpenSelectedModal,
});

//fetching
export const getLeads = async (dispatch, params, authToken) => {
  try {
    dispatch(setLeads([]));
    dispatch(setIsLoading(true));
    const leads = await demandService.getLeads(params, authToken);
    dispatch(setLeads(parseLeads(leads.data.results)));
    dispatch(setTotalLeads(leads.data.count));
    dispatch(setIsLoading(false));
  } catch {
    Swal.fire("Error", "No se han podido obtener los leads.", "error");
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateLBtoLeads = async (ids: number[], executiveId: number, authToken: string) => {
  try {
    const response = (await demandService.changeLBtoLeads(
      ids,
      executiveId,
      authToken
    )) as AxiosResponse;
    return response.status === 200;
  } catch {
    Swal.fire(
      "Error",
      "No se han podido actualizar los ejecutivos para los leads seleccionados.",
      "error"
    );
  }
};
