import { makeStyles, Theme } from "@material-ui/core";

const useTemplateFormStyles = makeStyles<Theme>((theme) => ({
  templateFormRoot: {
    paddingTop: theme.spacing(3),
    width: 450,
    display: "flex",
    flexDirection: "column",
  },
  fileBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  downloadFile: {
    marginBottom: theme.spacing(2.5),
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  arrowBack: {
    fontSize: 14,
  },
  backButton: {
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}));

export default useTemplateFormStyles;
