import moment from "moment";
import { useMemo } from "react";
import { usePDF } from "@react-pdf/renderer";

import { GridColDef, GridColumns } from "@material-ui/x-grid";

import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";

import useStyles from "Components/Admin/administrator/supply/utils/TableStyles";
import useInspectionReports from "hooks/useInspectionReports/useInspectionReports";
import InspectionReportDocument from "../../../shared/components/InspectionReportDocumentV2";
import { useTable } from "context/tableContext/tableContext";
import { useProperty } from "context/propertyContext/propertyContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { Inspection } from "hooks/useInspectionReports/types";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  filterable: false,
  align: "left",
  sortable: false,
  minWidth: 200,
};

const DownloadPDFRow = ({ row, propertyData }) => {
  const [instance] = usePDF({
    document: InspectionReportDocument({
      inspectionReport: row as Inspection,
      propertyData: {
        address: propertyData?.address,
        region: propertyData?.region,
      },
    }),
  });
  return (
    <LinkButton
      disabled={instance.loading}
      onClick={() => {
        window.open(instance.url, "_blank");
      }}>
      Descargar acta
    </LinkButton>
  );
};

function Table() {
  const classes = useStyles();
  const { state } = useProperty();
  const { state: tableState, dispatch: tableDispatch } = useTable();

  const { currentPage, pageSize } = tableState;

  const {
    inspectionReportList = [],
    isLoading,
    count,
  } = useInspectionReports(state?.property?.id, currentPage, pageSize);

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "createdAt",
        headerName: "Creación",
        sortable: true,
        renderCell: ({ row }) => moment(row?.createdAt).local().format("DD-MM-YYYY"),
      },
      {
        ...columnsConfig,
        field: "type",
        headerName: "Tipo de acta",
        minWidth: 300,
        renderCell: ({ row }) => row?.scheduleType || "No especificado",
      },
      {
        ...columnsConfig,
        field: "appraiser",
        headerName: "Asesor",
        minWidth: 300,
        renderCell: ({ row }) => row?.appraiser?.name,
      },
      {
        ...columnsConfig,
        field: "showLink",
        headerName: "Ver acta",
        renderCell: ({ row }) => <DownloadPDFRow row={row} propertyData={state.property} />,
      },
    ],
    [state.property]
  );

  return (
    <div className={classes.container}>
      <CustomDataGrid
        className={classes.table}
        rowCount={count}
        columns={columns}
        rows={inspectionReportList}
        loading={isLoading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => tableDispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => tableDispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          tableDispatch(setPageSize(params));
          tableDispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

export default Table;
