import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  selected?: boolean;
  clickleable?: boolean;
}

const usePinkStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomArrow: {
    marginBottom: -1,
    width: 0,
    height: 0,
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    borderTop: ({ selected }) =>
      `5px solid ${selected ? theme.palette.grey[900] : theme.palette.primary.light}`,
    borderRadius: 10,
  },
  mainPropContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: 15,
    backgroundColor: ({ selected }) =>
      selected ? theme.palette.grey[900] : theme.palette.primary.light,
    color: "white",
    minWidth: 44,
    padding: theme.spacing(0.5, 1.3),
    borderRadius: 10,
    fontSize: theme.typography.pxToRem(12),
    cursor: ({ clickleable }) => (clickleable ? "pointer" : "default"),
  },
}));

export default usePinkStyles;
