import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import useEvaluationDocuments from "./hooks/useEvaluationDocuments";

interface Props {
  evaluationId: number;
  country: Locale;
}

const EvaluationDocuments = ({ evaluationId, country }: Props) => {
  const { data, isError, isLoading, isSuccess } = useEvaluationDocuments(evaluationId, country);

  return (
    <Grid item xs={12} md={6}>
      <AdminCard title="Documentos de evaluación" withSpace={false} showTitleBackground={true}>
        {isError && (
          <Typography component="span">Ocurrió un error al cargar los documentos</Typography>
        )}
        {isLoading && <Spinner />}
        {isSuccess && (
          <DesignSystemButton href={data.link} label="Descargar" variant="primary" size="small" />
        )}
      </AdminCard>
    </Grid>
  );
};

export default EvaluationDocuments;
