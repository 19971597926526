import { useCallback, useState } from "react";

import { useLandlordVip } from "context/landlordVipContext/landlordVipContext";
import { setFilters } from "context/landlordVipContext/landlordVipActions";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import { TableFilters } from "context/tableContext/tableTypes";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setShowModal, setTableFilters } from "context/tableContext/tableActions";

function FilterOptions() {
  const [executiveEmail, setExecutiveEmail] = useState<string>("");

  const { state, dispatch: dispatchLandlordVip } = useLandlordVip();
  const { filters } = state;
  const { state: tableState, dispatch } = useTable();

  const handleFilterModal = () => dispatch(setShowModal(false));

  const handleSubmit = useCallback(() => {
    if (executiveEmail !== "") {
      const filtersTable: TableFilters = {
        page_size: 10,
        page: 1,
        ...(executiveEmail && { executiveEmail }),
      };
      dispatch(setCurrentPage(1));
      dispatchLandlordVip(
        setFilters({
          ...filters,
          size: 10,
          page: 1,
          executiveEmail: executiveEmail || null,
        })
      );
      dispatch(setTableFilters(filtersTable));
    }
    dispatch(setShowModal(false));
  }, [dispatch, executiveEmail]);

  const handleDelete = () => {
    setExecutiveEmail("");
    dispatchLandlordVip(
      setFilters({
        ...filters,
        executiveEmail: null,
      })
    );
    dispatch(setCurrentPage(1));
    dispatch(setTableFilters({}));
    dispatch(setShowModal(false));
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleFilterModal}
      onEscapeKeyDown={handleFilterModal}>
      <FilterDialog.Content>
        <FilterDialog.Item label="Correo ejecutivo/a">
          <FilterDialog.TextField
            type="text"
            value={executiveEmail}
            onChange={(e) => setExecutiveEmail(e.target.value)}
          />
        </FilterDialog.Item>
      </FilterDialog.Content>
      <FilterDialog.Actions onDelete={handleDelete} onApply={handleSubmit} />
    </FilterDialog>
  );
}

export default FilterOptions;
