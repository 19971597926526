import { makeStyles, Theme } from "@material-ui/core";

const useProgressCardStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  textArea: {
    display: "block",
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: "none",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  textAreaRoot: {
    maxHeight: 150,
    overflowY: "auto",
    borderRadius: "1rem",
    border: "1px solid",
    borderColor: theme.palette.grey[400],
    margin: theme.spacing(0, 1),
    marginBottom: theme.spacing(1),
    "&::-webkit-scrollbar": {
      width: 6,
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: theme.shadows[2],
    },

    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[400],
      border: "1px solid white",
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.grey[100],
    },
  },
}));

export default useProgressCardStyles;
