import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import subscriptionService from "services/subscriptionService";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface IFormData {
  observation: string;
}

const useObservation = (subscriptionId: number) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      const data = {
        observation: formData.observation,
      };
      const response = await subscriptionService.updateSubscription(
        subscriptionId,
        data,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subscription");
        alert.success({
          message: "La observación se creó exitosamente",
        });
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo crear la observación",
          disclaimer: `Error: ${reason ? reason?.data?.detail : e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useObservation;
