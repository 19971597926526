import { useCallback, useState } from "react";
import { SnackbarProvider } from "notistack";

import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import ModalDialog from "@houm-com/ui/ModalDialog";

import useLegalRepresentativeCardStyles from "./LegalRepresentativeCardStyles";
import useDeleteContractPerson from "../../hooks/useDeleteContractPerson";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import IndividualLegalRepresentative from "./IndividualLegalRepresentative";
import { ContractModel, ILegalRepresentative, IPerson } from "models/Contract";
import { countryCode } from "utils";

import { CountryUpperCase } from "models/Countries";
import CodebtorFormEdit from "./CodebtorFormEdit";

interface Props {
  contract: ContractModel;
  country: CountryUpperCase;
}

type LegalRepresentativeType = "lessee_legal_representative" | "lessor_legal_representative";
interface ICustomPerson extends IPerson {
  role: LegalRepresentativeType;
}

function LegalRepresentativeCard({ contract, country }: Props) {
  const classes = useLegalRepresentativeCardStyles();
  const { mutate } = useDeleteContractPerson(contract.id);
  const [openModal, setOpenModal] = useState(false);
  const [roleType, setRoleType] = useState<LegalRepresentativeType | null>(null);
  const [person, setPerson] = useState<ICustomPerson | null>(null);
  const [editFlag, setEditFlag] = useState(false);

  const createLegalRepresentative = useCallback((role: LegalRepresentativeType) => {
    setEditFlag(false);
    setRoleType(role);
    setOpenModal(true);
  }, []);

  const editLegalRepresentative = useCallback(
    (role: LegalRepresentativeType, representative: ILegalRepresentative) => {
      setEditFlag(true);
      setRoleType(role);
      setPerson({
        email: representative?.email,
        phone: representative?.phone,
        name: representative?.name,
        address: representative?.address,
        role,
        occupation: representative?.occupation,
        document: representative?.document,
        document_type: representative?.document_type,
        rfc_number: representative?.rfc_number,
        civil_status: representative?.civil_status,
      });
      setOpenModal(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setPerson(null);
  }, []);

  return (
    <>
      <CollapseCard title="Representantes legales">
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-start">
                <IndividualLegalRepresentative
                  country={country}
                  title="Arrendatario"
                  legalRepresentative={contract.lessee_legal_representative}
                  onCreateLegalRepresentative={() =>
                    createLegalRepresentative("lessee_legal_representative")
                  }
                  onEditLegalRepresentative={() =>
                    editLegalRepresentative(
                      "lessee_legal_representative",
                      contract.lessee_legal_representative
                    )
                  }
                  onDeleteLegalRepresentative={(contractPersonId) => mutate({ contractPersonId })}
                />
                <IndividualLegalRepresentative
                  country={country}
                  title="Propietario"
                  legalRepresentative={contract.lessor_legal_representative}
                  onCreateLegalRepresentative={() =>
                    createLegalRepresentative("lessor_legal_representative")
                  }
                  onEditLegalRepresentative={() =>
                    editLegalRepresentative(
                      "lessor_legal_representative",
                      contract.lessor_legal_representative
                    )
                  }
                  onDeleteLegalRepresentative={(contractPersonId) => mutate({ contractPersonId })}
                />
              </Grid>
            </CardContent>
          </div>
        </Card>
      </CollapseCard>
      <ModalDialog
        isOpen={openModal}
        onClose={closeModal}
        title={editFlag ? "Representantes legales" : "Agregar representante legal"}
        subtitle={editFlag ? "Editar información" : "Crear representante legal"}>
        <SnackbarProvider>
          <CodebtorFormEdit
            contract={contract}
            person={person}
            country={countryCode(country)}
            type={roleType}
            typeAction={editFlag ? "edit" : "creation"}
          />
        </SnackbarProvider>
      </ModalDialog>
    </>
  );
}

export default LegalRepresentativeCard;
