import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import documentTypeService from "services/documentTypeService";
import { parseDocumentTypes } from "./parsers";
import { DocumentTypeProps } from "./types";

const useGetDocumentTypes = (country) => {
  const { getToken } = useGetToken();
  const { data, isLoading, isError }: UseQueryResult<DocumentTypeProps[], Error> = useQuery<
    DocumentTypeProps[],
    Error
  >(["documentTypes"], async () => {
    const authToken = await getToken();
    const response = await documentTypeService.getList(country, authToken);
    return parseDocumentTypes(response.data.results);
  });
  return { data, isLoading, isError };
};

export default useGetDocumentTypes;
