import { Map } from "models/Mapbox";

export enum ActionType {
  SET_MAP,
  SET_LOADED,
}

export interface State {
  map: Map;
  loaded: boolean;
}

export type SetMap = {
  type: typeof ActionType.SET_MAP;
  map: Map;
};

export type SetLoaded = {
  type: typeof ActionType.SET_LOADED;
  loaded: boolean;
};

export type Action = SetMap | SetLoaded;

export type Dispatch = (action: Action) => void;
