import { useHistory } from "react-router-dom";

import { useFeatureManager } from "@houm-com/feature-manager";
import IconButton from "@houm-com/ui/IconButton";
import FieldSelect from "@houm-com/ui/FieldSelect";
import { IoAddOutline as PlusIcon } from "@houm-com/ui-icons";

import { CountryUpperCase } from "models/Countries";
import useModal from "hooks/useModal";
import { LeaseType, PromotionStatus } from "hooks/useGetPromotions/utils/types";
import { countryCode } from "utils";

import CreatePromotionModal from "../PromotionsList/components/CreatePromotionModal";
import { classes } from "./CallToActionsStyles";

interface Props {
  handleStatus: (status: PromotionStatus) => void;
  setLeaseType: (leaseType: LeaseType) => void;
  country: CountryUpperCase;
}

const CallToActions = ({ handleStatus, setLeaseType, country }: Props) => {
  const { handleOpenModal, open } = useModal();
  const history = useHistory();
  const { data: promotionFlag, status: promotionFlagStatus } =
    useFeatureManager("new_promotions_form");

  const handleClick = () => {
    if (promotionFlagStatus === "success" && promotionFlag?.enabled) {
      history.push(`/admin/promotions/create/${countryCode(country)}`);
    } else {
      handleOpenModal();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.selectsContainer}>
        <FieldSelect
          id="state"
          label="Estado"
          required
          options={[
            { label: "Activas", value: "actives" },
            { label: "Desactivadas", value: "finalized" },
          ]}
          onChange={(e) => handleStatus(e.target.value as PromotionStatus)}
          className={classes.select}
        />
        <FieldSelect
          id="type"
          label="Tipo de promoción"
          required
          options={[
            { label: "Todas", value: "all" },
            { label: "Comisión Houm", value: "management_fee" },
            { label: "Corretaje", value: "brokerage" },
            // { label: "Arriendo", value: "rental" }, // Waiting on backend development
          ]}
          onChange={(e) => setLeaseType(e.target.value as LeaseType)}
          className={classes.select}
        />
      </div>
      <IconButton
        size="lg"
        variant="primary"
        onClick={handleClick}
        icon={<PlusIcon size={24} />}
        position="end">
        Crear promoción
      </IconButton>

      <CreatePromotionModal open={open} handleClose={handleOpenModal} country={country} />
    </div>
  );
};

export default CallToActions;
