import { useState } from "react";

import { PropertyData } from "models/Property";
import { Typologies } from "context/multifamilyContext/typology/typologyTypes";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CollapseButton from "Components/Admin/administrator/multifamily/Components/CollapseButton";
import Schedulers from "Components/Admin/administrator/properties/show/partials/Schedulers";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";

import ScheduleTable from "./components/Table";
import ScheduleGrid from "./components/ScheduleGrid";
import {
  RootStyled,
  ButtonContainerStyled,
  TableContainerStyled,
  ScheduleContainerStyled,
} from "./ScheduleSectionStyles";

interface Props {
  property?: PropertyData;
  typology?: Typologies;
}

function ScheduleSection({ property = null, typology = null }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <CustomDialog open={open} title="Agendamiento" onClose={handleClose}>
        <Schedulers property={property} />
      </CustomDialog>
      <RootStyled>
        <ButtonContainerStyled>
          <DesignSystemButton
            size="medium"
            label="Agendar"
            variant="primary"
            onClick={handleOpen}
          />
        </ButtonContainerStyled>
        <CollapseButton label="Visitas">
          <TableContainerStyled>
            <ScheduleTable property={property} typology={typology} />
          </TableContainerStyled>
        </CollapseButton>
        <CollapseButton label="Calendario de visitas">
          <ScheduleContainerStyled>
            <ScheduleGrid propertyId={1234} country="Chile" commune="Santiago" />{" "}
            {/* TODO: this must be adjusted */}
          </ScheduleContainerStyled>
        </CollapseButton>
      </RootStyled>
    </>
  );
}

export default ScheduleSection;
