import { CountryUpperCase, Locale } from "models/Countries";
import { localeToCountry } from "utils/countryCode";

export const getCountry = (country: Locale): CountryUpperCase => {
  if (country === "mx") {
    return "México";
  } else {
    return localeToCountry[country];
  }
};
