import { Grid } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { UserModel } from "models/User";

import DesignCardContent from "../../../DesignCardContent";
import User from "./components/User";

interface Props {
  userLandlord: UserModel | null;
  userTenant: UserModel | null;
}

const PlatformUser = ({ userLandlord, userTenant }: Props) => (
  <AdminCard
    title="Usuario de la plataforma / Corredor"
    withSpace={false}
    showTitleBackground={true}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DesignCardContent title="Propietario">
          <User user={userLandlord} />
        </DesignCardContent>
      </Grid>
      {userTenant && (
        <Grid item xs={6}>
          <DesignCardContent title="Arrendatario">
            <User user={userTenant} />
          </DesignCardContent>
        </Grid>
      )}
    </Grid>
  </AdminCard>
);

export default PlatformUser;
