import { useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";
import FieldSelect from "@houm-com/ui/FieldSelect";

import { ControlledUserText } from "Components/Admin/UIComponents/ControlledForm";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ContractModel } from "models/Contract";
import { PropertyData } from "models/Property";
import { countryCode } from "utils";

import { propertyFormSchema, requiredText } from "../validationSchema";
import { propertyFormDefaultValues } from "./utils/utils";
import useUpdateProperty from "./hooks/useUpdateProperty";
import useGetHoumers from "./hooks/useGetHoumers";
import { useFormNotifications } from "hooks";
import { IProperty } from "./utils/types";
import classes from "./PropertyFormStyles";

interface Props {
  property: PropertyData;
  contract: ContractModel;
  onSubmitProperty?: () => void;
}

function PropertyForm({ property, contract, onSubmitProperty }: Props) {
  const { t } = useTranslation(["contracts"]);
  const { houmers, houmersLoading } = useGetHoumers();
  const houmerId = useMemo(() => {
    if (houmers?.length && property.homechecker) {
      const houmerDataId = houmers.filter((h) => h.label === property.homechecker.name)[0];
      if (houmerDataId) {
        return houmerDataId.value;
      }
    }
  }, [houmers, property]);
  const { mutate, isSubmitting } = useUpdateProperty(
    property,
    contract,
    houmerId,
    onSubmitProperty
  );
  const methods = useForm<IProperty>({
    resolver: yupResolver(propertyFormSchema),
    reValidateMode: "onBlur",
    defaultValues: propertyFormDefaultValues(contract, property),
  });
  const { errors } = methods.formState;
  useFormNotifications<IProperty>({ errors, requiredText });
  const onSubmit: SubmitHandler<IProperty> = (data) => {
    mutate({ data });
  };
  const startAdornment = () => <div className={classes.startAdornment}>$ </div>;
  return (
    <Loading loading={houmersLoading}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContainer}>
          <FieldInput
            id="id"
            label="ID Propiedad"
            placeholder="ID Propiedad"
            disabled
            errorMessage={errors.id?.message}
            {...methods.register("id")}
          />
          <FieldInput
            id="publishedDate"
            label="Fecha publicación"
            placeholder="Fecha publicación"
            disabled
            errorMessage={errors.publishedDate?.message}
            {...methods.register("publishedDate")}
          />
          <FieldInput
            id="address"
            label="Dirección"
            placeholder="Dirección"
            disabled
            errorMessage={errors.address?.message}
            {...methods.register("address")}
          />
          <FieldInput
            id="canon"
            label="Canon de arriendo"
            placeholder="Canon de arriendo"
            defaultValue={property?.property_details[0]?.valor}
            type="number"
            step="any"
            startAdornment={startAdornment}
            errorMessage={errors.canon?.message}
            {...methods.register("canon")}
          />
          {houmers && (
            <FieldSelect
              id="houmerId"
              label="Houmer"
              placeholder="Houmer"
              options={houmers}
              defaultValue={houmerId ?? ""}
              errorMessage={errors.houmerId?.message}
              {...methods.register("houmerId")}
            />
          )}
          <ControlledUserText
            label="Executiva/o"
            id="executive"
            defaultValue={property?.houm_owner ?? null}
            qParams={{ role: "seller" }}
          />
          <FieldInput
            id="max_people_quantity"
            label="Pueden vivir máximo"
            placeholder="5"
            type="number"
            errorMessage={errors.max_people_quantity?.message}
            {...methods.register("max_people_quantity")}
          />
          <FieldInput
            id="street_number"
            label="Número de calle"
            placeholder="Número de calle"
            type="number"
            errorMessage={errors.street_number?.message}
            {...methods.register("street_number")}
          />
          <FieldInput
            id="numero_depto"
            label="Número departamento"
            placeholder="Número departamento"
            errorMessage={errors.numero_depto?.message}
            {...methods.register("numero_depto")}
          />
          <FieldInput
            id="comuna"
            label="Comuna"
            placeholder="Comuna"
            errorMessage={errors.comuna?.message}
            {...methods.register("comuna")}
          />
          {countryCode(property.country) === "co" && (
            <>
              <FieldInput
                id="stratus"
                label="Estrato"
                placeholder="Estrato"
                type="number"
                errorMessage={errors.stratus?.message}
                {...methods.register("stratus")}
              />
              <FieldInput
                id="antiquity"
                label="Antigüedad"
                placeholder="Antigüedad"
                type="number"
                errorMessage={errors.antiquity?.message}
                {...methods.register("antiquity")}
              />
              <FieldInput
                id="state"
                label="Estado"
                placeholder="Estado"
                errorMessage={errors.state?.message}
                {...methods.register("state")}
              />
            </>
          )}
          <p className={classes.infoText}>{t("property-form-info")}</p>
          <FieldInput
            id="parking_number"
            label="Numero del Estacionamiento(s)"
            placeholder="Numero del Estacionamiento(s)"
            errorMessage={errors.parking_number?.message}
            {...methods.register("parking_number")}
          />
          <FieldInput
            id="warehouse_number"
            label="Numero de la Bodega(s)"
            placeholder="Numero de la Bodega(s)"
            errorMessage={errors.warehouse_number?.message}
            {...methods.register("warehouse_number")}
          />
          <FieldInput
            id="pet_bans"
            label="Prohibiciones de Animales"
            placeholder="Prohibiciones de Animales"
            errorMessage={errors.pet_bans?.message}
            {...methods.register("pet_bans")}
          />
          <FieldInput
            id="other_bans"
            label="Otras Prohibiciones"
            placeholder="Otras Prohibiciones"
            errorMessage={errors.other_bans?.message}
            {...methods.register("other_bans")}
          />
          <div className={classes.submitButtonContainer}>
            <Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
              Guardar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Loading>
  );
}

export default PropertyForm;
