import { saveAs } from "file-saver";

import Button from "@houm-com/ui/Button";

import { Receipt } from "../../hooks/useGetReceipts/utils/types";

interface Props {
  receipts: Receipt[];
}

const DownloadReceipt = ({ receipts }: Props) => {
  const openReceipt = (receipt: Receipt) => {
    if (receipt.source === "integrator") return window.open(receipt.url, "_blank");
    return saveAs(receipt.url, receipt.label);
  };

  return (
    <>
      <p className="text-p18 font-bold mb-2">Documentos tributarios</p>
      <div className="flex gap-2 flex-wrap">
        {receipts.map((receipt) => (
          <Button key={receipt.id} size="sm" variant="primary" onClick={() => openReceipt(receipt)}>
            {receipt.label}
          </Button>
        ))}
      </div>
    </>
  );
};

export default DownloadReceipt;
