import { useEffect, useState } from "react";
import classNames from "classnames";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { GridCloseIcon } from "@material-ui/x-grid";

import WriteComment from "Components/Admin/administrator/evaluations/components/WriteComment";
import CustomLoadingButton from "../Buttons/CustomLoadingButton";
import useStyles from "./RejectDesistModalStyles";

interface Reasons {
  Aseguradora?: string[];
  Houm?: string[];
  Propietario?: string[];
  Arrendatario?: string[];
}
interface Props {
  open: boolean;
  onClose: () => void;
  possibleReasons?: { reject?: Reasons; desist?: Reasons };
  possibleAuthors?: string[];
  onSave: (reason: string, author: string, discardType: string) => (comment: string) => void;
  title?: string;
  entity?: string;
  disableOptionChange?: boolean;
  type?: string;
  defaultComment?: string;
}

function RejectDesistModal({
  open,
  onClose,
  possibleReasons,
  possibleAuthors,
  onSave,
  title = "Descartar Contrato",
  entity = "contract",
  disableOptionChange = false,
  type = "reject",
  defaultComment = "",
}: Props) {
  const classes = useStyles();
  const [author, setAuthor] = useState(possibleAuthors[0]);
  const [reason, setReason] = useState("Otro");
  const [discardType, setDiscardType] = useState(type);

  useEffect(() => {
    setDiscardType(type);
  }, [type]);

  const handleChangeAuthor = (e) => {
    setAuthor(e.target.value);
  };

  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };

  const handleChangeDiscardType = (buttonType: string) => () => {
    setDiscardType(buttonType);
  };
  const desistButtonClass =
    discardType === "desist" ? classes.selectedButton : classes.unselectedButton;
  const rejectButtonClass =
    discardType === "reject" ? classes.selectedButton : classes.unselectedButton;

  const writeCommentProps = {
    onSave: onSave(reason, author, discardType),
    buttonText: "Descartar",
    defaultValue: defaultComment,
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      transitionDuration={500}
      PaperProps={{
        style: {
          borderRadius: 15,
        },
      }}>
      <Grid container justifyContent="space-between">
        <DialogTitle>
          {title}
          <div className={classes.subtitle}>
            {entity === "contract"
              ? "Este contrato se irá a rechazados"
              : "Esta evaluación será rechazada/desistida"}
          </div>
        </DialogTitle>
        <DialogActions>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <GridCloseIcon />
          </IconButton>
        </DialogActions>
      </Grid>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}>
          <Typography className={classes.reason}>Autor:</Typography>
          <Select
            value={author}
            onChange={handleChangeAuthor}
            className={classes.select}
            disabled={possibleAuthors.length === 1}>
            {possibleAuthors.map((possibleAuthor) => (
              <MenuItem key={possibleAuthor} value={possibleAuthor}>
                {possibleAuthor}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}>
          <Typography className={classes.reason}>Motivo:</Typography>
          <Select value={reason} onChange={handleChangeReason} className={classes.select}>
            {possibleReasons[discardType][author].map((possibleReason) => (
              <MenuItem key={possibleReason} value={possibleReason}>
                {possibleReason}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mb={3}>
          <Typography className={classes.reasonComment}>Tipo de descarte:</Typography>
          {possibleReasons?.reject && (
            <CustomLoadingButton
              text="Rechazado"
              onClick={handleChangeDiscardType("reject")}
              disabled={disableOptionChange && discardType === "desist"}
              type="outlined"
              buttonClassName={classNames(rejectButtonClass, classes.leftButton)}
            />
          )}
          {possibleReasons?.desist && (
            <CustomLoadingButton
              text="Desistido"
              onClick={handleChangeDiscardType("desist")}
              disabled={disableOptionChange && discardType === "reject"}
              type="outlined"
              buttonClassName={desistButtonClass}
            />
          )}
        </Box>
        <Typography className={classes.reasonComment}>Motivo del descarte:</Typography>
        <WriteComment {...writeCommentProps} />
      </DialogContent>
    </Dialog>
  );
}

export default RejectDesistModal;
