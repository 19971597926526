import { AxiosError } from "axios";
import Swal from "sweetalert2";

import verifikService from "services/verifikService";
import { VerifikModel } from "models/Verifik";

export interface IFormData {
  document: string;
  documentType: string;
  date?: string;
}

const parseDocumentType = {
  2: "CC",
  3: "NIT",
  4: "PA",
  11: "CE",
  12: "PEP",
};

const parseFormData = (data: IFormData) => ({
  doc_type: parseDocumentType[data.documentType],
  doc_number: data.document,
  doc_issue_date: data.date,
});

export const downloadDocument = async (data: IFormData, token: string) => {
  const parseData = parseFormData(data);
  try {
    const { data }: { data: VerifikModel } = await verifikService.get_reports(token, parseData);
    if (data?.error) throw data.error;
    const {
      person_data: {
        document_status: { link_to_pdf: linkPdf },
      },
    } = data;
    if (linkPdf) {
      const link = document.createElement("a");
      link.href = linkPdf;
      link.download = `report.pdf`;
      link.click();
    } else {
      throw new Error("Reporte no encontrado");
    }
  } catch (e) {
    const error = e as AxiosError;
    const { response } = error;
    Swal.fire({
      type: "error",
      title: `Error al obtener reporte`,
      text: `No se pudo obtener el reporte de antecendentes penales para el documento ${
        data.document
      }, error: ${response ?? e}`,
    });
  }
};
