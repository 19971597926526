import { format } from "date-fns";
import currency from "@houm-com/formats/currency";

import { Typography } from "@material-ui/core";

import { MovementBreakdownRecord } from "models/Payments";

import { getLandlordDeposit, getTotalAmount } from "./utils";
import useMovementInfoStyles from "./MovementInfoStyles";

interface DetailProps {
  movement: MovementBreakdownRecord;
}

const MovementInfo = ({ movement }: DetailProps) => {
  const classes = useMovementInfoStyles();

  const isUF = !!movement.uf_value;

  const renderUfValue = () => (
    <Typography component="span" className={classes.details}>
      {` | Valor a la fecha de pago ${format(
        movement.status === "PAID" ? new Date(movement.date) : new Date(),
        "dd-MM-yyyy"
      )} a ${currency(movement.uf_value, movement.currency, { maximumFractionDigits: 0 })}`}
    </Typography>
  );

  return (
    <>
      {movement.country === "cl" && (
        <>
          <Typography className={classes.details}>
            Valor arriendo: {currency(movement.original_amount, movement.currency)}
          </Typography>
          <Typography className={classes.details}>
            Monto de deposito: {getTotalAmount(movement)}
          </Typography>
          <Typography className={classes.details}>
            Día de deposito a propietario: {getLandlordDeposit(movement)}
          </Typography>
        </>
      )}
      <Typography className={classes.details}>ID movimiento: {movement.movement_id}</Typography>
      <Typography className={classes.details}>Comentario: {movement.description}</Typography>
      <Typography className={classes.details}>
        Moneda: {isUF ? "UF" : movement.currency}
        {movement.country === "cl" && isUF && renderUfValue()}
      </Typography>
      <Typography className={classes.details}>Método de pago: {movement.method}</Typography>
    </>
  );
};

export default MovementInfo;
