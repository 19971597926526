import { UseQueryResult, useQuery } from "react-query";

import { OptionTypes } from "Components/Admin/UIComponents/ControlledForm/ControlledList";
import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { CONTRACT_TYPES } from "../utils/constants";

const useGetContractTypes = (country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data }: UseQueryResult<OptionTypes[], Error> = useQuery(
    ["contractTypes"],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getContractTypes(authToken);
      return response?.data?.contract_types?.map(
        (contractType: string) => CONTRACT_TYPES[contractType]
      );
    },
    {
      enabled: country === "cl",
    }
  );

  return { isLoading, error, data };
};

export default useGetContractTypes;
