import { useState } from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { ContractModel } from "models/Contract";

import PenaltyDisclaimer from "./components/PenaltyDisclaimer";
import useContractDetailsStyles from "./ContractDetailsStyles";
import DisplayCard from "./components/DisplayCard";
import FormCard from "./components/FormCard";

const ContractDetails = ({ contract }: { contract: ContractModel | null }) => {
  const classes = useContractDetailsStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleIsEdit = () => setIsEditing((prev) => !prev);

  return (
    <Grid item xs={12}>
      <AdminCard title="Contrato" withSpace={false} showTitleBackground={true}>
        {!contract && <Typography>Contrato no encontrado</Typography>}
        {contract && (
          <Box className={classes.cardContent}>
            {isEditing && <FormCard contract={contract} handleIsEdit={handleIsEdit} />}
            {!isEditing && <DisplayCard contract={contract} handleIsEdit={handleIsEdit} />}
            {contract.property.country === "Colombia" && <PenaltyDisclaimer />}
          </Box>
        )}
      </AdminCard>
    </Grid>
  );
};

export default ContractDetails;
