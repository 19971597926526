import contractFields from "context/closureFormContext/closureFormFields";
import { numberWithCommas } from "utils";

import { ValueProps, filter, ValueEntry } from "./utils";

const PropertyValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.property[valueKey];
  if (filter(displayLabel, isAdmin, country)) return null;

  let displayValue;
  if (value) {
    switch (valueKey) {
      case "rentValue":
      case "commonExpenses":
      case "warranty":
        displayValue = `$${numberWithCommas(value)}`;
        break;
      case "isFlat":
        displayValue = value === "0" ? "Casa" : "Departamento";
        break;
      case "inCondo":
      case "publicDeed":
      case "hasCommonExpenses":
        displayValue = value === "0" ? "No" : "Si";
        break;
      case "isHorizontal":
        displayValue = value === "0" ? "No" : "Si";
        break;
      case "isUrban":
        displayValue = value === "0" ? "Rural" : "Urbano";
        break;
      case "neighborhood":
        if (typeof value === "object") displayValue = value.label;
        break;
      default:
        displayValue = value;
    }
  } else {
    displayValue = null;
  }
  return <ValueEntry label={displayLabel().label(country)} value={displayValue} />;
};

export default PropertyValue;
