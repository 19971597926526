import { makeStyles, Theme } from "@material-ui/core";

const useReservationDetailsStyles = makeStyles<Theme>((theme) => ({
  label: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
}));

export default useReservationDetailsStyles;
