import Drawer from "@material-ui/core/Drawer";

import DocumentsView from "apps/evaluator-admin/components/DocumentsView";
import { Document } from "domains/evaluations/models/Document";

import { classes } from "./DocumentsDrawerStyles";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  documents: Document[];
  memberName: string;
  evaluationMemberId: string;
}

const DocumentsDrawer = ({
  open,
  onHandleClose,
  documents,
  memberName,
  evaluationMemberId,
}: Props) => (
  <Drawer
    anchor="bottom"
    open={open}
    onClose={onHandleClose}
    variant="persistent"
    classes={{
      paperAnchorBottom: classes.drawerPaperAnchorBottom,
      docked: !open && classes.drawerDocked,
      paper: classes.drawerPaper,
    }}>
    <div className={classes.drawerHeader}>
      <h2 className={classes.drawerTitle}>Documents {memberName}</h2>
      <button onClick={onHandleClose}>Cerrar</button>
    </div>
    <div className={classes.drawerContent}>
      <DocumentsView documents={documents} evaluationMemberId={evaluationMemberId} />
    </div>
  </Drawer>
);

export default DocumentsDrawer;
