import { TextField } from "@material-ui/core";

import useRenewalCustomSelectStyles from "./RenewalCustomSelectStyles";

interface Props {
  id: any;
  name: string;
  setValue: any;
  value: string;
  disabled: boolean;
  defValue: string;
  listOptions: any;
  keyValue: string;
  title: string;
  valid: boolean;
  defaultValue?: string | number;
}

function BankAccountSelect({
  id,
  name,
  setValue,
  value,
  defValue,
  listOptions,
  keyValue,
  title,
  valid,
  defaultValue,
}: Props) {
  const classes = useRenewalCustomSelectStyles();

  if (!valid) return <div />;

  return (
    <div className={classes.inputContainer}>
      <h6 className={classes.selectTitle}>{title}</h6>
      <TextField
        select
        id={id}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        defaultValue={defaultValue}
        name={name}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        autoComplete="off"
        InputProps={{
          classes: {
            notchedOutline: classes.noBorder,
            input: classes.customTextField,
          },
        }}
        SelectProps={{
          native: true,
        }}>
        <option disabled value="" selected>
          {defValue}
        </option>
        {listOptions.map((item) => (
          <option
            className={classes.formSelectItem}
            value={item[`${keyValue}`]}
            key={item[`${keyValue}`]}>
            {item.name}
          </option>
        ))}
      </TextField>
    </div>
  );
}

export default BankAccountSelect;
