import { makeStyles, Theme } from "@material-ui/core";

const useDataWrapperStyles = makeStyles((theme: Theme) => ({
  dataWrapperRoot: {
    paddingRight: theme.spacing(7),
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  value: {
    padding: theme.spacing(0.5, 1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
  },
  inputLabel: {
    fontWeight: 700,
  },
  input: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
  },
}));

export default useDataWrapperStyles;
