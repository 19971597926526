import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldTextArea from "@houm-com/ui/FieldTextArea";
import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";

import { setCurrentTab } from "context/plansContext/plansActions";
import { usePlans } from "context/plansContext/plansContext";
import useGetMandateTypes from "hooks/usePlans/useGetMandateTypes";
import useGetTemplates from "hooks/usePlans/useGetTemplates";
import { IPlanCategory } from "models/Plan";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

import { templateOptions } from "../utils";
import PlanFormProps from "../../pages/PlansForm/types";
import classes from "./PlanDataStyles";

interface Props {
  hasManagement: boolean;
  isDisabled?: boolean;
  country: Locale;
  planCategories: IPlanCategory[];
}

export default function PlanData({
  hasManagement,
  isDisabled = false,
  country,
  planCategories,
}: Props) {
  const { state, dispatch } = usePlans();
  const { mandateTypes } = useGetMandateTypes(country);
  const { templates } = useGetTemplates();
  const methods = useFormContext<PlanFormProps>();
  const { errors = {} } = methods.formState;

  useEffect(() => {
    if (!isDisabled) {
      if (!hasManagement) {
        methods.setValue("duration", 0);
        methods.setValue("adminCommission", "0");
        methods.setValue("guaranteedMonths", 0);
      } else if (!(state.planData.duration && state.planData.adminCommission)) {
        methods.setValue("duration", 0);
        methods.setValue("adminCommission", "");
      }
    }
  }, [hasManagement]);

  const planCategoriesOptions = useMemo(
    () =>
      planCategories.map((category) => ({
        label: category.description,
        value: category.id,
      })),
    [planCategories]
  );

  const mandateTypesOptions = useMemo(
    () =>
      mandateTypes.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [mandateTypes]
  );

  const percentageAdornment = () => <div className={classes.percentageAdornment}>%</div>;

  return (
    <div className={classes.container}>
      <div className={classes.firstCol}>
        <div className={classes.firstColMargin}>
          <FieldInput
            id="name"
            label="Nombre del plan"
            placeholder="Ingresar"
            defaultValue={state.planData.name}
            disabled={isDisabled}
            errorMessage={errors.name?.message}
            {...methods.register("name")}
          />
        </div>
        <div className={classes.firstCol}>
          <FieldSelect
            id="contractTemplate"
            label="Asociar template"
            placeholder="Seleccione un template"
            defaultValue={state.planData.contractTemplate ?? ""}
            options={templateOptions(templates, countryCode(state.planData.country))}
            className={classes.selection}
            errorMessage={errors.contractTemplate?.message}
            {...methods.register("contractTemplate")}
          />
        </div>
        <FieldTextArea
          id="description"
          label="Descripción del plan"
          placeholder="Ingresar"
          defaultValue={state.planData.description}
          className={classes.descriptionInput}
          disabled={isDisabled}
          errorMessage={errors.description?.message}
          {...methods.register("description")}
        />
      </div>
      <div className={classes.secondCol}>
        <div className={classes.secondColDivisionContainer}>
          <div className={classes.secondColItem}>
            <FieldSwitchSelector
              id="isActive"
              label="Estado"
              labelPosition="top"
              defaultValue={state.planData.isActive ? "yes" : "no"}
              options={[
                {
                  label: "Activo",
                  value: "yes",
                },
                {
                  label: "Inactivo",
                  value: "no",
                },
              ]}
              disabled={isDisabled}
              errorMessage={errors.isActive?.message}
              {...methods.register("isActive")}
            />
          </div>
          <div className={classes.secondColItem}>
            <FieldInput
              id="landlordBrokerage"
              label="% Corretaje propietario"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.landlordBrokerage}
              type="number"
              disabled={isDisabled}
              onClick={() => dispatch(setCurrentTab(1, !state.currentTab.isActive))}
              errorMessage={errors.landlordBrokerage?.message}
              {...methods.register("landlordBrokerage", { valueAsNumber: true })}
            />
          </div>
          <div className={classes.secondColItem}>
            <FieldInput
              id="landlordBrokerageClosing"
              label="Cierre propietario"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.landlordBrokerageClosing}
              type="number"
              disabled={isDisabled}
              onClick={() => dispatch(setCurrentTab(4, !state.currentTab.isActive))}
              errorMessage={errors.landlordBrokerageClosing?.message}
              {...methods.register("landlordBrokerageClosing", { valueAsNumber: true })}
            />
          </div>
        </div>

        <div className={classes.secondColDivisionContainer}>
          <div className={classes.secondColItem}>
            <FieldInput
              id="duration"
              name="duration"
              label="Duración (meses)"
              placeholder="Ingresar"
              defaultValue={state.planData.duration ?? 0}
              type="number"
              disabled={isDisabled || !hasManagement}
              onClick={() => dispatch(setCurrentTab(4, !state.currentTab.isActive))}
              errorMessage={errors.duration?.message}
              {...methods.register("duration", { valueAsNumber: true })}
            />
          </div>

          <div className={classes.secondColItem}>
            <FieldInput
              id="tenantBrokerage"
              label="% Corretaje arrendatario"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.tenantBrokerage}
              type="number"
              disabled={isDisabled}
              onClick={() => dispatch(setCurrentTab(2, !state.currentTab.isActive))}
              errorMessage={errors.tenantBrokerage?.message}
              {...methods.register("tenantBrokerage", { valueAsNumber: true })}
            />
          </div>
          <div className={classes.secondColItem}>
            <FieldInput
              id="tenantBrokerageClosing"
              label="Cierre arrendatario"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.tenantBrokerageClosing}
              type="number"
              disabled={isDisabled}
              onClick={() => dispatch(setCurrentTab(3, !state.currentTab.isActive))}
              errorMessage={errors.tenantBrokerageClosing?.message}
              {...methods.register("tenantBrokerageClosing", { valueAsNumber: true })}
            />
          </div>
        </div>
        <div className={classes.secondColDivisionContainer}>
          <div className={classes.secondColItem}>
            <FieldInput
              id="adminCommission"
              label="Comisión de administración"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.adminCommission ?? ""}
              type="number"
              disabled={isDisabled || !hasManagement}
              form="novalidateform"
              onClick={() => dispatch(setCurrentTab(0, !state.currentTab.isActive))}
              errorMessage={errors.adminCommission?.message}
              {...methods.register("adminCommission", { valueAsNumber: true })}
            />
          </div>
          <div className={classes.secondColItem}>
            <FieldSelect
              id="category"
              label="Categoría"
              placeholder="Seleccione una categoría"
              defaultValue={state.planData.category?.id ?? ""}
              options={planCategoriesOptions}
              className={classes.selection}
              disabled={isDisabled}
              errorMessage={errors.category?.message}
              {...methods.register("category")}
            />
          </div>
        </div>
        <div className={classes.secondColDivisionContainer}>
          <div className={classes.secondColItem}>
            <FieldSelect
              id="paymentOption"
              label="Opción de pago"
              placeholder="Seleccione una opción"
              defaultValue={state.planData.paymentOption?.id ?? ""}
              options={mandateTypesOptions}
              className={classes.selection}
              disabled={isDisabled}
              errorMessage={errors.paymentOption?.message}
              {...methods.register("paymentOption")}
            />
          </div>
          <div className={classes.secondColItem}>
            <FieldInput
              id="guaranteedMonths"
              label="Meses garantizados"
              placeholder="Ingresar"
              startAdornment={percentageAdornment}
              defaultValue={state.planData.guaranteedMonths ?? 0}
              type="number"
              disabled={isDisabled || !hasManagement}
              errorMessage={errors.guaranteedMonths?.message}
              {...methods.register("guaranteedMonths", { valueAsNumber: true })}
            />
          </div>
          {country === "co" && (
            <div className={classes.secondColItem}>
              <FieldInput
                id="minimumAdminFee"
                label="Minimo comisión de administración"
                placeholder="Ingresar"
                startAdornment={percentageAdornment}
                defaultValue={state.planData.guaranteedMonths ?? 0}
                type="number"
                disabled={isDisabled}
                errorMessage={errors.minimumAdminFee?.message}
                {...methods.register("minimumAdminFee", { valueAsNumber: true })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
