import { Theme, makeStyles } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

const useEditPaymentDateFormStyles = makeStyles((theme: Theme) => ({
  formContent: {
    width: "100%",
  },
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
  textInput: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
  },
}));

export default useEditPaymentDateFormStyles;
