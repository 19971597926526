/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from "react";

import { useGetToken } from "hooks";
import TableLayout from "../../../UIComponents/TableLayout";
import { useDashboard } from "context/controlTower/controlTowerContext";
import Header from "Components/Admin/administrator/usersLeads/components/Header";
import Table from "./AppraisersTable";
import { getAppraisers } from "context/controlTower/controlTowerActions";

export default function UsersTable() {
  const { getToken } = useGetToken();
  const { dispatch } = useDashboard();

  useEffect(() => {
    getToken().then((authToken) => getAppraisers(authToken, dispatch));
  }, []);

  return (
    <TableLayout>
      <Header title="Mostradores y Fotógrafos" show={false} />
      <TableLayout.Table>
        <Table />
      </TableLayout.Table>
    </TableLayout>
  );
}
