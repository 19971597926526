import { cn } from "@houm-com/ui/utils";
import { Locale } from "models/Countries";
import classes from "./TableHeadStyles";

interface TableHeadProps {
  country: Locale;
}

export default function TableHead({ country }: TableHeadProps) {
  return (
    <thead>
      <tr className={classes.container}>
        <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
          <div className={classes.column}>IDs</div>
        </th>
        <th className={cn(classes.columnContainer, classes.bigColumnContainer)}>
          <div className={classes.column}>Arrendatario</div>
        </th>
        <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
          <div className={classes.column}>Cobranza</div>
        </th>
        <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
          <div className={classes.column}>Plan</div>
        </th>
        <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
          <div className={classes.column}>Meses Garantizados</div>
        </th>
        <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
          <div className={classes.column}>Deuda</div>
        </th>
        {country !== "mx" && (
          <>
            <th className={cn(classes.columnContainer, classes.bigColumnContainer)}>
              <div className={classes.column}>Aseguradora</div>
            </th>
            <th className={cn(classes.columnContainer, classes.normalColumnContainer)}>
              <div className={classes.lastColumn}></div>
            </th>
          </>
        )}
      </tr>
    </thead>
  );
}
