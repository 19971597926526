import NewAppraiserIndex from "./NewAppraiserIndex";
import { DashboardProvider } from "context/controlTower/controlTowerContext";
import { SnackbarProvider } from "notistack";

export default function NewAppraiser() {
  return (
    <DashboardProvider>
      <SnackbarProvider>
        <NewAppraiserIndex />
      </SnackbarProvider>
    </DashboardProvider>
  );
}
