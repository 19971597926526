import { useCallback } from "react";
import { SnackbarProvider } from "notistack";

import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import { PropertyProvider } from "context/propertyContext/propertyContext";
import { useAlert } from "context/alertContext/alertContext";

import AdminPropertyShow from "./AdminPropertyShow";

import { clearAlertInfo } from "context/alertContext/alertActions";

function AdminPropertyShowIndex() {
  const {
    state: { alertInfo },
    dispatch,
  } = useAlert();

  const handleSnackbarClose = useCallback(() => {
    dispatch(clearAlertInfo());
  }, []);

  return (
    <PropertyProvider>
      <SnackbarProvider>
        <AdminPropertyShow />
        <DesignSystemSnackbar
          type={alertInfo.type}
          open={alertInfo.open}
          onClose={handleSnackbarClose}
          head={alertInfo.head}
          body={alertInfo.body}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </SnackbarProvider>
    </PropertyProvider>
  );
}

export default AdminPropertyShowIndex;
