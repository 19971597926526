import { CircularProgress } from "@material-ui/core";

import LeadAvailabilityDetails from "Components/Admin/administrator/leads/leadProfile/components/AvailabilityDetails";
import ScheduleGenerator from "Components/Admin/administrator/leads/leadProfile/components/ScheduleGenerator";
import useStyles from "Components/Admin/administrator/leads/leadProfile/components/LeadAvailabilityFormStyles";
import { useAvailability } from "context/availabilityContext/availabilityContext";

function LeadAvailability() {
  const { state } = useAvailability();
  const classes = useStyles();

  if (state.propertyInfo.address)
    return (
      <>
        <LeadAvailabilityDetails />
        {!state.propertyInfo.houmbox && <ScheduleGenerator />}
      </>
    );
  else
    return (
      <div className={classes.loading}>
        <CircularProgress color="primary" />
      </div>
    );
}

export default LeadAvailability;
