import { Typography } from "@material-ui/core";

import { ProgressCardProps } from "context/controlTower/controlTowerTypes";
import useProgressCardStyles from "./ProgressCardStyles";

function ProgressCard({ title, progress, Icon }: ProgressCardProps) {
  const classes = useProgressCardStyles();

  return (
    <>
      <Typography className={classes.valueInfo} color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography className={classes.title} color="textSecondary">
        <Icon className={classes.icon} /> {progress}
      </Typography>
    </>
  );
}
export default ProgressCard;
