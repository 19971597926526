const NoncomplianceText = "No cumple con todos los requisitos Houm/";

const evaluationDiscardInfo = {
  authors: ["Propietario", "Aseguradora", "Arrendatario", "Houm"],
  desist: {
    Aseguradora: ["No pasó el filtro de la aseguradora", "Otro"],
    Houm: [
      "Duplicidad de reserva",
      "Error en publicación Houm",
      "Propiedad ya tiene a otro postulante aprobado que pagó reserva",
      "Propiedad arrendada con Houm a otra persona",
      "Otro",
    ],
    Propietario: [
      "Propietario rechaza perfil",
      "Propietario arriendó el inmueble con otro corredor",
      "Desiste por problemas personales/salud/económicos",
      "Propietario desiste del servicio",
      "Propietario arrienda a un familiar",
      "Incumplimiento de tiempos Houm",
      "Propietario cambia condiciones",
      "Propietario decide vender",
      "Otro",
    ],
    Arrendatario: [
      "No cargó documentos solicitados",
      "Desiste por problemas personales/salud/económicos",
      "Incumplimiento de tiempos Houm",
      "Consiguió otra propiedad",
      "Propietario cambia condiciones",
      "Reservó por error",
      "Reservó dos veces",
      "Postulante no estará en el contrato",
      "No cumple requisitos de Houm",
      "Otro",
    ],
  },
  reject: {
    Aseguradora: ["Rechazado por la aseguradora", "Rechazado Codeudor por la aseguradora", "Otro"],
    Houm: [
      `${NoncomplianceText}3X`,
      `${NoncomplianceText}Postula con boletH honorarios`,
      `${NoncomplianceText}Sólo tiene un F22`,
      `${NoncomplianceText}Contrato a plazo menor a un año`,
      `${NoncomplianceText}Incongruencia en documentos`,
      `${NoncomplianceText}Postula como empresa`,
      `${NoncomplianceText}No envía todas las liquidaciones`,
      `${NoncomplianceText}No envía contrato`,
      `${NoncomplianceText}No envía AFP`,
      "Otro",
    ],
    Propietario: ["Otro"],
    Arrendatario: ["Otro"],
  },
};

export { evaluationDiscardInfo };
