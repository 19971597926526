import { useQuery, UseQueryResult } from "react-query";

import { IExitProcessResponse } from "services/contractService/types";
import contractService from "services/contractService";
import { useGetToken } from "hooks";

const useGetExitProcess = (contractId: number) => {
  const { getToken } = useGetToken();

  const { data, isLoading, isError, isSuccess }: UseQueryResult<IExitProcessResponse, Error> =
    useQuery<IExitProcessResponse, Error>(
      ["exitProcess", contractId],
      async () => {
        const authToken = await getToken();
        const exitProcessResponse = await contractService.getExitProcess(contractId, authToken);
        return exitProcessResponse.data;
      },
      {
        enabled: !!contractId,
        refetchOnMount: true,
      }
    );

  return {
    exitProcess: data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useGetExitProcess;
