import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    width: "100%",
  },
}));
