import { LandlordVipList } from "./landlordVipTypes";

export function parseLandlordVip(data: any): LandlordVipList {
  return {
    id: `${data.email}-${data.administration_executive?.email}`,
    activeManagedProperties: data?.active_managed_properties,
    executiveName: data?.administration_executive?.name,
    executiveEmail: data?.administration_executive?.email,
    landlordEmail: data?.email,
    landlordName: `${data?.name} ${data?.last_name}`,
    landlordPhone: data?.phone,
  };
}
