import moment from "moment";
import { capitalizeString } from "utils";

export const nextFourTeenDays = (timezone: string) => {
  const fourteenDaysInUI = [];
  const fourteenDaysInMomentFormat = [];
  for (let i = 0; i < 14; i++) {
    fourteenDaysInUI.push(
      capitalizeString(
        moment
          .utc()
          .tz(timezone)
          .add(i + 1, "days")
          .format("dddd DD/MM")
      )
    );
    fourteenDaysInMomentFormat.push(
      moment
        .utc()
        .tz(timezone)
        .add(i + 1, "days")
        .format("YYYY-MM-DD")
    );
  }
  return { fourteenDaysInUI, fourteenDaysInMomentFormat };
};

export const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};
