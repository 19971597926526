import { useState } from "react";
import { alert } from "libs/alerts";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Button from "@houm-com/ui/Button";

import Grid from "@material-ui/core/Grid";

import DesignCardContent from "Components/Admin/administrator/subscriptions/components/DesignCardContent";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import contractService from "services/contractService";
import { useGetToken } from "hooks";

import useObservationFormStyles from "./ObservationFormStyles";
import { ObservationType } from "./utils/types";

interface IData {
  observations: string;
}

interface Props {
  contractId: number;
  observationType: ObservationType;
  observation: string;
}

function ObservationForm({ contractId, observationType, observation }: Props) {
  const classes = useObservationFormStyles();
  const { getToken } = useGetToken();
  const [loading, setLoading] = useState(false);

  const methods = useForm<IData>({
    defaultValues: {
      observations: observation,
    },
  });

  const updateObservations = async (observations: string, authToken: string) => {
    setLoading(true);
    try {
      const data =
        observationType === "landlord" ? { landlord_observations: observations } : { observations };
      await contractService.update(contractId, data, authToken);
      alert.success({
        message: "Observaciones actualizadas.",
      });
    } catch (e) {
      alert.error({
        message: `Error al actualizar las observaciones: ${e}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<IData> = (data) => {
    getToken().then((authToken) => updateObservations(data.observations, authToken));
  };

  return (
    <DesignCardContent
      title={`Observaciones ${observationType === "landlord" ? "propietario" : "arrendatario"}`}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
          <Grid item container alignItems="flex-end" spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                label="Observaciones"
                id="observations"
                textFieldProps={{
                  placeholder: "Escribir observación...",
                  InputProps: {
                    classes: {
                      root: classes.descriptionInput,
                    },
                  },
                  multiline: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                type="submit"
                size="md"
                isLoading={loading}
                disabled={loading}>
                Actualizar observaciones
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DesignCardContent>
  );
}

export default ObservationForm;
