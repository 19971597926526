import { SimilarProperty } from "models";
import { SimilarHistoricProperties } from "models/Calculator";

export enum ActionType {
  SET_SIMILAR_HISTORIC_PROPERTIES,
  SET_SIMILAR_PROPERTIES,
  SET_LOADING,
}

export interface State {
  similarHistoricProperties: SimilarHistoricProperties[];
  similarProperties: SimilarProperty[];
  loading: boolean;
}

interface SetSimilarHistoricProperties {
  type: typeof ActionType.SET_SIMILAR_HISTORIC_PROPERTIES;
  similarHistoricProperties: SimilarHistoricProperties[];
}

interface setSimilarProperties {
  type: typeof ActionType.SET_SIMILAR_PROPERTIES;
  similarProperties: SimilarProperty[] | undefined;
}
interface setLoading {
  type: typeof ActionType.SET_LOADING;
  loading: boolean;
}

export type Action = SetSimilarHistoricProperties | setLoading | setSimilarProperties;

export type Dispatch = (action: Action) => void;
