import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

const useFormDate = (isEdit: boolean) => {
  const { watch, setValue } = useFormContext();

  const initDateValue = watch("initDate");

  useEffect(() => {
    if (!isEdit) {
      const initDate = initDateValue ?? new Date();
      const tomorrow = new Date();
      tomorrow.setDate(initDate.getDate() + 1);
      setValue("endDate", tomorrow);
    }
  }, [initDateValue, setValue, isEdit]);

  return {
    initDateValue,
  };
};

export default useFormDate;
