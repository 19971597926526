import { Theme, makeStyles } from "@material-ui/core/styles";

const useLandlordCardStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1.4),
  },
  divider: {
    width: "95%",
  },
  text: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  cardSize: {
    height: "100%",
  },
}));

export default useLandlordCardStyles;
