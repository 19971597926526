import { Theme, makeStyles } from "@material-ui/core";

const useCircularStepperStyles = makeStyles<Theme>((theme) => ({
  containerProgress: {
    position: "relative",
  },
  containerSteps: {
    marginLeft: theme.spacing(1),
  },
  steps: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
    textAlign: "center",
    position: "absolute",
    top: 11,
    left: 11,
  },
  stepText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
  },
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.primary.main,
  },
  top: {
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
    color: "#F9E8E5",
  },
}));

export default useCircularStepperStyles;
