import { useEffect } from "react";

import { Typography } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";

import { useGetToken } from "hooks";
import { StringOption } from "models/Option";
import { CountryUpperCase } from "models/Countries";
import { countryList } from "utils/countryCode";

import { useMacrozones } from "context/macrozones/macrozonesContext";
import { useMap } from "context/map/mapContext";
import { LoaderType } from "context/macrozones/macrozonesTypes";

import { ControlledAutocomplete } from "Components/Admin/UIComponents/ControlledForm";
import { getMacrozonesByCountry, updateMacrozones } from "context/macrozones/macrozonesActions";

import useHeaderSelectorStyles from "./HeaderSelectorStyles";

interface Fields {
  country: StringOption;
  city: StringOption[];
}

function HeaderSelector() {
  const { getToken } = useGetToken();
  const classes = useHeaderSelectorStyles();
  const {
    state: { cities, macrozonesByCity, loaders },
    dispatch,
  } = useMacrozones();
  const {
    state: { map },
  } = useMap();

  const methods = useForm<Fields>({
    defaultValues: {
      country: countryList[0],
      city: [],
    },
  });

  const currentCountry = methods.watch("country")?.value as CountryUpperCase;
  const currentCities = methods.watch("city");

  useEffect(() => {
    const updateCities = async () => {
      const authToken = await getToken();
      methods.setValue("city", []);
      getMacrozonesByCountry(currentCountry, dispatch, authToken);
    };
    if (currentCountry) {
      updateCities();
    }
  }, [currentCountry]);

  useEffect(() => {
    updateMacrozones(dispatch, currentCities, macrozonesByCity);

    if (currentCities?.length > 0) {
      const city = macrozonesByCity[currentCities[currentCities.length - 1].value];
      map.flyTo({
        zoom: 9,
        center: city[0].polygon[0][0],
      });
    }
  }, [currentCities]);

  const commonProps = {
    disableUnderline: true,
    wrapperPadding: false,
    inputProps: {
      className: classes.input,
    },
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.selectInput,
      },
    },
  };

  return (
    <>
      <Typography className={classes.title}> Macrozonas </Typography>
      <FormProvider {...methods}>
        <form>
          <div className={classes.inputsContainer}>
            <ControlledAutocomplete
              id="country"
              label="País"
              options={countryList}
              labelClassName={classes.inputLabel}
              textFieldProps={commonProps}
            />
            <ControlledAutocomplete
              multiple
              id="city"
              label="Ciudad"
              emptyOption={false}
              options={cities || []}
              labelClassName={classes.inputLabel}
              inputTagsNode={() => null}
              textFieldProps={{
                disabled: loaders[LoaderType.GET_MC],
                ...commonProps,
              }}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
}
export default HeaderSelector;
