import { makeStyles, Theme } from "@material-ui/core";

const useLetterOfIntentIndexStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: theme.spacing(1, 5),
  },
  personsContainer: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  rightContainer: {
    width: "50%",
  },
  loading: {
    marginTop: theme.spacing(5),
  },
}));
export default useLetterOfIntentIndexStyles;
