/* eslint-disable import/prefer-default-export */
export const fromToOptions = (from: number, to: number, object: boolean = true) => {
  const array = [];
  for (let i = from; i <= to; i++) {
    if (object) {
      array.push({ value: i, label: i.toString() });
    } else {
      array.push(i.toString());
    }
  }
  return array;
};
