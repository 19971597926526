import { makeStyles, Theme } from "@material-ui/core";

const useRepairSelectionStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  ctaContainer: {
    paddingBottom: theme.spacing(2),
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 0,
  },
  spacer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
}));

export default useRepairSelectionStyles;
