import { makeStyles } from "@material-ui/core";

const useLeadFormStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
  secondRow: {
    marginTop: theme.spacing(3),
  },
  textArea: {
    padding: theme.spacing(1, 1.5),
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    borderRadius: "20px",
  },
  textAreaLabel: {
    marginBottom: theme.spacing(1),
  },
  underline: {
    "&&&:before": {
      borderWidth: "0.6px",
      borderColor: theme.palette.grey[900],
    },
    "&&:after": {
      borderColor: theme.palette.grey[900],
      borderWidth: "0.6px",
    },
  },
  buttonContainer: {
    display: "flex",
    height: "100%",
  },
  saveButton: {
    textTransform: "none",
    fontWeight: 700,
    height: "fit-content",
    borderRadius: "100px",
    alignSelf: "flex-end",
    padding: theme.spacing(3 / 4, 4),
  },
}));

export default useLeadFormStyles;
