import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import demandService from "services/demandService";
import { ActionType, Action, Dispatch, OptionList, City } from "./listTypes";

import {
  ResLostReason,
  ResActivityNames,
  ResCities,
  ResFinancial,
  Deal,
  ResOrigins,
} from "models/DemandLead";
import { Paginate } from "models/ResponseWithPaginate";
import { Country, CountryUpperCase, Locale } from "models/Countries";
import { getFullResults } from "Components/Admin/administrator/demand/utils/fullPaginationRequest";

export const setExecutives = (executives: OptionList[]): Action => ({
  type: ActionType.SET_EXECUTIVES,
  executives,
});

export const setOrigins = (origins: OptionList[]): Action => ({
  type: ActionType.SET_ORIGINS,
  origins,
});

export const setLostReasons = (lostReasons: OptionList[]): Action => ({
  type: ActionType.SET_LOST_REASONS,
  lostReasons,
});

export const setActivityNames = (activityNames: OptionList[]): Action => ({
  type: ActionType.SET_ACTIVITY_NAMES,
  activityNames,
});

export const setCities = (cities: City[]): Action => ({
  type: ActionType.SET_CITIES,
  cities,
});

export const setFinancialTypes = (financialTypes: OptionList[]): Action => ({
  type: ActionType.SET_FINANCIAL_TYPES,
  financialTypes,
});

export const getExecutives = async (
  dispatch: Dispatch,
  country: Locale | Country | CountryUpperCase | "",
  token: string,
  deal: Deal
) => {
  try {
    const res = await getFullResults(country, token, deal);
    const parsedExecutives = res.map(({ name, last_name, id }) => ({
      label: `${name} ${last_name}`,
      value: id,
    }));
    dispatch(setExecutives(parsedExecutives));
  } catch {
    Swal.fire("Error", "No se han podido obtener los ejecutivos.", "error");
  }
};

export const getOrigins = async (
  dispatch: Dispatch,
  country: Country | CountryUpperCase | "",
  token: string
) => {
  try {
    const res = (await demandService.getOrigins(country, token)) as AxiosResponse<ResOrigins>;
    const parsedOrigins = res.data.lead_origins.map((item) => ({
      label: item,
      value: item,
    }));
    dispatch(setOrigins(parsedOrigins));
  } catch {
    Swal.fire("Error", "No se han podido obtener los origenes.", "error");
  }
};

export const getLostReasons = async (dispatch: Dispatch, token: string) => {
  try {
    const res = (await demandService.getLostReasons(token)) as AxiosResponse<
      Paginate<ResLostReason>
    >;
    const parsedReaons = res.data.results.map(({ reason_name, id }) => ({
      label: reason_name,
      value: id,
    }));
    dispatch(setLostReasons(parsedReaons));
  } catch {
    Swal.fire("Error", "No se han podido obtener las razones de rechazar al lead.", "error");
  }
};

export const getActivityNames = async (dispatch: Dispatch, token: string) => {
  try {
    const res = (await demandService.getActivityNames(token)) as AxiosResponse<
      Paginate<ResActivityNames>
    >;
    const parsedAcitivityNames = res.data.results.map(({ activity_name, id }) => ({
      label: activity_name,
      value: id,
    }));
    dispatch(setActivityNames(parsedAcitivityNames));
  } catch (error) {
    console.error(error);
  }
};

export const getFinancialTypes = async (dispatch: Dispatch, token: string, locale: Locale) => {
  try {
    const pageSizeValue = 50;
    const res = (await demandService.getFinancialTypes(
      token,
      pageSizeValue,
      locale
    )) as AxiosResponse<Paginate<ResFinancial>>;
    const parsedFinancialTypes = res.data.results
      .filter((item) => item.country === locale)
      .map(({ name, id }) => ({
        label: name,
        value: id,
      }));
    dispatch(setFinancialTypes(parsedFinancialTypes));
  } catch (error) {
    Swal.fire("Error", "No se han podido obtener los tipos de financiamiento.", "error");
  }
};

export const getCities = async (dispatch: Dispatch, token: string, locale: Locale) => {
  try {
    const pageSizeValue = 30;
    const res = (await demandService.getCities(
      token,
      pageSizeValue,
      locale
    )) as AxiosResponse<ResCities>;
    const parsedCities: City[] = res.data.cities.map(({ city, city_id, communes }) => ({
      label: city,
      value: city_id,
      communes: communes.map(({ commune }) => ({
        value: commune,
        label: commune,
      })),
    }));
    dispatch(setCities(parsedCities));
  } catch (error) {
    Swal.fire("Error", "No se han podido obtener las ciudades.", "error");
  }
};
