import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";

import { ReactComponent as ClipIcon } from "assets/icons/clip.svg";
import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  ControlledFileUpload,
  ControlledNumber,
} from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";

import { GGCCInfo } from "../../../utils/types";
import useCreateCommonExpenses from "../../../hooks/useCreateCommonExpenses";
import { otherSchema, requiredText } from "./utils/validationSchema";
import useOtherCompanyFormStyles from "./OtherCompanyFormStyles";
import { parseFormData } from "./utils/parsers";
import { IFormData } from "./utils/types";

interface Props {
  handleClose: () => void;
  propertyId: number;
  previousData?: GGCCInfo;
}

const OtherCompanyForm = ({ handleClose, propertyId, previousData }: Props) => {
  const classes = useOtherCompanyFormStyles();
  const { mutate, isLoading } = useCreateCommonExpenses(!!previousData, handleClose);

  const methods = useForm<IFormData>({
    resolver: yupResolver(otherSchema()),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IFormData> = (formData) => {
    mutate(parseFormData(formData, propertyId));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledTextField
              label="Correo"
              id="email"
              defaultValue={previousData?.email || ""}
              textFieldProps={{
                InputProps: {
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "Ingresar",
                },
              }}
              wrapperPadding={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledNumber
              label="Monto de deuda vigente"
              id="currentDebt"
              defaultValue={previousData?.currentDebt || ""}
              textFieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "000.0",
                },
                type: "number",
              }}
              wrapperPadding={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledKeyboardDatePicker
              id="dueDate"
              label="Fecha de vencimiento"
              defaultValue={previousData?.dueDate || new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
              }}
              disableUnderline
              inputClassName={classes.pickerInput}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledNumber
              label="Monto de deuda morosa"
              id="aggregateDebt"
              defaultValue={previousData?.aggregateDebt || ""}
              textFieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "000.0",
                },
                type: "number",
              }}
              wrapperPadding={false}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledFileUpload
              allowedMimeTypes={["image/jpeg", "image/png", "application/pdf"]}
              id="voucher"
              label="Comprobante de pago"
              labelClassName={classes.voucherLabel}
              text="Subir archivo"
              icon={<ClipIcon className={classes.voucherIcon} />}
              buttonClassName={classes.voucherButton}
            />
            <Typography className={classes.voucherHelper}>
              Formato de archivos permitidos: JPEG, JPG, PNG y PDF
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.buttons}>
          <DesignSystemButton
            variant="secondary"
            size="small"
            label="Cancelar"
            onClick={handleClose}
            buttonClassName={classes.cancelButton}
          />
          <DesignSystemButton
            variant="primary"
            size="small"
            label="Guardar"
            type="submit"
            isLoading={isLoading}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default OtherCompanyForm;
