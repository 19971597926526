import { IconButton } from "@material-ui/core";
import { IoTrashOutline } from "@houm-com/ui-icons";

import ProtectedComponent from "domains/auth/components/ProtectedComponent";

interface Props {
  onHandleClick: () => void;
}

const ButtonDelete = ({ onHandleClick }: Props) => (
  <ProtectedComponent scope="roles" strategy="any" require={["super_admin", "management_admin"]}>
    <IconButton
      size="small"
      className="!bg-[red] flex justify-center items-center h-fit"
      onClick={onHandleClick}
      id="delete-penalty">
      <IoTrashOutline className="w-3 h-3" color="#FFF" />
    </IconButton>
  </ProtectedComponent>
);

export default ButtonDelete;
