import { IPromotion } from "models/Promotions";
import { PromotionsResponse } from "domains/promotion/service/types";

const getObjRenamed = (months: Object, keyValue: string) =>
  Object.entries(months).reduce((acc, [key, value]) => {
    const newKey = `${keyValue}${key.charAt(0).toUpperCase()}${key.slice(1)}`.replace("_", "");
    acc[newKey] = value;
    return acc;
  }, {});

export const parserPromotions = (promotion: PromotionsResponse): IPromotion => ({
  id: promotion.id,
  initDate: promotion.start_date,
  endDate: promotion.end_date,
  createdAt: new Date(promotion.created_at),
  owner: promotion.incharge,
  promotionName: promotion.name,
  promotionStock: promotion.total_promotions,
  target: promotion.objective,
  description: promotion.comment,
  link: promotion.term_and_conditions_link,
  promotionCode: promotion.code,
  stock: promotion.stock,
  active: promotion.active,
  country: promotion.country,
  cities: promotion.cities,
  discountType: promotion.discount_type,
  adminCommission: {
    adminCommissionFlag: promotion.management_fee?.active === true ? "yes" : "no",
    adminCommissionMonthQuantity: promotion.management_fee?.months
      ? Object.keys(promotion.management_fee.months).length
      : 0,
    adminCommissionMonth: promotion.management_fee?.months
      ? getObjRenamed(promotion.management_fee?.months, "adminCommission")
      : null,
  },
  leaseLandlord: {
    leaseLandlordFlag: promotion.landlord_lease?.active === true ? "yes" : "no",
    leaseLandlordMonthQuantity: promotion.landlord_lease?.months
      ? Object.keys(promotion.landlord_lease.months).length
      : 0,
    leaseLandlordMonth:
      promotion.landlord_lease?.months &&
      getObjRenamed(promotion.landlord_lease.months, "leaseLandlord"),
  },
  leaseTenant: {
    leaseTenantFlag: promotion.tenant_lease?.active === true ? "yes" : "no",
    leaseTenantMonthQuantity: promotion.tenant_lease?.months
      ? Object.keys(promotion.tenant_lease.months).length
      : 0,
    leaseTenantMonth:
      promotion.tenant_lease?.months &&
      getObjRenamed(promotion.tenant_lease?.months, "leaseTenant"),
  },
  landlordBrokerage: {
    landlordBrokerageFlag: promotion.landlord_brokerage?.active === true ? "yes" : "no",
    landlordBrokerageValue: promotion.landlord_brokerage?.brokerage,
    landlordBrokerageMonthQuantity: promotion.landlord_brokerage?.months
      ? Object.keys(promotion.landlord_brokerage.months).length
      : 0,
    landlordBrokerageClosing: promotion.landlord_brokerage?.closure,
    landlordBrokerageMonth:
      promotion.landlord_brokerage?.months &&
      getObjRenamed(promotion.landlord_brokerage.months, "landlordBrokerage"),
  },
  tenantBrokerage: {
    tenantBrokerageFlag: promotion.tenant_brokerage?.active === true ? "yes" : "no",
    tenantBrokerageMonthQuantity: promotion.tenant_brokerage?.months
      ? Object.keys(promotion.tenant_brokerage.months).length
      : 0,
    tenantBrokerageValue: promotion.tenant_brokerage?.brokerage,
    tenantBrokerageClosing: promotion.tenant_brokerage?.closure,
    tenantBrokerageMonth:
      promotion.tenant_brokerage?.months &&
      getObjRenamed(promotion.tenant_brokerage.months, "tenantBrokerage"),
  },
});
