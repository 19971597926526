export default (regions) => {
  const communeList = [];
  Object.entries(regions || {}).forEach(([region, communes]: [string, any]) => {
    Object.keys(communes?.communes || {}).forEach((commune) => {
      communeList.push({
        label: `${commune}, ${region}`,
        value: commune,
      });
    });
  });
  return communeList;
};
