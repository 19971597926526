const verticalMove = 0.0001;
const horizontalMove = 0.00025;
const doubleVerticalMove = 2 * verticalMove;
const doubleHorizontalMove = 2 * horizontalMove;

export const slightlyModifyCoordinates = (coords) => {
  const rand = Math.random();
  if (rand >= 0.941) {
    return [coords[0] - doubleHorizontalMove, coords[1] + doubleVerticalMove];
  }
  if (rand >= 0.882) {
    return [coords[0] - horizontalMove, coords[1] + doubleVerticalMove];
  }
  if (rand >= 0.823) {
    return [coords[0] + horizontalMove, coords[1] + doubleVerticalMove];
  }
  if (rand >= 0.764) {
    return [coords[0] + doubleHorizontalMove, coords[1] + doubleVerticalMove];
  }
  if (rand >= 0.705) {
    return [coords[0] - doubleHorizontalMove, coords[1] + verticalMove];
  }
  if (rand >= 0.647) {
    return [coords[0] - horizontalMove, coords[1] + verticalMove];
  }
  if (rand >= 0.588) {
    return [coords[0] + horizontalMove, coords[1] + verticalMove];
  }
  if (rand >= 0.529) {
    return [coords[0] + doubleHorizontalMove, coords[1] + verticalMove];
  }
  if (rand >= 0.47) {
    return [coords[0] - doubleHorizontalMove, coords[1] - verticalMove];
  }
  if (rand >= 0.411) {
    return [coords[0] - horizontalMove, coords[1] - verticalMove];
  }
  if (rand >= 0.352) {
    return [coords[0] + horizontalMove, coords[1] - verticalMove];
  }
  if (rand >= 0.294) {
    return [coords[0] + doubleHorizontalMove, coords[1] - verticalMove];
  }
  if (rand >= 0.235) {
    return [coords[0] - doubleHorizontalMove, coords[1] - doubleVerticalMove];
  }
  if (rand >= 0.176) {
    return [coords[0] - horizontalMove, coords[1] - doubleVerticalMove];
  }
  if (rand >= 0.117) {
    return [coords[0] + horizontalMove, coords[1] - doubleVerticalMove];
  }
  if (rand >= 0.058) {
    return [coords[0] + doubleHorizontalMove, coords[1] - doubleVerticalMove];
  }
  return coords;
};
