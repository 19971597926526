import { CountryUpperCase } from "models/Countries";

/* eslint-disable camelcase */
export const SET_CONTRACT_LIST = "SET_CONTRACT_LIST";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const SET_LOADING = "SET_LOADING";
export const SET_FILTERS = "SET_FILTERS";
export const SET_IS_OPEN_FILTER_MODAL = "SET_IS_OPEN_FILTER_MODAL";

// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export interface Filters {
  search?: string;
  init_date?: string;
  houm_owner?: string;
}
export interface State {
  loading: boolean;
  contractList: ContractList[];
  numberOfContracts: number | null;
  country: CountryUpperCase | "";
  filters: Filters;
  isOpenFilterModal: boolean;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface changeCountry {
  type: typeof CHANGE_COUNTRY;
  country: CountryUpperCase | "";
}

export interface setFilters {
  type: typeof SET_FILTERS;
  filters: Filters;
}

export interface setIsOpenFilterModal {
  type: typeof SET_IS_OPEN_FILTER_MODAL;
  isOpenFilterModal: boolean;
}
interface setContractList {
  type: typeof SET_CONTRACT_LIST;
  payload: { contractList: ContractList[]; count: number };
}

export interface ContractList {
  id: number;
  mainTenantName: string;
  ownerName: string;
  houmOwnerName: string;
  propertyId: number;
  propertyUid: string;
  propertyAddress: string;
  propertyStreet: string;
  propertyNumberDpto: number;
  createdAt: string;
}

export type Action =
  | setLoading
  | setContractList
  | changeCountry
  | setFilters
  | setIsOpenFilterModal;
