import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";

import { useGetToken } from "hooks";
import PropertyVisibilityService from "services/propertyVisibilityService";
import { ChangePropertyStatusOperationType } from "services/propertyVisibilityService/types";
import {
  AlertPayload,
  ChangeVisibilityPropertyForm,
  SubmitHandlerArgs,
  UsePropertyVisibilityProps,
} from "./types";
import { HANDLED_ERROR_TYPES, SUCCESS_MESSAGE_BODY_BY_OPERATION_TYPE } from "./constants";
import { operationReasonsSerializer } from "./utils";
import { AxiosError } from "axios";

const useChangePropertyVisibility = ({
  mode,
  propertyId,
  operationType,
}: UsePropertyVisibilityProps) => {
  const { getToken } = useGetToken();
  const [alertPayload, setAlertPayload] = useState<AlertPayload | null>(null);

  const handleSuccessfulRequest = (action: ChangePropertyStatusOperationType) =>
    setAlertPayload({
      type: "success",
      head: "",
      body: SUCCESS_MESSAGE_BODY_BY_OPERATION_TYPE[action],
    });

  const handleFailedRequest = (err: AxiosError<{ error?: string }>) => {
    const {
      response: { data },
    } = err;
    const sanitizedErrorMessage =
      HANDLED_ERROR_TYPES[data?.error] || data.error || HANDLED_ERROR_TYPES.UNHANDLED_ERROR;
    setAlertPayload({
      type: "error",
      head: sanitizedErrorMessage,
    });
  };

  const handleFailedReasonsRequests = () =>
    setAlertPayload({
      type: "error",
      head: "No se pudieron obtener los motivos de la operación",
    });

  const handleGetOperationReasons = async () => {
    const authToken = await getToken();
    const visibilityService = PropertyVisibilityService(authToken);

    const operationReasonsByType = {
      [ChangePropertyStatusOperationType.UNPUBLISH]: visibilityService.getUnpublishReasons,
      [ChangePropertyStatusOperationType.SET_AS_LOST]: visibilityService.getSetAsLostReasons,
      //TODO update this callback when publish reasons are available
      [ChangePropertyStatusOperationType.PUBLISH]: visibilityService.getUnpublishReasons,
    };

    const { data: reasons } = await operationReasonsByType[operationType]({
      businessType: mode,
      operationType,
    });
    const serializedReasons = operationReasonsSerializer[operationType]({
      reasons,
    });
    return serializedReasons;
  };

  const {
    data: operationReasons = [],
    isLoading: isLoadingOperationReasons,
    isError: isErrorGettingOperationReasons,
  } = useQuery([mode, "operationReasons"], handleGetOperationReasons, {
    onError: handleFailedReasonsRequests,
    enabled: Boolean(operationType),
  });

  const formMethods = useForm<ChangeVisibilityPropertyForm>();

  const [reasonId] = formMethods.watch(["reasonId"]);

  const submitHandlerByOperationType = async ({
    operationType,
    observation,
    reasonId,
  }: SubmitHandlerArgs) => {
    const authToken = await getToken();
    const propertyVisibilityService = PropertyVisibilityService(authToken);

    const ServiceOperationByOperationType = {
      [ChangePropertyStatusOperationType.UNPUBLISH]:
        propertyVisibilityService.unpublishPropertyById,
      [ChangePropertyStatusOperationType.PUBLISH]: propertyVisibilityService.publishPropertyById,
      [ChangePropertyStatusOperationType.SET_AS_LOST]: propertyVisibilityService.setPropertyAsLost,
    };

    await ServiceOperationByOperationType[operationType]({
      observation,
      propertyId,
      reasonId,
      businessType: mode,
    });

    return operationType;
  };

  const {
    mutateAsync: handleOperationTypeRequest,
    isLoading: isLoadingApplyingOperationType,
    isError: isErrorApplyingOperationType,
    isSuccess: isSuccessApplyingOperationType,
  } = useMutation(submitHandlerByOperationType, {
    onSuccess: handleSuccessfulRequest,
    onError: handleFailedRequest,
  });

  const onSubmitForm = formMethods.handleSubmit((formData: ChangeVisibilityPropertyForm) =>
    handleOperationTypeRequest({
      reasonId: formData.reasonId,
      observation: formData.observation,
      operationType,
    })
  );

  const somethingHappened =
    isErrorApplyingOperationType ||
    isSuccessApplyingOperationType ||
    isErrorGettingOperationReasons;

  return {
    reasonId,
    operationReasons,
    formMethods,
    onSubmitForm,
    isLoadingOperationReasons,
    isLoadingApplyingOperationType,
    isSuccessApplyingOperationType,
    isErrorGettingOperationReasons,
    isErrorApplyingOperationType,
    somethingHappened,
    alertPayload,
  };
};

export default useChangePropertyVisibility;
