export default {
  container: "mt-4",
  grayText: (isDisabled: boolean) => (isDisabled ? "text-secondary-40 mt-2" : "text-black-60 mt-2"),
  value: "cursor-pointer",
  button:
    "text-primary-cta cursor-pointer flex items-center border-none hover:bg-transparent hover:text-primary-cta px-0",
  submitButton:
    "flex items-center justify-center mt-4 font-bold text-primary-cta w-full border-none hover:bg-transparent hover:text-primary-cta mx-auto",
  reportDateInput: "cursor-pointer",
};
