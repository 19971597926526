import { Tab } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

interface CustomTabProps extends React.ComponentProps<typeof Tab> {
  label: string;
}

export const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.3,
      margin: theme.spacing(0, 2),
      color: theme.palette.secondary.light,
    },
    wrapper: {
      marginBottom: theme.spacing(2),
    },
  })
)((props: CustomTabProps) => <Tab {...props} />);
