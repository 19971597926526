import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { Grid } from "@material-ui/core";
import Button from "@houm-com/ui/Button";

import { IExitProcessResponse } from "services/contractService/types";
import { ControlledDatePickerOutline } from "Components/Admin/UIComponents/ControlledForm";

import { agreedDateValidationSchema } from "./utils/agreedDateValidationSchema";
import useUpdateExitProcessDate from "../../hooks/useUpdateExitProcessDate";
import ModalLayout from "../ModalLayout";
import { defaultDate } from "./utils/parsers";

type FormFields = {
  agreedDate: string;
};

export default function ExitProcessEditAgreedDateModal({
  isOpen,
  setClose,
  exitProcess,
}: {
  isOpen: boolean;
  setClose: () => void;
  exitProcess: IExitProcessResponse;
}) {
  const methods = useForm<FormFields>({
    resolver: yupResolver(agreedDateValidationSchema),
    reValidateMode: "onSubmit",
    defaultValues: {
      agreedDate: defaultDate(exitProcess),
    },
  });

  const { mutate: updateAgreedDate, isLoading } = useUpdateExitProcessDate(exitProcess, setClose);
  const onSubmit: SubmitHandler<FormFields> = (data: { agreedDate: string }) =>
    updateAgreedDate({ agreed_date: data.agreedDate });

  return (
    <ModalLayout
      open={isOpen}
      handleClose={() => setClose()}
      title="Editar fecha proceso de salida"
      withIcon={false}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <ControlledDatePickerOutline
                id="agreedDate"
                label="Editar fecha acordada"
                type="date"
                outlineProps={{
                  size: "small",
                  fullWidth: false,
                }}
              />
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <Button isLoading={isLoading} size="lg" variant="primary" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ModalLayout>
  );
}
