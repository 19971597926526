import { useEffect, useState } from "react";

import { UserResponse } from "models/User";

import { useGetToken, useDemandParams } from "hooks";
import { useList } from "context/demandLeadContext/list/listContext";
import userService from "services/userService";

import {
  setCountry,
  setDeal,
  setProfiled,
  getLeads,
} from "context/demandLeadContext/leads/demandLeadActions";

import { getExecutives, getOrigins } from "context/demandLeadContext/list/listActions";

import Header from "Components/Admin/administrator/demand/components/NewHeaderWithCountries";
import LeadTable from "./table/Table";
import FilterBar from "./filter/FilterBar";
import FilterModal from "./modals/FilterModal";
import AssignToOther from "./modals/AssignToOther";
import TagBar from "./filter/TagBar";
import FollowingDateFilter from "./filter/FollowingDateFilter";

import useStyles from "./DemandLeadIndexStyles";

interface Props {
  deal: "arriendo" | "venta";
  profiled: boolean;
}

function LeadIndex({ deal, profiled }: Props) {
  const classes = useStyles();
  const [user, setUser] = useState<UserResponse>();
  const { params, demandState, demandDispatch, tableState } = useDemandParams();

  const { dispatch: dispatchList } = useList();
  const { getToken } = useGetToken();

  useEffect(() => {
    const getUser = async () => {
      const userRes = await userService.me();
      if (userRes) {
        setUser(userRes);
        const normalizedCountry = userRes.country.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        demandDispatch(setCountry(normalizedCountry.toLowerCase()));
      }
    };
    if (!user) {
      getUser();
      demandDispatch(setDeal(deal));
      demandDispatch(setProfiled(profiled));
    }
  }, []);

  useEffect(() => {
    if (!demandState.deal || !demandState.country) return;
    getToken().then((authToken) => {
      getExecutives(
        dispatchList,
        demandState.country,
        authToken,
        demandState.deal === "venta" ? "sales" : "rentals"
      );
      getOrigins(dispatchList, demandState.country, authToken);
    });
  }, [demandState.country, demandState.deal]);

  useEffect(() => {
    if (deal && demandState.country && user) {
      getToken().then((authToken) => getLeads(demandDispatch, params, authToken));
    }
  }, [
    user,
    demandState.filters.leadQualifier?.label,
    demandState.filters.origin?.label,
    demandState.filters.state?.length,
    demandState.filters.date?.label,
    demandState.filters.following_date_from,
    demandState.country,
    tableState.currentPage,
    tableState.ordering,
    tableState.pageSize,
    tableState.ordering,
  ]);
  return (
    <>
      {/* Modal */}
      <AssignToOther />
      <FilterModal />
      {/* Modal */}
      <Header
        country={demandState.country}
        onCountryChange={(country) => demandDispatch(setCountry(country))}
        title={
          profiled
            ? deal === "arriendo"
              ? "Tenants calificados"
              : "Buyers calificados"
            : deal === "arriendo"
            ? "Tenants por contactar"
            : "Buyers por contactar"
        }
      />
      <FilterBar />
      <div className={classes.tagsAndDateFilterContainer}>
        <TagBar />
        <FollowingDateFilter />
      </div>
      <LeadTable />
    </>
  );
}
export default LeadIndex;
