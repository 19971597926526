import { CountryUpperCase } from "models/Countries";

export type Dispatch = (action: Action) => void;

export const SET_FILTERS = "SET_FILTERS";
export const SET_FORM = "SET_FORM";
export const SET_TAGS = "SET_TAGS";
export const SET_TAGS_TYPES = "SET_TAGS_TYPES";
export const SET_ID_ERROR = "SET_ID_ERROR";
export const SET_LOADING_FETCH = "SET_LOADING_FETCH";
export const SET_LOADING_POST = "SET_LOADING_POST";
export const SET_SHOW_SUCCESS = "SET_SHOW_SUCCESS";
export const SET_SHOW_DELETE_MODAL = "SET_SHOW_DELETE_MODAL";

export interface State {
  filters: TagFilters;
  showModal: boolean;
  form: TagForm;
  tags: TagsData;
  loadingPost: boolean;
  showSuccess: boolean;
  showDeleteModal: boolean;
  idError?: boolean;
  tagTypes?: Option[];
}

export interface Option {
  label: string;
  value: number;
}

export interface TagsData {
  data?: Tag[];
  loading?: boolean;
  refetch?: boolean;
}

export interface TagBase {
  id?: string;
  name?: string;
  country?: CountryUpperCase;
  beginDate?: string;
  dueDate?: string;
  endLess?: boolean;
  associationId?: string;
}

export interface Tag extends TagBase {
  description: string;
}

export interface TagFilters extends TagBase {}

export interface TagForm extends TagBase {
  ids?: string[];
}

interface setFilters {
  type: typeof SET_FILTERS;
  filters: TagFilters;
}

interface setTagTypes {
  type: typeof SET_TAGS_TYPES;
  tagTypes: Option[];
}

interface setForm {
  type: typeof SET_FORM;
  form: TagForm;
}

interface setTags {
  type: typeof SET_TAGS;
  tags: TagsData;
}

interface setIdError {
  type: typeof SET_ID_ERROR;
  idError: boolean;
}

interface setLoadingPost {
  type: typeof SET_LOADING_POST;
  loadingPost: boolean;
}

interface setShowSuccess {
  type: typeof SET_SHOW_SUCCESS;
  showSuccess: boolean;
}

interface setShowDeleteModal {
  type: typeof SET_SHOW_DELETE_MODAL;
  showDeleteModal: boolean;
}

export type Action =
  | setFilters
  | setForm
  | setTags
  | setIdError
  | setTagTypes
  | setLoadingPost
  | setShowSuccess
  | setShowDeleteModal;
