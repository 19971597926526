import { Container } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import { CountryUpperCase } from "models/Countries";

import useAppliedPromotions from "./hooks/useAppliedPromotions";
import AppliedPromotions from "./components/AppliedPromotions";
import SelectPromotion from "./components/SelectPromotion";
import usePromotionStyles from "./PromotionStyles";
import { checkStatus } from "../utils";

interface Props {
  country: CountryUpperCase;
  contractId: number;
  onFetchSimulation: ({ contractId, save }) => void;
  contractStatus: string;
}

const Promotion = ({ country, contractId, onFetchSimulation, contractStatus }: Props) => {
  const classes = usePromotionStyles();
  const { data, isLoading } = useAppliedPromotions(contractId);

  if (isLoading) return <Spinner />;

  const getPromotionsId = () => data.map((promotion) => promotion.id);

  return (
    <Container className={classes.promotionRoot}>
      {data.length === 0 &&
        !checkStatus(["Pagado", "Boton de pago enviado", "Finalizado"], contractStatus) && (
          <SelectPromotion
            country={country}
            contractId={contractId}
            appliedPromotions={getPromotionsId()}
            onFetchSimulation={onFetchSimulation}
          />
        )}
      {data.length > 0 && (
        <AppliedPromotions
          promotions={data}
          contractId={contractId}
          onFetchSimulation={onFetchSimulation}
        />
      )}
    </Container>
  );
};

export default Promotion;
