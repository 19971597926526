/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";

import CustomTextFieldOutline from "Components/Admin/UIComponents/CustomTextFieldOutline";

type OutlineProps = Partial<typeof CustomTextFieldOutline>;

interface Props {
  label: string;
  id: string;
  disabled?: boolean;
  defaultValue?: string | number;
  controllerProps?: React.ComponentProps<typeof Controller>;
  outlineProps?: OutlineProps;
  labelClassName?: string;
  type?: string;
}

function ControlledDatePickerOutline({
  label,
  id,
  disabled,
  defaultValue,
  controllerProps,
  outlineProps,
  type = "time",
  labelClassName,
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <CustomTextFieldOutline
          id={id}
          label={label}
          disabled={disabled}
          value={value}
          type={type}
          onChange={onChange}
          error={invalid}
          errorMessage={error?.message}
          labelClassName={labelClassName}
          {...outlineProps}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledDatePickerOutline;
