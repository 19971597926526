import { makeStyles, Theme } from "@material-ui/core";

const usePorfileStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: "100%",
    maxWidth: 300,
    maxHeight: 300,
    height: "fit-content",
    alignSelf: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(5, 4),
    gap: theme.spacing(1),
  },
  centered: {
    alignSelf: "center",
    textAlign: "center",
  },
  button: {
    marginTop: "auto",
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grey[500],
  },

  chipContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    flexWrap: "wrap",
  },
}));

export default usePorfileStyles;
