import { FormControl, FormLabel } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import FormHelperText from "@material-ui/core/FormHelperText";

import { IProperty } from "models/createProperty";

import usePropertySearch from "hooks/usePropertySearch";

import SearchBar from "Components/Admin/UIComponents/molecules/Searchbar";

import usePropertySearchBarStyles from "./PropertySearchBarStyles";

function PropertySearchBar({ id, defaultValue }) {
  const classes = usePropertySearchBarStyles();

  const { handleSearch, loading, properties } = usePropertySearch({});

  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <FormControl className={classes.container} error={invalid}>
          <FormLabel className={classes.label}>ID de propiedad</FormLabel>
          <SearchBar
            disabled={!!defaultValue}
            id="propertySearchBar"
            className={classes.autocomplete}
            placeholder="Id de propiedad"
            onChange={(e, property) => onChange(property)}
            loading={loading}
            noOptionsText="No se encontraron resultados"
            loadingText="Buscando ..."
            getOptionLabel={(option: IProperty) => `${option?.id?.toString()}, ${option.address}`}
            onInputChange={(e, val) => handleSearch(val, { ordering: "id" })}
            options={properties}
            value={value}
          />
          <FormHelperText className={classes.helper}>{error && error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
export default PropertySearchBar;
