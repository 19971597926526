import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { TokyCallInfo } from "context/tokyContext/tokyTypes";
import useStyles from "./TokyCallTableCellInfoStyles";

interface Props {
  callInfo: TokyCallInfo;
  doesCallExists: boolean;
}

function TokyCallTableCellInfo({ callInfo, doesCallExists }: Props) {
  const classes = useStyles();
  const isCallInfoAvailable = callInfo.url && callInfo.duration;

  if (!doesCallExists) return <Typography className={classes.text}>No aplica</Typography>;
  if (!isCallInfoAvailable) return <Typography className={classes.text}>No disponible</Typography>;

  return (
    <Box className={classes.container}>
      <a href={callInfo.url} target="_blank" rel="noopener noreferrer" className={classes.link}>
        Escuchar
      </a>
      <Typography className={classes.text}>{`[${callInfo.duration}]`}</Typography>
    </Box>
  );
}

export default TokyCallTableCellInfo;
