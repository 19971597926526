/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useMemo } from "react";
import { useGetToken } from "hooks";

import Table from "./components/Table";
import Header from "./components/Header";
import TableHeader from "./components/TableHeader";
import FilterOptions from "./components/FilterOptions";

import { useProperties } from "./context/propertiesContext";
import { getProperties } from "./context/propertiesActions";
import { useTable } from "../../../../context/tableContext/tableContext";
import { PropertyTableFilters } from "../../../../context/tableContext/tableTypes";

interface Filters extends PropertyTableFilters {
  country: string;
  for_rental: boolean;
  for_sale: boolean;
  ordering?: string;
}

function PropertyIndex() {
  const { state, dispatch } = useProperties();
  const { getToken } = useGetToken();
  const { state: tableStates } = useTable();
  const { ordering, tableFilters, pageSize, currentPage } = tableStates;

  const mappedFilters = useMemo(() => {
    const filters: Filters = {
      country: state.country.charAt(0).toUpperCase() + state.country.slice(1),
      for_sale: state.market === "sales",
      for_rental: state.market === "rental",
      ...tableFilters,
    };
    if (ordering) filters.ordering = ordering;
    return filters;
  }, [state.country, state.market, ordering, tableFilters]);

  useEffect(() => {
    getToken().then((authToken) =>
      getProperties(pageSize, currentPage, mappedFilters, dispatch, authToken)
    );
  }, [getProperties, mappedFilters, pageSize, currentPage]);

  return (
    <>
      <Header />
      <TableHeader />
      <Table />
      <FilterOptions />
    </>
  );
}

export default PropertyIndex;
