import Grid, { GridSize } from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import useInfoPersonStyles from "./InfoPersonStyles";

interface Props {
  size?: GridSize;
  label: string;
  text: string | number;
}

const InfoPerson = ({ size, label, text }: Props) => {
  const classes = useInfoPersonStyles();

  return (
    <Grid item xs={size ?? 6}>
      <Typography component="h6" className={classes.textLabel}>
        {label}
      </Typography>
      <Typography component="h6" className={classes.text}>
        {text}
      </Typography>
      <Divider className={classes.divider} />
    </Grid>
  );
};

export default InfoPerson;
