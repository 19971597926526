import * as React from "react";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";

import ScheduleHC from "./ScheduleHC";
import ScheduleUser from "./ScheduleUser";
import statusTranslator from "utils/statusTranslator";
import { PropertyData } from "models/Property";
import ScheduleInspector from "./ScheduleInspector";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Schedulers({ property }: { property: PropertyData }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const propertyDetails = property.property_details[0];
  const salesDetails = property.sales_details;

  const disabledVisits =
    statusTranslator(propertyDetails?.status) !== "Publicada" &&
    statusTranslator(salesDetails?.status) !== "Publicada";

  return (
    <Box>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Fotos" {...a11yProps(0)} />
        <Tab label="Visita usuario" {...a11yProps(1)} disabled={disabledVisits} />
        <Tab label="Inspección" {...a11yProps(1)} />
        <Tab label="Salida" {...a11yProps(1)} />
        <Tab label="Limpieza" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ScheduleHC property={property} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScheduleUser property={property} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ScheduleInspector property={property} scheduleType="Inspection" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ScheduleInspector property={property} scheduleType="Exit" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ScheduleInspector property={property} scheduleType="Cleaning" />
      </TabPanel>
    </Box>
  );
}

export default Schedulers;
