import * as yup from "yup";

const requiredField = "Campo requerido";

export const promotionFormSchema = yup.object().shape({
  name: yup.string().required(requiredField),
  goal: yup.string().required(requiredField),
  startDate: yup.string().required(requiredField),
  endDate: yup.string().required(requiredField),
  promoCode: yup.string().nullable(),
  cities: yup.array().min(1, requiredField),
  comment: yup.string().nullable(),
  link: yup.string().nullable(),
  promotionsAmount: yup
    .number()
    .integer("Debe ser un número entero")
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable()
    .required(requiredField),
  promotionTarget: yup.string().required(requiredField),
  promotionUser: yup.string().required(requiredField),
  promotionType: yup.string().nullable().required(requiredField),
  discountMonths: yup.string().required(requiredField),
  landlordManagementDiscount: yup.string().when(["promotionTarget", "hasMonthlyVariations"], {
    is: (target, hasMonthlyVariations) =>
      ["both", "management_fee"].includes(target) && !hasMonthlyVariations,
    then: yup.string().required(requiredField),
  }),
  landlordBrokerageDiscount: yup.string().when(["promotionTarget", "promotionUser"], {
    is: (target, user) =>
      ["both", "brokerage"].includes(target) && ["both", "landlord"].includes(user),
    then: yup.string().required(requiredField),
  }),
  landlordObservations: yup.string().nullable(),
  tenantBrokerageDiscount: yup.string().when(["promotionTarget", "promotionUser"], {
    is: (target, user) =>
      ["both", "brokerage"].includes(target) && ["both", "tenant"].includes(user),
    then: yup.string().required(requiredField),
  }),
  tenantObservations: yup.string().nullable(),
  hasMonthlyVariations: yup.string().nullable(),
  monthlyDiscounts: yup.object().when(["hasMonthlyVariations"], {
    is: "true",
    then: yup.object().required(requiredField),
  }),
});
