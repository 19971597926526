import { useQuery, UseQueryResult } from "react-query";

import bankAccountService from "services/bankAccountService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { IBanks } from "services/bankAccountService/types";

const useBankAccountList = (country: Locale) => {
  const { getToken } = useGetToken();

  const {
    isLoading: isLoadingBankList,
    isError: isErrorBankList,
    data: dataBankList,
  }: UseQueryResult<IBanks[], Error> = useQuery<IBanks[], Error>(
    [`${country}BankList`, country],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountService.getBanks(country, authToken);
      return response.data.banks;
    }
  );

  return {
    isLoadingBankList,
    isErrorBankList,
    dataBankList,
  };
};

export default useBankAccountList;
