import { PropertyProps } from "./types";
import { PropertyData } from "models/Property";

export const parsePropertyInfo = (data: PropertyData): PropertyProps => ({
  commonExpenses: data.property_details[0].gc,
  commune: data.comuna,
  currencyCode: data.property_details[0].currency_code,
  detailsId: data.property_details[0].id,
  flatNumber: data.numero_depto,
  id: data.id,
  rentValue: data.property_details[0].valor,
  parkingNumber: data.property_details[0].parking_numbers,
  street: data.address,
  streetNumber: data.street_number,
  user: {
    fullName: `${data.user.name} ${data.user.last_name}` || "",
    document: data.user.rut || "",
    documentType: data.user?.document_type?.id || 1,
    phone: data.user.phone || "",
    email: data.user.email || "",
    address: data.user.address || "",
  },
  warehouseNumber: data.property_details[0].warehouse_number,
});
