import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  paper: {
    minWidth: "80vw",
    minHeight: "66vh",
    borderRadius: 20,
    height: "fit-content",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 5),
    [theme.breakpoints.up("lg")]: {
      minWidth: "55vw",
      minHeight: "60vh",
    },
  },
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wrapperContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    gap: theme.spacing(4),
  },
  firstColumn: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "40%",
    gap: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      flexBasis: "45%",
      gap: theme.spacing(2),
    },
  },
  secondColumn: {
    width: "100%",
  },
  divider: {
    minHeight: "100%",
  },
  hours: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    width: "100%",
  },
  buttonWrapper: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
    "& > button": {
      padding: theme.spacing(0.8, 5),
      borderRadius: 50,
      fontWeight: 700,
      textTransform: "none",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
    },
  },
}));
