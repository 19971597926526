import { Typography } from "@material-ui/core";

import { ReactComponent as HoumLogo } from "assets/img/OUI).svg";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { useClosureForm } from "context/closureFormContext/closureFormContext";

import useStyles from "./SuccessModalStyles";

interface Props {
  open: boolean;
}

function SuccessModal({ open }: Props) {
  const { state } = useClosureForm();
  const classes = useStyles();

  const getModalTitle = () => {
    if (state.propertyData.country === "Chile") {
      return "Tus datos han sido enviados. El siguiente paso es elegir el plan al cual te quieres suscribir.";
    } else {
      return "Tus datos han sido enviados.";
    }
  };

  const getModalRedirectUrl = () => {
    if (state.propertyData.country === "Chile") {
      return `/landlord/new-properties/${state.ids.propertyUid}?type=forRent&page=plans`;
    } else {
      return `/landlord/new-properties/${state.ids.propertyUid}?type=forRent`;
    }
  };

  const getModalButtonLabel = () => {
    if (state.propertyData.country === "Chile") {
      return "Ver planes";
    } else {
      return "Finalizar";
    }
  };

  return (
    <CustomDialog
      title=""
      onClose={() => window.history.back()}
      classes={{ modalContainer: classes.paper }}
      open={open}>
      <div className={classes.modalContainer}>
        <HoumLogo className={classes.logo} />
        <Typography className={classes.title}>¡Formulario guardado!</Typography>
        {!state.isAdmin && (
          <>
            <Typography className={classes.subtitle}>{getModalTitle()}</Typography>
            <DesignSystemButton
              onClick={() => window.location.assign(getModalRedirectUrl())}
              variant="primary"
              label={getModalButtonLabel()}
              size="medium"
            />
          </>
        )}
      </div>
    </CustomDialog>
  );
}

export default SuccessModal;
