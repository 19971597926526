const classes = {
  linkContainer: "absolute flex gap-1 items-center hover:underline cursor-pointer",
  percentageLink: "top-[52px] left-[230px]",
  fixedLink: "top-[100px] left-[310px]",
  link: "text-p12 !text-information-120",
  container: "absolute w-[200px] rounded-2xl p-4 bg-white shadow-std flex flex-col",
  percentageContainer: "left-[310px] top-[-165px] z-[5000]",
  fixedContainer: "left-[385px] top-[-110px] z-[5000]",
  close: "mb-1 self-end cursor-pointer",
  row: "flex justify-between",
  bold: "text-p12 font-bold",
  breakdown: "text-black-80 text-p12",
  total: "[&>p]:text-p12",
};

export default classes;
