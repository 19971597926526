import { setCountry } from "../context/usersActions";
import HeaderWithCountries from "../../../UIComponents/HeaderWithCountries";
import { useUsers } from "../context/usersContext";
import { Country } from "../../../../../models/Countries";

interface Props {
  title: string;
  show?: boolean;
}
function Header({ title, show = true }: Props) {
  const { state, dispatch } = useUsers();
  const onCountryChangeHandler = (country: Country) => {
    dispatch(setCountry(country));
  };
  return (
    <HeaderWithCountries
      title={title}
      country={state.country}
      onCountryChange={onCountryChangeHandler}
      showCountry={show}
    />
  );
}

export default Header;
