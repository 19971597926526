import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";
import qs from "query-string";
import { PropertyBusinessType, PropertyService } from "./types";

const PropertyVisibilityService = (token: string): PropertyService => {
  const httpClient = createHttp(token, BACKEND_URL);

  const getUnpublishReasons: PropertyService["getUnpublishReasons"] = ({
    businessType,
    operationType,
  }) =>
    httpClient.get(
      `/properties/v2/status_change_reasons?${qs.stringify({
        business_type: businessType,
        type: operationType,
      })}`
    );

  const getSetAsLostReasons: PropertyService["getSetAsLostReasons"] = () =>
    httpClient.get("/properties/v2/lost_reasons/");

  const unpublishPropertyById: PropertyService["unpublishPropertyById"] = ({
    propertyId,
    reasonId,
    observation,
  }) =>
    httpClient.post(`/properties/v2/${propertyId}/unpublish_prop/`, {
      reason_id: reasonId,
      observations: observation,
    });

  const publishPropertyById: PropertyService["publishPropertyById"] = ({
    propertyId,
    reasonId,
    observation,
  }) =>
    httpClient.post(`/properties/v2/${propertyId}/publish_prop/`, {
      reason_id: reasonId,
      observations: observation,
    });

  const setPropertyAsLost: PropertyService["setPropertyAsLost"] = ({
    propertyId,
    reasonId,
    observation,
    businessType,
  }) => {
    const setAsLostTrail =
      businessType === PropertyBusinessType.RENT ? "set_lost_for_rent" : "set_lost_for_sale";
    return httpClient.post(`/properties/v2/${propertyId}/${setAsLostTrail}/`, {
      reason: reasonId,
      observations: observation,
    });
  };
  return {
    getUnpublishReasons,
    getSetAsLostReasons,
    unpublishPropertyById,
    publishPropertyById,
    setPropertyAsLost,
  };
};

export default PropertyVisibilityService;
