import { snakeCase } from "lodash";
import qs from "query-string";

import { SearchFilterTypes } from "./types";

export const getLandlordUrl = (
  landlordId: number,
  filter: SearchFilterTypes,
  searchQuery: string
): string => {
  const baseURL = `/admin/landlord/${landlordId}`;
  const searchHash = {};
  switch (filter) {
    case "propertyId":
    case "quotationId":
    case "document":
    case "tenant":
      searchHash[snakeCase(filter)] = searchQuery;
      return `${baseURL}?${qs.stringify(searchHash)}`;
    case "search":
    default:
      return baseURL;
  }
};
