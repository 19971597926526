import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { alert } from "@houm-com/ui/Alert";

import { Locale } from "models/Countries";
import applicantService from "services/applicantService";
import leadService from "services/leadService";
import leaseService from "services/leaseService";
import { useGetToken } from "hooks";

import {
  IFormData,
  IPersonData,
  parseApplicantResponse,
  parseDataApplicant,
  parseDataLease,
  parseFormData,
} from "../utils";

interface Props {
  propertyUid: string;
  propertyId: number;
  onSuccess: () => void;
  onError?: () => void;
}

interface MutationProps {
  data: IPersonData;
  country: Locale;
}

const useOnSubmitApplicantForm = ({ propertyUid, propertyId, onSuccess, onError }: Props) => {
  const { getToken } = useGetToken();
  const history = useHistory();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async ({ data, country }: MutationProps) => {
      const authToken = await getToken();
      const formData: IFormData = parseFormData(data, country);
      const userData = parseDataApplicant(formData, country, propertyUid);
      await leadService.newPostLead(userData, authToken);
      const applicantResponse = await applicantService.createApplicant(userData, authToken);
      const applicant = parseApplicantResponse(applicantResponse.data);
      const leaseData = parseDataLease(applicant.userId, propertyId);
      await leaseService.postLease(leaseData, authToken);
      onSuccess();
      return applicant;
    },
    {
      onSuccess: (applicant, { data }) => {
        onSuccess?.();
        alert.success({
          message: "Postulante creado",
          disclaimer: `El postulante ${data.name} ${data.lastName} ha sido exitosamente creado`,
          primaryCallToAction: {
            action: () => history.push(`/admin/applicants/${applicant.id}`),
            label: "Ir a la vista de aplicante",
          },
        });
      },
      onError: (error: AxiosError) => {
        onError();
        const { response } = error;
        const err = response && Object.keys(response?.data);
        alert.error({
          message: "Error al crear postulante",
          disclaimer: `El postulante no se pudo crear, error: ${response?.data[`${err}`] ?? error}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useOnSubmitApplicantForm;
