export const statusOptions = {
  available: "Disponible",
  other: "No disponible",
  reserved_by_houm: "Reservado por Houm",
  reserved_by_other: "Reservado por otro",
  sold_by_houm: "Vendida por Houm",
  sold_by_other: "Vendida por otro",
};

export const filteredData = (rows, query) => {
  const filteredArray = [];
  if (!query) {
    return rows;
  }
  rows.forEach((row) => {
    if (row.name.toLowerCase().includes(query.toLowerCase())) {
      filteredArray.push(row);
      return;
    }
    const filteredUnits = row.subitems?.filter((unit) =>
      unit.name?.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredUnits.length > 0) {
      filteredArray.push({ ...row, subitems: filteredUnits });
    }
  });
  return filteredArray;
};

export const parseRows = (response) => {
  if (!response) {
    return [];
  }
  const layouts = response.map((layout) => ({
    id: layout.id,
    total: layout.units.length,
    layout_file_url: `https://s3.amazonaws.com/houm-real-estate-media/layout/${layout.layout_file_name}`,
    name: layout.name,
    features: {
      rooms: layout.rooms,
      bathrooms: layout.bathrooms,
      building_area: layout.building_area,
      usable_area: layout.usable_area,
      studio: layout?.has_studio,
    },
    subitems: layout.units
      .map((unit) => ({
        id: unit.id,
        price: { currency: unit.currency, value: unit.price },
        status: unit.status,
        name: unit.name,
        features: {
          orientation: unit.orientation,
          floor: unit.floor,
          parking: unit.parking_spaces,
          warehouse: unit.has_storage,
        },
      }))
      .sort((a, b) => a.price.value - b.price.value),
  }));
  return layouts.map((layout) => ({
    ...layout,
    price: layout.subitems[0]?.price,
  }));
};

//When the file is not changed in the edit form, it has only the "preview" field
export const isImageNew = (image: File) => Boolean(image.name);
