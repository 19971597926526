import { Button, ButtonProps } from "@material-ui/core";
import useHourButtonStyles from "./HourButtonStyle";

interface Props extends ButtonProps {
  selected?: boolean;
}

function HourButton({ children, selected, ...props }: Props) {
  const classes = useHourButtonStyles();
  return (
    <Button
      color={selected ? "primary" : "default"}
      variant="outlined"
      disableElevation
      classes={{
        root: classes.root,
        outlinedPrimary: classes.selected,
      }}
      {...props}>
      {children}
    </Button>
  );
}

export default HourButton;
