import { useState } from "react";
import { SnackbarProvider } from "notistack";
import { Link, useParams } from "react-router-dom";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { Locale } from "models/Countries";

import useStyles from "./GenerateNewContractStyles";
import InitContract from "./components/InitContract";

function GenerateNewContract() {
  const classes = useStyles();
  const { state } = useEvaluation();
  const { singleEvaluation } = state;
  const [generatingContract, setGeneratingContract] = useState<boolean>(false);
  const [rentWarning, setRentWarning] = useState<boolean>(false);
  const { country } = useParams<{ country: string }>();

  const handleOpenGeneratingContract = () => {
    setGeneratingContract(true);
  };

  const handleCloseGeneratingContract = () => {
    setGeneratingContract(false);
  };

  const handleOpenRentWarning = () => {
    if (state.canPay) {
      handleOpenGeneratingContract();
    } else {
      setRentWarning(true);
    }
  };

  const handleCloseRentWarning = () => {
    setRentWarning(false);
  };

  const handleGenerateAnyway = () => {
    handleCloseRentWarning();
    handleOpenGeneratingContract();
  };

  const flagAllApplicantsApproved = (evaluation) => {
    const evalFiltered = evaluation.evaluations.filter(
      (evaluation) => evaluation.status === "Perfil aprobado"
    );
    const evalComerciallyFiltered = evaluation.commerciallyApproved.filter(
      (evaluation) => evaluation.status === "Aprobado comercialmente"
    );
    const evaluationsTotal = evaluation.evaluations.length;
    return (
      evalFiltered.length === evaluationsTotal ||
      evalComerciallyFiltered.length === evaluationsTotal
    );
  };

  if (!flagAllApplicantsApproved(singleEvaluation)) return null;

  return (
    <Grid item xs={10}>
      {singleEvaluation.evaluations[0].contracts &&
        singleEvaluation.evaluations[0].contracts.length > 0 && (
          <Typography>
            Contratos asociados:{" "}
            {singleEvaluation.evaluations[0].contracts.map((item) => (
              <Link to={`/admin/contracts/${item}`} key={item}>
                {item}{" "}
              </Link>
            ))}
          </Typography>
        )}
      <CustomLoadingButton
        text={
          singleEvaluation.evaluations[0].contracts &&
          singleEvaluation.evaluations[0].contracts.length > 0
            ? "Generar contrato nuevo"
            : "Pasar a contratos por aprobar"
        }
        onClick={handleOpenRentWarning}
        type="contained"
        fullSize={true}
      />
      <CustomDialog open={rentWarning} onClose={handleCloseRentWarning} title="">
        <div className={classes.warningDialogRoot}>
          <div className={classes.warningDialogTextContainer}>
            <Typography className={classes.warningDialogTitle}>
              ¿Estás seguro de que quieres generar el contrato?
            </Typography>
            <Typography className={classes.warningDialogSubtitle}>
              Esta{" "}
              <Box fontWeight="fontWeightBold" component="span">
                evaluación no cumple la regla
              </Box>{" "}
              de que las rentas sumen 3 veces el valor del arriendo
            </Typography>
          </div>
          <div className={classes.warningDialogButtonsContainer}>
            <CustomLoadingButton
              text="Sí, generar"
              onClick={handleGenerateAnyway}
              type="outlined"
              buttonClassName={classes.warningDialogButton}
            />
            <CustomLoadingButton
              text="Cancelar"
              onClick={handleCloseRentWarning}
              type="contained"
              buttonClassName={classes.warningDialogButton}
            />
          </div>
        </div>
      </CustomDialog>
      <Dialog
        open={generatingContract}
        onClose={handleCloseGeneratingContract}
        transitionDuration={500}
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}>
        <Grid container justifyContent="space-between">
          <DialogTitle>Generar contrato</DialogTitle>
          <DialogActions>
            <IconButton className={classes.closeButton} onClick={handleCloseGeneratingContract}>
              <GridCloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
        <DialogContent className={classes.customDialog}>
          <SnackbarProvider>
            <InitContract onSave={handleCloseGeneratingContract} country={country as Locale} />
          </SnackbarProvider>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

export default GenerateNewContract;
