import { Grid, Typography } from "@material-ui/core";

import {
  beginHours,
  endHours,
} from "Components/Admin/administrator/availability/AvailabilityConsts";
import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import useAppraiserInformationStyles from "./useAppraiserInformationStyles";
import { useDashboard } from "context/controlTower/controlTowerContext";

interface Props {
  day: string;
  index: string;
}

function DailySchedule({ day, index }: Props) {
  const classes = useAppraiserInformationStyles();
  const { state } = useDashboard();
  const availableHoursInThisDay = state.availability.availability.filter((item) =>
    item.begin_date.includes(index)
  );

  const isHourChecked = (daySchedule, currentHour) => {
    const isHourPresent = daySchedule.filter((item) => item.begin_date.includes(currentHour));
    return isHourPresent.length > 0;
  };
  return (
    <Grid className={classes.daily} container justifyContent="center">
      <Typography className={classes.title}> {day}</Typography>
      {beginHours.slice(2, -3).map((item, i) => (
        <ControlledCheckbox
          label={`${beginHours[i + 2]} - ${endHours[i + 2]}`}
          id={`${index} ${beginHours[i + 2]}_${index} ${endHours[i + 2]}`}
          key={`${index}_${beginHours[i + 2]}_${endHours[i + 2]}`}
          placement="end"
          justifyContent="center"
          defaultChecked={isHourChecked(availableHoursInThisDay, beginHours[i + 2])}
          size="small"
        />
      ))}
    </Grid>
  );
}

export default DailySchedule;
