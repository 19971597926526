import { useHistory } from "react-router-dom";
import Button, { ButtonProps } from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import useStyles from "./BackButtonStyles";

interface IPropsBackButton extends ButtonProps {
  arrow?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  backTo?: string;
}

function BackButton({ children, arrow, disabled, backTo, ...props }: IPropsBackButton) {
  const classes = useStyles({ disabled });
  const history = useHistory();

  const handleGoBack = () => (backTo ? history.push(backTo) : history.goBack());

  return (
    <Button {...props} className={classes.backButton} onClick={props.onClick || handleGoBack}>
      {arrow && (
        <div className={classes.childrenBackButton}>
          <ArrowBackIosIcon className={classes.largeIcon} />
          {children}
        </div>
      )}
      {!arrow && children}
    </Button>
  );
}

export default BackButton;
