import { makeStyles, Theme } from "@material-ui/core";

const useFilterFieldStyles = makeStyles((theme: Theme) => ({
  textFieldWrapper: {
    width: 180,
  },
  textField: {
    borderRadius: 20,
    border: `1px solid ${theme.palette.grey[75]}`,
    paddingLeft: theme.spacing(2),
  },
  dialogText: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
  boldText: {
    fontWeight: 700,
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[75]}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default useFilterFieldStyles;
