import useFilterBarStyles from "./FilterbarStyles";
import SearchContract from "../Searchbar";
import { useContract } from "context/contractContext/contractContext";
import { setIsOpenFilterModal } from "context/contractContext/contractActions";
import CustomButtonGray from "Components/Admin/administrator/demand/components/CustomButtonGray";

import { ReactComponent as Options } from "assets/images/options.svg";

function FilterBar() {
  const classes = useFilterBarStyles();
  const {
    state: { isOpenFilterModal },
    dispatch,
  } = useContract();
  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        <SearchContract />
      </div>
      <div className={classes.buttonsContainer}>
        <CustomButtonGray
          startIcon={<Options />}
          onClick={() => dispatch(setIsOpenFilterModal(!isOpenFilterModal))}>
          Filtrar
        </CustomButtonGray>
      </div>
    </div>
  );
}

export default FilterBar;
