import { useState } from "react";
import { TextField } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";

import useStyles from "./useSearchBarStyles";

type Props = {
  onChange: (string) => void;
  placeholder?: string;
};

function SearchBar({ onChange, placeholder = "Búsqueda" }: Props) {
  const classes = useStyles();
  const [query, setQuery] = useState<string>("");

  return (
    <TextField
      className={classes.searchBarContainer}
      id="searchQuery"
      value={query}
      onChange={(e) => {
        setQuery(e?.target?.value);
        onChange(e?.target?.value);
      }}
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        className: classes.searchBar,
        startAdornment: <SearchOutlined className={classes.icon} />,
      }}
    />
  );
}

export default SearchBar;
