import { Grid, Typography } from "@material-ui/core";

import CustomRadioButton from "Components/Admin/UIComponents/DesignSystem/form/CustomRadioButton";
import CustomFileDropzone from "Components/Admin/UIComponents/DesignSystem/form/CustomFileDropzone";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { IRequestForm } from "Components/Admin/administrator/subscriptions/hooks/requests/types";
import useQuotation from "Components/Admin/administrator/subscriptions/hooks/useQuotation";
import { RepairmentType, RequestorRole, ResponsibleTeam } from "services/requestsService/types";

import useRepairFormStyles from "./RepairFormStyles";
import RADIO_OPTIONS_BY_COUNTRY from "./constants/radioOptionsByCountry";

interface Props {
  setRequestForm: (value: IRequestForm) => void;
  requestForm: IRequestForm;
}

const RepairForm = ({ setRequestForm, requestForm }: Props) => {
  const { subscription } = useQuotation();
  const { repairType, requestorRole, observation, responsibleTeam } = requestForm;
  const radioOptions = RADIO_OPTIONS_BY_COUNTRY[subscription?.property?.country || "Chile"];
  const classes = useRepairFormStyles();

  return (
    <Grid container>
      <Grid container xs={6} spacing={2}>
        <Grid item xs={12}>
          <CustomRadioButton
            label="¿Quién solicita la reparación?"
            value={requestorRole}
            onChange={(e) =>
              setRequestForm({ ...requestForm, requestorRole: e.target.value as RequestorRole })
            }
            id="role"
            options={radioOptions.originOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            label="Equipo responsable"
            value={responsibleTeam}
            onChange={(e) =>
              setRequestForm({
                ...requestForm,
                responsibleTeam: e.target.value as ResponsibleTeam,
              })
            }
            id="role"
            options={radioOptions.responsibleOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomRadioButton
            label="Tipo de reparación"
            value={repairType}
            onChange={(e) =>
              setRequestForm({ ...requestForm, repairType: e.target.value as RepairmentType })
            }
            id="type"
            options={radioOptions.typeOptions}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography className={classes.textFieldLabel}>Descripción del problema</Typography>
        <CustomTextField
          className={classes.textField}
          minRows={3}
          multiline
          wrapperPadding={false}
          required
          onChange={(e) => setRequestForm({ ...requestForm, observation: e.target.value })}
          value={observation}
          placeholder="Escribe aquí tu texto"
          maxLength={1024}
        />
        <CustomFileDropzone
          id="files"
          onChange={(files) => setRequestForm({ ...requestForm, photos: files as File[] })}
          label="Agregar fotos"
          maxFiles={10}
        />
      </Grid>
    </Grid>
  );
};

export default RepairForm;
