import AsignAgent from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/AutoAsign/AsignAgent";
import News from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/News/News";
import ConfirmationPropietary from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/ConfirmationProp/ConfirmationProp";
import CancelationPropietary from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/CancelationProp/CancelationProp";
import FilterModal from "Components/Admin/administrator/demand/visits/Tenants/Components/filter/FilterModal";
import Processing from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/Processing";
import Success from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/Success";
import Failure from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/Failure";
import ContactInfo from "Components/Admin/administrator/demand/visits/Tenants/Components/modals/ContactInfo/ContactInfo";

function Modals() {
  return (
    <>
      <AsignAgent />
      <ConfirmationPropietary />
      <CancelationPropietary />
      <News />
      <Processing />
      <Success />
      <Failure />
      <ContactInfo />
      <FilterModal />
    </>
  );
}

export default Modals;
