import { makeStyles, Theme } from "@material-ui/core";

const useNoAvailabilityStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
    maxWidth: "fit-content",
    textAlign: "center",
  },
}));

export default useNoAvailabilityStyles;
