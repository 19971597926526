import { makeStyles, Theme } from "@material-ui/core";

const useRefundsStyles = makeStyles((theme: Theme) => ({
  refundsRoot: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
  },
  noReservations: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
  refunds: {
    marginTop: theme.spacing(3),
  },
}));

export default useRefundsStyles;
