import { IconButton, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { useProject } from "context/projectContext/projectContext";
import NewUserDialog from "Components/Admin/UIComponents/ProjectForm/NewUserDialog";
import { ControlledUserText } from "Components/Admin/UIComponents/ControlledForm";
import { setUserModal } from "context/projectContext/projectActions";
import FormBlock from "./FormBlock";
import useStyles from "./UserStyle";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

function User() {
  const classes = useStyles();
  const { state, dispatch } = useProject();
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("user", state.user);
  }, [setValue, state.user]);

  return (
    <FormBlock>
      <div className={classes.container}>
        <div className={classes.textFieldContainer}>
          <ControlledUserText
            id="user"
            label="Desarrolladora/inmobiliaria"
            defaultValue={state.project?.property?.user || state.user}
          />
        </div>
        <IconButton onClick={() => dispatch(setUserModal(true))} color="primary" id="create_user">
          <AddCircleIcon />
        </IconButton>
      </div>
      <Typography variant="subtitle1" color="primary">
        Propiedad de <span className={classes.bold}>proyecto inmobiliario</span>
      </Typography>
      <NewUserDialog />
    </FormBlock>
  );
}

export default User;
