import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";

import { EvaluationMemberStatusDictionary } from "apps/evaluator-admin/utils/constants";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import {
  EvaluationsFilter,
  EvaluationMemberStatusEnum,
} from "domains/evaluations/models/Evaluations";
import { useFormNotifications } from "hooks";

import { evaluatorFilterFormSchema, requiredText } from "./utils/validationSchema";
import { FormData } from "./utils/types";
import { useEffect } from "react";

interface Props {
  filters: EvaluationsFilter;
  onSaveFilters: (filters: Partial<EvaluationsFilter>) => void;
}

const FilterModal = ({ filters, onSaveFilters }: Props) => {
  const { state: tableState, dispatch } = useTable();

  const methods = useForm<FormData>({
    resolver: yupResolver(evaluatorFilterFormSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      ...filters,
      memberStatus: filters?.status || null,
      applicantName: filters?.applicantName || null,
      evaluationId: String(filters?.evaluationId) || null,
      propertyId: filters?.propertyId || null,
      createdAtFilter: filters.sort === "created_at" ? filters.order : null,
      updatedAtFilter: filters.sort === "updated_at" ? filters.order : null,
    },
  });
  const { errors } = methods.formState;

  const getSort = (createdAtFilter: string, updatedAtFilter: string) => {
    if (createdAtFilter || updatedAtFilter) {
      return {
        sort: createdAtFilter ? "created_at" : "updated_at",
        order: createdAtFilter || updatedAtFilter,
      };
    }
    return {
      sort: null,
      order: null,
    };
  };

  useFormNotifications<FormData>({ errors, requiredText });
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const { createdAtFilter, updatedAtFilter, ...keys } = data;
    const newFilters = {
      ...keys,
      ...getSort(createdAtFilter, updatedAtFilter),
    };
    const filters: Partial<EvaluationsFilter> = Object.fromEntries(
      Object.entries(newFilters).filter(([_, v]) => v != null)
    );
    onSaveFilters(filters);
    handleFilterModal();
  };

  const handleFilterModal = () => dispatch(setShowModal(false));

  const handleDelete = () => {
    onSaveFilters({
      size: 10,
      page: 1,
    });
    methods.reset();
    dispatch(setTableFilters({}));
    dispatch(setShowModal(false));
  };

  const orderOptions = [
    { value: "desc", label: "Más nuevo" },
    { value: "asc", label: "Más antiguo" },
  ];

  const [updateAt, createdAt] = methods.watch(["updatedAtFilter", "createdAtFilter"]);

  useEffect(() => {
    if (createdAt) methods.setValue("updatedAtFilter", null);
  }, [createdAt]);

  useEffect(() => {
    if (updateAt) methods.setValue("createdAtFilter", null);
  }, [updateAt]);

  return (
    <ModalDialog title="Filtros" isOpen={tableState.showModal} onClose={handleFilterModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalDialog.Content classNameContent="flex flex-col p-1 gap-2">
            {/* <FieldInput
              id="applicantName"
              label="Postulante"
              errorMessage={errors.applicantName?.message}
              form="novalidateform"
              {...methods.register("applicantName")}
            /> */}
            <FieldInput
              id="evaluationId"
              label="ID Evaluación"
              type="number"
              errorMessage={errors.evaluationId?.message}
              form="novalidateform"
              {...methods.register("evaluationId")}
            />
            <FieldInput
              id="propertyId"
              label="ID Propiedad"
              type="number"
              errorMessage={errors.propertyId?.message}
              form="novalidateform"
              {...methods.register("propertyId")}
            />

            <FieldSelect
              label="Estado"
              id="memberStatus"
              errorMessage={errors.memberStatus?.message}
              placeholder="Selecciona un estado"
              options={EvaluationMemberStatusEnum.map((s) => ({
                label: EvaluationMemberStatusDictionary[s],
                value: s,
              }))}
              form="novalidateform"
              {...methods.register("memberStatus")}
            />
            <FieldSelect
              label="Creación"
              id="createdAtFilter"
              errorMessage={errors.createdAtFilter?.message}
              placeholder="Seleccionar"
              options={orderOptions}
              form="novalidateform"
              {...methods.register("createdAtFilter")}
            />
            <FieldSelect
              label="Última modificación"
              id="updatedAtFilter"
              errorMessage={errors.updatedAtFilter?.message}
              placeholder="Seleccionar"
              options={orderOptions}
              form="novalidateform"
              {...methods.register("updatedAtFilter")}
            />
          </ModalDialog.Content>
          <ModalDialog.Actions
            cancelButtonLabel="Borrar"
            onClose={handleDelete}
            submitButtonLabel="Aplicar"
            submitButtonProps={{
              type: "submit",
            }}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default FilterModal;
