import { useState } from "react";
import Carousel from "react-material-ui-carousel";

import { Button, IconButton } from "@material-ui/core";
import { DoubleArrow } from "@material-ui/icons";

import InspectionReportCard from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportCard";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useProperty } from "context/propertyContext/propertyContext";
import useStyles from "./GeneralSpacesPhotoGalleryStyles";

function GeneralSpacesSummary() {
  const { state } = useProperty();
  const classes = useStyles();

  const [isCarouselOpen, setIsCarouselOpen] = useState(false);

  const maximumImagesToDisplay = 5;

  const generalSpacesImages = state.inspectionReport.data?.spaces
    ? state.inspectionReport.data?.spaces?.map((space) => space.photo)
    : [];

  const slicedGeneralSpacesImages = generalSpacesImages?.slice(0, maximumImagesToDisplay);

  const handleOpenCarousel = () => setIsCarouselOpen(true);
  const handleCloseCarousel = () => setIsCarouselOpen(false);

  return (
    <InspectionReportCard title="Fotos de los espacios">
      <Loading loading={state.inspectionReport.loading}>
        <>
          <Button className={classes.imgContainerBtn} onClick={handleOpenCarousel}>
            {slicedGeneralSpacesImages.map((image) => (
              <div key={`image-${image}`} className={classes.imgContainer}>
                <img className={classes.img} src={image} width="100" alt="small-preview" />
              </div>
            ))}
            {generalSpacesImages.length > maximumImagesToDisplay && (
              <IconButton
                className={classes.moreImagesBtn}
                aria-label="remove"
                onClick={handleOpenCarousel}>
                <DoubleArrow fontSize="small" />
              </IconButton>
            )}
          </Button>
          <CustomDialog
            title="Fotos de los espacios"
            open={isCarouselOpen}
            onClose={handleCloseCarousel}>
            <Carousel animation="slide" autoPlay={false}>
              {generalSpacesImages.map((image) => (
                <div key={`image-${image}`} className={classes.carouselImgContainer}>
                  <img
                    className={classes.carouselImg}
                    src={image}
                    width="100"
                    alt="large-preview"
                  />
                </div>
              ))}
            </Carousel>
          </CustomDialog>
        </>
      </Loading>
    </InspectionReportCard>
  );
}

export default GeneralSpacesSummary;
