import classnames from "classnames";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@material-ui/core";
import useStyles from "./CustomTextAreaStyles";

interface Props extends TextareaAutosizeProps {
  error?: boolean;
  errorMessage?: string;
  label?: string;
  labelClassName?: string;
  warning?: boolean;
  warningMessage?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
  isGrey?: boolean;
}

function CustomTextArea({
  error,
  label,
  errorMessage = "Error",
  disabled = false,
  labelClassName = "",
  warning,
  warningMessage,
  labelPlacement = "top",
  minRows = 2,
  maxRows = 4,
  isGrey = false,
  ...props
}: Props) {
  const classes = useStyles({ error, warning, isGrey });

  return (
    <FormControl className={classes.wrapper} error={error}>
      <FormControlLabel
        classes={{
          label: classnames(classes.label, labelClassName),
          root: classes.wrapper,
        }}
        control={
          <div className={classes.textAreaRoot}>
            <TextareaAutosize
              disabled={disabled}
              className={classes.textArea}
              minRows={minRows}
              maxRows={maxRows}
              {...props}
            />
          </div>
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      <FormHelperText error={error}>
        {(error && errorMessage) || (warning && warningMessage)}
      </FormHelperText>
    </FormControl>
  );
}

export default CustomTextArea;
