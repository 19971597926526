import * as yup from "yup";
import notNumberToNull from "./notNumberToNull";

export const requiredText = "Obligatorio";
export const requiredUserText = "Hace falta seleccionar el usuario";
const invalidValue = "El valor ingresado no es válido";
const mustBeInteger = `${invalidValue}. Debe ser valor entero`;
const mustBePositiveValue = "El valor debe ser 0 o mayor a 0";
export const wrongNumber = "Número de teléfono inválido";
export const wrongEmail = "Ingrese un email válido";

export const number = yup.number().transform(notNumberToNull);
export const positiveNumber = number.min(0, mustBePositiveValue);

export const intNumber = number.integer(mustBeInteger);
export const positiveIntNumber = positiveNumber.integer(mustBeInteger);
