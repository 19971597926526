import { makeStyles, Theme } from "@material-ui/core";

const useScheduleGeneratorStyles = makeStyles((theme: Theme) => ({
  checkboxContainer: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  selectorContainer: {
    width: 150,
    margin: theme.spacing(1, 1, 0, 0),
  },
  checkbox: {
    marginLeft: theme.spacing(0),
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  cancelButton: {
    borderRadius: 200,
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
    backgroundColor: "transparent",
    width: "300px",
  },
  selectorLabel: {
    width: "100%",
    fontWeight: 400,
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
  },
  selector: {
    marginTop: theme.spacing(0.3),
    minWidth: 130,
  },
}));

export default useScheduleGeneratorStyles;
