import currency from "@houm-com/formats/currency";
import Button from "@houm-com/ui/Button";

import { Locale } from "models/Countries";

import classes from "./EditContractInfoStyles";
import { renderMoneyType } from "../utils";

interface Props {
  commonExpenses: number;
  canonAmount: number;
  typeMoney: string;
  country: Locale;
  contractCountry: Locale;
  handleCloseModal: () => void;
  handleAcceptInfoModal: () => void;
}

export default function EditContractInfo({
  canonAmount,
  commonExpenses,
  typeMoney,
  handleCloseModal,
  handleAcceptInfoModal,
  country,
  contractCountry,
}: Props) {
  const canonValue = currency(canonAmount, renderMoneyType(typeMoney, contractCountry), {
    locale: country,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return (
    <div className={classes.container}>
      <p>
        El contracto se modificará y el valor del canon será {canonValue},{" "}
        {country !== "cl" ? `el valor de los gastos comunes ${commonExpenses}` : ""}, ¿Está seguro?
      </p>
      <div className={classes.buttonsContainer}>
        <Button variant="primary" size="md" onClick={handleAcceptInfoModal}>
          Aceptar
        </Button>
        <Button variant="tertiary" size="md" onClick={handleCloseModal}>
          Cancelar
        </Button>
      </div>
    </div>
  );
}
