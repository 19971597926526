import Alert from "@material-ui/lab/Alert";

import { CountryUpperCase } from "models/Countries";
import { commonExpenseLabelByCountry } from "Components/Admin/UIComponents/PropertyForm/utils/propertyFormFieldsInfo";

interface Props {
  country: CountryUpperCase;
}

function CommonExpensesAlert({ country }: Props) {
  return (
    <Alert severity="warning">
      <strong>Advertencia: </strong>
      Has elegido la opción de
      <strong className="ml-1">
        {`${commonExpenseLabelByCountry(country)} incluida dentro del arriendo`}
      </strong>
      . El valor indicado para {commonExpenseLabelByCountry(country)} será sólo referencial y
      <strong className="mx-1">no sera considerado en el precio final de arriendo y/o venta</strong>
      según corresponda.
    </Alert>
  );
}

export default CommonExpensesAlert;
