import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import { useParams } from "react-router-dom";

import { useEvaluation } from "context/evaluationContext/evaluationContext";
import BrokerageSwitchSelector from "./SwitchSelector";
import useStyles from "./EvaluationHeadStyles";
import { EVALUATION_FORM_URL } from "env";

function EvaluationHead() {
  const { state } = useEvaluation();
  const params = useParams<{ country: string }>();
  const { singleEvaluation, token } = state;
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Typography variant="h5" gutterBottom className={classes.evaluationTitle}>
            Evaluación: {singleEvaluation.codebtorName}
          </Typography>
        </Grid>
        <Grid item>{singleEvaluation.evaluations.length > 0 && <BrokerageSwitchSelector />}</Grid>
      </Grid>
      {token != null && (
        <a
          href={`${EVALUATION_FORM_URL}/${params.country}/evaluation?token=${token}`}
          target="_blank"
          rel="noopener noreferrer">
          Ir a evaluación de postulante
        </a>
      )}
    </Grid>
  );
}

export default EvaluationHead;
