import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { InputAdornment } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import TableRowImagePreviewer from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableRowImagePreviewer";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useProperty } from "context/propertyContext/propertyContext";
import {
  ControlledSelection,
  ControlledTextArea,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { updatePropertyInspectionReport } from "context/propertyContext/propertyActions";
import {
  inspectionReportFeaturesNameTranslation,
  inspectionReportQualificationOptions,
} from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";
import {
  InspectionReportFeature,
  InspectionReportFeatureUpdateData,
  InspectionReportGeneralSpaceUpdateDataForm,
  SpaceFeatureImagesType,
} from "services/propertyService/types";
import validationSchema from "./validationSchema";
import { parseInspectionReportGeneralSpaceUpdateDataForm } from "./utils";
import { getObjectLabel } from "utils";
import { filterImagesByName } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils";
import { GroupedInspectionReportSpace } from "models/InspectionReport";
import { useGetToken } from "hooks";
import useStyles from "./SpaceFeaturesEditRowStyles";

interface Props {
  currentSpace: GroupedInspectionReportSpace;
  currentSpaceFeaturesImages: SpaceFeatureImagesType;
  feature: InspectionReportFeature;
  setCurrentFeatureEditingName: Dispatch<SetStateAction<string>>;
}

function SpaceFeaturesEditRow({
  currentSpace,
  currentSpaceFeaturesImages,
  feature,
  setCurrentFeatureEditingName,
}: Props) {
  const { state, dispatch } = useProperty();
  const { getToken } = useGetToken();
  const classes = useStyles();

  const methods = useForm<InspectionReportGeneralSpaceUpdateDataForm>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<InspectionReportGeneralSpaceUpdateDataForm> = async (data) => {
    try {
      const sendData = parseInspectionReportGeneralSpaceUpdateDataForm(
        currentSpace.id,
        feature.name,
        data as InspectionReportFeatureUpdateData
      );
      const authToken = await getToken();
      await updatePropertyInspectionReport(
        state.inspectionReport.data.id,
        state.property.id,
        sendData,
        dispatch,
        authToken
      );
    } catch (err) {
      console.error(err);
    } finally {
      setCurrentFeatureEditingName(null);
    }
  };

  return (
    <FormProvider {...methods} key="SpaceFeatureRow">
      <form key="SpaceFeatureRow" onSubmit={methods.handleSubmit(onSubmit)}>
        <InspectionReportTableRow
          classes={{
            root: classes.rowRoot,
          }}
          isEdit={true}>
          <ControlledTextField
            label=""
            id="name"
            textFieldProps={{
              InputProps: {
                classes: {
                  input: classes.bold,
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <Edit className={classes.editIcon} />
                  </InputAdornment>
                ),
              },
            }}
            disabled
            defaultValue={getObjectLabel(inspectionReportFeaturesNameTranslation, feature.name)}
          />
          <ControlledSelection
            id="qualification"
            label=""
            defaultValue={feature.qualification}
            options={inspectionReportQualificationOptions}
            simple
            classes={{
              container: classes.container,
              label: classes.label,
              buttonList: classes.buttonList,
            }}
          />
          <ControlledTextArea
            id="observation"
            label=""
            defaultValue={feature.observation ?? ""}
            maxRows={2}
          />
          <TableRowImagePreviewer
            rowName={`${getObjectLabel(inspectionReportFeaturesNameTranslation, feature.name)} (${
              currentSpace.name
            })`}
            imagesArr={filterImagesByName(feature.name, currentSpaceFeaturesImages)}
          />
          <div>
            <LinkButton type="submit">Guardar</LinkButton>
            <LinkButton onClick={() => setCurrentFeatureEditingName(null)}>Cancelar</LinkButton>
          </div>
        </InspectionReportTableRow>
      </form>
    </FormProvider>
  );
}

export default SpaceFeaturesEditRow;
