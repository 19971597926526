import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import Form from "../Form";

const SalaryForm = () => {
  const { watch, getValues, setValue } = useFormContext<MemberInformation>();

  const [month1, month2, month3] = watch([
    "salaryIncomeMonth1",
    "salaryIncomeMonth2",
    "salaryIncomeMonth3",
  ]);

  const getNextMonth = (month: string) => {
    const nextMonth = (Number(month) + 1) % 12;
    return nextMonth === 0 ? "12" : nextMonth.toString();
  };

  useEffect(() => {
    if (getValues("otherIncomeMonth1")) setValue("otherIncomeMonth1", month1);
    if (getValues("otherIncomeMonth2")) setValue("otherIncomeMonth2", month2);
    if (getValues("otherIncomeMonth3")) setValue("otherIncomeMonth3", month3);
  }, [getValues, month1, month2, month3, setValue]);

  useEffect(() => {
    if (month1) {
      setValue("salaryIncomeMonth2", getNextMonth(month1));
      setValue("salaryIncomeMonth3", getNextMonth((Number(month1) + 1).toString()));
    }
  }, [month1, setValue]);

  return (
    <>
      {[...Array(3).keys()].map((liquidationId) => (
        <Form type="salary" key={liquidationId} liquidationId={(liquidationId + 1) as 1 | 2 | 3} />
      ))}
    </>
  );
};

export default SalaryForm;
