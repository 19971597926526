import { CountryUpperCase } from "models/Countries";
import { FormRadioOptions } from "./types";

const CL_RADIO_OPTIONS = {
  originOptions: [
    {
      label: "Propietario",
      value: "landlord",
    },
    {
      label: "Arrendatario",
      value: "tenant",
    },
  ],
  typeOptions: [
    {
      label: "Plomería / Gasfitería:",
      value: "plumbing",
      description: "Instalación y/o mantenimiento de tuberías (baño y cocina)",
    },
    {
      label: "Cerrajería:",
      value: "locksmith",
      description: "Instalación y mantenimiento de chapas",
    },
    {
      label: "Calefacción:",
      value: "heating",
      description: "Problemas con la calefacción",
    },
    {
      label: "Estructurales:",
      value: "structural",
      description: "Daños en pisos, techos y paredes",
    },
    {
      label: "Arreglos estéticos:",
      value: "cosmetic_repairs",
      description: "Pinturas y arreglos menores",
    },
    {
      label: "Eléctrico:",
      value: "electrical",
      description: "Daños y mantenimiento en el cableado o en tablero eléctrico",
    },
    {
      label: "Limpieza:",
      value: "cleaning",
      description: "Aplica al momento de la entrega",
    },
  ],
  responsibleOptions: [
    {
      label: "Field Ops Local",
      value: "field_ops_cl",
    },
    {
      label: "Field Ops Regional",
      value: "field_ops_regional",
    },
  ],
};

const CO_RADIO_OPTIONS = {
  originOptions: [
    {
      label: "Propietario",
      value: "landlord",
    },
    {
      label: "Arrendatario",
      value: "tenant",
    },
  ],
  typeOptions: [
    {
      label: "Plomería / Gasfitería:",
      value: "plumbing",
      description: "Instalación y/o mantenimiento de tuberías (baño y cocina)",
    },
    {
      label: "Cerrajería:",
      value: "locksmith",
      description: "Instalación y mantenimiento de chapas",
    },
    {
      label: "Calefacción:",
      value: "heating",
      description: "Problemas con la calefacción",
    },
    {
      label: "Estructurales:",
      value: "structural",
      description: "Daños en pisos, techos y paredes",
    },
    {
      label: "Arreglos estéticos:",
      value: "cosmetic_repairs",
      description: "Pinturas y arreglos menores",
    },
    {
      label: "Eléctrico:",
      value: "electrical",
      description: "Daños y mantenimiento en el cableado o en tablero eléctrico",
    },
    {
      label: "Limpieza:",
      value: "cleaning",
      description: "Aplica al momento de la entrega",
    },
  ],
  responsibleOptions: [
    {
      label: "Field Ops Local",
      value: "field_ops_cl",
    },
    {
      label: "Field Ops Regional",
      value: "field_ops_regional",
    },
  ],
};

const MX_RADIO_OPTIONS = {
  originOptions: [
    {
      label: "Propietario",
      value: "landlord",
    },
    {
      label: "Inquilino",
      value: "tenant",
    },
  ],
  typeOptions: [
    {
      label: "Plomería:",
      value: "plumbing",
      description: "Instalación y/o mantenimiento de tuberías (baño y cocina)",
    },
    {
      label: "Cerrajería:",
      value: "locksmith",
      description: "Instalación y mantenimiento de chapas",
    },
    {
      label: "Aire acondicionado:",
      value: "heating",
      description: "Problemas con el aparato de aire acondicionado",
    },
    {
      label: "Estructurales:",
      value: "structural",
      description: "Daños en pisos, techos y paredes",
    },
    {
      label: "Arreglos estéticos:",
      value: "cosmetic_repairs",
      description: "Pinturas y arreglos menores",
    },
    {
      label: "Eléctrico:",
      value: "electrical",
      description: "Daños y mantenimiento en el cableado o en estufas eléctricas.",
    },
    {
      label: "Limpieza:",
      value: "cleaning",
      description: "Aplica al momento de la entrega",
    },
  ],
  responsibleOptions: [
    {
      label: "Field Ops Local",
      value: "field_ops_cl",
    },
    {
      label: "Field Ops Regional",
      value: "field_ops_regional",
    },
  ],
};

const RADIO_OPTIONS_BY_COUNTRY: Record<CountryUpperCase, FormRadioOptions> = {
  Chile: CL_RADIO_OPTIONS,
  Colombia: CO_RADIO_OPTIONS,
  Mexico: MX_RADIO_OPTIONS,
  México: MX_RADIO_OPTIONS,
};

export default RADIO_OPTIONS_BY_COUNTRY;
