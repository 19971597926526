/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from "react";

import { GridOverlay } from "@material-ui/x-grid";

import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import useGetEvaluations from "domains/evaluations/hooks/useGetEvaluations";
import { EvaluationsFilter } from "domains/evaluations/models/Evaluations";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { Locale } from "models/Countries";

import useEvaluationsListTableStyles from "./EvaluationsListTableStyles";
import FilterModal from "./components/FilterModal";
import { getColumns } from "./utils/columns";
import Toolbar from "./components/Toolbar";

interface Props {
  country: Locale;
  filters: EvaluationsFilter;
  onChangeFilters: (filters: Partial<EvaluationsFilter>) => void;
}

const EvaluationsListTable = ({ country, filters, onChangeFilters }: Props) => {
  const { data, isError, isSuccess, isFetching } = useGetEvaluations(country, filters);
  const classes = useEvaluationsListTableStyles();
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columns = useMemo(() => getColumns({ country }), [country]);

  return (
    <div className="w-full h-full">
      <CustomDataGrid
        rowCount={data?.totalCount}
        columns={columns}
        rows={data?.evaluations}
        loading={isFetching}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => {
          onChangeFilters({ ...filters, page: params + 1 });
          dispatch(setCurrentPage(params + 1));
        }}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          onChangeFilters({ ...filters, size: params, page: 1 });
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          NoRowsOverlay: () => {
            if (isError)
              return (
                <GridOverlay>
                  <p className="text-p16">Ha ocurrido un error en la carga de datos</p>
                </GridOverlay>
              );
            if (!isSuccess && data.totalCount === 0)
              return (
                <GridOverlay>
                  <p className="text-p16">No hay evaluaciones</p>
                </GridOverlay>
              );
            return null;
          },
          ErrorOverlay: () => (
            <GridOverlay>
              <p className="text-p16">Ha ocurrido un error </p>
            </GridOverlay>
          ),
          Toolbar,
        }}
        classes={{
          columnHeader: classes.headerWrapper,
        }}
      />
      <FilterModal filters={filters} onSaveFilters={onChangeFilters} />
    </div>
  );
};

export default EvaluationsListTable;
