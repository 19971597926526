import { Theme, makeStyles } from "@material-ui/core";

export const useTextFieldStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formControl: {
    minWidth: "100%",
    [theme.breakpoints.down("md")]: {
      minWidth: "300px",
    },
  },
  labelFocused: {
    color: "#263238 !important",
    fontWeight: "bold",
  },
  label: {
    width: "100%",
    fontWeight: 700,
    color: "#263238",
    fontSize: "1rem",
    marginLeft: theme.spacing(4),
  },
  input: {
    padding: "0px !important",
    fontSize: "1rem",
    color: "#607D8B !important",
    minWidth: "100%",
    marginTop: "0px !important",
    height: 50,
  },
  helper: {
    fontSize: "0.65rem",
  },
  underline: {
    "&&&:before": {
      borderWidth: "0px",
      borderColor: theme.palette.grey["50"],
    },
    "&&:after": {
      borderWidth: "0px",
      borderColor: theme.palette.grey["50"],
    },
  },
}));

export const style = (theme: Theme) => ({
  itemWrapper: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formControl: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      minWidth: "300px",
    },
  },
  labelFocused: {
    color: "#263238 !important",
    fontWeight: "bold",
  },
  label: {
    width: "100%",
    fontWeight: 700,
    color: "#263238",
    fontSize: "1rem",
    minWidth: "230px",
  },
  input: {
    padding: "0px !important",
    fontSize: "1rem",
    color: "#607D8B !important",
    maxWidth: "360px",
  },
  helper: {
    fontSize: "0.65rem",
  },
  underline: {
    //Se quita la inferior si es necesario
    "&&&:before": {
      borderWidth: "0px",
    },
    "&&:after": {
      borderWidth: "0px",
    },
  },
});
