import { Action, State, ActionType } from "./mapTypes";

export const initialState: State = {
  map: null,
  loaded: false,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_MAP: {
      return { ...state, map: action.map };
    }
    case ActionType.SET_LOADED: {
      return { ...state, loaded: action.loaded };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
