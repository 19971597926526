import { useEffect, useState } from "react";
import { FormProvider, useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@material-ui/core";

import { useGetToken } from "hooks";
import {
  ControlledAutocomplete,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { fetchBasicServices, setBasicService } from "context/multifamilyContext/unit/unitActions";
import validationSchema from "./validationSchema";

import useStyles from "./BasicServicesModalStyles";
import BaseModal from "../../../../../BaseModal";

interface Props {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  onSuccess?: () => void;
}

interface Service {
  label: string;
  value: number;
}

function BasicServices({ open, onClose, showCloseButton = false, onSuccess }: Props) {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const [services, setServices] = useState<Service[]>([]);
  const [isSending, setIsSending] = useState(false);
  const {
    state: {
      property: { country, id },
    },
    dispatch,
  } = useMultifamilyUnit();

  useEffect(() => {
    getToken().then(async (authToken) => {
      const res = await fetchBasicServices(country, authToken);
      setServices(res);
    });
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onSubmit",
  });
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    getToken().then(async (authToken) => {
      setIsSending(true);
      try {
        await setBasicService(data.clientNumber, data.service.value, id, authToken, dispatch);
        onSuccess?.();
      } finally {
        setIsSending(false);
      }
    });
  };
  const textFieldProps = {
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.background,
      },
      placeholder: "Ingresar",
    },
  };
  return (
    <BaseModal open={open} onClose={onClose} showCloseButton={showCloseButton}>
      <div className={classes.container}>
        <Typography variant="h4"> Nuevo servicio </Typography>
        <Typography>
          Por favor selecciona el tipo de servicio que vas a agregar, la empresa que ofrece este
          servicio y escribe el número de cliente.
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContainer}>
            <div className={classes.inputsContainer}>
              <ControlledAutocomplete
                id="service"
                label="Selecciona el servicio"
                options={services}
                textFieldProps={textFieldProps}
              />
              <ControlledTextField
                id="clientNumber"
                label="Número de cliente"
                textFieldProps={textFieldProps}
              />
            </div>
            <DesignSystemButton
              label="Guardar"
              type="submit"
              size="medium"
              variant="primary"
              isLoading={isSending}
            />
          </form>
        </FormProvider>
      </div>
    </BaseModal>
  );
}
export default BasicServices;
