import * as yup from "yup";

export const requiredText = "Obligatorio";

interface IDocumentTypeList {
  value: number;
  label: string;
  regex: string;
}

export const verifikSchema = (documentTypeList: IDocumentTypeList[]) =>
  yup
    .object({
      document: yup.string().nullable().required(requiredText),
      documentType: yup.string().nullable().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.documentType) {
        return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
      }
      const regexValue = documentTypeList.filter(
        (doc) => doc.value === parseInt(obj.documentType)
      )[0];
      const regex = new RegExp(regexValue.regex);
      if (regex.test(obj.document.toUpperCase())) {
        return true;
      }
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
