import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledList } from "Components/Admin/UIComponents/ControlledForm";
import Spinner from "Components/Shared/partials/Spinner";
import { CountryUpperCase, LocaleCountries } from "models/Countries";
import useGetSimplePromotions from "hooks/useGetSimplePromotions";
import { useFormNotifications } from "hooks";

import { promotionFormSchema, requiredText } from "../../utils/validationSchema";
import useSelectPromotionFormStyles from "./SelectPromotionFormStyles";
import useApplyPromotion from "../../hooks/useApplyPromotion";
import { PromotionProps } from "../../utils/types";
import countryCode from "utils/countryCode";

interface Props {
  country: CountryUpperCase;
  contractId: number;
  appliedPromotions: number[];
  onFetchSimulation: ({ contractId, save }) => void;
}

const SelectPromotionForm = ({
  country,
  contractId,
  appliedPromotions = [],
  onFetchSimulation,
}: Props) => {
  const classes = useSelectPromotionFormStyles();
  const { data, isLoading } = useGetSimplePromotions(
    "actives",
    countryCode(country) as LocaleCountries
  );
  const { mutate, isLoading: isLoadingApply } = useApplyPromotion(onFetchSimulation, contractId);

  const promotions = data
    .filter((promotionData) => !appliedPromotions.includes(promotionData.id))
    .map((promotion) => ({
      ...promotion,
      label: promotion.promotionName,
      value: promotion.id,
    }));

  const methods = useForm<PromotionProps>({
    resolver: yupResolver(promotionFormSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<PromotionProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<PromotionProps> = (data) => {
    const parsedData = {
      contract: contractId,
      promotion: data.promotionId,
    };
    mutate(parsedData);
  };

  if (isLoading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item md={6} xs={12}>
            <ControlledList
              label="Selecciona la promoción"
              id="promotionId"
              defaultValue=""
              disableUnderline
              options={promotions}
              classes={{
                select: classes.select,
              }}
            />
          </Grid>
          <Grid container item md={6} xs={12} justifyContent="flex-end">
            <Grid item>
              <DesignSystemButton
                label="Aplicar promoción"
                type="submit"
                size="small"
                variant="primary"
                isLoading={isLoadingApply}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default SelectPromotionForm;
