import { makeStyles, Theme } from "@material-ui/core";

const useHeaderSelectorStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
  },
  inputsContainer: {
    maxWidth: "100%",
    display: "flex",
    gap: theme.spacing(5),
    alignItems: "flex-end",
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  selectInput: {
    borderRadius: 8,
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
  },
}));

export default useHeaderSelectorStyles;
