import { SnackbarProvider } from "notistack";

import { LeadProvider } from "context/leadContext/leadContext";
import { TabProvider } from "context/tabContext/tabContext";
import { TableProvider } from "context/tableContext/tableContext";
import { AvailavilityProvider } from "context/availabilityContext/availabilityContext";

import PropertyVisitsTabIndex from "./PropertyVisitsTabIndex";

export default function VisitsController({ property }) {
  return (
    <AvailavilityProvider>
      <LeadProvider>
        <SnackbarProvider>
          <TabProvider>
            <TableProvider>
              <PropertyVisitsTabIndex property={property} />
            </TableProvider>
          </TabProvider>
        </SnackbarProvider>
      </LeadProvider>
    </AvailavilityProvider>
  );
}
