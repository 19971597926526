import React from "react";
import clx from "classnames";

import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  Link,
  SvgIcon,
  SvgIconTypeMap,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import { Size, Variant } from "models/designSystem";
import useStyles from "./DesignSystemButtonStyles";
import { getCicularProgressSize } from "./DesignSystemUtils";
import { Link as RouterLink } from "react-router-dom";

export interface Props<C extends React.ElementType> {
  ariaLabel?: string;
  breakpoint?: Breakpoint;
  buttonClassName?: string;
  buttonProps?: Partial<ButtonProps & { component: C }>;
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  sameTab?: boolean;
  Icon?: React.ReactElement<OverridableComponent<SvgIconTypeMap>>;
  iconClassName?: string;
  id?: string;
  isLoading?: boolean;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size: Size;
  target?: "_blank" | "_self";
  type?: "submit" | "button";
  variant: Variant;
  labelClassName?: string;
}

function DesignSystemButton<C extends React.ElementType>({
  ariaLabel = "",
  breakpoint = "md",
  buttonClassName = "",
  buttonProps,
  disabled = false,
  fullWidth = false,
  href,
  sameTab = false,
  label,
  Icon,
  iconClassName = "",
  id,
  isLoading = false,
  onClick,
  size,
  target = "_self",
  type = "button",
  variant,
  labelClassName,
}: Props<C>) {
  const classes = useStyles({ breakpoint });

  const button = (
    <MuiButton
      {...buttonProps}
      className={clx(
        { [classes.fullWidth]: fullWidth },
        classes.base,
        classes[size],
        classes[variant],
        buttonClassName
      )}
      disabled={disabled || isLoading}
      id={id}
      onClick={(e) => onClick && onClick(e)}
      type={type}>
      {isLoading && (
        <CircularProgress
          size={getCicularProgressSize(size)}
          className={clx(classes.circularProgress, classes[`${variant}Color`], {
            [classes[`${variant}ColorDisabled`]]: disabled || isLoading,
          })}
        />
      )}
      <div
        className={clx(labelClassName, { [classes.loading]: isLoading }, classes.labelContainer)}>
        {Icon && (
          <SvgIcon
            className={clx(
              iconClassName,
              classes.icon,
              classes[`${variant}Color`],
              classes[`${size}Icon`]
            )}>
            {Icon}
          </SvgIcon>
        )}
        {label}
      </div>
    </MuiButton>
  );

  if (href) {
    if (sameTab) return <RouterLink to={href}>{button}</RouterLink>;
    return (
      <Link
        id={`${id}-link`}
        target={target}
        referrerPolicy="no-referrer"
        rel="noopener noreferrer"
        aria-label={ariaLabel}
        href={href}>
        {button}
      </Link>
    );
  }
  return button;
}

export default DesignSystemButton;
