import MapHandler from "libs/mapbox/MapHandler";

import { coLocation } from "./constants";
import HeaderSelector from "./HeaderSelector";
import MacrozoneMap from "./MacrozoneMap";
import useMacrozoneIndexStyles from "./MacrozoneIndexStyles";
import ModalMessage from "./ModalMessage";

function MacrozoneIndex() {
  const classes = useMacrozoneIndexStyles();
  return (
    <div className={classes.container}>
      <ModalMessage />
      <HeaderSelector />
      <MacrozoneMap />
      <MapHandler {...coLocation} />
    </div>
  );
}

export default MacrozoneIndex;
