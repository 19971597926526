import { InputAdornment } from "@material-ui/core";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { useProperty } from "context/propertyContext/propertyContext";
import useUserFormRole from "hooks/useUserFormRole";
import { useFormContext } from "react-hook-form";
import { numberToString } from "utils";

function Brokerage() {
  const { state } = useProperty();
  const { watch } = useFormContext();

  const role = useUserFormRole();

  const forRental = watch("for_rental", state.property?.for_rental);
  const forSale =
    role === "real_estate_developer" ? true : watch("for_sale", state.property?.for_sale);

  const country = watch("country", state.property?.country);
  if ((forRental || forSale) && country)
    return (
      <FormBlock>
        {country === "Chile" && role !== "real_estate_developer" && (
          <ControlledTextField id="pin" label="Rol" defaultValue={state.property?.pin} />
        )}
        {forRental && (
          <ControlledTextField
            id="lessor_brokerage_commission"
            label="Comisión Arriendo"
            defaultValue={numberToString(state.property?.lessor_brokerage_commission) || "50"}
            textFieldProps={{
              InputProps: {
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              },
            }}
          />
        )}
        {forSale && (
          <ControlledTextField
            id="brokerage_commission"
            label="Comisión Venta"
            defaultValue={
              numberToString(state.property?.sales_details?.brokerage_commission) || "1,5"
            }
            textFieldProps={{
              InputProps: {
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              },
            }}
          />
        )}
      </FormBlock>
    );
  return <div />;
}

export default Brokerage;
