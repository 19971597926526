import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: { width: "100%", height: "100%" },
  link: {
    "&, &:hover": {
      color: theme.palette.primary.main,
    },
  },
  table: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: "8px",
      backgroundColor: theme.palette.grey["50"],
      color: theme.palette.grey["500"],
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-cell": {
      paddingLeft: theme.spacing(2),
      borderBottomWidth: 0,
      borderTopWidth: 0,
    },
    "& .MuiDataGrid-row": {
      margin: theme.spacing(0.2, 0),
      transition: "0.3s",
      "&:hover": {
        fontWeight: 800,
        backgroundColor: "#F7F8FC",
      },
    },
  },
}));
