import { Grid, GridProps } from "@material-ui/core";

function Row({ children, ...props }: GridProps) {
  return (
    <Grid item xs={12} {...props}>
      {children}
    </Grid>
  );
}

export default Row;
