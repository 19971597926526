import Tag from "@houm-com/ui/Tag";
import { format, formatDistanceStrict } from "date-fns";
import esLocale from "date-fns/locale/es";

import { EvaluationComment, CommentTypeParsedEnum } from "domains/evaluations/models/Evaluations";

interface Props {
  comment: EvaluationComment;
}

const Comment = ({ comment }: Props) => (
  <div className="flex p-1 gap-2 flex-col">
    <div className="flex justify-between items-center gap-2">
      <p className="text-p16 font-semibold">
        {comment.commenterName}
        {" - "}
        <span className="text-p14 text-black-60">
          {`${format(new Date(comment.createdAt), "PPPpp", {
            locale: esLocale,
          })} - ${formatDistanceStrict(new Date(comment.createdAt), new Date(), {
            addSuffix: true,
            locale: esLocale,
          })}`}
        </span>
      </p>
      <Tag label={CommentTypeParsedEnum[comment.commentType]} />
    </div>
    <p className="text-p16 font-normal">{comment.comment}</p>
  </div>
);

export default Comment;
