import { Link } from "react-router-dom";

interface Props {
  contractId: number;
}

const Contract = ({ contractId }: Props) => (
  <p className="text-p16">
    {`Contrato generado: `}
    <Link to={`/admin/contracts/${contractId}`}>{contractId}</Link>
  </p>
);

export default Contract;
