import { Theme, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  labelFocused: {
    color: `${theme.palette.grey[900]}`,
    fontWeight: "bold",
  },
  label: {
    display: "contents",
    width: "100%",
    color: theme.palette.grey[900],
    fontSize: "0.8125rem",
    fontWeight: "bold",
  },
  input: {
    padding: theme.spacing(1, 0),
    fontSize: "1rem",
    marginTop: 0,
    color: `${theme.palette.grey[500]}`,
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
  helper: {
    fontSize: "0.65rem",
  },
  underline: {
    "&&&:before": {
      borderWidth: 1,
      borderColor: theme.palette.grey[50],
    },
    "&&:after": {
      borderWidth: 2,
      borderColor: theme.palette.grey[50],
    },
  },
}));
