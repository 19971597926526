import { Locale } from "models/Countries";
import { IWarrantyStatus } from "models/Warranty";

import DiscountForm from "./components/DiscountForm";
import ModalLayout from "../../../ModalLayout";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  contractId: number;
  country: Locale;
  status: IWarrantyStatus;
}

function CreateDiscountModal({ open, onHandleClose, contractId, country, status }: Props) {
  return (
    <ModalLayout open={open} handleOnClose={onHandleClose} title="Descuento">
      <DiscountForm
        contractId={contractId}
        country={country}
        onHandleClose={onHandleClose}
        status={status}
      />
    </ModalLayout>
  );
}

export default CreateDiscountModal;
