import { formatAddress } from "utils/createProperty";

export const parseBody = (data) => {
  const needToParseAddress = data.address.formatted_address;
  const formattedAddress = needToParseAddress ? formatAddress(data.address) : null;
  const neighborhood = data?.neighborhood;
  const parsedAddress = {
    address: formattedAddress?.street,
    latitude: formattedAddress?.latitude,
    longitude: formattedAddress?.longitude,
    neighborhood: neighborhood?.value,
    region: formattedAddress?.region,
    commune: formattedAddress?.commune,
    street_number: formattedAddress?.streetNumber,
  };
  const amenities = {
    association_name: null,
    floors: undefined,
    guest_parking_spaces: undefined,
    has_all_day_vigilance: data.has_all_day_vigilance,
    has_bbq_area: data.has_bbq_area,
    has_cinema: data.has_cinema,
    has_concierge: data.has_concierge,
    has_elevator: data.has_elevator,
    has_gourmet_space: data.has_gourmet_space,
    has_gym: data.has_gym,
    has_laundry: data.has_laundry,
    has_party_room: data.has_party_room,
    has_patio: data.has_patio,
    has_pet_area: data.has_pet_area,
    has_playground: data.has_playground,
    has_playroom: data.has_playroom,
    has_games_room: data.has_games_room,
    has_roof_garden: data.has_roof_garden,
    has_sauna: data.has_sauna,
    has_steam_room: data.has_steam_room,
    has_swimming_pool: data.has_swimming_pool,
    is_condo: undefined,
  };
  return {
    ...parsedAddress,
    country: data.country,
    houm_executive: data?.executiveOfBuilding?.id,
    name: data.buildingName,
    year_built: data.constructionYear,
    administration_email: data.emailOfAdministrator,
    administration_phone: `+${data.phoneOfAdministrator.country.callingCode}${data.phoneOfAdministrator.phone}`,
    concierge_name: data.conciergeName,
    concierge_email: data.emailOfConcierge,
    concierge_phone: `+${data.phoneOfConcierge.country.callingCode}${data.phoneOfConcierge.phone}`,
    landlord: data?.landlord?.id,
    association_amenities: amenities,
  };
};
