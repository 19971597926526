import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { Subscription } from "models/Subscription";
import { UserResponse } from "models/User";
import { IPlan } from "models/Plan";

import Movements from "./components/movements";
import { contractCurrencyParse } from "./utils/utils";

interface Props {
  subscription: Subscription;
  plan: IPlan;
}

const Payments = ({ subscription, plan }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="payments">
      <Movements
        country={subscription?.property.country}
        subId={subscription?.id}
        propertyId={subscription?.property?.id}
        landlordId={subscription?.property?.user?.id}
        plan={subscription?.contract?.plan ?? plan}
        landlordContract={subscription?.property?.user as UserResponse}
        tenantContract={subscription?.tenant as UserResponse}
        contractId={subscription?.contract.id}
        contractCurrency={
          contractCurrencyParse[subscription?.property.country][subscription?.contract?.type_money]
        }
      />
    </TabPanel>
  );
};

export default Payments;
