import { useState } from "react";

import { DialogContent, Grid, Typography } from "@material-ui/core";
import Button from "@houm-com/ui/Button";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useUserBankAccount from "hooks/useUserBankAccount";

import useApplicantBankAccountStyles from "./ApplicantBankAccountStyles";
import BankAccountInfo from "./components/BankAccountInfo";
import BankAccountForm from "./components/BankAccountForm";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
  userId: number;
  propertyId: number;
  cardClassName?: string;
}

const ApplicantBankAccount = ({ country, userId, propertyId, cardClassName }: Props) => {
  const classes = useApplicantBankAccountStyles();
  const { dataBankAccount, isLoadingBankAccount } = useUserBankAccount(country, userId, propertyId);
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => setOpenModal((prev) => !prev);

  return (
    <>
      <AdminCard
        title="Cuenta bancaria (reembolso)"
        className={cardClassName}
        showTitleBackground={true}
        withSpace={false}>
        <Loading loading={isLoadingBankAccount}>
          {dataBankAccount ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BankAccountInfo bankAccount={dataBankAccount} country={country} />
              </Grid>
              <Grid item xs={12}>
                <Button size="sm" variant="tertiary" onClick={handleModal}>
                  Actualizar cuenta bancaria
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button size="sm" variant="tertiary" onClick={handleModal}>
              Ingresar cuenta bancaria
            </Button>
          )}
        </Loading>
      </AdminCard>
      <DSModal open={openModal} onClose={handleModal} maxWidth="xs" showCloseButton scroll="paper">
        <DialogContent>
          <Typography className={classes.title}>Cuenta bancaria</Typography>
          <BankAccountForm
            country={country}
            bankAccount={dataBankAccount}
            userId={userId}
            propertyId={propertyId}
            handleClose={handleModal}
          />
        </DialogContent>
      </DSModal>
    </>
  );
};

export default ApplicantBankAccount;
