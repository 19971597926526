import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import {
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Drawer,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from "@material-ui/icons/Star";
import Group from "@material-ui/icons/Group";

import { ReactComponent as ChevronUp } from "assets/icons/arrows/chevron-up.svg";
import { ReactComponent as ChevronDown } from "assets/icons/arrows/chevron-down.svg";

import {
  emptyPropertyDetails,
  emptySalesDetails,
} from "Components/Admin/UIComponents/Cards/emptyDetails";
import ModifyPropertyForm from "Components/Admin/UIComponents/PropertyForm/ModifyPropertyForm";
import {
  PropertyData,
  PropertyRentalStatus,
  PropertySaleStatus,
  PropertyDetails,
  SalesDetails,
} from "models/Property";
import { validatePropertyForPublish } from "context/propertyContext/propertyActions";
import { setSingleProject } from "context/projectContext/projectActions";
// Hotfix! refactor needed
import { useProject } from "context/projectContext/projectContext";
import { AddTenant } from "Components/Admin/UIComponents/Modals";
import subscriptionService from "services/subscriptionService";
import { useSelectStyles } from "./BasicPropertyInfoStyle";
import propertyService from "services/propertyService";
import statusTranslator from "utils/statusTranslator";
import { generalOptions } from "utils/createProperty";
import { CountryUpperCase } from "models/Countries";
import EditIcon from "@material-ui/icons/Edit";
import { useGetToken } from "hooks";
import { validateThirdPartyToPublish, verifyHoumboxToPublish } from "context/propertyContext/utils";

import { useSnackbar } from "notistack";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import HeaderDataItem from "./HeaderDataItem";
import {
  ChangePropertyStatusOperationType,
  PropertyBusinessType,
} from "services/propertyVisibilityService/types";
import PropertyVisibilityManager from "./PropertyVisibilityManager";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import HoumOwnerModal from "../../components/BasicPropertyInfo/components/modals/HoumOwnerModal";
import HoumCheckerModal from "../../components/BasicPropertyInfo/components/modals/HoumCheckerModal";
import usePropertyAppraiser from "./hooks/usePropertyHoumer";
import HoumerModal from "../../components/BasicPropertyInfo/components/modals/HoumerModal";
import UnassignHoumerModal from "../../components/BasicPropertyInfo/components/modals/UnassignHoumerModal";
import useGuard from "domains/auth/hooks/useGuard";

interface Props {
  property: PropertyData;
  toogleEditProject: (e: boolean) => void;
}

const formatLastStatus = (extra: string, country: CountryUpperCase) => {
  const timezone = generalOptions[country].defaultZone;
  return !!extra && (moment.tz(extra, timezone || "America/Bogota").format("YYYY-MM-DD") || "-");
};

const publishErrorMessages = {
  COMMON_EXPENSES:
    "Para publicar la propiedad es necesario que los gastos comunes estén ingresados",
  MISSING_ENERGY_BILL:
    "Para publicar la propiedad es necesario que la cuenta de luz esté ingresada",
  MISSING_GAS_BILL: "Para publicar la propiedad es necesario que la cuenta de gas esté ingresada",
  MISSING_WATER_BILL:
    "Para publicar la propiedad es necesario que la cuenta de agua esté ingresada",
};

const getPublishErrorType = (message) => {
  if (message.includes("must have a water bill")) return "MISSING_WATER_BILL";
  if (message.includes("have an energy bill")) return "MISSING_ENERGY_BILL";
  if (message.includes("must have a gas bill")) return "MISSING_GAS_BILL";
  if (message.includes("must have common expenses")) return "COMMON_EXPENSES";
  return "UNHANDLED_ERROR";
};

const BasicPropertyInfo = ({ property, toogleEditProject }: Props) => {
  const { getToken } = useGetToken();
  const history = useHistory();
  const { dispatch } = useProject();
  const { enqueueSnackbar } = useSnackbar();

  const [homechecker, setHomechecker] = useState(false);
  const [changeHoumOwner, setChangeHoumOwner] = useState(false);
  const [changeHoumer, setChangeHoumer] = useState(false);
  const [unassignHoumer, setUnassignHoumer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openChangeVisibilityOperation, setOpenChangeVisibilityOperation] = useState<{
    operationType: ChangePropertyStatusOperationType;
    propertyMode: PropertyBusinessType;
  }>(null);
  const [showModal, setShowModal] = useState(false);
  const [flagLandlordVip, setFlagLandlordVip] = useState(false);
  const [openPopperMenu, setOpenPopperMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { guard } = useGuard();
  const allowEditProperty =
    guard.roles.any([
      "control_tower_agent",
      "sales",
      "control_tower_admin",
      "admin",
      "evaluation",
      "property_advisor",
      "management",
    ]) || guard.permissions.any(["update:property:all"]);

  const showOwnerDetails =
    guard.roles.any([
      "control_tower_agent",
      "sales",
      "control_tower_admin",
      "admin",
      "evaluation",
      "property_advisor",
      "management",
    ]) || guard.permissions.any(["read:property_owner:all"]);

  const handleTogglePopperMenu = () => {
    setOpenPopperMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenPopperMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopperMenu(false);
    }
  }

  const classes = useSelectStyles();

  const propertyDetails =
    property?.property_details?.length > 0 ? property.property_details[0] : emptyPropertyDetails;

  const salesDetails = property?.sales_details || emptySalesDetails;

  useEffect(() => {
    const fetchLandlordVipFlag = async (authToken) => {
      const res = await subscriptionService.getFlagLandlordsVipProperty(property.id, authToken);
      if (!res) return;
      setFlagLandlordVip(res.data.is_vip);
    };
    getToken().then((authToken) => {
      fetchLandlordVipFlag(authToken);
    });
  }, []);

  const alertSuccess = () => {
    Swal.fire(
      "Success",
      "Se ha actualizado el estado de la propiedad correctamente",
      "success"
    ).then(() => history.go(0));
  };

  const alertError = (error: string) => {
    Swal.fire({
      title: "Ha ocurrido un error",
      text: `ERROR: ${error}`,
      type: "error",
    });
  };

  const handlePublishForRent = async () => {
    const isValidForPublish = validatePropertyForPublish(property);

    const thirdPartyErrors = validateThirdPartyToPublish(property);
    const houmboxErrors = verifyHoumboxToPublish(property);

    [...houmboxErrors.errors, ...thirdPartyErrors.errors].forEach((err) => enqueueSnackbar(err));

    if (!isValidForPublish || thirdPartyErrors.withError || houmboxErrors.withError) return;

    const authToken = await getToken();

    try {
      await propertyService.publishForRent(property.id, authToken);
      alertSuccess();
    } catch (err) {
      const error = err as AxiosError<{ errors: string[] }>;
      const { response } = error;
      if (response.status === 403) {
        const msg = response.data.errors;
        const msgString = msg.join(" ");
        const errorType = getPublishErrorType(msgString);
        const handledErrorMessage = publishErrorMessages[errorType] || msgString;
        alertError(handledErrorMessage);
      }
    }
  };

  const handleRepublishForRent = async () => {
    const isValidForPublish = validatePropertyForPublish(property);
    if (!isValidForPublish) return;
    const authToken = await getToken();
    try {
      await propertyService.rePublishForRent(property.id, authToken);
      alertSuccess();
    } catch (err) {
      const error = err as AxiosError<{ errors: string[] }>;
      const { response } = error;
      if (response.status === 403) {
        const msg = response.data.errors;
        const msgString = msg.join(" ");
        const errorType = getPublishErrorType(msgString);
        const handledErrorMessage = publishErrorMessages[errorType] || msgString;
        alertError(handledErrorMessage);
      }
    }
  };

  const handlePublishForSale = async () => {
    const isValidForPublish = validatePropertyForPublish(property);
    if (!isValidForPublish) return;
    const authToken = await getToken();
    try {
      await propertyService.publishForSale(property.id, authToken);
      alertSuccess();
    } catch (err) {
      const error = err as AxiosError<{ errors: string[] }>;
      const { response } = error;
      if (response.status === 403) {
        const msg = response.data.errors;
        const msgString = msg.join(" ");
        const errorType = getPublishErrorType(msgString);
        const handledErrorMessage = publishErrorMessages[errorType] || msgString;
        alertError(handledErrorMessage);
      }
    }
  };

  const handleReserveProperty = async () => {
    try {
      const authToken = await getToken();
      await propertyService.reserve(property.id, authToken);
      alertSuccess();
    } catch (err) {
      const error = err as AxiosError;
      const { response } = error;
      if (response.status === 403) {
        const msg = response.data[0];
        alertError(msg);
      }
    }
  };

  const handleSetSoldProperty = async () => {
    try {
      const authToken = await getToken();
      await propertyService.sold(property.id, authToken);
      alertSuccess();
    } catch (err) {
      const error = err as AxiosError;
      const { response } = error;
      if (response.status === 403) {
        const msg = response.data[0];
        alertError(msg);
      }
      console.error(error);
    }
  };

  const handleEditProject = async () => {
    const authToken = await getToken();
    await setSingleProject(property.id, dispatch, authToken);
    toogleEditProject(true);
  };

  const ReserveItemMenu = useMemo(() => {
    if (propertyDetails.status === "published" || propertyDetails.status === "republished") {
      return (
        <MenuItem
          key="Rerserve"
          onClick={() => {
            setOpenPopperMenu(false);
            handleReserveProperty();
          }}>
          Reservar propiedad
        </MenuItem>
      );
    }
    return false;
  }, [propertyDetails]);

  const ContractItemMenu = useMemo(() => {
    if (property.country === "Colombia") {
      return (
        <MenuItem
          key="Create contract"
          onClick={() => {
            setOpenPopperMenu(false);
            setShowModal(true);
          }}>
          Crear contrato/mandato
        </MenuItem>
      );
    }
  }, [property.country]);

  const SoldItemMenu = useMemo(() => {
    if (salesDetails.status === "published") {
      return (
        <MenuItem
          key="Set Sold"
          onClick={() => {
            setOpenPopperMenu(false);
            handleSetSoldProperty();
          }}>
          Marcar como vendida
        </MenuItem>
      );
    } else {
      return null;
    }
  }, [salesDetails.status]);

  const EditPropertyMenu = useMemo(
    () => (
      <MenuItem
        key="Edit"
        onClick={() => {
          setOpenPopperMenu(false);
          setVisible(true);
        }}>
        Editar
      </MenuItem>
    ),
    []
  );

  const PipedriveMenu = useMemo(
    () => (
      <MenuItem
        key="Pipedrive"
        onClick={() => {
          setOpenPopperMenu(false);
          window.open(`https://arriendoasegurado.pipedrive.com/deal/${property.deal_id}`);
        }}>
        Ver en pipedrive
      </MenuItem>
    ),
    [property.deal_id]
  );

  const newMenu = [
    allowEditProperty && EditPropertyMenu,
    PipedriveMenu,
    property?.for_rental && ReserveItemMenu,
    property?.for_sale && SoldItemMenu,
    property?.country && ContractItemMenu,
  ];

  const isPublishedOnRent =
    propertyDetails.status === "published" || propertyDetails.status === "republished";
  const isPublishedOnSale =
    salesDetails.status === "published" || salesDetails.status === "republished";
  const isReserved = propertyDetails.status === "reserved";

  const availableForRent = property?.for_rental && !isPublishedOnRent;
  const availableForSale = property?.for_sale && !isPublishedOnSale;

  const isRentedOrUnpublished =
    propertyDetails.status === "rented" || propertyDetails.status === "unpublished";
  const canBeSetAsLostForRental = property?.for_rental && isRentedOrUnpublished;

  const DrowdownIcon = useMemo(() => (openPopperMenu ? ChevronUp : ChevronDown), [openPopperMenu]);

  const notRealEstateDeveloper = property?.user?.role !== "real_estate_developer";

  const { appraiser, isError, refetch: refetchHoumer } = usePropertyAppraiser(property.id);
  const houmer = isError ? undefined : appraiser;

  return (
    <>
      <AdminCard title="Datos del Lead" className={classes.cardRoot}>
        <Box className={classes.cardHeaderContainer}>
          {property?.user?.role === "real_estate_developer" && (
            <DesignSystemButton
              size="medium"
              label="Actualizar"
              variant="tertiaryA"
              onClick={handleEditProject}
              buttonClassName={classes.updateButton}
            />
          )}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {showOwnerDetails ? (
              <>
                <HeaderDataItem
                  label="Fecha de creación"
                  value={moment(property?.created_at).format("DD-MM-YYYY")}
                />
                <HeaderDataItem
                  label="Nombre del propietario"
                  value={`${property?.user?.name} ${property?.user?.last_name ?? ""}`}
                />
                <HeaderDataItem label="Correo" value={property?.user?.email} />
                <HeaderDataItem label="Teléfono" value={property?.user?.phone} />
              </>
            ) : (
              <Typography>Información no disponible</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.personEditable}>
              <HeaderDataItem label="HC" value={property?.homechecker?.name} />
              <Typography
                component="span"
                onClick={() => setHomechecker(true)}
                className={classes.changeButton}>
                {property?.homechecker?.name ? "Cambiar" : "Asignar"}
                <EditIcon className={classes.editIcon} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AdminCard>
      <AdminCard title={`Propiedad ID ${property?.id}`} elevation={0} className={classes.cardRoot}>
        <Box className={classes.cardHeaderContainer}>
          <Box>
            {flagLandlordVip && (
              <Chip
                icon={<StarIcon className={classes.vipIcon} />}
                label="Propietario VIP"
                variant="default"
                className={classes.chip}
              />
            )}
            {property?.investment_type === "multifamily" && (
              <Chip
                icon={<Group className={classes.multifamilyIcon} />}
                label="Multifamily"
                variant="default"
                className={classes.chip}
              />
            )}
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className={classes.personEditable}>
              <HeaderDataItem
                label="PA"
                value={`${property?.houm_owner?.name} ${property?.houm_owner?.last_name ?? ""}`}
              />
              <Typography
                component="span"
                onClick={() => setChangeHoumOwner(true)}
                className={classes.changeButton}>
                {property?.houm_owner ? "Cambiar" : "Asignar"}
                <EditIcon className={classes.editIcon} />
              </Typography>
            </Box>
            <Box className={classes.personEditable}>
              <HeaderDataItem label="Houmer" value={houmer?.name ?? "Sin asignar"} />
              <Typography
                component="span"
                onClick={() => setChangeHoumer(true)}
                className={classes.changeButton}>
                {houmer ? "Cambiar" : "Asignar"}
                <EditIcon className={classes.editIcon} />
              </Typography>
              {houmer && (
                <Typography
                  component="span"
                  onClick={() => setUnassignHoumer(true)}
                  className={classes.changeButton}>
                  Quitar
                  <CloseIcon className={classes.editIcon} />
                </Typography>
              )}
            </Box>
            {property?.for_rental && (
              <HeaderDataItem
                label="Estado renta"
                value={statusTranslator(propertyDetails?.status as PropertyRentalStatus)}
              />
            )}
            {property?.for_sale && (
              <HeaderDataItem
                label="Estado venta"
                value={statusTranslator(salesDetails?.status as PropertySaleStatus)}
              />
            )}
            {propertyDetails?.last_status_change && property?.for_rental && (
              <HeaderDataItem
                label="Último estado"
                value={formatLastStatus(
                  (propertyDetails as PropertyDetails).last_status_change,
                  property?.country
                )}
              />
            )}
            {propertyDetails?.last_status_change && property?.for_sale && (
              <HeaderDataItem
                label="Último estado"
                value={formatLastStatus(
                  (salesDetails as SalesDetails).last_status_change,
                  property?.country
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} className={classes.actionButtonContainer}>
            {handlePublishForSale && (
              <Popper
                open={openPopperMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.popper}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openPopperMenu}
                          id="menu-list-grow"
                          onKeyDown={(e) => handleListKeyDown(e)}>
                          {newMenu.map((menu) => menu)}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )}
            {notRealEstateDeveloper && (
              <Typography
                aria-haspopup="true"
                aria-controls="menu-list-grow"
                className={classes.actionsDropdownTrigger}
                innerRef={anchorRef}
                onClick={handleTogglePopperMenu}>
                Acciones <DrowdownIcon className={classes.dropdownIcon} />
              </Typography>
            )}
            <Box className={classes.actionsButtonsWrapper}>
              <Box className={classes.actionButtonsRow}>
                {canBeSetAsLostForRental && notRealEstateDeveloper && (
                  <DesignSystemButton
                    label="Dar por perdida para arriendo"
                    size="small"
                    variant="secondaryB"
                    onClick={() =>
                      setOpenChangeVisibilityOperation({
                        operationType: ChangePropertyStatusOperationType.SET_AS_LOST,
                        propertyMode: PropertyBusinessType.RENT,
                      })
                    }
                  />
                )}
                {isPublishedOnRent && notRealEstateDeveloper && (
                  <DesignSystemButton
                    label="Despublicar para renta"
                    size="small"
                    variant="primaryB"
                    onClick={() =>
                      setOpenChangeVisibilityOperation({
                        operationType: ChangePropertyStatusOperationType.UNPUBLISH,
                        propertyMode: PropertyBusinessType.RENT,
                      })
                    }
                  />
                )}
                {availableForRent && notRealEstateDeveloper && (
                  <DesignSystemButton
                    label="Publicar para arriendo"
                    size="small"
                    variant="primaryB"
                    onClick={isReserved ? handleRepublishForRent : handlePublishForRent}
                  />
                )}
              </Box>
              {isPublishedOnSale && (
                <DesignSystemButton
                  label="Despublicar para venta"
                  size="small"
                  variant="primaryB"
                  onClick={() =>
                    setOpenChangeVisibilityOperation({
                      operationType: ChangePropertyStatusOperationType.UNPUBLISH,
                      propertyMode: PropertyBusinessType.SALE,
                    })
                  }
                />
              )}
              {availableForSale && (
                <DesignSystemButton
                  label="Publicar para venta"
                  size="small"
                  variant="primaryB"
                  onClick={handlePublishForSale}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </AdminCard>
      <AddTenant
        showModal={showModal}
        setShowModal={(action) => setShowModal(action)}
        country={property.country}
        propertyId={property.id}
      />
      <PropertyVisibilityManager
        open={openChangeVisibilityOperation?.operationType}
        onClose={() => setOpenChangeVisibilityOperation(null)}
        property={property}
        operationType={openChangeVisibilityOperation?.operationType}
        propertyMode={openChangeVisibilityOperation?.propertyMode}
      />
      <HoumCheckerModal
        actualHoumCheckerId={property?.homechecker ? property.homechecker.id : null}
        handleClose={() => setHomechecker(false)}
        propertyUid={property.uid}
        open={homechecker}
      />
      <HoumerModal
        open={changeHoumer}
        handleClose={() => setChangeHoumer(false)}
        actualHoumer={houmer ?? null}
        propertyId={property?.id}
        refetchHoumer={refetchHoumer}
      />
      <UnassignHoumerModal
        open={unassignHoumer}
        handleClose={() => setUnassignHoumer(false)}
        propertyId={property?.id}
        refetchHoumer={refetchHoumer}
      />
      <HoumOwnerModal
        open={changeHoumOwner}
        handleClose={() => setChangeHoumOwner(false)}
        actualHoumOwner={property?.houm_owner ?? null}
        propertyId={property?.id}
      />
      <Drawer
        anchor="bottom"
        open={visible}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setVisible(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <ModifyPropertyForm property={property} />
      </Drawer>
    </>
  );
};

export default BasicPropertyInfo;
