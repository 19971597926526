import { SnackbarProvider } from "notistack";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useCreatePaymentButtonStyles from "./CreatePaymentButtonStyles";
import CreatePaymentForm from "./CreatePaymentForm";
import { EvaluationModel } from "models/Evaluation";
import { ContractModel } from "models/Contract";

interface Props {
  contract: ContractModel;
  evaluation: EvaluationModel[];
  brokerage: boolean;
}

function CreatePaymentButton({ contract, evaluation, brokerage }: Props) {
  const classes = useCreatePaymentButtonStyles();

  return (
    <CollapseCard title="Generar botón de pago">
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <Typography component="h6" className={classes.textLabel}>
                  Envía el <strong>botón de pago</strong> con el siguiente botón
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {contract.status !== "Boton de pago enviado" ? (
                  <SnackbarProvider>
                    <CreatePaymentForm
                      contract={contract}
                      country={contract.property.country}
                      evaluation={evaluation}
                      brokerage={brokerage}
                    />
                  </SnackbarProvider>
                ) : (
                  <Typography component="h6" className={classes.textLabel}>
                    El botón de pago ya ha sido creado.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </CollapseCard>
  );
}

export default CreatePaymentButton;
