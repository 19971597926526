import { TextField, OutlinedTextFieldProps } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";

import useSearchbarStyles from "./SearchbarStyles";

type AutocompletProps = Omit<React.ComponentProps<typeof Autocomplete>, "renderInput">;

interface Props extends AutocompletProps {
  textFieldProps?: OutlinedTextFieldProps;
}

function Searchbar(props: Props) {
  const classes = useSearchbarStyles();
  return (
    <Autocomplete
      className={classes.autocomplete}
      {...props}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          {...props?.textFieldProps}
          placeholder={props.placeholder ?? "Buscar"}
          InputProps={{
            ...params.InputProps,
            ...props?.textFieldProps?.InputProps,
            startAdornment: <SearchOutlined />,
            classes: {
              notchedOutline: classes.outline,
            },
          }}
        />
      )}
    />
  );
}

export default Searchbar;
