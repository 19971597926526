import { Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useTag } from "context/tagContext/tagContext";
import { deleteTag, getTags, setShowDeleteModal } from "context/tagContext/tagActions";
import { Tag } from "context/tagContext/tagTypes";
import useStyles from "./useDeleteModalStyles";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useGetToken } from "hooks";

interface Props {
  tag: Tag;
}

function ConfirmModal({ tag }: Props) {
  const { state, dispatch } = useTag();
  const classes = useStyles();
  const { getToken } = useGetToken();

  const handleConfirmDelete = async () => {
    const authToken = await getToken();
    await deleteTag(tag.associationId, dispatch, authToken);
    dispatch(setShowDeleteModal(false));
    await getTags(state.filters.id, dispatch, authToken);
  };

  return (
    <CustomCenteredModal open={state.showDeleteModal} title="Eliminar tag marketplace">
      <div className={classes.modal}>
        <Typography id="modalContent" className={classes.content}>
          Estás por <strong>eliminar el tag "{tag?.name}"</strong> del{" "}
          <strong>ID {state.filters.id}</strong>. Al ser eliminado no se podrá deshacer esta acción.
        </Typography>
        <Typography id="modalContent" className={classes.content}>
          <strong>¡No lo olvides!</strong> Esta acción puede{" "}
          <strong>tardar unos minutos en verse reflejada en houm.com</strong>
        </Typography>
        <div className={classes.buttonsContainer}>
          <LinkButton
            classes={{
              root: classes.cancelButton,
            }}
            onClick={() => dispatch(setShowDeleteModal(false))}>
            Cancelar
          </LinkButton>
          <DesignSystemButton
            label="Eliminar"
            size="medium"
            isLoading={state.loadingPost}
            variant="primary"
            onClick={() => handleConfirmDelete()}
          />
        </div>
      </div>
    </CustomCenteredModal>
  );
}

export default ConfirmModal;
