import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "libs/alerts";

import newPaymentService from "services/newPaymentService";
import { useGetToken } from "hooks";

import { IRefoundReservation } from "../utils/types";

const useRefundReservation = (handleModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: IRefoundReservation) => {
      const authToken = await getToken();
      const response = await newPaymentService.createRefund(
        formData.country,
        formData.reservationId,
        formData.parsedData,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reservationUserById");
        queryClient.invalidateQueries("refundsByUser");
        handleModal();
        alert.success({
          message: "Reembolso creado",
          disclaimer: "El reembolso se ha creado correctamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo crear el reembolso de la reserva",
          disclaimer: reason?.data?.message ?? "Error al crear el reembolso",
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useRefundReservation;
