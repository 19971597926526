import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import contractService from "services/contractService";
import { IContractExitProcess } from "models/Contract";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

const useUpdateExitProcessDate = (exitProcess: IContractExitProcess, closeModal: () => void) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    async ({ agreed_date }: { agreed_date: string }) => {
      const authToken = await getToken();
      const args: [number, string, { agreed_date: string }] = [
        exitProcess.contract_id,
        authToken,
        { agreed_date },
      ];
      await contractService.updateAgreedDateExitProcess(...args);
    },
    {
      onSuccess: () => {
        closeModal();
        alert.success({
          message: "Fecha de salida actualizada",
        });
        queryClient.invalidateQueries("exitProcess");
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "Error al actualizar la fecha de salida",
          disclaimer: `Error: ${reason ? reason?.data?.detail : e}`,
        });
      },
    }
  );

  return { mutate, isLoading, isSuccess };
};

export default useUpdateExitProcessDate;
