import { makeStyles, Theme } from "@material-ui/core";

const ConfirmModalStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginTop: theme.spacing(2),
    "& ul": {
      marginTop: theme.spacing(),
      listStylePosition: "inside",
    },
  },
}));

export default ConfirmModalStyles;
