import { useEffect, useMemo } from "react";
import capitalize from "lodash/capitalize";
import { useGetToken } from "hooks";

import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import TabPanel from "Components/Admin/UIComponents/Tabs/TabPanel";
import { CHANGE_COUNTRY } from "context/contractContext/contractTypes";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { getContractList, setFilters } from "context/contractContext/contractActions";
import { useTab } from "context/tabContext/tabContext";
import { useContract } from "context/contractContext/contractContext";
import { tabOptions, tabsIndexs } from "context/contractContext/utils";
import { useTable } from "context/tableContext/tableContext";
import { CountryUpperCase } from "models/Countries";
import Filterbar from "./components/Filterbar";
import FilterModal from "./components/FilterModal";
import Tagbar from "./components/Tagbar";
import Table from "./components/table";
import Header from "Components/Admin/administrator/demand/components/NewHeaderWithCountries";

function NewContractList() {
  const { state: tableStates, dispatch: dispatchTable } = useTable();
  const { pageSize, currentPage } = tableStates;
  const { state, dispatch } = useContract();
  const { getToken } = useGetToken();
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  useEffect(() => {
    dispatchTable(setPageSize(10));
    dispatchTable(setCurrentPage(0));
    dispatch(
      setFilters({
        ...state.filters,
      })
    );
  }, []);

  useEffect(() => {
    dispatchTable(setCurrentPage(0));
  }, [currentTabValue]);

  useMemo(() => {
    getToken().then((authToken) => {
      getContractList(
        pageSize,
        currentPage,
        currentTabValue,
        capitalize(state.country),
        authToken,
        state.filters,
        dispatch
      );
    });
  }, [
    pageSize,
    currentPage,
    state.country,
    state.filters?.search,
    state.filters?.houm_owner,
    state.filters?.init_date,
    currentTabValue,
  ]);

  const onClickHandle = (country: CountryUpperCase | "") => {
    dispatchTable(setCurrentPage(0));
    dispatch({ type: CHANGE_COUNTRY, country });
  };

  return (
    <>
      <FilterModal />
      <Header title="Contratos" onCountryChange={onClickHandle} />
      <Filterbar />
      <Tagbar />
      <TabMenu options={tabOptions} />
      {tabsIndexs.map((tabIndex) => (
        <TabPanel key={`${tabIndex}-tab`} value={currentTabValue} index={tabIndex}>
          <Table status={tabIndex} />
        </TabPanel>
      ))}
    </>
  );
}

export default NewContractList;
