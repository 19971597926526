import { Grid } from "@material-ui/core";
import Spinner from "@houm-com/ui/Spinner";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { parseDocs } from "utils";

import useDocumentsCo from "../../../../hooks/useDocumentsCo";
import useStyles from "./DocumentsStyles";

interface Props {
  propertyId: number;
}

export default function Documents({ propertyId }: Props) {
  const classes = useStyles();
  const { data, isLoading, isSuccess, isError } = useDocumentsCo({ propertyId });

  const renderDocuments = () => {
    const documents = Object.keys(data).filter((document) => data[document]);

    if (!documents.length) return <p className="text-p16 font-normal">No hay documentos</p>;

    return (
      <ul className={classes.list}>
        {documents.map((document) => (
          <li key={document}>
            <a href={data[document]}>{parseDocs[document]}</a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Grid item xs={12}>
      <AdminCard title="Documentos" withSpace={false} showTitleBackground={true}>
        {isLoading && (
          <Spinner classNameContainer="flex justify-center relative" variant="primary" size="xl" />
        )}
        {isSuccess && renderDocuments()}
        {isError && <p className="text-p16 font-normal">Error al obtener documentos</p>}
      </AdminCard>
    </Grid>
  );
}
