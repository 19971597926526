import { makeStyles, Theme } from "@material-ui/core";

const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    height: "100%",
    width: "100%",
  },
}));

export default useTooltipStyles;
