import { UseQueryResult, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Evaluation, EvaluationSchema } from "domains/evaluations/models/Evaluations";
import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useEvaluation = ({ evaluationId, country }: { evaluationId?: string; country: Locale }) => {
  const { evaluationId: evaluationIdParams } = useParams<{ evaluationId: string }>();
  const { getToken } = useGetToken();

  const id = evaluationIdParams || evaluationId;

  const query: UseQueryResult<Evaluation, Error> = useQuery(
    ["evaluation", id],
    async () => {
      const authToken = await getToken();
      const { data } = await service.getEvaluation(id, country, authToken);
      return EvaluationSchema.parse(data);
    },
    {
      enabled: !!country,
      retry: false,
    }
  );

  return {
    ...query,
  };
};

export default useEvaluation;
