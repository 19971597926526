import { makeStyles, Theme } from "@material-ui/core";

const useQueryDisclaimerStyles = makeStyles<Theme>((theme) => ({
  disclaimerContainer: {
    backgroundColor: "#EAF1FB",
    padding: theme.spacing(1.25, 1.25, 0, 1.25),
    borderRadius: 4,
  },
  text: {
    color: "#2663BC",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    paddingBottom: theme.spacing(1.25),
  },
}));

export default useQueryDisclaimerStyles;
