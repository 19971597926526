import { AddressComponentItem } from "models/Address";

const addressObjectContains = (
  addressComponents: AddressComponentItem[],
  typeName: string,
  addressComponentName: string
): boolean => {
  if (!addressComponents) return false;
  return addressComponents.some(
    (addressItem) =>
      addressItem.types.includes(typeName) && addressItem.long_name === addressComponentName
  );
};

export default addressObjectContains;
