import getPrice from "@houm-com/formats/currency";

import { Typography } from "@material-ui/core";

import { getPersonWarranty } from "../../../../utils/utils";
import usePlanDetailsStyles from "./PlanDetailsStyles";

interface Props {
  planType: string | null;
  planName: string | null;
  readjust: string | null;
  adminCommission: string | null;
  planDiscount: string | null;
  warranty: string | null;
  personWarranty: string | null;
  currency: string | null;
}

const PlanDetails = ({
  planType,
  planName,
  readjust,
  adminCommission,
  planDiscount,
  warranty,
  personWarranty,
  currency,
}: Props) => {
  const classes = usePlanDetailsStyles();

  return (
    <>
      <Typography className={classes.text}>
        <b>Nombre:</b>
        {` ${planName ?? "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Tipo de plan: </b>
        {` ${planType ?? "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Reajuste:</b>
        {` ${readjust ?? "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Comisión:</b>
        {` ${adminCommission ? `${adminCommission}%` : "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Descuentos de comisiones:</b>
        {` ${planDiscount ? `${Number(planDiscount)}%` : "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Monto de la garantía:</b>
        {` ${
          warranty
            ? getPrice(Number(warranty), currency)
            : "Información no disponible, contrato antiguo."
        }`}
      </Typography>
      <Typography className={classes.text}>
        <b>¿Quien retiene la garantía?:</b>
        {` ${
          personWarranty
            ? getPersonWarranty[personWarranty]
            : "Información no disponible, contrato antiguo."
        }`}
      </Typography>
    </>
  );
};

export default PlanDetails;
