import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useModalButtonsStyles from "./ModalButtonsStyles";

interface Props {
  onHandleClose: () => void;
  isLoading?: boolean;
}

const ModalButtons = ({ onHandleClose, isLoading = false }: Props) => {
  const classes = useModalButtonsStyles();

  return (
    <Grid container justifyContent="flex-end" alignItems="center" className={classes.submitButton}>
      <DesignSystemButton
        variant="tertiaryB"
        label="Cancelar"
        size="small"
        onClick={onHandleClose}
      />
      <DesignSystemButton
        variant="primary"
        label="Guardar"
        size="small"
        type="submit"
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default ModalButtons;
