import { useCallback } from "react";

import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { clearAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";

import useApplicant from "./hooks/useApplicant";
import Applicant from "./Applicant";

const ApplicantShow = () => {
  const { applicantData, applicantLoading } = useApplicant();

  const {
    state: { alertInfo },
    dispatch,
  } = useAlert();

  const handleSnackbarClose = useCallback(() => {
    dispatch(clearAlertInfo());
  }, []);

  return (
    <>
      <Loading loading={applicantLoading}>
        <Applicant applicant={applicantData} />
      </Loading>
      <DesignSystemSnackbar
        type={alertInfo.type}
        open={alertInfo.open}
        onClose={handleSnackbarClose}
        head={alertInfo.head}
        body={alertInfo.body}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );
};

export default ApplicantShow;
