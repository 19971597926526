import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";

import { ControlledList, ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import { buttonGeneratorSchema, requiredText } from "./validationSchema";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useCreatePaymentFormStyles from "./CreatePaymentFormStyles";
import { getBrokerageTransfer, parseQuotationData } from "./utils";
import newEvaluationService from "services/newEvaluationService";
import bankAccountService from "services/bankAccountService";
import { useFormNotifications, useGetToken } from "hooks";
// import quotationService from "services/quotationService";
import applicantService from "services/applicantService";
import schedulesService from "services/schedulesService";
import contractService from "services/contractService";
import { EvaluationModel } from "models/Evaluation";
import { CountryUpperCase } from "models/Countries";
import { ContractModel } from "models/Contract";
import useTooltipStyles from "./TooltipStyles";
import { HoumerModel } from "models/Houmer";
import { countryCode } from "utils";

interface IData {
  houmerId: string;
  firstPaymentDate: string;
  payDay: number;
}

interface Props {
  contract: ContractModel;
  country: CountryUpperCase;
  evaluation: EvaluationModel[];
  brokerage: boolean;
}

interface IParseAppraissers {
  value: string;
  label: string;
}

function CreatePaymentForm({ contract, country, evaluation, brokerage }: Props) {
  const classesTooltip = useTooltipStyles();
  const classes = useCreatePaymentFormStyles();
  const { getToken } = useGetToken();
  const [loading, setLoading] = useState(false);
  const [loadingAppraisers, setLoadingAppraisers] = useState(true);
  const [loadingBank, setLoadingBank] = useState(true);
  const [appraisers, setAppraisers] = useState<IParseAppraissers[] | null>(null);
  const [, setApplicantId] = useState<string | null>(null);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [hasBankAccount, setHasBankAccount] = useState(false);

  const methods = useForm<IData>({
    resolver: yupResolver(buttonGeneratorSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IData>({ errors, requiredText });

  const fetchAppraisers = async (authToken: string) => {
    setLoadingAppraisers(true);
    const appraisersData = await schedulesService.getAllAppraisers(authToken);
    if (appraisersData) {
      const appraisersResponse: HoumerModel[] = appraisersData["data"];
      const parseAppraisers = appraisersResponse.map((appr) => ({
        value: appr.id,
        label: appr.name,
      }));
      const arrayWithDefult = [
        {
          value: "none",
          label: "Ningúno (traspaso)",
        },
        ...parseAppraisers,
      ];
      setAppraisers(arrayWithDefult);
    }
    setLoadingAppraisers(false);
  };

  const fetchEvaluations = async (authToken: string) => {
    if (contract.evaluation_ids) {
      const evaluations = await newEvaluationService.getByIds(
        contract.evaluation_ids,
        authToken,
        countryCode(contract.property?.country)
      );
      if (evaluations) {
        const evs = evaluations.data.evaluations;
        setApplicantId(evs[0].related_applicant_id);
      }
    }
  };

  const fetchBankAccount = async (authToken: string) => {
    const code = contract.property?.country ? countryCode(contract.property?.country) : null;
    const fetchedBankAccount = await bankAccountService.getByUserAndProperty(
      code,
      contract.property?.user?.id,
      contract.property?.id,
      authToken
    );
    if (fetchedBankAccount && [200, 201].includes(fetchedBankAccount.status)) {
      setHasBankAccount(true);
    }
    setLoadingBank(false);
  };

  useEffect(() => {
    getToken().then(async (authToken) => {
      fetchBankAccount(authToken);
      if (country !== "Colombia") fetchEvaluations(authToken);
      fetchAppraisers(authToken);
    });
  }, []);

  // const sendMailButtonPayment = async (authToken: string, quotationId: number) => {
  //   const q = await quotationService.send_payment_button(
  //     quotationId,
  //     contract.rent_application
  //       ? contract.rent_application.applicant.id
  //       : applicantId,
  //     authToken,
  //   );
  //   if (q) {
  //     Swal.fire(
  //       "Success",
  //       "Se envió el botón de pago correctamente",
  //       "success",
  //     );
  //   } else {
  //     Swal.fire(
  //       "Error",
  //       "Se ha producido un error, intente mas tarde",
  //       "error",
  //     );
  //   }
  //   setLoading(false);
  // };

  const createQuotation = async (authToken: string) => {
    const data = parseQuotationData(contract, evaluation, brokerage);
    if (contract.is_transfer === false) {
      await applicantService
        .generateUpdateQuotation(data, authToken)
        .then(async (e) => {
          await createNewPayment(authToken, e.data.quotation_id);
        })
        .catch((e) => {
          Swal.fire({
            type: "error",
            title: "Error",
            text: `No se ha podido crear la quotation. Error: ${e}`,
          });
          setLoading(false);
        });
    } else {
      await applicantService
        .generatePaymentButton(data, authToken)
        .then(async () => {
          Swal.fire({
            type: "success",
            title: "Éxito",
            text: `Transferencia activada`,
          });
          setLoading(false);
        })
        .catch((e) => {
          Swal.fire({
            type: "error",
            title: "Error",
            text: `No se ha podido crear la quotation. Error: ${e}`,
          });
          setLoading(false);
        });
    }
  };

  const manageEndpoint = async (authToken: string) => {
    if (contract.is_transfer || contract.quotation === null) {
      await createQuotation(authToken);
    } else {
      await createNewPayment(authToken, contract.quotation.id);
    }
  };

  const makeSimulation = async () => {
    Swal.fire({
      title: "Atención",
      text: "Se va a generar el botón de pago según la simulación mostrada en la tarjeta 'Detalles del plan', una vez realizada está acción no se podrá a volver a realizar una simulación, ¿Está seguro que quiere continuar?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
    }).then(async (result) => {
      if (result.value) {
        const authToken = await getToken();
        if (contract.is_payment_simulation) {
          manageEndpoint(authToken);
        } else {
          await contractService
            .getSimulation(contract.id, true, authToken)
            .then(async () => {
              manageEndpoint(authToken);
            })
            .catch((e) => {
              Swal.fire({
                type: "error",
                title: "Error",
                text: `No se pudo crear botón de pago, debido a que no se pudo confirmar la simulación de pagos. Error: ${e}`,
              });
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
      }
    });
  };

  const updateContractStatus = async (authToken: string) => {
    try {
      const newStatusContract = contract;
      newStatusContract.status = "Boton de pago enviado";
      await contractService.update(newStatusContract.id, newStatusContract, authToken);
    } catch (e) {
      Swal.fire({
        type: "error",
        title: "Error",
        text: `Botón de pago creado, pero no se pudo actualizar estado de contrato a "Botón de pago enviado"`,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNewPayment = async (authToken: string, quotationId: number) => {
    await contractService
      .contractPaymentIncome(contract.id, authToken)
      .then(async () => {
        if (country === "Chile") {
          // sendMailButtonPayment(authToken, quotationId);
          await updateContractStatus(authToken);
          Swal.fire("Success", "Se creó el botón de pago correctamente", "success");
        } else {
          await updateContractStatus(authToken);
          Swal.fire("Success", "Se envió el botón de pago correctamente", "success");
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire(
          "Error",
          `Se ha producido un error enviando el botón de pago, intente mas tarde \n ${JSON.stringify(
            e.response?.data
          )}`,
          "error"
        );
      });
  };

  const onSubmit: SubmitHandler<IData> = () => {
    (async () => {
      setLoading(true);
      makeSimulation();
    })();
  };

  const getHoumer = () => {
    if (contract.is_transfer || !brokerage) {
      return "none";
    }
    return contract?.houmer ? contract?.houmer?.id : "";
  };

  return (
    <FormProvider {...methods}>
      <Loading loading={loadingAppraisers || loadingBank}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
          <Grid item container alignItems="flex-end">
            <>
              {getBrokerageTransfer(contract, brokerage) && (
                <Grid item xs={6}>
                  <ControlledList
                    label="Mostrador"
                    id="houmerId"
                    defaultValue={getHoumer()}
                    options={appraisers}
                    disableUnderline
                    classes={{
                      select: classes.select,
                    }}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <ControlledTextField
                  label="Fecha primer pago"
                  id="firstPaymentDate"
                  defaultValue={contract?.first_payment_date ? contract?.first_payment_date : ""}
                  textFieldProps={{
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                    },
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={8}>
                <ControlledTextField
                  label="Día de pago"
                  id="payDay"
                  defaultValue={contract?.pay_day ?? ""}
                  textFieldProps={{
                    placeholder: "Ingresar número, ej: 5",
                    type: "number",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                    },
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  open={onMouseOver && !hasBankAccount}
                  arrow
                  classes={classesTooltip}
                  title="Para poder generar botón de pago el propietario debe tener asociada una cuenta bancaria">
                  <div
                    className={classes.ctaContent}
                    onMouseOver={() => setOnMouseOver(true)}
                    onMouseOut={() => setOnMouseOver(false)}
                    onFocus={() => setOnMouseOver(true)}
                    onBlur={() => setOnMouseOver(false)}>
                    <CustomLoadingButton
                      text={
                        contract.is_transfer
                          ? "Activar automáticamente (SIN PAGO)"
                          : "Generar botón de pago"
                      }
                      loading={loading}
                      disabled={loading || !hasBankAccount}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </>
          </Grid>
        </form>
      </Loading>
    </FormProvider>
  );
}

export default CreatePaymentForm;
