import { makeStyles, Theme } from "@material-ui/core";

const usePageSelectorStyles = makeStyles((theme: Theme) => ({
  ul: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
}));

export default usePageSelectorStyles;
