import React from "react";

import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";
import { useProject } from "context/projectContext/projectContext";
import FormBlock from "./FormBlock";

function ProjectName() {
  const { state } = useProject();

  return (
    <FormBlock>
      <ControlledSelection
        id="country"
        label="País"
        defaultValue={state.project?.property?.country}
        options={[
          { value: "Chile", label: "Chile" },
          { value: "Colombia", label: "Colombia" },
          { value: "Mexico", label: "México" },
        ]}
      />
    </FormBlock>
  );
}

export default React.memo(ProjectName);
