import { FieldError } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { MuiPickersUtilsProvider, KeyboardTimePicker, TimePickerProps } from "@material-ui/pickers";
import moment from "moment";

import useStyles from "./InputTimePickerStyles";

interface InputTimePickerProps extends TimePickerProps {
  formError?: FieldError;
}

export default function InputTimePicker({ formError, ...props }: InputTimePickerProps) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <KeyboardTimePicker
        error={Boolean(formError)}
        helperText={formError?.message}
        InputProps={{
          classes: {
            root: classes.input,
            underline: classes.underline,
          },
        }}
        InputLabelProps={{
          className: classes.label,
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          },
        }}
        FormHelperTextProps={{ className: classes.helper }}
        variant="inline"
        keyboardIcon={<ScheduleIcon />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
