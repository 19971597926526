import { makeStyles, Theme } from "@material-ui/core";

const useOtherCompanyFormStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    marginBottom: theme.spacing(1),
  },
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttons: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  textInput: {
    background: theme.palette.grey[50],
    border: 4,
    paddingLeft: theme.spacing(1),
  },
  pickerInput: {
    background: theme.palette.grey[50],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& input": {
      color: theme.palette.grey[500],
    },
  },
  voucherLabel: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  voucherIcon: {
    "& path": {
      stroke: "#4C6977",
    },
  },
  voucherButton: {
    margin: 0,
    width: 300,
    justifyContent: "flex-start",
    border: "1px dashed #4C6977",
    color: "#4C6977",
    "&:hover": {
      border: "1px solid #4C6977",
      backgroundColor: "transparent",
    },
  },
  voucherHelper: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
}));

export default useOtherCompanyFormStyles;
