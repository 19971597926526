import { makeStyles, Theme } from "@material-ui/core";

const useBrokerageConfirmModalStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

export default useBrokerageConfirmModalStyles;
