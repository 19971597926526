import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";

import { PromotionFormData } from "../../../../utils/types";

interface Props {
  months: string;
}

const MonthlyDiscounts = ({ months }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<PromotionFormData>();
  return (
    <>
      {Array.from({ length: Number(months) }).map((_, index) => (
        <FieldInput
          id={`monthlyDiscounts.month${index}`}
          key={`monthlyDiscounts.month${index}`}
          label={`Mes ${index + 1}`}
          type="number"
          required
          placeholder="0%"
          errorMessage={errors.monthlyDiscounts?.[`month${index + 1}`]?.message}
          {...register(`monthlyDiscounts.month${index}`)}
          form="novalidateform"
        />
      ))}
    </>
  );
};

export default MonthlyDiscounts;
