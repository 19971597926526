import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import usePropertyStatisticsSummaryStyles from "./PropertyStatisticsSummaryStyles";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import propertyService from "services/propertyService";
import { PropertyData } from "models/Property";
import { useGetToken } from "hooks";

interface Props {
  property: PropertyData;
}

interface IStatistics {
  pageviews: number;
  all_visits: number;
  cancelled_visits: number;
  done_visits: number;
}

function PropertyStatisticsSummary({ property }: Props) {
  const { getToken } = useGetToken();
  const classes = usePropertyStatisticsSummaryStyles();
  const [information, setInformation] = useState<IStatistics | null>(null);
  const [loading, setLoading] = useState(true);

  const getStatistics = async (authToken: string) => {
    try {
      const result = await propertyService.newMarketplaceStatistics(property.uid, authToken);
      setInformation(result.data);
    } catch (e) {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudo obtener las estadísticas de agendamiento para la propiedad",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken().then((authToken) => getStatistics(authToken));
  }, [property.uid]);

  const renderCardInfo = (information: number, label: string) => (
    <Grid item xs={3}>
      <Card>
        <CardContent className={classes.card}>
          <Typography className={classes.info}>{information}</Typography>
          <Typography className={classes.label}>{label}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <CollapseCard title="Últimas 2 semanas">
      <Loading loading={loading}>
        <div className={classes.collapse}>
          <Grid container spacing={3}>
            {renderCardInfo(information?.pageviews, "Visitas en la página")}
            {renderCardInfo(information?.all_visits, "Visitas agendadas")}
            {renderCardInfo(information?.cancelled_visits, "Visitas canceladas")}
            {renderCardInfo(information?.done_visits, "Visitas finalizadas")}
            <Grid item xs={12}>
              <Typography className={classes.homecheckerLabel}>
                <strong>Homechecker:</strong>{" "}
                {property?.homechecker ? property?.homechecker.name : "No hay homechecker definido"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Loading>
    </CollapseCard>
  );
}

export default PropertyStatisticsSummary;
