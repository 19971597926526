import { UseQueryResult, useQuery } from "react-query";

import requestsService from "services/requestsService";
import { useGetToken } from "hooks";

import { REPAIRS_REQUESTS_LIST_ALERTS } from "./constants";
import { parseRepairsRequests } from "./utils";
import useQuotation from "../useQuotation";
import { IRepairsRequest } from "./types";
import { alert } from "libs/alerts";

const useRepairsRequests = () => {
  const { getToken } = useGetToken();
  const { subscription } = useQuotation();

  const { data, isLoading, isError }: UseQueryResult<IRepairsRequest[], Error> = useQuery<
    IRepairsRequest[],
    Error
  >(
    ["repairsRequestsList", subscription?.contract?.property?.id],
    async () => {
      const authToken = await getToken();
      const response = await requestsService.getRepairsRequests(
        authToken,
        subscription?.contract?.property?.id
      );
      return parseRepairsRequests(response.data);
    },
    {
      onError: () => alert.error(REPAIRS_REQUESTS_LIST_ALERTS.ERROR),
      enabled: !!subscription?.contract?.property?.id,
    }
  );

  return {
    repairsRequestsList: data,
    isLoading,
    isError,
  };
};

export default useRepairsRequests;
