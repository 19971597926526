import * as React from "react";
import { NavLink } from "react-router-dom";

import useStyles from "./CustomLinkStyles";

interface Props {
  to: string;
  text?: string;
  align?: string;
  navigation?: boolean;
  target?: string;
}

const CustomLink: React.FC<Props> = ({ to, text = "Ver", align, navigation = true, ...props }) => {
  const classes = useStyles({ align });
  if (navigation)
    return (
      <NavLink to={to} className={classes.link} {...props}>
        {text}
      </NavLink>
    );

  return (
    <a href={to} className={classes.link} {...props}>
      {text}
    </a>
  );
};

export default CustomLink;
