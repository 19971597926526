/* eslint-disable react/require-default-props */
import clx from "classnames";

import {
  TextField,
  FormControl,
  FormControlLabel,
  StandardTextFieldProps,
  FormHelperText,
} from "@material-ui/core";
import useStyles from "./CustomTextFieldStyles";

interface Props extends StandardTextFieldProps {
  errorMessage?: string;
  labelClassName?: string;
  bordered?: boolean;
  labelPlacement?: "top" | "bottom" | "end" | "start";
  wrapperPadding?: boolean;
  textFieldClassName?: string;
  wrapperClassName?: string;
  warning?: boolean;
  warningMessage?: string;
  disableUnderline?: boolean;
  maxLength?: number;
  inputClassName?: string;
}

const CustomTextField = ({
  error,
  label,
  errorMessage = "Error",
  InputProps,
  disabled = false,
  labelClassName = "",
  bordered = false,
  labelPlacement = "top",
  wrapperPadding = true,
  textFieldClassName = "",
  inputClassName = "",
  warning,
  warningMessage,
  disableUnderline = true,
  wrapperClassName,
  maxLength,
  ...props
}: Props) => {
  const classes = useStyles({ error, bordered, warning, wrapperPadding });

  const characterCount = () => {
    const text = (props?.value as string) || "";
    return `${text.length}/${maxLength}`;
  };

  return (
    <FormControl className={clx(classes.wrapper, wrapperClassName)} error={!!error}>
      <FormControlLabel
        classes={{
          label: clx(classes.label, labelClassName),
          root: classes.wrapper,
        }}
        control={
          <TextField
            disabled={disabled}
            error={error}
            className={clx(classes.textFieldRoot, textFieldClassName)}
            helperText={(error && errorMessage) || (warning && warningMessage)}
            InputProps={{
              disableUnderline,
              ...InputProps,
              classes: {
                root: inputClassName,
                underline: classes.underline,
                ...InputProps?.classes,
              },
            }}
            inputProps={{
              maxLength,
            }}
            {...props}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {maxLength && (
        <FormHelperText
          classes={{
            root: classes.characterCount,
          }}>
          {characterCount()}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomTextField;
