/* eslint-disable prefer-rest-params */
declare global {
  interface Window {
    hj: any;
    _hjSettings: { hjid: string; hjsv: string };
    _scriptPath: string;
  }
}

const scritpLoader = (hjid: string, hjsv = "6") => {
  ((h, o, t, j, a, r) => {
    h.hj =
      h.hj ||
      function load() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid, hjsv };
    [a] = o.getElementsByTagName("head");
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
};

const hj = (...params) => {
  if (window.hj) {
    window.hj(...params);
  }
};

const hotjar = {
  initialize: (id: string) => {
    scritpLoader(id);
  },
  identify: (userId?: string, properties?: Record<string, any> | undefined) => {
    hj("identify", userId, properties || {});
  },
  event: (event: string) => {
    hj("event", event);
  },
  stateChange: (relativePath: string) => {
    hj("stateChange", relativePath);
  },
};

export default hotjar;
