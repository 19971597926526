import { createTheme } from "@material-ui/core";

export const CustomThemePicker = (theme) =>
  createTheme({
    ...theme,
    // @ts-ignore
    overrides: {
      ...theme.overrides,
      MuiPickersBasePicker: {
        pickerView: {
          width: "100%",
          maxWidth: "none",
        },
      },
      MuiPickersCalendar: {
        week: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      MuiPickersCalendarHeader: {
        ...theme.overrides.MuiPickersCalendarHeader,
        switchHeader: {
          fontFamily: "Nunito",
          width: "100%",
          justifyContent: "center",
          padding: theme.spacing(0, 3),
          textTransform: "capitalize",
          marginBottom: theme.spacing(4),
          marginTop: -theme.spacing(1),
        },
        dayLabel: {
          fontFamily: "Nunito",
          textTransform: "capitalize",
          fontWeight: 700,
          color: theme.palette.secondary.light,
        },
        transitionContainer: {
          width: "30%",
          "& p": {
            fontWeight: 700,
            fontFamily: "Nunito",
          },
        },
        daysHeader: {
          fontWeight: 700,
          display: "flex",
          maxHeight: 16,
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
      MuiPickersDay: {
        day: {
          fontWeight: 400,
          color: theme.palette.grey[900],
          "& p": {
            fontSize: theme.typography.pxToRem(16),
            fontFamily: "Nunito",
          },
        },
        dayDisabled: {
          color: theme.palette.secondary.light,
        },
        daySelected: {
          fontWeight: 700,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        },
      },
    },
  });
