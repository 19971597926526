import { useState } from "react";

import { Typography } from "@material-ui/core";

import Button from "@houm-com/ui/Button";

import SearchBarSelectProperty from "Components/Admin/administrator/dashboard/partials/SearchBarSelectProperty";

import useSelectContractPropertyStyles from "./SelectContractPropertyStyles";
import useSelectProperty from "../../hooks/useSelectProperty";

interface Props {
  contractId: number;
}

function SelectContractProperty({ contractId }: Props) {
  const classes = useSelectContractPropertyStyles();
  const { mutate, isLoading } = useSelectProperty(contractId);
  const [propertyId, setPropertyId] = useState<number | null>(null);

  const onSelectProperty = (id: number) => setPropertyId(id);

  const onClick = () =>
    mutate({
      propertyId,
    });

  return (
    <div className={classes.mainContainer}>
      <div className={classes.halfWidthContainer}>
        <Typography className={classes.title}>
          Este contrato no tiene una propiedad asignada
        </Typography>
        <Typography> Seleccionar propiedad: </Typography>
        <SearchBarSelectProperty onSelect={onSelectProperty} />
        <Button variant="primary" size="md" onClick={onClick} isLoading={isLoading}>
          Actualizar contrato
        </Button>
      </div>
    </div>
  );
}

export default SelectContractProperty;
