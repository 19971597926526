import { makeStyles, Theme } from "@material-ui/core";

const PersonCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(3),
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    alignSelf: "flex-end",
  },
  buttonContainer: {
    display: "flex",
    alignSelf: "flex-end",
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
}));
export default PersonCardStyles;
