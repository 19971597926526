import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import CustomLink from "Components/Admin/UIComponents/CustomLink";
import EvaluatorLink from "./EvaluatorLink";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useReservations } from "../context/reservationsContext";

import useStyles from "./TableStyles";
import Toolbar from "./Toolbar";

function Table() {
  const classes = useStyles();
  const { state } = useReservations();
  const { loading, reservations, numberOfReservations: resultsCount } = state;

  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  if (state.country !== "chile") {
    return (
      <div className={classes.container}>
        <p>Esta tabla aún no está disponible</p>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={resultsCount}
        columns={columns}
        rows={reservations}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
};

const columns: GridColumns = [
  {
    ...columnsConfig,
    field: "name",
    headerName: "Nombre",
    width: 100,
  },
  {
    ...columnsConfig,
    field: "property",
    headerName: "Propiedad",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "amount",
    headerName: "Monto",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "paymentDate",
    headerName: "Fecha pago",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "action",
    headerName: "Acción",
    width: 100,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => <CustomLink to={value as string} />,
  },
  {
    ...columnsConfig,
    field: "evaluator",
    headerName: "Evaluator",
    width: 100,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => <EvaluatorLink applicant={value as string} />,
  },
];
