import React from "react";

import { Grid } from "@material-ui/core";

import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { ContractModel } from "models/Contract";
import { countryCode } from "utils";

import ExitProcessInfo from "./components/ExitProcessInfo";
import Warranty from "./components/Warranty";

interface Props {
  contract: ContractModel;
  subscriptionId: number;
  tenantId: number;
}

const ExitProcess = ({ contract, subscriptionId, tenantId }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="exitProcess">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <ExitProcessInfo contractId={contract.id} />
        </Grid>
        <Warranty
          contractId={contract.id}
          countryCode={countryCode(contract?.property.country)}
          propertyId={contract.property.id}
          landlordId={contract.property.user as unknown as number}
          tenantId={tenantId}
          subscriptionId={subscriptionId}
        />
      </Grid>
    </TabPanel>
  );
};

export default React.memo(ExitProcess);
