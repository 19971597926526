import { makeStyles, Theme } from "@material-ui/core";

const useUpdatingDisclaimerStyles = makeStyles((theme: Theme) => ({
  updatingDisclaimerRoot: {
    marginBottom: theme.spacing(2),
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: "#EAF1FB",
    color: "#2663BC",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.6,
  },
}));

export default useUpdatingDisclaimerStyles;
