import { Container, Typography } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { PropertyProvider } from "context/propertyContext/propertyContext";
import { PropertyData } from "models/Property";
import PropertyForm from "./PropertyForm";

interface Props {
  property: PropertyData;
}

export default function ModifyPropertyForm({ property }: Props) {
  return (
    <Container maxWidth="md">
      <Typography align="center" variant="h4">
        Edita la propiedad
      </Typography>
      <SnackbarProvider>
        <PropertyProvider
          initialState={{
            property,
            refetchPropertyInfo: false,
            neighborhoods: [],
            loading: false,
            address: null,
            user: property?.user,
            openUserModal: false,
            inspectionReports: {
              loading: false,
              refetch: false,
              count: null,
              results: [],
            },
            inspectionReport: {
              loading: false,
              refetch: false,
              data: null,
            },
            inspectionReportsCurrentView: "main",
          }}>
          <PropertyForm />
        </PropertyProvider>
      </SnackbarProvider>
    </Container>
  );
}
