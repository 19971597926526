import { Box, Typography } from "@material-ui/core";
import { HTMLProps } from "react";
import usePageNumberStyles from "./PageNumberStyles";

interface Props extends HTMLProps<HTMLButtonElement> {
  selected: boolean;
  page: number;
}

function PageNumber({ selected, page, onClick }: Props) {
  const classes = usePageNumberStyles({ selected });

  return (
    <Box className={classes.pageNumberWrapper} onClick={onClick}>
      <Typography className={classes.pageNumber}>{page}</Typography>
    </Box>
  );
}

export default PageNumber;
