import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldTextArea from "@houm-com/ui/FieldTextArea";
import FieldSelect from "@houm-com/ui/FieldSelect";
import ModalDialog from "@houm-com/ui/ModalDialog";

import useChangeEvaluationMemberStatus from "domains/evaluations/hooks/useChangeEvaluationMemberStatus";
import { StatusMemberEnum } from "domains/evaluations/models/Evaluations";
import useReactHookForm from "hooks/useReactHookForm";

import { commentFormSchema } from "./utils/validationSchema";
import { evaluationDiscardInfo } from "./utils/constants";
import { RejectType } from "../../utils/types";
import { FormData } from "./utils/types";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  evaluationMemberId: string;
  rejectType: RejectType;
}

const RejectModal = ({
  evaluationMemberId,
  open,
  handleOpenModal,
  rejectType = "reject",
}: Props) => {
  const { mutate, isLoading } = useChangeEvaluationMemberStatus({
    evaluationMemberId,
    onHandleSuccess: handleOpenModal,
  });
  const { methods, errors } = useReactHookForm<FormData>({
    formSchema: commentFormSchema,
  });
  const onSubmit: SubmitHandler<FormData> = (data) =>
    mutate({
      status: rejectType === "desist" ? StatusMemberEnum.DESISTED : StatusMemberEnum.REJECTED,
      cancellationData: {
        authorLabel: data.author,
        reason: data.reason,
        comment: data.comment,
      },
    });

  const author = methods.watch("author");

  return (
    <ModalDialog
      title={rejectType === "desist" ? "Desistir Evaluación" : "Rechazar Evaluación"}
      subtitle="Esta evaluación será rechazada/desistida"
      isOpen={open}
      onClose={handleOpenModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalDialog.Content classNameContent="flex flex-col p-1 gap-2">
            <FieldSelect
              label="Autor"
              id="author"
              errorMessage={errors.author?.message}
              placeholder="Selecciona una opción"
              defaultValue={evaluationDiscardInfo.authors[0]}
              options={evaluationDiscardInfo.authors.map((author) => ({
                label: author,
                value: author,
              }))}
              form="novalidateform"
              {...methods.register("author")}
            />
            {author && rejectType && (
              <FieldSelect
                label="Motivo"
                id="reason"
                errorMessage={errors.reason?.message}
                placeholder="Selecciona un motivo"
                options={evaluationDiscardInfo[rejectType][author].map((reason: string) => ({
                  label: reason,
                  value: reason,
                }))}
                form="novalidateform"
                {...methods.register("reason")}
              />
            )}
            <FieldTextArea
              label="Motivo del descarte:"
              id="comment"
              errorMessage={errors.comment?.message}
              placeholder="Escribe un motivo"
              form="novalidateform"
              {...methods.register("comment")}
            />
          </ModalDialog.Content>
          <ModalDialog.Actions
            onClose={handleOpenModal}
            submitButtonLabel="Enviar"
            submitButtonProps={{
              type: "submit",
              isLoading,
            }}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default RejectModal;
