import { UseQueryResult, useQuery } from "react-query";

import { CountryUpperCase } from "models/Countries";
import planService from "services/planService";
import useGetToken from "hooks/useGetToken";

import { parserPlans } from "./utils/parsers";
import { IParsePlan, IPlanFilters } from "./utils/types";

const usePlans = (
  country: CountryUpperCase,
  refetchOnMount: boolean = false,
  filters?: IPlanFilters
) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IParsePlan[], Error> = useQuery<IParsePlan[], Error>(
    ["getPlans", country],
    async () => {
      const authToken = await getToken();
      const response = await planService.getPlans(country, authToken, filters);
      const parsePlans = response.data.map(parserPlans);
      return parsePlans;
    },
    {
      retry: false,
      refetchOnMount,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default usePlans;
