import { PromotionFormData } from "Components/Admin/administrator/promotions/pages/CreatePromotion/utils/types";
import { IPlanBenefit, IPostPromotion } from "../service/types";
import { Locale } from "models/Countries";

const parseTenantBrokerage = (promotion: PromotionFormData): IPlanBenefit[] => [
  {
    benefit_strategy: promotion.promotionType,
    applied_to: "tenant",
    affects: "brokerage",
    amount: String(promotion.tenantBrokerageDiscount),
    month: 0,
  },
];

const parseLandlordBrokerage = (promotion: PromotionFormData): IPlanBenefit[] => [
  {
    benefit_strategy: promotion.promotionType,
    applied_to: "landlord",
    affects: "brokerage",
    amount: String(promotion.landlordBrokerageDiscount),
    month: 0,
  },
];

const parseManagement = (promotion: PromotionFormData): IPlanBenefit[] => {
  const varies = promotion.hasMonthlyVariations === "true";
  return [...Array(parseInt(promotion.discountMonths))].map((_, index) => ({
    benefit_strategy: promotion.promotionType,
    applied_to: "landlord",
    affects: "management_fee",
    amount: varies
      ? promotion.monthlyDiscounts[`month${index}`]
      : String(promotion.landlordManagementDiscount),
    month: index + 1,
  }));
};

const parseIPlanBenefit = (promotion: PromotionFormData): IPlanBenefit[] => {
  const response: IPlanBenefit[] = [];
  if (["both", "brokerage"].includes(promotion.promotionTarget)) {
    if (["both", "tenant"].includes(promotion.promotionUser)) {
      response.push(...parseTenantBrokerage(promotion));
    }
    if (["both", "landlord"].includes(promotion.promotionUser)) {
      response.push(...parseLandlordBrokerage(promotion));
    }
  }
  if (["both", "management_fee"].includes(promotion.promotionTarget)) {
    response.push(...parseManagement(promotion));
  }
  return response;
};

const parsePostPromotion = (promotion: PromotionFormData, country: Locale): IPostPromotion => ({
  name: promotion.name,
  start_date: promotion.startDate,
  end_date: promotion.endDate,
  total_promotions: promotion.promotionsAmount,
  objective: promotion.goal,
  comment: promotion.comment ? promotion.comment : null,
  lease_benefit_descriptions: parseIPlanBenefit(promotion),
  term_and_conditions_link: promotion.link ? promotion.link : null,
  cities: promotion.cities,
  country,
  active: true,
});

export default parsePostPromotion;
