import * as yup from "yup";

const requiredText = "Campo obligatorio";

const validationSchema = yup.object({
  qualification: yup.string().required(requiredText),
  observation: yup.string().nullable(),
});

export default validationSchema;
