import moment from "moment";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Typography } from "@material-ui/core";

import {
  ControlledList,
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { useProject } from "context/projectContext/projectContext";
import { yearOptionsCreator } from "utils";
import { optionMonths } from "utils/datetime";
import { fromToOptions } from "utils/fromToOptions";

import useStyles from "./RealStateStyles";

const yearOptions = yearOptionsCreator(2020, 10);

function RealEstate() {
  const classes = useStyles();

  const { state } = useProject();
  const { control } = useFormContext();

  const handoverDate = state.project?.property?.sales_details?.first_available_handover_date;

  const defaultMonth = useMemo(() => handoverDate && moment(handoverDate).month(), [handoverDate]);

  const defaultYear = useMemo(() => handoverDate && moment(handoverDate).year(), [handoverDate]);

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.project?.property?.country,
  });

  return (
    <FormBlock>
      <ControlledTextField
        id="floors"
        label="Número de pisos"
        defaultValue={state.project?.property?.association_amenities?.floors}
        textFieldProps={{ placeholder: "2" }}
      />
      <Typography component="h3" className={classes.label}>
        ¿Disponibilidad de entrega?
      </Typography>
      <div className={classes.deliveryDate}>
        <ControlledList
          id="project_delivery_month"
          options={optionMonths}
          defaultValue={defaultMonth}
          label="Mes"
          classes={{ container: classes.labelContainer }}
          disableUnderline
        />
        <ControlledList
          id="project_delivery_year"
          options={yearOptions}
          defaultValue={defaultYear}
          label="Año"
          classes={{ container: classes.labelContainer }}
          disableUnderline
        />
      </div>
      {country === "Colombia" && (
        <>
          <ControlledSelection
            id="vis"
            label="Subsidio"
            defaultValue={state.project?.development_details?.vis ?? false}
            options={[
              { value: true, label: "VIS" },
              { value: false, label: "No VIS" },
            ]}
          />
          <ControlledSelection
            id="stratus"
            defaultValue={state.project?.property?.stratus}
            label="Estrato"
            optionWidth="30px"
            options={fromToOptions(1, 6)}
          />
        </>
      )}
    </FormBlock>
  );
}

export default RealEstate;
