import useStyles from "./PortalLinkStyles";

interface Props {
  src?: string;
  alt?: string;
  url?: string;
}

function PortalLink({ src = "", alt = "", url = "" }: Props) {
  const classes = useStyles();
  return (
    <a className={classes.root} href={url} target="_blank" rel="noreferrer">
      <img src={src} alt={alt} />
    </a>
  );
}

export default PortalLink;
