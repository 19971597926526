import * as React from "react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { ClassNameMap, ThemeProvider } from "@material-ui/styles";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import { ReactComponent as CalendarSvg } from "assets/icons/calendar.svg";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import theme from "styles/globals";

import useStyles from "./ControlledKeyboardDatePickerStyles";

interface Props {
  label: string;
  id: string;
  disabled?: boolean;
  defaultValue?: Date;
  controllerProps?: React.ComponentProps<typeof Controller>;
  textFieldProps?: React.ComponentProps<typeof CustomTextField>;
  labelClassName?: string;
  type?: string;
  dataPickerProps?: Partial<React.ComponentProps<typeof DatePicker>>;
  disableUnderline?: boolean;
  inputClassName?: string;
  pickerClassName?: Partial<ClassNameMap<"root" | "input">>;
  datePickerTheme?: any;
}

function ControlledKeyboardDatePicker({
  label,
  id,
  defaultValue,
  controllerProps,
  labelClassName,
  dataPickerProps,
  inputClassName,
  disableUnderline = false,
  pickerClassName,
  datePickerTheme = null,
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <FormControl className={classes.control} error={invalid}>
          <FormControlLabel
            classes={{
              label: classNames(classes.label, labelClassName),
              root: classes.wrapper,
            }}
            control={
              <MuiPickersUtilsProvider
                libInstance={esLocale}
                locale={esLocale}
                utils={DateFnsUtils}>
                <ThemeProvider theme={datePickerTheme ?? theme}>
                  <DatePicker
                    variant="inline"
                    margin="none"
                    value={value}
                    onChange={onChange}
                    disableToolbar
                    InputLabelProps={{ shrink: true, className: classes.label }}
                    autoOk={true}
                    InputProps={{
                      endAdornment: <CalendarSvg />,
                      disableUnderline,
                      classes: {
                        input: pickerClassName?.input,
                        root: pickerClassName?.root,
                      },
                    }}
                    color="primary"
                    className={classNames(classes.picker, inputClassName)}
                    {...dataPickerProps}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            }
            label={label}
            labelPlacement="top"
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}

export default ControlledKeyboardDatePicker;
