import moment from "moment";

export const generateHours = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (x, i) => ({
    label: moment(i + start, "HH").format("HH:mm"),
    value: i + start,
  }));

export const defaultHours = generateHours(8, 22);

export const isHourChecked = (checkedHours, currentDate) => {
  const isHourPresent = checkedHours.filter(
    (item) => moment(item, "HH").format("HH") === moment(currentDate, "HH").format("HH")
  );
  return isHourPresent.length > 0;
};

interface P {
  hours: number[];
  day: string;
}

export const createScheduleGrid = ({ hours, day }: P): Record<string, boolean> => {
  const base = {};
  defaultHours.forEach((item) => {
    if (isHourChecked(hours, item.value)) {
      Object.assign(base, { [`${day}.${item.value}`]: true });
    } else {
      Object.assign(base, { [`${day}.${item.value}`]: false });
    }
  });
  return base;
};

export const createDefaultDayHours = ({ hours, day }: P): Record<string, boolean> => {
  const base = {};
  defaultHours.forEach((item) => {
    Object.assign(base, { [item.value]: false });
  });
  return base;
};
