import { useEffect, useState, memo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useGetToken } from "hooks";
import { useTab } from "context/tabContext/tabContext";
import { useList } from "context/demandLeadContext/list/listContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { getProfiling, updateProfiling } from "context/demandLeadContext/lead/demandLeadActions";

import Accordion from "Components/Admin/UIComponents/Accordion/Accordion";
import ProfilingForm from "./forms/ProfilingForm";

import { profilingSchema } from "./forms/validationSchema";

function Profiling() {
  const [accordionState, setAccordionState] = useState(true);

  const methods = useForm({
    resolver: yupResolver(profilingSchema),
  });
  const {
    state: { cities, financialTypes },
  } = useList();
  const {
    state: { lead, profiling },
    dispatch,
  } = useDemandLead();
  const {
    state: { currentTabValue },
  } = useTab();

  useEffect(() => {
    if (currentTabValue === "agendar") setAccordionState(false);
  }, [currentTabValue]);

  const { getToken } = useGetToken();

  useEffect(() => {
    if (cities.length === 0 && financialTypes.length === 0 && lead) {
      getToken().then((authToken) => {
        getProfiling(dispatch, lead.lead.id, authToken);
      });
    }
  }, [cities, financialTypes, lead]);

  const handleSubmit = (data) => {
    getToken().then((authToken) => {
      updateProfiling(dispatch, lead.lead.id, profiling?.id, data, authToken);
      getProfiling(dispatch, lead.lead.id, authToken);
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Accordion
          changeState={() => setAccordionState(!accordionState)}
          expanded={accordionState}
          title="Perfilamiento">
          <ProfilingForm />
        </Accordion>
      </form>
    </FormProvider>
  );
}

export default memo(Profiling);
