import useRecommendPriceStyles from "./RecommendPriceChartStyle";
import { Typography } from "@material-ui/core";
import { formatCurrency } from "utils/formatCurrency";
import { ISugestion } from "models/Recommendation";

interface Props {
  title: string;
  prices: ISugestion;
  showChart?: boolean;
}

function RecommendPriceChart({ title, prices, showChart }: Props) {
  const classes = useRecommendPriceStyles();
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">{title}</Typography>
      {showChart && <img className={classes.img} src="/static/images/BarChar.svg" alt="" />}
      <div className={classes.wrapper}>
        <div className={classes.item}>
          <div className={classes.title}>Minimo</div>
          <div className={classes.content}>
            {formatCurrency(prices?.currency, prices?.min_price)}
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.title}>Recomendado</div>
          <div className={classes.content}>
            {formatCurrency(prices?.currency, prices?.suggested_price)}
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.title}>Maximo</div>
          <div className={classes.content}>
            {formatCurrency(prices?.currency, prices?.max_price)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendPriceChart;
