import { RISK_URL } from "../env";
import { createHttp } from "./helper";

export default {
  sendGetDicom: async (data, token) => {
    try {
      const res = await createHttp(token, RISK_URL).post("/cl/report", data);
      if (res.data.stateDto.name) {
        return res.data.stateDto.name;
      }
      return false;
    } catch (err) {
      return false;
    }
  },
};
