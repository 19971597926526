import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    margin: theme.spacing(3),
  },
}));

export default useStyles;
