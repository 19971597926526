import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { fetchTemplates } from "context/contractTemplatesContext/contractTemplatesActions";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import { useGetToken } from "hooks";

const useTemplates = () => {
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const {
    state: { fetchingTemplates, view },
    dispatch,
  } = useContractTemplates();

  const fetch = async () => {
    const authToken = await getToken();
    fetchTemplates(authToken, dispatch, parseInt(id)); // useQuery for this in the future
  };

  useEffect(() => {
    fetch();
  }, [id]);

  return { id, fetchingTemplates, view };
};

export default useTemplates;
