import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[5],
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
}));
