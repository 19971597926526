import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { QuotationModel, Subscription } from "models/Subscription";
import subscriptionService from "services/subscriptionService";
import quotationService from "services/quotationService";
import { useGetToken } from "hooks";

const useQuotation = () => {
  const { id: quotationId } = useParams<{ id: string }>();
  const { getToken } = useGetToken();

  const {
    isLoading: isloadingQuotation,
    isError: isQuotationError,
    data: dataQuotation = null,
  }: UseQueryResult<QuotationModel, Error> = useQuery<QuotationModel, Error>(
    ["quotation", quotationId],
    async () => {
      const authToken = await getToken();
      const quotationResponse = await quotationService.quotation(Number(quotationId), authToken);
      return quotationResponse.data;
    },
    {
      refetchOnMount: true,
    }
  );
  const contractId = dataQuotation?.contract?.id;

  const {
    isLoading: isLoadingSubscription,
    isError: isSubscriptionError,
    data,
  }: UseQueryResult<Subscription, Error> = useQuery<Subscription, Error>(
    ["subscription", contractId],
    async () => {
      const authToken = await getToken();
      const subscriptionResponse = await subscriptionService.getByContractId(contractId, authToken);
      return subscriptionResponse?.data;
    },
    {
      enabled: !!contractId,
      refetchOnMount: true,
    }
  );

  return {
    isLoading: isLoadingSubscription || isloadingQuotation,
    isError: isQuotationError || isSubscriptionError,
    quotation: dataQuotation,
    subscription: data,
  };
};

export default useQuotation;
