import classNames from "classnames";
import { Typography } from "@material-ui/core";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";

import useStyles from "./TableStyles";

type divProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Section: React.FC<divProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, props.className)} {...props}>
      {children}
    </div>
  );
};

const Title: React.FC<React.ComponentProps<typeof Typography>> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classNames(classes.title, props.className)} {...props}>
      {children}
    </Typography>
  );
};

export default { Title, Section };
