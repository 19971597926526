import { Grid, Typography } from "@material-ui/core";

import useGridItemStyles from "./GridItemStyles";

interface Props {
  text: string;
}

function GridItem({ text }: Props) {
  const classes = useGridItemStyles();

  return (
    <Grid item xs={4}>
      <Typography className={classes.text}>{text}</Typography>
    </Grid>
  );
}

export default GridItem;
