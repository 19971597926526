import { State, Action, ActionType } from "./calculatorTypes";

export const initialState: State = {
  loading: false,
  noResults: false,
  deal: "rental",
  similarProperties: [],
  similarExternalProperties: { for_rental: [], for_sale: [] },
  squareMeterPrices: { for_rental: null, for_sale: null },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_FORMATTED_ADDRESS: {
      return { ...state, formattedAddress: action.formattedAddress };
    }

    case ActionType.SET_SIMILAR_PROPERTIES: {
      return { ...state, similarProperties: action.similarProperties };
    }
    case ActionType.SET_SIMILAR_EXTERNAL_PROPERTIES: {
      return {
        ...state,
        similarExternalProperties: action.similarExternalProperties,
      };
    }

    case ActionType.SET_LOADING: {
      return { ...state, loading: action.loading };
    }

    case ActionType.SET_CALCULATOR_RESULTS: {
      return {
        ...state,
        calculatorResponse: action.calculatorResponse,
        noResults: false,
      };
    }
    case ActionType.SET_BY_QUERY_PARAMS: {
      return { ...action.state };
    }
    case ActionType.SET_NO_RESULTS: {
      return { ...state, noResults: action.noResults };
    }
    case ActionType.ERROR: {
      return {
        ...state,
        error: { status: action.status, message: action.message },
        noResults: true,
        loading: false,
      };
    }
    case ActionType.RESET: {
      return initialState;
    }

    case ActionType.CHANGE_DEAL: {
      return { ...state, deal: action.deal };
    }
    case ActionType.SET_SQUARE_METER_PRICES: {
      return {
        ...state,
        squareMeterPrices: action.squareMeterPrices,
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
