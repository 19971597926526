import { useFormContext, useWatch } from "react-hook-form";
import { useProperty } from "context/propertyContext/propertyContext";
import { IUserAutoComplete } from "models";
import { useMemo } from "react";

const useUserFormRole = () => {
  const { state } = useProperty();
  const { control } = useFormContext();
  const { user } = state;

  const formUser: IUserAutoComplete = useWatch({
    control,
    name: "user",
  });

  const role = useMemo(() => formUser?.role || user?.role, [formUser, user]);

  return role;
};

export default useUserFormRole;
