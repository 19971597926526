import moment from "moment";
import { getTimezones } from "utils/scheduleTimezone";
import { CountryUpperCase, Regions } from "models/Countries";

interface Props {
  currentHour: string;
  country?: CountryUpperCase;
  region?: Regions;
  timezone?: string;
}

export const scheduleResponse = ({ currentHour, country, region, timezone }: Props) => {
  if (!country && !timezone) return null;
  const beginHour = moment
    .utc(currentHour)
    .tz(timezone || getTimezones(country, region))
    .format("h:mm a");
  const endHour = moment
    .utc(currentHour)
    .add(1, "hour")
    .tz(timezone || getTimezones(country, region))
    .format("h:mm a");
  const dayShcedule = moment
    .utc(currentHour)
    .tz(timezone || getTimezones(country, region))
    .format("dddd, D MMMM YYYY");
  return `${beginHour} - ${endHour}, ${dayShcedule}`;
};
