import { useParams } from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";

import { MemberPersonalInformation, UserProfile } from "domains/evaluations/models/Evaluations";
import DocumentsView from "apps/evaluator-admin/components/DocumentsView";
import { Document } from "domains/evaluations/models/Document";
import { Locale } from "models/Countries";

import EvaluateForm from "./components/EvaluateForm";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  documents: Document[];
  personInfo: MemberPersonalInformation;
  userInformation: UserProfile;
  memberId: string;
}
const EvaluateDrawer = ({
  open,
  onHandleClose,
  documents,
  personInfo,
  userInformation,
  memberId,
}: Props) => {
  const { country } = useParams<{
    country: Locale;
  }>();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onHandleClose}
      variant="persistent"
      classes={{
        paperAnchorBottom: "!max-h-screen",
        docked: !open && "hidden",
        paper: "!overflow-y-hidden !z-10",
      }}>
      <div className="flex justify-between items-center p-4">
        <h2 className="text-xl font-bold">Evaluación {personInfo.name}</h2>
        <button onClick={onHandleClose}>Cerrar</button>
      </div>
      <div className="p-4 pt-0 flex flex-row gap-2 h-[calc(100vh-56px)]">
        <div className="w-[30%] overflow-y-scroll pr-2 pl-1">
          <EvaluateForm
            country={country}
            personInformation={personInfo}
            userInformation={userInformation}
            memberId={memberId}
          />
        </div>
        <div className="w-[70%]">
          <DocumentsView documents={documents} evaluationMemberId={memberId} />
        </div>
      </div>
    </Drawer>
  );
};

export default EvaluateDrawer;
