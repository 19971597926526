import { addressObjectContains } from "utils";

interface AddressComponentItem {
  long_name: string;
  short_name: string;
  types: string[];
}

const isAdressInNuevaLeon = (addressComponents: AddressComponentItem[]): boolean => {
  if (!addressComponents) return false;
  const addressIsInNuevaLeon = addressObjectContains(
    addressComponents,
    "administrative_area_level_1",
    "Nuevo León"
  );
  const addressIsInMonterrey = addressObjectContains(addressComponents, "locality", "Monterrey");
  return addressIsInNuevaLeon || addressIsInMonterrey;
};

export default isAdressInNuevaLeon;
