import { MetricsParams } from "models/Calculator";
import { PropertyData } from "models/Property";
import { countryCode } from "utils";

export const parseMetricsParams = (property: PropertyData): MetricsParams => ({
  latitude: property?.property_details[0]?.latitud,
  longitude: property?.property_details[0]?.longitud,
  property_type: property?.type === "departamento" ? "apartment" : "house",
  country_code: countryCode(property?.country),
});

export const areValidMetrics = (metrics: MetricsParams): boolean =>
  !!metrics.latitude && !!metrics.longitude && !!metrics.property_type && !!metrics.country_code;
