import { Box, Grid } from "@material-ui/core";

import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { CountryUpperCase } from "models/Countries";

import useSubscriptionNavStyles from "./SubscriptionNavStyles";
import { steps } from "./utils";

interface Props {
  quotationId: number;
  country: CountryUpperCase;
  isRequest?: boolean;
}

const SubscriptionNav = ({ quotationId, country, isRequest = false }: Props) => {
  const classes = useSubscriptionNavStyles();

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Breadcrumbs steps={steps(quotationId, isRequest)} />
        </Grid>
        <Grid item>
          <CountryChip country={country} className={classes.chip} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubscriptionNav;
