import { makeStyles, Theme } from "@material-ui/core";

const useTitleStyles = makeStyles<Theme>((theme) => ({
  titleRoot: {
    paddingTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.25,
  },
}));

export default useTitleStyles;
