import ContractPersonEditIndex, { ContractPersonEditProps } from "./ContractPersonEditIndex";
import { ContractPersonProvider } from "context/contractPersonContext/contractPersonContext";

const NO_PROPERTY = -1;

export default function ContractPersonEdit({
  contractId = "",
  contractPersonId = "",
  country: paramsCountry,
  propertyId = NO_PROPERTY,
  redirectionUrl = "",
  role: paramsRole,
  title: paramsTitle,
}: ContractPersonEditProps) {
  return (
    <ContractPersonProvider>
      <ContractPersonEditIndex
        contractId={contractId}
        contractPersonId={contractPersonId}
        country={paramsCountry}
        propertyId={propertyId}
        redirectionUrl={redirectionUrl}
        role={paramsRole}
        title={paramsTitle}
      />
    </ContractPersonProvider>
  );
}
