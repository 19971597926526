import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  labelPos: "start" | "top";
  color: "light" | "strong";
}
const useCustomAutocomplete = makeStyles<Theme, Props>((theme) => ({
  container: {
    minWidth: "25%",
    gap: theme.spacing(2),
    width: "100%",
    display: ({ labelPos }) => (labelPos === "start" ? "flex" : "block"),
    alignItems: "center",
    justifyContent: ({ labelPos }) => (labelPos === "start" ? "space-between" : ""),
    padding: theme.spacing(2, 0),
  },
  label: {
    fontSize: "1.125rem",
    fontWeight: 700,
    marginBottom: 0,
    marginRight: ({ labelPos }) => (labelPos === "start" ? theme.spacing(3) : 0),
  },
  input: {
    minWidth: 220,
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    color: ({ color }) => (color === "light" ? theme.palette.grey[500] : ""),
  },
  tag: {
    border: `1px solid ${theme.palette.secondary.light}`,
    backgroundColor: "white",
  },
}));

export default useCustomAutocomplete;
