import { Locale } from "models/Countries";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import { localeToCurrency } from "utils/countryCurrency";
import { getAverageSalary } from "../../utils/utils";

interface Props {
  country: Locale;
}

const useAverageSalary = ({ country }: Props) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<MemberInformation>();

  const [income1, income2, income3] = watch([
    "salaryIncomeAmount1",
    "salaryIncomeAmount2",
    "salaryIncomeAmount3",
  ]);
  const [otherIncome1, otherIncome2, otherIncome3] = watch([
    "otherIncomeAmount1",
    "otherIncomeAmount2",
    "otherIncomeAmount3",
  ]);

  const getSalary = (income: string | undefined, otherIncome: string | undefined) => {
    const salary = income ? parseFloat(income) : 0;
    const otherSalary = otherIncome ? parseFloat(otherIncome) : 0;
    return (salary + otherSalary).toString();
  };

  useEffect(() => {
    setValue(
      "confirmedSalary",
      getAverageSalary(
        [
          getSalary(income1, otherIncome1),
          getSalary(income2, otherIncome2),
          getSalary(income3, otherIncome3),
        ],
        localeToCurrency[country]
      )
    );
  }, [income1, income2, income3, otherIncome1, otherIncome2, otherIncome3, setValue]);

  return {
    register,
    errors,
  };
};

export default useAverageSalary;
