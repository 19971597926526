import * as yup from "yup";

const floatWithComma = yup
  .number()
  .transform((_, value) => {
    if (value) {
      const valid = value.match(/^\d*,?\d+$/);
      if (valid) return parseFloat(value.replace(/,/, "."));
      else return new Error();
    }
  })
  .typeError("Solo se permiten números positivos separados por una coma");

export default floatWithComma;
