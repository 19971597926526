import { format } from "date-fns";

import { Box, Typography } from "@material-ui/core";

import { BasicService } from "Components/Admin/UIComponents/PropertyServices/utils/types";
import { numberWithCommas } from "utils";

import useCommonExpenseDataStyles from "./CommonExpenseDataStyles";
import ServiceDetails from "Components/Admin/UIComponents/PropertyServices/components/ServiceDetails";
import QueryDisclaimer from "Components/Admin/UIComponents/PropertyServices/components/QueryDisclaimer";

const CommonExpenseData = ({
  administrationEmail,
  aggregateDebt,
  clientInfo,
  currentDebt,
  overdueDate,
  updatedAt,
  status,
}: Partial<BasicService>) => {
  const classes = useCommonExpenseDataStyles({ aggregateDebt });

  return (
    <>
      <Box className={classes.item}>
        <Typography className={classes.bold}>Correo :</Typography>
        <Typography>{administrationEmail || "Sin registro"}</Typography>
      </Box>

      <Box className={classes.item}>
        <Typography className={classes.bold}>Fecha de registro :</Typography>
        <Typography>{format(updatedAt, "dd/MM/yyyy")}</Typography>
      </Box>

      {!clientInfo.isValid && (
        <Typography className={classes.notValid}>
          Ha ocurrido un error obteniendo la información. Por favor validar el código ingresado
        </Typography>
      )}
      {!["in_process", "pending"].includes(status) && clientInfo.isValid ? (
        <ServiceDetails
          currentDebt={`$${numberWithCommas(currentDebt)}`}
          overdueDate={format(overdueDate, "dd/MM/yyyy")}
          aggregateDebt={`$${numberWithCommas(aggregateDebt)}`}
        />
      ) : (
        <QueryDisclaimer />
      )}
    </>
  );
};

export default CommonExpenseData;
