import { useState } from "react";
import Swal from "sweetalert2";
import clx from "classnames";

import Button from "@houm-com/ui/Button";
import { Chip, Grid, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import contractService from "services/contractService";
import { useGetToken } from "hooks";

import useSignatureCardInfoStyles from "./SignatureCardInfoStyles";
import { parseSignatureKeys } from "../../utils/utils";

interface Props {
  role: string;
  fullName: string;
  image: string;
  signProof: string;
  statusStored: string;
  status: string;
  id: number | string;
  contractId: number;
}

function SignatureCardInfo({
  role,
  fullName,
  image,
  signProof,
  statusStored,
  status,
  id,
  contractId,
}: Props) {
  const { getToken } = useGetToken();
  const classes = useSignatureCardInfoStyles();
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const generateNewSigningLink = async (contractId: number, contractPersonId: number) => {
    const authToken = await getToken();
    try {
      const fetched = await contractService.generateNewSigningLink(
        {
          contract_id: contractId,
          contract_person_id: contractPersonId,
        },
        authToken
      );

      Swal.fire({
        type: "success",
        title: `Link de firma perteneciente a ${fullName}`,
        html: `<div style="background: lightgrey; padding: 10px; margin-top: 10px; margin-bottom: 10px; border-radius: 5px;">
        <span id="link" style="color: white; font-weight: bold">${fetched.data.link}</span>
        ${
          fetched.data?.complementary_link
            ? `<span id="complementary_link" style="color: white; font-weight: bold">Link 2: ${fetched.data.link}</span> `
            : ""
        }
        </div>`,
        showCancelButton: true,
        confirmButtonText: "Copiar",
        cancelButtonText: "Ok",
        confirmButtonColor: "#ffbb33",
        cancelButtonColor: "#4285F4",
        showLoaderOnConfirm: true,
        preConfirm: (a) => {
          const dummy = document.createElement("textarea");
          document.body.appendChild(dummy);
          dummy.value = fetched.data.link;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
          Swal.showValidationMessage("¡Copiado!");
        },
      });
    } catch {
      Swal.fire("Error", "No se pudo obtener link", "error");
    } finally {
      setLoadingGenerate(false);
    }
  };

  const getLink = () => {
    setLoadingGenerate(true);
    Swal.fire({
      type: "warning",
      title: "¿Obtener link?",
      text: "Esto invalidará cualquier otro link activo para esta persona",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar y obtener link",
    }).then((res) => {
      if (res.value) {
        generateNewSigningLink(contractId, id as number);
      } else {
        setLoadingGenerate(false);
      }
    });
  };

  return (
    <Grid item container xs={12} md={6} className={classes.gridContainer}>
      <Grid item xs={12} className={classes.infoUserHeader}>
        <Typography component="h3" className={classes.textTitleLabel}>
          {parseSignatureKeys[role]}
        </Typography>
        <Typography component="h6" className={classes.textLabelInfo}>
          {fullName}
        </Typography>
      </Grid>
      <Grid item className={classes.signatureContainer}>
        {image ? (
          <Grid container direction="column" alignItems="center">
            <img src={image} alt="signature" width="150px" />
            <Chip
              label={status === "signed" ? "Firmado" : "No firmado"}
              className={clx(classes.chip, {
                [classes.ready]: status === "signed",
              })}
              variant="outlined"
              size="small"
            />
            {status === "unsigned" && statusStored}
          </Grid>
        ) : (
          <>
            <Typography className={classes.noSignature}>Firma pendiente</Typography>
            <Typography className={classes.noSignatureInfo}>{statusStored}</Typography>
          </>
        )}
      </Grid>
      <Grid item container xs={12} justifyContent="flex-start" className={classes.containerButton}>
        <div className={classes.buttonGroup}>
          <Grid item xs={12}>
            <Button
              variant="secondary"
              size="md"
              onClick={() => window.open(`/admin/contract-person/${id}/force_signature`, "_blank")}>
              Subir firma
            </Button>
          </Grid>
          {id !== "No hay" && (
            <Grid item xs={12}>
              <Loading loading={loadingGenerate} size={14}>
                <Typography className={classes.linkButton} onClick={() => getLink()}>
                  Obtener link
                </Typography>
              </Loading>
            </Grid>
          )}
          {signProof ? (
            <a href={signProof}>
              <img src={signProof} alt="evidencia de firma" width="150px" />
            </a>
          ) : (
            <Typography className={classes.noSignature}>Sin foto de verificación</Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default SignatureCardInfo;
