import { Box, Grid, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Spinner from "Components/Shared/partials/Spinner";

import { CONTRACT_ONLINE_PROCESS_STATUS } from "Components/Admin/administrator/contracts/constants";
import useGetAcceptanceDocuments from "./hooks/useGetAcceptanceDocuments";
import AcceptanceDocument from "./components/AcceptanceDocument";
import useContractDocumentsStyles from "./ContractDocumentsStyles";

interface Props {
  contractId: number | null;
  onlineProcessStatus: string;
}

const ContractDocuments = ({ contractId, onlineProcessStatus }: Props) => {
  const classes = useContractDocumentsStyles();
  const { newDocumentsData, documentsData, isLoading, newDocumentsError, isSuccess } =
    useGetAcceptanceDocuments(contractId);

  return (
    <Grid item xs={12} md={6}>
      <AdminCard title="Documentos Firmados" withSpace={false} showTitleBackground={true}>
        <Box className={classes.documentsWrapper}>
          {onlineProcessStatus !== CONTRACT_ONLINE_PROCESS_STATUS["signaturesCompleted"] &&
            onlineProcessStatus !== CONTRACT_ONLINE_PROCESS_STATUS["finalized"] && (
              <Typography component="h6">
                El contrato aún no ha sido firmado por todas las partes involucradas
              </Typography>
            )}
          {!newDocumentsData?.length && documentsData?.contract && (
            <AcceptanceDocument link={documentsData?.contract} title="Contrato antiguo" />
          )}
          {!newDocumentsData?.length && documentsData?.mandate && (
            <AcceptanceDocument link={documentsData?.mandate} title="Mandato" />
          )}
          {isLoading && <Spinner />}
          {newDocumentsError && (
            <Typography>Ocurrió un problema al cargar los documentos</Typography>
          )}
          {isSuccess &&
            newDocumentsData.map((document) => (
              <AcceptanceDocument
                key={document.title}
                link={document.link}
                title={document.title}
              />
            ))}
        </Box>
      </AdminCard>
    </Grid>
  );
};

export default ContractDocuments;
