export const filterInitialValues = {
  businessType: null,
  portal: null,
  portalPlan: null,
};

export interface TotalQuotaTableFilterTypes {
  businessType?: string;
  portal?: string;
  portalPlan?: string;
}
