/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridOverlay, XGrid, XGridProps } from "@material-ui/x-grid";
import { LinearProgress } from "@material-ui/core";

import GetAppIcon from "@material-ui/icons/GetApp";
import useStyles from "./CustomDataGridStyles";

function CustomDataGrid(props: XGridProps) {
  const {
    rowsPerPageOptions = [5, 10, 20, 50, 100],
    paginationMode = "server",
    filterMode = "server",
    sortingMode = "server",
    components,
    localeText,
  } = props;
  const classes = useStyles();
  return (
    <XGrid
      className={classes.table}
      {...props}
      pagination
      rowsPerPageOptions={rowsPerPageOptions}
      paginationMode={paginationMode}
      filterMode={filterMode}
      sortingMode={sortingMode}
      autoHeight
      disableSelectionOnClick
      disableMultipleColumnsSorting
      components={{
        ExportIcon: GetAppIcon,
        LoadingOverlay: CustomLoadingOverlay,
        ...components,
      }}
      localeText={{
        toolbarExport: "Descargar",
        toolbarExportCSV: "Descargar CSV",
        ...localeText,
      }}
    />
  );
}

export default CustomDataGrid;

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div className="w-full">
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
