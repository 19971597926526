import * as yup from "yup";

import { floatWithComma } from "utils/yupValidators";

export const requiredText = "Obligatorio";
export const requiredUserText = "Hace falta seleccionar el usuario";
const invalidGoogleRoute = "La dirección ingresada no es válida, (route/address not found)";
const invalidValue = "El valor ingresado no es válido";

const price = floatWithComma.nullable().min(0);

const notNumberToNull = (val, originalValue) =>
  Number.isNaN(val) && originalValue === "" ? null : val;

const addressValidation = yup.lazy((value) => {
  if (!value || typeof value !== "object") return yup.string().required(requiredText);
  if (value?.parsed) return yup.object();
  return yup
    .object()
    .test("address_components", invalidGoogleRoute, (val) => {
      const components = val.address_components as {
        long_name: string;
        short_name: string;
        types: string[];
      }[];
      const routeComponent = components.find((component) => component.types.includes("route"));
      if (routeComponent) {
        return true;
      }
      return false;
    })
    .typeError("Seleccionar una dirección recomendada")
    .required(requiredText);
});

export const validate = {
  user: yup
    .object({ role: yup.string().nullable() })
    .required(requiredUserText)
    .typeError(requiredUserText),
  country: yup.string().required(requiredText).typeError(requiredText),
  association_name: yup.string().required(requiredText),
  address_object: addressValidation,
  street_number: yup.string(),
  type: yup.string().required(requiredText),
  floors: yup.number().integer().nullable().transform(notNumberToNull).typeError(invalidValue),
  project_delivery_month: yup.number().integer().required(requiredText).typeError(invalidValue),
  project_delivery_year: yup.number().integer().required(requiredText).typeError(invalidValue),
  development_status: yup.string().typeError(invalidValue),
  valor_venta: price.min(0),
  tipo_moneda_venta: yup.string().typeError(invalidValue),
  custom_commune: yup.object().when(["street_name", "street_commune"], {
    is: (street_name, street_commune) => Boolean(street_name) && !street_commune,
    then: yup.object().required(requiredText),
  }),
};
