import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import useStyles from "./PipedriveStyles";

function Pipedrive() {
  const classes = useStyles();
  return (
    <FormBlock>
      <h3>
        Pipedrive <span className={classes.optional}>(opcional)</span>
      </h3>

      <ControlledTextField
        id="deal_id"
        label="ID de Negocio"
        textFieldProps={{ placeholder: "ID de Negocio" }}
      />
    </FormBlock>
  );
}

export default Pipedrive;
