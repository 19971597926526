import { TableProvider } from "context/tableContext/tableContext";

import RequestsTable from "./RequestsTable";

function PropertyInspectionReportsMainIndex() {
  return (
    <TableProvider>
      <RequestsTable />
    </TableProvider>
  );
}

export default PropertyInspectionReportsMainIndex;
