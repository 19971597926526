import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Spinner from "Components/Shared/partials/Spinner";
import { CountryUpperCase } from "models/Countries";

import useCreatePromotionModalStyles from "./CreatePromotionModalStyles";
import CitySelector from "./components/CitySelector";
import useCities from "../../hooks/useCities";
import { countryCode } from "utils";

interface Props {
  open: boolean;
  handleClose: () => void;
  country: CountryUpperCase;
}

const CreatePromotionModal = ({ open, handleClose, country }: Props) => {
  const classes = useCreatePromotionModalStyles();
  const { data, isError, isLoading } = useCities(countryCode(country));

  const renderChildren = () => {
    if (isLoading) return <Spinner />;
    if (isError) return <h1>Error cargando las ciudades</h1>;
    else
      return <CitySelector originalCities={data} handleOpenModal={handleClose} country={country} />;
  };

  return (
    <DSModal
      paperClassName={classes.dialog}
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      showCloseButton
      scroll="paper">
      <Loading loading={isLoading}>{renderChildren()}</Loading>
    </DSModal>
  );
};

export default CreatePromotionModal;
