import { makeStyles, Theme } from "@material-ui/core";

const useInterestedPeopleStyles = makeStyles((theme: Theme) => ({
  container: { width: "100%", height: "100%" },
  link: {
    "&, &:hover": {
      color: theme.palette.primary.main,
    },
  },
  table: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: "8px",
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[500],
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-cell": {
      padding: theme.spacing(2),
      borderBottomWidth: 0,
      borderTopWidth: 0,
    },
    "& .MuiDataGrid-row": {
      transition: "0.3s",
      "&:hover": {
        fontWeight: 800,
        backgroundColor: "#F7F8FC",
      },
    },
  },
  collapse: {
    padding: theme.spacing(3),
  },
}));

export default useInterestedPeopleStyles;
