import Swal from "sweetalert2";
import { useGetToken } from "hooks";
import { completeLeadActivity } from "context/leadContext/leadActions";
import { useLead } from "context/leadContext/leadContext";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";
import useStyles from "./CompleteActivityButtonStyles";

import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";

interface Props {
  activityId: number;
  isCompleted?: boolean;
}

function CompleteActivityButton({ activityId, isCompleted = true }: Props) {
  const { getToken } = useGetToken();
  const { dispatch } = useLead();
  const classes = useStyles();

  const handleOnClick = async () => {
    const response = await Swal.fire({
      type: "warning",
      title: "¿Deseas marcar esta actividad como Completada?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    });
    const { value: confirm } = response;
    if (confirm) {
      const authToken = await getToken();
      const sendData = {};
      await completeLeadActivity(activityId, sendData, dispatch, authToken);
    }
  };
  if (isCompleted) return <CheckCircleOutlineOutlined className={classes.completedIcon} />;

  return <LinkButton onClick={handleOnClick}>Realizar</LinkButton>;
}

export default CompleteActivityButton;
