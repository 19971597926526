import { ReactNode } from "react";

import { Typography } from "@material-ui/core";
import { ReactComponent as HoumLogo } from "assets/img/OUI).svg";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import CustomCenteredModalStyles from "./CustomCenteredModalStyles";

interface Props {
  onClose?: () => void;
  open: boolean;
  showCloseButton?: boolean;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  withLogo?: boolean;
  logoPosition?: "top" | "bottom";
}

function CustomCenteredModal({
  open,
  onClose,
  showCloseButton = false,
  title,
  subtitle,
  children = null,
  withLogo = false,
  logoPosition = "bottom",
}: Props) {
  const classes = CustomCenteredModalStyles({ closeButton: showCloseButton });
  return (
    <CustomDialog title="" onClose={onClose} open={open} showCloseButton={showCloseButton}>
      <div className={classes.container}>
        {withLogo && logoPosition === "top" && (
          <div className={classes.imageContainer}>
            <HoumLogo />
          </div>
        )}

        <div className={classes.textContainer}>
          <Typography id="modalTitle" className={classes.title}>
            {title}
          </Typography>
          <Typography id="modalSubtitle" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </div>
        {children}
        {withLogo && logoPosition === "bottom" && (
          <div className={classes.imageContainer}>
            <HoumLogo />
          </div>
        )}
      </div>
    </CustomDialog>
  );
}

export default CustomCenteredModal;
