import { IoAppsOutline as AppsIcon } from "@houm-com/ui-icons";
//import StreetViewsIndex from "Components/Analytics/streetViews/StreetViewsIndex";
import ValidarDatacredito from "Components/Landing/Components/ValidarDatacredito";
import ValidarRut from "Components/Landing/Components/ValidarRut";
import Verifik from "Components/Admin/administrator/users/validateUser";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Apps",
  icon: AppsIcon,
  children: [
    {
      to: "/apps/validate-datacredito",
      path: "/apps/validate-datacredito",
      component: ValidarDatacredito,
      label: "Datacredito",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "super_admin",
      ],
    },
    {
      to: "/apps/validate-rut",
      path: "/apps/validate-rut",
      component: ValidarRut,
      label: "Dicom",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "super_admin",
      ],
    },
    {
      to: "/admin/verifik",
      path: "/admin/verifik",
      component: Verifik,
      label: "Verifik",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "super_admin",
      ],
    },
  ],
});
