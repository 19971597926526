import GenerateContract from "./components/GenerateContract";
import { Evaluation } from "domains/evaluations/models/Evaluations";

import { classes } from "./ContractCardStyles";
import Contract from "./components/Contract";

interface Props {
  totalSalary: string;
  propertyId: number;
  contractGenerated: number | null;
  evaluation: Evaluation;
}

const ContractCard = ({ totalSalary, propertyId, contractGenerated, evaluation }: Props) => (
  <div className={classes.contractCardContainer}>
    {contractGenerated && <Contract contractId={contractGenerated} />}
    {!contractGenerated && (
      <GenerateContract evaluation={evaluation} propertyId={propertyId} totalSalary={totalSalary} />
    )}
  </div>
);

export default ContractCard;
