import { OptionProps } from "Components/Admin/UIComponents/RemoteSuggester/types";
import { ResCities } from "models/DemandLead";
import { SelectorSerializerResponse } from "models/Option";
import { PropertyData } from "models/Property";
import { Paginate } from "models/ResponseWithPaginate";
import { UserModel } from "models/User";
import { IndexCompaniesResponse } from "services/companyService/types";
import { IndexPublicationTypesRequestResponse } from "services/integrationService/types";

export const propertyResponseToSelectorParser = (
  propertyData: Paginate<PropertyData>
): OptionProps[] =>
  propertyData.results.map((property) => ({
    label: `[ID: ${property.id}] ${property.address}`,
    id: property.id,
  }));

export const userResponseToSelectorParser = (userData: UserModel[]): OptionProps[] =>
  userData.map((user) => ({
    label: `${user.name} ${user.last_name} [${user.role}]`,
    id: user.email,
  }));

export const publicationTypeResponseToSelectorParser = (
  portalPlanData: IndexPublicationTypesRequestResponse
): OptionProps[] =>
  portalPlanData.map((portalPlan) => ({
    label: portalPlan,
    id: portalPlan,
  }));

export const companiesResponseToSelectorParser = (
  companyData: Paginate<IndexCompaniesResponse>
): OptionProps[] =>
  companyData.results.map((company) => ({
    label: company.name,
    id: company.name,
  }));

export const standardParser = (data: SelectorSerializerResponse[]): OptionProps[] =>
  data.map((option) => ({
    label: option.for_selector_label,
    id: option.for_selector_id,
  }));

export const citiesResponseToSelectorParser = (resCitiesData: ResCities) =>
  resCitiesData.cities.map((resCity) => ({
    label: resCity.city,
    id: resCity.city_id,
  }));
