import { compact } from "lodash";

import { PropertyData } from "models/Property";
import propertyStatusTranslator from "utils/statusTranslator";

export const getPropertyStatusText = (property: PropertyData): string => {
  const propertyRentalStatus = propertyStatusTranslator(property?.property_details[0]?.status);
  const propertySaleStatus = propertyStatusTranslator(property?.sales_details?.status);

  const propertyRentalStatusText = property?.for_rental
    ? `Arriendo: ${propertyRentalStatus}`
    : null;
  const propertySalesStatusText = property?.for_sale ? `Venta: ${propertySaleStatus}` : null;

  return compact([propertyRentalStatusText, propertySalesStatusText]).join(" / ");
};
