import { IoBusinessOutline as RealEstateIcon } from "@houm-com/ui-icons";
import NewProject from "Components/Admin/administrator/projects/newProjects";
import ProjectsTable from "Components/Admin/administrator/projects";
import Tags from "Components/Admin/administrator/projects/tags";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Proyectos inmobiliarios",
  icon: RealEstateIcon,
  children: [
    {
      to: "/real-estate-projects",
      path: "/real-estate-projects",
      component: ProjectsTable,
      label: "Proyectos",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/new-project",
      component: NewProject,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/real-estate-projects/tags",
      path: "/real-estate-projects/tags",
      component: Tags,
      label: "Tags",
      scope: "roles",
      strategy: "any",
      require: ["sales_admin", "sales", "super_admin"],
    },
  ],
});
