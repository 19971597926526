import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { DialogContent, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";

import useExchangeReservationModalStyles from "./ExchangeReservationModalStyles";
import { exchangeSchema } from "./utils/validationSchema";
import { parseExchangeReservation } from "./utils/utils";
import useExchange from "../../hooks/useExchange";
import UserData from "./components/UserData";
import { FormData } from "./utils/types";

interface Props {
  openModal: boolean;
  handleModal: () => void;
  propertyId: number;
  user: UserModel;
  movementId: string;
  country: Locale;
}

const ExchangeReservationModal = ({
  openModal,
  handleModal,
  propertyId,
  user,
  country,
  movementId,
}: Props) => {
  const { mutate, isLoading } = useExchange(country, movementId, handleModal);
  const classes = useExchangeReservationModalStyles();

  const methods = useForm<FormData>({
    resolver: yupResolver(exchangeSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => {
    const parsedData = parseExchangeReservation(dataForm.uid);
    mutate(parsedData);
  };

  return (
    <DSModal open={openModal} onClose={handleModal} maxWidth="xs" showCloseButton scroll="paper">
      <DialogContent>
        <Typography className={classes.title}>Pagar con canje</Typography>
        <FormProvider {...methods}>
          <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <ControlledTextField
                  id="uid"
                  label="UID de la reserva que se usará como medio de pago"
                  defaultValue=""
                  wrapperPadding={false}
                  textFieldProps={{
                    placeholder: "Ingresa UID",
                    InputProps: {
                      classes: {
                        root: classes.textInput,
                      },
                    },
                  }}
                />
                <UserData user={user} propertyId={propertyId} />
              </Grid>
              <Grid item>
                <DesignSystemButton
                  label="Pagar"
                  type="submit"
                  size="small"
                  variant="primary"
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
    </DSModal>
  );
};

export default ExchangeReservationModal;
