import { Container, Grid, Typography } from "@material-ui/core";

import { getCountry } from "Components/Admin/administrator/contracts/ContractTemplates/utils";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import { useHistory } from "react-router-dom";

import TableHeader from "./components/TableHeader";
import TableItem from "./components/TableItem";
import useTemplatesTableStyles from "./TemplatesTableStyles";

function TemplatesTable() {
  const classes = useTemplatesTableStyles();
  const history = useHistory();
  const { state } = useContractTemplates();
  const {
    fetchingTemplates,
    templates,
    filters: { country },
  } = state;

  const filteredTemplates = templates.filter((template) => template.country === country);

  return (
    <Loading loading={fetchingTemplates}>
      <Grid container>
        <TableHeader />
        {filteredTemplates.map((template) => (
          <TableItem
            {...template}
            key={template.id}
            handleClick={() => history.push(`/admin/contract_templates/${template.id}`)}
          />
        ))}
        {filteredTemplates.length === 0 && (
          <Container className={classes.noTemplatesContainer}>
            <Typography>Aún no hay templates en {getCountry(country)} :(</Typography>
          </Container>
        )}
      </Grid>
    </Loading>
  );
}

export default TemplatesTable;
