import { useState } from "react";
import Swal from "sweetalert2";

import Typography from "@material-ui/core/Typography";
import { Chip, Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import newPaymentService from "services/newPaymentService";
import contractService from "services/contractService";
import { CountryUpperCase } from "models/Countries";
import { ContractModel } from "models/Contract";
import { PropertyData } from "models/Property";
import { useGetToken } from "hooks";
import { countryCode } from "utils";
import { AxiosError } from "axios";

import { checkStatus, renderBackStep, steps } from "./utils";
import useHeaderStyles from "./HeaderStyles";
import { Link } from "react-router-dom";

interface Props {
  contract: ContractModel;
  property: PropertyData;
  brokerage: boolean;
  updateContract: (contract: ContractModel) => void;
}

function Header({ contract, property, brokerage = true, updateContract }: Props) {
  const { getToken } = useGetToken();
  const classes = useHeaderStyles();
  const [loading, setLoading] = useState(false);

  const backStepContract = async () => {
    setLoading(true);
    try {
      const authToken = await getToken();
      const newStatusContract = {
        ...contract,
        status: renderBackStep[contract.status].status,
        is_payment_simulation: false,
      };
      const res = await contractService.update(newStatusContract.id, newStatusContract, authToken);
      Swal.fire(
        "Success",
        `Enviado correctamente a contratos "${renderBackStep[contract.status].label}"`,
        "success"
      ).then(() => updateContract(res.data));
    } catch {
      Swal.fire(
        "Error",
        `El contrato no ha podido ser enviado a "${renderBackStep[contract.status].label}"`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePaymentDetails = async (authToken: string) => {
    await newPaymentService
      .deletePaymentDetails(countryCode(contract.property.country), contract.id, authToken)
      .then(() => backStepContract())
      .catch((e) => {
        const error = e as AxiosError;
        const reason = error?.response;
        if (reason?.status === 404) {
          backStepContract();
        } else {
          setLoading(false);
          Swal.fire(
            "Error",
            `No se ha podido eliminar los detalles de pago asociados al contrato, Error: ${error}`,
            "error"
          );
        }
      });
  };

  const handleDeletePaymentButton = async () => {
    setLoading(true);
    const authToken = await getToken();
    await newPaymentService
      .deletePaymentButton(countryCode(contract.property.country), contract.id, authToken)
      .then(() => handleDeletePaymentDetails(authToken))
      .catch((e) => {
        const error = e as AxiosError<{ message?: string }>;
        const reason = error?.response;
        if (reason?.status === 404) {
          handleDeletePaymentDetails(authToken);
        } else {
          setLoading(false);
          Swal.fire(
            "Error",
            `No se ha podido eliminar el botón de cierre, Error: ${
              reason ? reason?.data?.message : e
            }`,
            "error"
          );
        }
      });
  };

  const handleChangeStatus = () => {
    Swal.fire({
      title: "Atención",
      text: `El contracto cambiará de estado a ${
        renderBackStep[contract.status].label
      }, ¿Está seguro?`,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        if (contract.status === "Boton de pago enviado") {
          handleDeletePaymentButton();
        } else {
          backStepContract();
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div className={classes.container}>
        <Breadcrumbs steps={steps(contract)} />
        <CountryChip country={property.country as CountryUpperCase} className={classes.chip} />
      </div>
      <Grid
        container
        className={classes.containerTitle}
        justifyContent="space-between"
        alignItems="center">
        <Grid item>
          <div className={classes.contractTitle}>
            <Typography component="span" className={classes.textTitle}>
              Contrato ID {contract.id}
            </Typography>
            {!brokerage && (
              <Chip
                className={classes.borkerageInfo}
                color="primary"
                label="Sin corretaje"
                size="medium"
              />
            )}
          </div>
          <Typography component="h6" className={classes.textSubtitle}>
            Estado: {contract.status}
          </Typography>
          {checkStatus(
            ["Generar boton de pago", "Pagado", "Desistido", "Rechazado", "Boton de pago enviado"],
            contract.status
          ) &&
            contract?.quotation?.id && (
              <Link
                to={`/admin/clients/${contract?.quotation?.id}/`}
                target="_blank"
                className={classes.linkSubscription}>
                Ver subscripción
              </Link>
            )}
        </Grid>
        <Grid item>
          {checkStatus(
            ["Generar boton de pago", "Listo para redaccion", "Boton de pago enviado"],
            contract.status
          ) && (
            <DesignSystemButton
              label={`Devolver contrato a "${renderBackStep[contract.status].label}"`}
              variant="primary"
              type="button"
              size="medium"
              onClick={() => handleChangeStatus()}
              isLoading={loading}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
