import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import httpClient from "@houm-com/http-client";

import { IPlanMovement, ISimulationData } from "models/Contract";

import { BACKEND_URL } from "../../env";
import {
  IAppliedPromotions,
  IApplyPromo,
  IContractDebtStateResponse,
  IContractExitProcessData,
  IExitProcessResponse,
  ILegacyContractDocumentsResponse,
  IAcceptanceDocumentsResponse,
  IDocumentSectionsLinksResponse,
  IDesertionProcessInfoResponse,
  ITenantPlan,
  IContractTypes,
  IExternalCollection,
  IExternalCollectionResponse,
} from "./types";
import { clientConfig, createHttp, getToken } from "../helper";

const getPagination = (pageSize, page) =>
  pageSize && page ? `page_size=${pageSize}&page=${page}` : "";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  async index(pageSize = 10, page = 1, status, country, token, queryObject) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    const queryParams = { ...queryObject, status, country };
    const queryString = qs.stringify(queryParams, qsConfig);
    return http.get(`/v2/contracts/?${getPagination(pageSize, page)}&${queryString}`);
  },

  get(contractId, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.get(`/v2/contracts/${contractId}/`);
  },

  sendProfileV2(contractId: number, token: string) {
    return createHttp(token).post(`/v2/contracts/${contractId}/new_send_profile/`);
  },

  sendReminder(contractId, token) {
    return createHttp(token).post(`/v2/contracts/${contractId}/send_lessor_remainder/`);
  },

  newRetrieveSignatureStatus(contractId, token) {
    return createHttp(token).get(`/v2/contracts/${contractId}/retrieve_signature_status`);
  },

  getContractDocuments(
    contractId: number,
    type: string,
    token: string
  ): Promise<AxiosResponse<ILegacyContractDocumentsResponse[]>> {
    return createHttp(token).get(`/v2/contracts/${contractId}/documents?type=${type}`);
  },

  getDocumentSectionsLinks(
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IDocumentSectionsLinksResponse>> {
    return createHttp(token).get(`/v2/contracts/${contractId}/documents/document_sections_links`);
  },

  create(data, token) {
    return createHttp(token).post("/admin/v2/init-contract-with-tenant-and-owner/", data);
  },

  update(id, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.put(`/v2/contracts/${id}/`, data);
  },

  setPlan(contractId, formData, token) {
    return createHttp(token).put(`/v2/contracts/${contractId}/set_plan/`, formData);
  },

  setContractTenantPlan(contractId: number, data: ITenantPlan, token: string) {
    return createHttp(token).post(`/admin/v2/contracts/${contractId}/tenant-plan/`, data);
  },

  getContractTenantPlans(token: string) {
    return createHttp(token).get(`/admin/v2/contracts/tenant-plans`);
  },

  getCurrentContractTenantPlan(contractId: number, token: string) {
    return createHttp(token).get(`/admin/v2/contracts/${contractId}/tenant-plan/`);
  },

  getPerson(personId, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.get(`/lessors/v2/contract_persons/${personId}/`).catch((error) => false);
  },

  getPersonByDocument(document, documentType = "", token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http
      .get(`/lessors/v2/contract_persons/get_by_document`, {
        params: {
          document,
          document_type: documentType,
        },
      })
      .catch((error) => {
        if (error.response.status !== 404) {
          console.error(error);
        }
        return error;
      });
  },

  createPerson(contractId, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.post(`/v2/contracts/${contractId}/new_person/`, data);
  },

  setContractData(token: string, data: FormData) {
    return createHttp(token).post(`/v2/contracts/data/set/`, data);
  },

  setCustomContractData(token: string, data: FormData) {
    return createHttp(token).post(`/v2/contracts/data/set/custom/`, data);
  },

  registerLegalRepresentative(contractId, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.post(`/v2/contracts/${contractId}/new_legal_representative/`, data);
  },

  signFiles: (contractId, token) =>
    createHttp(token)
      .post(`/v2/contracts/${contractId}/sign_files/`)
      .catch((error) => {
        throw error;
      }),

  sendSignedFiles: (contractId, token) =>
    createHttp(token)
      .post(`/v2/contracts/${contractId}/send_signed_files/`)
      .catch((error) => {
        throw error;
      }),

  generateNewSigningLink(data, token) {
    return createHttp(token).post(`/v2/contracts/link/generate-new/`, data);
  },

  retrieveSignedContracts(contractId, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.get(`/v2/contracts/${contractId}/signed_contracts`);
  },

  init(data, token) {
    return createHttp(token).post(`/admin/v2/init-contract/`, data);
  },

  getSignatureFile(link) {
    return axios({
      url: link,
      method: "GET",
      responseType: "blob",
    }).catch((error) => false);
  },

  notifyFilledForm(propertyId, token) {
    return createHttp(token).post(
      `properties/v2/${propertyId}/send_contract_form_fill_notification`
    );
  },

  deleteCodebtor(contractId, contractPersonId, token) {
    return createHttp(token).delete(`/v2/contracts/${contractId}/remove_codebtor`, {
      data: { contract_person_id: contractPersonId },
    });
  },

  getSimulation(
    contractId: number,
    save: boolean,
    token: string
  ): Promise<AxiosResponse<ISimulationData>> {
    const client = httpClient(clientConfig(token));
    return client.post(`/v2/contracts/${contractId}/payment_simulation`, {
      save,
    });
  },

  getPlanMovements(contractId, token) {
    return createHttp(token).get(`/v2/contract_discount/?contract_id=${contractId}`);
  },

  createPlanMovements(data: IPlanMovement, token: string): Promise<AxiosResponse<IPlanMovement>> {
    return createHttp(token).post(`/v2/contract_discount/`, data);
  },

  editPlanMovements(
    data: IPlanMovement,
    movementId: number,
    token: string
  ): Promise<AxiosResponse<IPlanMovement>> {
    return createHttp(token).put(`/v2/contract_discount/${movementId}/`, data);
  },

  deletePlanMovements(movementId: number, token: string) {
    return createHttp(token).delete(`/v2/contract_discount/${movementId}/`);
  },

  createLegalRepresentative(contractId, data, token) {
    return createHttp(token).post(`/v2/contracts/${contractId}/new_legal_representative/`, data);
  },

  createNewPerson(contractId, data, token) {
    return createHttp(token).post(`/v2/contracts/${contractId}/new_person/`, data);
  },

  newNotifyInitOnlineContract(contractId, token) {
    return createHttp(token).post(`/v2/contracts/online-signing/start`, {
      contract_id: contractId,
    });
  },

  getContractByProperty(propertyId, token) {
    return createHttp(token).get(`/v2/contract_by_property/${propertyId}`);
  },

  contractPaymentIncome: (contractId, token) =>
    createHttp(token, BACKEND_URL).post(`/v2/contracts/${contractId}/payment_generate_button`),

  getExitProcess: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IExitProcessResponse>> =>
    createHttp(token).get(`/v2/contracts/${contractId}/exit_process/`),

  beginExitProcess: (contractId: number, token: string, data: IContractExitProcessData) =>
    createHttp(token).put(`/v2/contracts/${contractId}/exit_process/begin`, data),

  cancelExitProcess: (contractId: number, token: string) =>
    createHttp(token).put(`/v2/contracts/${contractId}/exit_process/cancel`),

  finalizeExitProcess: (contractId: number, token: string) =>
    createHttp(token).put(`/v2/contracts/${contractId}/exit_process/finalize`),

  updateAgreedDateExitProcess: (contractId: number, token: string, data: { agreed_date: string }) =>
    createHttp(token).put(`/v2/contracts/${contractId}/exit_process/agreed_date`, data),

  applyPromotion: (applyData: IApplyPromo, token: string) =>
    createHttp(token).post(`admin/v2/promotions/apply_closure/`, applyData),

  appliedPromotionById: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IAppliedPromotions[]>> =>
    createHttp(token).get(`admin/v2/promotions/applied?contract=${contractId}`),

  deleteAppliedPromotion: (contractId: number, promotionId: number, token: string) =>
    createHttp(token).delete(
      `admin/v2/promotions/cancel_apply/?promotion=${promotionId}&contract=${contractId}`
    ),

  changeContractConditions: (
    contractId: number,
    data: {
      amount?: number;
      common_expenses?: number;
      type_money?: "pesos" | "uf";
      plan?: number;
      readjust?: "Anual" | "Semestral" | "Trimestral" | "Sin Reajuste";
    },
    token: string
  ) => createHttp(token).post(`/v2/contracts/${contractId}/change-request/`, data),

  getContractDebtState: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IContractDebtStateResponse>> =>
    createHttp(token, BACKEND_URL).get(
      `/admin/v2/debt-collection/contract_state?contract_id=${contractId}`
    ),

  getAcceptanceDocuments: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IAcceptanceDocumentsResponse>> =>
    createHttp(token, BACKEND_URL).get(`/v2/contracts/${contractId}/acceptance_documents/`),

  deleteContractPerson: (contractId: number, data: { contract_person_id: number }, token: string) =>
    createHttp(token, BACKEND_URL).put(`/v2/contracts/${contractId}/remove_person/`, data),

  startDesertionProcess: (contractId: number, token: string) =>
    createHttp(token).post(`/v2/contracts/${contractId}/desertion/begin/`),

  getDesertionProcessInfo: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IDesertionProcessInfoResponse>> =>
    createHttp(token).get(`/v2/contracts/${contractId}/desertion/`),

  cancelDesertionProcess: (contractId: number, token: string) =>
    createHttp(token).patch(`/v2/contracts/${contractId}/desertion/cancel/`),

  getContractTypes: (token: string): Promise<AxiosResponse<IContractTypes>> =>
    createHttp(token).get("/v2/contracts/types"),

  getExternalCollections: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IExternalCollectionResponse>> =>
    createHttp(token).get(`/v2/contracts/${contractId}/external-collection`),

  postExternalCollection: (contractId: number, token: string, data: IExternalCollection) =>
    createHttp(token).post(`/v2/contracts/${contractId}/external-collection`, data),
};
