import { useMemo } from "react";

import moment from "moment";
import { Button } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { useGetToken } from "hooks";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useList } from "context/demandLeadContext/list/listContext";
import {
  setIsOpenActivityForm,
  updateActivity,
} from "context/demandLeadContext/lead/demandLeadActions";

import {
  ControlledTextField,
  ControlledAutocomplete,
  ControlledDatePicker,
} from "Components/Admin/UIComponents/ControlledForm";

import CompletedActivity from "../modals/CompletedActivity";
import useActivityFormStyles from "./ActivityFormStyles";
import { activitySchema } from "./validationSchema";

import { options, originOptions } from "Components/Admin/administrator/demand/utils/options";

function ActivityForm() {
  const classes = useActivityFormStyles();
  const methods = useForm({ resolver: yupResolver(activitySchema) });
  const state = methods.watch("state");

  const { getToken } = useGetToken();

  const {
    state: { isOpenActivityForm, completedActivity, lead },
    dispatch,
  } = useDemandLead();
  const {
    state: { lostReasons },
  } = useList();

  const textFieldProps = useMemo(
    () => ({
      InputProps: {
        disableUnderline: false,
        classes: {
          underline: classes.underline,
        },
      },
    }),
    []
  );

  const handleSubmit = (data) => {
    getToken().then((token) => {
      updateActivity(
        dispatch,
        completedActivity.currentActivity.id,
        lead,
        { ...data, mode: data.state.value },
        token
      );
    });
  };

  if (!isOpenActivityForm) return null;

  return (
    <FormProvider {...methods}>
      {/* Modal */}
      <CompletedActivity />
      {/* /Modal */}
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={classes.container}>
          <div className={classes.defaultColumn}>
            <div className={classes.middleColumn}>
              <ControlledAutocomplete
                id="state"
                label=""
                options={options}
                textFieldProps={{
                  ...textFieldProps,
                  placeholder: "Estado",
                  disabled: true,
                }}
                defaultValue={options[0]}
              />
            </div>
            {options[2].value === state?.value && (
              <div className={classes.middleColumn}>
                <ControlledAutocomplete
                  id="origin"
                  label=""
                  options={originOptions}
                  defaultValue={originOptions[1]}
                  textFieldProps={{
                    ...textFieldProps,
                    placeholder: "Origen/Medio",
                  }}
                />
              </div>
            )}

            {options[1].value === state?.value && (
              <ControlledAutocomplete
                id="reason"
                label=""
                options={lostReasons}
                textFieldProps={{ ...textFieldProps, placeholder: "Razón" }}
              />
            )}

            {options[2].value === state?.value && (
              <div className={classes.middleColumn}>
                <ControlledDatePicker
                  type="date"
                  label="Vencimiento"
                  id="date"
                  textFieldProps={{
                    ...textFieldProps,
                    inputProps: { min: `${moment().year() - 1}-01-01` },
                  }}
                />
              </div>
            )}
            {options[2].value === state?.value && (
              <div className={classes.middleColumn}>
                <ControlledDatePicker
                  type="time"
                  label="-"
                  id="time"
                  textFieldProps={textFieldProps}
                />
              </div>
            )}
          </div>
          <div className={classes.defaultColumn}>
            <ControlledTextField
              textFieldProps={{
                multiline: true,
                minRows: 5,
                InputProps: {
                  className: classes.textArea,
                },
              }}
              id="comments"
              label={<div className={classes.textAreaLabel}>Observaciones</div>}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="text"
              className={classes.textButton}
              onClick={() => dispatch(setIsOpenActivityForm(false))}>
              Cancelar
            </Button>
            <Button
              fullWidth
              className={classes.saveButton}
              color="primary"
              variant="outlined"
              size="small"
              type="submit">
              Guardar
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default ActivityForm;
