import { DialogContent, DialogTitle, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import useObservationModalStyles from "./ObservationModalStyles";

interface Props {
  openModal: boolean;
  onClose: () => void;
  observation: string;
}

const ObservationModal = ({ openModal, onClose, observation }: Props) => {
  const classes = useObservationModalStyles();
  return (
    <DSModal open={openModal} onClose={onClose} maxWidth="xs" showCloseButton scroll="paper">
      <DialogTitle>
        <Typography className={classes.title}>Comentario</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{observation}</Typography>
      </DialogContent>
    </DSModal>
  );
};

export default ObservationModal;
