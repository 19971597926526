import { Fade, Grid, LinearProgress, Typography } from "@material-ui/core";

import MarketplacePropertyCard from "Components/Admin/administrator/properties/show/partials/MarketplacePropertyCard";
import { useCalculator } from "context/calculatorContext/calculatorContext";
import { useSimilarProperties } from "context/similarProperties/similarPropertiesContext";
import { SimilarProperty } from "models";
import { Locale } from "models/Countries";

import useStyles from "./SimilarPropertiesGalleryStyles";

interface Props {
  properties: SimilarProperty[];
  locale: Locale;
}

function SimilarPropertiesGallery({ properties, locale }: Props) {
  const classes = useStyles();

  const { state } = useCalculator();
  const { state: similarPropState } = useSimilarProperties();
  const { loading } = similarPropState;
  const { deal } = state;

  if (loading)
    return (
      <Grid container spacing={3} className={classes.loadingContainer}>
        <Grid item xs={12}>
          <LinearProgress color="primary" />
        </Grid>
      </Grid>
    );

  if (properties.length === 0)
    return (
      <Grid container spacing={3} className={classes.noItemsContainer}>
        <Grid item xs={12} className={classes.noItems}>
          <Typography> No se encontraron propiedades similares</Typography>
        </Grid>
      </Grid>
    );
  return (
    <Fade in={properties?.length > 0}>
      <Grid container spacing={3} className={classes.galleryContainer}>
        {properties.map((similarProperty) => (
          <Grid item xs={6} md={4} lg={3}>
            <MarketplacePropertyCard property={similarProperty} locale={locale} deal={deal} />
          </Grid>
        ))}
      </Grid>
    </Fade>
  );
}

export default SimilarPropertiesGallery;
