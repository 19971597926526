import { makeStyles, Theme } from "@material-ui/core";

const InfoItemGeneralValueStyles = makeStyles<Theme>((theme) => ({
  value: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
}));

export default InfoItemGeneralValueStyles;
