import { IProperty } from "models";
import { CountryUpperCase, Timezones } from "models/Countries";
import { UserModel } from "models/User";
import { PhoneInput } from "models/PhoneInput";

export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
export const SET_APPRAISER_ID = "SET_APPRAISER_ID";
export const SET_TIMEZONE = "SET_TIMEZONE";
export const SET_LOADING = "SET_LOADING";
export const SET_DATES = "SET_DATES";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_APPRAISERS = "SET_APPRAISERS";
export const SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES";
export const SET_AVAILABILITY = "SET_AVAILABILITY";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_USER = "SET_USER";
export const SET_CONFIG_MODAL = "SET_CONFIG_MODAL";
export const SET_CANCEL_REASONS = "SET_CANCEL_REASONS";
export const SET_CANCEL_RESCHEDULE_MODAL = "SET_CANCEL_RESCHEDULE_MODAL";
export const SET_ADVISORY_RESPONSE = "SET_ADVISORY_RESPONSE";
export const SET_SCHEDULED = "SET_SCHEDULED";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY";

export type Dispatch = (action: Action) => void;

export interface Availability {
  //[key: number of the day]: [{beginHour: "07:00", endHour: 18:00}]
  [key: string]: { beginHour: string; endHour: string }[];
}

export interface ConfigModal {
  isOpen?: boolean;
  title?: string;
  subtitle?: string;
  reload?: boolean;
}

export interface ConfigCancelRescheduleModal {
  isOpen?: boolean;
  type?: "reschedule" | "cancelation";
}

export interface CancelReasons {
  value: number;
  label: string;
}

export interface TimeBlock {
  day: number;
  beginHour: string;
  endHour: string;
}

export interface AdvisoryResponse {
  appraiserId: string;
  appraiserName: string;
  scheduleId: number;
  daySchedule: string;
  leadEmail: string;
}

export interface State {
  scheduled?: boolean;
  cancelRescheduleModal: ConfigCancelRescheduleModal;
  cancelReasons: CancelReasons[];
  user: UserModel;
  events: Options[];
  documentTypes: Options[];
  loading: boolean;
  eventType: string;
  appraiserId: string;
  beginDate: string;
  endDate: string;
  appraisers: Options[];
  appraiserEmail: string;
  propertyId: number;
  userType: string;
  availability: Availability;
  property?: IProperty;
  configModal?: ConfigModal;
  advisoryResponse?: AdvisoryResponse;
  userEmail?: string;
  userCountry?: CountryUpperCase;
}

export interface SetIsOpenCancelRescheduleModal {
  type: typeof SET_CANCEL_RESCHEDULE_MODAL;
  cancelRescheduleModal: ConfigCancelRescheduleModal;
}
interface SetScheduled {
  type: typeof SET_SCHEDULED;
  scheduled: boolean;
}

interface SetConfigModal {
  type: typeof SET_CONFIG_MODAL;
  configModal: ConfigModal;
}

export interface SetCancelReasons {
  type: typeof SET_CANCEL_REASONS;
  cancelReasons: CancelReasons[];
}

interface SetProperty {
  type: typeof SET_PROPERTY;
  property: IProperty;
}

interface SetUser {
  type: typeof SET_USER;
  user: UserModel;
}

interface setEventTypes {
  type: typeof SET_EVENT_TYPES;
  events: Options[];
}

interface setAppraisers {
  type: typeof SET_APPRAISERS;
  appraisers: Options[];
}

interface setDocTypes {
  type: typeof SET_DOCUMENT_TYPES;
  documentTypes: Options[];
}

interface setEventType {
  type: typeof SET_EVENT_TYPE;
  eventType: string;
}

interface setAppraiser {
  type: typeof SET_APPRAISER_ID;
  appraiserId: string;
}

interface setTimezone {
  type: typeof SET_TIMEZONE;
  timezone: string;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setDates {
  type: typeof SET_DATES;
  beginDate: string;
  endDate: string;
}

interface setComments {
  type: typeof SET_COMMENTS;
  comments: string;
}
interface SetAvailability {
  type: typeof SET_AVAILABILITY;
  avalability?: Availability;
}
interface SetAdvisoryResponse {
  type: typeof SET_ADVISORY_RESPONSE;
  advisoryResponse?: AdvisoryResponse;
}

interface SetUserEmail {
  type: typeof SET_USER_EMAIL;
  userEmail?: string;
}

interface SetUserCountry {
  type: typeof SET_USER_COUNTRY;
  userCountry?: CountryUpperCase;
}

export type Action =
  | SetScheduled
  | SetIsOpenCancelRescheduleModal
  | SetCancelReasons
  | SetConfigModal
  | SetProperty
  | SetUser
  | SetAdvisoryResponse
  | setEventTypes
  | setAppraiser
  | setTimezone
  | setLoading
  | setDates
  | setComments
  | setEventType
  | setAppraisers
  | SetAvailability
  | SetUserEmail
  | SetUserCountry
  | setDocTypes;

export interface CreateEventForm {
  cancelReason: NumberOption;
  event_type: Options;
  appraiser: Options;
  begin_date: string;
  end_date: string;
  date: string;
  comment: string;
  property: IProperty;
  user: UserModel;
  hour: string;
  availability: Availability;
  timezone: Timezones;
  email: string;
  name: string;
  last_name: string;
  phone: PhoneInput;
  document: string;
  documentType: string;
}

export interface UserFormType {
  name: string;
  last_name: string;
  country: string;
  email: string;
  phone_number: string;
  event_type: string;
}

export interface Options {
  value: string;
  label: string;
}
export interface NumberOption {
  value: number;
  label: string;
}
