import IconButton from "@houm-com/ui/IconButton";

import { GridFilterListIcon } from "@material-ui/data-grid";
import { GridToolbarContainer } from "@material-ui/x-grid";

import { useTable } from "context/tableContext/tableContext";
import { setShowModal } from "context/tableContext/tableActions";

const Toolbar = () => {
  const { dispatch } = useTable();

  const handleFilterClick = () => dispatch(setShowModal(true));

  return (
    <GridToolbarContainer className="flex items-center justify-end w-full p-0 mb-2">
      <IconButton
        variant="tertiary"
        size="sm"
        position="start"
        icon={<GridFilterListIcon color="primary" />}
        onClick={handleFilterClick}>
        Filtrar
      </IconButton>
    </GridToolbarContainer>
  );
};

export default Toolbar;
