import moment from "moment";

import { Tag, TagForm } from "./tagTypes";

export const parseTags = (data): Tag[] =>
  data.map((item) => ({
    associationId: item?.id,
    id: item?.tag?.id,
    name: item?.tag?.tag,
    country: item?.tag?.country,
    description: item?.tag?.description,
    beginDate: moment.utc(item?.start_date).format("DD-MM-YYYY"),
    dueDate: moment.utc(item?.due_date).format("DD-MM-YYYY"),
  }));

export const parseTagDetails = (data: TagForm) => {
  const tagDetails = {
    start_date: moment(data.beginDate).format("YYYY-MM-DD"),
    tag_id: data.id,
    ids: data.ids,
    country: data.country,
    ...(data.dueDate && {
      due_date: moment(data.dueDate).format("YYYY-MM-DD"),
    }),
  };
  return tagDetails;
};
