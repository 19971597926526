import { ContractTemplatesProvider } from "context/contractTemplatesContext/contractTemplatesContext";
import ContractTemplates from "./ContractTemplates";

export default function ContractTempaltes() {
  return (
    <ContractTemplatesProvider>
      <ContractTemplates />
    </ContractTemplatesProvider>
  );
}
