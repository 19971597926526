import { makeStyles, Theme } from "@material-ui/core";

interface IPropsTheme {
  disabled?: boolean;
  compressed?: boolean;
  wired?: boolean;
}

export const useOptionButtonStyles = makeStyles<Theme, IPropsTheme>((theme) => ({
  contract: {
    minWidth: theme.spacing(2),
  },
  optionButtonWrap: {
    textTransform: "none",
    fontWeight: 600,
    marginRight: "10px",
    border: "1px solid",
    borderRadius: "6px",
    minWidth: ({ wired }) => (wired ? "160px" : ""),
    padding: ({ compressed }) => (compressed ? "3px 11px" : ""),
    borderColor: ({ disabled }) =>
      !disabled ? theme.palette.secondary.main : theme.palette.secondary.light,
    color: ({ disabled }) =>
      !disabled ? theme.palette.primary.main : theme.palette.secondary.light,
    fontSize: ({ compressed }) => (compressed ? "0.875rem" : "1rem"),
    backgroundColor: ({ disabled }) => (!disabled ? theme.palette.secondary.main : "white"),

    "&:hover": {
      backgroundColor: ({ disabled }) => (!disabled ? theme.palette.primary.light : "#f5f5f5"),
      color: ({ disabled }) => (!disabled ? "white" : ""),
    },
  },
}));

export const useButtonNavigationStyles = makeStyles<Theme, IPropsTheme>((theme) => ({
  largeIcon: {
    fontSize: "1rem",
    color: "#607D8B",
  },
  nextButton: {
    textTransform: "none",
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    fontWeight: 600,
    fontSize: "0.875rem",
    borderRadius: "6px",
    color: ({ disabled }) => (!disabled ? theme.palette.primary.contrastText : "white"),
    backgroundColor: ({ disabled }) =>
      !disabled ? theme.palette.primary.main : theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  backButton: {
    backgroundColor: "white",
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    fontWeight: 600,
    fontSize: "0.875rem",
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  childrenBackButton: {
    color: theme.palette.grey[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

interface IPropsHourButtonStyle {
  active?: boolean;
}
export const useHourButtonStyles = makeStyles<Theme, IPropsHourButtonStyle>((theme) => ({
  hourButton: {
    borderRadius: "10px",
    border: "2px solid",
    fontSize: "1rem",
    padding: theme.spacing(2, 5),
    fontWeight: ({ active }) => (active ? 700 : 600),
    backgroundColor: ({ active }) => (active ? theme.palette.secondary.main : "white"),
    color: ({ active }) => (active ? theme.palette.primary.main : theme.palette.grey[900]),
    borderColor: ({ active }) => (active ? theme.palette.primary.main : theme.palette.grey[100]),

    "&:hover": {
      backgroundColor: ({ active }) => (active ? theme.palette.primary.light : "#f5f5f5"),
      color: ({ active }) => (active ? "white" : ""),
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      padding: theme.spacing(1.5, 4),
    },
  },
}));
