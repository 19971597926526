import { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { useList } from "context/demandLeadContext/list/listContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import Title from "./Title";
import ControlledSelectionStyled from "./Inputs/ControlledSelectionStyled";
import ControlledTextfieldStyled from "./Inputs/ControlledTextfieldStyled";

import { type, propertyStatus } from "./options";

function PropertyInformation() {
  const { watch } = useFormContext();
  const {
    state: { cities },
  } = useList();

  const {
    state: { profiling },
  } = useDemandLead();

  const communes = useMemo(() => {
    const cityValue = watch("city");
    const communeArray = cities.find((city) => city.value === parseInt(cityValue))?.communes;

    return communeArray || [];
  }, [watch("city"), cities]);

  return (
    <>
      <Title text="Información de la propiedad" />
      <Grid container spacing={4}>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.city?.id || ""}
            compressed
            fullWidth
            simple
            placeholder="bogota/cdmx/santiago"
            options={cities}
            label="Ciudad"
            emptyValue={1}
            id="city"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.commune || ""}
            compressed
            fullWidth
            simple
            options={communes}
            label="Comuna"
            id="commune"
            emptyValue={null}
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.sector || ""}
            placeholder="2"
            label="Sector"
            id="sector"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.property_type || ""}
            compressed
            fullWidth
            simple
            options={type}
            label="Tipo de propiedad"
            id="property_type"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.property_state || ""}
            compressed
            fullWidth
            simple
            options={propertyStatus}
            label="Estado propiedad"
            id="property_state"
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.budget_min || ""}
            startAdornment="$"
            placeholder="0,00"
            label="Presupuesto Min. "
            id="budget_min"
            type="number"
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.budget_max || ""}
            startAdornment="$"
            placeholder="0,00"
            label="Presupuesto Max."
            id="budget_max"
            type="number"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PropertyInformation;
