import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import useScheduleErrorStyles from "./ScheduleErrorStyles";
import { getTypeError, parseDate } from "./utils/utils";

interface Props {
  error: {
    message: string;
    extra?: {
      quotation_id?: number;
      schedule_type?: string;
      date?: string;
    };
  };
}

const ScheduleError = ({ error }: Props) => {
  const classes = useScheduleErrorStyles({
    type: getTypeError(error?.message),
  });
  const { t } = useTranslation(["scheduler.error"]);

  return (
    <Box className={classes.errorContainer}>
      <Typography className={classes.message}>
        {t(error?.message, { ns: "scheduler.error" })}
        {error?.extra?.quotation_id && (
          <Link className={classes.link} to={`/admin/clients/${error.extra.quotation_id}`}>
            iniciar la salida aquí.
          </Link>
        )}
        {error?.extra?.date && (
          <Typography component="span" className={classes.message}>
            {parseDate(error.extra.date)}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default ScheduleError;
