import { makeStyles, Theme } from "@material-ui/core";

const useActionButtonStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    textTransform: "none",
    borderRadius: "20px",
    minWidth: 140,
    fontWeight: 700,
  },
}));

export default useActionButtonStyles;
