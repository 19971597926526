import { useEffect } from "react";
import {
  TimelineOutlined,
  ScheduleOutlined,
  AddAPhotoOutlined,
  EmojiPeopleOutlined,
} from "@material-ui/icons";

import { useGetToken } from "hooks";
import { HeaderWithIcon } from "Components/Admin/UIComponents";
import DashboardLayout from "Components/Admin/UIComponents/DashboardLayout";
import CardContainer from "Components/Admin/UIComponents/Cards/CardContainer";
import ProgressCard from "Components/Admin/UIComponents/Cards/ProgressBarCard";
import ChartCard from "Components/Admin/UIComponents/Cards/ChartCard";
import BarChart from "Components/Admin/UIComponents/atoms/BarChart";
import Table from "./usersTable";

import { useDashboard } from "context/controlTower/controlTowerContext";
import {
  getChartsData,
  getSummaryData,
  getAppraisers,
} from "context/controlTower/controlTowerActions";

function Dashboard() {
  const { getToken } = useGetToken();
  const { state: dashboardStates, dispatch } = useDashboard();
  const { todayData, tomorrowData, summary, loadingCharts, loadingSummary } = dashboardStates;

  useEffect(() => {
    getToken().then((authToken) => {
      getAppraisers(authToken, dispatch);
      getChartsData(authToken, dispatch);
      getSummaryData(authToken, dispatch);
    });
  }, []);

  return (
    <DashboardLayout>
      <HeaderWithIcon title="Control Tower: Dashboard" Icon={TimelineOutlined} />
      <CardContainer loading={loadingCharts || loadingSummary}>
        <ChartCard>
          <ProgressCard
            title="Mostradores activos"
            progress={summary.activeAppraisers}
            Icon={EmojiPeopleOutlined}
          />
          <ProgressCard
            title="Fotografos activos"
            progress={summary.activePhotographers}
            Icon={AddAPhotoOutlined}
          />
          <ProgressCard
            title="Horas ofrecidas"
            progress={summary.availableHours}
            Icon={ScheduleOutlined}
          />
        </ChartCard>
        <ChartCard title="Hoy">
          <BarChart inputData={todayData} labels={["Canceladas", "Terminadas", "En espera"]} />
        </ChartCard>
        <ChartCard title="Mañana">
          <BarChart inputData={tomorrowData} labels={["Canceladas", "Confirmadas", "En espera"]} />
        </ChartCard>
      </CardContainer>
      <Table />
    </DashboardLayout>
  );
}

export default Dashboard;
