import { NegotiationsProvider } from "context/negotiationsContext/negotiationsContext";
import NegotiationsTabIndex from "./NegotiationsTabIndex";

export default function NegotiationsController({ property }) {
  return (
    <NegotiationsProvider>
      <NegotiationsTabIndex property={property} />
    </NegotiationsProvider>
  );
}
