import { Grid } from "@material-ui/core";
import { LandlordSummary } from "models/AdminModels/Landlord";
import useLandlordDataGridStyles from "./LandlordDataGridStyles";
import GridItem from "./components/GridItem";
import GridItemTooltip from "./components/GridItemTooltip";

interface Props {
  data: LandlordSummary;
}

function LandlordDataGrid({ data }: Props) {
  const classes = useLandlordDataGridStyles();

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.row}>
        <GridItem text={`N° documento: ${data.document}`} />
      </Grid>

      <Grid container item xs={12} className={classes.row}>
        <GridItem text={`Teléfono: ${data.phone}`} />

        <GridItem text={`Propiedades totales: ${data.propertiesCount}`} />

        <GridItem text={`Requerimientos abiertos: ${data.tickets?.open || "-"}`} />
      </Grid>

      <Grid container item xs={12} className={classes.row}>
        <GridItemTooltip text={`Correo: ${data.email}`} tooltip={data.email} />

        <GridItem text={`Propiedades inactivas: ${data.propertiesCount - data.activeProperties}`} />

        <GridItem text={`Requerimientos pospuestos: ${data.tickets?.snoozed || "-"}`} />
      </Grid>
    </Grid>
  );
}

export default LandlordDataGrid;
