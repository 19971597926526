import { makeStyles } from "@material-ui/core";

const useTagBarStyles = makeStyles((theme) => ({
  tagsContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),
    flex: 1,
  },
}));
export default useTagBarStyles;
