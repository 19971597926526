import { format } from "date-fns";
import { GridColumns } from "@material-ui/x-grid";
import { SvgIcon, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import { RemoveRedEyeOutlined } from "@material-ui/icons";

import { Photo } from "Components/Admin/administrator/subscriptions/hooks/requests/types";

import { COLUMNS_CONFIG, REPAIRMENT_TYPE_LABEL, REQUESTOR_ROLE_LABEL } from "./constants";

interface getColumnsProps {
  classes: ClassNameMap;
  onObservationClick: (observation: string) => void;
  onPhotosClick: (observation: Photo[]) => void;
}

const OBSERVATION_CHARACTERS_MAX_LENGTH = 30;

const getColumns = ({
  classes,
  onObservationClick,
  onPhotosClick,
}: getColumnsProps): GridColumns => [
  {
    ...COLUMNS_CONFIG,
    field: "id",
    headerName: "ID",
    flex: 0.3,
  },
  {
    ...COLUMNS_CONFIG,
    field: "repairmentType",
    headerName: "Tipo de reparación",
    flex: 0.8,
    renderCell: ({ row }) => REPAIRMENT_TYPE_LABEL[row?.repairmentType],
  },
  {
    ...COLUMNS_CONFIG,
    field: "requestorRole",
    headerName: "Solicitante",
    flex: 0.6,
    renderCell: ({ row }) => REQUESTOR_ROLE_LABEL[row?.requestorRole],
  },
  {
    ...COLUMNS_CONFIG,
    field: "createdAt",
    headerName: "Fecha de creación",
    flex: 0.6,
    renderCell: ({ row }) => format(row?.createdAt, "dd/MM/yyyy"),
  },
  {
    ...COLUMNS_CONFIG,
    field: "observation",
    headerName: "Descripción",
    width: 300,
    renderCell: ({ row }) => (
      <Typography className={classes.observation}>
        <span className={classes.observationText}>{row.observation}</span>
        {row.observation?.length > OBSERVATION_CHARACTERS_MAX_LENGTH && (
          <SvgIcon className={classes.icon} onClick={() => onObservationClick(row.observation)}>
            <RemoveRedEyeOutlined />
          </SvgIcon>
        )}
      </Typography>
    ),
  },
  {
    ...COLUMNS_CONFIG,
    field: "photos",
    headerName: "Gestión",
    flex: 0.8,
    renderCell: ({ row }) => (
      <Typography className={classes.photos} onClick={() => onPhotosClick(row.photos)}>
        Ver fotos
        <SvgIcon className={classes.icon}>
          <RemoveRedEyeOutlined />
        </SvgIcon>
      </Typography>
    ),
  },
];

export default getColumns;
