import { ReactNode, useState } from "react";

import { GridColDef, GridColumns, GridCellParams } from "@material-ui/x-grid";
import moment from "moment";

import { CustomDataGrid } from "Components/Admin/UIComponents";
import PoperCell from "Components/Admin/administrator/properties/show/tabs/scheduler/PoperCell";

import { reportTypeTranslations, visitTypesTranslations } from "./utils/translations";
import useGetPropertyReports from "./hooks/useGetPropertyReports";
import useStyles from "./ReportTableStyles";

interface Props {
  propertyId: number;
}

function ReportTable({ propertyId }: Props) {
  const [paginationFilters, setPaginationFilters] = useState({
    page: 1,
    page_size: 10,
  });
  const classes = useStyles();

  const { data: reports, isLoading } = useGetPropertyReports(propertyId, paginationFilters);

  const renderCellExpand = (value: string | ReactNode, width: number) => (
    <PoperCell value={value} width={width} />
  );

  const columnsConfig: GridColDef = {
    flex: 1,
    field: "",
    resizable: true,
    filterable: false,
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      field: "type",
      headerName: "Tipo de solicitud",
      renderCell: ({ value, colDef }: GridCellParams) =>
        renderCellExpand(
          reportTypeTranslations[value as string] ?? "-------",
          colDef.computedWidth
        ),
    },
    {
      ...columnsConfig,
      field: "created_at",
      headerName: "Fecha de creación",
      renderCell: ({ value, colDef }: GridCellParams) =>
        renderCellExpand(
          moment(value as string).format("DD.MM.YYYY") ?? "-------",
          colDef.computedWidth
        ),
    },
    {
      ...columnsConfig,
      field: "schedule_type",
      headerName: "Tipo de visita",
      renderCell: ({ value, colDef }: GridCellParams) =>
        renderCellExpand(
          visitTypesTranslations[value as string] || "-------",
          colDef.computedWidth
        ),
    },
    {
      ...columnsConfig,
      field: "applicant",
      headerName: "Nombre del postulante",
      renderCell: ({ row, colDef }: GridCellParams) =>
        row?.applicant?.name
          ? renderCellExpand(
              `${row?.applicant?.name} ${row?.applicant?.last_name ?? ""}`,
              colDef.computedWidth
            )
          : "-------",
    },
    {
      ...columnsConfig,
      field: "hoummer",
      headerName: "Nombre del Houmer",
      renderCell: ({ row, colDef }: GridCellParams) =>
        row?.appraiser?.name
          ? renderCellExpand(
              `${row?.appraiser?.name} ${row?.appraiser?.last_name ?? ""}`,
              colDef.computedWidth
            )
          : "-------",
    },
    {
      ...columnsConfig,
      field: "description",
      headerName: "Descripción",
      renderCell: ({ value, colDef }: GridCellParams) =>
        renderCellExpand(value || "-------", colDef.computedWidth),
    },
  ];

  return (
    <CustomDataGrid
      columns={columns}
      loading={isLoading}
      rows={reports?.results ?? []}
      className={classes.table}
      rowCount={reports?.count ?? 0}
      page={paginationFilters.page - 1}
      pageSize={paginationFilters.page_size}
      onPageChange={(params) =>
        setPaginationFilters({
          ...paginationFilters,
          page: params + 1,
        })
      }
      onPageSizeChange={(params) => {
        setPaginationFilters({
          ...paginationFilters,
          page_size: params,
          page: 1,
        });
      }}
    />
  );
}

export default ReportTable;
