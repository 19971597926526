import { useQuery, UseQueryResult } from "react-query";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parserReceipts } from "./utils/parsers";
import { Receipt } from "./utils/types";

interface Props {
  country: Locale;
  movementId: string;
}

const useGetReceipts = ({ country, movementId }: Props) => {
  const { getToken } = useGetToken();

  const query: UseQueryResult<Receipt[], Error> = useQuery(
    ["receipts", country, movementId],
    async () => {
      const authToken = await getToken();
      const receiptsResponse = await newPaymentService.getReceipts(movementId, country, authToken);
      const parsedData = parserReceipts(receiptsResponse.data, country);
      return parsedData;
    },
    {
      enabled: !!movementId,
      retry: false,
    }
  );

  return {
    ...query,
  };
};

export default useGetReceipts;
