import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import { useGetToken } from "hooks";
import bankAccountService from "services/bankAccountService";
import { Locale } from "models/Countries";

interface FormData {
  identifier?: string;
  account_number: string;
  account_type: string;
  bank_id: string;
  document: string;
  email: string;
  holder: string;
}

const useBankAccount = (
  isNew: boolean,
  country: Locale,
  userId: number,
  propertyId: number,
  closeModal: () => void
) => {
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (data: FormData) => {
      const authToken = await getToken();
      if (isNew) await bankAccountService.create(country, userId, data, authToken);
      else await bankAccountService.update(country, userId, propertyId, data, authToken);
    },
    {
      onSuccess: () => {
        closeModal();
        dispatch(
          setAlertInfo({
            open: true,
            head: `La cuenta bancaria se ha ${isNew ? "creado" : "modificado"} con exito`,
            type: "success",
          })
        );
        queryClient.invalidateQueries("userBankAccount");
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: `La cuenta bancaria no se ha ${isNew ? "crear" : "modificar"}`,
            type: "error",
            body: `Error: ${reason ? reason?.data?.detail : e}`,
          })
        );
      },
    }
  );

  return { mutate, isLoading, isSuccess };
};

export default useBankAccount;
