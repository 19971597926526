import { makeStyles, Theme } from "@material-ui/core";

const useTenantFieldStyles = makeStyles((theme: Theme) => ({
  payment: { fontWeight: 700 },
  date: {
    marginBottom: theme.spacing(1),
  },
}));

export default useTenantFieldStyles;
