import { ReactNode, useState } from "react";
import moment from "moment";

import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useGetSchedulesCancelReasons from "hooks/useGetSchedules/useGetSchedulesCancelReasons";
import { ISchedule, IScheduleModifier } from "hooks/useGetSchedules/utils/types";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useGetSchedules from "hooks/useGetSchedules/useGetSchedules";
import { PropertyData } from "models/Property";
import { getTimezone } from "utils";

import ScheduleApplicant from "../../partials/ScheduleApplicant";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import ScheduleCancel from "../../partials/ScheduleCancel";
import PropertySchedules from "./PropertySchedules";
import Schedulers from "../../partials/Schedulers";
import useScheduleStyles from "./ScheduleStyles";
import PoperCell from "./PoperCell";

interface Props {
  property: PropertyData;
}

function Schedule({ property }: Props) {
  const classes = useScheduleStyles();
  const [schedulingHour, setSchedulingHour] = useState(false);
  const { cancelReasons, isLoading: cancelReasonsLoading } = useGetSchedulesCancelReasons(
    property.id
  );
  const { schedules = [], isLoading: schedulesLoading } = useGetSchedules(property.id);
  const isLoading = cancelReasonsLoading || schedulesLoading;

  const handleClose = () => {
    setSchedulingHour(false);
  };

  const getEditorName = (modifier: IScheduleModifier) => {
    if (modifier) {
      return `${modifier.name || ""} 
        ${(modifier.name !== modifier.lastName && modifier.lastName) || ""} 
        (${modifier.role})`;
    } else {
      return null;
    }
  };

  const renderCellExpand = (value: string | ReactNode, width: number) => (
    <PoperCell value={value} width={width} />
  );

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
  };

  const idConfig: GridColDef = {
    field: "",
    resizable: false,
    flex: 1,
    filterable: false,
    hide: false,
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      field: "id",
      headerName: "ID",
      flex: 0.66,
      renderCell: ({ row }: GridCellParams) => row.id,
    },
    {
      ...idConfig,
      field: "begin_date",
      headerName: "Fecha",
      renderCell: ({ row }: GridCellParams) => (
        <span>
          {moment
            .utc(row.beginDate)
            .clone()
            .tz(getTimezone(property.country, property.comuna))
            .format("YYYY-MM-DD HH:mm")}
        </span>
      ),
    },
    {
      ...columnsConfig,
      field: "applicant",
      headerName: "Postulante",
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(<ScheduleApplicant schedule={row as ISchedule} />, colDef.computedWidth),
    },
    {
      ...columnsConfig,
      field: "comment",
      headerName: "Comentario de Interés",
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(row.feedback, colDef.computedWidth),
    },
    {
      ...columnsConfig,
      field: "appraiser",
      headerName: "Agente",
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(row.appraiser.name, colDef.computedWidth),
    },
    {
      ...columnsConfig,
      field: "modifier",
      headerName: "Editor",
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(getEditorName(row.modifier), colDef.computedWidth),
    },
    {
      ...columnsConfig,
      field: "schedule_type",
      headerName: "Tipo",
      sortable: false,
      flex: 0.66,
      renderCell: ({ row }: GridCellParams) => row.scheduleType.name,
    },
    {
      ...columnsConfig,
      field: "status",
      headerName: "Estado",
      sortable: false,
      flex: 0.7,
      renderCell: ({ row }: GridCellParams) => row.status,
    },
    {
      ...columnsConfig,
      field: "cancel_reason_motive",
      headerName: "Motivo de cancelacion",
      sortable: false,
      flex: 1.4,
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(
          <ScheduleCancel schedule={row as ISchedule} reasons={cancelReasons} />,
          colDef.computedWidth
        ),
    },
  ];

  return (
    <Loading loading={isLoading}>
      <Grid container spacing={3}>
        <CustomDialog open={schedulingHour} title="Agendamiento" onClose={() => handleClose()}>
          <Schedulers property={property} />
        </CustomDialog>
        <Grid container item xs={12} justifyContent="flex-start">
          <DesignSystemButton
            variant="primary"
            size="small"
            label="Agendar"
            onClick={() => setSchedulingHour(true)}
          />
        </Grid>
        <Grid item xs={12}>
          <CollapseCard title="Visitas">
            <div className={classes.collapse}>
              <CustomDataGrid
                className={classes.table}
                rowCount={schedules?.length}
                columns={columns}
                rows={schedules}
                hideFooter
                hideFooterPagination
                hideFooterRowCount
              />
            </div>
          </CollapseCard>
        </Grid>
        <Grid item xs={12}>
          <CollapseCard title="Calendario de visitas">
            <PropertySchedules
              propertyId={property?.id}
              country={property?.country}
              commune={property?.comuna}
            />
          </CollapseCard>
        </Grid>
      </Grid>
    </Loading>
  );
}

export default Schedule;
