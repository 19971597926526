import { format } from "date-fns";

import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";
import Spinner from "Components/Shared/partials/Spinner";
import { Tenant } from "models/AdminModels/Contracts";
import { Locale } from "models/Countries";
import { numberWithCommas } from "utils";

import { getTenantPaymentStatusTagProps } from "../../utils/utils";
import useTenantFieldStyles from "./TenantFieldStyles";
import { MainText } from "../../../typography";
import useTenantField from "./useTenantField";
import BaseField from "../../../BaseField";

interface Props extends Tenant {
  propertyId: number;
  status: string;
  country: Locale;
}

function TenantField({ id, name, phone, email, propertyId, status, country }: Props) {
  const classes = useTenantFieldStyles();
  const { isLoading, error, data } = useTenantField(propertyId, country);

  if (isLoading) return <Spinner size={20} />;

  return (
    <BaseField requiredData={id && status === "LEASED"}>
      <MainText className={classes.payment}>${numberWithCommas(data?.lesseePayment)}</MainText>
      {data?.lesseePaymentDate && data?.lesseePaymentStatus !== "UNPAID" && (
        <MainText className={classes.date}>
          {format(data?.lesseePaymentDate, "dd/MM/yyyy")}
        </MainText>
      )}
      {!error && (
        <DSTag {...getTenantPaymentStatusTagProps(data?.lesseePaymentStatus, data?.agreedDate)} />
      )}
    </BaseField>
  );
}

export default TenantField;
