export enum MovementConcept {
  MANAGEMENT = "Administración",
  LEASE = "Mensualidad",
  INSURANCE = "Seguro",
  CLEANING = "Limpieza",
  BROKERAGE = "Corretaje",
  CONTRACT = "Contrato",
  RESERVATION = "Reserva",
  OPERATIONAL = "Cobro Operacional",
}

export enum MovementType {
  TICKET = "Boleta",
  INVOICE = "Factura",
}

export interface Receipt {
  id: number;
  label: string;
  url: string;
  source: string;
}
