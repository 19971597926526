import { UsersProvider } from "Components/Admin/administrator/usersLeads/context/usersContext";
import UsersTable from "./UsersTable";
import { TableProvider } from "context/tableContext/tableContext";
import { SnackbarProvider } from "notistack";

export default function UsersTableController() {
  return (
    <UsersProvider>
      <SnackbarProvider>
        <TableProvider>
          <UsersTable />
        </TableProvider>
      </SnackbarProvider>
    </UsersProvider>
  );
}
