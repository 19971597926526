import { makeStyles } from "@material-ui/core";

export default makeStyles({
  table: {
    border: 0,
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "#F7F8FC",
    },
    "& .MuiDataGrid-cell": {
      borderBottomWidth: 0,
    },
    "& .MuiDataGrid-overlay": {
      alignSelf: "start",
    },
  },
});
