import { makeStyles, Theme } from "@material-ui/core";

export const useSelectStyles = makeStyles((theme: Theme) => ({
  select: {
    width: "100%",
  },
  vipIcon: {
    color: theme.palette.warning.main,
  },
  multifamilyIcon: {
    color: theme.palette.success.main,
  },
  chip: {
    fontWeight: "bold",
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[2],
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  changeButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 700,
  },
  editIcon: {
    fontSize: 18,
    marginLeft: theme.spacing(0.5),
  },
  cardRoot: {
    margin: theme.spacing(0, 0, 2),
  },
  leadCardTitle: {
    fontWeight: 700,
    fontSize: "1.25rem",
    marginBottom: theme.spacing(2),
  },
  propertyCardTitle: {
    fontWeight: 700,
    fontSize: "1.75rem",
    marginBottom: theme.spacing(2),
  },
  cardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  updateButton: {
    border: "none",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  personEditable: {
    display: "flex",
  },
  actionButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "end",
  },
  actionsDropdownTrigger: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: "#263238",
  },

  actionsButtonsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1.2),
  },
  actionButtonsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    alignItems: "center",
    width: "100%",
  },
  dropdownIcon: {
    width: 24,
    height: 24,
    "&>path": {
      stroke: "#263238",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerPaper: {
    paddingBottom: theme.spacing(4),
  },
}));

export const usePropertyInfoStyles = makeStyles((theme) => ({
  itemBoldProperty: {
    fontWeight: "bold",
    marginRight: theme.spacing(0.5),
  },
  extraOnItem: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(5),
  },
}));
