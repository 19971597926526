import { makeStyles, Theme } from "@material-ui/core";

const useBasicServiceModalStyles = makeStyles((theme: Theme) => ({
  addBasicServiceRoot: {
    padding: theme.spacing(1, 4),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
}));

export default useBasicServiceModalStyles;
