import * as yup from "yup";
import { validate, format } from "rut.js";

import { Locale } from "models/Countries";

export const requiredText = "Obligatorio";

const bannedDocumentListChile = ["11111111", "1.111.111-1", "11.111.111-1", "111111111"];
type DocumentTypes = {
  value: number;
  label: string;
  regex: string;
};

export const bankAccountSchema = (country: Locale, documentTypeList: DocumentTypes[]) =>
  yup
    .object({
      account_number: yup.string().typeError(requiredText).required(requiredText),
      account_type: country !== "mx" && yup.string().required(requiredText),
      bank_id: yup.string().typeError(requiredText).required(requiredText),
      document: yup.string().required(requiredText),
      document_type: country !== "cl" && yup.string().required(requiredText),
      email: yup.string().email("Email inválido").required(requiredText),
      holder: yup.string().required(requiredText),
      clabe_number:
        country === "mx" &&
        yup
          .string()
          .typeError(requiredText)
          .required(requiredText)
          .test("len", "Cuenta clabe no válida", (val) => val.toString().length === 18),
    })
    .test("validationDocument", null, (obj) => {
      if (country === "cl") {
        if (validate(format(obj.document))) return true;
        else return new yup.ValidationError("Documento no válido", null, "document", "typeError");
      }
      if (!obj.document_type) {
        return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
      }
      const regexValue = documentTypeList.filter(
        (doc) => doc.value === Number(obj.document_type)
      )[0];
      const regex = new RegExp(regexValue.regex);
      if (parseInt(obj.document_type) === 1 && bannedDocumentListChile.includes(obj.document)) {
        return new yup.ValidationError(
          "No es posible usar un RUT genérico",
          null,
          "document",
          "typeError"
        );
      }
      if (regex.test(obj.document.toUpperCase())) {
        return true;
      }
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
