import { Tooltip, TooltipProps } from "@material-ui/core";
import Tag, { TagProps } from "./components/Tag";
import TagType from "./types";
import getColors from "./utils";

interface DSTagProps extends Omit<TagProps, "colors"> {
  type: TagType;
  tooltip?: boolean;
  tooltipProps?: Omit<TooltipProps, "title" | "children">;
}

function DSTag({ type, tooltip, tooltipProps, ...props }: DSTagProps) {
  const colors = getColors(type);

  if (!tooltip) return <Tag colors={colors} {...props} />;
  return (
    <Tooltip title={props.text} {...tooltipProps}>
      <Tag colors={colors} {...props} />
    </Tooltip>
  );
}

export default DSTag;
