import zod from "zod";

export const HoumerSchema = zod.object({
  name: zod.string().nullable(),
  email: zod.string().nullable(),
  phone: zod.string().nullable(),
  photo: zod.string().nullable(),
});

export type Houmer = zod.infer<typeof HoumerSchema>;
