import { BACKEND_URL } from "env";
import { createHttp, createNoAuthHttp } from "./helper";

export default {
  getTags(propId: string, token: string) {
    return createNoAuthHttp().get(`/real_estate_developments/tags/${propId}`);
  },

  getTagTypes() {
    return createNoAuthHttp(BACKEND_URL).get(`/marketplace_tags/`);
  },

  createTag(data, token: string) {
    return createHttp(token).post(`/real_estate_developments/v2/development_tags/`, data);
  },

  editTag(id: string, data, token: string) {
    return createHttp(token).put(`/real_estate_developments/v2/development_tags/${id}/`, data);
  },

  deleteTag(id: string, token: string) {
    return createHttp(token).delete(`/real_estate_developments/v2/development_tags/${id}/`);
  },
};
