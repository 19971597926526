export const OPTION_LIST = [
  {
    value: "DELETED",
    label: "Cobro eliminado anteriormente",
  },
  {
    value: "PENALTY",
    label: "Cobro multa",
  },
  {
    value: "PENAL_CLAUSE",
    label: "Cláusula penal",
  },
  { value: "PENDING_DEBT", label: "Deuda pendiente" },
  { value: "RENOVATION_ERROR", label: "Error en renovación" },
  { value: "LEASE", label: "Mes de arriendo adicional" },
  {
    value: "DAMAGED_PROPERTY_NEGOTIATION",
    label: "Negociación propiedad siniestrada",
  },
  {
    value: "PUBLIC_SERVICES",
    label: "Servicios públicos",
  },
  {
    value: "DEFERRED_INSURANCE",
    label: "Póliza Jurídica Diferida (México)",
  },
];
