import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(5, 0),
    marginLeft: theme.spacing(-2),
  },
  downloadContainer: {
    display: "flex",
    alignItems: "center",
  },
  downloadLabel: {
    fontWeight: 700,
    marginRight: theme.spacing(5),
  },
  downloadButton: {
    fontSize: "0.875rem",
    border: "1px dashed",
    textTransform: "none",
    borderRadius: 8,
    padding: theme.spacing(0.5, 1.5),
  },
  imageContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

export default useStyles;
