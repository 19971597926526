import { Dialog, LinearProgress } from "@material-ui/core";
import { useProperty } from "context/propertyContext/propertyContext";

import useStyles from "./LoadingDialogStyles";

function LoadingDialog() {
  const classes = useStyles();
  const { state } = useProperty();
  return (
    <Dialog open={state.loading}>
      <div className={classes.container}>
        <LinearProgress color="primary" />
        <div className={classes.titleContainer}>
          <h1>Creando propiedad ...</h1>
        </div>
      </div>
    </Dialog>
  );
}

export default LoadingDialog;
