import { Card } from "@material-ui/core";
import useNoDataTableCardStyles from "./NoDataTableCardStyles";

function NoDataTableCard({ textTitle, textSubtitle }) {
  const classes = useNoDataTableCardStyles();
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.container}>
        <div className={classes.title}>{textTitle}</div>
        <div className={classes.subtitle}>{textSubtitle} </div>
      </div>
    </Card>
  );
}

export default NoDataTableCard;
