import { useCallback, useEffect, useState } from "react";

import { ICity } from "Components/Admin/administrator/promotions/utils/types";

const useHandleCity = (dataCities: ICity[]) => {
  const [cities, setCities] = useState(dataCities);
  const [selectedCities, setSelectedCities] = useState<ICity[]>([]);

  const handleAddCity = useCallback((id: number) => {
    const filteredSelectedData = cities.find((city) => city.id === id);
    setSelectedCities((prevData) => [...prevData, filteredSelectedData]);
  }, []);

  const handleDeleteCity = useCallback(
    (id: number) => {
      const filteredSelectedData = selectedCities.filter((city) => city.id !== id);
      setSelectedCities(filteredSelectedData);
    },
    [selectedCities]
  );

  useEffect(() => {
    const combinedArray = dataCities.concat(selectedCities);
    const nonRepeatedObjects = combinedArray.filter(
      (obj, index, arr) => !arr.some((t, i) => i !== index && t.id === obj?.id)
    );
    setCities(nonRepeatedObjects);
  }, [selectedCities]);

  return {
    cities,
    selectedCities,
    handleAddCity,
    handleDeleteCity,
  };
};

export default useHandleCity;
