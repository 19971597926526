import { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";

import { EvaluationDetailsModel, EvaluationModel } from "models/Evaluation";
import newEvaluationService from "services/newEvaluationService";
import Spinner from "Components/Shared/partials/Spinner";
import { ContractModel } from "models/Contract";
import { useGetToken } from "hooks";
import { countryCode } from "utils";

import SelectContractProperty from "./components/SelectContractProperty";
import { parseCodebtorData, parseProfileData } from "./utils";
import usePropertyInfo from "./hooks/usePropertyInfo";
import useGetContract from "./hooks/useGetContract";
import ContractDetailV2 from "./ContractDetailV2";

function Contract() {
  const params = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const {
    data: contract,
    isLoading: isLoadingContract,
    refetch,
  } = useGetContract(parseInt(params.id));
  const { data: property, isLoading: isLoadingPropertyData } = usePropertyInfo(
    contract?.property?.id
  );
  const [profiles, setProfiles] = useState([]);
  const [evaluation, setEvaluation] = useState<EvaluationModel[] | null>(null);

  const setContractInfo = useMemo(
    () => async (contract: ContractModel) => {
      if (contract === null) return;
      const authToken = await getToken();
      if (
        contract.property.country === "Colombia" ||
        (contract.property.country === "Mexico" && !contract.evaluation_ids)
      ) {
        setProfiles(parseCodebtorData(contract));
        setEvaluation([]);
      }
      if (contract.evaluation_ids) {
        const evaluations = await newEvaluationService.getByIds(
          contract.evaluation_ids,
          authToken,
          countryCode(contract.property.country)
        );
        if (evaluations) {
          const evs: EvaluationModel[] = evaluations.data.evaluations;
          setEvaluation(evs);
        }
        if (contract.status === "Aprobacion propietario" || contract.status === "Perfil enviado") {
          const res = await newEvaluationService.getMultipleEvaluationDetails(
            contract.evaluation_ids,
            authToken,
            countryCode(contract.property.country)
          );
          if (res) {
            let mainApplicantEvaluationId: number | null;
            let evaluationData: EvaluationModel[];
            if (evaluations) {
              evaluationData = evaluations["data"].evaluations;
              const { id } = evaluationData.filter(
                (evl) => evl.main_applicant.id === evl.user.id
              )[0];
              mainApplicantEvaluationId = id;
            }
            const evaluationDetails: EvaluationDetailsModel[] = res.data.evaluation_details;
            const uniqueValuesSet = new Set();
            const uniqueEvaluation = evaluationDetails.filter((obj) => {
              const isPresentInSet = uniqueValuesSet.has(obj.evaluation_id);
              uniqueValuesSet.add(obj.evaluation_id);
              return !isPresentInSet;
            });

            setProfiles(
              uniqueEvaluation.map((item: EvaluationDetailsModel) => {
                const isCodebtor = mainApplicantEvaluationId
                  ? mainApplicantEvaluationId !== item.evaluation_id
                  : false;
                return parseProfileData(item, isCodebtor, countryCode(contract.property.country));
              })
            );
          }
        } else {
          setProfiles(parseCodebtorData(contract));
        }
      } else {
        setEvaluation([]);
        setProfiles(parseCodebtorData(contract));
      }
    },
    [contract]
  );

  useEffect(() => {
    if (contract) setContractInfo(contract);
  }, [contract]);

  const updateContract = useCallback((newContract: ContractModel) => refetch(), [contract]);

  if (isLoadingContract || isLoadingPropertyData) return <Spinner />;

  if (!contract?.property) {
    return <SelectContractProperty contractId={contract?.id} />;
  }

  if (contract) {
    return (
      <ContractDetailV2
        contract={contract}
        profiles={profiles}
        evaluation={evaluation}
        property={property}
        updateContract={updateContract}
      />
    );
  }
  return null;
}

export default Contract;
