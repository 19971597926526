import { Fragment, useCallback, useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import Swal from "sweetalert2";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import ModalDialog from "@houm-com/ui/ModalDialog";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import { RejectDesistModal } from "Components/Admin/UIComponents/Modals";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import newEvaluationService from "services/newEvaluationService";
import { contractDetailDiscardInfo } from "utils/discardInfo";
import Spinner from "Components/Shared/partials/Spinner";
import { ContractModel, IPerson } from "models/Contract";
import propertyService from "services/propertyService";
import contractService from "services/contractService";
import { EvaluationModel } from "models/Evaluation";
import { CountryUpperCase } from "models/Countries";
import countryCode from "utils/countryCode";
import { useGetToken } from "hooks";

import { checkStatus, getEvaluation, renderDataTenant } from "../utils";
import useTenantsCardStyles from "./TenantsCardStyles";
import CodebtorFormEdit from "./CodebtorFormEdit";
import SendProfileModal from "./SendProfileModal";

interface FormData {
  status: string;
  message?: string;
}

interface Props {
  contract: ContractModel;
  country: CountryUpperCase;
  evaluation: EvaluationModel[];
  profiles: IPerson[];
}

function TenantsCard({ contract, country, evaluation, profiles }: Props) {
  const { getToken } = useGetToken();
  const classes = useTenantsCardStyles();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openRejectDesist, setOpenRejectDesist] = useState(false);
  const [openEditTenantModal, setEditTenantOpenModal] = useState(false);
  const [profileToEdit, setProfileToEdit] = useState<IPerson | null>(null);
  const [contractSubmited, setContractSubmited] = useState(false);
  const [mainApplicantId, setMainApplicantId] = useState<number | null>(null);
  const [loadingButtons, setLoadingButtons] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = (): void => {
    setProfileToEdit(null);
    setOpenModal(false);
  };

  const editTenantModal = (person: IPerson): void => {
    setProfileToEdit(person);
    setEditTenantOpenModal(!openEditTenantModal);
  };

  const getFullContractStatus = async () => {
    const authToken = await getToken();
    try {
      const fetchedContractInfo = await propertyService.getContractPropertyInfo(
        contract.property.id,
        authToken
      );
      setContractSubmited(fetchedContractInfo.data.submitted);
    } catch (e) {
      Swal.fire(
        "Error",
        `No se pudo obtener datos del contrato para enviar recordatorio, ${e}`,
        "error"
      );
    }
  };

  const getEvaluations = async () => {
    setLoadingButtons(true);
    const authToken = await getToken();
    const evaluations = await newEvaluationService.getByIds(
      contract.evaluation_ids,
      authToken,
      countryCode(country)
    );
    if (evaluations) {
      const evaluationList: EvaluationModel[] = evaluations.data.evaluations;
      const filterMainTenant = evaluationList.find(
        (ev) =>
          ev.main_applicant.email === contract.main_tenant.email ||
          ev.main_applicant.document === contract.main_tenant.document
      );
      if (filterMainTenant) setMainApplicantId(filterMainTenant.main_applicant.id);
    }
    setLoadingButtons(false);
  };

  useEffect(() => {
    getFullContractStatus();
    if (contract) {
      if (
        checkStatus(
          ["Aprobacion propietario", "Perfil enviado", "Perfil aprobado", "Re-evaluacion"],
          contract.status
        ) &&
        contract?.evaluation_ids &&
        contract.evaluation_ids.length &&
        !(contract.property.country === "Colombia")
      ) {
        getEvaluations();
      }
    }
  }, [contract]);

  const sendReminder = async () => {
    const authToken = await getToken();
    Swal.fire({
      title: "Atención",
      text: "Se va a enviar un recordatorio al propietario para completar los datos del contrato, ¿Está seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Enviar",
    }).then(async (result) => {
      if (result.value) {
        try {
          await contractService.sendReminder(contract.id, authToken);
          Swal.fire("Success", "Recordatorio enviado correctamente", "success");
        } catch (e) {
          Swal.fire("Error", `No se pudo enviar email de recordatorio, ${e}`, "error");
        }
      }
    });
  };

  const handleDeleteCodebtor = async (person: IPerson) => {
    const authToken = await getToken();
    Swal.fire({
      title: "Atención",
      text: `Se va a eliminar el codeudor ${person.name}, ¿Está seguro?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.value) {
        try {
          await contractService.deleteCodebtor(contract.id, person.id, authToken);
          Swal.fire("Success", "Codeudor eliminado correctamente", "success").then(() => {
            window.location.reload();
          });
        } catch (e) {
          Swal.fire("Error", `No pudimos eliminar el codeudor, ${e}`, "error");
        }
      }
    });
  };

  const onClickLink = (profile: IPerson) => {
    const ev = getEvaluation(evaluation, profile);
    const code = countryCode(contract.property.country);
    window.open(`/admin/evaluations/${code}/${ev.main_applicant.id}/${ev.user.id}`, "_blank");
  };

  const flagSatusAprobation = (status: string): boolean =>
    [
      "Aprobacion propietario",
      "Perfil no enviado",
      "Perfil enviado",
      "Perfil aprobado",
      "Re-evaluacion",
      "Perfil rechazado",
    ].includes(status);

  const statusLabel = (status: string): string | null => {
    if (flagSatusAprobation(status)) {
      return status === "Aprobacion propietario" ? "Perfil no enviado" : status;
    } else {
      return null;
    }
  };

  const renderTitle = (status: string): string => {
    if (flagSatusAprobation(status)) {
      return "Datos postulantes (s)";
    } else {
      return "Datos arrendatario (s)";
    }
  };

  const renderStylesChip = () => {
    if (contract.status === "Perfil aprobado") {
      return classes.chipApproved;
    } else if (contract.status === "Perfil rechazado") {
      return classes.chipRejected;
    } else if (contract.status === "Re-evaluacion") {
      return classes.chipReevaluation;
    } else if (contract.status === "Perfil enviado") {
      return classes.chipSend;
    }
    return null;
  };

  const renderSendProfileButton =
    contract.status === "Aprobacion propietario" &&
    evaluation &&
    evaluation.length &&
    evaluation[0].brokerage;

  const renderSeeEvaluation = (profile: IPerson) =>
    country !== "Colombia" &&
    evaluation !== null &&
    evaluation.length !== 0 &&
    getEvaluation(evaluation, profile);

  const onSaveWithWarning = useCallback(
    (reason: string, author: string, type: string) => (comment: string) => {
      Swal.fire({
        title: "Al rechazar este perfil, estarás rechazando también el contrato",
        text: "¿Estás seguro/a de que quieres rechazar este perfil y contrato?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: theme.palette.grey[300],
        cancelButtonColor: theme.palette.primary.main,
        confirmButtonText: "Sí, rechazar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          rejectProfile(mainApplicantId, { status: "rejected", message: comment }, reason, author);
        }
      });
    },
    [mainApplicantId]
  );

  const rejectProfile = async (
    mainApplicantId: number,
    data: FormData,
    reason: string,
    author: string
  ) => {
    const authToken = await getToken();
    const newStatusContract = {
      ...contract,
      status: "Rechazado",
      discard_reason: reason,
      discard_author: author,
      discard_comment: data.message,
    };
    try {
      await newEvaluationService.updateLandlordEvaluation(
        mainApplicantId,
        countryCode(contract.property.country),
        data,
        authToken
      );
      await contractService.update(newStatusContract.id, newStatusContract, authToken);
    } catch (e) {
      Swal.fire({
        type: "error",
        title: "No se pudo rechazar el perfil",
        text: `Por favor intente nuevamente más tarde o contáctese con soporte. Error: ${e}`,
      });
    }
  };

  return (
    <>
      <CollapseCard
        title={renderTitle(contract.status)}
        status={statusLabel(contract.status)}
        chipClassName={renderStylesChip()}>
        {!evaluation ? (
          <Spinner />
        ) : (
          <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Grid container spacing={3} alignItems="flex-end">
                  {profiles.map((profile) => (
                    <Fragment key={`${profile.identification}-${profile.evaluationId}`}>
                      {renderDataTenant(evaluation, profile, country).map(
                        (item) =>
                          item.notShow !== country && (
                            <Grid item xs={4} lg={item.size} key={item.label}>
                              <Typography className={classes.textLabel}>{item.label}</Typography>
                              <Typography className={classes.text}>{item.text}</Typography>
                              <Divider className={classes.divider} />
                            </Grid>
                          )
                      )}
                      {renderSeeEvaluation(profile) && (
                        <Grid item xs={12}>
                          <Typography
                            component="span"
                            className={classes.textLink}
                            onClick={() => onClickLink(profile)}>
                            Ver evaluación
                          </Typography>
                        </Grid>
                      )}
                      {!flagSatusAprobation(contract.status) && (
                        <Grid item container xs={12}>
                          {!checkStatus(
                            [
                              "Generar boton de pago",
                              "Desistido",
                              "Rechazado",
                              "Boton de pago enviado",
                              "Pagado",
                              "Finalizado",
                            ],
                            contract.status
                          ) && (
                            <Grid item xs={2}>
                              <CustomLoadingButton
                                text="Editar arrendatario"
                                onClick={() => editTenantModal(profile)}
                              />
                            </Grid>
                          )}
                          {profile.isCodebtor && contract.status === "Listo para redaccion" && (
                            <Grid item xs={2}>
                              <CustomLoadingButton
                                text="Eliminar"
                                onClick={() => handleDeleteCodebtor(profile)}
                                type="outlined"
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {evaluation.length > 1 ||
                        (profiles.length > 1 && (
                          <Divider className={classes.divider} variant="middle" />
                        ))}
                    </Fragment>
                  ))}
                </Grid>
                <Loading loading={loadingButtons} className={classes.loading}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    {renderSendProfileButton && (
                      <Grid item>
                        <DesignSystemButton
                          label="Mandar perfil"
                          onClick={() => handleOpenModal()}
                          variant="primary"
                          size="small"
                        />
                      </Grid>
                    )}
                    {!contractSubmited && (
                      <>
                        {checkStatus(["Perfil enviado", "Perfil aprobado"], contract.status) && (
                          <Grid item>
                            <DesignSystemButton
                              label="Enviar recordatorio"
                              onClick={() => sendReminder()}
                              variant="primary"
                              size="small"
                            />
                          </Grid>
                        )}
                        {checkStatus(["Re-evaluacion"], contract.status) && !contractSubmited && (
                          <>
                            <Grid item>
                              <DesignSystemButton
                                label="Rechazar"
                                onClick={() => setOpenRejectDesist(true)}
                                variant="tertiaryB"
                                size="small"
                              />
                            </Grid>
                            <Grid item>
                              <DesignSystemButton
                                label="Reenviar perfil"
                                onClick={() => handleOpenModal()}
                                variant="primary"
                                size="small"
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </Loading>
              </CardContent>
            </div>
          </Card>
        )}
      </CollapseCard>
      {contract.property.country !== "Colombia" && (
        <SendProfileModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          contract={contract}
          reevaluationFlag={contract.status === "Re-evaluacion"}
          mainApplicantId={mainApplicantId}
        />
      )}
      <ModalDialog
        isOpen={openEditTenantModal}
        onClose={() => setEditTenantOpenModal(false)}
        title="Datos arrendatario (s)"
        subtitle="Editar información">
        <SnackbarProvider>
          <CodebtorFormEdit
            contract={contract}
            person={profileToEdit}
            country={countryCode(country)}
            type={profileToEdit?.isCodebtor ? "codebtor" : "tenant"}
          />
        </SnackbarProvider>
      </ModalDialog>
      <RejectDesistModal
        open={openRejectDesist}
        onClose={() => setOpenRejectDesist((open) => !open)}
        onSave={onSaveWithWarning}
        possibleAuthors={["Propietario"]}
        possibleReasons={{
          reject: contractDetailDiscardInfo.rejectReasons,
        }}
        defaultComment={contract.discard_comment}
      />
    </>
  );
}

export default TenantsCard;
