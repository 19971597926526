import { useState } from "react";

import { IoSearchOutline } from "@houm-com/ui-icons";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { localeIntoCountryUpperCase, localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";
import usePlans from "hooks/usePlans";
import { countryCode } from "utils";

import CreatePlanDialog from "./components/CreatePlanDialog";
import classes from "./AvailablePlansStyles";
import PlanCards from "./components/PlanCards";
import { steps } from "./utils/constants";

export default function AvailablePlans() {
  const [searchBar, setSearchBar] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Locale>("cl");
  const { data: plans, isLoading: plansLoading } = usePlans(localeToCountry[selectedCountry], true);

  const handleCountryChange = (country) => setSelectedCountry(countryCode(country));
  const handleSearchBarChange = ({ target: { value } }) => setSearchBar(value);
  const handleOpenDialog = () => setCreateDialogOpen(true);
  return (
    <div className={classes.root}>
      <Loading loading={plansLoading}>
        <>
          <div className={classes.header}>
            <Breadcrumbs steps={steps} />
            <CountrySelector
              stateCountry={localeIntoCountryUpperCase[selectedCountry]}
              onChange={handleCountryChange}
            />
          </div>
          <div className={classes.container}>
            <h1 className={classes.title}>Planes</h1>
            <div className={classes.subHeader}>
              <FieldInput
                type="search"
                placeholder="Buscar plan"
                className={classes.searchBar}
                startAdornment={IoSearchOutline}
                onChange={handleSearchBarChange}
              />
              <Button onClick={handleOpenDialog} variant="primary" size="lg">
                Crear plan
              </Button>
            </div>
            <PlanCards searchBar={searchBar} plans={plans} />
          </div>
          <CreatePlanDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
        </>
      </Loading>
    </div>
  );
}
