import { State, Action, ActionType } from "./lettersOfIntentListTypes";

export const initialState: State = {
  loading: false,
  lettersOfIntent: [],
  numberOfLettersOfIntent: null,
  country: "Chile",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case ActionType.SET_LETTERS_OF_INTENT: {
      return {
        ...state,
        lettersOfIntent: action.payload.lettersOfIntent,
        numberOfLettersOfIntent: action.payload.count,
      };
    }
    case ActionType.CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
