import { Box, Typography } from "@material-ui/core";

import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import { LocaleCountries } from "models/Countries";
import useBankDataCardStyles from "./BankDataCardStyles";

function BankDataCard() {
  const classes = useBankDataCardStyles();
  const { state } = useLetterOfIntent();
  const { bankData, country, person } = state;

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Datos bancarios</Typography>
      <Box hidden={country != LocaleCountries.MX}>
        <Typography className={classes.label}>Cuenta CLABE:</Typography>
        <Typography className={classes.value}>{bankData.clabe}</Typography>
      </Box>
      <Box>
        <Typography className={classes.label}>Banco:</Typography>
        <Typography className={classes.value}>Santander</Typography>
      </Box>
      <Box>
        <Typography className={classes.label}>Titular de la cuenta:</Typography>
        <Typography className={classes.value}>{person.fullname}</Typography>
      </Box>
    </Box>
  );
}

export default BankDataCard;
