import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    margin: theme.spacing(4, 0, 2),
  },
  closingDetails: {
    marginTop: theme.spacing(2),
  },
  tabsContent: {
    marginTop: theme.spacing(4),
  },
}));

export default useStyles;
