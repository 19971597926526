import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  title: {
    fontWeight: "bold",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.25),
  },
  card: {
    width: "100%",
    marginTop: theme.spacing(2),
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  published: {
    color: "#2db7f5",
    borderColor: "#2db7f5",
  },
  noPublished: {
    color: "orange",
    borderColor: "orange",
  },
}));
