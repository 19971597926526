import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import subscriptionService from "services/subscriptionService";
import { alert } from "libs/alerts";
import { useGetToken } from "hooks";

interface IFormData {
  contractId: number;
}

const useReactivateSubscription = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const mutation = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      const data = {
        contract_id: formData.contractId,
        send_emails: true,
      };
      const response = await subscriptionService.activateSubscription(data, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("subscription");
        alert.success({
          message: "La subscripción se activó correctamente",
        });
        onClose?.();
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo activar subscripción",
          disclaimer: `Error: ${reason ? reason?.data?.detail : e}`,
        });
      },
    }
  );

  return {
    ...mutation,
  };
};

export default useReactivateSubscription;
