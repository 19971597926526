import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    margin: theme.spacing(3, 0),
    fontWeight: theme.typography.fontWeightBold as number,
  },
  imagesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  image: {
    width: 150,
    height: 150,
    objectFit: "contain",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export default useStyles;
