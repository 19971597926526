import { UseQueryResult, useQuery } from "react-query";

import { CountryUpperCase } from "models/Countries";
import { CityProps } from "models/Plan";
import propertyService from "services/propertyService";
import { countryCode } from "utils";

const useGetCities = (country: CountryUpperCase) => {
  const {
    data: availableCities,
    isLoading,
    isError,
  }: UseQueryResult<CityProps[], Error> = useQuery<CityProps[], Error>(
    ["getCities", country],
    async () => {
      const response = await propertyService.getCitiesByCountry(countryCode(country));
      return response.data.results;
    }
  );

  return {
    availableCities,
    isLoading,
    isError,
  };
};

export default useGetCities;
