// import { format } from "date-fns";
import { CountryUpperCase } from "models/Countries";
import { linkagesMap } from "../constants";

import { ItemCardProps } from "../VisitCard/ItemCard";

interface Props {
  country: CountryUpperCase;
  keyManager: string;
  currentStep: string;
  updatedAt: string;
}

const keysFields = ({ country, keyManager, currentStep, updatedAt }: Props): ItemCardProps[] => [
  {
    title: "",
    alias: linkagesMap[country].find((item) => item.value === keyManager)?.label,
    position: "vertical",
    content: keyManager,
    formKey: "key_manager",
    editableMode: "select",
    editable: currentStep === "editable",
    options: linkagesMap[country],
    bold: true,
  },
  {
    title: "Última actualización",
    position: "vertical",
    content: updatedAt || "Sin información",
    editable: false,
    formKey: "updated_at",
  },
];

export default keysFields;
