export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const BACKEND_WORKER_URL = process.env.REACT_APP_BACKEND_WORKER_URL;
export const PROPERTY_DUES_URL = process.env.REACT_APP_PROPERTY_DUES_URL;
export const PAYMENTS_URL = process.env.REACT_APP_PAYMENTS_URL;
export const REV_URL = process.env.REACT_APP_REV_URL;
export const RISK_URL = process.env.REACT_APP_RISK_URL;
export const EVALUATIONS_URL = process.env.REACT_APP_EVALUATIONS_URL;
export const EVALUATION_FORM_URL = process.env.REACT_APP_EVALUATION_FORM_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const AUTH0_ROLES = process.env.REACT_APP_AUTH0_ROLES;
export const AUTH0_CLIENTID = process.env.REACT_APP_AUTH0_CLIENTID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const SALES_URL = process.env.REACT_APP_SALES_URL;
export const MAPBOX_API_TOKEN = process.env.REACT_APP_MAPBOX_API_TOKEN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const TOKY_API_URL = process.env.REACT_APP_TOKY_API_URL;
export const TOKY_APP_KEY = process.env.REACT_APP_TOKY_APP_KEY;
export const TOKY_APP_ID = process.env.REACT_APP_TOKY_APP_ID;
export const TOKY_AGENT_ID = process.env.REACT_APP_TOKY_AGENT_ID;
export const KUSTOMER_API_KEY = process.env.REACT_APP_KUSTOMER_API_KEY;
export const KUSTOMER_JWT_SECRET = process.env.REACT_APP_KUSTOMER_JWT_SECRET;
export const XGRID_LICENSE = process.env.REACT_APP_XGRID_LICENSE;
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOUM_COM_URL = process.env.REACT_APP_HOUM_COM_URL;
export const REACT_QUERY_DEV_TOOLS = process.env.REACT_APP_REACT_QUERY_DEV_TOOLS;
export const ANALITYCS_ID = process.env.REACT_APP_ANALITYCS_ID;
export const OPTIMIZE_ID = process.env.REACT_APP_OPTIMIZE_ID;
export const ME_HOUM_COM_URL = process.env.REACT_APP_ME_HOUM_COM_URL;
export const HOUM_PUBLIC_IMG_PROXY = process.env.REACT_APP_HOUM_PUBLIC_IMG_PROXY;
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
export const RESIZE_URL = process.env.REACT_APP_IMAGE_RESIZER_URL;
export const REFER_FROM_PROP_ADMIN = true;
export const NEW_LANDLORD_LOI_FORM = true;
export const EDIT_INSPECION_REPORTS_ENABLED = false;
export const FREATURE_MANAGER_BASE_URL = process.env.REACT_APP_FREATURE_MANAGER_BASE_URL;
export const FEATURE_MANAGER_APP_ID = process.env.REACT_APP_FEATURE_MANAGER_APP_ID;
export const API_VALUES_URL = process.env.REACT_APP_API_VALUES_URL;
export const MS_AUTH_URL = process.env.REACT_APP_MS_AUTH_URL;
export const DATADOG_APPLICATIONID = process.env.REACT_APP_DATADOG_APPLICATIONID;
export const DATADOG_CLIENTTOKEN = process.env.REACT_APP_DATADOG_CLIENTTOKEN;
export const DATADOG_SERVICE = process.env.REACT_APP_DATADOG_SERVICE;
