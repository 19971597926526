import { makeStyles, Theme } from "@material-ui/core";

const useEmptyDataTagStyles = makeStyles<Theme>((theme) => ({
  tag: {
    padding: theme.spacing(0.25, 1),
    backgroundColor: "#F9E8E5",
    border: "2px solid #900000",
    borderRadius: 8,
    maxWidth: 81,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  tagText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: "#900000",
  },
}));

export default useEmptyDataTagStyles;
