import { useCallback } from "react";
import { Document, Font, Page, Image } from "@react-pdf/renderer";

import NunitoRegular from "assets/font/Nunito/Nunito-Regular.ttf";
import NunitoBold from "assets/font/Nunito/Nunito-Bold.ttf";
import PageBg from "./assets/images/page-bg.png";

import InspectionReportHeader from "./components/InspectionReportHeader";
import IntroductionPage from "./pages/Introduction";
import SpacesQualificationPage from "./pages/SpacesQualification";
import ReportActionPage from "./pages/ReportActions";
import TenantProofPage from "./pages/TenantProof";

import { Inspection } from "hooks/useInspectionReports/types";
import { getSpaceQualificationPages, getReportActionsPages } from "./utils";
import useInspectionReportDocumentStyles from "./InspectionReportDocumentStyles";

Font.register({
  family: "Nunito",
  src: NunitoRegular,
});

Font.register({
  family: "Nunito",
  src: NunitoBold,
});
interface PropertyData {
  address: string;
  region: string;
}

export interface InspectionReportDocumentProps {
  inspectionReport: Inspection;
  propertyData: PropertyData;
}

function InspectionReportDocument({
  inspectionReport,
  propertyData,
}: InspectionReportDocumentProps) {
  const styles = useInspectionReportDocumentStyles;

  const { spaceQualifications = [] } = inspectionReport || {};

  const spaceQualificationPages = getSpaceQualificationPages(spaceQualifications);

  const reportActionsPages = getReportActionsPages(spaceQualifications);

  const hasTenantProofSection = !!inspectionReport?.tenantProof?.signature_url;
  const hasSpaceQualificationSection = !!spaceQualificationPages.length;
  const hasReportActionsSection = !!reportActionsPages.length;

  const PageWrapper = useCallback(
    ({ children }) => {
      const completeAddress = `${propertyData?.address}, ${propertyData?.region}`;
      return (
        <Page wrap size="LETTER" style={styles.pageRoot}>
          <Image fixed src={PageBg} style={styles.pageBackground} />
          <InspectionReportHeader
            address={completeAddress}
            propertyId={inspectionReport?.propertyId}
          />
          {children}
        </Page>
      );
    },
    [styles.pageRoot, styles.pageBackground, inspectionReport, propertyData]
  );

  return (
    <Document
      title={`Acta de inspección ${inspectionReport?.id}`}
      author="Houm.com"
      subject="Acta de inspección">
      <PageWrapper>
        <IntroductionPage inspectionReport={inspectionReport} />
      </PageWrapper>
      {hasSpaceQualificationSection &&
        spaceQualificationPages.map((spaceQualificationPage, idx) => (
          <PageWrapper key={`${spaceQualificationPage.spaceName}_${idx}`}>
            <SpacesQualificationPage firstSpace={idx === 0} {...spaceQualificationPage} />
          </PageWrapper>
        ))}

      {hasReportActionsSection &&
        reportActionsPages.map((reportAction, idx) => (
          <PageWrapper key={`${reportAction.createdAt}_${idx}`}>
            <ReportActionPage {...reportAction} />
          </PageWrapper>
        ))}

      {hasTenantProofSection && (
        <PageWrapper>
          <TenantProofPage inspectionReport={inspectionReport} />
        </PageWrapper>
      )}
    </Document>
  );
}

export default InspectionReportDocument;
