import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import planService from "services/planService";
import useGetToken from "hooks/useGetToken";
import { IParsePlan } from "./utils/types";

interface Props {
  plan: IParsePlan;
}

const useToggleActivePlan = () => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading, isSuccess, error } = useMutation(
    async ({ plan }: Props) => {
      const authToken = await getToken();
      await planService.enableDisablePlan(String(plan.id), !plan.active, authToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getPlans"]);
        alert.success({
          message: "Plan modificado exitosamente",
        });
      },
      onError: () => {
        alert.error({
          message: "El plan no pudo ser modficado",
        });
      },
    }
  );

  return {
    toggleActivePlan: mutate,
    toggleActivePlanLoading: isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useToggleActivePlan;
