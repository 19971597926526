import { State, Action, SET_PLAN_DATA, SET_STEPS, SET_CURRENT_TAB } from "./plansTypes";

export const initialState: State = {
  planData: {
    id: "",
    name: "",
    description: "",
    isActive: true,
    country: "Chile",
    cities: [],
    landlordBrokerage: "",
    tenantBrokerage: "",
    landlordBrokerageClosing: "",
    tenantBrokerageClosing: "",
    adminCommission: "",
    contractTemplate: null,
    paymentOption: null,
    duration: null,
    monthlyData: [],
    minimumAdminFee: 0,
    guaranteedMonths: 0,
    category: null,
  },
  readyToSubmit: false,
  selectedCountry: "Chile",
  steps: [
    {
      link: "#",
      label: "Cierres",
    },
    {
      link: "/admin/plans",
      label: "Planes",
    },
  ],
  currentTab: {
    id: 0,
    isActive: false,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_PLAN_DATA:
      return {
        ...state,
        planData: { ...state.planData, ...action.planData },
      };
    case SET_STEPS:
      return {
        ...state,
        steps: [...state.steps, ...action.steps],
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.currentTab,
      };
    default:
      return state;
  }
};

export default apiReducer;
