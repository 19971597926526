import { useGetToken } from "hooks";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";

import { updateCurrentProperty } from "context/demandLeadContext/schedule/demandScheduleActions";

import PropertiesCarrousel from "./PropertyCarousel";
import ScheduleButton from "./ScheduleButton";

function SimilarProperties() {
  const { getToken } = useGetToken();

  const {
    state: { similarProperties },
    dispatch,
  } = useDemandSchedule();

  const {
    state: { lead },
  } = useDemandLead();

  const handleSelectProperty = (similarProperty) => {
    getToken().then(async (authToken) => {
      updateCurrentProperty(similarProperty.id, lead.status.business_type, dispatch, authToken);
    });
  };

  return (
    <PropertiesCarrousel
      items={similarProperties}
      title="Propiedades similares"
      deal="sale"
      onClickChildren={handleSelectProperty}>
      <ScheduleButton>Agendar</ScheduleButton>
    </PropertiesCarrousel>
  );
}

export default SimilarProperties;
