import { makeStyles, Theme } from "@material-ui/core";

const useUnitHeaderStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 700,
    fontSize: "1.25rem",
    marginBottom: theme.spacing(3),
  },
  executiveContainer: {
    display: "flex",
    alignItems: "center",
    "& button": {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  userContainer: {
    padding: theme.spacing(2),
    width: theme.breakpoints.values.sm,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

export default useUnitHeaderStyles;
