import currency from "@houm-com/formats/currency";
import Button from "@houm-com/ui/Button";
import Spinner from "@houm-com/ui/Spinner";
import Tag from "@houm-com/ui/Tag";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { EvaluationStatus } from "domains/evaluations/models/Evaluations";
import { Permissions as P } from "domains/evaluations/utils/constants";
import useProperty from "domains/evaluations/hooks/useProperty";
import { localeToCountry } from "utils/countryCode";
import useModal from "hooks/useModal";

import ChangePropertyModal from "./components/ChangePropertyModal";
import ProtectComponent from "../ProtectComponent";
import { classes } from "./PropertyCardStyles";

interface Props {
  propertyId: number;
  evaluationId: string;
  evaluationStatus: EvaluationStatus;
}

const PropertyCard = ({ propertyId, evaluationId, evaluationStatus }: Props) => {
  const { handleOpenModal, open } = useModal();
  const { data, isLoading, isError, isSuccess } = useProperty({ propertyId });

  const openProperty = () => window.open(`/admin/properties/${data.uid}`, "_blank");
  const isPublished = ["published", "republished"].includes(data?.propertyDetails?.status);

  return (
    <>
      <AdminCard
        className={classes.propertyCardContainer}
        title="Propiedad a la que postula"
        showTitleBackground={true}
        withSpace={false}>
        {isLoading && <Spinner variant="primary" size="xl" />}
        {isError && <p>Error al cargar propiedad</p>}
        {isSuccess && (
          <div className={classes.propertyCardInfoContainer}>
            <div>
              <div className={classes.headerContainer}>
                <p className={classes.textNormal}>
                  ID [{data.id}] {data.address}
                </p>
                <Tag
                  label={isPublished ? "Publicada" : "No publicada"}
                  variant={isPublished ? "info" : "warning"}
                />
              </div>
              <p className={classes.textNormal}>{`${data.commune}, ${data.region}`}</p>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>País:</p>
                <p className={classes.textNormal}>{localeToCountry[data.country]}</p>
              </span>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>Dormitorios:</p>
                <p className={classes.textNormal}>{data.propertyDetails.bedrooms}</p>
              </span>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>Ejecutivo:</p>
                <p
                  className={
                    classes.textNormal
                  }>{`${data.houmOwner.name} ${data.houmOwner.lastName}`}</p>
              </span>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>Homechecker:</p>
                <p className={classes.textNormal}>
                  {data.homechecker ? data.homechecker.name : "No definido"}
                </p>
              </span>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>Precio de arriendo:</p>
                <p className={classes.textNormal}>
                  {currency(data.propertyDetails.price, data.propertyDetails.currency, {
                    maximumFractionDigits: 0,
                  })}
                </p>
              </span>
              <span className={classes.textContainer}>
                <p className={classes.textBold}>3 veces el precio de arriendo:</p>
                <p className={classes.textNormal}>
                  {currency(3 * data.propertyDetails.price, data.propertyDetails.currency, {
                    maximumFractionDigits: 0,
                  })}
                </p>
              </span>
            </div>
            <div className={classes.ctaContainer}>
              <Button size="sm" variant="tertiary" onClick={openProperty}>
                Ver propiedad
              </Button>
              <ProtectComponent
                evaluationStatus={evaluationStatus}
                requiredPermissions={[P.UPDATE_EVALUATION]}>
                <Button size="sm" variant="tertiary" onClick={handleOpenModal}>
                  Cambiar propiedad
                </Button>
              </ProtectComponent>
            </div>
          </div>
        )}
      </AdminCard>
      <ChangePropertyModal
        handleOpenModal={handleOpenModal}
        open={open}
        evaluationId={evaluationId}
      />
    </>
  );
};

export default PropertyCard;
