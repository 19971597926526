import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

import Spinner from "Components/Shared/partials/Spinner";

import PropertyData from "./components/PropertyData";
import LandlordData from "./components/LandlordData";
import useGetContractInfo from "./hooks/useGetContractInfo";
import useGetDocumentTypes from "./hooks/useGetDocumentTypes";
import useGetPropertyInfo from "./hooks/useGetPropertyInfo";
import { parseContractLandlord, parseContractProperty } from "./utils/parsers";

const ContractInfoCl = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: propertyData,
    isLoading: propertyLoading,
    isError: propertyError,
  } = useGetPropertyInfo(id);
  const {
    data: contractData,
    isLoading: contractLoading,
    isError: contractError,
  } = useGetContractInfo(propertyData?.id);
  const {
    data: documentTypes,
    isLoading: documentTypesLoading,
    isError: documentTypesError,
  } = useGetDocumentTypes("Chile");

  if (propertyLoading || contractLoading || documentTypesLoading) return <Spinner />;

  if (propertyError || contractError || documentTypesError)
    return <div>Ha ocurrido un error cargando los datos</div>;

  return (
    <Grid container>
      <Grid item xs={12}>
        <PropertyData
          contractPropertyData={parseContractProperty(propertyData, contractData)}
          propertyId={id}
        />
      </Grid>
      <Grid item xs={12}>
        <LandlordData
          contractLandlordData={parseContractLandlord(propertyData, contractData)}
          documentTypes={documentTypes}
          propertyId={propertyData.id}
        />
      </Grid>
    </Grid>
  );
};

export default ContractInfoCl;
