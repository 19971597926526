import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    minWidth: 350,
    borderRadius: 5,
    padding: theme.spacing(4, 4),
  },
}));

export default useStyles;
