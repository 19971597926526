import { ITabOption } from "context/tabContext/tabTypes";

export const tabOptions: ITabOption[] = [
  { label: "Lead", value: "lead" },
  { label: "Propiedades", value: "properties" },
  { label: "Fotos", value: "photos", isDisabled: true },
  { label: "Visitas", value: "visits" },
  { label: "Precio", value: "price", isDisabled: true },
  { label: "Propietario", value: "owner", isDisabled: true },
  { label: "Corretajes", value: "brokerage", isDisabled: true },
];

interface ScheduleOptions {
  label: string;
  value: keyof typeof initialState;
}

export const scheduleOptions: ScheduleOptions[] = [
  { label: "Lunes", value: "monday" },
  { label: "Martes", value: "tuesday" },
  { label: "Miércoles", value: "wednesday" },
  { label: "Jueves", value: "thursday" },
  { label: "Viernes", value: "friday" },
  { label: "Sábado", value: "saturday" },
  { label: "Domingo", value: "sunday" },
];

export const initialState = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};
