/* eslint-disable no-await-in-loop */

import { AxiosResponse } from "axios";
import demandService from "services/demandService";
import { Paginate } from "models/ResponseWithPaginate";
import { ResExecutive } from "models/DemandLead";

export const getFullResults = async (country, token, deal): Promise<ResExecutive[]> => {
  let counter: number = 1;
  let res = (await demandService.getExecutives(country, token, counter, deal)) as AxiosResponse<
    Paginate<ResExecutive>
  >;
  let next = res.data?.next;
  const result = res.data?.results;
  if (next) {
    do {
      counter += 1;
      res = (await demandService.getExecutives(country, token, counter, deal)) as AxiosResponse<
        Paginate<ResExecutive>
      >;
      if (res.data?.results) result.push(...res.data.results);
      next = res.data?.next;
    } while (next);
  }
  return result;
};
