import { ButtonBase, Fade, Grid, Theme, Typography, useMediaQuery } from "@material-ui/core";
import useStyles from "./SimilarPropertiesStyles";
import { Locale } from "models/Countries";
import { useMemo, useState, useEffect } from "react";
import { SimilarProperty } from "models";

import SimilarPropertiesGallery from "./SimilarPropertiesGallery";
import { useSimilarProperties } from "context/similarProperties/similarPropertiesContext";

interface Props {
  locale: Locale;
}

function SimilarProperties({ locale }: Props) {
  const { state } = useSimilarProperties();
  const { similarProperties } = state;
  const classes = useStyles();

  const [viewMore, setViewMore] = useState(false);
  const [displayedSimilarProperties, setDisplayedSimilarProperties] = useState<SimilarProperty[]>(
    []
  );

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const displayPropertiesCount = useMemo(() => {
    if (!isMd && !isLg) return 4;
    if (isMd && !isLg) return 6;
    return 8;
  }, [isMd, isLg]);

  useEffect(() => {
    if (similarProperties?.length > 0) {
      if (viewMore) {
        setDisplayedSimilarProperties(similarProperties);
      } else {
        setDisplayedSimilarProperties(similarProperties.slice(0, displayPropertiesCount));
      }
    } else {
      setDisplayedSimilarProperties([]);
    }
  }, [viewMore, displayPropertiesCount, similarProperties]);

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Propiedades similares en Houm
      </Typography>
      <Grid container spacing={3} className={classes.galleryContainer}>
        <SimilarPropertiesGallery properties={displayedSimilarProperties} locale={locale} />
      </Grid>
      <Fade
        in={
          (displayedSimilarProperties.length !== similarProperties.length &&
            displayedSimilarProperties.length > 0) ||
          viewMore
        }>
        <ButtonBase onClick={() => setViewMore((prev) => !prev)} className={classes.viewMoreButton}>
          {!viewMore ? "Ver más" : "Ver menos"}
        </ButtonBase>
      </Fade>
    </div>
  );
}

export default SimilarProperties;
