import * as yup from "yup";

export const requiredText = "Obligatorio";
export const invalid = "El valor ingresado no es válido";
export const long = "El comentario puede tener un máximo de 255 caracteres";

export default yup.object({
  leadQualifier: yup.string(),
  leadQualifierStatus: yup.string(),
  lastContact: yup.string(),
  followUpDate: yup.string(),
  score: yup.string().nullable(),
  lostReason: yup.string().nullable(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  comments: yup.string().max(255, long).typeError(invalid),
});
