import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import schedulesService from "services/schedulesService";

import { Houmer } from "./types";

const useAllAppraisers = (config = {}) => {
  const { getToken } = useGetToken();
  const { data: appraisers, ...rest }: UseQueryResult<Houmer[], Error> = useQuery<Houmer[], Error>(
    "allAppraisersList",
    async () => {
      const authToken = await getToken();
      const response = await schedulesService.getAppraisers(authToken, { status: "active" });
      return response.data;
    },
    {
      ...config,
      onError: () => [],
    }
  );

  return { appraisers, ...rest };
};

export default useAllAppraisers;
