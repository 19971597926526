import clsx from "clsx";

import { MenuItem, Select as MuiSelect, SelectProps } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import useSelectStyles from "./SelectStyles";

interface Option {
  value: string;
  label: string;
}

interface Props extends SelectProps {
  options: Option[];
}

const Select = ({ options, ...props }: Props) => {
  const classes = useSelectStyles();

  const selectProps: SelectProps = {
    IconComponent: ExpandMoreIcon,
    ...props,
    className: clsx(classes.selectRoot, props.className),
    classes: {
      ...props.classes,
      select: clsx(classes.select, props.classes?.select),
    },
  };

  return (
    <MuiSelect {...selectProps}>
      <MenuItem key="empty" value="0">
        Seleccione
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={String(option.value)} value={String(option.value)}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

export default Select;
