import { makeStyles, Theme } from "@material-ui/core";

const useTemplatesTableStyles = makeStyles<Theme>((theme) => ({
  noTemplatesContainer: {
    width: "100%",
    paddingTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
}));

export default useTemplatesTableStyles;
