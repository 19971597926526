import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  text: {
    fontWeight: 600,
    color: theme.palette.grey[600],
    minWidth: 300,
  },
  spacesFiltersContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  filterButton: {
    marginBottom: theme.spacing(1),
  },
}));
