import { useMutation, useQueryClient } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

const useSelectProperty = (contractId: number) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: { propertyId: number }) => {
      const authToken = await getToken();
      const response = await contractService.update(contractId, formData, authToken);
      return response;
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Propiedad del contracto actualizada",
          disclaimer: "La propiedad del contrato ha sido actualizada exitosamente",
        });
        queryClient.invalidateQueries(["contract_data"]);
      },
      onError: (e) => {
        alert.error({
          message: "Error al actualizar propiedad del contrato",
          disclaimer: `Hubo un error al actualizar propiedad del contrato, error: ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useSelectProperty;
