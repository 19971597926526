import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Grid from "@material-ui/core/Grid";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import newEvaluationService from "services/newEvaluationService";
import documentTypeService from "services/documentTypeService";
import { IDocumentTypeList, IPrevData } from "./EvaluateType";
import DocumentsView from "./DocumentsView";
import useStyles from "./EvaluateStyles";
import EvaluateV2 from "./EvaluateV2";
import { useGetToken } from "hooks";

interface Props {
  evaluation: {
    id: number;
    user: {
      name: string;
      last_name: string;
      role: string;
      email: string;
      id: number;
      rut: string;
      second_last_name: string;
      country: string;
      document: string;
    };
    documents: any;
  };
}

function Evaluate({ evaluation }: Props) {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const params = useParams<{ country: string }>();

  const [documentTypeList, setDocumentTypeList] = useState<IDocumentTypeList[] | null>(null);
  const [loadingDocumentType, setLoadingDocumentType] = useState(true);
  const [loadingPrevData, setLoadingPrevData] = useState(true);
  const [prevData, setPrevData] = useState<IPrevData | null>(null);

  useEffect(() => {
    if (params.country === "mx") {
      getToken().then(async (authToken) => {
        const docTypes = await documentTypeService.getList(evaluation.user.country, authToken);
        if (!docTypes) return;
        const docFiltered = docTypes["data"].results
          .filter((doc) => doc.identifier !== "RFC")
          .map((doc) => ({
            id: doc.id,
            type: doc.type,
            value: doc.id.toString(),
            label: doc.type,
            validationRegex: doc.validation_regex,
          }));
        setDocumentTypeList(docFiltered);
        await fetchDetails(authToken);
        setLoadingDocumentType(false);
      });
    } else {
      getToken().then(async (authToken) => {
        await fetchDetails(authToken);
        setLoadingDocumentType(false);
      });
    }
  }, []);

  const fetchDetails = useCallback(
    async (authToken) => {
      await newEvaluationService
        .getEvaluationDetails(evaluation.id, authToken, params.country)
        .then((e) => {
          setPrevData(e["data"].evaluation_details.details);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingPrevData(false));
    },
    [evaluation.id]
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Loading loading={loadingDocumentType || loadingPrevData}>
          <>
            <Grid item xs={4}>
              <SnackbarProvider>
                <EvaluateV2
                  evaluation={evaluation}
                  documentTypeList={documentTypeList}
                  prevData={prevData}
                />
              </SnackbarProvider>
            </Grid>
            <Grid item xs={8}>
              <DocumentsView documents={evaluation.documents} />
            </Grid>
          </>
        </Loading>
      </Grid>
    </div>
  );
}

export default Evaluate;
