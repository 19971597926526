import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  cardRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(4, 1.25, 2),
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  textBold: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
}));
