export default {
  titleContainer: "w-full bg-secondary-10 my-2",
  title: "font-bold text-h4 p-1",
  subtitle: "font-bold text-h4 my-1",
  comment: "my-2 p-2 border rounded-xl border-secondary-40",
  commentHeadInfo: "flex items-center justify-between",
  primaryText: "font-bold",
  secondaryText: "font-normal text-secondary-80",
  commentContent: "p-1",
};
