import { useState } from "react";
import { TableProvider } from "context/tableContext/tableContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import BasicServiceModal from "./components/modals/BasicServicesModal";

import BasicServiceTable from "./components/Table";

import useStyles from "./BasicServiceStyles";

function BasicServices() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  return (
    <TableProvider>
      <BasicServiceModal
        open={openModal}
        showCloseButton
        onClose={() => setOpenModal(false)}
        onSuccess={() => setOpenModal(false)}
      />
      <div className={classes.buttonContainer}>
        <DesignSystemButton
          variant="primary"
          size="medium"
          label="Agregar servicio"
          onClick={() => setOpenModal(true)}
        />
      </div>
      <div className={classes.container}>
        <BasicServiceTable />
      </div>
    </TableProvider>
  );
}

export default BasicServices;
