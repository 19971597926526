import { ReactNode, ComponentProps } from "react";

import { Divider, Checkbox } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import ItemCard from "./ItemCard";
import useVisitCardStyles from "./VisitCardStyles";
import { ReactComponent as Checked } from "assets/img/Checked.svg";
import { ReactComponent as Unchecked } from "assets/img/Unchecked.svg";

type Sides = "left" | "right";

interface Props {
  id: string;
  title: ReactNode;
  content?: ReactNode;
  /**
   * set of readable/editable inputs
   * ! if any input is editable the component shall be wrapper inside of FormProvider component
   */
  items?: ComponentProps<typeof ItemCard>[];
  required?: boolean;
  buttonOptions?: {
    buttonLabel: string;
    buttonPosition?: Sides;
    iconPosition?: Sides;
    buttonIcon?: ComponentProps<typeof DesignSystemButton>["Icon"];
    buttonCallback?: ComponentProps<typeof DesignSystemButton>["onClick"];
    isSubmit?: boolean;
  };
  checkedOptions?: {
    checked: boolean;
    onChange?: () => void;
  };
  isEditable?: boolean;
}

function VisitCard({
  id,
  title,
  items = null,
  content = null,
  buttonOptions = null,
  checkedOptions = null,
  required = false,
  isEditable = true,
}: Props) {
  const classes = useVisitCardStyles({
    position: buttonOptions?.buttonPosition,
  });
  return (
    <div className={classes.card}>
      <div className={classes.titleContainer}>
        <div className={classes.requiredContainer}>
          {title}
          {required && <span className={classes.requiredSymbol}>*</span>}
        </div>

        {checkedOptions && isEditable && (
          <Checkbox
            icon={<Unchecked />}
            checked={checkedOptions.checked}
            value={checkedOptions.checked}
            id={`${id}-checkbox`}
            onClick={() => checkedOptions.onChange && checkedOptions.onChange()}
            checkedIcon={<Checked />}
            classes={{
              root: classes.checkRoot,
            }}
          />
        )}
      </div>

      {(content || items) && <Divider />}

      {items && items?.map((props) => <ItemCard {...props} key={props.title} />)}
      {content}

      {/* footer section */}
      {buttonOptions && isEditable && !buttonOptions.isSubmit && (
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            type="button"
            size="small"
            id={`${id}-button`}
            buttonProps={{
              endIcon: buttonOptions.iconPosition === "right" && buttonOptions.buttonIcon,
              startIcon: buttonOptions.iconPosition === "left" && buttonOptions.buttonIcon,
            }}
            onClick={buttonOptions.buttonCallback}
            label={buttonOptions.buttonLabel}
            variant="primary"
          />
        </div>
      )}
      {buttonOptions && isEditable && buttonOptions.isSubmit && (
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            type="submit"
            size="small"
            id={`${id}-submit`}
            buttonProps={{
              endIcon: buttonOptions.iconPosition === "right" && buttonOptions.buttonIcon,
              startIcon: buttonOptions.iconPosition === "left" && buttonOptions.buttonIcon,
            }}
            label={buttonOptions.buttonLabel}
            variant="primary"
          />
        </div>
      )}
    </div>
  );
}

export default VisitCard;
