import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  mode: "horizontal" | "vertical";
}

const useItemCardStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    display: "flex",
    gap: ({ mode }) => (mode === "horizontal" ? theme.spacing(2) : 0),
    flexDirection: ({ mode }) => (mode === "horizontal" ? "row" : "column"),
  },
  input: {
    borderRadius: 8,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[50],
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
  },
  content: {
    whiteSpace: "pre-line",
    fontSize: theme.typography.pxToRem(14),
  },
  countryWrapper: {
    padding: 0,
  },
  countryInput: {
    borderRadius: 8,
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.grey[50],
  },
  selectInput: {
    borderRadius: 8,
    backgroundColor: theme.palette.grey[50],
  },
  numberWrapper: {
    padding: 0,
  },
}));

export default useItemCardStyles;
