import { useMemo, useState } from "react";
import { chunk } from "lodash";

import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import Spinner from "Components/Shared/partials/Spinner";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { Photo } from "Components/Admin/administrator/subscriptions/hooks/requests/types";
import useRepairsRequests from "Components/Admin/administrator/subscriptions/hooks/requests/useRepairsRequests";

import useRequestsTableStyles from "./RequestsTableStyles";
import ObservationModal from "../ObservationModal";
import getColumns from "./columns";
import PhotosModal from "../PhotosModal";

const RequestsTable = () => {
  const { state: tableState, dispatch: tableDispatch } = useTable();
  const { currentPage, pageSize } = tableState;
  const { repairsRequestsList = [], isLoading } = useRepairsRequests();
  const [isObservationOpen, setIsObservationOpen] = useState(false);
  const [currentObservation, setCurrentObservation] = useState("");
  const [isPhotosOpen, setIsPhotosOpen] = useState(false);
  const [currentPhotos, setCurrentPhotos] = useState<Photo[]>([]);

  const repairsRequestsListChunked = useMemo(
    () => chunk(repairsRequestsList, pageSize),
    [pageSize, repairsRequestsList]
  );
  const classes = useRequestsTableStyles();
  const onObservationClick = (observation: string) => {
    setCurrentObservation(observation);
    setIsObservationOpen(true);
  };
  const onPhotosClick = (photos: Photo[]) => {
    setIsPhotosOpen(true);
    setCurrentPhotos(photos);
  };
  const columns = useMemo(
    () =>
      getColumns({
        classes,
        onObservationClick,
        onPhotosClick,
      }),
    [classes]
  );

  if (isLoading) return <Spinner />;
  return (
    <>
      <CustomDataGrid
        className={classes.table}
        rowCount={repairsRequestsList?.length || 0}
        columns={columns}
        rows={repairsRequestsListChunked[currentPage - 1] || []}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => tableDispatch(setCurrentPage(params + 1))}
        onPageSizeChange={(params) => {
          tableDispatch(setPageSize(params));
          tableDispatch(setCurrentPage(1));
        }}
      />
      <ObservationModal
        openModal={isObservationOpen}
        onClose={() => setIsObservationOpen(false)}
        observation={currentObservation}
      />
      <PhotosModal
        openModal={isPhotosOpen}
        onClose={() => setIsPhotosOpen(false)}
        photos={currentPhotos}
      />
    </>
  );
};

export default RequestsTable;
