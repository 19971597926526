import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    width: 500,
    display: "flex",
    flexDirection: "column",
  },
  saveButton: {
    marginTop: theme.spacing(2.5),
    textTransform: "none",
    borderRadius: 25,
    fontWeight: "bold",
    width: 200,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
}));
