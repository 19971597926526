import ForwardIcon from "@material-ui/icons/Forward";
import { Grid } from "@material-ui/core";

import { IReservation } from "services/newPaymentService/types";

import ApplicantReservationData from "../ApplicantReservationData";
import OutflowReservationData from "../OutflowReservationData";
import ProfitReservationData from "../ProfitReservationData";
import ReservationDetails from "../ReservationDetails";
import useDetailsStyles from "./DetailsStyles";
import CardDetail from "../CardDetail";

interface Props {
  reservations: IReservation[];
}
const Details = ({ reservations }: Props) => {
  const classes = useDetailsStyles();

  const filterIncomeReservation = reservations.filter((mov) => mov.type === "INCOME");
  const filterOutflowReservation = reservations.filter((mov) => mov.type === "OUTFLOW");
  const filterProfitReservation = reservations.filter((mov) => mov.type === "PROFIT");

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item container xs={5}>
        <ReservationDetails type="Recaudado">
          {filterIncomeReservation.map((reservation) => (
            <CardDetail
              key={reservation.legacy_id}
              title="Pago reserva arrendatario"
              reservation={reservation}>
              <ApplicantReservationData reservation={reservation} />
            </CardDetail>
          ))}
        </ReservationDetails>
      </Grid>
      <Grid item container xs={2} justifyContent="center">
        <ForwardIcon className={classes.icon} />
      </Grid>
      <Grid item container xs={5} spacing={2}>
        <ReservationDetails type="Pago clientes">
          {filterOutflowReservation.map((reservation) => (
            <CardDetail key={reservation.legacy_id} title="Reembolso" reservation={reservation}>
              <OutflowReservationData reservation={reservation} />
            </CardDetail>
          ))}
        </ReservationDetails>
        {filterProfitReservation.length > 0 && (
          <ReservationDetails type="Houm">
            {filterProfitReservation.map((reservation) => (
              <CardDetail
                key={reservation.legacy_id}
                title="Ingreso Houm"
                reservation={reservation}>
                <ProfitReservationData reservation={reservation} />
              </CardDetail>
            ))}
          </ReservationDetails>
        )}
      </Grid>
    </Grid>
  );
};

export default Details;
