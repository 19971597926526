import { MacrozonesProvider } from "context/macrozones/macrozonesContext";
import { SnackbarProvider } from "notistack";
import { MapProvider } from "context/map/mapContext";
import MacrozoneIndex from "./components/MacrozoneIndex";

function Macrozones() {
  return (
    <MacrozonesProvider>
      <SnackbarProvider>
        <MapProvider>
          <MacrozoneIndex />
        </MapProvider>
      </SnackbarProvider>
    </MacrozonesProvider>
  );
}

export default Macrozones;
