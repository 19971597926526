import { Lead as ResLead } from "models/DemandLead";

interface Lead {
  id: number;
  created_at: string;
  status: string;
  origin: string;
  following_date: string;
  name: string;
  email: string;
  phone: string;
  lq: string;
  qualification: number;
}

export const parseLeads = (data: ResLead[]): Lead[] =>
  data.map(({ lead, status, executive, id, origin, created_at, following_date }) => ({
    id,
    created_at,
    origin,
    following_date,
    email: lead.email,
    lq: `${executive.name} ${executive.last_name}`,
    name: lead.name,
    phone: lead.phone,
    status: status.status,
    qualification: lead.qualification,
  }));
