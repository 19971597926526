import { HeaderWithCountries } from "Components/Admin/UIComponents";
import { setCountry } from "context/landlordVipContext/landlordVipActions";
import { useLandlordVip } from "context/landlordVipContext/landlordVipContext";
import { Country } from "context/landlordVipContext/landlordVipTypes";

function Header() {
  const { dispatch, state } = useLandlordVip();

  const onClickHandle = (country: Country) => {
    dispatch(setCountry(country));
  };

  return (
    <HeaderWithCountries
      title="Propietarios VIP"
      country={state.country}
      onCountryChange={onClickHandle}
    />
  );
}

export default Header;
