import currency from "@houm-com/formats/currency";

import { GridAlignment, GridColDef, GridColumns } from "@material-ui/x-grid";
import { Box, IconButton, Typography } from "@material-ui/core";

// import { ReactComponent as DownloadIcon } from "assets/icons/download-file.svg";
// import { ReactComponent as VisibleIcon } from "assets/icons/visible-v2.svg";
import { IWarrantyDiscountsBreakdown, IWarrantyStatus } from "models/Warranty";
import { ReactComponent as TrashSvg } from "assets/icons/trash-bold.svg";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import useDeleteDiscount from "../../../../hooks/useDeleteDiscount";
import useDiscountsTableStyles from "./DiscountsTableStyles";

interface Props {
  contractId: number;
  country: Locale;
  discounts: IWarrantyDiscountsBreakdown[];
  warrantyStatus: IWarrantyStatus;
  currencyCode: CurrencyCode;
}

const DiscountsTable = ({
  contractId,
  country,
  discounts,
  warrantyStatus,
  currencyCode,
}: Props) => {
  const classes = useDiscountsTableStyles(discounts.length)();
  const { mutate } = useDeleteDiscount(contractId, country);

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    filterable: false,
    cellClassName: classes.centerCell,
    sortable: false,
    disableColumnMenu: true,
    disableReorder: true,
    editable: false,
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      field: "amount",
      headerName: "Cantidad",
      type: "number",
      resizable: false,
      headerClassName: classes.firstHeaderCell,
      align: "right",
      width: 140,
      renderCell: ({ value }) => currency(value as number, currencyCode),
    },
    {
      ...columnsConfig,
      field: "reason",
      headerName: "Motivo",
      type: "string",
      flex: 1,
    },
    {
      ...columnsConfig,
      field: "description",
      headerName: "Comentarios",
      flex: 2,
      type: "string",
    },
    // {
    //   ...columnsConfig,
    //   field: "documents",
    //   headerName: "Documentos",
    //   headerClassName: classes.commentsHeaderCell,
    //   align: "center",
    //   width: 148,
    //   renderCell: ({ value, row }) => (
    //     <Box className={classes.buttonsContainer}>
    //       <Box className={classes.documentsButton}>
    //         <Typography className={classes.documentText}>Ver</Typography>
    //         <VisibleIcon className={classes.visibleIcon} />
    //       </Box>
    //       <Box className={classes.documentsButton}>
    //         <Typography className={classes.documentText}>Descargar</Typography>
    //         <DownloadIcon className={classes.documentIcon} />
    //       </Box>
    //     </Box>
    //   ),
    // },
    ...(["NEGATIVE", "UNLIQUIDATED"].includes(warrantyStatus)
      ? [
          {
            ...columnsConfig,
            field: "actions",
            headerName: "-",
            resizable: false,
            headerClassName: classes.lastRenderedHeaderCell,
            cellClassName: classes.lastRenderedRowCell,
            align: "center" as GridAlignment,
            width: 40,
            renderHeader: () => <span className={classes.emptyHeaderName}>-</span>,
            renderCell: ({ row }) => (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => mutate({ movementId: row.id })}>
                <TrashSvg className={classes.trashIcon} />
              </IconButton>
            ),
          },
        ]
      : []),
  ];

  return (
    <Box>
      {discounts.length === 0 && (
        <Typography className={classes.emptyData}>No hay ningún descuento registrado.</Typography>
      )}
      {discounts.length > 0 && (
        <CustomDataGrid
          autoHeight={false}
          hideFooter
          rowHeight={48}
          headerHeight={40}
          rowCount={discounts.length}
          columns={columns}
          rows={discounts}
          loading={false}
          disableSelectionOnClick={true}
          classes={{
            root: classes.root,
            columnHeader: classes.headerWrapper,
          }}
        />
      )}
    </Box>
  );
};

export default DiscountsTable;
