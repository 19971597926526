import { useMutation } from "react-query";
import { AxiosError } from "axios";

import scheduleService from "services/schedulesService";
import { CountryUpperCase } from "models/Countries";
import useApplicant from "./useApplicant";
import { UserModel } from "models/User";
import { useGetToken } from "hooks";

interface IVisitSchedule {
  formData: {
    applicant_email?: string;
    appraiser: string;
    begin_hour: string;
    end_hour: string;
    prop: number;
    schedule_type: string;
    comment: string;
    not_emails: boolean;
    origin: string;
  };
  user: UserModel;
  propertyId: number;
  manualUser: {
    email: string;
    name: string;
    last_name: string;
    document: string;
    documentType: string;
    phone: string;
    country: CountryUpperCase;
  };
  eventTypeValue: string;
}

interface useCreateVisitScheduleProps {
  isSuccessHandler?: () => void;
}

const useCreateVisitSchedule = ({ isSuccessHandler }: useCreateVisitScheduleProps) => {
  const { getToken } = useGetToken();

  const DEFAULT_ERROR = {
    message: "UNHANDLED_ERROR",
  };

  const { mutate, isError, isLoading, isSuccess, error } = useMutation(
    async (data: IVisitSchedule) => {
      const authToken = await getToken();

      if (data.eventTypeValue.includes("Visit")) {
        useApplicant(data.user, authToken, data.propertyId, data.manualUser);
      }

      const response = await scheduleService.createSchedule(data.formData, authToken);
      return response?.data;
    },
    {
      onSuccess: () => isSuccessHandler?.(),
    }
  );

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
    error:
      (
        error as AxiosError<{
          message: string;
          extra?: {
            quotation_id?: number;
            schedule_type?: string;
            date?: string;
          };
        }>
      )?.response?.data ?? DEFAULT_ERROR,
  };
};

export default useCreateVisitSchedule;
