import { cn } from "@houm-com/ui/utils";

import { localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";

import classes from "./CountryChipStyles";

interface CountryChipProps {
  country: Locale;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
}

const countryFlag = (country: Locale) => (
  <img
    className={classes.icon}
    alt={`flag ${localeToCountry[country].toLocaleLowerCase()}`}
    src={`https://s3.amazonaws.com/houm.com/static/media/countries/Flag${country.toLocaleUpperCase()}.png`}
  />
);

const CountryChip = ({ country, onClick, selected = false, className = "" }: CountryChipProps) => (
  <div
    className={cn([classes.container, className, [{ [classes.selected]: selected }]])}
    onClick={onClick}
    data-testid={`chip-${country}`}>
    <p>{localeToCountry[country]}</p>
    {countryFlag(country)}
  </div>
);

export default CountryChip;
