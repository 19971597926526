import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountStyles = makeStyles<Theme>((theme) => ({
  bankAccountContainer: {
    gap: 12,
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: 700,
    },
    "& span": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
      fontWeight: 400,
    },
  },
  card: {
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    margin: 0,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

export default useBankAccountStyles;
