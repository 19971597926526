import { makeStyles, Theme } from "@material-ui/core";

const useServiceDetailsStyles = makeStyles<Theme>((theme) => ({
  serviceItem: {
    width: "100%",
    display: "flex",
  },
  aggregatedDebt: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  disabledText: {
    color: "#A8ADAF",
  },
  text: {
    marginLeft: theme.spacing(0.5),
  },
  label: {
    paddingLeft: 0,
  },
}));

export default useServiceDetailsStyles;
