import { Typography } from "@material-ui/core";
import clx from "classnames";

import { Locale } from "models/Countries";
import { parsePrice } from "utils";

import usePaymentDetailsStyles from "./PaymentDetailsStyles";

interface Props {
  typeMoney: string;
  amount: number;
  countryCode: Locale;
}

const PaymentDetails = ({ typeMoney, amount, countryCode }: Props) => {
  const classes = usePaymentDetailsStyles();

  return (
    <>
      {countryCode === "co" && (
        <Typography className={clx(classes.text, classes.disclaimer)}>
          <>
            <b>Multa:</b>
            <span className={classes.textDisclaimer}> Ver información adjunta abajo</span>
          </>
        </Typography>
      )}
      <Typography className={classes.text}>
        <b>Moneda:</b>
        {` ${typeMoney ?? "-"}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Monto de arriendo:</b>
        {` ${amount && typeMoney ? parsePrice(amount, typeMoney) : "-"}`}
      </Typography>
    </>
  );
};

export default PaymentDetails;
