import { useState } from "react";
import { useParams } from "react-router-dom";

import ModalDialog from "@houm-com/ui/ModalDialog";

import useChangeProperty from "domains/evaluations/hooks/useChangeProperty";
import { Locale } from "models/Countries";

import { ISuggestedProperty } from "../../utils/types";
import PropertySearch from "../PropertySearch";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  evaluationId?: string;
}

const ChangePropertyModal = ({ evaluationId, open, handleOpenModal }: Props) => {
  const { country } = useParams<{ country: Locale }>();
  const { mutate, isLoading } = useChangeProperty({
    evaluationId,
    country,
    onHandleSuccess: handleOpenModal,
  });
  const [property, setProperty] = useState<ISuggestedProperty>(null);

  const onSelectProperty = (prop: ISuggestedProperty) => setProperty(prop);
  const onSubmit = () => mutate({ propertyId: property.id, propertyUid: property.uid });

  return (
    <ModalDialog title="Seleccionar propiedad" isOpen={open} onClose={handleOpenModal}>
      <ModalDialog.Content classNameContent="flex flex-col p-1 gap-2">
        <PropertySearch onSelectProperty={onSelectProperty} country={country} />
      </ModalDialog.Content>
      <ModalDialog.Actions
        onClose={handleOpenModal}
        submitButtonLabel="Cambiar"
        onSubmit={onSubmit}
        submitButtonProps={{
          type: "button",
          isLoading,
          disabled: !property,
        }}
      />
    </ModalDialog>
  );
};

export default ChangePropertyModal;
