import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Button from "@houm-com/ui/Button";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { setPlanData, setSteps, submitData } from "context/plansContext/plansActions";
import { usePlans } from "context/plansContext/plansContext";
import {
  parseCities,
  parseFormData,
  parseMonthlyData,
  parsePlanById,
} from "context/plansContext/utils";
import { useGetToken, useQueryParams } from "hooks";
import useGetPlansCategories from "hooks/usePlans/useGetPlansCategories";
import { CountryUpperCase } from "models/Countries";
import planService from "services/planService";
import useGetMandateTypes from "hooks/usePlans/useGetMandateTypes";
import useGetTemplates from "hooks/usePlans/useGetTemplates";
import { countryCode } from "utils";

import PlansHeader from "../../layouts/plansHeader";
import useStyles from "./PlanDetailStyles";
import PlanData from "../../layouts/PlanData";
import MonthlyDetails from "./MonthlyDetailData";
import PlanFormProps from "../PlansForm/types";

function PlanDetail() {
  const classes = useStyles();
  const history = useHistory();
  const query = useQueryParams();
  const params = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const { state, dispatch } = usePlans();
  const cities = query.get("cities").split(",");
  const country = query.get("country");
  const { planCategories, isLoading: isCategoriesLoading } = useGetPlansCategories(
    countryCode(country)
  );
  const { isLoading: isMandateTypesLoading } = useGetMandateTypes(country as CountryUpperCase);
  const { isLoading: isTemplatesLoading } = useGetTemplates();
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const isLoading = isCategoriesLoading || isMandateTypesLoading || isTemplatesLoading;
  const getPlanDetail = async (authToken: string) => {
    try {
      const result = await planService.getPlanById(Number(params.id), authToken);
      dispatch(setPlanData(parsePlanById(result.data)));
    } catch {
      console.error("Error fetching plan detail");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken().then((authToken) => {
      getPlanDetail(authToken);

      dispatch(
        setPlanData({
          country: country as CountryUpperCase,
        })
      );

      if (!state.steps.find((step) => step.label === "Detalle Plan")) {
        dispatch(
          setSteps([
            {
              link: `/admin/plans/show/${params.id}?country=${country}&cities=${cities.join(",")}`,
              label: "Detalle Plan",
            },
          ])
        );
      }
    });
  }, []);

  const handleSoftDeletePlan = () => {
    Swal.fire({
      title: "Atención",
      text: `Se va a eliminar el plan ${state.planData.name}, ¿Está seguro?`,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        try {
          setLoadingDelete(true);
          const authToken = await getToken();
          await planService.deletePlan(Number(params.id), authToken);
          Swal.fire({
            title: "Exito",
            type: "success",
            text: `El plan ${state.planData.name} ha sido eliminado exitosamente`,
          }).then(() => history.push(`/admin/plans`));
        } catch (e) {
          Swal.fire({
            type: "error",
            text: `Hubo un error eliminado el plan, Error: ${e}`,
          });
        } finally {
          setLoadingDelete(false);
        }
      }
    });
  };

  const methods = useForm<PlanFormProps>();

  const onSubmit: SubmitHandler<PlanFormProps> = (data) => {
    setSubmitting(true);
    const planData = {
      id: state.planData.id,
      ...data,
      monthlyData: parseMonthlyData(data),
    } as PlanFormProps;
    dispatch(setPlanData(parseFormData(planData, planCategories)));
    getToken()
      .then(async (authToken) => {
        await submitData(
          planData,
          state.selectedCountry as CountryUpperCase,
          parseCities(state.planData.cities),
          authToken,
          true
        ).then(() => history.push("/admin/plans"));
      })
      .catch((e) => {
        console.error(e);
        Swal.fire({
          type: "error",
          title: "Ups!",
          text: "No se pudo cargar el template",
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <FormProvider {...methods}>
      <div className={classes.root}>
        <Loading loading={loading || isLoading}>
          <>
            <PlansHeader steps={state.steps} />
            <div className="p-4">
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PlanData
                  hasManagement={state.planData?.category?.hasManagement}
                  isDisabled={true}
                  country={countryCode(country)}
                  planCategories={planCategories}
                />
                <DesignSystemButton
                  type="submit"
                  label="Guardar template"
                  size="small"
                  variant="primary"
                  isLoading={submitting}
                />
              </form>
              {state.planData.category?.hasManagement && <MonthlyDetails />}
            </div>
            <div className="flex justify-center mb-4">
              <Button
                variant="primary"
                size="lg"
                isLoading={loadingDelete}
                disabled={loadingDelete}
                onClick={handleSoftDeletePlan}>
                Eliminar
              </Button>
            </div>
          </>
        </Loading>
      </div>
    </FormProvider>
  );
}

export default PlanDetail;
