import { makeStyles, Theme } from "@material-ui/core";

const useinitContractFormStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  formContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useinitContractFormStyles;
