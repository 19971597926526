import { useMemo } from "react";

import { IDebtCollectionInsuranceStatus } from "hooks/useGetDebtCollectionInsurances/utils/types";
import { IPlanCategory } from "models/Plan";
import { Locale } from "models/Countries";

import statuses from "../utils/insuranceStatuses";

interface UseFilterOptionsProps {
  insuranceCode: string;
  insurances: IDebtCollectionInsuranceStatus[];
  country: Locale;
  planCategories: IPlanCategory[];
}

const useFilterOptions = ({
  insuranceCode,
  insurances,
  country,
  planCategories,
}: UseFilterOptionsProps) => {
  const statusOptions = [
    { label: "Activo", value: "ONGOING" },
    { label: "Finalizado", value: "FINISHED" },
  ];
  const isHoumInsurance = (insuranceCode: string) =>
    insuranceCode?.toLocaleLowerCase().includes("houm");
  const insuranceStatusOptions = useMemo(
    () =>
      country !== "mx" && !isHoumInsurance(insuranceCode) && insurances?.length
        ? insurances
            .find((insurance) => !isHoumInsurance(insurance.company_code))
            .statuses?.map((status) => ({
              label: statuses[country]({})[status]?.name,
              value: status,
            }))
        : [],
    [insurances, country, insuranceCode]
  );
  const insurancesOptions = useMemo(
    () =>
      insurances.map((i) => ({
        label: i.company_code.split("_")[0],
        value: i.company_code,
      })),
    [insurances]
  );
  const planTypesOptions = planCategories.map((planCategory) => ({
    value: planCategory.id.toString(),
    label: planCategory.description,
  }));
  return {
    status: statusOptions,
    insurance_status: insuranceStatusOptions,
    insurance_code: insurancesOptions,
    plan_category_id: planTypesOptions,
  };
};

export default useFilterOptions;
