import { Typography } from "@material-ui/core";
import clx from "classnames";

import useTitleStyles from "./TitleStyles";

interface Props {
  className?: string;
}

const Title: React.FC<Props> = ({ children, className }) => {
  const classes = useTitleStyles();

  return (
    <Typography component="h2" className={clx(classes.titleRoot, className)}>
      {children}
    </Typography>
  );
};

export default Title;
