import { useFormContext } from "react-hook-form";

import { ICity } from "Components/Admin/administrator/promotions/utils/types";
import { Locale } from "models/Countries";
import { localeToCountry } from "utils/countryCode";

import { parseSummaryData } from "../../utils/utils";
import { classes } from "./PromotionSummaryStyles";

interface Props {
  availableCities: ICity[];
  country: Locale;
}

const PromotionSummary = ({ availableCities, country }: Props) => {
  const { watch } = useFormContext();
  const cities = watch("cities") as string[] | undefined;
  const promotionTarget = watch("promotionTarget");
  const landlordManagementDiscount = parseSummaryData(watch("landlordManagementDiscount"), "%");
  const landlordBrokerageDiscount = parseSummaryData(watch("landlordBrokerageDiscount"), "%");
  const tenantBrokerageDiscount = parseSummaryData(watch("tenantBrokerageDiscount"), "%");
  const duration = parseSummaryData(watch("discountMonths"), " meses");

  return (
    <div className={classes.promotionSummaryRoot}>
      <p className={classes.title}>Resumen de la promoción</p>

      <div>
        <p className={classes.title}>{localeToCountry[country]}</p>
        <div className={classes.citiesContainer}>
          {cities &&
            cities.map((cityNumber) => (
              <p key={`city-${cityNumber}`} className={classes.city}>
                {availableCities.find((city) => city.id === parseInt(cityNumber))?.name}
              </p>
            ))}
        </div>
      </div>

      <div>
        <p className={classes.title}>{watch("name")}</p>
        <p className={classes.text}>{watch("goal")}</p>
      </div>

      <div>
        <p className={classes.title}>Detalles de la promoción</p>
        {promotionTarget !== "brokerage" && <p className={classes.text}>Duración: {duration}</p>}
        <p className={classes.text}>
          Descuento comisión a propietario: {landlordManagementDiscount}
        </p>
        <p className={classes.text}>
          Descuento corretaje a propietario: {landlordBrokerageDiscount}
        </p>
        <p className={classes.text}>
          Descuento corretaje a arrendatario: {tenantBrokerageDiscount}
        </p>
      </div>
    </div>
  );
};

export default PromotionSummary;
