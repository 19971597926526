import { UserSegmentation } from "models/User";

const parseWayOfContact = (key: string): string => {
  const values = {
    phone_call: "Llamada telefónica",
    whatsapp: "Whatsapp",
    email: "Email",
    "-": "-",
  };
  return values[key];
};

const parseWayToHelp = (key: string): string => {
  const values = {
    schedule_visit: "Agendar visita",
    searching_assistance: "Buscando asistencia",
    more_info: "Más información",
    other: "Otro",
  };
  return values[key];
};

const parseFinancing = (key: string): string => {
  const values = {
    none: "Ninguno",
    other: "Otro",
    cash_payment: "Pago en efectivo",
    approved_loan: "Préstamo aprovado",
    preapproved_loan: "Préstamo pre-aprovado",
  };
  return values[key];
};

const parsePropertyType = (key: string): string => {
  const values = {
    apartment: "Departamento",
    house: "Casa",
    any: "Casa / Departamento",
  };
  return values[key];
};

const parsePrice = (price: number | null) => (price ? `$ ${price}` : "No especificado");

const parsePriceRange = (min: number | null, max: number | null) =>
  `${parsePrice(min)} - ${parsePrice(max)}`;

export const parseSegmentationData = (segmentation: UserSegmentation) => [
  [
    {
      label: "Forma de contacto",
      value: parseWayOfContact(segmentation.wayOfContact || "-"),
    },
    {
      label: "Forma de ayuda",
      value: parseWayToHelp(segmentation.wayToHelp || "-"),
    },
    {
      label: "Motivo de búsqueda",
      value: segmentation.searchReason || "-",
    },
    {
      label: "Qué busca",
      value: parsePropertyType(segmentation.propertyType || "-"),
    },
    { label: "Dónde busca", value: segmentation.address || "-" },
    {
      label: "Rango de precio",
      value: parsePriceRange(segmentation.minPrice, segmentation.maxPrice),
    },
  ],
  [
    {
      label: "Financiamiento",
      value: parseFinancing(segmentation.financing || "-"),
    },
    {
      label: "Ingreso mensual",
      value: parsePriceRange(segmentation.minWage, segmentation.maxWage),
    },
    {
      label: "Capacidad de ahorro",
      value: parsePriceRange(segmentation.minSaving, segmentation.maxSaving),
    },
    {
      label: "Comentario adicional",
      value: segmentation.comments || "-",
    },
  ],
];

export const parseAddress = (details) => {
  const depto = details.numero_depto ? `Dpto ${details.numero_depto}, ` : "";
  const commune = details.comuna ? `, ${details.comuna}` : "";
  return `${depto}${details.address} ${details.street_number}${commune}`;
};
