import { makeStyles, Theme } from "@material-ui/core";

const useHourButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 100,
    padding: theme.spacing(13 / 8, 19 / 8),
    fontWeight: 700,
    maxHeight: 48,
  },
  selected: {
    backgroundColor: "#FEF1F0",
  },
}));

export default useHourButtonStyles;
