export const PENALTY = "PENALTY";

export const INCOME = "INCOME";
export const OUTFLOW = "OUTFLOW";

export const TYPE_ALL = "all";

export const CONCEPTS = {
  reservation: "RESERVATION",
  contract: "CONTRACT",
  lease: "LEASE",
  spot: "SPOT",
  other: "OTHER",
};
