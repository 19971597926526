import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@material-ui/core/Box";

import Spinner from "Components/Shared/partials/Spinner";
import useGetInsuranceCompanies from "domains/insuranceCompanies/hooks/useGetInsuranceCompanies";
import { useFormNotifications } from "hooks";
import { Locale } from "models/Countries";

import useMultipleEvaluationDetails from "../../../../hooks/useMultipleEvaluationDetails";
import { initContractSchema, requiredText } from "../utils/validationSchema";
import useInitContract from "../../../../hooks/useInitContract";
import InitContractForm from "../InitContractForm";
import ApplicantsInfo from "../ApplicantsInfo";
import ErrorsList from "./components/ErrorsList";

interface IContractInit {
  selectedDate: string;
  tenantHasPets: string;
  childHabitants: string;
  adultHabitants: string;
  insuranceCompany: string;
}

interface Props {
  onSave: () => void;
  country: Locale;
}

function InitContract({ onSave, country }: Props) {
  const {
    evaluations,
    evaluationData,
    isLoading: isLoadingEvaluations,
  } = useMultipleEvaluationDetails();
  const { insuranceCompanies, insuranceCompaniesError, insuranceCompaniesLoading } =
    useGetInsuranceCompanies(country);
  const { initContract, isLoading, errors: contractErrors } = useInitContract();

  const methods = useForm<IContractInit>({
    resolver: yupResolver(initContractSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<IContractInit>({ errors, requiredText });

  const onSubmit: SubmitHandler<IContractInit> = async (formData) => {
    if (isLoading) return;
    const { adultHabitants, childHabitants, selectedDate, tenantHasPets, insuranceCompany } =
      formData;
    initContract({
      evaluationData,
      selectedDate,
      tenantHasPets: tenantHasPets === "yes",
      adultHabitants,
      childHabitants,
      insuranceCompany,
    });
  };

  if (contractErrors?.length) return <ErrorsList errors={contractErrors} />;

  if (insuranceCompaniesError)
    return <ErrorsList errors={[{ text: "Error cargando aseguradoras", id: 1 }]} />;

  if (insuranceCompaniesLoading || isLoadingEvaluations) return <Spinner />;

  return (
    <Box display="flex" flexDirection="column">
      <ApplicantsInfo evaluations={evaluations} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InitContractForm isLoading={isLoading} insuranceCompanies={insuranceCompanies} />
        </form>
      </FormProvider>
    </Box>
  );
}

export default InitContract;
