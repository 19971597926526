import { useState } from "react";
import { capitalize } from "lodash";

import { GridCellParams, GridColDef } from "@material-ui/x-grid";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { Grid, Typography } from "@material-ui/core";
import ModalDialog from "@houm-com/ui/ModalDialog";

import { ReactComponent as Edit } from "assets/icons/editRounded.svg";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { ContractModel, IPlanMovement } from "models/Contract";
import PlanMovementForm from "./PlanMovementForm";
import useStyles from "./PlanMovementsStyles";
import { checkStatus } from "../utils";
import { parsePrice } from "utils";

interface Props {
  type: "lessee" | "lessor";
  planMovement: IPlanMovement[];
  contract: ContractModel;
  setPlanMovement: (movement: IPlanMovement[]) => void;
}

function PlanMovements({ type, planMovement, contract, setPlanMovement }: Props) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [planToEdit, setPlanToEdit] = useState<IPlanMovement | null>(null);

  const handleOpenEditMovement = (data: IPlanMovement) => {
    setOpenDialog(true);
    setPlanToEdit(data);
  };

  const handleCloseEditMovement = () => {
    setOpenDialog(false);
    setPlanToEdit(null);
  };

  const handleOpenCreateMovement = () => setOpenCreateDialog(true);

  const handleCloseCreateMovement = () => setOpenCreateDialog(false);

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
    cellClassName: classes.centerCell,
  };

  const columns = [
    {
      ...columnsConfig,
      field: "type_movement",
      headerName: "Descuento / abono",
      sortable: false,
      renderCell: ({ value }) => <Typography>{value === "Cargo" ? "Descuento" : value}</Typography>,
    },
    {
      ...columnsConfig,
      field: "description",
      headerName: "Descripción",
      sortable: false,
    },
    {
      ...columnsConfig,
      field: "amount",
      headerName: "Monto total",
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div className={classes.amount}>
          <Typography>
            {parsePrice(Number(params.row.amount), contract?.type_money, true)}
          </Typography>
          {!checkStatus(["Boton de pago enviado", "Pagado", "Finalizado"], contract.status) && (
            <Edit
              className={classes.editIcon}
              onClick={() => handleOpenEditMovement(params.row as IPlanMovement)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.container}>
          <CustomDataGrid
            classes={{
              root: classes.root,
              columnHeader: classes.headerCell,
            }}
            rowCount={1}
            columns={columns}
            rows={planMovement.filter((d) => d.person === capitalize(type))}
            loading={false}
            localeText={{
              columnMenuShowColumns: "Mostrar Columnas",
              columnMenuHideColumn: "Ocultar Columna",
            }}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
          />
        </div>
      </Grid>
      {!["Boton de pago enviado", "Pagado", "Finalizado"].includes(contract.status) && (
        <Grid item xs={12} className={classes.buttonContainer}>
          <span className={classes.buttonAddCharge} onClick={() => handleOpenCreateMovement()}>
            <ControlPointIcon titleAccess="Agregar descuento / abono" />
            Agregar descuento / abono
          </span>
        </Grid>
      )}
      <ModalDialog
        isOpen={openDialog}
        onClose={() => handleCloseEditMovement()}
        title="Editar descuento/abono"
        subtitle="Para el primer mes">
        <PlanMovementForm
          type={type}
          movement={planToEdit}
          contract={contract}
          movements={planMovement}
          onClose={handleCloseEditMovement}
          setPlanMovement={setPlanMovement}
        />
      </ModalDialog>
      <ModalDialog
        isOpen={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        title="Agregar descuento/abono"
        subtitle="Para el primer mes">
        <PlanMovementForm
          type={type}
          contract={contract}
          movements={planMovement}
          onClose={handleCloseCreateMovement}
          setPlanMovement={setPlanMovement}
        />
      </ModalDialog>
    </>
  );
}

export default PlanMovements;
