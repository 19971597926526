import { UsersProvider } from "../context/usersContext";
import UsersTable from "./UsersTable";
import { TableProvider } from "../../../../../context/tableContext/tableContext";

export default function UsersTableProvider() {
  return (
    <UsersProvider>
      <TableProvider>
        <UsersTable />
      </TableProvider>
    </UsersProvider>
  );
}
