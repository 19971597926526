import PropertyInspectionReportsIndex from "./PropertyInspectionReportMain";
import { TableProvider } from "context/tableContext/tableContext";

function PropertyInspectionReportsMainIndex() {
  return (
    <TableProvider>
      <PropertyInspectionReportsIndex />
    </TableProvider>
  );
}

export default PropertyInspectionReportsMainIndex;
