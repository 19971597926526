export const statesMx = [
  {
    value: "AGS",
    label: "Aguascalientes",
  },
  {
    value: "BCN",
    label: "Baja California",
  },
  {
    value: "BCS",
    label: "Baja California Sur",
  },
  {
    value: "CAM",
    label: "Campeche",
  },
  {
    value: "CHS",
    label: "Chiapas",
  },
  {
    value: "CHI",
    label: "Chihuahua",
  },
  {
    value: "CDMX",
    label: "Ciudad de México",
  },
  {
    value: "COA",
    label: "Coahuila",
  },
  {
    value: "COL",
    label: "Colima",
  },
  {
    value: "DGO",
    label: "Durango",
  },
  {
    value: "EM",
    label: "Estado de México",
  },
  {
    value: "GTO",
    label: "Guanajuato",
  },
  {
    value: "GRO",
    label: "Guerrero",
  },
  {
    value: "HGO",
    label: "Hidalgo",
  },
  {
    value: "JAL",
    label: "Jalisco",
  },
  {
    value: "MICH",
    label: "Michoacán",
  },
  {
    value: "MOR",
    label: "Morelos",
  },
  {
    value: "NAY",
    label: "Nayarit",
  },
  {
    value: "NL",
    label: "Nuevo León",
  },
  {
    value: "OAX",
    label: "Oaxaca",
  },
  {
    value: "PUE",
    label: "Puebla",
  },
  {
    value: "QR",
    label: "Quintana Roo",
  },
  {
    value: "QRO",
    label: "Querétaro",
  },
  {
    value: "SLP",
    label: "San Luis Potosí",
  },
  {
    value: "SIN",
    label: "Sinaloa",
  },
  {
    value: "SON",
    label: "Sonora",
  },
  {
    value: "TAB",
    label: "Tabasco",
  },
  {
    value: "TAM",
    label: "Tamaulipas",
  },
  {
    value: "TLAX",
    label: "Tlaxcala",
  },
  {
    value: "VER",
    label: "Veracruz",
  },
  {
    value: "YUC",
    label: "Yucatán",
  },
  {
    value: "ZAC",
    label: "Zacatecas",
  },
];
