import { useState } from "react";

import { Button, Typography } from "@material-ui/core";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { useProperty } from "context/propertyContext/propertyContext";
import useStyles from "./SpaceDetailsInfoStyles";
import { getObjectLabel } from "utils";
import { getCurrentSpace } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils";
import { inspectionReportQualificationOptions } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";

function SpacesFilter() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useProperty();
  const classes = useStyles();

  const currentSpace = getCurrentSpace(
    state.inspectionReport?.data?.spaces,
    state.inspectionReport?.currentSpaceName
  );

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <Typography className={classes.label}>Valoración del espacio:</Typography>
        <Typography className={classes.text}>
          {getObjectLabel(inspectionReportQualificationOptions, currentSpace?.qualification)}
        </Typography>
      </div>
      <div className={classes.box}>
        <Typography className={classes.label}>Foto del espacio:</Typography>
        <Button className={classes.imgContainerBtn} onClick={handleOpenModal}>
          <div key={`image-${currentSpace?.photo}`} className={classes.imgContainer}>
            <img
              className={classes.img}
              src={currentSpace?.photo}
              width="100"
              alt="small-preview"
            />
          </div>
        </Button>
      </div>
      <CustomDialog title={currentSpace?.name} open={isModalOpen} onClose={handleCloseModal}>
        <div key={`image-${currentSpace?.photo}`} className={classes.modalImgContainer}>
          <img
            className={classes.modalImg}
            src={currentSpace?.photo}
            width="100"
            alt="large-preview"
          />
        </div>
      </CustomDialog>
    </div>
  );
}

export default SpacesFilter;
