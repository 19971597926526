import { Layout } from "models/Mapbox";

export const textLayout: Layout = {
  "icon-allow-overlap": false,
  "icon-ignore-placement": true,
  "icon-padding": 0,
  "icon-size": 0.4,
  "icon-text-fit": "width",
  "icon-text-fit-padding": [0, 60, 0, 60],
  "text-allow-overlap": false,
  "text-field": "{name}",
  "text-padding": 0,
  "text-size": 13.5,
};

export const coLocation = {
  lat: 4.624335,
  lng: -74.06364,
};
