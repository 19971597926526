import { SnackbarProvider } from "notistack";

import { ContractProvider } from "context/contractContext/contractContext";
import { TableProvider } from "context/tableContext/tableContext";
import { TabProvider } from "context/tabContext/tabContext";
import NewContractList from "./NewContractList";

export default function ContractList() {
  return (
    <ContractProvider>
      <TableProvider>
        <SnackbarProvider>
          <TabProvider>
            <NewContractList />
          </TabProvider>
        </SnackbarProvider>
      </TableProvider>
    </ContractProvider>
  );
}
