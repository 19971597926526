import Swal from "sweetalert2";

import PlanFormProps from "Components/Admin/administrator/plans/pages/PlansForm/types";
import { StepProps } from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { CountryUpperCase } from "models/Countries";
import PlanProps from "models/Plan";
import planService from "services/planService";

import { Action, SET_PLAN_DATA, SET_STEPS, SET_CURRENT_TAB } from "./plansTypes";
import { parsePostPlanData } from "./utils";

export const setCurrentTab = (id: number, isActive: boolean): Action => ({
  type: SET_CURRENT_TAB,
  currentTab: { id, isActive },
});

export const setPlanData = (planData: Partial<PlanProps>): Action => ({
  type: SET_PLAN_DATA,
  planData,
});

export const setSteps = (steps: StepProps[]): Action => ({
  type: SET_STEPS,
  steps,
});

export async function submitData(
  statePlan: PlanFormProps,
  country: CountryUpperCase,
  cities: number[],
  authToken: string,
  edit?: boolean
) {
  try {
    if (edit) {
      await planService.editPlan(statePlan.id, statePlan.contractTemplate, authToken);
    } else {
      await planService.postPlan(parsePostPlanData(statePlan, country, cities), authToken);
    }
    Swal.fire({
      type: "success",
      text: `El plan ha sido ${edit ? "editado" : "creado"} exitosamente`,
    }).then(() => true);
  } catch (e) {
    Swal.fire({
      title: `El plan no se ha ${edit ? "editado" : "creado"}, error: ${e}`,
      type: "error",
    });
    return false;
  }
}
