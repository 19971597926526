import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import { BACKEND_URL } from "env";
import { getToken, createHttp } from "services/helper";
import {
  ICompany,
  IUserFilters,
  ILandlordSummaryResponse,
  ILandlordTicketsResponse,
  ILandlordTicketsCountResponse,
  UserResponse,
  UserModel,
} from "models/User";
import { Paginate } from "models/ResponseWithPaginate";
import { Locale } from "models/Countries";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  getUser(token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.get("/propietary/v2/me/");
  },

  me() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      return user;
    }
    return null;
  },

  get(id: number, token: string, cancelToken = undefined): Promise<AxiosResponse<UserModel>> {
    const http = createHttp(token, ...Array(2), cancelToken);
    return http.get(`/admin/v2/users/${id}/`);
  },

  getByEmail(
    email: string,
    token: string,
    cancelToken?: string
  ): Promise<AxiosResponse<{ user: UserModel }>> {
    return createHttp(token, BACKEND_URL, "application/json", cancelToken).get(
      `/admin/v2/users/get_by_email?email=${email}`
    );
  },

  update(id: number, data, token: string) {
    return createHttp(token).put(`/admin/v2/users/${id}`, data);
  },

  getUserAggregation(id, token) {
    const http = createHttp(token);
    return http.get(`/admin/v2/user_aggregation/${id}/`);
  },

  userSuggesterRequest(
    filters: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<Paginate<UserResponse>>> {
    return createHttp(token).get(`/admin/v2/users/?${qs.stringify(filters)}`);
  },

  async getUsersBySearchQuery(
    query,
    token,
    qParams = "",
    cancelToken = undefined,
    targetSource = "users"
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers, cancelToken });
    try {
      const response = await http.get(`/admin/v2/${targetSource}/?search=${query}${qParams}`);
      return response.data;
    } catch (error) {
      return { results: [] };
    }
  },

  getUsers(
    filters: IUserFilters,
    token: string,
    cancelToken?: string
  ): Promise<AxiosResponse<UserModel[]>> {
    return createHttp(token, BACKEND_URL, "application/json", cancelToken).get(
      `/admin/v2/users/?${qs.stringify(filters)}`
    );
  },

  async getUsersList(pageSize, page, { ...filters }, token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = await axios.create({ baseURL: BACKEND_URL, headers });
    const response = await http.get(
      `admin/v2/users/?page_size=${pageSize}&page=${page}&${qs.stringify(filters)}`
    );
    return response;
  },
  async resetLandlordPassword(email, token) {
    return createHttp(token).get(`propietary/v2/landlord_login?email=${email}`);
  },
  async sendResetPasswordMail(email, token) {
    return createHttp(token).post("propietary/v2/send_reset_password_email", {
      email,
    });
  },
  async createAdminUser(data, token) {
    return createHttp(token).post("v2/create_admin_user", data);
  },

  async getCompanies(search: string, token: string) {
    if (search) return createHttp(token).get(`/v2/companies/?search=${search}`);
    return createHttp(token).get("/v2/companies/");
  },

  async postCompany(data: ICompany, token: string) {
    return createHttp(token).post("/v2/companies/", data);
  },

  getLandlordSummary(id: string, token: string): Promise<AxiosResponse<ILandlordSummaryResponse>> {
    return createHttp(token).get(`/admin/v2/landlord_summary/${id}/`);
  },
  getLandlordTicketsCount(
    id: string,
    token: string
  ): Promise<AxiosResponse<ILandlordTicketsCountResponse>> {
    return createHttp(token).get(`/admin/v2/landlord_summary/${id}/tickets/?get_count=true`);
  },
  getLandlordTickets(
    id: string,
    token: string,
    params = {}
  ): Promise<AxiosResponse<Paginate<ILandlordTicketsResponse>>> {
    return createHttp(token).get(
      `/admin/v2/landlord_summary/${id}/tickets?${qs.stringify(params, qsConfig)}`
    );
  },
  searchLandlord(
    token: string,
    params?: {
      name?: string;
      country?: Locale;
      email?: string;
      document?: string;
      property_id?: string;
      subscription_id?: string;
    }
  ): Promise<AxiosResponse<Paginate<ILandlordSummaryResponse>>> {
    return createHttp(token).get(`/admin/v2/landlord_summary/`, {
      params: {
        ...params,
      },
    });
  },
};
