import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { setView, submitTemplate } from "context/contractTemplatesContext/contractTemplatesActions";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import { ContractTemplateProps } from "context/contractTemplatesContext/contractTemplatesTypes";
import { useGetToken } from "hooks";

const useTemplate = () => {
  const {
    state: {
      newTemplate: { id, country, name },
    },
    dispatch,
  } = useContractTemplates();
  const { getToken } = useGetToken();
  const isNewTemplate = !id;
  const history = useHistory();

  const handleBack = () => {
    dispatch(setView("templates"));
    if (!isNewTemplate) {
      history.push("/admin/contract_templates");
    }
  };

  const handleSubmit = useCallback((data: Partial<ContractTemplateProps>) => {
    (async () => {
      const authToken = await getToken();
      const submitData = {
        ...data,
        country,
        id,
      } as ContractTemplateProps;
      await submitTemplate(isNewTemplate, submitData, dispatch, authToken);
      handleBack();
    })();
  }, []);

  return { country, isNewTemplate, name, handleSubmit, handleBack };
};

export default useTemplate;
