import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { alert } from "@houm-com/ui/Alert";

import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface Props {
  evaluationMemberId: string;
  onHandleSuccess?: () => void;
}

const useDeleteMember = ({ evaluationMemberId, onHandleSuccess }: Props) => {
  const { country } = useParams<{ country: Locale }>();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async () => {
    const authToken = await getToken();
    await service.deleteMember(evaluationMemberId, country, authToken);
  });

  useEffect(() => {
    if (query.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      onHandleSuccess?.();
      alert.success({
        message: "El postulante ha sido eliminado correctamente",
      });
    }
  }, [query.isSuccess, queryClient]);

  useEffect(() => {
    if (query.isError) {
      alert.error({
        message: "Error al intentar eliminar postulante",
      });
    }
  }, [query.isError]);

  return query;
};

export default useDeleteMember;
