import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { alert } from "@houm-com/ui/Alert";

import service from "domains/evaluations/service";
import useUser from "domains/user/hooks/useUser";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface FormData {
  comment: string;
  commentType: "simple_comment" | "document_request";
}

interface Props {
  evaluationMemberId: string;
  onHandleSuccess?: () => void;
}

const useCreateComment = ({ evaluationMemberId, onHandleSuccess }: Props) => {
  const { country } = useParams<{ country: Locale }>();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async (formData: FormData) => {
    const authToken = await getToken();
    const parsedData = {
      comment: formData.comment,
      commenter_id: user.id,
      comment_type: formData.commentType,
    };
    await service.createComment(evaluationMemberId, parsedData, country, authToken);
  });

  useEffect(() => {
    if (query.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      onHandleSuccess?.();
      alert.success({
        message: "El comentario ha sido enviado correctamente",
      });
    }
  }, [query.isSuccess, queryClient]);

  useEffect(() => {
    if (query.isError) {
      alert.error({
        message: "Error al enviar el comentario",
      });
    }
  }, [query.isError]);

  return query;
};

export default useCreateComment;
