import * as yup from "yup";

export const requiredText = "Obligatorio";

export default yup
  .object({
    name: yup.string().required(requiredText).typeError(requiredText),
    rooms: yup.mixed().when("roomsOther", {
      is: (other) => !other,
      then: yup.number().required(requiredText).typeError(requiredText),
    }),
    roomsOther: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    bathrooms: yup.mixed().when("bathroomsOther", {
      is: (other) => !other,
      then: yup.number().required(requiredText).typeError(requiredText),
    }),
    bathroomsOther: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    totalArea: yup.number().required(requiredText).typeError(requiredText),
    usableArea: yup.number().required(requiredText).typeError(requiredText),
    image: yup.mixed(),
  })
  .test("imageTest", null, (obj) => {
    if (obj.image?.length > 0) {
      return true;
    }

    return new yup.ValidationError(
      "Recuerda subir una imagen del plano de tu tipología",
      "image",
      "required"
    );
  });
