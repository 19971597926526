import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  linkContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  arrowIcon: {
    fontSize: theme.typography.pxToRem(25),
  },
  backButton: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
  },
  evaluationTitle: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: "bold",
  },
  chip: {
    marginLeft: 20,
    marginTop: "auto",
    marginBottom: "auto",
    backgroundColor: "orange",
    borderColor: "orange",
  },
}));
