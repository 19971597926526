import {
  InspectionReportGeneralSpaceUpdateDataForm,
  InspectionReportUpdateData,
} from "services/propertyService/types";

export const parseInspectionReportGeneralSpaceUpdateDataForm = (
  spaceId: number,
  data: InspectionReportGeneralSpaceUpdateDataForm
): InspectionReportUpdateData => ({
  spaces: [
    {
      id: spaceId,
      overall_qualification: data.overall_qualification,
      overall_observation: data.overall_observation,
    },
  ],
});
