import { Route, Switch } from "react-router-dom";
import React from "react";
import Unauthorized from "domains/auth/components/Unauthorized";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { IoGridOutline as DashboardIcon } from "@houm-com/ui-icons";
import Dashboard from "Components/Admin/administrator/dashboard";
import buyersRouterItems from "apps/buyers-admin/router";
import tenantRouterItems from "apps/tenant-admin/router";
import controlTowerItems from "apps/control-tower/router";
import evaluationsItems from "apps/evaluator-admin/router";
import clientsItems from "apps/clients-admin/router";
import closingItems from "apps/closing-admin/router";
//import SupplyItems from "apps/supply-admin/router";
import realEstateProjectsItems from "apps/real-estate-projects-admin/router";
import brokerageItems from "apps/brokerage-admin/router";
import integrationsItems from "apps/integrations-admin/router";
import appsItems from "apps/apps-admin/router";
import usersItems from "apps/users-admin/router";
import houmersAdminItems from "apps/houmers-admin/router";
import houmersItems from "apps/houmers/router";
import organizationsItems from "apps/organizations/router";
import { routeBuilder } from "domains/auth/utils/router-builder";
//import NotFound from "./components/NotFound";

export const items = [
  routeBuilder({
    label: "Dashboard",
    to: "/",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  }),
  buyersRouterItems,
  tenantRouterItems,
  controlTowerItems,
  evaluationsItems,
  clientsItems,
  closingItems,
  //supplyItems,
  realEstateProjectsItems,
  brokerageItems,
  integrationsItems,
  appsItems,
  usersItems,
  houmersAdminItems,
  houmersItems,
  organizationsItems,
  // FIXME: * route is not working when using switch
  // will try to add a not found component when migrating
  // react router 6.0
  // {
  //   path: "*",
  //   component: NotFound,
  // },
];

export default function Router() {
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.path && (
            <Route path={item.path} exact>
              <item.component />
            </Route>
          )}
          <Switch>
            {item.children?.map(({ path, component: Component, ...rest }) => (
              <Route
                key={path}
                path={path}
                exact
                render={() => {
                  if ("require" in rest) {
                    return (
                      <ProtectedComponent {...rest} fallback={<Unauthorized />}>
                        <Component />
                      </ProtectedComponent>
                    );
                  }
                  return <Component />;
                }}
              />
            ))}
          </Switch>
        </React.Fragment>
      ))}
    </>
  );
}
