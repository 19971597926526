import {
  RedTag,
  YellowTag,
  GreenTag,
  LightGreenTag,
  OrangeTag,
  BlueTag,
  LightOrangeTag,
  WhiteTag,
  BlackWhiteTag,
  LightBlueTag,
} from "./Tags";
import propertyStatusTranslator from "utils/statusTranslator";
import { PropertyData } from "models/Property";

// available property statuses
const Statuses = (props = {}) => ({
  incomplete: {
    id: 1,
    name: propertyStatusTranslator("incomplete"),
    tag: <BlackWhiteTag content={propertyStatusTranslator("incomplete")} {...props} />,
  },
  photo_scheduled: {
    id: 2,
    name: propertyStatusTranslator("photo_scheduled"),
    tag: <WhiteTag content={propertyStatusTranslator("photo_scheduled")} {...props} />,
  },
  photo_cancelled: {
    id: 3,
    name: propertyStatusTranslator("photo_cancelled"),
    tag: <LightOrangeTag content={propertyStatusTranslator("photo_cancelled")} {...props} />,
  },
  photo_done: {
    id: 4,
    name: propertyStatusTranslator("photo_done"),
    tag: <LightBlueTag content={propertyStatusTranslator("photo_done")} {...props} />,
  },
  complete: {
    id: 5,
    name: propertyStatusTranslator("complete"),
    tag: <BlueTag content={propertyStatusTranslator("complete")} {...props} />,
  },
  published: {
    id: 6,
    name: propertyStatusTranslator("published"),
    tag: <LightGreenTag content={propertyStatusTranslator("published")} {...props} />,
  },
  reserved: {
    id: 7,
    name: propertyStatusTranslator("reserved"),
    tag: <LightGreenTag content={propertyStatusTranslator("reserved")} {...props} />,
  },
  rented: {
    id: 8,
    name: propertyStatusTranslator("rented"),
    tag: <GreenTag content={propertyStatusTranslator("rented")} {...props} />,
  },
  unpublished: {
    id: 9,
    name: propertyStatusTranslator("unpublished"),
    tag: <OrangeTag content={propertyStatusTranslator("unpublished")} {...props} />,
  },
  sold: {
    id: 10,
    name: propertyStatusTranslator("sold"),
    tag: <GreenTag content={propertyStatusTranslator("sold")} {...props} />,
  },
  lost: {
    id: 11,
    name: propertyStatusTranslator("lost"),
    tag: <RedTag content={propertyStatusTranslator("lost")} {...props} />,
  },
  republished: {
    id: 12,
    name: propertyStatusTranslator("republished"),
    tag: <LightGreenTag content={propertyStatusTranslator("republished")} {...props} />,
  },
  unknown: {
    id: 13,
    name: propertyStatusTranslator("unknown"),
    tag: <YellowTag content={propertyStatusTranslator("unknown")} {...props} />,
  },
  archived: {
    id: 14,
    name: propertyStatusTranslator("archived"),
    tag: <WhiteTag content={propertyStatusTranslator("archived")} {...props} />,
  },
  out_of_coverage: {
    id: 15,
    name: propertyStatusTranslator("out_of_coverage"),
    tag: <LightOrangeTag content={propertyStatusTranslator("out_of_coverage")} {...props} />,
  },
  unpublished_by_import: {
    id: 16,
    name: propertyStatusTranslator("unpublished_by_import"),
    tag: <OrangeTag content={propertyStatusTranslator("unpublished_by_import")} {...props} />,
  },
  negotiation: {
    id: 17,
    name: propertyStatusTranslator("negotiation"),
    tag: <LightGreenTag content={propertyStatusTranslator("negotiation")} {...props} />,
  },
});

interface Props {
  propData: PropertyData;
  category: "forRent" | "forSale";
  tagStyles?: string;
}

const PropertyStatusTag = ({ propData, category, tagStyles = null }: Props) => {
  try {
    if (category === "forSale") {
      return Statuses(tagStyles)[propData.sales_details.status].tag;
    }
    return Statuses(tagStyles)[propData.property_details[0].status].tag;
  } catch (e) {
    if (category === "forSale") {
      console.error(propData.sales_details.status);
    }
    if (category === "forRent") {
      console.error(propData.property_details[0].status);
    }
    return Statuses(tagStyles).unknown.tag;
  }
};

export { Statuses, PropertyStatusTag };
