import { makeStyles, Theme } from "@material-ui/core";

const useReportDownloadStyles = makeStyles<Theme>((theme) => ({
  link: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    textDecoration: "underline",
    fontWeight: 700,
    cursor: "pointer",
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
    fill: theme.palette.grey[900],
    stroke: theme.palette.grey[900],
    strokeWidth: 0.8,
    height: 18,
  },
}));

export default useReportDownloadStyles;
