import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import es from "date-fns/locale/es";
import { capitalize } from "lodash";

const getWeekDays = () => {
  const today = new Date();
  const week = eachDayOfInterval({
    start: startOfWeek(today, { weekStartsOn: 1 }),
    end: endOfWeek(today, { weekStartsOn: 1 }),
  });
  const weekDays = week.map((day) => capitalize(format(day, "EEEE", { locale: es })));
  return weekDays;
};

export default getWeekDays;
