import React from "react";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useProject } from "context/projectContext/projectContext";
import FormBlock from "./FormBlock";

function ProjectName() {
  const { state } = useProject();

  return (
    <FormBlock>
      <ControlledTextField
        id="association_name"
        label="Nombre de proyecto"
        defaultValue={state.project?.property?.association_amenities?.association_name ?? ""}
      />
    </FormBlock>
  );
}

export default React.memo(ProjectName);
