import { makeStyles, Theme } from "@material-ui/core";

const useRenewalCustomSelectStyles = makeStyles<Theme>((theme) => ({
  inputContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noBorder: {
    border: `0.5px solid ${theme.palette.grey[500]}`,
    borderRadius: 8,
  },
  customTextField: {
    fontSize: "1rem",
    padding: theme.spacing(1),
  },
  formSelectItem: {
    paddingRight: theme.spacing(4),
  },
  selectTitle: {
    paddingTop: theme.spacing(1),
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: theme.spacing(0),
  },
}));

export default useRenewalCustomSelectStyles;
