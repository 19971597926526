import { makeStyles, Theme } from "@material-ui/core";

const useEditButtonStyles = makeStyles<Theme>((theme) => ({
  editButton: {
    textTransform: "none",
    padding: theme.spacing(0, 0.5),
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  editText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: 1.66,
    color: theme.palette.primary.main,
  },
  icon: {
    width: 11,
    "& path": {
      stroke: theme.palette.primary.main,
    },
  },
}));

export default useEditButtonStyles;
