import { Divider } from "@material-ui/core";
import { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";

import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import useStyles from "./PersonFormStyles";
import { useContractPerson } from "context/contractPersonContext/contractPersonContext";
import { setPerson } from "context/contractPersonContext/contractPersonActions";

export default function DocumentsInput() {
  const { state, dispatch } = useContractPerson();
  const { control } = useFormContext();

  const type = useWatch({
    control,
    name: "documentType",
    defaultValue: state.person.documentType?.id || "",
  });

  useEffect(() => {
    dispatch(
      setPerson({
        ...state.person,
        documentType: state.documentTypes.find((doc) => doc.id === type),
      })
    );
  }, [type]);

  const docTypesOptions = state.documentTypes
    .filter((doc) => doc.type !== "RFC")
    .map((doc) => ({
      value: doc.id,
      label: doc.type,
    }));

  const docTypeDisabled = !!state.documentTypes.find(
    (doc) => doc.id === state.person.documentType?.id
  );

  const documentDisabled =
    !!state.person.document && !!state.person.document.match(state.person.documentType?.regex);

  const rfcDisabled =
    !!state.person.rfc && !!state.documentTypes.find((doc) => doc.type === "RFC").regex;

  const classes = useStyles({ disabled: docTypeDisabled });
  if (["Mexico", "México", "Colombia"].includes(state.country)) {
    return (
      <>
        <div className={classes.documentTypeWrapper}>
          <ControlledSelection
            id="documentType"
            label="Tipo de documento"
            defaultValue={state.person.documentType?.id || ""}
            options={docTypesOptions}
            compressed={true}
            simple={true}
            classes={{
              label: classes.documentTypeLabel,
            }}
            disabled={docTypeDisabled}
          />
        </div>
        <ControlledTextField
          id="document"
          label="Número de documento"
          textFieldProps={{
            placeholder: "Número de documento",
          }}
          defaultValue={state.person.document}
          disabled={documentDisabled}
        />
        {state.country !== "Colombia" && (
          <>
            <Divider />
            <ControlledTextField
              id="rfc"
              label="RFC"
              textFieldProps={{
                placeholder: "RFC",
              }}
              defaultValue={state.person.rfc || ""}
              disabled={rfcDisabled}
            />
          </>
        )}
      </>
    );
  }
  return (
    <ControlledTextField
      id="document"
      label="Número de documento"
      textFieldProps={{
        placeholder: "Número de documento",
      }}
      defaultValue={state.person.document}
      disabled={documentDisabled}
    />
  );
}
