/* eslint-disable react/prop-types */
import * as React from "react";

import { Dialog, Typography, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";
import useStyles from "./CustomDialogStyles";
import classNames from "classnames";

interface CustomDialogProp {
  children?: React.ReactNode;
  classes?: {
    title?: string;
    subtitle?: string;
    titleContainer?: string;
    modalContainer?: string;
    content?: string;
  };
  dialogProps?: Partial<React.ComponentProps<typeof Dialog>>;
  onClose: () => void;
  open: boolean;
  showCloseButton?: boolean;
  subtitle?: React.ReactNode;
  minWidth?: string | number;
  title?: string;
  titleIcon?: React.ReactNode;
}

const CustomDialog: React.FC<CustomDialogProp> = ({
  children,
  classes: paramClasses,
  dialogProps = {},
  onClose,
  open,
  showCloseButton = true,
  subtitle,
  title,
  titleIcon,
  minWidth,
}) => {
  const classes = useStyles({ minWidth });

  return (
    <Dialog
      open={open}
      classes={{
        paperWidthSm: classes.paperWidth,
      }}
      PaperProps={{
        className: classNames(classes.modalContainer, paramClasses?.modalContainer),
      }}
      {...dialogProps}>
      {showCloseButton && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <GridCloseIcon />
        </IconButton>
      )}
      <DialogTitle
        disableTypography
        className={classNames(classes.dialogTitleBar, paramClasses?.titleContainer)}>
        <Typography
          variant="h2"
          component="h2"
          className={classNames(classes.dialogTitle, paramClasses?.title)}>
          {titleIcon}
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h6"
            component="h3"
            className={classNames(classes.dialogSubtitle, paramClasses?.subtitle)}>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent
        classes={{
          root: classNames(classes.dialogContentRoot, paramClasses?.content),
        }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
