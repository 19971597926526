import { SnackbarProvider } from "notistack";
import NewInternalUser from "./NewInternalUser";

export default function UsersLeadsController() {
  return (
    <SnackbarProvider>
      <NewInternalUser />
    </SnackbarProvider>
  );
}
