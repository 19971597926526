import { CountryUpperCase } from "models/Countries";
import { ME_HOUM_COM_URL } from "env";

import countryCode from "./countryCode";

const parsePaymentLink = (link: string, movementId: string, country: CountryUpperCase): string => {
  if (country === "Chile" || country === "Colombia") {
    return `${ME_HOUM_COM_URL}/reservation/${countryCode(country)}/pay/${movementId}`;
  }
  return link;
};

export default parsePaymentLink;
