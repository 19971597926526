export const getStatusData = (state: string) => {
  switch (state) {
    case "UNPAID":
      return {
        statusLabel: "Pendiente",
        actionLabel: "marcar como pendiente",
        color: "#2C73DB",
      };
    case "PENDING":
      return { statusLabel: "Pendiente de confirmación", color: "#2C73DB" };
    case "PAID":
      return {
        statusLabel: "Pagado",
        actionLabel: "marcar como pagado",
        color: "#48AA02",
      };
    case "INSURANCE_MANAGEMENT":
      return {
        statusLabel: "Gestión Ext.",
        actionLabel: "marcar en gestión externa",
        color: "#B0BEC5",
      };
    case "FROZEN":
      return {
        statusLabel: "Congelado",
        actionLabel: "congelar",
        color: "orange",
      };
    case "LATE":
      return { statusLabel: "Atrasado", color: "orange" };
    case "REFUNDED":
      return { statusLabel: "Reembolsado", color: "orange" };
    case "CANCELED":
      return {
        statusLabel: "Anulado",
        actionLabel: "cancelar",
        color: "#AE0400",
      };
    case "IN_PROGRESS":
      return {
        statusLabel: "En progreso",
        color: "#EFF2F3",
      };
    case "UNSCHEDULED":
      return {
        statusLabel: "No programado",
        color: "#B0BEC5",
      };
    case "LOST":
      return {
        statusLabel: "Perdido",
        actionLabel: "marcar como Perdido",
        color: "#2C73DB",
      };
    default:
      return { statusLabel: state, color: "geekblue" };
  }
};
