import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const RootStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0),
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(3),
}));

export const TableContainerStyled = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const ScheduleContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));
