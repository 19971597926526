import { makeStyles, Theme } from "@material-ui/core";

const useRecommendPriceChartStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  img: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    maxHeight: "160px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    display: "column",
    justifyContent: "flex-start",
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8125rem",
    },
  },
  content: {
    fontWeight: 700,
    fontSize: "1.125rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  },
}));
export default useRecommendPriceChartStyles;
