import { FormProvider, SubmitHandler } from "react-hook-form";

import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldSelect from "@houm-com/ui/FieldSelect";
import { InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import ControlledNumber from "Components/Admin/UIComponents/ControlledForm/ControlledNumber";
import useReactHookForm from "hooks/useReactHookForm";
import { MovementType } from "models/Payments";
import { Locale } from "models/Countries";

import { markAsPaidSchema } from "../../utils/validationSchema";
import useTransferOptions from "../../hooks/useTransferOptions";
import { MarkAsPaidFormProps } from "../../utils/types";
import useMarkAsPaid from "../../hooks/useMarkAsPaid";

interface Props {
  movementId: string;
  country: Locale;
  totalAmount: number;
  handleOnClose: () => void;
  movementType: MovementType;
}

function MarkAsPaidForm({ movementId, country, totalAmount, handleOnClose, movementType }: Props) {
  const { transferOptions } = useTransferOptions({ country, movementType });
  const { mutate, isLoading } = useMarkAsPaid(country, movementId, handleOnClose);
  const { methods, errors } = useReactHookForm<MarkAsPaidFormProps>({
    formSchema: markAsPaidSchema,
  });

  const onSubmit: SubmitHandler<MarkAsPaidFormProps> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <ModalDialog.Content classNameContent="p-0.5">
          <Grid item container alignItems="flex-start">
            <Grid item xs={12}>
              <ControlledKeyboardDatePicker
                id="paymentDate"
                label="Fecha de pago"
                defaultValue={new Date()}
                dataPickerProps={{ format: "dd/MM/yyyy", maxDate: new Date() }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledNumber
                label="Monto"
                id="amount"
                defaultValue={totalAmount ?? ""}
                textFieldProps={{
                  InputProps: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                  type: "number",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldSelect
                label="Tipo de pago"
                id="method"
                required
                options={transferOptions}
                form="novalidateform"
                placeholder="Selecciona un tipo de pago"
                {...methods.register("method")}
                errorMessage={errors.method?.message}
              />
            </Grid>
            {/* <Grid item xs={12} >
            <ControlledFileUpload
              allowedMimeTypes={["png", "jpeg", "jpg", "pdf"]}
              id="comprobant"
              label="Comprobante"
              buttonClassName={classes.fileButton}
              containerFileClasses={classes.fileContainer}
              text="+"
              deleteFile={true}
            />
          </Grid> */}
          </Grid>
        </ModalDialog.Content>
        <ModalDialog.Actions
          buttonsPosition="center"
          submitButtonProps={{
            isLoading,
          }}
          submitButtonLabel="Guardar"
          onSubmit={methods.handleSubmit(onSubmit)}
        />
      </form>
    </FormProvider>
  );
}

export default MarkAsPaidForm;
