import { Box, Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import useCircularStepperStyles from "./CircularStepperStyles";

interface Props {
  step: number;
  totalSteps: number;
  hideStepText?: boolean;
}

export default function CircularStepper({ step, totalSteps, hideStepText }: Props) {
  const classes = useCircularStepperStyles();
  return (
    <Grid item direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item className={classes.containerProgress}>
        <Typography component="h3" className={classes.steps}>
          <strong>{step + 1}</strong>/{totalSteps}
        </Typography>
        <Box className={classes.root}>
          <CircularProgress variant="determinate" className={classes.bottom} value={100} />
          <CircularProgress
            variant="determinate"
            className={classes.top}
            value={((totalSteps - (step + 1)) / totalSteps) * 100}
          />
        </Box>
      </Grid>
      <Grid item xs={8} className={classes.containerSteps}>
        {!hideStepText && (
          <Typography component="h3" className={classes.stepText}>
            Paso {step + 1}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
