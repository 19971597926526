import { Box, Grid, IconButton, Tooltip, TooltipProps, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import ObservationModal from "../ObservationModal";

import { useObservationStyles, useTooltipStyles } from "./ObservationStyles";
import useObservationModal from "./hooks/useObservationModal";

const EditIconToolTip = (props: TooltipProps) => {
  const classes = useTooltipStyles();
  return <Tooltip arrow classes={classes} {...props} />;
};

interface Props {
  observation: string;
  subscriptionId: number;
}

const Observation = ({ observation, subscriptionId }: Props) => {
  const classes = useObservationStyles();
  const { observationModal, onHandleModal } = useObservationModal();

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.observationContainer}>
          <EditIconToolTip title="Editar observación">
            <IconButton className={classes.closeButton} onClick={() => onHandleModal(true)}>
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </EditIconToolTip>
          <Box className={classes.observationContainer}>
            <Typography className={classes.label}>Observación:</Typography>
            <Typography className={classes.observationText}>{observation}</Typography>
          </Box>
        </Box>
      </Grid>
      <ObservationModal
        subscriptionId={subscriptionId}
        open={observationModal}
        onHandleClose={() => onHandleModal(false)}
        observation={observation}
      />
    </>
  );
};

export default Observation;
