import { es } from "date-fns/locale";
import { format } from "date-fns";

export type ErrorType =
  | "SCHEDULE_CREATION_FAILURE_ALREADY_SCHEDULED_VISIT"
  | "SCHEDULE_CREATION_FAILURE_SUBSCRIPTION_DOES_NOT_EXIT"
  | "SCHEDULE_CREATION_FAILURE_EXIT_PROCESS_HAS_NOT_STARTED"
  | "UNHANDLED_ERROR"
  | "DEFAULT";

export const getTypeError = (typeError: string = "DEFAULT") => {
  switch (typeError) {
    case "SCHEDULE_CREATION_FAILURE_ALREADY_SCHEDULED_VISIT":
      return "info";
    case "SCHEDULE_CREATION_FAILURE_SUBSCRIPTION_DOES_NOT_EXIT":
    case "SCHEDULE_CREATION_FAILURE_EXIT_PROCESS_HAS_NOT_STARTED":
    case "UNHANDLED_ERROR":
    case "DEFAULT":
    default:
      return "error";
  }
};

export const parseDate = (date: string) =>
  format(new Date(`${date}T00:00:00`), "cccc d 'de' MMMM 'del' yyyy", {
    locale: es,
  });
