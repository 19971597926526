/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from "moment";

import { PropertyRentalStatus, PropertySaleStatus } from "../../../../../models/Property";
import { SET_LOADING, SET_PROPERTIES, Dispatch } from "./propertiesTypes";
import propertyService from "../../../../../services/propertyService";
import statusTranslator from "../../../../../utils/statusTranslator";

export const getProperties = async (
  pageSize,
  currentPage,
  mappedFilters,
  dispatch: Dispatch,
  token: string
) => {
  dispatch({ type: SET_LOADING, loading: true });

  try {
    const response = await propertyService.adminIndex(pageSize, currentPage, mappedFilters, token);
    if (response) {
      const { results, count } = response.data;
      const properties = results.map((p) => {
        const result = {
          id: p.id,
          status: (mappedFilters.for_sale
            ? p.sales_details?.status
            : p.property_details[0]?.status) as PropertyRentalStatus | PropertySaleStatus,
          houmOwner: `${p?.houm_owner?.name} ${p?.houm_owner?.last_name}`,
          homeChecker: p.homechecker?.email,
          landlordEmail: p.user?.email,
          link: `/admin/properties/${p.uid}`,
          published: null,
          translatedStatus: null,
          lastUpdate: null,
        };

        result.translatedStatus = statusTranslator(result.status);

        if (mappedFilters.for_sale) {
          result.published = result.status === "published" ? "Publicada" : "No publicada";
          result.lastUpdate = moment(p.sales_details?.last_status_change).format("YYYY-MM-DD");
        } else {
          result.published =
            result.status === "published" || p.rentalStatus === "republished"
              ? "Publicada"
              : "No publicada";
          result.lastUpdate = moment(p.property_details[0].last_status_change).format("YYYY-MM-DD");
        }
        return result;
      });

      dispatch({ type: SET_PROPERTIES, payload: { properties, count } });
    }
  } catch (e) {
    dispatch({ type: SET_LOADING, loading: false });
    throw e;
  }
};
