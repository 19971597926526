import { useCallback } from "react";
import { useParams } from "react-router-dom";

import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import { EvaluationProvider } from "context/evaluationContext/evaluationContext";
import { clearAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import { TableLayout } from "Components/Admin/UIComponents";

import IndexSingleEvaluationMx from "./mx/IndexSingleEvaluationMx";
import IndexSingleEvaluation from "./cl/IndexSingleEvaluation";

export default function SingleEvaluation() {
  const params = useParams<{ country: string }>();

  const {
    state: { alertInfo },
    dispatch,
  } = useAlert();

  const handleSnackbarClose = useCallback(() => {
    dispatch(clearAlertInfo());
  }, []);

  const renderEvaluation = () => {
    if (params.country === "cl") {
      return <IndexSingleEvaluation />;
    } else {
      return <IndexSingleEvaluationMx />;
    }
  };

  return (
    <EvaluationProvider>
      <TableLayout>{renderEvaluation()}</TableLayout>
      <DesignSystemSnackbar
        type={alertInfo.type}
        open={alertInfo.open}
        onClose={handleSnackbarClose}
        head={alertInfo.head}
        body={alertInfo.body}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </EvaluationProvider>
  );
}
