import { Card, Typography } from "@material-ui/core";

import { useIntegration } from "context/integrationContext/integrationContext";

import useStyles from "./TotalQuotaInfoStyles";

const unlimitedQuota = 1000000;

function TotalQuotaInfo() {
  const {
    state: {
      totalQuota: { data: quotas },
    },
  } = useIntegration();

  const classes = useStyles();

  if (!quotas || quotas.length === 0) return null;

  const { quota: totalQuota, publishedStock } = quotas[0];

  return (
    <Card className={classes.root}>
      <Typography variant="body1">
        Cupos totales:{" "}
        <strong>{totalQuota >= unlimitedQuota ? "Ilimitados" : `${totalQuota || 0} cupos`}</strong>
      </Typography>
      <Typography variant="body1">
        Cupos asignados: <strong>{`${publishedStock || 0} cupos`}</strong>
      </Typography>
    </Card>
  );
}

export default TotalQuotaInfo;
