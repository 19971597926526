import { ComponentProps } from "react";

import VisitCard from "../VisitCard";

interface Props {
  checked: boolean;
  currentStep: string;
  houmBoxNumber: string;
}

const houmBoxFields = ({
  checked,
  currentStep,
  houmBoxNumber,
}: Props): ComponentProps<typeof VisitCard>["items"] => {
  if (!checked) return null;
  return [
    {
      title: "Número de candado",
      editable: currentStep === "editable",
      content: houmBoxNumber,
      position: "vertical",
      formKey: "houm_box_number",
    },
  ];
};

export default houmBoxFields;
