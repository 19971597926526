import { format } from "date-fns";

import { Typography, Grid } from "@material-ui/core";

import { IContractExitProcess } from "models/Contract";

import {
  parseExitProcessRequestedByUser,
  parseExitProcessType,
  parseExitProcessStatus,
} from "../../utils/utils";

interface Props {
  exitProcess: IContractExitProcess;
}

function ExitProcessInfo({ exitProcess }: Props) {
  const estimatedDate = exitProcess.estimated_date
    ? format(new Date(`${exitProcess.estimated_date}T00:00:00`), "dd-MM-yyyy")
    : "-";
  const processStatus = parseExitProcessStatus(exitProcess.status);
  const comment = exitProcess.comment ?? "Sin comentarios";
  const requestedByUser = exitProcess.requested_by_user
    ? parseExitProcessRequestedByUser(exitProcess.requested_by_user)
    : "-";
  const type = exitProcess.type ? parseExitProcessType(exitProcess.type) : "-";
  const agreedDate = exitProcess.agreed_date
    ? format(new Date(`${exitProcess.agreed_date}T00:00:00`), "dd-MM-yyyy")
    : "-";

  return (
    <Grid container item direction="column">
      <Typography>{`Tipo: ${type}`}</Typography>
      <Typography>{`Estado: ${processStatus}`}</Typography>
      <Typography>{`Solicitado por: ${requestedByUser}`}</Typography>
      <Typography>{`Fecha Estimada: ${estimatedDate}`}</Typography>
      <Typography>{`Comentarios: ${comment}`}</Typography>
      <Typography>{`Fecha Acordada: ${agreedDate}`}</Typography>
    </Grid>
  );
}

export default ExitProcessInfo;
