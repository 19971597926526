import { useEffect, useMemo, useReducer, useState } from "react";
import { ButtonBase, CircularProgress, Grid, Typography } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";

import useStyles from "./ScheduleGeneratorStyle";
import { scheduleOptions, initialState } from "./LeadConsts";
import CheckboxWithLabel from "Components/Admin/UIComponents/atoms/CheckboxWithLabel";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import DailySchedule from "./DailySchedule";
import { AvailabilityForm } from "context/availabilityContext/availabilityTypes";
import { getTimezone } from "utils";
import { parseStringToDay } from "utils/datetime";
import { useGetToken } from "hooks";
import {
  checkMaximumSelectedAvailabilities,
  updatePropertySchedule,
} from "context/availabilityContext/availabilityActions";
import { useLead } from "context/leadContext/leadContext";

const reducer = (state, action) => {
  switch (action.type) {
    case "allDays":
      return {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      };
    case "modifyDay":
      return { ...state, [action.key]: action.value };
    case "noneDays":
      return initialState;
    default:
      return state;
  }
};

function ScheduleGenerator() {
  const classes = useStyles();
  const [scheduleDays, dispatch] = useReducer(reducer, initialState);
  const { state, dispatch: availabilityDispatch } = useAvailability();
  const { state: leadState } = useLead();
  const { getToken } = useGetToken();
  const methods = useForm<AvailabilityForm>();

  const [allDays, setAllDays] = useState<boolean>(false);
  useEffect(() => {
    Object.keys(initialState).forEach((elem, i) => {
      const daySchedule = state.propertyAvailability.filter((item) => item.day === i + 1);

      if (daySchedule.length > 0) setDay(elem, true);
    });
  }, [state.propertyAvailability, state.loadingData]);

  const setDay = (key: string, value: boolean) => {
    dispatch({ type: "modifyDay", key, value });
  };

  useEffect(() => {
    if (allDays) dispatch({ type: "allDays" });
    else dispatch({ type: "noneDays" });
  }, [allDays]);

  const checkEqualTimezones = useMemo(() => {
    state.multiPropertyId.forEach((id) => {
      const property = leadState.leadProperties.find((prop) => parseInt(prop.id) === id);
      if (
        getTimezone(property?.country, property?.location) !==
        getTimezone(state?.propertyInfo?.country, state?.propertyInfo?.location)
      )
        return false;
    });
    return true;
  }, [leadState?.leadProperties, state?.propertyInfo, state.multiPropertyId]);

  const onSubmit: SubmitHandler<AvailabilityForm> = (data, e) => {
    if (checkEqualTimezones) {
      const activeDays = Object.entries(scheduleDays)
        .filter((item) => item[1])
        .map((elem) => `${Object.keys(scheduleDays).indexOf(elem[0]) + 1}`);

      const updateData = Object.entries(data)
        .filter((day) => day[1])
        .filter((item) => activeDays.includes(item[0][0]))
        .map((elem) =>
          parseStringToDay(
            elem,
            getTimezone(state?.propertyInfo?.country, state?.propertyInfo?.location)
          )
        );

      if (!checkMaximumSelectedAvailabilities(updateData)) return;

      getToken().then((authToken) => {
        updatePropertySchedule(state.multiPropertyId, availabilityDispatch, updateData, authToken);
      });
    } else {
      Swal.fire({
        title: "Error al seleccionar propiedades",
        text: "Las propiedades seleccionadas no comparten la misma zona horaria",
        type: "error",
      });
    }
  };

  return (
    <FormProvider {...methods} key="AvailabilityProvider">
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography component="h3" className={classes.title}>
          Selecciona un día y/o hora para buscar una fecha específica.
        </Typography>
        <Typography component="h3" className={classes.subTitle}>
          Selecciona los días que buscas
        </Typography>
        <Grid container justifyContent="flex-start" className={classes.checkboxContainer}>
          <Grid item>
            <CheckboxWithLabel
              label="Todos"
              value={allDays}
              placement="end"
              size="small"
              justifyContent="flex-start"
              checkboxProps={{ classes: { root: classes.checkbox } }}
              labelProps={classes.checkboxLabel}
              onChange={() => setAllDays((prev) => !prev)}
            />
          </Grid>
          {scheduleOptions &&
            scheduleOptions.map((item) => (
              <Grid item key={item.label}>
                <CheckboxWithLabel
                  label={item.label}
                  value={scheduleDays[item.value]}
                  placement="end"
                  size="small"
                  justifyContent="flex-start"
                  checkboxProps={{ classes: { root: classes.checkbox } }}
                  labelProps={classes.checkboxLabel}
                  onChange={() => setDay(item.value, !scheduleDays[item.value])}
                />
              </Grid>
            ))}
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <ButtonBase className={classes.cancelButton} type="submit">
              {state.loadingSchedule ? <CircularProgress color="primary" size={20} /> : "Guardar"}
            </ButtonBase>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" alignItems="flex-end">
          {Object.entries(scheduleDays)
            .filter((item) => item[1])
            .map((elem) => (
              <DailySchedule
                day={`${elem[0]}`}
                index={Object.keys(scheduleDays).indexOf(elem[0]) + 1}
                key={elem[0]}
              />
            ))}
        </Grid>
      </form>
    </FormProvider>
  );
}

export default ScheduleGenerator;
