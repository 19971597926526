import { Grid } from "@material-ui/core";
import React from "react";

import useStyles from "./FormContainerStyles";

interface Props extends Partial<React.ComponentProps<typeof Grid>> {
  children: React.ReactNode;
}

function FormContainer({ children, ...props }: Props) {
  const classes = useStyles();
  return (
    <Grid className={classes.rootGridForm} container direction="column" spacing={2} {...props}>
      {children}
    </Grid>
  );
}

export default FormContainer;
