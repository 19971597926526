import { makeStyles, Theme } from "@material-ui/core";

const useUnansweredQuestionStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: "#F8FAFB",
    padding: theme.spacing(2),
    borderRadius: 16,
    border: "1px solid #ECF2F4",
  },
  questionHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    color: "#515B60",
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    color: "#515B60",
  },
  question: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
  },
  author: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    color: "#515B60",
  },
  textField: {
    backgroundColor: "#EFF2F3",
    width: "100%",
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    marginTop: theme.spacing(3),
  },
  textAreaInput: {
    fontSize: `${theme.typography.pxToRem(14)}`,
    lineHeight: "22px",
    color: theme.palette.grey[900],
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
}));

export default useUnansweredQuestionStyles;
