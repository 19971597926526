import { ActionType, State, Action } from "./listTypes";

export const initialState: State = {
  executives: [],
  origins: [],
  lostReasons: [],
  activityNames: [],
  cities: [],
  financialTypes: [],
};

const demandLeadReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_EXECUTIVES:
      return {
        ...state,
        executives: action.executives,
      };

    case ActionType.SET_ORIGINS:
      return {
        ...state,
        origins: action.origins,
      };

    case ActionType.SET_LOST_REASONS:
      return {
        ...state,
        lostReasons: action.lostReasons,
      };

    case ActionType.SET_CITIES:
      return {
        ...state,
        cities: action.cities,
      };

    case ActionType.SET_FINANCIAL_TYPES:
      return {
        ...state,
        financialTypes: action.financialTypes,
      };

    case ActionType.SET_ACTIVITY_NAMES:
      return {
        ...state,
        activityNames: action.activityNames,
      };
    default:
      return state;
  }
};

export default demandLeadReducer;
