import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useEvaluationsListTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      backgroundColor: theme.palette.grey[50],
      fontSize: theme.typography.pxToRem(14),
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: theme.palette.grey[500],
        borderBottomWidth: 0,
      },
    },
  })
);

export default useEvaluationsListTableStyles;
