import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import { IUserAutoComplete } from "models/createProperty";
import { HoumOwner } from "models/Property";
import { IUserFilters } from "models/User";
import UserSuggester from "../UserSuggester";

interface Props {
  id: string;
  label: string;
  defaultValue: IUserAutoComplete | HoumOwner;
  controllerProps?: React.ComponentProps<typeof Controller>;
  qParams?: IUserFilters;
  noOptionsText?: React.ReactNode;
}

function ControlledUserText({
  controllerProps,
  id,
  label,
  defaultValue,
  qParams = {},
  noOptionsText,
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => (
        <UserSuggester
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          id={name}
          label={label}
          error={!!error?.message}
          errorMessage={error?.message}
          qParams={qParams}
          noOptionsText={noOptionsText}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledUserText;
