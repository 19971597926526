import { primary } from "styles/globals";

import { SimilarProperty } from "models";

import { similarPropertiesDataset } from "utils/mapboxDataset";

export const sourceNames = {
  similarProperty: "similar-properties",
};

export const clustersConfigLayer = {
  //circles
  cluster: {
    source: sourceNames.similarProperty,
    id: `${sourceNames.similarProperty}-cluster`,
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-stroke-width": 2,
      "circle-stroke-color": "#fff",
      "circle-color": primary.main,

      "circle-radius": 13,
    },
  },
  clusterCount: {
    id: `${sourceNames.similarProperty}-count`,
    source: sourceNames.similarProperty,
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      "text-allow-overlap": true,
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 10,
    },
    paint: { "text-color": "white" },
  },
  item: {
    id: `${sourceNames.similarProperty}-item`,
    source: sourceNames.similarProperty,
    type: "symbol",
    filter: ["!", ["has", "point_count"]],
    layout: {
      "icon-allow-overlap": false,
      "icon-ignore-placement": true,
      "icon-image": "price-background",
      "icon-padding": 0,
      "icon-size": 0.4,
      "icon-text-fit": "width",
      "icon-text-fit-padding": [0, 60, 0, 60],
      "text-allow-overlap": false,
      "text-field": "{price}",
      "text-padding": 0,
      "text-size": 11.5,
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    },
    paint: { "text-color": "#000000" },
  },
};

export const loadImage = (mapRef, image, imageName: string) => {
  mapRef.current.loadImage(image, (err, image) => {
    if (err) throw err;
    if (!mapRef.current.hasImage(imageName)) {
      mapRef.current.addImage(imageName, image);
    }
  });
};

export const createOrUpdateSource = (
  mapRef,
  data: SimilarProperty[] = [],
  currentPropertyId: number
) => {
  const dataset = similarPropertiesDataset(data.filter((item) => item.id !== currentPropertyId));
  if (!mapRef.current) return;
  if (mapRef.current?.getSource(sourceNames.similarProperty)) {
    mapRef.current.getSource(sourceNames.similarProperty).setData(dataset);
    return;
  }
  mapRef.current.addSource(sourceNames.similarProperty, {
    type: "geojson",
    data: dataset,
    cluster: true,
    clusterMaxZoom: 25,
    clusterRadius: 50,
    tolerance: 0,
  });
};

export const createLayers = (mapRef) => {
  if (!mapRef.current.getLayer(clustersConfigLayer.item.id))
    mapRef.current.addLayer(clustersConfigLayer.item);
  if (!mapRef.current.getLayer(clustersConfigLayer.cluster.id))
    mapRef.current.addLayer(clustersConfigLayer.cluster);
  if (!mapRef.current.getLayer(clustersConfigLayer.clusterCount.id))
    mapRef.current.addLayer(clustersConfigLayer.clusterCount);
};
