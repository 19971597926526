import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, CircularProgress, Grid } from "@material-ui/core";

import {
  getApplicant,
  getEvaluationToken,
  getHoumer,
  getSingleEvaluation,
  handleChangeRent,
  setCanPay,
} from "context/evaluationContext/evaluationAction";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import Reservation from "Components/Admin/UIComponents/Reservation";
import { useGetToken } from "hooks";

import GenerateNewContract from "../components/GenerateNewContract";
import EvaluationHead from "../components/EvaluationHead";
import CodebtorCard from "../components/CodebtorCard";
import useStyles from "./IndexSingleEvaluationStyles";
import HoumerCard from "../components/HoumerCard";
import Property from "../components/Property";
import { getRentsSum } from "../utils";

function IndexSingleEvaluationMx() {
  const { getToken } = useGetToken();
  const classes = useStyles();
  const {
    main_id: mainId,
    codebtor_id: codebtorIds,
    country,
  } = useParams<{
    main_id: string;
    codebtor_id: string;
    country: string;
  }>();
  const { state, dispatch } = useEvaluation();
  const { singleEvaluation, loadingSingleEvaluation, applicant, property3x } = state;

  useEffect(() => {
    getToken().then((authToken) => {
      getSingleEvaluation(mainId, codebtorIds, "mx", authToken, dispatch);
      getEvaluationToken(mainId, authToken, country, dispatch);
    });
  }, []);

  useEffect(() => {
    if (
      singleEvaluation.evaluations.length > 0 &&
      singleEvaluation.evaluations[0].related_applicant_id
    ) {
      getToken().then((authToken) => {
        getApplicant(singleEvaluation.evaluations[0].related_applicant_id, authToken, dispatch);
        getHoumer(singleEvaluation.evaluations[0].related_applicant_id, authToken, dispatch);
      });
    }
  }, [singleEvaluation.evaluations]);

  useEffect(() => {
    const rentsSum = singleEvaluation.rents ? getRentsSum(singleEvaluation.rents) : 0;
    const canPayValue = property3x <= rentsSum;
    dispatch(setCanPay(canPayValue));
  }, [property3x, singleEvaluation.rents]);

  if (loadingSingleEvaluation) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container direction="column" spacing={2}>
      <EvaluationHead />
      <Grid container justifyContent="center">
        <Grid item xs={6} container justifyContent="center" alignContent="center">
          <GenerateNewContract />
        </Grid>
        {singleEvaluation.evaluations.length > 0 &&
          singleEvaluation.evaluations[0].brokerage &&
          singleEvaluation.evaluations[0].related_applicant_id && (
            <Grid item container xs={6} justifyContent="center" alignContent="center">
              <HoumerCard />
            </Grid>
          )}
      </Grid>
      {singleEvaluation.evaluations.length > 0 && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <CodebtorCard
                evaluation={singleEvaluation.evaluations[0]}
                evaluationPerson="Arrendatario Principal"
                onChangeRent={(value) =>
                  handleChangeRent(
                    singleEvaluation.evaluations[0].id,
                    value,
                    singleEvaluation.rents,
                    singleEvaluation,
                    dispatch
                  )
                }
              />
            </Grid>
          </Grid>
          {singleEvaluation.evaluations.length > 1 &&
            singleEvaluation.evaluations.slice(1).map((item) => (
              <Grid item xs={12} key={item.id}>
                <CodebtorCard
                  evaluation={item}
                  evaluationPerson="Codeudor"
                  onChangeRent={(value) =>
                    handleChangeRent(
                      item.id,
                      value,
                      singleEvaluation.rents,
                      singleEvaluation,
                      dispatch
                    )
                  }
                />
              </Grid>
            ))}
          <Grid container className={classes.gridContainer}>
            <Property />
            <Grid item xs={6}>
              {applicant && (
                <Grid item xs={12}>
                  <Reservation
                    propertyId={singleEvaluation.propertyId}
                    userId={applicant.user_id}
                    applicantId={applicant.id}
                    country="mx"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default IndexSingleEvaluationMx;
