import React from "react";
import Highlighter from "react-highlight-words";

import { ISuggestedProperty } from "apps/evaluator-admin/components/PropertyCard/utils/types";
import { renderAddress } from "utils";

import useSuggestionStyles from "./SuggestionStyles";

interface Props {
  suggestion: ISuggestedProperty;
  value: string;
}

const Suggestion = ({ suggestion, value }: Props) => {
  const classes = useSuggestionStyles();

  return (
    <div className={classes.suggestionContainerComponent}>
      <Highlighter
        className={classes.title}
        searchWords={[value["query"]]}
        autoEscape
        textToHighlight={`ID ${suggestion.id}: ${renderAddress({
          address: suggestion?.address,
          comuna: suggestion?.commune,
          numero_depto: suggestion?.apartmentNumber,
          street_number: suggestion?.streetNumber,
          type: suggestion?.type,
        })}`}
      />
    </div>
  );
};

export default React.memo(Suggestion);
