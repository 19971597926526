import { Box, Typography } from "@material-ui/core";

import { Locale } from "models/Countries";
import { UserModel } from "models/User";

import useRefundsStyles from "./RefundsStyles";
import useGetRefunds from "../../hooks/useGetRefunds";
import ReservationCard from "../ReservationCard";

interface Props {
  country: Locale;
  user: UserModel;
  propertyId: number;
}

const Refunds = ({ country, user, propertyId }: Props) => {
  const classes = useRefundsStyles();
  const { refundsLoading, refundsError, refunds } = useGetRefunds(country, user?.id, propertyId);
  if (refundsLoading || !refunds?.length) return null;

  if (refundsError)
    return (
      <Typography variant="body2" component="p" className={classes.noReservations}>
        Error al obtener reservas
      </Typography>
    );

  return (
    <Box className={classes.refundsRoot}>
      <Typography className={classes.subtitle}>Pagos de reserva devueltos</Typography>
      {refunds.map((reservation) => (
        <ReservationCard
          reservation={reservation}
          key={reservation.movement_id}
          country={country}
          propertyId={propertyId}
          user={user}
          isRefund
        />
      ))}
    </Box>
  );
};

export default Refunds;
