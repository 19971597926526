import qs from "query-string";

import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";

import { AnswerPropertyQuestion, GetPropertyQuestions } from "./types";

const QuestionsService = (token: string) => {
  const httpClient = createHttp(token, BACKEND_URL);

  const getPropertyQuestions: GetPropertyQuestions = ({ propertyId, pageSize, page, ordering }) =>
    httpClient.get(
      `/properties/v2/questions/?${qs.stringify({
        property: propertyId,
        page_size: pageSize,
        page,
        ordering,
      })}`
    );

  const answerPropertyQuestion: AnswerPropertyQuestion = ({ questionId, answer }) =>
    httpClient.post(`/properties/v2/questions/${questionId}/answer/`, {
      answer,
    });
  return {
    getPropertyQuestions,
    answerPropertyQuestion,
  };
};

export default QuestionsService;
