import { makeStyles, Theme } from "@material-ui/core";

const schedulersStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  label: {
    width: "100%",
  },
  commentBox: {
    marginTop: theme.spacing(2),
    width: "100%",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default schedulersStyles;
