import { useEffect } from "react";

import { FormProvider } from "react-hook-form";

import { useProperty } from "context/propertyContext/propertyContext";
import { updateHCDetails, getProperty } from "context/propertyContext/propertyActions";

import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";
import { useGetToken } from "hooks";
import inhabitedFields from "./fields";

function InhabitedCard() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProperty();

  const hcId = state.property?.home_checker_details?.[0].id;

  const isInhabited = state.property?.home_checker_details?.[0].inhabited_property;

  const inhabitedDetails = state.property?.home_checker_details?.[0].inhabited_property_description;

  const {
    methods,
    goNextStep,
    goBackStep,
    label,
    currentStep,
    checked,
    toggleCheck,
    changedDefaultCheck,
  } = useVisitCard({
    defaultChecked: isInhabited,
  });

  useEffect(() => {
    if (checked) {
      goBackStep();
    }
  }, [checked]);

  const fields = inhabitedFields({ checked, currentStep, inhabitedDetails });

  const handleSubmit = async (data) => {
    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      {
        ...data,
        inhabited_property: true,
      },
      hcId,
      dispatch,
      authToken
    );
    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };
  const buttonLabel = checked ? label : "Actualizar";

  const showButton = changedDefaultCheck || checked;

  const handleRemoveThirdParty = async () => {
    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      {
        inhabited_property: false,
      },
      hcId,
      dispatch,
      authToken
    );

    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          id="inhabited"
          items={fields}
          title={<TitleCard title="La propiedad esta habitada" />}
          checkedOptions={{
            checked,
            onChange: toggleCheck,
          }}
          buttonOptions={
            showButton && {
              buttonLabel,
              buttonCallback: checked ? goNextStep : handleRemoveThirdParty,
              buttonPosition: "right",
              isSubmit: checked && currentStep === "editable",
            }
          }
        />
      </form>
    </FormProvider>
  );
}

export default InhabitedCard;
