import StarIcon from "@material-ui/icons/Star";
import { Chip, Grid } from "@material-ui/core";

import useLandlordVip from "../../hooks/useLandlordVip";
import useLandlordVipStyles from "./LandlordVipStyles";

const LandlordVip = ({ subscriptionId }: { subscriptionId: number }) => {
  const classes = useLandlordVipStyles();
  const { isLandlordVip } = useLandlordVip(subscriptionId);

  if (!isLandlordVip) return null;

  return (
    <Grid item xs={12} container justifyContent="flex-end">
      <Chip
        icon={<StarIcon className={classes.vipIcon} />}
        label="Propietario VIP"
        variant="default"
        className={classes.chip}
      />
    </Grid>
  );
};

export default LandlordVip;
