import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
  },
}));
