import { makeStyles, Theme } from "@material-ui/core";

const useModalTitle = makeStyles((theme: Theme) => ({
  modalTitleRoot: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
}));

export default useModalTitle;
