import { UseQueryResult, useQuery } from "react-query";

import schedulesService from "services/schedulesService";
import useGetToken from "hooks/useGetToken";
import { parseCancelReasons } from "./utils/parsers";
import { CancelReasons } from "./utils/types";

const useGetSchedulesCancelReasons = (propertyId: number) => {
  const { getToken } = useGetToken();
  const {
    data: cancelReasons,
    isLoading,
    isError,
  }: UseQueryResult<CancelReasons, Error> = useQuery<CancelReasons, Error>(
    ["getSchedulesCancelReasons", propertyId],
    async () => {
      const token = await getToken();
      const response = await schedulesService.getCancelReasons(token);
      return parseCancelReasons(response.data.cancel_reasons);
    }
  );

  return {
    cancelReasons,
    isLoading,
    isError,
  };
};

export default useGetSchedulesCancelReasons;
