import { Controller, useFormContext } from "react-hook-form";
import classnames from "classnames";

import { FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";

import useControlledSwitchSelectorStyles from "./ControlledSwitchSelectorStyles";
import SwitchSelector from "../../DesignSystem/SwitchSelector";

interface Props {
  id: string;
  label: string;
  defaultValue?: string;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  labelClassName?: string;
  options: { label: string; key: string }[];
  buttonClassname?: string;
  switchLabelClassname?: string;
  groupClassname?: string;
  disabled?: boolean;
}

const ControlledSwitchSelector = ({
  id,
  label,
  defaultValue,
  controllerProps,
  labelClassName,
  options,
  buttonClassname,
  switchLabelClassname,
  groupClassname,
  disabled = false,
}: Props) => {
  const { control } = useFormContext();
  const classes = useControlledSwitchSelectorStyles();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <FormControl className={classes.wrapper} error={invalid}>
          <FormControlLabel
            classes={{
              label: classnames(classes.label, labelClassName),
              root: classes.wrapper,
            }}
            labelPlacement="top"
            control={
              <SwitchSelector
                handleChange={onChange}
                options={options}
                defaultValue={defaultValue}
                buttonClassname={buttonClassname}
                labelClassname={switchLabelClassname}
                groupClassname={groupClassname}
                disabled={disabled}
              />
            }
            label={label}
          />
          <FormHelperText error={invalid}>{error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
};

export default ControlledSwitchSelector;
