import Swal from "sweetalert2";
import { useGetToken } from "hooks";

import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useToky } from "context/tokyContext/tokyContext";
import {
  callByToky,
  setOnCloseCallbackFn,
  closePhone,
  setCallbacks,
} from "context/tokyContext/tokyActions";
import { useLead } from "context/leadContext/leadContext";
import { completeLeadActivity } from "context/leadContext/leadActions";
import { PhoneInput } from "models/PhoneInput";
import { ICallbacks, TokySessionStatus } from "context/tokyContext/tokyTypes";

interface Props {
  activityId: number;
  targetDialNumber: PhoneInput;
}

function ActivityTokyCallButton({ activityId, targetDialNumber }: Props) {
  const { getToken } = useGetToken();
  const { dispatch } = useLead();
  const { dispatch: tokyDispatch } = useToky();

  const callbacks: ICallbacks = {
    [TokySessionStatus.BYE]: () => {
      closePhone(tokyDispatch);
    },
  };

  const completeActivityCallback = async (tokyCallId: string) => {
    const response = await Swal.fire({
      type: "warning",
      title: "¿Deseas marcar esta actividad como Completada?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    });
    const { value: confirm } = response;
    if (confirm) {
      const authToken = await getToken();
      const sendData = {
        tokyCallId,
      };
      await completeLeadActivity(activityId, sendData, dispatch, authToken);
    }
  };

  const handleSetUpTokyCall = () => {
    tokyDispatch(setCallbacks(callbacks));
    tokyDispatch(
      setOnCloseCallbackFn((tokyCallId: string) => completeActivityCallback(tokyCallId))
    );
    callByToky(targetDialNumber, tokyDispatch);
  };

  return <LinkButton onClick={handleSetUpTokyCall}>Llamar por Toky</LinkButton>;
}

export default ActivityTokyCallButton;
