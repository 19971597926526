import { format } from "date-fns";
import { useState } from "react";

import { Typography } from "@material-ui/core";

import PhotosModal from "Components/Admin/administrator/subscriptions/components/Requests/components/PhotosModal";

import { IQuestion } from "../../hooks/types";
import useAnsweredQuestionStyles from "./AnsweredQuestionStyles";
import PhotosButton from "../PhotosButton";

interface Props {
  question: IQuestion;
}

const AnsweredQuestion = ({ question }: Props) => {
  const classes = useAnsweredQuestionStyles();
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.questionHeader}>
          <Typography className={classes.label}>Pregunta</Typography>
          <Typography className={classes.date}>
            {format(question.createdAt, "dd/MM/yyyy")}
          </Typography>
        </div>
        <Typography className={classes.question}>{question.question}</Typography>
        <PhotosButton
          onClick={() => setIsPhotosModalOpen(true)}
          hasPhotos={question.photos.length > 0}
        />
        <div className={classes.answerHeader}>
          <Typography className={classes.label}>Respuesta</Typography>
          <Typography className={classes.date}>
            {format(question.updatedAt, "dd/MM/yyyy")}
          </Typography>
        </div>
        <Typography className={classes.answer}>{question.answer}</Typography>
      </div>
      <PhotosModal
        openModal={isPhotosModalOpen}
        onClose={() => setIsPhotosModalOpen(false)}
        photos={question.photos}
      />
    </>
  );
};

export default AnsweredQuestion;
