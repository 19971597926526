import { useQuery, UseQueryResult } from "react-query";

import { useDebouncedSearchQuery, useGetToken } from "hooks";
import { Subscription } from "models/Subscription";
import subscriptionService from "services/subscriptionService";

const useSearchBarSubscriptions = () => {
  const { getToken } = useGetToken();
  const { handleOnDebouncedSearchChange, debouncedQuery } = useDebouncedSearchQuery();
  const { isLoading, error, data }: UseQueryResult<Subscription[], Error> = useQuery<
    Subscription[],
    Error
  >(
    ["searchSubscription", debouncedQuery],
    async () => {
      const token = await getToken();
      const res = await subscriptionService.search(debouncedQuery, token);
      return typeof res !== "boolean" && res?.data?.results;
    },
    {
      enabled: !!debouncedQuery,
    }
  );
  return {
    isLoading,
    error,
    data,
    setSearchQuery: handleOnDebouncedSearchChange,
    searchQuery: debouncedQuery,
  };
};

export default useSearchBarSubscriptions;
