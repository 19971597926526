import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid",
    borderColor: theme.palette.grey[100],
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    width: "70%",
    marginBottom: theme.spacing(3),
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  disabledTitle: {
    fontSize: "1.25rem",
    color: "rgba(0, 0, 0, 0.38)",
  },
  text: {
    minHeight: 22,
  },
  disabledText: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

export default useStyles;
