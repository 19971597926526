import _ from "lodash";

import { Grid, Typography } from "@material-ui/core";

import { IMonthlyData, ISimulationData } from "models/Contract";
import useStyles from "./PaymentsPerMonthStyles";
import { numberWithCommas } from "utils";

interface Props {
  type: "lessee" | "lessor";
  simulationData: ISimulationData;
}

function PaymentsPerMonth({ type, simulationData }: Props) {
  const classes = useStyles();
  const chunkedData = _.chunk(simulationData?.monthly_details, 6);

  const getAmount = (month: IMonthlyData) =>
    type === "lessee" ? month?.total_payment_lessee : month?.total_payment_lessor;

  return (
    <>
      <Typography component="p" className={classes.title}>
        Montos de los siguientes meses
      </Typography>
      <div className={classes.rootMonth}>
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
          className={classes.grid}>
          {chunkedData.map((chunked, i) => (
            <Grid container item xs={6} justifyContent="center" alignItems="flex-start" key={i}>
              {chunked.map((month) => (
                <Grid
                  key={`${month.month_number}-${type}-${month.movement_created}`}
                  container
                  alignItems="stretch"
                  className={classes.monthCard}>
                  <Grid
                    container
                    item
                    xs={6}
                    className={classes.monthGrid}
                    alignItems="center"
                    justifyContent="center">
                    Mes {month.month_number}
                  </Grid>
                  <Grid
                    container
                    item
                    className={classes.textFieldGrid}
                    xs={6}
                    alignItems="center"
                    justifyContent="center">
                    {`${month.currency === "UF" ? "UF" : "$"}
                      ${
                        month.currency === "UF"
                          ? getAmount(month)
                          : numberWithCommas(getAmount(month))
                      }`}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

export default PaymentsPerMonth;
