import { Button, CircularProgress } from "@material-ui/core";
import classNames from "classnames";

import useCustomLoadingButtomStyles from "./CustomLoadingButtonStyles";

interface Props {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  type?: "contained" | "text" | "outlined";
  fullSize?: boolean;
  buttonClassName?: string;
  typeEvent?: "button" | "submit" | "reset";
}

function CustomLoadingButton({
  text,
  onClick,
  disabled = false,
  loading = false,
  type = "contained",
  fullSize = false,
  buttonClassName,
  typeEvent = "submit",
  ...props
}: Props) {
  const classes = useCustomLoadingButtomStyles();

  return (
    <Button
      {...props}
      size="small"
      onClick={onClick}
      type={typeEvent}
      color="primary"
      variant={type}
      disabled={disabled}
      className={classNames(classes.buttonNext, buttonClassName, {
        disabled: classes.disabled,
      })}
      fullWidth={fullSize}>
      {loading ? (
        <CircularProgress size={20} color={type === "contained" ? "secondary" : "primary"} />
      ) : (
        text
      )}
    </Button>
  );
}

export default CustomLoadingButton;
