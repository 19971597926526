import Geocode from "react-geocode";

import { GOOGLE_MAPS_API_KEY } from "env";
import { GeocoderResult, GeocoderType } from "models/geoCoder";

class Geocoder {
  static async fromLatLng(region: string, lat: number, lng: number): Promise<GeocoderResult> {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage("es");
    Geocode.setRegion(region);
    const resp = (await Geocode.fromLatLng(lng, lat)) as GeocoderType;
    const address = resp.results[0];
    return address;
  }
}

export default Geocoder;
