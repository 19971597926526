import { Theme, makeStyles } from "@material-ui/core/styles";

const useSignDocsStyles = makeStyles((theme: Theme) => ({
  buttonOption: {
    border: "none",
    borderRadius: 100,
    boxShadow: "none",
    fontWeight: 700,
    position: "relative",
    textTransform: "none",
    fontSize: "1rem",
    lineHeight: "1.375em",
    height: 40,
    minWidth: 200,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 3),
    },
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
  },
  icon: {
    color: "white",
  },
  button: {
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
  },
}));

export default useSignDocsStyles;
