import * as yup from "yup";
import { phoneObject } from "utils/yupValidators/phone";

export const requiredText = "Obligatorio";

export const phoneValidationSchema = yup.object({
  phoneTarget: phoneObject.typeError("Número de celular inválido").required(requiredText),
  phoneCaller: yup
    .object({
      value: yup.string().required(requiredText),
      label: yup.string().required(requiredText),
    })
    .required(requiredText)
    .typeError(requiredText),
});
