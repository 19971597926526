import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";

import { useGetToken } from "hooks";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  setIsOpenNewsModal,
  setScheduleNews,
} from "context/demandScheduledVisitsContext/demandScheduledActions";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";

import useNewsStyles from "./NewsStyles";

function News() {
  const classes = useNewsStyles();
  const methods = useForm();
  const { getToken } = useGetToken();
  const {
    state: { isOpenNewsModal, news, scheduleId },
    dispatch,
  } = useDemandScheduled();

  useEffect(() => {
    methods.setValue("News", news);
  }, [news]);

  const handleSubmit = (data) => {
    getToken().then((authToken) => setScheduleNews(dispatch, authToken, scheduleId, data.News));
  };

  return (
    <CustomCenteredModal
      open={isOpenNewsModal}
      onClose={() => dispatch(setIsOpenNewsModal(false))}
      title="Novedades sobre la visita."
      subtitle={`ID: ${scheduleId}`}
      showCloseButton>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <ControlledTextField
            textFieldProps={{
              multiline: true,
              minRows: 4,
              InputProps: {
                className: classes.textArea,
              },
            }}
            id="News"
            label={<div className={classes.textAreaLabel}>Novedades</div>}
          />
          <div className={classes.buttonsContainer}>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="text"
              className={classes.textButton}
              onClick={() => dispatch(setIsOpenNewsModal(false))}>
              Cancelar
            </Button>
            <Button
              fullWidth
              className={classes.saveButton}
              color="primary"
              variant="outlined"
              size="small"
              type="submit">
              Guardar
            </Button>
          </div>
        </form>
      </FormProvider>
    </CustomCenteredModal>
  );
}
export default News;
