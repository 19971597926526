import { makeStyles, Theme } from "@material-ui/core";

const useLinkEvaluationsFormStyles = makeStyles<Theme>((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
  },
  link: {
    color: "blue",
  },
}));

export default useLinkEvaluationsFormStyles;
