import { Theme, makeStyles } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

interface Props {
  breakpoint: Breakpoint;
}

const useStyles = makeStyles<Theme, Props>((theme) => {
  const disabled = {
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
  };

  return {
    base: {
      border: "none",
      borderRadius: 100,
      boxShadow: "none",
      fontWeight: 700,
      position: "relative",
      textTransform: "none",
    },
    // sizes
    large: (props) => ({
      fontSize: "1.125rem",
      lineHeight: "1.3em",
      height: 48,
      padding: theme.spacing(0, 6),
      [theme.breakpoints.up(props.breakpoint)]: {
        padding: theme.spacing(0, 7.25),
      },
    }),
    medium: (props) => ({
      fontSize: "1rem",
      lineHeight: "1.375em",
      height: 40,
      padding: theme.spacing(0, 5),
      [theme.breakpoints.up(props.breakpoint)]: {
        padding: theme.spacing(0, 6.5),
      },
    }),
    small: {
      fontSize: "0.875rem",
      lineHeight: "1.4em",
      height: 32,
      padding: theme.spacing(0, 4),
    },
    // colors
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: "#FFC9C2",
        color: theme.palette.primary.main,
        "& > span > div > *": {
          color: theme.palette.primary.main,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&:disabled": {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.secondary.light,
      },
    },
    secondary: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      stroke: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[900],
        "& > span > div > *": {
          color: theme.palette.grey[900],
          stroke: theme.palette.grey[900],
        },
      },
      "&:active": {
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${theme.palette.grey[800]}`,
        boxShadow: "4px 4px 30px rgba(0,0,0,0.25)",
        color: theme.palette.grey[900],
      },
      "&:disabled": { ...disabled },
    },
    tertiaryA: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: "#FEF1F0",
        border: "1px solid #FEF1F0",
      },
      "&:active": {
        backgroundColor: "#FEF1F0",
      },
      "&:disabled": { ...disabled },
    },
    tertiaryB: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[900]}`,
      color: theme.palette.grey[900],
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.light}`,
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: "4px 4px 30px rgba(0,0,0,0.25)",
      },
      "&:disabled": { ...disabled },
    },
    whatsapp: {
      backgroundColor: "#33D26B", // houm palette whatsapp
      color: theme.palette.common.white,
      "$:hover": {
        backgroundColor: "#26AB55", // houm palette whatsapp hover
      },
      "&:disabled": {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.secondary.light,
      },
    },
    primaryB: {
      padding: 0,
      width: "fit-content",
      height: "fit-content",

      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: "1.375em",
      color: theme.palette.primary.main,

      backgroundColor: "transparent",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 0,

      transition: "all 0.1s ease",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#FFC9C2",
        borderColor: "#FFC9C2",
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: theme.palette.grey[500],
        borderColor: theme.palette.grey[500],
      },
    },
    secondaryB: {
      padding: 0,
      width: "fit-content",
      height: "fit-content",

      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: "1.375em",
      color: theme.palette.grey[900],

      backgroundColor: "transparent",
      borderBottom: "1px solid #263238",
      borderRadius: 0,

      transition: "all 0.1s ease",
      "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.grey[700],
        borderColor: theme.palette.grey[700],
      },
      "&:disabled": {
        backgroundColor: "transparent",
        color: theme.palette.grey[500],
        borderColor: theme.palette.grey[500],
      },
    },
    // Label
    labelContainer: {
      alignItems: "center",
      display: "flex",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    largeIcon: {
      fontSize: "1.5rem",
    },
    mediumIcon: {
      fontSize: "1.25rem",
    },
    smallIcon: {
      fontSize: "1rem",
    },
    circularProgress: {
      position: "absolute",
    },
    primaryColor: { color: theme.palette.common.white },
    primaryColorDisabled: { color: theme.palette.primary.main },

    secondaryColor: { color: theme.palette.common.white },
    secondaryColorDisabled: { color: theme.palette.grey[900] },

    tertiaryAColor: { color: theme.palette.primary.main },
    tertiaryAColorDisabled: { color: theme.palette.primary.main },

    tertiaryBColor: { color: theme.palette.grey[900] },
    tertiaryBColorDisabled: { color: theme.palette.grey[900] },

    whatsappColor: { color: theme.palette.common.white },
    whatsappColorDisabled: { color: "#33D26B" },
    loading: {
      color: "transparent",
    },

    fullWidth: {
      padding: 0,
      width: "100%",
    },
  };
});

export default useStyles;
