import { ReactNode, useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import { AvTimerOutlined } from "@material-ui/icons";
import { GridColDef, GridColumns, GridSelectionModel } from "@material-ui/x-grid";

import { useTable } from "context/tableContext/tableContext";
import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { capitalizeString } from "utils";
import CustomCell from "./Cells";
import useStyles from "./TableStyles";
import { getTimeDifference } from "../../utils/time";
import { setSelectedLeads } from "context/demandLeadContext/leads/demandLeadActions";

function CellStatus(val) {
  if (val === "Sin contactar") return <strong>{val}</strong>;
  return val;
}
type CellDirection = "normal" | "left" | "right";
interface EvaluateCellProps {
  children: ReactNode;
  row?: any;
  direction?: CellDirection;
}
function EvaluateCell({ children, row, direction = "normal" }: EvaluateCellProps) {
  return (
    <CustomCell
      highlight={row.status !== "Perdido" && moment().isAfter(row.following_date)}
      direction={direction}>
      {children}
    </CustomCell>
  );
}

function Table() {
  /**
   * Ref A
   * If exists some id in batch of the new page,
   * the method  onSelectionModelChange will be called two times with an empty array
   * in other case, onSelectionModelChange will be called one time
   * * Important: When optionPerPage changes the current page batch will be reset
   */

  const classes = useStyles();

  const [changedPage, setChangedPage] = useState(0);

  const {
    state: { leads, totalLeads, isLoading, deal, profiled, selectedLeads },
    dispatch: demandDispatch,
  } = useDemandLead();

  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize, ordering } = tableStates;

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
    cellClassName: classes.centerCell,
  };

  useEffect(() => {
    dispatch(setSorting([{ field: "following_date", sort: "asc" }]));
  }, []);

  const columns = useMemo(() => {
    const actionColumn = {
      ...columnsConfig,
      field: "actions",
      headerName: "-",
      sortable: false,
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: classes.lastHeaderCell,
      cellClassName: classes.actionCell,
      flex: 0.5,
      //@ts-ignore
      renderCell: ({ row }: GridCellParams) => (
        <Link to={`/demand/lead/${row.id}`} className={classes.link}>
          Ver
        </Link>
      ),
    };

    const qualificationColumn = {
      ...columnsConfig,
      field: "qualification",
      sortable: false,
      headerName: "Nota",
      flex: 0.72,
      renderCell: ({ value, row }) => <EvaluateCell row={row}>{value}</EvaluateCell>,
    };

    const defaultColumns1 = [
      {
        ...columnsConfig,
        field: "expirations",
        //@ts-ignore
        headerName: <AvTimerOutlined />,
        type: "date",
        sortable: false,
        flex: 0.7,
        headerClassName: classes.firstHeaderCell,
        cellClassName: classes.leftCell,
        renderCell: ({ row }) => (
          <EvaluateCell row={row} direction="left">
            {row.following_date ? getTimeDifference(row.following_date) : "-"}
          </EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "created_at",
        headerName: "Creación",
        type: "date",
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>
            {/* @ts-ignore */}
            {capitalizeString(moment(value).format("MMM DD HH:mm"))}
          </EvaluateCell>
        ),
      },
    ];

    const defaultColumns2 = [
      {
        ...columnsConfig,
        field: "status",
        headerName: "Estado de lead",
        sortable: false,
        renderCell: ({ value, row }) => <EvaluateCell row={row}>{CellStatus(value)}</EvaluateCell>,
      },
      {
        ...columnsConfig,
        field: "origin",
        headerName: "Origen",
        sortable: false,
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>{!value ? "Sin registrar" : value}</EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "following_date",
        headerName: "Vencimiento",
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>
            {/* @ts-ignore */}
            {value ? capitalizeString(moment(value).format("MMM DD HH:mm")) : "-"}
          </EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "name",
        headerName: "Nombre",
        sortable: false,
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>{!value ? "Sin registrar" : value}</EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "email",
        headerName: "Correo",
        sortable: false,
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>{!value ? "Sin registrar" : value}</EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "phone",
        headerName: "Teléfono",
        sortable: false,
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row}>{!value ? "Sin registrar" : value}</EvaluateCell>
        ),
      },
      {
        ...columnsConfig,
        field: "lq",
        headerName: deal === "arriendo" ? "LR Qualifier" : "LB Qualifier Buyer",
        sortable: false,
        headerClassName: classes.lastRenderedHeaderCell,
        cellClassName: classes.rightCell,
        renderCell: ({ value, row }) => (
          <EvaluateCell row={row} direction="right">
            {value}
          </EvaluateCell>
        ),
      },
    ];

    if (profiled) {
      if (deal !== "arriendo")
        return [...defaultColumns1, qualificationColumn, ...defaultColumns2, actionColumn];
      return [...defaultColumns1, qualificationColumn, ...defaultColumns2];
    } else {
      if (deal !== "arriendo") return [...defaultColumns1, ...defaultColumns2, actionColumn];
      return [...defaultColumns1, ...defaultColumns2];
    }
  }, [deal, profiled]) as GridColumns;

  const handleSort = (model) => {
    let newOrdering: string | null = null;
    if (model.length > 0) {
      newOrdering = `${model[0].sort === "asc" ? "" : "-"}${model[0].field}`;
    }
    if (newOrdering !== ordering) {
      dispatch(setSorting(model));
    }
  };

  const handleSelection = (currentSelection: GridSelectionModel) => {
    if (changedPage === 0) {
      demandDispatch(
        setSelectedLeads({
          ...selectedLeads,
          [currentPage]: currentSelection as number[],
        })
      );
      return;
    }
    setChangedPage(changedPage - 1);
  };

  /**
   * Update the page without remove the other checkbox selections
   * @param page  values: [0, 1 ,2 ,3 ,4]
   * @see Ref A
   */
  const handlePageChange = (page: number) => {
    dispatch(setCurrentPage(page + 1));
    if (selectedLeads[page + 1] && selectedLeads[page + 1].length > 0) {
      setChangedPage(2);
      return;
    }
    setChangedPage(1);
  };

  const handlePageSizeChange = (size: number) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    demandDispatch(setSelectedLeads({}));
  };

  return (
    <div className={classes.container}>
      <CustomDataGrid
        hideFooterSelectedRowCount
        checkboxSelection={deal === "venta"}
        rowCount={totalLeads}
        columns={columns}
        rows={leads}
        loading={isLoading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onSortModelChange={handleSort}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={handlePageSizeChange}
        selectionModel={!isLoading && selectedLeads[currentPage]}
        onSelectionModelChange={handleSelection}
        classes={{
          root: classes.root,
          columnHeader: classes.headerWrapper,
        }}
        localeText={{
          columnMenuShowColumns: "Mostrar Columnas",
          columnMenuHideColumn: "Ocultar Columna",
          columnMenuUnsort: "No Ordenar",
          columnMenuSortAsc: "Ordenar Ascendente",
          columnMenuSortDesc: "Ordenar Descendente",
        }}
      />
    </div>
  );
}

export default Table;
