import clsx from "clsx";

import { Box, Typography } from "@material-ui/core";

import useServiceDetailsStyles from "./ServiceDetailsStyles";
import CardBold from "../../BasicServices/components/typography/CardBold";

interface Props {
  currentDebt?: string;
  disabled?: boolean;
  overdueDate?: string;
  aggregateDebt?: string;
}

function ServiceDetails({
  currentDebt = "-",
  disabled = false,
  overdueDate = "-",
  aggregateDebt = "-",
}: Props) {
  const classes = useServiceDetailsStyles();
  const labelName = clsx({
    [classes.disabledText]: disabled,
    [classes.label]: disabled,
  });
  const typographyClassName = clsx(classes.text, {
    [classes.disabledText]: disabled,
  });

  return (
    <>
      <Box className={classes.serviceItem}>
        <CardBold className={labelName}>Deuda vigente :</CardBold>
        <Typography className={typographyClassName}>{currentDebt}</Typography>
      </Box>
      <Box className={classes.serviceItem}>
        <CardBold className={labelName}>Fecha de vencimiento :</CardBold>
        <Typography className={typographyClassName}>{overdueDate}</Typography>
      </Box>
      <Box className={classes.serviceItem}>
        <CardBold className={labelName}>Deuda morosa :</CardBold>
        <Typography
          className={clsx(classes.serviceValue, classes.aggregatedDebt, typographyClassName)}>
          {aggregateDebt}
        </Typography>
      </Box>
    </>
  );
}

export default ServiceDetails;
