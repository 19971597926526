import { format } from "date-fns";

import DetailsItem from "../DetailsItem";

type PromotionNameProps = {
  name: String;
  initDate: Date;
  endDate: Date;
};

export default function PromotionNameAndDate({ name, initDate, endDate }: PromotionNameProps) {
  const initialDate = format(initDate, "dd/MM/yyyy");
  const finalDate = format(endDate, "dd/MM/yyyy");
  return (
    <DetailsItem title="Nombre de la promoción">
      <p>{name}</p>
      <span>
        Disponible del {initialDate} al {finalDate}
      </span>
    </DetailsItem>
  );
}
