import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  contentRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: theme.spacing(2),
  },
  suggesterLabel: {
    color: theme.palette.grey[500],
  },
  selectorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectorLabel: {
    color: theme.palette.grey[500],
    fontWeight: 700,
    marginBottom: 0,
    padding: theme.spacing(0.5, 0),
  },
  selectorRoot: {
    height: 28,
    padding: 0,
    borderBottom: "0.8px solid #ECEFF1",
  },
  applyFiltersButton: {
    height: 32,
    backgroundColor: theme.palette.grey[900],
    color: "white",
    borderRadius: 16,
    padding: theme.spacing(2, 4),
    borderColor: "transparent",
    transition: "0.3s",
    "& span": {
      fontWeight: 400,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      color: "white",
      "& span": {
        color: "white",
      },
    },
  },
  textfieldPadding: {
    padding: theme.spacing(0, 2),
  },
}));

export default useStyles;
