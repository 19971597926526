import { TableProvider } from "context/tableContext/tableContext";

import InterestedSectionTable from "./components/Table";

import useStyles from "./InterestedSectionStyles";

function InterestedSection() {
  const classes = useStyles();
  return (
    <TableProvider>
      <div className={classes.container}>
        <InterestedSectionTable />
      </div>
    </TableProvider>
  );
}

export default InterestedSection;
