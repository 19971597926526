import RepairsRequestPage from "./RepairsRequestPage";

import { TabProvider } from "context/tabContext/tabContext";
import { SnackbarProvider } from "notistack";

export default function RepairsRequestPageHead() {
  return (
    <TabProvider>
      <SnackbarProvider>
        <RepairsRequestPage />
      </SnackbarProvider>
    </TabProvider>
  );
}
