import { useState } from "react";
import { pdfjs } from "react-pdf";

import { Document as DocumentsProps } from "domains/evaluations/models/Document";

import { classes } from "./DocumentsViewStyles";
import DocumentViewer from "../DocumentViewer";
import SidePanel from "./components/SidePanel";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  documents: DocumentsProps[];
  evaluationMemberId: string;
}
const DocumentsView = ({ documents, evaluationMemberId }: Props) => {
  const [selectedDocument, setSelectedDocument] = useState<DocumentsProps | null>(
    documents.length > 0 ? documents[0] : null
  );
  const onHandleSelectedDocument = (doc: DocumentsProps) => setSelectedDocument(doc);

  return (
    <div className={classes.root}>
      <SidePanel
        documents={documents}
        onHandleSelectedDocument={onHandleSelectedDocument}
        selectedDocument={selectedDocument}
      />
      <div className={classes.documentViewerPanel}>
        {selectedDocument ? (
          <DocumentViewer document={selectedDocument} evaluationMemberId={evaluationMemberId} />
        ) : (
          <div className={classes.noDocumentPanel}>
            <p>No hay documentos</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsView;
