import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

interface IFormData {
  property_id?: number;
  company?: string;
  total_debt?: number;
  expired_debt?: number;
  administration_email?: string;
  client_info?: {
    external_id: string;
    community_name?: string;
    unit?: string;
  };
  file?: File;
}

const useCreateCommonExpenses = (edit: boolean, handleClose: () => void) => {
  const queryClient = useQueryClient();
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();

  const {
    mutate: uploadFile,
    isLoading: uploadLoading,
    isError: uploadError,
  } = useMutation(
    async (data: { url: string; file: File }) => {
      const response = await propertyService.uploadVoucher(data.url, data.file);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["propertyServices"]);
        dispatch(
          setAlertInfo({
            open: true,
            head: "Comprobante de pago cargado exitosamente",
            type: "success",
          })
        );
      },
      onError: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error al subir comprobante de pago",
            type: "error",
          })
        );
      },
    }
  );

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();

      let infoResponse;
      if (edit) {
        infoResponse = await propertyService.updatePropertyBasicService(
          formData.property_id,
          authToken,
          {
            company: formData.company,
            is_active: true,
            client_info: formData.client_info,
            property_id: formData.property_id,
          }
        );
      } else {
        infoResponse = await propertyService.createCommonExpense(
          formData.property_id,
          formData,
          authToken
        );
      }

      if (formData.company === "otro") {
        const response = await propertyService.createCommonExpenseOther(
          formData.property_id,
          formData,
          authToken
        );
        return response?.data;
      }
      return infoResponse?.data;
    },
    {
      onSuccess: (data, variables) => {
        if (data?.presigned_url && variables?.file) {
          const formData = {
            url: data?.presigned_url,
            file: variables?.file,
          };
          uploadFile(formData);
        } else {
          queryClient.invalidateQueries(["propertyServices"]);
        }

        dispatch(
          setAlertInfo({
            open: true,
            head: `Gasto común ${edit ? "editado" : "creado"} exitosamente`,
            type: "success",
          })
        );
        handleClose();
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: `No se pudo ${edit ? "editar" : "crear"} el gasto común`,
            type: "error",
            body: `Error: ${reason?.data?.detail ?? e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    uploadLoading,
    uploadError,
  };
};

export default useCreateCommonExpenses;
