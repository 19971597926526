import { makeStyles, Theme } from "@material-ui/core";

const useGrayChipStyles = makeStyles<Theme>((theme) => ({
  chip: {
    padding: 0,
    fontWeight: 700,
    color: theme.palette.grey.A400,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[50],
  },
  root: { height: "fit-content" },
}));

export default useGrayChipStyles;
