import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  buttonConatiner: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  formContainer: {
    paddingRight: theme.spacing(16),
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  formControlContainer: {
    marginBottom: theme.spacing(1),
    "& .MuiFormControlLabel-root": {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiFormControlLabel-label": {
        fontSize: "0.875rem",
        fontWeight: 700,
      },
    },
    "& .MuiInputBase-root": {
      paddingLeft: theme.spacing(1),
      borderRadius: "4px",
      backgroundColor: "#EFF2F3",
      "&.MuiInput-underline:before": {
        border: "none",
      },
    },
  },
  formInput: {
    padding: theme.spacing(0, 1),
    backgroundColor: "#EFF2F3",
  },
  separator: {
    margin: theme.spacing(2, 0),
  },
}));

export default useStyles;
