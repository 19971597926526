import { Grid } from "@material-ui/core";
import { MainText } from "../../../../../../typography";
import Row from "../Row";

interface Props {
  date: string;
}

function Date({ date }: Props) {
  return (
    <Row container justifyContent="space-between">
      <Grid item>
        <MainText>Fecha</MainText>
      </Grid>
      <Grid item>
        <MainText>{date}</MainText>
      </Grid>
    </Row>
  );
}

export default Date;
