import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import User from "Components/Admin/UIComponents/User";
import propertyService from "services/propertyService";
import { numberWithCommas } from "utils/index";
import { useGetToken } from "hooks";

import usePropertyCardStyles from "./PropertyCardStyles";

const PropertyCard = ({ property }) => {
  const classes = usePropertyCardStyles();
  const { getToken } = useGetToken();
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const authToken = await getToken();
      const propertyId = property.uid;
      const res = await propertyService.get(propertyId, authToken);
      if (res) {
        setPropertyData(res.data);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <AdminCard title="Propiedad" showTitleBackground={true} withSpace={false}>
      <Loading loading={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.linkContainer}>
              <Link to={`/admin/properties/${property.uid}`}>
                {propertyData?.address} {propertyData?.street_number}, {propertyData?.comuna}
              </Link>
              <Link
                to={`https://arriendoasegurado.pipedrive.com/deal/${propertyData?.deal_id}`}
                target="_blank"
                rel="noopener noreferrer">
                Ver en pipedrive
              </Link>
            </Box>
            {propertyData?.property_details.length > 0 && (
              <Typography>
                {`Monto Arriendo: ${numberWithCommas(propertyData?.property_details[0].valor)} ${
                  propertyData?.property_details[0].tipo_moneda
                } `}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <User type="Propietario" user={propertyData?.user} />
          </Grid>
        </Grid>
      </Loading>
    </AdminCard>
  );
};

export default PropertyCard;
