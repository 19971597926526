interface ICountryDocuments {
  Chile: ICountryValue;
  Colombia: ICountryValue;
  Mexico: ICountryValue;
  México: ICountryValue;
}

interface ICountryValue {
  label: string;
  value: string;
}

export const documentMexicoById = {
  5: "INE",
  7: "Pasaporte",
  9: "RFC",
  13: "CURP",
};

const countryDocuments: ICountryDocuments = {
  Chile: {
    label: "Rut",
    value: "RUT",
  },
  Colombia: {
    label: "Documento de identidad",
    value: "Documento",
  },
  Mexico: {
    label: "RFC",
    value: "RFC",
  },
  México: {
    label: "RFC",
    value: "RFC",
  },
};

export default countryDocuments;
