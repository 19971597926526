import classnames from "classnames";
import useStyles from "./CustomTextFieldStyles";
import { TextField } from "@material-ui/core";

interface Props<T> {
  id?: string;
  label: string;
  value?: T;
  options?: T[];
  labelPos?: "top" | "start";
  onChange?: (value: T) => void;
  color?: "light" | "strong";
  multiple?: boolean;
  placeholder?: string;
  containerClass?: string;
  inputProps?: React.ComponentProps<typeof TextField>["InputProps"];
  type?: "text" | "number" | "date" | "password";
}
function CustomTextField({
  label,
  onChange,
  labelPos = "start",
  color = "strong",
  placeholder,
  containerClass,
  inputProps,
  type = "text",
  value,
}: Props<any>) {
  const classes = useStyles({ labelPos, color });
  return (
    <div className={classnames(classes.container, containerClass)}>
      {label && <p className={classes.label}>{label}</p>}
      <TextField
        value={value}
        type={type}
        className={classes.input}
        placeholder={placeholder}
        InputProps={{ ...inputProps }}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default CustomTextField;
