import { useMutation, useQueryClient } from "react-query";
import { alert } from "@houm-com/ui/Alert";

import applicantService from "services/applicantService";
import { useGetToken } from "hooks";

interface IUseUpdateChecker {
  applicantId: string;
  onSuccessHandler: () => void;
}

const useUpdateHoumer = ({ applicantId, onSuccessHandler }: IUseUpdateChecker) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isError } = useMutation(
    async (data: { houmerId: string }) => {
      const authToken = await getToken();
      return applicantService.changeHoumer(
        applicantId,
        {
          appraiser_id: data.houmerId,
        },
        authToken
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("houmer_by_applicant_id", { exact: false });
        alert.success({
          message: "Se ha asigando al houmer de forma exitosa",
        });
        onSuccessHandler();
      },
      onError: () => {
        alert.success({
          message: "¡Oh no! Ocurrió un problema",
          disclaimer: `No se pudo asignar al houmer, intenta de nuevo`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
  };
};

export default useUpdateHoumer;
