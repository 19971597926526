import { makeStyles, Theme } from "@material-ui/core";

const useTableItemStyles = makeStyles<Theme>((theme) => ({
  itemRoot: {
    margin: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 8,
  },
  itemColumn: {
    height: 54,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  clickable: {
    cursor: "pointer",
  },
  itemFont: {
    margin: theme.spacing(1),
    maxHeight: 54,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
}));

export default useTableItemStyles;
