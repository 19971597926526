import { makeStyles, Theme } from "@material-ui/core";

const usePropertyDataCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(2, 3),
    borderRadius: 20,
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: "1rem",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  moreInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  flagStatus: {
    borderRadius: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.grey[300],
    width: "fit-content",
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(18),
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    display: "flex",
  },
  flagStatusIcon: {
    width: 12,
    marginRight: theme.spacing(1),
  },
  flagStatusValidated: {
    fontWeight: 700,
    backgroundColor: "#C1E4A8",
    color: "#26810A",
  },
}));
export default usePropertyDataCardStyles;
