import { makeStyles, Theme } from "@material-ui/core";

const useExitProcessStartModalStyles = makeStyles((theme: Theme) => ({
  commentInput: {
    top: 5,
    padding: theme.spacing(1),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
  },
}));

export default useExitProcessStartModalStyles;
