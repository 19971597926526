import { useState } from "react";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import { Button, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";

import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import BackButton from "Components/Admin/administrator/multifamily/Components/BackButton";
import SimulateTextfield from "../../Components/SimulateTextfield/SimulateTextfield";
import Images from "../../Components/Images/Images";
import SubHeader from "./SubHeader/SubHeader";

import useStyles from "./HeaderStyles";

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);
  const {
    state: { currentId: MFid, multifamily },
  } = useMultifamily();
  const {
    state: { currentId: UTid, typology, UTImages },
  } = useTypology();

  const handleDownload = () => {
    saveAs(typology?.layout_file_url);
  };

  const handleBack = () => history.push(`/admin/multifamily/${MFid}`);

  return (
    <div className={classes.container}>
      <div>
        <BackButton backButtonProps={{ label: "Atras", onClick: handleBack }} />
      </div>
      <div className={classes.rows}>
        <Typography className={classes.title}>
          {multifamily.name} - {typology?.name}
        </Typography>
        <div className={classes.buttons}>
          <DesignSystemButton
            label="Editar tipología"
            size="medium"
            variant="secondary"
            href={`/admin/multifamily/${MFid}/typology/create/${UTid}`}
            sameTab
          />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.columns}>
          <SimulateTextfield title="Nombre" value={typology?.name} />
          <SimulateTextfield
            title="Cantidad de habitaciones"
            value={typology?.bedrooms?.toString()}
          />
          <SimulateTextfield
            title="M2 construidos"
            value={`${typology?.built_area?.toString()} mts2`}
          />
          <SimulateTextfield title="Descripción Houm" value={typology?.description} />
        </div>
        <div className={classes.columns}>
          <SimulateTextfield title="Cantidad de baños" value={typology?.bathrooms?.toString()} />
          <SimulateTextfield
            title="M2 totales"
            value={`${typology?.total_area?.toString()} mts2`}
          />
          <SimulateTextfield
            title="Descripción portales externos"
            value={typology?.external_description}
          />
          <div className={classes.downloadContainer}>
            <Typography className={classes.downloadLabel}>Descargar planos</Typography>
            <Button
              className={classes.downloadButton}
              onClick={handleDownload}
              disabled={
                typology?.layout_file_url ===
                "https://s3.amazonaws.com/houm-real-estate-media/layout/multifamily/"
              }>
              <AttachFile fontSize="small" />
              Descargar archivos
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {showInfo && (
          <div className={classes.extraContainer}>
            <Images title="Imágenes tipología" images={UTImages} />
            <SubHeader />
          </div>
        )}
        <div className={classes.keypadButtons}>
          <BackButton
            backButtonProps={{
              label: showInfo ? "Ocultar características" : "Ver características",
              onClick: () => setShowInfo(!showInfo),
              icon: false,
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default Header;
