import { Grid } from "@material-ui/core";
import _ from "lodash";

import useStyles from "../../layouts/monthlyTableStyles";
import MonthTextfield from "../../layouts/monthTextfield";

interface Props {
  monthValues: {
    month: number;
    value: string;
    name: string;
  }[];
  disabled?: boolean;
}

function MonthlyTable({ monthValues, disabled = false }: Props) {
  const classes = useStyles();
  const newChunkedData = _.chunk(monthValues, 6);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        alignItems="flex-start"
        justifyContent="center"
        className={classes.grid}>
        {newChunkedData.map((chunked) => (
          <Grid container item xs={6} justifyContent="center" alignItems="flex-start">
            {chunked.map((month) => (
              <MonthTextfield
                key={`${month?.name}-month-${month?.month}`}
                id={`${month?.name}-month-${month?.month}`}
                number={month?.month}
                defaultValue={Number(month?.value).toString()}
                disabled={disabled}
              />
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default MonthlyTable;
