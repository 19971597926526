import { ReactNode, useEffect, createContext, useContext, useMemo } from "react";

import { useMap } from "context/map/mapContext";

import { Map, SourceFeature } from "models/Mapbox";

interface SourceProps {
  id: string;
  children?: ReactNode;
  sourceData: SourceFeature;
}

const createOrUpdate = (map: Map, source: SourceFeature, id: string) => {
  const currentSource = map?.getSource(id);
  if (!currentSource) {
    map?.addSource(id, source);
    const newSource = map?.getSource(id);
    return newSource;
  }

  map.getSource(id).setData(source?.data);
  return map.getSource(id);
};

const ApiContext = createContext<{ id: string }>(undefined);

export const useSource = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  return context;
};

function Source({ id, children, sourceData }: SourceProps) {
  const {
    state: { map },
  } = useMap();
  const valueId = useMemo(() => ({ id }), [id]);

  useEffect(
    () => () => {
      if (map.style && map.style._loaded && map.getSource(id)) {
        const allLayers = map.getStyle()?.layers;
        if (allLayers) {
          for (const layer of allLayers) {
            if (layer.source === id) {
              map.removeLayer(layer.id);
            }
          }
        }
        map.removeSource(id);
      }
      return undefined;
    },
    [map]
  );

  const source = useMemo(() => {
    if (map) {
      return createOrUpdate(map, sourceData, id);
    }
    return null;
  }, [map, sourceData, id]);

  return <ApiContext.Provider value={valueId}>{(source && children) || null}</ApiContext.Provider>;
}

export default Source;
