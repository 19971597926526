export const idOptions = [
  {
    label: "Suscripción",
    value: "quotation_id",
  },
  {
    label: "Nuevo ID Suscripción",
    value: "subscription_id",
  },
  {
    label: "Propiedad",
    value: "property_id",
  },
  {
    label: "Contrato",
    value: "contract_id",
  },
  {
    label: "Email de propietario",
    value: "propietary_email",
  },
  {
    label: "Email de arrendatario",
    value: "tenant_email",
  },
];

export const emptyIds = {
  property_id: undefined,
  quotation_id: undefined,
  subscription_id: undefined,
  contract_id: undefined,
  tenant_email: undefined,
  propietary_email: undefined,
};
