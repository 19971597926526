import { makeStyles, Theme } from "@material-ui/core";

const useSelectContractPropertyStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  halfWidthContainer: {
    width: "50%",
  },
  button: {
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
  },
  title: {
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
}));

export default useSelectContractPropertyStyles;
