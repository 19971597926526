import { UseQueryResult, useQuery } from "react-query";

import { Property, PropertySchema } from "domains/evaluations/models/Property";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

interface Props {
  propertyId: number;
  viewName?: string;
}

const useProperty = ({ propertyId, viewName = "rental_details" }: Props) => {
  const { getToken } = useGetToken();

  const query: UseQueryResult<Property, Error> = useQuery(
    ["property_data", propertyId],
    async () => {
      const authToken = await getToken();
      const { data } = await propertyService.getPropertyInfo(propertyId, authToken, viewName);
      return PropertySchema.parse(data);
    },
    {
      enabled: !!propertyId,
      retry: false,
    }
  );

  return { ...query };
};

export default useProperty;
