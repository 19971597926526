import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModalDialog from "@houm-com/ui/ModalDialog";
import { InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import PaymentAdjustConcept from "Components/Admin/UIComponents/PaymentAdjustConcept";
import {
  ControlledList,
  ControlledNumber,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";
import { localeToCountry } from "utils/countryCode";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import { movementPlanSchema, requiredText } from "./utils/validationSchema";
import useCreateChargeDiscount from "../../hooks/useCreateChargeDiscount";
import useChangeDiscountFormStyles from "./ChangeDiscountFormStyles";
import { AdjustmentFormProps } from "./utils/types";

interface Props {
  country: Locale;
  movementId: string;
  onClose: () => void;
  adjustmentType?: "monthly_payment" | "closing_payment" | "payment_creation" | "spot_payment";
  movementType: "INCOME" | "OUTFLOW" | "PROFIT";
  contractCurrency: CurrencyCode;
}

function ChargeDiscountForm({
  country,
  movementId,
  onClose,
  adjustmentType,
  movementType,
  contractCurrency,
}: Props) {
  const classes = useChangeDiscountFormStyles();
  const { mutate, isLoading } = useCreateChargeDiscount(country, movementId, onClose);
  const countryName = localeToCountry[country];

  const methods = useForm<AdjustmentFormProps>({
    resolver: yupResolver(movementPlanSchema(country, contractCurrency)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<AdjustmentFormProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<AdjustmentFormProps> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  const typeAdjustment = methods.watch("adjustmentType");
  const parseAjdustmentType = {
    1: "payment",
    2: "discount",
  };

  return (
    <FormProvider {...methods}>
      <form className={classes.formContent}>
        <ModalDialog.Content>
          <Grid item container alignItems="flex-start">
            <Grid item xs={12}>
              <ControlledList
                label="Seleccionar tipo de pago"
                id="adjustmentType"
                defaultValue=""
                disableUnderline
                options={[
                  { value: "1", label: "Abono" },
                  { value: "2", label: "Descuento" },
                ]}
                classes={{
                  select: classes.select,
                }}
              />
            </Grid>
            {country === "cl" && contractCurrency === "CLF" && (
              <Grid item xs={12}>
                <ControlledSelection
                  id="currency"
                  label="Tipo de moneda"
                  defaultValue="CLP"
                  options={[
                    { value: "CLP", label: "Pesos" },
                    { value: "UF", label: "UF" },
                  ]}
                  classes={{
                    label: classes.selectionLabel,
                    container: classes.selectionContainer,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ControlledNumber
                label="Monto"
                id="amount"
                defaultValue=""
                textFieldProps={{
                  InputProps: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                  type: "number",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PaymentAdjustConcept
                country={countryName}
                paymentType={parseAjdustmentType[typeAdjustment]}
                adjustmentType={adjustmentType}
                movementType={movementType}
              />
            </Grid>
          </Grid>
        </ModalDialog.Content>
        <ModalDialog.Actions
          onSubmit={methods.handleSubmit((data) => onSubmit(data))}
          submitButtonLabel="Guardar"
          submitButtonProps={{ isLoading, disabled: isLoading }}
          buttonsPosition="center"
        />
      </form>
    </FormProvider>
  );
}

export default ChargeDiscountForm;
