import { Divider } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import { BuyInformation, PropertyInformation, Score, Features } from "./profiling";

import useProfilingFormStyles from "./ProfilingFormStyle";

function ProfilingForm() {
  const classes = useProfilingFormStyles();
  const {
    state: { profiling, renderProfileForm },
  } = useDemandLead();

  return (
    <Loading loading={!profiling && !renderProfileForm}>
      <div className={classes.container}>
        <BuyInformation />
        <Divider className={classes.divider} />
        <PropertyInformation />
        <Divider className={classes.divider} />
        <Features />
        <Divider className={classes.divider} />
        <Score />
      </div>
    </Loading>
  );
}

export default ProfilingForm;
