import ObservationForm from "./components/ObservationForm";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  subscriptionId: number;
  observation: string;
}

const ObservationModal = ({ open, onHandleClose, subscriptionId, observation }: Props) => (
  <DSModal open={open} onClose={onHandleClose} maxWidth="xs" showCloseButton>
    <ObservationForm
      onClose={onHandleClose}
      subscriptionId={subscriptionId}
      observation={observation}
    />
  </DSModal>
);

export default ObservationModal;
