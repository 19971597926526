import { HOUM_COM_URL } from "env";

import HoumLogo from "assets/images/houmLogo.png";

import { PropertyData } from "models/Property";

const COUNTRY_BASED_COUNTRY_CODES = {
  Chile: "cl",
  Mexico: "mx",
  Colombia: "co",
};

//TODO: move this definition to a global space
export enum BusinessType {
  SALE = "sale",
  RENT = "rent",
}

export enum PropertyType {
  APARTMENT = "departamento",
  HOUSE = "casa",
}

const PROPERTY_TYPE_BY_COUNTRY_CODE = {
  cl: {
    [PropertyType.APARTMENT]: "departamento",
    [PropertyType.HOUSE]: "casa",
  },
  mx: {
    [PropertyType.APARTMENT]: "departamento",
    [PropertyType.HOUSE]: "casa",
  },
  co: {
    [PropertyType.APARTMENT]: "apartamento",
    [PropertyType.HOUSE]: "casa",
  },
};

const BUSINESS_TYPE_BY_COUNTRY_CODE = {
  cl: {
    [BusinessType.SALE]: "venta",
    [BusinessType.RENT]: "arriendo",
  },
  mx: {
    [BusinessType.SALE]: "venta",
    [BusinessType.RENT]: "renta",
  },
  co: {
    [BusinessType.SALE]: "venta",
    [BusinessType.RENT]: "arriendo",
  },
};

const REGION_ALIASES = {
  "region-metropolitana": "santiago",
  "region-de-valparaiso": "valparaiso",
};

const sanitizeRegionToURL = (region: string) => {
  const regionWithoutAccents = region.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const regionWithoutSpaces = regionWithoutAccents.replace(/\s/g, "-");
  const regionLowerCase = regionWithoutSpaces.toLowerCase();
  const regionWithAlias = REGION_ALIASES[regionLowerCase] || regionLowerCase;
  return regionWithAlias;
};

export const getPortalLinks = (property: PropertyData = null) => {
  const development = property?.user?.role === "real_estate_developer" ? "proyecto" : "";
  const countryCode = COUNTRY_BASED_COUNTRY_CODES[property?.country];
  const announceTypeByCountry = BUSINESS_TYPE_BY_COUNTRY_CODE[countryCode];
  const propertyType = PROPERTY_TYPE_BY_COUNTRY_CODE[countryCode][property?.type];
  const propertyRegion = sanitizeRegionToURL(property?.region);
  const propertyCommune = sanitizeRegionToURL(property?.comuna);
  const urlRoot = `${HOUM_COM_URL}/${countryCode}`;
  const salesURL = `${urlRoot}/${
    announceTypeByCountry[BusinessType.SALE]
  }-${propertyType}-${propertyRegion}/${propertyCommune}`;
  const rentalsURL = `${urlRoot}/${
    announceTypeByCountry[BusinessType.RENT]
  }-${propertyType}-${propertyRegion}/${propertyCommune}`;
  const developmentTrail = development ? `/${development}` : "";
  const completeRentalURL = `${rentalsURL}/${property.id}${developmentTrail}`;
  const completeSalesURL = `${salesURL}/${property.id}${developmentTrail}`;

  return {
    rentals: [
      {
        url: completeRentalURL,
        src: HoumLogo,
        alt: "Houm",
        label: "Houm - Arriendo",
      },
    ],
    sales: [
      {
        url: completeSalesURL,
        src: HoumLogo,
        alt: "Houm",
        label: "Houm - Venta",
      },
    ],
  };
};
