import { makeStyles, Theme } from "@material-ui/core";

const useCodebtorFormStyles = makeStyles<Theme>((theme) => ({
  formContent: {
    width: "100%",
  },
  selectionLabel: {
    transform: "none",
    position: "relative",
    fontSize: theme.typography.pxToRem(14),
    paddingTop: theme.spacing(1),
  },
  selectionContainer: {
    margin: 0,
    paddingLeft: theme.spacing(0.5),
  },
  buttonSelection: {
    borderRadius: 100,
    padding: theme.spacing(0.5, 2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useCodebtorFormStyles;
