import { Divider, Grid } from "@material-ui/core";

import { ReactComponent as DownArrowSvg } from "assets/icons/arrows/down.svg";
import { ReactComponent as EmailSvg } from "assets/icons/email.svg";
import { ReactComponent as HomeSvg } from "assets/icons/home.svg";
import { ReactComponent as NotStonksSvg } from "assets/icons/not-stonks.svg";
import { ILastPaymentData } from "Components/Admin/administrator/clients/Landlord/utils/types";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Spinner from "Components/Shared/partials/Spinner";

import { MainText, ModalTitle, ModalText } from "../../../../typography";
import useBreakdownModalStyles from "./BreakdownModalStyles";
import Date from "./components/Date";
import Item from "./components/Item";
import Row from "./components/Row";
import Total from "./components/Total";

interface Props extends ILastPaymentData {
  isLoading: boolean;
  error: Error;
  onClose: () => void;
  open: boolean;
}

function BreakdownModal({
  isLoading,
  error,
  open,
  onClose,
  total,
  date,
  rent,
  plan,
  planName,
  charges,
  discounts,
}: Props) {
  const classes = useBreakdownModalStyles();

  if (isLoading)
    return (
      <DSModal open={open} onClose={onClose} showCloseButton>
        <Spinner />
      </DSModal>
    );

  if (error) {
    return (
      <DSModal open={open} onClose={onClose} showCloseButton>
        <MainText>Error cargando el desglose: {error}</MainText>
      </DSModal>
    );
  }

  return (
    <DSModal open={open} onClose={onClose} showCloseButton classes={{ paper: classes.modalRoot }}>
      <Grid container className={classes.gridRoot}>
        <Row className={classes.title}>
          <ModalTitle>Último pago</ModalTitle>
        </Row>

        <Row className={classes.subtitle}>
          <ModalText>Revisa el detalle del último pago que recibió el propietario</ModalText>
        </Row>

        <Total total={total} />

        <Date date={date} />

        <Row>
          <Divider className={classes.divider} />
        </Row>

        <Item name="Valor arriendo" value={rent} icon={<HomeSvg />} />

        <Item name={`Cargo por ${planName || "plan"}`} value={`- ${plan}`} icon={<EmailSvg />} />

        {charges?.length > 0 &&
          charges.map((charge) => (
            <Item
              key={charge.id}
              name={charge.name}
              value={`+ ${charge.value}`}
              icon={<DownArrowSvg />}
            />
          ))}

        {discounts?.length > 0 &&
          discounts.map((discount) => (
            <Item
              key={discount.id}
              name={discount.name}
              value={`- ${discount.value}`}
              icon={<NotStonksSvg />}
            />
          ))}

        <Row>
          <Divider className={classes.divider} />
        </Row>

        <Row container justifyContent="flex-end">
          <DesignSystemButton
            label="Aceptar"
            onClick={onClose}
            size="medium"
            variant="primary"
            buttonClassName={classes.button}
          />
        </Row>
      </Grid>
    </DSModal>
  );
}

export default BreakdownModal;
