import { useCallback, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { ControlledList, ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import newEvaluationService from "services/newEvaluationService";
import { useFormNotifications, useGetToken } from "hooks";
import { statesMx } from "constants/mexicoStates";
import { Locale } from "models/Countries";

import { addressSchema, requiredText } from "../validationSchema";
import useEditAddressFormStyles from "./EditAddressFormStyles";

export interface IAddress {
  city: string;
  colonia: string;
  exteriorNumber: string;
  interiorNumber: string;
  state: string;
  street: string;
  zip: string;
}

export interface IEvaluationDetails {
  address?: IAddress;
  comment: string;
  confirmed_salary: string;
  contract_type: string;
  dependent: boolean;
  document: string;
  employer: string;
  last_name: string;
  last_names: string;
  name: string;
  occupation: string;
  position: string;
  rfc_number: string;
  salary_1: number;
  salary_2: number;
  salary_3: number;
  salary_avg: number;
  salary_months: string;
  salary_others: 0;
  salary_others_description: string;
  scoreBuro: string;
  second_last_name: string;
}

interface Props {
  evaluation: {
    id: number;
  };
}

function EditAddressForm({ evaluation }: Props) {
  const classes = useEditAddressFormStyles();
  const { getToken } = useGetToken();
  const params = useParams<{ country: Locale }>();
  const [loadingEvaluationDetails, setSetLoadingEvaluationDetails] = useState(true);
  const [address, setAddress] = useState<IAddress | null>(null);
  const [loading, setLoading] = useState(false);
  const [previousData, setPreviousData] = useState<IEvaluationDetails | null>(null);

  const fetchDetails = useCallback(async (authToken) => {
    setSetLoadingEvaluationDetails(true);
    const res = await newEvaluationService.getEvaluationDetails(
      evaluation.id,
      authToken,
      params.country
    );
    if (res) {
      const evaluationDetails = res.data?.evaluation_details?.details;
      setPreviousData(evaluationDetails);
      const { address }: { address: IAddress } = evaluationDetails;
      setAddress(address);
    }
    setSetLoadingEvaluationDetails(false);
  }, []);

  useEffect(() => {
    getToken().then((authToken) => fetchDetails(authToken));
  }, [evaluation]);

  const methods = useForm<IAddress>({
    resolver: yupResolver(addressSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IAddress>({ errors, requiredText });

  const onSubmit: SubmitHandler<IAddress> = (data) => {
    (async () => {
      setLoading(true);
      const formData = {
        ...previousData,
        address: data,
      };
      try {
        const authToken = await getToken();
        await newEvaluationService.updateEvaluationDetails(
          evaluation.id,
          formData,
          authToken,
          params.country
        );
        Swal.fire({
          type: "success",
          title: "Dirección actualizada",
          text: `La dirección ha sido actualizada con éxito`,
        }).then(() => window.location.reload());
      } catch (e) {
        Swal.fire({
          type: "error",
          text: "No se pudo editar la dirección, porfavor contactarse con soporte",
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Loading loading={loadingEvaluationDetails}>
      <FormProvider {...methods}>
        <div className={classes.formContainer}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ControlledTextField
              label="Calle"
              id="street"
              defaultValue={address?.street}
              textFieldProps={{ placeholder: "Ingresar nombre" }}
            />
            <ControlledTextField
              label="Numéro exterior"
              id="exteriorNumber"
              defaultValue={address?.exteriorNumber}
              textFieldProps={{
                placeholder: "Ingresar número exterior",
                type: "number",
              }}
            />
            <ControlledTextField
              label="Numéro interior"
              id="interiorNumber"
              defaultValue={address?.interiorNumber}
              textFieldProps={{
                placeholder: "Ingresar número interior (si no tiene, ingrese 0)",
                type: "number",
              }}
            />
            <ControlledTextField
              label="Colonia"
              id="colonia"
              defaultValue={address?.colonia}
              textFieldProps={{ placeholder: "Ingresar colonia" }}
            />
            <ControlledTextField
              label="Ciudad"
              id="city"
              defaultValue={address?.city}
              textFieldProps={{ placeholder: "Ingresar ciudad" }}
            />
            <ControlledList
              disableUnderline
              label="Estado"
              id="state"
              defaultValue={address?.state}
              options={statesMx}
            />
            <ControlledTextField
              label="Código Postal"
              id="zip"
              defaultValue={address?.zip}
              textFieldProps={{
                placeholder: "Ingresar código postal",
                type: "number",
              }}
            />
            <div className={classes.buttonContainer}>
              <CustomButton active className={classes.button} type="submit">
                <Loading loading={loading} size={20}>
                  <span>Editar</span>
                </Loading>
              </CustomButton>
            </div>
          </form>
        </div>
      </FormProvider>
    </Loading>
  );
}

export default EditAddressForm;
