import Button from "@houm-com/ui/Button";
import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import usePropertyPriceRequired from "domains/evaluations/hooks/usePropertyPriceRequired";
import { Evaluation } from "domains/evaluations/models/Evaluations";
import useModal from "hooks/useModal";

import ContractForm from "../ContractForm/ContractForm";

interface Props {
  propertyId: number;
  totalSalary: string;
  evaluation: Evaluation;
}

const GenerateContract = ({ propertyId, totalSalary, evaluation }: Props) => {
  const { isLoading, getPropertyPrice, isError, isSuccess } = usePropertyPriceRequired({
    propertyId,
  });
  const { handleOpenModal, open } = useModal();
  const { handleOpenModal: onHandleOpenContractFrom, open: openContractFrom } = useModal();

  if (isLoading) return null;

  const onGenerateContract = () => {
    const propertyPrice = getPropertyPrice();
    if (Number(totalSalary) < propertyPrice) handleOpenModal();
    else onHandleOpenContractFrom();
  };

  return (
    <>
      {isError && <p className="text-p16">Error, no se puede generar contrato</p>}
      {isSuccess && (
        <Button size="md" variant="primary" type="button" onClick={onGenerateContract}>
          Aprobar evaluación
        </Button>
      )}
      <ConfirmDialog
        title="Estás seguro de que quieres generar el contrato?"
        subtitle="Esta evaluación no cumple la regla de que las rentas sumen 3 veces el valor del arriendo"
        isOpen={open}
        onClose={handleOpenModal}
        onSubmit={() => {
          handleOpenModal();
          onHandleOpenContractFrom();
        }}
        submitButtonLabel="Sí, aprobar"
      />
      <ContractForm
        evaluation={evaluation}
        handleOpenModal={onHandleOpenContractFrom}
        open={openContractFrom}
      />
    </>
  );
};

export default GenerateContract;
