import { MutableRefObject } from "react";

import { makeStyles, Theme } from "@material-ui/core";

interface OptionPropsTypes {
  width: number;
  wrapper: MutableRefObject<HTMLDivElement>;
}

const usePoperCellStyles = makeStyles<Theme, OptionPropsTypes>((theme) => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  text: {
    height: 1,
    width: ({ width }) => width,
    display: "block",
    position: "absolute",
    top: 0,
  },
  popper: {
    marginLeft: theme.spacing(-2),
    width: ({ width }) => width,
  },
  paper: {
    maxWidth: ({ width }) => width,
    overflowX: "auto",
    minHeight: ({ wrapper }) => (wrapper.current ? wrapper.current.offsetHeight - 3 : "auto"),
    "&::-webkit-scrollbar": {
      width: 3,
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: theme.shadows[2],
    },

    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[400],
      border: "1px solid white",
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.grey[100],
    },
  },
  valueText: {
    padding: theme.spacing(1),
  },
}));

export default usePoperCellStyles;
