import { makeStyles, Theme } from "@material-ui/core";

const usePropertyFieldStyles = makeStyles((theme: Theme) => ({
  addressContainer: {
    marginLeft: theme.spacing(1),
  },
  address: {
    marginBottom: theme.spacing(0.5),
    maxHeight: 56,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default usePropertyFieldStyles;
