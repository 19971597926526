import { useState } from "react";

import { Box, Typography } from "@material-ui/core";

import {
  AvailableCompanies,
  ClientInfo,
} from "Components/Admin/UIComponents/PropertyServices/utils/types";
import { Locale } from "models/Countries";

import { GGCCInfo } from "../../../../utils/types";
import CommonExpensesModal from "../../../CommonExpensesModal";
import useEditCommonExpensesStyles from "./EditCommonExpensesStyles";

interface Props {
  company: AvailableCompanies;
  country: Locale;
  propertyId: number;
  data: ClientInfo | GGCCInfo;
}

const EditCommonExpenses = ({ company, country, propertyId, data }: Props) => {
  const classes = useEditCommonExpensesStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box className={classes.editExpenseWrapper}>
      <Typography className={classes.editExpense} onClick={() => setOpenModal(true)}>
        Editar información
      </Typography>
      <CommonExpensesModal
        company={company}
        country={country}
        handleClose={() => setOpenModal(false)}
        propertyId={propertyId}
        open={openModal}
        data={data}
      />
    </Box>
  );
};

export default EditCommonExpenses;
