import { makeStyles, Theme } from "@material-ui/core";

const useHeaderWithCountriesStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
}));

export default useHeaderWithCountriesStyles;
