import { makeStyles } from "@material-ui/core";

const generalCommentsListStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  commentRoot: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  commenter: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
  },
  comment: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
  },
  date: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(11),
  },
}));

export default generalCommentsListStyles;
