import { SnackbarProvider } from "notistack";

import PropertyInspectionReportsTab from "./PropertyInspectionReportsTab";
import { PropertyProvider } from "context/propertyContext/propertyContext";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function PropertyInspectionReportsTabIndex({ property }: Props) {
  return (
    <SnackbarProvider>
      <PropertyProvider>
        <PropertyInspectionReportsTab property={property} />
      </PropertyProvider>
    </SnackbarProvider>
  );
}

export default PropertyInspectionReportsTabIndex;
