import { makeStyles } from "@material-ui/core";

const useContactInfoStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 2),
    fontSize: theme.typography.pxToRem(20),
  },
}));

export default useContactInfoStyles;
