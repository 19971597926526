import { Theme, makeStyles } from "@material-ui/core/styles";

const useFinalizeSignStepStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.4),
  },
  button: {
    transition: "0.5s",
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
      transition: "0.5s",
    },
  },
}));

export default useFinalizeSignStepStyles;
