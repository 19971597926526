import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetToken } from "hooks";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { GridCloseIcon } from "@material-ui/x-grid";
import { useTheme, withStyles } from "@material-ui/core/styles";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import ApplicantBankAccount from "Components/Admin/UIComponents/ApplicantBankAccount";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import { changeProperty } from "context/evaluationContext/evaluationAction";

import SearchBarSelectProperty from "../../dashboard/partials/SearchBarSelectProperty";
import PropertyCard from "./PropertyCard";
import useStyles from "./PropertyStyles";
import { numberWithCommas } from "utils";
import { getRentsSum } from "../utils";

function Property() {
  const { getToken } = useGetToken();
  const theme = useTheme();
  const classes = useStyles();
  const params = useParams<{ main_id: string; country: string }>();
  const { state, dispatch } = useEvaluation();
  const { applicant, singleEvaluation } = state;
  const { main_id: mainId, country } = params;
  const [changingProperty, setChangingProperty] = useState<boolean>(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState<number>(null);
  const [selectedPropertyUid, setSelectedPropertyUid] = useState<string>(null);
  const [rentsSum, setRentsSum] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseChangingProperty = () => {
    setChangingProperty(false);
  };

  const onSelectProperty = (id, uid) => {
    setSelectedPropertyId(id);
    setSelectedPropertyUid(uid);
  };

  const handleChangeProperty = async () => {
    setLoading(true);
    const authToken = await getToken();
    const res = changeProperty(
      Number(mainId),
      applicant.email,
      selectedPropertyId,
      selectedPropertyUid,
      singleEvaluation,
      authToken,
      country,
      dispatch
    );
    if (res) {
      handleCloseChangingProperty();
    }
    setLoading(false);
  };

  useEffect(() => {
    const sum = singleEvaluation.rents ? getRentsSum(singleEvaluation.rents) : 0;
    setRentsSum(typeof sum === "number" ? sum : 0);
  }, [singleEvaluation]);

  const getRentsSumColor = () =>
    state.canPay ? theme.palette.success.main : theme.palette.error.main;

  const RentsSumTypography = withStyles({
    root: {
      color: getRentsSumColor(),
    },
  })(Typography);

  return (
    <>
      <Grid item container xs={12} spacing={2} alignItems="flex-start">
        <Grid item xs={12} className={classes.propertyContainer}>
          <PropertyCard
            propertyUid={singleEvaluation.propertyUid}
            setChangingProperty={setChangingProperty}
          />
        </Grid>
        <Grid item xs={12} className={classes.rentContainer}>
          <Card className={classes.card}>
            <CardContent>
              <Box>
                <RentsSumTypography variant="h6">
                  {`Suma de las rentas: ${country === "cl" ? "CLP" : "MXN"}$ ${numberWithCommas(
                    rentsSum
                  )}`}
                </RentsSumTypography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {applicant && country === "cl" && (
          <Grid item xs={12}>
            <ApplicantBankAccount
              propertyId={applicant?.property.id}
              userId={applicant.user_id}
              country={country}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={changingProperty}
        onClose={handleCloseChangingProperty}
        transitionDuration={500}
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}>
        <Grid container justifyContent="space-between">
          <DialogTitle>Seleccionar propiedad</DialogTitle>
          <DialogActions>
            <IconButton className={classes.closeButton} onClick={handleChangeProperty}>
              <GridCloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
        <DialogContent className={classes.propertySearch}>
          <SearchBarSelectProperty onSelect={onSelectProperty} />
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <CustomLoadingButton
            text="Confirmar"
            onClick={handleChangeProperty}
            type="contained"
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Property;
