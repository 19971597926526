import { Theme, makeStyles } from "@material-ui/core/styles";

const useObservationFormStyles = makeStyles((theme: Theme) => ({
  formContent: {
    width: "100%",
  },
  descriptionInput: {
    top: 5,
    minHeight: 120,
    padding: theme.spacing(2),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
  },
}));

export default useObservationFormStyles;
