import { Theme, makeStyles } from "@material-ui/core";

const useDashboardLayoutStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(-3, -3, -3, -3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
    minHeight: "100vh",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export default useDashboardLayoutStyles;
