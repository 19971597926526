import { Box, Typography } from "@material-ui/core";

import { PropertyData } from "models/Property";
import { ReactComponent as StarIcon } from "assets/icons/App/star.svg";

import { amenities } from "./utils/amenities";
import useStyles from "./AmenitiesCardStyles";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

interface Props {
  property?: PropertyData;
}

function AmenitiesCard({ property = null }: Props) {
  const classes = useStyles();

  const details = property?.property_details[0];

  const renderAmenity = (amenity: string) => <Box className={classes.amenityBox}>{amenity}</Box>;

  return (
    <AdminCard className={classes.cardRoot}>
      <Typography variant="h6" className={classes.title}>
        <StarIcon /> Características de la propiedad
      </Typography>
      <Box className={classes.amenityContainer}>
        {/* laundry */}
        {details?.laundry_capacity &&
          renderAmenity(amenities.getLaundryLabel(details?.laundry_capacity))}
        {/* property furnished */}
        {details?.furnished && renderAmenity(amenities.getFurnishedLabel(details?.furnished))}
        {/* kitchen furnished */}
        {details?.kitchen_is_furnished &&
          renderAmenity(amenities.getKitchenFurnishedLabel(details?.kitchen_is_furnished))}
        {/* closet */}
        {details?.closet && renderAmenity(amenities.getClosetLabel(details?.closet))}
        {/* air conditioning */}
        {details?.has_air_conditioning &&
          renderAmenity(amenities.getAirConditioningLabel(details?.has_air_conditioning))}
        {/* heating */}
        {details?.calefaccion && renderAmenity(amenities.getHeatingLabel(details?.calefaccion))}
        {/* pets */}
        {details?.mascotas && renderAmenity(amenities.getPetfriendlyLabel(details?.mascotas))}
        {/* water heater */}
        {details?.hot_water_system &&
          renderAmenity(amenities.getWaterLabel(Boolean(details?.hot_water_system)))}
        {/* warehouse */}
        {details?.bodega && renderAmenity(amenities.getWarehouseLabel(details?.bodega))}
        {/* garden */}
        {details?.has_roof_garden &&
          renderAmenity(amenities.getGardenLabel(details?.has_roof_garden))}
        {/* curtains */}
        {details?.curtain_hanger &&
          renderAmenity(amenities.getCurtainHangerLabel(details?.curtain_hanger))}
        {/* clothing room */}
        {details?.has_clothing_area &&
          renderAmenity(amenities.getClothingAreaLabel(details?.has_clothing_area))}
        {/* balcony */}
        {details?.has_balcony && renderAmenity(amenities.getBalconyLabel(details?.has_balcony))}
      </Box>
    </AdminCard>
  );
}

export default AmenitiesCard;
