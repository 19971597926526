import { FC } from "react";
import { useQuery, UseQueryResult } from "react-query";

import { Grid, Link, List, ListItem, Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import rentApplicationService from "services/rentApplicationService";
import { IDocuments } from "models/Evaluation";
import { useGetToken } from "hooks";

interface Props {
  rentApplicationId: number;
}
const LegacyDocuments: FC<Props> = ({ rentApplicationId }) => {
  const { getToken } = useGetToken();
  const {
    isLoading,
    data = [],
    error,
  }: UseQueryResult<IDocuments[], Error> = useQuery<IDocuments[], Error>(
    ["documentList"],
    async () => {
      const authToken = await getToken();
      const res = await rentApplicationService.listDocuments(rentApplicationId, authToken);
      const documents = res.data.map((item) => item.documents);
      return documents.flat();
    }
  );

  if (data.length === 0) return null;

  return (
    <Grid item xs={12} md={6}>
      <AdminCard
        title="Documentos evaluaciones antiguas"
        withSpace={false}
        showTitleBackground={true}>
        <Loading loading={isLoading}>
          {error ? (
            <Typography>Hubo un problema cargando los documentos</Typography>
          ) : (
            <List>
              {data.map((item) => (
                <ListItem key={item.id}>
                  <Link href={item.url}>{item.filename}</Link>
                </ListItem>
              ))}
            </List>
          )}
        </Loading>
      </AdminCard>
    </Grid>
  );
};

export default LegacyDocuments;
