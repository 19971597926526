import { useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { Box, Grid, Typography } from "@material-ui/core";

import useDetailsCardsStyles from "./DetailsCardsStyles";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useGetToken } from "hooks";
import { Property } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { editNegotiationProperty } from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";

interface PropertyIdentificationForm {
  individualization?: string;
}

function DetailsCards() {
  const [edit, setEdit] = useState(false);
  const classes = useDetailsCardsStyles();
  const { dispatch, state } = useLetterOfIntent();
  const { country, negotiation, property } = state;

  const { getToken } = useGetToken();
  const methods = useForm<PropertyIdentificationForm>({
    reValidateMode: "onSubmit",
  });

  const onSubmit: SubmitHandler<PropertyIdentificationForm> = (data) => {
    const propertyData: Property = {
      ...property,
      individualization: data.individualization,
    };
    setEdit(false);

    getToken().then((authToken) => {
      editNegotiationProperty({
        property: propertyData,
        country,
        negotiationId: negotiation.id,
        token: authToken,
        dispatch,
      });
    });
  };

  if (!country || !negotiation) return null;
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.containerCard}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography className={classes.label}>Individualización de la propiedad</Typography>
            {!edit ? (
              <Typography className={classes.richValue}>
                {property?.individualization ?? ""}
              </Typography>
            ) : (
              <ControlledTextField
                label=""
                id="individualization"
                key="individualization"
                defaultValue={property?.individualization ?? ""}
                textFieldProps={{
                  multiline: true,
                  textFieldClassName: classes.textareaField,
                  InputProps: {
                    classes: {
                      input: classes.inputField,
                    },
                  },
                }}
              />
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {!edit ? (
              <Box className={classes.buttonContainer}>
                <DesignSystemButton
                  label="Editar datos"
                  size="small"
                  variant="tertiaryB"
                  buttonClassName={classes.button}
                  onClick={() => setEdit(true)}
                />
              </Box>
            ) : (
              <Box className={classes.buttonContainer}>
                <DesignSystemButton
                  label="Cancelar"
                  size="small"
                  variant="tertiaryB"
                  buttonClassName={classes.button}
                  onClick={() => setEdit(false)}
                />
                <DesignSystemButton label="Guardar" size="small" variant="primary" type="submit" />
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default DetailsCards;
