import React from "react";
import { useParams } from "react-router-dom";
import { useGetCurrentUser } from "hooks";
import clx from "classnames";

import SwitchSelector from "Components/Admin/UIComponents/DesignSystem/SwitchSelector";
import { useEvaluation } from "context/evaluationContext/evaluationContext";

import BrokerageConfirmModal from "./components/BrokerageConfirmModal";
import useBrokerageConfirmModal from "./hooks/useBrokerageConfirmModal";
import useUpdateEvaluationBrokerage from "./hooks/useUpdateEvaluationBrokerage";
import { SwitchBrokerageTypes, SwitchBrokerageLabels } from "./utils/BrokerageSwitchSelectorTypes";

const BrokerageSwitchSelector = () => {
  const { openBrokerageChangeModal, handleBrokerageModal } = useBrokerageConfirmModal();
  const { state } = useEvaluation();
  const params = useParams<{
    main_id: string;
    codebtor_id: string;
    country: string;
  }>();
  const { singleEvaluation } = state;
  const firstEvaluation = singleEvaluation.evaluations[0];
  const evaluationHasBrokerage = firstEvaluation.brokerage;
  const { user } = useGetCurrentUser();
  const onModalSubmitSuccess = () => {
    handleBrokerageModal();
    window.location.reload();
  };

  const { mutate: updateEvaluationBrokerage } = useUpdateEvaluationBrokerage(
    firstEvaluation.id,
    params.country,
    onModalSubmitSuccess
  );

  const onModalSubmit = () => {
    updateEvaluationBrokerage({
      status: firstEvaluation.status,
      updated_by: user.id,
      brokerage: !evaluationHasBrokerage,
    });
  };

  const switchHandleChange = (e) => {
    handleBrokerageModal();
  };
  const switchValue = evaluationHasBrokerage
    ? SwitchBrokerageTypes.BROKERAGE
    : SwitchBrokerageTypes.NO_BROKERAGE;

  const modalActionText = evaluationHasBrokerage
    ? SwitchBrokerageLabels.NO_BROKERAGE
    : SwitchBrokerageLabels.BROKERAGE;
  return (
    <>
      <SwitchSelector
        disabled={singleEvaluation.evaluations.length === 0}
        handleChange={switchHandleChange}
        options={[
          { label: SwitchBrokerageLabels.BROKERAGE, key: SwitchBrokerageTypes.BROKERAGE },
          { label: SwitchBrokerageLabels.NO_BROKERAGE, key: SwitchBrokerageTypes.NO_BROKERAGE },
        ]}
        buttonSelectClassName={clx({
          "!bg-primary-cta !text-white": switchValue === SwitchBrokerageTypes.NO_BROKERAGE,
        })}
        defaultValue={switchValue}
        preventChange
      />
      <BrokerageConfirmModal
        setClose={handleBrokerageModal}
        isOpen={openBrokerageChangeModal}
        actionText={modalActionText}
        onSubmit={onModalSubmit}
      />
    </>
  );
};

export default React.memo(BrokerageSwitchSelector);
