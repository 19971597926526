import { Controller, useFormContext } from "react-hook-form";

import CustomNumberField from "Components/Admin/UIComponents/CustomNumberField";

interface Props {
  id: string;
  bordered?: boolean;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  defaultValue?: number;
  disabled?: boolean;
  label: string;
  labelClassName?: string;
  numberFieldProps?: Partial<React.ComponentProps<typeof CustomNumberField>>;
  placeholder?: string;
  warn?: (value: string) => boolean;
  warningMessage?: string;
}

function ControlledNumberField({
  id,
  bordered = false,
  controllerProps,
  defaultValue,
  disabled = false,
  label,
  numberFieldProps,
  labelClassName,
  placeholder,
  warn = () => false,
  warningMessage = "",
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <CustomNumberField
          disabled={disabled}
          id={id}
          value={value}
          label={label}
          onValueChange={(values) => onChange(values.floatValue)}
          bordered={bordered}
          error={invalid}
          errorMessage={error?.message}
          labelClassName={labelClassName}
          warning={warn(value)}
          warningMessage={warningMessage}
          placeholder={placeholder}
          {...numberFieldProps}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledNumberField;
