import { styled, Theme, Box, Typography } from "@material-ui/core";

export const Title = styled(Typography)<Theme>(({ theme }) => ({
  fontWeight: 700,
  margin: theme.spacing(2, 0, 0.5),
}));

export const Text = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: "0.875rem",
  marginBottom: theme.spacing(1),
  "& span": {
    fontWeight: 700,
  },
}));

export const DateLabel = styled(Typography)<Theme>(({ theme }) => ({
  color: "#607D8B",
  fontSize: "0.875rem",
  marginBottom: theme.spacing(2),
}));

export const EnvironmentContainer = styled(Box)<Theme>(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));
