import { ReactChild } from "react";

import { cn } from "@houm-com/ui/utils";
import { IoCreateOutline } from "@houm-com/ui-icons";

import classes from "./TagsStyles";

interface Props {
  content: ReactChild;
  textColor?: string;
  backColor?: string;
  tagStyles?: string;
  hasIcon?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

function BaseTag({ content, textColor, backColor, tagStyles = "", hasIcon, onClick }: Props) {
  return (
    <div
      onClick={() => onClick?.()}
      style={{ color: textColor, backgroundColor: backColor }}
      className={cn(classes.tag, tagStyles)}>
      <span className={classes.icon}>{content}</span>
      {hasIcon && <IoCreateOutline color={textColor} />}
    </div>
  );
}

function RedTag(props: Props) {
  return <BaseTag textColor="white" backColor="#8b0300" {...props} />;
}

function YellowTag(props: Props) {
  return <BaseTag textColor="white" backColor="#5c4e01" {...props} />;
}

function GreenTag(props: Props) {
  return <BaseTag textColor="white" backColor="#367f02" {...props} />;
}

function WhiteTag(props: Props) {
  return <BaseTag textColor="#777e82" backColor="#e9eaeb" {...props} />;
}

function BlackWhiteTag(props: Props) {
  return <BaseTag textColor="white" backColor="#263238" {...props} />;
}

function LightOrangeTag(props: Props) {
  return <BaseTag textColor="#700c0a" backColor="#e7a299" {...props} />;
}

function BlueTag(props: Props) {
  return <BaseTag textColor="#2663BC" backColor="#E0F1FF" {...props} />;
}

function LightBlueTag(props: Props) {
  return <BaseTag textColor="#1c3862" backColor="#abc7f1" {...props} />;
}

function OrangeTag(props: Props) {
  return <BaseTag textColor="#FF452B" backColor="#FFCBBF" {...props} />;
}

function GreyTag(props: Props) {
  return <BaseTag textColor="white" backColor="#00982D" {...props} />;
}

function LightGreenTag(props: Props) {
  return <BaseTag textColor="#285f02" backColor="#daeecc" {...props} />;
}

function LightYellowTag(props: Props) {
  return <BaseTag textColor="#5c4e01" backColor="#f5e79a" {...props} />;
}

export {
  RedTag,
  YellowTag,
  GreenTag,
  LightGreenTag,
  GreyTag,
  OrangeTag,
  BlueTag,
  LightOrangeTag,
  WhiteTag,
  BlackWhiteTag,
  LightBlueTag,
  LightYellowTag,
};
