import { useAuthClient } from "libs/auth";
import { BACKEND_URL } from "env";
import { UserProfileSchema } from "../models/UserProfile";
import { useQuery } from "react-query";

export default function useFetchProfile() {
  const { client, status } = useAuthClient();
  return useQuery(
    "user-profile",
    async () => {
      const { data } = await client.get(`${BACKEND_URL}propietary/v2/me/`);
      const parsedData = UserProfileSchema.parse(data);
      return parsedData;
    },
    {
      enabled: status === "success",
    }
  );
}
