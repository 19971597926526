import { Typologies } from "context/multifamilyContext/typology/typologyTypes";

export const parseBody = (data: Typologies, MFid, UTid) => {
  const videoId: string[] = data?.video_url?.split("=");
  let realVideoLinkToFrame;
  if (data?.video_url && !data?.video_url.includes("embed")) {
    realVideoLinkToFrame = `https://www.youtube.com/embed/${videoId[1]}`;
  } else {
    realVideoLinkToFrame = data?.video_url;
  }

  let fileName =
    data?.layout_filename && data?.layout_filename[0]?.name ? data?.layout_filename[0]?.name : "";
  fileName = fileName.replaceAll(" ", "");
  const updateFile = {
    layout_filename: fileName !== "" ? `${UTid}-${fileName}` : undefined,
  };

  const firstForm = {
    name: data?.name,
    bedrooms: data?.bedrooms,
    bathrooms: data?.bathrooms,
    total_area: data?.total_area,
    built_area: data?.built_area,
    description: data?.description,
    external_description: data?.external_description,
  };

  const secondForm = {
    closet: data?.closet,
    terrace: data?.terrace,
    suite_rooms: data?.suite_rooms,
    video_url: realVideoLinkToFrame,
    environments: data?.environments,
    video360_url: data?.video360_url,
    terrace_area: data?.terrace_area,
    service_rooms: data?.service_rooms,
    apartment_type: data?.apartment_type,
    has_laundry_room: data?.has_laundry_room || false,
    laundry_room_area: data?.laundry_room_area,
    kitchen_is_furnished: data?.kitchen_is_furnished || false,
    has_kitchen_daily_dining_room: data?.has_kitchen_daily_dining_room || false,
  };

  const body = {
    multifamily: MFid,
    ...firstForm,
    ...secondForm,
  };

  if (fileName === "") return body;
  return {
    ...body,
    ...updateFile,
  };
};
