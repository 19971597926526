import { add, format } from "date-fns";
import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import newPaymentService from "services/newPaymentService";
import { IReservation } from "services/newPaymentService/types";

const filterRefunds = (refunds: IReservation[], filter: (refund: IReservation) => boolean) => {
  if (!filter) return refunds;
  return refunds.filter((refund) => filter(refund));
};

const useGetRefunds = (
  country: Locale,
  userId: number,
  propertyId: number,
  filter?: (refund: IReservation) => boolean
) => {
  const { getToken } = useGetToken();

  const {
    isLoading: refundsLoading,
    isError: refundsError,
    data: refunds,
  }: UseQueryResult<IReservation[], Error> = useQuery<IReservation[], Error>(
    ["refundsByUser", country, userId, country, propertyId],
    async () => {
      const refundsService =
        country === "cl" ? newPaymentService.getRefundsCL : newPaymentService.getRefunds;
      const authToken = await getToken();
      const response = await refundsService(
        country,
        userId,
        propertyId,
        {
          dateFrom: "2020-01-01",
          dateTo: format(add(new Date(), { days: 31 }), "yyyy-MM-dd"),
        },
        authToken
      );
      return filterRefunds(response.data?.movements, filter);
    },
    {
      enabled: !!userId && !!propertyId,
    }
  );
  return { refundsLoading, refundsError, refunds };
};

export default useGetRefunds;
