import React from "react";
import { Grid } from "@material-ui/core";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import useFormStyles from "./FormStyles";

function NameFields() {
  const classes = useFormStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.underlined}>
        <ControlledTextField id="name" label="Nombre" />
      </Grid>
      <Grid item xs={12} className={classes.underlined}>
        <ControlledTextField id="last_name" label="Apellido" />
      </Grid>
      <Grid item xs={12} className={classes.underlined}>
        <ControlledTextField id="username" label="Nombre usuario" />
      </Grid>
    </Grid>
  );
}

export default React.memo(NameFields);
