import { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import { InputLabel } from "@material-ui/core";

import { RemoteSuggester } from "Components/Admin/UIComponents";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import useStyles from "./FilterOptionsStyles";
import {
  QuotaByMacrozoneTableFilterTypes,
  filterInitialValues,
} from "Components/Admin/administrator/integrations/pages/portalQuotas/components/QuotaByMacrozone/utils/filters";
import integrationService from "services/integrationService";
import {
  citiesResponseToSelectorParser,
  publicationTypeResponseToSelectorParser,
  standardParser,
} from "Components/Admin/administrator/integrations/utils/parsers";
import { IndexQuotaByMacrozoneFilters } from "services/integrationService/types";
import { useFirstRender } from "hooks";
import { businessTypesOptions } from "Components/Admin/administrator/integrations/utils/commonFilterOptions";
import initialFilters from "Components/Admin/administrator/integrations/pages/portalQuotas/utils/initialFilters";
import { setQuotaByMacrozoneFiltersAction } from "context/integrationContext/integrationActions";
import countryCode from "utils/countryCode";

interface Props {
  setCurrentFilters: Dispatch<SetStateAction<IndexQuotaByMacrozoneFilters>>;
}

function FilterOptions({ setCurrentFilters }: Props) {
  const classes = useStyles();
  const { state, dispatch: integrationDispatch } = useIntegration();
  const { state: tableState, dispatch } = useTable();
  const firstRender = useFirstRender();

  const handleCloseModal = () => dispatch(setShowModal(false));

  const handleSubmit = useCallback(() => {
    const tableFilters: QuotaByMacrozoneTableFilterTypes = {
      ...tableState.tableFilters,
      businessType: state.quotaByMacrozoneFilters.businessType,
      city: state.quotaByMacrozoneFilters?.city?.id as string,
      macrozone: state.quotaByMacrozoneFilters?.macrozone?.id as string,
      portal: state.quotaByMacrozoneFilters.portal?.id as string,
      portalPlan: state.quotaByMacrozoneFilters.portalPlan?.id as string,
    };
    dispatch(setTableFilters(tableFilters));
    handleCloseModal();
  }, [dispatch, state.quotaByMacrozoneFilters]);

  useEffect(() => {
    if (firstRender) return;
    handleDelete();
  }, [state.country]);

  const handleDelete = () => {
    setQuotaByMacrozoneFiltersAction(filterInitialValues, integrationDispatch);
    dispatch(setTableFilters({}));
    setCurrentFilters(initialFilters);
    handleCloseModal();
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      fullWidth
      maxWidth="md">
      <div className={classes.contentRoot}>
        <div className={classes.selectorContainer}>
          <InputLabel className={classes.selectorLabel} id="business-type-selector-label">
            Línea de negocio
          </InputLabel>
          <SelectionOptions
            id="businessType"
            simple
            compressed={false}
            value={state.quotaByMacrozoneFilters.businessType}
            onChange={(businessType) =>
              setQuotaByMacrozoneFiltersAction({ businessType }, integrationDispatch)
            }
            options={businessTypesOptions}
            classes={{
              root: classes.selectorRoot,
            }}
          />
        </div>
        <RemoteSuggester
          label="Portal"
          value={state.quotaByMacrozoneFilters.portal}
          onChange={(portal) => setQuotaByMacrozoneFiltersAction({ portal }, integrationDispatch)}
          indexService={integrationService.indexPortalNamesRequest}
          filters={{ country: state.country }}
          parser={standardParser}
          textFieldProps={{
            placeholder: "Selecciona un portal",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Destaque"
          value={state.quotaByMacrozoneFilters.portalPlan}
          onChange={(portalPlan) =>
            setQuotaByMacrozoneFiltersAction({ portalPlan }, integrationDispatch)
          }
          indexService={integrationService.indexPublicationTypesNamesRequest}
          filters={{
            country: state.country,
            portal: state.quotaByMacrozoneFilters?.portal?.id as string,
            business: state.quotaByMacrozoneFilters?.businessType,
          }}
          parser={publicationTypeResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Selecciona un destaque",
          }}
          classes={{ label: classes.suggesterLabel }}
          disabled={!state.quotaByMacrozoneFilters?.portal}
        />
        <RemoteSuggester
          label="Ciudad"
          value={state.quotaByMacrozoneFilters.city}
          onChange={(city) => setQuotaByMacrozoneFiltersAction({ city }, integrationDispatch)}
          indexService={integrationService.indexCitiesRequest}
          filters={{ country: countryCode(state.country) }}
          parser={citiesResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Seleccione una ciudad",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Macrozona"
          value={state.quotaByMacrozoneFilters.macrozone}
          onChange={(macrozone) =>
            setQuotaByMacrozoneFiltersAction({ macrozone }, integrationDispatch)
          }
          indexService={integrationService.indexMacrozoneNamesRequest}
          filters={{ country: state.country }}
          parser={standardParser}
          textFieldProps={{
            placeholder: "Seleccione una macrozona",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
      </div>
      <FilterDialog.Actions
        applyTitle="Filtrar"
        onDelete={handleDelete}
        onApply={handleSubmit}
        classes={{ applyButton: classes.applyFiltersButton }}
      />
    </FilterDialog>
  );
}

export default FilterOptions;
