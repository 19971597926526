import { Fragment } from "react";

import { Typography } from "@material-ui/core";
import { useQuery, UseQueryResult } from "react-query";

import { PropertyData } from "models/Property";
import propertyService from "services/propertyService/propertyService";
import { useGetToken } from "hooks";
import { countryCode } from "utils";
import { formatCurrency } from "utils/formatCurrency";

import Header from "./components/Header";
import PropertyInfo from "./components/PropertyInfo";
import Map from "./components/Map";
import PropertyReport from "./components/PropertyReport";
import {
  PriceRoot,
  DividerStyled,
  Subtitle,
  SimilarPropertyContainer,
  MapContainer,
} from "./PriceStyles";
import { MainProperty, SimilarProperties, Report } from "./utils/types";

interface Props {
  propertyId?: number;
  deal?: "rental" | "sale";
  property?: PropertyData;
}

function Price({ property = null, deal = "rental", propertyId }: Props) {
  // -----------------------------------------------------------
  const queryNamePrefix = "rev-to-pdf";
  const { getToken } = useGetToken();
  const { data: ownerPropertyData }: UseQueryResult<MainProperty, Error> = useQuery<
    MainProperty,
    Error
  >([`${queryNamePrefix}-propertyData`, propertyId], async () => {
    const authToken = await getToken();
    const response = await propertyService.getLandlordProperty(propertyId, authToken);
    return response?.data;
  });

  const { data: similarProperties }: UseQueryResult<SimilarProperties[], Error> = useQuery<
    SimilarProperties[],
    Error
  >([`${queryNamePrefix}-similarProperties`, propertyId], async () => {
    const authToken = await getToken();
    const response = await propertyService.getLandlordSimilarProperties(
      propertyId,
      deal === "rental" ? "rent" : "sale",
      authToken
    );
    return response?.data;
  });

  const { data: reports }: UseQueryResult<Report[], Error> = useQuery<Report[], Error>(
    [`${queryNamePrefix}-reports`, propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getLandlordPropertyActs(propertyId, authToken);
      return response?.data;
    }
  );

  // -----------------------------------------------------------

  const getRecommendedPrice = () => {
    let value = 0;
    let currency = "";
    if (deal === "rental") {
      value = ownerPropertyData?.vitruvio_suggest || 0;
      currency = ownerPropertyData?.property_details[0]?.currency_code;

      return formatCurrency(currency, value);
    }
    if (deal === "sale") {
      value = ownerPropertyData?.sales_details?.recommended_price || 0;
      currency = ownerPropertyData?.sales_details?.currency_code;

      return formatCurrency(currency, value);
    }
  };

  const getCurrentPrice = (thisData) => {
    let value = 0;
    let currency = "";
    if (deal === "rental") {
      value = thisData?.property_details[0]?.valor || 0;
      currency = thisData?.property_details[0]?.currency_code;

      return formatCurrency(currency, value);
    }
    if (deal === "sale") {
      value = thisData?.sales_details?.price || 0;
      currency = thisData?.sales_details?.currency_code;

      return formatCurrency(currency, value);
    }
  };

  return (
    <PriceRoot>
      <Header />
      <Subtitle>Tu propiedad</Subtitle>

      <PropertyInfo
        isOwnerProperty
        property={ownerPropertyData}
        currentPrice={getCurrentPrice(ownerPropertyData)}
        recommendedPrice={getRecommendedPrice()}
      />
      <DividerStyled />

      <SimilarPropertyContainer>
        <Subtitle>🧐 Conoce propiedades similares a la tuya</Subtitle>
        {(!similarProperties || similarProperties.length === 0) && (
          <Typography>No hay propiedades similares</Typography>
        )}
        {similarProperties?.map((propertyItem) => (
          <Fragment key={propertyItem.id}>
            <PropertyInfo
              property={{
                ...propertyItem,
              }}
              currentPrice={getCurrentPrice(propertyItem)}
            />
            <DividerStyled />
          </Fragment>
        ))}
      </SimilarPropertyContainer>

      <MapContainer>
        <Map
          deal={deal}
          lat={property?.property_details[0]?.latitud}
          lng={property?.property_details[0]?.longitud}
          locale={countryCode(property?.country)}
          similarProperties={similarProperties}
          ownerProperty={ownerPropertyData}
        />
      </MapContainer>
      <DividerStyled />

      <PropertyReport reports={reports} />
    </PriceRoot>
  );
}

export default Price;
