import { useCallback, useState } from "react";

const useObservationModal = () => {
  const [observationModal, setObservationModal] = useState(false);

  const onHandleModal = useCallback(
    (value: boolean) => {
      setObservationModal(value);
    },
    [observationModal]
  );

  return {
    observationModal,
    onHandleModal,
  };
};

export default useObservationModal;
