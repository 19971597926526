export const getErrorMessage = (message: string): string => {
  const UNPAID_LEASE_ERROR = "contract has an unpaid lease";

  switch (message) {
    case UNPAID_LEASE_ERROR:
      return "Hay un botón de pago en estado 'Pendiente' / 'Pendiente de pago'";
    default:
      return "Intente nuevamente más tarde o contactese con soporte";
  }
};
