import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { Subscription } from "models/Subscription";
import Renewal from "./components/Renewal";

interface Props {
  subscription: Subscription;
}

const RenewalView = ({ subscription }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="renewal">
      <Renewal {...subscription} />
    </TabPanel>
  );
};

export default RenewalView;
