import { Key } from "react";

import DetailsItem from "../DetailsItem";
import classes from "../../PromotionDetailsStyles";

type City = {
  id: Key;
  name: String;
};

type CitiesListProps = {
  cities: City[];
};

export default function CitiesList({ cities }: CitiesListProps) {
  return (
    <DetailsItem title="Ciudades en las que queda activa">
      <div className={classes.citiesContainer}>
        {cities.map((city) => (
          <span key={city.id}>{city.name}</span>
        ))}
      </div>
    </DetailsItem>
  );
}
