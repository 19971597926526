import { makeStyles, Theme } from "@material-ui/core";

const useMacrozoneIndexStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
}));

export default useMacrozoneIndexStyles;
