import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "auto",
    display: "flex",
    gap: theme.spacing(1),
  },
}));

export default useStyles;
