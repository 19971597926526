import { PlansProvider } from "context/plansContext/plansContext";
import { SnackbarProvider } from "notistack";
import PlansForm from "./PlansForm";

export default function CreatePlanIndex() {
  return (
    <PlansProvider>
      <SnackbarProvider>
        <PlansForm />
      </SnackbarProvider>
    </PlansProvider>
  );
}
