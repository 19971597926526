import { useCallback } from "react";

import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import { clearAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";

import Contract from "./Contract";

function index() {
  const {
    state: { alertInfo },
    dispatch,
  } = useAlert();

  const handleSnackbarClose = useCallback(() => {
    dispatch(clearAlertInfo());
  }, []);

  return (
    <>
      <Contract />
      <DesignSystemSnackbar
        type={alertInfo.type}
        open={alertInfo.open}
        onClose={handleSnackbarClose}
        head={alertInfo.head}
        body={alertInfo.body}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  );
}

export default index;
