import { makeStyles } from "@material-ui/core";

const useIndexStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexdirection: "row",
    gap: theme.spacing(3),
  },
}));

export default useIndexStyles;
