import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme, { align }>((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    width: "100%",
    textAlign: ({ align }) => align || "center",
  },
}));

export default useStyles;
