import {
  ChangePropertyStatusOperationType,
  SetAsLostReason,
  UnpublishReason,
} from "services/propertyVisibilityService/types";

interface ReasonSerializerArgs {
  reasons: SetAsLostReason[] | UnpublishReason[];
}

export interface SanitizedReason {
  label: string;
  value: string;
}

type ReasonSerializer = (args: ReasonSerializerArgs) => SanitizedReason[];

export const serializeUnpublishReasons: ReasonSerializer = ({ reasons }) =>
  reasons.map((reason) => ({
    label: reason.label,
    value: String(reason.value),
  }));

export const serializeSetAsLostReasons: ReasonSerializer = ({ reasons }) =>
  reasons.map((reason) => ({
    label: reason.name,
    value: String(reason.id),
  }));

export const operationReasonsSerializer = {
  [ChangePropertyStatusOperationType.UNPUBLISH]: serializeUnpublishReasons,
  [ChangePropertyStatusOperationType.SET_AS_LOST]: serializeSetAsLostReasons,
  //TODO update this callback when publish reasons are available
  [ChangePropertyStatusOperationType.PUBLISH]: serializeUnpublishReasons,
};
