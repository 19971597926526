import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import promotionService from "domains/promotion/service";
import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import { useGetToken } from "hooks";

const useDeletePromotion = (promotionId: number) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();

  const {
    mutate: deletePromotion,
    isError,
    isLoading: isLoadingDelete,
    isSuccess,
    error,
  } = useMutation(
    async () => {
      const authToken = await getToken();
      const response = await promotionService.deletePromotion(promotionId, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("promotions_list");
        dispatch(
          setAlertInfo({
            open: true,
            head: "La promoción se ha eliminado correctamente",
            type: "success",
          })
        );
        setTimeout(() => {
          history.push("/admin/promotions");
        }, 2000);
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error al eliminar promoción",
            type: "error",
            body: `La promoción no se pudo eliminar, error: ${reason ?? e}`,
          })
        );
      },
    }
  );

  return {
    deletePromotion,
    isLoadingDelete,
    isError,
    isSuccess,
    error,
  };
};

export default useDeletePromotion;
