import { useHistory } from "react-router-dom";

import { Avatar, Box, Chip, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { numberWithCommas } from "utils";
import { UserModel } from "models/User";

import DesignSystemButton from "../Buttons/DesignSystemButton";
import { AdminCard } from "../Cards/AdminCard";
import useUserStyles from "./UserStyles";

interface Codebtor {
  role: string;
  id: string;
  name: string;
  last_name: string;
  email: string;
  phone: string;
  rut: string;
  second_last_name: string;
  declared_income: number;
  document: string;
}

interface Props {
  actions?: React.ReactNode;
  type: "Propietario" | "Arrendatario" | "Codeudor" | "Coarrendatario" | "Aval";
  user: Codebtor | UserModel;
}

const User: React.FC<Props> = ({ user, type, actions }) => {
  const history = useHistory();
  const classes = useUserStyles();

  if (!user && type === "Arrendatario")
    return (
      <AdminCard title={type || "Usuario"} showTitleBackground={true} withSpace={false}>
        <Typography>No existe información de arrendatario</Typography>
      </AdminCard>
    );

  return (
    <AdminCard title={type || "Usuario"} showTitleBackground={true} withSpace={false}>
      <Grid container spacing={2}>
        {!user ? (
          <Typography>{`No existe información de ${type.toLowerCase()}`}</Typography>
        ) : (
          <>
            <Grid item container xs={4} spacing={1} justifyContent="center" alignItems="center">
              <Grid item>
                <Avatar alt="user" />
              </Grid>
              <Grid item>
                <DesignSystemButton
                  label="Modificar Usuario"
                  type="submit"
                  size="small"
                  variant="primary"
                  onClick={() => history.push(`/admin/users/${user.id}/edit`)}
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {user?.name ?? ""} {user?.last_name ?? ""}
              </Typography>
              <Typography>{user.email}</Typography>
              {user.document ? (
                <Box className={classes.documentContainer}>
                  <Typography>{user.document}</Typography>
                  <CheckCircleIcon className={classes.icon} />
                </Box>
              ) : (
                <Chip label="Falta Documento" className={classes.chip} />
              )}
              <Typography>{user.phone}</Typography>
              {type !== "Propietario" && (
                <Typography>
                  {user.declared_income
                    ? `Monto declarado: ${numberWithCommas(user.declared_income)}`
                    : null}
                </Typography>
              )}
              {(type === "Arrendatario" && actions) || null}
            </Grid>
          </>
        )}
      </Grid>
    </AdminCard>
  );
};

export default User;
