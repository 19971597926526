import { format, addMonths } from "date-fns";

//@see https://date-fns.org/v2.29.3/docs/Unicode-Tokens

const defaultFormat = "yyyy-MM-dd";

export const defaultBuyerRentalFilter = {
  status: { label: "Agendada", value: "Scheduled" },
  thirdCoord: { label: "Con Coordinación", value: 1 },
};

export const nextTwoMonthFilter = {
  beginDate: format(new Date(), defaultFormat),
  endDate: format(addMonths(new Date(), 2), defaultFormat),
};
