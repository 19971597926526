import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import useStyles from "../CreateMF/Components/Checkboxs/CheckboxsStyles";

interface Props {
  id: string;
  label: string;
  value: boolean;
}

function DisabledCheckbox({ id, label, value }: Props) {
  const classes = useStyles();
  return (
    <FormControl className={classes.wrapper}>
      <FormControlLabel
        classes={{
          label: classes.label,
          root: classes.wrapper,
        }}
        control={
          <Checkbox
            id={id}
            checked={value}
            classes={{
              root: classes.box,
            }}
            color="primary"
            disabled
          />
        }
        label={label}
        labelPlacement="start"
      />
    </FormControl>
  );
}
export default DisabledCheckbox;
