import * as yup from "yup";

export const requiredText = "Obligatorio";

export const initContractSchema = yup.object({
  selectedDate: yup.string().required(requiredText),
  tenantHasPets: yup.string().required(requiredText),
  childHabitants: yup.string().required(requiredText),
  adultHabitants: yup.string().required(requiredText),
  insuranceCompany: yup.string().required(requiredText),
});
