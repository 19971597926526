export const contractCurrencyParse = {
  Chile: {
    pesos: "CLP",
    uf: "CLF",
    "u.f.": "CLF",
  },
  Mexico: {
    pesos: "MXN",
  },
  Colombia: {
    pesos: "COP",
  },
};
