import { makeStyles, Theme } from "@material-ui/core";

const useWarrantyStatusStyles = makeStyles<Theme>((theme) => ({
  tag: {
    padding: theme.spacing(0.25, 1),
    borderRadius: 2,
    width: 70,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paid: {
    backgroundColor: "#439F03",
  },
  canceled: {
    backgroundColor: "#900000",
  },
  pending: {
    backgroundColor: "#A07800",
  },
  unliquidated: {
    backgroundColor: theme.palette.grey[500],
  },
  negative: {
    backgroundColor: "#AE0400",
    width: "auto",
  },
  tagText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(10),
    fontStyle: "italic",
    lineHeight: 2,
    color: "#FDF9E6",
  },
}));

export default useWarrantyStatusStyles;
