import { useHistory } from "react-router-dom";
import CreateForm from "./Components/Form/Form";
import BackButton from "../Components/BackButton";

import useStyles from "./indexStyles";

function CreateMF() {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => history.push("/admin/multifamily");

  return (
    <div className={classes.container}>
      <div>
        <BackButton backButtonProps={{ label: "Atras", onClick: handleBack }} />
      </div>
      <div className={classes.form}>
        <CreateForm />
      </div>
    </div>
  );
}
export default CreateMF;
