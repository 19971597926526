import Grid from "@material-ui/core/Grid";

import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { ContractModel } from "models/Contract";
import DocumentsGenerationProcess from "Components/Admin/UIComponents/DocumentsGenerationProcess";
import SignProcess from "Components/Admin/UIComponents/SignProcess";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

interface Props {
  contract: ContractModel;
}

const SignRenewal = ({ contract }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="signRenewal">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AdminCard title="Revisar borradores" withSpace={false} showTitleBackground={true}>
            <DocumentsGenerationProcess
              contractId={contract?.id}
              contractType={contract?.plan?.name}
              landlordObservations={contract?.landlord_observations}
              tenantObservations={contract?.observations}
            />
          </AdminCard>
        </Grid>
        <Grid item xs={12}>
          <AdminCard title="Estado de las firmas" withSpace={false} showTitleBackground={true}>
            <SignProcess contractId={contract?.id} />
          </AdminCard>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default SignRenewal;
