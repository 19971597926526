/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, makeStyles } from "@material-ui/core";
import { GridFilterListIcon } from "@material-ui/data-grid";
import * as React from "react";

type Props = React.ComponentProps<typeof Button>;

function FilterButton(props: Props) {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      disableElevation
      color="primary"
      classes={{ root: classes.rootButton }}
      style={{ height: 32 }}
      {...props}>
      <div className={classes.buttonContainer}>
        <GridFilterListIcon color="primary" className={classes.icon} />
        Filtrar
      </div>
    </Button>
  );
}

export default FilterButton;

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: { marginRight: 3 },
  rootButton: { maxHeight: 32 },
});
