import React, { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import moment from "moment";

import Divider from "./CustomDivider";
import {
  ControlledList,
  ControlledNumberField,
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { emptyPropertyDetails } from "Components/Admin/UIComponents/Cards/emptyDetails";
import { useProperty } from "context/propertyContext/propertyContext";
import { yesNoBoolean, yesNoBinary } from "utils/createProperty";
import { fromToOptions } from "utils/fromToOptions";
import { orientationOptions } from "utils/orientation";
import useUserFormRole from "hooks/useUserFormRole";
import {
  getApartmentTypeField,
  getHasLaundryRoomLabels,
  getParkingOptions,
} from "../utils/propertyFormFieldsInfo";
import isAdressInNuevaLeon from "Components/Admin/UIComponents/PropertyForm/utils/isAdressInNuevaLeon";

function PropertyDetails() {
  const { state } = useProperty();
  const { property } = state;
  const { control } = useFormContext();

  const role = useUserFormRole();

  const propertyDetails = useMemo(
    () => state.property?.property_details[0] || emptyPropertyDetails,
    [state.property?.property_details[0]]
  );

  const defaultYear = useMemo(() => {
    if (state.property?.year_built) {
      return state.property?.year_built;
    } else if (state.property?.antiquity) {
      return moment().year() - Number(state.property?.antiquity);
    }
  }, [state.property]);

  const type = useWatch({
    control,
    name: "type",
    defaultValue: state.property?.type,
  });

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.property?.country,
  });

  const address = useWatch({ control, name: "address_object" });

  return (
    <FormBlock>
      <h3>Características principales</h3>
      <ControlledNumberField
        id="m_terreno"
        defaultValue={
          propertyDetails?.m_terreno ? parseFloat(propertyDetails?.m_terreno) : undefined
        }
        label="Superficie total en m²"
        placeholder="231 m2"
        numberFieldProps={{
          suffix: " m²",
        }}
      />
      <ControlledNumberField
        id="m_construidos"
        defaultValue={
          propertyDetails?.m_construidos ? parseFloat(propertyDetails?.m_construidos) : undefined
        }
        label="Superficie interior construida en m²"
        placeholder="100 m²"
        numberFieldProps={{
          suffix: " m²",
        }}
      />
      {role !== "real_estate_developer" && (
        <>
          <ControlledNumberField
            id="terrace_size"
            defaultValue={
              propertyDetails?.terrace_size ? parseFloat(propertyDetails?.terrace_size) : undefined
            }
            label="Superficie de la terraza en m²"
            placeholder="25 m²"
            numberFieldProps={{
              suffix: " m²",
            }}
          />
          <ControlledNumberField
            id="loggia_size"
            defaultValue={
              propertyDetails?.loggia_size ? parseFloat(propertyDetails?.loggia_size) : undefined
            }
            label="Superficie de la loggia en m²"
            placeholder="10 m²"
            numberFieldProps={{
              suffix: " m²",
            }}
          />
          {type === "departamento" && (
            <>
              <ControlledList
                id="orientation"
                label="Orientacion"
                defaultValue={propertyDetails?.orientacion ?? ""}
                options={orientationOptions}
                disableUnderline={true}
              />
              <ControlledNumberField
                id="apartment_floor"
                label="Piso del departamento"
                defaultValue={state.property?.apartment_floor}
                placeholder="10"
              />
              {country && (
                <ControlledSelection
                  id="apartment_type"
                  optionWidth="40px"
                  defaultValue={propertyDetails.apartment_type}
                  label={getApartmentTypeField(country).label}
                  simple={true}
                  emptyValue=""
                  options={getApartmentTypeField(country).options}
                />
              )}
            </>
          )}
          <ControlledNumberField
            id="year_built"
            label="Año de construcción"
            defaultValue={defaultYear as number}
            placeholder="1984"
            numberFieldProps={{
              thousandSeparator: false,
              maxLength: 4,
            }}
          />
          <ControlledSelection
            id="is_new"
            optionWidth="40px"
            defaultValue={state.property?.is_new || false}
            label="Propiedad nueva, ¿La propiedad ha sido habitada antes?"
            options={yesNoBoolean}
          />
        </>
      )}
      <Divider />
      {country === "Colombia" && (
        <>
          <ControlledSelection
            id="stratus"
            defaultValue={state.property?.stratus}
            label="Estrato"
            optionWidth="30px"
            options={fromToOptions(1, 6)}
          />
          <Divider />
        </>
      )}
      <ControlledSelection
        id="dormitorios"
        defaultValue={propertyDetails.dormitorios}
        label="Número de habitaciones"
        optionWidth="30px"
        options={fromToOptions(1, 10)}
      />
      {role !== "real_estate_developer" && (
        <ControlledSelection
          id="en_suite"
          defaultValue={propertyDetails.en_suite}
          label="Número de dormitorios en suite"
          optionWidth="30px"
          options={fromToOptions(0, 4)}
        />
      )}
      <ControlledSelection
        id="banos"
        defaultValue={propertyDetails.banos}
        label="Número de baños"
        optionWidth="30px"
        options={fromToOptions(1, 8)}
      />
      {country === "Mexico" && (
        <ControlledSelection
          id="half_baths"
          defaultValue={propertyDetails.half_baths}
          label="Medios baños"
          optionWidth="30px"
          options={fromToOptions(0, 8)}
        />
      )}
      {role !== "real_estate_developer" && (
        <ControlledSelection
          id="servicios"
          defaultValue={propertyDetails.servicios}
          label="Número de piezas de servicio"
          optionWidth="30px"
          options={fromToOptions(0, 8)}
        />
      )}
      <ControlledSelection
        id="estacionamientos"
        defaultValue={propertyDetails.estacionamientos}
        label="Número de estacionamientos"
        optionWidth="30px"
        options={fromToOptions(0, 4)}
      />
      <Divider />
      <ControlledSelection
        id="bodega"
        optionWidth="40px"
        defaultValue={propertyDetails.bodega}
        label="Bodega"
        options={yesNoBoolean}
      />
      {role !== "real_estate_developer" && (
        <>
          <ControlledSelection
            id="kitchen_daily_dining_room"
            optionWidth="40px"
            defaultValue={propertyDetails.kitchen_daily_dining_room}
            label="Comedor de diario"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="terraza"
            optionWidth="40px"
            defaultValue={propertyDetails.terraza}
            label="Terraza"
            options={yesNoBinary}
          />
          <ControlledSelection
            id="mascotas"
            optionWidth="40px"
            defaultValue={propertyDetails.mascotas}
            label="Se permiten mascotas"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="has_private_roof_garden"
            optionWidth="40px"
            defaultValue={propertyDetails.has_roof_garden}
            label="Roof garden privado"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="calefaccion"
            optionWidth="40px"
            defaultValue={propertyDetails.calefaccion}
            label="Calefacción"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="curtain_hanger"
            optionWidth="40px"
            defaultValue={propertyDetails.curtain_hanger}
            label="Colgadores para cortinas"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="has_air_conditioning"
            optionWidth="40px"
            defaultValue={propertyDetails.has_air_conditioning}
            label="Aire acondicionado"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="kitchen_is_furnished"
            optionWidth="40px"
            defaultValue={propertyDetails.kitchen_is_furnished}
            label="Cocina equipada"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="laundry_capacity"
            optionWidth="40px"
            defaultValue={propertyDetails.laundry_capacity}
            label="Conexión para lavadora"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="is_renewed"
            optionWidth="40px"
            defaultValue={propertyDetails.is_renewed ?? false}
            label="¿Propiedad ha sido renovada?"
            options={yesNoBoolean}
          />
          <ControlledSelection
            id="furnished"
            optionWidth="40px"
            defaultValue={propertyDetails.furnished}
            label="Amoblado"
            emptyValue=""
            simple={true}
            options={[
              { value: "fully", label: "Amoblado" },
              { value: "partially", label: "Semi Amoblado" },
              { value: "non", label: "Vacío" },
            ]}
          />
          <ControlledSelection
            id="house_extension"
            optionWidth="40px"
            defaultValue={propertyDetails.house_extension}
            label="Ampliaciones"
            emptyValue=""
            simple={true}
            options={[
              { value: "regularized", label: "Regularizada" },
              { value: "in_process", label: "En proceso" },
              { value: "unregularized", label: "No regularizada" },
              { value: "none", label: "Sin ampliaciones" },
            ]}
          />
          <ControlledSelection
            id="hot_water_system"
            optionWidth="40px"
            defaultValue={propertyDetails.hot_water_system}
            label="Agua caliente"
            emptyValue=""
            simple={true}
            options={[
              { value: "thermal_store", label: "Termo" },
              { value: "boiler", label: "Caldera" },
              { value: "califont", label: "Calefont" },
            ]}
          />

          {country && ["Colombia", "Mexico"].includes(country) && (
            <ControlledSelection
              id="parking_type"
              optionWidth="40px"
              defaultValue={propertyDetails.parking_type}
              label="Tipo de estacionamiento"
              emptyValue=""
              simple={true}
              options={getParkingOptions(country)}
            />
          )}
          <ControlledSelection
            id="closet"
            optionWidth="40px"
            defaultValue={propertyDetails.closet}
            label="Closet"
            emptyValue=""
            simple={true}
            options={[
              { label: "Closet", value: "closet" },
              { label: "Walk-in closet", value: "walk_in" },
            ]}
          />
          {country && (
            <ControlledSelection
              id="has_laundry_room"
              optionWidth="40px"
              defaultValue={propertyDetails.has_laundry_room}
              label={getHasLaundryRoomLabels(country)}
              options={yesNoBoolean}
            />
          )}
          {country && ["Colombia", "Chile"].includes(country) && (
            <ControlledTextField
              id="environments"
              label="Ambientes"
              defaultValue={propertyDetails.environments}
              textFieldProps={{
                placeholder: "Introduzca N° de ambientes",
                InputProps: {
                  type: "number",
                },
              }}
            />
          )}
          {country && ["Colombia"].includes(country) && (
            <>
              <ControlledSelection
                id="has_clothing_area"
                label="Zona de ropa"
                optionWidth="40px"
                defaultValue={propertyDetails.has_clothing_area}
                options={yesNoBoolean}
              />
              <ControlledSelection
                id="is_construction_in_gray"
                label="Obra en gris"
                optionWidth="40px"
                defaultValue={propertyDetails.is_construction_in_gray}
                options={yesNoBoolean}
              />
            </>
          )}
          {(isAdressInNuevaLeon(address?.address_components) ||
            property?.region === "Nuevo León") && (
            <>
              <ControlledSelection
                id="has_water_tank"
                label="¿Tiene tinaco?"
                optionWidth="40px"
                defaultValue={propertyDetails.has_water_tank}
                options={yesNoBoolean}
              />
              <ControlledNumberField
                id="minisplits"
                label="N° de minisplits / módulos de aire acondicionado"
                defaultValue={propertyDetails.minisplits}
              />
            </>
          )}
        </>
      )}
    </FormBlock>
  );
}

export default React.memo(PropertyDetails);
