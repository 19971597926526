import { makeStyles, Theme } from "@material-ui/core";

export const useObservationStyles = makeStyles((theme: Theme) => ({
  editIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
  },
  closeButton: {
    zIndex: 1,
    color: "black",
  },
  observationContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  observationText: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
}));
