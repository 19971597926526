import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  galleryContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
    width: "100%",
    height: "100%",
  },
  loadingContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 3),
    width: "100%",
    height: "10px",
  },
  noItemsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 3),
    width: "100%",
  },
  noItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    margin: theme.spacing(5, 0),
  },
}));
