import { useEffect, useState } from "react";

import { useGetToken } from "hooks";

import { parseHoumAdvisors } from "Components/Admin/administrator/demand/utils/parseHoumAdvisors";

import { Typography, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import {
  updateCurrentProperty,
  setAppraiserId,
} from "context/demandLeadContext/schedule/demandScheduleActions";

import useHeaderInformationStyles from "./HeaderInformationStyle";

function HeaderInformation() {
  const classes = useHeaderInformationStyles();
  const [houmAdvisor, setHoumAdvisor] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Cualquiera");
  const {
    state: { property, appraiserSelectedId },
    dispatch: dispatchSchedule,
  } = useDemandSchedule();
  const {
    state: { schedules },
  } = useDemandLead();
  const { getToken } = useGetToken();

  useEffect(() => {
    setHoumAdvisor(parseHoumAdvisors(schedules));
  }, [schedules]);

  //Call every 60 seconds for disponibility
  useEffect(() => {
    const interval = setInterval(() => {
      handleGetAvailableHoursByHA(appraiserSelectedId);
    }, 60000);
    return () => clearInterval(interval);
  }, [appraiserSelectedId]);

  const handleGetAvailableHoursByHA = (appraiserId = undefined) => {
    const correctAppraiserValue = appraiserId === "Cualquiera" ? undefined : appraiserId;
    setSelectedValue(appraiserId);
    dispatchSchedule(setAppraiserId(correctAppraiserValue));
    getToken().then((authToken) => {
      updateCurrentProperty(
        property.id,
        "sales",
        dispatchSchedule,
        authToken,
        correctAppraiserValue,
        false
      );
    });
  };
  return (
    <div className={classes.container}>
      <Typography component="p" className={classes.title}>
        Agendamiento ID
      </Typography>
      <div className={classes.row}>
        <Typography component="p" className={classes.titleRow}>
          ID propiedad:
        </Typography>
        <Typography component="p">{property.id}</Typography>
      </div>
      <div className={classes.row}>
        <Typography component="p" className={classes.titleRow}>
          Dirección:
        </Typography>
        <Typography component="p">{property.address}</Typography>
      </div>
      {houmAdvisor && houmAdvisor.length > 0 && (
        <div className={classes.container}>
          <Typography component="p" className={classes.title}>
            Houm Advisor
          </Typography>
          <RadioGroup
            aria-label="selectedHoumer"
            name="HA"
            value={selectedValue}
            onChange={(e) => handleGetAvailableHoursByHA(e.target.value)}>
            {houmAdvisor &&
              houmAdvisor.map((houmer) => (
                <FormControlLabel
                  key={houmer.appraiserId}
                  value={houmer.appraiserId}
                  labelPlacement="end"
                  control={<Radio color="primary" />}
                  label={houmer.appraiser}
                />
              ))}
            <FormControlLabel
              value="Cualquiera"
              labelPlacement="end"
              control={<Radio color="primary" />}
              label="Cualquiera"
            />
          </RadioGroup>
        </div>
      )}
    </div>
  );
}

export default HeaderInformation;
