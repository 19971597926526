import { makeStyles, Theme } from "@material-ui/core";

const useScheduleRangeItemStyless = makeStyles((theme: Theme) => ({
  range: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(15),
  },
  containerDay: {
    maxWidth: 44,
  },
  nameDay: {
    maxWidth: 50,
    color: theme.palette.grey[900],
    fontWeight: 700,
  },
}));

export default useScheduleRangeItemStyless;
