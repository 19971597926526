import { useState } from "react";

import { Typography } from "@material-ui/core";

import SpaceItemsEditRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/edit/components/SpaceItemsEditRow";
import TableTitle from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableTitle";
import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import TableRowImagePreviewer from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableRowImagePreviewer";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useProperty } from "context/propertyContext/propertyContext";
import {
  inspectionReportItemsNameTranslation,
  inspectionReportQualificationOptions,
} from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";
import {
  filterImagesByName,
  getCurrentSpace,
} from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils";
import { getObjectLabel } from "utils";
import { titlesArrayBase, editTitlesArray } from "./utils";
import { SpaceItemImagesType } from "services/propertyService/types";

function SpaceItemsTable() {
  const [currentItemEditingName, setCurrentItemEditingName] = useState<string>(null);
  const { state } = useProperty();
  const isEditView = state.inspectionReportsCurrentView === "edit";

  const currentSpace = getCurrentSpace(
    state.inspectionReport?.data?.spaces,
    state.inspectionReport?.currentSpaceName
  );

  const currentSpaceItemsImages: SpaceItemImagesType = currentSpace?.items.map((item) => ({
    name: item.name,
    images: item.photo_links.map((photoLink) => photoLink.link),
  }));

  if (!currentSpace?.items) return null;

  return (
    <>
      <TableTitle
        isItemRow
        titlesArr={isEditView ? editTitlesArray : titlesArrayBase}
        isEdit={isEditView}
      />
      {currentSpace.items.map((item, index) =>
        currentItemEditingName === item.name ? (
          <SpaceItemsEditRow
            currentSpace={currentSpace}
            item={item}
            currentSpaceItemsImages={currentSpaceItemsImages}
            setCurrentItemEditingName={setCurrentItemEditingName}
          />
        ) : (
          <InspectionReportTableRow
            key={`item-${item.qualification}-${index + 1}`}
            isItemRow
            isEdit={isEditView}>
            <Typography>
              {getObjectLabel(inspectionReportItemsNameTranslation, item.name)}
            </Typography>
            <Typography align="center">{item.quantity}</Typography>
            <Typography>
              {getObjectLabel(inspectionReportQualificationOptions, item.qualification)}
            </Typography>
            <Typography>{item.observation ?? "Sin observación"}</Typography>
            <TableRowImagePreviewer
              rowName={`${getObjectLabel(inspectionReportItemsNameTranslation, item.name)} (${
                currentSpace.name
              })`}
              imagesArr={filterImagesByName(item.name, currentSpaceItemsImages)}
            />
            {isEditView && (
              <LinkButton onClick={() => setCurrentItemEditingName(item.name)}>Editar</LinkButton>
            )}
          </InspectionReportTableRow>
        )
      )}
    </>
  );
}

export default SpaceItemsTable;
