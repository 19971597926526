import { makeStyles, Theme } from "@material-ui/core";

const useEmptyValueStyles = makeStyles<Theme>((theme) => ({
  emptyValueContainer: {
    display: "inline-block",
    marginTop: theme.spacing(1.25),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    textTransform: "none",
    padding: theme.spacing(0, 0.5),
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: theme.palette.primary.main,
  },
  plusIcon: {
    width: 8.93,
    height: 8.93,
    stroke: "red",
    "& path": {
      stroke: theme.palette.primary.main,
    },
  },
}));

export default useEmptyValueStyles;
