import { Theme, makeStyles } from "@material-ui/core";

const useCardContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  loading: {
    marginBottom: theme.spacing(5),
  },
}));

export default useCardContainerStyles;
