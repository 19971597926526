import { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@material-ui/core";

import { parseCountryCurrencyCode } from "utils";
import { fromToOptions } from "utils/fromToOptions";
import { yesNoBoolean } from "utils/createProperty";
import { orientationOptions } from "utils/orientation";
import { optionsFromObject } from "utils/optionsFromObject";
import { useGetToken } from "hooks";
import { NewUnitForm } from "context/projectContext/projectTypes";
import { useProject } from "context/projectContext/projectContext";
import { postUnit, editUnit } from "context/projectContext/projectActions";
import {
  ControlledList,
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import validationSchema from "./validationSchema";
import useStyles from "../useLayoutFormStyles";
import { statusOptions } from "../LayoutsUtils";

interface Props {
  layoutId: number;
  model?: NewUnitForm;
  currency: string;
  country: string;
  floors: number;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

function UnitForm({ layoutId, currency, country, floors, handleClose, model = null }: Props) {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProject();
  const classes = useStyles();

  const maxFloors = floors > 0 ? floors : 30;
  const currencyCode = useMemo(
    () => parseCountryCurrencyCode(country, currency),
    [country, currency]
  );

  const methods = useForm<NewUnitForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });
  const onSubmit: SubmitHandler<NewUnitForm> = async (data, e) => {
    const authToken = await getToken();
    if (model?.id) {
      await editUnit(model.id, data, layoutId, currencyCode, dispatch, authToken);
    } else {
      await postUnit(layoutId, data, currencyCode, dispatch, authToken);
    }
    handleClose(false);
  };
  return (
    <FormProvider {...methods} key="LayoutForm">
      <form key="NewUnitForm" onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledTextField
                id="name"
                label="Nombre de la unidad"
                defaultValue={model?.name}
                labelClassName={classes.titleLabel}
                textFieldProps={{
                  placeholder: "Nombre",
                  InputProps: { disableUnderline: false },
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledTextField
                id="price"
                label="Precio"
                defaultValue={model?.price}
                labelClassName={classes.titleLabel}
                textFieldProps={{
                  placeholder: "400,000",
                  InputProps: {
                    disableUnderline: false,
                    startAdornment: (
                      <Typography component="span" className={classes.inputAdornment}>
                        {currency}
                      </Typography>
                    ),
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledList
                id="status"
                label="Estado del proyecto"
                options={optionsFromObject(statusOptions)}
                defaultValue={model?.status || "available"}
                disableUnderline={true}
                classes={{
                  label: classes.titleLabel,
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledList
                id="orientation"
                label=""
                placeholder="Orientacion"
                defaultValue={model?.orientation}
                options={orientationOptions}
                disableUnderline={true}
              />
            </Grid>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledList
                id="floor"
                label=""
                placeholder="Piso"
                defaultValue={model?.floor}
                options={fromToOptions(1, maxFloors)}
                disableUnderline={true}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledSelection
                id="parking_spaces"
                label="Estacionamiento"
                defaultValue={model?.parking_spaces}
                optionWidth="30px"
                options={fromToOptions(0, 5)}
                round
                classes={{
                  container: classes.itemContent,
                  label: classes.titleLabel,
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.formItem}>
              <ControlledSelection
                id="has_storage"
                label="Bodega"
                optionWidth="30px"
                defaultValue={model?.has_storage}
                options={yesNoBoolean}
                round
                classes={{
                  container: classes.itemContent,
                  label: classes.titleLabel,
                }}
              />
            </Grid>
            <Grid
              container
              item
              alignContent="center"
              justifyContent="flex-end"
              className={classes.buttonsContainer}>
              <LinkButton onClick={() => handleClose(false)}>Cancelar</LinkButton>
              <DesignSystemButton
                disabled={state.loadingUpload}
                type="submit"
                variant="tertiaryA"
                size="small"
                label="Guardar"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default UnitForm;
