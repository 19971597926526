import { Box, Grid, Hidden, Typography } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import { LandlordSummary } from "models/AdminModels/Landlord";

import LandlordDataGrid from "./components/LandlordDataGrid";
import ReportDownload from "./components/ReportDownload";
import VipChip from "./components/VipChip";
import useLandlordDataStyles from "./LandlordStyles";

interface Props {
  isLoading: boolean;
  error?: Error;
  data: LandlordSummary;
}

function LandlordData({ isLoading, error, data }: Props) {
  const classes = useLandlordDataStyles();

  if (isLoading)
    return (
      <Box className={classes.landlordContainer}>
        <Spinner />
      </Box>
    );

  if (error)
    return (
      <Box className={classes.landlordContainer}>Error cargando los datos del propietario</Box>
    );

  return (
    <Box className={classes.landlordContainer} role="container">
      <Box className={classes.headerContainer}>
        <Typography className={classes.title}>{data.fullName}</Typography>
        {data.userType === "vip" && <VipChip />}
      </Box>
      <Grid container className={classes.infoGrid}>
        <Grid item xs={12}>
          <LandlordDataGrid data={data} />
        </Grid>
        {/* HIDDEN FOR FUTURE DEVELOPMENT */}
        <Hidden xsUp>
          <Grid item xs={2} className={classes.reportContainer}>
            <ReportDownload />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}

export default LandlordData;
