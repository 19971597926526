import { useEffect, useState } from "react";
import clx from "classnames";
import moment from "moment";
import axios from "axios";

import { Chip } from "@material-ui/core";

import useReservationStatusStyles from "./ReservationStatusStyles";
import newPaymentService from "services/newPaymentService";
import applicantService from "services/applicantService";
import countryCode from "utils/countryCode";
import Loading from "../molecules/Loading";
import { useGetToken } from "hooks";

interface Props {
  applicantId: string;
}

function ReservationStatus({ applicantId }: Props) {
  const { getToken } = useGetToken();
  const classes = useReservationStatusStyles();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("No pagado");

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    getToken().then(async (authToken) => {
      try {
        const applicant = await applicantService.get(applicantId, authToken);
        const reservationStatus = await newPaymentService.getUserReservations(
          countryCode(applicant.data.property.country),
          applicant.data.user_id,
          applicant.data.property.id,
          {
            dateFrom: "2020-01-01",
            dateTo: moment().add("days", 31).format("YYYY-MM-DD"),
          },
          authToken,
          source.token
        );
        const { movements } = reservationStatus.data;
        if (movements[0] && ["PAID"].includes(movements[0].status)) {
          setStatus("Pagado");
        }
      } catch (error) {
        if (axios.isCancel(error)) return;
        setStatus("Error");
      } finally {
        setLoading(false);
      }
    });

    return () => source.cancel();
  }, [applicantId]);

  return (
    <Loading loading={loading} size={20}>
      <Chip
        label={status}
        size="small"
        className={clx({
          [classes.chip]: status === "Pagado",
        })}
      />
    </Loading>
  );
}

export default ReservationStatus;
