import { CurrencyCode } from "models/Currencies";

const parseCountryCurrencyCode = (country: string, currency: string): CurrencyCode => {
  if (currency === "U.F.") return "CLF";
  switch (country.toLocaleLowerCase()) {
    case "chile":
      return "CLP";
    case "mexico":
      return "MXN";
    case "colombia":
      return "COP";
    default:
      return null;
  }
};

export default parseCountryCurrencyCode;
