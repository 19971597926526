import { cn } from "@houm-com/ui/utils";
import { NavLink, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { IoChevronForwardOutline as ExpandIcon } from "@houm-com/ui-icons";
import { items } from "apps/main/Router";
import useGuard from "domains/auth/hooks/useGuard";
import { RouteItem } from "domains/auth/models/RouteItem";

function useIsActiveMenu() {
  const { pathname } = useLocation();
  return (item: RouteItem) => {
    if (pathname && item.to === pathname) return true;
    if (item.children) {
      return item.children.some((child) => child.to === pathname);
    }
    return false;
  };
}

function useShouldRender() {
  const { guard } = useGuard();

  function shouldRender(item: RouteItem) {
    let show = true;
    if (!item.to) show = false;
    if (item.children) show = item.children.some((child) => shouldRender(child));
    if ("require" in item && "scope" in item && "strategy" in item)
      show = guard[item.scope][item.strategy](item.require);
    if (!item.label) show = false;
    return show;
  }

  return (item: RouteItem) => shouldRender(item);
}

function MenuItemLink({ item, isRootItem = false }: { item: RouteItem; isRootItem?: boolean }) {
  return (
    <NavLink
      to={item.to || "/"}
      activeClassName="text-primary-cta"
      className={cn(
        "hover:text-primary-cta hover:bg-container",
        "group flex gap-x-3 rounded-md p-2",
        isRootItem ? "font-semibold" : "pr-2 pl-9"
      )}>
      {item.icon && <item.icon className={cn("h-6 w-6 shrink-0")} aria-hidden="true" />}
      {item.label}
    </NavLink>
  );
}

function MenuItemExampable({ item }: { item: RouteItem }) {
  const isActiveMenu = useIsActiveMenu();
  const shouldRender = useShouldRender();
  return (
    <Disclosure as="div" defaultOpen={isActiveMenu(item)}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={cn(
              "hover:text-primary-cta hover:bg-container",
              "items-center w-full group flex gap-x-3 rounded-md p-2 font-semibold"
            )}>
            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
            {item.label}
            <ExpandIcon
              className={cn(open && "rotate-90", "ml-auto h-5 w-5 shrink-0")}
              aria-hidden="true"
            />
          </Disclosure.Button>
          <Disclosure.Panel as="ul" className="mt-1 px-2">
            {item.children.map(
              (subItem) =>
                shouldRender(subItem) && (
                  <li key={subItem.label}>
                    <MenuItemLink item={subItem} />
                  </li>
                )
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default function MenuItems() {
  const shouldRender = useShouldRender();
  return (
    <ul className="-mx-2 space-y-1">
      {items.map(
        (item) =>
          shouldRender(item) && (
            <li key={item.label}>
              {item.children ? (
                <MenuItemExampable item={item} />
              ) : (
                item.to && <MenuItemLink item={item} isRootItem />
              )}
            </li>
          )
      )}
    </ul>
  );
}
