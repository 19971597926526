import { LetterOfIntentProvider } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import LetterOfIntentNegotiationDetailIndex from "./LetterOfIntentDetailIndex";

export default function LetterOfIntentDetail() {
  return (
    <LetterOfIntentProvider>
      <LetterOfIntentNegotiationDetailIndex />
    </LetterOfIntentProvider>
  );
}
