import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  videos: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(20),
  },
  radioContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  title: {
    fontSize: "1.25rem",
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

export default useStyles;
