import { useState, useEffect } from "react";
import Gallery from "react-photo-gallery";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import PhotoUploader from "./PhotoUploader";
import Photo from "./Photo";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { updateTypologyPhotosOrder } from "context/multifamilyContext/typology/typologyActions";
import { updateMultifamilyPhotosOrder } from "context/multifamilyContext/multifamily/multifamilyActions";
import { MFImages, sortedPhotos } from "context/multifamilyContext/multifamily/multifamilyTypes";

import { useGetToken } from "hooks";

import usePhotosGalleryStyles from "./PhotosGalleryStyles";

const SortablePhoto = SortableElement((item) => (
  <Photo
    type={item.type}
    uid={item.uid}
    index={item.index}
    onClick={item.onClick}
    photo={item.photo}
  />
));
const SortableGallery = SortableContainer(({ items, uid, type }) => (
  <Gallery
    photos={items}
    renderImage={(props) => <SortablePhoto uid={uid} type={type} {...props} />}
  />
));

interface Props {
  photos: MFImages[];
  uid: string;
  url: string;
  type: string;
  post: string;
  delete: string;
  extensions: string[];
  headerName: string;
}

function PhotosGallery(props: Props) {
  const { getToken } = useGetToken();
  const {
    state: { currentId: UTid },
    dispatch: UTDispatch,
  } = useTypology();
  const {
    state: { currentId: MFid },
    dispatch: MFDispatch,
  } = useMultifamily();
  const classes = usePhotosGalleryStyles();
  const [items, setItems] = useState<MFImages[]>(props.photos || []);
  const [type, setType] = useState<string>(props.type);

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  const getSorted = () => {
    const arr = [];
    for (let index = 0; index < items.length; index++) {
      const element = { ...items[index], order: index };
      arr.push(element);
    }
    return arr;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sorted = arrayMove(items, oldIndex, newIndex);
    const arr = [];
    for (let index = 0; index < sorted.length; index++) {
      const element = {
        ...sorted[index],
        order: index,
      };
      arr.push(element);
    }
    setItems(arr);
  };

  const handleUpdatePhotosOrder = async () => {
    const authToken = await getToken();
    const body = { photos: getSorted() };
    if (type === "typology") {
      await updateTypologyPhotosOrder(UTid, body as sortedPhotos, UTDispatch, authToken);
    }
    if (type === "multifamily") {
      await updateMultifamilyPhotosOrder(MFid, body as sortedPhotos, MFDispatch, authToken);
    }
  };

  return (
    <div className={classes.container}>
      <h2>{props.headerName}</h2>
      <p>Puedes reorganizarlas arrastrando las imágenes</p>
      <PhotoUploader {...props} />
      <SortableGallery
        items={items}
        type={props.type}
        uid={props.uid}
        onSortEnd={onSortEnd}
        axis="xy"
      />
      <DesignSystemButton
        buttonClassName={classes.button}
        label="Actualizar orden"
        size="medium"
        variant="secondary"
        onClick={handleUpdatePhotosOrder}
      />
    </div>
  );
}

export default PhotosGallery;
