import { Timezones } from "models/Countries";
import moment from "moment";
import { Availability } from "services/appraiserService/types";

import { TimeBlock } from "./eventTypes";

export interface ResReasons {
  id: number;
  text: string;
}

export const parseReasons = (data: ResReasons[]) => {
  if (data) {
    return data.map((item) => ({
      value: item.id,
      label: item.text,
    }));
  }
  return [];
};

/**
 * @param availability: Availability from parse appraiser availablity response
 * @returns object where the key is the day (1 to 7) and the values are an array of IS08601 date time
 */
export const parseAppraiserAv = (availability: TimeBlock[]) => {
  const sorted = availability.sort(
    (a, b) => moment(a.beginHour, "HH:mm:ss").hour() - moment(b.beginHour, "HH:mm:ss").hour()
  );
  const parseAvailability = sorted.reduce((acc, { beginHour, day }) => {
    const begin = moment(beginHour, "HH:mm").toISOString();
    return {
      ...acc,
      [day]: acc[day] ? [...acc[day], begin] : [begin],
    };
  }, {});
  return parseAvailability;
};

export const parseNewAppraiserAv = (availability: Availability[], timezone: Timezones) => {
  const parseAvailability = availability.reduce((acc, { begin_date }) => {
    const day = moment(begin_date).day();
    const realDay = day === 0 ? 7 : day;
    const begin = moment.tz(begin_date, timezone).toISOString();
    return {
      ...acc,
      [realDay]: acc[realDay] ? [...acc[realDay], begin] : [begin],
    };
  }, {});
  return parseAvailability;
};

export const eventTypesMap = {
  Keys: "Llaves",
  Visit: "Visita renta",
  Advisory: "Asesoria",
  Photo: "Fotos renta",
  SalesPhoto: "Fotos venta",
  SalesVisit: "Visita venta",
  Inspection: "Inspección",
  Cleaning: "Limpieza",
};
