import { makeStyles, Theme } from "@material-ui/core";

const useCitySelectorStyles = makeStyles<Theme>((theme) => ({
  dialogContainer: {
    padding: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(0, 1),
  },
  formControl: {
    width: "100%",
  },
  title: {
    fontSize: 20,
    lineHeight: 1.4,
    marginBottom: theme.spacing(2),
  },
  selectLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.5,
  },
  select: {
    borderRadius: 4,
  },
  asterisk: {
    color: theme.palette.primary.main,
  },
  cityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cityName: {
    fontSize: theme.typography.pxToRem(16),
  },
  addCity: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
  menu: {
    marginTop: theme.spacing(1),
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey[50],
  },
  selectedCitiesContainer: {
    marginTop: theme.spacing(1),
  },
  formContainer: {
    overflowY: "auto",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useCitySelectorStyles;
