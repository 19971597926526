import { format } from "date-fns";
import { isEmpty } from "lodash";
import { KeysInfoResponse } from "services/propertyService/types";
import { IKeys } from "./types";

const KEYS_LOCATIONS = {
  houmbox: "Houmbox",
  landlord: "Propietario",
  concierge: "Conserje",
  appraiser: "Houmer",
};

export const parseKeys = (results: KeysInfoResponse): IKeys => {
  if (isEmpty(results)) return undefined;
  return {
    updatedAt: format(new Date(results.updated_at), "dd/MM/yyyy"),
    houmboxLockNumber: results.houmbox_lock_number,
    location: KEYS_LOCATIONS[results.location],
  };
};
