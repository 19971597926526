import { Grid, Typography } from "@material-ui/core";

import { PropertyData } from "models/Property";
import { getMinLayout } from "utils/getMinLayout";
import { orientationOptions } from "utils/orientation";

import useStyles from "./CharacteristicsListStyles";

const houseExtensions = {
  regularized: "Regularizada",
  unregularized: "No regularizada",
  in_process: "En proceso",
  none: "Sin ampliaciones",
};

interface Props {
  property?: PropertyData;
}

function CharacteristicList({ property = null }: Props) {
  const classes = useStyles();

  const isProject = property?.user?.role === "real_estate_developer";
  const minLayout: any = property?.unit_types?.length > 0 ? getMinLayout(property) : null;

  const getOrientation = () => {
    if (property?.property_details[0]?.orientacion) {
      const orientation = orientationOptions.find(
        (option) => option.value === property?.property_details[0]?.orientacion
      );
      return orientation?.label;
    }
    return "";
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Typography className={classes.title}>
          🛋 {property?.property_details[0]?.environments || ""} Ambientes
        </Typography>
        <Typography className={classes.text}>
          {isProject ? minLayout?.rooms : property?.property_details[0]?.dormitorios} dormitorios
        </Typography>
        <Typography className={classes.text}>
          {isProject ? minLayout?.bathrooms ?? "" : property?.property_details[0]?.banos} baños
        </Typography>
        {property.country === "Mexico" && (
          <Typography className={classes.textLight}>
            {property?.half_baths || ""} medios baños
          </Typography>
        )}
        <Typography className={classes.text}>
          {property?.property_details[0]?.estacionamientos || "sin "} estacionamientos
        </Typography>
        {property?.property_details[0]?.estacionamientos > 0 && (
          <>
            <Typography className={classes.textLight}>
              Tipo de estacionemiento: {property?.property_details[0]?.parking_type || "---"}
            </Typography>
            <Typography className={classes.textLight}>
              Número de estacionemiento: {property?.property_details[0]?.parking_numbers || "---"}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography className={classes.title}>📐 Área</Typography>
        <Typography className={classes.text}>
          {isProject ? minLayout?.building_area ?? "" : property?.property_details[0]?.m_terreno}
          m² de superficie total
        </Typography>
        <Typography className={classes.textLight}>
          {isProject ? minLayout?.usable_area ?? "" : property?.property_details[0]?.m_construidos}
          m² de superficie construida
        </Typography>
        <Typography className={classes.textLight}>
          {property?.property_details[0]?.terrace_size || "---"}m² de superficie de terraza
        </Typography>
        <Typography className={classes.title}>🔧 Sobre su construcción</Typography>
        <Typography className={classes.text}>Ampliación</Typography>
        <Typography className={classes.textLight}>
          {houseExtensions[property?.property_details[0]?.house_extension]}
        </Typography>
        <Typography className={classes.text}>
          Construida en: {property?.year_built || "---"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography className={classes.title}>🏠 Sobre la propiedad</Typography>
        <Typography className={classes.text}>Tipo de propiedad</Typography>
        <Typography className={classes.textLight}>{property?.type || "---"}</Typography>
        <Typography className={classes.text}>Departamento</Typography>
        <Typography className={classes.textLight}>
          {property?.property_details[0]?.apartment_type || "---"}
        </Typography>
        <Typography className={classes.text}>Está en un condominio</Typography>
        <Typography className={classes.textLight}>
          {property?.property_details[0]?.condominio ? "Sí" : "No"}
        </Typography>
        <Typography className={classes.text}>Orientación</Typography>
        <Typography className={classes.textLight}>{getOrientation()}</Typography>
      </Grid>
    </Grid>
  );
}

export default CharacteristicList;
