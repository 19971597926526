import * as yup from "yup";

export const requiredText = "Obligatorio";

export const evaluatorFilterFormSchema = yup.object({
  evaluationId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  memberStatus: yup.string().nullable(),
  applicantName: yup.string().nullable(),
  propertyId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  createdAtFilter: yup.string().nullable(),
  updatedAtFilter: yup.string().nullable(),
});
