import { useEffect, useState, memo } from "react";
import { useParams } from "react-router-dom";

import { useGetToken } from "hooks";
import {
  getExecutives,
  getActivityNames,
  getLostReasons,
  getFinancialTypes,
  getCities,
} from "context/demandLeadContext/list/listActions";
import { useTab } from "context/tabContext/tabContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useList } from "context/demandLeadContext/list/listContext";
import {
  getLead,
  getSchedules,
  getViewedProperties,
} from "context/demandLeadContext/lead/demandLeadActions";
import {
  updateCurrentProperty,
  setIsOpenScheduleAdvisoryModal,
} from "context/demandLeadContext/schedule/demandScheduleActions";

import Menu from "./Menu";
import Title from "./Title";
import ReviewInformation from "./modals/ReviewInformation";
import SaveInformation from "./modals/SaveInformation";
import Success from "./modals/Success";
import Error from "./modals/Error";
import SuccessScheduled from "./modals/SuccessScheduled";
import NoAvailability from "./modals/NoAvailability";
import ScheduleAdvisory from "./schedule/advisory/ScheduleAdvisory";

import SimilarProperties from "./schedule/SimilarProperties";
import Lead from "./Lead";
import ActivityForm from "./forms/ActivityForm";
import ActivityTable from "./table/ActivityTable";
import Profiling from "./Profiling";

import PropertySearchBar from "./schedule/PropertySearchBar";
import SelectedProperty from "./schedule/SelectedProperty";
import SchedulingForm from "./schedule/SchedulingForm";
import HeaderInformation from "./schedule/HeaderInformation";
import IncompleteInformation from "./schedule/IncompleteInformation";
import SchedulesTable from "./schedule/table/Table";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useIndexStyles from "./DemandLeadIndexStyles";

function DemandLeadIndex() {
  const classes = useIndexStyles();
  const [repeat, setRepeat] = useState(false);
  const {
    dispatch,
    state: { lead, activities, viewedProperties },
  } = useDemandLead();
  const {
    state: { currentTabValue },
  } = useTab();

  const {
    state: { isScheduling, property, availability, isOpenSuccessScheduled },
    dispatch: dispatchSchedule,
  } = useDemandSchedule();

  const { dispatch: dispatchList } = useList();

  const { getToken } = useGetToken();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setRepeat(true);
    if (repeat) {
      getToken().then((authToken) => {
        getLead(dispatch, id, authToken);
      });
    }
  }, [repeat]);

  useEffect(() => {
    if (lead) {
      getToken().then((authToken) => {
        getSchedules(dispatch, lead.lead?.email, lead.lead?.phone, authToken);
      });
    }
  }, [lead, isOpenSuccessScheduled]);

  useEffect(() => {
    if (lead && repeat) {
      getToken().then((authToken) => {
        getExecutives(
          dispatchList,
          lead.lead.country,
          authToken,
          lead.status.business_type === "sales" ? "sales" : "rentals"
        );
        getActivityNames(dispatchList, authToken);
        getLostReasons(dispatchList, authToken);

        getFinancialTypes(dispatchList, authToken, lead.lead.country);
        getCities(dispatchList, authToken, lead.lead.country);
      });
    }
  }, [lead?.lead.country]);

  useEffect(() => {
    if (activities.length > 0) {
      getToken().then((authToken) => getViewedProperties(activities, dispatch, authToken));
    }
  }, [activities.length]);

  useEffect(() => {
    if (viewedProperties.length > 0 && !property) {
      const firstViewedProperty = activities.find((item) => item.property_id);
      if (!firstViewedProperty || currentTabValue === "contacto") return;

      getToken().then((authToken) => {
        updateCurrentProperty(
          firstViewedProperty.property_id,
          "sales",
          dispatchSchedule,
          authToken
        );
      });
    }
  }, [viewedProperties, currentTabValue]);

  const handleAdvisoryModal = () => {
    dispatchSchedule(setIsOpenScheduleAdvisoryModal(true));
  };

  return (
    <>
      {/* Modals */}
      <Error />
      <ReviewInformation />
      <SaveInformation />
      <Success />
      <SuccessScheduled />
      {/* Modals */}
      <Menu />
      <Lead />
      <Profiling />
      {currentTabValue === "contacto" && (
        <>
          <Title>Historial de contacto</Title>
          <ActivityForm />
          <ActivityTable />
        </>
      )}
      {currentTabValue === "agendar" && (
        <>
          <NoAvailability />
          {!lead?.lead?.phone || !lead?.lead?.email ? (
            <IncompleteInformation />
          ) : (
            <>
              {!isScheduling && (
                <>
                  <ScheduleAdvisory />
                  <div className={classes.container}>
                    <Title>Agendamiento</Title>
                    <DesignSystemButton
                      onClick={handleAdvisoryModal}
                      label="Agendar Asesoría"
                      size="small"
                      variant="tertiaryA"
                    />
                  </div>
                  <SchedulesTable />
                  <Title>Busqueda de propiedades</Title>
                  <PropertySearchBar />
                </>
              )}
              {property && !isScheduling && <SelectedProperty />}

              {isScheduling && availability && (
                <>
                  <HeaderInformation />
                  <SchedulingForm />
                </>
              )}
              {property && <SimilarProperties />}
            </>
          )}
        </>
      )}
    </>
  );
}
export default memo(DemandLeadIndex);
