import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1, 2),
  },
  formGrid: {
    padding: theme.spacing(2.25),
  },
  submitButton: {
    marginBottom: theme.spacing(11),
    textTransform: "none",
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "1.25rem",
    fontWeight: "bold",
    height: 48,
    width: 224,
    borderRadius: 24,
    background: "white",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      color: "white",
    },
  },
}));

export default useStyles;
