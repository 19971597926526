import { useEffect, useState } from "react";
import { StandardTextFieldProps } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { IUserAutoComplete } from "models/createProperty";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { useDebounceState } from "domains/common/utils/debounce-state";

export interface OptionProps {
  name: string;
  lastName: string;
  email: string;
  id: string | number;
  role?: string;
}

function Option({ name, lastName, email, id, role = null }: OptionProps) {
  return (
    <div className="flex flex-col">
      <div>
        {name} {lastName} (id: {id})
      </div>
      <div>{email}</div>
      {role && <div>role: {role}</div>}
    </div>
  );
}

export interface Props {
  label: string;
  id: string;
  loading: boolean;
  onChange: (e: any) => void;
  onQueryChange?: (querySearch: string) => void;
  value: IUserAutoComplete;
  defaultValue?: IUserAutoComplete;
  errorMessage?: string;
  textFieldProps?: StandardTextFieldProps;
  classes?: {
    container?: string;
    label?: string;
  };
  error?: boolean;
  users: IUserAutoComplete[];
  noOptionsText?: React.ReactNode;
}

function UserAutocomplete({
  id,
  label,
  value,
  onChange,
  error,
  loading,
  errorMessage = "Error",
  defaultValue,
  textFieldProps,
  users,
  onQueryChange = null,
  classes: propClasses,
  noOptionsText = "No se encontraron resultados",
}: Props) {
  const [selectedUser, setSelectedUser] = useState<IUserAutoComplete>(value);
  const [innerInputValue, setInnerInputValue] = useState("");
  const debounceState = useDebounceState(innerInputValue);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    onChange(user);
  };

  useEffect(() => {
    if (defaultValue) setSelectedUser(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (debounceState) {
      onQueryChange?.(debounceState);
    }
  }, [debounceState, onQueryChange]);

  const handleOnSearchChange = (input: string) => {
    setInnerInputValue(input);
  };

  const showNoOptionsText = !loading && !users?.length && !!debounceState && !selectedUser;

  return (
    <Autocomplete
      noOptionsText={showNoOptionsText ? noOptionsText : null}
      freeSolo={!showNoOptionsText}
      defaultValue={defaultValue}
      value={selectedUser}
      loading={loading}
      loadingText="Buscando..."
      options={users ?? []}
      onChange={(_, user) => handleSelectUser(user)}
      onInputChange={(e, val) => handleOnSearchChange(val)}
      getOptionLabel={(option) =>
        `${option.name} ${option.last_name || ""},  ${option.email || ""} `
      }
      renderOption={(option) => (
        <Option
          name={option.name}
          lastName={option.last_name}
          email={option.email}
          id={option.id}
          role={option?.role}
        />
      )}
      renderInput={(params) => (
        <CustomTextField
          fullWidth
          label={label}
          {...params}
          error={error}
          InputProps={{
            ...params.InputProps,
            className:
              "px-2 py-1 text-p14 md:text-p16 bg-secondary-10 rounded w-full placeholder-black-60 disabled:bg-transparent disabled:text-slate-500 disabled:shadow-none focus:outline-none focus:border-information-100 focus:ring-1 focus:ring-information-100 invalid:border-error-100 focus:invalid:border-error-100 focus:invalid:ring-error-100",
          }}
          id={id}
          errorMessage={errorMessage}
          {...textFieldProps}
          labelClassName={propClasses?.label}
        />
      )}
    />
  );
}
export default UserAutocomplete;
