import { useEffect, useState, useRef } from "react";
import { UserResponse } from "models/User";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import userService from "services/userService";
import { useGetToken } from "hooks";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage } from "context/tableContext/tableActions";
import {
  setCountry,
  setAgent,
  getVisits,
  getHoumers,
  getAgents,
  setFilters,
  setRoles,
  setDeal,
  getCancelReasons,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

import Header from "Components/Admin/administrator/demand/components/NewHeaderWithCountries";
import SheduledTable from "Components/Admin/administrator/demand/visits/Tenants/Components/table/Table";
import FilterBar from "Components/Admin/administrator/demand/visits/Tenants/Components/filter/FilterBar";
import TagBar from "Components/Admin/administrator/demand/visits/Tenants/Components/filter/TagBar";
import { CountryUpperCase } from "models/Countries";
import Modals from "Components/Admin/administrator/demand/visits/Tenants/Components/modals";

interface Props {
  deal: "SalesVisit" | "Visit" | "keys";
  title: string;
}

function DemandSheduledIndex({ deal, title }: Props) {
  const userAuth0 = useAuth0();
  const [user, setUser] = useState<UserResponse>();
  const { getToken } = useGetToken();
  const { state: tableState, dispatch: tableDispatch } = useTable();
  const { dispatch, state } = useDemandScheduled();
  const source = useRef(null);

  function getSource() {
    source.current = null;
    const { CancelToken } = axios;
    source.current = CancelToken.source();
    return source.current;
  }

  useEffect(() => {
    const getUser = async () => {
      const user = await userService.me();
      if (user) {
        setUser(user);
        const normalizedCountry = user.country.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        dispatch(setCountry(normalizedCountry.toLowerCase()));
        dispatch(setAgent(user.id));
      }
    };
    getUser();

    dispatch(setRoles(userAuth0.user["https://api.houm.com/roles"]));
    dispatch(setDeal(deal));

    getToken().then((authToken) => {
      getCancelReasons(dispatch, authToken);
    });
  }, []);

  useEffect(() => {
    if (state.country && user) {
      getToken().then((authToken) => {
        getHoumers(dispatch, state.country, authToken);
        getAgents(dispatch, authToken);
      });
    }
  }, [state.country, user]);

  useEffect(() => {
    dispatch(
      setFilters({
        ...state.filters,
        search: null,
      })
    );
  }, [state.country]);

  useEffect(() => {
    if (state.country && user) {
      const params = {
        business_type: deal,
        country: state.country,
        page: tableState.currentPage,
        ordering: tableState.ordering,
        page_size: tableState.pageSize,
        begin_date: state.filters?.beginDate,
        end_date: state.filters?.endDate,
        ct_agent: state.filters?.agent?.value ? state.filters?.agent?.value : null,
        houmer: state.filters?.houmer?.label,
        third_coord: state.filters?.thirdCoord?.value,
        status: state.filters?.status?.value,
        search: state.filters?.search,
      };
      source.current?.cancel();
      const sourceCancelation = getSource();
      getToken().then((authToken) => {
        getVisits(dispatch, params, authToken, sourceCancelation.token);
      });
    }
  }, [
    user,
    deal,
    state.filters?.beginDate,
    state.filters?.endDate,
    state.filters?.houmer?.label,
    state.filters?.agent?.label,
    state.filters?.thirdCoord?.value,
    state.filters?.status?.value,
    state.filters?.search,
    state.country,
    state.ctAgent,
    tableState.currentPage,
    tableState.ordering,
    tableState.pageSize,
    state.isOpenAsignSuccessModal,
    state.isOpenAsignFailModal,
  ]);

  const handleCountryChange = (country: CountryUpperCase | "") => {
    tableDispatch(setCurrentPage(1));
    dispatch(setCountry(country));
  };

  if (!state.roles) return null;
  return (
    <>
      <Modals />
      <Header country={state.country} onCountryChange={handleCountryChange} title={title} />
      <FilterBar />
      <TagBar />
      <SheduledTable />
    </>
  );
}
export default DemandSheduledIndex;
