import useUser from "domains/user/hooks/useUser";
import { BACKEND_URL } from "env";
import { useAuthClient } from "libs/auth";
import { useQuery } from "react-query";

// const schema = zod.object({
//   count: zod.number(),
//   next: zod.string().nullable(),
//   previous: zod.string().nullable(),
//   results: zod.array(HoumerGroupSchema),
// });

export function useQueryUserOrganizations() {
  const { user } = useUser();
  const { client, status } = useAuthClient();
  return useQuery(
    ["organizations:user:all"],
    async () => {
      const { data } =
        await client.get(`${BACKEND_URL}organizations/v2/user/${user.id}/organizations/?only_active=false
      `);
      return data;
    },
    {
      enabled: status === "success" && !!user,
    }
  );
}

export function useQueryOrganizations() {
  const { client, status } = useAuthClient();
  return useQuery(
    ["organizations:all"],
    async () => {
      const { data } = await client.get(`${BACKEND_URL}organizations/v2/
        `);
      return data;
    },
    {
      enabled: status === "success",
    }
  );
}

export function useQueryOrgProperties(orgId) {
  const { client, status } = useAuthClient();
  return useQuery(
    ["organization:properties"],
    async () => {
      const { data } = await client.get(`${BACKEND_URL}organizations/v2/${orgId}/properties/admin
        `);
      return data;
    },
    {
      enabled: status === "success",
    }
  );
}

export function useQueryOrgMembers(orgId) {
  const { client, status } = useAuthClient();
  return useQuery(
    ["organization:members"],
    async () => {
      const { data } = await client.get(`${BACKEND_URL}organizations/v2/${orgId}/members
      `);
      return data;
    },
    {
      enabled: status === "success",
    }
  );
}
