import { useCallback, useState } from "react";
import FilterDialog from "../../../UIComponents/FilterDialog";
import { useTable } from "../../../../../context/tableContext/tableContext";
import { setShowModal, setTableFilters } from "../../../../../context/tableContext/tableActions";

function FilterOptions() {
  const { state, dispatch } = useTable();

  const [idFilter, setIdFilter] = useState<string>(null);
  const [createdAtFilter, setCreatedAtFilter] = useState<string>(null);
  const [nameFilter, setNameFilter] = useState<string>(null);
  const [emailFilter, setEmailFilter] = useState<string>(null);
  const [phoneFilter, setPhoneFilter] = useState<string>(null);

  const handleFilterModal = () => {
    dispatch(setShowModal(false));
  };

  const handleSubmit = useCallback(() => {
    const filters = {
      ...(idFilter && { id: idFilter }),
      ...(createdAtFilter && { creation_date: createdAtFilter }),
      ...(nameFilter && { fullname: nameFilter }),
      ...(emailFilter && { email: emailFilter }),
      ...(phoneFilter && { phone: phoneFilter }),
    };

    dispatch(setTableFilters(filters));
    dispatch(setShowModal(false));
  }, [idFilter, createdAtFilter, nameFilter, emailFilter, phoneFilter, dispatch]);

  const handleDelete = () => {
    setIdFilter(null);
    setCreatedAtFilter(null);
    setEmailFilter(null);
    setNameFilter(null);
    setPhoneFilter(null);
    dispatch(setTableFilters({}));
    dispatch(setShowModal(false));
  };

  return (
    <FilterDialog
      open={state.showModal}
      onClose={handleFilterModal}
      onEscapeKeyDown={handleFilterModal}>
      <FilterDialog.Content>
        <FilterDialog.Item label="ID">
          <FilterDialog.TextField
            type="number"
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Fecha creación">
          <FilterDialog.TextField
            type="date"
            value={createdAtFilter}
            onChange={(e) => setCreatedAtFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Nombre y/o apellido">
          <FilterDialog.TextField
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Correo">
          <FilterDialog.TextField
            type="text"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Número de teléfono">
          <FilterDialog.TextField
            type="text"
            value={phoneFilter}
            onChange={(e) => setPhoneFilter(e.target.value)}
          />
        </FilterDialog.Item>
      </FilterDialog.Content>
      <FilterDialog.Actions onDelete={handleDelete} onApply={handleSubmit} />
    </FilterDialog>
  );
}

export default FilterOptions;
