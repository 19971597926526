import { makeStyles } from "@material-ui/core";

const useSaveInformationStyles = makeStyles((theme) => ({
  exitButton: {
    textTransform: "none",
    textDecoration: "underline",
  },
  saveButton: {
    textTransform: "none",
    borderRadius: "20px",
  },
  buttonContainers: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
}));

export default useSaveInformationStyles;
