import { makeStyles, Theme } from "@material-ui/core";

const usePropertyPhotosStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1, 0),
    alignSelf: "flex-end",
  },
  uploader: {
    backgroundColor: "#EFF2F3",
    borderRadius: theme.spacing(1),
  },
}));

export default usePropertyPhotosStyles;
