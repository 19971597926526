import { makeStyles, Theme } from "@material-ui/core";

const useApplicantStyles = makeStyles<Theme>((theme) => ({
  title: {
    marginTop: theme.spacing(0),
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useApplicantStyles;
