import { useState } from "react";
import { Typography } from "@material-ui/core";

import GeneralSpaceEditRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/edit/components/GeneralSpaceEditRow";
import InspectionReportCard from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportCard";
import TableTitle from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableTitle";
import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import TableRowImagePreviewer from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableRowImagePreviewer";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useProperty } from "context/propertyContext/propertyContext";
import { setInspectionReportCurrentSpaceFilter } from "context/propertyContext/propertyActions";
import { getObjectLabel } from "utils";
import { inspectionReportQualificationOptions } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";
import { titlesArrayBase, editTitlesArray } from "./utils";

function GeneralSpacesSummary() {
  const [currentSpaceEditingId, setCurrentSpaceEditingId] = useState<number>(null);
  const { state, dispatch } = useProperty();
  const isEditView = state.inspectionReportsCurrentView === "edit";

  const handleSetSpaceFilter = (spaceName: string) =>
    dispatch(setInspectionReportCurrentSpaceFilter(spaceName));

  return (
    <InspectionReportCard title="Resumen general de los ambientes">
      <Loading loading={state.inspectionReport.loading}>
        <>
          <TableTitle
            titlesArr={isEditView ? editTitlesArray : titlesArrayBase}
            isEdit={isEditView}
          />
          {state.inspectionReport.data?.generalSpacesSummary?.map((space) =>
            currentSpaceEditingId === space.id ? (
              <GeneralSpaceEditRow
                space={space}
                setCurrentSpaceEditingId={setCurrentSpaceEditingId}
              />
            ) : (
              <InspectionReportTableRow isEdit={isEditView} key={space.id}>
                <LinkButton onClick={() => handleSetSpaceFilter(space.name)}>
                  {space.name}
                </LinkButton>
                <Typography>
                  {getObjectLabel(inspectionReportQualificationOptions, space.overallQualification)}
                </Typography>
                <Typography>{space.overallObservation ?? "Sin observación"}</Typography>
                <TableRowImagePreviewer rowName={space.name} imagesArr={[space?.photo]} />
                {isEditView && (
                  <LinkButton onClick={() => setCurrentSpaceEditingId(space.id)}>Editar</LinkButton>
                )}
              </InspectionReportTableRow>
            )
          )}
        </>
      </Loading>
    </InspectionReportCard>
  );
}

export default GeneralSpacesSummary;
