import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  cardRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1.25, 2),
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  underlinedBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textBold: {
    fontWeight: 700,
  },
  subtitle: {
    fontWeight: 700,
  },
  linkContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  copyButtonContainer: {
    margin: theme.spacing(2, 0),
  },
}));
