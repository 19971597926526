import { Suspense } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import Alert from "@houm-com/ui/Alert";

import { AlertProvider } from "context/alertContext/alertContext";
import Theme from "apps/main/components/Theme";
import AuthenticateUser from "apps/main/components/AuthenticateUser";
import AuthorizeUser from "apps/main/components/AuthorizeUser";
import TokyHandler from "libs/toky/TokyHandler";
import MainLayout from "apps/main/components/MainLayout";
import MainRouter from "apps/main/Router";

import { REACT_QUERY_DEV_TOOLS } from "env";
import { AuthProvider } from "libs/auth";
import LoadingScreen from "domains/common/components/LoadingScreen";
import { Alerts } from "libs/alerts";

const MainApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });
  return (
    <Suspense fallback={<LoadingScreen />}>
      <QueryClientProvider client={queryClient}>
        <Theme>
          <Router>
            <Alert />
            <Alerts />
            <AuthProvider>
              <AuthenticateUser>
                {/* AuthorizeUser is a temporary access control step until we move all authorizations to permissions */}
                <AuthorizeUser>
                  <TokyHandler>
                    <AlertProvider>
                      <MainLayout>
                        <MainRouter />
                      </MainLayout>
                    </AlertProvider>
                  </TokyHandler>
                </AuthorizeUser>
              </AuthenticateUser>
            </AuthProvider>
          </Router>
        </Theme>
        {REACT_QUERY_DEV_TOOLS && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
    </Suspense>
  );
};

export default MainApp;
