import { useGetToken } from "hooks";
import { UseQueryResult, useQuery } from "react-query";

import { useFeatureManager } from "@houm-com/feature-manager";
import contractService from "services/contractService";

import { parseAcceptanceDocuments } from "../utils/parsers";
import { IAcceptanceDocument } from "../utils/types";

const useGetAcceptanceDocuments = (contractId) => {
  const { getToken } = useGetToken();
  const { data: dictionaryData, status: dictionaryStatus } = useFeatureManager(
    "acceptance_names_dictionary"
  );
  const acceptanceNamesDictionary =
    dictionaryStatus === "success" && dictionaryData?.enabled ? dictionaryData.content : null;

  const {
    data: newDocumentsData,
    isLoading: newDocumentsLoading,
    isError: newDocumentsError,
    isSuccess: newDocumentsSuccess,
  }: UseQueryResult<IAcceptanceDocument[], Error> = useQuery<IAcceptanceDocument[], Error>(
    ["get_acceptance", acceptanceNamesDictionary],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getAcceptanceDocuments(contractId, authToken);
      return parseAcceptanceDocuments(response.data, acceptanceNamesDictionary);
    },
    {
      enabled: !!acceptanceNamesDictionary,
    }
  );

  const {
    data: documentsData,
    isLoading: documentsLoading,
    isError: documentsError,
  } = useQuery(["get_acceptance_old", contractId], async () => {
    const authToken = await getToken();
    const response = await contractService.retrieveSignedContracts(contractId, authToken);
    return response.data;
  });

  return {
    newDocumentsData,
    documentsData,
    newDocumentsError,
    documentsError,
    isLoading: newDocumentsLoading || documentsLoading || dictionaryStatus === "loading",
    isSuccess: newDocumentsSuccess,
  };
};

export default useGetAcceptanceDocuments;
