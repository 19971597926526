import { makeStyles, Theme } from "@material-ui/core";

const useCellsStyles = makeStyles((theme: Theme) => ({
  blackUnderline: {
    textDecoration: "underline !important",
    color: "#000000 !important",
  },
}));

export default useCellsStyles;
