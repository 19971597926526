import { makeStyles, Theme } from "@material-ui/core";

const useDesignCardContentStyles = makeStyles<Theme>((theme) => ({
  titleContainer: {
    background: theme.palette.grey[50],
    borderRadius: 2,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    padding: theme.spacing(0.5),
    fontWeight: 700,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  boxContainer: {
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useDesignCardContentStyles;
