import { makeStyles, Theme } from "@material-ui/core";

const useItemStyles = makeStyles((theme: Theme) => ({
  nameWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  iconWrapper: {
    marginRight: theme.spacing(0.5),
    "& svg": {
      transform: "scale(1.25)",
    },
  },
}));

export default useItemStyles;
