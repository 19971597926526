import { useEffect } from "react";

import { Link } from "react-router-dom";
import { GridColumns, GridColDef } from "@material-ui/x-grid";

import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();

  const { state: tableStates, dispatch } = useTable();
  const { state: MultifamilyStates } = useMultifamily();
  const { state: TypologyStates } = useTypology();
  const { currentId } = MultifamilyStates;
  const { typologies, isLoadingDataTable } = TypologyStates;
  const { currentPage, pageSize } = tableStates;

  useEffect(() => dispatch(setPageSize(10)), []);

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "Nombre tipología",
      field: "name",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Plano",
      field: "layout_file_url",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => {
        if (
          value &&
          value !== "https://s3.amazonaws.com/houm-real-estate-media/layout/multifamily/"
        ) {
          return <img src={value as string} alt="plano" width={80} />;
        }
        return "---------";
      },
    },
    {
      ...columnsConfig,
      headerName: "Unidades",
      field: "units_number",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Habitaciones",
      field: "bedrooms",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "Baños",
      field: "bathrooms",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "M2 construidos",
      field: "built_area",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      headerName: "M2 totales",
      field: "total_area",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => value || "---------",
    },
    {
      ...columnsConfig,
      field: "actions",
      headerName: "",
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: classes.lastHeaderCell,
      cellClassName: classes.actionCell,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Link to={`/admin/multifamily/${currentId}/typology/${row.id}`} className={classes.link}>
          Ver tipología
        </Link>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        rowCount={typologies?.length || 0}
        columns={columns}
        rows={
          typologies?.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
          ) || []
        }
        loading={isLoadingDataTable}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
