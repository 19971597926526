import Swal from "sweetalert2";
import { AxiosError, AxiosResponse } from "axios";

import propertyService from "services/propertyService";
import multifamilyService from "services/multifamilyService";
import { PropertyData } from "models/Property";
import { PhoneInput as Phone } from "models/PhoneInput";
import { HomeCheckerDetailsSnakeCase, IProperty } from "models";
import { Paginate } from "models/ResponseWithPaginate";

import {
  ActionType,
  Action,
  Dispatch,
  MultifamilyUnit,
  VisitEvent,
  ExtraStates,
  BasicServices,
  UnitsUploadError,
} from "./unitTypes";
import { CountryUpperCase } from "models/Countries";
import { uploadToS3 } from "utils/fileStreaming";
import moment from "moment";

export const setProperty = (property: PropertyData): Action => ({
  type: ActionType.SET_PROPERTY,
  property,
});

export const setPropertyServices = (propertyServices: BasicServices[]): Action => ({
  type: ActionType.SET_PROPERTY_SERVICES,
  propertyServices,
});

export const setScheduledVisits = (scheduledVisits: VisitEvent[]): Action => ({
  type: ActionType.SET_SCHEDULED_VISITS,
  scheduledVisits,
});

export const setUnits = (units: MultifamilyUnit[]): Action => ({
  type: ActionType.SET_UNITS,
  units,
});

export const setUnit = (unit: MultifamilyUnit): Action => ({
  type: ActionType.SET_UNIT,
  unit,
});

export const setExtraStates = (extraStates: ExtraStates): Action => ({
  type: ActionType.SET_EXTRA_STATES,
  extraStates,
});

export const setLoadingUpload = (loadingUpload: boolean): Action => ({
  type: ActionType.SET_LOADING_UPLOAD,
  loadingUpload,
});

export const setUnitsUploadModal = (unitsUploadModal: boolean): Action => ({
  type: ActionType.SET_UNIT_UPLOAD_MODAL,
  unitsUploadModal,
});

export const setUnitsUploadErrors = (unitsUploadErrors: UnitsUploadError[]): Action => ({
  type: ActionType.SET_UNIT_UPLOAD_ERRORS,
  unitsUploadErrors,
});

export const fetchPropertyData = async (id: string, token: string, dispatch: Dispatch) => {
  try {
    const response = await propertyService.get(id, token);
    dispatch(setProperty(response.data));
  } catch {
    Swal.fire("Error", "No se pudo obtener la información de la unidad", "error");
  }
};

export const fetchScheduledVisits = async (id: number, token: string, dispatch: Dispatch) => {
  try {
    const response = await propertyService.pendingSchedules(id, token);
    const visits = response.data.map((visit) => ({
      start: visit.begin_date,
    }));
    dispatch(setScheduledVisits(visits));
  } catch {
    Swal.fire("Error", "No se pudieron cargar los horarios", "error");
  }
};

export const fetchUnits = async (id: number, token: string, dispatch: Dispatch) => {
  try {
    dispatch(setExtraStates({ isFetchingAllUnits: true }));
    const response = (await multifamilyService.getUnits(id, token)) as AxiosResponse<
      Paginate<MultifamilyUnit>
    >;
    dispatch(setUnits(response.data.results));
  } catch {
    Swal.fire("Error", "No se pudieron cargar las unidades", "error");
  } finally {
    dispatch(setExtraStates({ isFetchingAllUnits: false }));
  }
};

export const fetchUnit = async (id: number, token: string, dispatch: Dispatch) => {
  try {
    const response = (await multifamilyService.getUnit(
      id,
      token
    )) as AxiosResponse<MultifamilyUnit>;
    dispatch(setUnit(response.data));
    await fetchPropertyData(response.data.property.uid, token, dispatch);
    await fetchBasicServicesForProperty(response.data.property.id, token, dispatch);
  } catch {
    Swal.fire("Error", "No se pudo cargar la unidad", "error");
  }
};

export const fetchCoordination = async (id: number, token: string) => {
  try {
    const HCDResponse = await propertyService.homeCheckerDetails(id.toString(), token);
    const bodyToReturn = {
      homeCheckerDetails: HCDResponse.data,
    };
    return bodyToReturn;
  } catch {
    Swal.fire("Error", "No se pudieron cargar los detalles de coordinación de visitas", "error");
  }
};

export interface updateCoordinationData {
  inhabitedProperty: boolean;
  inhabitedPropertyDescription: string;
  keyLocation: boolean;
  keyLocationDescription: string;
  keyLocationPhone: Phone;
  propertyWithHoumbox: boolean;
  thirdPartyCoordination: boolean;
  thirdPartyCoordinationDescription: string;
  thirdPartyCoordinationPhone: Phone;
}

export const updateCoordination = async (
  data: updateCoordinationData,
  id: number,
  uid: string,
  token: string
) => {
  try {
    const dataForKeys: IProperty | Partial<PropertyData> = {
      keys_info:
        data?.keyLocationDescription?.length > 0 ? data.keyLocationDescription : "Sin información",
    };
    const dataForHomeCheckerDetails: HomeCheckerDetailsSnakeCase = {
      has_houm_box: data?.propertyWithHoumbox,
      inhabited_property: data?.inhabitedProperty,
      inhabited_property_description: data?.inhabitedPropertyDescription
        ? data?.inhabitedPropertyDescription
        : "",
      third_party_coordination: data?.thirdPartyCoordination,
      third_party_coordination_description: data?.thirdPartyCoordinationDescription
        ? data?.thirdPartyCoordinationDescription
        : "",
      third_party_coordination_phone: `+${data?.keyLocationPhone?.country?.callingCode}${data?.keyLocationPhone?.phone}`,
    };

    await propertyService.update_keys(dataForKeys, uid, token);
    await propertyService.updateHomeCheckerDetails(id, dataForHomeCheckerDetails, token);
    Swal.fire("Exito", "Se actualizaron los detalles de coordinación de visitas", "success");
  } catch {
    Swal.fire("Error", "No se pudieron guardar los detalles de coordinación de visitas", "error");
  }
};

export const updateProperty = async (id: number, data: {}, token: string, dispatch: Dispatch) => {
  try {
    await propertyService.updateProperty(id, data, token);
    Swal.fire("Éxito", "Se actualizó la propiedad", "success");
  } catch {
    Swal.fire("Error", "No se pudo actualizar la propiedad", "error");
  }
};

export interface BasicService {
  id: number;
  name: string;
  type: string;
  country: CountryUpperCase;
  verify_url: string;
}

export const fetchBasicServices = async (country: CountryUpperCase, authToken: string) => {
  try {
    const res = (await propertyService.getBasicServices(authToken, country)) as AxiosResponse<
      Paginate<BasicService>
    >;
    const parseServices = res.data.results.map((service) => ({
      label: service.name,
      value: service.id,
    }));
    return parseServices;
  } catch {
    Swal.fire("Error", "No se pudo cargar la lista de servicios básicos", "error");
  }
};

export const fetchBasicServicesForProperty = async (
  propertyId: number,
  authToken: string,
  dispatch: Dispatch
) => {
  try {
    const translateService = (service) => {
      switch (service) {
        case "water":
          return "Agua";
        case "energy":
          return "Electricidad";
        case "gas":
          return "Gas";
        default:
          return "¿?";
      }
    };
    const servicesRes = await propertyService.getPropertyBasicServicesV1(propertyId, authToken);
    const parseData = servicesRes.data.results.map((service) => ({
      serviceType: translateService(service.service.type),
      company: service.service.name,
      customerNumber: service.client_number,
      id: service.id,
    }));
    dispatch(setPropertyServices(parseData));
  } catch {
    Swal.fire("Error", "No se pudieron cargar los servicios básicos de la propiedad", "error");
  }
};

export const setBasicService = async (
  clientNumber: string,
  serviceId: number,
  propertyId: number,
  authToken: string,
  dispatch: Dispatch
) => {
  const body = {
    service_id: serviceId,
    client_number: clientNumber,
    prop_id: propertyId,
  };
  try {
    await propertyService.attachService(body, authToken);
    await fetchBasicServicesForProperty(propertyId, authToken, dispatch);
    Swal.fire("Éxito", "Se agregó el servicio básico", "success");
  } catch {
    Swal.fire("Error", "No se pudo agregar el servicio básico", "error");
  }
};

export const deleteBasicService = async (
  serviceId: number,
  propertyId: number,
  authToken: string,
  dispatch: Dispatch
) => {
  try {
    await propertyService.deleteBasicService(authToken, serviceId, propertyId);
    await fetchBasicServicesForProperty(propertyId, authToken, dispatch);
    Swal.fire("Éxito", "Se eliminó el servicio básico", "success");
  } catch {
    Swal.fire("Error", "No se pudo eliminar el servicio básico", "error");
  }
};

export const uploadUnitsFile = async (
  multifamilyId: number,
  file: File,
  authToken: string,
  dispatch: Dispatch
) => {
  try {
    dispatch(setLoadingUpload(true));
    const timestamp = moment(new Date(Date.now())).format("YYYY-MM-DD-HH:mm:ss");
    const filename = `units_${multifamilyId}_${timestamp}.xlsx`;
    const presignedUrl = await multifamilyService.getUnitsFilePresignedUrl(filename, authToken);
    await uploadToS3({
      fileContents: file,
      presignedUrl: presignedUrl.data,
    });
    return filename;
  } catch {
    dispatch(setLoadingUpload(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo subir el archivo para ser procesado",
    });
  }
};

export const parseUnitsFile = async (
  multifamilyId: number,
  filename: string,
  authToken: string,
  dispatch: Dispatch
) => {
  try {
    await multifamilyService.parseUnitsFile({ filename, multifamily: multifamilyId }, authToken);
    Swal.fire({
      type: "success",
      title: "Carga exitosa",
      text: "Las unidades han sido subidas correctamente",
    });
    return { parseErrors: false, errorsList: [] };
  } catch (e) {
    const error = e as AxiosError;
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo procesar el archivo",
    });
    return { parseErrors: true, errorsList: error.response.data };
  } finally {
    dispatch(setLoadingUpload(false));
  }
};
