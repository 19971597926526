import { useEffect } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";

import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldTextArea from "@houm-com/ui/FieldTextArea";

import useCreateComment from "domains/evaluations/hooks/useCreateComment";
import useReactHookForm from "hooks/useReactHookForm";
import { commentFormSchema } from "./utils/validationSchema";
import { FormData } from "./utils/types";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  evaluationMemberId: string;
}

const AskDocumentationModal = ({ evaluationMemberId, open, handleOpenModal }: Props) => {
  const { mutate, isLoading } = useCreateComment({
    evaluationMemberId,
    onHandleSuccess: handleOpenModal,
  });
  const { methods, errors } = useReactHookForm<FormData>({
    formSchema: commentFormSchema,
  });

  useEffect(() => {
    methods.reset();
  }, []);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (isLoading) return;
    mutate({
      ...data,
      commentType: "document_request",
    });
  };

  return (
    <ModalDialog title="Pedir documentación" isOpen={open} onClose={handleOpenModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalDialog.Content classNameContent="flex flex-col p-1 gap-2">
            <FieldTextArea
              label="Mensaje a enviar"
              id="comment"
              errorMessage={errors.comment?.message}
              placeholder="Escribe un mensaje"
              form="novalidateform"
              {...methods.register("comment")}
            />
          </ModalDialog.Content>
          <ModalDialog.Actions
            onClose={handleOpenModal}
            submitButtonLabel="Enviar"
            submitButtonProps={{
              type: "submit",
              isLoading,
            }}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default AskDocumentationModal;
