import { useEffect, useState } from "react";
import clsx from "clsx";

import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";

import useSwitchSelectorStyles from "./SwitchSelectorStyles";

interface Props {
  options: { label: string; key: string }[];
  handleChange: (e: string) => void;
  defaultValue: string;
  buttonClassname?: string;
  labelClassname?: string;
  groupClassname?: string;
  disabled?: boolean;
  preventChange?: boolean;
  buttonSelectClassName?: string;
  switchContainerClassName?: string;
}

const SwitchSelector = ({
  options,
  defaultValue,
  handleChange,
  buttonClassname,
  labelClassname,
  groupClassname,
  buttonSelectClassName,
  preventChange = false,
  disabled = false,
  switchContainerClassName,
}: Props) => {
  const classes = useSwitchSelectorStyles();
  const [keySelected, setKeySelected] = useState(defaultValue);

  const handleClick = (key: string) => {
    handleChange(key);
    if (!preventChange) {
      setKeySelected(key);
    }
  };

  useEffect(() => {
    setKeySelected(defaultValue);
  }, [defaultValue]);

  return (
    <Box className={clsx(classes.container, switchContainerClassName)}>
      <ButtonGroup
        variant="outlined"
        className={clsx(classes.group, groupClassname)}
        defaultValue={defaultValue}>
        {options.map((tab) => (
          <Button
            key={tab.key}
            className={clsx(classes.button, buttonClassname, {
              [classes.buttonSelected]: keySelected === tab.key,
              [buttonSelectClassName]: buttonSelectClassName && keySelected === tab.key,
            })}
            disabled={disabled}
            onClick={() => tab.key !== keySelected && handleClick(tab.key)}
            id={tab.key}>
            <Typography
              className={clsx(classes.title, labelClassname, {
                [classes.titleSelected]: keySelected === tab.key,
                [classes.titleNotSelected]: keySelected !== tab.key,
              })}>
              {tab.label}
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default SwitchSelector;
