import { useFeatureManager } from "@houm-com/feature-manager";
import useStyles from "./ConfirmModalStyles";

function CancelModalDisclaimer() {
  const classes = useStyles();
  const { data: cancelFlagData, status: cancelFlagStatus } =
    useFeatureManager("cancel_exit_process");
  return (
    <div className={classes.bodyContainer}>
      <div>
        Al Cancelar la salida no se reversa ninguna de las acciones anteriormente realizadas. Debes
        revisar:
      </div>
      <div>
        <ul>
          {cancelFlagStatus === "success" &&
            cancelFlagData?.enabled &&
            cancelFlagData?.content.disclaimers.map((disclaimer: string) => <li>{disclaimer}</li>)}
        </ul>
      </div>
    </div>
  );
}

export default CancelModalDisclaimer;
