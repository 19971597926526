import { CountryUpperCase } from "models/Countries";

export enum ActionType {
  SET_COUNTRY,
  SET_VISITS,
  SET_HOUMERS,
  SET_CANCEL_REASONS,
  SET_AGENT,
  SET_ERROR_MSG,
  SET_ROLES,
  SET_AGENTS,
  SET_INFO,
  SET_NEWS,
  SET_SCHEDULE_ID,
  SET_APPRAISER_ID,
  SET_IS_OPEN_FILTER_MODAL,
  SET_IS_OPEN_INFO_MODAL,
  SET_IS_OPEN_NEWS_MODAL,
  SET_IS_OPEN_CANCEL_MODAL,
  SET_IS_OPEN_ASIGN_CT_AGENT_MODAL,
  SET_IS_OPEN_ASIGN_PROPIETARY_MODAL,
  SET_IS_OPEN_ASIGN_FAIL_MODAL,
  SET_IS_OPEN_ASIGN_SUCCESS_MODAL,
  SET_IS_OPEN_ASIGN_PROCESSING_MODAL,
  SET_FILTERS,
  SET_LOADING,
  SET_TOTAL_VISITS,
  SET_DEAL,
}

export interface Filters {
  houmer?: { label: string };
  agent?: { label: string; value: string };
  beginDate?: string;
  endDate?: string;
  status?: InitialFilters;
  thirdCoord?: InitialFilters;
  search?: string;
}

export interface CtAgent {
  id: number;
  name: string;
}

export interface Visits {
  id: number;
  visit_date: string;
  idProperty: number;
  ctAgent: string;
  coord: string;
  statusVisit: string;
  houmer: Houmer;
  contact: Contact;
}

interface Contact {
  name: string;
  sur_name: string;
  phone: string;
  status?: string;
}

interface Houmer {
  name: string;
  sur_name: string;
  phone: string;
  status?: string;
}

interface Houmers {
  name: string;
}

interface Agents {
  id: number;
  name: string;
}

export interface CancelReasons {
  cancelType: string;
  value: string;
  label: string;
}

export interface InitialFilters {
  label: string;
  value: string | number;
}

export interface SetCountry {
  type: typeof ActionType.SET_COUNTRY;
  country: CountryUpperCase;
}

export interface SetVisits {
  type: typeof ActionType.SET_VISITS;
  visits: Visits[];
}

export interface SetHoumers {
  type: typeof ActionType.SET_HOUMERS;
  houmers: Houmers[];
}

export interface SetCancelReasons {
  type: typeof ActionType.SET_CANCEL_REASONS;
  cancelReasons: CancelReasons[];
}

export interface SetAgents {
  type: typeof ActionType.SET_AGENTS;
  agents: Agents[];
}

export interface SetAgent {
  type: typeof ActionType.SET_AGENT;
  ctAgent: number;
}

export interface SetErrorMsg {
  type: typeof ActionType.SET_ERROR_MSG;
  errorMsg: string;
}

export interface SetRoles {
  type: typeof ActionType.SET_ROLES;
  roles: string;
}

export interface SetScheduleId {
  type: typeof ActionType.SET_SCHEDULE_ID;
  scheduleId: number;
}

export interface SetAppraiserId {
  type: typeof ActionType.SET_APPRAISER_ID;
  appraiserId: string;
}

export interface SetIsOpenModal {
  type: typeof ActionType.SET_IS_OPEN_FILTER_MODAL;
  isOpenFilterModal: boolean;
}

export interface SetIsOpenInfoModal {
  type: typeof ActionType.SET_IS_OPEN_INFO_MODAL;
  isOpenInfoModal: boolean;
}

export interface SetIsOpenNewsModal {
  type: typeof ActionType.SET_IS_OPEN_NEWS_MODAL;
  isOpenNewsModal: boolean;
}

export interface SetIsOpenCancelModal {
  type: typeof ActionType.SET_IS_OPEN_CANCEL_MODAL;
  isOpenCancelModal: boolean;
}

export interface SetIsOpenAsignCtAgentModal {
  type: typeof ActionType.SET_IS_OPEN_ASIGN_CT_AGENT_MODAL;
  isOpenAsignCtAgentModal: boolean;
}

export interface SetIsOpenAsignPropietaryModal {
  type: typeof ActionType.SET_IS_OPEN_ASIGN_PROPIETARY_MODAL;
  isOpenAsignPropietaryModal: boolean;
}

export interface SetIsOpenAsignFailModal {
  type: typeof ActionType.SET_IS_OPEN_ASIGN_FAIL_MODAL;
  isOpenAsignFailModal: boolean;
}

export interface SetIsOpenAsignSuccessModal {
  type: typeof ActionType.SET_IS_OPEN_ASIGN_SUCCESS_MODAL;
  isOpenAsignSuccessModal: boolean;
}

export interface SetIsOpenAsignProcessingModal {
  type: typeof ActionType.SET_IS_OPEN_ASIGN_PROCESSING_MODAL;
  isOpenAsignProcessingModal: boolean;
}

export interface SetFilters {
  type: typeof ActionType.SET_FILTERS;
  filters: Filters;
}

export interface SetIsLoading {
  type: typeof ActionType.SET_LOADING;
  isLoading: boolean;
}

export interface SetTotalVisits {
  type: typeof ActionType.SET_TOTAL_VISITS;
  totalVisits: number;
}

export interface SetInfo {
  type: typeof ActionType.SET_INFO;
  contactInfo: string;
}

export interface SetNews {
  type: typeof ActionType.SET_NEWS;
  news: string;
}

export interface SetDeal {
  type: typeof ActionType.SET_DEAL;
  deal: string;
}

export type Action =
  | SetTotalVisits
  | SetIsLoading
  | SetCountry
  | SetVisits
  | SetHoumers
  | SetCancelReasons
  | SetInfo
  | SetNews
  | SetDeal
  | SetAgents
  | SetAgent
  | SetErrorMsg
  | SetRoles
  | SetScheduleId
  | SetAppraiserId
  | SetIsOpenModal
  | SetIsOpenInfoModal
  | SetIsOpenNewsModal
  | SetIsOpenCancelModal
  | SetIsOpenAsignCtAgentModal
  | SetIsOpenAsignPropietaryModal
  | SetIsOpenAsignFailModal
  | SetIsOpenAsignSuccessModal
  | SetIsOpenAsignProcessingModal
  | SetFilters;

export interface State {
  country: CountryUpperCase;
  visits: Visits[];
  houmers: Houmers[];
  cancelReasons: CancelReasons[];
  agents: Agents[];
  isOpenFilterModal: boolean;
  isOpenInfoModal: boolean;
  isOpenNewsModal: boolean;
  isOpenCancelModal: boolean;
  isOpenAsignCtAgentModal: boolean;
  isOpenAsignPropietaryModal: boolean;
  isOpenAsignFailModal: boolean;
  isOpenAsignSuccessModal: boolean;
  isOpenAsignProcessingModal: boolean;
  filters: Filters;
  contactInfo: string;
  news: string;
  errorMsg: string;
  roles: string[];
  ctAgent: CtAgent;
  scheduleId: number;
  appraiserId: string;
  isLoading: boolean;
  totalVisits: number;
  deal: "keys" | "SalesVisit" | "Visit";
}

export type Dispatch = (action: Action) => void;
