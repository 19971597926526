import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";

interface Props {
  contractId: number | null;
  handleIsEdit: () => void;
}

const ContractButtons = ({ contractId, handleIsEdit }: Props) => (
  <Grid item container xs={12} justifyContent="flex-start" alignItems="center" spacing={2}>
    <Grid item>
      <DesignSystemButton
        label="Ver contrato"
        variant="tertiaryB"
        size="small"
        type="submit"
        target="_blank"
        href={`/admin/contracts/${contractId}`}
      />
    </Grid>
    <ProtectedComponent scope="permissions" require={["create:contract_change_request:all"]}>
      <Grid item>
        <DesignSystemButton
          label="Editar contrato"
          variant="primary"
          size="small"
          onClick={handleIsEdit}
        />
      </Grid>
    </ProtectedComponent>
  </Grid>
);
export default ContractButtons;
