import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  noImgsText: {
    color: theme.palette.grey[200],
  },
  imgContainerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    width: "8rem",
    height: "8rem",
    borderRadius: "1rem",
    overflow: "hidden",
    transition: "0.3s",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(4),
    },
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  moreImagesBtn: {
    marginLeft: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  img: {
    display: "flex",
    width: "100%",
  },
  carouselImgContainer: {
    display: "flex",
    maxHeight: "50vh",
  },
  carouselImg: {
    display: "flex",
    width: "100%",
    objectFit: "contain",
  },
}));
