import { makeStyles } from "@material-ui/core";

const useCustomButtonStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minHeight: "40px",
    borderRadius: "50px",
    border: `1px solid ${theme.palette.grey["500"]}`,
  },
  buttonChildren: {
    display: "flex",
    alignItems: "center",
  },
}));
export default useCustomButtonStyles;
