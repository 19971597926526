import { TableProvider } from "context/tableContext/tableContext";
import { DemandSheduledProvider } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { nextTwoMonthFilter } from "context/demandScheduledVisitsContext/demandScheduledUtils";
import DemandSheduledIndex from "./visits/Tenants/DemandScheduledIndex";

function SheduledVisitsTenantIndex() {
  return (
    <DemandSheduledProvider defaultFilters={nextTwoMonthFilter}>
      <TableProvider>
        <DemandSheduledIndex deal="keys" title="Visitas de Entrega" />
      </TableProvider>
    </DemandSheduledProvider>
  );
}
export default SheduledVisitsTenantIndex;
