import { Box, Typography } from "@material-ui/core";

import useBasicServicesHeaderStyles from "./BasicServicesHeaderStyles";

function BasicServicesHeader() {
  const classes = useBasicServicesHeaderStyles();
  return (
    <Box>
      <Typography>
        El <span className={classes.bold}>consultar</span> la información puede tardar{" "}
        <span className={classes.bold}>hasta 5 minutos</span>
      </Typography>
    </Box>
  );
}

export default BasicServicesHeader;
