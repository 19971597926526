export const optionsTable = {
  movement: {
    textTitle: "¡No hay movimientos!",
    textSubtitle: "Aún no se han registrado movimientos",
    textFilter: "No existen movimientos que cumplan con sus filtros de búsqueda",
  },
  basicService: {
    textTitle: "¡No tiene servicios básicos!",
    textSubtitle: "Aún no se han registrado servicios básicos",
    textFilter: "No existen servicios básicos que cumplan con sus filtros de búsqueda",
  },
  bankAccount: {
    textTitle: "¡Publica tu propiedad!",
    textSubtitle: "Para poder asociar cuentas de banco, debes tener al menos una propiedad.",
  },
  forSale: {
    textTitle: "¡Pon tus propiedades en venta!",
    textSubtitle: "Comienza a vender y administrar tu propiedad con nosotros",
    textFilter: "No existen propiedades en venta que cumplan con sus filtros de búsqueda",
  },
  forRent: {
    textTitle: "¡Pon tus propiedades en arriendo!",
    textSubtitle: "Comienza a arrendar y administrar tu propiedad con nosotros",
    textFilter: "No existen propiedades en renta que cumplan con sus filtros de búsqueda",
  },
  leadProperties: {
    textTitle: "¡No hay propiedades!",
    textSubtitle: "No se encontraron propiedades asociadas a este lead",
  },
  applications: {
    textTitle: "¡No hay postulantes!",
    textSubtitle: "Aún la propiedad no cuenta con postulantes a evaluar",
  },
  tags: {
    textTitle: "¡No hay promociones!",
    textSubtitle: "Se debe ingresar un ID para buscar Tags asignados",
    textFilter: "No existen Tags que cumplan con sus filtros de búsqueda",
  },
  inspectionReports: {
    textTitle: "¡No hay actas de inspección!",
    textSubtitle: "La propiedad seleccionada no tiene actas de inspección para revisar",
  },
};
