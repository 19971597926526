import { useEffect, useState, Fragment, Dispatch, SetStateAction } from "react";
import clx from "classnames";

import { ButtonBase, SvgIcon, TableCell, TableRow } from "@material-ui/core";

import { ReactComponent as CircleDownButton } from "assets/icons/down-circle.svg";
import { ReactComponent as RestCircleIcon } from "assets/icons/rest-circle.svg";
import { ReactComponent as CircleUpButton } from "assets/icons/up-circle.svg";
import { ReactComponent as AddCircleIcon } from "assets/icons/add-circle.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditButton } from "assets/icons/edit.svg";

import { Row, Header } from "./CollapsibleTableTypes";
import CollapsibleCell from "./CollapsibleCell";
import useStyles from "./LayoutTableStyles";

interface Props {
  row: Row;
  headers: Header[];
  isFormOpen: boolean;
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
  createSubRow?: (id?: number, close?: Dispatch<SetStateAction<boolean>>) => JSX.Element;
  deleteRow?: (id?: number, name?: string) => Promise<void>;
  deleteSubRow?: (id?: number, subname?: string, name?: string) => Promise<void>;
  editRow?: (value?: any, close?: Dispatch<SetStateAction<boolean>>) => JSX.Element | JSX.Element;
  editSubRow?: (
    value?: any,
    id?: number,
    close?: Dispatch<SetStateAction<boolean>>
  ) => JSX.Element | JSX.Element;
}

function CollapsebleRow({
  row,
  headers,
  isFormOpen,
  setIsFormOpen,
  createSubRow = null,
  deleteRow = null,
  deleteSubRow = null,
  editRow = null,
  editSubRow = null,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openSubEdit, setOpenSubEdit] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  useEffect(() => {
    setIsFormOpen(openEdit || openCreate || openSubEdit);
  }, [openEdit, openCreate, openSubEdit]);

  const classes = useStyles();
  return (
    <>
      <TableRow>
        {headers.map((value) => (
          <TableCell
            className={classes.cell}
            style={{ width: value.width }}
            key={`${row.id}-${value.id}`}>
            {value.renderCell ? value.renderCell(row[value.id], row) : row[value.id]}
          </TableCell>
        ))}
        <TableCell className={classes.cell}>
          <div className={classes.iconActions}>
            {createSubRow && (
              <ButtonBase
                onClick={() => setOpenCreate(!openCreate)}
                classes={{ disabled: classes.iconDisabled }}
                disabled={isFormOpen && !openCreate}>
                <SvgIcon
                  component={openCreate ? RestCircleIcon : AddCircleIcon}
                  fontSize="small"
                  className={classes.icon}
                />
              </ButtonBase>
            )}
            {editRow && (
              <ButtonBase
                onClick={() => setOpenEdit(!openEdit)}
                disabled={isFormOpen && !openEdit}
                classes={{ disabled: classes.iconDisabled }}>
                <SvgIcon
                  component={EditButton}
                  fontSize="small"
                  className={clx(classes.icon, classes.editIcon)}
                />
              </ButtonBase>
            )}

            {row?.subitems?.length > 0 && (
              <ButtonBase onClick={() => setOpen(!open)}>
                <SvgIcon
                  component={open ? CircleUpButton : CircleDownButton}
                  fontSize="small"
                  className={classes.icon}
                />
              </ButtonBase>
            )}

            {deleteRow && (
              <ButtonBase
                disabled={row?.subitems?.length > 0 || isFormOpen}
                onClick={() => deleteRow(row.id, row?.name)}
                className={classes.trashIcon}
                classes={{ disabled: classes.iconDisabled }}>
                <SvgIcon component={TrashIcon} fontSize="small" className={classes.icon} />
              </ButtonBase>
            )}
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <CollapsibleCell open={openEdit} colSpan={headers.length}>
          {editRow && editRow(row, setOpenEdit)}
        </CollapsibleCell>
      </TableRow>

      <TableRow>
        <CollapsibleCell open={openCreate} colSpan={headers.length}>
          {createSubRow(row.id, setOpenCreate)}
        </CollapsibleCell>
      </TableRow>

      {row?.subitems?.map((item) => (
        <Fragment key={item.id}>
          <TableRow>
            {headers.map((value) => (
              <CollapsibleCell open={open} key={`${item.id}-${value.id}`}>
                {value.renderSubCell ? value.renderSubCell(item[value.subId]) : item[value.subId]}
              </CollapsibleCell>
            ))}
            <CollapsibleCell open={open}>
              <>
                {editSubRow && (
                  <ButtonBase
                    onClick={() => {
                      setSelectedId(item.id);
                      setOpenSubEdit(!openSubEdit);
                    }}
                    disabled={isFormOpen && !(openSubEdit && selectedId == item.id)}
                    classes={{ disabled: classes.iconDisabled }}>
                    <SvgIcon
                      component={EditButton}
                      fontSize="small"
                      className={clx(classes.icon, classes.editIcon)}
                    />
                  </ButtonBase>
                )}
                {deleteSubRow && (
                  <ButtonBase
                    disabled={isFormOpen}
                    onClick={() => deleteSubRow(item.id, item?.name, row?.name)}
                    className={classes.trashIcon}
                    classes={{ disabled: classes.iconDisabled }}>
                    <SvgIcon component={TrashIcon} fontSize="small" className={classes.icon} />
                  </ButtonBase>
                )}
              </>
            </CollapsibleCell>
          </TableRow>

          <TableRow>
            <CollapsibleCell open={openSubEdit && selectedId == item.id} colSpan={headers.length}>
              {editSubRow(item, row.id, setOpenSubEdit)}
            </CollapsibleCell>
          </TableRow>
        </Fragment>
      ))}
    </>
  );
}

export default CollapsebleRow;
