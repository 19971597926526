import { makeStyles, Theme } from "@material-ui/core";

const usePropertyGeneralTabStyles = makeStyles<Theme>((theme) => ({
  collapse: {
    padding: theme.spacing(2),
  },
  collapsesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default usePropertyGeneralTabStyles;
