import _ from "lodash";
import { BooleanString as BS } from "../constants";

import {
  Services,
  PropertyDataProps,
  LandlordDataProps,
  ThirdPartyProps,
  PublicServicesProps,
  AdminDataProps,
  FileLinksProps,
  MaritalRegime,
  CivilStatus,
  DocumentOptionProps,
} from "../closureFormTypes";
import { isEmpty } from "utils";

export function parseIds(propertyData: any) {
  return {
    propertyUid: propertyData.uid,
    propertyId: propertyData.id,
    propertyDetailsId: propertyData.property_details[0].id,
    userId: propertyData.user.id,
  };
}

export function parsePropertyData(
  propertyData: any,
  contractInfo: any
): Partial<PropertyDataProps> {
  const base = {
    address: propertyData.address,
    addressNumber: propertyData.street_number,
    country: propertyData.country,
    parkingNumbers: propertyData.property_details[0].parking_numbers,
    isUrban: contractInfo.is_urban ? BS.YES : BS.NO,
    propertyRegistrationNumber: propertyData.pin,
    rentValue: propertyData.property_details[0].valor,
    isFurnished: propertyData.property_details[0].furnished?.toString(),
    commonExpenses: propertyData.property_details[0].gc ?? "",
    mortgage: contractInfo.mortgage_code,
    domainRestriction: contractInfo.domain_restriction,
    paymentCondition: contractInfo.special_pay_condition,
    chargeCondition: contractInfo.special_charge_condition,
    policy: contractInfo.policy,
    warehouseNumber: propertyData.property_details[0].warehouse_number,
    departmentNumber: propertyData.numero_depto,
    maxPeopleQuantity: contractInfo.max_people_quantity,
  };
  switch (propertyData.country) {
    case "Chile":
      base["commune"] = propertyData.comuna;
      break;
    case "Colombia":
      base["isHorizontal"] = contractInfo.part_of_horizontal ? BS.YES : BS.NO;
      break;
    case "Mexico":
      base["availableDate"] = contractInfo.available_date;
      base["outerNumber"] = contractInfo.exterior_number;
      base["innerNumber"] = contractInfo.inner_number;
      base["neighborhood"] = {
        value: propertyData.neighborhood?.id,
        label: propertyData.neighborhood?.neighborhood,
      };
      base["commune"] = propertyData.comuna;
      base["region"] = propertyData.region;
      base["postalCode"] = contractInfo.postal_code;
      base["isFlat"] = propertyData.type === "casa" ? BS.NO : BS.YES;
      base["inCondo"] = contractInfo.condominium ? BS.YES : BS.NO;
      base["publicDeed"] = contractInfo.public_deed ? BS.YES : BS.NO;
      base["publicDeedNumber"] = contractInfo.public_deed_number;
      base["publicDeedDate"] = contractInfo.public_deed_date;
      base["notary"] = contractInfo.notary_name;
      base["notaryNumber"] = contractInfo.notary_number;
      base["predialNumber"] = contractInfo.predial_number;
      base["hasCommonExpenses"] = contractInfo.include_common_expenses ? BS.YES : BS.NO;
      base["warranty"] = contractInfo.warranty_month_amount;
      break;
    default:
      break;
  }
  return _.omitBy(base, isEmpty);
}

export function parseDocumentTypes(fetchedDocuments: any): DocumentOptionProps[] {
  return fetchedDocuments
    .filter((doc) => doc.identifier !== "RFC")
    .map((doc) => ({
      value: doc.id,
      label: doc.identifier,
      validationRegex: doc.validation_regex,
    }));
}

export function parseLandlordData(
  propertyData: any,
  contractInfo: any
): Partial<LandlordDataProps> {
  const base = {
    name:
      contractInfo.owner?.name || `${propertyData.user.name} ${propertyData.user.last_name}` || "",
    userType: contractInfo.owner?.tributary_type || "natural",
    document: contractInfo.owner?.rut || propertyData.user.rut || "",
    documentType:
      contractInfo.owner?.document_type ||
      propertyData.user?.document_type?.id?.toString() ||
      BS.NO,
    phoneNumber: contractInfo.owner?.phone || propertyData.user.phone || "",
    email: contractInfo.owner?.email || propertyData.user.email || "",
    address: contractInfo.owner?.address || propertyData.user.address || "",
    city: contractInfo.owner?.city ?? "",
    country: contractInfo.owner?.country || propertyData.country || "",
    civilStatus: _.camelCase(contractInfo.owner?.civil_status) || ("unmarried" as CivilStatus),
    maritalRegime: _.camelCase(contractInfo.owner?.marital_regime) || ("notApply" as MaritalRegime),
    isIvaResponsible: propertyData.property_details[0].is_iva_responsible ? BS.YES : BS.NO,
    specialCondition: contractInfo.special_owner_condition,
    isNew: BS.NO,
  };
  switch (propertyData.country) {
    case "Mexico":
      base["landlordBrokerageCommission"] = contractInfo.lessor_brokerage_commission;
      base["tenantBrokerageCommission"] = contractInfo.lessee_brokerage_commission;
      base["rfc"] = contractInfo.owner?.rfc_number;
      base["occupation"] = contractInfo.owner?.occupation;
      break;
    default:
      break;
  }
  return _.omitBy(base, isEmpty);
}

export function parseThirdPartyData(thirdParty: any): ThirdPartyProps {
  return {
    name: thirdParty?.name || "",
    document: thirdParty?.document || "",
    email: thirdParty?.email || "",
    phone: thirdParty?.phone || "",
    address: thirdParty?.address || "",
    country: thirdParty?.country || "",
    documentType: thirdParty?.document_type || BS.NO,
  };
}

export function parseService(services: any, type: string) {
  const filtered = services.filter((service) => service.type === type);
  return filtered.map((service) => ({
    value: service.id,
    label: service.name,
  }));
}

export function parseServices(
  services: any,
  availableServices: Services,
  contractInfo: any
): PublicServicesProps {
  const parsedServices = {
    waterService: 0,
    waterClient: "",
    energyService: 0,
    energyClient: "",
    gasService: 0,
    gasClient: "",
    cleaningClient: "",
    availableServices,
    includedServices: contractInfo.included_services,
    additionalServices: contractInfo.additional_services,
  };
  services.data.results.forEach((item) => {
    switch (item.service.type) {
      case "energy":
        parsedServices.energyClient = item.client_number;
        parsedServices.energyService = item.service.id;
        break;
      case "water":
        parsedServices.waterClient = item.client_number;
        parsedServices.waterService = item.service.id;
        break;
      case "gas":
        parsedServices.gasClient = item.client_number;
        parsedServices.gasService = item.service.id;
        break;
      default:
        break;
    }
  });
  return parsedServices;
}

export function parseAdminData(propertyData: any, contractInfo: any): AdminDataProps {
  return {
    email: propertyData.administration_email,
    phoneNumber: propertyData.administration_phonenumber,
    hasHoumCleaning: propertyData.property_details[0].has_houm_cleaning ? BS.YES : BS.NO,
    cleaningAmount: contractInfo.cleaning_service_amount,
  };
}

export function parseFileLinks(fileLinksData: any): FileLinksProps {
  return {
    ccFile: fileLinksData.cc,
    predialFile: fileLinksData.predial,
    energyReceipt: fileLinksData.energy_service_receipt,
    gasReceipt: fileLinksData.gas_service_receipt,
    waterReceipt: fileLinksData.water_service_receipt,
    cleaningReceipt: fileLinksData.cleaning_service_receipt,
    administrationReceipt: fileLinksData.administration_service_receipt,
    traditionLibertyFile: fileLinksData.liberty_tradition,
    ineFile: fileLinksData.ine,
    csfFile: fileLinksData.csf,
    publicDeed: fileLinksData.public_deed,
    predialVoucher: fileLinksData.predial_voucher,
  };
}
