import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

interface Props {
  handleIsEdit: () => void;
  disabled?: boolean;
}

const ContractFormButtons = ({ handleIsEdit, disabled = true }: Props) => (
  <Grid item container xs={12} justifyContent="flex-start" alignItems="center" spacing={2}>
    <Grid item>
      <DesignSystemButton
        label="Cancelar"
        variant="tertiaryB"
        size="small"
        onClick={handleIsEdit}
      />
    </Grid>
    <Grid item>
      <DesignSystemButton
        disabled={disabled}
        label="Guardar cambios"
        variant="primary"
        size="small"
        type="submit"
      />
    </Grid>
  </Grid>
);

export default ContractFormButtons;
