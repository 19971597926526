import { makeStyles, Theme } from "@material-ui/core";

const useCompanyFormStyles = makeStyles((theme: Theme) => ({
  selectorWrapper: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    width: "100%",
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
  },
  required: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
  },
}));

export default useCompanyFormStyles;
