import { Box, Typography } from "@material-ui/core";
import { IoDownloadOutline as DownloadIcon } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import useAcceptanceDocumentStyles from "./AcceptanceDocumentStyles";

interface Props {
  link?: string;
  title: string;
}

const AcceptanceDocument = ({ link, title }: Props) => {
  const classes = useAcceptanceDocumentStyles();

  return (
    <Box className={classes.acceptanceDocumentRoot}>
      <Typography className={classes.acceptanceDocumentTitle}>{title}</Typography>
      {link && (
        <LightButton
          variant="primary"
          size="md"
          onClick={() => window.open(link, "_blank")}
          className={classes.downloadLink}
          icon={<DownloadIcon />}
          position="end">
          Descargar
        </LightButton>
      )}
      {!link && (
        <Typography className={classes.acceptanceDocumentTitle}>Documento no disponible</Typography>
      )}
    </Box>
  );
};

export default AcceptanceDocument;
