import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";
import Swal from "sweetalert2";

import newEvaluationService from "services/newEvaluationService";
import contractService from "services/contractService";
import useGetToken from "./useGetToken";
import { Locale } from "models/Countries";

interface FormDataCreateEval {
  country: Locale;
  mainApplicantId: number | string;
  initDate: string;
  habitants_description: string;
  contractId: number;
}

const useCreateLandlordEvaluation = () => {
  const { getToken } = useGetToken();

  const { mutate: createLandlordEvaluation, isLoading: createEvaluationLoading } = useMutation(
    async (formData: FormDataCreateEval) => {
      const authToken = await getToken();
      await newEvaluationService.createLandlordEvaluation(
        formData.country,
        {
          main_applicant_id: formData.mainApplicantId,
          contract_init_date: format(new Date(formData.initDate), "yyyy-MM-dd"),
          contract_habitants_description: formData.habitants_description,
        },
        authToken
      );
    },
    {
      onSuccess: (_, data) =>
        sendProfile({
          contractId: data.contractId,
          initDate: data.initDate,
        }),
      onError: (e: Error) => {
        Swal.fire({
          type: "error",
          text: "No se pudo crear la evaluación del postulante",
        }).then(() => window.location.reload());
      },
    }
  );

  const { mutate: sendProfile, isLoading: loadingSendProfile } = useMutation(
    async (formData: { contractId: number; initDate: string }) => {
      const authToken = await getToken();
      await contractService.sendProfileV2(formData.contractId, authToken);
    },
    {
      onSuccess: () => {
        Swal.fire({
          type: "success",
          text: "Se ha enviado el postulante al propietario correctamente",
        }).then(() => window.location.reload());
      },
      onError: (e) => {
        const error = e as AxiosError<{ message?: string }>;
        const reason = error.response?.data?.message;
        Swal.fire({
          type: "error",
          text: `La evaluación del postulante se han generado con éxito, pero ha ocurrido un error al enviar el perfíl al postulante. Error: ${
            reason ?? e
          }`,
        }).then(() => window.location.reload());
      },
    }
  );

  return {
    createLandlordEvaluation,
    loading: loadingSendProfile || createEvaluationLoading,
  };
};

export default useCreateLandlordEvaluation;
