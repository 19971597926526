import { makeStyles } from "@material-ui/core";

const usePropertyVisibilityManagerStyles = makeStyles((theme) => ({
  gridRoot: {
    width: 532,
    padding: 0,
    margin: 0,
  },
  dialogWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: theme.spacing(4, 4),
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    alignSelf: "flex-start",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "end",
  },
  dialogActions: {
    width: "100%",
    paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
    },
  },
  // hardcoded styles to override material ui styles
  selectITem: {
    width: "100%",
    borderRadius: theme.spacing(0.5),
    border: "none",
    outline: "none",
    backgroundColor: "#EFF2F3",
    "&:hover": {
      outline: "none",
      border: "none",
      borderRadius: "0.25rem",
    },
    "&.Mui-selected": {
      border: "none",
      outline: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  selectContainer: {
    width: "100%",
    border: "none",
    outline: "none",
    "&:before": {
      display: "none",
    },
  },
  "&.Mui-selected": {
    border: "none",
    outline: "none",
  },
  selectItemContainer: {
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  selectWrapper: {
    width: "100%",
    padding: theme.spacing(0),
    border: "none",
    outline: "none",
    "&.Mui-selected": {
      border: "none",
      outline: "none",
    },
    "&.Mui-focused": {
      border: "none",
      outline: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  textField_test: {
    width: "100%",
    minHeight: 112,
    backgroundColor: "#EFF2F3",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.25, 1),
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: "#263238",
  },
  textFieldWrapper: {
    marginTop: theme.spacing(0.5),
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: "#263238",
  },
  actionButton: {
    width: "fit-content",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  errorDialogBox: {
    padding: theme.spacing(2, 2),
    backgroundColor: theme.palette.grey[10],
    borderRadius: theme.spacing(1),
  },
}));

export default usePropertyVisibilityManagerStyles;
