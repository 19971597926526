import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flex: "1",
    alignItems: "flex-end",
  },
  textFieldContainer: { flex: 2 },
  confirmButtonRounded: {
    borderRadius: 200,
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  cancelButton: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    padding: theme.spacing(1, 3),
    border: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: 8,
    cursor: "pointer",
  },
}));
