import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  rootGridForm: {
    "& .MuiGrid-item": {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default useStyles;
