import { useState } from "react";
import { SnackbarProvider } from "notistack";

import EvaluationsListStatistics from "apps/evaluator-admin/components/EvaluationsListStatistics";
import EvaluationsListHeader from "apps/evaluator-admin/components/EvaluationsListHeader";
import EvaluationsListTable from "apps/evaluator-admin/components/EvaluationsListTable";
import { EvaluationsFilter } from "domains/evaluations/models/Evaluations";
import { TableProvider } from "context/tableContext/tableContext";
import { Locale } from "models/Countries";

const defaultFilters = {
  size: 10,
  page: 1,
};

const EvaluationsList = () => {
  const [country, setCountry] = useState<Locale>("cl");
  const [filters, setFilters] = useState<Partial<EvaluationsFilter>>(defaultFilters);

  const onResetFilters = () => setFilters({ size: filters.size, page: 1 });
  const onChangeFilters = (filters: Partial<EvaluationsFilter>) => setFilters(filters);
  const onChangeCountry = (country: Locale) => {
    setCountry(country);
    onResetFilters();
  };

  return (
    <SnackbarProvider>
      <TableProvider>
        <div className="flex flex-col gap-6">
          <EvaluationsListHeader country={country} onChangeCountry={onChangeCountry} />
          <EvaluationsListStatistics country={country} />
          <EvaluationsListTable
            country={country}
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        </div>
      </TableProvider>
    </SnackbarProvider>
  );
};

export default EvaluationsList;
