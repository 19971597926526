import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import newEvaluationService from "services/newEvaluationService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import useStyles from "./RejectOrDesistReasonStyles";

interface Props {
  evaluationId: number;
}

function RejectOrDesistReason({ evaluationId }: Props) {
  const { getToken } = useGetToken();
  const params = useParams<{ country: Locale }>();

  const classes = useStyles();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (authToken) => {
      const res = await newEvaluationService.getRejectOrDesist(
        evaluationId,
        authToken,
        params.country
      );
      if (res) {
        setData(res.data.results[0]);
      }
      setLoading(false);
    };
    getToken().then((authToken) => fetchData(authToken));
  }, [evaluationId]);

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        data != null && (
          <>
            <Typography>Motivo</Typography>
            <Box border={1} borderColor="text.primary" borderRadius={8} my={1} p={2}>
              <Typography>{data.reason}</Typography>
            </Box>
            <Typography>Comentario</Typography>
            <Box border={1} borderColor="text.primary" borderRadius={8} my={1} p={2}>
              <Typography>{data.comment}</Typography>
            </Box>
            <Typography>Autor</Typography>
            <Box border={1} borderColor="text.primary" borderRadius={8} mt={1} mb={3} p={2}>
              <Typography>{data.author}</Typography>
            </Box>
          </>
        )
      )}
    </div>
  );
}

export default RejectOrDesistReason;
