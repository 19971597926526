import { useFormContext } from "react-hook-form";

import FieldCheckbox from "@houm-com/ui/FieldCheckbox";
import FieldInput from "@houm-com/ui/FieldInput";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";
import FieldTextArea from "@houm-com/ui/FieldTextArea";

import { PromotionFormData } from "../../../../utils/types";
import { promotionTypeOptions } from "../../../../utils/constants";
import MonthlyDiscounts from "../MonthlyDiscounts";
import { classes } from "../SpecificDiscountsStyles";
import { FixedDiscountExample, PercentageDiscountExample } from "../DiscountExamples";

interface Props {
  headless?: boolean;
}

const LandlordManagementDiscount = ({ headless = false }: Props) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<PromotionFormData>();
  const promotionTarget = watch("promotionTarget");
  const hasMonthlyVariations = watch("hasMonthlyVariations")?.length > 0;
  const discountMonths = watch("discountMonths");

  return (
    <div className={classes.container}>
      {!headless && (
        <div className={classes.header}>
          <p className={classes.title}>Descuento en comisión de administración</p>
          <p className={classes.landlordTag}>Propietario</p>
        </div>
      )}
      <div className="relative">
        <FixedDiscountExample />
        <PercentageDiscountExample />
        <FieldRadioButton
          id="promotionType"
          label="Tipo de descuento"
          options={promotionTypeOptions(promotionTarget)}
          required
          errorMessage={errors.promotionType?.message}
          form="novalidateform"
          {...register("promotionType")}
        />
      </div>
      <FieldInput
        id="discountMonths"
        label="Número de meses que dura el descuento"
        subLabel="Número de meses en los que se aplicará el descuento"
        required
        type="number"
        placeholder="0"
        errorMessage={errors.discountMonths?.message}
        form="novalidateform"
        {...register("discountMonths")}
      />
      {!hasMonthlyVariations && (
        <FieldInput
          id="landlordManagementDiscount"
          label="Cantidad de descuento"
          required
          type="number"
          placeholder="0%"
          errorMessage={errors.landlordManagementDiscount?.message}
          form="novalidateform"
          {...register("landlordManagementDiscount")}
        />
      )}
      <FieldCheckbox
        id="hasMonthlyVariations"
        label=""
        options={[{ label: "Este descuento varía con los meses", value: "true" }]}
        form="novalidateform"
        {...register("hasMonthlyVariations")}
      />
      {hasMonthlyVariations && <MonthlyDiscounts months={discountMonths} />}
      {promotionTarget !== "both" && (
        <FieldTextArea
          id="landlordObservations"
          label="Observaciones para los propietarios"
          subLabel="Información que verán los propietarios en su contrato"
          placeholder="Algo que debamos saber"
          defaultValue=""
          classNameTextArea="min-h-[120px]"
          errorMessage={errors.landlordObservations?.message}
          form="novalidateform"
          {...register("landlordObservations")}
        />
      )}
    </div>
  );
};

export default LandlordManagementDiscount;
