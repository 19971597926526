import { useState } from "react";
import { Grid } from "@material-ui/core";

import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useContractTemplates } from "context/contractTemplatesContext/contractTemplatesContext";
import {
  setFilters,
  setSubmitResponse,
} from "context/contractTemplatesContext/contractTemplatesActions";
import { CountryUpperCase } from "models/Countries";
import countryCode from "utils/countryCode";

import Title from "../typographies/Title";
import CreateTemplateDialog from "./components/CreateTemplateDialog";
import TemplatesTable from "./components/TemplatesTable";
import { getCountry } from "../../utils";

function Templates() {
  const [openModal, setOpenModal] = useState(false);
  const { state, dispatch } = useContractTemplates();
  const { fetchingTemplates, filters, submitResponse } = state;

  const handleSnackbarClose = () => {
    dispatch(setSubmitResponse(""));
  };

  return (
    <Loading loading={fetchingTemplates}>
      <>
        <Grid container spacing={3}>
          <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Breadcrumbs steps={[{ label: "Cierres" }, { label: "Templates" }]} />
            </Grid>
            <Grid item>
              <CountrySelector
                stateCountry={getCountry(filters.country)}
                onChange={(country: CountryUpperCase) =>
                  dispatch(setFilters({ country: countryCode(country) }))
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Title>Templates</Title>
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            <DesignSystemButton
              label="Nuevo template"
              size="small"
              variant="primary"
              onClick={() => setOpenModal(true)}
            />
          </Grid>
          <Grid item xs={12}>
            <TemplatesTable />
          </Grid>
        </Grid>
        <CreateTemplateDialog open={openModal} onClose={() => setOpenModal(false)} />
        <DesignSystemSnackbar
          type="error"
          open={submitResponse === "error"}
          onClose={handleSnackbarClose}
          head="Ha ocurrido un problema al subir los Documentos"
        />
        <DesignSystemSnackbar
          type="success"
          open={submitResponse === "success"}
          onClose={handleSnackbarClose}
          head="Documentos adjuntados exitosamente"
        />
      </>
    </Loading>
  );
}

export default Templates;
