import { makeStyles } from "@material-ui/core";

const useScheduleStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    margin: theme.spacing(3, 0),
  },
  searchContainer: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "40%",
  },
}));
export default useScheduleStyles;
