import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import promotionService from "domains/promotion/service";
import { EditPromotionForm } from "domains/promotion/service/types";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

const useEditPromotion = () => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const {
    mutate: editPromotion,
    isError,
    isLoading: isEditLoading,
    isSuccess,
    error,
  } = useMutation(
    async ({ data, promotionId }: { data: EditPromotionForm; promotionId: number }) => {
      const authToken = await getToken();
      const response = await promotionService.newEditPromotion(data, promotionId, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("promotions_list");
        queryClient.invalidateQueries("promotion_by_id");
        alert.success({
          message: "La promoción se ha editado correctamente",
        });
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: "Error al editar promoción",
          disclaimer: `La promoción no se pudo editar, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    editPromotion,
    isEditLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useEditPromotion;
