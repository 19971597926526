import { useGetToken } from "hooks";
import { useQuery } from "react-query";
import contractService from "services/contractService";
import { IDesertionProcessInfoResponse } from "services/contractService/types";
import { IDesertionProcessInfo } from "../utils/types";

const parseDesertionProcessInfo = (data: IDesertionProcessInfoResponse): IDesertionProcessInfo => ({
  contractId: data.contract_id,
  startDate: new Date(data.created_at),
  id: data.id,
  status: data.status,
});

const useGetDesertionProcessInfo = (contractId: number | null) => {
  const { getToken } = useGetToken();
  const { data: desertionProcessInfo, ...rest } = useQuery(
    ["get-desertion-process-info", contractId],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getDesertionProcessInfo(contractId, authToken);
      return parseDesertionProcessInfo(response.data);
    },
    { enabled: !!contractId, retry: false }
  );
  return { desertionProcessInfo, ...rest };
};

export default useGetDesertionProcessInfo;
