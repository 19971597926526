import { makeStyles } from "@material-ui/core";

const useCancelRescheduleModalStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: "none",
    borderRadius: 20,
    minWidth: 150,
    fontWeight: 700,
  },
  labelAutocomplete: {
    width: "100%",
  },
  buttonContainers: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(0, 6),
  },
  autocompleteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  openReasonContainer: {
    margin: "0px auto",
    width: "100%",
  },
  textArea: {
    borderRadius: 20,
    border: "1px solid",
    padding: theme.spacing(1, 1.5),
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
    height: "100%",
  },
  textAreaLabel: {
    marginBottom: theme.spacing(1),
  },
}));

export default useCancelRescheduleModalStyles;
