import { useMemo, useState } from "react";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import { CustomDataGrid, CustomLink } from "Components/Admin/UIComponents";
import { useSimilarProperties } from "context/similarProperties/similarPropertiesContext";

import { useCalculator } from "context/calculatorContext/calculatorContext";

import moment from "moment";
import Table from "Components/Admin/administrator/properties/show/tabs/rev/Table";

function DealTimeTable() {
  const { state } = useSimilarProperties();
  const { similarHistoricProperties } = state;
  const { state: calculatorState } = useCalculator();
  const { deal } = calculatorState;

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const dealTitle = useMemo(() => (deal === "rental" ? "arriendo" : "venta"), [deal]);

  return (
    <Table.Section>
      <Table.Title>{`Tiempo de ${dealTitle}`}</Table.Title>
      <CustomDataGrid
        columns={columns}
        rows={similarHistoricProperties}
        paginationMode="client"
        sortingMode="client"
        pageSize={pageSize}
        onPageSizeChange={(p) => setPageSize(p)}
        page={page}
        onPageChange={(p) => setPage(p)}
        localeText={{ noRowsLabel: "Sin resultados" }}
      />
    </Table.Section>
  );
}

export default DealTimeTable;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

const columns: GridColumns = [
  {
    ...columnsConfig,
    field: "publicationDate",
    headerName: "Fecha de publicación",
    width: 160,
    sortable: true,
    type: "date",
    sortComparator: (v1, v2) => {
      const newV1 = moment(v1 as string, "YYYY-MM-DD").valueOf();
      const newV2 = moment(v2 as string, "YYYY-MM-DD").valueOf();
      return newV1 > newV2 ? 1 : -1;
    },
  },
  {
    ...columnsConfig,
    field: "reservationDate",
    headerName: "Fecha de reserva",
    width: 160,
    sortable: true,
    type: "date",
    sortComparator: (v1, v2) => {
      const newV1 = moment(v1 as string, "YYYY-MM-DD").valueOf();
      const newV2 = moment(v2 as string, "YYYY-MM-DD").valueOf();
      return newV1 > newV2 ? 1 : -1;
    },
  },
  {
    ...columnsConfig,
    field: "address",
    headerName: "Dirección",
    width: 160,
  },
  {
    ...columnsConfig,
    field: "price",
    headerName: "Precio",
    width: 160,
  },
  {
    ...columnsConfig,
    field: "hasHoumBox",
    headerName: "Houm Box",
    width: 50,
    sortable: true,
  },
  {
    ...columnsConfig,
    field: "visits",
    headerName: "Visitas",
    width: 50,
    sortable: true,
  },
  {
    field: "id",
    resizable: false,
    filterable: false,
    headerName: "ID",
    width: 100,
  },

  {
    ...columnsConfig,
    field: "publishedTime",
    headerName: "Tiempo [Dias]",
    width: 140,
    sortable: true,
  },
  {
    ...columnsConfig,
    field: "link",
    headerName: "Acciones",
    width: 50,

    renderCell: ({ value }: GridCellParams) => <CustomLink to={value as string} target="_blank" />,
  },
];
