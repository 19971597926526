import HeaderWithCountries from "Components/Admin/UIComponents/HeaderWithCountries";
import { changeCountry, getLocationOptions } from "context/projectContext/projectActions";
import { useProject } from "context/projectContext/projectContext";
import { Country } from "context/projectContext/projectTypes";
import { setCurrentPage } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import { useGetToken } from "hooks";
import { useEffect } from "react";

interface Props {
  title: string;
}

function Header({ title }: Props) {
  const { dispatch, state } = useProject();
  const { dispatch: tableDispatch } = useTable();
  const { getToken } = useGetToken();

  const onClickHandle = (country: Country) => {
    dispatch(changeCountry(country));
    tableDispatch(setCurrentPage(1));
  };

  useEffect(() => {
    getToken().then((authToken) => getLocationOptions(state.country, dispatch, authToken));
  }, [state.country]);

  return (
    <HeaderWithCountries title={title} country={state.country} onCountryChange={onClickHandle} />
  );
}

export default Header;
