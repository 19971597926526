export const status = [
  { value: "Not Contact", label: "Sin contactar" },
  { value: "Not Answer", label: "No contesta" },
  { value: "Following", label: "Seguimiento" },
  { value: "Lost", label: "Perdido" },
  { value: "Qualified", label: "Calificado" },
];

export const qualifications = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const lostReasons = [
  { label: "Inmueble comercial", value: "Inmueble comercial" },
  { label: "Inmueble en venta", value: "Inmueble en venta" },
  { label: "Fuera de cobertura", value: "Fuera de cobertura" },
  {
    label: "Arriendo directo sin admnistracion",
    value: "Arriendo directo sin admnistracion",
  },
  { label: "Ya arrendo su inmueble", value: "Ya arrendo su inmueble" },
  { label: "Es agente inmobiliario", value: "Es agente inmobiliario" },
  {
    label: "No ha registrado sus datos para ser contactado",
    value: "No ha registrado sus datos para ser contactado",
  },
  {
    label: "No esta interesado en el servicio",
    value: "No esta interesado en el servicio",
  },
  {
    label: "No pudo ser contactado en 4 intentos",
    value: "No pudo ser contactado en 4 intentos",
  },
];

export const subjects = [
  { value: "Call 1", label: "Llamada 1" },
  { value: "Follow Up", label: "Seguimiento" },
  { value: "Schedule Photo", label: "Agendar Foto" },
  { value: "Follow Up Photo", label: "Seguimiento Foto" },
  { value: "Other", label: "Otro" },
];

export const contactMediums = [
  { value: "Call", label: "Llamada" },
  { value: "Message", label: "Mensaje" },
  { value: "Mail", label: "Correo" },
];

export const completedOptions = [
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

export const cities = [
  {
    label: "Santiago",
    value: "Región Metropolitana de Santiago",
    country: "chile",
  },
  { label: "Valparaíso", value: "Valparaíso", country: "chile" },
  { label: "Concepción", value: "Concepción", country: "chile" },
  { label: "Limache", value: "Limache", country: "chile" },
  { label: "Chicureo", value: "Chicureo", country: "chile" },
  { label: "Quilpué", value: "Quilpué", country: "chile" },
  { label: "Viña del Mar", value: "Viña del Mar", country: "chile" },
  { label: "Medellín", value: "Medellín", country: "colombia" },
  { label: "Bogotá", value: "Bogotá", country: "colombia" },
  { label: "Ciudad de México", value: "Ciudad de Mexico", country: "mexico" },
  { label: "Guadalajara", value: "Guadalajara", country: "mexico" },
  { label: "Monterrey", value: "Monterrey", country: "mexico" },
  { label: "Tijuana", value: "Tijuana", country: "mexico" },
];
