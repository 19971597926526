import { setCountry } from "../context/reservationsActions";
import HeaderWithCountries from "Components/Admin/UIComponents/HeaderWithCountries";
import { useReservations } from "../context/reservationsContext";
import { Country } from "models/Countries";

interface Props {
  title: string;
  show?: boolean;
}

function Header({ title, show = true }: Props) {
  const { state, dispatch } = useReservations();
  const onCountryChangeHandler = (country: Country) => {
    dispatch(setCountry(country));
  };
  return (
    <HeaderWithCountries
      title={title}
      country={state.country}
      onCountryChange={onCountryChangeHandler}
      showCountry={show}
    />
  );
}

export default Header;
