import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";

import { useTag } from "context/tagContext/tagContext";

import { ConfirmModal, CountrySelect, SearchID, SubmitTag, TagDetails } from "./components";

import validationSchema from "./validationSchema";
import { useEffect } from "react";
import { setShowSuccess } from "context/tagContext/tagActions";

function TagsForm() {
  const { state, dispatch } = useTag();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    dispatch(setShowSuccess(false));
  }, []);

  return (
    <FormProvider {...methods}>
      <form>
        <Grid container justifyContent="flex-start">
          <CountrySelect />
          {state.form.country && <SearchID />}
          {state.form.ids.length > 0 && (
            <Grid item xs={12} container justifyContent="space-between">
              <TagDetails />
              <SubmitTag />
            </Grid>
          )}
        </Grid>
      </form>
      <ConfirmModal />
    </FormProvider>
  );
}

export default TagsForm;
