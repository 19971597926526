import { StringOption } from "models/Option";
import { AppraiserRole } from "models/Appraiser";

export const getDefaultRolValues = (
  defaultValues: AppraiserRole[],
  currentOptions: StringOption[]
): AppraiserRole[] => {
  const defaults = defaultValues.filter((val) =>
    currentOptions.some((option) => option.value === val)
  );
  return defaults;
};
