import axios, { AxiosResponse } from "axios";
import qs from "query-string";

import { Locale } from "models/Countries";

import { createHttp } from "../helper";
import { PAYMENTS_URL, BACKEND_URL } from "../../env";
import { ParseCreatePaymentOrder } from "models/Payments";
import { IWarrantyReceiver } from "models/Warranty";
import {
  IAdjustmentData,
  ICreateReservation,
  IExchangeReservation,
  ILandlordBreakdownResponse,
  IMarkAsPaidData,
  IReceiptsResponse,
  IRefundReservation,
  IReservationResponse,
  ISpotData,
  ISubscriptionMovementsResponse,
  IWarrantyResponse,
  IWarrantyDiscountResponse,
  IWarrantyReasonsService,
  IMarkAsPaid,
  IEditDateRefundReservation,
} from "./types";

const paymentsLegacy = axios.create({
  baseURL: `${PAYMENTS_URL}v1/`,
  headers: { "Content-Type": "application/json" },
});

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  createReservation: (country: Locale, data: ICreateReservation, token: string) =>
    createHttp(token, PAYMENTS_URL).post(`/${country}/v3/movements/income/reservations`, data),

  createRefund: (country: Locale, reservationId: string, data: IRefundReservation, token: string) =>
    createHttp(token, PAYMENTS_URL).post(
      `${country}/v3/reservations/${reservationId}/refund`,
      data
    ),

  editRefundDate: (
    country: Locale,
    reservationId: string,
    data: IEditDateRefundReservation,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).put(
      `${country}/v3/reservations/refund/${reservationId}/reschedule`,
      data
    ),

  getRefundsCL: (
    country: Locale,
    userId: number,
    propId: number,
    { dateFrom, dateTo }: { dateFrom: string; dateTo: string },
    token: string
  ): Promise<AxiosResponse<IReservationResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/people/${userId}/movements`, {
      params: {
        get_only: "refund",
        from: dateFrom,
        to: dateTo,
        property_id: propId,
      },
    }),

  getRefunds: (
    country: Locale,
    userId: number,
    propId: number,
    { dateFrom, dateTo }: { dateFrom: string; dateTo: string },
    token: string
  ): Promise<AxiosResponse<IReservationResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/people/${userId}/movements`, {
      params: {
        type: "outflow",
        concept: "refund",
        from: dateFrom,
        to: dateTo,
        property_id: propId,
      },
    }),

  createExchangeV2: (
    country: Locale,
    movementId: string,
    data: IExchangeReservation,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).put(
      `${country}/v3/movements/income/reservations/${movementId}/exchange`,
      data
    ),

  getGroupV2: (
    country: Locale,
    groupId: string,
    token: string
  ): Promise<AxiosResponse<IReservationResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/movements/groups/${groupId}`),

  getUserReservations: (
    country: Locale,
    userId: number,
    propId: number,
    { dateFrom, dateTo }: { dateFrom: string; dateTo: string },
    token: string,
    cancelToken = undefined
  ): Promise<AxiosResponse<IReservationResponse>> => {
    let urlQuery: string;
    if (country === "cl") {
      urlQuery = `get_only=reservation&from=${dateFrom}&to=${dateTo}&property_id=${propId}`;
    } else if (["co", "mx"].includes(country)) {
      urlQuery = `type=income&concept=reservation&from=${dateFrom}&to=${dateTo}&property_id=${propId}`;
    }
    return createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/movements?${urlQuery}`,
      {
        cancelToken,
      }
    );
  },

  getReceiptsByQuotation: async (quotationId) =>
    paymentsLegacy.get(`/quotations/${quotationId}/receipts`).catch(),

  getAllSubscriptionMovements(
    country: Locale,
    subId: number,
    mType: "outflow" | "income",
    token: string
  ): Promise<AxiosResponse<ISubscriptionMovementsResponse>> {
    const http = createHttp(token, BACKEND_URL);
    return http.get(`/v2/movements/${country}/subscriptions/${subId}?type=${mType}`);
  },

  reservationsIndex: (country, status, pageSize, page, token) =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/movements?per_page=${pageSize}&page=${page}&type=income&concept=reservation&status=${status}&from=2020-01-01&to=3000-01-01`
    ),

  deletePaymentButton: (country: Locale = "cl", contractId: number, token: string) =>
    createHttp(token, PAYMENTS_URL).delete(`${country}/v3/movements/income/contracts`, {
      data: { contract_id: contractId },
    }),

  deletePaymentDetails: (country: Locale = "cl", contractId: number, token: string) =>
    createHttp(token, PAYMENTS_URL).delete(`${country}/v3/payment_details`, {
      data: { contract_id: contractId },
    }),

  getBreakdownMovements: (
    country: Locale = "cl",
    contractId: number,
    token: string,
    endDate: string = null,
    startDate: string = null
  ) =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/movements/income/breakdown?${qs.stringify(
        { contract_id: contractId, start_date: startDate, end_date: endDate },
        qsConfig
      )}`
    ),

  getLandlordBreakdown: (
    country: Locale,
    userId: string,
    token: string,
    params = {}
  ): Promise<AxiosResponse<ILandlordBreakdownResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/full_breakdown?${qs.stringify(params, qsConfig)}`
    ),

  // PAYMENTS VIEW
  createPaymentOrder: (country: Locale, data: ParseCreatePaymentOrder, token: string) =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/movements/groups`, data),

  createSpotPayment: (country: Locale, data: ISpotData, token: string) =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/movements/spot`, data),

  markAsPaidMovement: (country: Locale, movementId: string, data: IMarkAsPaidData, token: string) =>
    createHttp(token, PAYMENTS_URL).put(`${country}/v3/movements/${movementId}/pay`, data),

  changePayMovementData: (
    country: Locale,
    movementId: string,
    data: { status?: string; agreed_date?: string },
    token: string
  ) => createHttp(token, PAYMENTS_URL).patch(`${country}/v3/movements/${movementId}`, data),

  deletePenalty: (country: Locale, movementId: string, token: string) =>
    createHttp(token, PAYMENTS_URL).delete(
      `${country}/v3/movements/income/leases/penalty/${movementId}`
    ),

  deleteAdjustment: (country: Locale, movementId: string, adjustmentId: string, token: string) =>
    createHttp(token, PAYMENTS_URL).delete(
      `${country}/v3/movements/${movementId}/adjustment/${adjustmentId}`
    ),

  activatePromotion: (country: Locale, subId: number, months: number, token: string) =>
    createHttp(token, PAYMENTS_URL).post(
      `${country}/v3/movements/promotions/${subId}?number_of_months=${months}`
    ),

  createAditional: (country: Locale, movementId: string, data: IAdjustmentData, token: string) =>
    createHttp(token, PAYMENTS_URL).put(`${country}/v3/movements/${movementId}/additionals`, data),

  createDiscount: (country: Locale, movementId: string, data: IAdjustmentData, token: string) =>
    createHttp(token, PAYMENTS_URL).put(`${country}/v3/movements/${movementId}/discounts`, data),

  getReceipts: (
    movementId: string,
    country: Locale = "cl",
    token: string
  ): Promise<AxiosResponse<IReceiptsResponse[]>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/billing/${movementId}/invoices`),

  getWarranty: (
    country: Locale,
    contractId: number,
    token: string
  ): Promise<AxiosResponse<IWarrantyResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/warranty/${contractId}`),

  createWarrantyDiscount: (
    country: Locale,
    contractId: number,
    data: {
      amount: number;
      description: string;
      reason: string;
    },
    token: string
  ): Promise<AxiosResponse<IWarrantyDiscountResponse>> =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/warranty/${contractId}/discount`, data),

  deleteWarrantyDiscount: (
    country: Locale,
    contractId: number,
    movementId: string,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).delete(
      `${country}/v3/warranty/${contractId}/discount/${movementId}`
    ),

  liquidateWarranty: (
    country: Locale,
    contractId: number,
    data: {
      lessor_id: number;
      lessee_id: number;
      property_id: number;
      subscription_id: number;
    },
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/warranty/${contractId}/liquidation`, data),

  changeWarrantyCondition: (
    country: Locale,
    contractId: number,
    data: {
      receiver?: IWarrantyReceiver;
      agreed_date?: string;
    },
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).put(`${country}/v3/warranty/${contractId}/liquidation`, data),

  getWarrantyReasons: (
    country: Locale,
    token: string
  ): Promise<AxiosResponse<IWarrantyReasonsService[]>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/warranty/discount/reasons`),
  markReservationAsPaid: (token: string, country: Locale, data: IMarkAsPaid) =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/manual-payment/reservation/notify`, data),
};
