import * as yup from "yup";
import { validate, format } from "rut.js";

import { CountryUpperCase } from "models/Countries";

export const requiredText = "Obligatorio";

const validateDocument = (
  country: CountryUpperCase,
  value: string,
  documentType: number = null
): boolean => {
  const INE_ID = 5;
  switch (country) {
    case "Chile":
      return validate(format(value));
    case "Colombia":
      return true;
    case "Mexico":
      if (documentType === INE_ID) {
        return /^(\d{13})|(-$)/.test(value);
      } else {
        return /^[a-zA-Z]{1}/.test(value);
      }
    default:
      return false;
  }
};

const rfcValidation = () =>
  yup.mixed().test({
    name: "rfcNumber",
    message: "RFC inválido",
    test: (value) =>
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/.test(
        value
      ),
  });

export const evaluateFormSchema = (country: CountryUpperCase) =>
  yup
    .object({
      street: country === "Chile" && yup.string().required(requiredText),
      city: country === "Chile" && yup.string().required(requiredText),
      name: yup.string().required(requiredText),
      lastName: yup.string().required(requiredText),
      secondLastName: yup.string().required(requiredText),
      salary1: yup.string().required(requiredText),
      salary2: yup.string().required(requiredText),
      salary3: yup.string().required(requiredText),
      salaryOthers: yup.string().required(requiredText),
      salaryAvg: yup.string().required(requiredText),
      confirmedSalary: yup.string().required(requiredText),
      contractType: yup.string().required(requiredText),
      dependent: yup.string().required(requiredText),
      document: yup.string().required(requiredText),
      documentType: country === "Mexico" && yup.number().required(requiredText),
      employer: yup.string().required(requiredText),
      nationality: country === "Chile" && yup.string().required(requiredText),
      occupation: yup.string().required(requiredText),
      position: yup.string().required(requiredText),
      salaryMonths: yup.string().required(requiredText),
      salaryOthersDescription: yup.string().required(requiredText),
      trialsComment: yup.string(),
      visa: country === "Chile" && yup.string().required(requiredText),
      rfcNumber: country === "Mexico" && rfcValidation().required(requiredText),
      scoreBuro:
        country === "Mexico" && yup.number().typeError(requiredText).required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.documentType && country === "Mexico") {
        return new yup.ValidationError("Obligatorio", null, "document_type", "typeError");
      }
      if (validateDocument(country, obj.document, obj.documentType)) {
        return true;
      }
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
