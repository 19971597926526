import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { useGetToken, useQueryParams } from "hooks";
import {
  fetchPerson,
  initializeState,
  setDocumentTypes,
} from "context/contractPersonContext/contractPersonActions";
import { useContractPerson } from "context/contractPersonContext/contractPersonContext";
import { CountryUpperCase } from "models/Countries";
import documentTypeService from "services/documentTypeService";

import DocumentForm from "./DocumentForm";
import PersonForm from "./PersonForm";

export interface ContractPersonEditProps {
  contractId?: string;
  contractPersonId?: string;
  country?: CountryUpperCase;
  propertyId: number;
  redirectionUrl?: string;
  role: string;
  title: string;
}

function ContractPersonEdit({
  contractId: paramsContractId,
  contractPersonId,
  country: paramsCountry,
  propertyId,
  redirectionUrl,
  role: paramsRole,
  title: paramsTitle,
}: ContractPersonEditProps) {
  const { state, dispatch } = useContractPerson();
  const { getToken } = useGetToken();
  const params = useParams<{ id: string }>();
  const query = useQueryParams();

  useEffect(() => {
    (async () => {
      const authToken = await getToken();
      const country = paramsCountry || ((query.get("country") ?? "Chile") as CountryUpperCase);
      const contractId = paramsContractId || params.id;
      initializeState(
        state,
        dispatch,
        paramsTitle,
        country,
        propertyId,
        redirectionUrl,
        paramsRole,
        contractId,
        contractPersonId,
        authToken
      );
    })();
  }, []);

  useEffect(() => {
    // fetch DocumentTypes per country
    if (state.country) {
      (async () => {
        const authToken = await getToken();
        const res = await documentTypeService.getList(state.country, authToken);
        if (typeof res === "object" && res?.data) {
          dispatch(
            setDocumentTypes(
              res.data?.results.map((res) => ({
                id: res.id,
                type: res.type,
                regex: new RegExp(res.validation_regex),
              }))
            )
          );
        }
      })();
    }
  }, [state.country]);

  useEffect(() => {
    if (state.searchParams) {
      (async () => {
        const authToken = await getToken();
        fetchPerson(authToken, state, dispatch);
      })();
    }
  }, [state.searchParams]);

  if (!state.person.ready) {
    return <DocumentForm />;
  }

  return (
    <SnackbarProvider>
      <PersonForm />
    </SnackbarProvider>
  );
}

export default ContractPersonEdit;
