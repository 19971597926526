import moment from "moment";
import { renderAddress, renderPriceProperty } from "utils";
import { IActivity, ILead, QualificationForm } from "./leadTypes";

export const parseUpdateLeadQualification = (data: QualificationForm) => ({
  email: data.email,
  phone: data.phone,
  qualification: data.score,
  lost_reason: data.lostReason,
  status: data.status,
  detail: data.comments,
});

export const parseGetLeadData = (data): ILead => ({
  id: data?.id,
  country: data?.country,
  name: data?.name,
  email: data?.email,
  qualification: data?.qualification,
  leadQualifier: data?.owner?.name,
  phone: data?.phone,
  status: data?.status,
  lostReason: data?.lostReason,
  createdAt: moment(data?.created_at).utc().local().format("YYYY-MM-DD"),
  campaign: data?.campaign,
  category: data?.category,
  followUpDate: data?.following_date
    ? moment(data?.following_date).utc().local().format("DD/MM/YYYY HH:mm")
    : "",
  properties: data?.user?.properties
    ? data.user.properties.map((property) => ({
        id: property?.id,
        address: renderAddress(property),
        status: property?.property_details[0]?.status,
      }))
    : [],
});

export const parseCreateLeadActivity = (data: IActivity) => ({
  lead: data.lead,
  end_date: moment(data.endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
  property: data.property,
  contact_medium: data.contactMedium,
  subject: data.subject,
  other_subject: data.otherSubject,
  status: data.status,
  detail: data.detail,
});

export const parseGetLeadActivitiesResult = (results): IActivity[] =>
  results.map((activity) => ({
    id: activity?.id,
    lead: activity?.lead?.id,
    leadId: activity?.lead?.id,
    leadName: activity?.lead?.name,
    leadQualification: activity?.lead?.qualification,
    leadPhone: activity?.lead?.phone,
    createdAt: moment(activity?.created_at).utc().local().format("DD-MM-YYYY HH:mm"),
    endDate: moment(new Date(activity?.end_date)).utc().local().format("DD-MM-YYYY HH:mm"),
    property: activity?.property,
    propId: activity?.property?.id,
    contactMedium: activity?.contact_medium,
    subject: activity?.subject,
    otherSubject: activity?.other_subject,
    detail: activity?.detail,
    origin: activity?.origin,
    owner: activity?.lead?.owner?.name ?? "",
    status: activity?.status,
    isCompleted: activity?.is_completed,
    tokyCallId: activity?.toky_call_id,
    tokyCall: {
      id: activity?.toky_call?.callid,
      callId: activity?.toky_call?.callid,
      duration: activity?.toky_call?.duration,
      url: activity?.toky_call?.record_url,
    },
  }));

export const parseGetLeadProperties = (data) =>
  data.map((property) => ({
    id: property.id,
    forSale: property?.for_sale,
    forRental: property?.for_rental,
    photoUrl: property?.photos[0]?.url,
    address: renderAddress(property),
    formatedPrice: renderPriceProperty(property, property.for_rental ? "forRental" : "forSale"),
    status: property.for_rental
      ? property?.property_details[0]?.status
      : property?.sales_details?.status,
    showUrl: `/admin/properties/${property.uid}`,
    user: `${property?.user?.name} ${property?.user?.last_name}`,
    owner: property?.houm_owner?.name,
    country: property?.country,
    location: property?.comuna,
  }));
