import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  marker: {
    backgroundImage: "url('../../public/static/images/map-icon.png')",
    backgroundSize: "cover",
    width: 20,
    height: 20,
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 1,
  },

  mapRoot: {
    width: "100%",
    height: "100%",
    borderRadius: "0",
    "& .marker": {
      backgroundImage: "url('/static/images/my-property-pin.png')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: 45,
      height: 45,
      cursor: "pointer",
    },
    "& .prices": {
      backgroundColor: "#fff",
      backgroundSize: "cover",
      justifyContent: "center",
      borderRadius: 100,
      fontWeight: 700,
      padding: theme.spacing(0.3),
      cursor: "pointer",
      zIndex: 100,
    },
    "& p": {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1,
      margin: theme.spacing(0.5, 0),
    },
    "& .priceContainer": {
      display: "flex",
      flexDirection: "column",
      fontFamily: "'Nunito', sans-serif",

      backgroundColor: "#FFFFFF",
      backgroundSize: "cover",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      borderRadius: "15px",

      padding: theme.spacing(1),
      cursor: "pointer",
      zIndex: 200,
    },
    "& .pointer": {
      width: 0,
      height: 0,
      borderRight: "5px solid transparent",
      borderLeft: "5px solid transparent",
      borderTop: `5px solid white`,
    },
    "& .container": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .mainPropContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      top: 15,
    },
    "& .root": {
      textFont: ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  "mapbox-map-wrapper": {
    width: "100%",
    height: "100%",
    "& .mapboxgl-canvas-container": {
      overflow: "hidden",
    },
    "& .mapboxgl-popup": {
      padding: "20px",
      zIndex: theme.zIndex.tooltip,
    },

    "& .mapboxgl-popup-content": {
      textAlign: "center",
      fontFamily: "'Nunito', sans-serif",
      padding: 0,
      display: "flex",
      borderRadius: "15px",
      overflow: "hidden",
    },

    "& .mapbox-popup-prop-wrap": {
      fontSize: "14px",
      color: "#6D6D6D",
      "& .popup-mapbox-prop-image": {
        width: "200px !important",
        height: "130px !important",
        objectFit: "cover",
        marginBottom: theme.spacing(1),
      },
    },

    "& .mapbox-prop-card-description": {
      padding: theme.spacing(1, 2),
    },

    "& .mapbox-prop-card-features": {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: theme.spacing(1),
    },

    "& .mapbox-prop-card-price": {
      fontWeight: "normal",
      fontSize: "1.125rem",
      color: "#212121",
      marginBottom: theme.spacing(1),
    },
    "& .mapbox-no-prop-text": {
      marginTop: theme.spacing(2),
      width: "200px !important",
    },
  },
}));

export default useStyles;
