import { Link } from "react-router-dom";

import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import { Grid } from "@material-ui/core";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { PropertyData } from "models/Property";

import useSubscriptionsInfoStyles from "./SubscriptionsInfoStyles";
import NoRowsOverlayError from "./components/NoRowsOverlayError";
import useSubscriptions from "./hooks/useSubscriptions";
import ReportTable from "./components/ReportTable";

interface Props {
  property: PropertyData;
}

function SubscriptionsInfo({ property }: Props) {
  const classes = useSubscriptionsInfoStyles();
  const { data, error, isLoading } = useSubscriptions(property?.id);

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
    sortable: false,
  };

  const idConfig: GridColDef = {
    field: "",
    resizable: false,
    flex: 1,
    filterable: false,
    hide: false,
  };

  const columns: GridColumns = [
    {
      ...idConfig,
      field: "id",
      headerName: "ID",
      renderCell: ({ value }: GridCellParams) => (
        <Link to={`/admin/clients/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      ...columnsConfig,
      field: "createDate",
      headerName: "Fecha",
      renderCell: ({ value }: GridCellParams) => value,
    },
    {
      ...columnsConfig,
      field: "tenant",
      headerName: "Arrendatario",
      renderCell: ({ value }: GridCellParams) => value,
    },
    {
      ...idConfig,
      field: "landlord",
      headerName: "Propietario",
      renderCell: ({ value }: GridCellParams) => value,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CollapseCard title="Solicitudes de contrato">
          <div className={classes.collapse}>
            <CustomDataGrid
              className={classes.table}
              rowCount={data.length}
              columns={columns}
              rows={data}
              hideFooter
              hideFooterPagination
              hideFooterRowCount
              loading={isLoading}
              components={{
                NoRowsOverlay: error && NoRowsOverlayError,
              }}
            />
          </div>
        </CollapseCard>
      </Grid>
      <Grid item xs={12}>
        <CollapseCard title="Solicitudes y reportes">
          <div className={classes.collapse}>
            <ReportTable propertyId={property?.id} />
          </div>
        </CollapseCard>
      </Grid>
    </Grid>
  );
}

export default SubscriptionsInfo;
