import BaseField from "../../../BaseField";
import { MainText } from "../../../typography";

interface ReadjustProps {
  type: string;
  date: string;
}

function ReadjustField({ type, date }: ReadjustProps) {
  return (
    <BaseField requiredData={type && date}>
      <MainText>{type}</MainText>
      <MainText>{date}</MainText>
    </BaseField>
  );
}

export default ReadjustField;
