import ModalDialog from "@houm-com/ui/ModalDialog";

import { IUserBankAccountResponse } from "services/bankAccountService/types";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import RefundForm from "./components/RefundForm";

interface Props {
  openModal: boolean;
  handleModal: () => void;
  reservationAmount: number | null;
  currency: CurrencyCode | null;
  country: Locale;
  dataBankAccount?: IUserBankAccountResponse | null;
  movementId?: string;
  propertyId: number;
}

const RefundModal = ({
  openModal,
  handleModal,
  reservationAmount,
  currency,
  country,
  dataBankAccount,
  movementId,
  propertyId,
}: Props) => (
  <ModalDialog isOpen={openModal} title="Generar reembolso" onClose={handleModal} hasCloseBtn>
    <RefundForm
      reservationAmount={reservationAmount}
      currency={currency}
      country={country}
      reservationId={movementId}
      dataBankAccount={dataBankAccount}
      handleModal={handleModal}
      propertyId={propertyId}
    />
  </ModalDialog>
);

export default RefundModal;
