import currency from "@houm-com/formats/currency";

import {
  MovementBreakdownRecord,
  Breakdown,
  MovementDetailRow,
  MovementType,
} from "models/Payments";
import { IMovements } from "services/newPaymentService/types";
import { isCurrentDateAllowed } from "utils/datetime";
import compareMovements from "utils/dateUtilities";

import { CONCEPTS, INCOME, OUTFLOW, PENALTY, TYPE_ALL } from "./constants";

export const getPenaltyTotal = (additionalsBreakdown: Breakdown[]): number => {
  let penalty = 0;
  (additionalsBreakdown || []).forEach((item) => {
    if (item.label === PENALTY) {
      penalty += item.value;
    }
  });
  return penalty;
};

export const parseMovementsRows = (movements: MovementBreakdownRecord[]): MovementDetailRow[] =>
  movements.map((movement) => ({
    id: movement.movement_id,
    type: movement.type,
    agreed_date: movement.agreed_date,
    status: movement.status,
    totalAmount: currency(movement?.total_amount ?? 0, movement.currency),
    penalty: getPenaltyTotal(movement.additionals_breakdown),
    payment: currency(movement.payment ?? 0, movement.currency),
    datePayment: movement.date,
    currency: movement.currency,
    actions: {
      movement_id: movement.movement_id,
      country: movement.country,
      status: movement.status,
      agreed_date: movement.agreed_date,
      total_amount: movement.total_amount,
      penalty: getPenaltyTotal(movement.additionals_breakdown),
      concept: movement.concept,
      type: movement.type,
    },
  }));

export const typeToOptions = () => [
  { label: "Ambos", value: TYPE_ALL },
  { label: "Propietario", value: OUTFLOW },
  { label: "Arrendatario", value: INCOME },
];

const filterMovements = (
  movements: IMovements[],
  movementType: string,
  shortCountry: string,
  concept: string
) =>
  movements
    .filter(
      (item) =>
        item.concept === CONCEPTS[concept] &&
        (movementType === TYPE_ALL ? true : item.type === movementType)
    )
    .map((item) => ({
      ...item,
      key: item.movement_id,
      country: shortCountry,
    }));

export const getAllMovements = (
  movements: IMovements[],
  movementType: string = TYPE_ALL,
  shortCountry: string
) => ({
  reservationMovements: {
    movements: filterMovements(movements, movementType, shortCountry, "reservation"),
    title: "Pago de Reservas",
  },
  contractMovements: {
    movements: filterMovements(movements, movementType, shortCountry, "contract"),
    title: "Pago de Cierres",
  },
  spotMovements: {
    movements: movements
      .filter(
        (item) =>
          item.concept === CONCEPTS["spot"] &&
          (movementType === TYPE_ALL ? true : movementType === "OUTFLOW")
      )
      .map((item) => ({
        ...item,
        key: item.movement_id,
        country: shortCountry,
      })),
    title: "Pagos Spot",
  },
  otherMovements: {
    movements: filterMovements(movements, movementType, shortCountry, "other"),
    title: "Otros",
  },
  leaseMovements: {
    movements: getLeaseMovements(movements, movementType, shortCountry),
    title: "Mensualidades",
  },
});

const getLeaseMovements = (
  movements: IMovements[],
  type: string = TYPE_ALL,
  shortCountry: string
) => {
  const data = movements
    .filter((item) => item.concept === "LEASE" || item.concept === "LEASE_ADVANCEMENT")
    .map((item) => ({
      ...item,
      key: item.movement_id,
      country: shortCountry,
    }));

  const arrayByGroupId = data.reduce((groups, item) => {
    const group = groups[item.group_id] || [];
    group.push(item);
    groups[item.group_id] = group;
    return groups;
  }, {});

  const parsedData = Object.entries(arrayByGroupId)
    .map(([key]) => {
      const currentObject: IMovements[] = arrayByGroupId[key];
      return {
        movements: currentObject,
        agreedDate: currentObject?.find((obj) => obj.type === "INCOME")?.agreed_date,
      };
    })
    .sort(compareMovements.compareMovements("agreedDate"))
    .map((mov) => mov.movements);

  const concatData = [].concat(...parsedData).reverse();
  if (type === "all") return concatData;
  const filterData = concatData.filter((movement) => movement.type === type);

  return filterData;
};

export const getIfChangeIsAllowed = (agreedDate: string, movementType: MovementType) => {
  if (movementType === "INCOME") return true;
  return isCurrentDateAllowed(agreedDate);
};
