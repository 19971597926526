import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  shadow?: boolean;
  expanded?: boolean;
}

const useDesignSystemTabsStyles = makeStyles<Theme, Props>((theme) => ({
  appBar: {
    boxShadow: ({ shadow }) => (shadow ? "auto" : "none"),
    marginBottom: theme.spacing(2),
  },
  barContainer: {
    margin: ({ expanded }) => (expanded ? theme.spacing(3) : "auto"),
    marginBottom: theme.spacing(3),
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 700,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
    borderRadius: 8,
  },
  tabRoot: {
    fontSize: ({ expanded }) =>
      expanded ? theme.typography.pxToRem(18) : theme.typography.pxToRem(16),
    textTransform: "unset",
    color: "black",
    minWidth: "auto",
    paddingInline: ({ expanded }) => (expanded ? 10 : 0),
    marginLeft: theme.spacing(3),
    paddingBottom: ({ expanded }) => (expanded ? theme.spacing(2.5) : 0),
    paddingTop: ({ expanded }) => (expanded ? theme.spacing(2.5) : 0),
    paddingLeft: ({ expanded }) => (expanded ? theme.spacing(2.5) : 0),
    paddingRight: ({ expanded }) => (expanded ? theme.spacing(2.5) : 0),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
}));

export default useDesignSystemTabsStyles;
