import { useState } from "react";

import { Box } from "@material-ui/core";

import { ReactComponent as Options } from "assets/images/options.svg";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DesignSystemTabs from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/DesignSystemTabs";
import { useTab } from "context/tabContext/tabContext";

import { useBreadcrumbs } from "../hooks";
import FilterModal from "./components/FilterModal";
import LandlordData from "./components/LandlordData";
import PropertiesTable from "./components/PropertiesTable";
import TicketsTable from "./components/TicketsTable";
import useFilters from "./hooks/useFilters";
import useLandlordSummary from "./hooks/useLandlordSummary";
import useLandlordStyles from "./LandlordStyles";
import tabOptions from "./utils/constants";

function Landlord() {
  const classes = useLandlordStyles();
  const {
    state: { currentTabValue },
  } = useTab();
  const [openModal, setOpenModal] = useState(false);
  const { filters, setFilters } = useFilters();
  const { isLoading, error, data } = useLandlordSummary();
  const { breadcrumbs } = useBreadcrumbs(data);

  const renderTable = () => {
    switch (currentTabValue) {
      case "kustomer":
        return <TicketsTable ticketsCount={data.tickets?.count} />;
      case "properties":
      default:
        return <PropertiesTable propertiesCount={data.propertiesCount} filters={filters} />;
    }
  };
  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.landlordHeader}>
        <Breadcrumbs steps={breadcrumbs} />
      </Box>
      <LandlordData isLoading={isLoading} error={error} data={data} />
      <Box className={classes.buttonsContainer}>
        <Box className={classes.tabContainer}>
          <DesignSystemTabs options={tabOptions} defaultValue="properties" />
        </Box>
        {currentTabValue === "properties" && (
          <DesignSystemButton
            label="Filtrar"
            size="small"
            variant="secondary"
            onClick={() => setOpenModal(true)}
            buttonClassName={classes.filter}
            Icon={<Options className={classes.filterIcon} />}
          />
        )}
      </Box>
      {renderTable()}
      <FilterModal
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
}

export default Landlord;
