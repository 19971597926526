import { AxiosResponse } from "axios";

import { QuotationModel } from "models/Subscription";

import { createHttp } from "./helper";

export default {
  quotation(id: number, token: string): Promise<AxiosResponse<QuotationModel>> {
    return createHttp(token).get(`/v2/quotations/${id}/show_full_info/`);
  },
};
