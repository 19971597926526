import { useFormContext } from "react-hook-form";

import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";

import { IInsuranceCompany } from "services/insuranceService/types";
import { IContractInit } from "../../utils/types";

interface Props {
  insuranceCompanies: IInsuranceCompany[];
}

const InitContractForm = ({ insuranceCompanies }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IContractInit>();

  return (
    <>
      <ControlledKeyboardDatePicker
        id="selectedDate"
        label="Fecha inicio de contrato propuesto"
        defaultValue={new Date()}
        dataPickerProps={{
          format: "dd/MM/yyyy",
          minDate: new Date(),
        }}
      />
      <FieldInput
        label="¿Cuántos adultos vivirán en la propiedad?"
        id="adultHabitants"
        type="number"
        step={1}
        errorMessage={errors.adultHabitants?.message}
        placeholder="2"
        form="novalidateform"
        {...register("adultHabitants")}
      />
      <FieldInput
        label="¿Cuántos niños vivirán en la propiedad?"
        id="childHabitants"
        type="number"
        step={1}
        errorMessage={errors.childHabitants?.message}
        placeholder="2"
        form="novalidateform"
        {...register("childHabitants")}
      />
      <FieldSelect
        label="¿Tiene mascotas?"
        id="tenantHasPets"
        errorMessage={errors.tenantHasPets?.message}
        placeholder="Seleccionar"
        defaultValue=""
        options={[
          { value: "yes", label: "Si" },
          { value: "no", label: "No" },
        ]}
        form="novalidateform"
        {...register("tenantHasPets")}
      />
      <FieldSelect
        label="Compañía aseguradora"
        id="insuranceCompany"
        errorMessage={errors.insuranceCompany?.message}
        placeholder="Seleccionar"
        defaultValue={insuranceCompanies.length === 1 ? insuranceCompanies[0].id : ""}
        options={insuranceCompanies.map((company) => ({
          value: company.id,
          label: company.name,
        }))}
        form="novalidateform"
        disabled={insuranceCompanies.length === 1}
        {...register("insuranceCompany")}
      />
    </>
  );
};

export default InitContractForm;
