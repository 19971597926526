import { useState } from "react";

import Swal from "sweetalert2";

import { Typography, InputLabel, CircularProgress } from "@material-ui/core";

import { RemoteSuggester } from "Components/Admin/UIComponents";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { useIntegration } from "context/integrationContext/integrationContext";
import {
  setPlanEditionFiltersAction,
  refetchPlanEditionData,
  setPlanEditionStates,
  resetPlanEditionFilters,
  updateManyQuotas,
  getTotalQuota,
} from "context/integrationContext/integrationActions";
import integrationService from "services/integrationService";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import {
  publicationTypeResponseToSelectorParser,
  standardParser,
} from "Components/Admin/administrator/integrations/utils/parsers";
import { businessTypesOptions } from "Components/Admin/administrator/integrations/utils/commonFilterOptions";
import { useGetToken } from "hooks";
import { OptionProps } from "Components/Admin/UIComponents/RemoteSuggester/types";

import TotalQuotaInfo from "./TotalQuotaInfo";
import useStyles from "./RequiredFiltersToolbarStyles";

function RequiredFiltersToolbar() {
  const [displayTotalQuota, setDisplayTotalQuota] = useState(false);

  const { state, dispatch } = useIntegration();
  const classes = useStyles();
  const { getToken } = useGetToken();
  const { dispatch: tableDispatch } = useTable();

  const { portal, portalPlan, businessType } = state.planEditionFilters;
  const { isEditing, isUpdating, quotasOnEdition, quotasWithError } = state.planEditionStates;
  const canSearchMacrozones = !!portal && !!portalPlan && !!businessType;

  const handleSearchMacrozones = () => {
    setDisplayTotalQuota(true);
    dispatch(
      setPlanEditionStates({
        isActiveSearch: true,
        isEditing: false,
        quotasOnEdition: [],
      })
    );
    dispatch(refetchPlanEditionData());
  };

  const handleResetFilters = () => {
    setDisplayTotalQuota(false);
    dispatch(
      setPlanEditionStates({
        isActiveSearch: false,
        isEditing: false,
        quotasOnEdition: [],
      })
    );
    dispatch(resetPlanEditionFilters());
    tableDispatch(setCurrentPage(1));
    tableDispatch(setPageSize(10));
    dispatch(refetchPlanEditionData());
  };

  const handleEditPlan = () => {
    dispatch(setPlanEditionStates({ isEditing: true }));
  };

  const handleSavePlan = () => {
    if (quotasOnEdition.length === 0) {
      Swal.fire({
        type: "warning",
        title: "No se han detectado cambios",
        text: "No se han detectado cambios para actualizar",
      });
      return null;
    }
    if (quotasWithError.length > 0) {
      Swal.fire({
        type: "error",
        title: "Campos con errores",
        text: "Por favor, corrija los campos con errores",
      });
      return null;
    }
    getToken().then((authToken) => {
      updateManyQuotas(quotasOnEdition, dispatch, authToken);
    });
  };

  const handleCancelEdit = () => {
    dispatch(
      setPlanEditionStates({
        isEditing: false,
        quotasOnEdition: [],
        quotasWithError: [],
      })
    );
  };

  const handlePortalPlanChange = (value: OptionProps) => {
    setPlanEditionFiltersAction({ portalPlan: value }, dispatch);
    getToken().then((authToken) => {
      getTotalQuota(
        {
          portal: portal.id as string,
          publication_type: value.id as string,
          business_type: businessType,
          country: state.country,
        },
        dispatch,
        authToken
      );
    });
  };

  const buttonText = canSearchMacrozones ? "Aplicar filtro" : "Selecciona filtros...";

  const editButtonText = isEditing ? "Guardar cupos" : "Editar cupos";

  const editButtonAction = isEditing ? handleSavePlan : handleEditPlan;

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>Selecciona para poder editar</Typography>
        {displayTotalQuota && <TotalQuotaInfo />}
      </div>
      <div className={classes.root}>
        <div className={classes.filtersContainer}>
          <div className={classes.selectorContainer}>
            <InputLabel className={classes.selectorLabel} id="business-type-selector-label">
              Línea de negocio
            </InputLabel>
            <SelectionOptions
              id="businessType"
              simple
              compressed={false}
              value={state.planEditionFilters.businessType}
              onChange={(businessType) => setPlanEditionFiltersAction({ businessType }, dispatch)}
              options={businessTypesOptions}
              classes={{
                root: classes.selectorRoot,
              }}
              disabled={state.planEditionStates.isActiveSearch}
            />
          </div>
          <RemoteSuggester
            label="Portal"
            value={state.planEditionFilters.portal}
            onChange={(portal) => setPlanEditionFiltersAction({ portal }, dispatch)}
            indexService={integrationService.indexPortalNamesRequest}
            filters={{ country: state.country }}
            parser={standardParser}
            textFieldProps={{
              placeholder: "Selecciona un portal",
            }}
            classes={{ label: classes.suggesterLabel }}
            disabled={state.planEditionStates.isActiveSearch}
          />
          <RemoteSuggester
            label="Destaque"
            value={state.planEditionFilters.portalPlan}
            onChange={(portalPlan) => handlePortalPlanChange(portalPlan)}
            indexService={integrationService.indexPublicationTypesNamesRequest}
            filters={{
              country: state.country,
              portal: portal?.id as string,
              business: businessType,
            }}
            parser={publicationTypeResponseToSelectorParser}
            textFieldProps={{
              placeholder: "Selecciona un destaque",
            }}
            classes={{ label: classes.suggesterLabel }}
            disabled={!portal}
          />
        </div>
        <div className={classes.buttonContainer}>
          {state.planEditionStates.isActiveSearch && !state.planEdition.loading ? (
            <CustomButton
              active
              kind="black"
              className={classes.searchButton}
              onClick={handleResetFilters}>
              Borrar filtro
            </CustomButton>
          ) : (
            <CustomButton
              active={canSearchMacrozones}
              kind="black"
              className={classes.searchButton}
              disabled={!canSearchMacrozones}
              onClick={handleSearchMacrozones}>
              {buttonText}
            </CustomButton>
          )}
        </div>
      </div>
      <div className={classes.editionButtonContainer}>
        <CustomButton
          active
          kind="main"
          className={classes.searchButton}
          onClick={editButtonAction}>
          {isUpdating && <CircularProgress size={20} className={classes.buttonSpinner} />}
          {editButtonText}
        </CustomButton>
        {isEditing && (
          <CustomButton
            active
            kind="secondary"
            className={classes.searchButton}
            onClick={handleCancelEdit}>
            Cancelar edición
          </CustomButton>
        )}
      </div>
    </>
  );
}

export default RequiredFiltersToolbar;
