import currency from "@houm-com/formats/currency";
import { Currency, Locale } from "models/Countries";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import useDeletePenalty from "./hooks/useDeletePenalty";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  movementId: string;
  country: Locale;
  penalty: number;
  movementCurrency: Currency;
}

function DeletePenaltyFormModal({
  open,
  onHandleClose,
  movementId,
  country,
  penalty,
  movementCurrency,
}: Props) {
  const { mutate, isLoading } = useDeletePenalty(onHandleClose);

  const deletePenalty = () => {
    if (isLoading) return;
    mutate({
      movementId,
      country,
    });
  };

  return (
    <ConfirmDialog
      title={`¿Seguro que quieres quitar la multa de ${
        movementCurrency ? currency(penalty, movementCurrency) : ""
      }?`}
      isOpen={open}
      onClose={onHandleClose}
      onSubmit={deletePenalty}
      submitButtonProps={{
        isLoading,
      }}
      submitButtonLabel="Sí, quitar"
    />
  );
}

export default DeletePenaltyFormModal;
