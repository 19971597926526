import { makeStyles, Theme } from "@material-ui/core";

const useUserStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
  documentContainer: {
    display: "flex",
    alignItems: "center",
  },
  chip: {
    backgroundColor: "white",
    border: "1px solid #AE0400",
    color: "#AE0400",
    height: "20px",
  },
}));

export default useUserStyles;
