import {
  State,
  Action,
  SET_IDS,
  SET_AVAILABLE_STEPS,
  SET_STEP,
  NEXT_STEP,
  PREV_STEP,
  SET_LOADING,
  SET_PROPERTY_DATA,
  SET_LANDLORD_DATA,
  SET_THIRD_PARTY_DATA,
  SET_PUBLIC_SERVICES,
  SET_ADMIN_DATA,
  SET_FILES,
  SET_READY,
  SET_ALREADY_SUBMITTED,
  SET_DOC_TYPES,
  SET_IS_ADMIN,
  SET_SUBMITTING,
  SET_NAV_TEXT,
  SET_STEP_MODE,
  SET_FILE_LINKS,
} from "./closureFormTypes";
import { BooleanString as BS } from "./constants";

export const initialState: State = {
  loading: true,
  availableSteps: [
    "propertyData",
    "landlordData",
    "thirdPartyData",
    "publicServices",
    "adminData",
    "fileLinks",
  ],
  step: "propertyData",
  readyToSubmit: "",
  alreadySubmitted: false,
  submitting: false,
  navText: "Continuar",
  stepMode: true,
  isAdmin: false,
  documentTypes: [],
  ids: {
    propertyUid: "",
    propertyId: 0,
    propertyDetailsId: 0,
    userId: 0,
  },
  propertyData: {
    address: "",
    addressNumber: "",
    outerNumber: "",
    innerNumber: "",
    neighborhood: { value: 0, label: "" },
    commune: "",
    region: "",
    country: "",
    postalCode: "",
    rentValue: "",
    availableDate: "",
    isFlat: BS.NO,
    isHorizontal: BS.YES,
    departmentNumber: "",
    isFurnished: "non",
    parkingNumbers: "",
    isUrban: BS.NO,
    inCondo: BS.NO,
    publicDeed: BS.NO,
    publicDeedNumber: "",
    publicDeedDate: "",
    notary: "",
    notaryNumber: "",
    predialNumber: "",
    propertyRegistrationNumber: "",
    hasCommonExpenses: BS.NO,
    commonExpenses: "",
    mortgage: "",
    domainRestriction: "",
    paymentCondition: "",
    chargeCondition: "",
    policy: "",
    warranty: "",
    warehouseNumber: "",
    maxPeopleQuantity: BS.NO,
  },
  landlordData: {
    name: "",
    userType: "natural",
    document: "",
    documentType: BS.NO,
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    country: "",
    civilStatus: "unmarried",
    maritalRegime: "notApply",
    isIvaResponsible: "",
    specialCondition: "",
    isNew: BS.NO,
    tenantBrokerageCommission: 1,
    landlordBrokerageCommission: 1,
    rfc: "",
    occupation: "",
  },
  thirdPartyData: {
    name: "",
    document: "",
    email: "",
    phone: "",
    address: "",
    country: "Colombia",
    documentType: BS.NO,
  },
  publicServices: {
    waterService: 0,
    waterClient: "",
    energyService: 0,
    energyClient: "",
    gasService: 0,
    gasClient: "",
    cleaningClient: "",
    availableServices: null,
    includedServices: "",
    additionalServices: "",
  },
  adminData: {
    email: "",
    phoneNumber: "",
    hasHoumCleaning: BS.YES,
    cleaningAmount: BS.NO,
  },
  files: {
    ccFile: null,
    predialFile: null,
    energyReceipt: null,
    gasReceipt: null,
    waterReceipt: null,
    cleaningReceipt: null,
    administrationReceipt: null,
    traditionLibertyFile: null,
    ineFile: null,
    csfFile: null,
    publicDeed: null,
    predialVoucher: null,
  },
  fileLinks: {
    ccFile: "",
    predialFile: "",
    energyReceipt: "",
    gasReceipt: "",
    waterReceipt: "",
    cleaningReceipt: "",
    administrationReceipt: "",
    ineFile: "",
    csfFile: "",
    traditionLibertyFile: "",
    publicDeed: "",
    predialVoucher: "",
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_IDS:
      return { ...state, ids: action.ids };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_AVAILABLE_STEPS:
      return { ...state, availableSteps: action.steps };
    case SET_STEP:
      return { ...state, step: action.step };
    case NEXT_STEP:
      return {
        ...state,
        step: state.availableSteps[state.availableSteps.indexOf(state.step) + 1],
      };
    case PREV_STEP:
      return {
        ...state,
        step: state.availableSteps[state.availableSteps.indexOf(state.step) - 1],
      };
    case SET_PROPERTY_DATA:
      return {
        ...state,
        propertyData: { ...state.propertyData, ...action.propertyData },
      };
    case SET_LANDLORD_DATA:
      return {
        ...state,
        landlordData: { ...state.landlordData, ...action.landlordData },
      };
    case SET_THIRD_PARTY_DATA:
      return { ...state, thirdPartyData: action.thirdPartyData };
    case SET_PUBLIC_SERVICES:
      return { ...state, publicServices: action.publicServices };
    case SET_ADMIN_DATA:
      return { ...state, adminData: action.adminData };
    case SET_FILES:
      return { ...state, files: { ...action.files } };
    case SET_FILE_LINKS:
      return { ...state, fileLinks: action.fileLinks };
    case SET_READY:
      return { ...state, readyToSubmit: action.ready };
    case SET_ALREADY_SUBMITTED:
      return { ...state, alreadySubmitted: action.alreadySubmitted };
    case SET_SUBMITTING:
      return { ...state, submitting: action.submitting };
    case SET_NAV_TEXT:
      return { ...state, navText: action.navText };
    case SET_STEP_MODE:
      return { ...state, stepMode: action.stepMode };
    case SET_IS_ADMIN:
      return { ...state, isAdmin: action.isAdmin };
    case SET_DOC_TYPES:
      return { ...state, documentTypes: action.documentTypes };
    default:
      return state;
  }
};

export default apiReducer;
