import { Map } from "models/Mapbox";
import { Action, ActionType } from "./mapTypes";

export const setMap = (map: Map): Action => ({
  map,
  type: ActionType.SET_MAP,
});

export const setLoaded = (loaded: boolean): Action => ({
  loaded,
  type: ActionType.SET_LOADED,
});
