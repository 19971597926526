import { ReactNode } from "react";
import useStyles from "./CellStyles";

interface Props {
  direction: "normal" | "right" | "left";
  highlight?: boolean;
  children: ReactNode;
}
function CustomCell({ direction = "normal", highlight, children }: Props) {
  const classes = useStyles({ direction, highlight });
  return <div className={classes.customCell}>{children}</div>;
}
export default CustomCell;
