import { alert } from "@houm-com/ui/Alert";
import { useMutation } from "react-query";

import newEvaluationService from "services/newEvaluationService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface FormData {
  evaluationId: number;
  documentId: number;
  country: Locale;
}

const useDeleteFile = () => {
  const { getToken } = useGetToken();
  const queryMutate = useMutation(
    async (formData: FormData) => {
      const authToken = await getToken();
      const response = await newEvaluationService.deleteEvaluationFile(
        formData.country,
        formData.evaluationId,
        formData.documentId,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Archivo eliminado correctamente",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      onError: (e) => {
        alert.error({
          message: "Error no se pudo eliminar el archivo",
        });
      },
    }
  );

  return {
    ...queryMutate,
  };
};

export default useDeleteFile;
