import { Grid } from "@material-ui/core";

import useStyles from "./monthlyTableStyles";
import MonthTextfield from "./monthTextfield";

interface Props {
  id: string;
  monthsNumber: number;
  defaultValue?: string;
}

function MonthlyTable({ id, monthsNumber, defaultValue }: Props) {
  const classes = useStyles();
  const months = !Number.isNaN(monthsNumber) && monthsNumber > 0 ? monthsNumber : 0;
  const hasTwoColumns = months > 6;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        alignItems="flex-start"
        justifyContent="center"
        className={classes.grid}>
        <Grid container item xs={6} justifyContent="center" alignItems="flex-start">
          {[...Array(Math.min(months, 6)).keys()].map((n) => (
            <MonthTextfield
              key={`${id}-month-${n + 1}`}
              id={`${id}-month-${n + 1}`}
              number={n + 1}
              defaultValue={defaultValue}
            />
          ))}
        </Grid>
        {hasTwoColumns && (
          <Grid container item xs={6} justifyContent="center" alignItems="flex-start">
            {[...Array(months - 6).keys()].map((n) => (
              <MonthTextfield
                key={`${id}-month-${n + 7}`}
                id={`${id}-month-${n + 7}`}
                number={n + 7}
                defaultValue={defaultValue}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default MonthlyTable;
