import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useProperty } from "context/propertyContext/propertyContext";
import { setModalMessage } from "context/propertyContext/propertyActions";

function ModalMessage() {
  const {
    state: { modalMessage },
    dispatch,
  } = useProperty();

  const handleClose = () => dispatch(setModalMessage({ isOpen: false, title: "" }));

  return (
    <CustomCenteredModal
      withLogo
      showCloseButton
      logoPosition="top"
      open={!!modalMessage?.isOpen}
      title={modalMessage?.title}
      subtitle={modalMessage?.subtitle}
      onClose={handleClose}
    />
  );
}

export default ModalMessage;
