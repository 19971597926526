import { ReactNode, useRef, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

import mapboxgl from "mapbox-gl";

interface Props {
  mapRef: any;
  lat: number;
  lng: number;
  popUpClassName?: string;
  onOpen?: (e) => void;
  onClose?: (e) => void;
  children?: ReactNode;
}

function getClassList(className: string) {
  return new Set(className ? className.trim().split(/\s+/) : []);
}

/**
 * Allow jsx syntax in our popups
 *
 * @see https://github.com/visgl/react-map-gl/blob/master/src/components/popup.ts
 */

const Popup = ({ mapRef, lat, lng, popUpClassName, ...props }: Props) => {
  const container = useMemo(() => document.createElement("div"), []);

  const thisRef = useRef({ props });
  thisRef.current.props = props;

  const popup = useMemo(() => {
    const pp = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: true,
    }).setLngLat([lng, lat]);

    pp.on("open", (e) => {
      if (thisRef.current.props?.onOpen) {
        thisRef.current.props?.onOpen(e);
      }
    });

    pp.on("close", (e) => {
      if (thisRef.current.props?.onClose) {
        thisRef.current.props?.onClose(e);
      }
    });
    return pp;
  }, []);

  useEffect(() => {
    if (popup.isOpen()) {
      if (popup.getLngLat().lng !== lng || popup.getLngLat().lat !== lat) {
        popup.setLngLat([lng, lat]);
      }
    }
  }, [lat, lng, popUpClassName]);

  useEffect(() => {
    popup.setDOMContent(container).addTo(mapRef.current);
    return () => {
      if (popup.isOpen()) {
        popup.remove();
      }
    };
  }, []);

  if (popup.isOpen()) {
    const prevClassList = getClassList(popup.getElement().className);
    const nextClassList = getClassList(popUpClassName);
    for (const c of prevClassList) {
      if (!nextClassList.has(c)) {
        popup.removeClassName(c);
      }
    }
    for (const c of nextClassList) {
      if (!prevClassList.has(c)) {
        popup.addClassName(c);
      }
    }
  }

  return createPortal(props.children, container);
};

export default Popup;
