import { makeStyles } from "@material-ui/core";

const useScheduleAdvisoryStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 4),
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(1, 1, 1, 1),
    margin: theme.spacing(0, 0, 4, 0),
    maxWidth: "60%",
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
  subsectionHeader: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    padding: 0,
    zIndex: 1,
    color: "black",
  },
  saveButton: {
    textTransform: "none",
    borderRadius: "20px",
  },
  scheduleButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scheduleButton: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0.5, 3),
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 100,
  },
  paper: {
    borderRadius: 20,
    padding: theme.spacing(3, 0),
    display: "flex",
    minWidth: 500,
  },
  fisrtColumn: {
    flexBasis: "20%",
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    flexDirection: "column",
  },
  hourColumn: {
    flexBasis: "25%",
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    flexDirection: "column",
  },
  calendarColumn: {
    flexBasis: "40%",
    flexGrow: 0,
    flexShrink: 0,
  },
  hours: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(3),
    width: "100%",
  },
  timeZoneContainer: {
    maxWidth: 300,
  },
  timeZoneTitle: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(20),
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
  timeZone: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(0),
  },
}));

export default useScheduleAdvisoryStyles;
