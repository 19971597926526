import { compareDesc } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";

import promotionService from "domains/promotion/service";
import { LocaleCountries } from "models/Countries";
import { IPromotion } from "models/Promotions";
import { useGetToken } from "hooks";

import { parserPromotions } from "./utils/parsers";
import { LeaseType, PromotionStatus } from "./utils/types";

const useGetPromotions = (
  status: PromotionStatus,
  leaseType: LeaseType,
  country: LocaleCountries
) => {
  const statusFlag = status === "actives";
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IPromotion[], Error> = useQuery<IPromotion[], Error>(
    ["promotions_list", status, country, leaseType],
    async () => {
      const authToken = await getToken();
      const response = await promotionService.getPromotions(
        {
          country,
          page_size: 100,
          active: statusFlag,
          lease_type: leaseType,
        },
        authToken
      );
      const promotions = response.data.results;
      const parsedPromotions = promotions.map((promotion) => parserPromotions(promotion));
      const orderedPromotions = parsedPromotions.sort((a, b) =>
        compareDesc(a.createdAt, b.createdAt)
      );
      return orderedPromotions;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useGetPromotions;
