import Spinner from "@houm-com/ui/Spinner";

export default function LoadingScreen() {
  return (
    <div className="relative">
      <div className="absolute inset-0 h-screen flex justify-center items-center">
        <Spinner variant="primary" size="xl" />
      </div>
    </div>
  );
}
