/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import CustomLink from "Components/Admin/UIComponents/CustomLink";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useProperties } from "../context/propertiesContext";

import useStyles from "./TableStyles";
import Toolbar from "./Toolbar";
import useGuard from "domains/auth/hooks/useGuard";
import { useMemo } from "react";

function Table() {
  const classes = useStyles();
  const { state } = useProperties();
  const { loading, properties, numberOfProperties: resultsCount } = state;

  const { guard } = useGuard();
  const showOwnerEmail =
    guard.roles.any([
      "control_tower_agent",
      "sales",
      "control_tower_admin",
      "admin",
      "management",
    ]) || guard.permissions.any(["read:property_owner:all"]);

  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;
  const collumsToRender = useMemo(() => columns(showOwnerEmail), [showOwnerEmail]);
  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={resultsCount}
        columns={collumsToRender}
        rows={properties}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
};

const columns = (showOwnerEmail): GridColumns => [
  {
    ...columnsConfig,
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    ...columnsConfig,
    field: "published",
    headerName: "Publicación",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "lastUpdate",
    headerName: "Último cambio de estado",
    type: "date",
    width: 100,
    // TODO: Set up sorting when api allows it
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "translatedStatus",
    headerName: "Estado",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "houmOwner",
    headerName: "Ejecutivo",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "homeChecker",
    headerName: "Home Checker",
    width: 100,
    sortable: false,
  },
  ...(showOwnerEmail
    ? [
        {
          ...columnsConfig,
          field: "landlordEmail",
          headerName: "Correo propietario",
          width: 100,
          sortable: false,
        },
      ]
    : []),
  {
    ...columnsConfig,
    field: "link",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => <CustomLink to={value as string} />,
  },
];
