import { EvaluationStatus, EvaluationStatusListEnum } from "domains/evaluations/models/Evaluations";

export const EvaluationStatusDictionary: {
  [locale in EvaluationStatus]: string;
} = {
  [EvaluationStatusListEnum.PENDING]: "Pendiente",
  [EvaluationStatusListEnum.APPROVED]: "Aprobada",
  [EvaluationStatusListEnum.REJECTED]: "Rechazada",
  [EvaluationStatusListEnum.CANCELLED]: "Cancelada",
  [EvaluationStatusListEnum.INCOMPLETE]: "Incompleta",
  [EvaluationStatusListEnum.PARTIALLY_APPROVED]: "Pre-aprobada",
};
