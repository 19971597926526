import { Typography } from "@material-ui/core";

interface Props {
  serviceType: "gastos comunes" | "servicios básicos";
}

const NoServices = ({ serviceType }: Props) => (
  <Typography>
    Esta propiedad no tiene {serviceType} registrados, agrégalos y empieza a darles seguimiento.
  </Typography>
);

export default NoServices;
