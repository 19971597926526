import { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { GridToolbarContainer } from "@material-ui/x-grid";
import { SearchOutlined } from "@material-ui/icons";

import BasicTextSearchBar from "Components/Admin/UIComponents/molecules/BasicTextSearchBar";
import CustomButtonGray from "Components/Admin/administrator/demand/components/CustomButtonGray";
import { useTable } from "context/tableContext/tableContext";
import { setShowModal, setSearchQuery } from "context/tableContext/tableActions";
import useStyles from "./ToolbarStyles";
import { ReactComponent as Options } from "assets/images/options.svg";
import useDebouncedSearchQuery from "hooks/useDebouncedSearchQuery";

function Toolbar() {
  const classes = useStyles();
  const { dispatch } = useTable();

  const { query, handleOnDebouncedSearchChange, debouncedQuery } = useDebouncedSearchQuery();

  const handleFilterClick = () => dispatch(setShowModal(true));

  useEffect(() => {
    dispatch(setSearchQuery(debouncedQuery));
  }, [debouncedQuery]);

  return (
    <GridToolbarContainer>
      <Grid container alignItems="center" justifyContent="space-between" className="mb-3">
        <Grid item xs={6}>
          <BasicTextSearchBar
            value={query}
            InputProps={{
              startAdornment: <SearchOutlined color="action" />,
              placeholder: "Búsqueda por ID",
            }}
            onChange={(e) => handleOnDebouncedSearchChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
          <CustomButtonGray
            className={classes.filterButtonRoot}
            startIcon={<Options className={classes.filteButtonIcon} />}
            onClick={handleFilterClick}>
            Filtrar
          </CustomButtonGray>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default Toolbar;
