import { makeStyles, Theme } from "@material-ui/core";

const useHoursIntervalsStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "grid",
    gridTemplateRows: "1fr 1fr 1fr",
    gridRowGap: theme.spacing(3),
    gridTemplateColumns: "1fr 1fr 1fr",
  },
}));

export default useHoursIntervalsStyles;
