import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";

import useStyles from "./ControlledDropAreaStyles";
import classNames from "classnames";
import DropArea from "./DropArea";

interface Props {
  allowedMimeTypes?: string;
  helperText?: string;
  filesLimit?: number;
  id: string;
  subtitle?: string;
  label?: string;
  Icon?: any;
  files?: number;
  controllerProps?: React.ComponentProps<typeof Controller>;
  defaultValue?: (File | string)[] | null;
  labelProps?: string;
}

function ControlledDropArea({
  allowedMimeTypes = "image/*",
  id,
  label = "",
  subtitle,
  controllerProps,
  filesLimit = 1,
  defaultValue = null,
  labelProps = "",
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
        <FormControl className={classes.wrapper} error={invalid} fullWidth>
          <span id={id} className={classNames(classes.label, labelProps)}>
            {label}
          </span>
          {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
          <DropArea
            id={id}
            onChange={onChange}
            initialFiles={defaultValue}
            accepted={allowedMimeTypes}
            maxFiles={filesLimit}
          />
          <FormHelperText>{error?.message}</FormHelperText>
          <span>{error?.message}</span>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}

export default ControlledDropArea;
