import Divider from "@material-ui/core/Divider";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useClosureForm } from "context/closureFormContext/closureFormContext";

import { Props, filter } from "./utils";
import useStyles from "../ClosureFormStyles";

interface TextFieldProps extends Props {
  defaultValue: string | number;
  hint?: string;
  textFieldProps?: Partial<React.ComponentProps<typeof ControlledTextField>>;
}

const FilteredTextField: React.FC<TextFieldProps> = ({
  id,
  fieldInfo,
  defaultValue,
  textFieldProps,
  hint = "",
}) => {
  const { state } = useClosureForm();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  const classes = useStyles();
  return (
    <>
      <ControlledTextField
        label={fieldInfo.label(state.propertyData.country)}
        id={id}
        textFieldProps={{ placeholder: "Ingresar", helperText: fieldInfo.helperText }}
        defaultValue={defaultValue}
        {...textFieldProps}
      />
      <Divider className={classes.divider} />
      {hint && <p className={classes.hint}>{hint}</p>}
    </>
  );
};

export default FilteredTextField;
