import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import rentApplicationService from "services/rentApplicationService";
import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import { useGetToken } from "hooks";
import { ITenant } from "../utils/types";

interface IFormData {
  main_aval?: number;
  applicant_id: number;
  init_date_proposal: string;
  signature_date_proposal: string;
  tenants: ITenant[];
  main_tenant: number;
  habitants_description: string;
  appraiser_id: string;
}

const useInitContract = () => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      await rentApplicationService.initContractWithoutEvaluation(formData, authToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractCreatedFlag");
        dispatch(
          setAlertInfo({
            open: true,
            head: "La observación se creó exitosamente",
            type: "success",
          })
        );
      },
      onError: (e: AxiosError<{ detail: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: `No se pudo crear la observación`,
            type: "error",
            body: `Error: ${reason ? reason?.data?.detail : e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useInitContract;
