import { UseQueryResult, useQuery } from "react-query";

import { OptionTypes } from "Components/Admin/UIComponents/ControlledForm/ControlledList";
import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parseTenantPlansResponse } from "../utils/utils";

const useGetContractTenantPlans = (country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data }: UseQueryResult<OptionTypes[], Error> = useQuery(
    ["tenantPlans"],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getContractTenantPlans(authToken);
      return parseTenantPlansResponse(response?.data?.data);
    },
    {
      enabled: country === "cl",
    }
  );

  return { isLoading, error, data };
};

export default useGetContractTenantPlans;
