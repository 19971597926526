import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Swal from "sweetalert2";

import { useGetToken } from "hooks";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { Multifamilies } from "context/multifamilyContext/multifamily/multifamilyTypes";
import {
  publishMultifamily,
  unpublishMultifamily,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { setUnitsUploadModal } from "context/multifamilyContext/unit/unitActions";
import UnitsUploadModal from "Components/Admin/administrator/multifamily/Components/unitsUpload/UnitsUploadModal";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import BackButton from "Components/Admin/administrator/multifamily/Components/BackButton";
import SimulateTextfield from "../../Components/SimulateTextfield/SimulateTextfield";
import Images from "../../Components/Images/Images";
import Checkboxs from "Components/Admin/administrator/multifamily/CreateMF/Components/Checkboxs/Checkboxs";

import useStyles from "./HeaderStyles";

function Header() {
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const classes = useStyles();
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);

  const {
    state: { multifamily, MFImages, currentId },
  } = useMultifamily();

  const { dispatch: unitDispatch } = useMultifamilyUnit();

  const showAddress = (multifamily: Multifamilies) =>
    `${multifamily?.address}
     ${multifamily?.street_number}
     ${multifamily?.commune}
     ${multifamily?.region}`;

  const handleBack = () => history.push("/admin/multifamily");

  const handlePublish = () => {
    Swal.fire({
      title: "¿Quieres publicar el multifamily?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      text: "Asegurate de que todo este listo para publicar!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, publicar!",
    }).then((result) => {
      if (result.value) {
        getToken().then((authToken) => {
          publishMultifamily(parseInt(id), authToken);
        });
      }
    });
  };

  const handleUnpublish = () => {
    Swal.fire({
      title: "¿Quieres despublicar el multifamily?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      text: "Despublicarás todas las unidades del Multifamily",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, depublicar!",
    }).then((result) => {
      if (result.value) {
        getToken().then((authToken) => {
          unpublishMultifamily(parseInt(id), authToken);
        });
      }
    });
  };

  return (
    <div className={classes.container}>
      <div>
        <BackButton backButtonProps={{ label: "Atras", onClick: handleBack }} />
      </div>
      <div className={classes.rows}>
        <div>
          <Typography className={classes.title}>{multifamily?.name}</Typography>
          {!multifamily.published
            ? multifamily?.name && (
                <span className={classes.publish} onClick={handlePublish}>
                  Publicar
                </span>
              )
            : multifamily?.name && (
                <span className={classes.publish} onClick={handleUnpublish}>
                  Despublicar
                </span>
              )}
        </div>
        <UnitsUploadModal id={currentId} />
        <div className={classes.buttons}>
          <DesignSystemButton
            label="Editar Multifamily"
            size="medium"
            variant="secondary"
            href={`/admin/multifamily/create/${multifamily?.id}`}
            sameTab
          />
          <DesignSystemButton
            label="Crear tipologías"
            size="medium"
            variant="primary"
            href={`/admin/multifamily/${currentId}/typology/create/`}
            sameTab
          />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.columns}>
          <SimulateTextfield title="Nombre" value={multifamily?.name} />
          <SimulateTextfield title="Dirección" value={showAddress(multifamily)} />
          <SimulateTextfield title="Año de construcción" value={multifamily?.year_built} />
        </div>
        <div className={classes.columns}>
          <SimulateTextfield title="País" value={multifamily?.country} />
          <SimulateTextfield
            title="Ejecutivo del edificio"
            value={`${multifamily?.houm_executive?.name} ${multifamily?.houm_executive?.last_name}`}
          />
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {showInfo && (
          <div className={classes.extraContainer}>
            <div className={classes.infoContainer}>
              <div className={classes.columns}>
                <Typography className={classes.subtitle}>Conserjería</Typography>
                <SimulateTextfield title="Nombre" value={multifamily?.concierge_name} />
                <SimulateTextfield title="Email" value={multifamily?.concierge_email} />
                <SimulateTextfield title="Teléfono" value={multifamily?.concierge_phone} />
              </div>
              <div className={classes.columns}>
                <Typography className={classes.subtitle}>Administración</Typography>
                <SimulateTextfield title="Email" value={multifamily?.administration_email} />
                <SimulateTextfield title="Teléfono" value={multifamily?.administration_phone} />
              </div>
            </div>
            <div>
              <Images title="Imágenes multifamily" images={MFImages || []} />
              <Checkboxs controlled={false} amenities={multifamily?.association_amenities} />
            </div>
          </div>
        )}
        <div className={classes.keypadButtons}>
          <BackButton
            backButtonProps={{
              label: showInfo ? "Ocultar características" : "Ver características",
              onClick: () => setShowInfo(!showInfo),
              icon: false,
            }}
          />
          <DesignSystemButton
            label="Creación masiva de unidades"
            size="medium"
            variant="secondary"
            onClick={() => unitDispatch(setUnitsUploadModal(true))}
          />
        </div>
      </div>
    </div>
  );
}
export default Header;
