import Swal from "sweetalert2";

import Button from "@houm-com/ui/Button";

import useSignDocsStyles from "./SignDocsStyles";
import useSignFiles from "../../hooks/useSignFiles";

interface Props {
  contractId: number;
}

function SignDocs({ contractId }: Props) {
  const classes = useSignDocsStyles();
  const { mutate: mutateSignFiles, isLoading: isLoadingSignFiles } = useSignFiles(contractId);

  const handleClick = () => {
    Swal.fire({
      type: "warning",
      title: "¿Firmar documentos?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then((res) => {
      if (res.value) {
        mutateSignFiles();
      }
    });
  };

  return (
    <Button
      variant="primary"
      size="lg"
      className={classes.buttonOption}
      onClick={handleClick}
      isLoading={isLoadingSignFiles}>
      Firmar documentos
    </Button>
  );
}

export default SignDocs;
