import { makeStyles, Theme } from "@material-ui/core";

const usePersonStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontHeight: 1.6,
    marginBottom: theme.spacing(1.5),
  },
}));

export default usePersonStyles;
