import Button from "@houm-com/ui/Button";
import Spinner from "@houm-com/ui/Spinner";
import { Avatar } from "@material-ui/core";

import { EvaluationStatus } from "domains/evaluations/models/Evaluations";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { Permissions as P } from "domains/evaluations/utils/constants";
import useHoumer from "domains/evaluations/hooks/useHoumer";
import useModal from "hooks/useModal";

import HoumerModal from "./components/HoumerModal";
import ProtectComponent from "../ProtectComponent";
import { classes } from "./HoumerCardStyles";

interface Props {
  applicantId?: string;
  evaluationStatus: EvaluationStatus;
}

const HoumerCard = ({ applicantId = null, evaluationStatus }: Props) => {
  const { handleOpenModal, open } = useModal();
  const { isFetching, isError, isSuccess, data } = useHoumer({ applicantId });

  return (
    <>
      <AdminCard
        className={classes.houmerCardContainer}
        title="Houmer"
        showTitleBackground={true}
        withSpace={false}>
        {!applicantId && <p className={classes.textNormal}>No hay un houmer asociado</p>}
        {isFetching && (
          <Spinner classNameContainer={classes.spinner} variant="primary" size="2xl" />
        )}
        {!isFetching && isError && <p className={classes.textNormal}>Error al buscar Houmer</p>}
        {!isFetching && isSuccess && (
          <div className={classes.houmerInfoContainer}>
            {data.name !== "" ? (
              <div className={classes.houmerInfo}>
                {/* TODO: Change this material ui component  */}
                <Avatar src={data.photo} className={classes.avatar} />
                <div>
                  <p className={classes.textNormal}>{data.name}</p>
                  <p className={classes.textNormal}>{data.email}</p>
                  <p className={classes.textNormal}>{data.phone}</p>
                </div>
              </div>
            ) : (
              <p className={classes.textNormal}>No hay un houmer asociado</p>
            )}
            <ProtectComponent
              requiredPermissions={[P.UPDATE_HOUMER]}
              evaluationStatus={evaluationStatus}>
              <div className={classes.buttonContainer}>
                <Button size="sm" variant="tertiary" onClick={handleOpenModal}>
                  Cambiar
                </Button>
              </div>
            </ProtectComponent>
          </div>
        )}
      </AdminCard>
      <HoumerModal
        applicantId={applicantId}
        houmerId={null}
        handleOpenModal={handleOpenModal}
        open={open}
      />
    </>
  );
};

export default HoumerCard;
