import { makeStyles, Theme } from "@material-ui/core";

const useGeneralFormStyles = makeStyles<Theme>((theme) => ({
  textField: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    marginBottom: theme.spacing(0.5),
  },
  input: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[75]}`,
    fontSize: theme.typography.pxToRem(12),
  },
  descriptionInput: {
    top: 5,
    minHeight: 64,
    padding: theme.spacing(1, 1.5),
    alignItems: "flex-start",
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[75]}`,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
  },
  pickerRoot: {
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 4,
  },
  pickerInput: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: theme.palette.grey[500],
  },
  inputNumber: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[75]}`,
    fontSize: theme.typography.pxToRem(12),
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  selectContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
  },
  selectLabel: {
    paddingBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.66,
  },
  selectButtonList: {
    paddingTop: theme.spacing(1),
  },
}));

export default useGeneralFormStyles;
