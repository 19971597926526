import numberWithCommas from "./numberWithCommas";

export const formatCurrency = (currency: string, price: number) => {
  if (price) {
    if (currency !== "CLF") {
      return `${currency} $${numberWithCommas(Math.round(price))}`;
    }
    return `UF ${price.toFixed(2)}`;
  }
  return "N/A";
};
