import { makeStyles, Theme } from "@material-ui/core";

const useAnsweredQuestionStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: "#F8FAFB",
    padding: theme.spacing(2),
    borderRadius: 16,
    border: "1px solid #ECF2F4",
  },
  questionHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  answerHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    color: "#515B60",
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    color: "#515B60",
  },
  question: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
  },
  answer: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
  },
}));

export default useAnsweredQuestionStyles;
