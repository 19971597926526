// import tagsService from "services/tagsService";
import projectsService from "services/projectsService";
import tagsService from "services/tagsService";
import Swal from "sweetalert2";
import {
  Action,
  Dispatch,
  SET_FILTERS,
  SET_FORM,
  SET_TAGS,
  SET_ID_ERROR,
  TagFilters,
  TagForm,
  TagsData,
  SET_TAGS_TYPES,
  Option,
  SET_LOADING_POST,
  SET_SHOW_SUCCESS,
  SET_SHOW_DELETE_MODAL,
} from "./tagTypes";
import { parseTagDetails, parseTags } from "./tagUtils";

export const setTags = (tags: TagsData): Action => ({
  type: SET_TAGS,
  tags,
});

export const setForm = (form: TagForm): Action => ({
  type: SET_FORM,
  form,
});

export const setFilters = (filters: TagFilters): Action => ({
  type: SET_FILTERS,
  filters,
});

export const setShowSuccess = (showSuccess: boolean): Action => ({
  type: SET_SHOW_SUCCESS,
  showSuccess,
});

export const setShowDeleteModal = (showDeleteModal: boolean): Action => ({
  type: SET_SHOW_DELETE_MODAL,
  showDeleteModal,
});

export const setIdError = (idError: boolean): Action => ({
  type: SET_ID_ERROR,
  idError,
});

export const setTagTypes = (tagTypes: Option[]): Action => ({
  type: SET_TAGS_TYPES,
  tagTypes,
});

export const setLoadingPost = (loadingPost: boolean): Action => ({
  type: SET_LOADING_POST,
  loadingPost,
});

export const clearForm = (): Action => ({
  type: SET_FORM,
  form: { ids: [], country: null },
});

export const getTags = async (id: string, dispatch: Dispatch, token: string) => {
  dispatch(setTags({ data: [], loading: true }));
  try {
    const response = await tagsService.getTags(id, token);
    if (response) {
      const tags = parseTags(response.data);
      dispatch(setTags({ data: tags, loading: false }));
    }
  } catch (e) {
    dispatch(setTags({ loading: false }));
    console.error(e);
  }
};

export const deleteTag = async (id: string, dispatch: Dispatch, token: string) => {
  try {
    dispatch(setLoadingPost(true));
    await tagsService.deleteTag(id, token);
  } catch (err) {
    Swal.fire("Error", "Error eliminando Tag, intente nuevamente.", "error");
    console.error(err);
  } finally {
    dispatch(setLoadingPost(false));
  }
};

export const editTag = async (id: string, data: TagForm, dispatch: Dispatch, token: string) => {
  try {
    dispatch(setLoadingPost(true));
    const response = await tagsService.editTag(id, parseTagDetails(data), token);
    if (response.data) {
      dispatch(setShowSuccess(true));
    }
  } catch (err) {
    console.error(err);
    Swal.fire("Error", "Error editando Tag, intente nuevamente.", "error");
    return false;
  } finally {
    dispatch(setLoadingPost(false));
  }
};

export const validateProjectId = async (propId: string, dispatch: Dispatch, token: string) => {
  try {
    dispatch(setIdError(false));
    const project = await projectsService.getProject(parseInt(propId), token);
    if (project.data) {
      dispatch(setIdError(false));
    }
  } catch (err) {
    dispatch(setIdError(true));
    console.error(err);
  }
};

export const getTagTypes = async (country?: string, dispatch?: Dispatch) => {
  try {
    const tagTypes = await tagsService.getTagTypes();
    const tagsByCountry = tagTypes?.data?.results
      ?.filter((item) => item.country === country || item.country === "All")
      .map((tag) => ({ label: tag.tag, value: tag.id }));
    dispatch(setTagTypes(tagsByCountry));
  } catch (err) {
    console.error(err);
  }
};

export const assignTag = async (data: TagForm, dispatch: Dispatch, token: string) => {
  try {
    dispatch(setLoadingPost(true));
    const response = await tagsService.createTag(parseTagDetails(data), token);
    if (response.data) {
      dispatch(setShowSuccess(true));
    }
  } catch (err) {
    console.error(err);
    Swal.fire("Error", "Error asociando Tag, intente nuevamente.", "error");
    return false;
  } finally {
    dispatch(setLoadingPost(false));
  }
};
