import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useCommonExpensesHeaderStyles from "./CommonExpensesHeaderStyles";

interface Props {
  addCommonExpense: () => void;
  hasCommonExpenses: boolean;
}

const CommonExpensesHeader = ({ addCommonExpense, hasCommonExpenses }: Props) => {
  const classes = useCommonExpensesHeaderStyles();

  return (
    <Box className={classes.commonExpensesHeaderRoot}>
      {!hasCommonExpenses && (
        <DesignSystemButton
          variant="primary"
          size="small"
          label="Agregar +"
          onClick={addCommonExpense}
        />
      )}
    </Box>
  );
};

export default CommonExpensesHeader;
