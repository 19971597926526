import { UseQueryResult, useQuery } from "react-query";

import { Houmer, HoumerSchema } from "domains/evaluations/models/Houmer";
import applicantService from "services/applicantService";
import { useGetToken } from "hooks";

interface Props {
  applicantId: string;
}

const useHoumer = ({ applicantId }: Props) => {
  const { getToken } = useGetToken();

  const query: UseQueryResult<Houmer, Error> = useQuery(
    ["houmer_by_applicant_id", applicantId],
    async () => {
      const authToken = await getToken();
      const { data } = await applicantService.getHoumer(applicantId, authToken);
      return HoumerSchema.parse(data);
    },
    {
      enabled: !!applicantId,
      retry: false,
    }
  );

  return { ...query };
};

export default useHoumer;
