import { useState } from "react";

import Button from "@houm-com/ui/Button";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";

import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";

import useDocumentsGenerationProcessStyles from "./DocumentsGenerationProcessStyles";
import useDocumentSectionsLinks from "./hooks/useDocumentSectionsLinks";
import useDocumentLinks from "./hooks/useDocumentLinks";
import GenerateDocuments from "./components/GenerateDocuments";
import ObservationForm from "./components/ObservationForm";
import EditContract from "./components/EditContract";
import CurrentFiles from "./components/CurrentFiles";

interface Props {
  contractId: number;
  contractType: string;
  tenantObservations: string;
  landlordObservations: string;
}

const DocumentsGenerationProcess = ({
  contractId,
  contractType,
  tenantObservations,
  landlordObservations,
}: Props) => {
  const classes = useDocumentsGenerationProcessStyles();
  const { data, isLoading } = useDocumentLinks(contractId);
  const {
    data: documentSectionsLinks,
    isLoading: loadingDocumentSectionsLinks,
    isSuccess: isSuccessDocumentSectionsLinks,
  } = useDocumentSectionsLinks(contractId);
  const [showContractModal, setShowContractModal] = useState(false);

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography component="h6" className={classes.textLabel}>
            Genera los borradores y revisa que toda la información esté bien
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ObservationForm
                contractId={contractId}
                observation={landlordObservations}
                observationType="landlord"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ObservationForm
                contractId={contractId}
                observation={tenantObservations}
                observationType="tenant"
              />
            </Grid>
          </Grid>
          <Box className={classes.buttonRootContent}>
            <Box>
              <Typography className={classes.textLabelTitle}>Acciones</Typography>
              <Box className={classes.buttonContainer}>
                <GenerateDocuments contractId={contractId} />
                <ProtectedComponent
                  scope="permissions"
                  require={["update:custom_online_contract_data:all"]}>
                  <Button
                    variant="secondary"
                    size="md"
                    type="button"
                    onClick={() => setShowContractModal(true)}>
                    Editar documentos
                  </Button>
                </ProtectedComponent>
              </Box>
            </Box>
            <CurrentFiles contractId={contractId} links={data} isLoading={isLoading} />
          </Box>
        </CardContent>
      </Card>
      <CustomDialog
        open={showContractModal}
        title="Editar documentos"
        subtitle="Elige los documentos a editar"
        onClose={() => setShowContractModal(false)}>
        <Loading loading={loadingDocumentSectionsLinks}>
          {isSuccessDocumentSectionsLinks && (
            <EditContract
              contractId={contractId}
              documentSectionsLinks={documentSectionsLinks}
              contractType={contractType}
            />
          )}
        </Loading>
      </CustomDialog>
    </>
  );
};

export default DocumentsGenerationProcess;
