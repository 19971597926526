import { Box, Typography } from "@material-ui/core";

import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

import useReportDownloadStyles from "./ReportDownloadStyles";

function ReportDownload() {
  const classes = useReportDownloadStyles();

  return (
    <Box display="flex" alignItems="center">
      <DownloadIcon className={classes.downloadIcon} />
      <Typography className={classes.link}>Descargar Informe</Typography>
    </Box>
  );
}

export default ReportDownload;
