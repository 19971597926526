import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";

import schedulesService from "services/schedulesService";
import { Houmer } from "hooks/useAllAppraisers/types";

const usePropertyAppraiser = (propertyId: number) => {
  const { getToken } = useGetToken();
  const {
    data: appraiser,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<Houmer, Error> = useQuery<Houmer, Error>(
    ["propertyAppraiser", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await schedulesService.getPropertyAppraiser(authToken, propertyId);
      return response.data;
    },
    {
      retry: false,
    }
  );

  return { appraiser, isLoading, isError, refetch };
};

export default usePropertyAppraiser;
