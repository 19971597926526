import { Button } from "@material-ui/core";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import useSaveInformationStyles from "./SaveInformationStyles";

function SaveInformation() {
  const classes = useSaveInformationStyles();

  return (
    <CustomCenteredModal
      open={false}
      onClose={() => null}
      title="Descartar cambios"
      subtitle="¿Estás seguro de querer irte sin guardar los cambios?"
      showCloseButton={!false}
      withLogo>
      <div className={classes.buttonContainers}>
        <Button color="primary" size="small" variant="text" className={classes.exitButton}>
          Salir sin guardar
        </Button>
        <Button size="small" variant="outlined" color="primary" className={classes.saveButton}>
          Guardar cambios
        </Button>
      </div>
    </CustomCenteredModal>
  );
}

export default SaveInformation;
