/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import StyledRadio from "../atoms/CustomStyleRadio";

import useStyles from "./ControlledOptionsStyles";

interface OptionTypes {
  value: string | number | boolean;
  label: string;
}

interface Props {
  controllerProps?: React.ComponentProps<typeof Controller>;
  defaultValue?: string | number;
  helperText?: string;
  id: string;
  label?: ReactNode | string | null;
  options: OptionTypes[];
  column?: boolean;
  disabled?: boolean;
  optionClassName?: string;
}

export default function ControlledOptions({
  controllerProps,
  defaultValue,
  helperText,
  id,
  label = null,
  options,
  column = true,
  disabled = false,
  optionClassName = "",
}: Props) {
  const { control } = useFormContext();

  const classes = useStyles({ column });
  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error, invalid } }) => (
        <FormControl
          error={invalid}
          className={classes.container}
          component="fieldset"
          disabled={disabled}>
          {label && (
            <span id={id} className={classes.label}>
              {label}
            </span>
          )}
          {helperText && <div className={classes.helperText}>{helperText}</div>}
          <RadioGroup
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            classes={{
              root: classes.rootRadio,
            }}>
            {options.map((option) => (
              <FormControlLabel
                key={`${option.value}-${option.label}`}
                value={option.value}
                control={<StyledRadio />}
                label={option.label}
                classes={{
                  label: optionClassName,
                }}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{error && error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}
