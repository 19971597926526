/* eslint-disable camelcase */
import { ModifyPropertyForm, CreatePropertyForm } from "models/createProperty";
import { formatAddress, generalOptions } from "utils/createProperty";
import isEmpty from "./isEmpty";

import _ from "lodash";
import moment from "moment";
import parseCountryCurrencyCode from "./parseCountryCurrencyCode";
import { PropertyData } from "models/Property";

export default (data: ModifyPropertyForm & CreatePropertyForm, property?: PropertyData) => {
  const {
    address_object,
    apartment_type,
    apartment_floor,
    association_name,
    banos,
    bodega,
    brokerage_commission,
    calefaccion,
    curtain_hanger,
    closet,
    code,
    condominio,
    country,
    coordinates,
    custom_commune,
    deal_id,
    dormitorios,
    estacionamientos,
    environments,
    en_suite,
    furnished,
    for_rental,
    for_sale,
    floors,
    type,
    gc,
    guest_parking_spaces,
    gym,
    numero_depto,
    half_baths,
    has_swimming_pool,
    has_playground,
    has_all_day_vigilance,
    has_party_room,
    has_games_room,
    has_cinema,
    has_sauna,
    has_steam_room,
    has_bbq_area,
    has_laundry,
    has_elevator,
    has_concierge,
    has_pet_area,
    has_gourmet_space,
    has_playroom,
    has_balcony,
    has_patio,
    has_water_tank,
    house_extension,
    hot_water_system,
    has_laundry_room,
    has_private_roof_garden,
    has_roof_garden_amenities,
    has_clothing_area,
    has_air_conditioning,
    is_construction_in_gray,
    is_common_expenses_included,
    is_renewed,
    is_new,
    investment_type,
    kitchen_daily_dining_room,
    kitchen_is_furnished,
    laundry_capacity,
    lessor_brokerage_commission,
    loggia_size,
    mascotas,
    m_terreno,
    m_construidos,
    minisplits,
    neighborhood,
    orientacion_object,
    orientation,
    parking_type,
    pin,
    promotional_price,
    promotion_months,
    pipedrive_id,
    project_delivery_month,
    project_delivery_year,
    stratus,
    second_line_address,
    servicios,
    street_number,
    taxes,
    taxes_currency,
    tipo_moneda,
    tipo_moneda_venta,
    terrace_size,
    terraza,
    user,
    valor,
    valor_venta,
    year_built,
  } = data;
  let address;
  // TODO: this can be removed after the API /locations/address/ is establed and we remove google suggestions
  if (typeof address_object === "object" && "parsed" in address_object) {
    address = address_object;
  } else if (typeof address_object === "object") {
    address = formatAddress(address_object);
  }
  let c;
  if (coordinates) {
    c = { lng: coordinates[0], lat: coordinates[1] };
  }
  let first_available_handover_date;
  if (project_delivery_month != null && project_delivery_year) {
    first_available_handover_date = new Date(project_delivery_year, project_delivery_month);
  }

  let amoblado = null;
  if (furnished) amoblado = furnished !== "non" ? 1 : 0;

  const newProperty = {
    address: address?.street || property?.address,
    street_number: street_number || address?.streetNumber || property?.street_number,
    region: address?.region || property?.region,
    comuna: custom_commune?.value || address?.commune || property?.comuna,
    state: generalOptions[country]?.state,
    google_formatted_address:
      typeof address_object === "object" && "formatted_address" in address_object
        ? address_object.formatted_address
        : null,
    neighborhood_id: neighborhood?.value || -1,
    second_line_address,
    type,
    numero_depto,
    for_rental,
    for_sale: for_sale || user?.role === "real_estate_developer",
    country,
    stratus,
    lessor_brokerage_commission: lessor_brokerage_commission || 50,
    half_baths,
    pin,
    apartment_floor,
    user_id: user?.id || property?.user?.id,
    user: user?.id || property?.user?.id,
    is_new,
    investment_type,
    taxes,
    taxes_currency,
    taxes_currency_code: parseCountryCurrencyCode(country, taxes_currency),
    deal_id,
    pipedrive_id,
    code,
  };

  const associationAmenities = {
    association_name,
    floors,
    guest_parking_spaces,
    has_all_day_vigilance,
    has_bbq_area,
    has_cinema,
    has_concierge,
    has_elevator,
    has_games_room,
    has_gourmet_space,
    has_gym: gym,
    has_laundry,
    has_party_room,
    has_patio,
    has_pet_area,
    has_playground,
    has_playroom,
    has_roof_garden: has_roof_garden_amenities,
    has_sauna,
    has_steam_room,
    has_swimming_pool,
  };

  const salesDetails = {
    brokerage_commission: brokerage_commission || 1.5,
    currency: tipo_moneda_venta,
    currency_code: parseCountryCurrencyCode(country, tipo_moneda_venta),
    price: valor_venta,
    first_available_handover_date,
  };

  const propertyDetails = {
    latitud: c?.lat || address?.lat,
    longitud: c?.lng || address?.lng,
    tipo_moneda,
    currency_code: parseCountryCurrencyCode(country, tipo_moneda),
    tipo_moneda_venta,
    gym,
    condominio,
    piscina: has_swimming_pool ? 1 : 0,
    amoblado,
    m_terreno,
    m_construidos,
    terrace_size,
    loggia_size,
    dormitorios,
    en_suite,
    banos,
    servicios,
    estacionamientos,
    terraza,
    bodega,
    mascotas,
    calefaccion,
    curtain_hanger,
    furnished,
    house_extension,
    hot_water_system: hot_water_system || "undefined",
    gc,
    orientacion: orientation || orientacion_object?.value,
    half_baths,
    valor,
    valor_venta,
    promotional_price: promotional_price || 0,
    promotion_months,
    has_balcony,
    kitchen_daily_dining_room,
    has_roof_garden: has_private_roof_garden,
    apartment_type,
    parking_type,
    kitchen_is_furnished,
    closet,
    has_air_conditioning,
    laundry_capacity,
    has_laundry_room,
    environments,
    has_clothing_area,
    is_construction_in_gray,
    is_common_expenses_included,
    is_renewed,
    has_water_tank,
    minisplits,
  };

  return {
    ..._.omitBy(newProperty, isEmpty),
    antiquity: year_built ? moment().year() - year_built : null,
    year_built,
    property_details: _.omitBy(propertyDetails, isEmpty),
    association_amenities: _.omitBy(associationAmenities, isEmpty),
    sales_details: _.omitBy(salesDetails, isEmpty),
  };
};
