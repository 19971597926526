import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { useEvaluation } from "context/evaluationContext/evaluationContext";
import newEvaluationService from "services/newEvaluationService";
import { EvaluationModel } from "models/Evaluation";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { ContractPersonEvaluation, parseContractPersonEvaluation } from "../utils";

const useInitContract = () => {
  const { country } = useParams<{ country: Locale }>();
  const { getToken } = useGetToken();
  const { state } = useEvaluation();
  const { singleEvaluation } = state;
  const evaluations: EvaluationModel[] = singleEvaluation.commerciallyApproved;

  const { isLoading, data, isError }: UseQueryResult<ContractPersonEvaluation[], Error> = useQuery<
    ContractPersonEvaluation[],
    Error
  >(["multipleEvaluations"], async () => {
    const authToken = await getToken();
    const response = await newEvaluationService.getMultipleEvaluationDetails(
      evaluations.map((ev) => ev?.id),
      authToken,
      country as Locale
    );

    const data = response["data"]?.evaluation_details;
    return evaluations.map((ev) => {
      const filterEvaluation = data.find((prevData) => prevData?.evaluation_id === ev.id);
      const evaluation: ContractPersonEvaluation = parseContractPersonEvaluation(
        ev,
        filterEvaluation?.details
      );
      if (country === "mx") evaluation.rfc_number = filterEvaluation?.details?.rfc_number;
      return evaluation;
    });
  });

  return {
    evaluations,
    evaluationData: isError ? evaluations : data,
    isLoading,
  };
};

export default useInitContract;
