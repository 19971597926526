import { makeStyles, Theme } from "@material-ui/core";

const useModalTextStyles = makeStyles((theme: Theme) => ({
  modalTextRoot: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
  },
}));

export default useModalTextStyles;
