import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  grid: {
    width: "100%",
  },
  monthCard: {
    margin: theme.spacing(0.5),
    height: 40,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 8,
    fontWeight: "bold",
    "&:focus-within": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  monthGrid: {
    borderRight: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: "0px 8px 8px 0px",
  },
  amountGrid: {
    borderLeft: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: "0px 8px 8px 0px",
  },
  textFieldGrid: {
    borderRadius: "0px 8px 8px 0px",
    "&:focus-within": {
      backgroundColor: "#F7F8FC",
    },
  },
  rootMonth: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    margin: theme.spacing(3, 0, 2, 0),
  },
}));
