import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Box } from "@material-ui/core";

import { useGetToken } from "hooks";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import {
  getLetterOfIntent,
  setCountry,
  setLoiId,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import Header from "./components/Header";
import PersonsCard from "./components/PersonsCard";
import useLetterOfIntentIndexStyles from "./LetterOfIntentIndexStyles";
import NegotiationCard from "./components/NegotiationCard";
import PropertyDataCard from "./components/PropertyDataCard";
import { Locale } from "models/Countries";

const useQuery = () => new URLSearchParams(useLocation().search);

function LetterOfIntentIndex() {
  const { dispatch } = useLetterOfIntent();
  const { getToken } = useGetToken();
  const classes = useLetterOfIntentIndexStyles();

  const params = useParams<{ id: string }>();
  const { id: loiId } = params;
  const country: Locale = useQuery().get("country") as Locale;

  useEffect(() => {
    dispatch(setCountry(country));
    dispatch(setLoiId(loiId));
    getToken().then((authToken) =>
      getLetterOfIntent({ country, loiId, token: authToken, dispatch })
    );
  }, []);

  return (
    <Box className={classes.container}>
      <Header />
      <Box className={classes.personsContainer}>
        <PersonsCard />
        <Box className={classes.rightContainer}>
          <NegotiationCard />
          <PropertyDataCard />
        </Box>
      </Box>
    </Box>
  );
}

export default LetterOfIntentIndex;
