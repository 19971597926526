import { toInteger } from "lodash";

import PlanFormProps from "Components/Admin/administrator/plans/pages/PlansForm/types";
import { CountryUpperCase } from "models/Countries";
import PlanProps, { ICity, IPlan, IPlanCategory, MonthlyDataProps } from "models/Plan";
import { parsePlanCategory } from "hooks/usePlans/utils/parsers";
import { IPlanPost } from "services/planService/types";

export const backendParsePlan = (plan: PlanProps) => plan;

enum BrokeragePercentage {
  LESSOR_PAYMENT = 100,
  LESSEE_PAYMENT = 100,
  LESSOR_BROKERAGE = 0,
  LESSEE_BROKERAGE = 0,
}

export const parseFormData = (
  data: PlanFormProps,
  planCategories: IPlanCategory[]
): Partial<PlanProps> => ({
  name: data.name,
  description: data.description,
  isActive: data.isActive,
  landlordBrokerage: data.landlordBrokerage,
  tenantBrokerage: data.tenantBrokerage,
  landlordBrokerageClosing: data.landlordBrokerageClosing,
  tenantBrokerageClosing: data.tenantBrokerageClosing,
  adminCommission: data.adminCommission,
  ...(data.contractTemplate && { contractTemplate: data.contractTemplate }),
  paymentOption: {
    id: toInteger(data.paymentOption),
  },
  duration: data.duration ?? 0,
  minimumAdminFee: data.minimumAdminFee,
  guaranteedMonths: data.guaranteedMonths,
  category: planCategories.find((category) => category.id === data.category),
});

const getFirstMonthValue = (id: number, defaultValue: number) => {
  if (id === 1) {
    return 100;
  } else {
    return defaultValue;
  }
};

export const parsePostPlanData = (
  data: PlanFormProps,
  country: CountryUpperCase,
  cities: number[]
): IPlanPost => ({
  name: data.name,
  description: data.description,
  active: data.isActive === "yes",
  lessor_brokerage: parseInt(data.landlordBrokerage),
  lessor_brokerage_closing: parseInt(data.landlordBrokerageClosing),
  lessee_brokerage: parseInt(data.tenantBrokerage),
  lessee_brokerage_closing: parseInt(data.tenantBrokerageClosing),
  admin_commission: parseInt(data.adminCommission),
  contract_template: data.contractTemplate,
  payment_option: data.paymentOption,
  months_duration: data.duration,
  guaranteed_months: data.guaranteedMonths,
  country,
  cities,
  minimum_admin_fee: data.minimumAdminFee,
  category: data.category,
  plan_months: data.monthlyData.map((dataMonth) => ({
    management_fee: dataMonth?.managementFee
      ? parseInt(dataMonth?.managementFee)
      : parseInt(data.adminCommission),
    lessor_payment: dataMonth?.lessorPayment
      ? parseInt(dataMonth?.lessorPayment)
      : BrokeragePercentage.LESSOR_PAYMENT,
    lessee_payment: dataMonth?.lesseePayment
      ? parseInt(dataMonth?.lesseePayment)
      : BrokeragePercentage.LESSEE_PAYMENT,
    lessor_brokerage: dataMonth?.lessorBrokerage
      ? parseInt(dataMonth?.lessorBrokerage)
      : getFirstMonthValue(dataMonth.month, BrokeragePercentage.LESSOR_BROKERAGE),
    lessee_brokerage: dataMonth?.lesseeBrokerage
      ? parseInt(dataMonth?.lesseeBrokerage)
      : getFirstMonthValue(dataMonth.month, BrokeragePercentage.LESSEE_BROKERAGE),
    month: dataMonth?.month,
  })),
});

export const parsePlanById = (plan: IPlan): Partial<PlanProps> => ({
  id: plan.id,
  name: plan.name,
  description: plan.description,
  isActive: plan.active,
  paymentOption: plan.payment_option,
  duration: plan.months_duration,
  country: plan.country,
  adminCommission: plan.admin_commission,
  contractTemplate: plan.contract_template?.id,
  cities: plan.cities,
  landlordBrokerage: plan.lessor_brokerage,
  landlordBrokerageClosing: plan.lessor_brokerage_closing,
  tenantBrokerage: plan.lessee_brokerage,
  tenantBrokerageClosing: plan.lessee_brokerage_closing,
  monthlyData: plan.plan_months.map((p) => ({
    lesseeBrokerage: p.lessee_brokerage,
    lesseePayment: p.lessee_payment,
    lessorBrokerage: p.lessee_brokerage,
    lessorPayment: p.lessor_payment,
    managementFee: p.management_fee,
    month: p.month,
  })),
  minimumAdminFee: plan.minimum_admin_fee,
  guaranteedMonths: plan.guaranteed_months,
  category: parsePlanCategory(plan.category),
});

export const parseMonthlyData = (data): MonthlyDataProps[] => {
  const aux: {
    month: number;
    managementFee: string;
    lessorPayment: string;
    lesseePayment: string;
    lessorBrokerage: string;
    lesseeBrokerage: string;
  }[] = [];
  for (let month = 1; month <= data.duration; month++) {
    aux.push({
      month,
      managementFee: data[`managementFee-month-${month}`],
      lessorPayment: data[`lessorPayment-month-${month}`],
      lesseePayment: data[`lesseePayment-month-${month}`],
      lessorBrokerage: data[`lessorBrokerage-month-${month}`],
      lesseeBrokerage: data[`lesseeBrokerage-month-${month}`],
    } as MonthlyDataProps);
  }
  return aux;
};

export const parseCities = (cities: any | number[] | ICity[]) => cities.map((city) => city.id);
