import Swal from "sweetalert2";

import {
  Dispatch,
  Action,
  SET_CURRENT_COUNTRY,
  SET_EXTERNAL_PORTAL_PUBLICATIONS,
  SET_TOTAL_QUOTA_DATA,
  SET_QUOTA_BY_MACROZONE_DATA,
  SET_TOTAL_QUOTA_FILTERS,
  SET_QUOTA_BY_MACROZONE_FILTERS,
  SET_PLAN_EDITION_DATA,
  SET_PLAN_EDITION_FILTERS,
  REFETCH_PLAN_EDITION_DATA,
  SET_PLAN_EDITION_STATES,
  RESET_PLAN_EDITION_FILTERS,
  RESET_CURRENT_PLAN_EDITION_ROW,
} from "./integrationTypes";
import integrationService from "services/integrationService";
import {
  ExternalPortalPublication,
  TotalQuota,
  QuotaByMacrozone,
  TotalQuotaFilters,
  QuotaByMacrozoneFilters,
  PlanEdition,
  PlanEditionFilters,
  PlanEditionStates,
  PlanQuota,
} from "models/Integration";
import { PaginatedTable } from "context/tableContext/tableTypes";
import {
  parseIndexExternalPortalPublicationsResponse,
  parseIndexQuotaByMacrozoneResponse,
  parseIndexTotalQuotaResponse,
} from "services/integrationService/parsers";
import {
  IndexExternalPortalPublicationsFilters,
  IndexQuotaByMacrozoneFilters,
  IndexTotalQuotaFilters,
  UpdateQuotaByMacrozoneBody,
} from "services/integrationService/types";
import { Country } from "models/Countries";

export const setCurrentCountry = (country: Country): Action => ({
  type: SET_CURRENT_COUNTRY,
  country,
});

export const setExternalPortalPublications = (
  externalPortalPublicationsInfo: Partial<PaginatedTable<ExternalPortalPublication>>
): Action => ({
  type: SET_EXTERNAL_PORTAL_PUBLICATIONS,
  externalPortalPublicationsInfo,
});

export const setTotalQuotaData = (totalQuotaData: Partial<PaginatedTable<TotalQuota>>): Action => ({
  type: SET_TOTAL_QUOTA_DATA,
  totalQuotaData,
});

export const setTotalQuotaFilters = (totalQuotaFilters: Partial<TotalQuotaFilters>): Action => ({
  type: SET_TOTAL_QUOTA_FILTERS,
  totalQuotaFilters,
});

export const setTotalQuotaFiltersAction = (
  filters: Partial<TotalQuotaFilters>,
  dispatch: Dispatch
): void => {
  dispatch(setTotalQuotaFilters(filters));
};

export const setQuotaByMacrozoneData = (
  quotaByMacrozoneData: Partial<PaginatedTable<QuotaByMacrozone>>
): Action => ({
  type: SET_QUOTA_BY_MACROZONE_DATA,
  quotaByMacrozoneData,
});

export const setQuotaByMacrozoneFilters = (
  quotaByMacrozoneFilters: Partial<QuotaByMacrozoneFilters>
): Action => ({
  type: SET_QUOTA_BY_MACROZONE_FILTERS,
  quotaByMacrozoneFilters,
});

export const setQuotaByMacrozoneFiltersAction = (
  filters: Partial<QuotaByMacrozoneFilters>,
  dispatch: Dispatch
): void => {
  dispatch(setQuotaByMacrozoneFilters(filters));
};

export const setPlanEditionData = (
  planEditionData: Partial<PaginatedTable<PlanEdition>>
): Action => ({
  type: SET_PLAN_EDITION_DATA,
  planEditionData,
});

export const setPlanEditionFilters = (planEditionFilters: Partial<PlanEditionFilters>): Action => ({
  type: SET_PLAN_EDITION_FILTERS,
  planEditionFilters,
});

export const setPlanEditionFiltersAction = (
  filters: Partial<PlanEditionFilters>,
  dispatch: Dispatch
): void => {
  dispatch(setPlanEditionFilters(filters));
};

export const resetPlanEditionFilters = (): Action => ({
  type: RESET_PLAN_EDITION_FILTERS,
});

export const refetchPlanEditionData = (): Action => ({
  type: REFETCH_PLAN_EDITION_DATA,
});

export const setPlanEditionStates = (planEditionStates: Partial<PlanEditionStates>): Action => ({
  type: SET_PLAN_EDITION_STATES,
  planEditionStates,
});

export const resetCurrentPlanEditingRow = (): Action => ({
  type: RESET_CURRENT_PLAN_EDITION_ROW,
});

export const getExternalPortalPublications = async (
  mappedFilters: IndexExternalPortalPublicationsFilters,
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  dispatch(setExternalPortalPublications({ loading: true }));

  try {
    const response = await integrationService.indexExternalPortalPublicationsRequest(
      mappedFilters,
      token
    );
    const externalPortalPublicationsData = parseIndexExternalPortalPublicationsResponse(
      response.data.results
    );
    dispatch(
      setExternalPortalPublications({
        loading: false,
        count: response.data.count,
        data: externalPortalPublicationsData,
      })
    );
  } catch (e) {
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de publicaciones en portales externos",
    });
  } finally {
    dispatch(setExternalPortalPublications({ loading: false }));
  }
};

export const getTotalQuota = async (
  mappedFilters: IndexTotalQuotaFilters,
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  dispatch(setTotalQuotaData({ loading: true }));

  try {
    const response = await integrationService.indexTotalQuotaRequest(mappedFilters, token);
    const totalQuotaData = parseIndexTotalQuotaResponse(response.data.results);
    dispatch(
      setTotalQuotaData({
        loading: false,
        count: response.data.count,
        data: totalQuotaData,
      })
    );
  } catch (e) {
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de cupos totales por portal",
    });
  } finally {
    dispatch(setTotalQuotaData({ loading: false }));
  }
};

export const getQuotaByMacrozone = async (
  mappedFilters: IndexQuotaByMacrozoneFilters,
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  dispatch(setQuotaByMacrozoneData({ loading: true }));

  try {
    const response = await integrationService.indexQuotaByMacrozoneRequest(mappedFilters, token);
    const quotaByMacrozoneData = parseIndexQuotaByMacrozoneResponse(response.data.results);
    dispatch(
      setQuotaByMacrozoneData({
        loading: false,
        count: response.data.count,
        data: quotaByMacrozoneData,
      })
    );
  } catch (e) {
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de cupos totales por portal",
    });
  } finally {
    dispatch(setQuotaByMacrozoneData({ loading: false }));
  }
};

export const getPlanEditionData = async (
  mappedFilters: Partial<IndexQuotaByMacrozoneFilters>,
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  dispatch(setPlanEditionData({ loading: true }));

  try {
    const responseQuotaByMacrozoneRequest = await integrationService.indexQuotaByMacrozoneRequest(
      mappedFilters,
      token
    );
    const quotaByMacrozoneData = parseIndexQuotaByMacrozoneResponse(
      responseQuotaByMacrozoneRequest.data.results
    );
    dispatch(
      setPlanEditionData({
        loading: false,
        count: responseQuotaByMacrozoneRequest.data.count,
        data: quotaByMacrozoneData,
      })
    );
  } catch (e) {
    await Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo obtener información de cupos por macrozona para éstos filtros",
    });
  } finally {
    dispatch(setPlanEditionData({ loading: false }));
  }
};

export const updatePortalQuota = async (
  id: number,
  data: UpdateQuotaByMacrozoneBody,
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  try {
    await integrationService.updateQuotaByMacrozoneRequest(id, data, token);
    Swal.fire({
      type: "success",
      title: "Cambios guardados",
      text: "Los cupos de ésta macrozona se han actualizado exitosamente",
    });
    dispatch(refetchPlanEditionData());
  } catch (e) {
    Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo actualizar los cupos de ésta macrozona",
    });
    console.error(e);
  }
};

export const updateManyQuotas = async (
  data: PlanQuota[],
  dispatch: Dispatch,
  token: string
): Promise<void> => {
  dispatch(setPlanEditionStates({ isUpdating: true }));
  try {
    await integrationService.updateManyQuotasRequest(data, token);
    Swal.fire({
      type: "success",
      title: "Cambios guardados",
      text: "Los cupos se han actualizado exitosamente",
    });
    dispatch(refetchPlanEditionData());
  } catch (e) {
    Swal.fire({
      type: "error",
      title: "Ops, algo salió mal",
      text: "No se pudo actualizar los cupos de ésta macrozona",
    });
  } finally {
    dispatch(
      setPlanEditionStates({
        isEditing: false,
        isUpdating: false,
        quotasOnEdition: [],
      })
    );
  }
};
