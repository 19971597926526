import "animate.css/animate.min.css";
import "@fullcalendar/daygrid/main.css";
import { LicenseInfo } from "@material-ui/x-grid";
import { ThemeProvider } from "@material-ui/core";
import theme from "styles/globals";
import "assets/css/font-awesome.min.css";
import "assets/css/main.css";
import "./theme.css";
import "./main.css";
import "@houm-com/ui/main.css";
import { XGRID_LICENSE } from "env";

LicenseInfo.setLicenseKey(XGRID_LICENSE);

function Theme({ children }: { children: React.ReactElement }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
