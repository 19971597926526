import Swal from "sweetalert2";

import propertyService from "services/propertyService";
import {
  Action,
  Dispatch,
  SET_CURRENT_STEP,
  SET_LOADING,
  SET_PROPERTY,
  SET_NEGOTIATIONS,
  CurrentStep,
  Property,
  Negotiation,
  ResponseToOffer,
  SET_RESPONSE_TO_OFFER,
  SET_CURRENT_NEGOTIATION,
  Offer,
  SET_CURRENT_OFFER,
  SET_COUNTRY_CODE,
  NegotiationAdmin,
  SET_NEGOTIATIONS_ADMIN,
} from "./negotiationsTypes";
import {
  parseNegotiations,
  parseNegotiationsAdmin,
  parseProperty,
  unParseResponseToOffer,
} from "./utils";
import negotiationService from "services/negotiationService/negotiationService";
import { Locale } from "models/Countries";
import { countryCode } from "utils";
import { AcceptOfferAdmin } from "services/negotiationService/models";

export const setCurrentStep = (currentStep: CurrentStep): Action => ({
  type: SET_CURRENT_STEP,
  currentStep,
});

export const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setProperty = (data: Property): Action => ({
  type: SET_PROPERTY,
  data,
});

export const setNegotiations = (negotiations: Negotiation[]): Action => ({
  type: SET_NEGOTIATIONS,
  negotiations,
});

export const setNegotiationsAdmin = (negotiationsAdmin: NegotiationAdmin[]): Action => ({
  type: SET_NEGOTIATIONS_ADMIN,
  negotiationsAdmin,
});

export const setCurrentNegotiation = (currentNegotiation: Negotiation): Action => ({
  type: SET_CURRENT_NEGOTIATION,
  currentNegotiation,
});

export const setCurrentOffer = (currentOffer: Offer): Action => ({
  type: SET_CURRENT_OFFER,
  currentOffer,
});

export const setCountryCode = (countryCode: Locale): Action => ({
  type: SET_COUNTRY_CODE,
  countryCode,
});

export const setResponseToOffer = (responseToOffer: ResponseToOffer): Action => ({
  type: SET_RESPONSE_TO_OFFER,
  responseToOffer,
});

export async function fetchData(uid: string, authToken: string, dispatch: Dispatch) {
  try {
    const propertyRes = await propertyService.get(uid, authToken);
    if (typeof propertyRes !== "boolean") {
      dispatch(setProperty(parseProperty(propertyRes.data)));
    }
    const code = countryCode(propertyRes.data.country.toLowerCase());
    dispatch(setCountryCode(code));
    const negotiationsRes = await negotiationService().getNegotiations(code, uid, authToken);
    dispatch(setNegotiations(parseNegotiations(negotiationsRes.data)));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    }).then(() => {
      window.history.back();
    });
  } finally {
    dispatch(setLoading(false));
  }
}

export async function fetchDataAdmin(
  uid: string,
  country: Locale,
  authToken: string,
  dispatch: Dispatch
) {
  try {
    const negotiationsRes = await negotiationService().getNegotiationsAdmin(
      country,
      uid,
      authToken
    );
    dispatch(setNegotiationsAdmin(parseNegotiationsAdmin(negotiationsRes.data)));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado.",
    });
  }
}

export const replyOffer = async (
  country: Locale,
  idOffer: string,
  data: ResponseToOffer,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    const reply = unParseResponseToOffer(data);
    await negotiationService().replyOffer(reply, country, idOffer, authToken);
    dispatch(setCurrentStep("offerAnswered"));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const rejectOfferAdmin = async ({
  offerId,
  country,
  response,
  authToken,
}: {
  offerId: string;
  country: Locale;
  response: ResponseToOffer;
  authToken: string;
}) => {
  try {
    const sanitizedResponse = unParseResponseToOffer(response);
    await negotiationService().rejectOfferAdmin(country, offerId, sanitizedResponse, authToken);
    Swal.fire({
      type: "success",
      text: "Oferta rechazada correctamente.",
    });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  }
};

export const acceptOfferAdmin = async ({
  offerId,
  country,
  response,
  file,
  authToken,
}: {
  offerId: string;
  country: Locale;
  response: AcceptOfferAdmin;
  file?: File;
  authToken: string;
}) => {
  try {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("bid_update", JSON.stringify(response));
    await negotiationService().acceptOfferAdmin(country, offerId, formData, authToken);
    Swal.fire({
      type: "success",
      text: "Oferta aceptada correctamente.",
    });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  }
};
