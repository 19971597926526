import { GridOverlay } from "@material-ui/x-grid";
import { MainText } from "../../../typography";

interface Props {
  error: Error;
}

function ErrorOverlay({ error }: Props) {
  return (
    <GridOverlay>
      <MainText>Ha ocurrido el siguiente error: {error.message}</MainText>
    </GridOverlay>
  );
}

export default ErrorOverlay;
