import { startCase } from "lodash";

import { GridColumns, GridColDef } from "@material-ui/x-grid";

import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag/DSTag";
import { RentStatus } from "models/Property";

import { formatDate } from "../../../utils/utils";
import BaseField from "../../BaseField";
import { MainText } from "../../typography";
import LinkField from "../components/LinkField";
import ReadjustField from "../components/ReadjustField";
import TenantField from "../components/TenantField";
import PropertyField from "../components/PropertyField";
import LastPaymentField from "../components/LastPaymentField";
import { rentStatusTagType } from "./utils";

const baseColumnsConfig: GridColDef = {
  field: "",
  resizable: true,
  filterable: false,
  sortable: false,
};

export const columnsConfig: GridColumns = [
  {
    ...baseColumnsConfig,
    headerName: "Propiedad",
    field: "address",
    width: 230,
    flex: 1,
    renderCell: (params) => (
      <PropertyField address={params.row.fullAddress} status={params.row.subscription?.status} />
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Estado arriendo",
    field: "rentStatus",
    width: 112,
    renderCell: (params) => (
      <BaseField requiredData={params.row.rentStatus}>
        <DSTag
          text={startCase(RentStatus[params.row.rentStatus])}
          type={rentStatusTagType(params.row.rentStatus)}
        />
      </BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "ID Propiedad",
    field: "id",
    width: 100,
    renderCell: (params) => (
      <LinkField link={`/admin/properties/${params.row.uid}`} text={params.row.id} />
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "ID Suscripción",
    field: "subscription",
    width: 100,
    renderCell: (params) => (
      <LinkField
        link={`/admin/clients/${params.row.contract?.quotation?.id}`}
        text={params.row.contract?.quotation?.id}
      />
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Inicio Contrato",
    field: "initDate",
    width: 110,
    renderCell: (params) => (
      <BaseField requiredData={params.row.contract?.initDate}>
        <MainText>{formatDate(params.row.contract?.initDate, "dd/MM/yyyy")}</MainText>
      </BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Plan",
    field: "plan",
    width: 120,
    renderCell: (params) => (
      <BaseField requiredData={params.row.plan?.name}>
        <MainText>{params.row.plan?.name}</MainText>
      </BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Reajuste",
    field: "readjustment",
    width: 120,
    renderCell: (params) => (
      <ReadjustField
        type={params.row.contract?.readjust}
        date={formatDate(params.row.readjustDate, "MMMM yyyy", true)}
      />
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Pago propietario",
    field: "last_payment",
    width: 115,
    renderCell: (params) => (
      <LastPaymentField propertyId={params.row.id} country={params.row.country} />
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Pago arrendatario",
    field: "tenant_data",
    flex: 1,
    renderCell: (params) => (
      <TenantField
        {...params.row.contract?.mainTenant}
        propertyId={params.row.id}
        status={params.row.subscription?.status}
        country={params.row.country}
      />
    ),
  },
];
