import { useState } from "react";

import Carousel from "react-material-ui-carousel";
import { Button, IconButton, Typography } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons/";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import useStyles from "./TableRowImagePreviewerStyles";

interface Props {
  imagesArr?: string[];
  rowName?: string;
}

function TableRowImagePreviewer({ imagesArr = [], rowName = "" }: Props) {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);

  const slicedImgsArray = imagesArr?.slice(0, 3);

  const classes = useStyles();

  const handleOpenCarousel = () => setIsCarouselOpen(true);
  const handleCloseCarousel = () => setIsCarouselOpen(false);

  if (!slicedImgsArray?.length)
    return <Typography className={classes.noImgsText}>Sin imágenes</Typography>;

  return (
    <>
      <Button className={classes.tableImgContainerBtn} onClick={handleOpenCarousel}>
        {slicedImgsArray.map((image) => (
          <div key={`image-${image}`} className={classes.tableImgContainer}>
            <img className={classes.tableImg} src={image} width="100" alt="small-preview" />
          </div>
        ))}
        {imagesArr.length > 3 && (
          <IconButton
            className={classes.tableImgContainer}
            aria-label="view-photos"
            onClick={handleOpenCarousel}>
            <MoreHoriz fontSize="small" />
          </IconButton>
        )}
      </Button>
      <CustomDialog title={rowName} open={isCarouselOpen} onClose={handleCloseCarousel}>
        <Carousel animation="slide" autoPlay={false}>
          {imagesArr.map((image) => (
            <div key={`image-${image}`} className={classes.carouselImgContainer}>
              <img className={classes.carouselImg} src={image} width="100" alt="large-preview" />
            </div>
          ))}
        </Carousel>
      </CustomDialog>
    </>
  );
}

export default TableRowImagePreviewer;
