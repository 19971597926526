import { makeStyles, Theme } from "@material-ui/core";

const useBasicServicesHeaderStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  bold: { fontWeight: 700 },
}));

export default useBasicServicesHeaderStyles;
