import { IPlan, IPlanCategory } from "models/Plan";
import { IPlanCategoryResponse } from "services/planService/types";
import { countryCode } from "utils";
import { IParsePlan } from "./types";

export const parserPlans = (plan: IPlan): IParsePlan => ({
  monthsDuration: plan.months_duration,
  adminCommission: plan.admin_commission,
  category: parsePlanCategory(plan.category),
  country: plan.country,
  cities: plan.cities,
  contractTemplate: {
    contractGeneralTemplate: plan?.contract_template?.contract_general_template as File | string,
    country: plan?.contract_template?.country,
    id: plan?.contract_template?.id,
    mandateGeneralTemplate: plan?.contract_template?.mandate_general_template as File | string,
    description: plan?.contract_template?.description,
    name: plan?.contract_template?.name,
  },
  lessorBrokerage: plan.lessor_brokerage,
  lesseeBrokerage: plan.lessee_brokerage,
  planMonths: plan?.plan_months.map((p) => ({
    lesseeBrokerage: p?.lessee_brokerage,
    lesseePayment: p?.lessee_payment,
    lessorBrokerage: p?.lessee_brokerage,
    lessorPayment: p?.lessor_payment,
    managementFee: p?.management_fee,
    month: p?.month,
  })),
  paymentOption: plan.payment_option,
  lessorBrokerageClosing: plan.lessor_brokerage_closing,
  lesseeBrokerageClosing: plan.lessee_brokerage_closing,
  label: plan.label,
  value: plan.value,
  price: plan.price,
  type: plan.type,
  id: plan.id,
  deletedAt: plan.deleted_at,
  isDeleted: plan.is_deleted,
  createdAt: plan.created_at,
  updatedAt: plan.updated_at,
  description: plan.description,
  active: plan.active,
  name: plan.name,
  contractStaticFile: plan?.contract_static_file as File | string,
  contractStaticFilePdf: plan?.contract_static_file_pdf as File | string,
  mandateStaticFile: plan?.mandate_static_file as File | string,
  mandateStaticFilePdf: plan?.mandate_static_file_pdf as File | string,
  isNew: plan.is_new,
  minimumAdminFee: plan.minimum_admin_fee,
  guaranteedMonths: plan.guaranteed_months,
});

export const parsePlanCategories = (planCategories: IPlanCategoryResponse[]): IPlanCategory[] =>
  planCategories.map(parsePlanCategory);

export const parsePlanCategory = (category: IPlanCategoryResponse | null): IPlanCategory | null =>
  category
    ? {
        id: category.id,
        key: category.key,
        description: category.description,
        country: countryCode(category.country),
        hasManagement: category.has_management,
        hasInsurance: category.has_insurance,
      }
    : null;
