import { Grid } from "@material-ui/core";

import { localeToCurrency } from "utils/countryCurrency";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useList } from "context/demandLeadContext/list/listContext";

import { ControlledList } from "Components/Admin/UIComponents/ControlledForm";
import ControlledSelectionStyled from "./Inputs/ControlledSelectionStyled";
import ControlledTextfieldStyled from "./Inputs/ControlledTextfieldStyled";
import Title from "./Title";

import { purchaseType, financing, temporality } from "./options";

import useBuyInformationStyles from "./BuyInformationStyle";

function BuyInformation() {
  const classes = useBuyInformationStyles();
  const {
    state: { lead, profiling },
  } = useDemandLead();

  const {
    state: { financialTypes },
  } = useList();

  return (
    <>
      <Title text="Información de compra" />
      <ControlledSelectionStyled
        compressed
        options={purchaseType}
        id="purchase_type"
        label="Tipo de compra"
        defaultValue={profiling?.purchase_type || 0}
      />
      <Grid container spacing={2}>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.financing?.id || 0}
            simple
            compressed
            fullWidth
            options={financialTypes || financing}
            label="Financiamiento"
            id="financing"
          />
        </Grid>

        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.temporality || 0}
            simple
            compressed
            fullWidth
            options={temporality}
            label="Temporalidad"
            id="temporality"
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.monthly_income || ""}
            startAdornment="$"
            placeholder="0,00"
            label="Ingresos mensuales"
            id="monthly_income"
            type="number"
          />
        </Grid>
        <Grid item>
          <ControlledList
            disableUnderline
            options={[
              {
                label: localeToCurrency[lead?.lead?.country],
                value: localeToCurrency[lead?.lead?.country],
              },
            ]}
            label="-"
            id="currency"
            classes={{
              itemContainer: classes.currencySelector,
            }}
            defaultValue={localeToCurrency[lead?.lead?.country]}
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.foot || ""}
            startAdornment="$"
            placeholder="0,00"
            label="Pie / Enganche / C. Inicial"
            id="foot"
            type="number"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default BuyInformation;
