import { makeStyles, Theme } from "@material-ui/core/styles";

const useCustomFileDropzone = makeStyles<Theme>((theme) => ({
  label: {
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
  },
  wrapper: {
    width: "100%",
  },
}));

export default useCustomFileDropzone;
