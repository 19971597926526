import { format } from "date-fns";

import currency from "@houm-com/formats/currency";

import { IUserBankAccountResponse } from "services/bankAccountService/types";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import { FormData } from "./types";

export const parseRefoundAmount = (
  reservationAmount: number,
  percentage: number,
  currencyCode: CurrencyCode
) => {
  const parsedPrice = (percentage / 100) * reservationAmount;
  return currency(parsedPrice, currencyCode, { maximumFractionDigits: 0 });
};

export const parseFormData = (
  reservationId: string,
  country: Locale,
  formData: FormData,
  bankAccountData: IUserBankAccountResponse,
  propertyId: number
) => ({
  reservationId,
  country,
  parsedData: {
    agreed_date: format(new Date(formData.agreedDate), "yyyy-MM-dd'T'00:00:00"),
    percentage: Number(formData.percentage),
    ...(formData?.description && { description: formData.description }),
    person_data: {
      person_id: bankAccountData.person_id,
      property_id: String(propertyId),
    },
  },
});

export const getTomorrowDay = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};
