import { Locale } from "models/Countries";

export interface FieldProp {
  label: (country: string) => string;
  disabledEdit: boolean;
  countries: Locale[];
}

const owner = {
  name: (): FieldProp => ({
    label: (country) => "Nombre(s)",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
  lastName: (): FieldProp => ({
    label: (country) => "Apellido paterno",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
  secondLastName: (): FieldProp => ({
    label: (country) => "Apellido materno",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
  email: (): FieldProp => ({
    label: (country) => "Correo electrónico",
    disabledEdit: true,
    countries: ["co", "mx", "cl"],
  }),
  phone: (): FieldProp => ({
    label: (country) => "Teléfono",
    disabledEdit: true,
    countries: ["co", "mx", "cl"],
  }),
  documentType: (): FieldProp => ({
    label: (country) => "Tipo de identificación:",
    disabledEdit: true,
    countries: ["co", "mx"],
  }),
  documentNumber: (): FieldProp => ({
    label: (country) => (country === "cl" ? "Rut" : "Número de identificación"),
    disabledEdit: true,
    countries: ["co", "mx", "cl"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    disabledEdit: false,
    countries: ["mx"],
  }),
  occupation: (): FieldProp => ({
    label: (country) => "Profesión",
    disabledEdit: false,
    countries: ["mx", "cl"],
  }),
  nationality: (): FieldProp => ({
    label: (country) => "Nacionalidad",
    disabledEdit: false,
    countries: ["mx", "cl"],
  }),
  civilStatus: (): FieldProp => ({
    label: (country) => "Estado Civil",
    disabledEdit: false,
    countries: ["cl", "mx"],
  }),
  conjugalRegime: (): FieldProp => ({
    label: (country) => "Régimen matrimonial",
    disabledEdit: false,
    countries: ["cl", "mx"],
  }),
};

const loiDetailBidder = {
  bidderPenaltyFee: (): FieldProp => ({
    label: (country) => "Multas definidas",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
  houmBidderFee: (): FieldProp => ({
    label: (country) => "Comisión Houm",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
};

const loiDetailOwner = {
  ownerPenaltyFee: (): FieldProp => ({
    label: (country) => "Multas definidas",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
  houmOwnerFee: (): FieldProp => ({
    label: (country) => "Comisión Houm",
    disabledEdit: false,
    countries: ["co", "mx", "cl"],
  }),
};

export default {
  owner,
  loiDetailBidder,
  loiDetailOwner,
};
