import { makeStyles, Theme } from "@material-ui/core";

const useDocumentsViewStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
  },
  item: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    width: "80%",
    marginLeft: theme.spacing(1),
  },
  sidePanel: {
    backgroundColor: theme.palette.divider,
    width: "fit-content",
    maxWidth: "20%",
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
  },
  docContainer: {
    display: "flex",
    justifyContent: "center",
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  pdfOptions: {
    backgroundColor: "#FFCBBF",
  },
  spotlightImage: {
    display: "inline-block",
    minWidth: "100%",
    "& img": {
      minWidth: "100%",
    },
    "& $iiz__zoom-img--visible": {
      minWidth: "200%",
    },
  },
  pageInput: {
    width: 50,
    height: 20,
  },
  downloadButton: {
    textTransform: "none",
    borderRadius: 25,
    fontWeight: "bold",
    width: 200,
  },
  selectPdf: {
    cursor: "pointer",
  },
  fileError: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
}));

export default useDocumentsViewStyles;
