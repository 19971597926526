/* eslint-disable camelcase */

import {
  HoumOwner,
  InvesmentType,
  PropertyRentalStatus,
  PropertySaleStatus,
} from "models/Property";
import { GeocoderResult } from "models/googleMaps";
import { CountryUpperCase, Locale } from "models/Countries";
import { PhoneInput } from "./PhoneInput";
import { OrientationOptionsTypes } from "utils/orientation";
import { DocumentType } from "./Document";
import { AddressDetails } from "models/Address";

export interface IAddress {
  prevSearch: string;
  street: string;
  streetNumber: string;
  district: string;
  region: string;
  country: string;
  lat: number;
  lng: number;
  commune: string;
  neighborhood: string;
}
export interface IUserAutoComplete {
  country?: string;
  document?: string | null;
  document_type?: string | DocumentType;
  email?: string;
  hashed_id?: string;
  id?: number | string;
  last_name?: string;
  name?: string;
  phone?: string;
  role?: string;
  rut?: string;
  second_last_name?: string;
  company?: number | string;
}

export interface IChildComponentRef {
  getValue: () => Object;
  setValue: (val: any) => void;
  validate: () => boolean;
}

export interface IDirectionFields {
  address: IAddress;
  directionFields: {
    numero_depto: string;
    street_number: string;
    type: string;
    neighborhood_id?: number;
    comuna?: string;
    second_line_address?: string;
  };
}

export interface IPropertyDetailsFields {
  terraza?: boolean;
  curtain_hanger?: boolean;
  calefaccion?: boolean;
  kitchen_is_furnished?: boolean;
  kitchen_daily_dining_room?: boolean;
  gym?: boolean;
  piscina?: boolean;
  condominio?: boolean;
  bodega?: boolean;
  amoblado?: boolean;
  mascotas?: boolean;
}

export interface IAmenitiesFields {
  has_swimming_pool: boolean;
  has_gym: boolean;
  has_roof_garden: boolean;
  has_patio: boolean;
  has_playground: boolean;
  has_all_day_vigilance: boolean;
  has_party_room: boolean;
  has_games_room: boolean;
  has_bbq_area: boolean;
  has_laundry: boolean;
  has_elevator: boolean;
  has_concierge: boolean;
  has_pet_area: boolean;
  has_gourmet_space: boolean;
  has_playroom: boolean;
}
export interface IPriceFields {
  tipo_moneda_venta?: string;
  currency?: string;
  valor_venta?: number;
  price?: number;
  // rent
  tipo_moneda?: string;
  currency_code?: string;
  valor: number;
  promotional_price: number;
  promotion_months: number;
}
export interface IPipedriveFields {
  deal_id?: string;
  pipedrive_id?: string;
}
export interface IMainFeaturesFields {
  m_terreno: number;
  m_construidos: number;
  stratus: number;
  dormitorios: number;
  en_suite: number;
  banos: number;
  half_baths: number;
  servicios: number;
  estacionamientos: number;
  bodega: boolean;
  has_balcony: boolean;
  has_roof_garden: boolean;
  calefaccion: boolean;
  curtain_hanger: boolean;
  furnished: string;
  amoblado: 1 | 0;
  terraza: 1 | 0;
  has_extensions: string;
  mascotas: boolean;
  gc?: number;
  antiquity?: number;
  orientacion: string;
}

interface Id {
  id?: number;
}

interface PropertyStatus {
  status?: { id?: number; name?: string };
  priority?: any;
}

interface PropertyPlan {
  plan_id?: number;
}

export interface HomeChecker {
  name?: string;
  photo?: string;
}

export type IProperty = Id & Property & PropertyStatus & PropertyPlan;
export interface Property {
  address?: string;
  second_line_address?: string;
  antiquity?: number;
  year_built?: number;
  is_new?: boolean;
  investment_type?: InvesmentType;
  taxes?: number;
  taxes_currency?: string;
  taxes_currency_code?: string;
  city?: string;
  comuna?: string;
  commune?: string;
  corretaje?: null | number;
  country?: CountryUpperCase;
  created_at?: Date | string;
  code?: string;
  deal_id?: string;
  for_rental?: boolean;
  for_sale?: boolean;
  is_favorite?: boolean;
  keys_info?: string;
  lessor?: null | any;
  neighborhood?: any;
  numero_depto?: string;
  photos?: any[];
  plan?: any;
  price?: number;
  property_details?: PropertyDetails[];
  home_checker_details?: HomeCheckerDetailsSnakeCase[];
  homechecker?: HomeChecker;
  houm_owner?: HoumOwner;
  propietary_comment?: string;
  published?: boolean;
  region?: string;
  route?: any; // deprecated
  state?: string;
  stratus?: number | null;
  street_number?: string;
  apartment_floor?: number;
  type?: string;
  uid?: string;
  updated_at?: Date;
  user?: IUserAutoComplete;
  video_url?: any;
  vitruvio_max?: number;
  vitruvio_min?: number;
  vitruvio_suggest?: number;
  sales_details?: SalesDetails & SalesDetailsStatus;
  association_amenities?: AssociationAmenities;
  pin?: string;
  lessor_brokerage_commission?: number;
  is_iva_responsible?: boolean;
  administration_email?: string;
  administration_phonenumber?: string;
}

interface SalesDetailsStatus {
  status?: PropertySaleStatus;
  priority?: string;
  price?: number;
}
export interface SalesDetails {
  brokerage_commission?: number;
  currency?: string;
  currency_code?: string;
  description?: string;
  first_available_handover_date?: string;
  first_publication_date?: string;
  last_publication_date?: string;
  lost_reason?: string;
  property?: number;
  price?: number;
  recommended_price?: number;
  recommended_price_max?: number;
  recommended_price_min?: number;
  external_description?: string;
}
export interface PropertyDetails {
  status?: PropertyRentalStatus;
  amoblado?: number;
  apartment_type?: string;
  banos?: number;
  bodega?: boolean;
  calefaccion?: boolean;
  closet?: string;
  condominio?: boolean;
  curtain_hanger?: boolean;
  currency_code?: string;
  dormitorios?: number;
  estacionamientos?: number;
  environments?: number;
  en_suite?: number;
  external_description?: string;
  first_available_handover_date?: string;
  furnished?: "non" | "fully" | "partially";
  gc?: number;
  gym?: boolean;
  has_air_conditioning?: boolean;
  half_baths?: number;
  has_balcony?: boolean;
  has_clothing_area?: boolean;
  has_laundry_room?: boolean;
  has_roof_garden?: boolean;
  has_water_tank?: boolean;
  hot_water_system?: "thermal_store" | "boiler" | "califont" | "undefined";
  house_extension?: "none" | "regularized" | "in_process" | "unregularized";
  id?: number;
  is_construction_in_gray?: boolean;
  is_common_expenses_included?: boolean;
  is_renewed?: boolean;
  kitchen_daily_dining_room?: boolean;
  kitchen_is_furnished?: boolean;
  latitud?: number;
  laundry_capacity?: boolean;
  loggia_size?: number;
  longitud?: number;
  m_construidos?: number;
  m_terreno?: number;
  mascotas?: boolean;
  minisplits?: number;
  observaciones?: string;
  observaciones_venta?: string;
  orientacion?: OrientationOptionsTypes;
  orientation?: string;
  parking?: boolean;
  parking_numbers?: string;
  parking_type?: string;
  piscina?: boolean;
  promotion_months?: number;
  promotional_price?: number;
  public_services_expenses?: number;
  servicios?: number;
  terrace_size?: number;
  terraza?: number;
  tipo_moneda?: string;
  tipo_moneda_venta?: string;
  ubicacion?: string;
  uid?: string;
  valor?: number;
  valor_venta?: number;
  vende_id?: number;
  warehouse_number?: string;
}

export interface AssociationAmenities {
  association_name?: string;
  floors?: number;
  guest_parking_spaces?: number;
  has_all_day_vigilance?: boolean;
  has_bbq_area?: boolean;
  has_cinema?: boolean;
  has_concierge?: boolean;
  has_elevator?: boolean;
  has_games_room?: boolean;
  has_gourmet_space?: boolean;
  has_gym?: boolean;
  has_laundry?: boolean;
  has_party_room?: boolean;
  has_patio?: boolean;
  has_pet_area?: boolean;
  has_playground?: boolean;
  has_playroom?: boolean;
  has_roof_garden?: boolean;
  has_sauna?: boolean;
  has_steam_room?: boolean;
  has_swimming_pool?: boolean;
  property?: number;
}

export interface SharedPropertyForm {
  neighborhood: { value: number; label: string } | Neighborhood;
  address_object: GeocoderResult | AddressDetails;
  orientacion_object: { value: number; label: string };
  custom_commune: { value: number; label: string };
  has_private_roof_garden: boolean;
  has_roof_garden_amenities: boolean;
  coordinates: [lng: number, lat: number];
  project_delivery_month?: number;
  project_delivery_year?: number;
}

export type ModifyPropertyForm = Property &
  PropertyDetails &
  SalesDetails &
  AssociationAmenities &
  SharedPropertyForm;

export interface CreatePropertyForm
  extends Property,
    PropertyDetails,
    SalesDetails,
    AssociationAmenities,
    SharedPropertyForm,
    IPipedriveFields {
  neighborhood: { value: number; label: string };
}

export interface ParsedAddress {
  prevSearch: string;
  neighborhood: string;
  city: string;
  street: string;
  streetNumber: string;
  commune: string;
  region: string;
  country: string;
  lat: number;
  lng: number;
  latitude: string;
  longitude: string;
}

export interface SimilarProperty {
  address: string;
  association_amenities: { association_name: null | string; floors: number };
  city: string | null;
  comuna: string;
  created_at: string;
  half_baths: number | null;
  id: number;
  last_publication_date: string;
  neighborhood: Neighborhood;
  numero_depto: string;
  photos: Photo[];
  price: Price[];
  priority: number;
  property_details: PropertyDetails[];
  sales_details?: Record<string, string>;
  region: string;
  street_number: string;
  status?: string;
  type: "departamento" | "casa";
  uid: string;
  unit_types: [];
  user: { role: "propietary" | "property_broker" | "real_estate_developer" | string };
}
interface Neighborhood {
  code: string;
  commune: string;
  coordinates: string;
  country: CountryUpperCase;
  country_code: Locale;
  id: number;
  latitude: number;
  longitude: number;
  neighborhood: string;
  operative: boolean;
  polygon: null | number;
  redirect_to: null | string;
  region: string;
}

interface Photo {
  created_at: string;
  featured: boolean;
  filename: string;
  id: string;
  order: number;
  updated_at: string;
  url: string;
}

export interface Price {
  value: number;
  currency: PriceCurrencyTypes;
}

export type PriceCurrencyTypes = "Pesos" | "U.F." | "CLP" | "UF";
export enum CurrencyByMarket {
  RENT = "tipo_moneda",
  SALE = "tipo_moneda_venta",
}

export interface IHomeCheckerDetails {
  administrationEmail?: string;
  administrationPhone?: PhoneInput;
  buildingAdministration?: string;
  concierge?: boolean;
  conciergeEmail?: string;
  conciergeName?: string;
  conciergePhone?: PhoneInput;
  hasHoumBox?: boolean;
  moveInPolicy?: string;
  moveInPolicyPhoto?: string;
  inhabitedProperty?: boolean;
  inhabitedPropertyDescription?: string;
  thirdPartyCoordination?: boolean;
  thirdPartyCoordinationDescription?: string;
  thirdPartyCoordinationPhone?: PhoneInput;
}

export interface HomeCheckerDetailsSnakeCase {
  administration_email?: string;
  administration_phone?: string;
  building_administration?: string;
  concierge?: boolean;
  concierge_email?: string;
  concierge_name?: string;
  concierge_phone?: string;
  has_houm_box?: boolean;
  move_in_policy?: string;
  move_in_policy_photo?: string;
  inhabited_property?: boolean;
  inhabited_property_description?: string;
  third_party_coordination?: boolean;
  third_party_coordination_description?: string;
  third_party_coordination_phone?: string;
}

export interface AutoProfilePropertyParsed {}

export interface FormattedAddress {
  lat: number;
  lng: number;
  city: string;
  region: string;
  street?: string;
  commune: string;
  country: string;
  colonies?: string;
  prevSearch: string;
  neighborhood: string;
  streetNumber?: string;
}

export interface RawAddress {
  types: string[];
  geometry: Geometry;
  html_attributions: any[];
  formatted_address: string;
  address_components: AddressComponent[];
}

interface AddressComponent {
  types: string[];
  long_name: string;
  short_name: string;
}

interface Geometry {
  location: Location;
  viewport: Viewport;
}

interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  north: number;
  south: number;
  west: number;
  east: number;
}
