import { TextField } from "@material-ui/core";

import useRenewalCustomInputStyles from "./RenewalCustomInputStyles";

interface Props {
  id: any;
  name: string;
  placeholder?: string;
  setValue: any;
  value: string | number;
  title: string;
  type?: string;
  valid: boolean;
}

function RenewalCustomInput({
  id,
  placeholder = "",
  name,
  setValue,
  value,
  title,
  type,
  valid,
}: Props) {
  const classes = useRenewalCustomInputStyles();
  if (!valid) return <div />;
  return (
    <div className={classes.inputContainer}>
      <h6 className={classes.title}>{title}</h6>
      <TextField
        id={id}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        name={name}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        autoComplete="off"
        type={type}
        InputProps={{
          classes: {
            notchedOutline: classes.noBorder,
            input: classes.customTextField,
          },
        }}
      />
    </div>
  );
}

export default RenewalCustomInput;
