import contractFields from "context/closureFormContext/closureFormFields";
import { numberWithCommas } from "utils";

import { ValueProps, filter, ValueEntry } from "./utils";

const AdminValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.adminstration[valueKey];
  if (filter(displayLabel, isAdmin, country)) return null;

  let displayValue;
  if (value) {
    switch (valueKey) {
      case "hasHoumCleaning":
        displayValue = value === "1" ? "Sí" : "No";
        break;
      case "cleaningAmount":
        displayValue = `$${numberWithCommas(value)}`;
        break;
      default:
        displayValue = null;
    }
  } else {
    displayValue = null;
  }
  return <ValueEntry label={displayLabel().label(country)} value={displayValue} />;
};

export default AdminValue;
