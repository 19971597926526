import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { format } from "date-fns";

import { Grid } from "@material-ui/core";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import {
  ControlledList,
  ControlledOptions,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { yupResolver } from "@hookform/resolvers/yup";

import useContractFormStyles from "./ContractFormStyles";
import { contractSchema } from "../../utils/validationSchema";
import useAppraisers from "../../hooks/useAppraisers";
import useInitContract from "../../hooks/useInitContract";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { FormData, ITenant } from "../../utils/types";

interface Props {
  tenants: ITenant[];
  applicantId: number;
}

const ContractForm = ({ tenants, applicantId }: Props) => {
  const { mutate, isLoading } = useInitContract();
  const { appraisersData, appraisersLoading } = useAppraisers();
  const classes = useContractFormStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(contractSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => {
    const mainAvalValue = tenants.filter((item) => item.type === "aval")[dataForm.mainAval];
    const data = {
      applicant_id: applicantId,
      init_date_proposal: format(new Date(dataForm.initDate), "yyyy-MM-dd"),
      signature_date_proposal: format(new Date(dataForm.signatureDate), "yyyy-MM-dd"),
      tenants: tenants.filter((item) => item.type !== "aval"),
      main_tenant: Number(dataForm.mainTenant),
      habitants_description: dataForm.habitants,
      appraiser_id: dataForm.appraiser,
      ...(dataForm.mainAval && { main_aval: Number(mainAvalValue) }),
    };
    mutate(data);
  };

  if (appraisersLoading) return <Spinner />;

  const getTenantsParsed = (tenants: ITenant[]) =>
    tenants.map((item, index) => ({
      value: index,
      label: `${item.name}`,
    }));

  const tenantFilterAval = tenants.filter((item) => item.type !== "aval");

  const tenantAval = tenants.filter((item) => item.type === "aval");

  return (
    <FormProvider {...methods}>
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ControlledKeyboardDatePicker
              id="initDate"
              label="Fecha Inicio Contrato"
              defaultValue={new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
              }}
            />
            <ControlledKeyboardDatePicker
              id="signatureDate"
              label="Fecha Firma"
              defaultValue={new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
              }}
            />
            <ControlledOptions
              id="mainTenant"
              label="Selecciona el arrendatario principal"
              defaultValue={0}
              options={getTenantsParsed(tenantFilterAval)}
            />
            {getTenantsParsed(tenantAval).length > 0 && (
              <ControlledOptions
                id="mainAval"
                defaultValue={0}
                label="Selecciona el codeudor principal"
                options={getTenantsParsed(tenantFilterAval)}
              />
            )}
            <ControlledTextField
              id="habitants"
              label="¿Cuántos vivirán en la propiedad?"
              textFieldProps={{ placeholder: "2 Adultos y 2 niños" }}
              defaultValue=""
            />
            <ControlledList
              id="appraiser"
              label="¿Quién mostró la propiedad?"
              options={appraisersData}
              classes={{
                label: classes.selectionLabel,
                container: classes.selectionContainer,
              }}
              disableUnderline
            />
          </Grid>
          <Grid item>
            <DesignSystemButton
              label="Enviar Postulante(s) para aprobación"
              type="submit"
              size="small"
              variant="primary"
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ContractForm;
