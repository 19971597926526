import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Grid from "@material-ui/core/Grid";

import useCreateCodebtor from "Components/Admin/administrator/applicants/hooks/useCreateCodebtor";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";
import UserFormFields from "Components/Admin/UIComponents/UserFormFields";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useDocumentTypeList, useFormNotifications } from "hooks";
import { Locale } from "models/Countries";
import { localeToCountry } from "utils/countryCode";

import { userFormSchema, requiredText } from "../../utils/validationSchema";
import useCodebtorFormStyles from "./CodebtorFormStyles";
import { parseFormData } from "../../utils/utils";
import { IFormData } from "../../utils/types";

interface Props {
  country: Locale;
  applicantId: string;
  propertyId: number;
  handleModal: () => void;
}

const CodebtorForm = ({ country, applicantId, propertyId, handleModal }: Props) => {
  const { mutate, isLoading } = useCreateCodebtor(handleModal);
  const { documentTypeList, loading: loadingDocumentType } = useDocumentTypeList(
    localeToCountry[country]
  );
  const classes = useCodebtorFormStyles();

  const methods = useForm<IFormData>({
    resolver: yupResolver(userFormSchema(documentTypeList, country)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    const parsedData = parseFormData(data, country, applicantId, propertyId);
    mutate(parsedData);
  };

  return (
    <Loading loading={loadingDocumentType}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
          <Grid container alignItems="flex-start" justifyContent="center">
            <UserFormFields country={country} documentTypeList={documentTypeList} />
            <ControlledSelection
              id="type"
              label="Tipo"
              defaultValue="lessee"
              options={[
                { value: "lessee", label: "Coarrendatario" },
                { value: "aval", label: "Codeudor" },
              ]}
              classes={{
                label: classes.selectionLabel,
                container: classes.selectionContainer,
              }}
              buttonClassName={classes.buttonSelection}
            />
            <Grid item className={classes.buttonContainer}>
              <DesignSystemButton
                label="Crear coarrendatario/codeudor"
                size="small"
                type="submit"
                variant="primary"
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Loading>
  );
};

export default CodebtorForm;
