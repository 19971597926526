import { useMemo } from "react";
import { Paper, Typography, Divider, SvgIcon, Fab } from "@material-ui/core";
import { SimilarProperty } from "models";
import { Locale } from "models/Countries";
import { marketplaceLink } from "utils/marketplaceLink";
import capitalize from "lodash/capitalize";

import useStyles from "./MarketplacePropertyCardStyles";
import { isExternalBroker } from "utils/userRoles";
import { getPropertyTitle } from "utils/titles";

import { ReactComponent as SurfaceSVG } from "assets/img/superficie.svg";

import { ReactComponent as BedSVG } from "assets/img/bed.svg";

import { ReactComponent as BathSVG } from "assets/img/bath.svg";

import { ReactComponent as TrashSVG } from "assets/icons/App/trash.svg";

import { formatCurrency } from "utils/formatCurrency";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { normalizeCommuneName, normalizeRegionName } from "utils/normalizer";
import clx from "classnames";

export type DealType = "rental" | "sale";
interface Props {
  property: SimilarProperty;
  locale: Locale;
  deal: DealType;
  isDeletable?: boolean;
  isCarouselCard?: boolean;
  actionDelete?: (propertyId: number) => void;
  scheduleButton?: boolean;
}

function MarketplacePropertyCard({
  property,
  locale,
  deal,
  isDeletable,
  actionDelete,
  isCarouselCard = false,
  scheduleButton,
}: Props) {
  const classes = useStyles({ isCarouselCard });
  const propertyTitle = useMemo(() => getPropertyTitle(property, locale), [property, locale]);

  const dealTitle = useMemo(() => (deal === "rental" ? "Arriendo" : "Venta"), [deal]);

  const propertyDetails = property.property_details[0];

  const isAvailableProperty = useMemo(
    () =>
      ["published", "republished"].includes(
        property.status || propertyDetails?.status || property.sales_details?.status
      ),
    [property.status, propertyDetails?.status, property.sales_details?.status]
  );

  const linkProperty = marketplaceLink.property(locale, deal, {
    type: property.type,
    commune: normalizeCommuneName(property.comuna),
    region: normalizeRegionName(property.region),
    id: property.id,
  });

  const goToProperty = () => {
    window.open(linkProperty);
  };

  const handleClickSchedule = () => {
    window.open(`${linkProperty}?toSchedule=true`, "_blank");
  };

  return (
    <Paper className={classes.cardContainerFavorite}>
      <div className={classes.container}>
        <div>
          {isDeletable && (
            <Fab
              size="small"
              onClick={() => actionDelete(property.id)}
              className={classes.iconButtonOverImage}>
              <TrashSVG />
            </Fab>
          )}
          <img
            src={property.photos[0]?.url}
            alt="Imagen de la propiedad"
            className={classes.coverPhoto}
            onClick={isAvailableProperty && goToProperty}
          />
          {!isAvailableProperty && (
            <div className={classes.overlayContainer}>
              <Typography className={classes.overlayTitle}>¡Propiedad no disponible! 💔</Typography>
              <Typography className={classes.overlayText}>
                Lo sentimos, esta propiedad <strong>ha sido reservada o despublicada.</strong>
              </Typography>
            </div>
          )}
        </div>
        <div onClick={isAvailableProperty && goToProperty}>
          <div className={classes.headerContainer}>
            <Typography className={classes.secondary}>{capitalize(property.type)}</Typography>
            {!isExternalBroker(property.user?.role) && (
              <div
                className={clx({
                  [classes.houmPropertyBadge]: isAvailableProperty,
                  [classes.houmPropertyBadgeDisabled]: !isAvailableProperty,
                })}>
                <Typography
                  className={clx({
                    [classes.houmPropertyText]: isAvailableProperty,
                    [classes.houmPropertyTextDisabled]: !isAvailableProperty,
                  })}>
                  Exclusivo Houm
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.titleContainer}>
            <Typography
              className={clx({
                [classes.title]: isAvailableProperty,
                [classes.titleDisabled]: !isAvailableProperty,
              })}>
              {propertyTitle}
            </Typography>
            <Typography className={classes.secondary}>{property.comuna}</Typography>
          </div>
          <div className={classes.priceContainer}>
            <Typography
              className={clx({
                [classes.priceTitle]: isAvailableProperty,
                [classes.priceTitleDisabled]: !isAvailableProperty,
              })}>
              {dealTitle}
            </Typography>
            <Typography
              className={clx({
                [classes.priceTitle]: isAvailableProperty,
                [classes.priceTitleDisabled]: !isAvailableProperty,
              })}>
              {property.price &&
                formatCurrency(property.price[0].currency, property.price[0].value)}
            </Typography>
          </div>
          <div className={classes.footer}>
            <Divider flexItem className={classes.divider} />
            <div className={classes.propertyDetailsContainer}>
              <div
                className={clx({
                  [classes.iconAndDetail]: isAvailableProperty,
                  [classes.iconAndDetailDisabled]: !isAvailableProperty,
                })}>
                <SvgIcon fontSize="inherit" viewBox="0 0 21 21">
                  <SurfaceSVG />
                </SvgIcon>
                <Typography className={classes.propertyDetailsText}>
                  {`${property.property_details[0].m_terreno} m² total |
              ${property.property_details[0].m_construidos} m² útil`}
                </Typography>
              </div>
              <div className={classes.rightFooter}>
                <div
                  className={clx({
                    [classes.iconAndDetail]: isAvailableProperty,
                    [classes.iconAndDetailDisabled]: !isAvailableProperty,
                  })}>
                  <SvgIcon fontSize="inherit" viewBox="0 0 21 17">
                    <BedSVG />
                  </SvgIcon>
                  <Typography className={classes.propertyDetailsText}>
                    {property.property_details[0].dormitorios}
                  </Typography>
                </div>
                <div
                  className={clx({
                    [classes.iconAndDetail]: isAvailableProperty,
                    [classes.iconAndDetailDisabled]: !isAvailableProperty,
                  })}>
                  <SvgIcon fontSize="inherit" className={classes.margin}>
                    <BathSVG />
                  </SvgIcon>

                  <Typography className={classes.propertyDetailsText}>
                    {property.property_details[0].banos}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        {scheduleButton && (
          <div className={classes.containerButtonSchedule}>
            <DesignSystemButton
              label="Agendar visita"
              variant="primary"
              disabled={!isAvailableProperty}
              size="small"
              onClick={() => handleClickSchedule()}
            />
          </div>
        )}
      </div>
    </Paper>
  );
}

export default MarketplacePropertyCard;
