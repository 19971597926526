import { Box, Typography } from "@material-ui/core";

import { ReactComponent as ClipIcon } from "assets/icons/clip.svg";

import useNoVoucherStyles from "./NoVoucherStyles";

const NoVoucher = () => {
  const classes = useNoVoucherStyles();

  return (
    <Box className={classes.noVoucherRoot}>
      <ClipIcon className={classes.icon} />
      <Typography>Sin comprobante de pago</Typography>
    </Box>
  );
};

export default NoVoucher;
