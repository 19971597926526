import moment from "moment";

import { Grid } from "@material-ui/core";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";

import useInterestedPeopleStyles from "./InterestedPeopleStyles";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function InterestedPeople({ property }: Props) {
  const classes = useInterestedPeopleStyles();

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
  };

  const idConfig: GridColDef = {
    field: "",
    resizable: false,
    flex: 1,
    filterable: false,
    hide: false,
  };

  const columns: GridColumns = [
    {
      ...idConfig,
      field: "created_at",
      headerName: "Fecha",
      renderCell: ({ row }: GridCellParams) => moment(row.created_at).format("LLLL"),
    },
    {
      ...columnsConfig,
      field: "name",
      headerName: "Nombre",
      renderCell: ({ value }: GridCellParams) => value,
    },
    {
      ...columnsConfig,
      field: "origin",
      headerName: "Origen",
      renderCell: ({ value }: GridCellParams) => value,
    },
    {
      ...columnsConfig,
      field: "phone",
      headerName: "Teléfono",
      renderCell: ({ value }: GridCellParams) => value,
    },
    {
      ...columnsConfig,
      field: "email",
      headerName: "Email",
      sortable: false,
      renderCell: ({ value }: GridCellParams) => value,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.collapse}>
          <CustomDataGrid
            className={classes.table}
            rowCount={property?.portal_applicants.length}
            columns={columns}
            rows={property?.portal_applicants}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default InterestedPeople;
