export const filterInitialValues = {
  company: null,
  businessType: null,
  executive: null,
  error: null,
  property: null,
  propertiesId: [],
  portal: null,
  portalPlan: null,
  status: null,
};

export interface ExternalPortalPublicationsTableFilterTypes {
  businessType?: string;
  company?: string;
  executive?: string;
  error?: string;
  property?: number | string;
  portal?: string;
  portalPlan?: string;
  status?: string;
}
