import { makeStyles, Theme } from "@material-ui/core";

const useLandlordStyles = makeStyles((theme: Theme) => ({
  rootContainer: {
    padding: theme.spacing(0, 3),
  },
  tabContainer: {
    marginLeft: theme.spacing(-3),
  },
  filterIcon: {
    stroke: "inherit",
    strokeWidth: 0.8,
  },
  filter: {
    height: "fit-content",
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    textTransform: "none",
    minHeight: 40,
    borderRadius: theme.spacing(6),
    border: `$1px solid ${theme.palette.grey["500"]}`,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(3, 0),
  },
  landlordHeader: {
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useLandlordStyles;
