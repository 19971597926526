import ModalDialog from "@houm-com/ui/ModalDialog";

interface Props {
  isOpen: boolean;
  handleOpen: () => void;
  cancelDesertionProcess: () => void;
}

const CancelDesertionProcessModal = ({ isOpen, handleOpen, cancelDesertionProcess }: Props) => (
  <ModalDialog
    isOpen={isOpen}
    title="¿Seguro que quieres cancelar el proceso de término de contrato?">
    <ModalDialog.Actions
      onClose={handleOpen}
      onSubmit={() => {
        cancelDesertionProcess();
        handleOpen();
      }}
    />
  </ModalDialog>
);

export default CancelDesertionProcessModal;
