import { ComponentProps } from "react";

import { Timezones } from "models/Countries";
import { parseDateToString } from "utils/datetime";

import VisitCard from "../VisitCard";

interface Props {
  currentStep: string;
  rentDate?: string;
  salesDate?: string;
  tz: Timezones;
}

const rentField = ({
  currentStep,
  rentDate,
  tz,
}: Props): ComponentProps<typeof VisitCard>["items"] => [
  {
    title: "Fecha arriendo/renta:",
    formKey: "handOverdate",
    editable: currentStep === "editable",
    content: parseDateToString(rentDate, tz),
    position: "horizontal",
    editableMode: "date",
  },
];

const saleField = ({
  currentStep,
  salesDate,
  tz,
}: Props): ComponentProps<typeof VisitCard>["items"] => [
  {
    title: "Fecha venta:",
    formKey: "handOverdateSale",
    editable: currentStep === "editable",
    content: parseDateToString(salesDate, tz),
    position: "horizontal",
    editableMode: "date",
  },
];

const rentSaleFields = ({
  currentStep,
  salesDate,
  rentDate,
  tz,
}: Props): ComponentProps<typeof VisitCard>["items"] => [
  ...rentField({ currentStep, tz, rentDate }),
  ...saleField({ currentStep, tz, salesDate }),
];

export default rentSaleFields;
