import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { UserResponse } from "models/User";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { IPlan } from "models/Plan";
import { alert } from "libs/alerts";

import { getIsBasicPlanFlag, getIsLegacyPlanFlag, parseData } from "../utils/utils";
import { PaymentOrderData } from "../utils/types";

const usePaymentOrder = (
  country: Locale,
  landlordContract: UserResponse,
  subscriptionId: number,
  propertyId: number,
  tenantContract: UserResponse,
  plan: IPlan | null,
  contractId: number,
  managementFee: string | number
) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const isBasicPlanFlag = getIsBasicPlanFlag(plan, country);
  const isLegacyPlanFlag = getIsLegacyPlanFlag(plan, country);

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: PaymentOrderData) => {
      const authToken = await getToken();
      const parsedData = parseData(
        formData,
        landlordContract,
        tenantContract,
        propertyId,
        subscriptionId,
        country,
        contractId,
        managementFee,
        isBasicPlanFlag,
        isLegacyPlanFlag
      );
      const response = await newPaymentService.createPaymentOrder(country, parsedData, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        alert.success({
          message: "Orden de pago creada",
          disclaimer: "La orden de pago se ha creado correctamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo crear la orden de pago",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default usePaymentOrder;
