import { useHistory, useLocation } from "react-router-dom";

import { StateType } from "../utils/types";

const useLocationData = () => {
  const history = useHistory();
  const { state: stateLocation } = useLocation<StateType>();

  if (!stateLocation) {
    history.push(`/admin/promotions`);
    return {
      country: undefined,
      citiesParsed: undefined,
      cities: undefined,
    };
  } else {
    const { cities, country } = stateLocation;

    const citiesParsed = cities.map((city) => city.name).join(", ");

    return {
      country,
      citiesParsed,
      cities,
    };
  }
};

export default useLocationData;
