/* eslint-disable import/prefer-default-export */
import moment from "moment";

import { UserApplication, UserSegmentation, UserVisit } from "models/User";
import { Country } from "models/Countries";
import applicantService from "services/applicantService";
import { parseAddress } from "utils/parseUserSegmentationData";
import propertyService from "services/propertyService";
import schedulesService from "services/schedulesService";
import api from "services/userService";
import {
  Dispatch,
  SET_LOADING,
  SET_USERS,
  SET_COUNTRY,
  SET_PROFILE,
  Action,
  UPDATE_APPLICATION,
  UPDATE_SEGMENTATION,
  UPDATE_VISITS,
} from "./usersTypes";

export const getUsers = async (
  pageSize,
  currentPage,
  mappedFilters,
  dispatch: Dispatch,
  authToken
) => {
  dispatch({ type: SET_LOADING, loading: true });
  const response = await api.getUsersList(pageSize, currentPage, mappedFilters, authToken);
  if (response) {
    const { results, count: resultCount } = response.data;

    const users = results.map((r) => ({
      id: r.id,
      firstName: r.name,
      lastNames: `${r.last_name} ${r.second_last_name}`,
      email: r.email,
      phone: r.phone,
      profile: r.hashed_id,
      createdAt: r.created_at ? moment(r.created_at).format("YYYY-MM-DD") : null,
    }));
    dispatch({ type: SET_USERS, payload: { users, resultCount } });
  }
};

export const setCountry = (country: Country): Action => ({
  type: SET_COUNTRY,
  country,
});

const parseSearchReason = (investing: boolean) => (investing ? "Invertir" : "Habitar");

const parseUserSegmentation = (response): UserSegmentation => ({
  date: response.date,
  propertyId: response.property,
  dealType: response.deal_type,
  wayOfContact: response.way_of_contact,
  wayToHelp: response.way_to_help,
  minPrice: response.min_price,
  maxPrice: response.max_price,
  propertyType: response.property_type,
  financing: response.financing_status,
  minSaving: response.min_monthly_saving,
  maxSaving: response.max_monthly_saving,
  minWage: response.min_wage,
  maxWage: response.max_wage,
  comments: response.comments,
  searchReason: parseSearchReason(response.is_investing),
});

const parseUserApplication = (response): UserApplication => ({
  id: response.id,
  propertyId: response.property,
  date: response.timestamp,
  status: response.status,
});

const visitStatus = (state: string): string => {
  const values = {
    Scheduled: "Agendada",
    Waiting: "Esperando",
    Confirmed: "Confirmada",
    Cancelled: "Cancelada",
    Done: "Visitada",
    Arrived: "Houmer llegó",
    Started: "Iniciada",
    "Not Initiated": "No iniciada",
  };
  return values[state];
};

const parseSchedule = (data): UserVisit => ({
  id: data.id,
  appraiser: data.appraiser.name,
  tag: visitStatus(data.status),
  date: data.begin_date,
  propertyId: data.prop.id,
  status: data.status,
});

export const getUser = async (userId: number, dispatch: Dispatch, authToken: string) => {
  dispatch({ type: SET_LOADING, loading: true });
  const response = await api.getUserAggregation(userId, authToken);
  if (response) {
    const result = response.data;
    const user = {
      id: result.id,
      firstName: result.name,
      lastNames: `${result.last_name} ${result.second_last_name}`,
      date: result.created_at,
      email: result.email,
      country: result.country,
      phone: result.phone,
      document: result.document,
      propertyId: result.property,
      segmentations: result.user_segmentation.map((segmentation) =>
        parseUserSegmentation(segmentation)
      ),
      applications: result.applications.map((application) => parseUserApplication(application)),
      visits: [],
    };
    dispatch({ type: SET_PROFILE, payload: user });
  }
};

export const getPropertyAddress = async (
  segmentation: UserSegmentation,
  index: number,
  dispatch: Dispatch,
  authToken: string
) => {
  const { data } = await propertyService.getByID(segmentation.propertyId, authToken);
  dispatch({
    type: UPDATE_SEGMENTATION,
    payload: {
      index,
      segmentation: { ...segmentation, address: parseAddress(data) },
    },
  });
};

export const getHoumer = async (
  application: UserApplication,
  index: number,
  dispatch: Dispatch,
  authToken: string
) => {
  const response = await applicantService.getAppraiser(application.id, authToken);
  if (response) {
    dispatch({
      type: UPDATE_APPLICATION,
      payload: {
        index,
        application: { ...application, houmer: response.data.name },
      },
    });
  }
};

export const getVisits = async (application: UserApplication, dispatch: Dispatch, authToken) => {
  const response = await schedulesService.getApplicantSchedules(application.id, authToken);
  if (response && response.data.schedules.length > 0) {
    const visits = response.data.schedules.map((schedule) => parseSchedule(schedule));
    dispatch({
      type: UPDATE_VISITS,
      payload: visits,
    });
  }
};
