export const titles = {
  cancelation: "¿Cancelar este agendamiento?",
  reschedule: "¿Deseas Reagendar?",
};

export const subtitle = {
  cancelation: "motivo de cancelación",
  reschedule: "motivo de reagendamiento",
};

export const buttonText = {
  cancelation: "Cancelar agendamiento",
  reschedule: "Reagendar",
};
