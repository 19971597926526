import { makeStyles, Theme } from "@material-ui/core";

const usePropertyStatisticsSummaryStyles = makeStyles((theme: Theme) => ({
  collapse: {
    padding: theme.spacing(3),
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 10,
    height: 125,
    backgroundColor: "#2F3469",
  },
  info: {
    fontSize: theme.typography.pxToRem(38),
    color: "white",
  },
  label: {
    color: "white",
  },
  homecheckerLabel: {
    color: theme.palette.grey[900],
  },
}));

export default usePropertyStatisticsSummaryStyles;
