import { useEffect, useState, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import classNames from "classnames";

import {
  ControlledPhoneInput,
  ControlledAutocomplete,
  OptionTypes,
} from "Components/Admin/UIComponents/ControlledForm";
import OptionButton from "Components/Admin/UIComponents/ControlledForm/OptionButton";
import { phoneValidationSchema } from "./phoneFormValidationSchema";
import { useToky } from "context/tokyContext/tokyContext";
import { TokySessionStatus } from "context/tokyContext/tokyTypes";
import { PhoneInput } from "models/PhoneInput";
import { dialNumbers } from "../utils/dialPhoneNumbers";
import useStyles from "./TokyPhoneFormStyles";

export interface PhoneProps {
  phoneTarget: PhoneInput;
  phoneCaller: string;
}

const tokySavedAgent = "lastAgentSelected";

function TokyPhoneForm() {
  const [blockCall, setBlockCall] = useState(false);
  const { state } = useToky();
  const { TokyClient, dialiedPhoneNumber, readyToCall, agentDids } = state;

  const methods = useForm<PhoneProps>({
    resolver: yupResolver(phoneValidationSchema),
  });

  const onSubmit: SubmitHandler<PhoneProps> = (data) => {
    const fromCaller = data?.phoneCaller;
    const toCaller = data?.phoneTarget?.parsedPhone;
    if (!TokyClient || !readyToCall || blockCall) return null;

    TokyClient.startCall({
      phoneNumber: toCaller,
      callerId: fromCaller,
    });
  };

  const handleOnDialButtonClick = (number: number): void => {
    const { country, phone } = methods.getValues("phoneTarget");
    const newPhoneInput = `${phone}${number}`;
    methods.setValue("phoneTarget", {
      country,
      phone: newPhoneInput as string,
    });
  };

  const isConnecting = state.tokySessionStatus === TokySessionStatus.CONNECTING;
  const isRinging = state.tokySessionStatus === TokySessionStatus.RINGING;
  const isOnActiveCall = state.tokySessionStatus !== TokySessionStatus.OFF;

  const handleEndCall = () => {
    if (!state.tokySession) return;
    setBlockCall(true);
    state.tokySession.endCall();
    setTimeout(() => setBlockCall(false), 1500);
  };

  const showcallStatusMessage = isConnecting || isRinging;
  const callStatusMessage = isConnecting ? "Conectando llamada..." : "Marcando...";

  const classes = useStyles({ showcallStatusMessage });

  useEffect(() => {
    methods.setValue("phoneTarget", dialiedPhoneNumber);

    const agentSaved = localStorage.getItem(tokySavedAgent);
    if (agentSaved) {
      methods.setValue("phoneCaller", JSON.parse(agentSaved));
    }
  }, [dialiedPhoneNumber]);

  const agentOptions = useMemo(
    () =>
      agentDids.map((agentDid) => ({
        label: agentDid.friendlyName,
        value: agentDid.number,
      })),
    [agentDids]
  );

  const handleChangeAgent = (val: OptionTypes) => {
    localStorage.setItem(tokySavedAgent, JSON.stringify(val));
  };

  return (
    <Box className={classes.formContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledPhoneInput id="phoneTarget" defaultValue={dialiedPhoneNumber} />
          <ControlledAutocomplete
            onChangeAction={handleChangeAgent}
            label="via numero"
            id="phoneCaller"
            options={agentOptions}
            labelClassName={classes.selectorLabel}
            textFieldProps={{
              InputProps: { disableUnderline: false },
              classes: { root: classes.root },
            }}
          />

          <Box className={classes.phoneDialNumbersContainer}>
            {dialNumbers.map((number) => (
              <OptionButton
                width="40px"
                key={`button-${number}`}
                round={false}
                onClick={() => handleOnDialButtonClick(number)}
                disabled={false}
                compressed={false}
                buttonClassName={
                  number === 0
                    ? classNames(classes.phoneDialNumber, classes.phoneDialLastNumber)
                    : classes.phoneDialNumber
                }>
                {number}
              </OptionButton>
            ))}
          </Box>
          <Box className={classes.callButtonContainer}>
            <Typography className={classes.callStatusMessage}>{callStatusMessage}</Typography>
            {isOnActiveCall ? (
              <MuiButton
                variant="contained"
                className={classNames(classes.callButton, classes.redBg)}
                onClick={handleEndCall}>
                Cortar
              </MuiButton>
            ) : (
              <MuiButton
                variant="contained"
                className={classNames(classes.callButton, classes.greenBg)}
                type="submit"
                disabled={!state.readyToCall}>
                Llamar
              </MuiButton>
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

export default TokyPhoneForm;
