import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";
import { useHistory } from "react-router-dom";

import {
  BackButton,
  NextButton,
} from "Components/Admin/administrator/properties/components/FormElements";
import { setProject, setSuccessModal } from "context/projectContext/projectActions";
import { useProject } from "context/projectContext/projectContext";
import useStyles from "./SuccessDialogStyles";

function SuccessDialog() {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = useProject();
  const { project, openSuccessModal } = state;
  const handleClose = () => {
    dispatch(setSuccessModal(false));
  };
  const handleRedirect = () => {
    const url = `/admin/properties/${project?.property?.uid}`;
    dispatch(setProject(null));
    history.push(url);
  };

  return (
    <Dialog open={openSuccessModal}>
      <DialogTitle>
        <div className={classes.dialogTitleContainer}>
          Proyecto ha sido creado satisfactoriamente
          <IconButton aria-label="close" onClick={handleClose}>
            <GridCloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {project && (
        <DialogContent>
          <p>ID: {project?.id}</p>
          <p>Region: {project?.property?.region}</p>
          <p>País: {project?.property?.country}</p>
          <p>Tipo de proyecto: {project?.property?.type}</p>
        </DialogContent>
      )}
      <DialogActions>
        <BackButton onClick={handleClose}> Cerrar </BackButton>
        <NextButton id="go-to-project" onClick={handleRedirect}>
          {" "}
          Ir a proyecto{" "}
        </NextButton>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessDialog;
