import { useState } from "react";
import { Locale } from "models/Countries";
import { parseLastPayment } from "../utils/parsers";

import useLandlordPayments from "./useLandlordPayments";

const useLastPaymentModal = (propertyId: number, country: Locale) => {
  const [open, setOpen] = useState(false);
  const { isLoading, error, data } = useLandlordPayments({
    propertyId,
    country,
  });
  const propertyPayment = data?.find((payment) => payment.propertyId === propertyId);

  const parsedData = parseLastPayment(propertyPayment);

  return {
    open,
    handleClose: () => setOpen(false),
    handleOpen: () => setOpen(true),
    isLoading,
    error,
    data: parsedData,
  };
};

export default useLastPaymentModal;
