import axios, { AxiosResponse } from "axios";
import { BACKEND_URL, REV_URL } from "env";
import {
  CalculatorResponse,
  SimilarPricesParams,
  SimilarExternalPropertiesResponse,
  MetricsByLocationResponse,
  MetricsParams,
} from "models/Calculator";
import { createHttp } from "services/helper";

const headers = {
  "Content-Type": "application/json",
};
const http = axios.create({
  baseURL: "https://services.houm.com",
  headers,
});

const marketplaceHttp = axios.create({ baseURL: BACKEND_URL, headers });

export default {
  valuate(data): Promise<AxiosResponse<CalculatorResponse>> {
    return http.post("/rentor/valuation", data);
  },
  async similarExternalProperties(
    params: SimilarPricesParams,
    token: string
  ): Promise<AxiosResponse<SimilarExternalPropertiesResponse>> {
    return createHttp(token, REV_URL).get("external-similar-properties", {
      params,
    });
  },
  getSimilarProperties(
    id: number,
    mode: "rental" | "sale",
    photos: number,
    propNumber: number
  ): Promise<any> {
    return marketplaceHttp.get(`properties/${id}/similar-properties`, {
      params: {
        mode,
        n_similar: propNumber,
        n_photos: photos,
      },
    });
  },
  async getSquareMeterPrice(
    params: MetricsParams,
    token: string
  ): Promise<AxiosResponse<MetricsByLocationResponse>> {
    return createHttp(token, REV_URL).get("metrics", {
      params,
    });
  },
};
