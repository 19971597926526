import { PropertyData } from "models/Property";

export enum ActionType {
  SET_UNIT,
  SET_PROPERTY,
  SET_PROPERTY_SERVICES,
  SET_SCHEDULED_VISITS,
  SET_UNITS,
  SET_EXTRA_STATES,
  SET_LOADING_UPLOAD,
  SET_UNIT_UPLOAD_MODAL,
  SET_UNIT_UPLOAD_ERRORS,
}

export interface MultifamilyUnit {
  id?: number;
  available?: boolean;
  state_description?: string;
  property?: Property;
  multifamily_houm_executive?: HoumExecutive;
}

export interface HoumExecutive {
  id?: number;
  full_name?: string;
}

export interface IUnitsUploadData {
  filename: string;
  multifamily: Number;
}

export interface UnitsUploadError {
  line: string;
  errors: string[];
}

export interface NewUnitsUploadForm {
  files: File[];
}

export interface Property {
  id?: number;
  uid?: string;
  apartment_floor?: number;
  houm_owner?: HoumExecutive;
  numero_depto?: string;
  property_details?: PropertyDetails[];
  video_url?: string;
  video360_url?: string;
}

export interface BasicServices {
  serviceType: string;
  company: string;
  customerNumber: string;
  id: number;
}

export interface PropertyDetails {
  bodega?: boolean;
  calefaccion?: boolean;
  currency_code?: string;
  curtain_hanger?: boolean;
  estacionamientos?: number;
  furnished?: string;
  gc?: number;
  has_air_conditioning?: boolean;
  hot_water_system?: string;
  is_renewed?: boolean;
  mascotas?: boolean;
  orientacion?: string;
  promotion_months?: number;
  valor?: number;
  promotional_price?: number;
}

export interface VisitEvent {
  start: string;
}

export interface ExtraStates {
  isFetchingAllUnits: boolean;
}

export interface SetProperty {
  type: typeof ActionType.SET_PROPERTY;
  property: PropertyData;
}

export interface SetPropertyServices {
  type: typeof ActionType.SET_PROPERTY_SERVICES;
  propertyServices: BasicServices[];
}

export interface SetUnits {
  type: typeof ActionType.SET_UNITS;
  units: MultifamilyUnit[];
}

export interface SetUnit {
  type: typeof ActionType.SET_UNIT;
  unit: MultifamilyUnit;
}

export interface SetScheduledVisits {
  type: typeof ActionType.SET_SCHEDULED_VISITS;
  scheduledVisits: VisitEvent[];
}

export interface SetExtraStates {
  type: typeof ActionType.SET_EXTRA_STATES;
  extraStates: ExtraStates;
}

export interface SetLoadingUpload {
  type: typeof ActionType.SET_LOADING_UPLOAD;
  loadingUpload: boolean;
}

export interface SetUnitsUploadModal {
  type: typeof ActionType.SET_UNIT_UPLOAD_MODAL;
  unitsUploadModal: boolean;
}

export interface SetUnitsUploadErros {
  type: typeof ActionType.SET_UNIT_UPLOAD_ERRORS;
  unitsUploadErrors: UnitsUploadError[];
}

export interface State {
  property?: PropertyData;
  propertyServices?: BasicServices[];
  units: MultifamilyUnit[];
  unit: MultifamilyUnit;
  scheduledVisits: VisitEvent[];
  extraStates: ExtraStates;
  loadingUpload: boolean;
  unitsUploadModal: boolean;
  unitsUploadErrors: UnitsUploadError[];
}

export type Action =
  | SetProperty
  | SetPropertyServices
  | SetScheduledVisits
  | SetUnits
  | SetUnit
  | SetExtraStates
  | SetLoadingUpload
  | SetUnitsUploadModal
  | SetUnitsUploadErros;

export type Dispatch = (action: Action) => void;
