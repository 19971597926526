import FilterTag from "./FilterTag";
import { setFilters, setTags } from "context/tagContext/tagActions";
import { useTag } from "context/tagContext/tagContext";

function FiltersBar() {
  const { state, dispatch } = useTag();
  return (
    state.filters.id && (
      <FilterTag
        filter={state.filters.id}
        onDelete={() => {
          dispatch(setFilters({ ...state.filters, id: "" }));
          dispatch(setTags({ ...state.tags, data: [] }));
        }}
      />
    )
  );
}

export default FiltersBar;
