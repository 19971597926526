import { format } from "date-fns";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { IExitProcessResponse } from "services/contractService/types";
import contractService from "services/contractService";
import {
  ControlledDatePickerOutline,
  ControlledTextField,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";
import {
  exitProcessAbandonmentType,
  exitProcessEvictionType,
  exitProcessRequestedByLandlordType,
  exitProcessRequestedByTenantType,
} from "models/Contract";

import { exitProcessValidationSchema, requiredText } from "./validationSchema";
import useExitProcessStartModalStyles from "./ExitProcessStartModalStyles";
import useExitProcess from "../../hooks/useExitProcess";
import ModalLayout from "../ModalLayout";
import {
  abandonmentLabel,
  evictionLabel,
  IContractExitProcessFormData,
  requestedByLandlordLabel,
  requestedByTenantLabel,
} from "../../utils/types";
import { parseFormData } from "../../utils/utils";

interface Props {
  isOpen: boolean;
  setClose: () => void;
  exitProcess: IExitProcessResponse;
}

const ExitProcessStartModal = ({ isOpen, setClose, exitProcess }: Props) => {
  const defaultDate = format(new Date(), "yyyy-MM-dd");
  const classes = useExitProcessStartModalStyles();

  const methods = useForm<IContractExitProcessFormData>({
    resolver: yupResolver(exitProcessValidationSchema),
    reValidateMode: "onSubmit",
  });

  const { errors } = methods.formState;
  useFormNotifications<IContractExitProcessFormData>({ errors, requiredText });

  const { mutate: createExitProcess, isLoading } = useExitProcess(
    contractService.beginExitProcess,
    "Se ha guardado la información del proceso de salida",
    "Error al comenzar proceso de salida",
    exitProcess,
    setClose
  );

  const onSubmit = (data: IContractExitProcessFormData) => {
    createExitProcess(parseFormData(data));
  };

  return (
    <ModalLayout
      open={isOpen}
      handleClose={() => setClose()}
      title="Proceso de salida"
      subtitle="Comenzar proceso de salida"
      withIcon={false}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <ControlledSelection
                id="requirementOrigin"
                label="Requerimiento por"
                defaultValue=""
                options={[
                  {
                    value: exitProcessRequestedByLandlordType,
                    label: requestedByLandlordLabel,
                  },
                  {
                    value: exitProcessRequestedByTenantType,
                    label: requestedByTenantLabel,
                  },
                  {
                    value: exitProcessAbandonmentType,
                    label: abandonmentLabel,
                  },
                  {
                    value: exitProcessEvictionType,
                    label: evictionLabel,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledDatePickerOutline
                id="estimatedDate"
                type="date"
                label="Seleccionar fecha estimada"
                defaultValue={defaultDate}
                outlineProps={{
                  size: "small",
                  fullWidth: false,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                label="Comentarios"
                id="comment"
                defaultValue=""
                textFieldProps={{
                  multiline: true,
                  minRows: 3,
                  InputProps: {
                    classes: {
                      root: classes.commentInput,
                    },
                  },
                  inputProps: {
                    maxLength: 1024,
                    placeholder: "Ingrese un comentario",
                  },
                }}
              />
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
              <DesignSystemButton
                isLoading={isLoading}
                label="Guardar"
                size="medium"
                variant="primary"
                type="submit"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ModalLayout>
  );
};

export default ExitProcessStartModal;
