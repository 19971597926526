import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import {
  getMultifamily,
  getMultifamilyPhotos,
  setCurrentId,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import { getTypologies } from "context/multifamilyContext/typology/typologyActions";
import { useGetToken } from "hooks";
import Header from "./Components/Header";
import Table from "./Components/table/Table";

import useStyles from "./indexStyles";

function MultiFamilyTable() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const { dispatch: multifamilyDispatch } = useMultifamily();
  const { dispatch: typologyDispatch } = useTypology();
  useEffect(() => {
    getToken().then((authToken) => {
      getMultifamily(multifamilyDispatch, parseInt(id), authToken);
      getMultifamilyPhotos(multifamilyDispatch, parseInt(id), authToken);
      multifamilyDispatch(setCurrentId(parseInt(id)));
      getTypologies(typologyDispatch, parseInt(id), authToken);
    });
  }, [id]);

  return (
    <div className={classes.container}>
      <Header />
      <Table />
    </div>
  );
}
export default MultiFamilyTable;
