import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  expandedButton: {
    width: "max-content",
  },
}));
