import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  closeButton?: boolean;
}
const useReviewInformationStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    minWidth: "33vw",
  },
  textContainer: {
    marginTop: ({ closeButton }) => (closeButton ? theme.spacing(3) : theme.spacing(1)),
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
  },
  subtitle: {
    display: "flex",
    whiteSpace: "nowrap",
    fontSize: theme.typography.pxToRem(18),
    maxWidth: "fit-content",
  },
  imageContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
}));
export default useReviewInformationStyles;
