import { useNewSubscriptionDisclaimerStyles } from "./NewSubscriptionDisclaimerStyles";

const NewSubscriptionDisclaimer = () => {
  const classes = useNewSubscriptionDisclaimerStyles();
  return (
    <div className={classes.container}>
      <span className={classes.animatedDot} />
      <p className={classes.disclaimer}>
        Ten en cuenta que hay una nueva suscripción. Da click en “Ir a nueva suscripción” para
        conocer más.
      </p>
    </div>
  );
};

export default NewSubscriptionDisclaimer;
