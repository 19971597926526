import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(32),
  },
  backButton: {
    border: "solid 0px #000",
    padding: theme.spacing(0, 1),
  },
}));
export default useHeaderStyles;
