import { makeStyles } from "@material-ui/core";

const useSearchbarStyles = makeStyles((theme) => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(2 / 8, 2),
    },
  },
  outline: {
    width: "100%",
    borderRadius: 50,
    color: theme.palette.grey["500"],
  },
}));
export default useSearchbarStyles;
