import { Box, Typography } from "@material-ui/core";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ReactComponent as BackIcon } from "assets/icons/arrows/chevron-back.svg";

import useHeaderStyles from "./HeaderStyles";

function Header() {
  const classes = useHeaderStyles();
  const goToBack = () => window.history.back();

  return (
    <Box className={classes.headerContainer}>
      <DesignSystemButton
        size="small"
        variant="tertiaryB"
        onClick={goToBack}
        label="Volver"
        Icon={<BackIcon />}
        buttonClassName={classes.backButton}
      />
      <Typography className={classes.title}>Propiedad</Typography>
    </Box>
  );
}

export default Header;
