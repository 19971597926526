import { useState, ReactNode, forwardRef, useEffect, useRef, useImperativeHandle } from "react";

import { FormControl } from "@material-ui/core";

import { OptionButton } from "./Buttons";
import HelperText from "./HelperText";
import Label from "./Label";
import useSelectableStyles from "./SelectableStyle";

type TValue = string | number | boolean | null;
export interface IOption {
  value: TValue;
  label: string;
}
interface ISelectableProps {
  options: IOption[];
  compressed?: boolean;
  wired?: boolean;
  id: string;
  label?: ReactNode | string;
  required?: boolean;
  onChange?: Function;
}

function Selectable(
  { options, compressed, id = "value", label, required, onChange, wired }: ISelectableProps,
  ref
) {
  const [current, setCurrent] = useState<IOption>();
  const [error, setError] = useState<boolean>(false);
  const [txtError, setTxtError] = useState<string>("");
  const difRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (error) {
      setError(false);
      setTxtError("");
    }
    if (onChange) {
      onChange(current?.value);
    }
  }, [current]);
  useImperativeHandle(ref, () => ({
    getValue() {
      return {
        [id]: current?.value,
      };
    },
    setValue(val: TValue) {
      const foundValue: IOption = options.find((item) => item.value === val);
      setCurrent(foundValue);
    },
    scrollToMe() {
      difRef?.current.scrollIntoView();
    },
    validate() {
      if (!current && required) {
        setError(true);
        setTxtError("Este campo es necesario");
        return false;
      }
      return true;
    },
  }));
  const classes = useSelectableStyles();
  return (
    <FormControl error={error} required={required}>
      <Label id={id}>{label}</Label>
      <div ref={difRef} className={classes.buttonsList}>
        {options.map((val) => (
          <OptionButton
            key={`${id}-${val.value}`}
            compressed={compressed}
            wired={wired}
            disabled={val?.value !== current?.value}
            onClick={() => (val?.value === current?.value ? setCurrent(null) : setCurrent(val))}>
            {val.label}
          </OptionButton>
        ))}
      </div>
      <HelperText hidden={!error}>{txtError}</HelperText>
    </FormControl>
  );
}
export default forwardRef(Selectable);
