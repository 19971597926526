import { Link } from "react-router-dom";
import BaseField from "../../../BaseField";
import useLinkFieldStyles from "./LinkFieldStyles";

function LinkField({ link, text }) {
  const classes = useLinkFieldStyles();
  return (
    <BaseField requiredData={link}>
      <Link to={link} className={classes.link}>
        {text}
      </Link>
    </BaseField>
  );
}

export default LinkField;
