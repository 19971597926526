import { makeStyles, Theme } from "@material-ui/core";

const useAssignToOtherStyles = makeStyles((theme: Theme) => ({
  centerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  autocompleteContainer: {
    display: "block",
    width: "auto",
  },
  stepTwoTitleContainer: {
    fontSize: theme.typography.pxToRem(18),
    display: "flex",
    justifyContent: "center",
    whiteSpace: "pre",
  },
  stepTwoButtonsContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
}));

export default useAssignToOtherStyles;
