import { useState } from "react";

import { Typography } from "@material-ui/core";

import SpaceFeaturesEditRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/edit/components/SpaceFeaturesEditRow";
import TableTitle from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableTitle";
import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import TableRowImagePreviewer from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableRowImagePreviewer";
import { useProperty } from "context/propertyContext/propertyContext";
import {
  inspectionReportFeaturesNameTranslation,
  inspectionReportQualificationOptions,
} from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";
import {
  filterImagesByName,
  getCurrentSpace,
} from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils";
import { getObjectLabel } from "utils";
import { titlesArrayBase, editTitlesArray } from "./utils";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { SpaceFeatureImagesType } from "services/propertyService/types";

function SpaceFeaturesTable() {
  const [currentFeatureEditingName, setCurrentFeatureEditingName] = useState<string>(null);
  const { state } = useProperty();
  const isEditView = state.inspectionReportsCurrentView === "edit";

  const currentSpace = getCurrentSpace(
    state.inspectionReport?.data?.spaces,
    state.inspectionReport?.currentSpaceName
  );

  const currentSpaceFeaturesImages: SpaceFeatureImagesType = currentSpace?.features.map(
    (feature) => ({
      name: feature.name,
      images: feature.photo_links.map((photoLink) => photoLink.link),
    })
  );

  if (!currentSpace?.features) return null;

  return (
    <>
      <TableTitle titlesArr={isEditView ? editTitlesArray : titlesArrayBase} isEdit={isEditView} />
      {currentSpace.features.map((feature, index) =>
        currentFeatureEditingName === feature.name ? (
          <SpaceFeaturesEditRow
            currentSpace={currentSpace}
            feature={feature}
            currentSpaceFeaturesImages={currentSpaceFeaturesImages}
            setCurrentFeatureEditingName={setCurrentFeatureEditingName}
          />
        ) : (
          <InspectionReportTableRow
            isEdit={isEditView}
            key={`feature-${feature.qualification}-${index + 1}`}>
            <Typography>
              {getObjectLabel(inspectionReportFeaturesNameTranslation, feature.name)}
            </Typography>
            <Typography>
              {getObjectLabel(inspectionReportQualificationOptions, feature.qualification)}
            </Typography>
            <Typography>{feature.observation ?? "Sin observación"}</Typography>
            <TableRowImagePreviewer
              rowName={`${getObjectLabel(inspectionReportFeaturesNameTranslation, feature.name)} (${
                currentSpace.name
              })`}
              imagesArr={filterImagesByName(feature.name, currentSpaceFeaturesImages)}
            />
            {isEditView && (
              <LinkButton onClick={() => setCurrentFeatureEditingName(feature.name)}>
                Editar
              </LinkButton>
            )}
          </InspectionReportTableRow>
        )
      )}
    </>
  );
}

export default SpaceFeaturesTable;
