import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService";

import { PropertyProps } from "./types";
import { parsePropertyInfo } from "./parsers";

const useGetPropertyInfo = (uid?: string) => {
  const { getToken } = useGetToken();
  const { data, isLoading, isError }: UseQueryResult<PropertyProps, Error> = useQuery<
    PropertyProps,
    Error
  >(
    ["contractPropertyInfo", uid],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.get(uid, authToken);
      return parsePropertyInfo(response.data);
    },
    {
      retry: false,
      enabled: !!uid,
    }
  );
  return { data, isLoading, isError };
};

export default useGetPropertyInfo;
