import { useEffect, useMemo } from "react";
import { useAuth } from "libs/auth";
import useFetchProfile from "./useFetchProfile";
import hotjar from "libs/hotjar";

export default function useUser() {
  const { user: userAuth0Data } = useAuth();
  const { data: userProfileData, status, ...rest } = useFetchProfile();

  const user = useMemo(
    () =>
      userProfileData
        ? {
            ...userProfileData,
            picture: userAuth0Data ? userAuth0Data.picture : undefined,
          }
        : undefined,
    [userProfileData, userAuth0Data]
  );
  /* FIXME: this is a hack from old times to share user data between different
   * parts of the app without accesing react state
   */
  useEffect(() => {
    if (user) {
      window.localStorage.setItem("user", JSON.stringify(user));
      hotjar.identify(`${user.id}`);
    }
  }, [user]);
  return {
    user,
    status: status === "success" && user ? "success" : status,
    ...rest,
  };
}
