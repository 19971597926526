import { IPromotion } from "models/Promotions";

export const getOrderedInfo = (data: IPromotion) => ({
  id: data.id,
  promotionName: data.promotionName,
  country: data.country,
  cities: data.cities,
  active: data.active,
  generalInfo: {
    id: data.id,
    initDate: new Date(`${data.initDate}T00:00:00`),
    endDate: new Date(`${data.endDate}T00:00:00`),
    promotionName: data.promotionName,
    promotionStock: data.promotionStock,
    target: data.target,
    link: data.link,
    promotionCode: data.promotionCode,
    description: data.description,
    landlordObservation: data.landlordObservation,
    tenantObservation: data.tenantObservation,
    discountType: data.discountType,
  },
  promotionDetails: {
    adminCommission: data.adminCommission,
    leaseLandlord: data.leaseLandlord,
    leaseTenant: data.leaseTenant,
    landlordBrokerage: data.landlordBrokerage,
    tenantBrokerage: data.tenantBrokerage,
  },
});
