import { makeStyles, Theme } from "@material-ui/core";

const useLinkFieldStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "underline",
    fontWeight: 700,
    color: theme.palette.grey[900],
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline !important",
    },
  },
}));

export default useLinkFieldStyles;
