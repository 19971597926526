import PropertyInspectionReportView from "./PropertyInspectionReportView";
import { TableProvider } from "context/tableContext/tableContext";

function PropertyInspectionReportViewIndex() {
  return (
    <TableProvider>
      <PropertyInspectionReportView />
    </TableProvider>
  );
}

export default PropertyInspectionReportViewIndex;
