import { makeStyles, Theme } from "@material-ui/core";

const useMultifamilyUnitStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    maxWidth: "80vw",
    padding: theme.spacing(4, 3),
  },
  customTabsStyle: {
    maxWidth: theme.breakpoints.values.lg,
    "& .MuiTabScrollButton-root": {
      backgroundColor: theme.palette.grey[200],
      "& svg": {
        fontSize: theme.typography.pxToRem(30),
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      "&:first-child": {
        borderStartStartRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-child": {
        borderStartEndRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
  visitsContainer: {
    padding: theme.spacing(2),
  },
}));

export default useMultifamilyUnitStyles;
