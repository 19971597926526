import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import propertyService from "services/propertyService";
import { localeToCountry } from "utils/countryCode";

import { parseAvailableCompanies } from "../utils/parsers";
import { AvailableCompaniesOptions } from "../utils/types";

const useAvailableCompanies = (country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, data: companies }: UseQueryResult<AvailableCompaniesOptions[], Error> =
    useQuery<AvailableCompaniesOptions[], Error>(["commonExpenseCompanies"], async () => {
      const authToken = await getToken();
      const response = await propertyService.getCommonExpenseCompanies(
        localeToCountry[country],
        authToken
      );
      return parseAvailableCompanies(response.data);
    });

  return {
    isLoading,
    companies,
  };
};

export default useAvailableCompanies;
