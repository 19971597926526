import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService";
import { parseKeys } from "./parsers";
import { IKeys } from "./types";

const useKeys = (id: number) => {
  const { getToken } = useGetToken();
  const { isLoading, error, data }: UseQueryResult<IKeys, Error> = useQuery<IKeys, Error>(
    ["keys", id],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getPropertyKeys(id, authToken);
      return parseKeys(response.data);
    }
  );

  return {
    isLoading,
    error,
    data,
  };
};

export default useKeys;
