import { makeStyles, Theme } from "@material-ui/core";

const useUserStyles = makeStyles<Theme>((theme) => ({
  infoText: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.5,
  },
  container: {
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useUserStyles;
