import Button from "@houm-com/ui/Button";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useGenerateContractCardStyles from "./GenerateContractCardStyles";
import useEditContract from "hooks/useEditContract";
import { ContractModel } from "models/Contract";

interface Props {
  contract: ContractModel;
}

function GenerateContractCard({ contract }: Props) {
  const { mutate, isLoading } = useEditContract({ contractId: contract.id });
  const classes = useGenerateContractCardStyles();

  const generateContract = async () => {
    const newStatusContract = {
      ...contract,
      status: "Listo para redaccion",
    };
    mutate(newStatusContract);
  };

  return (
    <CollapseCard title="Generar contrato">
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <Typography component="h6" className={classes.textLabel}>
                  Cuando esté toda la información lista y de acuerdo con las condiciones finales,
                  haz click en el botón de abajo. Se usará como fecha de inicio de contrato la fecha
                  propuesta en la tarjeta "Detalles contrato".
                </Typography>
              </Grid>
            </Grid>
            <Button size="md" variant="primary" isLoading={isLoading} onClick={generateContract}>
              Enviar a generación de contrato
            </Button>
          </CardContent>
        </div>
      </Card>
    </CollapseCard>
  );
}

export default GenerateContractCard;
