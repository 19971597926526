import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import Button from "@material-ui/core/Button";

import useFileUploadStyles from "./FileUploadStyles";

interface Props {
  label: string;
  allowedMimeTypes: string[];
  onChange: (e: any) => void;
}

export default function FileUpload({ label, onChange, allowedMimeTypes }: Props) {
  const classes = useFileUploadStyles();

  const id = useMemo(() => `file-upload-${uuidv4()}`, []);

  return (
    <Button component="label" className={classes.button} htmlFor={id}>
      {label}
      <input
        id={id}
        className={classes.hidden}
        name="upload-file"
        type="file"
        onChange={onChange}
        accept={allowedMimeTypes.join()}
      />
    </Button>
  );
}
