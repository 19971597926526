import { makeStyles, Theme } from "@material-ui/core";

const useTableStyles = makeStyles<Theme>((theme) => ({
  container: { width: "100%", height: "100%", marginTop: theme.spacing(1) },
  root: {
    "& .MuiDataGrid-columnsContainer": {
      borderWidth: 0,
    },
    "& .MuiDataGrid-cell": {
      padding: theme.spacing(0, 1),
      outline: "1px solid #E9EAEB",
      outlineOffset: -2,
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: theme.palette.grey[5],
      },
    },
    "& .MuiDataGrid-row.Mui-odd": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiDataGrid-columnSeparator": {
      color: theme.palette.grey[200],
    },
  },
  headerWrapper: {
    backgroundColor: theme.palette.grey[5],
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
      color: "#58646A",
    },
  },
  emailText: {
    color: theme.palette.primary.dark,
  },
}));

export default useTableStyles;
