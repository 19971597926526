import { useEffect, useState } from "react";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";

import { TotalQuotaIndex, QuotaByMacrozoneIndex } from "./components";
import Header from "Components/Admin/administrator/integrations/components/Header/Header";
import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import TabPanel from "Components/Admin/UIComponents/Tabs/TabPanel";
import { useIntegration } from "context/integrationContext/integrationContext";
import { getQuotaByMacrozone, getTotalQuota } from "context/integrationContext/integrationActions";
import { useTab } from "context/tabContext/tabContext";
import { tabOptions } from "./utils/tabOptions";
import { useGetToken } from "hooks";
import {
  IndexQuotaByMacrozoneFilters,
  IndexTotalQuotaFilters,
} from "services/integrationService/types";
import { isEmpty } from "utils";
import { TableProvider } from "context/tableContext/tableContext";

function PortalQuotas() {
  const { state, dispatch } = useIntegration();
  const { getToken } = useGetToken();
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  const initialFilters = {
    page: 1,
    page_size: 10,
    country: state.country,
  };

  // Initial fetch for both tables
  useEffect(() => {
    getToken().then((authToken) => {
      getTotalQuota(initialFilters, dispatch, authToken);
      getQuotaByMacrozone(initialFilters, dispatch, authToken);
    });
  }, []);

  // Filtered Total Quota Data Handling
  const [totalQuotaFilters, setTotalQuotaFilters] =
    useState<IndexTotalQuotaFilters>(initialFilters);

  useEffect(() => {
    const mappedFilters: IndexTotalQuotaFilters = omitBy(totalQuotaFilters, isEmpty);

    if (isEqual(mappedFilters, totalQuotaFilters)) return;
    setTotalQuotaFilters(mappedFilters);

    getToken().then((authToken) => {
      getTotalQuota(mappedFilters, dispatch, authToken);
    });
  }, [state.country, totalQuotaFilters]);

  // Filtered Total Quota Data Handling
  const [quotaByMacrozoneFilters, setQuotaByMacrozoneFilters] =
    useState<IndexQuotaByMacrozoneFilters>(initialFilters);

  useEffect(() => {
    const mappedFilters: IndexQuotaByMacrozoneFilters = omitBy(quotaByMacrozoneFilters, isEmpty);

    if (isEqual(mappedFilters, quotaByMacrozoneFilters)) return;
    setQuotaByMacrozoneFilters(mappedFilters);

    getToken().then((authToken) => {
      getQuotaByMacrozone(mappedFilters, dispatch, authToken);
    });
  }, [state.country, quotaByMacrozoneFilters]);

  return (
    <>
      <Header title="Cupos por portal" />
      <TabMenu options={tabOptions} defaultValue="total_quotas" />
      <TableProvider>
        <TabPanel value={currentTabValue} index="total_quotas">
          <TotalQuotaIndex filters={totalQuotaFilters} setFilters={setTotalQuotaFilters} />
        </TabPanel>
      </TableProvider>
      <TableProvider>
        <TabPanel value={currentTabValue} index="macrozone_quotas">
          <QuotaByMacrozoneIndex
            filters={quotaByMacrozoneFilters}
            setFilters={setQuotaByMacrozoneFilters}
          />
        </TabPanel>
      </TableProvider>
    </>
  );
}

export default PortalQuotas;
