import * as yup from "yup";

export const requiredText = "Obligatorio";
const invalidDate = "La fecha inicial debe ser menor a la final";

export const promotionSchema = () =>
  yup.object({
    endDate: yup.string().required(requiredText),
    initDate: yup
      .string()
      .required(requiredText)
      .when("endDate", {
        is: (endDate: string) => endDate && endDate.length > 0,
        then: yup.string().test("is-greater", invalidDate, function validate(initDate) {
          return initDate < this.parent.endDate;
        }),
      }),
    status: yup.string().oneOf(["active", "inactive"]).required(requiredText),
  });
