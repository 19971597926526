import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  direction?: "normal" | "left" | "right";
  highlight?: boolean;
}

const useCellStyles = makeStyles<Theme, Props>((theme) => ({
  customCell: {
    width: "100%",
    overflow: "hidden",
  },
}));
export default useCellStyles;
