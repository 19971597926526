import { makeStyles, Theme } from "@material-ui/core";

const useLeadPropertiesStyles = makeStyles((theme: Theme) => ({
  tablePropertyPhoto: {
    borderRadius: 8,
    height: 55,
    width: 100,
    objectFit: "cover",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    marginBottom: theme.spacing(2),
  },
  createButtonContainer: {
    margin: theme.spacing(2),
  },
  createButton: {
    borderRadius: "100rem",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  visitsTable: {
    "& .MuiDataGrid-row": {
      maxHeight: "unset !important",
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 10,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      padding: theme.spacing(2, 1),
    },

    "& .MuiDataGrid-renderingZone": {
      overflowY: "scroll",
    },
  },
}));

export default useLeadPropertiesStyles;
