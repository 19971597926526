import { State, Action, SET_LOADING, SET_RESERVATIONS, SET_COUNTRY } from "./reservationsTypes";

export const initialState: State = {
  loading: false,
  reservations: [],
  numberOfReservations: null,
  country: "chile",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_RESERVATIONS: {
      return {
        ...state,
        loading: false,
        reservations: action.payload.reservations,
        numberOfReservations: action.payload.count,
      };
    }
    case SET_COUNTRY: {
      return { ...state, country: action.country };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
