import { State, PersonProps } from "./contractPersonTypes";

export const frontParsePerson = (personData: any, state: State): PersonProps => ({
  address: personData.address ?? "",
  document: personData.document || personData.rut,
  documentType: state.documentTypes.find((doc) => doc.id === personData.document_type),
  email: personData.email ?? "",
  name: personData.name ?? "",
  occupation: personData.occupation ?? "",
  phone: personData.phone ?? "",
  rfc: personData.rfc_number ?? "",
  role: state.person?.role,
  ready: true,
});

export const backParsePerson = (formData: PersonProps, state: State) => {
  const data = {
    email: formData.email,
    phone: formData.phone,
    name: formData.name,
    address: formData.address,
    property_id: state.propertyId,
    role: state.person.role,
    occupation: formData.occupation,
  };
  switch (state.country) {
    case "Colombia":
      data["document"] = formData.document;
      data["document_type"] = formData.documentType;
      break;
    case "México":
    case "Mexico":
      data["document"] = formData.document;
      data["document_type"] = formData.documentType;
      data["rfc_number"] = formData.rfc;
      break;
    case "Chile":
    default:
      data["rut"] = formData.document;
      break;
  }
  return data;
};
