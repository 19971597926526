import { makeStyles, Theme } from "@material-ui/core";

const useBasicServiceFormStyles = makeStyles((theme: Theme) => ({
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  selectRoot: {
    margin: 0,
    marginRight: theme.spacing(2),
  },
  selectButtonList: {
    margin: 0,
  },
  select: {
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
  },
  selectLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    "&:focus": {
      color: theme.palette.grey[900],
    },
  },
  input: {
    marginBottom: 3,
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
  },
  buttons: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));

export default useBasicServiceFormStyles;
