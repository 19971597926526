import * as yup from "yup";

const REQUIRED_TEXT = "Campo requerido";
const RANGE_TEXT = "Fuera de rango";

const validatePercentageInput = yup
  .number()
  .required(REQUIRED_TEXT)
  .typeError(REQUIRED_TEXT)
  .min(1, RANGE_TEXT)
  .max(100, RANGE_TEXT)
  .nullable(false);

export const DetailsForm = yup.object().shape({
  ownerPenaltyFee: validatePercentageInput,
  bidderPenaltyFee: validatePercentageInput,
  houmOwnerFee: validatePercentageInput,
  houmBidderFee: validatePercentageInput,
});
