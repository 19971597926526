import { Dispatch, SetStateAction } from "react";

import { IconButton } from "@material-ui/core";

import useScheduleTagStyles from "./ScheduleTagStyles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  setShow: Dispatch<SetStateAction<boolean>>;
}

function ScheduleTag({ setShow }: Props) {
  const classes = useScheduleTagStyles();
  return (
    <div className={classes.container}>
      <div className={classes.tag}>
        <DateRangeIcon />
        Agendamiento
      </div>
      <IconButton onClick={() => setShow(false)} size="small" className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}
export default ScheduleTag;
