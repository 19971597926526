import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginTop: theme.spacing(1),
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  labelContainer: {
    marginTop: 0,
  },
  splitCell: { display: "flex", width: "50%" },
  percentage: { width: "30%" },
  notarialCosts: { width: "7%", minWidth: 40 },
}));

export default useStyles;
