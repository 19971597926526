interface Props {
  title?: string;
  message?: string;
}

function FullScreenMessage({ title, message }: Props) {
  return (
    <>
      <h2 className="text-h28">{title}</h2>
      <p className="text-p16">{message}</p>
    </>
  );
}

export default FullScreenMessage;
