import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => {
  const defaultBorder = `1px solid  ${theme.palette.grey[50]} !important`;
  const defaultBorderRadius = "8px";
  return {
    container: { width: "100%", height: "100%", marginTop: theme.spacing(1) },

    link: {
      textDecoration: "underline !important",
      color: `${theme.palette.primary.main} !important`,
    },
    root: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "white !important",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderWidth: 0,
      },
      "& .MuiDataGrid-row": {
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    headerCell: {
      backgroundColor: `${theme.palette.grey["50"]} !important`, //this only works with important
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: theme.palette.grey[500],
        borderBottomWidth: 0,
      },
    },
    firstHeaderCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
    },
    lastRenderedHeaderCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
    },
    leftCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
    },
    rightCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
      overflow: "hidden",
    },
    centerCell: {
      outline: defaultBorder,
      outlineOffset: -2,
      borderLeft: "0 !important",
    },
    lastHeaderCell: {
      display: "none !important",
      maxWidth: "20px !important",
    },
  };
});
