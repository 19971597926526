import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import ActivityForm from "./ActivityForm";
import { useLead } from "context/leadContext/leadContext";
import { hideActivityModalForm } from "context/leadContext/leadActions";

function ActivityFormModal() {
  const { state, dispatch } = useLead();

  const handleOnClose = () => {
    dispatch(hideActivityModalForm());
  };

  const isEdit = state.activityFormAction === "edit";
  const modalTitle = isEdit ? "Editar actividad" : "Agregar actividad";

  return (
    <CustomDialog open={state.showActivityFormModal} onClose={handleOnClose} title={modalTitle}>
      <ActivityForm closeModal={handleOnClose} action={state.activityFormAction} />
    </CustomDialog>
  );
}

export default ActivityFormModal;
