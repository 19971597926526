import { HomeCheckerDetailsSnakeCase, IHomeCheckerDetails } from "models";

import { PropertyData } from "models/Property";
import { PhoneInput } from "models/PhoneInput";
import { formatPhoneInput } from "utils";

export const parseGetHomeCheckerDetailResponse = (response): IHomeCheckerDetails => ({
  administrationEmail: response.administration_email as string,
  administrationPhone: formatPhoneInput(response.administration_phone) as PhoneInput,
  buildingAdministration: response.building_administration as string,
  concierge: response.concierge as boolean,
  conciergeEmail: response.concierge_email as string,
  conciergeName: response.concierge_name as string,
  conciergePhone: formatPhoneInput(response.concierge_phone) as PhoneInput,
  hasHoumBox: response.has_houm_box as boolean,
  moveInPolicy: response.move_in_policy as string,
  moveInPolicyPhoto: response.move_in_policy_photo as string,
  inhabitedProperty: response.inhabited_property as boolean,
  inhabitedPropertyDescription: response.inhabited_property_description as string,
  thirdPartyCoordination: response.third_party_coordination as boolean,
  thirdPartyCoordinationDescription: response.third_party_coordination_description as string,
  thirdPartyCoordinationPhone: formatPhoneInput(
    response.third_party_coordination_phone
  ) as PhoneInput,
});

export const parseHomeCheckerDetailSendData = (
  data: IHomeCheckerDetails
): HomeCheckerDetailsSnakeCase => ({
  administration_email: data.administrationEmail,
  administration_phone: data.administrationPhone?.parsedPhone,
  building_administration: data?.buildingAdministration,
  concierge: data.concierge,
  concierge_email: data?.conciergeEmail,
  concierge_name: data?.conciergeName,
  concierge_phone: data?.conciergePhone?.parsedPhone,
  has_houm_box: data?.hasHoumBox,
  move_in_policy: data?.moveInPolicy,
  move_in_policy_photo: data?.moveInPolicyPhoto,
  inhabited_property: data?.inhabitedProperty,
  inhabited_property_description: data?.inhabitedPropertyDescription,
  third_party_coordination: data?.thirdPartyCoordination,
  third_party_coordination_description: data?.thirdPartyCoordinationDescription,
  third_party_coordination_phone: data?.thirdPartyCoordinationPhone?.parsedPhone,
});

const noKeyManagerError = "No se ha asignado un responsable de las llaves";

const noNameError = "No se ha asignado un nombre para la coordinación con el tercero";
const noPhoneError = "No se ha asignado un número de teléfono para la coordinación con el tercero";

export const validateThirdPartyToPublish = (
  property: PropertyData
): { errors: string[]; withError: boolean } => {
  const {
    third_party_coordination_full_name: name,
    third_party_coordination_phone: phone,
    third_party_coordination: withThirdParty,
  } = property.home_checker_details[0];

  if (!withThirdParty) {
    return { errors: [], withError: false };
  }

  const errors = [...(!name ? [noNameError] : []), ...(!phone ? [noPhoneError] : [])] as string[];

  return { errors, withError: errors.length > 0 };
};

export const verifyHoumboxToPublish = (
  property: PropertyData
): { errors: string[]; withError: boolean } => {
  const { has_houm_box, key_manager } = property.home_checker_details[0];

  if (!has_houm_box) {
    const errors = [...(!key_manager ? [noKeyManagerError] : [])] as string[];
    return { errors, withError: errors.length > 0 };
  }

  return { errors: [], withError: false };
};
