import { makeStyles, Theme } from "@material-ui/core";

const useFeaturesStyles = makeStyles((theme: Theme) => ({
  bedroomWrapper: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export default useFeaturesStyles;
