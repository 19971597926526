import { Theme, makeStyles } from "@material-ui/core/styles";

const useClosingSpecificMovementStyles = makeStyles((theme: Theme) => ({
  description: {
    width: "60%",
  },
  inline: {
    display: "inline",
    fontSize: theme.typography.pxToRem(16),
    color: "#000000dd",
  },
  positive: {
    color: "#00982D",
  },
  negative: {
    color: "#F02C2C",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inlineTotal: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useClosingSpecificMovementStyles;
