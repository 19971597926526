import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { useGetToken } from "hooks";

import {
  getMultifamily,
  setCurrentId as setMultifamilyId,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import CreateForm from "./Components/Form/Form";
import BackButton from "../Components/BackButton";

import useStyles from "./indexStyles";

function CreateTypology() {
  const classes = useStyles();
  const history = useHistory();
  const { MFid } = useParams<{ MFid: string }>();
  const { getToken } = useGetToken();
  const {
    dispatch: dispatchMF,
    state: { multifamily },
  } = useMultifamily();

  useEffect(() => {
    dispatchMF(setMultifamilyId(parseInt(MFid)));
    if (MFid) {
      getToken().then((authToken) => {
        getMultifamily(dispatchMF, parseInt(MFid), authToken);
      });
    }
  }, [MFid]);

  const handleBack = () => history.push(`/admin/multifamily/${MFid}`);

  return (
    <div className={classes.container}>
      <div>
        <BackButton backButtonProps={{ label: "Atras", onClick: handleBack }} />
      </div>
      <Typography className={classes.title}>{multifamily?.name}</Typography>
      <div className={classes.form}>
        <CreateForm />
      </div>
    </div>
  );
}
export default CreateTypology;
