import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  simple?: boolean;
}

const useControlledSelectionStyles = makeStyles<Theme, Props>((theme) => ({
  labelSelection: {
    fontSize: theme.typography.pxToRem(20),
  },
  containerSelection: {
    margin: theme.spacing(1, 1 / 2),
  },
  underlineSelection: {
    borderBottom: "0.5px solid",
  },
  buttonList: {
    //simple is a select in other case group of buttons
    marginTop: ({ simple }) => theme.spacing(!simple ? 3 : 2.4),
  },
}));

export default useControlledSelectionStyles;
