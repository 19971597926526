import { makeStyles, Theme } from "@material-ui/core";

const useLandlordDataStyles = makeStyles((theme: Theme) => ({
  landlordContainer: {
    width: 770,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 4),
    border: `1px solid ${theme.palette.grey[75]}`,
    boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    borderRadius: 16,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.25,
    fontWeight: 700,
  },
  infoGrid: {
    marginTop: theme.spacing(2),
  },
  reportContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default useLandlordDataStyles;
