import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 60,
  },
  title: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: "bold",
    margin: theme.spacing(1, 0),
  },
  date: {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(1.5, 0),
    fontWeight: 500,
  },
  buttonContainer: {},
}));
