import Grid, { GridSize } from "@material-ui/core/Grid";
import useInfoDisplayStyles from "./InfoDisplayStyles";

interface Info {
  label: string;
  value: string | number;
}

interface Props {
  infoColumns: Array<Info[]>;
}

function InfoDisplay({ infoColumns }: Props) {
  const classes = useInfoDisplayStyles();
  const wide = 12;
  const columnsSize = wide / infoColumns.length;

  return (
    <Grid container spacing={1}>
      {infoColumns.map((infoList, index) => (
        <Grid item key={index.toString()} sm={columnsSize as GridSize}>
          {infoList.map((info, i) => (
            <p key={`${i.toString()}`} className={classes.paragraph}>
              {info.label}: <span className={classes.valueInfo}>{info.value}</span>
            </p>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default InfoDisplay;
