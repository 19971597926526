import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm, UseFormProps } from "react-hook-form";
import { AnySchema } from "yup";
import { OptionalObjectSchema } from "yup/lib/object";

import { useFormNotifications } from "hooks";

const requiredText = "Obligatorio";

const useReactHookForm = <T extends FieldValues>({
  formSchema,
  formOptions,
}: {
  formSchema: OptionalObjectSchema<Record<keyof T, AnySchema>>;
  formOptions?: UseFormProps<T>;
}) => {
  const methods = useForm<T>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onBlur",
    ...formOptions,
  });
  const { errors } = methods.formState;
  useFormNotifications<T>({ errors, requiredText });

  return {
    methods,
    errors,
  };
};

export default useReactHookForm;
