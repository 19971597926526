/* eslint-disable import/no-absolute-path */
import { useEffect, useState } from "react";
import { Divider, Grid, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as SquaredMetersIcon } from "assets/icons/superficie.svg";
import { ReactComponent as BedIcon } from "assets/icons/bed.svg";
import { ReactComponent as BathIcon } from "assets/icons/bath.svg";
import { ReactComponent as SunIcon } from "assets/icons/orientation.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/buildings.svg";
import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import { ReactComponent as StoreIcon } from "assets/icons/store.svg";
import { ReactComponent as AddCircleIcon } from "assets/icons/add-circle.svg";
import { ReactComponent as DocumentsIcon } from "assets/icons/documents.svg";

import CollapsibleTable from "Components/Admin/UIComponents/CollapsibleTable/CollapsibleTable";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import LayoutForm from "./LayoutForm";
import SearchBar from "./SearchBar";
import { ILayout } from "./LayoutTypes";
import { parseRows, filteredData, statusOptions } from "./LayoutsUtils";
import useStyles from "./useLayoutIndexStyles";
import { useProject } from "context/projectContext/projectContext";
import { formatCurrency } from "utils/formatCurrency";
import { useGetToken } from "hooks";
import {
  deleteLayout,
  setSelectedLayout,
  getLayouts,
  deleteUnit,
  setUnitsUploadModal,
  setSingleProject,
} from "context/projectContext/projectActions";
import { NewLayoutForm, NewUnitForm } from "context/projectContext/projectTypes";
import UnitForm from "./units/UnitForm";
import UnitsUploadModal from "./units/unitsUpload/UnitsUploadModal";
import { orientationOptions } from "utils/orientation";
import { getObjectLabel } from "utils";

interface Props {
  propId: number;
  currency: string;
  floors: number;
  country: string;
}

function LayoutIndex({ propId, currency, floors, country }: Props) {
  const classes = useStyles();

  const [openCreateModel, setOpenCreateModel] = useState<boolean>(false);
  const [rows, setRows] = useState<ILayout[]>([]);
  const [query, setQuery] = useState<string>("");

  const { getToken } = useGetToken();

  const { state, dispatch } = useProject();

  const setProject = async () => {
    const authToken = await getToken();
    await setSingleProject(propId, dispatch, authToken);
  };

  useEffect(() => {
    setProject();
  }, []);

  useEffect(() => {
    if (!state.loadingUpload) {
      const getAllLayouts = async () => {
        const authToken = await getToken();
        const response = await getLayouts(propId, dispatch, authToken);
        setRows(parseRows(response));
      };
      getAllLayouts();
    }
  }, [state.loadingUpload]);

  const handleCreateModel = () => <LayoutForm propId={propId} handleClose={setOpenCreateModel} />;

  const handleCreateUnit = (layoutId, handleClose) => (
    <UnitForm
      layoutId={layoutId}
      handleClose={handleClose}
      currency={currency}
      country={country}
      floors={floors}
    />
  );

  const handleEditModel = (row, handleClose) => {
    const data: NewLayoutForm = {
      ...row,
      totalArea: row?.features?.building_area,
      usableArea: row?.features?.usable_area,
      bathrooms: row?.features?.bathrooms,
      rooms: row?.features?.rooms,
      image: row.layout_file_url,
      studio: row?.features?.studio,
    };
    return <LayoutForm propId={propId} model={data} handleClose={handleClose} />;
  };

  const handleEditUnit = (row, layoutId, handleClose) => {
    const data: NewUnitForm = {
      ...row,
      price: row.price.value,
      has_storage: row.features.warehouse,
      parking_spaces: row.features.parking,
      orientation: row.features.orientation,
      floor: row.features.floor,
    };
    return (
      <UnitForm
        layoutId={layoutId}
        handleClose={handleClose}
        model={data}
        country={country}
        floors={floors}
        currency={currency}
      />
    );
  };

  const handleDeleteLayout = async (id: number, name: string) => {
    const token = await getToken();
    await deleteLayout(
      id,
      dispatch,
      name,
      {
        confirmButtonRounded: classes.confirmButtonRounded,
        cancelButton: classes.cancelButton,
      },
      token
    );
  };

  const handleDeleteUnit = async (id: number, subname: string, name: string) => {
    const token = await getToken();
    await deleteUnit(
      id,
      dispatch,
      name,
      subname,
      {
        confirmButtonRounded: classes.confirmButtonRounded,
        cancelButton: classes.cancelButton,
      },
      token
    );
  };

  const tableCols = [
    {
      id: "total",
      name: "Total",
      width: "8%",
      subId: "status",
      renderCell: (value) => <div>{`${value} unidades`}</div>,
      renderSubCell: (value) => <div>{`${statusOptions[value]}`}</div>,
    },
    {
      id: "layout_file_url",
      name: "Plano",
      width: "1%",
      renderCell: (value) => <img src={value} className={classes.layoutPhoto} alt="layout" />,
    },
    {
      id: "name",
      name: "Nombre",
      width: "7%",
      subId: "name",
    },
    {
      id: "features",
      name: "Características",
      width: "27%",
      subId: "features",
      renderCell: (value) => (
        <div className={classes.features}>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={SquaredMetersIcon} fontSize="small" className={classes.icon} />
            {` ${value?.building_area} m2 totales `}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={SquaredMetersIcon} fontSize="small" className={classes.icon} />
            {` ${value?.usable_area} m2 útiles `}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={BedIcon} fontSize="small" className={classes.icon} />
            {` ${value?.rooms} `}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={BathIcon} fontSize="small" className={classes.icon} />
            {`  ${value?.bathrooms}  `}
          </Typography>
        </div>
      ),
      renderSubCell: (value) => (
        <div className={classes.features}>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={SunIcon} fontSize="small" className={classes.icon} />
            {` Orientación ${getObjectLabel(orientationOptions, value?.orientation)} `}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={BuildingIcon} fontSize="small" className={classes.icon} />
            {` ${value?.floor} piso`}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={CarIcon} fontSize="small" className={classes.icon} />
            {` ${value?.parking} `}
          </Typography>
          <Typography className={classes.spanText} component="span">
            <SvgIcon component={StoreIcon} fontSize="small" className={classes.icon} />
            {` ${value?.warehouse ? "Si" : "No"} `}
          </Typography>
        </div>
      ),
    },
    {
      id: "price",
      name: "Precio",
      width: "7%",
      subId: "price",
      renderCell: (value) =>
        value ? (
          <Typography className={classes.spanText} component="span">
            {formatCurrency(value.currency, value.value)}
          </Typography>
        ) : (
          <Divider classes={{ root: classes.divider }} />
        ),
      renderSubCell: (value) => (
        <Typography className={classes.spanText} component="span">
          {formatCurrency(value.currency, value.value)}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        Tipologías del proyecto
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start">
        <Grid item xs={5}>
          <SearchBar onChange={(query: string) => setQuery(query)} placeholder="Búsqueda" />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" className={classes.buttonsContainer}>
          <DesignSystemButton
            onClick={() => {
              setOpenCreateModel(!openCreateModel);
              dispatch(setSelectedLayout(undefined));
            }}
            disabled={rows.length < 1}
            type="submit"
            variant="tertiaryA"
            size="small"
            label="Crear modelo"
            Icon={<AddCircleIcon className={classes.buttonIcon} />}
          />
          <DesignSystemButton
            onClick={() => dispatch(setUnitsUploadModal(true))}
            disabled={rows.length < 1}
            type="submit"
            variant="primary"
            size="small"
            label="Unidades masivas"
            Icon={<DocumentsIcon className={classes.buttonIcon} />}
          />
        </Grid>
      </Grid>
      <CollapsibleTable
        rows={filteredData(rows, query)}
        headers={tableCols}
        filtered={query.length > 0}
        openInputForm={openCreateModel}
        createSubRowComponent={handleCreateUnit}
        createRowComponent={handleCreateModel()}
        deleteRowComponent={handleDeleteLayout}
        deleteSubRowComponent={handleDeleteUnit}
        editRowComponent={handleEditModel}
        editSubRowComponent={handleEditUnit}
        loading={state.loading && rows.length == 0}
      />
      <UnitsUploadModal />
    </>
  );
}

export default LayoutIndex;
