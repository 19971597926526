import { Report } from "Components/Admin/administrator/properties/show/tabs/rev/Price/utils/types";

import Environment from "./components/Environment";
import { Title, Text, EnvironmentContainer } from "./PropertyReportStyles";

interface Props {
  reports: Report[];
}

function PropertyReport({ reports = [] }: Props) {
  return (
    <>
      <Title>Reporte de daños de tu propiedad</Title>
      <Text>
        <span>Inspeccionamos</span> 🔎 tu propiedad y si es necesario te pediremos algunos arreglos{" "}
        <span>para mejorar tu propiedad.</span>
      </Text>
      <EnvironmentContainer>
        {reports?.length > 0 &&
          reports.map((report, index) => (
            <Environment
              key={`${report.type}-${index}`}
              title={report.type}
              text={report.overall_observation}
            />
          ))}
        {reports?.length === 0 && (
          <Text>No hay reportes de daños para tu propiedad hasta el momento.</Text>
        )}
      </EnvironmentContainer>
    </>
  );
}

export default PropertyReport;
