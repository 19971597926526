import { makeStyles, Theme } from "@material-ui/core";

const useStatusOfferStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    maxWidth: "480px",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
  },
  subtitle: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  optionRoot: {
    marginLeft: theme.spacing(-1),
  },
  optionLabel: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  text: {
    maxWidth: "520px",
    margin: theme.spacing(3, 0),
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    "& p": {
      color: "#FF452B",
      display: "inline",
      fontWeight: 700,
    },
  },
  contentDialog: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  textField: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    width: "343px",
    alignSelf: "flex-start",
  },
  multilineTextField: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    width: "95%",
    minHeight: "64px",
    alignSelf: "flex-start",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginTop: theme.spacing(2),
  },
  wrapperField: {
    padding: "0px",
    "& label": {
      padding: "0px",
    },
  },
  comment: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: "none",
  },
  uploadButton: {
    margin: theme.spacing(1.5),
    textTransform: "none",
  },
  helperText: {
    margin: theme.spacing(0.5, 0, 1, 1),
    fontSize: "0.75rem",
    lineHeight: 1.4,
  },
}));

export default useStatusOfferStyles;
