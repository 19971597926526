import { useState } from "react";

import usePromotionStatus from "./usePromotionStatus";
import useCountry from "./useCountry";
import { LeaseType } from "hooks/useGetPromotions/utils/types";

const usePromotions = () => {
  const { handleCountry, country } = useCountry();
  const { handleStatus, status } = usePromotionStatus();
  const [leaseType, setLeaseType] = useState<LeaseType>("all");

  return {
    country,
    status,
    handleCountry,
    handleStatus,
    leaseType,
    setLeaseType,
  };
};

export default usePromotions;
