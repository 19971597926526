import { Grid } from "@material-ui/core";

import Property360Video from "Components/Admin/UIComponents/Cards/Property360Video";
import PropertyVideo from "Components/Admin/UIComponents/Cards/PropertyVideo";
import PropertyPhotos from "Components/Admin/UIComponents/PropertyPhotos";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

const PropertyMedia = ({ property }: Props) => (
  <Grid container>
    <Grid item xs={12}>
      <PropertyPhotos property={property} photos={property?.photos} />
    </Grid>
    <Grid item xs={6}>
      <PropertyVideo property={property} property_uid={property?.uid} />
    </Grid>
    <Grid item xs={6}>
      <Property360Video property={property} property_uid={property?.uid} />
    </Grid>
  </Grid>
);

export default PropertyMedia;
