import { Locale } from "models/Countries";

export interface ContractTemplateProps {
  id: number;
  contractFile?: File;
  contractLink?: string;
  country: Locale;
  description: string;
  mandateFile?: File;
  mandateLink?: string;
  name: string;
}

export type View = "templates" | "templateForm";

export type SubmitResponseType = "" | "success" | "error";

export interface Filters {
  country: Locale;
}
export interface State {
  fetchingTemplates: boolean;
  filters: Filters;
  newTemplate: ContractTemplateProps;
  submittingTemplate: boolean;
  submitResponse: SubmitResponseType;
  templates: ContractTemplateProps[];
  view: View;
}

export const SET_FETCHING_TEMPLATES = "SET_FETCHING_TEMPLATES";
export const SET_FILTERS = "SET_FILTERS";
export const SET_NEW_TEMPLATE = "SET_NEW_TEMPLATE";
export const SET_SUBMITTING_TEMPLATE = "SET_SUBMITTING_TEMPLATE";
export const SET_SUBMIT_RESPONSE = "SET_SUBMIT_RESPONSE";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_VIEW = "SET_VIEW";

interface setFetchingTemplates {
  type: typeof SET_FETCHING_TEMPLATES;
  fetchingTemplates: boolean;
}

interface setFilters {
  type: typeof SET_FILTERS;
  filters: Partial<Filters>;
}

interface setNewTemplate {
  type: typeof SET_NEW_TEMPLATE;
  newTemplate: Partial<ContractTemplateProps>;
}

interface setSubmittingTemplate {
  type: typeof SET_SUBMITTING_TEMPLATE;
  submittingTemplate: boolean;
}

interface setSubmitResponse {
  type: typeof SET_SUBMIT_RESPONSE;
  submitResponse: SubmitResponseType;
}

interface setTemplates {
  type: typeof SET_TEMPLATES;
  templates: ContractTemplateProps[];
}

interface setView {
  type: typeof SET_VIEW;
  view: View;
}

export type Action =
  | setFetchingTemplates
  | setFilters
  | setNewTemplate
  | setSubmittingTemplate
  | setSubmitResponse
  | setTemplates
  | setView;

export type Dispatch = (action: Action) => void;
