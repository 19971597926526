import { Grid } from "@material-ui/core";

import CustomTabs from "Components/Admin/UIComponents/CustomTabs";
import { usePlans } from "context/plansContext/plansContext";
import MonthlyTable from "./MonthValue";
import useStyles from "./MonthlyDatailsStyles";

export default function MonthlyDetails() {
  const { state } = usePlans();

  const getMonthValues = (type: string) =>
    state.planData.monthlyData.map((data) => ({
      month: data.month,
      value: data[`${type}`],
      name: type,
    }));

  const tabs = [
    {
      index: 0,
      header: "Cuotas de administración",
      component: <MonthlyTable monthValues={getMonthValues("managementFee")} disabled={true} />,
    },
    {
      index: 1,
      header: "Cuotas pago arriendo propietario",
      component: <MonthlyTable monthValues={getMonthValues("lessorPayment")} disabled={true} />,
    },
    {
      index: 2,
      header: "Cuotas pago arrendatario",
      component: <MonthlyTable monthValues={getMonthValues("lesseePayment")} disabled={true} />,
    },
    {
      index: 3,
      header: "Corretaje arrendatario",
      component: <MonthlyTable monthValues={getMonthValues("lesseeBrokerage")} disabled={true} />,
    },
    {
      index: 4,
      header: "Corretaje propietario",
      component: <MonthlyTable monthValues={getMonthValues("lessorBrokerage")} disabled={true} />,
    },
  ];

  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <CustomTabs
        tabsContent={tabs}
        aria="plans"
        tabsProps={{ classes: { root: classes.tabs } }}
        customIndex={state.currentTab}
      />
    </Grid>
  );
}
