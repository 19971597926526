import { TypologyProvider } from "context/multifamilyContext/typology/typologyContext";
import { MultifamilyUnitProvider } from "context/multifamilyContext/unit/unitContext";
import { MultifamilyProvider } from "context/multifamilyContext/multifamily/multifamilyContext";
import { TableProvider } from "context/tableContext/tableContext";

import MultifamilyUnit from "./MultifamilyUnit";

function MultifamilyUnitIndex() {
  return (
    <MultifamilyProvider>
      <TypologyProvider>
        <MultifamilyUnitProvider>
          <TableProvider>
            <MultifamilyUnit />
          </TableProvider>
        </MultifamilyUnitProvider>
      </TypologyProvider>
    </MultifamilyProvider>
  );
}

export default MultifamilyUnitIndex;
