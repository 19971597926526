import Swal from "sweetalert2";

import { CountryUpperCase } from "models/Countries";
import contractService from "services/contractService";

import {
  Action,
  Dispatch,
  State,
  SET_CONTRACT_ID,
  SET_COUNTRY,
  SET_DOCUMENT_TYPES,
  SET_FETCHING_PERSON,
  SET_IS_NEW_PERSON,
  SET_PERSON,
  SET_SEARCH_PARAMS,
  DocumentsProps,
  DocumentTypeProps,
  PersonProps,
  SET_TITLE,
  SET_PROPERTY_ID,
  SET_REDIRECTION_URL,
} from "./contractPersonTypes";
import { frontParsePerson } from "./utils";

export const setContractId = (contractId: string): Action => ({
  type: SET_CONTRACT_ID,
  contractId,
});

export const setCountry = (country: CountryUpperCase): Action => ({
  type: SET_COUNTRY,
  country,
});

export const setDocumentTypes = (documentTypes: DocumentTypeProps[]): Action => ({
  type: SET_DOCUMENT_TYPES,
  documentTypes,
});

export const setIsNewPerson = (isNewPerson: boolean): Action => ({
  type: SET_IS_NEW_PERSON,
  isNewPerson,
});

export const setFetchingPerson = (fetchingPerson: boolean): Action => ({
  type: SET_FETCHING_PERSON,
  fetchingPerson,
});

export const setPerson = (person: PersonProps): Action => ({
  type: SET_PERSON,
  person,
});

export const setPropertyId = (propertyId: number): Action => ({
  type: SET_PROPERTY_ID,
  propertyId,
});

export const setRedirectionUrl = (redirectionUrl: string): Action => ({
  type: SET_REDIRECTION_URL,
  redirectionUrl,
});

export const setSearchParams = (searchParams: DocumentsProps): Action => ({
  type: SET_SEARCH_PARAMS,
  searchParams,
});

export const setTitle = (title: string): Action => ({
  type: SET_TITLE,
  title,
});

export function initializeState(
  state: State,
  dispatch: Dispatch,
  title: string,
  country: CountryUpperCase,
  propertyId: number,
  redirectionUrl: string,
  paramsRole: string,
  contractId: string,
  contractPersonId: string,
  authToken: string
) {
  const isLessor = window.location.search.includes("isLessor");
  const isCodebtor = window.location.search.includes("isCodebtor");
  const isLegalRepresentative = window.location.search.includes("isLegalRepresentative");

  // define title
  if (title) {
    dispatch(setTitle(title));
  } else {
    let auxTitle = "";
    if (isLegalRepresentative) {
      auxTitle = "representante legal del ";
    }
    if (isLessor) {
      dispatch(setTitle(`${auxTitle}Propietario`));
    } else {
      dispatch(setTitle(`${auxTitle}Arrendatario`));
    }
    if (isCodebtor) {
      dispatch(setTitle("Codeudor"));
    }
  }

  // define country
  dispatch(setCountry(country));

  // define property id
  if (propertyId) dispatch(setPropertyId(propertyId));

  // define redirection url
  if (redirectionUrl) dispatch(setRedirectionUrl(redirectionUrl));

  // define role
  let role;
  if (paramsRole) {
    role = paramsRole;
  } else if (isLegalRepresentative) {
    role = isLessor ? "lessor_legal_representative" : "lessee_legal_representative";
  } else if (isCodebtor) {
    role = "codebtor";
  } else {
    role = isLessor ? "owner" : "tenant";
  }
  dispatch(setPerson({ ...state.person, role }));

  // define contract ID
  if (contractId) {
    dispatch(setContractId(contractId));
  }

  // get ContractPerson if it exists
  if (!state.person.ready) {
    let personData;
    if (contractPersonId) {
      (async () => {
        personData = await contractService.getPerson(contractPersonId, authToken);
      })();
    }
    if (personData) {
      dispatch(setPerson(frontParsePerson(personData.data, state)));
    }
  }
}

export async function fetchPerson(authToken: string, state: State, dispatch: Dispatch) {
  dispatch(setFetchingPerson(true));
  try {
    const res = await contractService.getPersonByDocument(
      state.searchParams.document,
      state.searchParams.documentType.id.toString(),
      authToken
    );

    let person = { ready: true };
    if (state.searchParams.documentType?.type === "RFC") {
      person["rfc"] = state.searchParams.document;
      person["document"] = "";
      person["documentType"] = undefined;
    }
    if (res?.status === 200) {
      dispatch(setIsNewPerson(false));
      if (res.data.document_type === 9) {
        person = {
          ...frontParsePerson(res.data, state),
          ...person,
        };
      } else {
        person = frontParsePerson(res.data, state);
      }
    } else if (res.response?.status === 404) {
      dispatch(setIsNewPerson(true));
      if (state.searchParams.documentType?.type !== "RFC") {
        person["document"] = state.searchParams.document;
        if (state.searchParams.documentType) {
          person["documentType"] = state.searchParams.documentType;
        }
      }
    }
    dispatch(setPerson({ ...state.person, ...person }));
  } catch (e) {
    Swal.fire("Error", "No se pudo cargar la información", "error");
  } finally {
    dispatch(setFetchingPerson(false));
  }
}

export const submitCreateContract = async (
  data: any,
  authToken,
  redirect: (url: string) => void
) => {
  try {
    const res = await contractService.create(data, authToken);
    redirect(`/admin/contracts/${res.data.contract_id}`);
  } catch (e) {
    console.error(e);
    Swal.fire("Error", `Ocurrió algo inesperado. ${e}`);
  }
};

export const submitCreateContractPerson = async (data: any, authToken: string, state: State) => {
  try {
    if (
      state.person.isLegalRepresentative ||
      state.person.role === "lessor_legal_representative" ||
      state.person.role === "lessee_legal_representative"
    ) {
      await contractService.registerLegalRepresentative(state.contractId, data, authToken);
    } else {
      await contractService.createPerson(state.contractId, data, authToken);
    }
    if (state.redirectionUrl) {
      window.location.replace(state.redirectionUrl);
    } else {
      window.history.back();
    }
  } catch {
    Swal.fire("Error", `Ocurrió un error. Rol enviado: ${data.role}`, "error");
  }
};
