import { CountryUpperCase } from "models/Countries";
import { StringOption } from "models/Option";
import macrozoneService from "services/macrozoneService";

import {
  Dispatch,
  ActionType,
  Action,
  MacrozoneByCity,
  MacrozoneSource,
  ModalMessage,
  Loaders,
  LoaderType,
} from "./macrozonesTypes";

import { groupMcByCity, getCityFromMacrozoneList, addSourceToMacrozone } from "./macrozonesUtils";

export const setCities = (cities: StringOption[]): Action => ({
  type: ActionType.SET_CITIES,
  cities,
});

export const setMacrozoneList = (macrozoneList: MacrozoneSource[]): Action => ({
  type: ActionType.SET_MACROZONES,
  macrozoneList,
});

export const setMcByCity = (macrozonesByCity: Record<string, MacrozoneByCity[]>): Action => ({
  type: ActionType.SET_MC_BY_CITY,
  macrozonesByCity,
});

export const setModalMessage = (modalMessage: ModalMessage): Action => ({
  type: ActionType.SET_MODAL_MESSAGE,
  modalMessage,
});

export const setLoaders = (loaders: Loaders): Action => ({
  type: ActionType.SET_LOADER,
  loaders,
});

export const getMacrozonesByCountry = async (
  country: CountryUpperCase,
  dispatch: Dispatch,
  token: string,
  cityId?: number
) => {
  try {
    dispatch(
      setLoaders({
        [LoaderType.GET_MC]: true,
      })
    );
    const getMacrozonesReq = await macrozoneService.getMacrozones(token, country, cityId);

    const byCity = groupMcByCity(getMacrozonesReq.data);

    const cities: StringOption[] = getCityFromMacrozoneList(byCity);

    dispatch(setCities(cities));
    dispatch(setMcByCity(byCity));
  } catch (e) {
    dispatch(
      setModalMessage({
        isOpen: true,
        title: "Ha ocurrido un error",
      })
    );
  } finally {
    dispatch(
      setLoaders({
        [LoaderType.GET_MC]: false,
      })
    );
  }
};

export const updateMacrozones = (
  dispatch: Dispatch,
  selectedCities: StringOption[],
  macrozoneCities: Record<string, MacrozoneByCity[]>
) => {
  if (!selectedCities) return;
  const allMacrozones = selectedCities.reduce(
    (prev, curr) => [...prev, ...macrozoneCities[curr.value]],
    []
  ) as MacrozoneByCity[];

  const macrozoneWithSource: MacrozoneSource[] = addSourceToMacrozone(allMacrozones);
  dispatch(setMacrozoneList(macrozoneWithSource));
};
