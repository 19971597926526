import { Box } from "@material-ui/core";

import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { Locale } from "models/Countries";

import useHeaderWithCountriesStyles from "./HeaderWithCountriesStyles";
import CountrySelector from "../CountrySelector";

interface Props {
  country?: Locale;
  showCountry?: boolean;
  onChangeCountry: (c: Locale) => void;
  breadcrumbsSteps: { link?: string; label: string }[];
  children?: React.ReactNode;
}

const HeaderWithCountries = ({
  country = "cl",
  onChangeCountry,
  showCountry = true,
  breadcrumbsSteps,
  children,
}: Props) => {
  const classes = useHeaderWithCountriesStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Breadcrumbs steps={breadcrumbsSteps} />
        {showCountry && <CountrySelector stateCountry={country} onChange={onChangeCountry} />}
      </Box>
      {children}
    </Box>
  );
};

export default HeaderWithCountries;
