import { makeStyles, Theme } from "@material-ui/core";

const useCellsStyles = makeStyles((theme: Theme) => ({
  symbolContainer: {
    display: "flex",
    gap: theme.spacing(11 / 8),
    justifyContent: "center",
    alignItems: "center",
  },

  blackUnderline: {
    textDecoration: "underline !important",
    color: "#000000 !important",
  },
}));

export default useCellsStyles;
