import { useEffect } from "react";

import LeadVisits from "Components/Admin/administrator/leads/leadProfile/components/LeadVisits";
import LeadAvailabilityForm from "Components/Admin/administrator/leads/leadProfile/components/LeadAvailabilityForm";
import LeadQualificationItem from "Components/Admin/administrator/leads/leadProfile/components/LeadQualification";
import ToggleBox from "Components/Admin/administrator/leads/leadProfile/components/ToggleBox";
import { useLead } from "context/leadContext/leadContext";
import { getLeadProperties } from "context/leadContext/leadActions";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import { useTable } from "context/tableContext/tableContext";
import { useGetToken } from "hooks";

function LeadVisitsTab() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();
  const { state: availabilityState } = useAvailability();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;

  useEffect(() => {
    getToken().then((authToken) => {
      if (state?.lead)
        getLeadProperties(pageSize, currentPage, state?.lead?.email, dispatch, authToken);
    });
  }, [state?.lead, pageSize, currentPage]);

  return (
    <>
      <ToggleBox title="Lead" open={false}>
        <LeadQualificationItem />
      </ToggleBox>
      {availabilityState?.multiPropertyId.length > 0 ? <LeadAvailabilityForm /> : <LeadVisits />}
    </>
  );
}

export default LeadVisitsTab;
