import { Box, BoxProps } from "@material-ui/core";

import { MainText } from "../typography";

interface Props extends BoxProps {
  requiredData?: any;
}

function BaseField({ children, requiredData, ...props }: Props) {
  if (requiredData) {
    return (
      <Box width="100%" {...props}>
        {children}
      </Box>
    );
  }
  return <MainText>Sin registrar</MainText>;
}

export default BaseField;
