import { makeStyles } from "@material-ui/core";

const useTagBarStyles = makeStyles((theme) => ({
  tagsContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    paddingBottom: theme.spacing(2),
  },
}));
export default useTagBarStyles;
