import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useDocumentsCardStyles from "./DocumentsCardStyles";
import DocumentsDialog from "./DocumentsDialog";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import { DocumentData } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { uploadFile } from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import { useGetToken } from "hooks";
import FileCard from "./FileCard";

function DocumentsCard() {
  const classes = useDocumentsCardStyles();
  const [openDocs, setOpenDocs] = useState(false);
  const [document, setDocument] = useState<DocumentData>(null);
  const hiddenFileInput = React.useRef(null);

  const { getToken } = useGetToken();
  const { state, dispatch } = useLetterOfIntent();
  const { documents, country, loiId } = state;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const file: File = event.target.files[0];
    getToken().then((authToken) =>
      uploadFile({ file, country, loiId, token: authToken, dispatch })
    );
  };

  const displayDocument = (document: DocumentData) => {
    setOpenDocs(true);
    setDocument(document);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.title}>Documentos cargados</Typography>
        <Grid container spacing={2}>
          {documents?.map((doc) => (
            <Grid item xs={6} key={doc.id}>
              <FileCard document={doc} displayDocument={displayDocument} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box className={classes.buttonContainer}>
        <DesignSystemButton
          label="Agregar Documento"
          size="medium"
          variant="tertiaryB"
          buttonClassName={classes.buttonSecondary}
          onClick={handleClick}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </Box>
      <Dialog
        open={openDocs}
        onClose={() => setOpenDocs(false)}
        scroll="paper"
        fullScreen
        transitionDuration={500}>
        <IconButton
          className={classes.closeButton}
          onClick={() => {
            setOpenDocs(false);
          }}>
          <GridCloseIcon />
        </IconButton>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            Documentos
          </Box>
        </DialogTitle>
        <DialogContent>
          <DocumentsDialog document={document} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default DocumentsCard;
