import { makeStyles, Theme } from "@material-ui/core";

const useMovementInfoStyles = makeStyles<Theme>((theme) => ({
  details: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2),
  },
}));

export default useMovementInfoStyles;
