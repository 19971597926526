import { SnackbarProvider } from "notistack";

import { Typography } from "@material-ui/core";

import { CountryUpperCase } from "models/Countries";

import useSelectPromotionStyles from "./SelectPromotionStyles";
import SelectPromotionForm from "../SelectPromotionForm";

interface Props {
  country: CountryUpperCase;
  contractId: number;
  appliedPromotions: number[];
  onFetchSimulation: ({ contractId, save }) => void;
}

const SelectPromotion = ({ country, contractId, appliedPromotions, onFetchSimulation }: Props) => {
  const classes = useSelectPromotionStyles();

  return (
    <>
      <Typography className={classes.title}>Aplicar promoción</Typography>
      <SnackbarProvider>
        <SelectPromotionForm
          country={country}
          contractId={contractId}
          appliedPromotions={appliedPromotions}
          onFetchSimulation={onFetchSimulation}
        />
      </SnackbarProvider>
    </>
  );
};

export default SelectPromotion;
