import { useEffect, useState } from "react";

import { InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Option from "./components/Option";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import useStyles from "./RemoteSuggesterStyles";
import useGetFilteredData from "./hooks/useGetFilteredData";
import useDebouncedSearchQuery from "hooks/useDebouncedSearchQuery";
import { OptionProps, RemoteSuggesterProps } from "./types";

function RemoteSuggester<T>({
  label,
  value,
  onChange,
  defaultValue,
  textFieldProps,
  indexService,
  filters,
  icon,
  parser = (val) => val as unknown as OptionProps[],
  queryParamName,
  noOptionsText = "No se encontraron resultados",
  loadingText = "Buscando...",
  classes: propClasses,
  disabled,
}: RemoteSuggesterProps<T>) {
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState<OptionProps | null>(value);

  const { handleOnDebouncedSearchChange, debouncedQuery } = useDebouncedSearchQuery();

  const { results, loading } = useGetFilteredData({
    indexService,
    filters,
    inputQuery: debouncedQuery,
    parser,
    queryParamName,
  });

  const handleOnChangeOption = (option: OptionProps) => {
    setSelectedOption(option);
    onChange(option);
  };

  useEffect(() => {
    if (defaultValue) setSelectedOption(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!value) setSelectedOption(null);
  }, [value]);

  return (
    <Autocomplete
      noOptionsText={noOptionsText}
      className={propClasses?.root}
      defaultValue={defaultValue}
      value={selectedOption}
      disabled={disabled || loading}
      loading={loading}
      loadingText={loadingText}
      options={results}
      onChange={(_, option) => handleOnChangeOption(option as OptionProps)}
      onInputChange={(e, val) => handleOnDebouncedSearchChange(val)}
      getOptionLabel={(option) => `${option.label}`}
      renderOption={(option) => <Option label={option.label} />}
      renderInput={(params) => (
        <CustomTextField
          fullWidth
          label={label}
          {...params}
          labelClassName={propClasses?.label}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.grayColor,
            },
            disableUnderline: false,
            startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
          }}
          {...textFieldProps}
        />
      )}
    />
  );
}
export default RemoteSuggester;
