import { Chip, Grid, Typography } from "@material-ui/core";
import { SearchOutlined, CloseOutlined } from "@material-ui/icons";

import useDebouncedSearchQuery from "hooks/useDebouncedSearchQuery";
import BasicTextSearchBar from "Components/Admin/UIComponents/molecules/BasicTextSearchBar";
import { useTag } from "context/tagContext/tagContext";
import { setForm, validateProjectId } from "context/tagContext/tagActions";

import useStyles from "../useTagFormStyles";
import { useEffect } from "react";
import { useGetToken } from "hooks";

function SearchID() {
  const classes = useStyles();

  const { state, dispatch } = useTag();
  const { getToken } = useGetToken();

  const { query, handleOnDebouncedSearchChange, debouncedQuery } = useDebouncedSearchQuery();

  useEffect(() => {
    if (debouncedQuery) {
      getToken().then((authToken) => validateProjectId(debouncedQuery, dispatch, authToken));
    }
  }, [debouncedQuery]);

  const handleDelete = (id) => {
    const newIds = state?.form?.ids.filter((item) => item !== id);
    dispatch(setForm({ ...state?.form, ids: newIds }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !state.idError) {
      dispatch(
        setForm({
          ...state?.form,
          ids: [...(state?.form?.ids || []), debouncedQuery],
        })
      );
      handleOnDebouncedSearchChange("");
    }
  };

  return (
    <Grid item xs={12} className={classes.formItem}>
      <Typography className={classes.idsTitle}>Búsqueda de IDs</Typography>
      {!state.form?.associationId && (
        <div className={classes.searchId}>
          <BasicTextSearchBar
            value={query}
            onChange={(e) => handleOnDebouncedSearchChange(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              startAdornment: <SearchOutlined color="primary" />,
              placeholder: "Búsqueda de ID",
            }}
            type="number"
            disabled={Boolean(state.form?.associationId)}
          />
          {state.idError && (
            <Typography component="span">
              El ID ingresado no existe o no corresponde a un proyecto / desarrollo
            </Typography>
          )}
        </div>
      )}
      {state?.form?.ids?.map((id) => (
        <Chip
          label={id}
          className={classes.chip}
          variant="outlined"
          onDelete={() => handleDelete(id)}
          deleteIcon={<CloseOutlined />}
        />
      ))}
    </Grid>
  );
}

export default SearchID;
