import { makeStyles, Theme } from "@material-ui/core";

const useTagsStyles = makeStyles<Theme, { textColor: string; backColor: string }>((theme) => ({
  chip: {
    borderRadius: 20,
    width: 150,
    color: (props) => props.textColor,
    height: 30,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    backgroundColor: (props) => props.backColor,
  },
}));

export default useTagsStyles;
