import { useLocalStorage } from "hooks";

import Tag from "Components/Admin/administrator/demand/components/Tag";
import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import { setFilters } from "context/demandLeadContext/leads/demandLeadActions";
import useTagBarStyles from "./TagBarStyles";

function TagBar() {
  const classes = useTagBarStyles();
  const { setValue } = useLocalStorage("filters", {});
  const {
    state: { filters },
    dispatch,
  } = useDemandLead();
  const handleRemove = (filter: string) => {
    dispatch(
      setFilters({
        ...filters,
        [filter]: null,
      })
    );
    setValue({
      leadQualifier: filters.leadQualifier,
      origin: filters.origin,
      state: filters.state,
      [filter]: null,
    });
  };

  const handleRemoveState = (value: { label: string }) => {
    const newState = [...filters.state].filter((item) => item.label !== value.label);
    dispatch(
      setFilters({
        ...filters,
        state: newState,
      })
    );
  };

  return (
    <div className={classes.tagsContainer}>
      {filters.leadQualifier && (
        <Tag id="leadQualifierTag" onRemove={() => handleRemove("leadQualifier")}>
          {filters.leadQualifier?.label}
        </Tag>
      )}
      {filters.origin && (
        <Tag id="originTag" onRemove={() => handleRemove("origin")}>
          {filters.origin?.label}
        </Tag>
      )}
      {filters.state.map((item) => (
        <Tag key={item.label} onRemove={() => handleRemoveState(item)}>
          {item.label}
        </Tag>
      ))}
      {filters.date && <Tag onRemove={() => handleRemove("date")}>{filters.date?.label}</Tag>}
    </div>
  );
}

export default TagBar;
