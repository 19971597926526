import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";
import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";

import BaseField from "../../../BaseField";
import useSimpleTagFieldStyles from "./SimpleTagFieldStyles";

interface Props {
  value: string;
}

function SimpleTagField({ value }: Props) {
  const classes = useSimpleTagFieldStyles();
  return (
    <BaseField requiredData={value}>
      <DSTag
        type={TagType.LIGHT_GREY}
        text={value}
        classes={{ wrapperClassName: classes.tagWrapper }}
        tooltip
        tooltipProps={{ placement: "bottom-start" }}
      />
    </BaseField>
  );
}

export default SimpleTagField;
