import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { add } from "date-fns";

import { useFeatureManager } from "@houm-com/feature-manager";
import ModalDialog from "@houm-com/ui/ModalDialog";

import { InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import {
  ControlledList,
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import ControlledNumber from "Components/Admin/UIComponents/ControlledForm/ControlledNumber";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { isWeekendDay } from "utils/datetime";
import { useFormNotifications } from "hooks";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

import { spotSchema, requiredText } from "../../utils/validationSchema";
import useSpotPaymentFormStyles from "./SpotPaymentFormStyles";
import useSpotPayment from "../../hooks/useSpotPayment";
import { IFormData } from "../../utils/utils";

interface Props {
  propertyId: number;
  userId: number;
  subscriptionId: number;
  country: Locale;
  handleOnCloseModal: () => void;
}

function SpotPaymentForm({
  propertyId,
  userId,
  subscriptionId,
  country,
  handleOnCloseModal,
}: Props) {
  const { data: reasonsData, status: reasonsStatus } = useFeatureManager("spot_payment_reasons");
  const { mutate, isLoading } = useSpotPayment({
    propertyId,
    userId,
    subscriptionId,
    country,
    onHandleClose: handleOnCloseModal,
  });

  const classes = useSpotPaymentFormStyles();
  const [reasonOther, setReasonOther] = useState(false);

  const methods = useForm<IFormData>({
    resolver: yupResolver(spotSchema(reasonOther, countryCode(country))),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const descriptionValue = methods.watch("reasonType");

  useEffect(() => {
    const flag = descriptionValue === "Otro";
    setReasonOther(flag);
  }, [descriptionValue]);

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <ModalDialog.Content>
        <Loading loading={reasonsStatus === "loading"}>
          <form className={classes.formContent}>
            <Grid item container alignItems="flex-start">
              <Grid item xs={12}>
                <ControlledNumber
                  label="Monto"
                  id="amount"
                  defaultValue=""
                  textFieldProps={{
                    InputProps: {
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    },
                    type: "number",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledList
                  label="Motivo"
                  id="reasonType"
                  defaultValue=""
                  disableUnderline
                  options={reasonsData?.content ?? []}
                  classes={{
                    select: classes.select,
                  }}
                />
              </Grid>
              {descriptionValue === "Otro" && (
                <Grid item xs={12}>
                  <ControlledTextField
                    label="Descripción"
                    id="descriptionAux"
                    defaultValue=""
                    textFieldProps={{
                      multiline: true,
                      minRows: 3,
                      InputProps: {
                        classes: {
                          root: classes.descriptionInput,
                        },
                      },
                      inputProps: {
                        maxlength: 40,
                        placeholder: "Ingrese una descripción de máximo 40 caracteres.",
                      },
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ControlledKeyboardDatePicker
                  id="paymentDate"
                  label="Fecha de pago"
                  defaultValue={add(new Date(), { days: 1 })}
                  dataPickerProps={{
                    minDate: add(new Date(), { days: 1 }),
                    format: "dd/MM/yyyy",
                    shouldDisableDate: (e) => isWeekendDay(e),
                  }}
                />
              </Grid>
              {country === "cl" && (
                <Grid item xs={12}>
                  <ControlledSelection
                    id="currency"
                    label="Tipo de moneda"
                    defaultValue="CLP"
                    options={[
                      { value: "CLP", label: "Pesos" },
                      { value: "UF", label: "UF" },
                    ]}
                    classes={{
                      label: classes.selectionLabel,
                      container: classes.selectionContainer,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <ModalDialog.Actions
              onSubmit={methods.handleSubmit(onSubmit)}
              submitButtonLabel="Crear pago spot"
              buttonsPosition="center"
              submitButtonProps={{
                type: "button",
                isLoading,
              }}
            />
          </form>
        </Loading>
      </ModalDialog.Content>
    </FormProvider>
  );
}

export default SpotPaymentForm;
