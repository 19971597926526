import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService";

import { parseGetHomeCheckerDetailResponse } from "context/propertyContext/utils";
import { IHomeCheckerDetails } from "models";
import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";

const useHomeCheckerDetails = (id: string) => {
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();
  const { isLoading, data }: UseQueryResult<IHomeCheckerDetails, Error> = useQuery<
    IHomeCheckerDetails,
    Error
  >(
    ["homeCheckerDetails", id],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.homeCheckerDetails(id, authToken);
      return parseGetHomeCheckerDetailResponse(response.data);
    },
    {
      onError: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error",
            type: "error",
            body: `Hubo un error al obtener la información de adinistración`,
          })
        );
      },
    }
  );
  return {
    isLoading,
    data,
  };
};

export default useHomeCheckerDetails;
