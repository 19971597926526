import { UseQueryResult, useQuery } from "react-query";

import { Statistics, StatisticsResponseSchema } from "domains/evaluations/models/Evaluations";
import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useGetStatistics = (country: Locale) => {
  const { getToken } = useGetToken();

  const query: UseQueryResult<Statistics, Error> = useQuery<Statistics, Error>(
    ["evaluation_statistics", country],
    async () => {
      const authToken = await getToken();
      const { data } = await service.getStatistics(authToken, country);
      return StatisticsResponseSchema.parse(data);
    },
    {
      enabled: !!country,
    }
  );

  return {
    ...query,
  };
};

export default useGetStatistics;
