import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { deliveryStatusOptions } from "context/projectContext/projectConsts";
import { useProject } from "context/projectContext/projectContext";
import { useFormContext, useWatch } from "react-hook-form";
import ControlledOptions from "../ControlledForm/ControlledOptions";

function DeliveryStatus() {
  const { control } = useFormContext();
  const { state } = useProject();

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.project?.property?.country,
  });
  return (
    <FormBlock>
      <ControlledOptions
        id="development_status"
        label="Estado de entrega de proyecto / departamento"
        options={deliveryStatusOptions(country)}
        defaultValue={state?.project?.development_status}
      />
    </FormBlock>
  );
}

export default DeliveryStatus;
