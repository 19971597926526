// @ts-nocheck
import moment from "moment";

import { GridCellParams, GridColDef, GridColumns, GridSortModel, esES } from "@material-ui/x-grid";

import { CustomLink, CustomDataGrid } from "Components/Admin/UIComponents";
import { useContract } from "context/contractContext/contractContext";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import useTableStyles from "Components/Admin/administrator/demand/leads/components/table/TableStyles";
import CustomCell from "Components/Admin/administrator/demand/leads/components/table/Cells";

interface Props {
  status?: string;
}

function Table({ status }: Props) {
  const classes = useTableStyles();
  const { state } = useContract();
  const { loading, contractList, numberOfContracts } = state;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize, ordering } = tableState;

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    filterable: false,
    flex: 1,
    hideSortIcons: true,
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      field: "id",
      headerName: "ID",
      flex: 0.6,
      renderCell: ({ value }: GridCellParams) => <CustomCell direction="left">{value}</CustomCell>,
    },
    {
      ...columnsConfig,
      field: "mainTenantName",
      headerName: "Postulante principal",
      sortable: false,
      renderCell: ({ value }: GridCellParams) => <CustomCell direction="left">{value}</CustomCell>,
    },
    {
      ...columnsConfig,
      field: "ownerName",
      headerName: "Propietario",
      sortable: false,
      renderCell: ({ value }: GridCellParams) => <CustomCell direction="left">{value}</CustomCell>,
    },
    {
      ...columnsConfig,
      field: "houmOwnerName",
      headerName: "Ejecutivo",
      sortable: false,
      renderCell: ({ value }: GridCellParams) => <CustomCell direction="left">{value}</CustomCell>,
    },
    {
      ...columnsConfig,
      field: "adress",
      headerName: "Propiedad",
      sortable: false,
      align: "left",
      renderCell: ({ row }) => (
        <CustomLink
          to={`/admin/properties/${row.propertyUid}`}
          text={`[${row.propertyId}] ${row.propertyAddress} ${row.propertyStreet}, dpto ${row.propertyNumberDpto}`}
        />
      ),
    },
    {
      ...columnsConfig,
      field: "createdAt",
      headerName: "Fecha aprobación comercial",
      width: 250,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <CustomCell direction="left">{moment(row.createdAt).format("LLLL")}</CustomCell>
      ),
    },
    {
      ...columnsConfig,
      field: "link",
      headerName: "Acciones",
      width: 100,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <CustomLink to={`/admin/contracts/${row.id}`} text="Ver" />
      ),
    },
  ];

  const handleSort = (model: GridSortModel) => {
    let newOrdering: string | null = null;
    if (model.length > 0) {
      newOrdering = `${model[0].sort === "asc" ? "" : "-"}${model[0].field}`;
    }
    if (newOrdering !== ordering) {
      dispatch(setSorting(model));
    }
  };

  return (
    <div className={classes.container}>
      <CustomDataGrid
        className={classes.table}
        rowCount={numberOfContracts}
        columns={columns}
        rows={contractList}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={handleSort}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        localeText={esES.props.MuiDataGrid.localeText}
      />
    </div>
  );
}

export default Table;
