import { IParsePlan } from "hooks/usePlans/utils/types";

import PlanCard from "./PlanCard";
import classes from "./PlanCardsStyles";

interface Props {
  searchBar: string;
  plans: IParsePlan[];
}

export default function PlanCards({ searchBar, plans }: Props) {
  return (
    <div className={classes.container}>
      {plans
        .filter((plan) =>
          searchBar ? plan.name?.toLocaleLowerCase().includes(searchBar?.toLocaleLowerCase()) : true
        )
        .map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
    </div>
  );
}
