import { Button, ButtonProps } from "@material-ui/core";
import useScheduleButtonStyles from "./ScheduleButtonStyle";

function ScheduleButton(props: ButtonProps) {
  const classes = useScheduleButtonStyles();

  return (
    <Button
      className={classes.scheduleButton}
      disableElevation
      variant="contained"
      color="primary"
      fullWidth
      {...props}
    />
  );
}

export default ScheduleButton;
