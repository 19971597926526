import { Locale } from "models/Countries";

import { AdminCard } from "../../Cards/AdminCard";
import { BasicService } from "../utils/types";
import CommonExpensesHeader from "./components/CommonExpensesHeader";
import CommonExpense from "./components/CommonExpense";
import CommonExpensesModal from "./components/CommonExpensesModal";
import CommonExpensesFooter from "./components/CommonExpensesFooter";

interface Props {
  country: Locale;
  openModal: boolean;
  setOpenModal: (b: boolean) => void;
  propertyServicesLoading: boolean;
  propertyServices: BasicService[];
  updateServices: () => void;
  updateLoading: boolean;
  updating: boolean;
  propertyId: number;
  backgroundTitle: boolean;
}

const CommonExpenses = ({
  country,
  openModal,
  setOpenModal,
  propertyServicesLoading,
  propertyServices,
  updateServices,
  updateLoading,
  updating,
  propertyId,
  backgroundTitle,
}: Props) => (
  <AdminCard
    title="Gastos comunes"
    withSpace={false}
    showTitleBackground={backgroundTitle}
    id="commonExpenses">
    <CommonExpensesHeader
      hasCommonExpenses={propertyServices?.length > 0}
      addCommonExpense={() => {
        setOpenModal(true);
      }}
    />

    <CommonExpensesModal
      propertyId={propertyId}
      open={openModal}
      handleClose={() => setOpenModal(false)}
      country={country}
    />

    <CommonExpense
      commonExpenses={propertyServices}
      country={country}
      loading={propertyServicesLoading}
      propertyId={propertyId}
    />

    <CommonExpensesFooter
      hasCommonExpenses={propertyServices?.length > 0}
      updating={updating}
      updateLoading={updateLoading}
      updateServices={updateServices}
    />
  </AdminCard>
);

export default CommonExpenses;
