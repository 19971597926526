import { isValidPhoneNumber } from "libphonenumber-js/min";

export function getVerifyPhoneNumber(phone: string, countryCode, error: boolean) {
  if (phone.length === 0) {
    return error ? "" : true;
  } else {
    return isValidPhoneNumber(phone, countryCode);
  }
}

export function strNormalize(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s/g, "-")
    .toLowerCase();
}

export function getCountryPhone(str: string) {
  if (!str) return;
  const chileValues = {
    callingCode: "56",
    countryCode: "CL",
    countryName: "Chile",
  };
  const colombiaValues = {
    callingCode: "57",
    countryCode: "CO",
    countryName: "Colombia",
  };
  const mexicoValues = {
    callingCode: "52",
    countryCode: "MX",
    countryName: "México",
  };
  const values = {
    cl: chileValues,
    chile: chileValues,
    co: colombiaValues,
    colombia: colombiaValues,
    mx: mexicoValues,
    mexico: mexicoValues,
    méxico: mexicoValues,
  };
  return values[str.toLocaleLowerCase()];
}

export const getCodelessNumber = (phoneNumberWithCode: string, country: string): string => {
  if (!country || !phoneNumberWithCode) return phoneNumberWithCode;
  let codelessNumber = phoneNumberWithCode;

  try {
    if (phoneNumberWithCode[0] === "+") {
      return phoneNumberWithCode.replace(`+${getCountryPhone(country)?.callingCode}`, "");
    }

    const code = codelessNumber.slice(0, 2);
    if (code === getCountryPhone(country)?.callingCode) {
      codelessNumber = codelessNumber.slice(2);
    }
  } catch (e) {
    return codelessNumber;
  }

  return codelessNumber;
};
