import { GridColDef } from "@material-ui/x-grid";

import { RepairmentType, RequestorRole } from "services/requestsService/types";

export const REPAIRMENT_TYPE_LABEL: Record<RepairmentType, string> = {
  plumbing: "Plomería / Gasfitería",
  locksmith: "Cerrajería",
  heating: "Calefacción",
  structural: "Estructurales",
  cosmetic_repairs: "Arreglos estéticos",
  electrical: "Eléctrico",
  electronic: "Electrodoméstico",
  cleaning: "Limpieza",
};

export const REQUESTOR_ROLE_LABEL: Record<RequestorRole, string> = {
  tenant: "Arrendatario",
  landlord: "Propietario",
};

export const COLUMNS_CONFIG: GridColDef = {
  field: "",
  resizable: true,
  filterable: false,
  align: "left",
  sortable: false,
};
