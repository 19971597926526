import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Divider, Typography } from "@material-ui/core";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import {
  ControlledDatePicker,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { setFilters, setIsOpenFilterModal } from "context/contractContext/contractActions";
import useFilterModalStyles from "./FilterModalStyles";
import { useContract } from "context/contractContext/contractContext";
import CallToAction from "./CallToAction";

export interface FilterProps {
  init_date: string;
  houm_owner: string;
}

function FilterModal() {
  const methods = useForm<FilterProps>();
  const {
    state: { isOpenFilterModal, filters },
    dispatch,
  } = useContract();
  const classes = useFilterModalStyles();

  const onSubmit: SubmitHandler<FilterProps> = (data) => {
    dispatch(setFilters({ ...filters, ...data }));
    closeModal();
  };
  const clearFilters = () => {
    methods.reset({ init_date: "", houm_owner: "" });
  };
  const closeModal = () => dispatch(setIsOpenFilterModal(false));

  return (
    <CustomDialog
      open={isOpenFilterModal}
      onClose={closeModal}
      subtitle={
        <div className={classes.subtitle}>
          <Typography>Selecciona los filtros para facilitar tu busqueda</Typography>
        </div>
      }
      classes={classes}
      title="Filtros">
      <FormProvider {...methods}>
        <div className={classes.formContainer}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formWrapper}>
            <ControlledDatePicker type="date" id="init_date" label="Fecha de inicio" />
            <Divider className={classes.divider} />
            <ControlledTextField
              id="houm_owner"
              label="Ejecutivo"
              textFieldProps={{
                placeholder: "Nombre, Apellido, RUT, Mail",
              }}
              defaultValue=""
            />
            <Divider className={classes.divider} />
            <CallToAction clearAction={clearFilters} />
          </form>
        </div>
      </FormProvider>
    </CustomDialog>
  );
}
export default FilterModal;
