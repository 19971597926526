import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import applicantService from "services/applicantService";
import { useGetToken } from "hooks";

import { ICreateCodebor } from "../utils/types";

const useCreateCodebtor = (handleModal: () => void) => {
  const { dispatch } = useAlert();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: ICreateCodebor) => {
      const authToken = await getToken();
      const response = await applicantService.codebtor(formData, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("applicant_by_id");
        handleModal();
        dispatch(
          setAlertInfo({
            open: true,
            head: "El codeudor se ha creado exitosamente",
            type: "success",
          })
        );
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: "No se pudo crear el codeudor",
            type: "error",
            body: `Error: ${reason?.data?.message ?? e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useCreateCodebtor;
