import { AxiosResponse } from "axios";
import qs from "query-string";

import { createHttp } from "services/helper";

export default {
  getLead(id, { ...filters }, token) {
    return createHttp(token).get(`/leads/v2/${id}/?${qs.stringify(filters)}`);
  },

  postLead(data, token) {
    return createHttp(token)
      .post("/v2/users/", data)
      .catch((error) => {
        console.error(`Petición con error ${error}`);
        return error;
      });
  },

  newPostLead(data, token) {
    return createHttp(token).post("/v2/users/", data);
  },

  updateLeadsQualification(id, data, token) {
    return createHttp(token).patch(`/leads/v2/${id}`, data);
  },

  getLeadActivities(leadId, filters, token) {
    return createHttp(token)
      .get(`/leads/v2/lead-activities/?lead=${leadId}&${qs.stringify(filters)}`)
      .catch((error) => {
        console.error(`Petición con error ${error}`);
        return error;
      });
  },

  postLeadActivity(data, token) {
    return createHttp(token)
      .post("/leads/v2/lead-activities/", data)
      .catch((error) => {
        console.error(`Petición con error ${error}`);
        return error;
      });
  },

  updateLeadActivity(id, data, token) {
    return createHttp(token)
      .patch(`/leads/v2/lead-activities/${id}/`, data)
      .catch((error) => {
        console.error(`Petición con error ${error}`);
        return error;
      });
  },

  postUser(
    data,
    token
  ): Promise<AxiosResponse<{ id?: number; token?: string; redirect?: string }>> {
    return createHttp(token).post("/v2/users/", data);
  },

  getLeadProperties(pageSize: number, page: number, email: string, token: string) {
    return createHttp(token).get(
      `admin/v2/properties/?landlord=${email}&page_size=${pageSize}&page=${page}`
    );
  },

  checkOverdueActivities(
    data,
    token
  ): Promise<AxiosResponse<{ have_missed_activities?: boolean }>> {
    return createHttp(token).post("/leads/v2/check_missed_activities/", data);
  },

  reassignLeads(leadQualifierId, data, token) {
    return createHttp(token).put(`/admin/v2/users/${leadQualifierId}/reassign_leads/`, data);
  },

  getLeadsIndexCampaignFilters(token: string) {
    return createHttp(token).get("/leads/v2/get_campaigns_options/");
  },

  getLeadsIndexCampaignNameFilters(city: string, vertical: string, token: string) {
    return createHttp(token).get(
      `/leads/v2/get_campaigns_options/?city_campaign=${city}&vertical=${vertical}&type=name`
    );
  },
};
