import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import { ControlledOptions } from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";

import { warrantyHolderSchema, requiredText } from "./utils/validationSchema";
import ModalLayout from "../../ModalLayout/ModalLayout";
import ModalButtons from "../../ModalButtons";

interface WarrantyHolderProps {
  warrantyPerson: "Houm" | "Lessor";
}

interface Props {
  open: boolean;
  onHandleClose: () => void;
}

const ChangeWarrantyHolder = ({ open, onHandleClose }: Props) => {
  const methods = useForm<WarrantyHolderProps>({
    resolver: yupResolver(warrantyHolderSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<WarrantyHolderProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<WarrantyHolderProps> = (data) => {};

  return (
    <ModalLayout open={open} handleOnClose={onHandleClose} title="Ubicación garantía">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item container alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <ControlledOptions
                id="personWarranty"
                label="Elige donde está ubicada la garantía de esta propiedad"
                defaultValue="Houm"
                options={[
                  { value: "Houm", label: "Houm" },
                  { value: "Lessor", label: "Propietario" },
                ]}
              />
            </Grid>
            <ModalButtons onHandleClose={() => onHandleClose()} />
          </Grid>
        </form>
      </FormProvider>
    </ModalLayout>
  );
};

export default ChangeWarrantyHolder;
