import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  icon: {
    color: "#52c41a",
  },
  avatar: {
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
  },
}));
