import { IContractTemplate } from "models/ContractTemplate";
import countryCode, { localeToCountry } from "utils/countryCode";

import { ContractTemplateProps } from "../contractTemplatesTypes";

export const backendParseTemplate = (data: ContractTemplateProps): FormData => {
  const formData = new FormData();
  formData.append("contract_general_template", data.contractFile);
  formData.append("country", localeToCountry[data.country] as "Chile" | "Colombia" | "Mexico");
  formData.append("mandate_general_template", data.mandateFile);
  formData.append("description", data.description);
  formData.append("name", data.name);
  return formData;
};

export const frontendParseTemplates = (data: IContractTemplate[]): ContractTemplateProps[] =>
  data.map((template) => ({
    contractLink: template.contract_general_template as string,
    country: countryCode(template.country),
    description: template.description,
    id: template.id,
    mandateLink: template.mandate_general_template as string,
    name: template.name,
  }));
