import { makeStyles } from "@material-ui/core";

const useSaveInformationStyles = makeStyles((theme) => ({
  gradeContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  options: {
    minWidth: 200,
  },
  gradeLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
  },
  actionButton: {
    textTransform: "none",
    borderRadius: "20px",
    minWidth: "150",
    fontWeight: 700,
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  advisoryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(2),
    margin: theme.spacing(0, 4, 4, 4),
  },
}));

export default useSaveInformationStyles;
