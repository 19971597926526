import { makeStyles, Theme } from "@material-ui/core";

const useModalLayoutStyles = makeStyles<Theme>((theme) => ({
  addBasicServiceRoot: {
    padding: theme.spacing(1, 4),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  icon: {
    marginRight: theme.spacing(3),
  },
}));

export default useModalLayoutStyles;
