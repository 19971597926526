import classnames from "classnames";
import useAutoCompleteStyles from "./CustomAutocompleteStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

interface Props<T> {
  id?: string;
  label: string;
  value?: T;
  options?: T[];
  labelPos?: "top" | "start";
  onChange?: (value: T) => void;
  color?: "light" | "strong";
  multiple?: boolean;
  placeholder?: string;
  containerClass?: string;
}
function CustomAutocomplete({
  id,
  label,
  value,
  onChange,
  options,
  labelPos = "start",
  color = "strong",
  multiple,
  placeholder,
  containerClass,
}: Props<any>) {
  const classes = useAutoCompleteStyles({ labelPos, color });
  return (
    <div className={classnames(classes.container, containerClass)}>
      {label && <p className={classes.label}>{label}</p>}
      <Autocomplete
        id={id}
        multiple={multiple}
        onChange={(_, value) => onChange(value)}
        className={classes.input}
        classes={{
          tag: classes.tag,
        }}
        getOptionLabel={(option) => (option.label ? option.label : option)}
        value={value}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
      />
    </div>
  );
}

export default CustomAutocomplete;
