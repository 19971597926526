import { useEffect } from "react";

import GeneralSpacesSummary from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/GeneralSpacesSummary";
import GeneralSpacesPhotoGallery from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/GeneralSpacesPhotoGallery";
import SpacesFilter from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/SpacesFilter";
import SpaceDetails from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/SpaceDetails";
import { useProperty } from "context/propertyContext/propertyContext";
import { getPropertyInspectionReport } from "context/propertyContext/propertyActions";
import { useGetToken } from "hooks";
import { TabProvider } from "context/tabContext/tabContext";

function PropertyInspectionReportView() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProperty();

  const inspectionReportId = state.inspectionReport.data.id;

  useEffect(() => {
    if (!inspectionReportId || !state.property?.id) return;
    getToken().then((authToken) => {
      getPropertyInspectionReport(inspectionReportId, state.property?.id, dispatch, authToken);
    });
  }, [
    getPropertyInspectionReport,
    state.property?.id,
    inspectionReportId,
    state.inspectionReport.refetch,
  ]);

  return (
    <>
      <GeneralSpacesSummary />
      <GeneralSpacesPhotoGallery />
      <SpacesFilter />
      <TabProvider>
        <SpaceDetails />
      </TabProvider>
    </>
  );
}

export default PropertyInspectionReportView;
