import { useCallback, useState } from "react";

const useBrokerageConfirmModal = () => {
  const [openBrokerageChangeModal, setOpenBrokerageChangeModal] = useState(false);
  const handleBrokerageModal = useCallback(() => {
    setOpenBrokerageChangeModal((prev) => !prev);
  }, []);

  return {
    openBrokerageChangeModal,
    handleBrokerageModal,
  };
};

export default useBrokerageConfirmModal;
