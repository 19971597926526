import moment from "moment";

export const parseEvaluation = (data) => ({
  id: data?.id,
  relatedApplicantId: data?.related_applicant_id,
  propertyId: data.property_id,
  mainApplicant: `${data.main_applicant?.name} ${data.main_applicant?.last_name}`,
  codebtor: `${data.user.name} ${data.user.last_name} (coudeudor)`,
  propertyUid: data?.property_uid,
  mainApplicantId: data?.main_applicant.id,
  codebtorId: data?.codebtor,
  status: data?.status,
  cratedDate: moment(data?.created_at).format("DD/MM/YYYY HH:mm"),
  updatedDate: `${moment(data.updated_at).format("DD/MM/YYYY HH:mm")} (${moment(
    data.updated_at
  ).fromNow()})`,
});

export const parseStatistics = (stats) => ({
  start: stats?.start,
  noDocs: stats?.no_docs,
  pendingDocs: stats?.pending_docs,
  readyForEvaluation: stats?.ready_for_evaluation,
  newDocs: stats?.new_docs,
  commerciallyApproved: stats?.commercially_approved,
  rejected: stats?.rejected,
  desisted: stats?.desisted,
});

export const defaultStatistics = () => ({
  start: 0,
  noDocs: 0,
  pendingDocs: 0,
  readyForEvaluation: 0,
  newDocs: 0,
  commerciallyApproved: 0,
  rejected: 0,
  desisted: 0,
});

export const parseSingleEvaluation = (data, brokerage: boolean) => ({
  codebtorName: data?.thisCodebtor,
  codebtorId: data["thisCodebtorId"],
  evaluations: [data?.main, ...(data?.codebtors || [])],
  propertyId: data?.main?.property_id,
  propertyUid: data?.main?.property_uid,
  commerciallyApproved: [data?.main, ...(data?.codebtors || [])].filter(
    (item) => item?.status === "Aprobado comercialmente" || !brokerage
  ),
  rents: data?.rents,
  bankAccount: null,
  rentsSum: 0,
});
