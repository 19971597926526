import { PropertyRentalStatus, PropertySaleStatus } from "../models/Property";

export default <S extends PropertyRentalStatus | PropertySaleStatus>(status: S) => {
  switch (status) {
    case "complete":
      return "Lista para publicar";
    case "incomplete":
      return "Incompleta";
    case "lost":
      return "Perdida";
    case "photo_cancelled":
      return "Foto Cancelada";
    case "photo_done":
      return "Foto Completada";
    case "photo_scheduled":
      return "Foto Agendada";
    case "published":
    case "republished":
      return "Publicada";
    case "reserved":
      return "Reservada";
    case "rented":
      return "Arrendada";
    case "unpublished":
      return "Despublicada";
    case "sold":
      return "Vendida";
    case "archived":
      return "Archivada";
    case "out_of_coverage":
      return "Fuera de cobertura";
    case "unpublished_by_import":
      return "Despublicada por corredora";
    case "unknown":
      return "Desconocido";
    case "negotiation":
      return "Negociación";
    default:
      return "Sin estado";
  }
};
