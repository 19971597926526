import { useQuery, UseQueryResult } from "react-query";
import { format, add } from "date-fns";

import { IReservation } from "services/newPaymentService/types";
import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useGetReservation = (country: Locale, userId: number, propertyId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading: isLoadingReservation,
    isError: isErrorReservation,
    data: dataReservation = [],
  }: UseQueryResult<IReservation[], Error> = useQuery<IReservation[], Error>(
    ["reservationUserById", userId, country, propertyId],
    async () => {
      const authToken = await getToken();
      const response = await newPaymentService.getUserReservations(
        country,
        userId,
        propertyId,
        {
          dateFrom: "2020-01-01",
          dateTo: format(add(new Date(), { days: 31 }), "yyyy-MM-dd"),
        },
        authToken
      );
      return response.data?.movements;
    },
    {
      enabled: !!userId && !!propertyId,
      retry: false,
    }
  );

  return {
    isLoadingReservation,
    isErrorReservation,
    dataReservation,
  };
};

export default useGetReservation;
