import Unauthorized from "domains/auth/components/Unauthorized";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import LoadingScreen from "domains/common/components/LoadingScreen";
import useGuard from "domains/auth/hooks/useGuard";

function AuthorizeUser({ children }: { children: React.ReactElement }) {
  const { status } = useGuard();
  if (status === "loading") return <LoadingScreen />;
  if (status === "error") return <Unauthorized />;
  return (
    <ProtectedComponent
      require={[
        "admin",
        "super_admin",
        "control_tower_admin",
        "sales_admin",
        "contract_admin",
        "evaluation_admin",
        "management_admin",
        "sales",
        "sales_guide",
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "management",
        "evaluation",
        "accountant",
        "houmer_group_admin",
        "houmer_group_leader",
        "collection",
        "rent_collection_and_accounting_operator",
        "rent_collection_and_accounting_admin",
        "rent_collection_and_accounting_read_only",
        "tenant_screening_and_selection_admin",
        "tenant_screening_and_selection_operator",
        "tenant_screening_and_selection_read_only",
        "property_maintenance_and_repairs_admin",
        "property_maintenance_and_repairs_operator",
        "property_maintenance_and_repairs_read_only",
        "customer_support_and_service_read_only",
        "customer_support_and_service_admin",
        "customer_support_and_service_operator",
      ]}
      scope="roles"
      strategy="any"
      fallback={<Unauthorized />}>
      {children}
    </ProtectedComponent>
  );
}

export default AuthorizeUser;
