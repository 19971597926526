import {
  State,
  Action,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_ORDERING,
  SHOW_MODAL,
  SHOW_SECOND_MODAL,
  SHOW_THIRD_MODAL,
  SET_TABLE_FILTERS,
  SET_SEARCH_QUERY,
} from "./tableTypes";

export const initialState: State = {
  currentPage: 1,
  pageSize: 10,
  tableFilters: {},
  showModal: false,
  showSecondModal: false,
  showThirdModal: false,
  ordering: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_ORDERING: {
      return { ...state, ordering: action.ordering };
    }
    case SET_PAGE_SIZE: {
      return { ...state, pageSize: action.pageSize };
    }
    case SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.currentPage };
    }
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          search_query: action.searchQuery,
        },
      };
    }
    case SHOW_MODAL: {
      return { ...state, showModal: action.showModal };
    }
    case SHOW_SECOND_MODAL: {
      return { ...state, showSecondModal: action.showSecondModal };
    }
    case SHOW_THIRD_MODAL: {
      return { ...state, showThirdModal: action.showThirdModal };
    }
    case SET_TABLE_FILTERS: {
      return { ...state, tableFilters: action.tableFilters, currentPage: 1 };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
