import { useGetToken } from "hooks";

import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import { completeLastActivity } from "context/demandLeadContext/lead/demandLeadActions";

import {
  setAdvisoryMessage,
  setIsOpenSuccessScheduled,
  setIsScheduling,
  setAdvisoryResponseData,
} from "context/demandLeadContext/schedule/demandScheduleActions";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";

import ActionButton from "Components/Admin/administrator/demand/components/ActionButton";
import AdvisoryResponseInfo from "./Advisory/AdvisoryResponseInfo";
import useCompleteActivityStyles from "./CompleteActivityStyles";

function CompleteActivity() {
  const classes = useCompleteActivityStyles();

  const { getToken } = useGetToken();

  const {
    state: { lead },
    dispatch: dispatchLead,
  } = useDemandLead();

  const {
    state: { isOpenSuccessScheduled, advisoryMessage, advisoryResponseData },
    dispatch,
  } = useDemandSchedule();

  const handleOtherSchedule = () => {
    dispatch(setIsScheduling(false));
    dispatch(setIsOpenSuccessScheduled(false));
    dispatch(setAdvisoryMessage(false));
  };

  const handleCompleteActivity = () => {
    getToken().then((authToken) => {
      completeLastActivity(dispatchLead, lead.lead.id, authToken);
      dispatch(setIsOpenSuccessScheduled(false));
      dispatch(setAdvisoryMessage(false));
    });
  };

  const handleCloseModal = () => {
    dispatch(setIsOpenSuccessScheduled(false));
    dispatch(setAdvisoryMessage(false));
    dispatch(
      setAdvisoryResponseData({
        appraiserId: "",
        appraiserName: "",
        scheduleId: null,
        daySchedule: null,
        leadEmail: null,
      })
    );
  };

  return (
    <CustomCenteredModal
      open={isOpenSuccessScheduled}
      onClose={handleCloseModal}
      title={`¡${advisoryMessage ? "Asesoría" : "Visita"} agendada con éxito!`}
      subtitle={
        advisoryMessage ? null : "¿Quieres agendar otra visita o deseas finalizar tu actividad?"
      }
      withLogo={!advisoryResponseData?.scheduleId}
      showCloseButton>
      {!advisoryMessage && (
        <div className={classes.buttonContainer}>
          <ActionButton onClick={handleCompleteActivity} variant="text">
            Finalizar actividad
          </ActionButton>
          <ActionButton onClick={handleOtherSchedule}>Agendar otra visita</ActionButton>
        </div>
      )}
      {advisoryResponseData.scheduleId && (
        <AdvisoryResponseInfo
          appraiserName={advisoryResponseData.appraiserName}
          daySchedule={advisoryResponseData.daySchedule}
          leadEmail={advisoryResponseData.leadEmail}
          scheduleId={advisoryResponseData.scheduleId}
        />
      )}
    </CustomCenteredModal>
  );
}

export default CompleteActivity;
