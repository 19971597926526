import { useMemo } from "react";

import { Avatar, Typography } from "@material-ui/core";
import clx from "classnames";

import { AppraiserRole } from "models/Appraiser";
import { useDashboard } from "context/controlTower/controlTowerContext";
import { setIsOpenEditModal } from "context/controlTower/controlTowerActions";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import UserItem from "./UserItem";
import useProfileStyles from "./ProfileStyles";
import GrayChip from "./GrayChip";
import { getMacrozoneLabelFromProfile } from "context/controlTower/controlTowerUtils";
import useGuard from "domains/auth/hooks/useGuard";

function Profile() {
  const { guard } = useGuard();
  const classes = useProfileStyles();
  const {
    state: {
      appraiserProfile: { macrozones, country, email, image, name, phone, roles, active },
    },
    dispatch,
  } = useDashboard();

  const labeledMacrozones = useMemo(() => getMacrozoneLabelFromProfile(macrozones), [macrozones]);

  const canEditProfile =
    guard.roles.any(["control_tower_admin"]) ||
    guard.permissions.any(["update:houmer_profile:all"]);
  const isLeadEnabled = roles.includes(AppraiserRole.LEADS_ENABLED);
  const isRentPhotographer = roles.includes(AppraiserRole.RENT_PHOTO);
  const isSalePhotographer = roles.includes(AppraiserRole.SALE_PHOTO);
  const isAdvisor = roles.includes(AppraiserRole.ADVISOR);
  const isHA = roles.includes(AppraiserRole.SALE_GUIDE);
  const isHoumer = roles.includes(AppraiserRole.RENT_GUIDE);
  const isIntern = roles.includes(AppraiserRole.INTERN);

  const appraiserRoles = [
    ...(isLeadEnabled ? ["LE"] : []),
    ...(isRentPhotographer ? ["Fotógrafo Renta"] : []),
    ...(isSalePhotographer ? ["Fotógrafo Venta"] : []),
    ...(isAdvisor ? ["Advisor"] : []),
    ...(isHA ? ["Houm Advisor"] : []),
    ...(isHoumer ? ["Houmer"] : []),
    ...(isIntern ? ["Interno"] : []),
  ];

  const activeText = active ? "Activo" : "No activo";

  const handleOpenEditModal = () => dispatch(setIsOpenEditModal(true));

  return (
    <div className={classes.container}>
      <Avatar className={classes.avatar} src={image} />
      <Typography variant="body1" className={clx(classes.title, classes.centered)}>
        {name}
      </Typography>
      <div className={classes.chipContainer}>
        {appraiserRoles.map((rol: string) => (
          <GrayChip key={rol} label={rol} />
        ))}
      </div>

      <UserItem variant="horizontal" content={country} title="País: " />
      <UserItem variant="vertical" content={email} title="Correo electronico" />
      <UserItem variant="vertical" content={phone} title="Teléfono" />
      <UserItem variant="vertical" title="Macrozonas">
        <div className={classes.chipContainer}>
          {labeledMacrozones.map((macrozone: string) => (
            <GrayChip key={macrozone} label={macrozone} />
          ))}
        </div>
      </UserItem>
      <UserItem variant="vertical" content={activeText} title="Estado del perfil" />

      {canEditProfile && (
        <DesignSystemButton
          id="updateProfileButton"
          onClick={handleOpenEditModal}
          label="Editar perfil"
          variant="primary"
          size="small"
          buttonClassName={clx(classes.button, classes.centered)}
        />
      )}
    </div>
  );
}

export default Profile;
