import { useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

export function formDate(date: string) {
  return format(new Date(date), "yyyy-MM-dd'T'00:00:00");
}

const useEditRefundDate = (country: Locale, reservationId: string, onCloseModal?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    async (formData: { newDate: string }) => {
      const authToken = await getToken();
      const data = {
        // TODO: formData.newDate should come formated as "yyyy-MM-dd'T'00:00:00" from the component or form that uses this hook
        new_date: formDate(formData.newDate),
      };
      const response = await newPaymentService.editRefundDate(
        country,
        reservationId,
        data,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reservationUserById");
        queryClient.invalidateQueries("refundsByUser");
        onCloseModal?.();
        alert.success({
          message: "La fecha de reembolso se ha editado correctamente",
        });
      },
      onError: (e: AxiosError<{ errors?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo actualizar la fecha de reembolso",
          disclaimer: `Error: ${reason?.data?.errors ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useEditRefundDate;
