import { makeStyles, Theme } from "@material-ui/core";

const useCodebtorDataCardStyles = makeStyles((theme: Theme) => ({
  dataBox: {
    width: "40%",
    paddingBottom: theme.spacing(0),
  },
  card: {
    width: "100%",
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default useCodebtorDataCardStyles;
