import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import insuranceService from "services/insuranceService";
import { IContractInsuranceCompany } from "models/InsuranceCompany";
import { parseContractInsuranceCompany } from "../utils/prasers";

const useGetInsuranceCompany = (contractId?: string) => {
  const { getToken } = useGetToken();

  const {
    data: insuranceCompany,
    isLoading: insuranceCompanyLoading,
    isError: insuranceCompanyError,
  }: UseQueryResult<IContractInsuranceCompany, Error> = useQuery<IContractInsuranceCompany, Error>(
    ["insuranceCompanyByContract", contractId],
    async () => {
      const token = await getToken();
      const response = await insuranceService.getInsuranceCompanyByContract(contractId, token);
      return parseContractInsuranceCompany(response.data);
    },
    {
      enabled: !!contractId,
    }
  );

  return {
    insuranceCompany,
    insuranceCompanyLoading,
    insuranceCompanyError,
  };
};

export default useGetInsuranceCompany;
