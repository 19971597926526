import { Chip } from "@material-ui/core";
import useGrayChipStyles from "./GrayChipStyles";

interface Props {
  label: string;
  onDelete?: () => void;
}

function GrayChip({ label, onDelete = null }: Props) {
  const classes = useGrayChipStyles();
  return (
    <Chip
      onDelete={onDelete}
      key={label}
      label={label}
      classes={{
        root: classes.root,
      }}
      variant="outlined"
      className={classes.chip}
    />
  );
}

export default GrayChip;
