import HomeOutlined from "@material-ui/icons/HomeOutlined";
import PersonOutlineOutlined from "@material-ui/icons/PersonOutlineOutlined";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import EmojiObjectsOutlined from "@material-ui/icons/EmojiObjectsOutlined";
import InsertDriveFileOutlined from "@material-ui/icons/InsertDriveFileOutlined";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";

export const contractCategories = [
  {
    key: "propertyData",
    label: "Datos propiedad",
    Icon: HomeOutlined,
  },
  {
    key: "landlordData",
    label: "Datos propietario",
    Icon: PersonOutlineOutlined,
  },
  {
    key: "thirdPartyData",
    label: "Datos tercero autorizado para restituir el inmueble",
    Icon: PersonAddOutlined,
  },
  {
    key: "publicServices",
    label: "Datos servicios públicos del inmueble a alquilar",
    Icon: EmojiObjectsOutlined,
  },
  {
    key: "adminData",
    label: "Datos de administración",
    Icon: WorkOutlineOutlined,
  },
  {
    key: "fileLinks",
    label: "Documentos adjuntos",
    Icon: InsertDriveFileOutlined,
  },
];
