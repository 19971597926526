import { Typography } from "@material-ui/core";

import useStyles from "./PropertyInfoItemStyles";

interface Props {
  label: string;
  value: string;
}

function PropertyInfoItem({ label, value }: Props) {
  const classes = useStyles();

  return (
    <Typography className={classes.infoItemText}>
      <strong>{label}:</strong> {value}
    </Typography>
  );
}

export default PropertyInfoItem;
