import { useFeatureManager } from "@houm-com/feature-manager";

import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Locale } from "models/Countries";

import UpdatingDisclaimer from "../../../components/UpdatingDisclaimer";
import useBasicServicesFooterStyles from "./BasicServicesFooterStyles";

interface Props {
  addService: () => void;
  hasServices?: boolean;
  updating: boolean;
  updateLoading: boolean;
  updateServices: () => void;
  country?: Locale;
}

function BasicServicesFooter({
  addService,
  hasServices = true,
  updating,
  updateLoading,
  updateServices,
  country,
}: Props) {
  const { data: servicesFlagData, status: servicesFlagStatus } = useFeatureManager(
    "show_services_details",
    { marketCode: country }
  );
  const showServiceDetails = servicesFlagStatus === "success" && servicesFlagData?.enabled;
  const classes = useBasicServicesFooterStyles();

  return (
    <Box>
      {updating && <UpdatingDisclaimer />}
      <Box className={classes.buttonsWrapper}>
        <DesignSystemButton
          variant="primary"
          size="small"
          label="Agregar +"
          onClick={addService}
          buttonClassName={classes.addButton}
        />
        {hasServices && showServiceDetails && (
          <DesignSystemButton
            variant="secondary"
            label="Actualizar"
            size="small"
            onClick={updateServices}
            isLoading={updateLoading}
            disabled={updating}
          />
        )}
      </Box>
    </Box>
  );
}

export default BasicServicesFooter;
