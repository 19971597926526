import { useMutation, useQueryClient } from "react-query";
import { capitalize } from "lodash";
import { AxiosError } from "axios";

import contractService from "services/contractService";
import { alert } from "libs/alerts";
import { useGetToken } from "hooks";

import { ICodebtorData, ContractPersonType } from "../../utils/types";
import { typeLabel } from "../../utils/parsers";

interface Props {
  contractId: number;
  type: ContractPersonType;
  typeAction: "edit" | "creation";
}

const useCodebtor = ({ contractId, type, typeAction }: Props) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: ICodebtorData) => {
      const authToken = await getToken();
      if (type === "lessee_legal_representative" || type === "lessor_legal_representative")
        await contractService.createLegalRepresentative(contractId, formData, authToken);
      else
        await contractService.createNewPerson(
          contractId,
          { ...formData, property_id: -1 },
          authToken
        );
      return {
        tributaryType: formData.tributary_type,
      };
    },
    {
      onSuccess: (e) => {
        alert.success({
          message: `${capitalize(typeLabel[type])} ${
            typeAction === "edit" ? "actualizado" : "creado"
          }`,
          disclaimer: `El ${typeLabel[type]} ha sido ${
            typeAction === "edit" ? "modificado" : "creado"
          } exitosamente`,
        });
        queryClient.invalidateQueries(["contract_data"]);
      },
      onError: (e: AxiosError) => {
        alert.error({
          message: `Error al ${typeAction === "edit" ? "editar" : "crear"} datos ${capitalize(
            typeLabel[type]
          )}`,
          disclaimer: `Los datos del ${typeLabel[type]} no han podido ser ${
            typeAction === "edit" ? "editados" : "creados"
          }, error: ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useCodebtor;
