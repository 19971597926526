import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledList, ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Spinner from "Components/Shared/partials/Spinner";
import { ChangePropertyStatusOperationType } from "services/propertyVisibilityService/types";
import useChangePropertyVisibility from "hooks/useChangePropertyVisibility";
import usePropertyVisibilityManagerStyles from "./PropertyVisibilityManagerStyles";

const TITLES_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]: "Despublicar propiedad",
  [ChangePropertyStatusOperationType.SET_AS_LOST]: "Dar propiedad por perdida",
};

const REASON_LABELS_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]:
    "Selecciona el por qué se va a despublicar la propiedad *",
  [ChangePropertyStatusOperationType.SET_AS_LOST]:
    "Selecciona el por qué se da por perdida la propiedad *",
};

const REASONS_ERROR_MESSAGES_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]:
    "Oops! Ha ocurrido un error al obtener los motivos para despublicar una propiedad y son necesarios para continuar",
  [ChangePropertyStatusOperationType.SET_AS_LOST]:
    "Oops! Ha ocurrido un error al obtener los motivos para dar por perdida una propiedad y son necesarios para continuar",
};

const SUBMIT_BUTTON_LABELS_BY_OPERATION_TYPE = {
  [ChangePropertyStatusOperationType.UNPUBLISH]: "Despublicar",
  [ChangePropertyStatusOperationType.SET_AS_LOST]: "Dar por perdida",
};

const PropertyVisibilityManager = ({ property, open, onClose, propertyMode, operationType }) => {
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const classes = usePropertyVisibilityManagerStyles();
  const history = useHistory();
  const {
    reasonId,
    operationReasons,
    formMethods,
    onSubmitForm,
    isLoadingOperationReasons,
    isLoadingApplyingOperationType,
    isErrorGettingOperationReasons,
    alertPayload,
    somethingHappened,
    isSuccessApplyingOperationType,
  } = useChangePropertyVisibility({
    mode: propertyMode,
    propertyId: property.id,
    operationType,
  });

  useEffect(() => {
    if (somethingHappened) {
      setOpenAlertDialog(true);
    }
  }, [somethingHappened]);

  useEffect(() => {
    if (isSuccessApplyingOperationType) {
      onClose();
      history.go(0);
    }
  }, [isSuccessApplyingOperationType]);

  return (
    <>
      <DSModal open={open} classes={{ paper: classes.gridRoot }} onClose={onClose} showCloseButton>
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmitForm}>
            <Grid container className={classes.dialogWrapper}>
              <Grid item className={classes.dialogTitle}>
                {TITLES_BY_OPERATION_TYPE[operationType]}
              </Grid>
              <Grid item className={classes.dialogContent}>
                {isErrorGettingOperationReasons && (
                  <Typography className={classes.errorDialogBox}>
                    🚨 {REASONS_ERROR_MESSAGES_BY_OPERATION_TYPE[operationType]}, por favor intente
                    más tarde.
                  </Typography>
                )}
                {isLoadingOperationReasons && <Spinner />}
                {!isLoadingOperationReasons && !!operationReasons?.length && (
                  <>
                    <ControlledList
                      label={REASON_LABELS_BY_OPERATION_TYPE[operationType]}
                      classes={{
                        select: classes.selectITem,
                        container: classes.selectContainer,
                        wrap: classes.selectWrapper,
                        label: classes.inputLabel,
                        itemContainer: classes.selectItemContainer,
                      }}
                      options={operationReasons}
                      id="reasonId"
                    />
                    <ControlledTextField
                      label="Indica la gestión realizada antes de realizar esta acción"
                      id="observation"
                      textFieldProps={{
                        className: classes.textField_test,
                        multiline: true,
                        minRows: 3,
                        labelClassName: classes.inputLabel,
                        wrapperClassName: classes.textFieldWrapper,
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item className={classes.dialogActions}>
                <DesignSystemButton
                  label="Cancelar"
                  variant="tertiaryB"
                  onClick={onClose}
                  buttonClassName={classes.actionButton}
                  size="medium"
                />
                <DesignSystemButton
                  label={SUBMIT_BUTTON_LABELS_BY_OPERATION_TYPE[operationType]}
                  variant="primary"
                  buttonClassName={classes.actionButton}
                  type="submit"
                  disabled={!reasonId}
                  isLoading={isLoadingApplyingOperationType}
                  size="medium"
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DSModal>
      {openAlertDialog && (
        <DesignSystemSnackbar
          {...alertPayload}
          open={somethingHappened}
          onClose={() => setOpenAlertDialog(false)}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}
    </>
  );
};

export default PropertyVisibilityManager;
