import { cn } from "@houm-com/ui/utils";
import { IoInformationCircleOutline as InfoIcon, IoClose as CloseIcon } from "@houm-com/ui-icons";

import classes from "./styles";
import { useState } from "react";

const PercentageDiscountExample = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={cn(classes.linkContainer, classes.percentageLink)}>
        <InfoIcon className="stroke-information-120" />
        <p onClick={() => setShow(!show)} className={classes.link}>
          Ejemplo
        </p>
      </div>
      {show && (
        <div className={cn(classes.container, classes.percentageContainer)}>
          <CloseIcon className={classes.close} onClick={() => setShow(false)} />

          <p className={classes.bold}>Descuento de comisión</p>
          <div className={classes.row}>
            <p className={classes.breakdown}>Arriendo/Renta:</p>
            <p className={classes.breakdown}>$1.000</p>
          </div>
          <div className={classes.row}>
            <p className={classes.breakdown}>Comisión:</p>
            <p className={classes.breakdown}>10% ($100)</p>
          </div>
          <div className={classes.row}>
            <p className={classes.breakdown}>Descuento:</p>
            <p className={classes.breakdown}>-10% (-$10)</p>
          </div>
          <div className={cn([classes.row, classes.total])}>
            <p>Total a pagar:</p>
            <p>$90</p>
          </div>

          <p className={classes.bold}>Descuento en corretaje</p>
          <div className={classes.row}>
            <p className={classes.breakdown}>Corretaje:</p>
            <p className={classes.breakdown}>$500</p>
          </div>
          <div className={classes.row}>
            <p className={classes.breakdown}>Descuento:</p>
            <p className={classes.breakdown}>-10% (-$50)</p>
          </div>
          <div className={cn([classes.row, classes.total])}>
            <p>Total a pagar:</p>
            <p>$450</p>
          </div>
        </div>
      )}
    </>
  );
};

export default PercentageDiscountExample;
