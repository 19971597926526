import { makeStyles, Theme } from "@material-ui/core";

const usePhotoStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: "red",
  },
  image: {
    width: 160,
    height: 160,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    objectPosition: "center center",
    margin: theme.spacing(0.25),
  },
  container: {
    position: "absolute",
    top: 8,
    left: 8,
    display: "flex",
    padding: 0,
  },
  tooltip: {
    backgroundColor: theme.palette.grey[75],
  },
  arrow: {
    color: theme.palette.grey[75],
  },
}));

export default usePhotoStyles;
