import { useEffect } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useStyles from "./ActivityFormStyles";
import { useFormNotifications, useGetToken } from "hooks";
import validationSchema, { requiredText } from "./activityFormValidationSchema";
import {
  ControlledDatePicker,
  ControlledList,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { contactMediums, subjects, status } from "./LeadQualificationConsts";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { useLead } from "context/leadContext/leadContext";
import { createLeadActivity, updateLeadActivity } from "context/leadContext/leadActions";
import { formatStringDateToForm, getObjectLabel, setDateTimeFormat, statusTranslator } from "utils";

import {
  ActivityModalActionType,
  IActivity,
  LeadPropertyInfo,
} from "context/leadContext/leadTypes";

interface Props {
  closeModal: () => void;
  action: ActivityModalActionType;
}

function ActivityForm({ closeModal, action }: Props) {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();
  const { selectedActivity } = state;

  const isEdit = action === "edit";
  const fromActivitiesIndex = action === "new_from_activity_index";
  const buttonMessage = isEdit ? "Guardar cambios" : "Agregar actividad";

  const methods = useForm<IActivity>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const { watch, setValue } = methods;
  const { errors } = methods.formState;

  const leadProperties = state?.lead?.properties;
  const leadPropertiesOptions = leadProperties.map((property: LeadPropertyInfo) => ({
    label: property.address,
    value: property.id,
  }));
  const showOtherSubjectDescription = watch("subject") === "Other";
  const selectedProperty = watch("property");

  const handleSetActivityState = () => {
    const selectedPropertyInfo = leadProperties.find(
      (property) => property.id === selectedProperty
    );
    if (selectedPropertyInfo?.status)
      setValue("status", statusTranslator(selectedPropertyInfo.status));
  };

  useEffect(handleSetActivityState, [selectedProperty]);

  const onSubmit: SubmitHandler<IActivity> = async (data, e) => {
    try {
      const authToken = await getToken();

      if (!isEdit) {
        const sendCreateData = {
          ...data,
          lead: fromActivitiesIndex ? state?.selectedLeadId : state?.lead?.id,
        };
        await createLeadActivity(sendCreateData, dispatch, authToken);
      } else if (isEdit && selectedActivity?.id)
        await updateLeadActivity(selectedActivity?.id, data, dispatch, authToken);
    } catch {
      console.error("error");
    } finally {
      closeModal();
    }
  };

  useFormNotifications<IActivity>({ errors, requiredText });

  const handleSetInitialCreatedAtDate = state.selectedActivity?.createdAt
    ? moment(formatStringDateToForm(state.selectedActivity?.createdAt)).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  return (
    <FormProvider {...methods} key="ActivityForm">
      <form key="ActivityForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" className={classes.formContainer}>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledDatePicker
              id="createdAt"
              label="Fecha creación"
              disabled
              defaultValue={handleSetInitialCreatedAtDate}
              type="date"
            />
          </Grid>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledDatePicker
              id="endDate"
              label="Fecha vencimiento"
              defaultValue={setDateTimeFormat(state.selectedActivity?.endDate)}
              type="datetime-local"
              textFieldProps={{
                InputProps: {
                  inputProps: {
                    max: isEdit ? setDateTimeFormat(state.selectedActivity?.endDate) : null,
                  },
                },
              }}
            />
          </Grid>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledList
              id="property"
              label="Propiedad"
              options={leadPropertiesOptions}
              defaultValue={state?.selectedActivity?.propId ?? ""}
              disabled={isEdit || leadProperties.length === 0}
              disableUnderline
              classes={{
                container: classes.selector,
                label: classes.selectorLabel,
              }}
            />
          </Grid>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledList
              id="contactMedium"
              label="Medio de contacto"
              options={contactMediums}
              defaultValue={state?.selectedActivity?.contactMedium ?? ""}
              disabled={isEdit}
              disableUnderline
              classes={{
                container: classes.selector,
                label: classes.selectorLabel,
              }}
            />
          </Grid>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledList
              id="subject"
              label="Asunto"
              options={subjects}
              defaultValue={state?.selectedActivity?.subject ?? ""}
              disabled={isEdit}
              disableUnderline
              classes={{
                container: classes.selector,
                label: classes.selectorLabel,
              }}
            />
          </Grid>
          <Grid item className={classes.formGridItem} xs={6}>
            <ControlledTextField
              id="status"
              label="Estado"
              defaultValue={
                isEdit
                  ? state?.selectedActivity?.status
                  : getObjectLabel(status, state?.lead?.status)
              }
              disabled={isEdit}
              textFieldProps={{
                InputProps: {
                  className: classes.formInput,
                },
              }}
            />
          </Grid>
          {showOtherSubjectDescription && (
            <Grid item className={classes.formGridItem} xs={12}>
              <ControlledTextField
                id="otherSubject"
                label="Descripción asunto"
                defaultValue={state?.selectedActivity?.otherSubject ?? ""}
                disabled={isEdit}
                textFieldProps={{
                  InputProps: {
                    className: classes.formInput,
                    disableUnderline: false,
                  },
                }}
              />
            </Grid>
          )}
          <Grid item className={classes.formGridItem} xs={12}>
            <ControlledTextField
              id="detail"
              label="Observaciones"
              bordered={true}
              defaultValue={state?.selectedActivity?.detail ?? ""}
              textFieldProps={{
                InputProps: {
                  disableUnderline: true,
                  multiline: true,
                  maxRows: 5,
                  minRows: 3,
                  className: "p-2",
                },
              }}
            />
          </Grid>
          <Grid item className={classes.submitButtonContainer} xs={12}>
            <CustomButton active className={classes.submitButton} type="submit">
              {buttonMessage}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default ActivityForm;
