import { LetterOfIntentProvider } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import LetterOfIntentPropertyDetailIndex from "./LetterOfIntentPropertyDetailIndex";

export default function LetterOfIntentPropertyDetail() {
  return (
    <LetterOfIntentProvider>
      <LetterOfIntentPropertyDetailIndex />
    </LetterOfIntentProvider>
  );
}
