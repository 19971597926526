import { UseQueryResult, useQuery } from "react-query";

import propertyService from "services/propertyService";
import { PropertyData } from "models/Property";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

const useGetContract = (propertyId: number) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data, refetch }: UseQueryResult<PropertyData, Error> = useQuery(
    ["contract_property_info", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getPropertyInfo(
        propertyId,
        authToken,
        "rental_details"
      );
      return response.data;
    },
    {
      enabled: !!propertyId,
      onError: (e) => {
        alert.error({
          message: "Error al cargar propiedad o no existe una asociada al contrato.",
          disclaimer: `Hubo un error al intentar obtener los datos de la propiedad, error: ${e}`,
        });
      },
    }
  );

  return { isLoading, error, data, refetch };
};

export default useGetContract;
