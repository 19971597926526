import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useCreateLandlordEvaluation, useFormNotifications } from "hooks";
import { ContractModel } from "models/Contract";
import { countryCode } from "utils";

import { adjustmentSchema, requiredText } from "../utils/validationSchema";
import SendProfileForm from "../SendProfileForm/SendProfileForm";
import useReSendProfile from "../../hooks/useReSendProfile";
import useSendProfileStyles from "./SendProfileStyles";

interface Props {
  contract: ContractModel;
  mainApplicantId: number;
  handleCloseModal: () => void;
  reevaluateFlag?: boolean;
}

interface IAdjustment {
  initDate: string;
  landlordEmail: string;
  landlordName: string;
}

function SendProfile({
  contract,
  mainApplicantId,
  handleCloseModal,
  reevaluateFlag = false,
}: Props) {
  const classes = useSendProfileStyles();
  const country = countryCode(contract?.property.country);
  const { createLandlordEvaluation, loading } = useCreateLandlordEvaluation();
  const { reSendProfile, loading: isLoadingReSendProfile } = useReSendProfile();

  const methods = useForm<IAdjustment>({
    resolver: yupResolver(adjustmentSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<IAdjustment>({ errors, requiredText });

  const onSubmit: SubmitHandler<IAdjustment> = async () => {
    if (contract.evaluation_ids.length === 0) return;
    if (reevaluateFlag)
      reSendProfile({
        contractId: contract.id,
        country,
        mainApplicantId,
      });
    else
      createLandlordEvaluation({
        contractId: contract.id,
        country,
        habitants_description: contract.habitants_description,
        initDate: contract.init_date,
        mainApplicantId,
      });
    handleCloseModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SendProfileForm contract={contract} />
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            label="Enviar Perfil"
            size="medium"
            variant="primary"
            type="submit"
            isLoading={loading || isLoadingReSendProfile}
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default SendProfile;
