import * as yup from "yup";

const requiredText = "Obligatorio";

const stringValidation = yup.string().required(requiredText).typeError(requiredText);
const numberValidation = yup.number().required(requiredText).typeError(requiredText);
const boolValidation = yup.boolean().required(requiredText).typeError(requiredText);

export default yup.object({
  name: stringValidation,
  price: numberValidation,
  status: stringValidation,
  orientation: stringValidation,
  floor: numberValidation,
  parking_spaces: numberValidation,
  has_storage: boolValidation,
});
