import { useCallback, useState } from "react";

import { ServiceForm } from "../utils/types";

const useModifyService = () => {
  const [modifyData, setModifyData] = useState(false);
  const [basicServiceData, setBasicServiceData] = useState<ServiceForm | null>(null);

  const handleResetData = useCallback(() => {
    setModifyData((prevData) => !prevData);
    setBasicServiceData(null);
  }, []);

  const onSaveBasicServiceData = (data: ServiceForm) => {
    setBasicServiceData(data);
    setModifyData(true);
  };

  return {
    handleResetData,
    onSaveBasicServiceData,
    basicServiceData,
    modifyData,
  };
};

export default useModifyService;
