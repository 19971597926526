import { ContractFormResponse } from "services/propertyService/types";

import { ContractProps } from "./types";

export const parsePropertyData = (data: ContractFormResponse): ContractProps => ({
  inhabitantsAmount: data.max_people_quantity,
  owner: {
    documentType: data.owner?.document_type,
    ...data.owner,
  },
});
