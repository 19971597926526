import { IUserAutoComplete, ParsedAddress, IHomeCheckerDetails } from "models/createProperty";
import { InspectionReport, InspectionReportTableInfo } from "models/InspectionReport";
import { PropertyData } from "models/Property";
import { UserModel } from "models/User";

export type Dispatch = (action: Action) => void;

export const SET_MODAL_MESSAGE = "SET_MODAL_MESSAGE";
export const SET_LOCATION_OPTIONS = "SET_LOCATION_OPTIONS";
export const SET_COMMUNES = "SET_COMMUNES";
export const SET_LOADING = "SET_LOADING";
export const SET_USER_MODAL = "SET_USER_MODAL";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_PROPERTY = "SET_PROPERTY";
export const REFETCH_PROPERTY_INFO = "REFETCH_PROPERTY_INFO";
export const SET_USER = "SET_USER";
export const SET_CREATED_PROPERTY = "SET_CREATED_PROPERTY";
export const SET_SUCCESS_MODAL = "SET_SUCCESS_MODAL";
export const SET_IS_SHORTER_FORM = "SET_IS_SHORTER_FORM";
export const SET_HOME_CHECKER_DETAILS = "SET_HOME_CHECKER_DETAILS";
export const SET_PROPERTY_INSPECTION_REPORTS_STATE = "SET_PROPERTY_INSPECTION_REPORTS_STATE";
export const REFETCH_INSPECTION_REPORTS = "REFETCH_INSPECTION_REPORTS";
export const SET_PROPERTY_INSPECTION_REPORT_STATE = "SET_PROPERTY_INSPECTION_REPORT_STATE";
export const REFETCH_INSPECTION_REPORT = "REFETCH_INSPECTION_REPORT";
export const SET_INSPECTION_REPORTS_CURRENT_VIEW = "SET_INSPECTION_REPORTS_CURRENT_VIEW";
export const SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER =
  "SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER";

export interface ModalMessage {
  isOpen: boolean;
  title: string;
  subtitle?: string;
}

export interface State {
  modalMessage?: ModalMessage;
  loading: boolean;
  property?: PropertyData;
  refetchPropertyInfo: boolean;
  neighborhoods: Options[];
  communes?: Options[];
  address: ParsedAddress | null;
  user: IUserAutoComplete | UserModel;
  openUserModal: boolean;
  openSuccessModal?: boolean;
  isShorterForm?: boolean;
  homeCheckerDetails?: IHomeCheckerDetails;
  inspectionReports: {
    loading: boolean;
    refetch: boolean;
    results: Array<InspectionReportTableInfo>;
    count: number;
  };
  inspectionReport: {
    loading: boolean;
    refetch: boolean;
    data: InspectionReport;
    currentSpaceName?: string;
  };
  inspectionReportsCurrentView: InspectionReportsCurrentViews;
}

export type InspectionReportsCurrentViews = "main" | "show" | "edit";

export type InspectionReportsInfoState = {
  loading?: boolean;
  refetch?: boolean;
  results?: Array<InspectionReportTableInfo>;
  count?: number;
};

export type InspectionReportInfoState = {
  loading?: boolean;
  refetch?: boolean;
  data?: InspectionReport;
};
interface setLocationOptions {
  type: typeof SET_LOCATION_OPTIONS;
  neighborhoods: Options[];
  communes: Options[];
}

interface setCreatedProperty {
  type: typeof SET_CREATED_PROPERTY;
  property: PropertyData;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}
interface setIsShorterForm {
  type: typeof SET_IS_SHORTER_FORM;
  isShorterForm: boolean;
}
interface setSuccessModal {
  type: typeof SET_SUCCESS_MODAL;
  openSuccessModal: boolean;
}

interface setUserModal {
  type: typeof SET_USER_MODAL;
  openUserModal: boolean;
}

interface setProperty {
  type: typeof SET_PROPERTY;
  property: PropertyData;
}

interface setModalMessage {
  type: typeof SET_MODAL_MESSAGE;
  modalMessage: ModalMessage;
}

interface refetchPropertyInfo {
  type: typeof REFETCH_PROPERTY_INFO;
}

interface setAddress {
  type: typeof SET_ADDRESS;
  address: ParsedAddress;
}

interface setUser {
  type: typeof SET_USER;
  user: IUserAutoComplete;
}

interface setHomeCheckerDetails {
  type: typeof SET_HOME_CHECKER_DETAILS;
  homeCheckerDetails: IHomeCheckerDetails;
}

interface setPropertyInspectionReports {
  type: typeof SET_PROPERTY_INSPECTION_REPORTS_STATE;
  inspectionReportsInfo: InspectionReportsInfoState;
}

interface refetchPropertyInspectionReports {
  type: typeof REFETCH_INSPECTION_REPORTS;
}

interface setPropertyInspectionReport {
  type: typeof SET_PROPERTY_INSPECTION_REPORT_STATE;
  inspectionReportInfo: InspectionReportInfoState;
}

interface refetchPropertyInspectionReport {
  type: typeof REFETCH_INSPECTION_REPORT;
}

interface setInspectionReportsCurrentView {
  type: typeof SET_INSPECTION_REPORTS_CURRENT_VIEW;
  view: InspectionReportsCurrentViews;
}

interface setInspectionReportCurrentSpaceFilter {
  type: typeof SET_CURRENT_INSPECTION_REPORT_SPACE_FILTER;
  spaceName: string;
}

export type Action =
  | setModalMessage
  | setIsShorterForm
  | setCreatedProperty
  | setLocationOptions
  | setLoading
  | setSuccessModal
  | setProperty
  | refetchPropertyInfo
  | setUser
  | setAddress
  | setIsShorterForm
  | setUserModal
  | setHomeCheckerDetails
  | setPropertyInspectionReports
  | refetchPropertyInspectionReports
  | setPropertyInspectionReport
  | refetchPropertyInspectionReport
  | setInspectionReportsCurrentView
  | setInspectionReportCurrentSpaceFilter;

interface Options {
  value: string;
  label: string;
}
