/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { AxiosResponse, AxiosError } from "axios";

import { getPropertyDays, availabilityToRange } from "utils/availabilityTime";
import { parseReasons, parseNewAppraiserAv, eventTypesMap } from "./eventUtils";
import { parseToUtc, getTimezoneWithCommune } from "utils/datetime";

import { IProperty } from "models";
import { UserModel } from "models/User";
import { CountryUpperCase, Timezones } from "models/Countries";
import { Availability } from "services/appraiserService/types";

import applicantService from "services/applicantService";
import schedulesService from "services/schedulesService";
import propertyService from "services/propertyService";
import userService from "services/userService";
import leadService from "services/leadService";

import {
  Action,
  Options,
  Dispatch,
  SET_EVENT_TYPES,
  SET_LOADING,
  SET_APPRAISERS,
  SET_DOCUMENT_TYPES,
  SET_AVAILABILITY,
  SET_PROPERTY,
  SET_CONFIG_MODAL,
  SET_CANCEL_REASONS,
  SET_CANCEL_RESCHEDULE_MODAL,
  SET_ADVISORY_RESPONSE,
  SET_SCHEDULED,
  SET_USER_EMAIL,
  SET_USER_COUNTRY,
  CancelReasons,
  ConfigModal,
  CreateEventForm,
  ConfigCancelRescheduleModal,
} from "./eventTypes";
import { SET_USER } from "context/propertyContext/propertyTypes";
import { scheduleResponse } from "utils/scheduleResponseHours";

export const setCancelRescheduleModal = (
  cancelRescheduleModal: ConfigCancelRescheduleModal
): Action => ({
  type: SET_CANCEL_RESCHEDULE_MODAL,
  cancelRescheduleModal,
});

export const setConfigModal = (configModal: ConfigModal): Action => ({
  type: SET_CONFIG_MODAL,
  configModal,
});

export const setCancelReasons = (cancelReasons: CancelReasons[]): Action => ({
  type: SET_CANCEL_REASONS,
  cancelReasons,
});

interface ShowModalErrorParam {
  dispatch: Dispatch;
  error: unknown;
  message?: string;
  reload?: boolean;
}

interface ShowModalParam {
  dispatch: Dispatch;
  title?: string;
  reload?: boolean;
}

const showModalError = ({ dispatch, error, message }: ShowModalErrorParam): void => {
  const err = error as AxiosError<{ message?: string }>;
  dispatch(
    setConfigModal({
      isOpen: true,
      title: "Ha ocurrido un error",
      subtitle: message || `${err?.response?.data?.message || err?.response.status}`,
    })
  );
};

const showModal = ({ dispatch, title, reload = true }: ShowModalParam): void => {
  dispatch(
    setConfigModal({
      title,
      reload,
      isOpen: true,
    })
  );
};

const showErrors = (error, dispatch) => {
  const keys = error?.response?.data && Object.keys(error.response.data);
  switch (true) {
    case keys?.includes("_schedule"): {
      const message =
        "Debes ingresar una fecha de agendamiento que corresponda a una fecha en el futuro";
      showModalError({ dispatch, error, message });
      break;
    }
    case keys?.includes("error"): {
      const message =
        "El Houmer asociado a esta operación se encuentra inactivo. Por favor, intenta asignando a otro Houmer";
      showModalError({ dispatch, error, message });
      break;
    }
    default: {
      const message = "Hubo un error al crear el agendamiento. Intenta nuevamente";
      showModalError({ dispatch, error, message });
      break;
    }
  }
};

export const setScheduled = (scheduled): Action => ({
  type: SET_SCHEDULED,
  scheduled,
});
export const setProperty = (property: IProperty): Action => ({
  type: SET_PROPERTY,
  property,
});

export const setUser = (user: UserModel): Action => ({
  type: SET_USER,
  user,
});

export const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setEventTypes = (events: Options[]): Action => ({
  type: SET_EVENT_TYPES,
  events,
});

export const setAppraisers = (appraisers: Options[]): Action => ({
  type: SET_APPRAISERS,
  appraisers,
});

export const setDocTypes = (documentTypes: Options[]): Action => ({
  type: SET_DOCUMENT_TYPES,
  documentTypes,
});

export const setAvailability = (avalability): Action => ({
  type: SET_AVAILABILITY,
  avalability,
});

export const setAdvisoryResponse = (advisoryResponse): Action => ({
  type: SET_ADVISORY_RESPONSE,
  advisoryResponse,
});

export const setUserEmail = (userEmail): Action => ({
  type: SET_USER_EMAIL,
  userEmail,
});

export const setUserCountry = (userCountry): Action => ({
  type: SET_USER_COUNTRY,
  userCountry,
});

export const getDefaultUser = async (email: string, dispatch: Dispatch, authToken: string) => {
  const userResponse = await userService.getByEmail(email, authToken);
  if (userResponse.data.user) {
    dispatch(setUser(userResponse.data.user));
  }
};

export const initStates = async (
  propId: number,
  email: string,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    dispatch(setLoading(true));
    if (propId) {
      //load defaultProperty
      await getDefaultProperty(propId, dispatch, authToken);
    }

    if (email) {
      await getDefaultUser(email, dispatch, authToken);
    }

    await getCancelReasons(authToken, dispatch);
    await getEvents(authToken, dispatch);
    await getAppraisers(authToken, dispatch);
  } catch {
    dispatch(setLoading(false));
  } finally {
    dispatch(setLoading(false));
  }
};

export interface ManualUserProps {
  email: string;
  name: string;
  last_name: string;
  document: string;
  documentType: string;
  phone: string;
  country: CountryUpperCase;
}

export const getApplicant = async (
  user: UserModel,
  authToken: string,
  propertyId: number = null,
  manualUser: ManualUserProps = null
) => {
  let userTemp;
  if (!user) {
    try {
      const userData = {
        role: "applicant",
        email: manualUser?.email,
        name: manualUser?.name,
        last_name: manualUser?.last_name,
        phone: manualUser?.phone,
        documentType: manualUser?.documentType,
        document: manualUser?.document,
        country: manualUser?.country,
      };
      const userRes = (await leadService.postLead(userData, authToken)) as AxiosResponse;
      if (userRes.data.email) {
        const { data } = await userService.getByEmail(userRes.data.email, authToken);
        userTemp = data.user;
      }
    } catch (error) {
      return error;
    }
  }
  userTemp = user || userTemp;
  try {
    const dataApplicant = {
      ...userTemp,
      property_id: propertyId || 74564, //This propId is only to get applicantId for advisory
      occupation: "-",
      last_name: userTemp.last_name || "-",
    };
    const res = (await applicantService.store(dataApplicant, authToken)) as AxiosResponse;
    return res.data.id;
  } catch (error) {
    return error;
  }
};

export const getDefaultProperty = async (
  propertyId: number,
  dispatch: Dispatch,
  authToken: string
) => {
  const propertyResponse = (await propertyService.getByID(
    propertyId,
    authToken
  )) as AxiosResponse<IProperty>;
  if (propertyResponse.data) {
    dispatch(setProperty(propertyResponse.data));
  }
};

export const getCancelReasons = async (authToken: string, dispatch: Dispatch) => {
  const response = await schedulesService.getCancelReasons(authToken);
  dispatch(setCancelReasons(parseReasons(response.data.cancel_reasons)));
};
export const getEvents = async (authToken: string, dispatch: Dispatch) => {
  const response = await schedulesService.getEvents(authToken);
  const itemsToRemove = ["ManualVisit", "Block"];
  const events = response.data
    .filter((item) => !itemsToRemove.includes(item.name))
    .map((item) => ({
      value: item.name,
      label: eventTypesMap[item.name] || item.name,
    }));
  dispatch({ type: SET_EVENT_TYPES, events });
};

export const getAppraisers = async (authToken: string, dispatch: Dispatch) => {
  try {
    const fetchedAppraisers = await schedulesService.fetchAppraisers(authToken);
    const filteredAppraisers = fetchedAppraisers.data.filter((appraiser) => appraiser.active);
    const appraisers = filteredAppraisers.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    dispatch(setAppraisers(appraisers));
  } catch (error) {
    console.error(error);
  }
};

export const postScheduleAdvisory = async (
  data: CreateEventForm,
  authToken: string,
  dispatch: Dispatch,
  manualUser: ManualUserProps = null
) => {
  dispatch(setLoading(true));
  try {
    const parsedDate = parseToUtc(
      `${data.date} ${data.begin_date}`,
      `${data.date} ${data.end_date}`,
      data.timezone
    );

    const fullDaySchedule = scheduleResponse({
      currentHour: parsedDate.begin_hour,
      timezone: data.timezone,
    });

    const applicantId = await getApplicant(data.user, authToken, null, manualUser);
    const body = {
      type: "Advisory",
      applicant: applicantId,
      begin_hour: parsedDate.begin_hour,
      end_hour: parsedDate.end_hour,
      appraiser: data.appraiser.value,
      origin: "Admin - Houmer",
    };

    const resVisit = (await schedulesService.createVisitSchedule(body, authToken)) as AxiosResponse;

    dispatch(setLoading(false));

    if (resVisit.status === 200) {
      showModal({
        dispatch,
        title: "¡Asesoría agendada con exito!",
      });
      dispatch(
        setAdvisoryResponse({
          appraiserId: resVisit.data.appraiser_id,
          appraiserName: resVisit.data.appraiser_name,
          scheduleId: resVisit.data.schedule_id,
          daySchedule: fullDaySchedule,
          leadEmail: data.user?.email || manualUser?.email,
        })
      );
    }
    if (!resVisit) {
      dispatch(setLoading(false));
      dispatch(
        setConfigModal({
          isOpen: true,
          title: "Houm advisor sin disponibilidad en ese horario",
        })
      );
    }
  } catch (error) {
    dispatch(setLoading(false));
    showErrors(error, dispatch);
  }
};

export const getAvailability = async (
  property: IProperty,
  dispatch: Dispatch,
  authToken: string
) => {
  const availability = (await propertyService.getAvailablePropertyHours(
    property.id,
    authToken
  )) as AxiosResponse;
  //availability with  utc format
  const utcAvalability = getPropertyDays(
    availability.data,
    null,
    property.country,
    property.comuna
  );

  //get ranges from availability
  const ranges = availabilityToRange(utcAvalability, null, property.country, property.comuna);

  dispatch(setAvailability(ranges));
};

export const getAppraiserAvailability = (
  appraiserAvailability: Availability[],
  property: IProperty,
  timezone: Timezones,
  dispatch: Dispatch
) => {
  const availability = parseNewAppraiserAv(appraiserAvailability, timezone);
  const ranges = availabilityToRange(availability, timezone, property?.country, property?.comuna);

  dispatch(setAvailability(ranges));
};

export const editSchedule = async (
  authToken: string,
  dispatch: Dispatch,
  id: number,
  timezone: Timezones,
  data: CreateEventForm
) => {
  try {
    const parsedDate = parseToUtc(
      `${data.date} ${data.begin_date}`,
      `${data.date} ${data.end_date}`,
      timezone || getTimezoneWithCommune(data.property?.country, data.property?.comuna)
    );

    const editData = {
      schedule: {
        begin_hour: parsedDate.begin_hour,
        end_hour: parsedDate.end_hour,
        appraiser_id: data.appraiser.value,
      },
      editor: "Control Tower",
    };
    dispatch(setLoading(true));
    const editEvent = await schedulesService.editSchedule(editData, id, authToken);
    dispatch(setLoading(false));
    if (editEvent) {
      showModal({ dispatch, title: "¡Reagendamiento Exitoso!" });
    }
  } catch (error) {
    dispatch(setLoading(false));
    showModalError({ dispatch, error });
  }
};

export const deleteSchedule = async (
  scheduleId: number,
  reasonId: number,
  comment: string,
  dispatch: Dispatch,
  authToken,
  eventEditor: string = "Control Tower"
) => {
  try {
    dispatch(setLoading(true));

    const deleteEvent = await schedulesService.setCancelStatus(
      scheduleId,
      reasonId,
      comment,
      eventEditor,
      authToken
    );
    dispatch(setLoading(false));
    if (deleteEvent) {
      showModal({ dispatch, title: "¡Agendamiento Cancelado!" });
    }
  } catch (error) {
    dispatch(setLoading(false));
    showModalError({ dispatch, error });
  }
};

export const confirmSchedule = async (
  eventId: number,
  source: string,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    dispatch(setLoading(true));
    const confirm = await schedulesService.confirmSchedule(eventId, source, authToken);
    dispatch(setLoading(false));
    if (confirm) {
      showModal({ dispatch, title: "¡Evento Confirmado!" });
    }
  } catch (error) {
    dispatch(setLoading(false));
    showModalError({ dispatch, error });
  }
};
