import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import {
  Button,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { contractHasManagement } from "Components/Admin/administrator/contracts/utils";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import renewalService from "services/renewalService";
import planService from "services/planService";
import { numberWithCommas } from "utils";
import { useGetToken } from "hooks";
import { IPlan } from "models/Plan";

import RenewalCustomSelect from "../RenewalCustomSelect";
import RenewalCustomInput from "../RenewalCustomInput";
import useRenewalStyles from "./RenewalStyles";

import { getErrorMessage } from "../../utils/utils";

function Renewal(props) {
  const { getToken } = useGetToken();
  const classes = useRenewalStyles();
  const [planId, setPlanId] = useState("");
  const [readjust, setReadjust] = useState("");
  const [fechaEmision, setFechaEmision] = useState(null);
  const [typeMoney, setTypeMoney] = useState("pesos");
  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState("");
  const [isOpenInitDate, setIsOpenInitDate] = useState(false);
  const [planList, setPlanList] = useState<IPlan[] | null>(null);
  const [loading, setLoading] = useState(true);

  const onChangeMoneyType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypeMoney(e.target.value);
  };

  const onChangeDate = (dateString: string) => {
    setFechaEmision(dateString);
  };

  useEffect(() => {}, [props.plan.id]);

  useEffect(() => {
    setLoading(true);
    getToken().then(async (authToken) => {
      try {
        const planFetch = await planService.getPlans(props.property.country, authToken);
        const parsePlans = planFetch?.data?.map((p: IPlan) => ({
          ...p,
          value: p.id,
          label: p.name,
        }));
        setSamePlan(parsePlans);
      } catch (e) {
        console.error(e);
        setPlanList([]);
      } finally {
        setLoading(false);
      }
    });
  }, []);

  const setSamePlan = (plansLists: IPlan[]) => {
    setPlanList(
      plansLists.map((plan) => {
        if (props.plan.id === plan.id) {
          setPlanId(props.plan.id);
          return {
            ...plan,
            name: `Continuar con mismo plan (${props.plan.description})`,
            id: plan.id,
          };
        }
        return plan;
      })
    );
  };

  const submit = async () => {
    const authToken = await getToken();
    if (planId === "") {
      Swal.fire("Atención", "Debe elegir el plan", "warning");
      return;
    }
    if (amount === null || amount === "") {
      Swal.fire("Atención", "Debe completar el nuevo monto", "warning");
      return;
    }
    if (readjust === "" && props.property.country === "Chile") {
      Swal.fire("Atención", "Debe elegir el tipo de reajuste", "warning");
      return;
    }
    if (parseInt(planId) !== props.plan.id) {
      Swal.fire({
        title: "Atención",
        text: "Se va a cambiar el plan de la suscripción, ¿Está seguro?",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          renew(authToken);
        }
      });
    } else {
      renew(authToken);
    }
  };

  const renew = async (authToken: string) => {
    try {
      if (props.property.country == "Chile") {
        await renewalService.renew(
          {
            contract_id: props.contract.id,
            amount: parseFloat(amount),
            discount: discount > 0 ? discount : 0,
            issue_date: fechaEmision,
            plan_id: typeof planId === "number" ? planId : parseInt(planId),
            readjust,
            money_type: typeMoney,
            country: props.property.country,
          },
          authToken
        );
      } else {
        await renewalService.renew(
          {
            subscription_id: props.id,
            amount: parseInt(amount),
            plan_id: typeof planId === "number" ? planId : parseInt(planId),
            months: 12,
            country: props.property.country,
          },
          authToken
        );
      }
      Swal.fire("Success", "Se ha renovado correctamente", "success").then(() =>
        window.location.reload()
      );
    } catch (err) {
      const error = err as AxiosError<{ detail?: string }>;
      const reason = error.response?.data?.detail;
      Swal.fire("Error", `No se pudo renovar. ${getErrorMessage(reason)}`, "error");
    }
  };

  if (!contractHasManagement(props.contract)) {
    return (
      <CardContent>
        <div className={classes.centerContainer}>
          <h4>No disponible, contrato sin administración</h4>
        </div>
      </CardContent>
    );
  }

  if (props.status !== "LEASED") {
    return (
      <CardContent>
        <div className={classes.centerContainer}>
          <h4>No disponible, subscripción inactiva</h4>
        </div>
      </CardContent>
    );
  }

  return (
    <Loading loading={loading}>
      <CardContent>
        <div className={classes.gridContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} container direction="column" spacing={2}>
              <div>
                <div className={classes.inputContainer}>
                  <h6 className={classes.title}>Monto Antiguo</h6>
                  <span>
                    {props.contract.type_money === "pesos"
                      ? `$ ${numberWithCommas(props.contract.amount)}`
                      : `${numberWithCommas(props.contract.amount)} ${props.contract.type_money}`}
                  </span>
                </div>
                <RenewalCustomInput
                  id="newAmount"
                  placeholder="Nuevo Monto"
                  name="newAmount"
                  setValue={setAmount}
                  value={amount}
                  title="Nuevo Monto"
                  type="number"
                  valid={true}
                />

                {props.property.country === "Chile" && (
                  <div className={classes.inputContainer}>
                    <FormControl component="fieldset">
                      <FormLabel className={classes.title} component="legend">
                        Tipo
                      </FormLabel>
                      <RadioGroup
                        classes={{
                          root: classes.rootRadio,
                        }}
                        aria-label="currency"
                        name="currency"
                        value={typeMoney}
                        onChange={onChangeMoneyType}>
                        <FormControlLabel
                          value="pesos"
                          control={
                            <Radio
                              color="primary"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "pesos" }}
                            />
                          }
                          label="Pesos"
                        />
                        <FormControlLabel
                          value="uf"
                          control={
                            <Radio
                              color="primary"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "uf" }}
                            />
                          }
                          label="UF"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                <RenewalCustomInput
                  id="discount"
                  placeholder="Descuento"
                  name="discount"
                  setValue={setDiscount}
                  value={discount}
                  title="Porcentaje de descuento"
                  type="number"
                  valid={props.property.country === "Chile"}
                />
                {props.property.country === "Chile" && (
                  <div className={classes.inputContainer}>
                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <div className={classes.inputContainer}>
                        <h6 className={classes.title}>Fecha de emision póliza</h6>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          id="date-picker-inline"
                          value={fechaEmision}
                          className={classes.dataPicker}
                          onChange={(date, dateString) => {
                            onChangeDate(dateString);
                            setIsOpenInitDate(false);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setIsOpenInitDate(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setIsOpenInitDate(false);
                            },
                          }}
                          InputProps={{
                            disableUnderline: true,
                            onFocus: () => {
                              setIsOpenInitDate(true);
                            },
                          }}
                          open={isOpenInitDate}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                )}
                <RenewalCustomSelect
                  id="plan"
                  title="Plan"
                  name="plan"
                  setValue={setPlanId}
                  value={planId}
                  defaultValue=""
                  defValue="Seleccione plan"
                  listOptions={planList}
                  keyValue="id"
                  disabled={false}
                  valid={true}
                />
                <RenewalCustomSelect
                  id="adjustment"
                  title="Reajuste"
                  name="adjustment"
                  setValue={setReadjust}
                  value={readjust}
                  defValue="Seleccione reajuste"
                  listOptions={[
                    { code: "Anual", name: "Anual" },
                    { code: "Semestral", name: "Semestral" },
                    { code: "Trimestral", name: "Trimestral" },
                    { code: "Sin Reajuste", name: "Sin Reajuste" },
                  ]}
                  keyValue="code"
                  disabled={false}
                  valid={props.property.country === "Chile"}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.centerContainer}>
          <Button
            classes={{
              root: classes.submitButton,
            }}
            onClick={() => submit()}>
            Guardar
          </Button>
        </div>
      </CardContent>
    </Loading>
  );
}

export default Renewal;
