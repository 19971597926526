import { makeStyles, Theme } from "@material-ui/core";

const useSubscriptionTitleStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.25,
  },
}));

export default useSubscriptionTitleStyles;
