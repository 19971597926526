import { Typography } from "@material-ui/core";
import useStyles from "./SimulateTextfieldStyles";

interface Props {
  title?: string;
  value: string;
  isHorizontal?: boolean;
}

function SimulateTextfield({ title, value, isHorizontal = false }: Props) {
  const classes = useStyles({ isHorizontal });
  return (
    <div className={isHorizontal ? classes.rowContainer : classes.container}>
      {title && (
        <Typography className={isHorizontal ? classes.disabledTitle : classes.title}>
          {title}
        </Typography>
      )}
      <Typography className={isHorizontal ? classes.disabledText : classes.text}>
        {value}
      </Typography>
    </div>
  );
}
export default SimulateTextfield;
