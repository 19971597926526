import { makeStyles, Theme } from "@material-ui/core";

const useUserDataStyles = makeStyles<Theme>((theme) => ({
  applicantContainer: {
    margin: theme.spacing(1.5, 0),
  },
  applicantData: {
    fontSize: theme.typography.pxToRem(16),
  },
  disclaimer: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useUserDataStyles;
