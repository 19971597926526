import { ChangeEvent } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import useCustomRadioButtonStyles from "./CustomRadioButtonStyles";

export interface Props {
  options: { value: string | number; label: string; description?: string }[];
  label?: string;
  value: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  id: string;
  disabled?: boolean;
}

const Label = ({ label, description }: { label: string; description?: string }) => {
  const classes = useCustomRadioButtonStyles();
  return (
    <Box className={classes.labelContainer}>
      {label}
      {description && <Typography className={classes.description}>{description}</Typography>}
    </Box>
  );
};
const CustomRadioButton = ({
  options,
  label: radioLabel,
  value: radioValue,
  onChange,
  id,
  disabled = false,
}: Props) => {
  const classes = useCustomRadioButtonStyles();
  return (
    <FormControl>
      {radioLabel && (
        <FormLabel classes={{ root: classes.label }} id={id}>
          {radioLabel}
        </FormLabel>
      )}
      <RadioGroup value={radioValue} onChange={onChange} name="controlled-radio-buttons-group">
        {options.map(({ value, label, description }) => (
          <FormControlLabel
            classes={{ root: classes.option }}
            value={value}
            key={label}
            disabled={disabled}
            control={<Radio color="primary" classes={{ root: classes.checkContainer }} />}
            label={<Label label={label} description={description} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioButton;
