import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";

import { FieldProp } from "context/closureFormContext/closureFormFields";
import { SelectionOption } from "context/closureFormContext/closureFormTypes";
import { CountryUpperCase } from "models/Countries";

import useStyles from "./ContractValuesStyles";

export function NoData() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.noData}>No hay datos</Typography>
      </Grid>
    </Grid>
  );
}

interface ValueEntryProps {
  label: string;
  value: string;
}

export const ValueEntry: React.FC<ValueEntryProps> = ({ label, value }) => {
  const classes = useStyles();
  let displayValue = value;
  if (!value || value === "undefined") displayValue = "Sin registro";
  return (
    <Grid container item xs={12}>
      <Grid item xs={6} md={5}>
        <Typography className={classes.entryText}>{label}:</Typography>
      </Grid>
      <Grid item xs={6} md={7} className={classes.gridValue}>
        <Typography
          className={classNames(classes.entryText, {
            [classes.noRegistry]: !value || value === "undefined",
          })}>
          {displayValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const filter = (
  displayLabel: undefined | (() => FieldProp),
  isAdmin: boolean,
  country: CountryUpperCase
) =>
  typeof displayLabel !== "function" ||
  (!isAdmin && displayLabel()?.admin) ||
  !displayLabel()?.countries.includes(country);

export interface ValueProps {
  valueKey: string;
  value: string | number | SelectionOption;
  isAdmin: boolean;
  country: CountryUpperCase;
}
