import { SnackbarProvider } from "notistack";

import { PlansProvider } from "context/plansContext/plansContext";
import PlanDetail from "./PlanDetail";

export default function CreatePlanIndex() {
  return (
    <PlansProvider>
      <SnackbarProvider>
        <PlanDetail />
      </SnackbarProvider>
    </PlansProvider>
  );
}
