import { makeStyles, Theme } from "@material-ui/core";

const usePenaltyDisclaimerStyles = makeStyles<Theme>((theme) => ({
  disclaimerContext: {
    backgroundColor: "#EAF1FB",
    padding: theme.spacing(2),
    gap: theme.spacing(0.5),
    borderRadius: 8,
    display: "grid",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default usePenaltyDisclaimerStyles;
