import { useGetToken } from "hooks";
import { UseQueryResult, useQuery } from "react-query";
import debtCollectionService from "services/debtCollectionService";
import { IDebtCollectionFilters, IDebtCollectionResponse } from "./utils/types";
import { IDebtCollection } from "models/IDebtCollection";
import { objectToCamelCase } from "./utils/parsers";

export default function useGetDebtCollections(filters: IDebtCollectionFilters) {
  const { getToken } = useGetToken();

  const { data, isLoading, isError }: UseQueryResult<IDebtCollectionResponse, Error> = useQuery<
    IDebtCollectionResponse,
    Error
  >(
    ["getDebtCollections", filters],
    async () => {
      const token = await getToken();
      const response = await debtCollectionService.getDebtCollections(token, filters);
      const { total } = response.data;
      return {
        total,
        data: (response?.data?.elements ?? [])
          .map((item: IDebtCollection) => objectToCamelCase<IDebtCollection>(item))
          .sort((item: IDebtCollection) => (item.collectionProcessStatus === "FINISHED" ? 1 : -1)),
      };
    },
    {
      enabled: !!filters.country,
    }
  );

  return {
    debtCollections: data?.data ?? [],
    totalDebtCollections: data?.total,
    isDebtCollectionsLoading: isLoading,
    isError,
  };
}
