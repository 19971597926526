import { format } from "date-fns";

import { cn } from "@houm-com/ui/utils";
import ModalDialog from "@houm-com/ui/ModalDialog";

import useGetDebtCollectionDetails from "hooks/useGetDebtCollectionDetail";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { Locale } from "models/Countries";

import classes from "./MoreInfoModalStyles";
import CommentHistory from "../CommentHistory";

interface MoreInfoModalProps {
  handleClose: () => void;
  country: Locale;
  isOpen: boolean;
  id: number;
}

const RentStatusTag = ({ status }: { status: string }) => {
  if (!status) return null;
  const isPublished = status === "published";
  return (
    <div
      className={cn(classes.tag, {
        [classes.publishedTag]: isPublished,
        [classes.unpublishedTag]: !isPublished,
      })}>
      {isPublished ? "Publicado" : "No publicado"}
    </div>
  );
};

const IncomeMovementInfo = ({ text, value }) => (
  <div className={classes.infoContainer}>
    <span className={classes.text}>{text} </span>
    <span>{!Number.isNaN(value) ? value : "-"}</span>
  </div>
);

const ContractDate = ({ text, date }) => {
  const formattedDate = date ? format(new Date(`${date}T00:00:00`), "dd/MM/yyyy") : "-";
  return (
    <div className={classes.infoContainer}>
      <span className={classes.text}>{text}</span>
      <span>{formattedDate}</span>
    </div>
  );
};

export default function MoreInfoModal({ isOpen, handleClose, id, country }: MoreInfoModalProps) {
  const { debtCollectionDetail, isDebtCollectionDetailLoading, isError } =
    useGetDebtCollectionDetails(id);
  return (
    <ModalDialog isOpen={isOpen} title="" hasCloseBtn onClose={handleClose}>
      <Loading loading={isDebtCollectionDetailLoading}>
        {isError ? (
          <p>Ocurrió un error buscando la información</p>
        ) : (
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <h2 className={classes.title}>Más información</h2>
            </div>
            <p className={classes.subtitle}>Estados</p>
            <div className={cn(classes.text, classes.infoContainer)}>
              Estado propiedad:{" "}
              <RentStatusTag status={debtCollectionDetail?.property?.rentStatus} />
            </div>
            <div className={cn(classes.text, classes.infoContainer)}>
              Estado contrato:{" "}
              {debtCollectionDetail?.contract?.status && (
                <div className={cn(classes.contractTag, classes.tag)}>
                  {debtCollectionDetail?.contract?.status}
                </div>
              )}
            </div>
            <p className={classes.subtitle}>Fechas de contrato</p>
            <ContractDate
              text="Fecha de inicio del contrato: "
              date={debtCollectionDetail?.contract?.initDate}
            />
            <ContractDate
              text="Fecha de finalización del contrato: "
              date={debtCollectionDetail?.contract?.endDate}
            />
            <div className={classes.titleContainer}>
              <h2 className={classes.title}>Datacrédito</h2>
            </div>
            <IncomeMovementInfo
              text="Total de movimientos:"
              value={debtCollectionDetail?.incomeMovementsData?.totalMovements}
            />
            <IncomeMovementInfo
              text="Movimientos pagados:"
              value={debtCollectionDetail?.incomeMovementsData?.paidDebtMovements}
            />
            <IncomeMovementInfo
              text="Movimientos no pagados:"
              value={debtCollectionDetail?.incomeMovementsData?.unpaidDebtMovements}
            />
            <CommentHistory country={country} debtCollectionDetail={debtCollectionDetail} />
          </div>
        )}
      </Loading>
    </ModalDialog>
  );
}
