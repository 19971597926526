export default {
  cc: "CC",
  predial: "Último predial del inmueble",
  liberty_tradition: "Certificado de Tradición y Libertad del inmueble",
  water_service_receipt: "Último recibo de Agua",
  gas_service_receipt: "Último recibo de Gas",
  energy_service_receipt: "Último recibo de Energía",
  administration_service_receipt: "Último recibo de Administración",
  cleaning_service_receipt: "Último recibo del Aseo ",
};
