import { format } from "rut.js";
import { format as formatDate } from "date-fns";
import { capitalize, startCase, toLower } from "lodash";

import { AddressEvaluationDetails, DetailsModel, EvaluationModel } from "models/Evaluation";
import { IRents } from "context/evaluationContext/evaluationTypes";
import { statesMx } from "constants/mexicoStates";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";

export const getRentsSum = (rents: IRents[]) =>
  Object.values(rents).reduce((acc, curr): number => acc + parseFloat(curr as string), 0);

export interface ContractPersonEvaluation extends EvaluationModel {
  document: string;
  document_type?: string | number;
  rfc_number?: string;
  occupation: string;
  average_salary: string;
  nationality: string;
  employer_name: string;
  address: {
    city: string;
    colonia: string;
    exteriorNumber: string;
    interiorNumber: string;
    state: string;
    street: string;
    zip: string;
  };
}

export const parseContractPersonEvaluation = (
  ev: EvaluationModel,
  details: DetailsModel
): ContractPersonEvaluation => ({
  ...ev,
  document: ev.user.country === "Chile" ? format(details?.document) : details?.document,
  occupation: details?.occupation,
  average_salary: details?.salary_avg,
  nationality: details?.nationality,
  employer_name: details?.employer,
  document_type: ev.user.country === "Chile" ? 1 : details?.documentType,
  address: details?.address,
});

const renderDocumentType = (user: UserModel, evaluation: ContractPersonEvaluation) => {
  if (user.country === "Chile") return 1;
  return evaluation?.document_type ? evaluation?.document_type : user?.document_type;
};

const capitalizeWord = (word: string) => startCase(toLower(word));

const parseAddressData = (addressData: AddressEvaluationDetails, country: Locale) => {
  if (!addressData) return "";
  if (country === "mx")
    return `${capitalizeWord(addressData.street)} ${addressData.interiorNumber} ${
      addressData.exteriorNumber
    }, ${capitalize(addressData.colonia)}, ${
      statesMx.find((state) => state.value === addressData.state).label
    }, CP ${addressData.zip}, ${capitalize(addressData.city)}`;
  return `${capitalizeWord(addressData.street)}, ${capitalize(addressData.city)}`;
};

export const parseTenantData = (
  evaluationData: ContractPersonEvaluation[] | EvaluationModel[],
  country: Locale
) =>
  evaluationData.map((ev) => ({
    name: `${ev.user.name} ${ev.user.last_name} ${ev.user.second_last_name}`,
    rut: ev?.document ?? ev.user.rut,
    document: ev?.document ?? ev.user.document,
    document_type: renderDocumentType(ev.user, ev),
    phone: ev.user.phone,
    email: ev.user.email,
    occupation: ev?.occupation,
    average_salary: ev?.average_salary,
    nationality: ev?.nationality,
    employer_name: ev?.employer_name,
    address: parseAddressData(ev?.address, country),
    rfc_number: ev?.rfc_number,
  }));

export const parseContractData = (
  evaluationData: ContractPersonEvaluation[] | EvaluationModel[],
  selectedDate: string,
  habitants: string,
  houmerId: string,
  tenantHasPets: boolean,
  country: Locale
) => ({
  init_date_proposal: formatDate(new Date(selectedDate), "yyyy-MM-dd"),
  property: evaluationData[0].property_id,
  tenants: parseTenantData(evaluationData, country),
  habitants_description: habitants,
  houmer: houmerId ?? null,
  evaluation_ids: evaluationData.map((ev) => ev.id),
  tenant_has_pets: tenantHasPets,
});
