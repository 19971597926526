import { Grid } from "@material-ui/core";

import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { CountryUpperCase } from "models/Countries";

interface Props {
  country?: CountryUpperCase | null;
  promotionSteps: {
    label: string;
    link?: string | null;
  }[];
}

const HeaderPromotion = ({ country, promotionSteps }: Props) => (
  <Grid container item xs={12} alignItems="center" justifyContent="space-between">
    <Breadcrumbs steps={promotionSteps} />
    <CountryChip country={country} selected={true} />
  </Grid>
);

export default HeaderPromotion;
