import { PromotionTarget } from "./types";

export const promotionTargetOptions = [
  { value: "management_fee", label: "Comisión de administración" },
  { value: "brokerage", label: "Corretaje" },
  { value: "both", label: "Comisión y Corretaje" },
];

export const promotionUserOptions = (target: PromotionTarget) => [
  { value: "landlord", label: "Propietario" },
  { value: "tenant", label: "Arrendatario", disabled: target !== "brokerage" },
  { value: "both", label: "Ambos", disabled: target === "management_fee" },
];

export const promotionTypeOptions = (target: PromotionTarget) => [
  {
    value: "percentage",
    label: "Porcentaje",
    disclaimer: "Porcentaje de descuento por aplicar",
  },
  {
    value: "fixed",
    label: "Cantidad fija",
    disclaimer: "Cantidad específica de descuento que se realizará",
  },
];
