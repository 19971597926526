import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { Box, BoxProps, Hidden, Typography } from "@material-ui/core";
import { ReactComponent as ArrowLeft } from "assets/icons/arrows/arrow-left.svg";

import useBackNavigationStyles from "./BackNavigationStyles";

interface Props extends Omit<BoxProps, "className"> {
  propertyId?: string;
  className?: string;
  backText?: string;
}

function BackNavigation({ backText, propertyId, className, ...props }: Props) {
  const classes = useBackNavigationStyles();
  const history = useHistory();

  return (
    <Hidden smDown>
      <Box
        className={clsx(classes.navigationWrapper, className)}
        onClick={() =>
          propertyId ? history.push(`/landlord/properties/${propertyId}`) : history.goBack()
        }
        {...props}>
        <ArrowLeft className={classes.arrow} />
        <Typography className={classes.text}>{backText || "Volver"}</Typography>
      </Box>
    </Hidden>
  );
}

export default BackNavigation;
