import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftColumn: {
    width: "48%",
  },
  rightColumn: {
    width: "48%",
  },
  background: {
    backgroundColor: "#EFF2F3",
    borderRadius: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(1),
    width: "100%",
    "& input.MuiInputBase-input": {
      color: theme.palette.grey[500],
    },
  },
}));

export default useStyles;
