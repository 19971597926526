import { Controller, useFormContext } from "react-hook-form";

import { TextFieldProps } from "@material-ui/core";

import { Country } from "models/PhoneInput";
import PhoneInput from "Components/Admin/UIComponents/CountryInput/PhoneInput";

interface Props {
  phoneLabel?: string;
  numberInputProps?: TextFieldProps;
  countryLabel?: string;
  countryInputProps?: TextFieldProps;
  id: string;
  defaultValue?: { country: Country; phone: string };
  labelClassName?: string;
  wrapperPadding?: boolean;
  disabled?: boolean;
  fieldContainerLabel?: string;
  fieldContainerLabelClassName?: string;
  classNameInputContainer?: string;
  countryWrapperClassName?: string;
  numberWrapperClassName?: string;
}

function ControlledPhoneInput({
  id,
  defaultValue,
  phoneLabel,
  numberInputProps,
  countryLabel,
  countryInputProps,
  labelClassName,
  wrapperPadding = true,
  disabled = false,
  fieldContainerLabel,
  fieldContainerLabelClassName,
  classNameInputContainer,
  countryWrapperClassName,
  numberWrapperClassName,
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error, invalid } }) => (
        <PhoneInput
          numberInputProps={{
            error: invalid,
            fullWidth: true,
            ...numberInputProps,
          }}
          countryInputLabel={countryLabel}
          countryInputProps={countryInputProps}
          error={invalid}
          disabled={disabled}
          wrapperPadding={wrapperPadding}
          errorMessage={error}
          onChange={onChange}
          value={value || defaultValue}
          numberInputLabel={phoneLabel}
          labelClassName={labelClassName}
          fieldContainerLabel={fieldContainerLabel}
          fieldContainerLabelClassName={fieldContainerLabelClassName}
          classNameInputContainer={classNameInputContainer}
          countryWrapperClassName={countryWrapperClassName}
          numberWrapperClassName={numberWrapperClassName}
        />
      )}
    />
  );
}

export default ControlledPhoneInput;
