import {
  State,
  Action,
  SET_PROPERTY_INFO,
  SET_PROPERTY_ID,
  CLEAR_PROPERTY,
  SET_LOADING_DATA,
  SET_PROPERTY_SCHEDULE,
  SET_LOADING_SCHEDULE,
  SET_MULTIPROPERTY_ID,
} from "./availabilityTypes";

export const initialState: State = {
  propertyId: null,
  propertyInfo: {
    country: "Chile",
    keysState: "",
    location: "",
    address: "",
    houmbox: null,
  },
  propertyAvailability: [],
  availableDays: [],
  loadingSchedule: false,
  loadingData: false,
  multiPropertyId: [],
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_PROPERTY_INFO: {
      return { ...state, propertyInfo: action.propertyData };
    }
    case SET_PROPERTY_ID: {
      return { ...state, propertyId: action.propertyId };
    }
    case CLEAR_PROPERTY: {
      return {
        ...state,
        propertyId: null,
        multiPropertyId: [],
        propertyInfo: {
          country: "",
          keysState: "",
          location: "",
          address: "",
          houmbox: null,
        },
        propertyAvailability: [],
      };
    }
    case SET_LOADING_DATA: {
      return { ...state, loadingData: action.loadingData };
    }
    case SET_LOADING_SCHEDULE: {
      return { ...state, loadingSchedule: action.loadingSchedule };
    }
    case SET_PROPERTY_SCHEDULE: {
      return { ...state, propertyAvailability: action.propertyAvailability };
    }
    case SET_MULTIPROPERTY_ID: {
      return { ...state, multiPropertyId: action.multiPropertyId };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
