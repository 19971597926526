import * as yup from "yup";

import {
  maximumValueByCountry,
  MAX_CLF_AMOUNT,
} from "Components/Admin/administrator/subscriptions/components/Payments/utils/constants";
import { Locale } from "models/Countries";

import { getFlagChileBasicPlan } from "./utils";

export const requiredText = "Obligatorio";

type FormType = "lessee" | "lessor";

export const paymentOrderSchema = (
  formType: FormType,
  country: Locale,
  isBasicPlanFlag: boolean,
  lesseDescription: string
) => {
  const isLessorDataFlag =
    country !== "cl" ||
    !isBasicPlanFlag ||
    getFlagChileBasicPlan(country, isBasicPlanFlag, lesseDescription);

  const numberValidation = yup.number().when("lesseeCurrency", (currency, schema) => {
    if (currency === "UF" && country === "cl") {
      return schema
        .min(0, "El descuento/abono no puede ser negativo")
        .max(MAX_CLF_AMOUNT, "El descuento/abono supera el máximo de 60 UF establecido")
        .typeError(requiredText)
        .required(requiredText);
    }
    return schema
      .min(0, "El descuento/abono no puede ser negativo")
      .max(maximumValueByCountry[country], "El descuento/abono supera el máximo establecido")
      .typeError(requiredText)
      .required(requiredText);
  });

  return yup.object({
    lesseeAmount: numberValidation,
    lesseeDescription: yup.string().required(requiredText),
    lesseeDate: yup.string().required(requiredText),
    lessorAmount: formType === "lessor" && isLessorDataFlag && numberValidation,
    lessorDescription:
      formType === "lessor" && isLessorDataFlag && yup.string().required(requiredText),
    lessorDate: formType === "lessor" && isLessorDataFlag && yup.string().required(requiredText),
    lesseeCurrency:
      country === "cl" && formType === "lessee" && yup.string().required(requiredText),
  });
};
