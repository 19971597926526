import * as yup from "yup";

import {
  MAX_CLF_AMOUNT,
  maximumValueByCountry,
} from "Components/Admin/administrator/subscriptions/components/Payments/utils/constants";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

export const requiredText = "Obligatorio";

export const movementPlanSchema = (country: Locale, contractCurrency: CurrencyCode) =>
  yup.object({
    amount: yup.number().when("currency", (currency, schema) => {
      if (currency === "UF" && country === "cl") {
        return schema
          .min(0, "El descuento/abono no puede ser negativo")
          .max(MAX_CLF_AMOUNT, "El descuento/abono supera el máximo de 60 UF establecido")
          .typeError(requiredText)
          .required(requiredText);
      }
      return schema
        .min(0, "El descuento/abono no puede ser negativo")
        .max(maximumValueByCountry[country], "El descuento/abono supera el máximo establecido")
        .typeError(requiredText)
        .required(requiredText);
    }),
    description: yup.string().required(requiredText),
    adjustmentType: yup.string().required(requiredText),
    currency: country === "cl" && contractCurrency === "CLF" && yup.string().required(requiredText),
  });
