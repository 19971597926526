import { useState } from "react";
import classNames from "classnames";

import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Chip from "@material-ui/core/Chip";

import useCollapseCardStyles from "./CollapseCardStyles";

interface Props {
  children: React.ReactNode;
  title: string;
  editData?: boolean;
  onEdit?: () => void;
  status?: string | null;
  rootstyle?: string;
  chipClassName?: string;
}

function CollapseCard({
  children,
  title,
  editData = false,
  onEdit,
  status = null,
  rootstyle = "",
  chipClassName = "",
}: Props) {
  const classes = useCollapseCardStyles();
  const [open, setOpen] = useState<boolean>(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      classes={{
        root: classNames(classes.root, rootstyle),
      }}>
      <ListItem classes={{ root: classes.head }}>
        <ListItemText primary={title} classes={{ primary: classes.title }} />
        <div>
          {status && (
            <Chip
              label={status}
              className={classNames(classes.status, chipClassName)}
              variant="outlined"
            />
          )}
          {editData && (
            <span className={classes.editText} onClick={onEdit}>
              Editar datos
            </span>
          )}
          {open ? (
            <ExpandLess className={classes.icon} onClick={handleClick} />
          ) : (
            <ExpandMore className={classes.icon} onClick={handleClick} />
          )}
        </div>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
}

export default CollapseCard;
