import Typography from "@material-ui/core/Typography";

import useStyles from "./NoResultsStyles";

function NoResults() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>No existen resultados para esta combinación de filtros</Typography>
    </div>
  );
}

export default NoResults;
