import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  onePercentPadding: {
    padding: "1%",
  },
  title: {
    fontSize: theme.typography.pxToRem(22),
  },
}));
