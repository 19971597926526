import { makeStyles } from "@material-ui/core";

const useFilterModalStyles = makeStyles((theme) => ({
  subtitle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "elipsis",
  },
  removeButton: {
    textTransform: "none",
    fontSize: "1rem",
  },
  filtersContainer: {
    marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  button: {
    fontSize: "1rem",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "40%",
    borderRadius: "50px",
    textTransform: "none",
  },
  footer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useFilterModalStyles;
