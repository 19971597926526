import { useState } from "react";

import { StandardTextFieldProps } from "@material-ui/core";

import { IUserAutoComplete } from "models/createProperty";
import { IUserFilters } from "models/User";

import UserAutocomplete from "../UserAutocomplete";
import useUsers from "./useUsers";

interface Props {
  label: string;
  id: string;
  onChange: (e: any) => void;
  value: IUserAutoComplete;
  defaultValue?: IUserAutoComplete;
  textFieldProps?: StandardTextFieldProps;
  qParams?: IUserFilters;
  classes?: {
    container?: string;
    label?: string;
  };
  error?: boolean;
  errorMessage?: string;
  noOptionsText?: string | React.ReactNode;
}

export default function UserSuggester({
  id,
  label,
  value,
  onChange,
  defaultValue,
  error,
  errorMessage,
  textFieldProps,
  qParams = {},
  noOptionsText,
  classes: propClasses,
}: Props) {
  const [querySearch, setQuerySearch] = useState<string>(null);
  const { users, isLoading, isError } = useUsers(querySearch, qParams, { enabled: !!querySearch });

  return (
    <UserAutocomplete
      value={value}
      id={id}
      label={label}
      error={error || isError}
      onQueryChange={(querySearch) => setQuerySearch(querySearch)}
      loading={isLoading}
      users={users}
      defaultValue={defaultValue}
      textFieldProps={textFieldProps}
      onChange={onChange}
      classes={propClasses}
      errorMessage={errorMessage}
      noOptionsText={noOptionsText}
    />
  );
}
