import { IOption } from "Components/Admin/administrator/properties/components/FormElements/Selectable";
import { PropertyDetails } from "models";

enum ColombiaLocality {
  Antioquia = "Antioquia",
  Bogota = "Bogotá",
}

enum ColombiaAdminAreaLevel1 {
  Cundinamarca = "Cundinamarca",
}

enum MexicoAdminAreaLevel1 {
  Jalisco = "Jalisco",
}

enum AddressComponent {
  Country = "country",
  StreetNumber = "street_number",
  Route = "route",
  Intersection = "intersection",
  Neighborhood = "neighborhood",
  Sublocality = "sublocality",
  Locality = "locality",
  AdminArea3 = "administrative_area_level_3",
  AdminArea2 = "administrative_area_level_2",
  AdminArea1 = "administrative_area_level_1",
}

/** Small domain based utils */
const pickThirdPartOfListedString = (listedString) => {
  const thirdPart = listedString.split(",")[2];
  const sanitizedThirdPart = thirdPart?.replace(/[0-9]/g, "").trim();
  return sanitizedThirdPart;
};

export const formatAddress = (sgs) => {
  // sgs documentation
  // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes

  const addressProp = (prop) => sgs.address_components.find((item) => item.types.includes(prop));

  const googleCountry: countries = addressProp(AddressComponent.Country).long_name;
  const sanitizedCountry = googleCountry.replace("é", "e");
  const googleStreetNumber = addressProp(AddressComponent.StreetNumber);
  const googleRoute = addressProp(AddressComponent.Route);
  const googleIntersection = addressProp(AddressComponent.Intersection);
  const googleNeighborhood = addressProp(AddressComponent.Neighborhood);
  const googleSublocality = addressProp(AddressComponent.Sublocality);
  const googleLocality = addressProp(AddressComponent.Locality);
  const googleAdminArea3 = addressProp(AddressComponent.AdminArea3);
  const googleAdminArea2 = addressProp(AddressComponent.AdminArea2);
  const googleAdminArea1 = addressProp(AddressComponent.AdminArea1);

  const googleFormattedAddress = sgs.formatted_address;

  /** Chile comune and region */
  const chileanCommune = googleAdminArea3 || googleLocality;

  /** Colombia comune and region */
  const COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_2 = {
    [ColombiaLocality.Antioquia]: googleLocality,
  };
  const COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_1 = {
    [ColombiaLocality.Antioquia]: googleLocality,
  };

  const COLOMBIAN_COMMUNE_FALLBACK = googleSublocality || googleLocality;

  const colombianCommune =
    COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_1[googleAdminArea1?.long_name] ||
    COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_2[googleAdminArea2?.long_name] ||
    COLOMBIAN_COMMUNE_FALLBACK;

  const isOnCundinamarca = googleAdminArea1?.long_name === ColombiaAdminAreaLevel1.Cundinamarca;
  const isOnBogota = googleLocality?.long_name === ColombiaLocality.Bogota;

  const colombianRegion = isOnCundinamarca && isOnBogota ? googleLocality : googleAdminArea1;

  /** Mexico comune and region */
  const mexicanCommune =
    googleAdminArea1?.long_name !== MexicoAdminAreaLevel1.Jalisco
      ? { long_name: pickThirdPartOfListedString(googleFormattedAddress) }
      : googleLocality;

  /** Commune and region by country */
  const LOCATION_VALUES_BY_COUNTRY = {
    [countries.CL]: {
      commune: chileanCommune,
      region: googleAdminArea1,
      neighborhood: googleNeighborhood,
    },
    [countries.CO]: {
      commune: colombianCommune,
      region: colombianRegion,
      neighborhood: googleNeighborhood,
    },
    [countries.MX]: {
      commune: mexicanCommune,
      region: googleAdminArea1,
      neighborhood: googleSublocality,
    },
  };

  const rawAddress = {
    city: googleAdminArea2,
    country: googleCountry,
    street: googleRoute || googleIntersection,
    streetNumber: googleStreetNumber,
    region: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].region,
    commune: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].commune,
    neighborhood: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].neighborhood,
  };

  // Check if lat / lng are callable, if yes, calls method to get position
  const lat =
    typeof sgs?.geometry?.location?.lat === "function"
      ? sgs.geometry.location.lat()
      : sgs?.geometry?.location?.lat;
  const lng =
    typeof sgs?.geometry?.location?.lng === "function"
      ? sgs.geometry.location.lng()
      : sgs?.geometry?.location?.lng;

  const parsedAddress = {
    prevSearch: sgs.formatted_address,
    neighborhood: rawAddress.neighborhood?.long_name || "",
    city: rawAddress.city?.long_name || "",
    street: rawAddress.street?.long_name || "",
    streetNumber: rawAddress.streetNumber?.long_name || "",
    commune: rawAddress.commune?.long_name || "",
    region: rawAddress.region?.long_name || "",
    country: rawAddress.country,
    lat,
    lng,
    latitude: lat,
    longitude: lng,
  };

  return parsedAddress;
};

export enum countries {
  CO = "Colombia",
  CL = "Chile",
  MX = "Mexico",
}
export const optionCountry: IOption[] = [
  {
    value: countries.CO,
    label: countries.CO,
  },
  {
    value: countries.MX,
    label: countries.MX,
  },
  {
    value: countries.CL,
    label: countries.CL,
  },
];

const basicTypesProperties = [
  {
    value: "departamento",
    label: "Departamento",
  },
  {
    value: "casa",
    label: "Casa",
  },
];

interface ICountry {
  tzName: string;
  defaultZone: string;
  zones: string[];
  placeholders?: {
    salePrice?: string;
    rentPrice?: string;
    gc?: string;
  };
  typeProperty: IOption[];
  currency: IOption[];
  labels: {
    rentPrice: string;
    documentName: string;
    taxes: string;
  };
  defaultTaxCurrency: string;
  documentType: IOption[];
  state?: string | null;
}

interface IGeneralOptions extends Record<countries, ICountry> {}

// to Do, usar libreria de internacionalizacion???
export const generalOptions: IGeneralOptions = {
  [countries.CO]: {
    zones: ["America/Bogota"],
    defaultZone: "America/Bogota",
    defaultTaxCurrency: "Pesos",
    tzName: "CO",
    placeholders: {
      salePrice: "20.000.000",
      rentPrice: "2.000.000",
      gc: "100.000 $",
    },
    typeProperty: basicTypesProperties,
    state: "cundinamarca",
    currency: [{ value: "Pesos", label: "COP" }],
    labels: {
      rentPrice: "Precio de arriendo",
      documentName: "Número de documento",
      taxes: "Impuesto predial",
    },
    documentType: [
      {
        label: "Cédula de ciudadanía",
        value: "cedula",
      },
      {
        label: "Cédula de extranjería",
        value: "cedulaext",
      },
      {
        label: "NIT",
        value: "nit",
      },
      {
        label: "Pasaporte",
        value: "pasaporte",
      },
      {
        label: "Sociedad extranjería",
        value: "sociedadext",
      },
    ],
  },
  [countries.CL]: {
    zones: ["America/Santiago"],
    defaultZone: "America/Santiago",
    defaultTaxCurrency: "U.F.",
    tzName: "CL",
    placeholders: {
      salePrice: "10",
      rentPrice: "200.000",
      gc: "20.000",
    },
    typeProperty: basicTypesProperties,
    currency: [
      { value: "Pesos", label: "CLP" },
      { value: "U.F.", label: "UF" },
    ],
    labels: {
      rentPrice: "Precio de arriendo",
      documentName: "RUT",
      taxes: "Contribuciones",
    },
    documentType: [],
    state: null,
  },
  [countries.MX]: {
    zones: ["America/Mexico_City", "America/Tijuana"],
    defaultZone: "America/Mexico_City",
    defaultTaxCurrency: "Pesos",
    tzName: "MX",
    placeholders: {
      salePrice: "200.000",
      rentPrice: "2.000",
      gc: "500",
    },
    state: "Estado de México",
    typeProperty: basicTypesProperties,
    currency: [{ value: "Pesos", label: "MXN" }],
    labels: {
      rentPrice: "Precio de renta",
      documentName: "Número de documento",
      taxes: "Impuesto predial",
    },
    documentType: [
      {
        label: "INE extranjería",
        value: "ine",
      },
      {
        label: "Pasaporte",
        value: "passmx",
      },
    ],
  },
};

export const validateAllRef = (fieldRefs) => {
  // validate a form
  let allowAction = true;
  fieldRefs.forEach((fRef) => {
    let val = fRef?.current?.validate();
    if (val === undefined) val = true;
    allowAction = allowAction && val;
  });
  return allowAction;
};
export const getValues = (fieldRefs) => {
  let values = {};
  fieldRefs.forEach((fRef) => {
    const val = fRef?.current?.getValue();
    values = {
      ...values,
      ...val,
    };
  });
  return values;
};

export const yesNoBoolean = [
  { value: true, label: "Si" },
  { value: false, label: "No" },
];
export const yesNoBinary = [
  { value: 1, label: "Si" },
  { value: 0, label: "No" },
];

export const propertyFurnishedOptions: {
  label: string;
  value: PropertyDetails["furnished"];
}[] = [
  { label: "Amoblado", value: "fully" },
  { label: "Semi-amoblado", value: "partially" },
  { label: "No amoblado", value: "non" },
];
