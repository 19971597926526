import { SnackbarProvider } from "notistack";
import Evaluation from "./Evaluation";

const EvaluationPage = () => (
  <SnackbarProvider>
    <Evaluation />
  </SnackbarProvider>
);

export default EvaluationPage;
