import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  linksContainer: {
    display: "flex",
  },
  link: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
