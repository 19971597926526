import { makeStyles } from "@material-ui/core";

const useToolbarStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
  },
}));

export default useToolbarStyles;
