import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import { VISA_TYPES } from "../EvaluateForm/utils/constants";

interface Props {
  documentTypes: {
    label: string;
    value: number;
  }[];
}

const PersonInformationForm = ({ documentTypes }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MemberInformation>();

  return (
    <div className="flex flex-col gap-1">
      <FieldInput
        id="name"
        placeholder="Ingresar nombre"
        label="Nombre"
        errorMessage={errors.name?.message}
        form="novalidateform"
        disabled
        required
        {...register("name")}
      />
      <FieldInput
        id="lastName"
        placeholder="Ingresar apellido"
        label="Apellido"
        errorMessage={errors.lastName?.message}
        form="novalidateform"
        disabled
        required
        {...register("lastName")}
      />
      <FieldInput
        id="secondLastName"
        placeholder="Ingresar segundo apellido"
        label="Segundo apellido"
        errorMessage={errors.secondLastName?.message}
        form="novalidateform"
        disabled
        required
        {...register("secondLastName")}
      />
      <FieldSelect
        label="Tipo de documento"
        id="documentType"
        placeholder="Selecciona un tipo de documento"
        options={documentTypes}
        form="novalidateform"
        disabled
        errorMessage={errors.documentType?.message}
        {...register("documentType")}
      />
      <FieldInput
        id="document"
        placeholder="Ingresar documento"
        label="Documento"
        errorMessage={errors.document?.message}
        form="novalidateform"
        disabled
        required
        {...register("document")}
      />
      <FieldInput
        id="nationality"
        placeholder="Ingresar nacionalidad"
        label="Nacionalidad"
        form="novalidateform"
        required
        errorMessage={errors.nationality?.message}
        {...register("nationality")}
      />
      <FieldSelect
        label="Tipo de visa"
        id="visaType"
        placeholder="Selecciona un tipo de visa"
        options={VISA_TYPES}
        form="novalidateform"
        errorMessage={errors.visaType?.message}
        {...register("visaType")}
      />
    </div>
  );
};

export default PersonInformationForm;
