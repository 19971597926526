import { ContractPropertyProps } from "../../../utils/types";

export const parseFormData = (data): ContractPropertyProps => ({
  commonExpenses: Number(data?.commonExpenses),
  commune: data?.commune,
  currencyCode: data?.currencyCode,
  flatNumber: data?.flatNumber,
  id: data?.id,
  detailsId: data?.detailsId,
  rentValue: Number(data?.rentValue),
  parkingNumber: data?.parkingNumber,
  street: data?.street,
  streetNumber: data?.streetNumber,
  warehouseNumber: Number(data?.warehouseNumber),
  inhabitantsAmount: Number(data?.inhabitantsAmount),
});
