import { useState, useEffect } from "react";
import { Button, Divider } from "@material-ui/core";

import { useLocalStorage } from "hooks";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { useList } from "context/demandLeadContext/list/listContext";
import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import {
  setIsIsOpenFilterModal,
  setFilters,
} from "context/demandLeadContext/leads/demandLeadActions";
import { filterModalStateOptions } from "../filter/constants";

import useFilterModalStyles from "./FilterModalStyles";
import Autocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";

function FilterModal() {
  const [lead, setLead] = useState<{ label: string; value: number } | null>();
  const [state, setState] = useState<{ label: string }[] | null>();
  const [origin, setOrigin] = useState<{ label: string } | null>();

  const modalFilters = {
    leadQualifier: lead,
    state,
    origin,
  };

  const { storedValue, setValue } = useLocalStorage("filters", {
    ...modalFilters,
  });

  const {
    state: { isOpenFilterModal, deal, filters },
    dispatch,
  } = useDemandLead();

  const {
    state: { executives, origins },
  } = useList();

  useEffect(() => {
    setState(storedValue.state || filters.state);
    setLead(storedValue.leadQualifier);
    setOrigin(storedValue.origin);
  }, [isOpenFilterModal]);

  useEffect(() => {
    if (!isOpenFilterModal) {
      dispatch(
        setFilters({
          ...filters,
          ...modalFilters,
        })
      );
    }
  }, [lead, state, origin]);

  const updateFilters = () => {
    dispatch(
      setFilters({
        ...filters,
        ...modalFilters,
      })
    );
    setValue({
      ...modalFilters,
    });
    closeModal();
  };

  const cleanFilters = () => {
    setLead(null);
    setState([]);
    setOrigin(null);
  };

  const closeModal = () => dispatch(setIsIsOpenFilterModal(false));
  const classes = useFilterModalStyles();

  return (
    <CustomDialog
      open={isOpenFilterModal}
      onClose={closeModal}
      subtitle={<div className={classes.subtitle}>Selecciona los filtros para los leads</div>}
      title="Filtros">
      <div className={classes.filtersContainer}>
        <Autocomplete
          id="leadQualifier"
          options={executives}
          value={lead === null ? null : lead || filters.leadQualifier}
          label={`Lead ${deal === "venta" ? "Buyer" : "Rental"} Qualifier`}
          onChange={setLead}
        />
        <Divider />
        <Autocomplete
          id="state"
          multiple
          onChange={setState}
          value={state}
          options={filterModalStateOptions}
          label="Estado"
        />
        <Divider />
        <Autocomplete
          id="origin"
          onChange={setOrigin}
          value={origin}
          options={origins}
          label="Origen"
        />
        <Divider />
      </div>
      <div className={classes.footer}>
        <Button
          autoFocus
          onClick={cleanFilters}
          className={classes.removeButton}
          variant="text"
          color="primary">
          Borrar
        </Button>
        <Button
          id="updateFilters"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={updateFilters}>
          Aplicar filtros
        </Button>
      </div>
    </CustomDialog>
  );
}
export default FilterModal;
