import Swal from "sweetalert2";

import useSendLinks from "Components/Admin/administrator/contracts/hooks/useSendLinks";

const useSendLink = () => {
  const { isLoading: isSending, mutate } = useSendLinks();

  const sendLinks = async (id: number) => {
    Swal.fire({
      type: "warning",
      title: "¿Comenzar proceso de firma online?",
      text: "Este proceso notificará a todos los involucrados. No te equivoques.",
      showCancelButton: true,
      confirmButtonText: "Comenzar proceso",
      cancelButtonText: "Revisar de nuevo",
    }).then(async (res) => {
      if (res.value) mutate({ contractId: id });
    });
  };

  return {
    sendLinks,
    isSending,
  };
};

export default useSendLink;
