import { Grid, Typography } from "@material-ui/core";

import useStyles from "./AvailabilityDetailsStyles";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import BackButton from "Components/Admin/UIComponents/Buttons/BackButton";
import {
  clearProperty,
  setPropertyInfo,
  updatePropertyHoumbox,
  updatePropertySchedule,
} from "context/availabilityContext/availabilityActions";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { useGetToken } from "hooks";
import { fullAvailability } from "utils/datetime";
import { getTimezone } from "utils";

function LeadAvailabilityDetails() {
  const classes = useStyles();
  const { state, dispatch } = useAvailability();
  const { getToken } = useGetToken();

  const handleHoumboxUpdate = (houmbox) => {
    dispatch(
      setPropertyInfo({
        ...state.propertyInfo,
        houmbox,
      })
    );
    getToken().then((authToken) =>
      updatePropertyHoumbox(state.multiPropertyId, houmbox, dispatch, authToken)
    );

    if (houmbox)
      getToken().then((authToken) =>
        updatePropertySchedule(
          state.multiPropertyId,
          dispatch,
          fullAvailability(getTimezone(state.propertyInfo.country, state.propertyInfo.location)),
          authToken
        )
      );
  };

  return (
    <Grid container justifyContent="flex-start" className={classes.container}>
      <Grid item xs={12}>
        <BackButton arrow onClick={() => dispatch(clearProperty())} className={classes.backButton}>
          Volver a tabla
        </BackButton>
      </Grid>
      {state.multiPropertyId.length === 1 ? (
        <>
          <Grid item xs={2}>
            <CustomTextField
              id="id"
              label="ID propiedad"
              disabled
              defaultValue={state.multiPropertyId[0]}
              type="text"
              InputProps={{
                className: classes.formInput,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <CustomTextField
              id="address"
              label="Dirección (Nombre, calle y número)"
              disabled
              type="text"
              defaultValue={state.propertyInfo.address}
              InputProps={{
                className: classes.formInput,
              }}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={6}>
          <CustomTextField
            id="id"
            label="ID propiedades"
            disabled
            defaultValue={state.multiPropertyId.join(" - ")}
            type="text"
            InputProps={{
              className: classes.formInput,
            }}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <Typography component="h3" className={classes.selectionLabel}>
          Houmbox
        </Typography>
        <SelectionOptions
          id="houmbox"
          options={[
            { label: "Si", value: true },
            { label: "No", value: false },
          ]}
          value={state.propertyInfo.houmbox}
          simple={false}
          onChange={(e) => handleHoumboxUpdate(e)}
          round
          buttonClassName={classes.button}
          defaultValue={state.propertyInfo.houmbox}
        />
      </Grid>
    </Grid>
  );
}

export default LeadAvailabilityDetails;
