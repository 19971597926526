import { Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";

import { useGetToken } from "hooks";

import { deleteMultifamilyPhoto } from "context/multifamilyContext/multifamily/multifamilyActions";
import { deleteTypologyPhoto } from "context/multifamilyContext/typology/typologyActions";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

function Photo({ index, onClick, photo, type, uid }) {
  const { getToken } = useGetToken();
  const body = { photos_id: [photo.id] };

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };

  const handleDelete = async (type) => {
    try {
      const authToken = await getToken();
      let res;
      switch (type) {
        case "multifamily":
          res = await deleteMultifamilyPhoto(uid, body, authToken);
          break;
        case "typology":
          res = await deleteTypologyPhoto(uid, body, authToken);
          break;
        default:
          break;
      }
      if (res) {
        window.location.reload();
      }
    } catch {
      Swal.fire("Error", "No se pudo eliminar la imagen, intente más tarde", "error");
    }
  };

  return (
    <Tooltip
      interactive
      arrow
      placement="top-start"
      title={
        <DesignSystemButton
          label="Borrar"
          size="small"
          variant="secondary"
          onClick={async () => handleDelete(type)}
        />
      }>
      <img
        src={photo.url}
        className="sortable-image"
        onClick={onClick ? handleClick : null}
        alt={photo.filename}
      />
    </Tooltip>
  );
}

export default Photo;
