import { IoGitNetworkOutline as IntegrationsIcon } from "@houm-com/ui-icons";
import ExternalPortalPublicationsIndex from "Components/Admin/administrator/integrations/pages/externalPortalPublications";
import PortalQuotasIndex from "Components/Admin/administrator/integrations/pages/portalQuotas";
import PlanEditionIndex from "Components/Admin/administrator/integrations/pages/planEdition";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Integraciones",
  icon: IntegrationsIcon,
  children: [
    {
      to: "/admin/integrations/portal-quotas",
      path: "/admin/integrations/portal-quotas",
      component: PortalQuotasIndex,
      label: "Cupos por portal",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/admin/integrations/external-portal-publications",
      path: "/admin/integrations/external-portal-publications",
      component: ExternalPortalPublicationsIndex,
      label: "Publicaciones en portales",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/admin/integrations/plan-edition",
      path: "/admin/integrations/plan-edition",
      component: PlanEditionIndex,
      label: "Edición de cupos",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
  ],
});
