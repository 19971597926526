import { Box, Typography } from "@material-ui/core";

import { PropertyData } from "models/Property";
import { ReactComponent as LocationIcon } from "assets/icons/App/location.svg";
import Map from "Components/Admin/UIComponents/atoms/Map";
import { countryCode } from "utils";
import { getDefaultLocation } from "utils/map";

import useStyles from "./LocationCardStyles";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

interface Props {
  property?: PropertyData;
}

function LocationCard({ property = null }: Props) {
  const locale = countryCode(property?.country || "chile");
  const { lat, lng } = getDefaultLocation(locale);

  const classes = useStyles();

  const coordinates: [lng: number, lat: number] = [
    property?.property_details[0]?.longitud || lng,
    property?.property_details[0]?.latitud || lat,
  ];

  return (
    <AdminCard className={classes.cardRoot}>
      <Typography variant="h6" className={classes.title}>
        <LocationIcon /> ¿Dónde se encuentra la propiedad?
      </Typography>
      <Box className={classes.mapContainer}>
        <Map defaultValue={coordinates} value={coordinates} locale={locale} hasMarker />
      </Box>
      <Typography className={classes.address}>
        📍 {property?.address}, {property?.street_number}, {property?.comuna}, {property?.region},{" "}
        {property?.country}
      </Typography>
      <Typography className={classes.dptoNumber}>
        Departamento no. {property?.numero_depto || "---"}
      </Typography>
    </AdminCard>
  );
}

export default LocationCard;
