import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { alert } from "libs/alerts";
import { useGetToken } from "hooks";
import contractService from "services/contractService";
import { ContractModel, IPerson } from "models/Contract";

const useMarkAsOwner = (contract: ContractModel, person: IPerson, onSuccess: () => void) => {
  const { getToken } = useGetToken();
  const { t } = useTranslation(["scheduler.error"]);

  const { mutate, isLoading: isSubmitting } = useMutation(
    async () => {
      const authToken = await getToken();
      const propertyUser = contract.property.user;
      const propertyOwner = contract.owner;
      const contractId = contract.id;
      if (!propertyUser.rut && !person?.document) {
        alert.error({
          message: "Error al confirmar datos",
          disclaimer: "No puede ser marcado como propietario porque el usuario no tiene RUT",
        });
        return;
      }
      const data = {
        document: propertyOwner?.document ?? (propertyUser.rut || person?.document),
        rut: propertyOwner?.document ?? (propertyUser.rut || person?.document),
        document_type:
          propertyOwner?.document_type?.id ?? (propertyUser.document_type || person?.document_type),
        email: propertyOwner?.email ?? propertyUser.email,
        phone: propertyOwner?.phone ?? propertyUser.phone,
        name:
          propertyOwner?.name ??
          `${propertyUser.name} ${propertyUser.last_name} ${propertyUser.second_last_name}`,
        address: propertyOwner?.address ?? (propertyUser.address || person?.address),
        role: "owner",
      };
      await contractService.createPerson(contractId, data, authToken);
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Datos confirmados",
          disclaimer: "Los datos del propietario han sido confirmados con éxito",
        });
        onSuccess();
      },
      onError: (e: AxiosError) => {
        const message = e?.response?.data?.message;
        alert.error({
          message: "Error al confirmar datos",
          disclaimer: t(message, { ns: "scheduler.error", userRole: "owner" }),
        });
      },
    }
  );
  return { mutate, isSubmitting };
};

export default useMarkAsOwner;
