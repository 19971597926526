import Grid from "@material-ui/core/Grid";

import { MovementBreakdownRecord } from "models/Payments";

import PaymentDetailsList from "../PaymentDetailsList";
import ButtonCopyLink from "../ButtonCopyLink";
import useDetailStyles from "./DetailStyles";
import MovementInfo from "../MovementInfo";
import Receipts from "../Receipts";

interface DetailProps {
  movement: MovementBreakdownRecord;
}

const DetailSubRow = ({ movement }: DetailProps) => {
  const classes = useDetailStyles();
  const notPayedIncome =
    movement.type === "INCOME" && movement.status === "UNPAID" && movement.payment_link;

  return (
    <Grid container className={classes.container}>
      <Grid item container xs={5}>
        <Grid item xs={12}>
          <MovementInfo movement={movement} />
        </Grid>
        {notPayedIncome && (
          <Grid item xs={12}>
            <ButtonCopyLink movement={movement} />
          </Grid>
        )}
        {movement.status === "PAID" && (
          <Grid item xs={12}>
            <Receipts movementId={movement.movement_id} country={movement.country} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={7}>
        <PaymentDetailsList record={movement} />
      </Grid>
    </Grid>
  );
};

export default DetailSubRow;
