import classnames from "classnames";

import { Box, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import {
  Owner,
  Bidder,
  PersonType,
  PersonTypes,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import usePersonCardStyles from "./PersonCardStyles";
import { ChevronRight } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { Locale } from "models/Countries";

interface PersonCardProps {
  person: Owner | Bidder;
  type: PersonType;
  country: Locale;
}

function PersonCard({ person, type, country }: PersonCardProps) {
  const classes = usePersonCardStyles();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { id: loiId } = params;

  const displayIcon = (status: string) => {
    switch (status) {
      case "ok":
        return (
          <CheckIcon className={classnames(classes.flagStatusIcon, classes.flagStatusValidated)} />
        );
      default:
        return null;
    }
  };

  const goToPersonDetail = () => {
    history.push(
      `/buyers/letterOfIntent/${loiId}/person/${person.id}?type=${type}&country=${country}`
    );
  };

  return (
    <Box className={classes.cardDocument} onClick={goToPersonDetail}>
      <Box>
        <Typography key={person.id} className={classes.personType}>
          {`${type === PersonTypes.OWNER ? "PROPIETARIO" : "COMPRADOR"} 
            ${person.isMainUser ? "PRINCIPAL" : "SECUNDARIO"}`}
        </Typography>
        <Typography key={person.id} className={classes.personName}>
          {person.fullname}
        </Typography>
        <Box
          className={classnames(classes.flagStatus, {
            [classes.flagStatusValidated]: true,
          })}>
          {displayIcon("no status yet")}
        </Box>
      </Box>
      <Box className={classes.iconContainer}>
        <ChevronRight />
      </Box>
    </Box>
  );
}

export default PersonCard;
