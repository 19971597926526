import { useState, useEffect, ReactNode } from "react";

import Carousel from "react-material-ui-carousel";

import { SimilarProperty } from "models";
import Title from "../Title";
import MarketplacePropertyCard from "Components/Admin/administrator/properties/show/partials/MarketplacePropertyCard";
import { getSlidesNumber } from "Components/Admin/administrator/demand/utils/getSlidesNumber";

import usePropertyCarouselStyles from "./PropertyCarouselStyles";

interface Props {
  items: SimilarProperty[];
  title: string;
  children?: ReactNode;
  deal: "rental" | "sale";
  onClickChildren?: (val: SimilarProperty) => void;
}

function PropertyCarousel({ items, title, children, deal, onClickChildren }: Props) {
  const classes = usePropertyCarouselStyles();
  const [slides, setSlides] = useState([]);
  const itemsPerSlide = getSlidesNumber();

  useEffect(() => {
    const chunks = [];
    for (let i = 0; i < items.length; i += itemsPerSlide) {
      chunks.push(items.slice(i, i + itemsPerSlide));
    }
    setSlides([...chunks]);
  }, [items, itemsPerSlide]);

  return (
    <>
      <Title>{title}</Title>
      <Carousel autoPlay={false}>
        {slides.map((slide, i) => (
          <div key={i.toString()} className={classes.carouselContainer}>
            {slide.map((item, i) => (
              <div className={classes.gap} key={i.toString()}>
                <MarketplacePropertyCard
                  property={item}
                  locale={item.neighborhood.country_code}
                  deal={deal}
                />
                {children && (
                  <div
                    onClick={() => onClickChildren && onClickChildren(item)}
                    className={classes.buttonContainer}>
                    {children}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default PropertyCarousel;
