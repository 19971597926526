import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import useInfoCardStyles from "./InfoCardStyles";

interface Info {
  label: string;
  value: string | number;
}

interface Props {
  title: string;
  state?: string;
  infoList: Array<Info>;
}

function InfoCard({ title, state, infoList }: Props) {
  const classes = useInfoCardStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <p className={classes.title}>{title}</p>
        {state && <Chip className={classes.state} label={state} />}
      </div>
      <div>
        {infoList.map((info) => (
          <p key={`${info.label}`}>
            {`${info.label} :`}
            <span className={classes.valueInfo}>{info.value}</span>
          </p>
        ))}
      </div>
    </Paper>
  );
}

InfoCard.defaultProps = {
  state: null,
};

export default InfoCard;
