import { CountryUpperCase } from "models/Countries";

export enum ActionType {
  SET_LOADING,
  SET_LETTERS_OF_INTENT,
  CHANGE_COUNTRY,
}

export interface State {
  loading: boolean;
  lettersOfIntent: LetterOfIntent[];
  numberOfLettersOfIntent?: number;
  country: CountryUpperCase;
}

export interface LetterOfIntent {
  id: string;
  propertyId: string;
  status: string;
  owner: Owner;
  propertyExecutiveName: string;
}

export interface Owner {
  name: string;
  phone: string;
  email: string;
}
interface changeCountry {
  type: typeof ActionType.CHANGE_COUNTRY;
  country: CountryUpperCase;
}

interface setLoading {
  type: typeof ActionType.SET_LOADING;
  loading: boolean;
}
interface setLettersOfIntent {
  type: typeof ActionType.SET_LETTERS_OF_INTENT;
  payload: { lettersOfIntent: LetterOfIntent[]; count: number };
}

export type Action = changeCountry | setLettersOfIntent | setLoading;

export type Dispatch = (action: Action) => void;
