import { ReactElement } from "react";

import Spinner from "Components/Shared/partials/Spinner";

interface Props {
  loading: boolean;
  iconClassName?: string;
  size?: number;
  children?: ReactElement;
  className?: string;
}

const Loading = ({ loading, children = null, className, iconClassName, size }: Props) => {
  if (loading) {
    return <Spinner className={className} iconClassName={iconClassName} size={size} />;
  }
  return children;
};

export default Loading;
