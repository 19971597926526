import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { ILandlordTicketsCount } from "models/AdminModels/Landlord";
import userService from "services/userService";

import { parseLandlordTicketsCount } from "../utils/parsers";

const useLandlordTicketsCount = (id: string) => {
  const { getToken } = useGetToken();
  const { isLoading, error, data }: UseQueryResult<ILandlordTicketsCount, Error> = useQuery<
    ILandlordTicketsCount,
    Error
  >(["landlordTicketsCount", id], async () => {
    const authToken = await getToken();
    const response = await userService.getLandlordTicketsCount(id, authToken);
    return parseLandlordTicketsCount(response.data);
  });
  return { isLoading, error, data };
};

export default useLandlordTicketsCount;
