import { Theme, makeStyles } from "@material-ui/core/styles";

const usePaymentAdjustStyles = makeStyles((theme: Theme) => ({
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
  textInput: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
}));

export default usePaymentAdjustStyles;
