import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
