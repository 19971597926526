import * as yup from "yup";

export const requiredText = "Obligatorio";
export const emailError = "Email inválido";

export const otherSchema = () =>
  yup.object({
    email: yup.string().email(emailError).required(requiredText),
    currentDebt: yup.number().typeError(requiredText).required(requiredText),
    dueDate: yup.string().required(requiredText),
    aggregateDebt: yup.number().typeError(requiredText).required(requiredText),
  });
