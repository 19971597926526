import { styled, Theme, Box, Typography } from "@material-ui/core";

export const Title = styled(Typography)<Theme>(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}));

export const Text = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: "0.75rem",
}));

export const ReportBox = styled(Box)<Theme>(({ theme }) => ({
  width: 164,
  border: "1px solid #DFE5E8",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.spacing(2),
}));
