import ModalDialog from "@houm-com/ui/ModalDialog";

import { Locale } from "models/Countries";

import EditPaymentDateForm from "./components/EditPaymentDateForm";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  movementId: string;
  subscriptionId: number;
  country: Locale;
  date: string;
}

function EditPaymentDateModal({
  open,
  handleOnClose,
  movementId,
  subscriptionId,
  country,
  date,
}: Props) {
  return (
    <ModalDialog
      isOpen={open}
      title="Editar o ingresar fecha de pago"
      subtitle={`Subscripción ${subscriptionId}`}
      onClose={handleOnClose}
      hasCloseBtn>
      <EditPaymentDateForm
        movementId={movementId}
        country={country}
        handleOnClose={handleOnClose}
        date={date}
      />
    </ModalDialog>
  );
}

export default EditPaymentDateModal;
