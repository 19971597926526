import { makeStyles, Theme } from "@material-ui/core";

const usePaymentDetailsStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontHeight: 1.6,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  disclaimer: {
    backgroundColor: "#EAF1FB",
    borderRadius: 8,
  },
  textDisclaimer: {
    fontStyle: "italic",
  },
}));

export default usePaymentDetailsStyles;
