import { useGetToken } from "hooks";
import { useMutation, useQueryClient } from "react-query";
import contractService from "services/contractService";
import { alert } from "libs/alerts";

const useSignFiles = (contractId: number) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading } = useMutation(
    async () => {
      const authToken = await getToken();
      await contractService.signFiles(contractId, authToken);
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Documentos firmados con éxito. Revísalos en la sección Archivos actuales",
        });
        queryClient.invalidateQueries("contract_documents_link");
      },
      onError: (e) => {
        alert.error({
          message: `Error al firmar los documentos ${e}`,
        });
      },
    }
  );

  return { mutate, isLoading };
};

export default useSignFiles;
