import { useEffect } from "react";

import LeadVisits from "Components/Admin/administrator/leads/leadProfile/components/LeadVisits";
import LeadAvailabilityForm from "Components/Admin/administrator/leads/leadProfile/components/LeadAvailabilityForm";
import { useLead } from "context/leadContext/leadContext";
import { getLeadProperties } from "context/leadContext/leadActions";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import { useTable } from "context/tableContext/tableContext";
import { useGetToken } from "hooks";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function PropertyVisitsTabIndex({ property }: Props) {
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();
  const { state: availabilityState } = useAvailability();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;

  useEffect(() => {
    getToken().then((authToken) =>
      getLeadProperties(pageSize, currentPage, property.user.email, dispatch, authToken)
    );
  }, [state?.lead, pageSize, currentPage]);

  if (availabilityState?.multiPropertyId.length > 0) {
    return <LeadAvailabilityForm />;
  }
  return <LeadVisits />;
}

export default PropertyVisitsTabIndex;
