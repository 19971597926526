import { Typography } from "@material-ui/core";

import { getDocumentType } from "Components/Admin/administrator/subscriptions/utils/utils";
import { Codebtor } from "models/Evaluation";

import usePersonStyles from "./PersonStyles";

const Person = ({ person }: { person: Codebtor | null }) => {
  const classes = usePersonStyles();

  return (
    <>
      <Typography className={classes.text}>
        <b>Nombre:</b>
        {` ${person?.name}`}
      </Typography>
      {person?.nationality && (
        <Typography className={classes.text}>
          <b>Nacionalidad:</b>
          {` ${person?.nationality}`}
        </Typography>
      )}
      <Typography className={classes.text}>
        <b>{` ${
          person?.document_type
            ? `${getDocumentType(person?.document_type as number)}: `
            : "Documento: "
        }`}</b>
        {` ${person?.document ?? person?.rut}`}
      </Typography>
      {person?.country === "Mexico" && (
        <Typography className={classes.text}>
          <b>RFC:</b>
          {` ${person?.rfcNumber ?? "-"}`}
        </Typography>
      )}
      <Typography className={classes.text}>
        <b>Correo electrónico:</b>
        {` ${person?.email}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Teléfono:</b>
        {` ${person?.phone}`}
      </Typography>
      <Typography className={classes.text}>
        <b>Domicilio:</b>
        {` ${person?.address ?? "-"}`}
      </Typography>
    </>
  );
};

export default Person;
