import { useGetToken } from "hooks";
import { useMutation } from "react-query";
import contractService from "services/contractService";

const useCancelDesertionProcess = () => {
  const { getToken } = useGetToken();
  const { mutate, ...rest } = useMutation(async (contractId: number) => {
    const authToken = await getToken();
    return contractService.cancelDesertionProcess(contractId, authToken);
  });
  return { cancelDesertionProcess: mutate, ...rest };
};

export default useCancelDesertionProcess;
