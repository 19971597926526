import { makeStyles, Theme } from "@material-ui/core";

const useLeadPropertiesStyles = makeStyles((theme: Theme) => ({
  tablePropertyPhoto: {
    borderRadius: 8,
    height: 55,
    width: 100,
    objectFit: "cover",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    marginBottom: theme.spacing(2),
  },
  createButtonContainer: {
    margin: theme.spacing(2),
  },
  createButton: {
    borderRadius: "100rem",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

export default useLeadPropertiesStyles;
