import { CountryUpperCase, Timezones } from "models/Countries";

export type ContractType = "Hired" | "New_freelance" | "B2B";

export enum AppraiserRole {
  ADVISOR = "Advisor",
  RENT_PHOTO = "RentPhotographer",
  SALE_PHOTO = "SalesPhotographer",
  RENT_GUIDE = "RentGuide",
  SALE_GUIDE = "SalesGuide",
  INTERN = "Intern",
  LEADS_ENABLED = "LeadsEnabled",
}

export type Point = [number, number];

export interface AppraiserMacrozone {
  city: string;
  country: CountryUpperCase;
  description: string; //"C-CDMX-MX"
  id: number;
  name: string; //"Zona Centro"
  polygon: [Point[]];
}

export interface AppraiserProfile {
  active?: boolean;
  contract_type?: ContractType;
  country: CountryUpperCase | "";
  email: string;
  image: string;
  name: string;
  phone: string;
  roles: Array<AppraiserRole>;
  timezone?: Timezones;
  macrozones: AppraiserMacrozone[];
}
