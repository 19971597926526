import { makeStyles } from "@material-ui/core";

const useScheduleCancel = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  reason: {
    lineHeight: 1,
  },
  comment: {
    lineHeight: 1,
    marginTop: theme.spacing(1),
  },
}));

export default useScheduleCancel;
