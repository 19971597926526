import { Box, InputBase, Typography } from "@material-ui/core";

import Select from "Components/Admin/UIComponents/DesignSystem/form/Select";

import useCompanyFormStyles from "./CompanyFormStyles";

interface Props {
  availableCompanies: { label: string; value: string }[];
  company: string;
  disabled?: boolean;
  setCompany: (s: string) => void;
}

const CompanyForm = ({ availableCompanies, company, setCompany, disabled = false }: Props) => {
  const classes = useCompanyFormStyles();

  return (
    <Box className={classes.selectorWrapper}>
      <Typography className={classes.selectLabel}>
        Nombre de la Empresa<span className={classes.required}>*</span>
      </Typography>
      <Select
        id="company"
        defaultValue={company}
        onChange={(e) => setCompany(String(e.target.value))}
        options={availableCompanies}
        input={<InputBase />}
        disabled={disabled}
      />
    </Box>
  );
};

export default CompanyForm;
