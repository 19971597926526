import currency from "@houm-com/formats/currency";

import { PropertyDetails, SalesDetails } from "models/Property";
import { CountryUpperCase, Currency } from "models/Countries";
import { currencyParse } from "utils/countryCurrency";
import { countryCode } from "utils";

const getPropertyCurrency = (
  currencyCode: Currency,
  oldCurrency: string,
  country: CountryUpperCase
) => {
  if (currencyCode) return currencyCode;
  return currencyParse[countryCode(country)][oldCurrency.toLowerCase()];
};

export const getRentPrice = (propertyDetails: PropertyDetails, country: CountryUpperCase) =>
  currency(
    propertyDetails?.valor,
    getPropertyCurrency(
      propertyDetails?.currency_code,
      propertyDetails?.tipo_moneda_venta,
      country
    ),
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );

export const getSalePrice = (salesDetails: SalesDetails, country: CountryUpperCase) =>
  currency(
    salesDetails?.price,
    getPropertyCurrency(salesDetails?.currency_code, salesDetails.currency, country),
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  );
