import { SnackbarProvider } from "notistack";

import PortalQuotas from "./PortalQuotas";
import { IntegrationProvider } from "context/integrationContext/integrationContext";
import { TabProvider } from "context/tabContext/tabContext";

function PortalQuotasIndex() {
  return (
    <IntegrationProvider>
      <SnackbarProvider>
        <TabProvider>
          <PortalQuotas />
        </TabProvider>
      </SnackbarProvider>
    </IntegrationProvider>
  );
}

export default PortalQuotasIndex;
