import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: { width: "100%", height: "100%" },
  link: {
    "&, &:hover": {
      color: theme.palette.primary.main,
    },
  },
  table: {
    border: 0,
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      transition: "0.3s",
      "&:hover": {
        fontWeight: 800,
        backgroundColor: "#F7F8FC",
      },
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: 8,
      backgroundColor: theme.palette.grey["50"],
      color: theme.palette.grey["500"],
      fontSize: "0.875rem",
    },
  },
  longText: {
    padding: theme.spacing(0.5, 1),
    whiteSpace: "initial",
    fontSize: theme.typography.pxToRem(14),
  },
}));
