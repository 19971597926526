import { Box, Typography } from "@material-ui/core";

import useStepTitleStyles from "./StepTitleStyles";

type Props = {
  title: string;
  subtitle: string;
};

const StepTitle = ({ title, subtitle }: Props) => {
  const classes = useStepTitleStyles();
  return (
    <Box>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.date}>{subtitle}</Typography>
    </Box>
  );
};

export default StepTitle;
