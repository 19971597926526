import { useEffect } from "react";

import { GridColumns, GridColDef } from "@material-ui/x-grid";
import moment from "moment";

import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();

  const {
    state: { property },
  } = useMultifamilyUnit();
  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize } = tableStates;

  useEffect(() => dispatch(setPageSize(10)), []);

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "ID",
      field: "id",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Fecha",
      field: "created_at",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => moment(value as string).format("DD.MM.YYYY") || "------",
    },
    {
      ...columnsConfig,
      headerName: "Arrendatario",
      field: "tenantName",
      cellClassName: classes.centerCell,
      renderCell: ({ row }) => (row.first_name ? `${row.first_name} ${row.last_name}` : "------"),
    },
    {
      ...columnsConfig,
      headerName: "Propietario",
      field: "ownerName",
      cellClassName: classes.centerCell,
      renderCell: ({ row }) => row.lessor?.name || "------",
    },
    {
      ...columnsConfig,
      headerName: "",
      field: "status",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => value || "------",
    },
  ];

  const sourceData = property?.quotations || [];

  return (
    <CustomDataGrid
      classes={{
        root: classes.root,
        columnHeader: classes.headerCell,
      }}
      rowCount={sourceData.length || 0}
      columns={columns}
      rows={sourceData}
      page={currentPage - 1}
      pageSize={pageSize}
      onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
      onPageSizeChange={(params) => {
        dispatch(setPageSize(params));
        dispatch(setCurrentPage(1));
      }}
    />
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
