import { ReactNode, Children } from "react";
import classNames from "classnames";

import useStyles from "./InspectionReportTableRowStyles";

interface Props {
  children: ReactNode;
  isTitle?: boolean;
  isItemRow?: boolean;
  isEdit?: boolean;
  classes?: {
    root?: string;
    cell?: string;
  };
}

function InspectionReportTableRow({
  children,
  isTitle = false,
  isItemRow = false,
  isEdit = false,
  classes: propClasses,
}: Props) {
  const classes = useStyles({ isTitle, isItemRow, isEdit });

  return (
    <div className={classNames(classes.root, propClasses?.root)}>
      {Children.map(children, (child, index) => (
        <div
          key={`table-cell-${index + 1}`}
          className={classNames(classes.cell, propClasses?.cell)}>
          {child}
        </div>
      ))}
    </div>
  );
}

export default InspectionReportTableRow;
