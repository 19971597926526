/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useState } from "react";

import Divider from "@material-ui/core/Divider";
import TuneIcon from "@material-ui/icons/Tune";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import { Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import { useGetToken } from "hooks";
import { parseSegmentationData } from "utils/parseUserSegmentationData";
import { InfoDisplay, HeaderWithIcon } from "Components/Admin/UIComponents";
import { useUsers } from "../../context/usersContext";
import { getPropertyAddress, getHoumer, getVisits } from "../../context/usersActions";
import { SET_VISITS } from "../../context/usersTypes";
import TenantTable from "./TenantTable";
import VisitsCarousel from "./VisitsCarousel";
import useTenantSearchTabStyles from "./TenantSearchTabStyles";

function TenantSearchTab() {
  const classes = useTenantSearchTabStyles();
  const { getToken } = useGetToken();
  const { state, dispatch } = useUsers();
  const { user } = state;
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => setShowModal((prev) => !prev);

  const setUserData = useCallback(async () => {
    const authToken = await getToken();
    user.applications.forEach((application, index) =>
      getHoumer(application, index, dispatch, authToken)
    );
    if (user.segmentations.length > 0) {
      user.segmentations.forEach((segmentation, index) => {
        if (segmentation.propertyId) {
          getPropertyAddress(segmentation, index, dispatch, authToken);
        }
      });
    }
    if (user.applications.length > 0) {
      dispatch({ type: SET_VISITS, payload: [] });
      user.applications.forEach((application) => {
        getVisits(application, dispatch, authToken);
      });
    }
  }, [user.id]);

  useEffect(() => {
    setUserData();
  }, []);

  return (
    <div className={classes.infoContainer}>
      <Modal className={classes.modal} open={showModal} onClose={handleShowModal}>
        <div className={classes.paper}>
          <HeaderWithIcon title="Historial centro de ayuda" Icon={DateRangeIcon} />
          {user.segmentations.map((segmentation, i) => (
            <div key={i.toString()}>
              <p className={classes.modalDate}>
                Fecha: {moment(segmentation.date).format("DD/MM/YYYY")}
              </p>
              <InfoDisplay infoColumns={parseSegmentationData(segmentation)} />
              <Divider className={classes.modalDivider} />
            </div>
          ))}
        </div>
      </Modal>

      <div className={classes.titleWithButton}>
        <HeaderWithIcon title="Centro de ayuda" Icon={TuneIcon} />
        <Button className={classes.historialButton} onClick={handleShowModal}>
          Historial
        </Button>
      </div>
      <div className={classes.infoBlock}>
        {user.segmentations.length > 0 ? (
          <InfoDisplay infoColumns={parseSegmentationData(user.segmentations[0])} />
        ) : (
          <h2>No tiene información de ayuda</h2>
        )}
      </div>
      <Divider className={classes.blockDivider} />

      <HeaderWithIcon title="Historial de Propiedades" Icon={SearchIcon} />
      <div className={classes.infoBlock}>
        <Grid container spacing={3}>
          <Grid item sm={5} />
          <Grid item sm={7}>
            <TenantTable />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.blockDivider} />

      <HeaderWithIcon title="Seguimiento visitas" Icon={SearchIcon} />
      <div className={classes.infoBlock}>
        {user.visits.length > 0 ? (
          <VisitsCarousel itemsPerSlide={5} items={user.visits} />
        ) : (
          <h2>No tiene visitas</h2>
        )}
      </div>
    </div>
  );
}

export default TenantSearchTab;
