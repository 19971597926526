import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService/propertyService";
import { Paginate } from "models/ResponseWithPaginate";

import { Report } from "../models";

const useGetPropertyReports = (propertyId: number, filters: {}) => {
  const { getToken } = useGetToken();
  const queryResponse: UseQueryResult<Paginate<Report>, Error> = useQuery<Paginate<Report>, Error>(
    ["inspectionReportRequest", propertyId, filters],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getPropertyInspectionReports(
        propertyId,
        authToken,
        filters
      );
      return response?.data;
    }
  );
  return queryResponse;
};

export default useGetPropertyReports;
