import { Locale } from "models/Countries";
import * as yup from "yup";

export const requiredText = "Obligatorio";

const percentage = yup
  .number()
  .typeError("Debe ser un número")
  .min(0, "Debe ser mayor o igual a cero")
  .max(100, "Debe ser menor a 100");

const duration = yup
  .number()
  .typeError("Cantidad de meses inválida")
  .min(1, "Debe ser mayor a cero")
  .max(12, "Debe ser menor a 13")
  .required(requiredText);

export const planValidationSchema = (country: Locale, hasManagement: boolean) =>
  yup.object({
    landlordBrokerage: percentage,
    tenantBrokerage: percentage,
    adminCommission: percentage,
    landlordBrokerageClosing: percentage,
    tenantBrokerageClosing: percentage,
    name: yup.string().required(requiredText),
    description: yup.string().required(requiredText),
    paymentOption: yup.string().required(requiredText),
    minimumAdminFee:
      country === "co" &&
      yup
        .number()
        .typeError("Debe ser un número")
        .min(0, "Debe ser mayor a cero")
        .required(requiredText),
    duration: hasManagement ? duration : yup.number(),
    category: yup.number().typeError(requiredText).required(requiredText),
    contractTemplate: yup.string().required(requiredText),
  });
