import { makeStyles, Theme } from "@material-ui/core";

const useDocumentsCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(3),
    width: "100%",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid #E9EAEB`,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(3),
    padding: 0,
    zIndex: 1,
    color: theme.palette.primary.main,
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: "#4C6977",
    alignSelf: "flex-end",
    width: "100%",
  },
  buttonSecondary: {
    backgroundColor: "transparent",
    border: `1px solid #4C6977`,
    alignSelf: "flex-end",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flexShrink: 0,
    width: "fit-content",
    gap: theme.spacing(1),
  },
}));
export default useDocumentsCardStyles;
