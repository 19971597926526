import * as React from "react";

const FormBlock: React.FC = ({ children }) => (
  <div className="my-4">
    {children}
    <hr className="h-px border-t-0 bg-black-20 my-4" />
  </div>
);

export default FormBlock;
