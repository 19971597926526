import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type TabsContextProps = {
  opened: {
    value: React.Key;
    component: ReactNode;
  };
  setOpened: Dispatch<SetStateAction<{ value: React.Key; component: ReactNode }>>;
} | null;

const TabsContext = createContext<TabsContextProps>(null);

const Tabs = ({ children }: { children: ReactElement<typeof Tab>[] }) => {
  const [opened, setOpened] = useState<{ value: React.Key; component: ReactNode }>({
    value: 0,
    component: false,
  });

  const tabsValue = useMemo(() => ({ opened, setOpened }), [opened, setOpened]);

  return (
    <TabsContext.Provider value={tabsValue}>
      <div className="text-sm font-medium text-center text-secondary-120">
        <ul className="flex flex-wrap mb-10">{children}</ul>
      </div>
      <div>{opened.component}</div>
    </TabsContext.Provider>
  );
};

const Tab: React.FC<{ value: string; title: string; children: ReactNode }> = ({
  value,
  title,
  children,
}) => {
  const { opened, setOpened } = useContext(TabsContext)!;
  const classes =
    "hover:text-secondary-cta p-4 font-semibold border-b-2 border-transparent cursor-pointer select-none";
  return (
    <li className="me-2" onClick={() => setOpened({ value, component: children })}>
      <span
        className={
          classes + (opened.value === value ? " text-black-120 border-b-primary-cta" : "")
        }>
        {title}
      </span>
    </li>
  );
};

export { Tabs, Tab };
