import { makeStyles, Theme } from "@material-ui/core";

const useMapHandlerStyles = makeStyles<Theme>((theme) => ({
  mapContainer: {
    height: "100%",
    width: "100%",
  },

  mapRoot: {
    width: "100%",
    height: "100%",
  },

  "mapbox-map-wrapper": {
    width: "100%",
    height: "100%",
    "& .mapboxgl-canvas-container": {
      overflow: "hidden",
    },
    borderRadius: 10,
  },
}));

export default useMapHandlerStyles;
