import { snakeCase } from "lodash";
import { useState } from "react";
import { UseQueryResult, useQuery } from "react-query";

import { useDebouncedSearchQuery, useGetToken } from "hooks";
import { LandlordSummary } from "models/AdminModels/Landlord";
import userService from "services/userService";

import { parseLandlordSearch } from "../../utils/parsers";
import { SearchFilterTypes } from "../utils/types";

const SANITIZE_DICTIONARY = {
  property_id: (a) => a?.replace(/[^a-zA-Z0-9]/g, "")?.trim(),
  default: (a) => a?.trim(),
};

const useLandlordSearch = () => {
  const [searchFilter, setSearchFilter] = useState<SearchFilterTypes>("search");
  const { handleOnDebouncedSearchChange, debouncedQuery } = useDebouncedSearchQuery();
  const { getToken } = useGetToken();
  const { isLoading, error, data }: UseQueryResult<LandlordSummary[], Error> = useQuery<
    LandlordSummary[],
    Error
  >(["searchLandlord", debouncedQuery, searchFilter], async () => {
    if (debouncedQuery) {
      const sanitizedFilterName = snakeCase(searchFilter);
      const sanitizeFunction =
        SANITIZE_DICTIONARY[sanitizedFilterName] || SANITIZE_DICTIONARY.default;
      const sanitizedQuery = sanitizeFunction(debouncedQuery);
      const filters = {
        [sanitizedFilterName]: sanitizedQuery,
      };
      const authToken = await getToken();
      const response = await userService.searchLandlord(authToken, filters);
      return parseLandlordSearch(response.data.results);
    }
  });

  return {
    isLoading,
    error,
    data,
    searchQuery: debouncedQuery,
    setSearchQuery: handleOnDebouncedSearchChange,
    searchFilter,
    setSearchFilter,
  };
};

export default useLandlordSearch;
