import { useState } from "react";
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import propertyService from "services/propertyService";

import { BasicService } from "../utils/types";
import { parsePropertyServices } from "../utils/parsers";

const useServices = (propertyId: number, country: Locale) => {
  const [openModal, setOpenModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const {
    isLoading: propertyServicesLoading,
    error: propertyServicesError,
    data: propertyServices = [],
  }: UseQueryResult<BasicService[], Error> = useQuery<BasicService[], Error>(
    ["propertyServices", propertyId, country],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getPropertyBasicServices(propertyId, authToken);
      return parsePropertyServices(response.data);
    }
  );

  const { mutate: updateServices, isLoading: updateLoading } = useMutation(
    async () => {
      const authToken = await getToken();
      await propertyService.updatePropertyBasicServices(propertyId, authToken);
    },
    {
      onSettled: () => queryClient.invalidateQueries(["propertyServices"]),
    }
  );

  const handleUpdate = async () => {
    setUpdating(true);
    updateServices();
    setTimeout(() => {
      setUpdating(false);
      queryClient.invalidateQueries(["propertyServices"]);
    }, 60000);
  };

  return {
    openModal,
    setOpenModal,
    propertyServicesLoading,
    propertyServicesError,
    propertyServices,
    updateServices: handleUpdate,
    updateLoading,
    updating,
  };
};

export default useServices;
