import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  searchBarContainer: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  searchBar: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(0.5, 2, 0.5, 2),
    borderRadius: "100rem",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));
