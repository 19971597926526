import { IoKeyOutline as KeyIcon } from "@houm-com/ui-icons";
import LeadTenantIndex from "Components/Admin/administrator/demand/LeadTenantIndex";
import ProfiledLeadTenantIndex from "Components/Admin/administrator/demand/ProfiledLeadTenantIndex";
import ScheduledVisitsTenantIndex from "Components/Admin/administrator/demand/ScheduledVisitsTenantIndex";
import ScheduledVisitKeysTenantIndex from "Components/Admin/administrator/demand/ScheduledVisitKeysTenantIndex";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Tenants",
  icon: KeyIcon,
  children: [
    {
      to: "/tenants/leads",
      path: "/tenants/leads",
      component: LeadTenantIndex,
      label: "Leads sin perfilar",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/tenants/profiled-leads",
      path: "/tenants/profiled-leads",
      component: ProfiledLeadTenantIndex,
      label: "Leads perfilados",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/tenants/scheduled-visits",
      path: "/tenants/scheduled-visits",
      component: ScheduledVisitsTenantIndex,
      label: "Visitas Agendadas",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/tenants/scheduled-visits-keys",
      path: "/tenants/scheduled-visits-keys",
      component: ScheduledVisitKeysTenantIndex,
      label: "Entregas",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
  ],
});
