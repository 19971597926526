import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

import { Subscription } from "models/Subscription";

const parseSubscriptionTableInfo = (subscriptions: Subscription[]) =>
  subscriptions.map((sub) => ({
    landlord: sub?.contract?.owner?.name,
    tenant: sub?.contract?.main_tenant?.name,
    id: sub?.legacy_id,
    createDate: format(new Date(sub?.created_at), "PPPpp", {
      locale: esLocale,
    }),
  }));

export default parseSubscriptionTableInfo;
