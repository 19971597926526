import { useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import { Typologies } from "context/multifamilyContext/typology/typologyTypes";
import { HoumExecutive } from "context/multifamilyContext/unit/unitTypes";
import { updateProperty, fetchUnit } from "context/multifamilyContext/unit/unitActions";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import SimulateTextfield from "Components/Admin/administrator/multifamily/Components/SimulateTextfield/SimulateTextfield";
import UserSuggester from "Components/Admin/UIComponents/UserSuggester";
import CustomDialog from "Components/Admin/UIComponents/CountryInput/CustomDialogCountry";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useGetToken } from "hooks";

import useStyles from "./UnitHeaderStyles";

interface Props {
  typology?: Typologies;
  executive?: HoumExecutive;
  multifamilyName: string;
}

function UnitHeader({ multifamilyName = "", typology = null, executive = null }: Props) {
  const [houmOwner, setHoumOwner] = useState<{ name: string; id: number }>({
    name: executive.full_name,
    id: executive.id,
  });
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const classes = useStyles();

  const { getToken } = useGetToken();
  const {
    state: { unit },
    dispatch,
  } = useMultifamilyUnit();
  const { id } = useParams<{ id: string }>();

  const propertyId = unit?.property?.id;

  const handleEditExecutive = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveExecutive = () => {
    if (!propertyId) return;
    getToken().then(async (token) => {
      setIsSaving(true);
      await updateProperty(propertyId, { houm_owner: houmOwner.id }, token, dispatch);
      await fetchUnit(parseInt(id), token, dispatch);
      setOpen(false);
      setIsSaving(false);
    });
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {multifamilyName} | {typology?.name} | ID Propiedad {propertyId}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6}>
          <SimulateTextfield title="Nombre" value={typology?.name} />
          <SimulateTextfield
            title="Cantidad de habitaciones"
            value={typology?.bedrooms?.toString()}
          />
          <SimulateTextfield title="Cantidad de baños" value={typology?.bathrooms?.toString()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SimulateTextfield title="M2 construidos totales" value={typology?.built_area} />
          <SimulateTextfield title="M2 construidos" value={typology?.total_area} />
          <Box className={classes.executiveContainer}>
            <SimulateTextfield title="Ejecutivo de la unidad" value={executive?.full_name} />
            <Tooltip title="Editar ejecutivo" placement="top">
              <IconButton onClick={handleEditExecutive}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <CustomDialog isOpen={open} onClose={handleClose}>
        <Box className={classes.userContainer}>
          <UserSuggester
            value={houmOwner}
            onChange={(e) => setHoumOwner(e ? { name: e?.name, id: e?.id } : null)}
            id="houmOwner"
            label="Cambiar ejecutivo"
            qParams={{ role: "seller" }}
          />
          <Box className={classes.buttonContainer}>
            <DesignSystemButton
              variant="primary"
              size="medium"
              onClick={handleSaveExecutive}
              label="Guardar"
              isLoading={isSaving}
            />
          </Box>
        </Box>
      </CustomDialog>
    </Box>
  );
}

export default UnitHeader;
