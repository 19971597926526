import { UseQueryResult, useQuery } from "react-query";

import contractService from "services/contractService";
import { TenantPlan } from "models/Contract";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useGetCurrentContractTenantPlan = (contractId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    error,
    data = "",
  }: UseQueryResult<TenantPlan, Error> = useQuery(
    ["currentTenantPlan", contractId],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getCurrentContractTenantPlan(contractId, authToken);
      return response?.data?.tenant_plan;
    },
    {
      enabled: !!contractId && country === "cl",
      retry: false,
    }
  );

  return { isLoading, error, data };
};

export default useGetCurrentContractTenantPlan;
