import { green, red } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useContractUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(0),
      "& > *": {
        width: "100%",
      },
    },
    centered: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      borderRadius: 50,
      minWidth: 140,
      padding: theme.spacing(1, 2),
      minHeight: 40,
      textAlign: "center",
      textTransform: "none",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: "bold",
      margin: theme.spacing(1),
      color: "white",
    },
    blackButton: {
      backgroundColor: "white",
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[500]}`,
      "&:hover": {
        transition: "0.5s",
        backgroundColor: theme.palette.grey[100],
        color: "white",
      },
    },
    submitButton: {
      backgroundColor: green[600],
      "&:hover": {
        backgroundColor: green[300],
      },
    },
    cancelButton: {
      backgroundColor: red[600],
      "&:hover": {
        backgroundColor: red[300],
      },
    },
    title: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    fileText: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.grey[900],
    },
    divider: {
      marginTop: 0,
      width: "100%",
    },
  })
);

export default useContractUploadStyles;
