import { Box, Typography } from "@material-ui/core";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import { changeCountry } from "context/letterOfIntent//lettersOfIntentList/lettersOfIntentListActions";
import { useLettersOfIntentList } from "context/letterOfIntent/lettersOfIntentList/lettersOfIntentListContext";
import useHeaderStyles from "./HeaderStyles";

function Header() {
  const { state, dispatch } = useLettersOfIntentList();
  const { country } = state;
  const classes = useHeaderStyles();

  return (
    <Box className={classes.headerContainer}>
      <Typography className={classes.title}>Documento de cierre</Typography>
      <CountrySelector
        stateCountry={country}
        onChange={(country) => {
          dispatch(changeCountry(country));
        }}
      />
    </Box>
  );
}

export default Header;
