import { makeStyles, Theme } from "@material-ui/core/styles";

const useFilterTagStyles = makeStyles((theme: Theme) => ({
  chip: {
    padding: theme.spacing(1),
    height: 36,
    borderRadius: 18,
    "& .MuiChip-label": {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
}));

export default useFilterTagStyles;
