export type Country = "chile" | "mexico" | "colombia";

export type CountryUpperCase = "Chile" | "Colombia" | "Mexico" | "México";

export type Timezones =
  | "America/Santiago"
  | "America/Bogota"
  | "America/Mexico_City"
  | "America/Tijuana";

export type Currency = "MXN" | "COP" | "CLF" | "CLP" | "USD";

export const LOCALE_ARRAY = ["cl", "co", "mx"];
export type Locale = "cl" | "co" | "mx";

export type Regions = "Baja California";

export enum Countries {
  CHILE = "chile",
  COLOMBIA = "colombia",
  MEXICO = "mexico",
}

export enum LocaleCountries {
  CL = "cl",
  CO = "co",
  MX = "mx",
}
