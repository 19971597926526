import { ActionType, State, Action } from "./unitTypes";

export const initialState: State = {
  unit: {},
  units: [],
  property: null,
  propertyServices: [],
  scheduledVisits: [],
  extraStates: {
    isFetchingAllUnits: false,
  },
  loadingUpload: false,
  unitsUploadModal: false,
  unitsUploadErrors: [],
};

const demandLeadReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PROPERTY:
      return {
        ...state,
        property: action.property,
      };
    case ActionType.SET_PROPERTY_SERVICES:
      return {
        ...state,
        propertyServices: action.propertyServices,
      };
    case ActionType.SET_SCHEDULED_VISITS:
      return {
        ...state,
        scheduledVisits: action.scheduledVisits,
      };
    case ActionType.SET_UNITS:
      return {
        ...state,
        units: action.units,
      };
    case ActionType.SET_UNIT:
      return {
        ...state,
        unit: action.unit,
      };
    case ActionType.SET_EXTRA_STATES:
      return {
        ...state,
        extraStates: {
          ...state.extraStates,
          ...action.extraStates,
        },
      };
    case ActionType.SET_LOADING_UPLOAD:
      return {
        ...state,
        loadingUpload: action.loadingUpload,
      };
    case ActionType.SET_UNIT_UPLOAD_MODAL:
      return {
        ...state,
        unitsUploadModal: action.unitsUploadModal,
      };
    case ActionType.SET_UNIT_UPLOAD_ERRORS:
      return {
        ...state,
        unitsUploadErrors: action.unitsUploadErrors,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
