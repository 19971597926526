import { useFeatureManager } from "@houm-com/feature-manager";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { Locale } from "models/Countries";

import BasicServicesHeader from "./components/BasicServicesHeader";
import BasicServicesFooter from "./components/BasicServicesFooter";
import BasicServiceModal from "./components/BasicServiceModal";
import PropertyServices from "./components/PropertyServices";
import { BasicService } from "../utils/types";

interface Props {
  propertyId: number;
  country: Locale;
  openModal: boolean;
  setOpenModal: (b: boolean) => void;
  propertyServicesLoading: boolean;
  propertyServicesError: Error;
  propertyServices: BasicService[];
  updateServices: () => void;
  updateLoading: boolean;
  updating: boolean;
  backgroundTitle: boolean;
}

const BasicServices = ({
  propertyId,
  country,
  openModal,
  setOpenModal,
  propertyServicesLoading,
  propertyServicesError,
  propertyServices,
  updateServices,
  updateLoading,
  updating,
  backgroundTitle,
}: Props) => {
  const { data: servicesFlagData, status: servicesFlagStatus } = useFeatureManager(
    "show_services_details",
    { marketCode: country }
  );
  const showServiceDetails = servicesFlagStatus === "success" && servicesFlagData?.enabled;

  return (
    <AdminCard title="Servicios básicos" withSpace={false} showTitleBackground={backgroundTitle}>
      {showServiceDetails && <BasicServicesHeader />}
      <BasicServiceModal
        open={openModal}
        country={country}
        handleClose={() => setOpenModal(false)}
        propertyId={propertyId}
        title="Ingresar nuevo"
      />

      <PropertyServices
        country={country}
        services={propertyServices}
        loading={propertyServicesLoading}
        error={propertyServicesError}
      />

      <BasicServicesFooter
        addService={() => setOpenModal(true)}
        hasServices={propertyServices?.length > 0}
        updating={updating}
        updateLoading={updateLoading}
        updateServices={updateServices}
        country={country}
      />
    </AdminCard>
  );
};

export default BasicServices;
