import { FormProvider, SubmitHandler } from "react-hook-form";

import { useFeatureManager } from "@houm-com/feature-manager";
import ConfirmDialog from "@houm-com/ui/ConfirmDialog";
import FieldSelect from "@houm-com/ui/FieldSelect";
import ModalDialog from "@houm-com/ui/ModalDialog";
import Spinner from "@houm-com/ui/Spinner";
import Button from "@houm-com/ui/Button";

import useReactHookForm from "hooks/useReactHookForm";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import useDeactivateSubscription from "../../hooks/useDeactivateSubscription";
import { unsubscribeSchema } from "./utils/validationSchema";
import { IForm } from "./utils/types";

interface Props {
  subscriptionId: number;
  country: Locale;
}

const DeactivateSubscription = ({ subscriptionId, country }: Props) => {
  const { data, status } = useFeatureManager("unsubscribe_reasons");
  const { handleOpenModal, open } = useModal();
  const { handleOpenModal: handleOpenConfirmDialog, open: openConfirmDialog } = useModal();
  const { methods, errors } = useReactHookForm<IForm>({ formSchema: unsubscribeSchema });
  const { mutate, isLoading } = useDeactivateSubscription(
    country === "cl" ? handleOpenModal : handleOpenConfirmDialog
  );

  const onUnsubscribe = (dataForm?: IForm) => {
    if (isLoading) return;
    mutate({ subscriptionId, ...dataForm });
  };

  const onSubmit: SubmitHandler<IForm> = (dataForm) => onUnsubscribe(dataForm);

  const onHandleModal = () => {
    if (country === "cl") return handleOpenModal();
    handleOpenConfirmDialog();
  };

  return (
    <>
      <Button size="md" variant="tertiary" onClick={onHandleModal}>
        Dar de baja
      </Button>
      <ModalDialog
        isOpen={open}
        hasCloseBtn
        onClose={handleOpenModal}
        title="Especifica el motivo de la baja">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalDialog.Content classNameContent="justify-center">
              {status === "loading" && (
                <Spinner
                  classNameContainer="relative flex justify-center m-2"
                  size="lg"
                  variant="primary"
                />
              )}
              {status === "success" && (
                <FieldSelect
                  id="finalizedReasonCode"
                  required
                  errorMessage={errors.finalizedReasonCode?.message}
                  placeholder="Selecciona un motivo"
                  options={data ? data?.content : []}
                  {...methods.register("finalizedReasonCode")}
                  form="novalidateform"
                />
              )}
            </ModalDialog.Content>
            <ModalDialog.Actions
              onClose={handleOpenModal}
              submitButtonProps={{
                isLoading,
                disabled: isLoading,
                type: "submit",
              }}
              submitButtonLabel="Dar de baja"
            />
          </form>
        </FormProvider>
      </ModalDialog>
      <ConfirmDialog
        isOpen={openConfirmDialog}
        title="¿Seguro que quieres dar de baja esta suscripción?"
        submitButtonLabel="Si, dar de baja"
        cancelButtonLabel="Cancelar"
        onSubmit={onUnsubscribe}
        onClose={handleOpenConfirmDialog}
        submitButtonProps={{ isLoading, disabled: isLoading }}
      />
    </>
  );
};

export default DeactivateSubscription;
