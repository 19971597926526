import { Country } from "models/Countries";

export type Dispatch = (action: Action) => void;

export const SET_LOADING = "SET_LOADING";
export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const SET_COUNTRY = "SET_COUNTRY";

export interface State {
  loading: boolean;
  reservations: Reservation[];
  numberOfReservations: number | null;
  country: Country;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: Country;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setReservations {
  type: typeof SET_RESERVATIONS;
  payload: { reservations: Reservation[]; count: number };
}

export type Action = setCountry | setReservations | setLoading;

interface Reservation {
  id: string;
  name: string;
  property: string;
  amount: string;
  paymentDate: string;
  action: string;
  evaluator: string;
}
