import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  aggregateDebt: number;
}

const useCommonExpenseDataStyles = makeStyles<Theme, Props>((theme) => ({
  item: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: "flex",
  },
  bold: {
    marginRight: theme.spacing(0.5),
    fontWeight: 700,
  },
  aggregateDebt: {
    fontWeight: ({ aggregateDebt }) => (aggregateDebt !== 0 ? 700 : 400),
    color: ({ aggregateDebt }) => (aggregateDebt !== 0 ? theme.palette.primary.main : "inherit"),
  },
  notValid: {
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
  },
}));

export default useCommonExpenseDataStyles;
