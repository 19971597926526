import qs from "query-string";

import { IUnitsUploadData } from "context/projectContext/projectTypes";
import { createHttp } from "./helper";

export default {
  postLayout(data, token) {
    return createHttp(token).post(`/real_estate_developments/v2/unit_types/`, data);
  },

  editLayout(data, layoutId, token) {
    return createHttp(token).put(`/real_estate_developments/v2/unit_types/${layoutId}/`, data);
  },

  deleteLayout(layoutId, token) {
    return createHttp(token).delete(`/real_estate_developments/v2/unit_types/${layoutId}/`);
  },

  postUnit(data, token) {
    return createHttp(token).post(`/real_estate_developments/v2/units/`, data);
  },

  editUnit(data, modelId, token) {
    return createHttp(token).put(`/real_estate_developments/v2/units/${modelId}/`, data);
  },

  deleteUnit(modelId, token) {
    return createHttp(token).delete(`/real_estate_developments/v2/units/${modelId}/`);
  },

  getLayouts(propId, token) {
    return createHttp(token).get(`/real_estate_developments/v2/property/${propId}/unit_types/`);
  },

  getLayoutImagePresignedUrl(filename: string, token: string) {
    return createHttp(token).get(
      `/real_estate_developments/v2/get_layout_url?filename=${filename}`
    );
  },

  getUnitsFilePresignedUrl(filename: string, token: string) {
    return createHttp(token).get(
      `/real_estate_developments/v2/get_units_file_url?filename=${filename}`
    );
  },

  parseUnitsFile(data: IUnitsUploadData, token: string) {
    return createHttp(token).post(`/real_estate_developments/v2/bulk_load`, data);
  },

  getProjects(pageSize, page, { ...filters }, token) {
    return createHttp(token).get(
      `/real_estate_developments/v2/developments/?page_size=${pageSize}&page=${page}&${qs.stringify(
        filters
      )}`
    );
  },

  createProject(project, token: string) {
    return createHttp(token).post(`real_estate_developments/v2/developments/`, project);
  },

  editProject(projectId: number, project, token: string) {
    return createHttp(token).put(`real_estate_developments/v2/developments/${projectId}/`, project);
  },

  getProject(propertyId: number, token: string) {
    return createHttp(token).get(`real_estate_developments/v2/property/${propertyId}`);
  },
};
