import { makeStyles, Theme } from "@material-ui/core";

const useControlledCustomTextFieldStyles = makeStyles<Theme>((theme) => ({
  container: {
    maxWidth: 172,
  },
  textFieldGrid: {
    borderRadius: "0px 8px 8px 0px",
    "&:focus-within": {
      backgroundColor: "#F7F8FC",
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
  labelContainer: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    minWidth: 57,
    padding: theme.spacing(0, 1),
    display: "flex",
    alignItems: "center",
    height: 31,
  },
  textContainer: {
    maxWidth: 103,
  },
  input: {
    height: 31,
    padding: theme.spacing(0.25, 1),
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: `1px solid ${theme.palette.grey[75]}`,
    background: theme.palette.grey[75],
    fontSize: theme.typography.pxToRem(12),
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  wrapper: {
    padding: 0,
  },
}));

export default useControlledCustomTextFieldStyles;
