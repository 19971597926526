import { Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { setCurrentTabValue } from "context/tabContext/tabActions";
import { useTab } from "context/tabContext/tabContext";
import { useTag } from "context/tagContext/tagContext";
import useStyles from "../useTagFormStyles";
import { useGetToken } from "hooks";
import { getTags } from "context/tagContext/tagActions";

function ConfirmModal() {
  const classes = useStyles();

  const { state, dispatch: tagDispatch } = useTag();
  const { getToken } = useGetToken();

  const { dispatch: tabDispatch } = useTab();

  const handleConfirm = async () => {
    tabDispatch(setCurrentTabValue("tags"));
    const authToken = await getToken();
    await getTags(state.filters.id, tagDispatch, authToken);
  };

  return (
    <CustomCenteredModal
      open={state.showSuccess}
      title="Tag creado con éxito"
      subtitle="El tag está en proceso de publicación">
      <div className={classes.modal}>
        <Typography id="modalSubtitle" className={classes.subtitle}>
          <strong>¡No lo olvides!</strong> Esta acción puede{" "}
          <strong>tardar unos minutos en verse reflejada en houm.com</strong>
        </Typography>
        <DesignSystemButton
          buttonClassName={classes.confirmButton}
          label="Crear nuevo Tag"
          size="medium"
          variant="primary"
          onClick={() => handleConfirm()}
        />
      </div>
    </CustomCenteredModal>
  );
}

export default ConfirmModal;
