import { makeStyles, Theme } from "@material-ui/core";

const useTemplateTitleStyles = makeStyles<Theme>((theme) => ({
  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
}));

export default useTemplateTitleStyles;
