import { IDocumentSectionsLinksResponse } from "services/contractService/types";
import { IDocumentSectionsLinks } from "./types";

export const parseDocumentSectionsLinks = (
  data: IDocumentSectionsLinksResponse
): IDocumentSectionsLinks => ({
  contractGeneral: data.contract_general,
  contractParticular: data.contract_particular,
  mandateGeneral: data.mandate_general,
  mandateParticular: data.mandate_particular,
});
