import * as React from "react";

import { Button as MuiButton, ButtonProps } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  useOptionButtonStyles,
  useButtonNavigationStyles,
  useHourButtonStyles,
} from "./ButtonStyle";

interface IPropsButton extends ButtonProps {
  disabled?: boolean;
  children?: React.ReactNode;
}
interface IPropsOptionButton extends ButtonProps {
  disabled?: boolean;
  children?: React.ReactNode;
  compressed?: boolean;
  wired?: boolean;
}
function NextButton({ children, disabled, ...props }: IPropsButton) {
  const classes = useButtonNavigationStyles({ disabled });
  return (
    <MuiButton {...props} disabled={disabled} className={classes.nextButton}>
      {children}
    </MuiButton>
  );
}

interface IPropsBackButton extends ButtonProps {
  arrow?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}
export function BackButton({ children, arrow, disabled, ...props }: IPropsBackButton) {
  const classes = useButtonNavigationStyles({ disabled });
  return (
    <MuiButton {...props} className={classes.backButton}>
      {arrow && (
        <div className={classes["childrenBackButton"]}>
          <ArrowBackIosIcon className={classes["largeIcon"]} />
          {children}
        </div>
      )}
      {!arrow && children}
    </MuiButton>
  );
}

export function OptionButton({
  children,
  disabled,
  compressed,
  wired,
  ...props
}: IPropsOptionButton) {
  const classes = useOptionButtonStyles({ disabled, compressed, wired });
  return (
    <MuiButton
      {...props}
      className={classes.optionButtonWrap}
      classes={compressed && { root: classes.contract }}>
      {children}
    </MuiButton>
  );
}

interface IpropsHourButton extends ButtonProps {
  children?: React.ReactNode;
  active?: boolean;
}
export function HourButton({ children, active, ...props }: IpropsHourButton) {
  const classes = useHourButtonStyles({ active });
  return (
    <MuiButton {...props} className={classes.hourButton}>
      {children}
    </MuiButton>
  );
}

export default NextButton;
