import { useEffect, useState } from "react";
import { InputAdornment, Typography } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useProject } from "context/projectContext/projectContext";
import FormBlock from "./FormBlock";
import useStyles from "./InvestmentStyles";
import CustomTextField from "../CustomTextField";

function Investment() {
  const [firstDepositValue, setFirstDepositValue] = useState<number>(null);
  const { state } = useProject();
  const { control } = useFormContext();
  const classes = useStyles();

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.project?.property?.country,
  });

  const currency = useWatch({
    control,
    name: "tipo_moneda_venta",
    defaultValue: state.project?.property?.sales_details?.currency,
  });

  const firstDeposit = useWatch({
    control,
    name: "first_deposit",
    defaultValue: state.project?.development_details?.first_deposit ?? undefined,
  });

  const totalValue = useWatch({
    control,
    name: "valor_venta",
    defaultValue: state.project?.property?.property_details[0]?.valor_venta,
  });

  useEffect(() => {
    if (firstDeposit) {
      setFirstDepositValue(Math.round((firstDeposit / 100) * totalValue));
    } else {
      setFirstDepositValue(0);
    }
  }, [firstDeposit, totalValue]);

  if (totalValue)
    return (
      <FormBlock>
        <ControlledTextField
          id="reservation_value"
          label="Reservalo / Apartalo desde"
          textFieldProps={{
            placeholder: "0,00",
            InputProps: {
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            },
          }}
          defaultValue={state.project?.development_details?.reservation_value}
        />
        <Typography component="h3" className={classes.label}>
          Valor del pie / enganche
        </Typography>
        <div className={classes.splitCell}>
          <CustomTextField
            value={firstDepositValue}
            disabled
            defaultValue={firstDepositValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency !== "U.F." ? "$" : "UF"}</InputAdornment>
              ),
              placeholder: "0,0",
            }}
          />
          <ControlledTextField
            id="first_deposit"
            label=""
            defaultValue={
              state.project?.development_details?.first_deposit
                ? state.project.development_details.first_deposit * 100
                : undefined
            }
            textFieldProps={{
              placeholder: "0",
              InputProps: {
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                className: classes.percentage,
              },
            }}
          />
        </div>
        {country === "Chile" && (
          <>
            <Typography component="h3" className={classes.label}>
              Paga el pie en:
            </Typography>
            <div className={classes.splitCell}>
              <ControlledTextField
                id="installments"
                label="N° mensualidades"
                textFieldProps={{
                  placeholder: "24",
                }}
                defaultValue={state.project?.development_details?.installments}
              />
              <ControlledTextField
                id="installments_value"
                label="Valor de las mensualidades"
                textFieldProps={{
                  placeholder: "0,00",
                  InputProps: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                }}
                defaultValue={state.project?.development_details?.installments_value}
              />
            </div>
          </>
        )}
        {country !== "Chile" && (
          <ControlledTextField
            id="notarial_costs"
            label="Costos notariales"
            defaultValue={
              state.project?.development_details?.notarial_costs
                ? state.project.development_details.notarial_costs * 100
                : undefined
            }
            textFieldProps={{
              placeholder: "0.0",
              InputProps: {
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                className: classes.notarialCosts,
              },
            }}
          />
        )}
      </FormBlock>
    );
  else return null;
}

export default Investment;
