import { format } from "date-fns";

import { Grid, Typography } from "@material-ui/core";

import useGetExitProcess from "Components/Admin/administrator/subscriptions/hooks/useGetExitProcess";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

import InfoItemGeneralValue from "../InfoItemGeneralValue";
import { requiredByParser } from "./utils/parsers";
import InfoItemLabel from "../InfoItemLabel";

interface Props {
  contractId: number;
}

const ExitProcessInfo = ({ contractId }: Props) => {
  const { exitProcess, isError, isLoading } = useGetExitProcess(contractId);

  return (
    <AdminCard title="Información de salida" withSpace={false} showTitleBackground={true}>
      <Loading loading={isLoading}>
        <>
          {isError && (
            <Typography>Error al obtener la información del proceso de salida</Typography>
          )}
          {exitProcess && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InfoItemLabel labelText="Solicitada por:">
                  <InfoItemGeneralValue
                    value={
                      exitProcess.requested_by_user
                        ? requiredByParser[exitProcess.requested_by_user]
                        : "-"
                    }
                  />
                </InfoItemLabel>
              </Grid>
              <Grid item xs={12} md={2}>
                <InfoItemLabel labelText="Fecha estimada de salida:">
                  <InfoItemGeneralValue
                    value={
                      exitProcess.estimated_date
                        ? format(new Date(exitProcess.estimated_date), "dd/MM/yyyy")
                        : "-"
                    }
                  />
                </InfoItemLabel>
              </Grid>
              {/* <Grid item xs={12} md={2}>
                <InfoItemLabel labelText="Fecha y hora de visita:">
                  <InfoItemGeneralValue value="Por agendar" />
                </InfoItemLabel>
              </Grid> */}
              <Grid item xs={12} md={8}>
                <InfoItemLabel labelText="Fecha de solicitud de término de contrato:">
                  <InfoItemGeneralValue
                    value={
                      exitProcess.begin_at
                        ? format(new Date(exitProcess.begin_at), "dd/MM/yyyy")
                        : "-"
                    }
                  />
                </InfoItemLabel>
              </Grid>
              {/* <Grid item xs={12}>
                <InfoItemLabel labelText="Inspector (Houmer):">
                  <InfoItemGeneralValue value="Nombre del inspector (Houmer)" />
                </InfoItemLabel>
              </Grid> */}
              <Grid item xs={12}>
                <InfoItemLabel labelText="Motivo de salida:">
                  <InfoItemGeneralValue value={exitProcess.comment ?? "-"} />
                </InfoItemLabel>
              </Grid>
            </Grid>
          )}
        </>
      </Loading>
    </AdminCard>
  );
};

export default ExitProcessInfo;
