import {
  ContractExitProcessTypes,
  ContractExitProcessRequestedByUser,
  ContractExitProcessStatuses,
  exitProcessEndedStatus,
  exitProcessCancelledStatus,
  exitProcessStartedStatus,
  exitProcessEarlyFinishType,
  exitProcessAbandonmentType,
  exitProcessRequestedByAdminType,
  exitProcessEvictionType,
  exitProcessRequestedByTenantType,
  exitProcessRequestedByLandlordType,
} from "models/Contract";
import {
  IContractExitProcessFormData,
  abandonmentLabel,
  earlyFinishLabel,
  evictionLabel,
  noRenovationLabel,
  requestedByAdminLabel,
  requestedByLandlordLabel,
  requestedByTenantLabel,
  statusLabels,
} from "./types";
import { IContractExitProcessData } from "services/contractService/types";

export const parseExitProcessStatus = (status: ContractExitProcessStatuses): string => {
  switch (status) {
    case exitProcessEndedStatus:
      return statusLabels.ENDED;
    case exitProcessCancelledStatus:
      return statusLabels.CANCELLED;
    case exitProcessStartedStatus:
      return statusLabels.IN_PROCESS;
    default:
      return statusLabels.NOT_STARTED;
  }
};

export const parseExitProcessType = (type: ContractExitProcessTypes): string => {
  switch (type) {
    case exitProcessEarlyFinishType:
      return earlyFinishLabel;
    case exitProcessAbandonmentType:
      return abandonmentLabel;
    case exitProcessEvictionType:
      return evictionLabel;
    default:
      return noRenovationLabel;
  }
};

export const parseExitProcessRequestedByUser = (
  type: ContractExitProcessRequestedByUser
): string => {
  switch (type) {
    case exitProcessRequestedByLandlordType:
      return requestedByLandlordLabel;
    case exitProcessRequestedByAdminType:
      return requestedByAdminLabel;
    default:
      return requestedByTenantLabel;
  }
};

export const parseFormData = (data: IContractExitProcessFormData): IContractExitProcessData => {
  const base = { comment: data.comment, estimated_date: data.estimatedDate };
  switch (data.requirementOrigin) {
    case exitProcessRequestedByLandlordType:
      return {
        ...base,
        requested_by_user: exitProcessRequestedByLandlordType,
      };
    case exitProcessRequestedByTenantType:
      return {
        ...base,
        requested_by_user: exitProcessRequestedByTenantType,
      };
    case exitProcessAbandonmentType:
      return {
        ...base,
        requested_by_user: exitProcessRequestedByAdminType,
        type: exitProcessAbandonmentType,
      };
    default:
      return {
        ...base,
        requested_by_user: exitProcessRequestedByAdminType,
        type: exitProcessEvictionType,
      };
  }
};
