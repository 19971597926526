import { makeStyles, Theme } from "@material-ui/core";

const useCreatePromotionModalStyles = makeStyles<Theme>((theme) => ({
  dialog: {
    borderRadius: 16,
    padding: theme.spacing(3, 2),
    display: "flex",
    minWidth: 532,
  },
}));

export default useCreatePromotionModalStyles;
