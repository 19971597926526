import { useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { useTab } from "context/tabContext/tabContext";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";

import useRequestsStyles from "./RequestsStyles";
import RequestsTable from "./components/RequestsTable";

const Requests = () => {
  const { state: tabState } = useTab();
  const { id } = useParams<{ id: string }>();
  const { currentTabValue } = tabState;
  const history = useHistory();
  const classes = useRequestsStyles();

  const onClickHandler = () => {
    history.push(`/admin/clients/${id}/repairs`);
  };

  return (
    <TabPanel value={currentTabValue} index="requests">
      <AdminCard title="Reparaciones" withSpace={false} showTitleBackground={true}>
        <Box className={classes.cardDescription}>
          <RequestsTable />
        </Box>
        <DesignSystemButton
          label="Crear solicitud"
          variant="primary"
          size="small"
          Icon={<Add />}
          onClick={onClickHandler}
        />
      </AdminCard>
    </TabPanel>
  );
};

export default Requests;
