import { Theme, makeStyles } from "@material-ui/core/styles";

const usePlanDetailsStyles = makeStyles((theme: Theme) => ({
  textLabelTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginBottom: theme.spacing(1.4),
    paddingLeft: 4.8,
  },
  textLabel: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 4.8,
    marginBottom: theme.spacing(1.75),
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
}));

export default usePlanDetailsStyles;
