import { useMutation } from "react-query";

import { IExternalCollection } from "services/contractService/types";
import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { ContractType, PlanProps } from "../utils/types";
import { CONTRACT_TYPES } from "../utils/constants";

const usePostExternalCollection = (contractId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const { mutate, isLoading: submittingExternalCollection } = useMutation(
    async (data: PlanProps) => {
      const requestBody: IExternalCollection = {
        contract_id: contractId,
        contract_type:
          country === "cl" ? data.contractType : (CONTRACT_TYPES.lease.value as ContractType),
        exclusivity_conditions: {
          plan_id: data.futurePlanId ? Number(data.futurePlanId) : null,
        },
      };
      const token = await getToken();
      await contractService.postExternalCollection(contractId, token, requestBody);
    },
    {
      onError: () => {
        alert.error({
          message: "Error al crear la cobranza externa",
          disclaimer: "No se pudo crear la cobranza externa, contactarse con soporte",
        });
      },
    }
  );
  return { mutate, submittingExternalCollection };
};

export default usePostExternalCollection;
