import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    marginTop: theme.spacing(2.5),
    borderRadius: 10,
    boxShadow: `2px 5px 10px 2px ${theme.palette.grey[100]}`,
  },
  h6: {
    marginTop: theme.spacing(3.75),
  },
  propertySearch: {
    width: 600,
    height: 200,
  },
  propertyContainer: {
    paddingRight: theme.spacing(2.5),
  },
  rentContainer: {
    paddingRight: theme.spacing(2.5),
  },
  closeButton: {
    zIndex: 1,
    color: "black",
  },
  saveButton: {
    marginTop: theme.spacing(2.5),
    textTransform: "none",
    borderRadius: 25,
    fontWeight: "bold",
    width: 200,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));
