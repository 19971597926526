import { format } from "date-fns";
import { IFormData } from "./types";

export const parseFormData = (data: IFormData, propertyId) => ({
  property_id: propertyId,
  company: "otro",
  total_debt: data.currentDebt + data.aggregateDebt,
  expired_debt: data.aggregateDebt,
  administration_email: data.email,
  due_date: `${format(new Date(data.dueDate), "yyyy-MM-dd")}T00:00:00`,
  client_info: {
    external_id: "",
  },
  document_name: data.voucher?.name,
  file: data.voucher,
});
