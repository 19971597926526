import { makeStyles, Theme } from "@material-ui/core";

const useContractFormStyles = makeStyles<Theme>((theme) => ({
  selectionLabel: {
    transform: "none",
    position: "relative",
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(1),
  },
  selectionContainer: {
    margin: 0,
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useContractFormStyles;
