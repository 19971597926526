import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[50]}`,
      borderRadius: 13,
    },
    inline: {
      display: "inline",
      fontSize: theme.typography.pxToRem(16),
    },
    description: {
      maxWidth: "75%",
    },
    inlineTotal: {
      display: "inline",
      fontWeight: 700,
      fontSize: 18,
      color: "#000000dd",
    },
    listHead: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
    },
    positive: {
      color: "#00982D",
      fontWeight: 700,
    },
    negative: {
      color: "#F02C2C",
      fontWeight: 700,
    },
    listContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    detailContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  })
);

export default useStyles;
