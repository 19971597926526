import { makeStyles, Theme } from "@material-ui/core";

const useTableHeaderStyles = makeStyles<Theme>((theme) => ({
  headerRoot: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(1.5, 0),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[50],
  },
  headerColumn: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerFont: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
    color: theme.palette.grey[500],
  },
}));

export default useTableHeaderStyles;
