import axios, { AxiosResponse } from "axios";
import qs from "query-string";
import { Moment } from "moment";

import { CountryUpperCase, Country } from "models/Countries";

import { getToken, createHttp } from "../helper";
import { BACKEND_URL } from "../../env";
import { IAppraiser } from "./types";

export default {
  async getAvailableHours(
    scheduleType,
    propId,
    token,
    appraiserId = undefined,
    country = null,
    cityId = null
  ) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http
      .get("/schedules/v2/get_available_hours/", {
        params: {
          schedule_type: scheduleType,
          property: propId,
          appraiser: appraiserId,
          country,
          city_id: cityId,
        },
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  },

  async getAllAppraisers(token) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.get("schedules/v2/get_all_appraisers").catch((error) => {
      console.error(error);
      return false;
    });
  },

  getAppraisers(token: string, { ...filters } = {}): Promise<AxiosResponse<IAppraiser[]>> {
    return createHttp(token).get(`schedules/v2/get_all_appraisers?${qs.stringify(filters)}`);
  },

  createPhotoSchedule(schedule, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.post("/schedules/v2/create_schedule_user/", schedule).catch((error) => {
      console.error(error);
      return false;
    });
  },

  createVisitSchedule(schedule, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.post("/schedules/v2/create_schedule_user/", schedule).catch((error) => {
      console.error(error);
      return false;
    });
  },

  createVisitScheduleV2(
    schedule: {
      begin_hour: Moment;
      end_hour: Moment;
      prop: string;
      date: Moment;
      type: "Exit" | "Inspection" | "Cleaning";
      origin: string;
      comment?: string;
    },
    token: string
  ) {
    return createHttp(token).post("/schedules/v2/create_schedule_user/", schedule);
  },

  getEvents(token) {
    return createHttp(token).get(`/schedules/v2/get_all_scheduletypes`);
  },

  createSchedule(data, token) {
    return createHttp(token).post("/schedules/v2/create_schedule", data);
  },

  editSchedule(data, id, token) {
    return createHttp(token).post(`/schedules/v2/reschedule/${id}`, data);
  },

  assignAppraiserToProp(data: { property_id: number; appraiser_id: string }, token) {
    return createHttp(token, BACKEND_URL).post("schedules/v2/assign-appraiser-to-prop", data);
  },

  unassignAppraiserToProp(data: { property_id: number }, token) {
    return createHttp(token, BACKEND_URL).delete(
      `schedules/v2/unassign-appraiser-to-prop?property_id=${data.property_id}`
    );
  },

  async setCancelStatus(scheduleId, reasonId, comment, eventEditor, authToken) {
    const httpInstance = createHttp(authToken);
    const data = {
      comment,
      schedule_id: scheduleId,
      cancel_reason_id: reasonId,
      event_editor: eventEditor,
    };
    return httpInstance.post(`/schedules/v2/cancel_schedule_visit`, data);
  },

  deleteSchedule(id, dontSendEmail, dontDelete, source, token, reason = null) {
    const body = {
      not_emails: dontSendEmail,
      dont_delete: dontDelete,
      action_source: source,
      ...(reason && { cancel_reason: reason }),
    };
    return createHttp(token).post(`/schedules/v2/delete_schedule/${id}`, body);
  },

  getCancelReasons(token) {
    return createHttp(token).get(`/schedules/v2/get_cancel_reasons`);
  },

  confirmSchedule(eventId, source, token) {
    return createHttp(token).post(`/schedules/v2/schedule-status/update/${eventId}`, {
      action: "Confirmed",
      action_source: source,
    });
  },

  getApplicantSchedules(_applicant, token) {
    return createHttp(token)
      .get(`/schedules/v2/applicant/${_applicant}`)
      .catch((error) => {
        console.error(error);
        return null;
      });
  },

  todayStats(token) {
    return createHttp(token).get("/schedules/v2/statistics/by-day/today");
  },

  tomorrowStats(token) {
    return createHttp(token).get("/schedules/v2/statistics/by-day/tomorrow");
  },

  generalSummary(token) {
    return createHttp(token).get("/schedules/v2/statistics/availability");
  },

  fetchAppraisers(token) {
    return createHttp(token).get(`/schedules/v2/get_all_appraisers`);
  },
  getMacrozones(token, country: CountryUpperCase | Country) {
    return createHttp(token).get(`/schedules/v2/macrozone-all?country=${country}`);
  },
  getPropertyAppraiser(token, propertyId: number) {
    return createHttp(token).get(`schedules/v2/property/get-appraiser`, {
      params: { property_id: propertyId },
    });
  },
};
