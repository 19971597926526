export const deliveryStatusOptions = (country) => {
  const deliveryOptions = [
    ...(country === "Colombia" ? [{ label: "Lanzamiento", value: "proposed" }] : []),
    {
      value: "site_plan_approved",
      label: "En blanco (CL) / Lanzamiento (MX) / Planos (CO)",
    },
    {
      value: "under_construction",
      label: "En verde (CL) / Preventa (MX) / Construcción (CO)",
    },
    {
      value: "completed",
      label: "Entrega inmediata (CL / MX / CO)",
    },
  ];
  return deliveryOptions;
};
