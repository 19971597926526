import { PropertyRentalStatus, PropertySaleStatus } from "../models/Property";
import propertyStatusTranslator from "utils/statusTranslator";

export interface Option {
  value: string;
  name: string;
}

export const propertySaleStatus: PropertySaleStatus[] = [
  "incomplete",
  "photo_scheduled",
  "photo_cancelled",
  "photo_done",
  "complete",
  "published",
  "sold",
  "unpublished",
  "lost",
  "archived",
  "out_of_coverage",
  "unpublished_by_import",
];

export const propertyRentalStatus: PropertyRentalStatus[] = [
  "incomplete",
  "photo_scheduled",
  "photo_cancelled",
  "photo_done",
  "complete",
  "published",
  "reserved",
  "republished",
  "rented",
  "unpublished",
  "archived",
  "lost",
  "out_of_coverage",
  "unpublished_by_import",
];

export const photoStatus: Option[] = [
  {
    value: "Done",
    name: "Realizada",
  },
  {
    value: "Scheduled",
    name: "Agendada",
  },
  {
    value: "Cancelled",
    name: "Cancelada",
  },
  {
    value: "Rescheduled",
    name: "Reagendada",
  },
  {
    value: "Confirmed",
    name: "Confirmada",
  },
];

export const propertyStatus: Option[] = [
  {
    value: "incomplete",
    name: propertyStatusTranslator("incomplete"),
  },
  {
    value: "photo_scheduled",
    name: propertyStatusTranslator("photo_scheduled"),
  },
  {
    value: "photo_cancelled",
    name: propertyStatusTranslator("photo_cancelled"),
  },
  {
    value: "photo_done",
    name: propertyStatusTranslator("photo_done"),
  },
  {
    value: "complete",
    name: propertyStatusTranslator("complete"),
  },
  {
    value: "published",
    name: propertyStatusTranslator("published"),
  },
  {
    value: "reserved",
    name: propertyStatusTranslator("reserved"),
  },
  {
    value: "rented",
    name: propertyStatusTranslator("rented"),
  },
  {
    value: "unpublished",
    name: propertyStatusTranslator("unpublished"),
  },
  {
    value: "sold",
    name: propertyStatusTranslator("sold"),
  },
  {
    value: "lost",
    name: propertyStatusTranslator("lost"),
  },
  {
    value: "republished",
    name: propertyStatusTranslator("republished"),
  },
  {
    value: "unknown",
    name: propertyStatusTranslator("unknown"),
  },
  {
    value: "archived",
    name: propertyStatusTranslator("archived"),
  },
  {
    value: "out_of_coverage",
    name: propertyStatusTranslator("out_of_coverage"),
  },
  {
    value: "unpublished_by_import",
    name: propertyStatusTranslator("unpublished_by_import"),
  },
];

export const projectStatus: Option[] = [
  {
    value: "incomplete",
    name: "Incompleta",
  },
  {
    value: "complete",
    name: "Lista para ser publicada",
  },
  {
    value: "published",
    name: "Publicada",
  },
  {
    value: "unpublished",
    name: "Despublicada",
  },
];
