import { Box } from "@material-ui/core";

import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import ArrowBack from "@material-ui/icons/ArrowBackIos";

import usePaginationArrowStyles from "./PaginationArrowStyles";
import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLButtonElement> {
  type: "previous" | "next";
}

function PaginationArrow({ type, onClick, disabled }: Props) {
  const classes = usePaginationArrowStyles({ disabled });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) onClick(event);
  };

  if (type === "next")
    return (
      <Box className={classes.pageArrowWrapper} onClick={handleClick}>
        <ArrowForward className={classes.pageArrow} />
      </Box>
    );

  if (type === "previous")
    return (
      <Box className={classes.pageArrowWrapper} onClick={handleClick}>
        <ArrowBack className={classes.pageArrow} />
      </Box>
    );
}

export default PaginationArrow;
