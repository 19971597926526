import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Spinner from "Components/Shared/partials/Spinner";
import usePostPromotion from "domains/promotion/hooks/usePostPromotion";
import { Locale } from "models/Countries";

import TitlePromotion from "../../components/TitlePromotion";
import useCities from "../Promotions/components/PromotionsList/hooks/useCities";
import GeneralInfoForm from "./components/GeneralInfoForm";

import PromotionSummary from "./components/PromotionSummary";

import useSteps from "./hooks/useSteps";
import { PromotionFormData } from "./utils/types";
import DetailsForm from "./components/DetailsForm";
import { promotionFormSchema } from "./utils/validationSchema";

const CreatePromotion = () => {
  const { country } = useParams<{ country: Locale }>();
  const { data: cities, isLoading, isError: citiesError } = useCities(country);
  const { currentStep, handleNext, handleBack } = useSteps({ totalSteps: 2 });
  const { mutate, isLoading: submitting } = usePostPromotion(country);

  const methods = useForm<PromotionFormData>({
    resolver: yupResolver(promotionFormSchema),
  });
  const onSubmit: SubmitHandler<PromotionFormData> = (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (cities) methods.reset({ cities: cities.map((city) => String(city.id)) });
  }, [cities]);

  if (isLoading) return <Spinner />;

  if (citiesError) return <p className="text-p24">Error cargando las ciudades</p>;

  return (
    <FormProvider {...methods}>
      <TitlePromotion title="Crear promoción" />
      <div className="w-full flex">
        <div className="w-7/12">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {currentStep === 1 && <GeneralInfoForm handleNext={handleNext} cities={cities} />}
            {currentStep === 2 && <DetailsForm handleBack={handleBack} submitting={submitting} />}
          </form>
        </div>
        <div className="w-5/12">
          <PromotionSummary availableCities={cities} country={country} />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreatePromotion;
