import { makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
  color: string;
  flagOnClick: boolean;
}

const useStatusStyles = makeStyles<Theme, StyleProps>((theme) => ({
  chip: {
    color: (props) => props.color,
    border: (props) => `1px solid ${props.color}`,
    backgroundColor: "white",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    "&:hover": {
      backgroundColor: (props) => props.flagOnClick && "#F7F8FC",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

export default useStatusStyles;
