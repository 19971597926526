import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => {
  const defaultBorder = `1px solid  ${theme.palette.grey[50]} !important`;
  const defaultBorderRadius = "8px";
  return {
    deleteButton: {
      border: "none",
      textDecoration: "underline",
      color: theme.palette.primary.main,
      "&:hover": {
        border: "none",
        textDecoration: "underline",
        backgroundColor: "transparent",
      },
    },
    root: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "white !important",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderWidth: 0,
      },
      "& .MuiDataGrid-row": {
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    headerCell: {
      backgroundColor: `${theme.palette.grey["50"]} !important`,
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: theme.palette.grey[500],
        borderBottomWidth: 0,
      },
    },
    firstHeaderCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
    },
    lastRenderedHeaderCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
    },
    leftCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
    },
    rightCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
      overflow: "hidden",
    },
    centerCell: {
      outline: defaultBorder,
      outlineOffset: -2,
      borderLeft: "0 !important",
    },
    lastHeaderCell: {
      display: "none !important",
      maxWidth: "20px !important",
    },
    actionCell: {
      backgroundColor: "white",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
  };
});
