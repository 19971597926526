import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import "./ForceSignature.css";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import loadImage from "blueimp-load-image/js";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import contractService from "../../../../services/contractService";
import signService from "../../../../services/signService";
import { useGetToken } from "../../../../hooks";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

let fileUrl = null;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ForceSignature(props) {
  const params = useParams();
  const { getToken } = useGetToken();
  const [src, setSrc] = useState(null);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [loading, setLoading] = useState(true);
  const [crop, setCrop] = useState({ unit: "%", width: 30 });
  const [fetchingSignatures, setFetchingSignatures] = useState(true);
  const [extractedSignatures, setExtractedSignatures] = useState([]);
  const [currentCiFrontOrientation, setCurrentCiFrontOrientation] = useState(0);
  const [imageRef, setImageRef] = useState(null);
  const [ciFront, setCiFront] = useState(null);
  const [ciBack, setCiBack] = useState(null);
  const [ciFrontLink, setCiFrontLink] = useState("");
  const [ciBackLink, setCiBackLink] = useState("");
  const [signatureLink, setSignatureLink] = useState("");
  const [blob, setBlob] = useState(null);
  const [alertData, setAlertData] = useState({
    message: "Hola!",
    type: "success",
  });
  const [open, setOpen] = useState(false);

  const openAlert = (message, type) => {
    setAlertData({
      message,
      type,
    });
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  useEffect(() => {
    const fetchLinks = async (authToken) => {
      const links = await signService.getSignatureLinks(params.id, authToken);
      if (links) {
        if (links.data.ci_front_link) {
          contractService.getSignatureFile(links.data.ci_front_link).then((response) => {
            const ci_front_file = new File([response.data], "ci_front_file.jpg", {
              type: "image/jpeg",
            });
            setCiFront(ci_front_file);
            setCiFrontLink(links.data.ci_front_link);
            executeLoadImage(ci_front_file, 0);
          });
        }
        if (links.data.ci_back_link) {
          contractService.getSignatureFile(links.data.ci_back_link).then((response) => {
            const ci_back_file = new File([response.data], "ci_back_file.jpg", {
              type: "image/jpeg",
            });
            setCiBack(ci_back_file);
            setCiBackLink(links.data.ci_back_link);
          });
        }
        if (links.data.signature_link) {
          setSignatureLink(links.data.signature_link);
        }
      }
      setLoading(false);
    };
    getToken().then((authToken) => fetchLinks(authToken));
  }, [params.id]);

  const executeLoadImage = (file, orientation) => {
    const orientations = [1, 6, 3, 8]; // orientations: 0=1, 1=6, 2=3, 3=8
    loadImage(
      file,
      (img) => {
        const base64data = img.toDataURL(`image/jpeg`);
        setSrc(base64data);
      },
      { orientation: orientations[orientation], canvas: true }
    );
  };

  const rotateCiFront = (orientation) => {
    const val = currentCiFrontOrientation + orientation;
    // circular array index
    const newOrientation = val < 0 ? 3 : val % 4;
    setCurrentCiFrontOrientation(newOrientation);
    executeLoadImage(ciFront, newOrientation);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCiFront(e.target.files[0]);
      executeLoadImage(e.target.files[0], 0);
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      getCroppedImg(imageRef, crop, "newFile.jpeg");
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            // reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          setBlob(blob);
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/png",
        1
      );
    });
  };

  const extractSignatures = async () => {
    setFetchingSignatures(true);
    const authToken = await getToken();
    const formData = new FormData();
    formData.append("ci", blob);
    const extracted = await signService.extractSignatures(formData, authToken);
    if (extracted) {
      setExtractedSignatures(extracted.data.links);
      setFetchingSignatures(false);
    }
  };

  const submitSignatureChange = async () => {
    const authToken = await getToken();
    const formData = new FormData();
    formData.append("ci_front", ciFront);
    formData.append("ci_back", ciBack);
    formData.append("ci_signature_key", selectedSignature);
    formData.append("contract_person_id", params.id);
    openAlert("Guardando firma y carnet", "info");
    const setPersonSignature = await signService.setContractPersonSignature(formData, authToken);
    if (setPersonSignature) {
      openAlert("Guardado", "success");
      window.history.back();
    } else {
      openAlert("Error", "error");
    }
  };

  return loading ? (
    <p>Loading</p>
  ) : (
    <>
      <Container style={{ padding: "10px 100px" }}>
        <h3>Genial, ahora solo debes subir una foto de tu carnet</h3>
        <p id="introduction-text">Este proceso es más facil en el celular</p>
        <br />
        <div key="Selecciona/toma una foto de tu carnet">
          <h3>
            Sube o toma una foto de la parte <strong>frontal</strong> del carnet
          </h3>
          <div className="cropper-preview-wrap">
            <input id="ci-front-file" type="file" accept="image/*" onChange={onSelectFile} />
          </div>
          {ciFrontLink !== "" && (
            <div>
              Imagen actual: <a href={ciFrontLink}>{ciFrontLink}</a>
            </div>
          )}
          <div />
        </div>
        <div
          key="Selecciona/toma una foto de la parte trasera de tu carnet"
          style={{ marginTop: 40 }}>
          <h3>
            Sube o toma una foto de la parte <strong>trasera</strong> del carnet
          </h3>
          <div className="cropper-preview-wrap">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                setCiBack(e.target.files[0]);
              }}
            />
          </div>
          {ciBackLink !== "" && (
            <div>
              Imagen actual: <a href={ciBackLink}>{ciBackLink}</a>
            </div>
          )}
        </div>

        <div key="Selecciona" style={{ marginTop: 40 }}>
          <h3>Selecciona tu firma</h3>
          <Row className="mb-3">
            <Col xs="4" sm="5" md="2" lg="1">
              Rotar:
            </Col>
            <Col xs="4" sm="2" md="2" lg="1">
              <Button onClick={() => rotateCiFront(-1)}>
                <RotateLeftIcon />
              </Button>
            </Col>
            <Col xs="4" sm="2" md="2" lg="1">
              <Button onClick={() => rotateCiFront(1)}>
                <RotateRightIcon />
              </Button>
            </Col>
          </Row>
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
          <br />
          <Button
            variant="contained"
            color="primary"
            id="commence-process"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              extractSignatures();
            }}>
            Confirmar
          </Button>
        </div>
        <div key="Confirma" style={{ marginTop: 60 }}>
          <h3>¿Cuál de las firmas se ve mejor?</h3>
          {signatureLink !== "" && (
            <>
              <div>
                Firma actual: <a href={signatureLink}>{signatureLink}</a>
              </div>
              <br />
            </>
          )}
          {!fetchingSignatures ? (
            <>
              <br />
              <Row>
                {extractedSignatures.map((item, i) => (
                  <Col sm={6} key={i}>
                    <img
                      id="signature-image"
                      src={item[Object.keys(item)[0]]}
                      alt="signature-1"
                      onClick={() => {
                        setSelectedSignature(Object.keys(item)[0]);
                      }}
                      className={`hoverable ${
                        Object.keys(item)[0] === selectedSignature
                          ? "selected-signature"
                          : "unselected-signature"
                      }`}
                    />
                  </Col>
                ))}
              </Row>
              <center>
                <Button
                  variant="contained"
                  color="primary"
                  id="commence-process"
                  style={{ textTransform: "capitalize", marginTop: 80 }}
                  onClick={async () => {
                    submitSignatureChange();
                  }}
                  disabled={!selectedSignature}>
                  Guardar cambios
                </Button>
              </center>
            </>
          ) : (
            <p>Selecciona el area de la firma en el paso anterior.</p>
          )}
        </div>
      </Container>
      <Snackbar open={open}>
        <Alert severity={alertData.type}>{alertData.message}</Alert>
      </Snackbar>
    </>
  );
}

export default ForceSignature;
