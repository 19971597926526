import { useQuery, UseQueryResult } from "react-query";

import subscriptionService from "services/subscriptionService";
import { useGetToken } from "hooks";

interface IData {
  is_vip: boolean;
  managed_properties: number;
}

const useLandlordVip = (subscriptionId: number) => {
  const { getToken } = useGetToken();

  const {
    data,
  }: UseQueryResult<Partial<IData>, Error> = useQuery<Partial<IData>, Error>(
    ["landlordVip"],
    async () => {
      const authToken = await getToken();
      const response = await subscriptionService.getFlagLandlordsVipSubscription(
        subscriptionId,
        authToken
      );
      return response.data;
    }
  );

  return {
    isLandlordVip: data?.is_vip,
  };
};

export default useLandlordVip;
