import React, { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Typography } from "@material-ui/core";

import {
  ControlledSelection,
  ControlledNumberField,
} from "Components/Admin/UIComponents/ControlledForm";
import FromBlock from "./FormBlock";
import ForRentalPrice from "Components/Admin/UIComponents/PropertyForm/components/ForRentalPrice";
import ForSalePrice from "Components/Admin/UIComponents/PropertyForm/components/ForSalePrice";
import { useProperty } from "context/propertyContext/propertyContext";
import useUserFormRole from "hooks/useUserFormRole";
import { getIsCommonExpensesIncludedLabel } from "Components/Admin/UIComponents/PropertyForm/utils/propertyFormFieldsInfo";
import { emptyPropertyDetails } from "Components/Admin/UIComponents/Cards/emptyDetails";
import { CurrencyByMarket } from "models/createProperty";
import { countries, generalOptions, yesNoBoolean } from "utils/createProperty";
import CommonExpensesAlert from "./CommonExpensesAlert";

function Price() {
  const { control, setValue } = useFormContext();
  const { state } = useProperty();

  const propertyDetails = useMemo(
    () => state.property?.property_details[0] || emptyPropertyDetails,
    [state.property?.property_details[0]]
  );

  const role = useUserFormRole();
  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.property?.country,
  });
  const forRental = useWatch({
    control,
    name: "for_rental",
    defaultValue: state.property?.for_rental,
  });
  const forSale = useWatch({
    control,
    name: "for_sale",
    defaultValue: state.property?.for_sale,
  });

  const isCommonExpensesIncluded: boolean = useWatch({
    control,
    name: "is_common_expenses_included",
    defaultValue: propertyDetails.is_common_expenses_included,
  });

  const commonExpenses: number = useWatch({
    control,
    name: "gc",
    defaultValue: propertyDetails.gc,
  });

  const showCommonExpensesAlert = isCommonExpensesIncluded && commonExpenses > 0;

  const updateCurrencyFieldOnChangeCountry = (type: CurrencyByMarket): void => {
    if (["Colombia", "Mexico"].includes(country)) {
      setValue(type, "Pesos");
    } else {
      setValue(type, propertyDetails[type]);
    }
  };

  useEffect(() => {
    if (forRental) updateCurrencyFieldOnChangeCountry(CurrencyByMarket.RENT);
    if (forSale) updateCurrencyFieldOnChangeCountry(CurrencyByMarket.SALE);
  }, [country]);

  if ((forRental || forSale || role === "real_estate_developer") && country)
    return (
      <FromBlock>
        <Typography variant="h6" component="h3">
          Información monetaria asociada a la propiedad
        </Typography>
        {forRental && <ForRentalPrice country={country} />}
        {(forSale || role === "real_estate_developer") && <ForSalePrice country={country} />}
        {country && country !== countries.CO && (
          <ControlledSelection
            id="is_common_expenses_included"
            label={getIsCommonExpensesIncludedLabel(country)}
            optionWidth="40px"
            defaultValue={propertyDetails.is_common_expenses_included}
            options={yesNoBoolean}
          />
        )}
        <ControlledNumberField
          id="gc"
          label="Gastos comunes"
          defaultValue={propertyDetails.gc || undefined}
          placeholder={country ? generalOptions[country].placeholders.gc : ""}
          numberFieldProps={{
            prefix: "$",
          }}
        />
        {showCommonExpensesAlert && <CommonExpensesAlert country={country} />}
      </FromBlock>
    );
  return <div />;
}

export default React.memo(Price);
