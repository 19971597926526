import { StringOption } from "models/Option";
import { PolygonAllResponse } from "services/macrozoneService/types";
import { MacrozoneByCity, MacrozoneSource } from "./macrozonesTypes";

export const getCityFromMacrozoneList = (
  mcList: Record<string, MacrozoneByCity[]>
): StringOption[] =>
  Object.keys(mcList).map((city) => ({
    label: city,
    value: city,
  }));

export const groupMcByCity = (
  macrozone: PolygonAllResponse
): {
  [key: string]: MacrozoneByCity[];
} => {
  const mcByCities = macrozone.reduce((prev, curr) => {
    const mcCity: MacrozoneByCity = {
      city: curr.city,
      id: curr.id,
      name: curr.name,
      polygon: [curr.macrozone_polygon],
    };
    if (prev[curr.city]) {
      prev[curr.city || "Otro"] = [...prev[curr.city], mcCity];
    } else {
      prev[curr.city || "Otro"] = [mcCity];
    }
    return prev;
  }, {});

  return mcByCities;
};

export const colors = [
  "#FF452B",
  "#2663BC",
  "#A40000",
  "#4C6977",
  "#E7A299",
  "#439F03",
  "#A07800",
  "#FF6349",
  "#08141A",
  "#900000",
  "#26AB55",
];

export const addSourceToMacrozone = (allMacrozones: MacrozoneByCity[]): MacrozoneSource[] =>
  allMacrozones.map((mcCity, i) => ({
    color: colors[i % colors.length],
    id: mcCity.id.toString(),
    source: {
      type: "geojson",
      data: {
        geometry: {
          coordinates: mcCity.polygon,
          type: "Polygon",
        },
        properties: { name: mcCity.name },
        type: "Feature",
      },
    },
  }));
