import { alert } from "@houm-com/ui/Alert";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { PromotionFormData } from "Components/Admin/administrator/promotions/pages/CreatePromotion/utils/types";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

import promotionService from "../service";
import parsePostPromotion from "../parsers/parsePostPromotion";

const usePostPromotion = (country: Locale) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutate, ...rest } = useMutation(
    async (data: PromotionFormData) => {
      const authToken = await getToken();
      const response = await promotionService.newCreatePromotion(
        parsePostPromotion(data, country),
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["promotions_list"]);
        history.push("/admin/promotions");
        alert.success({ message: "Promoción creada correctamente" });
      },
      onError: (e) => {
        const reasonString = JSON.stringify(e);
        if (!reasonString) {
          alert.error({
            message: "Error al crear la promoción. Revisa que todos los campos estén correctos",
          });
        } else {
          alert.error({ message: `Error al crear la promoción: ${reasonString}` });
        }
      },
    }
  );
  return { mutate, ...rest };
};

export default usePostPromotion;
