import * as React from "react";
import clx from "classnames";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridSpacing,
  TextField,
  Typography,
} from "@material-ui/core";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import CustomButton from "./Buttons/CustomButton";
import useStyles from "./FilterDialogStyles";

interface Composition {
  Item: React.FC<FilterItemProps>;
  Title: React.FC;
  Content: React.FC;
  Actions: React.FC<FilterActionsProps>;
  TextField: React.FC<TextFieldProps>;
}

type TextFieldProps = React.ComponentProps<typeof TextField>;
interface Props extends React.ComponentProps<typeof Dialog> {
  onClose: () => void;
  title?: string;
  subtitle?: string;
}

interface FilterItemProps {
  label: string;
  container?: boolean;
  spacing?: GridSpacing;
}

const FilterDialog: React.FC<Props> & Composition = (props) => (
  <CustomDialog
    open={props.open}
    title={props.title || "Filtros"}
    subtitle={props.subtitle || "Seleccionar Filtros"}
    onClose={props.onClose}
    dialogProps={{ ...props }}>
    {props.children}
  </CustomDialog>
);

const FilterDialogTitle: React.FC = (props) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.dialogTitleBar}>
      {props.children}
    </DialogTitle>
  );
};

const FilterDialogContent: React.FC = (props) => {
  const classes = useStyles();
  return (
    <DialogContent className={classes.dialogContent}>
      <Grid container>{props.children}</Grid>
    </DialogContent>
  );
};

interface FilterActionsProps {
  onDelete: () => void;
  onApply: () => void;
  deleteTitle?: string;
  applyTitle?: string;
  classes?: {
    container?: string;
    deleteButton?: string;
    applyButton?: string;
  };
}

const FilterDialogActions: React.FC<FilterActionsProps> = ({
  applyTitle,
  deleteTitle,
  onApply,
  onDelete,
  classes: propClasses,
}) => {
  const classes = useStyles();

  return (
    <DialogActions className={clx(classes.filterButtonsContainer, propClasses?.container)}>
      <Button className={propClasses?.deleteButton} onClick={onDelete}>
        {deleteTitle || "Borrar"}
      </Button>
      <CustomButton className={propClasses?.applyButton} active onClick={onApply}>
        {applyTitle || "Aplicar"}
      </CustomButton>
    </DialogActions>
  );
};

const FilterItem: React.FC<FilterItemProps> = ({
  label,
  children,
  container = true,
  spacing = 0,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.filterWrap}>
      <Grid item xs={12} sm={6} className={classes.labelContainer}>
        <Typography component="h3" className={classes.filterLabel}>
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container={container}
        spacing={spacing}
        className={classes.filterContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

const FilterTextField: React.FC<TextFieldProps> = (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      margin="dense"
      type="number"
      inputProps={{
        className: classes.textFieldInput,
        classes: { notchedOutline: classes.textFieldInputOutline },
      }}
      {...props}
    />
  );
};

FilterDialog.Item = FilterItem;
FilterDialog.Title = FilterDialogTitle;
FilterDialog.Content = FilterDialogContent;
FilterDialog.Actions = FilterDialogActions;
FilterDialog.TextField = FilterTextField;

export default FilterDialog;
