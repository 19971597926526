import moment from "moment";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import { ActionType, Action, Dispatch } from "./demandLeadTypes";

import {
  Lead,
  ResActivities,
  Activity,
  ResSchedules,
  Schedule,
  ModifyLeadForm,
  UpdateActivityForm,
  EditLeadBody,
  Profiling,
  ResProfiling,
} from "models/DemandLead";
import { IProperty } from "models";
import { Paginate } from "models/ResponseWithPaginate";

import demandService from "services/demandService";
import propertyService from "services/propertyService";

import { parseActivity, parsePyTupple, getUniqueProperties } from "./demandLeadUtils";

export const setLead = (lead: Lead): Action => ({
  type: ActionType.SET_LEAD,
  lead,
});

export const setViewedProperties = (viewedProperties: IProperty[]): Action => ({
  type: ActionType.SET_VIEWED_PROPERTIES,
  viewedProperties,
});

export const setProfiling = (profiling: Profiling): Action => ({
  type: ActionType.SET_PROFILING,
  profiling,
});

export const setRenderProfileForm = (renderForm: boolean): Action => ({
  type: ActionType.SET_RENDER_PROFILE_FORM,
  renderProfileForm: renderForm,
});

export const setActivities = (activities: Activity[]): Action => ({
  type: ActionType.SET_ACTIVITIES,
  activities,
});

export const setSchedules = (schedules: Schedule[]): Action => ({
  type: ActionType.SET_SCHEDULES,
  schedules,
});

export const setIsLoading = (isLoading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  isLoading,
});

export const setIsOpenReviewInformationModal = (open: boolean): Action => ({
  type: ActionType.SET_OPEN_REVIEW_INFORMATION_MODAL,
  isOpenReviewInformationModal: open,
});

export const setIsOpenSuccessModal = (open: boolean): Action => ({
  type: ActionType.SET_OPEN_SUCCESS_MODAL,
  isOpenSuccessModal: open,
});

export const setIsOpenErrorModal = (open: boolean): Action => ({
  type: ActionType.SET_OPEN_ERROR_MODAL,
  isOpenErrorModal: open,
});

export const setIsOpenActivityForm = (open: boolean): Action => ({
  type: ActionType.SET_OPEN_ACTIVITY_FORM,
  isOpenActivityForm: open,
});

export const setCompleteActivityConfig = (open: boolean, activity: Activity): Action => ({
  type: ActionType.SET_COMPLETED_ACTIVITY_CONFIG,
  completedActivity: { isOpenModal: open, currentActivity: activity },
});

export const setIsOpenSaveInformationModal = (open: boolean): Action => ({
  type: ActionType.SET_OPEN_SAVE_INFORMATION_MODAL,
  isOpenSaveInformationModal: open,
});

export const handleCloseButtonActivityModal = (dispatch: Dispatch) => {
  dispatch(setIsOpenActivityForm(false));
};

export const getLead = async (dispatch: Dispatch, id: string, token: string) => {
  dispatch(setIsLoading(true));
  try {
    const res = (await demandService.getLead(id, token)) as AxiosResponse<Lead>;

    const resActivity = (await demandService.getLeadActivity(
      res.data?.lead?.id,
      token
    )) as AxiosResponse<ResActivities>;

    dispatch(setLead(res.data));
    dispatch(setActivities(parseActivity(resActivity.data.table_data)));
    dispatch(setIsLoading(false));
  } catch {
    Swal.fire("Error", "No se han podido obtener los datos del lead.", "error");
    dispatch(setIsLoading(false));
  }
};

export const getProfiling = async (dispatch: Dispatch, leadId: number, token) => {
  try {
    dispatch(setIsLoading(true));
    const resProfiling = (await demandService.getLeadProfiling(
      leadId,
      token
    )) as AxiosResponse<ResProfiling>;

    const {
      property_state,
      property_type,
      purchase_type,
      rate_type,
      type_of_occupation,
      temporality,
      financing,
      city,
      ...rest
    } = resProfiling.data.lead_profile;

    dispatch(
      setProfiling({
        ...rest,
        financing,
        city,
        temporality: parsePyTupple(temporality),
        property_state: parsePyTupple(property_state),
        property_type: parsePyTupple(property_type),
        purchase_type: parsePyTupple(purchase_type),
        rate_type: parsePyTupple(rate_type),
        type_of_occupation: parsePyTupple(type_of_occupation),
      })
    );
    dispatch(setIsLoading(false));
  } catch {
    dispatch(setRenderProfileForm(true));
    dispatch(setIsLoading(false));
  }
};

export const getViewedProperties = async (
  activities: Activity[] = [],
  dispatch: Dispatch,
  token: string
) => {
  try {
    const idList: number[] = getUniqueProperties(activities);

    const properties = (await propertyService.getPropertiesByIds(idList, token, {
      page_size: 10,
    })) as AxiosResponse<Paginate<IProperty>>;

    dispatch(setViewedProperties(properties.data.results));
  } catch {
    Swal.fire("Error", "No se han podido obtener las propiedades vistas.", "error");
  }
};

export const updateProfiling = async (
  dispatch: Dispatch,
  leadId: number,
  profilingId: number,
  data,
  token: string
) => {
  try {
    //delete empty string values
    const filteredBody = Object.keys(data).reduce((prev, currentKey) => {
      if (data[currentKey] || data[currentKey] === 0) {
        return { ...prev, [currentKey]: data[currentKey] };
      }
      return prev;
    }, {});
    const res = await demandService.updateLeadProfiling(token, {
      ...filteredBody,
      lead_id: leadId,
      id_lead_profile: profilingId,
    });
    if (res.data) {
      dispatch(setIsOpenSuccessModal(true));
    }
  } catch {
    dispatch(setIsOpenErrorModal(true));
  }
};

export const modifyConctactLead = async (
  dispatch: Dispatch,
  data: ModifyLeadForm,
  leadId: number,
  token: string
) => {
  dispatch(setIsOpenReviewInformationModal(true));
  try {
    const body = {
      lead_id: leadId,
      email: data.email,
      comment: data.comment,
      phone: data.phone.parsedPhone,
      name: `${data.name} ${data.lastName}`,
      executive_id: data.executive.value,
      qualification: data.qualification,
    };

    //delete empty string values
    const filteredBody = Object.keys(body).reduce((prev, currentKey) => {
      if (body[currentKey]) {
        return { ...prev, [currentKey]: body[currentKey] };
      }
      return prev;
    }, {}) as EditLeadBody;

    await demandService.modifyLead(filteredBody, token);

    dispatch(setIsOpenReviewInformationModal(false));
    dispatch(setIsOpenSuccessModal(true));
  } catch {
    dispatch(setIsOpenReviewInformationModal(false));
    dispatch(setIsOpenErrorModal(true));
  }
};

//activities

export const updateQualification = async (
  dispatch: Dispatch,
  activityId: number,
  qualification: number,
  token: string
) => {
  try {
    dispatch(setIsOpenReviewInformationModal(true));
    const res = await demandService.qualifyLeadActivity(
      { lead_activity_id: activityId, qualification },
      token
    );
    if (res.data) {
      dispatch(setIsOpenReviewInformationModal(false));
      return true;
    }
  } catch {
    dispatch(setIsOpenReviewInformationModal(false));
    return false;
  }
};

export const completeLastActivity = async (dispatch: Dispatch, leadId: number, token: string) => {
  try {
    const res = await demandService.completeLastActivity(leadId, token);
    if (res) {
      const resActivity = (await demandService.getLeadActivity(
        leadId,
        token
      )) as AxiosResponse<ResActivities>;
      if (res) {
        dispatch(setActivities(parseActivity(resActivity.data.table_data)));
        dispatch(setIsOpenSuccessModal(true));
      }
    }
  } catch {
    dispatch(setIsOpenErrorModal(true));
  }
};

export const updateActivity = async (
  dispatch: Dispatch,
  activityId: number,
  lead: Lead,
  data: UpdateActivityForm,
  token: string
) => {
  try {
    let res;
    switch (data.mode) {
      case "Perdido":
        res = await demandService.lostLeadActivity(
          {
            lead_activity_id: activityId,
            reason: parseInt(data?.reason?.value),
            comments: data.comments,
          },
          token
        );
        break;

      default:
        {
          const inputDateTime = `${data.date} ${data.time}`;

          const dateInUtC = moment(inputDateTime).utc().format("YYYY-MM-DD HH:mm:ss");

          const body = {
            origin: data.origin.value,
            comment: data.comments,
            expired_date: dateInUtC,
            name_activity_id: parseInt(data.activityName.value),
            lead_activity_id: activityId,
          };
          res = await demandService.createLeadActivity(body, token);
        }
        break;
    }
    if (res.data) {
      dispatch(setIsOpenSuccessModal(true));
      const resActivity = (await demandService.getLeadActivity(
        lead?.lead.id,
        token
      )) as AxiosResponse<ResActivities>;

      dispatch(setActivities(parseActivity(resActivity.data.table_data)));

      dispatch(setIsOpenActivityForm(false));
    }
  } catch {
    dispatch(setIsOpenErrorModal(true));
  }
};
// schedules

const parseSchedule = (schedule: Schedule[]) =>
  schedule.map((schedule) => ({
    ...schedule,
    id: schedule.index,
    toAgend: schedule.visit_state === "No Agendada",
  }));

export const getSchedules = async (
  dispatch: Dispatch,
  email: string,
  phone: string,
  token: string
) => {
  dispatch(setIsLoading(true));
  try {
    const res = (await demandService.getLeadSchedules(
      email,
      phone,
      token
    )) as AxiosResponse<ResSchedules>;
    dispatch(setSchedules(parseSchedule(res.data.visits)));
    dispatch(setIsLoading(false));
  } catch {
    Swal.fire("Error", "No se ha podido obtener la agenda de este lead.", "error");
    dispatch(setIsLoading(false));
  }
};
