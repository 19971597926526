import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "sm";

const useSubmitButton = makeStyles((theme: Theme) => ({
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
  ctaButton: {
    padding: theme.spacing(1, 0),
    borderRadius: 8,
    fontSize: "1rem",
    lineHeight: 1.25,
    textTransform: "none",
    width: 130,
    height: 40,
  },
  ctaApply: {
    color: "white",
  },
}));

export default useSubmitButton;
