import { Typography } from "@material-ui/core";

import FormBlock from "./FormBlock";
import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";
import { useProperty } from "context/propertyContext/propertyContext";

function InvestmentType() {
  const { state } = useProperty();
  const { property } = state;

  return (
    <FormBlock>
      <Typography variant="h6">Seleccione el tipo de propiedad</Typography>
      <ControlledSelection
        id="investment_type"
        defaultValue={property?.investment_type ?? "regular"}
        options={[
          { label: "Regular", value: "regular" },
          { label: "Multifamily", value: "multifamily" },
        ]}
      />
    </FormBlock>
  );
}

export default InvestmentType;
