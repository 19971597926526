import {
  Action,
  SET_LOADING,
  CHANGE_COUNTRY,
  SET_LANDLORD_VIP,
  SET_LANDLORD_VIP_FILTER,
  LandlordVipFilter,
  Dispatch,
} from "context/landlordVipContext/landlordVipTypes";
import { Country } from "models/Countries";
import subscriptionService from "services/subscriptionService";
import { parseLandlordVip } from "./utils";

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setCountry = (country: Country): Action => ({
  type: CHANGE_COUNTRY,
  country,
});

export const setFilters = (filters: LandlordVipFilter): Action => ({
  type: SET_LANDLORD_VIP_FILTER,
  filters,
});

export const getLandlordVipList = async (
  pageSize: number,
  currentPage: number,
  country: string,
  tableStates: any,
  authToken: string,
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_LANDLORD_VIP,
    payload: { landlordVipList: [], count: 0 },
  });

  dispatch(setLoading(true));
  const response = await subscriptionService.getLandlordsVip(
    pageSize,
    currentPage,
    country,
    tableStates.executiveEmail,
    authToken
  );
  const {
    data: { count, results },
  } = response;
  const landlordVipList = results.map((vipData) => parseLandlordVip(vipData));
  dispatch({ type: SET_LANDLORD_VIP, payload: { landlordVipList, count } });
  dispatch(setLoading(false));
};
