import { makeStyles, Theme } from "@material-ui/core";

const useClientsHeaderStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(32),
    lineHeight: 1.25,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useClientsHeaderStyles;
