import { Locale } from "models/Countries";

import useMovements from "./useMovements";

const usePayments = (country: Locale, subId: number) => {
  const {
    data: incomeData,
    isLoading: isLoadingIncome,
    isError: isErrorIncome,
  } = useMovements(country, subId, "income");
  const {
    data: outflowData,
    isLoading: isLoadingOutflow,
    isError: isErrorOutflow,
  } = useMovements(country, subId, "outflow");

  const concatData = () => outflowData.concat(incomeData);

  return {
    movements: incomeData && outflowData ? concatData() : [],
    isLoading: isLoadingOutflow || isLoadingIncome,
    isErrorIncome,
    isErrorOutflow,
  };
};

export default usePayments;
