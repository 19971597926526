import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "libs/alerts";

import useGetToken from "hooks/useGetToken";
import debtCollectionService from "services/debtCollectionService";

import { IDebtCollectionUpdateInsuranceStatusBody } from "./utils/types";

export default function useChangeDebtCollectionInsuranceStatus(onFinish: () => void) {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (data: IDebtCollectionUpdateInsuranceStatusBody) => {
      const authToken = await getToken();
      const body = {
        ...data,
        contract_id: data.contractId,
        company_code: data.companyCode,
      };
      const response = await debtCollectionService.updateInsuranceStatus(authToken, body);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDebtCollections");
        onFinish();
        alert.success({
          message: "La información se actualizó exitosamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo actualizar la información",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
}
