import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";
import FieldTextArea from "@houm-com/ui/FieldTextArea";
import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import { ContractModel } from "models/Contract";
import { useFormNotifications } from "hooks";
import { countryCode } from "utils";

import { contractSchema, requiredText } from "../validationSchema";
import { formEditContractDefaultValues } from "./utils/utils";
import useEditContract from "./hooks/useEditContract";
import classes from "./FormEditContractStyles";
import { ContractProps } from "./utils/types";
import EditContractInfo from "./EditContractInfo";

interface Props {
  contract: ContractModel;
  handleClose: () => void;
}

function FormEditContract({ contract, handleClose }: Props) {
  const country = countryCode(contract.property.country);
  const { editContract, submitting: isLoading } = useEditContract(country, contract, handleClose);
  const [moneyType, setMoneyType] = useState(contract.type_money);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const methods = useForm<ContractProps>({
    resolver: yupResolver(contractSchema(country, moneyType)),
    reValidateMode: "onBlur",
    defaultValues: formEditContractDefaultValues(contract, country),
  });
  const { errors } = methods.formState;
  useFormNotifications<ContractProps>({ errors, requiredText });

  const [typeMoney, canonAmount, commonExpenses] = methods.watch([
    "typeMoney",
    "canonAmount",
    "commonExpenses",
  ]);

  useEffect(() => {
    setMoneyType(typeMoney);
  }, [typeMoney]);

  useEffect(() => {
    if (contract.owner?.tributary_type === "corporative")
      methods.setValue("billingDocument", "Factura");
  }, []);

  const onSubmit: SubmitHandler<ContractProps> = async (data) => {
    editContract({ data });
  };

  const onHandleInfoModalOpen = () => setIsInfoModalOpen(true);
  const onHandleInfoModalClose = () => setIsInfoModalOpen(false);

  const handleAcceptInfoModal = () => {
    onHandleInfoModalClose();
    methods.handleSubmit(onSubmit)();
  };

  const handleSubmitButtonClick = () => {
    if (country === "cl") {
      onHandleInfoModalOpen();
    } else {
      methods.handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form className={classes.formContent}>
          <FieldInput
            id="habitantsDescription"
            label="¿Cuántos vivirán en la propiedad?"
            placeholder="Ej: 2 adultos y 1 niño"
            errorMessage={errors.habitantsDescription?.message}
            {...methods.register("habitantsDescription")}
          />
          <FieldSelect
            id="billingDocument"
            label="Documento de comprobante de pago"
            defaultValue={contract.billing_document}
            disabled={contract.owner?.tributary_type === "corporative"}
            options={[
              { value: "Boleta", label: "Boleta (con IVA)" },
              { value: "Factura", label: "Factura (sin IVA)" },
            ]}
            errorMessage={errors.billingDocument?.message}
            {...methods.register("billingDocument")}
          />
          <FieldInput
            id="signatureDate"
            label="Fecha firma contrato"
            type="date"
            errorMessage={errors.signatureDate?.message}
            {...methods.register("signatureDate")}
          />
          <FieldInput
            id="initDateProposal"
            label="Fecha inicio de contrato propuesto"
            type="date"
            errorMessage={errors.initDateProposal?.message}
            {...methods.register("initDateProposal")}
          />
          <FieldInput
            id="propertyDeliveryDate"
            label="Fecha de entrega propiedad"
            type="date"
            errorMessage={errors.propertyDeliveryDate?.message}
            {...methods.register("propertyDeliveryDate")}
          />
          {country === "cl" && (
            <FieldSwitchSelector
              id="typeMoney"
              label="Tipo de moneda"
              defaultValue={contract.type_money || ""}
              options={[
                { value: "pesos", label: "Pesos" },
                { value: "uf", label: "UF" },
              ]}
              errorMessage={errors.typeMoney?.message}
              {...methods.register("typeMoney")}
            />
          )}
          <FieldInput
            id="canonAmount"
            label="Canon de arriendo"
            placeholder="Ingresar canon de arriendo, ej: 150000"
            type="number"
            errorMessage={errors.canonAmount?.message}
            {...methods.register("canonAmount")}
          />
          {country !== "cl" && (
            <FieldInput
              id="commonExpenses"
              label="Gastos comunes"
              placeholder="Ingresar valor gastos comunes, sin no están incluidos ingresar 0"
              type="number"
              errorMessage={errors.commonExpenses?.message}
              {...methods.register("commonExpenses")}
            />
          )}
          <FieldSwitchSelector
            id="hasHoumCleaning"
            label="¿Limpieza gestionada por Houm?"
            options={[
              { value: "true", label: "Si" },
              { value: "false", label: "No" },
            ]}
            errorMessage={errors.hasHoumCleaning?.message}
            {...methods.register("hasHoumCleaning")}
          />
          <FieldTextArea
            id="observations"
            label="Observaciones"
            placeholder="Escribir observación..."
            errorMessage={errors.observations?.message}
            {...methods.register("observations")}
          />
          <div className={classes.submitButton}>
            <Button
              variant="primary"
              size="md"
              disabled={isLoading}
              onClick={handleSubmitButtonClick}
              type="button">
              Guardar
            </Button>
          </div>
        </form>
      </FormProvider>
      <ModalDialog
        isOpen={isInfoModalOpen}
        title="Atención"
        onClose={onHandleInfoModalClose}
        hasCloseBtn>
        <EditContractInfo
          canonAmount={canonAmount}
          commonExpenses={commonExpenses}
          typeMoney={typeMoney}
          country={country}
          contractCountry={countryCode(contract.property.country)}
          handleCloseModal={onHandleInfoModalClose}
          handleAcceptInfoModal={handleAcceptInfoModal}
        />
      </ModalDialog>
    </>
  );
}

export default FormEditContract;
