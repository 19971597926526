import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  rightButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
