import { ReactChild } from "react";
import clx from "classnames";

import { Chip } from "@material-ui/core";

import useTagsStyles from "./TagsStyles";

interface Props {
  content: ReactChild;
  textColor: string;
  backColor: string;
  tagStyles?: string;
}

function BaseTag({ content, textColor, backColor, tagStyles }: Props) {
  const classes = useTagsStyles({ textColor, backColor });

  return <Chip className={clx(classes.chip, tagStyles)} label={content} />;
}

function RedTag(props) {
  return <BaseTag textColor="#900000" backColor="#EFCDCC" {...props} />;
}

function YellowTag(props) {
  return <BaseTag textColor="#A07800" backColor="#FAF3CC" {...props} />;
}

function GreenTag(props) {
  return <BaseTag textColor="#439F03" backColor="#DAEECC" {...props} />;
}

function WhiteTag(props) {
  return <BaseTag textColor="#FF866A" backColor="#F7F8FC" {...props} />;
}

function BlackWhiteTag(props) {
  return <BaseTag textColor="#607D8B" backColor="#D5E3F8" {...props} />;
}

function LightOrangeTag(props) {
  return <BaseTag textColor="#FF866A" backColor="#FEE9E8" {...props} />;
}

function BlueTag(props) {
  return <BaseTag textColor="#2663BC" backColor="#E0F1FF" {...props} />;
}

function LightBlueTag(props) {
  return <BaseTag textColor="#607D8B" backColor="#E0F1FF" {...props} />;
}

function OrangeTag(props) {
  return <BaseTag textColor="#FF452B" backColor="#FFCBBF" {...props} />;
}

function GreyTag(props) {
  return <BaseTag textColor="white" backColor="#00982D" {...props} />;
}

function LightGreenTag(props) {
  return <BaseTag textColor="#00982D" backColor="var(--green-alert)" {...props} />;
}

export {
  RedTag,
  YellowTag,
  GreenTag,
  LightGreenTag,
  GreyTag,
  OrangeTag,
  BlueTag,
  LightOrangeTag,
  WhiteTag,
  BlackWhiteTag,
  LightBlueTag,
};
