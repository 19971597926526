const NoncomplianceText = "No cumple con todos los requisitos Houm/";

const contractDetailDiscardInfo = {
  authors: ["Propietario", "Aseguradora", "Arrendatario", "Houm"],
  desistReasons: {
    Houm: ["Otro"],
    Propietario: ["Desiste por problemas personales/salud/económicos", "Otro"],
    Arrendatario: [
      "Desiste antes de ser aprobado el perfil",
      "Desiste por problemas personales/salud/económicos",
      "Propietario demora en aceptar perfil de postulante",
      "Propietario cambia condiciones",
      "Propietario se demora en aceptar perfil",
      "Otro",
    ],
    Aseguradora: ["Rechazado por la aseguradora", "Rechazado Codeudor por la aseguradora", "Otro"],
  },
  rejectReasons: {
    Houm: ["Otro"],
    Propietario: [
      "Propietario arrienda la propiedad por fuera",
      "Perfil rechazado por el propietario",
      "Propietario cambia condiciones",
      "Perfil rechazado por el propietario por discriminación",
      "Otro",
    ],
    Arrendatario: ["Otro"],
    Aseguradora: ["Otro"],
  },
};

const contractGenerateDiscardInfo = {
  authors: ["Propietario", "Arrendatario", "Houm"],
  desistReasons: {
    Houm: ["Otro"],
    Propietario: ["Otro"],
    Arrendatario: [
      "Propietario cambia las condiciones del contrato",
      "Postulante cambia las condiciones del contrato",
      "Postulante se quedó sin trabajo",
      "Postulante consiguió propiedad más económica",
      "No se está de acuerdo con el tipo de reajuste",
      "Demora en respuesta del propietario",
      "App de houmer indicaba que tenía estacionamiento y o bodega y no la incluía",
      "Propietario no acepta mascotas y en publicación o app de houmer indica que si se permite",
      "Houmer no deja claras las condiciones de Houm",
      "No aprueba el contrato",
      "Se solicitaron reparaciones y no se realizaron",
      "Se solicita cambio de fecha en el contrato y no se aceptó",
      "Se arrienda sin visita previa y, al verla,  no estaban de acuerdo con el estado de la propiedad",
      "Nunca se le avisó de la aprobación y arrendó por su cuenta",
      "Desiste por problemas personales/salud/económicos",
      "Otro",
    ],
  },
  rejectReasons: {
    Houm: ["Otro"],
    Propietario: [
      "Propietario cambia condiciones del contrato",
      "Postulante cambia condiciones del contrato",
      "Quería agregar y eliminar cláusulas importantes del contrato",
      "Venta de propiedad",
      "Propietario no está de acuerdo con la evaluación",
      "Se notificó personas adicionales después del perfil",
      "Arrendó por su cuenta o con otro corredor",
      "Se solicita cambio de fecha en el contrato y no se aceptó",
      "Contrato no notariado",
      "Desiste por problemas personales/salud/económicos",
      "Otro",
    ],
    Arrendatario: ["Otro"],
  },
};

const contractPaymentDiscardInfo = {
  authors: ["Propietario", "Arrendatario", "Houm"],
  desistReasons: {
    Houm: ["Otro"],
    Propietario: ["Otro"],
    Arrendatario: [
      "Desiste por problemas personales/salud/económicos",
      "No quedaron claras las condiciones del contrato",
      "No recibió la propiedad porque no estaba en condiciones",
      "Propiedad está en venta y arriendo",
      "Otro",
    ],
  },
  rejectReasons: {
    Houm: ["Otro"],
    Propietario: ["Otro"],
    Arrendatario: ["Otro"],
  },
};

const contractApproveDiscardInfo = {
  authors: ["Propietario", "Arrendatario", "Houm"],
  desistReasons: {
    Houm: ["Otro"],
    Propietario: ["Otro"],
    Arrendatario: ["Otro"],
  },
  rejectReasons: {
    Houm: ["Otro"],
    Propietario: ["Otro"],
    Arrendatario: ["Otro"],
  },
};

const evaluationDiscardInfo = {
  authors: ["Propietario", "Aseguradora", "Arrendatario", "Houm"],
  desistReasons: {
    Aseguradora: ["No pasó el filtro de la aseguradora", "Otro"],
    Houm: [
      "Duplicidad de reserva",
      "Error en publicación Houm",
      "Propiedad ya tiene a otro postulante aprobado que pagó reserva",
      "Propiedad arrendada con Houm a otra persona",
      "Otro",
    ],
    Propietario: [
      "Propietario rechaza perfil",
      "Propietario arriendó el inmueble con otro corredor",
      "Desiste por problemas personales/salud/económicos",
      "Propietario desiste del servicio",
      "Propietario arrienda a un familiar",
      "Incumplimiento de tiempos Houm",
      "Propietario cambia condiciones",
      "Propietario decide vender",
      "Otro",
    ],
    Arrendatario: [
      "No cargó documentos solicitados",
      "Desiste por problemas personales/salud/económicos",
      "Incumplimiento de tiempos Houm",
      "Consiguió otra propiedad",
      "Propietario cambia condiciones",
      "Reservó por error",
      "Reservó dos veces",
      "Postulante no estará en el contrato",
      "No cumple requisitos de Houm",
      "Otro",
    ],
  },
  rejectReasons: {
    Aseguradora: ["Rechazado por la aseguradora", "Rechazado Codeudor por la aseguradora", "Otro"],
    Houm: [
      `${NoncomplianceText}3X`,
      `${NoncomplianceText}Postula con boletH honorarios`,
      `${NoncomplianceText}Sólo tiene un F22`,
      `${NoncomplianceText}Contrato a plazo menor a un año`,
      `${NoncomplianceText}Incongruencia en documentos`,
      `${NoncomplianceText}Postula como empresa`,
      `${NoncomplianceText}No envía todas las liquidaciones`,
      `${NoncomplianceText}No envía contrato`,
      `${NoncomplianceText}No envía AFP`,
      "Otro",
    ],
    Propietario: ["Otro"],
    Arrendatario: ["Otro"],
  },
};

export {
  contractDetailDiscardInfo,
  contractGenerateDiscardInfo,
  contractPaymentDiscardInfo,
  contractApproveDiscardInfo,
  evaluationDiscardInfo,
};
