import { BasicServicesResponseV2, INewBasicService } from "services/propertyService/types";

import { formatCompany } from "../../../../utils/parsers";
import { ServiceForm } from "./types";

export interface BasicServiceProps {
  value: string;
  label: string;
}

export const parseBasicServices = (res: BasicServicesResponseV2[]): BasicServiceProps[] =>
  res.map((basicService) => ({
    value: basicService.name.toLocaleLowerCase(),
    label: formatCompany(basicService.name).toUpperCase(),
  }));

export const parseNewService = (data: ServiceForm, id: number): INewBasicService => ({
  company: data.company,
  property_id: id,
  client_info: { external_id: data.clientNumber.toString() },
  is_active: true,
});
