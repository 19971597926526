import { makeStyles, Theme } from "@material-ui/core";

const useDocumentsDialogStyles = makeStyles<Theme>((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
  },
  item: {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    width: "100%",
  },
  sidePanel: {
    backgroundColor: theme.palette.divider,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  selectPdf: {
    cursor: "pointer",
  },
  preview: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
}));

export default useDocumentsDialogStyles;
