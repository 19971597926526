export enum ActionType {
  SET_EXECUTIVES,
  SET_ORIGINS,
  SET_LOST_REASONS,
  SET_ACTIVITY_NAMES,
  SET_CITIES,
  SET_FINANCIAL_TYPES,
}

export interface OptionList {
  label: string;
  value: number | string;
}

export interface SetExecutives {
  type: typeof ActionType.SET_EXECUTIVES;
  executives: OptionList[];
}

export interface SetOrigins {
  type: typeof ActionType.SET_ORIGINS;
  origins: OptionList[];
}

export interface SetLostReasons {
  type: typeof ActionType.SET_LOST_REASONS;
  lostReasons: OptionList[];
}

export interface SetActivityNames {
  type: typeof ActionType.SET_ACTIVITY_NAMES;
  activityNames: OptionList[];
}
export interface SetCities {
  type: typeof ActionType.SET_CITIES;
  cities: City[];
}

export interface SetFinancialTypes {
  type: typeof ActionType.SET_FINANCIAL_TYPES;
  financialTypes: OptionList[];
}

export interface City extends OptionList {
  communes: OptionList[];
}

export interface State {
  executives: OptionList[];
  origins: OptionList[];
  lostReasons: OptionList[];
  activityNames: OptionList[];
  financialTypes: OptionList[];
  cities: City[];
}

export type Action =
  | SetExecutives
  | SetOrigins
  | SetLostReasons
  | SetActivityNames
  | SetFinancialTypes
  | SetCities;

export type Dispatch = (action: Action) => void;
