import * as yup from "yup";
import moment from "moment";

export const requiredText = "Obligatorio";
export const invalid = "El valor ingresado no es válido";
export const long = "El comentario puede tener un máximo de 255 caracteres";
const invalidDate = "Fecha de vencimiento debe ser en el futuro";

export default yup.object({
  createdAt: yup.string().required(requiredText),
  endDate: yup
    .string()
    .required(requiredText)
    .test("validDate", invalidDate, (value, { parent }) => {
      const formattedEndDate = moment(value);
      const formattedCreatedAt = moment(parent.createdAt);
      return formattedCreatedAt.isBefore(formattedEndDate);
    }),
  property: yup.string().nullable(),
  contactMedium: yup.string().nullable(),
  subject: yup.string().nullable(),
  detail: yup.string().max(255, long).typeError(invalid),
});
