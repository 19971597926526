import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { MarkAsPaidFormProps } from "../utils/types";

export function formatDate(date: string) {
  return format(new Date(date), "yyyy-MM-dd");
}
const useMarkAsPaid = (country: Locale, movementId: string, onCloseModal?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess } = useMutation(
    async (formData: MarkAsPaidFormProps) => {
      const authToken = await getToken();
      const data = {
        method: formData.method,
        payment: formData.amount,
        date: formatDate(formData.paymentDate),
      };
      const response = await newPaymentService.markAsPaidMovement(
        country,
        movementId,
        data,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        onCloseModal?.();
        alert.success({
          message: "Orden de pago actualizada",
          disclaimer: "La orden de pago se ha marcado como pagado correctamente",
        });
      },
      onError: (e: AxiosError) => {
        const error = e as AxiosError;
        const reason = error.response?.data?.message;
        alert.error({
          message: "Error al actualizar orden de pago",
          disclaimer: `No se pudo actualizar la orden de pago, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useMarkAsPaid;
