import { useHistory } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@houm-com/ui/DropdownMenu";
import { cn } from "@houm-com/ui/utils";

import useToggleActivePlan from "hooks/usePlans/useToggleActivePlan";
import { IParsePlan } from "hooks/usePlans/utils/types";

import classes from "./CardActionsStyles";

interface Props {
  plan: IParsePlan;
}

export default function CardActions({ plan }: Props) {
  const history = useHistory();
  const { toggleActivePlan } = useToggleActivePlan();

  const handleToggleActive = () => toggleActivePlan({ plan });

  const handleGetDetails = () => {
    const cityName = plan.cities.map((city) => city.name);
    const cities = `cities=${cityName.join(",")}`;
    history.push(`/admin/plans/show/${plan.id}?country=${plan.country}&${cities}`);
  };

  return (
    <div className={classes.root}>
      <div
        className={cn(
          classes.activityDot,
          plan.active ? classes.activityDotActive : classes.activityDotInactive
        )}
      />
      <DropdownMenu>
        <DropdownMenuTrigger>
          <button aria-controls="simple-menu" aria-haspopup="true" className={classes.actionDot}>
            +
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <div onClick={handleGetDetails}>Ver detalle</div>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <div onClick={handleToggleActive}>{plan?.active ? "Desactivar" : "Activar"}</div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
