import { useMutation, useQueryClient } from "react-query";
import { format } from "date-fns";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface SpotPaymentData {
  paymentDate: string;
  reasonType: string;
  amount: number;
  descriptionAux?: string;
  currency?: CurrencyCode;
}

const useSpotPayment = ({
  country,
  propertyId,
  subscriptionId,
  userId,
  onHandleClose,
}: {
  country: Locale;
  propertyId: number;
  userId: number;
  subscriptionId: number;
  onHandleClose?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: SpotPaymentData) => {
      const authToken = await getToken();
      const parsedData = {
        amount: formData.amount,
        currency: formData.currency,
        date: format(new Date(formData.paymentDate), "yyyy-MM-dd"),
        description:
          formData.reasonType === "Otro" ? `${formData.descriptionAux}` : formData.reasonType,
        property_id: propertyId,
        person_id: userId,
        subscription_id: subscriptionId,
      };
      const response = await newPaymentService.createSpotPayment(country, parsedData, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        alert.success({
          message: "Pago spot creado",
          disclaimer: "El pago spot  ha sido creado exitosamente",
        });
        onHandleClose?.();
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: "Error al crear pago",
          disclaimer: `El pago spot no han podido ser creado, error: ${reason}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useSpotPayment;
