import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { ICreateReservation } from "../utils/types";

const useCreateReservation = (country: Locale) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: ICreateReservation) => {
      const authToken = await getToken();
      const response = await newPaymentService.createReservation(country, formData, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reservationUserById");
        alert.success({
          message: "La reserva se ha creado exitosamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo crear la reserva",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useCreateReservation;
