import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  title: { alignSelf: "flex-start", fontWeight: 700 },
  viewMoreButton: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontWeight: 700,
    marginTop: theme.spacing(4),
  },
}));
