import { QuotationModel, Subscription } from "models/Subscription";
import { UserModel } from "models/User";
import { docTypesCL, docTypesCO, docTypesMX } from "services/documentTypeService/constants";

export const getDocumentType = (documentType: number) => {
  const documents = [...docTypesCL, ...docTypesCO, ...docTypesMX];
  return documents.find((doc) => doc.id === documentType)?.type;
};

export const parsePlanData = (subscription: Subscription, quotation: QuotationModel) => {
  const plan = subscription?.plan ?? quotation?.plan;
  return {
    description: plan?.description,
    country: plan?.country,
    discount: quotation?.discount,
    commission: plan?.admin_commission ?? plan?.price,
    typeMoney: subscription?.contract?.type_money,
    planType: plan?.type,
    totalPlan: quotation?.plan_total,
    monthlyPlan: quotation?.plan_mensual,
  };
};

export const parseDocumentType = (user: UserModel): number =>
  typeof user.document_type === "number" ? user.document_type : user.document_type?.id;

export const removeRepeatedNames = (fullName: string) => {
  const words = fullName.split(" ");
  const uniqueWords = [...new Set(words)];
  return uniqueWords.join(" ");
};
