import { ReactNode } from "react";

import { Typography } from "@material-ui/core";

import useStyles from "./InspectionReportCardStyles";

interface Props {
  title?: string;
  children: ReactNode;
}

function InspectionReportCard({ title, children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default InspectionReportCard;
