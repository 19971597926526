import ModalDialog from "@houm-com/ui/ModalDialog";

interface Props {
  isOpen: boolean;
  handleOpen: () => void;
  startDesertionProcess: () => void;
}

const StartDesertionProcessModal = ({ isOpen, handleOpen, startDesertionProcess }: Props) => (
  <ModalDialog
    isOpen={isOpen}
    title="¿Seguro que quieres iniciar el proceso de término de contrato?">
    <ModalDialog.Actions
      onClose={handleOpen}
      onSubmit={() => {
        startDesertionProcess();
        handleOpen();
      }}
    />
  </ModalDialog>
);

export default StartDesertionProcessModal;
