import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    width: "100%",
  },
}));
