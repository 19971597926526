import { Grid } from "@material-ui/core";

import Button from "@houm-com/ui/Button";

interface Props {
  handleOpenDialog: (e: string) => void;
}

const MovementButtons = ({ handleOpenDialog }: Props) => (
  <Grid item xs={6} container justifyContent="flex-end" spacing={1}>
    <Grid item>
      <Button
        variant="tertiary"
        type="button"
        size="sm"
        onClick={() => handleOpenDialog("paymentOrder")}>
        Crear orden de pago
      </Button>
    </Grid>
    <Grid item>
      <Button variant="tertiary" type="button" size="sm" onClick={() => handleOpenDialog("spot")}>
        Pagos spot
      </Button>
    </Grid>
  </Grid>
);

export default MovementButtons;
