import { useFormContext, useWatch } from "react-hook-form";

import {
  ControlledSelection,
  ControlledNumberField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useProperty } from "context/propertyContext/propertyContext";
import { generalOptions } from "utils/createProperty";
import { useMemo } from "react";
import { rentalMinMaxValues } from "utils/yupValidators/propertyPriceValidators";
import { generatePriceWarningMessage } from "Components/Admin/UIComponents/PropertyForm/utils/priceWarningMessage";

interface Props {
  country: string;
}

function ForRentalPrice({ country }: Props) {
  const { state } = useProperty();
  const { control } = useFormContext();

  const currencyOptions = useMemo(() => generalOptions[country]?.currency || [], [country]);

  const currencyCode = useWatch({
    control,
    name: "tipo_moneda",
    defaultValue: state.property?.property_details[0]?.tipo_moneda,
  });

  const minMaxValues = rentalMinMaxValues?.[country]?.[currencyCode];

  return (
    <div>
      <ControlledSelection
        id="tipo_moneda"
        label="Tipo de moneda para renta"
        defaultValue={state.property?.property_details[0]?.tipo_moneda || "Pesos"}
        options={currencyOptions}
      />
      <ControlledNumberField
        id="valor"
        label={generalOptions[country]?.labels.rentPrice || ""}
        defaultValue={state.property?.property_details[0]?.valor}
        placeholder={generalOptions[country]?.placeholders.rentPrice}
        numberFieldProps={{
          prefix: "$",
        }}
        warn={(fieldValue) =>
          Number(fieldValue) > minMaxValues?.max || Number(fieldValue) < minMaxValues?.min
        }
        warningMessage={generatePriceWarningMessage(minMaxValues, currencyCode)}
      />
      <ControlledNumberField
        id="promotional_price"
        label={`${generalOptions[country].labels.rentPrice} en promoción`}
        defaultValue={state.property?.property_details[0]?.promotional_price}
        placeholder={generalOptions[country]?.placeholders.rentPrice}
        numberFieldProps={{
          prefix: "$",
        }}
      />
      <ControlledNumberField
        id="promotion_months"
        label="Duración de la promoción (en meses)"
        defaultValue={state.property?.property_details[0]?.promotion_months}
        placeholder="4"
      />
    </div>
  );
}

export default ForRentalPrice;
