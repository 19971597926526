import { Grid } from "@material-ui/core";

import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import PropertyServices from "Components/Admin/UIComponents/PropertyServices";
import { QuotationModel, Subscription } from "models/Subscription";
import { useTab } from "context/tabContext/tabContext";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { countryCode } from "utils";

import ContractEndCard from "../ExitProcess/components/ContractEndCard";
import AdditionalSubDetails from "./components/AdditionalSubDetails";
import ExitProcessCard from "./components/ExitProcessCard";
import PlatformUser from "./components/PlatformUser";
import PropertyCard from "./components/PropertyCard";
import { parsePlanData } from "../../utils/utils";
import Documents from "./components/Documents";
import PlanCard from "./components/PlanCard";

interface Props {
  quotation: QuotationModel;
  subscription: Subscription;
}

const Summary = ({ quotation, subscription }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="summary">
      <Grid container alignItems="flex-start" justifyContent="space-around" spacing={2}>
        <Grid item xs={12}>
          <PlatformUser
            userLandlord={subscription?.property?.user}
            userTenant={subscription?.tenant}
          />
        </Grid>
        <Grid item container xs={12} md={7}>
          <Grid container spacing={2}>
            <PlanCard plan={parsePlanData(subscription, quotation)} />
            <AdditionalSubDetails
              contract={subscription?.contract}
              hasBrokerage={subscription?.contract?.has_brokerage ?? quotation.corretaje}
            />
            <ExitProcessCard contractId={subscription?.contract?.id} />
            <ProtectedComponent
              require={["management_admin", "super_admin"]}
              scope="roles"
              strategy="any">
              <ContractEndCard contractId={subscription?.contract?.id} />
            </ProtectedComponent>
            {subscription?.property?.country !== "Mexico" && (
              <Grid item xs={12}>
                <PropertyServices
                  propertyId={subscription?.property?.id}
                  country={countryCode(subscription?.property?.country)}
                  variant="commonExpenses"
                  backgroundTitle={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5}>
          <Grid container spacing={2}>
            <PropertyCard property={subscription?.property} />
            {subscription?.property?.country === "Colombia" && (
              <Documents propertyId={subscription?.property?.id} />
            )}
            <Grid item xs={12}>
              <PropertyServices
                propertyId={subscription?.property?.id}
                country={countryCode(subscription?.property?.country)}
                variant="basicServices"
                backgroundTitle={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default Summary;
