import { useState, useEffect } from "react";
import { Button, Divider, Typography } from "@material-ui/core";

import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";

import Autocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";
import {
  setFilters,
  setIsOpenFilterModal,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import { MFStatus } from "../utils/constants";
import { IStatusFilter } from "context/multifamilyContext/multifamily/multifamilyTypes";
import CustomTextField from "./CustomTextField";

import useFilterModalStyles from "./FilterModalStyles";
import BaseModal from "../../BaseModal";

function FilterModal() {
  const [status, setStatus] = useState<IStatusFilter | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [id, setId] = useState<number | null>(null);
  const [houmExecutive, setHoumExecutive] = useState<string | null>(null);
  const [landlord, setLandlord] = useState<string | null>(null);

  const {
    state: { isOpenFilterModal, filters },
    dispatch,
  } = useMultifamily();

  useEffect(() => {
    setStatus(filters.published);
    setName(filters.name);
    setId(filters.id);
    setHoumExecutive(filters.houm_executive);
    setLandlord(filters.landlord);
  }, []);

  const classes = useFilterModalStyles();
  const updateFilters = () => {
    dispatch(
      setFilters({
        ...filters,
        id,
        name,
        landlord,
        published: status,
        houm_executive: houmExecutive,
      })
    );
    closeModal();
  };
  const closeModal = () => dispatch(setIsOpenFilterModal(false));

  return (
    <BaseModal open={isOpenFilterModal} onClose={closeModal} showCloseButton>
      <div className={classes.mainContainer}>
        <Typography variant="h4"> Filtros de multifamily </Typography>

        <div className={classes.filtersContainer}>
          <Autocomplete onChange={setStatus} value={status} options={MFStatus} label="Estado" />
          <Divider />
          <CustomTextField onChange={setId} value={id} label="ID" type="number" />
          <Divider />
          <CustomTextField onChange={setName} value={name} label="Nombre del multifamily" />
          <Divider />
          <CustomTextField onChange={setLandlord} value={landlord} label="Nombre del contacto" />
          <Divider />
          <CustomTextField
            onChange={setHoumExecutive}
            value={houmExecutive}
            label="Nombre del asesor"
          />
          <Divider />
        </div>
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={updateFilters}>
            Aplicar filtros
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
export default FilterModal;
