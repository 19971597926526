import { UseQueryResult, useQuery } from "react-query";

import { IParsedContractTemplate } from "models/ContractTemplate";
import templatesService from "services/templatesService";
import useGetToken from "hooks/useGetToken";
import { countryCode } from "utils";

const useGetTemplates = () => {
  const { getToken } = useGetToken();

  const {
    data: templates,
    isLoading,
    isError,
  }: UseQueryResult<IParsedContractTemplate[], Error> = useQuery<IParsedContractTemplate[], Error>(
    ["getTemplates"],
    async () => {
      const token = await getToken();
      const response = await templatesService.getTemplates(token);

      return response.data.map((template) => ({
        id: template.id,
        name: template.name,
        country: countryCode(template.country),
      }));
    }
  );

  return {
    templates,
    isLoading,
    isError,
  };
};

export default useGetTemplates;
