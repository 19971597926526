import DetailsItem from "../DetailsItem";

type ObservationsProps = {
  observations?: String;
  title: String;
};

export default function Observations({ observations, title }: ObservationsProps) {
  return (
    <DetailsItem title={title}>
      <span>{observations ?? "Sin observaciones"}</span>
    </DetailsItem>
  );
}
