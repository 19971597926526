import { useQuery, UseQueryResult } from "react-query";

import newPaymentService from "services/newPaymentService";
import useGetToken from "hooks/useGetToken";
import { IWarranty } from "models/Warranty";
import { Locale } from "models/Countries";

import parseWarranty from "./utils/parsers";

const useWarranty = (contractId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, isError, data }: UseQueryResult<IWarranty, Error> = useQuery<IWarranty, Error>(
    ["contract_warranty", contractId],
    async () => {
      const authToken = await getToken();
      const response = await newPaymentService.getWarranty(country, contractId, authToken);
      return parseWarranty(response.data);
    },
    {
      enabled: !!contractId,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useWarranty;
