import { CancelReasons, ICancelReason, ISchedule, IScheduleResponse } from "./types";

export const parseCancelReasons = (cancelReasons: ICancelReason[]): CancelReasons =>
  cancelReasons.reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: current.text,
    }),
    {}
  );

export const parseSchedules = (schedules: IScheduleResponse[]): ISchedule[] =>
  schedules.map((schedule) => ({
    id: schedule.id,
    status: schedule.status,
    beginDate: schedule.begin_date,
    comment: schedule.comment,
    appraiser: schedule.appraiser,
    modifier: schedule.modifier
      ? {
          name: schedule.modifier.name,
          lastName: schedule.modifier.last_name,
          role: schedule.modifier.role,
        }
      : null,
    scheduleType: schedule.schedule_type,
    feedback: schedule.feedback,
    applicant: schedule.applicant,
    selectedCancelReason: schedule.selected_cancel_reason
      ? {
          type: schedule.selected_cancel_reason.type,
          typeText: schedule.selected_cancel_reason.type_text,
          comment: schedule.selected_cancel_reason.comment,
        }
      : null,
  }));
