import { AxiosResponse } from "axios";
import qs from "query-string";

import { createHttp } from "services/helper";
import { Paginate } from "models/ResponseWithPaginate";
import { IndexCompaniesResponse } from "./types";

export default {
  indexCompaniesRequest(
    { ...filters }: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<Paginate<IndexCompaniesResponse>>> {
    return createHttp(token).get(`/v2/companies/?${qs.stringify(filters)}`);
  },
};
