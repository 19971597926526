import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import contractService from "services/contractService";
import { ContractModel } from "models/Contract";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

import { parseEditContractRequestBody } from "../utils/utils";
import { ContractProps, IFormData } from "../utils/types";

interface EditContractProps {
  data: ContractProps;
}

const useEditContract = (country: Locale, contract: ContractModel, onSuccess: () => void) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate: editContract, isLoading: submitting } = useMutation(
    async ({ data }: EditContractProps) => {
      const formData: IFormData = parseEditContractRequestBody(data, country);
      const authToken = await getToken();
      await contractService.update(contract.id, formData, authToken);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contract_data");
        onSuccess();
        alert.success({
          message: "Contrato actualizado",
          disclaimer: "El contrato ha sido modificado exitosamente",
        });
      },
      onError: (error: AxiosError<{ message: string }>) => {
        alert.error({
          message: "Error al actualizar contrato",
          disclaimer: `No se pudo actualizar contrato, Error: ${error.message}`,
        });
      },
    }
  );
  return { editContract, submitting };
};

export default useEditContract;
