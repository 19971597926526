import { Box, Container, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";

import useModifyServiceModalStyles from "./ModifyServiceModalStyles";

interface Props {
  handleClose: () => void;
  onSubmitData: () => void;
  submitServiceLoading: boolean;
}

function ModifyServiceModal({ handleClose, onSubmitData, submitServiceLoading }: Props) {
  const classes = useModifyServiceModalStyles();

  return (
    <Box className={classes.deleteBasicServiceRoot}>
      <Container className={classes.titleContainer}>
        <WarningIcon />
        <Typography className={classes.title}>
          ¿Estás seguro que quieres cambiar la información?
        </Typography>
      </Container>
      <Container className={classes.textContainer}>
        <Typography className={classes.text}>
          Una vez que edites esta información estos cambios se verán reflejados en la sección
          correspondiente.
        </Typography>
      </Container>
      <Box className={classes.buttons}>
        <DesignSystemButton
          variant="secondary"
          size="small"
          label="Cancelar"
          onClick={handleClose}
          buttonClassName={classes.cancelButton}
        />
        <DesignSystemButton
          variant="primary"
          size="small"
          label="Confirmar"
          type="submit"
          onClick={() => onSubmitData()}
          isLoading={submitServiceLoading}
        />
      </Box>
    </Box>
  );
}

export default ModifyServiceModal;
