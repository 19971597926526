import { makeStyles } from "@material-ui/core";

const useConciergeDataStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  gridItemContainer: {
    padding: 0,
  },
  formInput: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(1),
    borderRadius: 5,
    "&.Mui-disabled": {
      borderColor: theme.palette.grey[200],
    },
  },
  divider: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  collapseContainer: {
    width: "100%",
  },
  label: {
    color: theme.palette.grey[900],
  },
  phoneInputContainer: {
    marginTop: theme.spacing(1),
  },
}));
export default useConciergeDataStyles;
