import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldTextArea from "@houm-com/ui/FieldTextArea";

import { PromotionFormData } from "../../../../utils/types";
import { classes } from "../SpecificDiscountsStyles";

interface Props {
  headless?: boolean;
}

const TenantBrokerageDiscount = ({ headless = false }: Props) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<PromotionFormData>();

  return (
    <div className={classes.container}>
      {!headless && (
        <div className={classes.header}>
          <p className={classes.title}>Descuento en corretaje</p>
          <p className={classes.tenantTag}>Arrendatario</p>
        </div>
      )}
      <FieldInput
        id="tenantBrokerageDiscount"
        label="Cantidad de descuento"
        required
        type="number"
        placeholder="0%"
        errorMessage={errors.tenantBrokerageDiscount?.message}
        form="novalidateform"
        {...register("tenantBrokerageDiscount")}
      />
      <FieldTextArea
        id="tenantObservations"
        label="Observaciones para los arrendatarios"
        subLabel="Información que verán los arrendatarios en su contrato"
        placeholder="Algo que debamos saber"
        defaultValue=""
        classNameTextArea="min-h-[120px]"
        errorMessage={errors.tenantObservations?.message}
        form="novalidateform"
        {...register("tenantObservations")}
      />
    </div>
  );
};

export default TenantBrokerageDiscount;
