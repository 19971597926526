import { useQuery, UseQueryResult } from "react-query";

import schedulesService from "services/schedulesService";
import { useGetToken } from "hooks";

const useAppraisers = () => {
  const { getToken } = useGetToken();

  const {
    isLoading: appraisersLoading,
    error: appraisersError,
    data: appraisersData = [],
  }: UseQueryResult<any, Error> = useQuery(["allAppraisers"], async () => {
    const authToken = await getToken();
    const response = await schedulesService.getAppraisers(authToken);
    return response.data.map((appraiser) => ({
      value: appraiser.id,
      label: appraiser.name,
    }));
  });

  return { appraisersLoading, appraisersError, appraisersData };
};

export default useAppraisers;
