import { GridColDef, GridColumns } from "@material-ui/x-grid";

import { formatDate } from "../../../utils/utils";
import BaseField from "../../BaseField";
import PriorityField from "../components/PriorityField";
import SimpleTagField from "../components/SimpleTagField";
import StatusField from "../components/StatusField";
import TicketsField from "../components/TicketsField";
import { priorityData, statusData } from "./constants";

const baseColumnsConfig: GridColDef = {
  field: "",
  resizable: true,
  filterable: false,
  sortable: false,
  width: 120,
};

export const columnsConfig: GridColumns = [
  {
    ...baseColumnsConfig,
    headerName: "N° Interacciones",
    field: "interactions",
    width: 80,
    renderCell: (params) => (
      <BaseField requiredData={params.row.interactions}>{params.row.interactions}</BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Prioridad",
    field: "priority",
    width: 92,
    renderCell: (params) => <PriorityField {...priorityData(params.row.priority)} />,
  },
  {
    ...baseColumnsConfig,
    headerName: "Estado",
    field: "status",
    width: 80,
    renderCell: (params) => <StatusField {...statusData(params.row.status)} />,
  },
  {
    ...baseColumnsConfig,
    headerName: "Última actividad",
    field: "updatedAt",
    width: 100,
    renderCell: (params) => (
      <BaseField requiredData={params.row.updatedAt}>
        {formatDate(params.row.updatedAt, "dd / MM / yyyy")}
      </BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Creación",
    field: "createdAt",
    width: 100,
    renderCell: (params) => (
      <BaseField requiredData={params.row.createdAt}>
        {formatDate(params.row.createdAt, "dd / MM / yyyy")}
      </BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Área",
    field: "assignedTeam",
    renderCell: (params) => (
      <BaseField requiredData={params.row.assignedTeam}>{params.row.assignedTeam}</BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Responsable",
    field: "assignedTo",
    renderCell: (params) => (
      <BaseField requiredData={params.row.assignedTo}>{params.row.assignedTo}</BaseField>
    ),
  },
  {
    ...baseColumnsConfig,
    headerName: "Property",
    field: "propertyStatus",
    flex: 1,
    renderCell: (params) => <SimpleTagField value={params.row.propertyStatus} />,
  },
  {
    ...baseColumnsConfig,
    headerName: "Cobranza",
    field: "chargesStatus",
    renderCell: (params) => <SimpleTagField value={params.row.chargesStatus} />,
  },
  {
    ...baseColumnsConfig,
    headerName: "Kustomer",
    field: "url",
    width: 80,
    renderCell: (params) => <TicketsField url={params.row.url} />,
  },
];
