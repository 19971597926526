import { isExternalBroker, isProject } from "utils/userRoles";

export const getPropertyTitle = (property, locale) => {
  const { association_amenities: amenities } = property;
  let title = property?.address;
  if (locale === "cl") {
    if (isExternalBroker(property?.user.role)) {
      title = property?.comuna;
    } else {
      title = property?.address;
      const shortAddress = title.replace(/(Calle )|(Avenida )/, "");
      if (shortAddress.length >= 12) {
        title = shortAddress;
      }
    }
  } else {
    title = property?.neighborhood?.neighborhood?.split(",")[0];
  }
  if (isProject(property?.user.role) && amenities?.association_name !== null) {
    title = amenities?.association_name;
  }
  return title;
};
