import { Grid } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";

import useGetExitProcess from "Components/Admin/administrator/subscriptions/hooks/useGetExitProcess";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import ExitProcessCallToAction from "./components/ExitProcessCallToAction";
import ExitProcessStartModal from "./components/ExitProcessStartModal";
import ExitProcessInfo from "./components/ExitProcessInfo";
import ConfirmModal from "./components/ConfirmModal";
import useModal from "./hooks/useModal";
import ExitProcessEditAgreedDateModal from "./components/ExitProcessEditAgreedDateModal/ExitProcessEditAgreedDateModal";

interface Props {
  contractId: number;
}

const ExitProcessCard = ({ contractId }: Props) => {
  const { exitProcess, isLoading } = useGetExitProcess(contractId);
  const {
    actionText,
    openConfirmActionModal,
    handleCloseConfirmModal,
    handleOpenConfirmActionModal,
    openStartProcessModal,
    handleStartProcessModal,
    openEditDateModal,
    handleOpenEditDateModal,
  } = useModal();

  return (
    <Grid item xs={12}>
      <AdminCard title="Gestionar proceso de salida" withSpace={false} showTitleBackground={true}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Grid container spacing={2}>
            {exitProcess.status !== "NOT_STARTED" && <ExitProcessInfo exitProcess={exitProcess} />}
            <ExitProcessCallToAction
              exitProcessState={exitProcess.status}
              handleOpenConfirmActionModal={handleOpenConfirmActionModal}
              handleOpenStartProcessModal={handleStartProcessModal}
              handleOpenEditDateModal={handleOpenEditDateModal}
            />
          </Grid>
        )}
        <ExitProcessStartModal
          setClose={handleStartProcessModal}
          isOpen={openStartProcessModal}
          exitProcess={exitProcess}
        />
        <ConfirmModal
          setClose={handleCloseConfirmModal}
          isOpen={openConfirmActionModal}
          actionText={actionText}
          exitProcess={exitProcess}
        />
        <ExitProcessEditAgreedDateModal
          setClose={handleOpenEditDateModal}
          isOpen={openEditDateModal}
          exitProcess={exitProcess}
        />
      </AdminCard>
    </Grid>
  );
};

export default ExitProcessCard;
