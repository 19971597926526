import { camelCase, isArray, transform, isObject } from "lodash";

export const objectToCamelCase = <T extends Record<string, unknown>>(
  obj: Record<string, unknown>
): T =>
  transform(obj, (result: Record<string, unknown>, value: unknown, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    result[camelKey] = isObject(value)
      ? objectToCamelCase<T>(value as Record<string, unknown>)
      : value;
  }) as T;
