import { ReactElement } from "react";

import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { EvaluationStatus } from "domains/evaluations/models/Evaluations";

interface Props {
  requiredPermissions: string[];
  children: ReactElement;
  evaluationStatus: EvaluationStatus;
  strategy?: "all" | "any";
}

const ProtectComponent = ({
  children,
  requiredPermissions,
  evaluationStatus,
  strategy = "all",
}: Props) => {
  if (evaluationStatus === "approved" || evaluationStatus === "rejected") return null;

  return (
    <ProtectedComponent scope="permissions" strategy={strategy} require={requiredPermissions}>
      {children}
    </ProtectedComponent>
  );
};

export default ProtectComponent;
