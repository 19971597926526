import { ReactElement, useState } from "react";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import UserForm from "Components/Admin/UIComponents/UserForm";
import { useProperty } from "context/propertyContext/propertyContext";
import { setNewUser, setUserModal } from "context/propertyContext/propertyActions";
import { useGetToken } from "hooks";
import { UserFormType } from "models/User";

function NewUserDialog(): ReactElement {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProperty();
  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");

  const handleSubmit = (user: UserFormType | undefined) => {
    if (!user) return;
    setStatus("loading");
    getToken().then(async (authToken) => {
      await setNewUser(user, authToken, dispatch);
      setStatus("success");
    });
  };
  const closeModal = () => {
    dispatch(setUserModal(false));
  };

  return (
    <CustomDialog open={state.openUserModal} onClose={closeModal} title="Crear propietario">
      <UserForm onSubmit={(e) => handleSubmit(e)} disabled={status === "loading"} />
    </CustomDialog>
  );
}

export default NewUserDialog;
