import { makeStyles, Theme } from "@material-ui/core";

const useContractDetailsStyles = makeStyles<Theme>((theme) => ({
  cardContent: {
    gap: theme.spacing(2),
    display: "grid",
  },
}));

export default useContractDetailsStyles;
