/* eslint-disable react/require-default-props */
import { useEffect, useState } from "react";
import { Select, MenuItem, InputBase, SelectProps, InputBaseProps } from "@material-ui/core";

import useStyles from "./SelectionOptionsStyles";

import OptionButton from "./OptionButton";

type Value = string | number | boolean | null;
export interface Option {
  value: Value;
  label: string;
}
interface Props {
  compressed?: boolean;
  defaultValue?: Value;
  id: string;
  onChange: Function;
  options: Option[];
  simple?: boolean;
  value: Value;
  width?: string;
  fullWidth?: boolean;
  round?: boolean;
  rectangleRounded?: boolean;
  disabled?: boolean;
  classes?: SelectProps["classes"];
  emptyValue?: string | number;
  buttonClassName?: string;
  inputBaseClasses?: InputBaseProps["classes"];
  acceptNull?: boolean;
}

function SelectionOptions({
  compressed,
  defaultValue,
  id,
  onChange,
  options,
  simple = false,
  value,
  width,
  classes: propClasses,
  fullWidth = false,
  round = false,
  rectangleRounded = false,
  emptyValue = "0",
  disabled = false,
  buttonClassName,
  inputBaseClasses,
  acceptNull = true,
}: Props) {
  const [valueState, setValueState] = useState<Value>(value);
  const classes = useStyles({ fullWidth });
  useEffect(() => {
    setValueState(value);
  }, [value]);

  useEffect(() => {
    if (defaultValue) {
      setValueState(defaultValue);
      onChange(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (value) {
      setValueState(value);
    }
  }, [value]);

  const onChangeHandle = (option, curr) => {
    if (acceptNull) {
      if (option.value === curr) {
        setValueState(null);
        onChange(null);
      } else {
        setValueState(option.value);
        onChange(option.value);
      }
    } else {
      setValueState(option.value);
      onChange(option.value);
    }
  };

  if (simple)
    return (
      <Select
        id={id}
        value={value}
        onChange={(e) => {
          onChangeHandle(e.target, value);
        }}
        input={<InputBase classes={inputBaseClasses} />}
        className={classes.input}
        disabled={disabled}
        classes={propClasses}>
        <MenuItem key="empty" value={emptyValue}>
          Seleccione
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={String(option.value)} value={String(option.value)}>
            {option.label.replaceAll("_", " ")}
          </MenuItem>
        ))}
      </Select>
    );

  return (
    <>
      {options.map((o) => (
        <OptionButton
          width={width}
          key={`${id}-${o.value}`}
          compressed={compressed}
          disabled={o.value !== valueState}
          round={round}
          rectangleRounded={rectangleRounded}
          onClick={() => onChangeHandle(o, valueState)}
          size="small"
          buttonClassName={buttonClassName}>
          {o.label}
        </OptionButton>
      ))}
    </>
  );
}

export default SelectionOptions;
