import {
  State,
  Action,
  SET_LOADING,
  SET_PROPERTIES,
  CHANGE_COUNTRY,
  CHANGE_MARKET,
} from "./propertiesTypes";

export const initialState: State = {
  loading: false,
  properties: [],
  numberOfProperties: null,
  country: "chile",
  market: "sales",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_PROPERTIES: {
      return {
        ...state,
        loading: false,
        properties: action.payload.properties,
        numberOfProperties: action.payload.count,
      };
    }
    case CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    case CHANGE_MARKET: {
      return { ...state, market: action.market };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
