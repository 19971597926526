import { IoChevronDownOutline } from "@houm-com/ui-icons";
import { cn } from "@houm-com/ui/utils";

import { Chip } from "@material-ui/core";

import useStatusStyles from "./StatusStyles";
import { getStatusData } from "../../utils";

interface StateProps {
  status: string;
  onClick?: (e) => void;
  id?: string;
  open?: boolean;
}

function State({ status, onClick, id, open }: StateProps) {
  const statusData = getStatusData(status);
  const flagOnClick = !!onClick;
  const classes = useStatusStyles({ color: statusData.color, flagOnClick });
  const anchorEl = document.getElementById(id);

  return onClick ? (
    <Chip
      label={statusData.statusLabel}
      className={classes.chip}
      id={id}
      onClick={(e) => !!onClick && onClick(e.currentTarget)}
      clickable
      onDelete={() => (onClick ? onClick(anchorEl) : false)}
      deleteIcon={
        <IoChevronDownOutline
          id={id}
          style={{ color: `${statusData.color}` }}
          className={cn("!h-[18px] !w-[18px]", [{ "rotate-180": open }])}
        />
      }
    />
  ) : (
    <Chip label={statusData.statusLabel} className={classes.chip} id={id} />
  );
}

export default State;
