import { makeStyles, Theme } from "@material-ui/core";

const useAdvisoryResponseInfo = makeStyles((theme: Theme) => ({
  advisoryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(2),
    margin: theme.spacing(0, 4, 2, 4),
  },
}));

export default useAdvisoryResponseInfo;
