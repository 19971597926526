import { Grid } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Button from "@houm-com/ui/Button";
import { buttonContainerStyles } from "./usePropertyInformationStyles";
import { AvailabilityForm } from "context/availabilityContext/availabilityTypes";
import DailySchedule from "./DailySchedule";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import { parseStringToDay } from "utils/datetime";
import { getTimezone } from "utils";
import usePropertySchedule from "../hooks/usePropertySchedule";

function PropertySchedule() {
  const { state } = useAvailability();

  const methods = useForm<AvailabilityForm>();

  const { mutate: updatePropertySchedule, isLoading: loadingSchedule } = usePropertySchedule();

  const onSubmit: SubmitHandler<AvailabilityForm> = (data, e) => {
    const updateData = Object.entries(data)
      .filter((item) => item[1])
      .map((elem) =>
        parseStringToDay(elem, getTimezone(state.propertyInfo.country, state.propertyInfo.location))
      );
    updatePropertySchedule({ id: state.propertyId, schedule: updateData });
  };

  return (
    <FormProvider {...methods} key="AvailabilityProvider">
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-around">
          <Grid xs={1}>
            <DailySchedule day="Lunes" index={1} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Martes" index={2} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Miércoles" index={3} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Jueves" index={4} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Viernes" index={5} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Sábado" index={6} />
          </Grid>
          <Grid xs={1}>
            <DailySchedule day="Domingo" index={7} />
          </Grid>
        </Grid>
        <div className={buttonContainerStyles}>
          <Button
            variant="secondary"
            type="submit"
            size="md"
            disabled={loadingSchedule}
            isLoading={loadingSchedule}>
            Actualizar horarios
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default PropertySchedule;
