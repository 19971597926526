import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SearchOutlined } from "@material-ui/icons";

import useSearchbarStyles from "./SearchbarStyles";

type Props = Omit<React.ComponentProps<typeof Autocomplete>, "renderInput">;
function Searchbar(props: Props) {
  const classes = useSearchbarStyles();
  return (
    <Autocomplete
      {...props}
      className={classes.autocomplete}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder={props.placeholder ? props.placeholder : "Buscar"}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchOutlined />,
            classes: {
              notchedOutline: classes.outline,
            },
          }}
        />
      )}
    />
  );
}
export default Searchbar;
