import { makeStyles } from "@material-ui/core";

export default makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    minHeight: 60,
    maxHeight: 60,
  },
  title: { fontSize: 33, fontWeight: "bold", marginTop: 10 },
  buttonContainer: {},
});
