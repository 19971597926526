import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";

import useStyles from "./CommentsStyles";

interface Props {
  comment: string;
  commenter: {
    name: string;
    last_name: string;
  };
  date: string;
  commentType: String;
}

function Comment({ comment, commenter, date, commentType }: Props) {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">
            {commenter.name} {commenter.last_name}{" "}
          </Typography>
          <Typography className={classes.leftMargin}>-</Typography>
          <Typography className={classes.leftMargin} variant="caption">
            {`${moment(date).format("DD/MM/YYYY HH:mm")} (${moment(date).fromNow()})`}
          </Typography>
          {commentType && <Chip label={commentType} className={classes.leftMargin} />}
        </Box>
        <Typography>{comment}</Typography>
      </CardContent>
    </Card>
  );
}

export default Comment;
