import { Box, Button, Typography } from "@material-ui/core";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import useEmptyValueStyles from "./EmptyValueStyles";
import EmptyDataTag from "../EmptyDataTag";

interface Props {
  onClick?: () => void;
}

const EmptyValue = ({ onClick }: Props) => {
  const classes = useEmptyValueStyles();

  return (
    <Box className={classes.emptyValueContainer}>
      <EmptyDataTag />
      <Button
        classes={{
          root: classes.buttonContainer,
          label: classes.label,
        }}
        onClick={onClick}>
        <Typography className={classes.text}>Agregar</Typography>
        <PlusIcon className={classes.plusIcon} />
      </Button>
    </Box>
  );
};

export default EmptyValue;
