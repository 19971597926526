import { makeStyles, Theme } from "@material-ui/core";

const useTablePaginationStyles = makeStyles((theme: Theme) => ({
  paginationWrapper: {
    marginTop: theme.spacing(3),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useTablePaginationStyles;
