import { useMemo } from "react";

import { GridColDef, GridColumns } from "@material-ui/x-grid";
import Typography from "@material-ui/core/Typography";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import CustomLink from "Components/Admin/UIComponents/CustomLink";
import Toolbar from "./Toolbar";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { businessTypeTranslation } from "Components/Admin/administrator/integrations/utils/translations";
import useStyles from "Components/Admin/administrator/integrations/components/Table/TableStyles";
import { PublicationLinkInformation } from "models/Integration";
import PoperCell from "Components/Admin/administrator/properties/show/tabs/scheduler/PoperCell";
import useCurrentTableStyles from "./TableStyles";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "left",
  sortable: false,
};

function Table() {
  const classes = useStyles();
  const currentTableStyles = useCurrentTableStyles();
  const { state } = useIntegration();
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "property",
        headerName: "ID Propiedad",
        renderCell: ({ row }) => (
          <CustomLink to={`/admin/properties/${row?.propertyUid ?? ""}`} text={row?.propertyId} />
        ),
        sortable: true,
        flex: 0.8,
      },
      {
        ...columnsConfig,
        field: "business_line",
        headerName: "Línea de negocio",
        renderCell: ({ row }) => businessTypeTranslation[row?.businessType],
      },
      {
        ...columnsConfig,
        field: "portal",
        headerName: "Portal",
        renderCell: ({ row }) => row?.portalName,
      },
      {
        ...columnsConfig,
        field: "portal_plan",
        headerName: "Destaque",
        renderCell: ({ row }) => row?.portalPlan,
        flex: 0.7,
      },
      {
        ...columnsConfig,
        field: "executive",
        headerName: "Ejecutivo",
        renderCell: ({ row }) => row?.houmOwnerName,
      },
      {
        ...columnsConfig,
        field: "link",
        headerName: "Links",
        renderCell: ({ row, colDef }) =>
          row?.publicationLinks.length ? (
            <PoperCell
              value={
                <div className={currentTableStyles.linksContainer}>
                  {row.publicationLinks.map((publicationLink: PublicationLinkInformation) => (
                    <div className={currentTableStyles.link}>
                      <CustomLink
                        navigation={false}
                        to={publicationLink.portalUrl}
                        target="_blank"
                        text={publicationLink.portalName}
                        align="left"
                      />
                    </div>
                  ))}
                </div>
              }
              width={colDef.computedWidth}
            />
          ) : (
            "No disponible"
          ),
        flex: 1.3,
      },
      {
        ...columnsConfig,
        field: "status",
        headerName: "Estado",
        renderCell: ({ row }) => (
          <Typography className={classes.longText}>{row?.status}</Typography>
        ),
        flex: 2,
      },
    ],
    [state.portalPublications.data]
  );

  return (
    <div className={classes.container}>
      <CustomDataGrid
        className={classes.table}
        rowCount={state.portalPublications.count}
        columns={columns}
        rows={state.portalPublications.data}
        loading={state.portalPublications.loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;
