import clx from "classnames";

import { Typography, TypographyProps } from "@material-ui/core";

import useModalTextStyles from "./ModalTextStyles";

function ModalText({ children, className, ...props }: TypographyProps) {
  const classes = useModalTextStyles();

  return (
    <Typography className={clx(classes.modalTextRoot, className)} {...props}>
      {children}
    </Typography>
  );
}

export default ModalText;
