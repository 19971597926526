import { Dispatch, SetStateAction, useEffect } from "react";

import Table from "./partials/Table";
import FilterOptions from "./partials/FilterOptions";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { IndexQuotaByMacrozoneFilters } from "services/integrationService/types";

interface Props {
  filters: IndexQuotaByMacrozoneFilters;
  setFilters: Dispatch<SetStateAction<IndexQuotaByMacrozoneFilters>>;
}

function QuotaByMacrozone({ filters, setFilters }: Props) {
  const { state } = useIntegration();
  const { state: tableStates } = useTable();
  const { ordering, tableFilters, pageSize, currentPage } = tableStates;

  useEffect(() => {
    const allFilters: IndexQuotaByMacrozoneFilters = {
      ...filters,
      business_type: tableFilters.businessType,
      country: state.country,
      city: tableFilters.city,
      macrozone: tableFilters.macrozone,
      portal: tableFilters.portal,
      page: currentPage,
      page_size: pageSize,
      publication_type: tableFilters.portalPlan,
      search: tableFilters.search_query,
      ordering,
    };
    setFilters(allFilters);
  }, [state.country, ordering, currentPage, pageSize, tableFilters]);

  return (
    <>
      <Table />
      <FilterOptions setCurrentFilters={setFilters} />
    </>
  );
}

export default QuotaByMacrozone;
