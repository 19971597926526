import React from "react";

import { ControlledGroupCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import { ROLES } from "context/controlTower/controlTowerConsts";
import useRoleFieldsStyles from "./RoleFieldsStyles";

function RoleField() {
  const classes = useRoleFieldsStyles();
  return (
    <div className={classes.roleContainer}>
      <ControlledGroupCheckbox defaultValues={[]} label="Roles" id="roles" options={ROLES} />
    </div>
  );
}
export default React.memo(RoleField);
