import clx from "classnames";

import { Typography, TypographyProps } from "@material-ui/core";

import useModalBoldStyles from "./ModalBoldStyles";

function ModalBold({ children, className, ...props }: TypographyProps) {
  const classes = useModalBoldStyles();

  return (
    <Typography className={clx(classes.modalBoldRoot, className)} {...props}>
      {children}
    </Typography>
  );
}

export default ModalBold;
