import { makeStyles, Theme } from "@material-ui/core/styles";

const useEditContractModalStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  submitButton: {
    margin: theme.spacing(2),
    transition: "0.5s",
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
      transition: "0.5s",
    },
  },
  listedFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spinner: {
    margin: theme.spacing(3),
    width: "inherit",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useEditContractModalStyles;
