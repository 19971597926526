import Searchbar from "Components/Admin/UIComponents/molecules/Searchbar";
import { setFilters } from "context/multifamilyContext/multifamily/multifamilyActions";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { Multifamilies } from "context/multifamilyContext/multifamily/multifamilyTypes";
import { useHistory } from "react-router-dom";

function SearchMultiFamily() {
  const history = useHistory();
  const { dispatch, state } = useMultifamily();

  const handleSearch = (val) => {
    dispatch(
      setFilters({
        ...state.filters,
        search: val,
      })
    );
  };

  return (
    <Searchbar
      options={state?.multifamilies}
      value={undefined}
      loading={state?.isLoadingDataTable}
      onInputChange={(e, val) => handleSearch(val)}
      placeholder="Búsqueda"
      onChange={(e, val: Multifamilies) => history.push(`/admin/multifamily/${val?.id}`)}
      getOptionLabel={(option: any) => `ID: ${option?.id} - ${option?.name}`}
    />
  );
}

export default SearchMultiFamily;
