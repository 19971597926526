import {
  RedTag,
  GreenTag,
  WhiteTag,
  LightBlueTag,
  LightGreenTag,
} from "Components/Admin/UIComponents/InsuranceTags/Tags";

type StatusProps = {
  hasIcon?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

const noStatus = (props: StatusProps) => ({
  name: "Seleccionar",
  tag: <WhiteTag content="Seleccionar" hasIcon {...props} />,
});

const clStatuses = (props: StatusProps) => ({
  ACTIVE: {
    name: "Activo",
    tag: <GreenTag content="Activo" {...props} />,
  },
  CANCELED: {
    name: "Anulado",
    tag: <LightGreenTag content="Anulado" {...props} />,
  },
  FINISHED: {
    name: "Finalizado",
    tag: <LightBlueTag content="Finalizado" {...props} />,
  },
  REJECTED: {
    name: "Rechazado",
    tag: <RedTag content="Rechazado" {...props} />,
  },
});

const coStatuses = (props: StatusProps) => ({
  ACTIVE: {
    name: "Activo",
    tag: <GreenTag content="Activo" {...props} />,
  },
  FINISHED: {
    name: "Terminado",
    tag: <LightGreenTag content="Terminado" {...props} />,
  },
  DESISTED: {
    name: "Desistido",
    tag: <GreenTag content="Desistido" {...props} />,
  },
  REJECTED: {
    name: "Rechazado",
    tag: <RedTag content="Rechazado" {...props} />,
  },
});

export default {
  cl: clStatuses,
  co: coStatuses,
  noStatus,
};
