import React, { useEffect, useState } from "react";
import { Tabs } from "@material-ui/core";

import useStyles from "./CustomTabsStyles";
import TabPanel from "./TabPanel";
import { CustomTab } from "./CustomTab";

interface TabContentProps {
  header: string;
  component: React.ReactNode;
}

interface CustomTabsProps {
  tabsContent: TabContentProps[];
  tabsProps?: Partial<React.ComponentProps<typeof Tabs>>;
  aria?: string;
  customIndex?: {
    id: number;
    isActive: boolean;
  };
  scrollClasses?: string;
}

function CustomTabs({
  tabsContent,
  tabsProps = {},
  aria = "simple",
  customIndex = null,
  scrollClasses = null,
}: CustomTabsProps) {
  const [currentTab, setCurrentTab] = useState<number>(customIndex?.id || 0);
  const [isActive, setIsActive] = useState<boolean>(customIndex?.isActive || false);
  const classes = useStyles();

  useEffect(() => {
    if (customIndex && customIndex.isActive !== isActive) {
      setCurrentTab(customIndex.id);
      setIsActive(customIndex.isActive);
    }
  }, [customIndex]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  tabsProps["classes"] = {
    ...tabsProps.classes,
    indicator: classes.indicator,
    root: scrollClasses,
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label={`${aria}-tabs`}
        variant="scrollable"
        scrollButtons="on"
        textColor="primary"
        indicatorColor="primary"
        {...tabsProps}>
        {tabsContent.map((tab) => (
          <CustomTab label={tab.header} key={tab.header} />
        ))}
      </Tabs>
      <div className="mt-4">
        {tabsContent.map((tab, index) => (
          <TabPanel value={currentTab} index={index} key={tab.header} aria={aria}>
            {tab.component}
          </TabPanel>
        ))}
      </div>
    </>
  );
}

export default CustomTabs;
