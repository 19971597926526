import { makeStyles, Theme } from "@material-ui/core";

const useBuyInformationStyles = makeStyles((theme: Theme) => ({
  currencySelector: {
    borderColor: theme.palette.grey[50],
    maxWidth: 130,
    fontSize: theme.typography.pxToRem(14),
    marginRight: "auto",
  },
}));

export default useBuyInformationStyles;
