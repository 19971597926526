import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  rowRoot: {
    backgroundColor: "#FEE9E8",
  },
  container: {
    marginTop: 0,
  },
  label: {
    display: "none",
  },
  buttonList: {
    marginTop: 0,
  },
  bold: {
    fontWeight: 700,
  },
  editIcon: {
    color: theme.palette.primary.main,
  },
}));
