import Tag from "Components/Admin/administrator/demand/components/Tag";
import { useContract } from "context/contractContext/contractContext";
import { setFilters } from "context/contractContext/contractActions";
import useTagbarStyles from "./TagbarStyles";

function TagBar() {
  const classes = useTagbarStyles();
  const {
    state: { filters },
    dispatch,
  } = useContract();
  const handleRemove = (filter: string) => {
    dispatch(
      setFilters({
        ...filters,
        [filter]: null,
      })
    );
  };
  return (
    <div className={classes.tagsContainer}>
      {filters.init_date && (
        <Tag onRemove={() => handleRemove("init_date")}>{filters.init_date}</Tag>
      )}
      {filters.houm_owner && (
        <Tag onRemove={() => handleRemove("houm_owner")}>{filters.houm_owner}</Tag>
      )}
    </div>
  );
}

export default TagBar;
