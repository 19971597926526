import useFilterBarStyles from "./FilterBarStyles";
import SearchVisit from "./SearchVisit";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { setIsIsOpenFilterModal } from "context/demandScheduledVisitsContext/demandScheduledActions";
import CustomButtonGray from "Components/Admin/administrator/demand/components/CustomButtonGray";

import { ReactComponent as Options } from "assets/images/options.svg";

function FilterBar() {
  const classes = useFilterBarStyles();
  const {
    state: { isOpenFilterModal },
    dispatch,
  } = useDemandScheduled();
  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        <SearchVisit />
      </div>
      <div className={classes.buttonsContainer}>
        <CustomButtonGray
          startIcon={<Options />}
          onClick={() => dispatch(setIsIsOpenFilterModal(!isOpenFilterModal))}>
          Filtrar
        </CustomButtonGray>
      </div>
    </div>
  );
}

export default FilterBar;
