import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { Box } from "@material-ui/core";

import Searchbar from "Components/Admin/UIComponents/molecules/Searchbar";

import useLandlordSearch from "./hooks/useLandlordSearch";
import ClientsHeader from "./components/ClientsHeader";
import SearchFilter from "./components/SearchFilter";
import { getSearchPlaceholder } from "./utils/searchPlaceholder";
import { getLandlordUrl } from "./utils/getLandlordUrl";

function LandlordSearch() {
  const history = useHistory();
  const { isLoading, error, data, searchQuery, setSearchQuery, searchFilter, setSearchFilter } =
    useLandlordSearch();

  if (error) {
    Swal.fire({
      type: "error",
      text: `Ha ocurrido el siguiente error: ${error}`,
    });
  }

  return (
    <Box display="flex" flexDirection="column">
      <ClientsHeader date={format(new Date(), "dd-MM-yyyy")} />

      <SearchFilter onChange={setSearchFilter} />

      <Searchbar
        filterOptions={(options, state) => options}
        color="primary"
        options={data || []}
        loading={isLoading}
        onInputChange={(e, val) => setSearchQuery(val)}
        onChange={(e, val: any) => history.push(getLandlordUrl(val?.id, searchFilter, searchQuery))}
        placeholder={getSearchPlaceholder(searchFilter)}
        getOptionLabel={(option: any) =>
          `ID: ${option?.id || option?.suscriptionID}, ${option?.fullName}, ${option?.email}`
        }
      />
    </Box>
  );
}

export default LandlordSearch;
