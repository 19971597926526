import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: theme.spacing(4),
  },
  containerColumns: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  leftColumn: {
    width: "48%",
  },
  rightColumn: {
    width: "48%",
  },
  label: {
    fontWeight: 700,
    fontSize: "1rem",
  },
  background: {
    backgroundColor: "#EFF2F3",
    borderRadius: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(1),
    width: "100%",
    "& input.MuiInputBase-input": {
      color: theme.palette.grey[500],
    },
  },
  formItem: {
    width: 260,
  },
  subtitle: {
    width: "100%",
    fontWeight: 400,
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[400],
  },
}));

export default useStyles;
