import { State, Action, ActionType } from "./similarPropertiesTypes";

export const initialState: State = {
  similarHistoricProperties: [],
  similarProperties: [],
  loading: false,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_SIMILAR_HISTORIC_PROPERTIES:
      return {
        ...state,
        similarHistoricProperties: action.similarHistoricProperties,
      };
    case ActionType.SET_LOADING:
      return { ...state, loading: action.loading };
    case ActionType.SET_SIMILAR_PROPERTIES:
      return { ...state, similarProperties: action.similarProperties };
    default:
      return state;
  }
};

export default apiReducer;
