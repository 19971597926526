import {
  State,
  Action,
  SET_FILTERS,
  SET_FORM,
  SET_TAGS,
  SET_ID_ERROR,
  SET_TAGS_TYPES,
  SET_LOADING_POST,
  SET_SHOW_SUCCESS,
  SET_SHOW_DELETE_MODAL,
} from "./tagTypes";

export const initialState: State = {
  filters: {
    id: "",
  },
  showModal: false,
  form: {
    ids: [],
    country: null,
  },
  tags: { data: [], loading: false, refetch: false },
  loadingPost: false,
  showSuccess: false,
  showDeleteModal: false,
  idError: false,
  tagTypes: [],
};

const apiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, filters: action.filters };

    case SET_FORM:
      return { ...state, form: action.form };

    case SET_TAGS:
      return {
        ...state,
        tags: {
          data: action.tags?.data ?? state.tags.data,
          loading: action.tags?.loading ?? state.tags.loading,
          refetch: action.tags?.refetch ?? state.tags.refetch,
        },
      };

    case SET_TAGS_TYPES:
      return { ...state, tagTypes: action.tagTypes };

    case SET_ID_ERROR:
      return { ...state, idError: action.idError };

    case SET_LOADING_POST:
      return { ...state, loadingPost: action.loadingPost };

    case SET_SHOW_SUCCESS:
      return { ...state, showSuccess: action.showSuccess };

    case SET_SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.showDeleteModal };

    default:
      return state;
  }
};

export default apiReducer;
