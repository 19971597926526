import { makeStyles, Theme } from "@material-ui/core";

const useRejectDesistModalStyles = makeStyles((theme: Theme) => ({
  select: {
    width: 250,
  },
  unselectedButton: {
    color: "#87a1ab",
    border: "1px solid #87a1ab",
  },
  selectedButton: {
    background: "#ffe8e8",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  leftButton: {
    marginRight: theme.spacing(1.5),
  },
  subtitle: {
    fontSize: "0.9rem",
    fontWeight: "initial",
  },
  closeButton: {
    zIndex: theme.zIndex.modal,
    color: "black",
  },
  reasonComment: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1.5),
  },
  reason: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
}));

export default useRejectDesistModalStyles;
