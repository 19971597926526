import { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useCalculator } from "context/calculatorContext/calculatorContext";
import { getSquareMeterPrice } from "context/calculatorContext/calculatorActions";
import { areValidMetrics, parseMetricsParams } from "context/calculatorContext/calculatorUtils";
import useStyles from "./SquareMeterPriceStyles";
import { numberWithCommas } from "utils";
import { useGetToken } from "hooks";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function SquareMeterPrice({ property }: Props) {
  const { state, dispatch } = useCalculator();
  const { getToken } = useGetToken();
  const classes = useStyles();

  const countryCurrencies = {
    forSale:
      property.country === "Chile"
        ? "U.F."
        : state.squareMeterPrices.for_sale?.surface_price?.currency,
    forRental:
      property.country === "Chile"
        ? "CLP"
        : state.squareMeterPrices.for_rental?.surface_price?.currency,
  };

  const formattedSalePriceNumber = numberWithCommas(
    state.squareMeterPrices.for_sale?.surface_price?.mean
  );
  const formattedSaleUnit = `${countryCurrencies.forSale} / m2`;

  const formattedRentalPriceNumber = numberWithCommas(
    state.squareMeterPrices.for_rental?.surface_price?.mean
  );
  const formattedRentalUnit = `${countryCurrencies.forRental} / m2`;

  useEffect(() => {
    const metricsParams = parseMetricsParams(property);
    if (!areValidMetrics(metricsParams)) return;
    getToken().then((authToken) => getSquareMeterPrice(metricsParams, dispatch, authToken));
  }, [property]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Valor promedio del sector</Typography>
      <Box className={classes.priceSummaryContainer}>
        <Typography className={classes.deal}>Venta:</Typography>
        <Typography className={classes.price}>{formattedSalePriceNumber}</Typography>
        <Typography className={classes.unit}>{formattedSaleUnit}</Typography>
      </Box>
      <Box className={classes.priceSummaryContainer}>
        <Typography className={classes.deal}>Arriendo:</Typography>
        <Typography className={classes.price}>{formattedRentalPriceNumber}</Typography>
        <Typography className={classes.unit}>{formattedRentalUnit}</Typography>
      </Box>
    </Box>
  );
}

export default SquareMeterPrice;
