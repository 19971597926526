import { FormControl, MenuItem, Select, Typography } from "@material-ui/core";
import useRowsCountStyles from "./RowsCountStyles";

interface Props {
  sizeOptions: number[];
  pageSize: number;
  setPageSize: (p: number) => void;
}

function RowsCount({ sizeOptions, pageSize, setPageSize }: Props) {
  const classes = useRowsCountStyles();

  return (
    <FormControl variant="outlined" className={classes.rowsCountWrapper}>
      <Typography className={classes.text}>Filas por página</Typography>
      <Select
        id="page-size-select"
        value={pageSize}
        onChange={(e) => setPageSize(e.target.value as number)}
        classes={{
          root: classes.select,
        }}>
        {sizeOptions.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RowsCount;
