import { format } from "date-fns";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";
import Button from "@houm-com/ui/Button";
import Grid from "@material-ui/core/Grid";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import useModal from "hooks/useModal";

import useStartDesertionProcess from "../../../MandateRefund/hooks/useStartDesertionProcess";
import useGetDesertionProcessInfo from "../../../MandateRefund/hooks/useGetDesertionProcessInfo";
import useCancelDesertionProcess from "../../../MandateRefund/hooks/useCancelDesertionProcess";
import StartDesertionProcessModal from "./components/StartDesertionProcessModal";
import CancelDesertionProcessModal from "./components/CancelDesertionProcessModal";

interface Props {
  contractId: number;
}

const ContractEndCard = ({ contractId }: Props) => {
  const queryClient = useQueryClient();
  const { isSuccess: desertionProcessStarted, desertionProcessInfo } =
    useGetDesertionProcessInfo(contractId);
  const {
    mutate: startDesertionProcess,
    isLoading: submitting,
    isError: startDesertionProcessError,
    isSuccess: startDesertionProcessSuccess,
  } = useStartDesertionProcess();
  const {
    cancelDesertionProcess,
    isLoading: submittingCancel,
    isError: cancelDesertionProcessError,
    isSuccess: cancelDesertionProcessSuccess,
  } = useCancelDesertionProcess();
  const { open: startProcessModalOpen, handleOpenModal: handleStartProcessModal } = useModal();
  const { open: cancelProcessModalOpen, handleOpenModal: handleCancelProcessModal } = useModal();

  useEffect(() => {
    if (startDesertionProcessError) {
      alert.error({
        message: "No se pudo iniciar el proceso de término de contrato con el propietario",
      });
    }

    if (startDesertionProcessSuccess) {
      queryClient.invalidateQueries("get-desertion-process-info");
      alert.success({
        message: "Proceso de término de contrato iniciado con éxito",
      });
    }
  }, [submitting]);

  useEffect(() => {
    if (cancelDesertionProcessError) {
      alert.error({
        message: "No se pudo cancelar el proceso de término de contrato",
      });
    }

    if (cancelDesertionProcessSuccess) {
      queryClient.invalidateQueries("get-desertion-process-info");
      alert.success({
        message: "Proceso de término de contrato cancelado con éxito",
      });
    }
  }, [submittingCancel]);

  return (
    <Grid item xs={12}>
      <AdminCard
        title="Gestionar proceso de término de contrato"
        withSpace={false}
        showTitleBackground={true}>
        <div>
          {desertionProcessStarted && (
            <p>Proceso iniciado el {format(desertionProcessInfo.startDate, "dd-MM-yyyy")}</p>
          )}
          <div className="mt-4 w-full flex justify-end gap-4">
            {desertionProcessStarted && (
              <Button
                variant="secondary"
                onClick={handleCancelProcessModal}
                isLoading={submitting}
                size="md">
                Cancelar proceso
              </Button>
            )}
            <Button
              variant="primary"
              onClick={handleStartProcessModal}
              isLoading={submitting}
              size="md"
              disabled={desertionProcessStarted}>
              Terminar contrato
            </Button>
          </div>
        </div>
        <StartDesertionProcessModal
          isOpen={startProcessModalOpen}
          startDesertionProcess={() => startDesertionProcess(contractId)}
          handleOpen={handleStartProcessModal}
        />
        <CancelDesertionProcessModal
          isOpen={cancelProcessModalOpen}
          cancelDesertionProcess={() => cancelDesertionProcess(contractId)}
          handleOpen={handleCancelProcessModal}
        />
      </AdminCard>
    </Grid>
  );
};

export default ContractEndCard;
