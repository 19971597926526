import { Box } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import CircularStepper from "Components/Admin/UIComponents/CircularStepper";
import useQuotation from "Components/Admin/administrator/subscriptions/hooks/useQuotation";

import useRepairsRequestSteps, { STEPS } from "../../hooks/requests/useRepairsRequestSteps";
import useRepairRequestAction from "../../hooks/requests/useRepairRequestAction";
import RepairSelection from "./components/RepairSelection";
import RepairVisit from "./components/RepairVisit";
import SubscriptionNav from "../SubscriptionNav";
import StepTitle from "./components/StepTitle";
import useRepairsRequestPageStyles from "./RepairsRequestPageStyles";
import getStepHeader from "./utils";

const RepairsRequestPage = () => {
  const { quotation, subscription, isLoading: isQuotationLoading } = useQuotation();
  const { currentStep, handleNextStep, handleBackStep, handleError, handleClose } =
    useRepairsRequestSteps();
  const { setVisitHours, visitHours, requestForm, setRequestForm, submitData, isLoading } =
    useRepairRequestAction({
      onSuccessHandler: handleClose,
      onErrorHandler: handleError,
    });
  const stepHeader = getStepHeader(currentStep, subscription?.property?.country);
  const classes = useRepairsRequestPageStyles();

  const stepComponent = () => {
    if (isLoading || isQuotationLoading) return <Spinner />;
    switch (currentStep) {
      case STEPS.Selection:
        return (
          <RepairSelection
            handleNext={handleNextStep}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
            handleClose={handleClose}
          />
        );
      case STEPS.Visit:
        return (
          <RepairVisit
            handleNext={submitData}
            handleBack={handleBackStep}
            setVisitHours={setVisitHours}
            visitHours={visitHours}
            handleClose={handleClose}
          />
        );
      default:
        return (
          <RepairSelection
            handleNext={handleNextStep}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
            handleClose={handleClose}
          />
        );
    }
  };
  return (
    <Box className={classes.container}>
      <SubscriptionNav
        isRequest
        quotationId={quotation?.id}
        country={subscription?.property?.country}
      />
      <Box className={classes.header}>
        <CircularStepper totalSteps={2} step={currentStep} hideStepText />
        <StepTitle title={stepHeader.title} subtitle={stepHeader.subtitle} />
      </Box>
      {stepComponent()}
    </Box>
  );
};

export default RepairsRequestPage;
