import { useEffect } from "react";

import { getMultifamilies } from "context/multifamilyContext/multifamily/multifamilyActions";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useGetToken } from "hooks";
import Header from "./Components/Header";
import Table from "./Components/table/Table";
import { useTable } from "context/tableContext/tableContext";

import useStyles from "./indexStyles";

function ListTable() {
  const classes = useStyles();
  const { getToken } = useGetToken();
  const { dispatch, state } = useMultifamily();
  const { state: tableState } = useTable();

  useEffect(() => {
    getToken().then((authToken) => {
      const filters = {
        page: tableState.currentPage,
        page_size: tableState.pageSize,
        // uncomment this block comment when backend filters are ready
        /* search: state.filters?.search,
        published: state.filters?.published?.value,
        name: state.filters?.name,
        id: state.filters?.id,
        houm_executive: state.filters?.houm_executive,
        landlord: state.filters?.landlord, */
      };
      getMultifamilies(dispatch, filters, authToken);
    });
  }, [
    tableState.currentPage,
    tableState.pageSize,
    state.filters?.search,
    state.filters?.published,
    state.filters?.name,
    state.filters?.id,
    state.filters?.houm_executive,
    state.filters?.landlord,
  ]);

  return (
    <div className={classes.container}>
      <Header />
      <Table />
    </div>
  );
}
export default ListTable;
