import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "bold",
    marginTop: theme.spacing(1.25),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
}));

export default useStyles;
