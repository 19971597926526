import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  disabled: boolean;
}

const usePaginationArrowStyles = makeStyles<Theme, Props>((theme) => ({
  pageArrowWrapper: {
    margin: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageArrow: {
    height: 11,
    width: 11,
    color: (props) => theme.palette.grey[props.disabled ? 200 : 900],
    "&:hover": {
      cursor: (props) => (props.disabled ? "default" : "pointer"),
      color: (props) => (props.disabled ? theme.palette.grey[200] : theme.palette.primary.main),
    },
  },
}));

export default usePaginationArrowStyles;
