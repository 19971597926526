import { StringOption } from "models/Option";

const commonLinkages: StringOption[] = [
  {
    value: "proprietary",
    label: "Propietario",
  },
  {
    value: "broker",
    label: "Corredor",
  },
  {
    label: "Otro",
    value: "other",
  },
];

export const coLinkages: StringOption[] = [
  ...commonLinkages,
  {
    label: "Arrendatario",
    value: "tenant",
  },
  {
    label: "Portero",
    value: "concierge",
  },
];

export const clLinkages: StringOption[] = [
  ...commonLinkages,
  {
    label: "Arrendatario",
    value: "tenant",
  },
  {
    label: "Conserje",
    value: "concierge",
  },
];

export const mxLinkages: StringOption[] = [
  ...commonLinkages,
  {
    label: "Inquilino",
    value: "tenant",
  },
  {
    label: "Conserje",
    value: "concierge",
  },
];

export const linkagesMap = {
  Colombia: coLinkages,
  Mexico: mxLinkages,
  Chile: clLinkages,
};
