import { makeStyles, Theme } from "@material-ui/core";

const useVoucherStyles = makeStyles((theme: Theme) => ({
  voucherRoot: {
    padding: theme.spacing(1, 0),
    border: "1px solid #ABC7F1",
    borderRadius: 4,
    backgroundColor: "#EAF1FB",
    display: "flex",
    cursor: "pointer",
  },
  icon: { marginRight: theme.spacing(0.5) },
}));

export default useVoucherStyles;
