import { makeStyles } from "@material-ui/core";

const useLeftColumnFormStyles = makeStyles((theme) => ({
  outline: {
    width: "100%",
    borderRadius: 50,
    border: `1px solid ${theme.palette.grey["200"]}`,
    color: theme.palette.grey["500"],
    padding: theme.spacing(0.313, 0, 0.313, 1),
  },
  underline: {
    border: "0px solid transparent",
  },
}));
export default useLeftColumnFormStyles;
