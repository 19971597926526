import { Box, Typography } from "@material-ui/core";
import { Locale } from "models/Countries";
import { useState } from "react";
import BasicServiceModal from "../../../BasicServiceModal";

import useEditServiceStyles from "./EditServiceStyles";

interface Props {
  clientNumber: string;
  country: Locale;
  propertyId: number;
  company: string;
}

function EditService({ clientNumber, country, propertyId, company }: Props) {
  const classes = useEditServiceStyles();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box className={classes.editServiceWrapper}>
      <Typography className={classes.editService} onClick={() => setOpenModal(true)}>
        Editar información
      </Typography>
      <BasicServiceModal
        company={company.toLocaleLowerCase().replace(" ", "_")}
        country={country}
        clientNumber={clientNumber}
        handleClose={() => setOpenModal(false)}
        propertyId={propertyId}
        open={openModal}
        title="Editar"
        update
      />
    </Box>
  );
}

export default EditService;
