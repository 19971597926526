import { makeStyles, Theme } from "@material-ui/core";

const useSchedulingFormStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    marginLeft: "auto",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    padding: theme.spacing(9 / 8, 13 / 2),
    textTransform: "none",
    fontWeight: 700,
    textDecoration: "underline",
    borderRadius: 100,
  },
  scheduleButton: {
    padding: theme.spacing(9 / 8, 13 / 2),
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 100,
  },
  title: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(4),
  },
  fisrtColumn: {
    flexBasis: "20%",
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    flexDirection: "column",
  },
  hourColumn: {
    flexBasis: "25%",
    flexGrow: 0,
    flexShrink: 0,
    width: "100%",
    flexDirection: "column",
  },
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    overflow: "hidden",
    boxShadow: "0px 0px 7px rgba(176, 190, 197, 0.5)",
    borderRadius: 20,
    padding: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      gap: theme.spacing(4),
    },
  },
  calendarColumn: {
    flexBasis: "30%",
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      flexBasis: "40%",
    },
  },
  hours: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      gap: theme.spacing(3),
    },
  },
}));

export default useSchedulingFormStyles;
