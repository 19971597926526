import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { useGetToken } from "hooks";
import { LandlordSummary } from "models/AdminModels/Landlord";
import userService from "services/userService";

import { parseLandlordSummary } from "../../utils/parsers";
import useLandlordTicketsCount from "./useLandlordTicketsCount";

const useLandlordSummary = () => {
  const { getToken } = useGetToken();
  const { id } = useParams<{ id: string }>();
  const {
    isLoading: summaryLoading,
    error: summaryError,
    data: summary,
  }: UseQueryResult<LandlordSummary, Error> = useQuery<LandlordSummary, Error>(
    ["landlordSummary", id],
    async () => {
      const authToken = await getToken();
      const response = await userService.getLandlordSummary(id, authToken);
      return parseLandlordSummary(response.data);
    }
  );

  const { isLoading: ticketsLoading, data: tickets } = useLandlordTicketsCount(id);

  const completeData = {
    ...summary,
    tickets,
  };

  return {
    isLoading: summaryLoading || ticketsLoading,
    error: summaryError,
    data: completeData,
  };
};

export default useLandlordSummary;
