import * as yup from "yup";
import { homeCheckerDetailsValidationObject } from "utils/yupValidators/property";

export const requiredText = "Este campo es obligatorio";

const {
  inhabitedProperty,
  inhabitedPropertyDescription,
  hasHoumBox,
  thirdPartyCoordination,
  thirdPartyCoordinationDescription,
  thirdPartyCoordinationPhone,
} = homeCheckerDetailsValidationObject;

export default yup.object({
  inhabitedProperty,
  inhabitedPropertyDescription,
  hasHoumBox,
  thirdPartyCoordination,
  thirdPartyCoordinationDescription,
  thirdPartyCoordinationPhone,
});
