import { Action, State } from "./contractTemplatesTypes";

export const initialState: State = {
  fetchingTemplates: true,
  filters: {
    country: "cl",
  },
  newTemplate: {
    id: 0,
    contractLink: "",
    country: "cl",
    description: "",
    mandateLink: "",
    name: "",
  },
  submittingTemplate: false,
  submitResponse: "",
  templates: [],
  view: "templates",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FETCHING_TEMPLATES":
      return { ...state, fetchingTemplates: action.fetchingTemplates };
    case "SET_FILTERS":
      return { ...state, filters: { ...state.filters, ...action.filters } };
    case "SET_NEW_TEMPLATE":
      return {
        ...state,
        newTemplate: { ...state.newTemplate, ...action.newTemplate },
      };
    case "SET_SUBMITTING_TEMPLATE":
      return { ...state, submittingTemplate: action.submittingTemplate };
    case "SET_SUBMIT_RESPONSE":
      return { ...state, submitResponse: action.submitResponse };
    case "SET_TEMPLATES":
      return { ...state, templates: action.templates };
    case "SET_VIEW":
      return { ...state, view: action.view };
    default:
      return state;
  }
};

export default apiReducer;
