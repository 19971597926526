import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import {
  IPhotosBody,
  IPresignedUrlResponse,
  IRepairRequestBody,
  IRepairsRequestResponse,
} from "./types";

export default {
  createRepairRequest(
    token: string,
    propertyId: number,
    data: IRepairRequestBody
  ): Promise<AxiosResponse> {
    return createHttp(token).post(`/properties/v2/${propertyId}/maintenance/request`, data);
  },

  getPresignedUrl(token: string, data: IPhotosBody): Promise<AxiosResponse<IPresignedUrlResponse>> {
    return createHttp(token).post("/properties/v2/maintenance/upload-url ", data);
  },
  getRepairsRequests(
    token: string,
    propId: number
  ): Promise<AxiosResponse<IRepairsRequestResponse[]>> {
    return createHttp(token).get(`/properties/v2/${propId}/maintenance/request/list`);
  },
};
