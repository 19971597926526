import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@houm-com/ui/Button";

import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import { IUserBankAccountResponse } from "services/bankAccountService/types";
import { IReservation } from "services/newPaymentService/types";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";

import ExchangeReservationModal from "../ExchangeReservationModal";
import useHandleModals from "../../hooks/useHandleModals";
import RefundDetailsModal from "../RefundDetailsModal";
import RefundModal from "../RefundModal";
import MarkPaidModal from "../MarkPaidModal";
import EditRefundDate from "../EditRefundDate";
import { countryCode } from "utils";

const ITEM_HEIGHT = 48;

interface Props {
  reservation: IReservation;
  country: Locale;
  dataBankAccount?: IUserBankAccountResponse | null;
  propertyId: number;
  user: UserModel;
  isRefund: boolean;
}

export default function ReservationOptions({
  country,
  reservation,
  dataBankAccount,
  propertyId,
  user,
  isRefund,
}: Props) {
  const {
    openMenu,
    handleClick,
    openRefund,
    openRefundModal,
    openExchangeModal,
    openExchangeReservationModal,
    openDetailsModal,
    openDetailsReservationModal,
    openMarkPaidModal,
    handleMarkPaidModal,
    openEditDate,
    openEditDateModal,
    handleClose,
    anchorEl,
  } = useHandleModals(dataBankAccount);
  const isMx = ["Mexico", "México"].includes(country);
  const isNotPaid = reservation.status !== "PAID";

  return (
    <>
      {isNotPaid && isMx && (
        <ProtectedComponent scope="permissions" require={["edit:payment_reservation:all"]}>
          <Button variant="primary" size="sm" className="ml-4" onClick={handleMarkPaidModal}>
            Marcar como pagado
          </Button>
        </ProtectedComponent>
      )}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        {reservation.status === "PAID" && <MenuItem onClick={openRefundModal}>Reembolsar</MenuItem>}
        {reservation.status === "UNPAID" &&
          (isRefund ? (
            <MenuItem onClick={openEditDateModal}>Editar fecha de reembolso</MenuItem>
          ) : (
            <MenuItem onClick={openExchangeReservationModal}>Pagar con canje</MenuItem>
          ))}
        {reservation.status === "REFUNDED" && (
          <MenuItem onClick={openDetailsReservationModal}>Ver detalles</MenuItem>
        )}
      </Menu>
      <RefundModal
        openModal={openRefund}
        handleModal={openRefundModal}
        reservationAmount={reservation.total_amount}
        currency={reservation.currency as CurrencyCode}
        country={country}
        dataBankAccount={dataBankAccount}
        movementId={reservation.movement_id}
        propertyId={propertyId}
      />
      <ExchangeReservationModal
        openModal={openExchangeModal}
        handleModal={openExchangeReservationModal}
        user={user}
        propertyId={propertyId}
        movementId={reservation.movement_id}
        country={country}
      />
      <RefundDetailsModal
        openModal={openDetailsModal}
        handleModal={openDetailsReservationModal}
        country={country}
        groupId={reservation?.group_id}
      />
      <MarkPaidModal
        country={countryCode(country)}
        openModal={openMarkPaidModal}
        handleModal={handleMarkPaidModal}
        reservationUid={reservation.movement_id}
        reservationAmount={reservation?.total_amount}
      />
      <EditRefundDate
        openModal={openEditDate}
        handleModal={openEditDateModal}
        country={country}
        agreedDate={reservation.agreed_date}
        movementId={reservation.movement_id}
      />
    </>
  );
}
