import { makeStyles } from "@material-ui/core";

export const useSelectStyles = makeStyles((theme) => ({
  menuItem: {
    fontSize: theme.spacing(1.7),
    fontWeight: 600,
  },
  menu: {
    "& :hover": {
      color: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      marginLeft: "3px",
      marginRight: "3px",
    },
  },
  labelFocused: {
    color: "#263238 !important",
  },
  label: {
    fontWeight: "bold",
    fontSize: "0.65rem",
    color: "#263238",
  },
  select: {
    color: "#607D8B",
    fontSize: "1rem",
    minWidth: "360px",
    borderWidth: "1px",
    borderColor: theme.palette.grey["50"],

    //Esta propiedad quita el borde   antes y despues de ser seleccionado
    "&&&:before": {
      borderWidth: "0px",
      borderColor: theme.palette.grey["50"],
    },
    "&&:after": {
      borderWidth: "0px",
      borderColor: theme.palette.grey["50"],
    },
  },
}));
