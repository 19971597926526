/* eslint-disable @typescript-eslint/no-use-before-define */
import { GridToolbarContainer } from "@material-ui/x-grid";

import { useTable } from "context/tableContext/tableContext";
import { setShowModal } from "context/tableContext/tableActions";
import FilterButton from "Components/Admin/UIComponents/Buttons/FilterButton";
import useStyles from "./ToolbarStyles";

function Toolbar() {
  const classes = useStyles();
  const { dispatch } = useTable();

  const handleFilterClick = () => {
    dispatch(setShowModal(true));
  };

  return (
    <GridToolbarContainer>
      <div className={classes.container}>
        <FilterButton onClick={handleFilterClick} />
      </div>
    </GridToolbarContainer>
  );
}

export default Toolbar;
