import { ComponentProps } from "react";
import { FormProvider } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useProperty } from "context/propertyContext/propertyContext";

import { handoverValidationSchema } from "./validationSchema";
import { getTimezone } from "utils/datetime";

import { getProperty, updateHCDetails } from "context/propertyContext/propertyActions";
import { useGetToken } from "hooks";

import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";

import rentSaleFields from "./fields";

function HandoverCard() {
  const { goNextStep, label, methods, currentStep, goBackStep } = useVisitCard({
    resolver: yupResolver(handoverValidationSchema),
  });

  const { state, dispatch } = useProperty();
  const { getToken } = useGetToken();

  const hcId = state.property?.home_checker_details?.[0].id;
  const toRent = state?.property?.for_rental;
  const toSale = state?.property?.for_sale;

  const tz = getTimezone(state.property?.country);

  const rentDate = state.property?.property_details?.[0].first_available_handover_date;
  const salesDate = state.property.sales_details.first_available_handover_date;

  const [rentField, saleField] = rentSaleFields({
    currentStep,
    salesDate,
    rentDate,
    tz,
  });

  const fields = [...(toRent ? [rentField] : []), ...(toSale ? [saleField] : [])] as ComponentProps<
    typeof VisitCard
  >["items"];

  const handleSubmit = async (data) => {
    const authToken = await getToken();
    const handOverKey = "first_available_handover_date";

    let [isUpdatedRent, isUpdatedSale] = [false, false];

    const { handOverdate, handOverdateSale } = data;

    if (handOverdate) {
      isUpdatedRent = await updateHCDetails(
        {
          property: {
            property_details: {
              [handOverKey]: handOverdate,
            },
          },
        },
        hcId,
        dispatch,
        authToken
      );
    }

    if (handOverdateSale) {
      isUpdatedSale = await updateHCDetails(
        {
          property: {
            sales_details: {
              [handOverKey]: handOverdateSale,
            },
          },
        },
        hcId,
        dispatch,
        authToken,
        true
      );
    }

    if (isUpdatedRent || isUpdatedSale) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          id="handover"
          items={fields}
          title={<TitleCard title="Fecha disponible para habitar" />}
          buttonOptions={{
            buttonLabel: label,
            buttonCallback: goNextStep,
            buttonPosition: "right",
            isSubmit: currentStep === "editable",
          }}
        />
      </form>
    </FormProvider>
  );
}

export default HandoverCard;
