import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { IoSearchOutline, IoCloseOutline } from "@houm-com/ui-icons";
import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";
import IconButton from "@houm-com/ui/IconButton";

import { Locale } from "models/Countries";
import useReactHookForm from "hooks/useReactHookForm";
import { IDebtCollectionFilters } from "hooks/useGetDebtCollections/utils/types";

import classes from "./SearchStyles";
import { validationSchema } from "./utils/validationSchema";
import { idOptions, emptyIds } from "./utils/constants";

export interface SearchProps {
  handleChangeFilters: (value: Partial<IDebtCollectionFilters>) => void;
  country: Locale;
}

type SearchFormType = {
  search: string;
  idType: string;
};

const Search = ({ handleChangeFilters, country }: SearchProps) => {
  const { methods, errors } = useReactHookForm<SearchFormType>({
    formSchema: validationSchema,
    formOptions: {
      defaultValues: {
        search: "",
        idType: idOptions[0].value,
      },
    },
  });
  const idType = methods.watch("idType");
  const onSubmit = (data: SearchFormType) => {
    if (data.search) {
      handleChangeFilters({ ...emptyIds, [data.idType]: data.search });
    }
  };
  const handleClearSearch = () => {
    handleChangeFilters({ ...emptyIds, [idType]: "" });
    methods.setValue("search", "");
  };
  useEffect(() => {
    methods.reset();
  }, [country]);
  const isEmailSearch = ["tenant_email", "propietary_email"].includes(idType);
  const label = isEmailSearch ? "Buscar por email" : "Buscar por ID";
  return (
    <div className={classes.container}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.searchContainer}>
          <FieldInput
            id="search"
            placeholder={label}
            label={label}
            type={isEmailSearch ? "string" : "number"}
            className={classes.input}
            errorMessage={errors.search?.message}
            {...methods.register("search")}
          />
          <FieldSelect
            id="idType"
            options={idOptions}
            placeholder="Seleccione un tipo de búsqueda"
            label="Tipo de búsqueda"
            defaultValue={idOptions[0].value}
            className={classes.input}
            errorMessage={errors.idType?.message}
            {...methods.register("idType")}
          />
          <IconButton
            icon={<IoSearchOutline />}
            position="start"
            variant="secondary"
            size="sm"
            className={classes.submitButton}
            type="submit">
            Buscar
          </IconButton>
        </form>
      </FormProvider>
      <IconButton
        icon={<IoCloseOutline size={15} />}
        position="start"
        variant="tertiary"
        size="sm"
        className={classes.clearSearchButton}
        onClick={handleClearSearch}
        type="button">
        Reiniciar búsqueda
      </IconButton>
    </div>
  );
};

export default Search;
