export const REPAIRS_REQUESTS_ALERTS: Record<string, { message: string; disclaimer: string }> = {
  SUCCESS: {
    message: "Hemos creado la solicitud de forma exitosa.",
    disclaimer: "Podrás ver la solicitud ahora en Pipe Drive",
  },
  ERROR: {
    message: "¡Oh no! Ocurrió un problema",
    disclaimer: "No pudimos crear tu solicitud, intenta de nuevo",
  },
};

export const REPAIRS_REQUESTS_LIST_ALERTS: Record<string, { message: string; disclaimer: string }> =
  {
    ERROR: {
      message: "¡Oh no! Ocurrió un problema",
      disclaimer: "No pudimos cargar las solicitudes, intenta de nuevo",
    },
  };
