import { ITenant } from "./types";

export const getCodebtors = (codebtors) =>
  codebtors.map((item) => ({
    rut: item.rut,
    document: item.document,
    document_type: item.document_type?.id,
    name: `${item.name} ${item.last_name} ${item.second_last_name}`,
    phone: item.phone,
    email: item.email,
    type: item.type,
  }));

export const parserTenants = (applicant, user): ITenant[] => [
  {
    rut: user?.rut,
    name: `${user?.name} ${user?.last_name} ${user?.second_last_name}`,
    phone: user?.phone,
    email: user?.email,
    type: "",
    document: user?.document,
    document_type: user?.document_type,
  },
  ...getCodebtors(applicant.codebtors),
];
