import { AxiosError } from "axios";
import { useMutation } from "react-query";
import Swal from "sweetalert2";

import newEvaluationService from "services/newEvaluationService";
import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface FormData {
  mainApplicantId: number;
  country: Locale;
  contractId: number;
}

const useReSendProfile = () => {
  const { getToken } = useGetToken();

  const { mutate: changeContractStatus, isLoading: isLoadingChangeStatus } = useMutation(
    async (formData: { contractId: number }) => {
      const authToken = await getToken();
      await contractService.update(
        formData.contractId,
        {
          status: "Perfil enviado",
          discard_comment: "",
        },
        authToken
      );
    },
    {
      onSuccess: () =>
        Swal.fire({ type: "success", text: "¡Perfil reenviado!" }).then(() =>
          window.location.reload()
        ),
      onError: (e: Error) => {
        const error = e as AxiosError<{ message: string }>;
        const reason = error.response?.data?.message;
        Swal.fire({
          type: "error",
          title: "Error",
          text: `No se pudo pudo actualizar el contrato a estado 'Perfil enviado'. Error: ${
            reason ?? e
          }`,
        });
      },
    }
  );

  const { mutate: reSendProfile, isLoading: isLoadingReSend } = useMutation(
    async (formData: FormData) => {
      const authToken = await getToken();
      await newEvaluationService.updateLandlordEvaluation(
        formData.mainApplicantId,
        formData.country,
        { status: "pending" },
        authToken
      );
    },
    {
      onSuccess: (_, data) => changeContractStatus({ contractId: data.contractId }),
      onError: (e: Error) =>
        Swal.fire({
          type: "error",
          title: "No se pudo reenviar el perfíl",
          text: `Error: ${e}`,
        }),
    }
  );

  return {
    reSendProfile,
    loading: isLoadingReSend || isLoadingChangeStatus,
  };
};

export default useReSendProfile;
