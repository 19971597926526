import {
  ExternalPortalPublication,
  TotalQuota,
  QuotaByMacrozone,
  PlanEdition,
  TotalQuotaFilters,
  QuotaByMacrozoneFilters,
  PlanEditionFilters,
  PlanEditionStates,
} from "models/Integration";
import { PaginatedTable } from "context/tableContext/tableTypes";
import { Country } from "models/Countries";

export type Dispatch = (action: Action) => void;

export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";
export const SET_EXTERNAL_PORTAL_PUBLICATIONS = "SET_EXTERNAL_PORTAL_PUBLICATIONS";
export const SET_TOTAL_QUOTA_DATA = "SET_TOTAL_QUOTA_DATA";
export const SET_TOTAL_QUOTA_FILTERS = "SET_TOTAL_QUOTA_FILTERS";
export const SET_QUOTA_BY_MACROZONE_DATA = "SET_QUOTA_BY_MACROZONE_DATA";
export const SET_QUOTA_BY_MACROZONE_FILTERS = "SET_QUOTA_BY_MACROZONE_FILTERS";
export const SET_PLAN_EDITION_DATA = "SET_PLAN_EDITION_DATA";
export const SET_PLAN_EDITION_FILTERS = "SET_PLAN_EDITION_FILTERS";
export const RESET_PLAN_EDITION_FILTERS = "RESET_PLAN_EDITION_FILTERS";
export const REFETCH_PLAN_EDITION_DATA = "REFETCH_PLAN_EDITION_DATA";
export const SET_PLAN_EDITION_STATES = "SET_PLAN_EDITION_STATES";
export const RESET_CURRENT_PLAN_EDITION_ROW = "RESET_CURRENT_PLAN_EDITION_ROW";

export interface State {
  country: Country;
  portalPublications: PaginatedTable<ExternalPortalPublication>;
  totalQuota: PaginatedTable<TotalQuota>;
  quotaByMacrozone: PaginatedTable<QuotaByMacrozone>;
  planEdition: PaginatedTable<PlanEdition>;
  totalQuotaFilters: TotalQuotaFilters;
  quotaByMacrozoneFilters: QuotaByMacrozoneFilters;
  planEditionFilters: PlanEditionFilters;
  planEditionStates: PlanEditionStates;
}

interface setCurrentCountry {
  type: typeof SET_CURRENT_COUNTRY;
  country: Country;
}

interface setExternalPortalPublications {
  type: typeof SET_EXTERNAL_PORTAL_PUBLICATIONS;
  externalPortalPublicationsInfo: Partial<PaginatedTable<ExternalPortalPublication>>;
}

interface setTotalQuotaData {
  type: typeof SET_TOTAL_QUOTA_DATA;
  totalQuotaData: Partial<PaginatedTable<TotalQuota>>;
}

interface setTotalQuotaFilters {
  type: typeof SET_TOTAL_QUOTA_FILTERS;
  totalQuotaFilters: Partial<TotalQuotaFilters>;
}

interface setQuotaByMacrozoneData {
  type: typeof SET_QUOTA_BY_MACROZONE_DATA;
  quotaByMacrozoneData: Partial<PaginatedTable<QuotaByMacrozone>>;
}

interface setQuotaByMacrozoneFilters {
  type: typeof SET_QUOTA_BY_MACROZONE_FILTERS;
  quotaByMacrozoneFilters: Partial<QuotaByMacrozoneFilters>;
}

interface setPlanEditionData {
  type: typeof SET_PLAN_EDITION_DATA;
  planEditionData: Partial<PaginatedTable<PlanEdition>>;
}

interface setPlanEditionFilters {
  type: typeof SET_PLAN_EDITION_FILTERS;
  planEditionFilters: Partial<PlanEditionFilters>;
}

interface resetPlanEditionFilters {
  type: typeof RESET_PLAN_EDITION_FILTERS;
}

interface refetchPlanEditionData {
  type: typeof REFETCH_PLAN_EDITION_DATA;
}

interface setPlanEditionStates {
  type: typeof SET_PLAN_EDITION_STATES;
  planEditionStates: Partial<PlanEditionStates>;
}

interface resetCurrentPlanEditingRow {
  type: typeof RESET_CURRENT_PLAN_EDITION_ROW;
}

export type Action =
  | setCurrentCountry
  | setExternalPortalPublications
  | setTotalQuotaData
  | setTotalQuotaFilters
  | setQuotaByMacrozoneData
  | setQuotaByMacrozoneFilters
  | setPlanEditionData
  | setPlanEditionFilters
  | resetPlanEditionFilters
  | refetchPlanEditionData
  | setPlanEditionStates
  | resetCurrentPlanEditingRow;
