import {
  Incomes,
  MemberPersonalInformation,
  UserProfile,
} from "domains/evaluations/models/Evaluations";

const parseSalaryData = (data: Incomes) => {
  const salaryIncomes = data.filter((income) => income.type === "salary");
  const otherIncomes = data.filter((income) => income.type === "other");
  const salaryIncomeData = salaryIncomes.map((income, index) => ({
    [`salaryIncomeMonth${index + 1}`]: income.month,
    [`salaryIncomeAmount${index + 1}`]: income.amount,
  }));
  const otherIncomeData = otherIncomes.map((income, index) => ({
    [`otherIncomeMonth${index + 1}`]: income.month,
    [`otherIncomeAmount${index + 1}`]: income.amount,
  }));

  const incomesData = [...salaryIncomeData, ...otherIncomeData];

  const result = incomesData.reduce((acc, curr) => {
    Object.entries(curr).forEach(([key, value]) => {
      acc[key] = value;
    });
    return acc;
  }, {});

  return result;
};

export const getDefaultData = (
  personInformation: MemberPersonalInformation,
  userInformation: UserProfile
) => ({
  name: userInformation?.name,
  lastName: userInformation?.lastName,
  secondLastName: userInformation?.secondLastName,
  document: userInformation?.document,
  documentType: userInformation?.documentType,
  nationality: personInformation?.nationality,
  visaType: personInformation?.visaType,
  fullAddress: "Aca va alguna ciudad", // ONLY CL Y CO
  occupation: personInformation?.occupation,
  employer: personInformation?.employer,
  employmentContractType: personInformation?.employmentContractType,
  incomeSourceType: personInformation?.incomeSourceType,
  ...parseSalaryData(personInformation.incomes),
  comment: personInformation?.comment,
});
