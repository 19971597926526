import { Activity } from "models/DemandLead";

export const parseActivity = (activities: Activity[]) => {
  let completed = false;

  return activities
    .sort((a, b) => {
      const dateA = a.contact || a.expiration;
      const dateB = b.contact || b.expiration;
      return new Date(dateB).getTime() - new Date(dateA).getTime();
    })
    .map((activity, i) => {
      const newActivity = {
        ...activity,
        toComplete: (activity.color === "red" && !completed) || activity.color === "yellow",
      };
      if (!completed && activity.color === "red") {
        completed = true;
      }
      return newActivity;
    });
};

/**
 * @param string Example  "('Vivienda',)
 * @returns "Vivienda"
 */

export const parsePyTupple = (val: string) => val.replace(/[()',]/g, "");

/**
 * @returns uniques ids from activity list
 */
export const getUniqueProperties = (activities: Activity[]): any =>
  activities
    .filter((item) => item.property_id)
    .map((item) => item.property_id)
    .filter((item, index, arr) => arr.indexOf(item) === index);
