import { SnackbarProvider } from "notistack";
import Filters, { FiltersProps } from "./Filters";

export default function FiltersController(props: FiltersProps) {
  return (
    <SnackbarProvider>
      <Filters {...props} />
    </SnackbarProvider>
  );
}
