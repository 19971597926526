import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import { Statuses } from "Components/Admin/UIComponents/PropertyTags/PropertyStatuses";
import useTableStyles from "Components/Admin/administrator/supply/utils/TableStyles";
import ButtonSelect from "Components/Admin/UIComponents/Buttons/ButtonSelect";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import DefaultPicture from "assets/images/defaultPropertyPhoto.png";
import usePropertiesTableStyles from "./LeadPropertiesStyles";
import { useTable } from "context/tableContext/tableContext";
import { useLead } from "context/leadContext/leadContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";

function LeadProperties() {
  const classes = usePropertiesTableStyles();
  const tableClasses = useTableStyles();
  const { state } = useLead();
  const history = useHistory();
  const [propertyType, setPropertyType] = useState("all");
  const { state: tableState, dispatch: tableDispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const types = {
    Venta: "forSale",
    Arriendo: "forRental",
    "Venta/Arriendo": "all",
  };

  const handleShowProperty = (url) => {
    history.push(url);
  };

  const handleCreateButton = () => {
    history.push({
      pathname: "/admin/properties/create",
      state: {
        leadId: state?.lead?.id,
      },
    });
  };

  const renderData = useCallback(() => {
    if (propertyType === "forRental") {
      return state.leadProperties.filter((property) => property.forRental);
    } else if (propertyType === "forSale") {
      return state.leadProperties.filter((property) => property.forSale);
    }
    return state.leadProperties;
  }, [propertyType, state.leadProperties]);

  const columns = [
    {
      field: "photos",
      headerName: "FOTO",
      sortable: false,
      filterable: false,
      width: 170,
      renderCell: (params) => (
        <img
          alt="property"
          className={classes.tablePropertyPhoto}
          src={params.row.photoUrl || DefaultPicture}
        />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      filterable: false,
      width: 130,
    },
    {
      field: "address",
      headerName: "DIRECCIÓN",
      sortable: false,
      filterable: false,
      width: 400,
      flex: 1,
    },
    {
      field: "formatedPrice",
      headerName: "VALOR",
      sortable: false,
      filterable: false,
      width: 220,
    },
    {
      field: "status",
      headerName: "ESTADO",
      sortable: false,
      filterable: false,
      width: 165,
      renderCell: (params) =>
        params.row.status ? Statuses()[params.row.status].tag : Statuses().unknown.tag,
    },
    {
      field: "showProperty",
      headerName: " ",
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => (
        <LinkButton onClick={() => handleShowProperty(params.row.showUrl)}>
          Ver Propiedad
        </LinkButton>
      ),
      headerClassName: "hideColumnLine",
    },
  ];
  return (
    <>
      <Typography className={classes.title}>Propiedades</Typography>
      <Grid container spacing={2} className="mb-3">
        {Object.entries(types).map(([label, value]) => (
          <Grid item key={value}>
            <ButtonSelect
              label={label}
              isActive={propertyType === value}
              onClick={() => setPropertyType(value)}
            />
          </Grid>
        ))}
      </Grid>
      <div className={tableClasses.container}>
        <CustomDataGrid
          className={tableClasses.table}
          rowCount={state.leadPropertiesCount}
          columns={columns}
          rows={renderData()}
          loading={state.loading}
          page={currentPage - 1}
          pageSize={pageSize}
          onPageChange={(params) => tableDispatch(setCurrentPage(params + 1))}
          onSortModelChange={(model) => tableDispatch(setSorting(model))}
          onPageSizeChange={(params) => {
            tableDispatch(setPageSize(params));
            tableDispatch(setCurrentPage(1));
          }}
        />
      </div>
      <Grid container className={classes.createButtonContainer} justifyContent="flex-end">
        <CustomButton active onClick={handleCreateButton} className={classes.createButton}>
          Crear propiedad
        </CustomButton>
      </Grid>
    </>
  );
}

export default LeadProperties;
