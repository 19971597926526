import { Box } from "@material-ui/core";
import clsx from "clsx";

import PageSelector from "./components/PageSelector";
import RowsCount from "./components/RowsCount";
import useTablePaginationStyles from "./TablePaginationStyles";

interface Props {
  pageCount: number;
  pageSize: number;
  setPageSize: (p: number) => void;
  pageSizeOptions: number[];
  currentPage: number;
  setCurrentPage: (p: number) => void;
  loading: boolean;
  className?: string;
}

function TablePagination({
  pageCount,
  pageSize,
  setPageSize,
  pageSizeOptions,
  currentPage,
  setCurrentPage,
  loading,
  className,
}: Props) {
  const classes = useTablePaginationStyles();

  if (loading) return null;

  return (
    <Box className={clsx(classes.paginationWrapper, className)}>
      <RowsCount pageSize={pageSize} setPageSize={setPageSize} sizeOptions={pageSizeOptions} />
      <PageSelector pageCount={pageCount} page={currentPage} setCurrentPage={setCurrentPage} />
    </Box>
  );
}

export default TablePagination;
