import useStyles from "./HeaderStyles";

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.title}>Ejecutivos/as de administración</p>
      </div>
    </div>
  );
}

export default Header;
