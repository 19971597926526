import { FormHelperText, Typography } from "@material-ui/core";
import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import { useProperty } from "context/propertyContext/propertyContext";
import useUserFormRole from "hooks/useUserFormRole";
import React from "react";
import { useFormState } from "react-hook-form";
import FormBlock from "./FormBlock";

function DealType() {
  const { state } = useProperty();
  const { property } = state;
  const { errors } = useFormState();
  const { market_type: fieldError } = errors;

  const role = useUserFormRole();
  if (role !== "real_estate_developer")
    return (
      <FormBlock>
        <Typography variant="h6">Indica si la propiedad es para vender o arrendar</Typography>
        <ControlledCheckbox
          id="for_rental"
          label="Arriendo"
          defaultChecked={property?.for_rental ?? false}
        />
        <ControlledCheckbox
          id="for_sale"
          label="Venta"
          defaultChecked={property?.for_sale ?? false}
        />
        <FormHelperText error>{fieldError && fieldError?.message}</FormHelperText>
      </FormBlock>
    );
  return <div />;
}

export default React.memo(DealType);
