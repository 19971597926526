import { makeStyles, Theme } from "@material-ui/core";

const useCreateTemplateDialogStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    padding: 0,
  },
  titleContainer: {
    maxWidth: 500,
  },
  title: {
    fontSize: theme.typography.pxToRem(22),
    lineHeight: 1.25,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.25,
  },
  selectCountry: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
}));

export default useCreateTemplateDialogStyles;
