import { useEffect } from "react";
import { Grid } from "@material-ui/core";

import { useCalculator } from "context/calculatorContext/calculatorContext";
import DealTime from "Components/Admin/administrator/properties/show/tabs/rev/DealTimeTable";
import ExternalSimilarProperties from "Components/Admin/administrator/properties/show/tabs/rev/ExternalSimilarProperties";
import {
  setSimilarExternalProperties,
  setSuggestedPrices,
} from "context/calculatorContext/calculatorActions";
import { countryCode } from "utils";
import PriceAndMap from "./PriceAndMap";
import SimilarPropertiesGallery from "./SimilarProperties";
import { useGetToken } from "hooks";
import {
  getSimilarHistoricProperties,
  setSimilarProperties,
} from "context/similarProperties/similarPropertiesActions";
import { useSimilarProperties } from "context/similarProperties/similarPropertiesContext";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function RevTab({ property }: Props) {
  const { state, dispatch } = useCalculator();
  const { dispatch: similarPropsDispatch } = useSimilarProperties();
  const { getToken } = useGetToken();

  const { calculatorResponse: recommendation, deal } = state;

  useEffect(() => {
    if (recommendation) setSimilarProperties(property, recommendation, deal, similarPropsDispatch);
  }, [property, recommendation, deal]);

  useEffect(() => {
    getToken().then((authToken) => setSimilarExternalProperties(property, dispatch, authToken));
  }, [property]);

  useEffect(() => {
    dispatch(setSuggestedPrices(property));
  }, [property]);

  useEffect(() => {
    getToken().then((authToken) =>
      getSimilarHistoricProperties(
        property.id,
        property.country,
        deal,
        100,
        property.comuna,
        similarPropsDispatch,
        authToken
      )
    );
  }, [deal, property.id]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item md={12}>
        <PriceAndMap property={property} />
      </Grid>
      <Grid item md={12}>
        <SimilarPropertiesGallery locale={countryCode(property.country)} />
      </Grid>
      <Grid>
        <DealTime />
      </Grid>
      <Grid>
        <ExternalSimilarProperties />
      </Grid>
    </Grid>
  );
}

export default RevTab;
