import { StepProps } from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { LandlordSummary } from "models/AdminModels/Landlord";

const useBreadcrumbs = (landlordData?: LandlordSummary): { breadcrumbs: StepProps[] } => {
  const breadcrumbs = [{ label: "Clientes" }, { label: "Propietarios", link: "/admin/landlord" }];
  if (!landlordData?.id || !landlordData?.fullName) return { breadcrumbs };
  breadcrumbs.push({
    label: landlordData.fullName,
    link: `/admin/landlord/${landlordData.id}`,
  });
  return { breadcrumbs };
};

export default useBreadcrumbs;
