import { useCallback, useMemo, useState } from "react";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { CountryUpperCase, Locale } from "models/Countries";
import { MovementBreakdownRecord } from "models/Payments";
import { CurrencyCode } from "models/Currencies";
import { UserResponse } from "models/User";
import { IPlan } from "models/Plan";

import CreatePaymentOrdersModal from "./modals/CreatePaymentOrdersModal";
import CreateSpotPaymentModal from "./modals/CreateSpotPaymentModal";
import { INCOME, OUTFLOW, TYPE_ALL } from "./constants";
import usePayments from "../../hooks/usePayments";
import MovementTable from "./MovementTable";
import ErrorMessage from "./ErrorMessage";
import { getAllMovements } from "./utils";
import { countryCode } from "utils";
import Header from "./Header";

interface MovementProps {
  country: CountryUpperCase;
  subId: number;
  propertyId: number;
  landlordId: number;
  plan: IPlan;
  landlordContract: UserResponse;
  tenantContract: UserResponse;
  contractId: number;
  contractCurrency: CurrencyCode;
}

type MovementType = typeof INCOME | typeof OUTFLOW | typeof TYPE_ALL;

const Movements = ({
  country,
  subId,
  propertyId,
  landlordId,
  plan,
  landlordContract,
  tenantContract,
  contractId,
  contractCurrency,
}: MovementProps) => {
  const [openSpotModal, setOpenSpotModal] = useState(false);
  const [openPaymentOrdersModal, setOpenPaymentOrdersModal] = useState(false);
  const [movementType, setMovementType] = useState<MovementType>(TYPE_ALL);
  const {
    movements,
    isLoading: loading,
    isErrorIncome,
    isErrorOutflow,
  } = usePayments(countryCode(country), subId);
  const shortCountry = countryCode(country);

  const onMovementTypeChange = useCallback(
    (e: typeof INCOME | typeof OUTFLOW | typeof TYPE_ALL) => {
      setMovementType(e);
    },
    [movementType]
  );

  const handleOpenDialog = useCallback((type: string) => {
    if (type === "spot") setOpenSpotModal(true);
    else setOpenPaymentOrdersModal(true);
  }, []);

  const allMovementsData = useMemo(
    () => getAllMovements(movements, movementType, shortCountry),
    [movements, movementType, shortCountry]
  );

  const renderTable = (movements: MovementBreakdownRecord[], title: string) => (
    <MovementTable
      movements={movements}
      loading={loading}
      subscriptionId={subId}
      title={title}
      key={title}
      contractCurrency={contractCurrency}
    />
  );

  return (
    <>
      <Header
        movementType={movementType}
        handleOpenDialog={handleOpenDialog}
        onMovementTypeChange={onMovementTypeChange}
      />
      <Loading loading={loading}>
        <>
          {isErrorIncome && (movementType === "all" || movementType === "INCOME") && (
            <ErrorMessage userType="arrendatario" />
          )}
          {isErrorOutflow && (movementType === "all" || movementType === "OUTFLOW") && (
            <ErrorMessage userType="propietario" />
          )}
          {Object.entries(allMovementsData).map(
            ([key]) =>
              allMovementsData[key].movements.length !== 0 &&
              renderTable(allMovementsData[key].movements, allMovementsData[key].title)
          )}
        </>
      </Loading>
      <CreateSpotPaymentModal
        open={openSpotModal}
        handleOnClose={() => setOpenSpotModal(false)}
        propertyId={propertyId}
        landlordId={landlordId}
        subscriptionId={subId}
        country={shortCountry}
      />
      <CreatePaymentOrdersModal
        open={openPaymentOrdersModal}
        handleOnClose={() => setOpenPaymentOrdersModal(false)}
        landlordContract={landlordContract}
        tenantContract={tenantContract}
        propertyId={propertyId}
        subscriptionId={subId}
        country={shortCountry as Locale}
        plan={plan}
        contractId={contractId}
      />
    </>
  );
};

export default Movements;
