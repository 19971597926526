import { useEffect } from "react";

import { GridColumns, GridColDef } from "@material-ui/x-grid";

import { useGetToken } from "hooks";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { deleteBasicService } from "context/multifamilyContext/unit/unitActions";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();
  const { getToken } = useGetToken();

  const { state: tableStates, dispatch } = useTable();
  const {
    state: { propertyServices, property },
    dispatch: propertyDispatch,
  } = useMultifamilyUnit();
  const { currentPage, pageSize } = tableStates;

  const handleDeleteService = (serviceId: number) => {
    getToken().then(async (authToken) => {
      await deleteBasicService(serviceId, property.id, authToken, propertyDispatch);
    });
  };

  useEffect(() => dispatch(setPageSize(10)), []);

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "Tipo de servicio",
      field: "serviceType",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ value }) => value,
    },
    {
      ...columnsConfig,
      headerName: "Empresa",
      field: "company",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value,
    },
    {
      ...columnsConfig,
      headerName: "No. Cliente",
      field: "customerNumber",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => value,
    },
    {
      ...columnsConfig,
      field: "actions",
      headerName: "",
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: classes.lastHeaderCell,
      cellClassName: classes.actionCell,
      flex: 0.5,
      renderCell: ({ row }) => (
        <DesignSystemButton
          size="medium"
          variant="tertiaryB"
          label="Eliminar"
          buttonClassName={classes.deleteButton}
          onClick={() => handleDeleteService(row.id)}
        />
      ),
    },
  ];

  return (
    <CustomDataGrid
      classes={{
        root: classes.root,
        columnHeader: classes.headerCell,
      }}
      rowCount={propertyServices?.length || 0}
      columns={columns}
      rows={propertyServices || []}
      loading={false}
      page={currentPage - 1}
      pageSize={pageSize}
      onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
      onPageSizeChange={(params) => {
        dispatch(setPageSize(params));
        dispatch(setCurrentPage(1));
      }}
    />
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
