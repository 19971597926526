import { Theme, makeStyles } from "@material-ui/core/styles";

const useSignStateStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
  },
  gridContainer: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(0.15, 1.5, 0, 1.5),
  },
  buttonLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
  },
  textTitleLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  textLabelInfo: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
  },
  container: {
    padding: theme.spacing(0, 1.5),
  },
  ctaContainer: {
    padding: theme.spacing(0, 1.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  button: {
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
  },
}));

export default useSignStateStyles;
