import { makeStyles, Theme } from "@material-ui/core";

const useLandlordVipStyles = makeStyles<Theme>((theme) => ({
  vipIcon: {
    color: "#fff",
  },
  chip: {
    fontWeight: "bold",
  },
}));

export default useLandlordVipStyles;
