import { useEffect, useMemo } from "react";
import { useGetToken } from "hooks";
import { useLead } from "context/leadContext/leadContext";
import { useTable } from "context/tableContext/tableContext";
import { getLeadActivities } from "context/leadContext/leadActions";
import { TableLayout } from "Components/Admin/UIComponents";
import LeadActivitiesHeader from "./LeadActivitiesHeader";
import ActivitiesTable from "./ActivitiesTable";
import { LeadActivitiesFilters } from "context/leadContext/leadTypes";

function LeadActivities() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();
  const { state: tableStates } = useTable();
  const { currentPage, pageSize, ordering } = tableStates;

  const mappedFilters = useMemo(() => {
    const filters: LeadActivitiesFilters = {
      page: currentPage,
      page_size: pageSize,
    };
    if (ordering) filters.ordering = ordering;
    return filters;
  }, [currentPage, pageSize, ordering]);

  useEffect(() => {
    getToken().then((authToken) => {
      getLeadActivities(state.lead?.id, mappedFilters, dispatch, authToken);
    });
  }, [state.lead, mappedFilters]);

  return (
    <>
      <LeadActivitiesHeader />
      <TableLayout.Table>
        <ActivitiesTable />
      </TableLayout.Table>
    </>
  );
}

export default LeadActivities;
