import { useState } from "react";
import Swal from "sweetalert2";

import { MenuItem, Select } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useValidarDataCreditoStyles from "./ValidarDataCreditoStyles";
import sendGetDatacredito from "services/sendGetDatacredito";
import { useGetToken } from "hooks";

function ValidarDatacredito() {
  const { getToken } = useGetToken();
  const classes = useValidarDataCreditoStyles();
  const [state, setState] = useState({
    document_type: "",
    dni: "",
    last_name: "",
  });

  const sendDatacredito = async () => {
    const data = {
      last_name: state.last_name,
      dni: state.dni,
      document_type: state.document_type,
    };

    const authToken = await getToken();
    const myResp = await sendGetDatacredito(data, authToken);

    if (myResp) {
      switch (myResp.status) {
        case "Aprobado":
          Swal.fire(
            "Candidato aprobado",
            `El candidato es apto con ${myResp.score} puntos`,
            "success"
          );
          break;
        case "Rechazado":
          Swal.fire(
            "Candidato rechazado",
            `El candidato no es apto con ${myResp.score} puntos`,
            "error"
          );
          break;
        case "Warning":
          Swal.fire(
            "Candidato en Warning",
            `El candidato está en un estado de Warning con ${myResp.score} puntos`,
            "info"
          );
          break;
        default:
          Swal.fire(
            "Candidato pendiente",
            "El candidato está en revisión, intenta más tarde",
            "info"
          );
      }
    } else {
      Swal.fire("Ha ocurrido un error", "Por favor intenta nuevamente", "warning");
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const options = [
    { value: "1", label: "Cédula de ciudadanía" },
    { value: "2", label: "NIT" },
    { value: "3", label: "Sociedad extranjera" },
    { value: "4", label: "Cédula de extranjería" },
    { value: "5", label: "Pasaporte" },
  ];

  return (
    <div className={classes.formContainer}>
      <Select
        onChange={(e) => {
          setState({ ...state, document_type: e.target.value });
        }}
        value={state.document_type}
        disableUnderline={true}
        displayEmpty={true}
        className={classes.selectDataCredito}>
        <MenuItem disabled className={classes.item} selected value="">
          Seleccionar
        </MenuItem>
        {options.map((item) => (
          <MenuItem className={classes.item} key={`${item.value}-${item.label}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <input
        name="dni"
        value={state.dni ? state.dni : ""}
        placeholder="Documento"
        onChange={(e) => handleChange(e)}
        className={classes.DniLastnameInput}
      />
      <br />
      <input
        name="last_name"
        value={state.last_name ? state.last_name : ""}
        placeholder="Primer Apellido"
        onChange={(e) => handleChange(e)}
        className={classes.DniLastnameInput}
      />
      <br />
      <DesignSystemButton
        label="Consultar"
        variant="primary"
        size="small"
        onClick={() => sendDatacredito()}
      />
      <br />
      <br />
    </div>
  );
}

export default ValidarDatacredito;
