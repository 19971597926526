import { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import { setForm, getTagTypes } from "context/tagContext/tagActions";
import { useTag } from "context/tagContext/tagContext";
import { CountryUpperCase } from "models/Countries";

import useStyles from "../useTagFormStyles";

function Country() {
  const { state, dispatch } = useTag();
  const classes = useStyles();

  const handleCountryChange = (country: CountryUpperCase) => {
    dispatch(setForm({ ...state.form, country }));
  };

  useEffect(() => {
    getTagTypes(state.form.country, dispatch);
  }, [state.form.country]);

  return (
    <Grid item>
      <Typography className={classes.countryTitle}>Pais del tag marketplace</Typography>
      <CountrySelector stateCountry={state?.form.country} onChange={handleCountryChange} />
    </Grid>
  );
}

export default Country;
