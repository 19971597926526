import { Upload } from "@progress/kendo-react-upload";
import { saveAs } from "file-saver";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import { BACKEND_URL } from "env";
import { ILegacyContractDocuments, ILegacyEvaluationColombia } from "./types";

interface Props {
  uid: number | string;
  saveUrl: string;
  removeUrl: string;
  documentsList: ILegacyContractDocuments[] | ILegacyEvaluationColombia[];
  onAction?: (e: string) => void;
}

const DynamicUploadFile = ({ uid, saveUrl, removeUrl, documentsList, onAction }: Props) => {
  const onBeforeUpload = (event) => {
    if (uid) {
      event.additionalData.uid = uid;
    }
  };

  const onBeforeRemove = (event) => {
    if (uid) {
      event.additionalData.uid = uid;
    }
  };

  const saveFile = (file: string, name: string) => {
    saveAs(file, name);
  };

  return (
    <>
      <Upload
        batch={false}
        multiple={true}
        defaultFiles={[]}
        onBeforeUpload={onBeforeUpload}
        onBeforeRemove={onBeforeRemove}
        withCredentials={false}
        saveUrl={`${BACKEND_URL}${saveUrl}`}
        removeUrl={`${BACKEND_URL}${removeUrl}`}
      />
      <List component="div" aria-label="documents">
        {documentsList &&
          documentsList.map((doc) => (
            <ListItem
              key={doc.filename}
              button
              onClick={() =>
                onAction ? onAction(doc.id as string) : saveFile(doc.url, doc.filename)
              }>
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary={doc.filename} />
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default DynamicUploadFile;
