import { useMemo } from "react";
import { Typography, Divider } from "@material-ui/core";
import { isPossiblePhoneNumber } from "libphonenumber-js";

import { formatPhoneInput } from "utils";
import { BasicOption } from "models/Option";

import {
  ControlledTextField,
  ControlledPhoneInput,
  ControlledAutocomplete,
} from "Components/Admin/UIComponents/ControlledForm";
import useItemCardStyles from "./ItemCardStyles";

export interface ItemCardProps {
  title: string;
  content: string | number;
  /**
   * A text to show in readable mode (editable = false)
   */
  alias?: string | number;
  position?: "horizontal" | "vertical";
  formKey: string;
  multiline?: boolean;
  editable?: boolean;
  options?: BasicOption[];
  editableMode?: "date" | "phone" | "string" | "select" | "number";
  rows?: number;
  disabled?: boolean;
  bold?: boolean;
}

function ItemCard({
  title,
  content,
  alias,
  options = [],
  formKey = "",
  disabled = false,
  editable = false,
  position = "horizontal",
  editableMode = "string",
  multiline = false,
  rows = 2,
  bold = false,
}: ItemCardProps) {
  const classes = useItemCardStyles({ mode: position });

  const phone = useMemo(() => {
    if (!content) return null;
    const isPhone = isPossiblePhoneNumber(content as string);
    if (isPhone) return formatPhoneInput(content as string);

    return null;
  }, [content]);

  const contentText = alias || content || "-";

  return (
    <>
      {!editable && (
        <div className={classes.container}>
          <Typography component="p" className={classes.title}>
            {title}
          </Typography>
          <Typography component="span" className={classes.content}>
            {bold ? <strong>{contentText}</strong> : contentText}
          </Typography>
          {position === "vertical" && <Divider />}
        </div>
      )}
      {editable && (
        <>
          {editableMode === "string" && (
            <ControlledTextField
              id={formKey}
              disabled={disabled}
              label={title}
              defaultValue={content}
              labelClassName={classes.inputLabel}
              textFieldProps={{
                minRows: rows,
                maxRows: rows,
                multiline,
                inputProps: {
                  className: classes.input,
                },
                wrapperPadding: false,
              }}
            />
          )}
          {editableMode === "date" && (
            <ControlledTextField
              id={formKey}
              label={title}
              disabled={disabled}
              defaultValue={content}
              labelClassName={classes.inputLabel}
              textFieldProps={{
                type: "date",
                inputProps: {
                  pattern: "d{4}-d{2}-d{2}", //YYYY-MM-DD
                  className: classes.input,
                },
                wrapperPadding: false,
              }}
            />
          )}
          {editableMode === "select" && (
            <ControlledAutocomplete
              id={formKey}
              options={options}
              labelClassName={classes.inputLabel}
              label={title}
              defaultValue={{ label: "", value: content }}
              textFieldProps={{
                wrapperPadding: false,
                inputProps: {
                  className: classes.input,
                },
                InputProps: {
                  disabled,
                  disableUnderline: true,
                  classes: {
                    root: classes.selectInput,
                  },
                },
              }}
            />
          )}
          {editableMode === "phone" && (
            <ControlledPhoneInput
              id={formKey}
              phoneLabel={title}
              disabled={disabled}
              countryWrapperClassName={classes.countryWrapper}
              numberWrapperClassName={classes.numberWrapper}
              defaultValue={{
                country: phone?.country,
                phone: phone?.phone,
              }}
              labelClassName={classes.inputLabel}
              numberInputProps={{
                inputProps: {
                  className: classes.input,
                },
                InputProps: {
                  disableUnderline: true,
                },
              }}
              countryInputProps={{
                inputProps: {
                  className: classes.input,
                },
                InputProps: {
                  disableUnderline: true,
                  classes: {
                    root: classes.countryInput,
                  },
                },
              }}
            />
          )}
        </>
      )}
    </>
  );
}
export default ItemCard;
