import { IoBagCheckOutline as EvaluatorIcon } from "@houm-com/ui-icons";
import SingleEvaluation from "Components/Admin/administrator/evaluations/SingleEvaluation";
import EvaluationsList from "./screens/EvaluationsList";
import { routeBuilder } from "domains/auth/utils/router-builder";

import Evaluation from "./screens/Evaluation";

export default routeBuilder({
  label: "Evaluator",
  icon: EvaluatorIcon,
  children: [
    {
      to: "/admin/evaluations",
      path: "/admin/evaluations",
      label: "Evaluaciones",
      component: EvaluationsList,
      scope: "permissions",
      strategy: "all",
      require: ["read:evaluations:all"],
    },
    {
      to: "/admin/evaluations/:country/:evaluationId",
      path: "/admin/evaluations/:country/:evaluationId",
      component: Evaluation,
      scope: "roles",
      strategy: "any",
      require: ["admin"],
    },
    {
      path: "/admin/evaluations/:country/:main_id/:codebtor_id",
      component: SingleEvaluation,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
  ],
});
