import { Grid, Typography } from "@material-ui/core";

import useSubscriptionTitleStyles from "./SubscriptionTitleStyles";
import SubscriptionStatus from "../SubscriptionStatus";

interface Props {
  quotationId: number;
  isLeased: boolean;
  isRenewal: boolean;
  contractId: number;
}

const SubscriptionTitle = ({ quotationId, isLeased, isRenewal, contractId }: Props) => {
  const classes = useSubscriptionTitleStyles();

  return (
    <Grid item container>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.title}>
          Suscripción nº {quotationId}
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <SubscriptionStatus
          subscriptionActive={isLeased}
          renewal={isRenewal}
          contractId={contractId}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionTitle;
