import { StringOption } from "models/Option";
import { AppraiserMacrozone } from "models/Appraiser";

export const parseMacrozoneFromReq = (macrozones, removeCountry: boolean = false): StringOption[] =>
  macrozones.map(({ for_selector_id, for_selector_label, for_selector_description }) => {
    const newDescription = removeCountry
      ? for_selector_description.replace(/-((CL)|(CO)|(MX))/g, "")
      : for_selector_description;
    return {
      value: for_selector_id,
      label: `${newDescription}, ${for_selector_label}`,
    };
  });

export const getMacrozoneLabelFromProfile = (macrozones: AppraiserMacrozone[]): string[] =>
  macrozones.map((mczone) => mczone.name.replace("Zona ", ""));

export const getOptionMacrozoneFromProfile = (macrozones: AppraiserMacrozone[]): StringOption[] =>
  macrozones.map((mc) => ({ label: mc.name, value: mc.id.toString() }));
