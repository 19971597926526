import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  filterWrap: {
    padding: theme.spacing(2, 0),
    borderBottom: `0.5px solid ${theme.palette.divider}`,
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  dialogTitleBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: theme.spacing(3),
  },
  labelContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },
  filterLabel: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  filterButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1.5),
  },
  dialogContent: {
    padding: theme.spacing(0, 2),
  },
  textFieldInput: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
  },
  textFieldInputLabel: {
    color: theme.palette.grey[500],
  },
  textFieldInputOutline: {
    borderColor: theme.palette.grey[400],
  },
}));
