interface FormData {
  propertyId: number;
  subscriptionId: number;
  lessorId: number;
  lesseeId: number;
}

export const parseFormData = (formData: FormData) => ({
  property_id: formData.propertyId,
  subscription_id: formData.subscriptionId,
  lessor_id: formData.lessorId,
  lessee_id: formData.lesseeId,
});
