import { ReactNode } from "react";
import clsx from "clsx";

import { Box, Card, CardContent, CardProps, Typography } from "@material-ui/core";
import useAdminCardStyles from "./AdminCardStyles";

interface Props extends Omit<CardProps, "className" | "children" | "component"> {
  className?: string;
  cardContentClassName?: string;
  children: ReactNode;
  title?: string;
  showTitleBackground?: boolean;
  withSpace?: boolean;
  component?: ReactNode;
}

export function AdminCard({
  children,
  className,
  cardContentClassName,
  title,
  showTitleBackground = false,
  withSpace = true,
  component = null,
  ...props
}: Props) {
  const classes = useAdminCardStyles({ showTitleBackground, withSpace });

  return (
    <Card className={clsx(classes.adminCardsRoot, className)} {...props}>
      <CardContent className={cardContentClassName}>
        {title && (
          <Box className={classes.titleContainer}>
            <Typography className={classes.title}>{title}</Typography>
            {component && component}
          </Box>
        )}
        <Box className={classes.cardContent}>{children}</Box>
      </CardContent>
    </Card>
  );
}
