import {
  State,
  Action,
  SET_TODAY_DATA,
  SET_TOMORROW_DATA,
  SET_SUMMARY_DATA,
  SET_LOADING_CHARTS,
  SET_LOADING_SUMMARY,
  SET_LOADING_TABLE,
  SET_APPRAISERS,
  SET_APPRAISERS_COUNT,
  SET_APPRAISER_EVENTS,
  SET_LOADING_EVENTS,
  SET_APPRAISER_PROFILE,
  SET_SHOW_MODAL,
  SET_FILTERS,
  SET_LOADING_CREATE,
  SET_SCHEDULE,
  SET_LOADING_DATA,
  SET_LOADING_UPDATE,
  SET_FILTERED_APPRAISERS,
  SET_LOADING_PHOTO,
  SET_CONFIG_MODAL,
  SET_MACROZONES,
  SET_TRIGGER,
} from "./controlTowerTypes";

export const initialState: State = {
  macrozones: [],
  configModal: {
    isOpen: false,
    title: "",
    subtitle: "",
  },
  todayData: {
    cancelled: 0,
    pending: 0,
    done: 0,
  },
  tomorrowData: {
    cancelled: 0,
    pending: 0,
    done: 0,
  },
  summary: {
    activeAppraisers: 0,
    activePhotographers: 0,
    availableHours: 0,
  },
  loadingCharts: true,
  loadingSummary: true,
  loadingTable: true,
  appraisers: [],
  filteredAppraisers: [],
  appraisersCount: 0,
  appraiserEvents: [],
  loadingEvents: true,
  appraiserProfile: {
    country: "",
    email: "",
    image: "",
    name: "",
    phone: "",
    roles: [],
    macrozones: [],
  },

  eventTypes: [],
  isOpenEditModal: false,
  filters: {
    name: "",
    roles: "",
    country: "",
  },
  loadingCreate: false,
  availability: { availability: [], count: 0 },
  loadingAvailability: false,
  loadingUpdate: false,
  loadingPhoto: false,
  trigger: false,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_TODAY_DATA: {
      return { ...state, todayData: action.todayData };
    }
    case SET_MACROZONES: {
      return { ...state, macrozones: action.macrozones };
    }
    case SET_TOMORROW_DATA: {
      return { ...state, tomorrowData: action.tomorrowData };
    }
    case SET_SUMMARY_DATA: {
      return { ...state, summary: action.summaryData };
    }
    case SET_LOADING_SUMMARY: {
      return { ...state, loadingSummary: action.loadingSummary };
    }
    case SET_LOADING_CHARTS: {
      return { ...state, loadingCharts: action.loadingCharts };
    }
    case SET_LOADING_TABLE: {
      return { ...state, loadingTable: action.loadingTable };
    }
    case SET_APPRAISERS: {
      return { ...state, appraisers: action.appraisers };
    }
    case SET_APPRAISERS_COUNT: {
      return { ...state, appraisersCount: action.appraisersCount };
    }
    case SET_APPRAISER_EVENTS: {
      return { ...state, appraiserEvents: action.appraiserEvents };
    }
    case SET_LOADING_EVENTS: {
      return { ...state, loadingEvents: action.loadingEvents };
    }
    case SET_APPRAISER_PROFILE: {
      return { ...state, appraiserProfile: action.profile };
    }
    case SET_SHOW_MODAL: {
      return { ...state, isOpenEditModal: action.isOpenEditModal };
    }
    case SET_FILTERS: {
      return { ...state, filters: action.filters };
    }
    case SET_LOADING_CREATE: {
      return { ...state, loadingCreate: action.loadingCreate };
    }
    case SET_SCHEDULE: {
      return { ...state, availability: action.availability };
    }
    case SET_LOADING_DATA: {
      return { ...state, loadingAvailability: action.loadingAvailability };
    }
    case SET_LOADING_UPDATE: {
      return { ...state, loadingUpdate: action.loadingUpdate };
    }
    case SET_FILTERED_APPRAISERS: {
      return { ...state, filteredAppraisers: action.filteredAppraisers };
    }
    case SET_LOADING_PHOTO: {
      return { ...state, loadingPhoto: action.loadingPhoto };
    }
    case SET_CONFIG_MODAL: {
      return { ...state, configModal: action.configModal };
    }
    case SET_TRIGGER: {
      return { ...state, trigger: !state.trigger };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
