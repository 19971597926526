import { makeStyles, Theme } from "@material-ui/core";

const useMovementListStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2.5),
    width: "auto",
  },
}));

export default useMovementListStyles;
