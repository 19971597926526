import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1, 2),
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "bold",
    marginTop: 10,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
  formGrid: {
    padding: theme.spacing(2.25),
  },
  textField: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
  divider: {
    backgroundColor: theme.palette.grey[50],
  },
  descriptionInput: {
    top: 5,
    height: 120,
    padding: theme.spacing(2),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
  },
  selection: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  selectionLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: "bold",
    transform: "translate(0px, -8px)",
  },
  tabs: {
    margin: theme.spacing(3, 0, 6),
  },
  submitButton: {
    marginBottom: theme.spacing(11),
    textTransform: "none",
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.25,
    fontWeight: "bold",
    height: 48,
    width: 224,
    borderRadius: 24,
  },
}));

export default useStyles;
