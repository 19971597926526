import { lazy, Suspense } from "react";
import { IoAppsOutline as AppsIcon } from "@houm-com/ui-icons";
import LoadingScreen from "domains/common/components/LoadingScreen";
import { routeBuilder } from "domains/auth/utils/router-builder";

const HoumerGroupsDetailScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-detail-screen" */ "./screens/HoumerGroupsDetailScreen"
    )
);
const HoumerGroupsUserRedirectScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-redirect-screen" */ "./screens/HoumerGroupsUserRedirectScreen"
    )
);
const HoumerGroupsUserProfileScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-profile-screen" */ "./screens/HoumerGroupsUserProfileScreen"
    )
);
const HoumerGroupsUserPropertiesScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-user-properties-screen" */ "./screens/HoumerGroupsUserPropertiesScreen"
    )
);
const HoumerGroupsPropertyScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-property-screen" */ "./screens/HoumerGroupsPropertyScreen"
    )
);
const HoumerGroupsListScreen = lazy(
  () =>
    import(
      /*webpackChunkName "houmer-group-admin-list-screen" */ "./screens/HoumerGroupsListScreen"
    )
);

export default routeBuilder({
  label: "Houmers admin",
  icon: AppsIcon,
  children: [
    {
      to: "/houmers/groups",
      path: "/houmers/groups",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsListScreen />
        </Suspense>
      ),
      label: "Grupos",
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
    {
      path: "/houmers/groups/:groupId",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsDetailScreen />
        </Suspense>
      ),
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
    {
      path: "/houmers/groups/:groupId/users/:userId",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsUserRedirectScreen />
        </Suspense>
      ),
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
    {
      path: "/houmers/groups/:groupId/users/:userId/profile",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsUserProfileScreen />
        </Suspense>
      ),
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
    {
      path: "/houmers/groups/:groupId/users/:userId/properties",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsUserPropertiesScreen />
        </Suspense>
      ),
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
    {
      path: "/houmers/groups/:groupId/users/:userId/properties/:id",
      component: () => (
        <Suspense fallback={<LoadingScreen />}>
          <HoumerGroupsPropertyScreen />
        </Suspense>
      ),
      scope: "permissions",
      strategy: "any",
      require: ["read:houmer_groups:all"],
    },
  ],
});
