import { makeStyles, Theme } from "@material-ui/core";

const useCreateReservationStyles = makeStyles<Theme>((theme) => ({
  createReservationContainer: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useCreateReservationStyles;
