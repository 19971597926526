import ListTable from "./ListTable";

import { TableProvider } from "context/tableContext/tableContext";
import { MultifamilyProvider } from "context/multifamilyContext/multifamily/multifamilyContext";

function MiltyFamilyIndex() {
  return (
    <MultifamilyProvider>
      <TableProvider>
        <ListTable />
      </TableProvider>
    </MultifamilyProvider>
  );
}
export default MiltyFamilyIndex;
