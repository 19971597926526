import { ComponentProps } from "react";

import VisitCard from "../VisitCard";

interface Props {
  currentStep: string;
  content: string;
}

const keyField = ({ currentStep, content }: Props): ComponentProps<typeof VisitCard>["items"] => [
  {
    content,
    title: "",
    formKey: "keysInfo",
    editable: currentStep === "editable",
    position: "vertical",
    multiline: true,
    rows: 10,
  },
];

export default keyField;
