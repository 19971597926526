/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useMemo } from "react";

import { useGetToken } from "hooks";
import { useUsers } from "../context/usersContext";
import { getUsers } from "../context/usersActions";
import { useTable } from "../../../../../context/tableContext/tableContext";
import { UsersTableFilters } from "../../../../../context/tableContext/tableTypes";
import Header from "../components/Header";
import Table from "../components/Table";
import FilterOptions from "../components/FilterOptions";

interface Filters extends UsersTableFilters {
  country: string;
  ordering?: string;
}

export default function UsersTableController() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useUsers();
  const { state: tableState } = useTable();
  const { ordering, tableFilters, currentPage, pageSize } = tableState;
  const mappedFilters = useMemo(() => {
    const filters: Filters = {
      country: state.country.charAt(0).toUpperCase() + state.country.slice(1),
      ...tableFilters,
    };
    if (ordering) filters.ordering = ordering;
    return filters;
  }, [state.country, ordering, tableState.tableFilters]);

  useEffect(() => {
    getToken().then((authToken) =>
      getUsers(pageSize, currentPage, mappedFilters, dispatch, authToken)
    );
  }, [getUsers, mappedFilters, pageSize, currentPage]);

  return (
    <>
      <Header title="Usuarios" />
      <Table />
      <FilterOptions />
    </>
  );
}
