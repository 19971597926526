import { ClosureFormProvider } from "context/closureFormContext/closureFormContext";
import ClosureForm from "./ClosureForm";
import { SnackbarProvider } from "notistack";

export default function ClosureFormController() {
  return (
    <ClosureFormProvider>
      <SnackbarProvider>
        <ClosureForm />
      </SnackbarProvider>
    </ClosureFormProvider>
  );
}
