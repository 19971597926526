import { makeStyles, Theme } from "@material-ui/core";

const useDetailsStyles = makeStyles<Theme>((theme) => ({
  icon: {
    fontSize: theme.typography.pxToRem(50),
    color: "green",
  },
}));

export default useDetailsStyles;
