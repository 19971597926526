import { ReactElement, useRef } from "react";

import IconButton from "@houm-com/ui/IconButton";
import { cn } from "@houm-com/ui/utils";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ReactComponent as Options } from "assets/images/options.svg";

import classes from "./FilterDialogStyles";
import useClickOutside from "../../hooks/useClickOutside";

interface FiltersDialogProps {
  children: ReactElement;
  isLoading: boolean;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  isOpen: boolean;
}

const FiltersDialog = ({
  children,
  isLoading,
  handleCloseModal,
  handleOpenModal,
  isOpen,
}: FiltersDialogProps) => {
  const dialogRef = useRef(null);
  useClickOutside(dialogRef, handleCloseModal);

  return (
    <div className={classes.container}>
      <IconButton
        icon={<Options />}
        type="button"
        position="end"
        variant="tertiary"
        size="sm"
        onClick={handleOpenModal}>
        Filtrar
      </IconButton>
      {isOpen && (
        <>
          <div className={classes.backdrop} />
          <div
            ref={dialogRef}
            className={cn(classes.dialog, {
              [classes.dialogLoading]: isLoading,
            })}>
            <Loading loading={isLoading}>{children}</Loading>
          </div>
        </>
      )}
    </div>
  );
};

export default FiltersDialog;
