import { useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { setCurrentTabValue } from "context/tabContext/tabActions";
import { ITabOption } from "context/tabContext/tabTypes";
import { useTab } from "context/tabContext/tabContext";

import useDesignSystemTabsStyles from "./DesignSystemTabsStyles";

interface IProps {
  options: ITabOption[];
  defaultValue?: string;
  shadow?: boolean;
  expanded?: boolean;
}

function DesignSystemTabs({ options, defaultValue, shadow = false, expanded = false }: IProps) {
  const classes = useDesignSystemTabsStyles({ shadow, expanded });
  const {
    state: { currentTabValue },
    dispatch,
  } = useTab();

  const handleSetTabOnMount = () => {
    if (defaultValue) return dispatch(setCurrentTabValue(defaultValue));
    dispatch(setCurrentTabValue(options[0].value));
  };

  useEffect(handleSetTabOnMount, []);

  if (!currentTabValue) return <CircularProgress />;

  return (
    <div data-testid="container" className={classes.barContainer}>
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          value={currentTabValue}
          aria-label="Seleccionar"
          onChange={(e, val) => dispatch(setCurrentTabValue(val))}>
          {options.map((option) => (
            <Tab
              id={`tab-${option.value}`}
              key={option.value}
              classes={{
                selected: classes.selected,
                root: classes.tabRoot,
              }}
              value={option.value}
              label={option.label}
              disabled={option.isDisabled}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
export default DesignSystemTabs;
