import SpaceDetailsInfo from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/SpaceDetailsInfo";
import SpaceFeaturesTable from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/SpaceFeaturesTable";
import SpaceItemsTable from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/SpaceItemsTable";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import TabPanel from "Components/Admin/UIComponents/Tabs/TabPanel";
import { useProperty } from "context/propertyContext/propertyContext";
import { useTab } from "context/tabContext/tabContext";

function SpaceDetails() {
  const { state } = useProperty();
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  const tabMenuOptions = [
    { label: "Ítems del espacio", value: "features" },
    { label: "Elementos del espacio", value: "items" },
  ];

  return (
    <Loading loading={state.inspectionReport.loading}>
      <>
        <SpaceDetailsInfo />
        <TabMenu options={tabMenuOptions} defaultValue="features" />
        <TabPanel value={currentTabValue} index="features">
          <SpaceFeaturesTable />
        </TabPanel>
        <TabPanel value={currentTabValue} index="items">
          <SpaceItemsTable />
        </TabPanel>
      </>
    </Loading>
  );
}

export default SpaceDetails;
