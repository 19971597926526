export const cancelReasons = [
  "Visita Dummie no coordinada",
  "No se pudo coordinar llaves con propietario",
  "No se pudo coordinar llaves con arrendatario",
  "Visita reagendada",
  "Cliente pidió cancelar la visita",
  "Visita duplicada",
  "Test",
  "Propiedad fuera de cobertura",
  "Cliente ya no está interesado",
];

const timezonesArray = [
  { value: "America/Santiago", label: "Chile" },
  { value: "America/Bogota", label: "Colombia" },
  { value: "America/Mexico_City", label: "Ciudad de Mexico" },
  { value: "America/Tijuana", label: "Tijuana" },
];

const countries = ["Chile", "Colombia", "Mexico", "Todos"];

const photoText = "Disponibilidad del fotografo";
const propertyText = "Disponibilidad de la propiedad";

const availableText = {
  Advisory: "Horario HA",
  Visit: propertyText,
  SalesVisit: propertyText,
  Photo: photoText,
  SalesPhoto: photoText,
};

const getAvailableText = (text) => availableText[text] || propertyText;

export { countries, timezonesArray, getAvailableText };
