import { useEffect, useMemo } from "react";

import { useGetToken } from "hooks";
import { TableLayout } from "Components/Admin/UIComponents";
import Table from "./Table";
import Header from "./Header";
import FilterOptions from "./FilterOptions";
import { useProject } from "context/projectContext/projectContext";
import { getProjects } from "context/projectContext/projectActions";
import { useTable } from "context/tableContext/tableContext";
import { Filters } from "context/projectContext/projectTypes";
import TableHeader from "./TableHeader";

function PropertyIndex() {
  const { state, dispatch } = useProject();
  const { getToken } = useGetToken();
  const { state: tableStates } = useTable();
  const { ordering, tableFilters, pageSize, currentPage } = tableStates;

  const mappedFilters = useMemo(() => {
    const filters: Filters = {
      country: state.country.charAt(0).toUpperCase() + state.country.slice(1),
      ...tableFilters,
    };
    if (ordering) filters.ordering = ordering;
    return filters;
  }, [state.country, ordering, tableFilters]);

  useEffect(() => {
    getToken().then((authToken) =>
      getProjects(pageSize, currentPage, mappedFilters, dispatch, authToken)
    );
  }, [getProjects, mappedFilters, pageSize, currentPage]);

  return (
    <TableLayout>
      <Header title="Proyectos Inmobiliarios" />
      <TableLayout.Table>
        <TableHeader />
        <Table />
      </TableLayout.Table>
      <FilterOptions />
    </TableLayout>
  );
}

export default PropertyIndex;
