import { useHistory } from "react-router-dom";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import useEvaluationsListStyles from "./EvaluationsListStyles";

const EvaluationsList = ({ evaluations }) => {
  const history = useHistory();
  const classes = useEvaluationsListStyles();

  return (
    <AdminCard title="Evaluaciones Involucradas" withSpace={false} showTitleBackground={true}>
      <Table aria-label="evaluations table">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {evaluations.map((evaluation) => (
            <TableRow
              key={evaluation.id}
              onClick={() =>
                history.push(
                  `/admin/evaluations/cl/${evaluation.main_applicant.id}/${evaluation.user.id}`
                )
              }
              aria-label="Ir a evaluación"
              hover
              classes={{
                hover: classes.row,
              }}>
              <TableCell>{`${evaluation.user.name} ${evaluation.user.last_name} ${evaluation.user.second_last_name}`}</TableCell>
              <TableCell>{evaluation.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AdminCard>
  );
};

export default EvaluationsList;
