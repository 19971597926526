import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { Moment } from "moment";

import scheduleService from "services/schedulesService";
import { useGetToken } from "hooks";

interface IVisitSchedule {
  begin_hour: Moment;
  end_hour: Moment;
  prop: string;
  date: Moment;
  type: "Exit" | "Inspection" | "Cleaning";
  origin: string;
  comment?: string;
}

const useCreateVisitSchedule = () => {
  const { getToken } = useGetToken();

  const DEFAULT_ERROR = {
    message: "UNHANDLED_ERROR",
  };

  const { mutate, isError, isLoading, isSuccess, error } = useMutation(
    async (data: IVisitSchedule) => {
      const authToken = await getToken();
      const response = await scheduleService.createVisitScheduleV2(data, authToken);
      return response?.data;
    }
  );

  const getErrorMessage = () => {
    if ((error as AxiosError)?.response?.status === 500 || !(error as AxiosError)?.response?.data)
      return DEFAULT_ERROR;

    return (
      error as AxiosError<{
        message: string;
        extra?: {
          quotation_id?: number;
          schedule_type?: string;
          date?: string;
        };
      }>
    )?.response?.data;
  };

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
    error: getErrorMessage(),
  };
};

export default useCreateVisitSchedule;
