import { useEffect } from "react";

export default function useFocusError(errors, setFocus) {
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => (errors[field] ? field : a), null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
}
