import { StatusMemberEnum, EvaluationMemberStatus } from "domains/evaluations/models/Evaluations";

export const EvaluationMemberStatusDictionary: {
  [locale in EvaluationMemberStatus]: string;
} = {
  [StatusMemberEnum.COMMERCIALLY_APPROVED]: "Aprobada comercialmente",
  [StatusMemberEnum.DESISTED]: "Desistida",
  [StatusMemberEnum.PENDING_DOCS]: "Documentación pendiente",
  [StatusMemberEnum.READY_FOR_EVALUATION]: "Lista para evaluar",
  [StatusMemberEnum.NEW_DOCS]: "Nuevos documentos",
  [StatusMemberEnum.APPROVED]: "Perfil aprobado",
  [StatusMemberEnum.REJECTED]: "Perfil rechazado",
  [StatusMemberEnum.START]: "Por comenzar",
  [StatusMemberEnum.NO_DOCS]: "Sin documentos",
};
