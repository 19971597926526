import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import axios from "axios";
import { AUTH0_DOMAIN, AUTH0_CLIENTID, AUTH0_AUDIENCE } from "env";

import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

export function useAuth() {
  const { loginWithRedirect, ...rest } = useAuth0();
  const redirectToLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
    return null;
  };
  return {
    ...rest,
    redirectToLogin,
  };
}

// FIXME: we should be able to use useAuthClient as a react query useQuery so we dont need to add the enable === status on our queries.
export function useAuthClient(options = {}) {
  const { getAccessTokenSilently } = useAuth0();
  const { data: token, ...rest } = useQuery(
    "auth-token",
    () => getAccessTokenSilently({ audience: AUTH0_AUDIENCE }),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      ...options,
    }
  );
  const client = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return {
    ...rest,
    data: token,
    client,
  };
}

// FIXME: wip of a useAuthQuery hook with authFn as a param
// interface UseAuthQuery<T> extends UseQueryOptions {
//   authFn: (client: AxiosInstance) => Promise<T>;
// }

// export function useAuthQuery<T>(options: UseAuthQuery<T>) {
//   const { status, client } = useAuthClient();
//   return useQuery({
//     ...options,
//     enabled: status === "success",
//     queryFn: () => options.authFn(client),
//   });
// }

export const AuthProvider = ({ children }: { children: React.ReactElement | null }) => {
  const history = useHistory();
  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};
