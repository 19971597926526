import { makeStyles, Theme } from "@material-ui/core/styles";

const useCalendarStyles = makeStyles((theme: Theme) => ({
  paperContainer: {
    width: "100%",
    padding: theme.spacing(1.5),
    height: "100%",
  },
  modal: {
    position: "absolute",
    overflow: "scroll",
  },
  arrowForward: {
    marginLeft: theme.spacing(2),
  },
  arrowBack: {
    marginRight: theme.spacing(2),
  },
  arrowsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2.5, 1.25),
  },
}));

export default useCalendarStyles;
