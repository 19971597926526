import { Theme, makeStyles } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

interface Props {
  isNegative?: boolean;
}

const useDiscountFormStyles = makeStyles<Theme, Props>((theme) => ({
  formContent: {
    width: "100%",
  },
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
  select: {
    border: "none",
    background: theme.palette.grey[50],
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
    color: theme.palette.grey[500],
  },
  submitButton: {
    marginTop: theme.spacing(3),
    display: "flex",
    gap: theme.spacing(2),
  },
  textInput: {
    background: theme.palette.grey[50],
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
    border: ({ isNegative }) => (isNegative ? "1px solid #AE0400" : "none"),
  },
  errorMessage: {
    marginLeft: theme.spacing(1),
    color: "#AE0400",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
  descriptionInput: {
    top: 5,
    minHeight: 120,
    padding: theme.spacing(1),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
    color: theme.palette.grey[500],
    background: theme.palette.grey[50],
  },
}));

export default useDiscountFormStyles;
