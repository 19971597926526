import { CountryUpperCase, Currency, Locale } from "models/Countries";

const countryCurrency = (country: CountryUpperCase): CurrencyAndCode => {
  switch (country) {
    case "Chile":
      return {
        currency: "CLP",
        code: "cl",
      };
    case "Colombia":
      return { currency: "COP", code: "co" };
    case "Mexico":
      return { currency: "MXN", code: "mx" };
    default:
      return { currency: "MXN", code: "mx" };
  }
};

export const localeToCurrency: { [locale in Locale]: Currency } = {
  co: "COP",
  cl: "CLP",
  mx: "MXN",
};

export const currencyToLocale = {
  COP: "Colombia",
  CLP: "Chile",
  MXN: "Mexico",
};

export const currencyParse = {
  cl: {
    pesos: "CLP",
    uf: "UF",
    "u.f.": "UF",
  },
  mx: {
    pesos: "MXN",
  },
  co: {
    pesos: "COP",
  },
};

export default countryCurrency;

interface CurrencyAndCode {
  currency: Currency;
  code: Locale;
}
