import { makeStyles, Theme } from "@material-ui/core";

const useModifyServiceModalStyles = makeStyles<Theme>((theme) => ({
  deleteBasicServiceRoot: {
    padding: theme.spacing(1, 4),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
    marginLeft: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttons: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
  textContainer: {
    marginTop: theme.spacing(2.5),
  },
}));

export default useModifyServiceModalStyles;
