import { Grid, Typography } from "@material-ui/core";

import ErrorOutline from "@material-ui/icons/ErrorOutline";

import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useProject } from "context/projectContext/projectContext";
import { setUnitsUploadErrors, setUnitsUploadModal } from "context/projectContext/projectActions";
import useStyles from "./useUnitsUploadModalStyles";
import UnitsUploadForm from "./UnitsUploadForm";
import ErrorsList from "./ErrorsList";

function UnitsUploadModal() {
  const { state, dispatch } = useProject();
  const classes = useStyles();
  const templateFileLink = "https://houm-production-public.s3.amazonaws.com/template.xlsx";

  const handleOnClose = () => {
    dispatch(setUnitsUploadModal(false));
    dispatch(setUnitsUploadErrors([]));
  };

  return (
    <CustomDialog
      open={state.openUnitsUploadModal}
      onClose={handleOnClose}
      title="Unidad de forma masiva">
      <div className={classes.modal}>
        <Grid className={classes.header} container justifyContent="space-between">
          <Grid item>
            <Typography component="strong" className={classes.boldText}>
              Excel de la unidad
            </Typography>
            <Typography component="span" className={classes.file}>
              template.xlsx
            </Typography>
          </Grid>
          <LinkButton onClick={handleOnClose}>
            <DownloadIcon className={classes.icon} />
            <a className={classes.link} href={templateFileLink}>
              Descargar excel editable
            </a>
          </LinkButton>
          <div className={classes.warning}>
            <ErrorOutline />
            <Typography component="p" className={classes.warningText}>
              <Typography component="strong" className={classes.boldText}>
                No cierres o refresques esta página{" "}
              </Typography>
              cuando estes cargando el excel,{" "}
              <Typography component="strong" className={classes.boldText}>
                ya que los cambios no se veran reflejados
              </Typography>
            </Typography>
          </div>
        </Grid>
        {state.unitsUploadErrors.length > 0 && <ErrorsList errors={state.unitsUploadErrors} />}
        <UnitsUploadForm handleOnClose={handleOnClose} />
      </div>
    </CustomDialog>
  );
}

export default UnitsUploadModal;
