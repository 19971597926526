import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import contractService from "services/contractService";
import useGetToken from "hooks/useGetToken";

const useDeletePlanMovement = (movementId: number, typeMovement: string, onSuccess: () => void) => {
  const { getToken } = useGetToken();

  const {
    mutate: deletePlanMovement,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useMutation(
    async () => {
      const authToken = await getToken();
      const response = await contractService.deletePlanMovements(movementId, authToken);
      return response.data;
    },
    {
      onSuccess: () => {
        alert.success({
          message: `${typeMovement} eliminado`,
          disclaimer: `El ${typeMovement} ha sido eliminado exitosamente`,
        });
        onSuccess();
      },
      onError: (e) => {
        alert.error({
          message: `Error al eliminar ${typeMovement}`,
          disclaimer: `No se puedo eliminar ${typeMovement}, error: ${e}`,
        });
      },
    }
  );

  return {
    deletePlanMovement,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useDeletePlanMovement;
