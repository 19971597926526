import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { Subscription } from "models/Subscription";

interface Props {
  option: Subscription;
  value: string;
}

const Option = ({ option, value }: Props) => (
  <Link to={`/admin/clients/${option.legacy_id}`}>
    <div>
      <Highlighter
        searchWords={[value]}
        autoEscape
        textToHighlight={`ID ${option.legacy_id} ${option.property?.address} ${option.property?.street_number} ${option.property?.numero_depto}`}
      />
      <br />
      <p style={{ fontSize: "11px", fontWeight: "bold", marginBottom: "0px" }}>
        <Highlighter
          searchWords={[value]}
          autoEscape
          textToHighlight={`Arrendatario ${option.contract?.main_tenant?.name} ${option.contract?.main_tenant?.email} ${option.contract?.main_tenant?.rut}`}
        />
      </p>
      <p style={{ fontSize: "11px", fontWeight: "bold", marginBottom: "0px" }}>
        <Highlighter
          searchWords={[value]}
          autoEscape
          textToHighlight={`Propietario ${option.contract?.owner?.name} ${option.contract?.owner?.email} ${option.contract?.owner?.rut}`}
        />
      </p>
    </div>
  </Link>
);

export default Option;
