import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import momentTz from "moment-timezone";
import { Box, Grid, Typography, CircularProgress, Divider, ThemeProvider } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Swal from "sweetalert2";

import scheduleService from "services/schedulesService";

import applicantService from "services/applicantService";

import { parseScheduleAvailability } from "utils/datetime";
import SelectHour from "./SelectHour";
import { NextButton, ApplicantAutocomplete, Selectable } from "../../components/FormElements";
import schedulersStyles from "./SchedulersStyle";
import { IOption } from "../../components/FormElements/Selectable";
import { useGetToken } from "hooks";
import CustomThemePicker from "./customDatePickerStyles";
import { getTimezone } from "utils";
import { PropertyData } from "models/Property";

interface IAvailability {
  [k: string]: string[];
}

enum scheduleTypesVisit {
  RENT = "Visit",
  SALE = "SalesVisit",
}
const optionButtons: IOption[] = [
  { label: "Visita arriendo", value: scheduleTypesVisit.RENT },
  { label: "Visita venta", value: scheduleTypesVisit.SALE },
];
function ScheduleUser({ property }: { property: PropertyData }) {
  const [type, setType] = useState<scheduleTypesVisit>(
    property.for_rental ? scheduleTypesVisit.RENT : scheduleTypesVisit.SALE
  );
  const [loadingReq, setLoadingReq] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const [allowedDays, setAllowedDays] = useState<moment.Moment[]>([]);
  const [availability, setAvailability] = useState<IAvailability | undefined>();
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
  const [aviliableHours, setAviliableHours] = useState<string[]>([]);
  const [availabilityParsed, setAvailabilityParsed] = useState<IAvailability | undefined>();

  const selectHourRef = useRef(null);
  const typeRef = useRef(null);
  const userRef = useRef(null);
  const history = useHistory();
  const { getToken } = useGetToken();

  useEffect(() => {
    momentTz.tz.setDefault();
    typeRef.current?.setValue(type);
  }, []);

  useEffect(() => {
    const getAvailability = async () => {
      if (!type) return;
      setLoading(true);
      const authToken = await getToken();
      const availabilityResponse = (await scheduleService.getAvailableHours(
        type,
        property?.id,
        authToken
      )) as AxiosResponse<any>;
      setLoading(false);
      setAvailability(availabilityResponse?.data);
    };
    if (property) {
      getAvailability();
    }
  }, [type]);

  useEffect(() => {
    const parsedAvailability = parseScheduleAvailability(
      availability,
      getTimezone(property?.country, property?.comuna)
    );
    setAvailabilityParsed(parsedAvailability);

    if (Object.keys(parsedAvailability).length > 0) {
      setIsEmpty(false);
      setAllowedDays(Object.keys(parsedAvailability).map((value) => moment(value)));

      setSelectedDay(moment(Object.keys(parsedAvailability)[0]));

      const filteredHours = parsedAvailability[Object.keys(parsedAvailability)[0]];

      setAviliableHours(filteredHours);
      selectHourRef.current?.setValue(filteredHours[0]);
    } else {
      setIsEmpty(true);
    }
  }, [availability]);

  const handleChangeDay = (day) => {
    if (day.isValid() && availability) {
      setSelectedDay(day);
      const newDay = Object.keys(availabilityParsed).find((item) =>
        moment(item).isSame(day, "date")
      );
      const filteredHours = availabilityParsed[newDay];
      setAviliableHours(filteredHours);
    }
  };
  const handleSubmit = async () => {
    const { user } = userRef.current.getValue();
    if (!user) {
      Swal.fire({
        title: "ERROR",
        type: "error",
        text: "Es necesario seleccionar un usuario",
      });
      return;
    }
    if (selectHourRef.current?.validate()) {
      const authToken = await getToken();
      const selectedHour = selectHourRef.current.getValue().hour;
      const beginHour = moment
        .utc(selectedHour)
        .clone()
        .tz(getTimezone(property.country, property?.comuna));
      const endHour = moment
        .utc(selectedHour)
        .clone()
        .add(2, "hours")
        .tz(getTimezone(property.country, property?.comuna));
      setLoadingReq(true);
      // HOTFIX: deleted these values due to endpoint update
      delete user.occupation;
      delete user.description;
      const res = (await applicantService.store(
        {
          ...user,
          property_id: property?.id,
          for_sale: type === scheduleTypesVisit.SALE,
        },
        authToken
      )) as AxiosResponse<any>;
      if (res) {
        const resVisit = await scheduleService.createVisitSchedule(
          {
            begin_hour: beginHour,
            end_hour: endHour,
            prop: property?.id,
            date: beginHour,
            applicant: res.data.id,
            type,
            origin: "Admin - Propiedad",
          },
          authToken
        );
        if (resVisit) {
          Swal.fire({
            type: "success",
            title: "La visita ha sido agendada exitósamente.",
            timer: 7500,
          }).then(() => history.go(0));
        } else {
          Swal.fire({
            type: "error",
            title: "No se ha podido agendar la visita.",
            timer: 7500,
          });
        }
      }
      setLoadingReq(false);
    }
  };
  const classes = schedulersStyles();
  return (
    <Box p={1}>
      {loading && <CircularProgress />}
      {property.for_rental && property.for_sale && (
        <Box p={1} mb={3}>
          <Selectable
            required
            ref={typeRef}
            onChange={setType}
            compressed
            wired
            options={optionButtons}
            id="type"
            label="Tipo de visita"
          />
        </Box>
      )}
      {isEmpty && !loading && (
        <Typography variant="h6">No se ha encontrado agenda disponible</Typography>
      )}
      {!isEmpty && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ApplicantAutocomplete required ref={userRef} label="Nombre o mail" />
            <Divider />
          </Grid>
          <Grid item sm={12} className={classes.tabs}>
            <ThemeProvider theme={(theme) => CustomThemePicker(theme)}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  shouldDisableDate={(date) => !allowedDays.find((day) => day.isSame(date, "date"))}
                  value={selectedDay}
                  variant="static"
                  disableToolbar
                  disablePast
                  onChange={handleChangeDay}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Grid>
          <Grid item sm={12}>
            <SelectHour
              id="hour"
              hours={aviliableHours}
              day={selectedDay.format("YYYY-MM-DD")}
              country={property?.country || "Chile"}
              commune={property?.comuna}
              ref={selectHourRef}
            />
          </Grid>
          <Box justifyContent="flex-end" display="flex" width="100%">
            <NextButton disabled={loadingReq} onClick={handleSubmit}>
              {loadingReq ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                "Agendar visita de usuario"
              )}
            </NextButton>
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default ScheduleUser;
