import { makeStyles, Theme } from "@material-ui/core";

const useNoQuestionsStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: 560,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
  },
}));

export default useNoQuestionsStyles;
