import { makeStyles, Theme } from "@material-ui/core";

const useTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 700,
    lineHeight: 1.375,
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useTitleStyles;
