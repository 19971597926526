import { useMemo, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { useGetToken } from "hooks";
import { debounce } from "lodash";

import { Locale } from "models/Countries";
import propertyService from "services/propertyService";

import { OptionType } from "./utils";

interface UseUpdateNeighborhoodsProps {
  country: Locale;
}

const useGetNeighborhoods = ({ country }: UseUpdateNeighborhoodsProps) => {
  const { getToken } = useGetToken();
  const [searchValue, setSearchValue] = useState("");
  const [isDebounceLoading, setIsDebounceLoading] = useState(false);

  const {
    isLoading,
    error,
    data = [],
  }: UseQueryResult<OptionType[], Error> = useQuery(
    ["neighborhoods", searchValue],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getAvailableNeighborhoods(
        country,
        authToken,
        searchValue
      );
      setIsDebounceLoading(false);
      return response.data.results.map((neighborhood) => ({
        value: neighborhood.id,
        label: neighborhood.neighborhood,
      }));
    },
    {
      enabled: !!searchValue,
    }
  );

  const debounceSearchNeighborhoods = useMemo(
    () =>
      debounce(async (value) => {
        setSearchValue(value);
      }, 1000),
    []
  );

  const handleNeighborhoodChange = ({ target: { value } }) => {
    setIsDebounceLoading(true);
    debounceSearchNeighborhoods(value);
  };

  return { handleNeighborhoodChange, data, isLoading: isLoading || isDebounceLoading, error };
};

export default useGetNeighborhoods;
