import { View, Text } from "@react-pdf/renderer";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";
import { Inspection } from "hooks/useInspectionReports/types";

interface TenantProofPageProps {
  inspectionReport: Inspection;
}

const TenantProofPage = ({ inspectionReport }: TenantProofPageProps) => {
  const styles = useInspectionReportDocumentStyles;
  return (
    <View style={styles.pageContent}>
      <View style={styles.tenantProofSection}>
        {/* Temporary disabled
        <Image
          style={styles.tenantProofImage}
          source={inspectionReport?.tenantProof?.signature_url}
        /> */}
        <Text style={styles.tenantProofCaption}>Firmado por arrendatario:</Text>
        <Text style={styles.tenantProofCaption}>{inspectionReport?.tenantProof?.name}</Text>
      </View>
    </View>
  );
};

export default TenantProofPage;
