import { Typography } from "@material-ui/core";

import useStyles from "./HeaderDataItemStyles";

interface Props {
  label: string;
  value: string | number;
}

function HeaderDataItem({ label, value }: Props) {
  const classes = useStyles();
  return (
    <Typography className={classes.text}>
      {label}:{" "}
      <Typography component="span" className={classes.textBold}>
        {value}
      </Typography>
    </Typography>
  );
}

export default HeaderDataItem;
