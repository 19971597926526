import { SearchFilterTypes } from "./types";

export const getSearchPlaceholder = (filter: SearchFilterTypes): string => {
  switch (filter) {
    case "propertyId":
      return "Búsqueda por ID de propiedad";
    case "quotationId":
      return "Búsqueda por ID de suscripción";
    case "document":
      return "Búsqueda por número de documento (debe ser exacto)";
    case "tenant":
      return "Búsqueda por nombre completo, mail o número de documento de arrendatario";
    case "search":
    default:
      return "Búsqueda por Propietario o ID Suscripción";
  }
};
