import { Typography } from "@material-ui/core";

import ApplicantFullName from "./components/ApplicantFullName";
import Applicant from "./components/Applicant";

function ApplicantsInfo({ evaluations }) {
  return (
    <>
      {evaluations.length > 0 && (
        <Applicant applicantType="Arrendatario principal">
          <ApplicantFullName user={evaluations[0].user} />
        </Applicant>
      )}
      {evaluations.length > 1 && (
        <Applicant applicantType="Codeudores">
          {evaluations.slice(1).map((item) => (
            <Typography variant="body2">
              <ApplicantFullName user={item.user} />
            </Typography>
          ))}
        </Applicant>
      )}
    </>
  );
}

export default ApplicantsInfo;
