import { FormProvider, useForm } from "react-hook-form";

import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import ControlledSelection from "Components/Admin/UIComponents/ControlledForm/ControlledSelection";

import useBasicServiceFormStyles from "./BasicServiceFormStyles";
import { BasicServiceProps } from "../../utils/parsers";
import { ServiceForm } from "../../utils/types";

interface Props {
  availableServices: BasicServiceProps[];
  clientNumber: string;
  company: string;
  handleClose: () => void;
  onSubmitData: (data: ServiceForm) => void;
  submitServiceLoading: boolean;
}

function BasicServiceForm({
  availableServices,
  clientNumber,
  company,
  handleClose,
  onSubmitData,
  submitServiceLoading,
}: Props) {
  const classes = useBasicServiceFormStyles();

  const methods = useForm<ServiceForm>({ reValidateMode: "onBlur" });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => onSubmitData(data))}>
        <Box className={classes.form}>
          <Box>
            <Typography className={classes.selectLabel}>Proveedor</Typography>
            <ControlledSelection
              id="company"
              defaultValue={company}
              options={availableServices}
              fullWidth
              simple
              classes={{
                container: classes.selectRoot,
                select: { root: classes.select },
                buttonList: classes.selectButtonList,
              }}
            />
          </Box>
          <ControlledTextField
            id="clientNumber"
            defaultValue={clientNumber}
            label="Número de cliente"
            wrapperPadding={false}
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.input,
                },
              },
            }}
          />
        </Box>
        <Box className={classes.buttons}>
          <DesignSystemButton
            variant="secondary"
            size="small"
            label="Cancelar"
            onClick={handleClose}
            buttonClassName={classes.cancelButton}
          />
          <DesignSystemButton
            variant="primary"
            size="small"
            label="Confirmar"
            type="submit"
            isLoading={submitServiceLoading}
          />
        </Box>
      </form>
    </FormProvider>
  );
}

export default BasicServiceForm;
