import { useState } from "react";

import Spinner from "Components/Shared/partials/Spinner";
import { CountryUpperCase, LocaleCountries } from "models/Countries";
import useGetPromotions from "hooks/useGetPromotions";
import { LeaseType, PromotionStatus } from "hooks/useGetPromotions/utils/types";

import usePromotionsListStyles from "./PromotionsListStyles";
import PromotionCard from "./components/PromotionCard";
import ErrorMessage from "./components/ErrorMessage";
import EditPromotionModal from "../EditPromotion/EditPromotionModal";
import countryCode from "utils/countryCode";

interface Props {
  status: PromotionStatus;
  leaseType: LeaseType;
  country: CountryUpperCase;
}

const PromotionsList = ({ status, leaseType, country }: Props) => {
  const [selectedPromotionToEdit, setSelectedPromotionToEdit] = useState(null);
  const classes = usePromotionsListStyles();
  const { data, isError, isLoading } = useGetPromotions(
    status,
    leaseType,
    countryCode(country) as LocaleCountries
  );

  if (isError) return <ErrorMessage />;
  if (isLoading) return <Spinner className={classes.spinner} />;
  return (
    <>
      {data.map((promotion) => (
        <div key={promotion.id} className="p-2 xs:w-full md:w-1/2">
          <PromotionCard
            promotion={promotion}
            setSelectedPromotionToEdit={setSelectedPromotionToEdit}
          />
        </div>
      ))}
      <EditPromotionModal
        promotion={selectedPromotionToEdit}
        onHandleClose={() => setSelectedPromotionToEdit(null)}
      />
    </>
  );
};

export default PromotionsList;
