import zod from "zod";

export interface FormattedAddress {
  prevSearch: string;
  neighborhood: string;
  city: string;
  street: string;
  streetNumber: string;
  commune: string;
  region: string;
  country: string;
  lat: number;
  lng: number;
}

export interface AddressComponentItem {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface RawAddress {
  address_components: AddressComponentItem[];
  formatted_address: string;
  types: string;
}

export const addressSearchSchema = zod.object({
  full_address: zod.string(),
  reference_id: zod.string(),
  reference_provider: zod.string(),
});

export const addressSearchResultsSchema = zod.object({
  results: zod.array(addressSearchSchema),
});
export type AddressSearch = zod.infer<typeof addressSearchSchema>;

export const addressDetailsSchema = zod
  .object({
    street: zod.string(),
    street_number: zod.string().nullable(),
    city: zod.string().nullable(),
    full_address: zod.string(),
    latitude: zod.number(),
    longitude: zod.number(),
    country: zod.string(),
    region: zod.string().nullable(),
    commune: zod.string().nullable(),
    neighborhood: zod.string().nullable(),
  })
  .transform((data) => ({
    ...data,
    prevSearch: data.full_address,
    streetNumber: data.street_number,
    lat: data.latitude,
    lng: data.longitude,
    parsed: true,
  }));
// need this object to parse data in the service handler
export type AddressDetails = zod.infer<typeof addressDetailsSchema>;
