import { makeStyles, Theme } from "@material-ui/core";

const useObservationFormStyles = makeStyles((theme: Theme) => ({
  buttons: {
    marginRight: theme.spacing(2),
  },
  formContent: {
    width: "100%",
  },
  descriptionInput: {
    top: 5,
    padding: theme.spacing(1),
    alignItems: "flex-start",
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[50]}`,
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
  },
  box: {
    padding: theme.spacing(1, 4),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
}));

export default useObservationFormStyles;
