/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Grid, makeStyles, Select } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTable } from "../../../../../context/tableContext/tableContext";
import { PropertyRentalStatus, PropertySaleStatus } from "../../../../../models/Property";
import { TableFilters } from "../../../../../context/tableContext/tableTypes";
import { setShowModal, setTableFilters } from "../../../../../context/tableContext/tableActions";
import { useProperties } from "../context/propertiesContext";
import { ButtonSelect } from "../../../UIComponents/Buttons/ButtonSelect";
import FilterDialog from "../../../UIComponents/FilterDialog";
import statusTranslator from "../../../../../utils/statusTranslator";

import { propertyRentalStatus, propertySaleStatus } from "../../../../../utils/propertyStatus";

const published = {
  Publicada: true,
  "No publicada": false,
};

function FilterOptions() {
  const classes = useStyles();

  const [idFilter, setIdFilter] = useState<string>("");
  const [isPublishedFilter, setIsPublishedFilter] = useState<boolean | null>();
  const [statusOptions, setStatusOptions] = useState<
    PropertyRentalStatus[] | PropertySaleStatus[]
  >();
  const [statusFilter, setStatusFilter] = useState<
    PropertyRentalStatus | PropertySaleStatus | null
  >();
  const [houmOwnerFilter, setHoumOwnerFilter] = useState<string>("");
  const [homeCheckerFilter, setHomeCheckerFilter] = useState<string>("");
  const [landlordEmailFilter, setLandlordEmailFilter] = useState<string>("");
  const [withHoumBox, setWithHoumBox] = useState<boolean>();

  const handleChangeWithHoumbox = useCallback(
    (val) => {
      if (val === withHoumBox) {
        setWithHoumBox(null);
      } else {
        setWithHoumBox(val);
      }
    },
    [setWithHoumBox]
  );

  const isPublishedClickHandler = (value: boolean) => {
    setIsPublishedFilter((prev) => (prev === value ? null : value));
  };

  const { state } = useProperties();
  const { state: tableState, dispatch } = useTable();

  useEffect(() => {
    if (state.market === "rental") {
      setStatusOptions(propertyRentalStatus);
    } else {
      setStatusOptions(propertySaleStatus);
    }
  }, [state.market]);

  const publishedCondition = useMemo(
    () => (state.market === "sales" ? "published" : "published,republished"),
    [state.market]
  );

  const handleFilterModal = () => dispatch(setShowModal(false));

  const handleSubmit = useCallback(() => {
    const filters: TableFilters = {
      has_houm_box: withHoumBox,
      ...(idFilter && { id: idFilter }),
      ...(houmOwnerFilter && { houm_owner: houmOwnerFilter }),
      ...(landlordEmailFilter && { landlord: landlordEmailFilter }),
      ...(homeCheckerFilter && { homechecker: homeCheckerFilter }),
      ...(isPublishedFilter === false && {
        [`${state.market}_status_not`]: publishedCondition,
      }),
      ...(statusFilter &&
        isPublishedFilter && {
          [`${state.market}_status`]: `${statusFilter},published,republished`,
        }),
      ...(!statusFilter &&
        isPublishedFilter && {
          [`${state.market}_status`]: publishedCondition,
        }),
      ...(statusFilter && { [`${state.market}_status`]: statusFilter }),
    };

    dispatch(setTableFilters(filters));
    dispatch(setShowModal(false));
  }, [
    dispatch,
    idFilter,
    isPublishedFilter,
    houmOwnerFilter,
    statusFilter,
    landlordEmailFilter,
    state.market,
    withHoumBox,
  ]);

  const handleDelete = () => {
    setIsPublishedFilter(null);
    setHoumOwnerFilter("");
    setLandlordEmailFilter("");
    setStatusFilter(null);
    setIdFilter("");
    dispatch(setTableFilters({}));
    dispatch(setShowModal(false));
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleFilterModal}
      onEscapeKeyDown={handleFilterModal}>
      <FilterDialog.Content>
        <FilterDialog.Item label="Buscar por ID">
          <FilterDialog.TextField
            type="number"
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Publicación" container spacing={1}>
          {Object.entries(published).map(([label, value]) => (
            <Grid item key={`type-selector-${value}`}>
              <ButtonSelect
                label={label}
                isActive={isPublishedFilter === value && isPublishedFilter !== null}
                onClick={() => isPublishedClickHandler(value)}
              />
            </Grid>
          ))}
        </FilterDialog.Item>
        <FilterDialog.Item label="Houmbox" container spacing={1}>
          <Grid item>
            <ButtonSelect
              label="Si"
              isActive={withHoumBox === true}
              onClick={() => handleChangeWithHoumbox(true)}
            />
          </Grid>
          <Grid item>
            <ButtonSelect
              label="No"
              isActive={withHoumBox === false}
              onClick={() => handleChangeWithHoumbox(false)}
            />
          </Grid>
        </FilterDialog.Item>
        {/* TODO: lastUpdate filter commented until api allows it */}
        {/* <FilterDialog.Item label="Actualización de estado" spacing={1} container>
            <Grid item>
              <TextField
                variant="outlined"
                margin="dense"
                inputProps={{
                  className: classes.textFieldInput,
                  classes: {
                    notchedOutline: classes.textFieldInputOutline
                  }
                }}
                type="date"
                // value={tempFilters.maxArea || ""}
                // onChange={(e) =>
                //   setTempFilters.setMaxArea(parseInt(e.target.value))
                // }
                label="Desde"
                InputLabelProps={{
                  shrink: true,
                  classes: { root: classes.textFieldInputLabel }
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="dense"
                inputProps={{
                  className: classes.textFieldInput,
                  classes: {
                    notchedOutline: classes.textFieldInputOutline
                  }
                }}
                type="date"
                // value={tempFilters.maxArea || ""}
                // onChange={(e) =>
                //   setTempFilters.setMaxArea(parseInt(e.target.value))
                // }
                label="Hasta"
                InputLabelProps={{
                  shrink: true,
                  classes: { root: classes.textFieldInputLabel }
                }}
              />
            </Grid>
          </FilterDialog.Item> */}
        <FilterDialog.Item label="Estado">
          <Select
            variant="outlined"
            native
            value={statusFilter || ""}
            onChange={(e) =>
              setStatusFilter(e.target.value as PropertySaleStatus | PropertyRentalStatus)
            }
            className={classes.selectInput}
            inputProps={{
              classes: {
                root: classes.textFieldInput,
              },
            }}>
            <option value="">Seleccionar</option>
            {statusOptions &&
              statusOptions.map((s) => (
                <option key={`id-${s}`} value={s}>
                  {statusTranslator(s)}
                </option>
              ))}
          </Select>
        </FilterDialog.Item>
        <FilterDialog.Item label="Ejecutivo">
          <FilterDialog.TextField
            type="text"
            value={houmOwnerFilter}
            onChange={(e) => setHoumOwnerFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Home Checker">
          <FilterDialog.TextField
            type="text"
            value={homeCheckerFilter}
            onChange={(e) => setHomeCheckerFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Correo Propietario">
          <FilterDialog.TextField
            type="text"
            value={landlordEmailFilter}
            onChange={(e) => setLandlordEmailFilter(e.target.value)}
          />
        </FilterDialog.Item>
      </FilterDialog.Content>
      <FilterDialog.Actions onDelete={handleDelete} onApply={handleSubmit} />
    </FilterDialog>
  );
}

export default FilterOptions;

const useStyles = makeStyles((theme) => ({
  textFieldInput: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
  },
  textFieldInputLabel: {
    color: theme.palette.grey[500],
  },
  textFieldInputOutline: {
    borderColor: theme.palette.grey[400],
  },
  selectInput: {
    minWidth: 196,
  },
}));
