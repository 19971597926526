import currency from "@houm-com/formats/currency";
import { Currency } from "models/Countries";

export const getSalaryAmount = (salary: number, currencyCode: Currency): string =>
  currency(salary, currencyCode, {
    maximumFractionDigits: 0,
  });

export const getAverageSalary = (
  salariesData: [string | null, string | null, string | null],
  currencyCode: Currency
): string => {
  const salaries = [];
  salariesData.forEach((salary) => salary && salaries.push(parseFloat(salary)));
  const salariesNotEmpty = salaries.filter((salary) => salary !== 0);
  if (salariesNotEmpty.length > 0)
    return getSalaryAmount(
      Math.trunc(
        salariesNotEmpty.reduce((acc, current) => acc + current, 0) / salariesNotEmpty.length
      ),
      currencyCode
    );
  return "";
};
