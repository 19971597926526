import clsx from "clsx";
import { useCallback, useState } from "react";

import { IoChevronDown as ArrowIcon } from "@houm-com/ui-icons";

import useCollapsibleContentStyles from "./CollapsibleContentStyles";

interface Props {
  children: React.ReactNode;
  size?: "small" | "medium";
  title: string;
  classNames?: {
    root?: string;
    content?: string;
  };
}

const CollapsibleContent = ({ children, classNames, size, title }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useCollapsibleContentStyles();
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);

  return (
    <div className={clsx(classes.contentAccordionRoot, classNames?.root)}>
      <div className={classes.header} onClick={toggleExpanded}>
        {size === "small" ? (
          <h4 className={clsx(classes.small)}>{title}</h4>
        ) : (
          <h2 className={clsx(classes.medium)}>{title}</h2>
        )}
        <ArrowIcon
          size="24"
          className={clsx(classes.arrow, { [classes.arrowExpanded]: expanded })}
        />
      </div>
      <div
        className={clsx(classes.content, classNames?.content, {
          [classes.collapsed]: !expanded,
          [classes.expanded]: expanded,
        })}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleContent;
