import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Swal from "sweetalert2";

import { TableProvider } from "context/tableContext/tableContext";
import { TableLayout } from "Components/Admin/UIComponents";
import executivesService from "services/executivesService";
import { IAssing, IExecutives } from "./utils";
import Header from "./components/Header";
import Table from "./components/Table";
import { useGetToken } from "hooks";
import { AUTH0_ROLES } from "env";

function ExecutivesIndex() {
  const { user, isLoading } = useAuth0();
  const { getToken } = useGetToken();
  const [rolUser, setRolUser] = useState([]);
  const [executives, setExecutives] = useState<IExecutives[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAssignation, setLoadingAssignation] = useState<boolean>(true);
  const [assignation, setAssignation] = useState<IAssing[]>([]);

  useEffect(() => {
    if (!isLoading && user) {
      setRolUser(user[AUTH0_ROLES]);
    }
    (async () => {
      const authToken = await getToken();
      const requestExecutives = await executivesService.getExecutives(authToken);
      setExecutives(requestExecutives.data);
      setLoading(false);

      const requestAssignation = await executivesService.getExecutivesAssignation(authToken);
      setAssignation(requestAssignation.data);
      setLoadingAssignation(false);
    })();
  }, []);

  const onExecutiveActivation = async (event) => {
    const { id, checked } = event.target;
    executives.forEach((ex) => {
      if (ex.id === parseInt(id)) {
        ex.active = checked;
      }
    });
    const authToken = await getToken();
    setExecutives([...executives]);
    executivesService
      .activateExecutive(id, checked, authToken)
      .then((res) => {
        if (res.data.active) {
          Swal.fire("Success", "La ejecutiva ha sido activada para asignación.", "success");
        } else {
          Swal.fire("Success", "La ejecutiva ha sido desactivada para asignación.", "success");
        }
      })
      .catch(() => {
        Swal.fire("Error", "Hubo un error aplicando el cambio", "error");
      });
  };

  return (
    <TableProvider>
      <TableLayout>
        <Header />
        <TableLayout.Table>
          <Table
            cells={executives}
            loading={loading}
            numberOfCells={executives.length}
            loadingAssignation={loadingAssignation}
            assignation={assignation}
            rolUser={rolUser}
            onExecutiveActivation={onExecutiveActivation}
          />
        </TableLayout.Table>
      </TableLayout>
    </TableProvider>
  );
}

export default ExecutivesIndex;
