export default {
  descriptionInput: "h-40 p-4 items-start rounded",
  input: "w-full",
  selection: "mt-2 w-full",
  selectionLabel: "text-base font-bold translate-y-8",
  percentageAdornment: "absolute top-1/2 transform -translate-y-1/2 left-2",
  container: "grid gap-2 grid-cols-12",
  firstColMargin: "mb-4",
  firstCol: "col-span-5 my-8",
  secondCol: "col-span-7",
  secondColDivisionContainer: "grid grid-cols-12 gap-4 items-end p-4 py-2",
  secondColItem: "col-span-4",
};
