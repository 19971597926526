import { makeStyles, Theme } from "@material-ui/core";

const useContractDocumentsStyles = makeStyles((theme: Theme) => ({
  documentsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
}));

export default useContractDocumentsStyles;
