import { Typography, Divider } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import moment from "moment";

import { Timezones } from "models/Countries";

import { useEvent } from "context/eventContext/eventContext";
import { ControlledDatePickerOutline } from "Components/Admin/UIComponents/ControlledForm";

import useTimefieldStyles from "./TimeFieldsStyles";

interface Props {
  beginDate: string;
  endDate?: string;
  defaultTZ?: Timezones;
  blocked?: boolean;
}

function TimeFields({ beginDate, endDate = "", defaultTZ = "America/Santiago" }: Props) {
  const {
    state: { propertyId },
  } = useEvent();

  const { watch } = useFormContext();

  const timeZ = watch("timeZone") ?? defaultTZ;
  const defaultDate = moment(beginDate).format("YYYY-MM-DD");

  const classes = useTimefieldStyles();

  beginDate = moment(beginDate).format("HH:mm");

  endDate =
    endDate !== ""
      ? moment(endDate).format("HH:mm")
      : moment(beginDate, "HH:mm").clone().add(2, "h").format("HH:mm");

  if (timeZ !== "") {
    return (
      <>
        {propertyId && (
          <div>
            <Typography variant="body1" className={classes.text}>
              Fecha de la visita
            </Typography>
            <Typography variant="body2">{defaultDate}</Typography>
          </div>
        )}
        <Divider />
        <ControlledDatePickerOutline
          id="date"
          type="date"
          label="Seleccionar fecha"
          defaultValue={defaultDate}
          outlineProps={{
            size: "small",
            fullWidth: false,
          }}
        />
        <Typography variant="body2" className={classes.text}>
          Seleccionar horario a reservar
        </Typography>
        <div className={classes.hoursContainer}>
          <div className={classes.hour}>
            <ControlledDatePickerOutline
              id="begin_date"
              label="Desde"
              defaultValue={beginDate}
              outlineProps={{
                size: "small",
              }}
            />
          </div>
          <div className={classes.hour}>
            <ControlledDatePickerOutline
              id="end_date"
              label="Hasta"
              defaultValue={endDate}
              outlineProps={{
                size: "small",
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default TimeFields;
