import { LetterOfIntentProvider } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import LetterOfIntentIndex from "./LettersOfIntentIndex";

export default function LetterOfIntent() {
  return (
    <LetterOfIntentProvider>
      <LetterOfIntentIndex />
    </LetterOfIntentProvider>
  );
}
