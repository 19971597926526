import { ReactNode } from "react";

import { Box, Container, Typography } from "@material-ui/core";

import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import useModalLayoutStyles from "./ModalLayoutStyles";

interface Props {
  handleClose: () => void;
  open: boolean;
  title: string;
  children: ReactNode;
  subtitle?: string;
  withIcon?: boolean;
}

const ModalLayout = ({ handleClose, open, title, children, subtitle, withIcon = true }: Props) => {
  const classes = useModalLayoutStyles();

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <Box className={classes.addBasicServiceRoot}>
        {withIcon ? (
          <Container className={classes.titleContainer}>
            <WarningIcon className={classes.icon} />
            <Typography component="h1" className={classes.title}>
              {title}
            </Typography>
          </Container>
        ) : (
          <Typography component="h1" className={classes.title}>
            {title}
          </Typography>
        )}
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        {children}
      </Box>
    </DSModal>
  );
};

export default ModalLayout;
