import { ReactElement } from "react";

import { Box, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import useModalLayoutStyles from "./ModalLayoutStyles";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  children: ReactElement;
  title?: string;
  subtitle?: string;
}

const ModalLayout = ({ children, title, subtitle, open, handleOnClose }: Props) => {
  const classes = useModalLayoutStyles();

  return (
    <DSModal open={open} onClose={handleOnClose} maxWidth="xs" showCloseButton>
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </Box>
        {children}
      </Box>
    </DSModal>
  );
};

export default ModalLayout;
