import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { OptionType } from "../../../utils/types";

const useFormMonth = (type: OptionType, optionalFlag: "yes" | "no") => {
  const { watch, unregister, setValue } = useFormContext();
  const [quantityMonth, applyFlag] = watch([`${type}Month`, `${type}Flag`]);

  const monthOptions = [
    ...(type === "landlordBrokerage" || type === "tenantBrokerage"
      ? [
          {
            value: 0,
            label: "Sin meses",
          },
        ]
      : []),
    ...[...Array(12).keys()].map((i) => ({
      value: i + 1,
      label: (i + 1).toString(),
    })),
  ];

  useEffect(() => {
    if (applyFlag !== "no" || !quantityMonth) return;
    [...Array(Number(quantityMonth)).keys()].map((i) => unregister(`${type}Month${i + 1}`));
    unregister(`${type}Month`);
    if (type === "landlordBrokerage" || type === "tenantBrokerage")
      unregister([`${type}`, `${type}Closing`]);
  }, [applyFlag]);

  useEffect(() => {
    if (optionalFlag) setValue(`${type}Flag`, optionalFlag);
  }, [optionalFlag]);

  useEffect(() => {
    if (!quantityMonth) return;
    const MAX_MONTH = 12;
    const restMonths = MAX_MONTH - Number(quantityMonth);
    [...Array(restMonths).keys()].map((i) =>
      unregister(`${type}Month${Number(quantityMonth) + i + 1}`)
    );
  }, [quantityMonth]);

  return {
    quantityMonth,
    applyFlag,
    monthOptions,
  };
};

export default useFormMonth;
