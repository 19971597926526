import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import ModalDialog from "@houm-com/ui/ModalDialog";
import Button from "@houm-com/ui/Button";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { setPlanData } from "context/plansContext/plansActions";
import { usePlans } from "context/plansContext/plansContext";
import useGetCities from "hooks/usePlans/useGetCities";

import CitySelection from "./CitySelection";
import classes from "./CreatePlanDialogStyles";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function CreatePlanDialog({ open, onClose }: Props) {
  const { state, dispatch } = usePlans();
  const { availableCities, isLoading: citiesLoading } = useGetCities(state.planData.country);
  const history = useHistory();

  useEffect(() => {
    if (availableCities?.length > 0) {
      dispatch(
        setPlanData({
          cities: [availableCities[0].id],
        })
      );
    }
  }, [availableCities]);

  const handleCreatePlan = () => {
    const cityName = state.planData.cities.map(
      (city) => availableCities.find((cityName) => city === cityName.id).name
    );
    const cities = `cities=${cityName.join(",")}`;
    history.push(`/admin/plans/create?country=${state.planData.country}&${cities}`);
  };

  return (
    <ModalDialog
      isOpen={open}
      title="Crear plan"
      subtitle="¿Dónde se aplicará el plan?"
      onClose={onClose}>
      <Loading loading={citiesLoading}>
        <div className={classes.wrapper}>
          <div className={classes.inputContainer}>
            <p className={classes.helperText}>Selecciona el país</p>
            <CountrySelector
              stateCountry={state.planData.country}
              onChange={(country) => {
                dispatch(setPlanData({ country }));
                dispatch(setPlanData({ cities: [] }));
              }}
            />
          </div>

          <CitySelection availableCities={availableCities} />

          <div className={classes.buttonContainer}>
            <Button onClick={() => handleCreatePlan()} variant="primary" size="lg">
              Continuar
            </Button>
          </div>
        </div>
      </Loading>
    </ModalDialog>
  );
}
