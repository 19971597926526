import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { capitalize } from "lodash";

import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import PaymentAdjustConcept from "Components/Admin/UIComponents/PaymentAdjustConcept";
import useCreatePlanMovement from "hooks/usePlanMovement/useCreatePlanMovement";
import useDeletePlanMovement from "hooks/usePlanMovement/useDeletePlanMovement";
import useEditPlanMovement from "hooks/usePlanMovement/useEditPlanMovement";
import { parseDescription } from "utils/adjustmentDescription";
import { ContractModel, IPlanMovement } from "models/Contract";
import { useFormNotifications } from "hooks";

import { movementPlanSchema, requiredText } from "../validationSchema";
import classes from "./PlanMovementFormStyles";

interface Props {
  contract: ContractModel;
  movement?: IPlanMovement;
  type?: "lessor" | "lessee";
  onClose: () => void;
  movements?: IPlanMovement[];
  setPlanMovement: (movement: IPlanMovement[]) => void;
}

const startAdornment = () => <div className={classes.startAdornment}>$</div>;

function PlanMovementForm({
  contract,
  movement = null,
  type = null,
  onClose,
  movements = null,
  setPlanMovement,
}: Props) {
  const onCreateSuccess = (result: IPlanMovement) => {
    setPlanMovement([...movements, result]);
    onClose();
  };
  const onEditSuccess = (result: IPlanMovement) => {
    const newData: IPlanMovement[] = movements.map((obj) => (obj.id === result.id ? result : obj));
    setPlanMovement(newData);
    onClose();
  };
  const onDeleteSuccess = () => {
    const newData: IPlanMovement[] = movements.map((obj) => obj.id !== movement.id && obj);
    setPlanMovement(newData);
    onClose();
  };
  const { createPlanMovement, isLoading: isCreatePlanMovementLoading } =
    useCreatePlanMovement(onCreateSuccess);
  const { editPlanMovement, isLoading: isEditPlanMovementLoading } = useEditPlanMovement(
    movement?.id,
    movement?.type_movement,
    onEditSuccess
  );
  const { deletePlanMovement, isLoading: isDeletePlanMovementLoading } = useDeletePlanMovement(
    movement?.id,
    movement?.type_movement,
    onDeleteSuccess
  );
  const isLoading =
    isCreatePlanMovementLoading || isEditPlanMovementLoading || isDeletePlanMovementLoading;

  const methods = useForm<IPlanMovement>({
    resolver: yupResolver(movementPlanSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IPlanMovement>({ errors, requiredText });

  const onSubmit: SubmitHandler<IPlanMovement> = (data) => {
    const formData: IPlanMovement = {
      ...movement,
      person: capitalize(type) as "Lessee" | "Lessor",
      amount: data.amount.toString(),
      description: parseDescription(data.description, data.descriptionAux),
      type_movement: data.type_movement,
      contract: contract.id,
      deposit_date: contract.init_date,
    };
    if (movement) {
      editPlanMovement({ formData });
    } else {
      createPlanMovement({ formData });
    }
  };

  const handleDelete = () => {
    deletePlanMovement();
  };

  const typeAdjustment = methods.watch("type_movement");
  const parseAjdustmentType = {
    Abono: "payment",
    Cargo: "discount",
  };

  return (
    <FormProvider {...methods}>
      <form className={classes.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
        <FieldSelect
          id="type_movement"
          label="Seleccionar tipo de pago"
          defaultValue={movement?.type_movement ?? ""}
          options={[
            {
              value: "Abono",
              label: "Abono",
            },
            {
              value: "Cargo",
              label: "Descuento",
            },
          ]}
          errorMessage={errors.type_movement?.message}
          {...methods.register("type_movement")}
        />
        <FieldInput
          id="amount"
          label="Monto"
          defaultValue={movement?.amount ? Number(movement?.amount) : ""}
          startAdornment={startAdornment}
          type="number"
          step="any"
          errorMessage={errors.amount?.message}
          {...methods.register("amount")}
        />
        <PaymentAdjustConcept
          defaultValue={movement?.type_movement}
          country={contract.property?.country}
          adjustmentType="closing_payment"
          paymentType={parseAjdustmentType[typeAdjustment]}
          movementType={type === "lessee" ? "INCOME" : "OUTFLOW"}
        />
        {movement ? (
          <div className={classes.ctaContent}>
            <Button
              isLoading={isLoading}
              onClick={handleDelete}
              variant="primary"
              size="sm"
              type="button">
              Eliminar
            </Button>
            <Button isLoading={isLoading} variant="primary" type="submit" size="sm">
              Actualizar
            </Button>
          </div>
        ) : (
          <div className={classes.ctaContent}>
            <Button isLoading={isLoading} variant="primary" type="submit" size="sm">
              Guardar
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export default PlanMovementForm;
