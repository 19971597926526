import { ReactNode } from "react";
import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import useLabelStyles from "./LabelStyles";

interface Props {
  label: string;
  children: ReactNode;
  labelClassName?: string;
}

const Label = ({ label, children, labelClassName }: Props) => {
  const classes = useLabelStyles();

  return (
    <Box className={classes.container}>
      <Typography component="span" className={clx(classes.text, labelClassName)}>
        <b>{label}</b>
      </Typography>
      {children}
    </Box>
  );
};

export default Label;
