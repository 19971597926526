import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import capitalize from "lodash/capitalize";
import Swal from "sweetalert2";

import {
  ControlledCheckbox,
  ControlledList,
  ControlledTextField,
  ControlledPhoneInput,
} from "Components/Admin/UIComponents/ControlledForm";
import CallToAction from "./SubmitButton";
import { userAdminSchema, requiredText } from "../validationSchema";
import { useFormNotifications, useGetToken } from "hooks";
import userService from "services/userService";
import useNewUserAdminStyles from "../NewInternalUserStyles";
import { Country, PhoneInput } from "models/PhoneInput";

import { getCountryPhone } from "utils/verifyPhoneNumber";
import { auth0RolesUser } from "utils/auth0RolesUser";

export interface UserProps {
  name: string;
  lastName: string;
  email: string;
  country: string;
  phone: PhoneInput;
  Analyst?: boolean;
  HoumAdvisor?: boolean;
  LeadQualifier?: boolean;
  contract?: boolean;
  control_tower?: boolean;
  evaluation?: boolean;
  management?: boolean;
  sales?: boolean;
}

function FormUser() {
  const { getToken } = useGetToken();
  const classes = useNewUserAdminStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const defaultCountry: Country = getCountryPhone("cl");

  const methods = useForm<UserProps>({
    resolver: yupResolver(userAdminSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;

  useFormNotifications<UserProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<UserProps> = (data) => {
    const roles = Object.keys(data).filter((key) => data[key] === true);
    if (roles.length === 0) {
      return Swal.fire({
        type: "warning",
        text: "Debe seleccionar al menos un rol",
      });
    }
    (async () => {
      setLoading(true);
      try {
        const formData = {
          name: capitalize(data.name),
          last_name: capitalize(data.lastName),
          country: data.country,
          email: data.email.toLowerCase(),
          roles,
          phone: data.phone.parsedPhone,
        };
        const authToken = await getToken();
        await userService.createAdminUser(formData, authToken);
        Swal.fire({
          type: "success",
          title: "Usuario creado",
          text: `Por favor, avisar a ${formData.name} ${formData.last_name} de revisar mail para crear una contraseña para ingresar al admin`,
        }).then(() => window.location.reload());
      } catch (e) {
        Swal.fire({
          type: "error",
          text: "No se pudo crear usuario, contactarse con soporte",
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ControlledTextField
          label="Nombre"
          id="name"
          textFieldProps={{ placeholder: "Ingresar nombre" }}
        />
        <ControlledTextField
          label="Apellido"
          id="lastName"
          textFieldProps={{ placeholder: "Ingresar apellido" }}
        />
        <ControlledPhoneInput
          id="phone"
          phoneLabel="Celular"
          defaultValue={{ country: defaultCountry, phone: "" }}
          countryInputProps={{
            InputProps: {
              classes: {
                root: classes.input,
              },
            },
            inputProps: {
              className: classes.formInput,
            },
          }}
          numberInputProps={{
            fullWidth: true,
            InputProps: {
              classes: {
                root: classes.input,
              },
            },
            inputProps: {
              className: classes.formInput,
            },
          }}
        />
        <ControlledTextField
          label="Correo electrónico (Houm)"
          id="email"
          textFieldProps={{ placeholder: "Ingresar email" }}
        />
        <ControlledList
          disableUnderline
          label="País"
          id="country"
          defaultValue=""
          options={[
            { value: "Colombia", label: "Colombia" },
            { value: "Chile", label: "Chile" },
            { value: "Mexico", label: "México" },
            { value: "Chile", label: "Global" },
          ]}
        />
        <p className={classes.label}>Roles</p>
        {auth0RolesUser.map((item) => (
          <ControlledCheckbox
            id={item.value}
            label={item.label}
            key={item.value}
            defaultChecked={false}
          />
        ))}
        <CallToAction loading={loading} />
      </form>
    </FormProvider>
  );
}

export default FormUser;
