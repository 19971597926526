import { useGetToken } from "hooks";
import { UseQueryResult, useQuery } from "react-query";

import debtCollectionService from "services/debtCollectionService";

import { IDebtCollectionDetail } from "./utils/types";
import { objectToCamelCase } from "hooks/useGetDebtCollections/utils/parsers";

export default function useGetDebtCollectionDetails(collectionProcessId: number) {
  const { getToken } = useGetToken();

  const { data, isLoading, isError }: UseQueryResult<IDebtCollectionDetail, Error> = useQuery<
    IDebtCollectionDetail,
    Error
  >(
    ["getDebtCollectionDetails", collectionProcessId],
    async () => {
      const token = await getToken();
      const response = await debtCollectionService.getDebtCollectionDetail(
        token,
        collectionProcessId
      );
      return objectToCamelCase<IDebtCollectionDetail>(response?.data);
    },
    {
      enabled: !!collectionProcessId,
    }
  );

  return {
    debtCollectionDetail: data,
    isDebtCollectionDetailLoading: isLoading,
    isError,
  };
}
