import { useHistory } from "react-router-dom";

import {
  Box,
  Container,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ICity } from "Components/Admin/administrator/promotions/utils/types";

import useHandleCity from "../../../../hooks/useHandleCity";
import useCitySelectorStyles from "./CitySelectorStyles";
import SelectedCity from "../SelectedCity";
import { CountryUpperCase } from "models/Countries";

interface Props {
  originalCities: ICity[];
  handleOpenModal: () => void;
  country: CountryUpperCase;
}

const CitySelector = ({ originalCities, handleOpenModal, country }: Props) => {
  const history = useHistory();
  const classes = useCitySelectorStyles();
  const { cities, handleAddCity, selectedCities, handleDeleteCity } = useHandleCity(originalCities);

  const createPromotion = () =>
    history.push({
      pathname: "/admin/promotions/create",
      state: {
        cities: selectedCities,
        country,
      },
    });

  return (
    <DialogContent className={classes.dialogContainer}>
      <Container className={classes.container}>
        <Typography variant="h1" className={classes.title}>
          Crea una promoción
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel
            classes={{
              root: classes.selectLabel,
              asterisk: classes.asterisk,
            }}
            required
            shrink>
            Selecciona una o más ciudades
          </InputLabel>
          <Select
            value=""
            onChange={(e) => handleAddCity(e.target.value as number)}
            displayEmpty
            disableUnderline
            classes={{ select: classes.select, selectMenu: classes.menu }}>
            <MenuItem value="" unselectable="on" disabled>
              <em>Seleccionar</em>
            </MenuItem>
            {cities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                <Box className={classes.cityContainer}>
                  <Typography className={classes.cityName}>{city.name}</Typography>
                  <Typography className={classes.addCity}>Agregar +</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={1} className={classes.selectedCitiesContainer}>
          {selectedCities.map((city) => (
            <Grid item xs={12} key={city?.id}>
              <SelectedCity cityName={city?.name} onDeleteCity={() => handleDeleteCity(city.id)} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end" className={classes.buttonContainer}>
          <Grid item>
            <DesignSystemButton
              label="Cancelar"
              size="small"
              variant="tertiaryB"
              onClick={handleOpenModal}
            />
          </Grid>
          <Grid item>
            <DesignSystemButton
              label="Continuar"
              size="small"
              variant="primary"
              disabled={selectedCities.length === 0}
              onClick={createPromotion}
            />
          </Grid>
        </Grid>
      </Container>
    </DialogContent>
  );
};

export default CitySelector;
