import toast, { Toaster, ToastBar } from "react-hot-toast";
import {
  IoCheckmarkCircleOutline as SuccessIcon,
  IoCloseOutline as CloseIcon,
  IoWarningOutline as WarningIcon,
  IoAlertCircleOutline as InfoIcon,
} from "@houm-com/ui-icons";
import config from "@houm-com/ui/tailwind.config";
import { cn } from "@houm-com/ui/utils";
import { ReactNode } from "react";

interface AlertProps {
  message: string;
  disclaimer?: ReactNode;
  onClose?: () => void;
}

interface AlertPropsComp extends AlertProps, React.ComponentProps<"div"> {
  icon?: React.ReactNode;
}
export const alert = {
  // for internal use
  alert: ({ message, disclaimer, onClose = () => {}, icon, className }: AlertPropsComp) => {
    const id = toast(
      <Alert
        message={message}
        disclaimer={disclaimer}
        onClose={() => {
          onClose();
          alert.dissmiss(id);
        }}
        className={className}
        icon={icon}
      />
    );
    return id;
  },
  // use for a success alert
  success: ({ ...props }: AlertProps) =>
    alert.alert({
      ...props,
      className: "text-success-100",
      icon: <SuccessIcon className="w-6 h-6 text-success-100" />,
    }),
  // use for a error alert
  error: ({ ...props }: AlertProps) => {
    alert.alert({
      ...props,
      className: "text-error-100",
      icon: <WarningIcon className="w-6 h-6 text-error-100" />,
    });
  },
  // use for a info alert
  info: ({ ...props }: AlertProps) =>
    alert.alert({
      ...props,
      className: "text-information-100",
      icon: <InfoIcon className="w-6 h-6 text-information-100" />,
    }),
  // use for a warning alert
  warning: ({ ...props }: AlertProps) =>
    alert.alert({
      ...props,
      className: "text-warning-100",
      icon: <InfoIcon className="w-6 h-6 text-warning-100" />,
    }),
  // use to dismiss an alert
  dissmiss: (id: string) => toast.dismiss(id),
  // use to dismiss all alerts
  clear: () => toast.dismiss(),
};

function Alert({ message, disclaimer, onClose, icon, className }: AlertPropsComp) {
  return (
    <div
      className={cn(
        "border-l-8 rounded-lg w-[450px] flex bg-white p-4 gap-2 shadow-2xl ring-1 ring-secondary-cta/10",
        className
      )}>
      {icon && <div>{icon}</div>}
      <div className="text-p16">
        <p className="font-bold">{message}</p>
        {disclaimer && <p className="text-black-80">{disclaimer}</p>}
      </div>
      <div className="ml-auto">
        <button onClick={() => onClose()}>
          <CloseIcon className="w-6 h-6 text-black-80" />
        </button>
      </div>
    </div>
  );
}

export function Alerts() {
  return (
    <Toaster
      position="top-right"
      containerStyle={{ top: "30px" }}
      toastOptions={{
        duration: 4000,
        style: {
          color: config.theme.colors["black-100"],
          padding: 0,
          boxShadow: "none",
          borderRadius: "8px",
          background: "transparent",
          width: "auto",
        },
      }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ message }) => (
            <div className="flex items-center" style={{ margin: "-4px 0px -4px -120px" }}>
              {message}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
