import { makeStyles, Theme } from "@material-ui/core";

const useLastPaymentStyles = makeStyles((theme: Theme) => ({
  text: {
    marginBottom: theme.spacing(1),
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

export default useLastPaymentStyles;
