import { useEffect } from "react";

import { Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import { fromToOptions } from "utils/fromToOptions";

import ControlledTextfieldStyled from "./Inputs/ControlledTextfieldStyled";
import ControlledSelectionStyled from "./Inputs/ControlledSelectionStyled";
import Title from "./Title";
import useFeaturesStyles from "./FeaturesStyle";

function Features() {
  const classes = useFeaturesStyles();
  const { watch, setValue } = useFormContext();

  const {
    state: { profiling },
  } = useDemandLead();

  const bedroomInput = ["bedrooms_studio", "bedrooms_count", "bedrooms"];
  const bathroomInput = ["bathrooms_count", "bathrooms"];
  const parkingInput = ["parking_count", "parkinglot"];

  //bedrooms
  useEffect(() => {
    //when bedroom studio is selected the other bedroom fields should be unselected
    if (watch(bedroomInput[0]) === 0) {
      setValue(bedroomInput[1], 0);
      setValue(bedroomInput[2], 0);
    }
  }, [watch(bedroomInput[0])]);

  useEffect(() => {
    //when bedroom count button is selected the bedroom studio buttom should be unselected
    if (watch(bedroomInput[1]) || watch(bedroomInput[1]) === 0) {
      setValue(bedroomInput[0], null);
      setValue(bedroomInput[2], watch(bedroomInput[1]));
      return;
    }
    setValue(bedroomInput[2], "");
  }, [watch(bedroomInput[1])]);

  useEffect(() => {
    if (watch(bedroomInput[2]) || watch(bedroomInput[2]) === 0) {
      setValue(bedroomInput[1], parseInt(watch(bedroomInput[2])));
      return;
    }
    setValue(bedroomInput[1], null);
  }, [watch(bedroomInput[2])]);

  //bathrooms
  useEffect(() => {
    if (watch(bathroomInput[0]) || watch(bathroomInput[0]) === 0) {
      setValue(bathroomInput[1], watch(bathroomInput[0]));
      return;
    }
    setValue(bathroomInput[1], "");
  }, [watch(bathroomInput[0])]);

  useEffect(() => {
    if (watch(bathroomInput[1]) || watch(bathroomInput[1]) === 0) {
      setValue(bathroomInput[0], parseInt(watch(bathroomInput[1])));
      return;
    }
    setValue(bathroomInput[0], null);
  }, [watch(bathroomInput[1])]);

  //parking
  useEffect(() => {
    if (watch(parkingInput[0]) || watch(parkingInput[0]) === 0) {
      setValue(parkingInput[1], watch(parkingInput[0]));
    }
  }, [watch(parkingInput[0])]);

  useEffect(() => {
    if (watch(parkingInput[1]) || watch(parkingInput[1]) === 0) {
      setValue(parkingInput[0], parseInt(watch(parkingInput[1])));
      return;
    }
    setValue(parkingInput[0], null);
  }, [watch(parkingInput[1])]);

  return (
    <>
      <Title text="Características de la propiedad" />
      <Grid container spacing={3}>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.total_surface || ""}
            type="number"
            placeholder="50"
            label="Superficie total"
            id="total_surface"
            endAdornment="m²"
          />
        </Grid>
        <Grid item>
          <ControlledTextfieldStyled
            defaultValue={profiling?.built_surface || ""}
            type="number"
            placeholder="126"
            label="Superficie útil"
            id="built_surface"
            endAdornment="m²"
          />
        </Grid>
        <Grid item>
          <ControlledSelectionStyled
            defaultValue={profiling?.terrace}
            options={[
              {
                label: "Si",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
            round
            label="Terraza"
            id="terrace"
            compressed
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item>
          {/* bedrooms */}
          <div className={classes.bedroomWrapper}>
            <ControlledSelectionStyled
              emptyValue={null}
              defaultValue={profiling?.bedrooms}
              options={[
                {
                  label: "Studio",
                  value: 0,
                },
              ]}
              label="Recámaras"
              id={bedroomInput[0]}
              compressed
              formFullwidth={false}
            />
            <ControlledSelectionStyled
              emptyValue={null}
              defaultValue={profiling?.bedrooms}
              options={fromToOptions(0, 5)}
              round
              label=""
              id={bedroomInput[1]}
              compressed
            />
          </div>
          <ControlledTextfieldStyled
            defaultValue={profiling?.bedrooms || ""}
            type="number"
            placeholder="2"
            label=""
            id={bedroomInput[2]}
          />
        </Grid>
        {/* bathrooms */}
        <Grid item>
          <ControlledSelectionStyled
            emptyValue={null}
            defaultValue={profiling?.bathrooms}
            options={fromToOptions(0, 5)}
            round
            label="Baño"
            id={bathroomInput[0]}
            compressed
          />
          <ControlledTextfieldStyled
            defaultValue={profiling?.bathrooms || ""}
            type="number"
            placeholder="2"
            label=""
            id={bathroomInput[1]}
          />
        </Grid>
        {/* Parking  parkinglot*/}
        <Grid item>
          <ControlledSelectionStyled
            emptyValue={null}
            defaultValue={profiling?.parkinglot}
            options={fromToOptions(0, 5)}
            round
            label="Estacionamiento"
            id={parkingInput[0]}
            compressed
          />

          <ControlledTextfieldStyled
            defaultValue={profiling?.parkinglot || ""}
            type="number"
            placeholder="2"
            label=""
            id={parkingInput[1]}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={3}>
          <ControlledSelectionStyled
            emptyValue={null}
            defaultValue={profiling?.warehouse}
            options={[
              {
                label: "Si",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
            round
            label="Bodega"
            id="warehouse"
            compressed
          />
        </Grid>
        <Grid item md={2}>
          <ControlledSelectionStyled
            emptyValue={null}
            defaultValue={profiling?.half_bathrooms}
            options={[
              {
                label: "Si",
                value: true,
              },
              {
                label: "No",
                value: false,
              },
            ]}
            round
            label="Medios baño"
            id="half_bathrooms"
            compressed
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Features;
