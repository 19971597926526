import { useEffect, useState } from "react";
import currency from "@houm-com/formats/currency";
import { Link } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { Chip } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { setProperty3X, setPropertyIsInUF } from "context/evaluationContext/evaluationAction";
import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";
import { currencyParse } from "utils/countryCurrency";
import { countryCode } from "utils";

import useStyles from "./PropertyCardStyles";

interface Props {
  propertyUid: string;
  setChangingProperty: (value: boolean) => void;
}

function PropertyCard({ propertyUid, setChangingProperty }: Props) {
  const { getToken } = useGetToken();
  const classes = useStyles();
  const { state, dispatch } = useEvaluation();
  const [property, setProperty] = useState<any>(null);
  const [propertyDetails, setPropertyDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProperty = async (authToken) => {
      if (propertyUid != null) {
        const propertyData = await propertyService.get(propertyUid, authToken);
        if (propertyData) {
          const property = propertyData["data"];
          setProperty(property);
          const propDetails = property.property_details[0];
          setPropertyDetails(propDetails);
          const propInUF = propDetails.tipo_moneda === "U.F.";
          dispatch(setPropertyIsInUF(propInUF));
          //@ts-ignore
          const prop3x = propInUF ? parseFloat(propDetails.valor) * 3 : propDetails.valor * 3;
          dispatch(setProperty3X(prop3x));
        }
      }
      setLoading(false);
    };
    getToken().then((authToken) => fetchProperty(authToken));
  }, [propertyUid]);

  const renderPropertyCard = () => {
    if (property && propertyDetails) {
      return (
        <>
          <div className={classes.titleContainer}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Propiedad a la que postula
            </Typography>
            <CustomLoadingButton
              text="CAMBIAR"
              onClick={() => setChangingProperty(true)}
              type="outlined"
            />
          </div>
          <Link to={`/admin/properties/${property.uid}`}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between">
              <Typography variant="subtitle1">
                ID [{property.id}] {property.address}, {property.street_number}
              </Typography>
              {["published", "republished"].includes(propertyDetails.status) ? (
                <Chip
                  label="Publicada en portales"
                  className={classes.published}
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="No publicada en portales"
                  className={classes.noPublished}
                  variant="outlined"
                />
              )}
            </Box>
          </Link>
          <Box>
            <Typography>
              {property.comuna}, {property.region}
            </Typography>
            <Typography>País: {property.country}</Typography>
            <Typography>
              M<sup>2</sup> de terreno: {propertyDetails.m_terreno}
            </Typography>
            <Typography>
              M<sup>2</sup> de construídos: {propertyDetails.m_construidos}
            </Typography>
            <Typography>Dormitorios: {propertyDetails.dormitorios}</Typography>
            <Typography>
              Ejecutivo: {`${property.houm_owner?.name} ${property.houm_owner?.last_name}`}
            </Typography>
            <Typography>
              Homechecker: {property.homechecker ? property.homechecker.name : "No definido"}
            </Typography>
            <Typography variant="h6">
              3 veces el arriendo:{" "}
              {currency(
                state.property3x,
                currencyParse[countryCode(property.country)][
                  (propertyDetails?.tipo_moneda as string)?.toLowerCase()
                ]
              )}
            </Typography>
          </Box>
        </>
      );
    }
    return (
      <Box>
        <Typography>Esta evaluación no tiene una propiedad asociada aún.</Typography>
      </Box>
    );
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Card className={classes.card}>
      <CardContent>{renderPropertyCard()}</CardContent>
    </Card>
  );
}

export default PropertyCard;
