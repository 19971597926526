import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { ControlledList, ControlledNumber } from "Components/Admin/UIComponents/ControlledForm";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useinitContractFormStyles from "./initContractFormStyles";
import { IInsuranceCompany } from "services/insuranceService/types";

interface Props {
  isLoading: boolean;
  insuranceCompanies: IInsuranceCompany[];
}

function InitContractForm({ isLoading = false, insuranceCompanies }: Props) {
  const classes = useinitContractFormStyles();

  return (
    <div className={classes.formContainer}>
      <ControlledKeyboardDatePicker
        id="selectedDate"
        label="Fecha inicio de contrato propuesto"
        defaultValue={new Date()}
        dataPickerProps={{
          format: "dd/MM/yyyy",
        }}
      />
      <ControlledNumber
        label="¿Cuántos adultos vivirán en la propiedad?"
        id="adultHabitants"
        textFieldProps={{ placeholder: "2" }}
      />
      <ControlledNumber
        label="¿Cuántos niños vivirán en la propiedad?"
        id="childHabitants"
        textFieldProps={{ placeholder: "2" }}
      />
      <ControlledList
        label="¿Tiene mascotas?"
        id="tenantHasPets"
        defaultValue=""
        options={[
          { value: "yes", label: "Si" },
          { value: "no", label: "No" },
        ]}
        disableUnderline
      />
      <ControlledList
        label="Compañía aseguradora"
        id="insuranceCompany"
        defaultValue={insuranceCompanies.length === 1 ? insuranceCompanies[0].id : ""}
        options={insuranceCompanies.map((company) => ({
          value: company.id,
          label: company.name,
        }))}
        disableUnderline
        disabled={insuranceCompanies.length === 1}
      />
      <div className={classes.buttonContainer}>
        <DesignSystemButton
          label="Confirmar"
          size="medium"
          variant="primary"
          type="submit"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default InitContractForm;
