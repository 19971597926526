import { useQuery } from "react-query";

import { useGetToken } from "hooks";
import inspectionReportService from "services/inspectionReportService";
import { parseReportListItem } from "./parsers";

const useInspectionReports = (propertyId: number, currentPage: number, pageSize: number) => {
  const { getToken } = useGetToken();

  const handleRequestInspectionReports = async () => {
    const authToken = await getToken();
    const { getInspectionsReport } = inspectionReportService(authToken);
    const {
      data: { results: inspectionReportList, count },
    } = await getInspectionsReport({
      propertyId,
      page: currentPage,
      pageSize,
    });
    const parsedReportList = inspectionReportList?.map(parseReportListItem);
    return { parsedReportList, count };
  };

  const { isLoading, data, isError } = useQuery(
    ["inspection_report", propertyId, currentPage, pageSize],
    handleRequestInspectionReports,
    { enabled: !!propertyId }
  );

  return {
    isLoading,
    isError,
    inspectionReportList: data?.parsedReportList,
    count: data?.count,
  };
};

export default useInspectionReports;
