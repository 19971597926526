import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Switch } from "@material-ui/core";
import classNames from "classnames";

import useStyles from "./CustomSwitchStyles";

export interface HelperText {
  on: string;
  off: string;
}

interface Props extends Partial<React.ComponentProps<typeof Switch>> {
  label: string;
  value: boolean;
  onChange: () => void;
  helperText?: "" | HelperText;
  labelClassName?: string;
  wrapperClassName?: string;
}

function CustomSwitch({
  label,
  value,
  onChange,
  helperText,
  labelClassName,
  wrapperClassName,
  ...props
}: Props) {
  const classes = useStyles();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (helperText) {
      setText(value ? helperText.on : helperText.off);
    }
  }, [value]);

  return (
    <FormControl className={wrapperClassName}>
      <FormControlLabel
        classes={{
          labelPlacementTop: classes.root,
          label: classNames(classes.label, labelClassName),
        }}
        label={label}
        labelPlacement="top"
        control={
          <div>
            <Switch
              checked={value}
              onChange={onChange}
              color="primary"
              className={classes.switch}
              {...props}
            />
            <span className={classes.helperText}>{text}</span>
          </div>
        }
      />
    </FormControl>
  );
}

export default CustomSwitch;
