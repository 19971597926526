import { ReactNode } from "react";

interface Props {
  value: string | number;
  index: string | number;
  children: ReactNode;
}

const TabPanel = ({ children, value, index, ...rest }: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`${index}`}
    aria-labelledby={`${index}`}
    {...rest}>
    {value === index && children}
  </div>
);

export default TabPanel;
