import { useMutation } from "react-query";
import { alert } from "@houm-com/ui/Alert";

import { useGetToken } from "hooks";
import schedulesService from "services/schedulesService";

interface IUseUpdateChecker {
  propertyId: number;
  onSuccessHandler: () => void;
}

const useUpdateHoumer = ({ propertyId, onSuccessHandler }: IUseUpdateChecker) => {
  const { getToken } = useGetToken();

  const { mutate, isLoading, isError } = useMutation(
    async (data: { houmerId: string }) => {
      const authToken = await getToken();
      return schedulesService.assignAppraiserToProp(
        {
          property_id: propertyId,
          appraiser_id: data.houmerId,
        },
        authToken
      );
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Se ha asigando al houmer de forma exitosa",
        });
        onSuccessHandler();
      },
      onError: () => {
        alert.success({
          message: "¡Oh no! Ocurrió un problema",
          disclaimer: `No se pudo asignar al houmer, intenta de nuevo`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
  };
};

export default useUpdateHoumer;
