import { useMutation, useQueryClient } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService";

import { parseBackendProperty, parseBackendPropertyDetails } from "./parsers";
import { ContractPropertyProps } from "../../utils/types";

const useSubmitProperty = (uid: string, setEditMode: (mode: boolean) => void) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const {
    mutate: propertyMutate,
    isLoading: propertyLoading,
    isError: propertyError,
  } = useMutation(
    async (data: ContractPropertyProps) => {
      const authToken = await getToken();
      const response = await propertyService.update(uid, parseBackendProperty(data), authToken);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractPropertyInfo");
        setEditMode(false);
      },
    }
  );

  const {
    mutate: detailsMutate,
    isLoading: detailsLoading,
    isError: detailsError,
  } = useMutation(
    async (data: ContractPropertyProps) => {
      const authToken = await getToken();
      const response = await propertyService.updateDetails(
        data.detailsId.toString(),
        parseBackendPropertyDetails(data),
        authToken
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractPropertyInfo");
        setEditMode(false);
      },
    }
  );

  const {
    mutate: contractMutate,
    isLoading: contractLoading,
    isError: contractError,
  } = useMutation(
    async (data: ContractPropertyProps) => {
      const authToken = await getToken();
      const response = await propertyService.updateContractPropertyInfo(
        data.id,
        { max_people_quantity: data.inhabitantsAmount },
        authToken
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractInfo");
        setEditMode(false);
      },
    }
  );

  return {
    mutate: (data: ContractPropertyProps) => {
      detailsMutate(data);
      propertyMutate(data);
      contractMutate(data);
    },
    submitting: propertyLoading || detailsLoading || contractLoading,
    submittingError: propertyError || detailsError || contractError,
  };
};

export default useSubmitProperty;
