import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import propertyService from "services/propertyService";
import { localeToCountry } from "utils/countryCode";

import { BasicServiceProps, parseBasicServices } from "../utils/parsers";

const useAvailableServices = (country: Locale) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    error,
    data: availableServices = [],
  }: UseQueryResult<BasicServiceProps[], Error> = useQuery<BasicServiceProps[], Error>(
    ["basicServicesList", country],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getBasicServicesIntegrations(
        localeToCountry[country],
        authToken
      );
      return parseBasicServices(response.data);
    }
  );

  return {
    isLoading,
    error,
    availableServices,
  };
};

export default useAvailableServices;
