import { LetterOfIntentProvider } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import LetterOfIntentPersonDetailIndex from "./LetterOfIntentPersonDetail";

export default function LetterOfIntentPersonDetail() {
  return (
    <LetterOfIntentProvider>
      <LetterOfIntentPersonDetailIndex />
    </LetterOfIntentProvider>
  );
}
