export interface houmAdvisor {
  appraiser: string;
  appraiserId: string;
}

export const parseHoumAdvisors = (schedules): houmAdvisor[] => {
  const parseSchedules = schedules.map((schedule) => {
    if (schedule.appraiser !== "N/A")
      return {
        appraiser: schedule.appraiser,
        appraiserId: schedule.appraiser_id,
      };
    return null;
  });
  const filterNullsAppraisers = parseSchedules.filter((x) => x !== null);
  const uniqueAppraisers = [];
  const map = new Map();
  for (const item of filterNullsAppraisers) {
    if (!map.has(item.appraiser)) {
      map.set(item.appraiser, true);
      uniqueAppraisers.push({
        appraiser: item.appraiser,
        appraiserId: item.appraiserId,
      });
    }
  }
  return uniqueAppraisers;
};
