import { makeStyles, Theme } from "@material-ui/core";

const useActionsMenuStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
    width: 16,
  },
  linkButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  linkButtonText: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textDecoration: "underline",
  },
  paper: {
    borderRadius: 9,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "#FEF1F0",
    },
  },
}));

export default useActionsMenuStyles;
