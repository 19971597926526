import { useEffect, memo } from "react";
import { useLocation } from "react-router-dom";

import { useGetToken } from "hooks";
import Calendar from "./Components/Calendar";
import { useDashboard } from "context/controlTower/controlTowerContext";
import {
  getAppraiserEvents,
  getAppraiserProfile,
  getAppraiserAvailability,
  getMacrozones,
} from "context/controlTower/controlTowerActions";
import Profile from "./Components/Profiler";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import FullScreenMessage from "Components/Admin/administrator/controlTower/profile/Components/FullScreenMessage";
import AppraiserSchedule from "../availability/AppraiserSchedule";
import useProfileindexStyles from "./ProfileIndexStyles";
import EditProfileModal from "./Components/EditModal/EditProfileModal";
import MessageModal from "./Components/MessageModal";
import { CountryUpperCase } from "models/Countries";

function ProfileIndex({ uuid }: { uuid?: string }) {
  const { getToken } = useGetToken();
  const { state: dashboardStates, dispatch } = useDashboard();
  const { loadingEvents, loadingAvailability, appraiserProfile } = dashboardStates;
  const classes = useProfileindexStyles();
  const pathname = useLocation().pathname.split("/");
  const id = uuid || pathname[pathname.length - 1];

  useEffect(() => {
    getToken().then((authToken) => {
      getAppraiserProfile(authToken, id, dispatch);
      getAppraiserEvents(authToken, id, dispatch);
    });
  }, []);

  useEffect(() => {
    if (dashboardStates.appraiserProfile.country) {
      getToken().then((authToken) => {
        getAppraiserAvailability(
          id,
          dispatch,
          dashboardStates.appraiserProfile.timezone,
          authToken
        );
        getMacrozones(
          authToken,
          dashboardStates.appraiserProfile.country as CountryUpperCase,
          dispatch,
          true
        );
      });
    }
  }, [dashboardStates.appraiserProfile.country, id, dashboardStates.trigger]);

  if (!appraiserProfile?.timezone && !loadingEvents) {
    return (
      <FullScreenMessage
        title="Appraiser sin Macrozona asignada"
        message="Es necesario que el appraiser tenga una macrozona para continuar."
      />
    );
  }

  return (
    <>
      {appraiserProfile.timezone && <EditProfileModal id={id} />}
      <MessageModal />
      <Loading loading={loadingEvents || loadingAvailability || appraiserProfile.email.length <= 0}>
        <>
          <div className={classes.topContainer}>
            <div className={classes.calendar}>
              <Calendar appraiserId={id} />
            </div>
            <Profile />
          </div>
          {appraiserProfile?.timezone && <AppraiserSchedule id={id} />}
        </>
      </Loading>
    </>
  );
}

export default memo(ProfileIndex);
