import React from "react";

import {
  PropertyDataProps,
  LandlordDataProps,
  ThirdPartyProps,
  PublicServicesProps,
  AdminDataProps,
  FilesProps,
} from "context/closureFormContext/closureFormTypes";
import { CountryUpperCase } from "models/Countries";

import AdminValue from "./AdminValue";
import FilesValue from "./FilesValue";
import LandlordValue from "./LandlordValue";
import PropertyValue from "./PropertyValue";
import PublicServicesValue from "./PublicServicesValue";
import ThirdPartyValue from "./ThirdPartyValue";
import { NoData } from "./utils";

interface ContractValuesProps {
  state:
    | PropertyDataProps
    | LandlordDataProps
    | ThirdPartyProps
    | PublicServicesProps
    | AdminDataProps
    | FilesProps;
  step: string;
  isAdmin: boolean;
  country: "" | CountryUpperCase;
}

const ContractValues: React.FC<ContractValuesProps> = ({ state, step, isAdmin, country }) => {
  if (!state || !country) return <NoData />;
  switch (step) {
    case "propertyData":
      return (
        <>
          {Object.keys(state).map((stateKey) => (
            <PropertyValue
              key={stateKey}
              valueKey={stateKey}
              value={state[stateKey]}
              isAdmin={isAdmin}
              country={country}
            />
          ))}
        </>
      );
    case "landlordData":
      return (
        <>
          {Object.keys(state).map((stateKey) => (
            <LandlordValue
              key={stateKey}
              valueKey={stateKey}
              value={state[stateKey]}
              isAdmin={isAdmin}
              country={country}
            />
          ))}
        </>
      );
    case "thirdPartyData":
      return (
        <>
          {Object.keys(state).map((stateKey) => (
            <ThirdPartyValue
              key={stateKey}
              valueKey={stateKey}
              value={state[stateKey]}
              isAdmin={isAdmin}
              country={country}
            />
          ))}
        </>
      );
    case "publicServices":
      return (
        <>
          {Object.keys(state).map((stateKey) => (
            <PublicServicesValue
              key={stateKey}
              valueKey={stateKey}
              value={state[stateKey]}
              isAdmin={isAdmin}
              country={country}
            />
          ))}
        </>
      );
    case "adminData":
      return (
        <>
          {Object.keys(state).map((stateKey) => (
            <AdminValue
              key={stateKey}
              valueKey={stateKey}
              value={state[stateKey]}
              isAdmin={isAdmin}
              country={country}
            />
          ))}
        </>
      );
    case "fileLinks":
      if (Object.values(state).some((value) => value !== null)) {
        return (
          <>
            {Object.keys(state).map((stateKey) => (
              <FilesValue
                key={stateKey}
                valueKey={stateKey}
                value={state[stateKey]}
                isAdmin={isAdmin}
                country={country}
              />
            ))}
          </>
        );
      }
      return <NoData />;

    default:
      return <NoData />;
  }
};

export default ContractValues;
