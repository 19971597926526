import { MutableRefObject, useEffect } from "react";

const useMapEffect = (
  map: MutableRefObject<any>,
  create: () => (() => void) | void,
  deps: unknown[] = []
): void => {
  useEffect(() => {
    if (!map.current) return;
    create();
  }, [map.current, ...deps]);
};

export default useMapEffect;
