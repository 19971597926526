export const SET_OPEN_ALERT = "SET_OPEN_ALERT";
export const SET_ALERT_INFO = "SET_ALERT_INFO";
export const CLEAR_ALERT_INFO = "SET_ALERT_INFO";
export type SnackbarType = "success" | "warning" | "error";

interface setOpenAlert {
  type: typeof SET_OPEN_ALERT;
  open: boolean;
}

export interface IAlertInfo {
  open: boolean;
  head: string;
  body?: string;
  type: SnackbarType;
}

interface setAlertInfo {
  type: typeof SET_ALERT_INFO;
  alertInfo: IAlertInfo;
}

interface clearAlertInfo {
  type: typeof CLEAR_ALERT_INFO;
  alertInfo: IAlertInfo;
}

export interface State {
  open: boolean;
  alertInfo: IAlertInfo;
}

export type Dispatch = (action: Action) => void;

export type Action = setOpenAlert | setAlertInfo | clearAlertInfo;
