import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { useProject } from "context/projectContext/projectContext";
import { getLocationOptions, createNeighborhood } from "context/projectContext/projectActions";
import { useFormContext, useWatch } from "react-hook-form";
import { useGetToken } from "hooks";
import {
  ControlledSelection,
  ControlledAddressText,
  ControlledTextField,
  ControlledAutocomplete,
  ControlledMap,
} from "Components/Admin/UIComponents/ControlledForm";
import { countryCode } from "utils";
import FormBlock from "./FormBlock";

function Address() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useProject();
  const { project, address: stateAddress } = state;

  const { control, setValue } = useFormContext();

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.project?.property?.country,
  });
  const address = useWatch({ control, name: "address_object" });

  const [coordinates, setCoordinates] = useState<[number, number]>(
    project?.property?.property_details[0]?.longitud
      ? [
          project?.property?.property_details[0]?.longitud,
          project?.property?.property_details[0]?.latitud,
        ]
      : [0, 0]
  );

  useEffect(() => {
    getToken().then(async (authToken) => {
      const neighborhood = (await createNeighborhood(
        address,
        dispatch,
        authToken
      )) as AxiosResponse<any>;
      if (neighborhood?.data) {
        setValue("neighborhood", {
          value: neighborhood.data.id,
          label: `${neighborhood.data.neighborhood}, ${neighborhood.data.commune}, ${neighborhood.data.region} `,
        });
        getLocationOptions(country, dispatch, authToken);
      }
    });
  }, [address]);

  useEffect(() => {
    if (stateAddress) {
      setValue("street_name", stateAddress.street);
      setValue("street_commune", stateAddress.commune);
      setValue("street_number", stateAddress.streetNumber);
      setValue("coordinates", [stateAddress.lng, stateAddress.lat]);
      setValue("neighborhood", null);
      setCoordinates([stateAddress.lng, stateAddress.lat]);
    }
  }, [stateAddress]);

  useEffect(() => {
    if (country) {
      getToken().then((authToken) => getLocationOptions(country, dispatch, authToken));
    }
  }, [country]);

  return (
    <FormBlock>
      <ControlledAddressText
        id="address_object"
        label="Dirección Completa (Nombre calle y número)"
        placeholder="Dirección..."
        defaultValue={
          project &&
          `${project?.property?.address} ${project?.property?.street_number}, ${project?.property?.comuna}, ${project?.property?.region}, ${project?.property?.country}`
        }
        country={country}
      />

      <ControlledTextField
        id="street_number"
        label="Número calle"
        defaultValue={project?.property?.street_number}
      />

      {Boolean(stateAddress?.street) && !stateAddress?.commune && (
        <ControlledAutocomplete id="custom_commune" label="Comuna" options={state?.communes} />
      )}
      <ControlledAutocomplete
        id="neighborhood"
        label="Barrio (opcional)"
        defaultValue={{
          value: state.project?.property?.neighborhood?.id,
          label: state.project?.property?.neighborhood
            ? `${state.project?.property?.neighborhood?.neighborhood}, ${state.project?.property?.neighborhood?.commune}, ${state.project?.property?.neighborhood?.region} `
            : "",
        }}
        options={state.neighborhoods}
      />
      <ControlledMap
        id="coordinates"
        label="Ubicación del proyecto/desarrollo"
        country={project?.property?.country && countryCode(project?.property?.country)}
        defaultValue={coordinates ?? [0, 0]}
      />

      <ControlledSelection
        id="type"
        label="Tipo de vivienda"
        defaultValue={project?.property?.type}
        options={[
          { value: "departamento", label: "Departamento" },
          { value: "casa", label: "Casa" },
        ]}
      />
    </FormBlock>
  );
}

export default React.memo(Address);
