import * as React from "react";
import { makeStyles } from "@material-ui/core";

interface Composition {
  Table: React.FC;
}

const TableLayout: React.FC & Composition = (props) => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const TableContainer: React.FC = (props) => {
  const classes = useStyles();
  return <div className={classes.tableContainer}>{props.children}</div>;
};

TableLayout.Table = TableContainer;

export default TableLayout;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: -theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: "#ECEFF1",
    minHeight: "100vh",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    height: "95%",
    backgroundColor: "#FFF",
    width: "100%",
    padding: theme.spacing(2, 2, 0, 2),
    alignSelf: "center",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
