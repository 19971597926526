import { add } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldInput from "@houm-com/ui/FieldInput";

import useRefundReservation from "Components/Admin/UIComponents/Reservation/hooks/useRefundReservation";
import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { IUserBankAccountResponse } from "services/bankAccountService/types";
import { CurrencyCode } from "models/Currencies";
import { isWeekendDay } from "utils/datetime";
import { Locale } from "models/Countries";

import { parseFormData, parseRefoundAmount } from "./utils/utils";
import { refundSchema } from "./utils/validationSchema";
import { FormData } from "./utils/types";

interface Props {
  reservationAmount: number | null;
  currency: CurrencyCode | null;
  country: Locale;
  dataBankAccount?: IUserBankAccountResponse | null;
  reservationId: string;
  handleModal: () => void;
  propertyId: number;
}

const RefundForm = ({
  reservationAmount,
  currency,
  dataBankAccount,
  reservationId,
  country,
  handleModal,
  propertyId,
}: Props) => {
  const { isLoading, mutate } = useRefundReservation(handleModal);
  const methods = useForm<FormData>({
    resolver: yupResolver(refundSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => {
    const parsedData = parseFormData(reservationId, country, dataForm, dataBankAccount, propertyId);
    mutate(parsedData);
  };

  const percentage = methods.watch("percentage");

  return (
    <FormProvider {...methods}>
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalDialog.Content classNameContent="flex flex-col gap-2 px-1">
          <FieldInput
            id="description"
            label="Motivo de la devolución"
            required
            defaultValue=""
            form="novalidateform"
            placeholder="Ingresa nombre"
            errorMessage={methods.formState.errors.description?.message}
            {...methods.register("description")}
          />
          <ControlledKeyboardDatePicker
            id="agreedDate"
            label="Fecha del reembolso"
            defaultValue={add(new Date(), { days: 1 })}
            dataPickerProps={{
              format: "dd/MM/yyyy",
              minDate: add(new Date(), { days: 1 }),
              shouldDisableDate: (e) => isWeekendDay(e),
            }}
          />
          <FieldInput
            id="percentage"
            label="Porcentaje a reembolsar"
            type="number"
            min={0}
            max={100}
            required
            defaultValue=""
            form="novalidateform"
            errorMessage={methods.formState.errors.percentage?.message}
            {...methods.register("percentage")}
            onChange={(e) => {
              if (Number(e.target.value) > 100) methods.setValue("percentage", "100");
              methods.setValue("percentage", e.target.value);
            }}
          />
          <p className="text-p16 font-normal">
            Monto a reembolsar:{" "}
            {percentage && parseRefoundAmount(reservationAmount, Number(percentage), currency)}
          </p>
        </ModalDialog.Content>
        <ModalDialog.Actions
          submitButtonLabel="Reembolsar"
          submitButtonProps={{ isLoading, disabled: isLoading, type: "submit" }}
          buttonsPosition="center"
        />
      </form>
    </FormProvider>
  );
};

export default RefundForm;
