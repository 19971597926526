import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag";
import TagTypes from "Components/Admin/UIComponents/DesignSystem/DSTag/types";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import useVipChipStyles from "./VipChipStyles";

function VipChip() {
  const classes = useVipChipStyles();
  return (
    <DSTag
      type={TagTypes.WARNING}
      text="VIP"
      icon={<StarIcon />}
      bordered
      classes={{
        wrapperClassName: classes.wrapper,
      }}
    />
  );
}

export default VipChip;
