import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  completedIcon: {
    color: theme.palette.primary.main,
    cursor: "auto",
    margin: "0 auto",
  },
}));

export default useStyles;
