import { useState } from "react";

import { Box, Collapse, Divider, Typography, ButtonBase } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import useStyles from "./CollapseButtonStyles";

interface Props {
  children: React.ReactNode;
  label: string;
}

function CollapseButton({ children, label }: Props) {
  const [open, setOpen] = useState<boolean>(true);

  const classes = useStyles();

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Box className={classes.container}>
        <Typography>{label}</Typography>
        {open ? (
          <ButtonBase onClick={handleOpen}>
            <ExpandLess />
          </ButtonBase>
        ) : (
          <ButtonBase onClick={handleOpen}>
            <ExpandMore />
          </ButtonBase>
        )}
      </Box>
      <Divider className={classes.separator} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}

export default CollapseButton;
