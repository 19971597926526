import classNames from "classnames";

import { ReactComponent as ArrowForward } from "assets/icons/arrows/chevron-right.svg";
import { Link, Typography } from "@material-ui/core";

import useStyles from "./BreadcrumbsStyles";

export interface StepProps {
  link?: string;
  label: string;
}

interface Props {
  steps: StepProps[];
}

function Breadcrumbs({ steps }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {steps.map((step, i) => (
        <div key={step.label} className={classes.label}>
          {step.link ? (
            <Link
              href={step.link}
              className={classNames(classes.hover, classes.labelText, {
                [classes.past]: i < steps.length - 1,
                [classes.active]: i === steps.length - 1,
              })}>
              {step.label}
            </Link>
          ) : (
            <Typography
              component="span"
              className={classNames(classes.labelText, {
                [classes.past]: i < steps.length - 1,
                [classes.active]: i === steps.length - 1,
              })}>
              {step.label}
            </Typography>
          )}
          {i < steps.length - 1 && <ArrowForward className={classes.arrow} />}
        </div>
      ))}
      <br />
    </div>
  );
}

export default Breadcrumbs;
