import { SnackbarProvider } from "notistack";

import Verifik from "./Verifik";

export default function ValidateUserController() {
  return (
    <SnackbarProvider>
      <Verifik />
    </SnackbarProvider>
  );
}
