import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    display: "flex",
    margin: "0px",
    width: "100%",
    padding: theme.spacing(0.3),
    justifyContent: "space-between",
  },
  label: {
    fontSize: theme.typography.pxToRem(20),
  },
  box: {
    marginLeft: theme.spacing(6),
    transform: "scale(1.2)",
  },
}));

export default useStyles;
