import { makeStyles, Theme } from "@material-ui/core";

const useBoldTextStyles = makeStyles((theme: Theme) => ({
  boldTextRoot: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
  },
}));

export default useBoldTextStyles;
