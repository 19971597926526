import React from "react";
import { Grid } from "@material-ui/core";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import useFormStyles from "./FormStyles";
import FormBlock from "./FormBlock";

function ContactFields() {
  const classes = useFormStyles();
  return (
    <FormBlock>
      <Grid container>
        <Grid item xs={12} className={classes.underlined}>
          <ControlledTextField id="phone" label="Teléfono" />
        </Grid>
        <Grid item xs={12} className={classes.underlined}>
          <ControlledTextField id="email" label="Correo" />
        </Grid>
      </Grid>
    </FormBlock>
  );
}

export default React.memo(ContactFields);
