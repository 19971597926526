import { makeStyles, Theme } from "@material-ui/core";

const useStepTitleStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useStepTitleStyles;
