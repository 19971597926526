import { Box, Typography } from "@material-ui/core";
import { UserModel } from "models/User";
import useUserDataStyles from "./UserDataStyles";

interface Props {
  user: UserModel;
  propertyId: number;
}

const UserData = ({ user, propertyId }: Props) => {
  const classes = useUserDataStyles();
  return (
    <>
      <Box className={classes.applicantContainer}>
        <Typography className={classes.applicantData}>ID Propiedad: {propertyId}</Typography>
        <Typography className={classes.applicantData}>
          A nombre de: {`${user.name ?? ""} ${user.last_name ?? ""} ${user.second_last_name ?? ""}`}
        </Typography>
        <Typography className={classes.applicantData}>Documento: {user.document ?? ""}</Typography>
      </Box>
      <Typography className={classes.disclaimer}>
        ** El monto de la reserva a canjear se ajustará dependiendo de lo que se haya pagado en la
        reserva que se encuentra pagada.
      </Typography>
    </>
  );
};

export default UserData;
