import { useState } from "react";
import { useMutation } from "react-query";

import { useGetToken } from "hooks";

import requestsService from "services/requestsService/requestsService";
import { alert } from "libs/alerts";

import { encodeRepairRequest, uploadRepairPhotos } from "./utils";
import { REPAIRS_REQUESTS_ALERTS } from "./constants";
import { IRequestForm, IVisitHour } from "./types";
import useQuotation from "../useQuotation";

const INITIAL_VISIT_HOURS: IVisitHour[] = Array.from({ length: 7 }, () => ({
  hours: [0, 0],
  checked: true,
}));

interface IUseRepairRequestAction {
  onSuccessHandler: () => void;
  onErrorHandler: () => void;
}

const useRepairRequesAction = ({ onSuccessHandler, onErrorHandler }: IUseRepairRequestAction) => {
  const { getToken } = useGetToken();
  const { subscription } = useQuotation();
  const [requestForm, setRequestForm] = useState<IRequestForm>({});
  const [visitHours, setVisitHours] = useState<IVisitHour[]>(INITIAL_VISIT_HOURS);

  const repairRequestMutation = useMutation(
    async () => {
      const authToken = await getToken();
      const fileNames = await uploadRepairPhotos(authToken, requestForm.photos);
      return requestsService.createRepairRequest(
        authToken,
        subscription?.contract?.property?.id,
        encodeRepairRequest({ requestForm, visitHours }, fileNames)
      );
    },
    {
      onSuccess: () => {
        alert.success(REPAIRS_REQUESTS_ALERTS.SUCCESS);
        onSuccessHandler();
      },
      onError: () => {
        alert.error(REPAIRS_REQUESTS_ALERTS.ERROR);
        onErrorHandler();
      },
    }
  );

  return {
    requestForm,
    setRequestForm,
    visitHours,
    setVisitHours,
    submitData: repairRequestMutation.mutate,
    isLoading: repairRequestMutation.isLoading,
  };
};

export default useRepairRequesAction;
