import { Box, Typography } from "@material-ui/core";

import usePenaltyDisclaimerStyles from "./PenaltyDisclaimerStyles";

const PenaltyDisclaimer = () => {
  const classes = usePenaltyDisclaimerStyles();

  return (
    <Box className={classes.disclaimerContext}>
      <Typography className={classes.title} component="h1">
        Información de multas de retrasos del pago del Canon
      </Typography>
      <Box>
        <Typography className={classes.text}>
          <b>Del día 6 al día 10 del mes:</b> 2%
        </Typography>
        <Typography className={classes.text}>
          <b>Del día 11 al día 15 del mes:</b> 4%
        </Typography>
        <Typography className={classes.text}>
          <b>Del día 16 asta el último día del mes:</b> 6%
        </Typography>
      </Box>
      <Typography className={classes.text}>
        % interés de mora (Código Civil) por debajo del máximo legal autorizado por el Gobierno
        Nacional
      </Typography>
    </Box>
  );
};

export default PenaltyDisclaimer;
