import Swal from "sweetalert2";
import { Box, Grid } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import DeleteService from "../../../components/DeleteService";
import NoServices from "../../../components/NoServices";
import { BasicService } from "../../../utils/types";
import CardBold from "../typography/CardBold";
import EditService from "./components/EditService";
import ServiceItems from "./components/ServiceItems";
import usePropertyServicesStyles from "./PropertyServicesStyles";

interface Props {
  country: Locale;
  services: BasicService[];
  loading: boolean;
  error: Error;
}

function PropertyServices({ country, services, loading, error }: Props) {
  const classes = usePropertyServicesStyles();

  if (loading) return <Spinner />;

  if (error) {
    Swal.fire("Error cargando servicios", error.message, "error");
    return null;
  }

  if (services?.length === 0) return <NoServices serviceType="servicios básicos" />;

  return (
    <Box className={classes.propertyServicesRoot}>
      {services?.map((service) => (
        <Box key={service.id} className={classes.serviceWrapper}>
          <Grid container>
            <Grid item xs={6}>
              <CardBold subtitle>{service.name}</CardBold>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={10}>
                <EditService
                  clientNumber={service.client}
                  country={country}
                  propertyId={service.propertyId}
                  company={service.company}
                />
              </Grid>
              <Grid item xs={2}>
                <DeleteService propertyId={service.propertyId} company={service.legacyCompany} />
              </Grid>
            </Grid>
          </Grid>
          <ServiceItems {...service} country={country} />
        </Box>
      ))}
    </Box>
  );
}

export default PropertyServices;
