import { makeStyles, Theme } from "@material-ui/core/styles";

const useAppraiserInformationStyles = makeStyles((theme: Theme) => ({
  wraper: {
    background: "#F7F8FC",
    borderRadius: "15px",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    maxHeight: 500,
    width: "30%",
  },
  container: {
    maxHeight: 400,
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default useAppraiserInformationStyles;
