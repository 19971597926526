import { useState } from "react";

import { Pagination } from "@material-ui/lab";
import { Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Spinner from "Components/Shared/partials/Spinner";

import useQuestionsCardStyles from "./QuestionCardStyles";
import useGetQuestions from "./hooks/useGetQuestions";
import NoQuestions from "./components/NoQuestions";
import AnsweredQuestion from "./components/AnsweredQuestion";
import UnansweredQuestion from "./components/UnansweredQuestion";

interface Props {
  propertyId: number;
}

const PAGE_SIZE = 3;

const QuestionsCard = ({ propertyId }: Props) => {
  const classes = useQuestionsCardStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, count, isLoading, refetch } = useGetQuestions({
    propertyId,
    pageSize: PAGE_SIZE,
    page: currentPage,
    ordering: "-created_at",
  });
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const totalPages = Math.ceil(count / PAGE_SIZE);

  if (isLoading) return <Spinner />;
  if (!data || data.length === 0)
    return (
      <AdminCard>
        <NoQuestions />
      </AdminCard>
    );
  return (
    <AdminCard>
      <Typography className={classes.title}>Preguntas a propietario</Typography>
      <div className={classes.questionsContainer}>
        {data.map((question) =>
          question.answer ? (
            <AnsweredQuestion question={question} />
          ) : (
            <UnansweredQuestion question={question} refetchQuestions={refetch} />
          )
        )}
      </div>
      <div className={classes.pagesContainer}>
        <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
      </div>
    </AdminCard>
  );
};

export default QuestionsCard;
