import { TableProvider } from "context/tableContext/tableContext";

import SolicitationSectionTable from "./components/Table";

import useStyles from "./SolicitationSectionStyles";

function SolicitationSection() {
  const classes = useStyles();
  return (
    <TableProvider>
      <div className={classes.container}>
        <SolicitationSectionTable />
      </div>
    </TableProvider>
  );
}

export default SolicitationSection;
