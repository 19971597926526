import { makeStyles, Theme } from "@material-ui/core";

const useHoumOwnerModalStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
}));

export default useHoumOwnerModalStyles;
