import { SnackbarProvider } from "notistack";
import { TableProvider } from "context/tableContext/tableContext";

import LeadShow from "Components/Admin/administrator/leads/leadProfile/LeadShow";
import { LeadProvider } from "context/leadContext/leadContext";
import { TabProvider } from "context/tabContext/tabContext";
import { AvailavilityProvider } from "context/availabilityContext/availabilityContext";

export default function LeadProfile() {
  return (
    <AvailavilityProvider>
      <LeadProvider>
        <SnackbarProvider>
          <TabProvider>
            <TableProvider>
              <LeadShow />
            </TableProvider>
          </TabProvider>
        </SnackbarProvider>
      </LeadProvider>
    </AvailavilityProvider>
  );
}
