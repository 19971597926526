import { FormControl, FormLabel } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";

import FormHelperText from "@material-ui/core/FormHelperText";

import { useUserSearch } from "hooks";
import { setUser, setUserEmail } from "context/eventContext/eventActions";
import { useEvent } from "context/eventContext/eventContext";
import { UserModel } from "models/User";

import SearchBar from "Components/Admin/UIComponents/molecules/Searchbar";
import UserSearchBarStyles from "./UserSearchBarStyles";

function UserSearchBar({ defaultValue, id, disabled = false }) {
  const classes = UserSearchBarStyles();
  const { handleSearch, loading, users } = useUserSearch();
  const { dispatch } = useEvent();

  const handleUserLabel = (user) => {
    if (!user) return "";
    return `${user.email || ""}`;
  };

  const handleEmailInput = (val) => {
    dispatch(setUserEmail(val));
    handleSearch(val);
  };

  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <FormControl className={classes.container} error={invalid}>
          <FormLabel className={classes.label}>Correo del usuario a agendar</FormLabel>
          <SearchBar
            disabled={disabled}
            id="userSearchBar"
            className={classes.autocomplete}
            placeholder="Email"
            onChange={(e, user) => {
              onChange(user);
              dispatch(setUser(user as UserModel));
            }}
            loading={loading}
            noOptionsText="No se encontraron resultados"
            loadingText="Buscando ..."
            getOptionLabel={(option) => handleUserLabel(option)}
            onInputChange={(e, val) => handleEmailInput(val)}
            options={users}
            value={value}
          />
          <FormHelperText className={classes.helper}>{error && error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
export default UserSearchBar;
