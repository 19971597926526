import { ContractDebtState } from "services/contractService/types";

export const getContractDebtStateLabel = (state: ContractDebtState) => {
  switch (state) {
    case "NO_DEBT":
      return "Sin deuda";
    case "LATE":
      return "En mora";
    case "SINISTER":
      return "En siniestro";
    default:
      return "Estado no registrado";
  }
};
