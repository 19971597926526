import { cn } from "@houm-com/ui/utils";

import { CountryUpperCase, Locale } from "models/Countries";
import { localeToCountry } from "utils/countryCode";
import { countryCode } from "utils";

import classes from "./CountryChipsStyles";

interface CountryChipProps {
  country: CountryUpperCase;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
}

const countryFlag = (country: Locale) => (
  <img
    className={classes.icon}
    alt={`flag ${localeToCountry[country].toLocaleLowerCase()}`}
    src={`https://s3.amazonaws.com/houm.com/static/media/countries/Flag${country.toLocaleUpperCase()}.png`}
  />
);

function CountryChip({
  country,
  onClick = () => {},
  selected = false,
  className = "",
}: CountryChipProps) {
  return (
    <div
      className={cn([classes.container, className, [{ [classes.selected]: selected }]])}
      onClick={onClick}>
      <p>{country}</p>
      {countryFlag(countryCode(country))}
    </div>
  );
}

export default CountryChip;
