import { saveAs } from "file-saver";

import { IoDownloadOutline, IoTrashOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import IconButton from "@houm-com/ui/IconButton";

import { IDocument } from "../../utils/types";
import { classes } from "./ControlPanelStyles";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";

interface Props {
  document: IDocument | null;
  pdfPage: number;
  onChangePage: (page: number) => void;
  fileError: boolean;
  pdfNumPages: number;
  handleOpenModal: () => void;
}

const ControlPanel = ({
  document,
  pdfPage,
  onChangePage,
  fileError,
  pdfNumPages,
  handleOpenModal,
}: Props) => {
  const onDownloadDocument = () => saveAs(document.path, document.filename);

  const handlePdfPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      const val = parseInt(e.target.value);
      if (val > 0 && val <= pdfNumPages) onChangePage(val);
    } else onChangePage(null);
  };

  return (
    <div className={classes.controlPanelRoot}>
      <IconButton
        icon={<IoDownloadOutline size="18" />}
        position="end"
        size="sm"
        variant="primary"
        onClick={onDownloadDocument}>
        Descargar
      </IconButton>
      {document?.content_type === "application/pdf" && !fileError && (
        <div className={classes.pdfPanelControl}>
          <LightButton
            variant="secondary"
            size="lg"
            disabled={pdfPage === 1}
            borderBottom
            className={classes.button}
            onClick={() => onChangePage(pdfPage - 1)}>
            Anterior
          </LightButton>
          <div className="contents">
            <p className="text-p14">Página </p>
            <input
              aria-label="input-page"
              type="num"
              value={pdfPage}
              className={classes.input}
              onChange={handlePdfPage}
              disabled={pdfPage === pdfNumPages || fileError}
            />
            <p className="text-p14"> de {pdfNumPages}</p>
          </div>
          <LightButton
            variant="secondary"
            size="lg"
            borderBottom
            className={classes.button}
            disabled={pdfPage === pdfNumPages || fileError}
            onClick={() => onChangePage(pdfPage + 1)}>
            Siguiente
          </LightButton>
        </div>
      )}
      <ProtectedComponent scope="permissions" require={["update:evaluation_documents:all"]}>
        <IconButton
          icon={<IoTrashOutline size="18" />}
          position="start"
          size="sm"
          variant="tertiary"
          onClick={handleOpenModal}
          className={classes.deleteButton}>
          Eliminar
        </IconButton>
      </ProtectedComponent>
    </div>
  );
};

export default ControlPanel;
