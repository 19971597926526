import { AxiosResponse } from "axios";

import applicantService from "services/applicantService";
import { CountryUpperCase } from "models/Countries";
import leadService from "services/leadService";
import userService from "services/userService";
import { UserModel } from "models/User";

interface ManualUserProps {
  email: string;
  name: string;
  last_name: string;
  document: string;
  documentType: string;
  phone: string;
  country: CountryUpperCase;
}

const useApplicant = async (
  user: UserModel,
  authToken: string,
  propertyId: number = null,
  manualUser: ManualUserProps = null
) => {
  let userTemp;
  if (!user) {
    // TODO: check if user can be null
    try {
      const userData = {
        role: "applicant",
        email: manualUser?.email,
        name: manualUser?.name,
        last_name: manualUser?.last_name,
        phone: manualUser?.phone,
        documentType: manualUser?.documentType,
        document: manualUser?.document,
        country: manualUser?.country,
      };
      const userRes = (await leadService.postLead(userData, authToken)) as AxiosResponse;
      if (userRes.data.email) {
        // TODO: check if email can be null
        const userSearchResponse = await userService.getByEmail(userRes.data.email, authToken);
        userTemp = userSearchResponse.data.user;
      }
    } catch (error) {
      return error;
    }
  }
  userTemp = user || userTemp;
  try {
    const dataApplicant = {
      ...userTemp,
      property_id: propertyId || 74564, //This propId is only to get applicantId for advisory
      occupation: "-",
      last_name: userTemp.last_name || "-",
    };
    const res = (await applicantService.store(dataApplicant, authToken)) as AxiosResponse;
    return res.data.id;
  } catch (error) {
    return error;
  }
};

export default useApplicant;
