export interface IExecutives {
  active: boolean;
  email: string;
  id: number;
  name: string;
  weight: number;
}

export interface IAssing {
  property_id: number;
  subscription_id: number;
  quotation_id: string;
  init_date: string;
  readjust: string;
  landlord_name: string;
  landlord_phone: string;
  landlord_email: string;
  tenant_name: string;
  tenant_phone: string;
  tenant_email: string;
  administration_executive_name: string;
  country: string;
}

export function parseData(data: any) {
  return {
    id_propiedad: data.property_id,
    id_suscripcion: data.subscription_id,
    id_solicitud: data.quotation_id,
    inicio_contrato: data.init_date,
    reajuste: data.readjust,
    nombre_propietario: data.landlord_name,
    telefono_propietario: data.landlord_phone,
    email_propietario: data.landlord_email,
    nombre_arrendatario: data.tenant_name,
    telefono_arrendatario: data.tenant_phone,
    email_arrendatario: data.tenant_email,
    ejecutiva: data.administration_executive_name,
    pais: data.country,
  };
}
