import { SnackbarProvider } from "notistack";

import ProfileIndex from "./ProfileIndex";
import { DashboardProvider } from "context/controlTower/controlTowerContext";

export default function Profile({ uuid }: { uuid?: string }) {
  return (
    <DashboardProvider>
      <SnackbarProvider>
        <ProfileIndex uuid={uuid} />
      </SnackbarProvider>
    </DashboardProvider>
  );
}
