import { IAcceptanceDocumentsResponse } from "services/contractService/types";
import { IAcceptanceDocument } from "./types";

export const parseAcceptanceDocuments = (
  data: IAcceptanceDocumentsResponse,
  acceptanceNamesDictionary
): IAcceptanceDocument[] =>
  Object.keys(data).map((key) => ({
    title: acceptanceNamesDictionary[key],
    link: data[key],
  }));
