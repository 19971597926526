import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  subtitle?: boolean;
}

const useCardSubtitleStyles = makeStyles<Theme, Props>((theme) => ({
  cardSubtitleRoot: {
    width: (props) => (props.subtitle ? "100%" : "auto"),
    marginBottom: (props) => theme.spacing(props.subtitle ? 1 : 0.5),
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 700,
    backgroundColor: (props) => (props.subtitle ? "#EFF2F3" : "transparent"),
  },
}));

export default useCardSubtitleStyles;
