import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  direction?: "normal" | "left" | "right";
  highlight?: boolean;
}

const useCellStyles = makeStyles<Theme, Props>((theme) => ({
  customCell: {
    width: "100%",
    height: "97%",
    padding: theme.spacing(0.125, 1.5),
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    backgroundColor: ({ highlight }) => (highlight ? "#FEF1F0" : "none"),
    fontWeight: ({ highlight }) => (highlight ? 700 : 400),
    borderTopLeftRadius: ({ direction }) => (direction === "left" ? "8px" : "0px"),
    borderBottomLeftRadius: ({ direction }) => (direction === "left" ? "8px" : "0px"),
    borderTopRightRadius: ({ direction }) => (direction === "right" ? "8px" : "0px"),
    borderBottomRightRadius: ({ direction }) => (direction === "right" ? "8px" : "0px"),
  },
}));
export default useCellStyles;
