import { makeStyles, Theme } from "@material-ui/core";

const useTableStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
  label: {
    textTransform: "none",
    color: theme.palette.grey[200],
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginLeft: theme.spacing(1.25),
  },
  vipExecutive: {
    background: "#00982D",
    borderColor: "green",
    color: "white",
    fontWeight: 700,
  },
  vipIcon: {
    color: "#fff",
    fontSize: 20,
  },
  normalExecutive: {
    background: theme.palette.grey[100],
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[900],
    fontWeight: 700,
  },
}));

export default useTableStyles;
