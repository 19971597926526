import { useEffect } from "react";
import { useProperty } from "context/propertyContext/propertyContext";

import { ControlledUserText } from "Components/Admin/UIComponents/ControlledForm";
import NewUserDialog from "Components/Admin/UIComponents/PropertyForm/components/NewUserDialog";
import { setUserModal } from "context/propertyContext/propertyActions";
import { useFormContext, useWatch } from "react-hook-form";
import FormBlock from "./FormBlock";
import Button from "@houm-com/ui/Button";

interface Props {
  label?: string;
  id?: string;
  currentUser?: any;
}

const propertyRoleWarningLookup = {
  propietary: () => (
    <>
      Propiedad de <span className="font-bold text-primary-cta">propietario particular</span>
    </>
  ),
  property_broker: () => (
    <>
      Propiedad para <span className="font-bold text-primary-cta">canje</span>
    </>
  ),
  applicant: () => (
    <span className="font-bold text-primary-cta">
      Este usuario tiene como rol "applicant" el cual no corresponde para crear propiedades
    </span>
  ),
};

function PropertyTitle({ role }: { role: string }) {
  const PropertyTitle = propertyRoleWarningLookup[role] || (() => null);
  return <PropertyTitle />;
}

export default function SelectUser({ id = "user", currentUser = null }: Props) {
  const {
    state: { user },
    dispatch,
  } = useProperty();
  const { reset, getValues, control } = useFormContext();
  const selectedUser = useWatch({
    control,
    name: "user",
  });

  useEffect(() => {
    if (user) {
      reset({ ...getValues(), user });
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      reset({ ...getValues(), user: currentUser });
    }
  }, [currentUser]);
  const openCreateUserModal = () => {
    dispatch(setUserModal(true));
  };

  return (
    <FormBlock>
      <h6 className="text-p20 mb-4">Dueño de la propiedad</h6>
      <ControlledUserText
        id={id}
        label="Buscar usuario"
        defaultValue={user || currentUser}
        qParams={{ role_not: "real_estate_developer" }} // este hay que cambiarlo
        noOptionsText={
          <div>
            <span>No se encontraron resultados: </span>
            <Button
              id="create_user"
              variant="secondary"
              size="sm"
              onMouseDown={() => openCreateUserModal()}>
              Crear propietario
            </Button>
          </div>
        }
      />

      {selectedUser && (
        <span>
          Observación: <PropertyTitle role={selectedUser.role} />
        </span>
      )}
      <NewUserDialog />
    </FormBlock>
  );
}
