import Swal from "sweetalert2";

import DynamicUploadFile from "Components/Admin/UIComponents/DynamicUploadFile";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import applicantService from "services/applicantService";
import { useGetToken } from "hooks";

interface Props {
  uid: string;
  documents: {
    created_at: string;
    filename: string;
    frontend_applicant_uid: string;
    id: string;
    timestamp: string;
    updated_at: string;
  }[];
}

const DocumentsColombia = ({ uid, documents }: Props) => {
  const { getToken } = useGetToken();

  const getDocument = async (id: string) => {
    try {
      const authToken = await getToken();
      applicantService
        .getDocument(id, authToken)
        .then((item) => item.data)
        .then((item) => (window.location.href = item.url));
    } catch {
      Swal.fire({
        type: "error",
        text: "No se pudo obtnener los documentos",
      });
    }
  };

  return (
    <AdminCard title="Documentos" withSpace={false} showTitleBackground={true}>
      <DynamicUploadFile
        documentsList={documents}
        saveUrl="applicant_documents/upload/"
        removeUrl="applicant_documents/remove/"
        uid={uid}
        onAction={(e: string) => getDocument(e)}
      />
    </AdminCard>
  );
};

export default DocumentsColombia;
