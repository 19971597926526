import { prop } from "lodash/fp";
import {
  ParseInspectionKeyInfo,
  ParseReportActions,
  ParseReportListItem,
  ParseSpaceQualification,
  ParseSpaceReport,
} from "./types";

export const parseReportActions: ParseReportActions = (reportActions) => ({
  id: reportActions.id,
  actionBy: reportActions.action_by,
  observation: reportActions.observation,
  photos: reportActions.photos.map(prop("photo_link")),
  createdAt: reportActions.created_at,
});

export const parseSpaceReport: ParseSpaceReport = (spaceReport) => ({
  id: spaceReport.id,
  feature: spaceReport.feature,
  status: spaceReport.status,
  reportActions: spaceReport.report_actions.map(parseReportActions),
});

export const parseSpaceQualification: ParseSpaceQualification = (spaceQualification) => ({
  id: spaceQualification.id,
  name: spaceQualification.space.type,
  qualification: spaceQualification.qualification,
  observation: spaceQualification.observation,
  photos: spaceQualification.photos.map(prop("photo_link")),
  reports: spaceQualification.space.reports.map(parseSpaceReport),
});

export const parseKeyInfo: ParseInspectionKeyInfo = (keyInfo) => ({
  id: keyInfo?.id,
  updatedAt: keyInfo?.updated_at,
  location: keyInfo?.location,
  frontDoorCopies: keyInfo?.front_door_copies,
  condoCopies: keyInfo?.condo_copies,
  garageCopies: keyInfo?.garage_copies,
  warehouseCopies: keyInfo?.warehouse_copies,
  otherCopies: keyInfo?.other_copies,
  otherCopiesDescription: keyInfo?.other_copies_description,
  photos: keyInfo?.photos?.map(prop("photo_link")),
});

export const parseReportListItem: ParseReportListItem = (inspection) => ({
  id: inspection.id,
  propertyId: inspection.property,
  createdAt: inspection.created_at,
  updatedAt: inspection.updated_at,
  appraiser: inspection.appraiser,
  isHabitable: inspection.is_habitable,
  servicesStatus: {
    waterStatus: inspection.water_status,
    energyStatus: inspection.energy_status,
    gasStatus: inspection.gas_status,
  },
  requiresCleaning: inspection.requires_cleaning,
  generalObservation: inspection.general_observation,
  cleaningObservation: inspection.cleaning_observation,
  keysInfo: parseKeyInfo(inspection.keys_info),
  tenantProof: inspection.tenant_proof,
  scheduleType: inspection.schedule_type,
  spaceQualifications: inspection.space_qualifications.map(parseSpaceQualification),
});
