import { useState } from "react";
import Swal from "sweetalert2";
import propertyService from "../../../../../services/propertyService";
import { useGetToken } from "../../../../../hooks";

function ChangeForSaleForRental(props) {
  const { getToken } = useGetToken();
  const [forRental, setForRental] = useState(props.property.for_rental);
  const [forSale, setForSale] = useState(props.property.for_sale);

  const handleChange = async () => {
    let data = null;
    if (props.dealType === "for_sale") {
      const newValue = !forSale;
      setForSale(newValue);
      data = {};
      data.for_sale = newValue;
    } else if (props.dealType === "for_rental") {
      const new_value = !forRental;
      setForRental(new_value);
      data = {};
      data.for_rental = new_value;
    }

    const authToken = await getToken();
    propertyService
      .update(props.property.uid, data, authToken)
      .then((item) =>
        Swal.fire("Success", "Se ha actualizado el estado de la propiedad", "success")
      )
      .catch((error) => Swal.fire("Error", "No se ha podido completar la solicitud", "error"));
  };

  if (props.dealType === "for_sale") {
    return (
      <span>
        <input
          type="checkbox"
          defaultChecked={forSale}
          onChange={() => handleChange()}
          disabled={forSale}
        />
        {props.text}
      </span>
    );
  }
  if (props.dealType === "for_rental") {
    return (
      <span>
        <input
          type="checkbox"
          defaultChecked={forRental}
          disabled={forRental}
          onChange={() => handleChange()}
        />
        {props.text}
      </span>
    );
  }
}

export default ChangeForSaleForRental;
