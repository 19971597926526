import moment from "moment";

export const getTimeDifference = (time) => {
  const now = moment();
  const momentTime = moment(time);
  const isNegative = now.isAfter(momentTime);
  const diffMinutes = Math.abs(now.diff(momentTime, "m"));
  const diffHours = Math.abs(now.diff(momentTime, "h"));

  if (diffHours < 24) {
    const hourStr = diffHours < 10 ? `0${diffHours}` : diffHours;
    const minutes = diffMinutes % 60;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${isNegative ? "-" : ""} ${hourStr}:${minutesStr}`;
  }
  if (diffHours >= 24 && diffHours < 48) return `${isNegative ? "-" : ""} 1 día`;
  return `${isNegative ? "-" : ""} ${Math.floor(diffHours / 24)} días`;
};
