import { IUseEditContractConditions } from "./types";

export type MoneyContract = {
  [key in "CLP" | "CLF"]: "pesos" | "uf";
};

export const typeMoneyContract: MoneyContract = {
  CLP: "pesos",
  CLF: "uf",
};

export const parserFormData = (data: IUseEditContractConditions) => ({
  ...(data.planId && {
    plan: data.planId,
  }),
  ...(data.currencyCode && {
    type_money: typeMoneyContract[data.currencyCode],
  }),
  ...(data.amount && {
    amount: Number(data.amount),
  }),
  ...(data.commonExpenses && {
    common_expenses: Number(data.commonExpenses),
  }),
  ...(data.readjust && {
    readjust: data.readjust,
  }),
});
