import { MouseEvent, useCallback, useState } from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { IoEllipsisVerticalCircleOutline, IoEllipsisVerticalCircleSharp } from "@houm-com/ui-icons";

import { ActionRecordField } from "models/Payments";
import useActionsMenuStyles from "./ActionsMenuStyles";
import LightButton from "@houm-com/ui/LightButton";

interface Action {
  label: string;
  onClick: Function;
}

interface ActionsMenuProps {
  actions: Action[];
  record: ActionRecordField;
}

function ActionsMenu({ actions, record }: ActionsMenuProps) {
  const classes = useActionsMenuStyles();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleActionsClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => setAnchor(e.currentTarget),
    []
  );

  const handleActionsClose = () => setAnchor(null);

  const handleActionItem = (item: Action) => {
    handleActionsClose();
    item.onClick({
      movementId: record.movement_id,
      country: record.country,
      agreedDate: record.agreed_date,
      totalAmount: record.total_amount,
      penalty: record.penalty,
      concept: record.concept,
      movementType: record.type,
    });
  };

  return (
    actions.length > 0 && (
      <>
        <LightButton
          size="sm"
          variant="primary"
          onClick={handleActionsClick}
          icon={
            anchor ? (
              <IoEllipsisVerticalCircleSharp className="w-5 h-5" />
            ) : (
              <IoEllipsisVerticalCircleOutline className="w-5 h-5" />
            )
          }>
          Opciones
        </LightButton>
        {actions.length > 0 && (
          <Menu
            id={`actionsMenu-${record.movement_id}`}
            anchorEl={anchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(anchor)}
            keepMounted
            onClose={handleActionsClose}
            getContentAnchorEl={null}
            classes={{
              paper: classes.paper,
            }}>
            {actions.map((item) => (
              <MenuItem
                key={`actionItem-${item.label}`}
                className={classes.menuItem}
                onClick={() => handleActionItem(item)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    )
  );
}

export default ActionsMenu;
