import Spinner from "Components/Shared/partials/Spinner";

import TemplateView from "./components/TemplateView";
import Templates from "./components/Templates";
import { useTemplates } from "./hooks";

function ContractTemplates() {
  const { id, fetchingTemplates, view } = useTemplates();

  if (fetchingTemplates) return <Spinner />;

  if (id) return <TemplateView />;

  switch (view) {
    case "templateForm":
      return <TemplateView />;
    case "templates":
    default:
      return <Templates />;
  }
}

export default ContractTemplates;
