import * as yup from "yup";

const REQUIRED_TEXT = "Este campo es obligatorio";
const REQUIRED_NUMBER = "Este campo debe ser un numero entero positivo";

export default yup.object({
  name: yup.string().required(REQUIRED_TEXT),
  bedrooms: yup.number().required(REQUIRED_TEXT).typeError(REQUIRED_NUMBER),
  built_area: yup.number().required(REQUIRED_TEXT).typeError(REQUIRED_NUMBER),
  description: yup.string().required(REQUIRED_TEXT),
  bathrooms: yup.number().required(REQUIRED_TEXT).typeError(REQUIRED_NUMBER),
  total_area: yup.number().required(REQUIRED_TEXT).typeError(REQUIRED_NUMBER),
  external_description: yup.string().required(REQUIRED_TEXT),
});
