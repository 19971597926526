import ProjectsTable from "./table/ProjectsTable";
import { ProjectProvider } from "context/projectContext/projectContext";
import { TableProvider } from "context/tableContext/tableContext";

export default function Projects() {
  return (
    <ProjectProvider>
      <TableProvider>
        <ProjectsTable />
      </TableProvider>
    </ProjectProvider>
  );
}
