import { useQuery, UseQueryResult } from "react-query";

import { IMovements } from "services/newPaymentService/types";
import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useMovements = (country: Locale, subId: number, type: "income" | "outflow") => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IMovements[], Error> = useQuery<IMovements[], Error>(
    [`${type}Movements`, country, subId, type],
    async () => {
      const authToken = await getToken();
      const incomeResponse = await newPaymentService.getAllSubscriptionMovements(
        country,
        subId,
        type,
        authToken
      );
      return incomeResponse.data.movements;
    },
    {
      refetchOnMount: true,
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useMovements;
