import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from "@material-ui/icons";

import useStyles from "./ToggleBoxStyles";

interface IPropsToggleBox {
  open?: boolean;
  title?: string;
  titleChip?: string;
  children?: React.ReactNode;
}

function ToggleBox({ children, open = true, title, titleChip, ...props }: IPropsToggleBox) {
  const [toggle, setToggle] = useState<boolean>(open);
  const classes = useStyles({ toggle });

  return (
    <div className={classes.container} {...props}>
      <div className={classes.titleContainer}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.title}>{title}</Typography>
          {titleChip && <Chip label={titleChip} className={classes.chip} />}
        </Box>
        <IconButton className={classes.toggleButton} onClick={() => setToggle((prev) => !prev)}>
          {toggle ? (
            <RemoveCircleOutlineOutlined className={classes.icon} />
          ) : (
            <AddCircleOutlineOutlined className={classes.icon} />
          )}
        </IconButton>
      </div>
      <div className={classes.toggleContainer}>{children}</div>
    </div>
  );
}

export default ToggleBox;
