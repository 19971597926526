import { useCallback, useState } from "react";

const usePromotionStatus = () => {
  const [status, setStatus] = useState<"finalized" | "actives">("actives");

  const handleStatus = useCallback((newStatus: "finalized" | "actives") => {
    setStatus(newStatus);
  }, []);

  return {
    status,
    handleStatus,
  };
};

export default usePromotionStatus;
