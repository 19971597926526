import { makeStyles, Theme } from "@material-ui/core";

const usePropertyServicesStyles = makeStyles((theme: Theme) => ({
  propertyServicesRoot: {
    marginTop: theme.spacing(1),
  },
  serviceWrapper: {
    marginBottom: theme.spacing(3),
  },
}));

export default usePropertyServicesStyles;
