import { IStatusFilter } from "context/multifamilyContext/multifamily/multifamilyTypes";

export const MFStatus: IStatusFilter[] = [
  {
    label: "Activo",
    value: true,
  },
  {
    label: "Inactivo",
    value: false,
  },
];
