import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

import ModalDialog from "@houm-com/ui/ModalDialog";

import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import ChargeDiscountForm from "./components/ChargeDiscountForm";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  movementId: string;
  country: Locale;
  date: string;
  handleCloseCreateMovement: () => void;
  adjustmentType?: "monthly_payment" | "closing_payment" | "payment_creation" | "spot_payment";
  movementType: "INCOME" | "OUTFLOW" | "PROFIT";
  contractCurrency: CurrencyCode;
}

function CreateDiscountChargeModal({
  open,
  onHandleClose,
  movementId,
  country,
  date,
  handleCloseCreateMovement,
  adjustmentType = "monthly_payment",
  movementType,
  contractCurrency,
}: Props) {
  return (
    <ModalDialog
      isOpen={open}
      subtitle={date && format(new Date(date), "PPP", { locale: esLocale })}
      title="Agregar descuento/abono"
      onClose={onHandleClose}
      hasCloseBtn>
      <ChargeDiscountForm
        movementId={movementId}
        country={country}
        onClose={handleCloseCreateMovement}
        adjustmentType={adjustmentType}
        movementType={movementType}
        contractCurrency={contractCurrency}
      />
    </ModalDialog>
  );
}

export default CreateDiscountChargeModal;
