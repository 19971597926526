import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import {
  IDebtCollectionResponse,
  IDebtCollectionFilters,
} from "hooks/useGetDebtCollections/utils/types";
import { IDebtCollectionUpdateInsurance } from "hooks/useChangeDebtCollectionInsurance/utils/types";
import { IDebtCollectionUpdateInsuranceStatusBody } from "hooks/useChangeDebtCollectionInsuranceStatus/utils/types";
import { IDebtCollectionInsurancesResponse } from "hooks/useGetDebtCollectionInsurances/utils/types";
import { IDebtCollectionDetailResponse } from "hooks/useGetDebtCollectionDetail/utils/types";

export default {
  getDebtCollections(
    token: string,
    filters?: IDebtCollectionFilters
  ): Promise<AxiosResponse<IDebtCollectionResponse>> {
    return createHttp(token).get(`/admin/v2/debt-collection/collection-processes`, {
      params: filters,
    });
  },
  getDebtCollectionInsurances(
    token: string
  ): Promise<AxiosResponse<IDebtCollectionInsurancesResponse>> {
    return createHttp(token).get(`/admin/v2/debt-collection/insurance-processes/statuses`);
  },
  getDebtCollectionDetail(
    token: string,
    collectionProcessId: number
  ): Promise<AxiosResponse<IDebtCollectionDetailResponse>> {
    return createHttp(token).get(
      `/admin/v2/debt-collection/collection-processes/detailed-information?collection_process_id=${collectionProcessId}`
    );
  },
  updateInsurance(
    token: string,
    contractId: string,
    companyCode: string,
    body: IDebtCollectionUpdateInsurance
  ): Promise<AxiosResponse<IDebtCollectionUpdateInsurance>> {
    return createHttp(token).post(
      `admin/v2/debt-collection/insurance-processes?contract_id=${contractId}&company_code=${companyCode}`,
      body
    );
  },
  updateInsuranceStatus(
    token: string,
    body: IDebtCollectionUpdateInsuranceStatusBody
  ): Promise<AxiosResponse<IDebtCollectionUpdateInsurance>> {
    return createHttp(token).post(
      `admin/v2/debt-collection/insurance-processes/update-status`,
      body
    );
  },
};
