import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  error: boolean;
  bordered: boolean;
  wrapperPadding?: boolean;
  warning?: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  wrapper: {
    margin: "0px",
    width: "100%",
    padding: ({ wrapperPadding }) => (wrapperPadding ? theme.spacing(0.3) : 0),
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  textFieldRoot: {
    width: "100%",
    "& input.MuiInputBase-input": {
      color: theme.palette.grey[500],
    },
    border: ({ bordered }) => (bordered ? `1px solid ${theme.palette.grey[300]}` : ""),
    borderRadius: ({ bordered }) => (bordered ? "10px" : ""),
    "& p.MuiFormHelperText-root": {
      "&.Mui-error": {
        color: theme.palette.primary.main,
      },
      color: (props) => (props.warning ? theme.palette.warning.main : theme.palette.grey[900]),
    },
  },
  underline: {
    "&&&:before": {
      borderWidth: theme.spacing(0.1),
      borderColor: "#ECEFF1",
    },
    "&&:after": {
      borderWidth: theme.spacing(0.1),
      borderColor: ({ error }) => (error ? theme.palette.primary.main : theme.palette.grey[500]),
    },
  },
  characterCount: {
    textAlign: "right",
    fontSize: theme.typography.pxToRem(12),
    color: "#7D8488",
  },
}));
