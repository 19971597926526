import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

import { parseNewService } from "../utils/parsers";
import { ServiceForm } from "../utils/types";

const useBasicServiceForm = (
  propertyId: number,
  handleClose: () => void,
  update?: boolean,
  handleResetData?: () => void
) => {
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate: submitService, isLoading: submitServiceLoading } = useMutation(
    async (formData: ServiceForm) => {
      const authToken = await getToken();
      if (update) {
        await propertyService.updatePropertyBasicService(
          propertyId,
          authToken,
          parseNewService(formData, propertyId)
        );
      } else {
        await propertyService.addPropertyBasicService(
          propertyId,
          authToken,
          parseNewService(formData, propertyId)
        );
      }
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["propertyServices"]);
        if (update) handleResetData();
      },
      onSuccess: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: `Servicio básico ${update ? "modificado" : "agregado"} correctamente`,
            type: "success",
          })
        );

        handleClose();
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: `No se pudo ${update ? "modificar" : "agregar"} el servicio básico`,
            type: "error",
            body: `Error: ${reason ? reason?.data?.detail : e}`,
          })
        );
      },
    }
  );

  return {
    submitService,
    submitServiceLoading,
  };
};

export default useBasicServiceForm;
