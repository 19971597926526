import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import promotionService from "domains/promotion/service";
import { setAlertInfo } from "context/alertContext/alertActions";
import { PromotionForm } from "domains/promotion/service/types";
import { useAlert } from "context/alertContext/alertContext";
import { useGetToken } from "hooks";

const useEditPromotion = () => {
  const queryClient = useQueryClient();
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();

  const {
    mutate: editPromotion,
    isError,
    isLoading: isEditLoading,
    isSuccess,
    error,
  } = useMutation(
    async (data: PromotionForm) => {
      const authToken = await getToken();
      const response = await promotionService.editPromotion(data, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("promotions_list");
        dispatch(
          setAlertInfo({
            open: true,
            head: "La promoción se ha editado correctamente",
            type: "success",
          })
        );
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        console.error(reason);
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error al editar promoción",
            type: "error",
          })
        );
      },
    }
  );

  return {
    editPromotion,
    isEditLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useEditPromotion;
