/**
 * schedule utils to handle availability
 */

import moment, { Moment } from "moment";

import { Timezones, CountryUpperCase } from "models/Countries";
import { getTimezoneWithCommune } from "./datetime";

const hourFormat = "HH:mm";

export interface Availability {
  //[2022-04-07] : ["2022-04-07T13:00:00", "2022-04-07T14:00:00"]
  [key: string]: string[]; //
}

interface ApplyFilterParam {
  availability: Availability;
  tz?: Timezones;
  country: CountryUpperCase;
  commune: string;
  removeConditions: ((hour: Moment, today: Moment) => boolean)[];
}

/**
 * @param hour: timestamp hour like 2022-04-07T13:00:00
 * @param country: property country
 * @param commune: necessary to handle tijuana timezone
 * @return a formatted hour  like 18:00
 */
export const getFormattedHour = (hour: string, country: CountryUpperCase, commune: string) =>
  moment.utc(hour).clone().tz(getTimezoneWithCommune(country, commune)).format(hourFormat);
/**
 *
 * @param avalialbility: response from scheduler or avalialability filtered
 * @param tz: timezone to handle hours and days
 * @param country: property country
 * @param commune: necessary to handle tijuana timezone
 * @param removeConditions: () conditionals to remove the hour
 * @returns avaliability without the hours
 */
export const applyFilterToHours = ({
  availability,
  tz,
  country,
  commune,
  removeConditions = [],
}: ApplyFilterParam) => {
  const timezone = tz || getTimezoneWithCommune(country, commune);
  const today = moment.utc().tz(timezone);

  const newAvailability = Object.values(availability).reduce((prevValue, hourList) => {
    hourList.forEach((hour) => {
      const utcHour = moment.utc(hour).clone().tz(timezone);

      if (removeConditions.length > 0) {
        const shouldRemove = removeConditions.find((fn) => fn(utcHour, today));
        if (shouldRemove) return;
      }

      const utcDay = utcHour.format("YYYY-MM-DD");
      if (prevValue[utcDay]) {
        prevValue[utcDay].push(hour);
      } else {
        prevValue[utcDay] = [hour];
      }
    });
    return prevValue;
  }, {});
  return newAvailability;
};

/**
 * hour filters
 */

// * moment and today params are using the country timezone

export const removeToday = (hour: Moment, today: Moment) => hour.isSame(today, "day");

export const removeHourBeforeToday = (hour: Moment, today: Moment) => hour.isBefore(today, "day");

//remove next day if already past midday
export const pastDayMidday = (hour: Moment, today: Moment) =>
  today.clone().add(1, "day").isSame(hour, "day") && today.hour() >= 12;

/**
 * @param hours: Array of hours in ISO8601 format
 * @returns array of consecutive hours like [{beginHour: 08:00, endHour: 9:00]
 * ! hours should be sorted by timezone hour
 */
export const consecutiveHours = (
  hours: string[],
  tz?: Timezones,
  country?: CountryUpperCase,
  commune?: string
) => {
  let leftIndex = 0;
  let rightIndex = 0;

  let batchs = [];

  const timezone = tz || getTimezoneWithCommune(country, commune);

  while (leftIndex < hours.length) {
    const next = hours[rightIndex + 1];
    const current = hours[rightIndex];

    const beginHour = moment.utc(hours[leftIndex]).tz(timezone).format(hourFormat);

    const endHour = moment.utc(current).add(1, "hour").tz(timezone).format(hourFormat);

    const batchItem = {
      beginHour,
      endHour: endHour === "00:00" ? "24:00" : endHour,
    };

    if (next) {
      rightIndex++;
      const isConsecutive =
        moment(next).tz(timezone).hour() - moment(current).tz(timezone).hour() === 1;
      if (!isConsecutive) {
        leftIndex = rightIndex;
        batchs = [...batchs, batchItem];
      }
    } else {
      batchs = [...batchs, batchItem];
      leftIndex = hours.length;
    }
  }
  return batchs;
};

interface PropertyDay {
  day: number;
  begin_hour: number;
  end_hour: number;
}

/**
 *
 * @param days: comes from schedules/property-availability, is the schedule of property
 * @param tz: if tz is not provided, it will be calculate from property data
 * @param country
 * @param commune
 * @returns a object where the key is the day number, 1-> monday, 7 ->sunday, the value of each key is an array with utcFormat
 */

export const getPropertyDays = (days: PropertyDay[], tz?: Timezones, country?, commune?) => {
  const timezone = tz || getTimezoneWithCommune(country, commune);
  const utcDays = days.reduce((prev, current) => {
    const formatted = moment.utc(current.begin_hour, "HH").tz(timezone).toISOString();
    return {
      ...prev,
      [current.day]: prev[current.day] ? [...prev[current.day], formatted] : [formatted],
    };
  }, {});

  const sorted = Object.entries<string[]>(utcDays).reduce(
    (prev, [day, hours]) => ({
      ...prev,
      [day]: hours.sort(
        (a, b) => moment.utc(a).tz(timezone).hour() - moment.utc(b).tz(timezone).hour()
      ),
    }),
    {}
  );

  return sorted;
};

/**
 * @param availability object where the key is a date o day, arrays with time in ISO8601 format are the values
 * @returns the object with the same keys but the values are an array of time range
 */

export const availabilityToRange = (
  availability: Availability,
  tz?: Timezones,
  country?: CountryUpperCase,
  commune?: string
) => {
  const timezone = tz || getTimezoneWithCommune(country, commune);
  const ranges = Object.entries<string[]>(availability).reduce(
    (prev, [day, hours]) => ({
      ...prev,
      [day]: consecutiveHours(hours, timezone),
    }),
    {}
  );

  return ranges;
};
