import { useState } from "react";
import copy from "copy-to-clipboard";
import classNames from "classnames";

import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Button, Typography } from "@material-ui/core";

import { MovementBreakdownRecord } from "models/Payments";
import { ME_HOUM_COM_URL } from "env";

import useButtonCopyLinkStyles from "./ButtonCopyLinkStyles";

interface DetailProps {
  movement: MovementBreakdownRecord;
}

const ButtonCopyLink = ({ movement }: DetailProps) => {
  const classes = useButtonCopyLinkStyles();
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    setCopied(true);
    if (movement.country === "co") {
      const concept = movement.concept === "CONTRACT" ? "contract" : "lease";
      const link = `${ME_HOUM_COM_URL}/${concept}/${movement.country}/pay/${movement.movement_id}`;
      copy(link);
    } else copy(movement.payment_link);
    setTimeout(() => {
      setCopied(false);
    }, 750);
  };

  return (
    <Button
      variant="contained"
      className={classNames(classes.cgdButton, {
        [classes.cgdButtonBase]: !copied,
        [classes.cgdButtonGreen]: copied,
      })}
      onClick={() => copyLink()}>
      <div className={classes.buttonContainer}>
        {copied ? (
          <>
            <CheckCircleOutlineOutlinedIcon className={classes.icon} />
            <Typography component="p">Link copiado</Typography>
          </>
        ) : (
          <>
            <FileCopyOutlinedIcon className={classes.icon} />
            <Typography component="p">Copiar link de pago</Typography>
          </>
        )}
      </div>
    </Button>
  );
};

export default ButtonCopyLink;
