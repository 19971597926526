import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface FormData {
  evaluationId: string;
  documentId: string;
  country: Locale;
}

interface Props {
  onHandleSuccess?: () => void;
}

const useDeleteFile = ({ onHandleSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const queryMutate = useMutation(async (formData: FormData) => {
    const authToken = await getToken();
    const response = await service.deleteEvaluationFile(
      formData.country,
      formData.evaluationId,
      formData.documentId,
      authToken
    );
    return response?.data;
  });

  useEffect(() => {
    if (queryMutate.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      onHandleSuccess?.();
      alert.success({
        message: "Archivo eliminado correctamente",
      });
    }
  }, [queryMutate.isSuccess, queryClient]);

  useEffect(() => {
    if (queryMutate.isError) {
      alert.error({
        message: "Error no se pudo eliminar el archivo",
      });
    }
  }, [queryMutate.isError]);

  return queryMutate;
};

export default useDeleteFile;
