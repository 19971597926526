import { useCallback } from "react";
import { SnackbarProvider } from "notistack";

import { PropertyProvider } from "context/propertyContext/propertyContext";
import { useAlert } from "context/alertContext/alertContext";
import { clearAlertInfo } from "context/alertContext/alertActions";
import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import ConciergeData from "./components/ConciergeData";

export default function Concierge({ property }) {
  const {
    state: { alertInfo },
    dispatch,
  } = useAlert();

  const handleSnackbarClose = useCallback(() => {
    dispatch(clearAlertInfo());
  }, []);

  return (
    <SnackbarProvider>
      <PropertyProvider>
        <ConciergeData property={property} />
      </PropertyProvider>
      <DesignSystemSnackbar
        type={alertInfo.type}
        open={alertInfo.open}
        onClose={handleSnackbarClose}
        head={alertInfo.head}
        body={alertInfo.body}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </SnackbarProvider>
  );
}
