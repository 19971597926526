import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
  },
  grayColor: { color: theme.palette.grey[500] },
}));

export default useStyles;
