import { makeStyles, Theme } from "@material-ui/core";

const useReadjustmentTooltipStyles = makeStyles<Theme>((theme) => ({
  arrow: {
    color: "white",
  },
  tooltip: {
    boxShadow: "3px 4px 10px rgba(38, 50, 56, 0.15)",
    backgroundColor: "white",
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    height: "100%",
    maxWidth: 400,
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    color: "red",
  },
}));

export default useReadjustmentTooltipStyles;
