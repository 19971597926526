import { Button, Typography } from "@material-ui/core";

import { ReactComponent as EditSvg } from "assets/icons/create.svg";
import useEditButtonStyles from "./EditButtonStyles";

interface Props {
  onClick: () => void;
}

const EditButton = ({ onClick }: Props) => {
  const classes = useEditButtonStyles();

  return (
    <Button
      classes={{
        root: classes.editButton,
        label: classes.label,
      }}
      onClick={onClick}>
      <Typography className={classes.editText}>Editar</Typography>
      <EditSvg className={classes.icon} />
    </Button>
  );
};

export default EditButton;
