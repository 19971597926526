import { IProperty } from "models";
import { ISuggestedProperty } from "./types";

export const parseSearchProperty = (properties: IProperty[]): ISuggestedProperty[] =>
  properties.map((property) => ({
    id: property.id,
    uid: property.uid,
    address: property.address,
    commune: property.comuna,
    streetNumber: property.street_number,
    apartmentNumber: property.numero_depto,
    type: property?.type,
  }));
