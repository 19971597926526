import Swal from "sweetalert2";

import { Locale } from "models/Countries";
import { LetterOfIntentDetail } from "services/negotiationService/models";

import {
  Action,
  ActionType,
  BankData,
  Dispatch,
  DocumentData,
  FileStatus,
  Negotiation,
  Owner,
  Bidder,
  State,
  BidFees,
  PersonType,
  PersonTypes,
  Property,
} from "./letterOfIntentTypes";
import {
  parseBankData,
  parseBidders,
  parseDocuments,
  parseNegotiation,
  parseOwner,
  parseOwners,
  parseProperty,
  unparseOwner,
  unparseBidFees,
  unparseProperty,
} from "./utils";
import negotiationService from "services/negotiationService/negotiationService";

export const setLoading = (loading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const setOwner = (owner: Owner): Action => ({
  type: ActionType.SET_OWNER,
  owner,
});

export const setBidders = (bidders: Bidder[]): Action => ({
  type: ActionType.SET_BIDDERS,
  bidders,
});

export const setOwners = (owners: Owner[]): Action => ({
  type: ActionType.SET_OWNERS,
  owners,
});

export const setNegotiation = (negotiation: Negotiation): Action => ({
  type: ActionType.SET_NEGOTIATION,
  negotiation,
});

export const setBankData = (bankData: BankData): Action => ({
  type: ActionType.SET_BANK_DATA,
  bankData,
});

export const setDocuments = (documents: DocumentData[]): Action => ({
  type: ActionType.SET_DOCUMENTS,
  documents,
});

export const setCountry = (country: Locale): Action => ({
  type: ActionType.SET_COUNTRY,
  country,
});

export const setLoiId = (loiId: string): Action => ({
  type: ActionType.SET_LOI_ID,
  loiId,
});

export const setPersonId = (personId: string): Action => ({
  type: ActionType.SET_PERSON_ID,
  personId,
});

export const setPerson = (person: Bidder | Owner): Action => ({
  type: ActionType.SET_PERSON,
  person,
});

export const setProperty = (property: Property): Action => ({
  type: ActionType.SET_PROPERTY,
  property,
});

export interface getLetterOfIntentArgs {
  country: Locale;
  loiId: string;
  token: string;
  dispatch: Dispatch;
}

export const getLetterOfIntent = async ({
  country,
  loiId,
  token,
  dispatch,
}: getLetterOfIntentArgs) => {
  dispatch(setLoading(true));
  try {
    const res = await negotiationService().getLOI(country, loiId, token);
    const loiDetail: LetterOfIntentDetail = res.data;

    if (loiDetail.negotiation.id) {
      const biddersRes = await negotiationService().getNegotiationsBidders(
        country,
        loiDetail.negotiation.id,
        token
      );
      const ownersRes = await negotiationService().getNegotiationsOwners(
        country,
        loiDetail.negotiation.id,
        token
      );
      const bidders = biddersRes.data;
      const owners = ownersRes.data;
      dispatch(setBidders(parseBidders(bidders)));
      dispatch(setOwners(parseOwners(owners)));
    }
    dispatch(setNegotiation(parseNegotiation(loiDetail.negotiation)));
    dispatch(setBankData(parseBankData(loiDetail.owner)));
    dispatch(setOwner(parseOwner(loiDetail.owner)));
    dispatch(setDocuments(parseDocuments(loiDetail.documents)));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface getLetterOfIntentPersonArgs {
  personId: string;
  type: PersonType;
  state: State;
  dispatch: Dispatch;
}

export const getLetterOfIntentPerson = async ({
  personId,
  type,
  state,
  dispatch,
}: getLetterOfIntentPersonArgs) => {
  dispatch(setLoading(true));
  try {
    if (type === PersonTypes.BIDDER && state.bidders) {
      dispatch(setPerson(state.bidders.find((bidder) => bidder.id === personId)));
    } else if (type === PersonTypes.OWNER && state.owners) {
      dispatch(setPerson(state.owners.find((owner) => owner.id === personId)));
    }
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface getLetterOfIntentNegotiationPropertyArgs {
  country: Locale;
  negotiationId: string;
  token: string;
  dispatch: Dispatch;
}

export const getLetterOfIntentNegotiationProperty = async ({
  country,
  negotiationId,
  token,
  dispatch,
}: getLetterOfIntentNegotiationPropertyArgs) => {
  dispatch(setLoading(true));
  try {
    const res = await negotiationService().getNegotiationProperty(country, negotiationId, token);
    dispatch(setProperty(parseProperty(res.data)));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface editNegotiationPropertyArgs {
  property: Property;
  country: Locale;
  negotiationId: string;
  token: string;
  dispatch: Dispatch;
}

export const editNegotiationProperty = async ({
  property,
  country,
  negotiationId,
  token,
  dispatch,
}: editNegotiationPropertyArgs) => {
  dispatch(setLoading(true));
  try {
    await negotiationService().editNegotiationProperty(
      unparseProperty(property),
      country,
      negotiationId,
      token
    );
    dispatch(setProperty(property));
    Swal.fire({
      type: "success",
      text: "Se han actualizado los datos con éxito",
    });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface editOwnerArgs {
  owner: Owner;
  country: Locale;
  userId: string;
  token: string;
  dispatch: Dispatch;
}

export const editOwner = async ({ owner, country, userId, token, dispatch }: editOwnerArgs) => {
  dispatch(setLoading(true));
  try {
    await negotiationService().editOwner(unparseOwner(owner), country, userId, token);
    dispatch(setOwner(owner));
    Swal.fire({
      type: "success",
      text: "Se han actualizado los datos con éxito",
    });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface editBidFeesArgs {
  bidFees: BidFees;
  country: Locale;
  bidId: string;
  token: string;
  negotiation: Negotiation;
  dispatch: Dispatch;
}

export const editBidFees = async ({
  bidFees,
  country,
  bidId,
  token,
  negotiation,
  dispatch,
}: editBidFeesArgs) => {
  dispatch(setLoading(true));
  try {
    await negotiationService().editBidFees(unparseBidFees(bidFees), country, bidId, token);
    Swal.fire({
      type: "success",
      text: "Se han actualizado los datos con éxito",
    });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
    dispatch(setNegotiation(negotiation));
  }
};

export interface uploadFileArgs {
  file: File;
  country: Locale;
  loiId: string;
  token: string;
  dispatch: Dispatch;
}

export const uploadFile = async ({ file, country, loiId, token, dispatch }: uploadFileArgs) => {
  try {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", file);
    await negotiationService().uploadLoiFile(token, country, loiId, formData);
    Swal.fire({
      type: "success",
      text: "Se ha subido el archivo con exito",
    });
    getLetterOfIntent({ country, loiId, token, dispatch });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export interface changeFileStatusArgs {
  country: Locale;
  documentId: string;
  loiId: string;
  status: FileStatus;
  token: string;
  dispatch: Dispatch;
}

export const changeFileStatus = async ({
  country,
  documentId,
  loiId,
  status,
  token,
  dispatch,
}: changeFileStatusArgs) => {
  try {
    dispatch(setLoading(true));
    await negotiationService().changeFileStatus(token, country, documentId, status);
    Swal.fire({
      type: "success",
      text: "Se ha actualizado el estado del archivo  con exito",
    });
    getLetterOfIntent({ country, loiId, token, dispatch });
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};
