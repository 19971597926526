import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";

import { fetchUnit } from "context/multifamilyContext/unit/unitActions";
import {
  getTypology,
  getTypologyPhotos,
} from "context/multifamilyContext/typology/typologyActions";
import { getMultifamily } from "context/multifamilyContext/multifamily/multifamilyActions";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { PropertyData } from "models/Property";
import CustomTabs from "Components/Admin/UIComponents/CustomTabs";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Visits from "Components/Admin/administrator/properties/show/tabs/visits";
import InspectionReport from "Components/Admin/administrator/properties/show/tabs/inspectionReports";
import { useGetToken } from "hooks";
import BackButton from "../Components/BackButton";
import UnitHeader from "./components/UnitHeader";
import CharacteristicsSection from "./components/CharacteristicsSection";
import ManageVisits from "./components/ManageVisits";
import BasicServices from "./components/BasicServices";
import InterestedSection from "./components/InterestedSection";
import ApplicantSection from "./components/ApplicantSection";
import SolicitationSection from "./components/SolicitationSection";
import ScheduleSection from "./components/ScheduleSection";
import useStyles from "./MultifamilyUnitStyles";
import ContractInfo from "Components/Admin/UIComponents/ContractInfo/ContractInfo";

function MultifamilyUnit() {
  const [loading, setLoading] = useState<boolean>(true);

  const {
    state: { property, unit },
    dispatch,
  } = useMultifamilyUnit();
  const {
    state: { typology, UTImages },
    dispatch: typologyDispatch,
  } = useTypology();
  const {
    state: { multifamily },
    dispatch: multifamilyDispatch,
  } = useMultifamily();

  const history = useHistory();
  const classes = useStyles();
  const { id, UTid, MFid } = useParams<{
    id: string;
    UTid: string;
    MFid: string;
  }>();

  const { getToken } = useGetToken();

  const handleBack = () =>
    history.push(`/admin/multifamily/${parseInt(MFid)}/typology/${parseInt(UTid)}`);

  const schema = [
    {
      index: 0,
      header: "Características",
      component: <CharacteristicsSection unit={unit} typology={typology} UTImages={UTImages} />,
    },
    {
      index: 1,
      header: "Coordinación de visitas",
      component: <ManageVisits />,
    },
    {
      index: 2,
      header: "Servicios básicos",
      component: <BasicServices />,
    },
    {
      index: 3,
      header: "Agenda",
      component: <ScheduleSection typology={typology} property={property as PropertyData} />,
    },
    {
      index: 4,
      header: "Interesados",
      component: <InterestedSection />,
    },
    {
      index: 5,
      header: "Postulantes",
      component: <ApplicantSection />,
    },
    {
      index: 6,
      header: "Solicitudes",
      component: <SolicitationSection />,
    },
    property?.uid && {
      index: 7,
      header: "Contrato",
      component: <ContractInfo headless={true} MFUnitId={property?.uid} />,
    },
    {
      index: property?.uid ? 8 : 7,
      header: "Visitas",
      component: (
        <Box className={classes.visitsContainer}>
          <Visits property={property} />
        </Box>
      ),
    },
    {
      index: property?.uid ? 9 : 8,
      header: "Actas",
      component: <InspectionReport property={property} />,
    },
  ];

  const getUnitData = async (authToken: string) => {
    setLoading(true);
    await fetchUnit(parseInt(id), authToken, dispatch);
    await getTypology(typologyDispatch, parseInt(UTid), authToken);
    await getTypologyPhotos(typologyDispatch, parseInt(UTid), authToken);
    await getMultifamily(multifamilyDispatch, parseInt(MFid), authToken);
    setLoading(false);
  };

  useEffect(() => {
    getToken().then((authToken) => getUnitData(authToken));
  }, []);

  return (
    <Loading loading={loading}>
      <>
        <BackButton backButtonProps={{ label: "Volver", onClick: handleBack }} />
        <Box className={classes.container}>
          <UnitHeader
            multifamilyName={multifamily?.name}
            typology={typology}
            executive={unit?.property?.houm_owner}
          />
          <CustomTabs tabsContent={schema} aria="plans" scrollClasses={classes.customTabsStyle} />
        </Box>
      </>
    </Loading>
  );
}

export default MultifamilyUnit;
