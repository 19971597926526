import { makeStyles, Theme } from "@material-ui/core";

const usePropertyPhotosStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  mainButton: {
    height: 35,
    width: "auto",
    marginTop: theme.spacing(1),
    marginLeft: 0,
  },
}));

export default usePropertyPhotosStyles;
