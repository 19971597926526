import { format } from "date-fns";

import { BasicService } from "models/AdminModels/BasicServices";
import { LandlordSummaryContract } from "models/AdminModels/Contracts";
import { ILandlordTickets, ILandlordTicketsCount } from "models/AdminModels/Landlord";
import { ILandlordBreakdown } from "models/AdminModels/Payments";
import { ILandlordSummaryProperty, RentStatus } from "models/AdminModels/Properties";
import { IBasicService } from "models/BasicServices";
import { ILandlordTicketsCountResponse, ILandlordTicketsResponse } from "models/User";
import { Breakdown, ILandlordBreakdownResponse } from "services/newPaymentService/types";
import {
  ILandlordSummaryContractResponse,
  ILandlordSummaryPropertyResponse,
} from "services/propertyService/types";
import { numberWithCommas } from "utils";

import { ILastPaymentData } from "./types";

export const parseLandlordTickets = (data: ILandlordTicketsResponse[]): ILandlordTickets[] =>
  data.map((ticket) => ({
    id: ticket.id,
    status: ticket.status,
    url: ticket.url,
    createdAt: ticket.created_at ? new Date(ticket.created_at) : null,
    updatedAt: ticket.updated_at ? new Date(ticket.updated_at) : null,
    assignedTo: ticket.assigned_to,
    assignedTeam: ticket.assigned_team,
    priority: ticket.priority,
    interactions: ticket.interactions,
    propertyStatus: ticket.property_status,
    chargesStatus: ticket.charges_status,
  }));

export const parseLandlordTicketsCount = (
  data: ILandlordTicketsCountResponse
): ILandlordTicketsCount => ({
  open: data.open_tickets,
  done: data.done_tickets,
  snoozed: data.snoozed_tickets,
  count: data.count,
});

export const parseBasicService = (bs: IBasicService): BasicService => ({
  id: bs.id,
  service: bs.service,
  overdue: bs.overdue,
  currentOverdue: bs.current_overdue,
});

export const parseContract = (
  contract: ILandlordSummaryContractResponse
): LandlordSummaryContract => ({
  id: contract?.id,
  quotation: contract?.quotation,
  initDate: contract ? new Date(contract?.init_date) : null,
  readjust: contract?.readjust,
  mainTenant: contract?.main_tenant,
});

const parseReadjustDate = (date?: string) => {
  if (!date) return null;
  const year = parseInt(date.split("/")[1]);
  const month = parseInt(date.split("/")[0]);
  return new Date(year, month - 1);
};

export const parseLandlordSummaryProperties = (
  data: ILandlordSummaryPropertyResponse[]
): ILandlordSummaryProperty[] =>
  data.map((property) => ({
    id: property.id,
    uid: property.uid,
    country: property.country || "cl", // delete when BE updates the EP
    fullAddress: property.full_address,
    basicServices: property.basic_services.map((bs) => parseBasicService(bs)),
    contract: parseContract(property.detailed_subscription_contract.contract),
    subscription: property.detailed_subscription_contract.subscription,
    rentStatus: property.detailed_subscription_contract.rent_status as RentStatus,
    readjustDate: parseReadjustDate(property.detailed_subscription_contract.next_readjust_date),
    plan: property.detailed_subscription_contract.plan,
  }));

const parseBreakdown = (data: Breakdown[]) =>
  data.map((bd) => ({
    detailId: bd.detail_id,
    label: bd.label,
    description: bd.description,
    value: bd.value,
  }));

export const parseLandlordBreakdown = (data: ILandlordBreakdownResponse): ILandlordBreakdown[] =>
  data?.properties?.map((payment) => ({
    propertyId: payment.property_id,
    lease: payment.lease,
    additionalBreakdown: parseBreakdown(payment.additional_breakdown),
    adjustments: payment.adjustments,
    agreedDate: new Date(payment.agreed_date),
    depositDate: new Date(payment.deposit_date),
    depositStatus: payment.deposit_status,
    currency: payment.currency,
    discountBreakdown: parseBreakdown(payment.discount_breakdown),
    lesseePayment: payment.lessee_payment,
    lesseePaymentDate: payment.lessee_payment ? new Date(payment.lessee_payment_date) : null,
    lesseePaymentStatus: payment.lessee_payment_status,
    management: payment.management,
    paidByLessee: payment.paid_by_lessee,
    payment: payment.payment,
  }));

const parseCurrency = (amount) => (amount ? `$${numberWithCommas(amount)}` : "");

export const parseDate = (date: Date | null) => (date ? format(date, "dd/MM/yyyy") : "");

export const parseLastPayment = (data: ILandlordBreakdown): ILastPaymentData => ({
  total: parseCurrency(data?.payment),
  date: data?.depositStatus === "PAID" ? parseDate(data?.depositDate) : parseDate(data?.agreedDate),
  rent: parseCurrency(data?.lease),
  plan: parseCurrency(data?.management),
  charges: data?.additionalBreakdown?.map((bd) => ({
    id: bd.detailId,
    name: bd.label,
    value: parseCurrency(bd.value),
  })),
  discounts: data?.discountBreakdown?.map((bd) => ({
    id: bd.detailId,
    name: bd.label,
    value: parseCurrency(bd.value),
  })),
  paidByLessee: data?.paidByLessee,
});
