import { makeStyles } from "@material-ui/core";

const useFollowingDateFilterStyles = makeStyles((theme) => ({
  followingDateFilterContainer: {
    display: "flex",
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),
    flex: 1,
    justifyContent: "flex-end",
  },
}));
export default useFollowingDateFilterStyles;
