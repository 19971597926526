import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import promotionService from "domains/promotion/service";
import { setAlertInfo } from "context/alertContext/alertActions";
import { PromotionForm } from "domains/promotion/service/types";
import { useAlert } from "context/alertContext/alertContext";
import { useGetToken } from "hooks";

const useCreatePromotion = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess, error } = useMutation(
    async (data: PromotionForm) => {
      const authToken = await getToken();
      const response = await promotionService.createPromotion(data, authToken);
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["promotions_list"]);
        dispatch(
          setAlertInfo({
            open: true,
            head: "La promoción se ha creado correctamente",
            type: "success",
          })
        );
        setTimeout(() => {
          history.push("/admin/promotions");
        }, 2000);
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        const reasonString = JSON.stringify(reason?.data);
        dispatch(
          setAlertInfo({
            open: true,
            head: "Error al crear promoción",
            type: "error",
            body: `La promoción no se pudo crear, error: ${reasonString ?? e}`,
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useCreatePromotion;
