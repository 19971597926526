import { makeStyles, Theme } from "@material-ui/core";

const PersonCardStyles = makeStyles<Theme>((theme) => ({
  containerCard: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(3, 3, 2),
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  richValue: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    alignSelf: "flex-end",
  },
  textareaField: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
  },
  percentageField: {
    alignSelf: "flex-start",
    width: "75px",
  },
  inputField: {
    fontSize: theme.typography.pxToRem(16),
  },
  inputExtraInfo: {
    margin: theme.spacing(2, 1),
  },
  buttonContainer: {
    display: "flex",
    alignSelf: "flex-end",
    gap: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
}));
export default PersonCardStyles;
