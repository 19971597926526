import { IoHomeOutline as BuyerIcon } from "@houm-com/ui-icons";
import LeadBuyerIndex from "Components/Admin/administrator/demand/LeadBuyerIndex";
import ProfiledLeadBuyerIndex from "Components/Admin/administrator/demand/ProfiledLeadBuyerIndex";
import ScheduledVisitsBuyersIndex from "Components/Admin/administrator/demand/ScheduledVisitsBuyersIndex";
import LettersOfIntentListIndex from "Components/Admin/administrator/letterOfIntent/lettersOfIntentList/index";
import letterOfIntentIndex from "Components/Admin/administrator/letterOfIntent/letterOfIntent/index";
import LetterOfIntentPersonDetailIndex from "Components/Admin/administrator/letterOfIntent/letterOfIntentPersonDetail/index";
import LetterOfIntentNegotiationDetailIndex from "Components/Admin/administrator/letterOfIntent/letterOfIntentDetail/index";
import LetterOfIntentPropertyDetailIndex from "Components/Admin/administrator/letterOfIntent/letterOfIntentPropertyDetail/index";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Buyers",
  icon: BuyerIcon,
  children: [
    {
      to: "/buyers/leads",
      path: "/buyers/leads",
      label: "Leads sin perfilar",
      component: LeadBuyerIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/buyers/profiled-leads",
      path: "/buyers/profiled-leads",
      label: "Leads perfilados",
      component: ProfiledLeadBuyerIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/buyers/scheduled-visits",
      path: "/buyers/scheduled-visits",
      label: "Visitas Agendadas",
      component: ScheduledVisitsBuyersIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      to: "/buyers/letterOfIntent",
      path: "/buyers/letterOfIntent",
      label: "LOIs",
      component: LettersOfIntentListIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "control_tower_admin",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "super_admin",
      ],
    },
    {
      path: "/buyers/letterOfIntent/:id",
      component: letterOfIntentIndex,
    },
    {
      path: "/buyers/letterOfIntent/:id/person/:person_id",
      component: LetterOfIntentPersonDetailIndex,
    },
    {
      path: "/buyers/letterOfIntent/:id/detail",
      component: LetterOfIntentNegotiationDetailIndex,
    },
    {
      path: "/buyers/letterOfIntent/:id/property",
      component: LetterOfIntentPropertyDetailIndex,
    },
  ],
});
