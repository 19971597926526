import { useFormContext } from "react-hook-form";
import Collapse from "@material-ui/core/Collapse";

import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { BooleanString as BS } from "context/closureFormContext/constants";

import { FilteredTextField } from "../FilteredComponents";

function CommonExpensesValue() {
  const { state } = useClosureForm();
  const { watch } = useFormContext();

  const stepState = state.propertyData;
  const stepFields = fields.property;

  return (
    <Collapse in={watch("hasCommonExpenses") === BS.YES}>
      <FilteredTextField
        id="commonExpenses"
        fieldInfo={stepFields.commonExpenses()}
        defaultValue={stepState.commonExpenses}
      />
    </Collapse>
  );
}

export default CommonExpensesValue;
