import { SvgIconTypeMap } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";

import { AppraiserProfile, AppraiserRole, ContractType } from "models/Appraiser";
import { CountryUpperCase } from "models/Countries";
import { StringOption } from "models/Option";
import { PhoneInput } from "models/PhoneInput";

export type Dispatch = (action: Action) => void;

export const SET_TODAY_DATA = "SET_TODAY_DATA";
export const SET_TOMORROW_DATA = "SET_TOMORROW_DATA";
export const SET_SUMMARY_DATA = "SET_SUMMARY_DATA";
export const SET_LOADING_SUMMARY = "SET_LOADING_SUMMARY";
export const SET_LOADING_CHARTS = "SET_LOADING_CHARTS";
export const SET_LOADING_TABLE = "SET_LOADING_TABLE";
export const SET_APPRAISERS = "SET_APPRAISERS";
export const SET_FILTERED_APPRAISERS = "SET_FILTERED_APPRAISERS";
export const SET_APPRAISERS_COUNT = "SET_APPRAISERS_COUNT";
export const SET_APPRAISER_EVENTS = "SET_APPRAISER_EVENTS";
export const SET_LOADING_EVENTS = "SET_LOADING_EVENTS";
export const SET_APPRAISER_PROFILE = "SET_APPRAISER_PROFILE";
export const SET_SHOW_MODAL = "SET_SHOW_MODAL";
export const SET_FILTERS = "SET_FILTERS";
export const SET_LOADING_CREATE = "SET_LOADING_CREATE";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_LOADING_DATA = "SET_LOADING_DATA";
export const SET_LOADING_UPDATE = "SET_LOADING_UPDATE";
export const SET_LOADING_PHOTO = "SET_LOADING_PHOTO";
export const SET_CONFIG_MODAL = "SET_CONFIG_MODAL";
export const SET_MACROZONES = "SET_MACROZONES";
export const SET_TRIGGER = "SET_TRIGGER";

export interface ConfigModal {
  title: string;
  subtitle: string;
  isOpen: boolean;
}

export interface State {
  todayData: BarChartInputData;
  tomorrowData: BarChartInputData;
  summary: SummaryData;
  loadingCharts: boolean;
  loadingSummary: boolean;
  loadingTable: boolean;
  appraisers: Appraiser[];
  filteredAppraisers: Appraiser[];
  appraisersCount: number;
  appraiserEvents: CalendarEvent[];
  loadingEvents: boolean;
  appraiserProfile: AppraiserProfile;
  eventTypes: string[];
  isOpenEditModal: boolean;
  filters: Filters;
  loadingCreate: boolean;
  availability: NewAvailability;
  loadingAvailability: boolean;
  loadingUpdate: boolean;
  loadingPhoto: boolean;
  configModal: ConfigModal;
  macrozones: StringOption[];
  trigger: boolean;
}

interface setTodayData {
  type: typeof SET_TODAY_DATA;
  todayData: BarChartInputData;
}

interface setFilterModal {
  type: typeof SET_SHOW_MODAL;
  isOpenEditModal: boolean;
}

interface setFilters {
  type: typeof SET_FILTERS;
  filters: Filters;
}

interface setTomorrowData {
  type: typeof SET_TOMORROW_DATA;
  tomorrowData: BarChartInputData;
}

interface setSummaryData {
  type: typeof SET_SUMMARY_DATA;
  summaryData: SummaryData;
}

interface setLoadingCharts {
  type: typeof SET_LOADING_CHARTS;
  loadingCharts: boolean;
}

interface setLoadingSummary {
  type: typeof SET_LOADING_SUMMARY;
  loadingSummary: boolean;
}

interface setLoadingTable {
  type: typeof SET_LOADING_TABLE;
  loadingTable: boolean;
}

interface setAppraisers {
  type: typeof SET_APPRAISERS;
  appraisers: Appraiser[];
}

interface setAppraisersCount {
  type: typeof SET_APPRAISERS_COUNT;
  appraisersCount: number;
}

interface setAppraisersEvents {
  type: typeof SET_APPRAISER_EVENTS;
  appraiserEvents: CalendarEvent[];
}

interface setLoadingEvents {
  type: typeof SET_LOADING_EVENTS;
  loadingEvents: boolean;
}

interface setAppraiserProfile {
  type: typeof SET_APPRAISER_PROFILE;
  profile: AppraiserProfile;
}

interface setLoadingCreate {
  type: typeof SET_LOADING_CREATE;
  loadingCreate: boolean;
}

interface setPropertySchedule {
  type: typeof SET_SCHEDULE;
  availability: NewAvailability;
}

interface setLoadingData {
  type: typeof SET_LOADING_DATA;
  loadingAvailability: boolean;
}

interface setLoadingUpdate {
  type: typeof SET_LOADING_UPDATE;
  loadingUpdate: boolean;
}

interface setFilteredAppraisers {
  type: typeof SET_FILTERED_APPRAISERS;
  filteredAppraisers: Appraiser[];
}

export interface setMacrozones {
  type: typeof SET_MACROZONES;
  macrozones: StringOption[];
}

interface setLoadingPhoto {
  type: typeof SET_LOADING_PHOTO;
  loadingPhoto: boolean;
}

interface SetConfigModal {
  type: typeof SET_CONFIG_MODAL;
  configModal: ConfigModal;
}

interface SetTrigger {
  type: typeof SET_TRIGGER;
}

export interface Appraiser {
  active: true;
  begin_hour: string;
  contract_type: string;
  country: CountryUpperCase;
  created_at: string;
  doc_number: string;
  doc_type: string;
  email: string;
  end_hour: string;
  filled_data: boolean;
  id: string;
  is_working: boolean;
  leads_enabled: boolean;
  name: string;
  password: string;
  phone: string;
  photo: string;
  role: string;
  roles: string[];
  updated_at: string;
  username: string;
}

export interface Filters {
  name: string;
  roles: string;
  country: string;
}

export interface TimeBlock {
  beginHour: number;
  endHour: number;
  day: number;
}

export interface iTimeBlock {
  begin_hour?: string;
  end_hour?: string;
  day?: number;
}

export interface NewAvTimeBlock {
  begin_date: string;
  end_date: string;
}

export interface NewAvailability {
  availability: NewAvTimeBlock[];
  count?: number;
}

export interface CalendarEvent {
  completed: boolean;
  id: string;
  begin_date: string;
  end_date: string;
  title: string;
  type: string;
  prop_id: string;
  email: string;
  applicant?: any;
  address?: string;
  comment?: string;
  prop_country?: string;
  status?: string;
}

export interface BarChartInputData {
  cancelled: number;
  pending: number;
  done: number;
}

export interface NewAppraiserForm {
  name: string;
  last_name: string;
  country: CountryUpperCase;
  username: string;
  phone: string;
  email: string;
  doc_type: string;
  contract_type?: ContractType;
  doc_number: string;
  roles: AppraiserRole;
  photo?: File;
  macrozones: StringOption[];
}

export interface SummaryData {
  activeAppraisers: number;
  activePhotographers: number;
  availableHours: number;
}

export interface BarChartProps {
  inputData: BarChartInputData;
  labels: string[];
}

export interface CardContainerProps {
  loading?: boolean;
  children: any;
}

export interface ChartCardProps {
  title?: string;
  caption?: string;
  children?: any;
}

export interface ProgressCardProps {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  progress: number;
  children?: any;
}

export interface editPhoto {
  id: string;
  photo: File;
}

export type Action =
  | setTodayData
  | setTomorrowData
  | setSummaryData
  | setLoadingCharts
  | setLoadingSummary
  | setLoadingTable
  | setAppraisers
  | setAppraisersCount
  | setAppraisersEvents
  | setLoadingEvents
  | setAppraiserProfile
  | setFilterModal
  | setFilters
  | setLoadingCreate
  | setPropertySchedule
  | setLoadingData
  | setLoadingUpdate
  | setFilteredAppraisers
  | setLoadingPhoto
  | setMacrozones
  | SetConfigModal
  | SetTrigger;

export interface EditProfileForm {
  name: string;
  email: string;
  phone: PhoneInput | string;
  firstRol: AppraiserRole[];
  secondRol: AppraiserRole[];
  contract_type: ContractType;
  active: boolean;
  macrozones: StringOption[];
}
