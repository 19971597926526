import React from "react";
import { Grid } from "@material-ui/core";

import {
  ControlledCheckbox,
  ControlledList,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import useFormStyles from "./FormStyles";
import { cancelReasons } from "context/eventContext/eventConst";
import FormBlock from "./FormBlock";

function DeleteFields() {
  const classes = useFormStyles();

  const reasons = cancelReasons.map((item) => ({ value: item, label: item }));

  return (
    <FormBlock>
      <Grid container>
        <ControlledList
          id="cancel_reason"
          label="Razón de la cancelación"
          defaultValue={cancelReasons[0]}
          options={reasons}
        />
        <Grid item xs={12} className={classes.underlined}>
          <ControlledTextField id="cancel_comments" label="Comentario de la cancelación" />
        </Grid>
        <ControlledCheckbox label="Enviar Correos" id="send_emails" />
      </Grid>
    </FormBlock>
  );
}

export default React.memo(DeleteFields);
