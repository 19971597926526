import { Grid } from "@material-ui/core";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import {
  ControlledNumber,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

import useGeneralFormStyles from "./GeneralFormStyles";
import { IGeneralInfo } from "../../../utils/types";
import useFormDate from "../hook/useFormDate";

interface Props {
  generalInfo?: IGeneralInfo;
  enabledEditFlag?: boolean;
}

const GeneralForm = ({ generalInfo = null, enabledEditFlag = true }: Props) => {
  const classes = useGeneralFormStyles();
  const { initDateValue } = useFormDate(!enabledEditFlag);

  return (
    <AdminCard withSpace={false}>
      <Grid container spacing={1}>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <ControlledKeyboardDatePicker
              id="initDate"
              label="Fecha de inicio"
              defaultValue={generalInfo?.initDate ? new Date(generalInfo?.initDate) : new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
                minDate: generalInfo?.initDate ?? new Date(),
                disabled: !!generalInfo?.initDate || !enabledEditFlag,
              }}
              labelClassName={classes.textField}
              inputClassName={classes.datePicker}
              pickerClassName={{
                input: classes.pickerInput,
                root: classes.pickerRoot,
              }}
              disableUnderline={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledKeyboardDatePicker
              id="endDate"
              label="Fecha de fin"
              defaultValue={generalInfo?.endDate ? new Date(generalInfo?.endDate) : new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
                minDate: initDateValue,
                disabled: !enabledEditFlag,
              }}
              labelClassName={classes.textField}
              inputClassName={classes.datePicker}
              pickerClassName={{
                input: classes.pickerInput,
                root: classes.pickerRoot,
              }}
              disableUnderline={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Nombre de la promoción"
            id="promotionName"
            defaultValue={generalInfo?.promotionName ?? ""}
            textFieldProps={{
              placeholder: "Ingresar",
              InputProps: {
                classes: {
                  root: classes.input,
                },
              },
              disabled: !enabledEditFlag,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledNumber
            label="Número máximo de promociones"
            id="promotionStock"
            defaultValue={generalInfo?.promotionStock ?? ""}
            textFieldProps={{
              placeholder: "Ingresar",
              InputProps: {
                classes: {
                  root: classes.inputNumber,
                },
              },
              disabled: !enabledEditFlag,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Objetivo"
            id="target"
            defaultValue={generalInfo?.target ?? ""}
            textFieldProps={{
              placeholder: "Ingresa aquí el objetivo de la promoción",
              InputProps: {
                classes: {
                  root: classes.input,
                },
              },
              disabled: !enabledEditFlag,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Comentario de la promoción"
            id="description"
            defaultValue={generalInfo?.description ?? ""}
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.descriptionInput,
                },
              },
              disabled: !enabledEditFlag,
              placeholder: "Ingresa aquí los comentarios que tengas",
              multiline: true,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Link de términos y condiciones"
            id="link"
            defaultValue={generalInfo?.link ?? ""}
            textFieldProps={{
              placeholder: "Ingresa aquí el link de Kustomer",
              InputProps: {
                classes: {
                  root: classes.input,
                },
              },
              disabled: !enabledEditFlag,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="N° de código promocional"
            defaultValue={generalInfo?.promotionCode ?? ""}
            id="promotionCode"
            textFieldProps={{
              placeholder: "Ingresa código",
              InputProps: {
                classes: {
                  root: classes.input,
                },
              },
              disabled: !enabledEditFlag,
            }}
            labelClassName={classes.textField}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Observaciones para el propietario"
            id="landlordObservation"
            defaultValue={generalInfo?.landlordObservation ?? ""}
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.descriptionInput,
                },
              },
              disabled: !enabledEditFlag,
              placeholder: "Ingresa aquí los comentarios que tengas",
              multiline: true,
            }}
            labelClassName={classes.textField}
            warn={(value) => true}
            warningMessage="*Este texto aparecerá en el contrato"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Observaciones para el arrendatario"
            id="tenantObservation"
            defaultValue={generalInfo?.tenantObservation ?? ""}
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.descriptionInput,
                },
              },
              disabled: !enabledEditFlag,
              placeholder: "Ingresa aquí los comentarios que tengas",
              multiline: true,
            }}
            labelClassName={classes.textField}
            warn={(value) => true}
            warningMessage="*Este texto aparecerá en el contrato"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledSelection
            disabled={!enabledEditFlag}
            label="Tipo de descuento"
            id="discountType"
            defaultValue={generalInfo?.discountType ?? "0"}
            options={[
              { value: "percentage", label: "Porcentaje" },
              { value: "fixed", label: "Fijo" },
            ]}
            classes={{
              container: classes.selectContainer,
              label: classes.selectLabel,
              buttonList: classes.selectButtonList,
            }}
            simple
          />
        </Grid>
      </Grid>
    </AdminCard>
  );
};

export default GeneralForm;
