import { useMemo } from "react";

import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import CustomLink from "Components/Admin/UIComponents/CustomLink";
import { useTable } from "context/tableContext/tableContext";
import { useProject } from "context/projectContext/projectContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";

import Toolbar from "./Toolbar";
import useStyles from "./TableStyles";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "left",
};

function Table() {
  const classes = useStyles();
  const { state } = useProject();
  const { loading, projects, numberOfProperties: resultsCount } = state;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "id",
        headerName: "ID",
      },
      {
        ...columnsConfig,
        field: "lastStatusChange",
        headerName: "Última modificación de estado",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "published",
        headerName: "Publicación",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "status",
        headerName: "Estado",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "projectName",
        headerName: "Nombre proyecto",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "commune",
        headerName: "Comuna",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "landlordEmail",
        headerName: "Correo Propietario",
        sortable: false,
      },
      {
        ...columnsConfig,
        field: "link",
        headerName: "Acciones",
        align: "center",
        sortable: false,
        renderCell: ({ value }: GridCellParams) => <CustomLink to={value as string} />,
      },
    ],
    [projects]
  );

  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={resultsCount}
        columns={columns}
        rows={projects}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;
