import { makeStyles, Theme } from "@material-ui/core";

const useSelectPromotionFormStyles = makeStyles<Theme>((theme) => ({
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
  formContent: {
    width: "100%",
  },
}));

export default useSelectPromotionFormStyles;
