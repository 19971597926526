import { useEffect } from "react";

import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import CustomLink from "Components/Admin/UIComponents/CustomLink";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useLettersOfIntentList } from "context/letterOfIntent/lettersOfIntentList/lettersOfIntentListContext";
import useStyles from "./TableStyles";
import { countryCode } from "utils";

function Table() {
  const { state } = useLettersOfIntentList();
  const classes = useStyles();
  const { loading, lettersOfIntent, numberOfLettersOfIntent: resultsCount, country } = state;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  let code = countryCode(country);
  useEffect(() => {
    code = countryCode(country);
  }, [country]);

  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={resultsCount}
        columns={columns(classes, code)}
        rows={lettersOfIntent}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        classes={{
          root: classes.root,
          columnHeader: classes.headerWrapper,
        }}
        localeText={{
          columnMenuShowColumns: "Mostrar Columnas",
          columnMenuHideColumn: "Ocultar Columna",
        }}
      />
    </div>
  );
}

export default Table;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  width: 100,
  filterable: false,
  sortable: false,
};

const columns = (classes, code): GridColumns => [
  {
    ...columnsConfig,
    field: "name",
    headerName: "Cliente",
    valueGetter: (params) => params.row.owner?.name,
  },
  {
    ...columnsConfig,
    field: "phone",
    headerName: "Teléfono",
    valueGetter: (params) => params.row.owner?.phone,
  },
  {
    ...columnsConfig,
    field: "email",
    headerName: "Email",
    cellClassName: classes.emailText,
    valueGetter: (params) => params.row.owner?.email,
  },
  {
    ...columnsConfig,
    field: "propertyExecutiveName",
    headerName: "Ejecutivo",
  },
  {
    ...columnsConfig,
    field: "propertyId",
    headerName: "ID Propiedad",
  },
  {
    ...columnsConfig,
    field: "id",
    headerName: "Acciones",
    renderCell: ({ value }: GridCellParams) => (
      <CustomLink to={`/buyers/letterOfIntent/${value}?country=${code}`} text="Ver detalles" />
    ),
  },
];
