import { ReactNode } from "react";

import usePinStyle from "./PinStyle";

interface Props {
  children: ReactNode;
  selected?: boolean;
  clickleable?: boolean;
  onClick?: () => void;
}

function Pin({ children, selected, clickleable, onClick }: Props) {
  const classes = usePinStyle({ selected, clickleable });
  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.mainPropContainer}>{children}</div>
      <div className={classes.bottomArrow} />
    </div>
  );
}

export default Pin;
