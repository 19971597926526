import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  grayLine?: boolean;
}

const useControlledTextfieldStyledStyles = makeStyles<Theme, Props>((theme) => ({
  underlineTextField: {
    "&&&:before": {
      borderWidth: "0.5px",
      borderColor: ({ grayLine }) => theme.palette.grey[grayLine ? 50 : 900],
    },
    "&&:after": {
      borderColor: ({ grayLine }) => theme.palette.grey[grayLine ? 50 : 900],
      borderWidth: "0.5px",
    },
  },
}));

export default useControlledTextfieldStyledStyles;
