import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  table: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      fontSize: "0.875rem",
      borderRadius: theme.spacing(1),
      color: theme.palette.grey[500],
      backgroundColor: theme.palette.grey[50],
    },
    "& .MuiDataGrid-cell": {
      borderTopWidth: 0,
      borderBottomWidth: 0,
      padding: theme.spacing(2),
    },
    "& .MuiDataGrid-row": {
      margin: theme.spacing(0.2, 0),
      transition: "0.3s",
      "&:hover": {
        fontWeight: 700,
        backgroundColor: theme.palette.grey[50],
      },
    },
  },
}));
