export const classes = {
  card: "relative p-4 flex-column gap-6 rounded-2xl bg-secondary-5 border border-secondary-15",
  activeTag: "h-fit px-2 bg-success-20 rounded-full text-success-120 font-bold",
  inactiveTag: "h-fit px-2 bg-black-20 rounded-full text-black-100 font-bold",
  citiesText: "text-p14 text-black-60",
  section: "mb-6 [&>p]:text-p14 [&>p]:text-secondary-cta",
  spread: "flex justify-between",
  title: "mt-2 text-p16 font-bold",
  details: "mb-2",
  text: "text-p14 text-secondary-cta ",
  action: "text-p14 font-bold border-b-2 cursor-pointer",
  editDates: "text-[14px] text-primary-cta border-primary-cta",
};
