import { Grid } from "@material-ui/core";

import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import { getCountry } from "Components/Admin/administrator/contracts/ContractTemplates/utils";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
  current: string;
}

function TemplateHeader({ country, current }: Props) {
  return (
    <Grid container item xs={12} alignItems="center" justifyContent="space-between">
      <Grid item>
        <Breadcrumbs steps={[{ label: "Cierres" }, { label: "Templates" }, { label: current }]} />
      </Grid>
      <Grid item>
        <CountryChip country={getCountry(country)} selected />
      </Grid>
    </Grid>
  );
}

export default TemplateHeader;
