import { format } from "date-fns";

import { Locale } from "models/Countries";
import { UserModel } from "models/User";
import parseDocumentColombia from "utils/parseRutColombia";

export const parseCreateReservation = (
  user: UserModel,
  country: Locale,
  paymentDate: string,
  propertyId: number,
  applicantId: string
) => ({
  person_data: {
    full_name: `${user.name ?? ""} ${user.last_name ?? ""}`,
    person_id: user.id,
    email: user.email,
    ...(country !== "mx" && { document: user.document }),
    ...(country === "co" && {
      document_type: parseDocumentColombia(user?.document_type as number),
    }),
  },
  property_id: propertyId,
  ...(country === "cl" ? { legacy_id: applicantId } : undefined),
  agreed_date: format(new Date(paymentDate), "yyyy-MM-dd'T'00:00:00"),
});

export const getTomorrowDay = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};
