export default {
  container: "flex flex-col space-y-2 items-end",
  buttonContainer: "w-full flex justify-center mb-2",
  button: "px-8",
  deleteFiltersButton:
    "cursor-pointer flex items-center justify-between border-none p-0 hover:bg-transparent",
  deleteFiltersButtonText: "text-primary-cta hover:text-primary-cta",
  deleteFiltersButtonIcon: "text-primary-cta hover:text-primary-cta w-4 h-4",
  debtInput: "mx-1",
};
