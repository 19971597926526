import { Dispatch, Action, ActionType } from "context/calculatorContext/calculatorTypes";
import { IProperty } from "models/createProperty";
import {
  CalculatorResponse,
  BasicPropertyDetails,
  PropertyAmenities,
  SimilarPricesParams,
  MetricsByLocationResponse,
  MetricsParams,
} from "models/Calculator";
import { countryCode, countryCurrency, parsePropertyToRentor } from "utils";
import _ from "lodash";

import rentorService from "services/rentruvioService";
import { PropertyData } from "models/Property";

export const setFormattedAddress = (formattedAddress): Action => ({
  type: ActionType.SET_FORMATTED_ADDRESS,
  formattedAddress,
});

export const resetPropertyDetails = (reset: boolean): Action => ({
  type: ActionType.RESET_PROPERTY_DETAILS,
  reset,
});

export const resetPropertyDetailsToOriginal = (): Action => ({
  type: ActionType.RESET_PROPERTY_DETAILS_TO_ORIGINAL,
});

export const setPropertyDetails = (basicPropertyDetails: BasicPropertyDetails): Action => ({
  type: ActionType.SET_PROPERTY_DETAILS,
  basicPropertyDetails,
});

export const setNewPropertyDetails = (basicPropertyDetails: BasicPropertyDetails): Action => ({
  type: ActionType.SET_NEW_PROPERTY_DETAILS,
  basicPropertyDetails,
});

export const setPropertyAmenities = (propertyAmenities: PropertyAmenities): Action => ({
  type: ActionType.SET_PROPERTY_AMENITIES,
  propertyAmenities,
});

export const reset = (): Action => ({ type: ActionType.RESET });

export const setCityError = (error: boolean): Action => ({
  type: ActionType.SET_CITY_ERROR,
  error,
});

export const changeDeal = (deal: "rental" | "sale"): Action => ({
  type: ActionType.CHANGE_DEAL,
  deal,
});

export const setLoading = (loading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const setNoResults = (noResults: boolean): Action => ({
  type: ActionType.SET_NO_RESULTS,
  noResults,
});

export const getSuggestedPrices = async (property, dispatch: Dispatch) => {
  try {
    dispatch(setLoading(true));

    const params = parsePropertyToRentor(property);

    const res = await rentorService.valuate(params);

    if (res.status === 204) {
      dispatch(setLoading(false));
      dispatch(setNoResults(true));
    } else {
      const { data }: { data: CalculatorResponse } = res;

      dispatch({
        type: ActionType.SET_CALCULATOR_RESULTS,
        calculatorResponse: data,
      });
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch({
      type: ActionType.ERROR,
      status: (err as any).status,
      message: (err as any)?.response?.data,
    });
  }
};

export const setSuggestedPrices = (property: PropertyData): Action => {
  const { country } = property;

  const { currency } = countryCurrency(country);

  const calculatorResponse: CalculatorResponse = {
    for_sale: {
      suggested_price: property.sales_details.recommended_price,
      max_price: property.sales_details.recommended_price_max,
      min_price: property.sales_details.recommended_price_min,
      currency: country === "Chile" ? "CLF" : currency,
    },
    for_rental: {
      suggested_price: property.vitruvio_suggest,
      max_price: property.vitruvio_max,
      min_price: property.vitruvio_min,
      currency,
    },
  };
  return {
    type: ActionType.SET_CALCULATOR_RESULTS,
    calculatorResponse,
  };
};

export const setSimilarProperties = async (
  property: IProperty,
  calculatorResponse: CalculatorResponse,
  deal,
  dispatch: Dispatch
) => {
  try {
    dispatch(setLoading(true));
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties: [],
    });

    const res = await rentorService.getSimilarProperties(property.id, deal, 1, 100);

    if (res.status === 204) throw new Error();

    const { data: similarProperties } = res;
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties,
    });
    dispatch(setLoading(false));
  } catch (err) {
    dispatch({
      type: ActionType.SET_SIMILAR_PROPERTIES,
      similarProperties: [],
    });
    dispatch(setLoading(false));
  }
};

export const setSimilarExternalProperties = async (
  property: PropertyData,
  dispatch: Dispatch,
  token: string
) => {
  try {
    const propertyDetails = parsePropertyToRentor(property);

    if (propertyDetails) {
      const {
        bathrooms,
        bedrooms,
        built_area,
        property_type,
        total_area,
        stratus,
        latitude,
        longitude,
      } = propertyDetails;

      const params: SimilarPricesParams = {
        property_type,
        total_area,
        built_area,
        bathrooms,
        bedrooms,
        stratus,
        latitude,
        longitude,
        country_code: countryCode(property.country),
      };

      const res = await rentorService.similarExternalProperties(params, token);
      const { data } = res;

      const similarExternalProperties = {
        for_rental: data.for_rental.map((p) => ({
          ...p,
          id: _.uniqueId() as string,
        })),
        for_sale: data.for_sale.map((p) => ({
          ...p,
          id: _.uniqueId() as string,
        })),
      };

      dispatch({
        type: ActionType.SET_SIMILAR_EXTERNAL_PROPERTIES,
        similarExternalProperties,
      });
    }
  } catch (err) {
    dispatch({
      type: ActionType.SET_SIMILAR_EXTERNAL_PROPERTIES,
      similarExternalProperties: { for_rental: [], for_sale: [] },
    });
  }
};

export const getSquareMeterPrice = async (
  filters: MetricsParams,
  dispatch: Dispatch,
  token: string
) => {
  try {
    const res = await rentorService.getSquareMeterPrice(filters, token);

    if (res.status === 200) {
      const { data }: { data: MetricsByLocationResponse } = res;
      dispatch({
        type: ActionType.SET_SQUARE_METER_PRICES,
        squareMeterPrices: data,
      });
    }
  } catch (err) {
    dispatch({
      type: ActionType.ERROR,
      status: (err as any).status,
      message: (err as any)?.response?.data,
    });
  }
};
