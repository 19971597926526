export default {
  association_name: null,
  floors: null,
  guest_parking_spaces: null,
  has_all_day_vigilance: null,
  has_bbq_area: null,
  has_cinema: null,
  has_concierge: null,
  has_elevator: null,
  has_games_room: null,
  has_gourmet_space: null,
  has_gym: null,
  has_laundry: null,
  has_party_room: null,
  has_patio: null,
  has_pet_area: null,
  has_playground: null,
  has_playroom: null,
  has_roof_garden: null,
  has_sauna: null,
  has_steam_room: null,
  has_swimming_pool: null,
  property: null,
};
