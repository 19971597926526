import { Locale } from "models/Countries";
import BasicServices from "./BasicServices";
import CommonExpenses from "./CommonExpenses";
import useServices from "./hooks/useServices";
import { filterServiceType } from "./utils/utils";

interface Props {
  propertyId: number;
  country: Locale;
  variant?: "basicServices" | "commonExpenses";
  backgroundTitle?: boolean;
}

const PropertyServices = ({ propertyId, country, variant, backgroundTitle = false }: Props) => {
  const servicesHook = useServices(propertyId, country);

  switch (variant) {
    case "commonExpenses":
      return (
        <CommonExpenses
          country={country}
          propertyId={propertyId}
          {...servicesHook}
          propertyServices={filterServiceType("commonExpense", servicesHook.propertyServices)}
          backgroundTitle={backgroundTitle}
        />
      );
    case "basicServices":
    default:
      return (
        <BasicServices
          propertyId={propertyId}
          country={country}
          {...servicesHook}
          propertyServices={filterServiceType("basicService", servicesHook.propertyServices)}
          backgroundTitle={backgroundTitle}
        />
      );
  }
};

export default PropertyServices;
