import { makeStyles } from "@material-ui/core";

const useSectionTitleStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.25rem",
    fontWeight: 700,
  },
}));

export default useSectionTitleStyles;
