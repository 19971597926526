import * as FileSaver from "file-saver";
import LightButton from "@houm-com/ui/LightButton";
import { Box, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useCurrentFilesStyles from "./CurrentFilesStyles";

interface Props {
  contractId: number;
  links: {
    contract: string;
    mandate: string;
  };
  isLoading: boolean;
}

const CurrentFiles = ({ contractId, links, isLoading }: Props) => {
  const classes = useCurrentFilesStyles();

  const saveFile = (file: string, type: string) =>
    FileSaver.saveAs(file, `${type}-${contractId}.pdf`);

  const noLinksEmpty = !!(links?.contract && links?.mandate);

  return (
    <Box className={classes.rootContainer}>
      <Typography component="h6" className={classes.textLabelTitle}>
        Archivos actuales
      </Typography>
      <Loading loading={isLoading}>
        {links && noLinksEmpty ? (
          <Box className={classes.linksContainer}>
            {links?.contract && (
              <LightButton
                variant="secondary"
                size="md"
                onClick={() => saveFile(links.contract, "Contrato")}>
                Contrato
              </LightButton>
            )}
            {links?.mandate && (
              <LightButton
                variant="secondary"
                size="md"
                onClick={() => saveFile(links.mandate, "Mandato")}>
                Mandato
              </LightButton>
            )}
          </Box>
        ) : (
          <Typography component="h6" className={classes.noFiles}>
            No existen los archivos de mandato y contrato.
          </Typography>
        )}
      </Loading>
    </Box>
  );
};

export default CurrentFiles;
