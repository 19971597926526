import { Grid, Typography } from "@material-ui/core";
import Seller from "../Seller";
import useSubscriptionInfoStyles from "./SubscriptionInfoStyles";

interface Props {
  subscriptionId: number;
  contractId: number;
  propertyId: number;
  sellerId: number;
  executive: string;
}

const SubscriptionInfo = ({
  subscriptionId,
  contractId,
  propertyId,
  sellerId,
  executive,
}: Props) => {
  const classes = useSubscriptionInfoStyles();
  return (
    <Grid item xs={12}>
      <Typography className={classes.infoText}>Nuevo ID Suscripción: {subscriptionId}</Typography>
      <Typography className={classes.infoText}>Contrato ID: {contractId}</Typography>
      <Typography className={classes.infoText}>Propiedad ID: {propertyId}</Typography>
      <Seller sellerId={sellerId} />
      <Typography className={classes.infoText}>Ejecutivo encargado: {executive}</Typography>
    </Grid>
  );
};

export default SubscriptionInfo;
