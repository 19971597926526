import { makeStyles, Theme } from "@material-ui/core";

const useInfoPersonStyles = makeStyles<Theme>((theme) => ({
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1.4),
  },
  divider: {
    width: "95%",
  },
  text: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
}));

export default useInfoPersonStyles;
