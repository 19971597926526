import { IProperty } from "models";
import { Lead, Activity, Schedule, Profiling } from "models/DemandLead";

export enum ActionType {
  SET_LEAD,
  SET_LOADING,
  SET_ACTIVITIES,
  SET_SCHEDULES,
  SET_PROFILING,
  SET_OPEN_ACTIVITY_FORM,
  SET_OPEN_REVIEW_INFORMATION_MODAL,
  SET_OPEN_SUCCESS_MODAL,
  SET_OPEN_ERROR_MODAL,
  SET_OPEN_SAVE_INFORMATION_MODAL,
  SET_CURRENT_TAB,
  SET_COMPLETED_ACTIVITY_CONFIG,
  SET_VIEWED_PROPERTIES,
  SET_RENDER_PROFILE_FORM,
}
export interface SetLead {
  type: typeof ActionType.SET_LEAD;
  lead: Lead;
}

export interface SetProfiling {
  type: typeof ActionType.SET_PROFILING;
  profiling: Profiling;
}

export interface SetRenderProfileForm {
  type: typeof ActionType.SET_RENDER_PROFILE_FORM;
  renderProfileForm: boolean;
}

export interface SetAcitivities {
  type: typeof ActionType.SET_ACTIVITIES;
  activities: Activity[];
}

export interface SetSchedules {
  type: typeof ActionType.SET_SCHEDULES;
  schedules: Schedule[];
}

export interface SetCurrentTab {
  type: typeof ActionType.SET_CURRENT_TAB;
  tab: number;
}

export interface SetIsOpenReviewInformationModal {
  type: typeof ActionType.SET_OPEN_REVIEW_INFORMATION_MODAL;
  isOpenReviewInformationModal: boolean;
}

export interface SetIsOpenSuccessModal {
  type: typeof ActionType.SET_OPEN_SUCCESS_MODAL;
  isOpenSuccessModal: boolean;
}

export interface SetIsOpenErrorModal {
  type: typeof ActionType.SET_OPEN_ERROR_MODAL;
  isOpenErrorModal: boolean;
}

export interface SetIsOpenSaveInformationModal {
  type: typeof ActionType.SET_OPEN_SAVE_INFORMATION_MODAL;
  isOpenSaveInformationModal: boolean;
}

export interface SetCompletedActivity {
  type: typeof ActionType.SET_COMPLETED_ACTIVITY_CONFIG;
  completedActivity: {
    isOpenModal: boolean;
    currentActivity: Activity;
  };
}
export interface SetViewedProperties {
  type: typeof ActionType.SET_VIEWED_PROPERTIES;
  viewedProperties: IProperty[];
}

export interface SetIsOpenActivityForm {
  type: typeof ActionType.SET_OPEN_ACTIVITY_FORM;
  isOpenActivityForm: boolean;
}

export interface SetIsLoading {
  type: typeof ActionType.SET_LOADING;
  isLoading: boolean;
}

export type Action =
  | SetCurrentTab
  | SetIsLoading
  | SetIsOpenActivityForm
  | SetCompletedActivity
  | SetIsOpenSaveInformationModal
  | SetIsOpenReviewInformationModal
  | SetIsOpenSuccessModal
  | SetIsOpenErrorModal
  | SetLead
  | SetProfiling
  | SetRenderProfileForm
  | SetViewedProperties
  | SetAcitivities
  | SetSchedules;

export interface State {
  lead?: Lead;
  renderProfileForm: boolean;
  profiling?: Profiling;
  activities?: Activity[];
  schedules?: Schedule[];
  isOpenReviewInformationModal: boolean;
  isOpenErrorModal: boolean;
  isOpenSuccessModal: boolean;
  isOpenSaveInformationModal: boolean;
  isOpenActivityForm: boolean;
  isLoading: boolean;
  completedActivity: {
    isOpenModal: boolean;
    currentActivity?: Activity;
  };
  viewedProperties: IProperty[];
}

export type Dispatch = (action: Action) => void;
