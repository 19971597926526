import { makeStyles, Theme } from "@material-ui/core";

const useRowsCountStyles = makeStyles((theme: Theme) => ({
  rowsCountWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiOutlinedInput-root": {
      borderRadius: 100,
    },
  },
  text: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
  select: {
    padding: theme.spacing(0, 1),
    height: 32,
    display: "flex",
    alignItems: "center",
    borderRadius: 100,
    border: `1px solid #ECEFF1`,
  },
}));

export default useRowsCountStyles;
