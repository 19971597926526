import TokyModal from "Components/Shared/Toky/TokyModal";
import { TokyProvider } from "context/tokyContext/tokyContext";
import { TOKY_APP_ID } from "env";

function TokyHandler({ children }: { children: React.ReactElement | null }) {
  if (!TOKY_APP_ID) {
    return children;
  }
  return (
    <TokyProvider>
      {children}
      <TokyModal />
    </TokyProvider>
  );
}

export default TokyHandler;
