import { format } from "date-fns";

import { MovementBreakdownRecord } from "models/Payments";
import { parsePrice } from "utils";

export const getTotalAmount = (movement: MovementBreakdownRecord) =>
  parsePrice(
    movement.status === "PAID" ? movement.payment : movement.total_amount,
    movement.currency
  );

export const getLandlordDeposit = (movement: MovementBreakdownRecord) => {
  if (!movement?.date || !movement?.agreed_date) return "N/A";
  return format(
    new Date(movement.status === "PAID" ? movement.date : movement.agreed_date),
    "dd-MM-yyyy"
  );
};
