import { es } from "date-fns/locale";
import { format } from "date-fns";
import clx from "classnames";

import { Typography } from "@material-ui/core";

import useGeneralDetailsStyles from "./GeneralDetailsStyles";

interface Props {
  id: number;
  initDate?: string;
  payDay: number;
  firstPaymentDate: string;
  labelClassName?: string;
}

const GeneralDetails = ({ id, initDate, payDay, firstPaymentDate, labelClassName }: Props) => {
  const classes = useGeneralDetailsStyles();
  const parseStringDate = (dateString: string, formatType: string): string => {
    const dt = new Date(dateString);
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    return format(dtDateOnly, formatType, { locale: es });
  };

  return (
    <>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>ID:</b>
        {` ${id}`}
      </Typography>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Fecha de inicio:</b>
        {` ${initDate ? parseStringDate(initDate, "dd/MM/yyyy") : "-"}`}
      </Typography>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Día de pago de arriendo:</b>
        {` ${payDay ? `Día ${payDay}` : "-"}`}
      </Typography>
      <Typography className={clx(classes.text, labelClassName)}>
        <b>Fecha primer pago:</b>
        {` ${firstPaymentDate ? parseStringDate(firstPaymentDate, "PPP ") : "-"}`}
      </Typography>
    </>
  );
};

export default GeneralDetails;
