import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { IWarrantyRetainer } from "models/Warranty";
import { Locale } from "models/Countries";

import useGetDesertionProcessInfo from "../../../../MandateRefund/hooks/useGetDesertionProcessInfo";
import useLiquidateWarranty from "../../../hooks/useLiquidateWarranty";
import { parseFormData } from "./utils/parsers";
import ModalLayout from "../../ModalLayout";
import Spinner from "@houm-com/ui/Spinner";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  countryCode: Locale;
  contractId: number;
  propertyId: number;
  subscriptionId: number;
  retainer: IWarrantyRetainer;
  lessorId: number;
  lesseeId: number;
}

const ConfirmWarrantyLiquidation = ({
  open,
  onHandleClose,
  contractId,
  countryCode,
  propertyId,
  subscriptionId,
  retainer,
  lessorId,
  lesseeId,
}: Props) => {
  const { mutate, isLoading } = useLiquidateWarranty(contractId, countryCode, onHandleClose);

  const liquidateWarranty = () => {
    if (isLoading) return;
    const parsedData = parseFormData({
      lessorId,
      lesseeId,
      propertyId,
      subscriptionId,
    });
    mutate(parsedData);
  };
  const { isSuccess, isLoading: desertionProcessInfoLoading } =
    useGetDesertionProcessInfo(contractId);

  return (
    <ModalLayout
      open={open}
      handleOnClose={onHandleClose}
      title={`¿Desea confirmar ${
        retainer === "HOUM" ? "la liquidación" : "el pago"
      }  de garantía?`}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            {retainer === "HOUM"
              ? "Despues de haber liquidado la garantía ya no se podrán hacer más cambios"
              : "¿Está seguro que el propietario ya gestionó que hacer con la garantía?"}
          </Typography>
          {desertionProcessInfoLoading && <Spinner size="lg" variant="primary" />}
          {!desertionProcessInfoLoading && isSuccess && (
            <Typography>
              Recuerde que si la liquidación se realiza previo a la fecha de liberación del contrato
              se debe agregar una multa por deserción
            </Typography>
          )}
        </Grid>
        <Grid item container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <DesignSystemButton
              variant="tertiaryB"
              label="Cancelar"
              size="small"
              onClick={onHandleClose}
            />
          </Grid>
          <Grid item>
            <DesignSystemButton
              label="Confirmar"
              size="small"
              variant="primary"
              onClick={liquidateWarranty}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalLayout>
  );
};

export default ConfirmWarrantyLiquidation;
