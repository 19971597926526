import { ReactNode } from "react";
import useTagsStyles from "./TagStyles";
import { IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

interface Props {
  children: ReactNode;
  id?: string;
  onRemove: () => void;
}
function Tag({ children, onRemove, id }: Props) {
  const classes = useTagsStyles();

  return (
    <div className={classes.tag} id={id}>
      {children}
      <IconButton className={classes.button} size="small" onClick={onRemove}>
        <CloseOutlined className={classes.icon} />
      </IconButton>
    </div>
  );
}
export default Tag;
