import { forwardRef, ReactNode } from "react";
import classNames from "classnames";

import { Typography } from "@material-ui/core";

import useTagStyle from "./TagStyles";

export interface TagProps {
  colors: {
    color: string;
    backgroundColor: string;
  };
  classes?: {
    wrapperClassName?: string;
    textClassName?: string;
  };
  text: string;
  bordered?: boolean;
  icon?: ReactNode;
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ colors, classes, text, bordered, icon, ...props }, ref) => {
    const innerClasses = useTagStyle({ ...colors, bordered });

    return (
      <div
        {...props}
        className={classNames(innerClasses.TagRoot, classes?.wrapperClassName)}
        ref={ref}>
        {icon}
        <Typography className={classNames(innerClasses.label, classes?.textClassName)}>
          {text}
        </Typography>
      </div>
    );
  }
);

export default Tag;
