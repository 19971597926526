import Typography from "@material-ui/core/Typography";

import useStyles from "./OptionStyles";

interface Props {
  label: string;
}

function Option({ label }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.grayColor}>
        {label}
      </Typography>
    </div>
  );
}

export default Option;
