import { format } from "date-fns";

import { CountryUpperCase, Locale } from "models/Countries";
import { DocumentType } from "models/Document";
import { UserResponse } from "models/User";
import { IPlan } from "models/Plan";

import { PaymentOrderData } from "./types";

const BASIC_PLAN_IDS = [20, 21];
const BASIC_PLAN_PAYMENT_OPTIONS = [1, 2];

export interface User {
  id: number;
  rut: string;
  document: string;
  document_type: number | DocumentType;
  name: string;
  last_name: string;
  role: string;
  phone: string;
  email: string;
  address: string | null;
  second_last_name: string;
  hashed_id: string;
  country: CountryUpperCase;
  created_at: string;
  updated_at: string;
}

const getUserData = (user: User, type: string = "lessor") => ({
  person_id: user?.id,
  full_name: user?.name,
  ...(type === "lessee" && {
    document_type: getDocument((user.document_type as DocumentType).identifier),
  }),
  ...(user?.document && { document: user?.document }),
  email: user?.email,
});

export const getIsBasicPlanFlag = (plan: IPlan, country: Locale) => {
  if (country !== "cl") return null;
  const isBasic =
    BASIC_PLAN_IDS.includes(plan?.id) ||
    BASIC_PLAN_PAYMENT_OPTIONS.includes(plan?.payment_option as unknown as number);
  return isBasic;
};

export const getIsLegacyPlanFlag = (plan: IPlan, country: Locale) => {
  if (country !== "cl") return null;
  const BASIC_PLAN_IDS = [20, 21];
  return BASIC_PLAN_IDS.includes(plan?.id);
};

export const getFlagChileBasicPlan = (
  country: Locale,
  isBasicPlanFlag: boolean,
  lesseeDescription: string
) => country === "cl" && isBasicPlanFlag && lesseeDescription !== "LEASE";

const getDocument = (document: string) => (document === "PASSPORT_MX" ? "PASSPORT" : document);

export const parseData = (
  data: PaymentOrderData,
  landlordContract: UserResponse,
  tenantContract: UserResponse,
  propertyId: number,
  subscriptionId: number,
  country: Locale,
  contractId: number,
  managementFee: number | string,
  isBasicPlan: boolean,
  isLegacyPlan: boolean
) => {
  //
  const lesseeData = {
    amount: data.lesseeAmount,
    date: format(new Date(data.lesseeDate), "yyyy-MM-dd"),
    person_data: getUserData(tenantContract, "lessee"),
  };
  const lessorData = {
    amount: data.lessorAmount,
    date: data?.lessorDate && format(new Date(data.lessorDate), "yyyy-MM-dd"),
    person_data: getUserData(landlordContract),
  };

  return {
    description: data.lesseeDescription,
    property_id: propertyId,
    subscription_id: subscriptionId,
    lessee_data: lesseeData,
    lessor_data: lessorData,
    ...(country === "cl" && data.lesseeCurrency && { currency: data.lesseeCurrency }),
    ...(country === "cl" && { is_basic: isBasicPlan }),
    ...(country === "cl" && isBasicPlan && { is_legacy_plan: isLegacyPlan }),
    ...(country === "cl" &&
      isBasicPlan && {
        contract_id: contractId,
        management_fee:
          typeof managementFee === "string" ? Number(managementFee as string) : managementFee,
      }),
  };
};
