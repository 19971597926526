import { isEmpty, mapKeys, omitBy, snakeCase } from "lodash";
import { useParams } from "react-router-dom";
import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { ILandlordSummaryProperty } from "models/AdminModels/Properties";
import propertyService from "services/propertyService";

import { parseLandlordSummaryProperties } from "../utils/parsers";
import { FiltersProps } from "./useFilters";

const useLandlordProperties = (currentPage: number, pageSize: number, filters?: FiltersProps) => {
  const { getToken } = useGetToken();
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    error,
    data = [],
  }: UseQueryResult<ILandlordSummaryProperty[], Error> = useQuery<
    ILandlordSummaryProperty[],
    Error
  >(
    ["landlordSummaryProperties", id, currentPage, pageSize, filters],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getLandlordSummaryProperties(parseInt(id), authToken, {
        page: currentPage,
        page_size: pageSize,
        ...mapKeys(omitBy(filters, isEmpty), (v, k) => snakeCase(k)),
      });
      return parseLandlordSummaryProperties(response.data.results);
    },
    {
      keepPreviousData: true,
    }
  );

  return {
    isLoading,
    error,
    data,
  };
};

export default useLandlordProperties;
