import * as yup from "yup";

export const requiredText = "Obligatorio";
export const emailError = "Email inválido";

export const comunidadFelizSchema = () =>
  yup.object({
    communityName: yup.string().required(requiredText),
    billNumber: yup
      .string()
      .trim()
      .required(requiredText)
      .matches(/^B-[a-zA-Z0-9_]{3}-[a-zA-Z0-9_]{3}$/, "No es un número de cliente valido"),
    unityName: yup.string().typeError(requiredText).required(requiredText),
  });
