import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useBrokerageConfirmModalStyles from "./BrokerageConfirmModalStyles";
import ModalLayout from "./ModalLayout";

interface Props {
  setClose: () => void;
  isOpen: boolean;
  actionText: string;
  onSubmit: () => void;
}

const ConfirmModal = ({ setClose, isOpen, actionText, onSubmit }: Props) => {
  const classes = useBrokerageConfirmModalStyles();
  return (
    <ModalLayout
      open={isOpen}
      handleClose={() => setClose()}
      withIcon
      title={`¿Estás seguro que quieres cambiar la evaluación a: ${actionText}?`}>
      <div className={classes.container}>
        <DesignSystemButton label="Confirmar" size="small" variant="primary" onClick={onSubmit} />
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
