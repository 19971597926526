import { ComponentProps } from "react";

import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";

import useControlledSelection from "./ControlledSelectionStyle";

interface Props extends ComponentProps<typeof ControlledSelection> {
  placeholder?: string;
}
function ControlledSelectionStyled({ placeholder = "", ...props }: Props) {
  const classes = useControlledSelection({ simple: props.simple });
  return (
    <ControlledSelection
      classes={{
        container: classes.containerSelection,
        label: classes.labelSelection,
        select: {
          root: classes.underlineSelection,
        },
        buttonList: classes.buttonList,
      }}
      {...props}
    />
  );
}

export default ControlledSelectionStyled;
