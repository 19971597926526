import { useRef, useState, useEffect } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import { Document, Page } from "react-pdf";

import { cn } from "@houm-com/ui/utils";

import { Document as DocumentsProps } from "domains/evaluations/models/Document";

interface Props {
  documents: DocumentsProps[];
  onHandleSelectedDocument: (doc: DocumentsProps) => void;
  selectedDocument: DocumentsProps | null;
}

const SidePanel = ({ documents, selectedDocument, onHandleSelectedDocument }: Props) => {
  const refSidePanel = useRef<HTMLDivElement>(null);
  const [sidePdfWidth, setSidePdfWidth] = useState(0);

  useEffect(() => {
    const panelWidth = refSidePanel.current?.offsetWidth;
    setSidePdfWidth(panelWidth ? panelWidth - 2 : panelWidth);
  }, [refSidePanel.current?.offsetWidth]);

  return (
    <div className="flex bg-[#B0BEC5] w-[20%] rounded-tl-md rounded-bl-md flex-col p-2">
      <div className="flex flex-col gap-2 w-full h-full" ref={refSidePanel}>
        {documents.map((doc) => (
          <div
            key={doc.id}
            className={cn("cursor-pointer flex justify-center", {
              "border-primary-cta border": selectedDocument && selectedDocument.id === doc.id,
            })}
            onClick={() => onHandleSelectedDocument(doc)}>
            {doc.contentType === "application/pdf" && (
              <Document
                file={doc.url}
                onPassword={() => {
                  throw new Error("file with password");
                }}
                error="No se pudo cargar este PDF">
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  pageNumber={1}
                  width={sidePdfWidth}
                />
              </Document>
            )}
            {doc.contentType !== "application/pdf" && (
              <InnerImageZoom
                src={doc.url}
                hideHint
                srcSet={`${doc.url} 480w, ${doc.url} 800w`}
                sizes="(max-width: 100px) auto, 100px"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidePanel;
