import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  minWidth?: string | number;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  paperWidth: {
    maxWidth: 900,
  },
  modalContainer: {
    minWidth: ({ minWidth }) => minWidth || 350,
    borderRadius: 8,
    padding: "20px 16px",
  },
  dialogTitleBar: {
    maxWidth: 280,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    padding: 0,
    zIndex: 1,
    color: "black",
  },
  dialogTitle: {
    lineHeight: 1.25,
    fontSize: "1.5rem",
    textAlign: "left",
  },
  dialogSubtitle: {
    marginTop: theme.spacing(1),
    lineHeight: 1.3,
    fontSize: "1.125rem",
    textAlign: "left",
  },
  dialogContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    alignContent: "start",
  },
  icon: {
    fontSize: 30,
  },
  dialogContentRoot: {
    padding: 0,
    overflow: "unset",
  },
}));

export default useStyles;
