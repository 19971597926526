import { makeStyles, Theme } from "@material-ui/core";

const useInfoItemLabelStyles = makeStyles<Theme>((theme) => ({
  label: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  value: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
}));

export default useInfoItemLabelStyles;
