import React from "react";
import { Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { Countries } from "utils/datetime";
import { docsManage } from "utils";
import { ControlledList, ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import useFormStyles from "./FormStyles";

function IdFields() {
  const classes = useFormStyles();
  const { watch } = useFormContext();
  const country = watch("country") ?? "Chile";

  return (
    <Grid container>
      <Grid item xs={12}>
        <ControlledList
          disableUnderline
          label="País"
          id="country"
          defaultValue="Chile"
          options={Countries}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledList
          disableUnderline
          id="doc_type"
          label="Tipo de documento"
          options={docsManage(country)}
        />
      </Grid>
      <Grid item xs={12} className={classes.underlined}>
        <ControlledTextField id="doc_number" label="Documento" />
      </Grid>
    </Grid>
  );
}

export default React.memo(IdFields);
