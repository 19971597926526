import moment from "moment";
import Swal from "sweetalert2";

import propertyService from "services/propertyService";
import {
  Action,
  Dispatch,
  CLEAR_PROPERTY,
  SET_PROPERTY_ID,
  SET_PROPERTY_INFO,
  PropertyData,
  SET_LOADING_DATA,
  TimeBlock,
  SET_PROPERTY_SCHEDULE,
  SET_LOADING_SCHEDULE,
  SET_MULTIPROPERTY_ID,
} from "./availabilityTypes";
import { getTimezone, renderAddress } from "utils";

import { getPropertyDays } from "utils/availabilityTime";

export const setPropertyId = (propertyId: number): Action => ({
  type: SET_PROPERTY_ID,
  propertyId,
});

export const clearProperty = (): Action => ({
  type: CLEAR_PROPERTY,
});

export const setPropertyInfo = (propertyData: PropertyData): Action => ({
  type: SET_PROPERTY_INFO,
  propertyData,
});

export const setLoadingData = (loadingData: boolean): Action => ({
  type: SET_LOADING_DATA,
  loadingData,
});

export const setLoadingSchedule = (loadingSchedule: boolean): Action => ({
  type: SET_LOADING_SCHEDULE,
  loadingSchedule,
});

export const setPropertySchedule = (propertyAvailability: TimeBlock[]): Action => ({
  type: SET_PROPERTY_SCHEDULE,
  propertyAvailability,
});
export const setMultiproperyId = (multiPropertyId: number[]): Action => ({
  type: SET_MULTIPROPERTY_ID,
  multiPropertyId,
});

export const getPropertyDetails = async (id: number, dispatch: Dispatch, token: string) => {
  dispatch(setLoadingData(true));
  try {
    const propertyInfo = await propertyService.getProperty(id, token);
    const propertyDetails = await propertyService.getPropertyDetails(id, token);
    const propertySchedule = await propertyService.getAvailablePropertyHours(id, token);
    //@ts-ignore
    getPropertyDays(
      propertySchedule.data,
      null,
      propertyInfo.data.country,
      propertyInfo.data.comuna
    );
    if (propertyInfo && propertyDetails) {
      dispatch(
        setPropertyInfo({
          location: propertyInfo.data.comuna,
          country: propertyInfo.data.country,
          keysState:
            propertyDetails.data?.homechecker_details?.key_state_description ?? "Sin información",
          houmbox: propertyDetails.data?.homechecker_details?.has_houm_box,
          address: renderAddress(propertyInfo.data),
        })
      );
    }
    if (propertySchedule) {
      const schedule = propertySchedule.data.map((item) => {
        const newBeginHour = moment
          .utc(item.begin_hour, "HH")
          .tz(getTimezone(propertyInfo.data.country, propertyInfo.data.comuna))
          .format("HH:mm:ss");
        const newEndHour = moment
          .utc(item.end_hour, "HH")
          .tz(getTimezone(propertyInfo.data.country, propertyInfo.data.comuna))
          .format("HH:mm:ss");
        return Object({
          beginHour: newBeginHour,
          endHour: newEndHour,
          day: item.day,
        });
      });
      dispatch(setPropertySchedule(schedule));
    }
    dispatch(setPropertyId(id));
  } catch (error) {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudieron cargar los datos",
    });
  } finally {
    dispatch(setLoadingData(false));
  }
};

export const updatePropertySchedule = async (
  id: number[] | number,
  dispatch: Dispatch,
  schedule: TimeBlock[],
  token: string
) => {
  dispatch(setLoadingSchedule(true));
  try {
    const updatedSchedule = await propertyService.updateAvailablePropertyHours(
      typeof id === "number" ? id : id[0],
      schedule,
      typeof id === "number" ? null : id,
      token
    );
    if (updatedSchedule) {
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "Horarios actualizados correctamente",
      }); //.then(() => dispatch(clearProperty()));
    } else {
      Swal.fire({
        type: "error",
        title: "Ups!",
        text: "No se pudieron actualizar los horarios",
      });
    }
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudieron actualizar los horarios",
    });
  } finally {
    dispatch(setLoadingSchedule(false));
  }
};

export const updatePropertyHoumbox = async (
  ids: number[],
  houmbox: boolean,
  dispatch: Dispatch,
  token: string
) => {
  try {
    ids.forEach(async (id) => {
      await propertyService.updateHomeCheckerDetails(id, { has_houm_box: houmbox }, token);
    });
  } catch {
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudo actualizar houmbox de la propiedad",
    });
  }
};

export const checkMaximumSelectedAvailabilities = (data: TimeBlock[]): boolean => {
  const maximumPossibleSelectedAvailabilities = 84;
  const selectedAvailabilitiesCount = data?.length ?? 0;
  if (selectedAvailabilitiesCount <= maximumPossibleSelectedAvailabilities) {
    return true;
  }
  const availabilitiesDiff = selectedAvailabilitiesCount - maximumPossibleSelectedAvailabilities;
  Swal.fire({
    type: "error",
    title: "Ups!",
    text: `Debes deseleccionar ${availabilitiesDiff} horario(s). El máximo número de horarios (checkboxs) a seleccionar es de ${maximumPossibleSelectedAvailabilities}`,
  });
  return false;
};
