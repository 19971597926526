import * as React from "react";

import { InputLabel } from "@material-ui/core";
import { useLabelStyles } from "./LabelStyle";

type Props = React.ComponentProps<typeof InputLabel>;

const Label: React.FC<Props> = ({ children, id, ...props }) => {
  const classes = useLabelStyles();

  return (
    <InputLabel id={id} shrink className={classes.label} {...props}>
      {children}
    </InputLabel>
  );
};
export default Label;
