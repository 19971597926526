/* eslint-disable prefer-destructuring */
import { useState } from "react";
import { Grid } from "@material-ui/core";
import Swal from "sweetalert2";

import Loading from "../molecules/Loading";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";
import CustomTextField from "../CustomTextField";
import CustomButton from "../Buttons/CustomButton";
import usePropertyVideoStyles from "./PropertyVideoStyles";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
  property_uid: string;
}

function PropertyVideo({ property, property_uid }: Props) {
  const { getToken } = useGetToken();
  const classes = usePropertyVideoStyles();
  const [videoUrls, setVideoUrls] = useState("");
  const [loading, setLoading] = useState(false);

  const setVideoUrl = async () => {
    try {
      const authToken = await getToken();
      let videoUrl = videoUrls;
      const splitedUrl = videoUrl?.split("=");
      videoUrl = splitedUrl[1].split("&")[0];
      const video_url = `https://www.youtube.com/embed/${videoUrl}`;
      const response = await propertyService.update(property_uid, { video_url }, authToken);
      if (response) {
        setLoading(false);
        Swal.fire({
          type: "success",
          text: "El video ha sido publicado",
        }).then(() => {
          window.location.reload();
        });
        return response;
      }
    } catch {
      setLoading(false);
      Swal.fire({
        type: "error",
        title: "El url del video no tiene el formato adecuado",
        text: "Intente con un url en el siguiente formato: https://www.youtube.com/watch?v=XXXXXXXX",
      });
      return undefined;
    }
  };

  const deleteVideoUrl = async () => {
    const authToken = await getToken();
    const myData = property;
    myData.video_url = "";
    const response = await propertyService.update(property_uid, myData, authToken);
    if (response) {
      setLoading(loading);
      Swal.fire({
        type: "success",
        text: "El video ha sido eliminado",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <Loading loading={loading}>
      <div className={classes.container}>
        <h2>Video de la propiedad</h2>
        <p>(El nombre no puede tener espacios)</p>
        {property.video_url && (
          <>
            <iframe
              title="propertyvideo"
              frameBorder="0"
              scrolling="no"
              width="400"
              height="300"
              src={property.video_url}
            />
            <br />
            <br />
            <CustomButton
              className={classes.deleteButton}
              active
              kind="secondary"
              onClick={() => {
                deleteVideoUrl();
              }}>
              Eliminar Video
            </CustomButton>
            <br />
            <br />
          </>
        )}
        <Grid item xs={6}>
          <CustomTextField
            label="Url de video"
            onChange={(e) => {
              setVideoUrls(e.target.value);
            }}
            InputProps={{ placeholder: "example.mp4", disableUnderline: false }}
          />
        </Grid>
        <CustomButton
          active
          className={classes.mainButton}
          kind="blue"
          onClick={() => {
            setVideoUrl();
          }}>
          Subir video
        </CustomButton>
      </div>
    </Loading>
  );
}

export default PropertyVideo;
