import { Theme } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/styles";
import { DatePicker, MuiPickersUtilsProvider, DatePickerProps } from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";

import { CustomThemePicker } from "./DatePickerStyles";

interface Props extends DatePickerProps {
  theme?: Theme;
}

function CustomDatepicker(props: Props) {
  return (
    <ThemeProvider theme={(theme) => props.theme ?? CustomThemePicker(theme)}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker disableToolbar disablePast variant="static" {...props} />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default CustomDatepicker;
