import { useMutation } from "react-query";

import { useGetToken } from "hooks";
import schedulesService from "services/schedulesService";
import { useAlert } from "context/alertContext/alertContext";
import { setAlertInfo } from "context/alertContext/alertActions";

interface IUseUpdateChecker {
  propertyId: number;
  onSuccessHandler: () => void;
}

const useUpdateChecker = ({ propertyId, onSuccessHandler }: IUseUpdateChecker) => {
  const { getToken } = useGetToken();
  const { dispatch } = useAlert();

  const { mutate, isLoading, isError } = useMutation(
    async () => {
      const authToken = await getToken();
      return schedulesService.unassignAppraiserToProp(
        {
          property_id: propertyId,
        },
        authToken
      );
    },
    {
      onSuccess: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: `Se ha desasigando al houmer de forma exitosa.`,
            body: "",
            type: "success",
          })
        );
        onSuccessHandler();
      },
      onError: () => {
        dispatch(
          setAlertInfo({
            open: true,
            head: `¡Oh no! Ocurrió un problema`,
            body: "No se pudo desasignar al houmer, intenta de nuevo",
            type: "error",
          })
        );
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
  };
};

export default useUpdateChecker;
