import { ReactNode } from "react";
import clx from "classnames";

import { Typography } from "@material-ui/core";

import useMainTextStyles from "./MainTextStyles";

interface MainTextProps {
  className?: string;
  children: ReactNode;
}

function MainText({ children, className }: MainTextProps) {
  const classes = useMainTextStyles();

  return <Typography className={clx(classes.mainTextRoot, className)}>{children}</Typography>;
}

export default MainText;
