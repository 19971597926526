export default {
  wrapper: "overflow-y-auto",
  inputContainer: "w-full",
  helperText: "font-bold mt-6 mb-2",
  buttonContainer: "flex mt-4 justify-center",
  cityContainer: "w-full flex justify-between items-center spacing-x-2 my-2 px-2",
  firstSelect: "w-full",
  select: "w-[90%]",
  deleteCityButton: "cursor-pointer",
  addCityButtonContainer: "flex justify-end my-2",
  addCityButton:
    "bg-transparent border-none text-primary-cta hover:bg-transparent hover:text-primary-cta",
};
