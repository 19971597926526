import { makeStyles, Theme } from "@material-ui/core";

const useErrorMessageStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
    marginLeft: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
}));

export default useErrorMessageStyles;
