import { UseQueryResult, useQuery } from "react-query";

import contractService from "services/contractService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parseExternalColection } from "../utils/utils";
import { ExternalCollection } from "../utils/types";

const useGetExternalCollection = (contractId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data }: UseQueryResult<ExternalCollection, Error> = useQuery(
    ["externalCollections"],
    async () => {
      const authToken = await getToken();
      const response = await contractService.getExternalCollections(contractId, authToken);
      return parseExternalColection(response?.data);
    },
    {
      enabled: country === "cl",
    }
  );

  return { isLoading, error, data };
};

export default useGetExternalCollection;
