import { Theme, makeStyles } from "@material-ui/core/styles";

const useSendProfileStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

export default useSendProfileStyles;
