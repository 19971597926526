import { compareDesc } from "date-fns";

import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";

import { RentStatus } from "models/AdminModels/Properties";

export const rentStatusTagType = (rentStatus: RentStatus) => {
  switch (rentStatus) {
    case "RENEWAL":
    case "CLOSURE":
      return TagType.SUCCESS;
    case "PUBLISHED":
    case "TO_RENEW":
      return TagType.WARNING;
    case "EXIT":
    case "TO_PUBLISH":
      return TagType.ERROR;
    case "RENTED":
    case "RESERVED":
    default:
      return TagType.INFO;
  }
};

export const getStatusTagProps = (subStatus: string) => {
  const type = subStatus === "LEASED" ? TagType.LIGHT_GREY : TagType.GREY;
  const text = subStatus === "LEASED" ? "Arrendada" : "No arrendada";
  return { text, type };
};

export const getTenantPaymentStatusTagProps = (
  tenantPaymentStatus: "UNPAID" | "PAID" | "CANCELED",
  agreedDate
) => {
  if (tenantPaymentStatus === "PAID") return { type: TagType.SUCCESS, text: "Al día" };
  if (compareDesc(agreedDate, new Date()) === 1 && tenantPaymentStatus === "UNPAID")
    return { type: TagType.ERROR, text: "Atrasado" };
  return { type: TagType.WARNING, text: "Pendiente" };
};
