import { Box, Grid } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import CardBold from "../../../BasicServices/components/typography/CardBold";
import NoServices from "../../../components/NoServices";
import DeleteService from "../../../components/DeleteService";
import { BasicService } from "../../../utils/types";
import useCommonExpenseStyles from "./CommonExpenseStyles";
import CommonExpenseData from "./components/CommonExpenseData";
import EditCommonExpenses from "./components/EditCommonExpenses";
import Voucher from "./components/Voucher";
import { getClientInfo } from "./utils/utils";

interface Props {
  commonExpenses: BasicService[];
  country: Locale;
  loading: boolean;
  propertyId: number;
}

const CommonExpense = ({ commonExpenses, country, loading, propertyId }: Props) => {
  const classes = useCommonExpenseStyles();

  if (loading) return <Spinner />;

  if (commonExpenses.length === 0) return <NoServices serviceType="gastos comunes" />;

  const { company, name, legacyCompany, documentUrl, documentName } = commonExpenses[0];

  return (
    <Box className={classes.commonExpensesListRoot}>
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <CardBold subtitle>{name}</CardBold>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={10}>
              <EditCommonExpenses
                company={company}
                country={country}
                propertyId={propertyId}
                data={getClientInfo(commonExpenses[0])}
              />
            </Grid>
            <Grid item xs={2}>
              <DeleteService propertyId={propertyId} company={legacyCompany} type="commonExpense" />
            </Grid>
          </Grid>
        </Grid>
        <CommonExpenseData {...commonExpenses[0]} />
        {legacyCompany === "otro" && (
          <Voucher voucherUrl={documentUrl} fileName={documentName || "Descargar comprobante"} />
        )}
      </Box>
    </Box>
  );
};

export default CommonExpense;
