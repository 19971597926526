import { makeStyles, Theme } from "@material-ui/core";

const useMovementTableStyles = makeStyles<Theme>((theme) => ({
  tableTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.grey[500],
    marginTop: theme.spacing(3),
  },
}));

export default useMovementTableStyles;
