import { makeStyles, Theme } from "@material-ui/core";

const useScheduleButtonStyles = makeStyles((theme: Theme) => ({
  scheduleButton: {
    fontWeight: 700,
    minWidth: 200,
    textTransform: "none",
    borderRadius: 50,
    marginTop: theme.spacing(3),
  },
}));

export default useScheduleButtonStyles;
