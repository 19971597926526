import { Controller, useFormContext } from "react-hook-form";

import { Grid, TextField } from "@material-ui/core";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";

import useStyles, { useCustomTextfieldStyles } from "./monthlyTableStyles";

interface Props {
  id: string;
  newValue: string;
  disabled: boolean;
}

function CustomTextField({ id, newValue, disabled }: Props) {
  const { control } = useFormContext();
  const classes = useCustomTextfieldStyles();

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TextField
          id={id}
          name={id}
          value={value}
          defaultValue={
            id === "lessorBrokerage-month-1" || id === "lesseeBrokerage-month-1" ? "100" : newValue
          }
          onChange={(e) => {
            if (e.target.value) {
              const newValue = Math.min(Math.max(parseFloat(e.target.value), 0), 100);
              onChange(newValue);
            } else {
              onChange(e.target.value);
            }
          }}
          onBlur={(e) => {
            onChange(e.target.value);
          }}
          required
          type="number"
          InputProps={{ classes, disableUnderline: true, disabled } as Partial<OutlinedInputProps>}
        />
      )}
    />
  );
}
interface MonthTextfieldProps {
  id: string;
  number: number;
  defaultValue: string;
  disabled?: boolean;
}

function MonthTextfield({ id, number, defaultValue, disabled = false }: MonthTextfieldProps) {
  const classes = useStyles();
  return (
    <Grid container alignItems="stretch" className={classes.monthCard}>
      <Grid
        container
        item
        xs={3}
        className={classes.monthGrid}
        alignItems="center"
        justifyContent="center">
        Mes {number}
      </Grid>
      <Grid
        container
        item
        className={classes.textFieldGrid}
        xs={6}
        alignItems="center"
        justifyContent="center">
        <CustomTextField id={id} newValue={defaultValue} disabled={disabled} />
      </Grid>
    </Grid>
  );
}

export default MonthTextfield;
