import { makeStyles, Theme } from "@material-ui/core";

const usePromotionStyles = makeStyles<Theme>((theme) => ({
  promotionRoot: {
    padding: 0,
    margin: theme.spacing(0, 1, 2, 1),
  },
}));

export default usePromotionStyles;
