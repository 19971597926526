import { cn } from "@houm-com/ui/utils";
import { add, differenceInCalendarMonths, format } from "date-fns";
import { useHistory } from "react-router-dom";

import { IPromotion } from "models/Promotions";

import { classes } from "./PromotionCardStyles";

interface Props {
  promotion: IPromotion;
  setSelectedPromotionToEdit: (promotion: IPromotion) => void;
}

export default function PromotionCard({ promotion, setSelectedPromotionToEdit }: Props) {
  const initDate = add(new Date(promotion.initDate), { days: 1 });
  const endDate = add(new Date(promotion.endDate), { days: 1 });
  const history = useHistory();

  const handleClickOpenEditModal = () => {
    setSelectedPromotionToEdit(promotion);
  };

  return (
    <div className={classes.card}>
      <div className={cn([classes.section, classes.spread])}>
        <p className={classes.citiesText}>{`Ciudades: ${promotion.cities.length}`}</p>
        {promotion.active ? (
          <div className={classes.activeTag}>Activa</div>
        ) : (
          <div className={classes.inactiveTag}>Desactivada</div>
        )}
      </div>

      <div className={classes.section}>
        <p className={classes.text}>
          {`Disponible desde ${format(initDate, "dd/MM/yyyy")} hasta ${format(
            endDate,
            "dd/MM/yyyy"
          )}`}
        </p>
        <h4 className={classes.title}>{promotion.promotionName || "Sin título"}</h4>
        <p>{`Objetivo: ${promotion.target || "N/A"}`}</p>
      </div>

      <div className={classes.section}>
        <h4 className={cn([classes.title, classes.details])}>Detalles de la promoción</h4>
        <p>{`Duración: ${differenceInCalendarMonths(endDate, initDate)} meses`}</p>
        <p>{`Descuento comisión a propietario: ${promotion.leaseLandlord.leaseLandlordMonthQuantity}%`}</p>
        <p>{`Descuento comisión a arrendatario: ${promotion.leaseTenant.leaseTenantMonthQuantity}%`}</p>
        <p>{`Descuento corretaje a propietario: ${
          promotion.landlordBrokerage.landlordBrokerageValue || "N/A"
        }`}</p>
        <p>{`Descuento corretaje a arrendatario: ${
          promotion.tenantBrokerage.tenantBrokerageValue || "N/A"
        }`}</p>
      </div>

      <div className={classes.spread}>
        <p
          className={classes.action}
          onClick={() => history.push(`/admin/promotions/${promotion.id}`)}>
          Ver detalles
        </p>
        <p onClick={handleClickOpenEditModal} className={cn([classes.action, classes.editDates])}>
          Editar
        </p>
      </div>
    </div>
  );
}
