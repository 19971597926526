import { useEffect } from "react";
import moment from "moment";

import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import { setFilters } from "context/demandLeadContext/leads/demandLeadActions";

import InputDatePicker from "Components/Admin/UIComponents/atoms/InputDatePicker";
import InputTimePicker from "Components/Admin/UIComponents/atoms/InputTimePicker";

import useStyles from "./FollowingDateFilterStyles";

export default function FollowingDateFilter() {
  const classes = useStyles();
  const { dispatch, state } = useDemandLead();

  useEffect(() => {
    const startToday = moment
      .utc(moment().startOf("day").add(8, "hours"))
      .format("YYYY-MM-DD HH:mmZZ");
    dispatch(
      setFilters({
        ...state.filters,
        following_date_from: startToday,
      })
    );
  }, []);

  const handleChangeDate = (date) => {
    const currentDate = moment.utc(state.filters.following_date_from).local();
    const newDate = moment(date);
    newDate.set({
      hour: currentDate.hour(),
      minute: currentDate.minutes(),
    });
    const newDateInUtc = moment.utc(newDate).format("YYYY-MM-DD HH:mmZZ");
    dispatch(
      setFilters({
        ...state.filters,
        following_date_from: newDateInUtc,
      })
    );
  };

  const handleChangeTime = (date) => {
    const currentDate = moment.utc(state.filters.following_date_from).local();
    const newDate = moment(date);
    newDate.set({
      year: currentDate.year(),
      month: currentDate.month(),
      date: currentDate.date(),
    });
    const newDateInUtc = moment.utc(newDate).format("YYYY-MM-DD HH:mmZZ");
    dispatch(
      setFilters({
        ...state.filters,
        following_date_from: newDateInUtc,
      })
    );
  };

  return (
    <div className={classes.followingDateFilterContainer}>
      <InputDatePicker
        id="selectedDayInput"
        value={moment.utc(state.filters.following_date_from).local()}
        onChange={handleChangeDate}
      />
      <InputTimePicker
        value={moment.utc(state.filters.following_date_from).local()}
        onChange={handleChangeTime}
      />
    </div>
  );
}
