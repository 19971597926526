import { Typography } from "@material-ui/core";

interface Props {
  user: {
    name: string;
    last_name: string;
    second_last_name: string;
  };
}

function ApplicantFullName({ user }: Props) {
  return (
    <Typography variant="body2">
      {`${user.name} ${user.last_name} ${user.second_last_name}`}
    </Typography>
  );
}

export default ApplicantFullName;
