import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Grid } from "@material-ui/core";

import { ReactComponent as AddIcon } from "assets/icons/Tenant/add-icon.svg";
import FilePreview from "../atoms/FilePreview";

import useStyles from "./DropAreaStyles";

interface Props {
  initialFiles?: (string | File)[];
  accepted?: string;
  maxFiles?: number;
  onChange: (files: (File | IFile)[]) => void;
  id: string;
}

interface IFile {
  preview: string;
  [x: string]: any;
}

const DropArea = ({
  initialFiles = null,
  accepted = "image/*",
  maxFiles = 1,
  onChange,
  id,
}: Props) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept: accepted,
    onDrop: <T extends File>(acceptedFiles: T[]) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  useEffect(() => {
    if (initialFiles)
      setFiles(
        initialFiles.map((item) => ({
          preview: item as string,
        }))
      );
  }, [initialFiles]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  const handleDelete = (file: string): void => {
    setFiles(files.filter((item) => item.preview !== file));
  };

  return (
    <Grid container className={classes.container}>
      {files?.map((file) => (
        <FilePreview
          src={file.preview}
          onDelete={handleDelete}
          key={file.preview}
          type={file?.type}
        />
      ))}
      {files.length < maxFiles && (
        <Grid item {...getRootProps({ className: classes.root })}>
          <input id={id} {...getInputProps()} />
          <AddIcon className={classes.icon} />
        </Grid>
      )}
    </Grid>
  );
};

export default DropArea;
