import currency from "@houm-com/formats/currency";

import ProtectedComponent from "domains/auth/components/ProtectedComponent";
import ButtonDelete from "../../../ButtonDelete";

interface Props {
  penaltyValue: number;
  penaltyCurrency: string;
  status: string;
  handleDeletePenalty: () => void;
}

const Penalty = ({ penaltyValue, penaltyCurrency, status, handleDeletePenalty }: Props) => (
  <div className="flex justify-center gap-2 items-center">
    <p className="align-middle text-p14">{currency(penaltyValue, penaltyCurrency)}</p>
    {status !== "PAID" && penaltyValue !== 0 && (
      <ProtectedComponent
        scope="roles"
        strategy="any"
        require={["super_admin", "management_admin"]}>
        <ButtonDelete onHandleClick={handleDeletePenalty} />
      </ProtectedComponent>
    )}
  </div>
);

export default Penalty;
