import { useState } from "react";

import { localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";

import { ISuggestedProperty } from "../../utils/types";
import useProperties from "../useProperties";

const useSearch = ({ searchValue, country }: { searchValue: string; country: Locale }) => {
  const [suggestions, setSuggestions] = useState<ISuggestedProperty[]>([]);

  const saveSuggestion = (data: ISuggestedProperty[]) => setSuggestions(data);
  const { isFetching, isError, refetch } = useProperties({
    valueSearch: searchValue,
    params: { page_size: 20, rental_status_not: "archived", country: localeToCountry[country] },
    handleResultData: saveSuggestion,
  });

  const resetSuggestion = () => setSuggestions([]);
  const makeSearch = (value: string) => refetch();

  return {
    suggestions,
    makeSearch,
    resetSuggestion,
    isLoading: isFetching,
    isError,
  };
};

export default useSearch;
