import axios from "axios";
import { BACKEND_URL } from "../env";
import { getToken } from "./helper";

export default {
  delete(id, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.delete(`/properties/v2/property_photos/${id}/`).catch((error) => false);
  },

  order(propertyId, arr, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http
      .post(`/propietary/v2/properties/${propertyId}/order_photos/`, {
        photos: arr,
      })
      .catch((error) => false);
  },
};
