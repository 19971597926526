import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useMacrozones } from "context/macrozones/macrozonesContext";
import { setModalMessage } from "context/macrozones/macrozonesActions";

function ModalMessage() {
  const {
    state: {
      modalMessage: { title, isOpen, subtitle },
    },
    dispatch,
  } = useMacrozones();

  const handleClose = () => {
    dispatch(setModalMessage({ isOpen: false }));
  };

  return (
    <CustomCenteredModal
      withLogo
      onClose={handleClose}
      showCloseButton
      open={isOpen}
      title={title}
      subtitle={subtitle}
    />
  );
}
export default ModalMessage;
