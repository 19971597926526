export const promotionSteps = [
  {
    label: "Cierres",
  },
  {
    link: "/admin/promotions",
    label: "Promociones",
  },
  {
    label: "Crear promoción",
  },
];
