import { useMemo, useState } from "react";

import { Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { fromToOptions } from "utils/fromToOptions";
import { useGetToken } from "hooks";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { setCurrentTabValue } from "context/tabContext/tabActions";
import { useTab } from "context/tabContext/tabContext";
import {
  handleCloseButtonActivityModal,
  setCompleteActivityConfig,
  updateQualification,
  getLead,
} from "context/demandLeadContext/lead/demandLeadActions";

import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import ActionButton from "Components/Admin/administrator/demand/components/ActionButton";
import useCompleteActivityStyles from "./CompleteActivityStyles";

function CompleteActivity() {
  const classes = useCompleteActivityStyles();
  const { getToken } = useGetToken();
  const { setValue } = useFormContext();

  const {
    state: { completedActivity, lead },
    dispatch,
  } = useDemandLead();

  const { dispatch: dispatchTab } = useTab();

  const titles = useMemo(() => ["Buyer calificado ¡Exitosamente!", "Finaliza tu actividad"], []);
  const [title, setTitle] = useState<string>(titles[1]);
  const [qualification, setQualification] = useState<number>();

  const handleButton = (value: "Perdido" | "Seguimiento" | "Agendamiento HA") => {
    setValue("state", { label: value, value });
    dispatch(setCompleteActivityConfig(false, completedActivity.currentActivity));

    if (value === "Seguimiento") {
      setValue("activityName", { label: "Realizar Seguimiento LQ", value: 1 });
    }
  };

  const changeQualification = (qualification: number) => {
    if (!qualification) return;
    getToken().then(async (authToken) => {
      setQualification(qualification);
      const statusUpdated = await updateQualification(
        dispatch,
        completedActivity?.currentActivity.id,
        qualification,
        authToken
      );

      if (statusUpdated) {
        setTitle(titles[0]);
        getLead(dispatch, `${lead.id}`, authToken);
      }
    });
  };

  const handleButtonSchedule = () => {
    dispatch(setCompleteActivityConfig(false, completedActivity.currentActivity));
    handleCloseButtonActivityModal(dispatch);
    dispatchTab(setCurrentTabValue("agendar"));
  };

  return (
    <CustomCenteredModal
      open={completedActivity.isOpenModal}
      onClose={() => handleCloseButtonActivityModal(dispatch)}
      title={title}
      subtitle="Elige una de las acciones para continuar"
      withLogo
      showCloseButton>
      {!lead?.lead.qualification && (
        <div className={classes.gradeContainer}>
          <Typography className={classes.gradeLabel}>Agregar nota:</Typography>
          <div className={classes.options}>
            <SelectionOptions
              id="qualification"
              onChange={changeQualification}
              value={qualification}
              round
              compressed
              simple={false}
              options={fromToOptions(1, 5)}
            />
          </div>
        </div>
      )}
      <div className={classes.buttonContainer}>
        <ActionButton onClick={() => handleButton("Seguimiento")}>Seguimiento</ActionButton>

        {lead.lead.qualification && (
          <ActionButton onClick={handleButtonSchedule}>Agendamiento</ActionButton>
        )}
        <ActionButton onClick={() => handleButton("Perdido")}>Perdido</ActionButton>
      </div>
    </CustomCenteredModal>
  );
}

export default CompleteActivity;
