import * as yup from "yup";

export const requiredText = "Obligatorio";

export const dateFormat = "YYYY-mm-dd";
export const hourFormat = "HH:mm";

export const exitProcessValidationSchema = yup.object({
  requirementOrigin: yup.string().required(requiredText),
  estimatedDate: yup.string().required(requiredText),
  comment: yup.string().required(requiredText),
});
