import useNewInternalUserStyles from "./NewInternalUserStyles";
import FormUser from "./components/FormUser";
import ScreenHeader from "domains/common/components/ScreenHeader";

function NewUserAdmin() {
  const classes = useNewInternalUserStyles();

  return (
    <>
      <ScreenHeader title="Nuevo usuario interno" description="Crea nuevos usuarios internos" />
      <div className={classes.formContainer}>
        <FormUser />
      </div>
    </>
  );
}

export default NewUserAdmin;
