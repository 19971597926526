import { ReactNode } from "react";
import { Button, ButtonProps } from "@material-ui/core";
import useCustomButtonStyles from "./CustomButtonGrayStyles";

interface Props extends ButtonProps {
  startIcon?: ReactNode;
  children?: ReactNode;
}
function CustomButton({ startIcon, children, ...props }: Props) {
  const classes = useCustomButtonStyles();
  return (
    <Button variant="outlined" className={classes.root} startIcon={startIcon} {...props}>
      {children}
    </Button>
  );
}

export default CustomButton;
