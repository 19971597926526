import { makeStyles, Theme } from "@material-ui/core/styles";

const useAppraiserInformationStyles = makeStyles((theme: Theme) => ({
  propertyInformationCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    minHeight: 100,
  },
  daily: {
    width: "auto",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[75],
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
  infoContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    minHeight: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  datesContainer: {
    display: "flex",
    gap: theme.spacing(1),
    overflowX: "scroll",
  },
  arrowsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alertText: {
    color: "#AE0400",
    fontWeight: "bold",
  },
  boldText: {
    fontWeight: "bold",
  },
  dateColumn: {
    minWidth: 170,
  },
}));

export default useAppraiserInformationStyles;
