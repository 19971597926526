import { GridSortModel } from "@material-ui/x-grid";
import {
  Action,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_ORDERING,
  SHOW_MODAL,
  SHOW_SECOND_MODAL,
  SHOW_THIRD_MODAL,
  SET_TABLE_FILTERS,
  SET_SEARCH_QUERY,
} from "./tableTypes";

export const setPageSize = (pageSize: number): Action => ({
  type: SET_PAGE_SIZE,
  pageSize,
});

export const setCurrentPage = (currentPage: number): Action => ({
  type: SET_CURRENT_PAGE,
  currentPage,
});

export const setShowModal = (showModal: boolean): Action => ({
  type: SHOW_MODAL,
  showModal,
});

export const setShowSecondModal = (showSecondModal: boolean): Action => ({
  type: SHOW_SECOND_MODAL,
  showSecondModal,
});

export const setShowThirdModal = (showThirdModal: boolean): Action => ({
  type: SHOW_THIRD_MODAL,
  showThirdModal,
});

export const setSorting = (sorting: GridSortModel): Action => {
  let ordering: string | null = null;
  if (sorting.length > 0) {
    ordering = `${sorting[0].sort === "asc" ? "" : "-"}${sorting[0].field}`;
  }
  return {
    type: SET_ORDERING,
    ordering,
  };
};

export const setTableFilters = (tableFilters): Action => ({
  type: SET_TABLE_FILTERS,
  tableFilters,
});

export const setSearchQuery = (searchQuery: string): Action => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
});
