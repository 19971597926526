import { Grid } from "@material-ui/core";

import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { INCOME, OUTFLOW, TYPE_ALL } from "../constants";
import MovementButtons from "../MovementButtons";
import useHeaderStyles from "./HeaderStyles";
import { typeToOptions } from "../utils";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";

type MovementType = typeof INCOME | typeof OUTFLOW | typeof TYPE_ALL;

interface Props {
  movementType: MovementType;
  onMovementTypeChange: (e: MovementType) => void;
  handleOpenDialog: (type: string) => void;
}

const Header = ({ movementType, onMovementTypeChange, handleOpenDialog }: Props) => {
  const classes = useHeaderStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={6}>
        <SelectionOptions
          id="typeBox"
          options={typeToOptions()}
          value={movementType}
          simple={false}
          onChange={onMovementTypeChange}
          buttonClassName={classes.buttonTypes}
          defaultValue={movementType}
        />
      </Grid>
      <ProtectedComponent
        scope="roles"
        strategy="any"
        require={["super_admin", "management_admin"]}>
        <MovementButtons handleOpenDialog={handleOpenDialog} />
      </ProtectedComponent>
    </Grid>
  );
};

export default Header;
