import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
  },
  grayColor: { color: theme.palette.grey[500] },
  underline: {
    borderWidth: theme.spacing(0.1),
    borderColor: theme.palette.grey[500],
  },
}));

export default useStyles;
