import { useMemo, useState } from "react";

import { useGetToken, useDemandParams } from "hooks";

import { useList } from "context/demandLeadContext/list/listContext";

import {
  setSelectedLeads,
  updateLBtoLeads,
  setIsOpenSelectedModal,
  getLeads,
} from "context/demandLeadContext/leads/demandLeadActions";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import ActionButton from "Components/Admin/administrator/demand/components/ActionButton";
import CustomAutocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";

import useAssignToOtherStyles from "./AssignToOtherStyles";

function AssignToOther() {
  /**
   *  step 0: Select a LQ
   *  step 1: Confirm  leads and LQ
   *  step 2: Success assignment message
   *  step 3: Error
   */

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [executive, setExecutive] = useState<{
    label: string;
    value: number;
  }>();

  const { getToken } = useGetToken();

  const {
    state: { executives },
  } = useList();

  // const { state: tableState } = useTable();

  const {
    params,
    demandState: { deal, selectedLeads, isOpenSelectedModal },
    demandDispatch,
  } = useDemandParams();

  const titles = [
    "Asignar selección a: ",
    "Asignar...",
    "Asignación exitosa",
    "Ha ocurrido un error",
  ];

  /**
   *  function to handle step 0 button
   */
  const handleAssignStep = () => {
    setStep(1);
  };

  const handleClose = () => {
    setStep(0);
    demandDispatch(setIsOpenSelectedModal(false));
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleUpdateLB = () => {
    getToken().then(async (authToken) => {
      setLoading(true);
      const success = await updateLBtoLeads(
        Object.values(selectedLeads).flat(),
        executive.value,
        authToken
      );
      if (success) {
        setLoading(false);
        setStep(2);
        demandDispatch(setSelectedLeads({}));
        return;
      }
      setLoading(false);
      setStep(3);
    });
  };

  const handleLastBack = () => {
    getToken().then((authToken) => getLeads(demandDispatch, params, authToken));

    handleClose();
  };

  const ammountLead = useMemo(() => {
    const ammount = Object.values(selectedLeads).reduce(
      (prev, current) => prev + current.length,
      0
    );
    return ammount;
  }, [selectedLeads]);

  const ammountMessage = ammountLead === 1 ? "1 Lead" : `${ammountLead} Leads`;

  const classes = useAssignToOtherStyles();

  return (
    <CustomCenteredModal
      withLogo={[0, 2, 3].includes(step)}
      open={isOpenSelectedModal}
      title={titles[step]}
      onClose={handleClose}>
      {step === 0 && (
        <>
          <div className={classes.centerContainer}>
            <CustomAutocomplete
              id="inputAutocomplete"
              containerClass={classes.autocompleteContainer}
              label=""
              options={executives}
              value={executive}
              onChange={setExecutive}
              placeholder={`Nombre ${deal === "venta" ? "LBQ" : "LQ"}`}
            />
          </div>
          <div className={classes.centerContainer}>
            <ActionButton id="backButton" variant="text" onClick={handleClose}>
              Atrás
            </ActionButton>
            <ActionButton id="nextButton" disabled={!executive} onClick={handleAssignStep}>
              Asignar
            </ActionButton>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className={classes.stepTwoTitleContainer}>
            <b>{ammountMessage}</b> seleccionados a <b>{executive.label}</b>
          </div>
          <Loading loading={loading}>
            <div className={classes.stepTwoButtonsContainer}>
              <ActionButton id="nextButton" disabled={!executive} onClick={handleUpdateLB}>
                Asignar
              </ActionButton>
              <ActionButton id="backButton" variant="text" onClick={handleBack}>
                Volver
              </ActionButton>
            </div>
          </Loading>
        </>
      )}
      {step === 2 && (
        <div className={classes.centerContainer}>
          <ActionButton id="backButton" variant="text" onClick={handleLastBack}>
            Volver
          </ActionButton>
        </div>
      )}
      {step === 3 && (
        <div className={classes.centerContainer}>
          <ActionButton id="backButton" variant="text" onClick={() => setStep(1)}>
            Volver
          </ActionButton>
        </div>
      )}
    </CustomCenteredModal>
  );
}

export default AssignToOther;
