import { useMemo } from "react";

import { IPromotionDetails } from "Components/Admin/administrator/promotions/components/PromotionForm/utils/types";

import DetailsItem from "../DetailsItem";

type GeneralInfoProps = {
  initDate: Date;
  endDate: Date;
  promotionDetails: IPromotionDetails;
};

export default function GeneralInfo({ initDate, endDate, promotionDetails }: GeneralInfoProps) {
  const duration = useMemo(() => {
    if (initDate && endDate) {
      return new Date(endDate.getTime() - initDate.getTime()).getMonth();
    }
    return null;
  }, [initDate, endDate]);

  const getPercentageValuesFromData = (key: string) =>
    promotionDetails[key][`${key}Flag`] === "yes" && promotionDetails[key][`${key}Value`]
      ? `${promotionDetails[key][`${key}Value`]}%`
      : "N/A";

  return (
    <DetailsItem title="Info general">
      {(duration || duration === 0) && <span>Duracion: {duration} meses</span>}
      <p>Descuento comisión a propietario: {getPercentageValuesFromData("landlordBrokerage")}</p>
      <p>Descuento comisión a arrendatario: {getPercentageValuesFromData("tenantBrokerage")}</p>
      <p>Descuento corretaje a propietario: {getPercentageValuesFromData("leaseLandlord")}</p>
      <p>Descuento corretaje a arrendatario: {getPercentageValuesFromData("leaseTenant")}</p>
    </DetailsItem>
  );
}
