import { styled, Theme, Box } from "@material-ui/core";

export const PriceChip = styled(Box)<Theme>(({ theme }) => ({
  height: 30,
  minWidth: 100,
  fontWeight: 700,
  color: "#607D8B",
  textAlign: "center",
  fontSize: "0.875rem",
  backgroundColor: "#EFF2F3",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0.5, 2),
}));
