import useUser from "domains/user/hooks/useUser";
import { useTranslation } from "react-i18next";
import ScreenHeader from "domains/common/components/ScreenHeader";

export default function Dashboard() {
  const { user, status } = useUser();
  const { t } = useTranslation(["dashboard"]);
  if (status === "loading") return <div>Loading...</div>;
  if (status === "error") return <div>Error</div>;
  return (
    <ScreenHeader
      title={`Hola, ${user.name}`}
      description={`${t("welcome.to.home", { ns: "dashboard" })}`}
    />
  );
}
