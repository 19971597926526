import { useState, useEffect, useRef } from "react";

import axios from "axios";

import useGetToken from "hooks/useGetToken";
import userService from "services/userService";

import { UserModel } from "models/User";
import { debounce } from "lodash";

const useUserSearch = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [loading, setLoading] = useState(false);

  const { getToken } = useGetToken();

  const source = useRef(null);

  useEffect(() => {
    getSource();
  }, []);

  const getSource = () => {
    source.current = null;
    const { CancelToken } = axios;
    source.current = CancelToken.source();
    return source.current;
  };

  const handleSearch = async (val) => {
    source.current?.cancel();
    const sourceCancelation = getSource();
    setUsers([]);
    getToken().then(async (authToken) => {
      try {
        setLoading(true);
        const userResponse = await userService.getUsers(
          { email: val },
          authToken,
          sourceCancelation.token
        );
        if (userResponse) {
          setUsers(userResponse.data);
          setLoading(false);
        }
      } catch (error) {
        setUsers([]);
      }
    });
  };
  const debounceSearch = useRef(debounce(handleSearch, 300));

  return {
    handleSearch: debounceSearch.current,
    users,
    loading,
  };
};
export default useUserSearch;
