import { useMemo } from "react";

import useFilterBarStyles from "./FilterBarStyles";
import SearchLead from "./SearchLead";
import CustomButtonGray from "Components/Admin/administrator/demand/components/CustomButtonGray";
import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import {
  setIsIsOpenFilterModal,
  setIsOpenSelectedModal,
} from "context/demandLeadContext/leads/demandLeadActions";

import { ReactComponent as Options } from "assets/images/options.svg";
import { ReactComponent as Reply } from "assets/icons/reply.svg";

import ActionButton from "Components/Admin/administrator/demand/components/ActionButton";

function FilterBar() {
  const classes = useFilterBarStyles();
  const {
    state: { isOpenFilterModal, selectedLeads },
    dispatch,
  } = useDemandLead();

  const someSelectedLead = useMemo(
    () => !!Object.values(selectedLeads).find((item) => item.length > 0),

    [selectedLeads]
  );

  const handleAssign = () => {
    dispatch(setIsOpenSelectedModal(true));
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchInputContainer}>
        <SearchLead />
      </div>
      <div className={classes.buttonsContainer}>
        {someSelectedLead && (
          <ActionButton id="reassignButton" startIcon={<Reply />} onClick={handleAssign}>
            Reasignar
          </ActionButton>
        )}
        <CustomButtonGray
          startIcon={<Options />}
          onClick={() => dispatch(setIsIsOpenFilterModal(!isOpenFilterModal))}>
          Filtrar
        </CustomButtonGray>
      </div>
    </div>
  );
}

export default FilterBar;
