import { useEffect, useState } from "react";
import moment from "moment";

import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Calendar } from "@fullcalendar/core";
import { Box, Typography } from "@material-ui/core";

import { fetchScheduledVisits } from "context/multifamilyContext/unit/unitActions";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { VisitEvent } from "context/multifamilyContext/unit/unitTypes";
import { CountryUpperCase } from "models/Countries";
import { useGetToken } from "hooks";
import { getTimezone } from "utils";
import { getMonthName } from "utils/datetime";

import {
  RootStyled,
  ButtonContainerStyled,
  DividerStyled,
  CalendarContainerStyled,
  ButtonBaseStyled,
} from "./ScheduleGridStyles";

interface Props {
  propertyId: number;
  country: CountryUpperCase;
  commune: string;
}

let calendar: Calendar;

function PropertySchedules({ propertyId, country, commune }: Props) {
  const [month, setMonth] = useState<number | null>(null);

  const {
    state: { scheduledVisits },
    dispatch,
  } = useMultifamilyUnit();

  const { getToken } = useGetToken();

  const fetchSchedules = async () => {
    const authToken = await getToken();
    await fetchScheduledVisits(propertyId, authToken, dispatch);
    scheduledVisits.forEach((item: VisitEvent, key: number) => {
      calendar.addEvent({
        title: "Visita",
        start: item.start,
        end: item.start,
        className: "",
        id: key.toString(),
      });
    });
    calendar.render();
  };

  const createCalendar = () => {
    const calendarEl = document.getElementById("calendar");
    const timezone = getTimezone(country, commune);
    moment.tz.setDefault(timezone);
    if (calendarEl != null) {
      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        timeZone: timezone,
      });
      const month = calendar && moment().month() + 1;
      setMonth(month);
      calendar.render();
    }
  };

  const handleNext = () => {
    calendar.next();
    if (month === 12) {
      setMonth(1);
    } else {
      setMonth(month + 1);
    }
  };

  const handlePrev = () => {
    calendar.prev();
    if (month === 1) {
      setMonth(12);
    } else {
      setMonth(month - 1);
    }
  };

  useEffect(() => {
    createCalendar();
    fetchSchedules();
  }, [propertyId]);

  return (
    <RootStyled>
      <ButtonContainerStyled>
        <ButtonBaseStyled onClick={handlePrev}>
          <ChevronLeft />
        </ButtonBaseStyled>
        <Typography>{getMonthName(month)}</Typography>
        <ButtonBaseStyled onClick={handleNext}>
          <ChevronRight />
        </ButtonBaseStyled>
      </ButtonContainerStyled>
      <DividerStyled />
      <CalendarContainerStyled>
        <Box id="calendar" />
      </CalendarContainerStyled>
    </RootStyled>
  );
}

export default PropertySchedules;
