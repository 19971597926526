import clx from "classnames";

import { Typography } from "@material-ui/core";

import { ControlledList, ControlledNumber } from "Components/Admin/UIComponents/ControlledForm";
import { CountryUpperCase } from "models/Countries";

import usePaymentDetailsStyles from "./PaymentDetailsStyles";
import Label from "../../../Label";

interface Props {
  typeMoney: string;
  amount: number;
  commonExpenses: number | null;
  country: CountryUpperCase;
}

const PaymentDetails = ({ typeMoney, amount, commonExpenses, country }: Props) => {
  const classes = usePaymentDetailsStyles();

  return (
    <>
      {country === "Colombia" && (
        <Label label="Multa:" labelClassName={classes.text}>
          <Typography component="span" className={clx(classes.text, classes.textDisclaimer)}>
            Ver información adjunta abajo
          </Typography>
        </Label>
      )}
      {country === "Chile" && (
        <Label label="Moneda:" labelClassName={classes.field}>
          <ControlledList
            label=""
            id="currencyCode"
            defaultValue={typeMoney ?? ""}
            options={[
              {
                value: "CLP",
                label: "CLP",
              },
              {
                value: "CLF",
                label: "CLF",
              },
            ]}
            classes={{
              select: classes.select,
            }}
            disableUnderline
          />
        </Label>
      )}
      <Label label="Monto de arriendo:" labelClassName={classes.field}>
        <ControlledNumber
          label=""
          id="amount"
          defaultValue={amount}
          wrapperPadding={false}
          textFieldProps={{
            InputProps: {
              classes: {
                root: classes.textInput,
              },
            },
          }}
        />
      </Label>
      {country !== "Chile" && (
        <Label label="Gastos comunes:" labelClassName={classes.field}>
          <ControlledNumber
            label=""
            id="commonExpenses"
            defaultValue={commonExpenses}
            wrapperPadding={false}
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.textInput,
                },
              },
            }}
          />
        </Label>
      )}
    </>
  );
};

export default PaymentDetails;
