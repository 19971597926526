import { useState, useEffect } from "react";

import { Typography } from "@material-ui/core";

import GeneralCommentsForm from "./GeneralCommentsForm";
import GeneralCommentsTimeline from "./GeneralCommentsList";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { getPropertyComments } from "context/propertyContext/propertyActions";
import { Comment, PropertyData } from "models/Property";
import { useGetToken } from "hooks";

interface Props {
  property: PropertyData;
}

function GeneralComments({ property }: Props) {
  const { getToken } = useGetToken();
  const [comments, setComments] = useState<Comment[]>([]);
  const [moreComments, setMoreComments] = useState(false);

  useEffect(() => {
    getToken().then((authToken) => {
      getPropertyComments(property?.uid, authToken).then((propertyComments) =>
        setComments(propertyComments)
      );
    });
  }, [moreComments]);

  return (
    <AdminCard>
      <Typography variant="h6" component="h3">
        Comentarios
      </Typography>
      <GeneralCommentsTimeline comments={comments} />
      <GeneralCommentsForm property={property} setMoreComments={setMoreComments} />
    </AdminCard>
  );
}

export default GeneralComments;
