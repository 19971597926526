import { makeStyles, Theme } from "@material-ui/core";

const useAppliedPromotionsStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1.5),
  },
  promotionName: {
    fontSize: theme.typography.pxToRem(16),
  },
  promotionsContainer: {
    width: "50%",
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default useAppliedPromotionsStyles;
