import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";
import useStyles from "./BasicTextSearchBarStyles";

interface Props extends StandardTextFieldProps {}

function BasicTextSearchBar({ InputProps, ...props }: Props) {
  const classes = useStyles({ icon: !!InputProps?.startAdornment });

  return (
    <TextField
      className={classes.searchBarContainer}
      id="searchQuery"
      InputProps={{
        disableUnderline: true,
        ...InputProps,
        className: classes.searchBar,
      }}
      {...props}
    />
  );
}

export default BasicTextSearchBar;
