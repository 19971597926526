import { useEffect } from "react";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import FiberManualRecordRounded from "@material-ui/icons/FiberManualRecordRounded";

import { useDashboard } from "context/controlTower/controlTowerContext";
import { useTable } from "context/tableContext/tableContext";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { filterData } from "context/controlTower/controlTowerActions";
import useTableStyles from "./TableStyles";
import FiltersForm from "./FiltersForm";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";

function AppraisersTable() {
  const { state: dashboardStates, dispatch: dashboardDispatch } = useDashboard();
  const { loadingTable, appraisers, appraisersCount, filteredAppraisers } = dashboardStates;
  const { state, dispatch } = useTable();
  const { pageSize, currentPage } = state;
  const classes = useTableStyles();

  useEffect(() => {
    filterData(dashboardDispatch, { name: "", country: "", roles: "" }, appraisers);
  }, [appraisers]);

  return (
    <div className={classes.container}>
      <FiltersForm />
      <CustomDataGrid
        paginationMode="client"
        filterMode="client"
        sortingMode="client"
        rowCount={appraisersCount}
        columns={columns}
        rows={filteredAppraisers}
        loading={loadingTable}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

export default AppraisersTable;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: true,
};

const columns: GridColumns = [
  {
    ...columnsConfig,
    field: "name",
    headerName: "Nombres",
    width: 100,
    sortable: true,
  },
  {
    ...columnsConfig,
    field: "country",
    headerName: "País",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "roles",
    headerName: "Función",
    width: 100,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "active",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: ({ value }: GridCellParams) =>
      value ? (
        <>
          <FiberManualRecordRounded fontSize="small" style={{ color: "green" }} />
          <span className="status">Trabajando</span>
        </>
      ) : (
        <>
          <FiberManualRecordRounded fontSize="small" style={{ color: "red" }} />
          <span className="status">Inactivo</span>
        </>
      ),
  },
  {
    ...columnsConfig,
    field: "id",
    headerName: "Acciones",
    width: 100,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => (
      <a href={`/control-tower/profile/${value}`} target="_blank">
        Ver perfil
      </a>
    ),
  },
];
