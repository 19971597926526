import { Grid, Typography } from "@material-ui/core";

import DynamicUploadFile from "Components/Admin/UIComponents/DynamicUploadFile";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useDocuments from "./hooks/useDocuments";

const UploadFiles = ({ contractId }: { contractId: number }) => {
  const { isLoading, data, isError } = useDocuments(contractId);

  return (
    <Grid item xs={12} md={6}>
      <AdminCard title="Cargar archivos" withSpace={false} showTitleBackground={true}>
        <Loading loading={isLoading}>
          {isError ? (
            <Typography>Error cargando archivos</Typography>
          ) : (
            <DynamicUploadFile
              documentsList={data}
              saveUrl={`contracts/${contractId}/documents/contrato-de-arriendo/upload/`}
              removeUrl={`contracts/${contractId}/documents/remove/`}
              uid={contractId as number}
            />
          )}
        </Loading>
      </AdminCard>
    </Grid>
  );
};

export default UploadFiles;
