import { makeStyles, Theme } from "@material-ui/core";

const useReservationCardStyles = makeStyles((theme: Theme) => ({
  refundChip: {
    marginLeft: theme.spacing(2),
    fontWeight: 700,
    maxHeight: 18,
    border: "1px solid #124FA8",
    color: "#124FA8",
    backgroundColor: "#D5E3F8",
  },
}));

export default useReservationCardStyles;
