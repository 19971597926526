/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Box from "@material-ui/core/Box";

interface Props {
  index: number | string;
  value: number | string;
  className?: string;
}

const TabPanel: React.FC<Props> = ({ children, value, index, ...props }) => (
  <div
    role="tabpanel"
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...props}>
    {value === index && <Box padding={3}>{children}</Box>}
  </div>
);

export default TabPanel;
