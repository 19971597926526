import { Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import {
  ControlledTextField,
  ControlledCheckbox,
  ControlledDropArea,
} from "Components/Admin/UIComponents/ControlledForm";
import { BACKEND_URL } from "env";
import PhotosGallery from "Components/Admin/administrator/multifamily/CreateMF/Components/ImageUploader/PhotosGallery";
import ControlledOptions from "Components/Admin/UIComponents/ControlledForm/ControlledOptions";
import { Typologies, UTImages } from "context/multifamilyContext/typology/typologyTypes";
import {
  closetValues,
  departmentType,
} from "Components/Admin/administrator/multifamily/utils/constants";

import useStyles from "./AmenitiesStyles";

interface Props {
  typology: Typologies;
  id: number;
  images: UTImages[];
}

function Amenities({
  typology: {
    apartment_type,
    closet,
    environments,
    has_kitchen_daily_dining_room,
    kitchen_is_furnished,
    has_laundry_room,
    laundry_room_area,
    service_rooms,
    suite_rooms,
    terrace_area,
    video360_url,
    video_url,
    layout_file_url,
  },
  id,
  images,
}: Props) {
  const classes = useStyles();
  const { watch } = useFormContext();
  const textFieldProps = (placeholder: string) => ({
    InputProps: {
      disableUnderline: true,
      classes: {
        root: classes.background,
      },
      placeholder,
    },
  });

  const terrace = watch("terrace");
  const laundry_room = watch("has_laundry_room");

  return (
    <div className={classes.container}>
      <div className={classes.formItem}>
        <ControlledDropArea
          id="layout_filename"
          label="Subir planos"
          subtitle="(Sube o arrastra la tipología)"
          defaultValue={
            layout_file_url &&
            layout_file_url !==
              "https://s3.amazonaws.com/houm-real-estate-media/layout/multifamily/"
              ? [layout_file_url]
              : undefined
          }
          labelProps={classes.titleLabel}
        />
        <Typography component="span" className={classes.subtitle}>
          Solo formato: jpeg
        </Typography>
      </div>
      <PhotosGallery
        photos={images}
        uid={id?.toString()}
        type="typology"
        url={`${BACKEND_URL}properties/v2/multifamily_unit_type`}
        post="photos/"
        delete="delete_photo/"
        extensions={[".jpg"]}
        headerName="Imágenes de la tipología"
      />
      <div className={classes.containerColumns}>
        <div className={classes.leftColumn}>
          <ControlledTextField
            label="URL video"
            id="video_url"
            defaultValue={video_url}
            textFieldProps={{ ...textFieldProps("Ingresar") }}
          />
        </div>
        <div className={classes.rightColumn}>
          <ControlledTextField
            label="URL tour 360"
            id="video360_url"
            defaultValue={video360_url}
            textFieldProps={{ ...textFieldProps("Ingresar") }}
          />
        </div>
      </div>
      <ControlledTextField
        label="Habitaciones en suite"
        id="suite_rooms"
        defaultValue={suite_rooms}
        textFieldProps={{ type: "number", ...textFieldProps(null) }}
      />
      <ControlledCheckbox
        label="Habitación de servicio"
        id="service_rooms"
        defaultChecked={service_rooms}
        labelProps={classes.label}
      />
      <ControlledCheckbox
        label="Terraza"
        id="terrace"
        defaultChecked={terrace_area > 0}
        labelProps={classes.label}
      />
      <ControlledTextField
        label=""
        id="terrace_area"
        defaultValue={terrace_area}
        disabled={!terrace}
        textFieldProps={{
          ...textFieldProps("Superficie en mts2 de la terraza"),
        }}
      />
      <ControlledCheckbox
        label="Comedor diario"
        id="has_kitchen_daily_dining_room"
        defaultChecked={has_kitchen_daily_dining_room}
        labelProps={classes.label}
      />
      <ControlledCheckbox
        label="Cocina equipada"
        id="kitchen_is_furnished"
        defaultChecked={kitchen_is_furnished}
        labelProps={classes.label}
      />
      <ControlledCheckbox
        label="Logia"
        id="has_laundry_room"
        defaultChecked={has_laundry_room}
        labelProps={classes.label}
      />
      <ControlledTextField
        label=""
        id="laundry_room_area"
        defaultValue={laundry_room_area}
        disabled={!laundry_room}
        textFieldProps={{ ...textFieldProps("Superficie en mts2 de la logia") }}
      />
      <ControlledOptions
        label="Tipo de departamento"
        id="apartment_type"
        defaultValue={apartment_type}
        options={departmentType}
      />
      <ControlledOptions label="Closet" id="closet" defaultValue={closet} options={closetValues} />
      <hr />
      <ControlledTextField
        label="Ambientes"
        id="environments"
        defaultValue={environments}
        textFieldProps={{ type: "number", ...textFieldProps(null) }}
      />
    </div>
  );
}

export default Amenities;
