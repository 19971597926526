import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import {
  ControlledDatePicker,
  ControlledList,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useDocumentTypeList, useFormNotifications, useGetToken } from "hooks";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { verifikSchema, requiredText } from "../validationSchema";
import { downloadDocument, IFormData } from "./utils";
import useVerifikStyles from "./VerifikStyles";

function Verifik() {
  const [loading, setLoading] = useState(false);
  const { documentTypeList, loading: loadingDocumentType } = useDocumentTypeList("Colombia");
  const classes = useVerifikStyles();
  const { getToken } = useGetToken();

  const methods = useForm<IFormData>({
    resolver: yupResolver(verifikSchema(documentTypeList)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    (async () => {
      setLoading(true);
      const token = await getToken();
      await downloadDocument(data, token);
      setLoading(false);
    })();
  };

  return (
    <Loading loading={loadingDocumentType}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6} className={classes.titleContent}>
          <Typography component="h1" className={classes.title}>
            Valida con Verifik
          </Typography>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.formContent}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <ControlledList
                    disableUnderline
                    label="Tipo de documento"
                    id="documentType"
                    options={documentTypeList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField
                    label="Documento"
                    id="document"
                    textFieldProps={{ placeholder: "Ingresar" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledDatePicker
                    type="date"
                    id="date"
                    label="Fecha de Emision Certificado Registraduria (Opcional)"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.ctaContent}>
                    <DesignSystemButton
                      label="Obtener Reporte"
                      variant="primary"
                      size="medium"
                      isLoading={loading}
                      type="submit"
                      disabled={loading}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Loading>
  );
}

export default Verifik;
