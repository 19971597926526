import { useCallback } from "react";
import Autosuggest from "react-autosuggest";

import { IoSearchOutline } from "@houm-com/ui-icons";
import Spinner from "@houm-com/ui/Spinner";

import { Locale } from "models/Countries";
import usePropertySearchStyles from "./PropertySearchStyles";
import { ISuggestedProperty } from "../../utils/types";
import useSuggestion from "../../hooks/useSuggestion";
import Suggestion from "./components/Suggestion";

interface Props {
  onSelectProperty: (prop: ISuggestedProperty) => void;
  country: Locale;
}

const PropertySearch = ({ onSelectProperty, country }: Props) => {
  const classes = usePropertySearchStyles();

  const {
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
    searchValue,
    getSuggestionValue,
    isLoading,
  } = useSuggestion(onSelectProperty, country);

  const renderSuggestion = useCallback(
    (suggestion: ISuggestedProperty) => <Suggestion suggestion={suggestion} value={searchValue} />,
    [searchValue]
  );

  const renderInputComponent = (inputPropss) => (
    <div className={classes.inputContainer}>
      <IoSearchOutline className={classes.searchIcon2} />
      <input {...inputPropss} />
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          theme={{
            input: classes.textField,
            container: classes.textFieldContainer,
          }}
        />
      </div>
      {isLoading && (
        <Spinner classNameContainer="my-1 flex justify-center" variant="primary" size="xl" />
      )}
    </div>
  );
};

export default PropertySearch;
