import { useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";
import subscriptionService from "services/subscriptionService";
import parseSubscriptionTableInfo from "../utils/parsers";

interface ISubscription {
  landlord: string;
  tenant: string;
  id: string;
  createDate: string;
}

const useSubscriptions = (propertyId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    error,
    data = [],
  }: UseQueryResult<ISubscription[], Error> = useQuery<ISubscription[], Error>(
    ["subscriptionsByPropertyId", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await subscriptionService.getAllByPropertyId(propertyId, authToken, {
        all: true,
      });
      const filterResponse = response.data.filter((subs) => subs.legacy_id);
      return parseSubscriptionTableInfo(filterResponse);
    },
    {
      enabled: !!propertyId,
      refetchOnMount: true,
    }
  );

  return {
    isLoading,
    error,
    data,
  };
};

export default useSubscriptions;
