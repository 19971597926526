import { makeStyles } from "@material-ui/core";

const useNewsStyles = makeStyles((theme) => ({
  textArea: {
    padding: theme.spacing(1, 1.5),
    border: "1px solid",
    borderColor: theme.palette.grey["200"],
    borderRadius: 10,
  },
  textAreaLabel: {
    marginBottom: theme.spacing(1),
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(1),
  },
  textButton: {
    textTransform: "none",
    borderRadius: 20,
  },
  saveButton: {
    textTransform: "none",
    fontWeight: 700,
    borderRadius: 20,
  },
}));

export default useNewsStyles;
