import ModalDialog from "@houm-com/ui/ModalDialog";

import { Evaluation } from "domains/evaluations/models/Evaluations";
import InitContract from "./components/InitContract";

interface Props {
  open: boolean;
  handleOpenModal: () => void;
  evaluation: Evaluation;
}

const ContractForm = ({ open, handleOpenModal, evaluation }: Props) => (
  <ModalDialog title="Generar contrato" isOpen={open} onClose={handleOpenModal}>
    <InitContract evaluation={evaluation} />
  </ModalDialog>
);

export default ContractForm;
