import ErrorScreen from "domains/common/components/ErrorScreen";
import { useRouteMatch } from "react-router-dom";

export default function Unauthorized() {
  const { path, url } = useRouteMatch();
  return (
    <ErrorScreen
      title="Acceso restringido."
      description="Lo sentimos, no tienes permisos para acceder a esta página."
      jsonErrorData={{ path, url }}
    />
  );
}
