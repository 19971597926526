import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(5, 0),
  },
  title: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(24),
  },
  date: {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(0, 2),
    fontWeight: 500,
  },
}));
export default useHeaderStyles;
