import { useCallback, useEffect, useState } from "react";

import ModalDialog from "@houm-com/ui/ModalDialog";

import { UserResponse } from "models/User";
import { Locale } from "models/Countries";
import { IPlan } from "models/Plan";

import CreatePaymentOrdersForm from "./components/CreatePaymentOrdersForm";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  landlordContract: UserResponse;
  tenantContract: UserResponse;
  propertyId: number;
  subscriptionId: number;
  country: Locale;
  plan: IPlan;
  contractId: number;
}

type FormType = "lessee" | "lessor";

function CreatePaymentOrdersModal({
  open,
  handleOnClose,
  landlordContract,
  tenantContract,
  propertyId,
  subscriptionId,
  country,
  plan,
  contractId,
}: Props) {
  const [formType, setFormType] = useState<FormType>("lessee");
  const [title, setTitle] = useState("Cobro a arrendatario");
  const [subTitle, setSubTitle] = useState("Ingresa la información del cobro");

  const changeFormType = useCallback((type: FormType) => {
    setFormType(type);
  }, []);

  useEffect(() => {
    if (formType === "lessor") {
      setTitle("Pago a propietario");
      setSubTitle("Completa los campos en caso de ser necesario");
    }
  }, [formType]);

  const closeModal = useCallback(() => {
    handleOnClose();
    setFormType("lessee");
    setTitle("Cobro a arrendatario");
    setSubTitle("Ingresa la información del cobro");
  }, []);

  return (
    <ModalDialog isOpen={open} subtitle={subTitle} title={title} onClose={closeModal} hasCloseBtn>
      <CreatePaymentOrdersForm
        onClose={closeModal}
        formType={formType}
        changeFormType={changeFormType}
        landlordContract={landlordContract}
        tenantContract={tenantContract}
        propertyId={propertyId}
        subscriptionId={subscriptionId}
        country={country}
        plan={plan}
        contractId={contractId}
      />
    </ModalDialog>
  );
}

export default CreatePaymentOrdersModal;
