import { ReactElement } from "react";

import { Box, Grid } from "@material-ui/core";

import { ModalText } from "../../../../../../typography";
import Row from "../Row";
import useItemStyles from "./ItemStyles";

interface Props {
  name: string;
  value: string;
  icon: ReactElement;
}

function Item({ name, value, icon }: Props) {
  const classes = useItemStyles();

  return (
    <Row container justifyContent="space-between">
      <Grid item className={classes.nameWrapper}>
        <Box className={classes.iconWrapper}>{icon}</Box>
        <ModalText>{name}</ModalText>
      </Grid>
      <Grid item>
        <ModalText>{value}</ModalText>
      </Grid>
    </Row>
  );
}

export default Item;
