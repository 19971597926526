import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserFormType } from "models/User";
import validationSchema from "./validationSchema";
import CompanyFields from "./CompanyFields";
import Button from "@houm-com/ui/Button";

interface Props {
  onSubmit?: (e: any) => void;
  disabled?: boolean;
}

function UserForm({ onSubmit, disabled = false }: Props) {
  const methods = useForm<UserFormType>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const onSubmitHandler: SubmitHandler<UserFormType> = (data, e) => {
    onSubmit?.(data);
  };

  return (
    <div className="max-w-screen-sm">
      <FormProvider {...methods} key="userProvider">
        <ControlledSelection
          id="country"
          label="País"
          options={[
            { value: "Chile", label: "Chile" },
            { value: "Colombia", label: "Colombia" },
            { value: "Mexico", label: "México" },
          ]}
        />
        <ControlledTextField id="name" label="Nombre" />
        <ControlledTextField id="last_name" label="Apellido" />
        <ControlledTextField id="email" label="Email" />
        <ControlledTextField id="phone_number" label="Teléfono" />
        <ControlledSelection
          id="role"
          label="Rol del usuario"
          options={[
            { value: "propietary", label: "Propietario" },
            { value: "property_broker", label: "Corredor" },
          ]}
        />
        <CompanyFields />
        <div className="flex flex-row-reverse mt-3">
          <Button
            id="create-proprietary"
            variant="primary"
            size="md"
            onClick={methods.handleSubmit(onSubmitHandler)}
            disabled={disabled}>
            Crear Propietario
          </Button>
        </div>
      </FormProvider>
    </div>
  );
}

export default UserForm;
