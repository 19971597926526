import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  disabled?: boolean;
  compressed?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  largeIcon: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  nextButton: {
    fontSize: "1rem",
    minWidth: 185,
    minHeight: 55,
    fontWeight: 600,
    borderRadius: 10,
    padding: theme.spacing(0, 3),
    color: (props) => (!props.disabled ? theme.palette.primary.contrastText : "white"),
    backgroundColor: (props) =>
      !props.disabled ? theme.palette.primary.main : theme.palette.grey[200],
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 48,
      borderRadius: 100,
    },
  },
  backButton: {
    minWidth: 185,
    fontSize: "1rem",
    minHeight: 55,
    backgroundColor: "white",
    fontWeight: 600,
    transition: "0.3s",
    borderRadius: 10,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      minHeight: 48,
      borderRadius: 100,
    },
  },
  //a functional button  with disabled color
  disabledButton: {
    minWidth: 211,
    minHeight: 55,
    fontSize: "1rem",
    color: "white",
    backgroundColor: theme.palette.grey[500],
    fontWeight: 600,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 48,
      borderRadius: 100,
    },
  },
  childrenBackButton: {
    color: theme.palette.grey[900],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    textTransform: "none",
  },
}));

export default useStyles;
