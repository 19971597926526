import * as yup from "yup";

export const validationSchema = yup.object({
  report_date: yup
    .string()
    .nullable()
    .optional()
    .transform((value) => (value === "" ? null : value))
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato: dd/mm/aaaa"),
  case_number: yup
    .number()
    .nullable()
    .optional()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .typeError("Debe ser un número")
    .positive("No puede ser negativo"),
});
