import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import ControlPanel from "./components/ControlPanel";
import Document from "./components/Document";

import useDeleteFile from "domains/evaluations/hooks/useDeleteFile";
import { Document as IDocument } from "domains/evaluations/models/Document";

interface Props {
  document: IDocument | null;
  evaluationMemberId: string;
}

const DocumentViewer = ({ document, evaluationMemberId }: Props) => {
  const { handleOpenModal: onHandleToggleModal, open } = useModal();
  const { mutate, isLoading } = useDeleteFile({ onHandleSuccess: onHandleToggleModal });
  const { country } = useParams<{
    country: Locale;
  }>();

  const [pdfNumPages, setPdfNumPages] = useState(null);
  const [pdfPage, setPdfPage] = useState(1);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    setPdfPage(1);
    setFileError(false);
  }, [document.id]);

  const onSetPdfNumberPages = (numPages: number) => setPdfNumPages(numPages);
  const onSetFileErrorFlag = () => setFileError(true);
  const onChangePage = (n: number) => setPdfPage(n);

  return (
    <>
      <ControlPanel
        document={document}
        handleOpenModal={onHandleToggleModal}
        onChangePage={onChangePage}
        pdfPage={pdfPage}
        fileError={fileError}
        pdfNumPages={pdfNumPages}
      />
      <Document
        document={document}
        onSetFileErrorFlag={onSetFileErrorFlag}
        onSetPdfNumberPages={onSetPdfNumberPages}
        pdfPage={pdfPage}
      />
      <ConfirmDialog
        title="¿Está seguro que quiere eliminar este archivo?"
        subtitle={`Una vez eliminado el archivo ${document.filename} no podrá recuperarlo.`}
        submitButtonLabel="Eliminar"
        cancelButtonLabel="Cancelar"
        isOpen={open}
        onSubmit={() =>
          mutate({
            evaluationId: evaluationMemberId,
            documentId: document.id,
            country,
          })
        }
        onClose={onHandleToggleModal}
        submitButtonProps={{
          isLoading,
          disabled: isLoading,
        }}
      />
    </>
  );
};

export default DocumentViewer;
