import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useTenantsCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      boxShadow: "none",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      minWidth: 293,
      height: "auto",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    textLabel: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      marginBottom: theme.spacing(1.4),
    },
    divider: {
      width: "95%",
      margin: theme.spacing(1),
    },
    text: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
    },
    textLink: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      textDecoration: "underline",
    },
    sendProfileButton: {
      marginTop: theme.spacing(3),
    },
    chipApproved: {
      color: theme.palette.success.main,
      background: "#E9FFDA",
      borderColor: theme.palette.success.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    chipRejected: {
      color: "#EA1601",
      borderColor: "#EA1601",
      background: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    chipReevaluation: {
      color: "#A07800",
      borderColor: "#A07800",
      background: "#FDF9E6",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    chipSend: {
      color: "#2C73DB",
      borderColor: "#2C73DB",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
    },
    loading: {
      height: "auto",
    },
  })
);

export default useTenantsCardStyles;
