import { Box } from "@material-ui/core";

import { IRequestForm } from "Components/Admin/administrator/subscriptions/hooks/requests/types";
import useRepairSelectionStyles from "./RepairSelectionStyles";
import RepairForm from "./RepairForm";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

interface Props {
  handleNext: () => void;
  handleClose: () => void;
  setRepairForm: (value: IRequestForm) => void;
  repairForm: IRequestForm;
}

const RepairSelection = ({ handleNext, setRepairForm, repairForm, handleClose }: Props) => {
  const classes = useRepairSelectionStyles();
  const { repairType, requestorRole, observation, photos } = repairForm;
  const isNextBntDisabled = !repairType || !requestorRole || !observation || photos?.length === 0;
  return (
    <Box className={classes.container}>
      <RepairForm setRequestForm={setRepairForm} requestForm={repairForm} />
      <Box className={classes.ctaContainer}>
        <Box className={classes.spacer}>
          <DesignSystemButton
            variant="tertiaryB"
            size="small"
            label="Cancelar"
            onClick={handleClose}
          />
          <DesignSystemButton
            disabled={isNextBntDisabled}
            variant="primary"
            size="small"
            label="Siguiente"
            onClick={handleNext}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RepairSelection;
