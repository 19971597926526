import { Grid, Typography } from "@material-ui/core";

import { beginHours, endHours } from "../AvailabilityConsts";
import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import usePropertyInformationStyles from "./usePropertyInformationStyles";
import { useAvailability } from "context/availabilityContext/availabilityContext";

interface Props {
  day: string;
  index: number;
}

function DailySchedule({ day, index }: Props) {
  const classes = usePropertyInformationStyles();
  const { state } = useAvailability();

  const daySchedule = state.propertyAvailability.filter((item) => item.day === index);

  const isHourChecked = (daySchedule, currentDate) => {
    const isHourPresent = daySchedule.filter((item) => item.beginHour === currentDate);
    return isHourPresent.length > 0;
  };

  return (
    <Grid className={classes.daily} container justifyContent="center">
      <Typography className={classes.title}> {day}</Typography>
      {beginHours.map((item, i) => (
        <ControlledCheckbox
          label={`${beginHours[i]} ${endHours[i]}`}
          id={`${index}_${beginHours[i]}_${endHours[i]}`}
          placement="end"
          justifyContent="center"
          defaultChecked={isHourChecked(daySchedule, beginHours[i])}
          size="small"
        />
      ))}
    </Grid>
  );
}

export default DailySchedule;
