import Swal from "sweetalert2";

import { CONTRACT_ONLINE_PROCESS_STATUS } from "Components/Admin/administrator/contracts/constants";
import contractService from "services/contractService";
import { ContractModel } from "models/Contract";

export interface ILinks {
  contract: string;
  mandate: string;
}

export const changeStatusCreatePaymentButton = async (
  contract: ContractModel,
  authToken: string
) => {
  const newStatusContract = contract;
  newStatusContract.online_process_status = CONTRACT_ONLINE_PROCESS_STATUS["finalized"];
  try {
    newStatusContract.status = "Generar boton de pago";
    await contractService.update(newStatusContract.id, newStatusContract, authToken);
    Swal.fire({
      type: "success",
      title: "Contracto actualizado",
      text: `Enviado correctamente a botón de pago`,
    }).then(() => window.location.reload());
  } catch (e) {
    Swal.fire({
      type: "error",
      text: `Ha ocurrido un error al finalizar el contrato, por favor intenta nuevamente, error: ${e}`,
    });
  }
};
