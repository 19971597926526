import { LandlordVipProvider } from "context/landlordVipContext/landlordVipContext";
import { TableProvider } from "context/tableContext/tableContext";
import LandlordVip from "./LandlordVip";

const index = () => (
  <LandlordVipProvider>
    <TableProvider>
      <LandlordVip />
    </TableProvider>
  </LandlordVipProvider>
);

export default index;
