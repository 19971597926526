import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles<Theme>((theme) => ({
  buttonTypes: {
    borderRadius: 100,
    fontSize: theme.typography.pxToRem(14),
    minWidth: 150,
    height: 32,
  },
}));

export default useHeaderStyles;
