import { UseQueryResult, useQuery } from "react-query";

import documentTypeService from "services/documentTypeService";
import { CountryUpperCase } from "models/Countries";
import { DocumentType } from "models/Document";
import useGetToken from "hooks/useGetToken";

type DocumentTypes = {
  value: number;
  label: string;
  regex: string;
  identifier: string;
};

const useGetDocumentTypes = (country: CountryUpperCase) => {
  const { getToken } = useGetToken();
  const {
    data = [],
    isLoading,
    isError,
  }: UseQueryResult<DocumentTypes[], Error> = useQuery<DocumentTypes[], Error>(
    ["documentTypes"],
    async () => {
      const authToken = await getToken();
      const response = await documentTypeService.getList(country, authToken);
      const docTypes: DocumentType[] = response.data.results;
      const parseDocumentsType = docTypes.map((h: DocumentType) => ({
        value: h.id,
        label: h.type,
        regex: h.validation_regex,
        identifier: h.identifier,
      }));
      return parseDocumentsType;
    },
    {
      enabled: !!country,
    }
  );
  return { documentTypeList: data, loading: isLoading, isError };
};

export default useGetDocumentTypes;
