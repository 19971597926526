import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Grid from "@material-ui/core/Grid";
import { InputAdornment, Typography } from "@material-ui/core";

import useDiscountReasons from "Components/Admin/administrator/subscriptions/components/ExitProcess/hooks/useDiscountReasons";
import useCreateDiscount from "Components/Admin/administrator/subscriptions/components/ExitProcess/hooks/useCreateDiscount";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import {
  ControlledList,
  ControlledNumber,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";
import { Locale } from "models/Countries";
import { IWarrantyStatus } from "models/Warranty";

import { movementPlanSchema, requiredText } from "./utils/validationSchema";
import useDiscountFormStyles from "./DiscountFormStyles";
import ModalButtons from "../../../../../ModalButtons";
import { AdjustmentFormProps } from "./utils/types";

interface Props {
  onHandleClose: () => void;
  contractId: number;
  country: Locale;
  status: IWarrantyStatus;
}

function DiscountForm({ onHandleClose, contractId, country, status }: Props) {
  const classes = useDiscountFormStyles({ isNegative: status === "NEGATIVE" });
  const { mutate, isLoading } = useCreateDiscount(contractId, country, onHandleClose);
  const { data, isError, isLoading: isLoadingReasons } = useDiscountReasons(country);

  const methods = useForm<AdjustmentFormProps>({
    resolver: yupResolver(movementPlanSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<AdjustmentFormProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<AdjustmentFormProps> = (data) => {
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form className={classes.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid item container alignItems="flex-start" spacing={1}>
          <Grid item xs={12}>
            <ControlledNumber
              label="Cantidad"
              id="amount"
              defaultValue=""
              textFieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: {
                    root: classes.textInput,
                  },
                },
                type: "number",
              }}
            />
            {status === "NEGATIVE" && (
              <Typography className={classes.errorMessage}>
                Este descuento sobrepasa el saldo de garantía disponible
              </Typography>
            )}
          </Grid>
          <Loading loading={isLoadingReasons}>
            <Grid item xs={12}>
              {isError && (
                <Typography>Hubo un error no pudimos consultar motivos de descuento</Typography>
              )}
              {data && (
                <ControlledList
                  label="Motivo de descuento"
                  id="reason"
                  defaultValue=""
                  disableUnderline
                  options={data?.map((reason) => ({
                    value: reason.value,
                    label: reason.label,
                  }))}
                  classes={{
                    select: classes.select,
                  }}
                />
              )}
            </Grid>
          </Loading>
          <Grid item xs={12}>
            <ControlledTextField
              label="Observaciones"
              id="description"
              defaultValue=""
              textFieldProps={{
                placeholder: "Escribir comentario",
                InputProps: {
                  classes: {
                    root: classes.descriptionInput,
                  },
                },
                multiline: true,
              }}
            />
          </Grid>
          <ModalButtons isLoading={isLoading} onHandleClose={() => onHandleClose()} />
        </Grid>
      </form>
    </FormProvider>
  );
}

export default DiscountForm;
