import { Grid } from "@material-ui/core";

import TitlePromotion from "Components/Admin/administrator/promotions/components/TitlePromotion";
import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import { CountryUpperCase } from "models/Countries";

interface Props {
  handleCountry: (status: CountryUpperCase) => void;
  country: CountryUpperCase;
}

const HeaderPromotion = ({ country, handleCountry }: Props) => (
  <Grid container item xs={12} alignItems="center" justifyContent="space-between">
    <TitlePromotion title="Promociones" />
    <CountrySelector
      stateCountry={country}
      onChange={(country: CountryUpperCase) => handleCountry(country)}
    />
  </Grid>
);

export default HeaderPromotion;
