import currency from "@houm-com/formats/currency";
import { capitalize } from "lodash";

import { GridSize } from "@material-ui/core";

import { documentMexicoById } from "utils/countryDocuments";
import { countryCode, countryDocuments, numberWithCommas } from "utils";
import { currencyParse } from "utils/countryCurrency";
import { CountryUpperCase, Locale } from "models/Countries";
import { EvaluationModel } from "models/Evaluation";
import { PropertyData } from "models/Property";
import {
  ContractModel,
  ILegalRepresentative,
  IPerson,
  ISimulationContractDetails,
} from "models/Contract";

interface RenderData {
  size: GridSize;
  label: string;
  text: string | number;
  notShow?: string;
}

export const getEvaluation = (evaluation: EvaluationModel[], profile: IPerson): EvaluationModel =>
  evaluation.filter((ev) => ev.id === profile.evaluationId)[0];

export function renderDataTenant(
  evaluation: EvaluationModel[],
  profile: IPerson,
  country: CountryUpperCase
): RenderData[] {
  const details = {
    phone: null,
    email: null,
    address: null,
  };
  if (country !== "Colombia" && evaluation.length && evaluation) {
    const evaluationDetail = getEvaluation(evaluation, profile);
    details.phone = evaluationDetail?.user?.phone;
    details.email = evaluationDetail?.user?.email;
    details.address = profile?.address ?? evaluationDetail?.user?.address;
  }

  return [
    {
      size: 3,
      label: "Nombre completo",
      text: profile?.name || "-",
    },
    {
      size: 2,
      label:
        country === "Mexico"
          ? documentMexicoById[profile.documentType] || profile.document_type.type
          : countryDocuments[country].label,
      text: profile?.document || "-",
    },
    {
      size: 2,
      label: "Nacionalidad",
      text: profile?.nationality || "-",
      notShow: "Colombia",
    },
    {
      size: 2,
      label: "Teléfono",
      text: details.phone || profile?.phone || "-",
    },
    {
      size: 3,
      label: "Correo electrónico",
      text: details.email || profile?.email || "-",
    },
    {
      size: 3,
      label: "Domicilio",
      text: details.address || profile?.address || "-",
    },
    {
      size: 3,
      label: "Ocupación",
      text: profile?.occupation || "-",
      notShow: "Colombia",
    },
    {
      size: 3,
      label: "Empleador",
      text: profile?.employer || "-",
      notShow: "Colombia",
    },
    {
      size: 3,
      label: "Liquidaciones promedio",
      text: `$ ${numberWithCommas(profile?.salaryAverage)}` || "-",
      notShow: "Colombia",
    },
  ];
}

export function renderDataContract(contract: ContractModel): RenderData[] {
  return [
    {
      size: 6,
      label: "¿Cuántos vivirán en la propiedad?",
      text: contract.habitants_description,
    },
    {
      size: 6,
      label: "Fecha firma contrato",
      text: contract.signature_date,
    },
    {
      size: 6,
      label: "Documento de comprobante de pago",
      text: contract.billing_document,
    },
    {
      size: 6,
      label: "Fecha inicio de contrato propuesto",
      text: contract.init_date_proposal,
    },
    {
      size: 6,
      label: "Fecha de entrega propiedad",
      text: contract.property_delivery_date,
    },
    {
      size: 6,
      label: "Canon de arriendo",
      text: contract?.amount
        ? currency(
            contract?.amount,
            currencyParse[countryCode(contract?.property?.country)][contract?.type_money]
          )
        : "-",
    },
    {
      size: 6 as GridSize,
      label: "Limpieza administrada por Houm?",
      text: getCleaningTextValue(contract?.has_houm_cleaning),
    },
    ...(contract?.property?.country !== "Chile"
      ? [
          {
            size: 6 as GridSize,
            label: "Gastos comunes",
            text: contract?.common_expenses
              ? currency(
                  contract?.common_expenses,
                  currencyParse[countryCode(contract?.property?.country)][contract?.type_money]
                )
              : "-",
          },
        ]
      : []),
  ];
}

export function renderDataUsers(
  person: IPerson | ILegalRepresentative,
  country: CountryUpperCase
): RenderData[] {
  return [
    {
      size: 6,
      label: "Nombre completo",
      text: person.name,
    },
    {
      size: 6,
      label: countryDocuments[country].label,
      text: person.document,
    },
    {
      size: 6,
      label: "Correo electrónico",
      text: person.email,
    },
    {
      size: 6,
      label: "Teléfono",
      text: person.phone,
    },
    {
      size: 6,
      label: "Domicilio",
      text: person.address,
    },
  ];
}

export const renderHeader = (status: string): string => {
  switch (status) {
    case "Aprobacion propietario,Perfil enviado":
    case "Perfil enviado":
      return "Por aprobar";
    case "Aprobado por propietario":
      return "Aprobado";
    case "Rechazado":
      return "Rechazado";
    case "Listo para redaccion":
      return "Listos para generar";
    case "Generar boton de pago":
      return "Botón de pago";
    default:
      return status;
  }
};

export const getBrokerage = (
  evaluation: EvaluationModel[],
  property: PropertyData
): boolean | null => {
  if (property.country === "Colombia" || (evaluation && evaluation.length === 0)) {
    return true;
  }
  return evaluation && evaluation[0] ? evaluation[0]?.brokerage : false;
};

export const getBrokerageTransfer = (contract: ContractModel, brokerage: boolean) => {
  if (contract.is_transfer) {
    return false;
  } else if (contract.property.country === "Chile") {
    return brokerage;
  }
  return true;
};

export const parseQuotationData = (
  contract: ContractModel,
  evaluation: EvaluationModel[],
  brokerage: boolean
) => ({
  init_date: contract?.init_date,
  first_payment_date: contract?.first_payment_date,
  applicant_id: contract?.rent_application
    ? contract?.rent_application?.applicant?.id
    : evaluation[0]?.related_applicant_id,
  brokerage: getBrokerageTransfer(contract, brokerage),
  mostrador: contract?.houmer?.id ? contract?.houmer?.id : null,
  contract_id: contract?.id,
  quotation_id: contract?.quotation ? contract?.quotation?.id : null,
  discount_code: null,
  discount: 0,
  pay_day: contract?.pay_day,
  aval: null,
  plan_id: contract?.plan?.id,
  readjust: contract?.readjust,
  automatic_activation: contract?.is_transfer ? contract?.is_transfer : null,
});

export const checkStatus = (statusArray: string[], status: string): boolean =>
  statusArray.includes(status);

export const steps = (contract: ContractModel) => [
  {
    label: "Cierres",
  },
  {
    label: "Contratos",
  },
  {
    label: renderHeader(contract.status),
  },
  {
    label: `ID ${contract.id}`,
  },
];

export const renderBackStep = {
  "Generar boton de pago": {
    label: "Listo para generar",
    status: "Listo para redaccion",
  },
  "Listo para redaccion": {
    label: "Aprobados",
    status: "Aprobado por propietario",
  },
  "Boton de pago enviado": {
    label: "Listo para generar",
    status: "Listo para redaccion",
  },
};

export const renderMoneyType = (moneyType: string, country: Locale): string =>
  currencyParse[country][moneyType];

export const getPaymentSimulationDetails = (
  details: ISimulationContractDetails,
  country: CountryUpperCase,
  type: string
) => {
  const parseDetails: {
    description: string;
    amount: number;
    type: string;
    currency: string;
  }[] = [];

  parseDetails.push(
    ...tenantLandlordBreakdown(details, country),
    ...(type === "lessor" ? landlordBreakdown(details, country) : []),
    ...(type === "lessee" ? tenantBreakdown(details, country) : []),
    {
      description: "TOTAL A PAGAR",
      amount: type === "lessee" ? details.total_payment_lessee : details.total_payment_lessor,
      type: "total",
      currency: details.currency,
    }
  );

  return parseDetails;
};

const tenantLandlordBreakdown = (
  details: ISimulationContractDetails,
  country: CountryUpperCase
) => [
  {
    description: "Días proporcionales",
    amount: details.proportional_days_payment,
    type: "positive",
    currency: details.currency,
  },
  ...(country !== "Chile"
    ? [
        {
          description: "Mes de adelanto",
          amount: details.advancement_pay,
          type: "positive",
          currency: details.currency,
        },
      ]
    : []),
  {
    description:
      country !== "Chile"
        ? "Monto proporcional por tarifa de administración"
        : "Monto proporcional gastos comunes",
    amount: details.proportional_common_expenses,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Adelanto gastos comunes",
    amount: details.advancement_common_expenses,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Gastos comunes",
    amount: details?.common_expenses,
    type: "positive",
    currency: details.currency,
  },
];

const tenantBreakdown = (details: ISimulationContractDetails, country: CountryUpperCase) => [
  {
    description: "Corretaje",
    amount: details.lessee_brokerage,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Descuento de corretaje",
    amount: details.lessee_brokerage_discount,
    type: "negative",
    currency: details.currency,
  },
  {
    description: "Reserva",
    amount: details.reservation_total,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Reserva pagada",
    amount:
      details?.paid_reservations && details?.paid_reservations.length > 0
        ? parseFloat(details.paid_reservations[0].toFixed(4))
        : 0,
    type: "negative",
    currency: details.currency,
  },
  ...(details.discounts_breakdown_lessee
    ? details.discounts_breakdown_lessee.map((discount) => ({
        description: capitalize(discount.description),
        amount: discount.value,
        type: "negative",
        currency: details.currency,
      }))
    : []),
  ...(details?.additional_breakdown_lessee
    ? details.additional_breakdown_lessee.map((additional) => ({
        description: capitalize(additional.description),
        amount: additional.value,
        type: "positive",
        currency: details.currency,
      }))
    : []),
  ...(details?.lessee_promotions
    ? details.lessee_promotions.map((promotion) => ({
        description: `Promoción: ${capitalize(promotion.description)}`,
        amount: Math.abs(promotion.value),
        type: promotion.value > 0 ? "positive" : "negative",
        currency: details.currency,
      }))
    : []),
  ...(country === "Chile"
    ? [
        {
          description: "Primera mensualidad arrendatario",
          amount: details.lease_lessee,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Mes de adelanto",
          amount: details.advancement_pay_lessee,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Garantía",
          amount: details.warranty,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Cobro operacional",
          amount: details.lessee_operational_charge,
          type: "positive",
          currency: details.currency,
        },
      ]
    : []),
  ...(country === "Colombia"
    ? [
        {
          description: "Cargos notariales",
          amount: details.notarial_charge,
          type: "positive",
          currency: details.currency,
        },
      ]
    : []),
  ...(country === "Mexico"
    ? [
        {
          description: "Cobro de póliza jurídica",
          amount: details.insurance,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Mes de arriendo",
          amount: details.lease,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Garantía",
          amount: details.warranty,
          type: "positive",
          currency: details.currency,
        },
      ]
    : []),
];

const landlordBreakdown = (details: ISimulationContractDetails, country: CountryUpperCase) => [
  {
    description: "Corretaje",
    amount: details.lessor_brokerage,
    type: "negative",
    currency: details.currency,
  },
  {
    description: "Descuento de corretaje",
    amount: details.lessor_brokerage_discount,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Adelanto de administración",
    amount: details.advancement_management,
    type: "negative",
    currency: details.currency,
  },
  {
    description: "Descuento adelantos de administración",
    amount: details.advancement_management_discount,
    type: "positive",
    currency: details.currency,
  },
  {
    description: "Días proporcionales de administración",
    amount: details.proportional_days_management,
    type: "negative",
    currency: details.currency,
  },
  {
    description: "Descuento días proporcionales de administración",
    amount: details.proportional_days_management_discount,
    type: "positive",
    currency: details.currency,
  },
  ...(details.discounts_breakdown_lessor
    ? details.discounts_breakdown_lessor.map((discount) => ({
        description: capitalize(discount.description),
        amount: discount.value,
        type: "negative",
        currency: details.currency,
      }))
    : []),
  ...(details?.additional_breakdown_lessor
    ? details.additional_breakdown_lessor.map((additional) => ({
        description: capitalize(additional.description),
        amount: additional.value,
        type: "positive",
        currency: details.currency,
      }))
    : []),
  ...(details?.lessor_promotions
    ? details.lessor_promotions.map((promotion) => ({
        description: `Promoción: ${capitalize(promotion.description)}`,
        amount: Math.abs(promotion.value),
        type: promotion.value > 0 ? "positive" : "negative",
        currency: details.currency,
      }))
    : []),
  ...(country === "Mexico"
    ? [
        {
          description: "Mes de arriendo",
          amount: details.lease,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Tarifa de administración",
          amount: details.management,
          type: "negative",
          currency: details.currency,
        },
        ...(details.warranty_retainer !== "HOUM"
          ? [
              {
                description: "Garantía",
                amount: details.warranty,
                type: "positive",
                currency: details.currency,
              },
            ]
          : []),
      ]
    : []),

  ...(country === "Chile"
    ? [
        {
          description: "Primera mensualidad propietario",
          amount: details.lease_lessor,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Administración primera mensualidad",
          amount: details.lease_management,
          type: "negative",
          currency: details.currency,
        },
        {
          description: "Descuentos en la administración de la primera mensualidad",
          amount: details.lease_management_discount,
          type: "positive",
          currency: details.currency,
        },
        {
          description: "Cobro operacional",
          amount: details.lessor_operational_charge,
          type: "negative",
          currency: details.currency,
        },
        ...(details.warranty_retainer === "LESSOR"
          ? [
              {
                description: "Garantía",
                amount: details.warranty,
                type: "positive",
                currency: details.currency,
              },
            ]
          : []),
        ...(details.plan_type === "NORMAL"
          ? [
              {
                description: "Mes de adelanto",
                amount: details.advancement_pay,
                type: "positive",
                currency: details.currency,
              },
            ]
          : []),
        ...(details.plan_type === "GUARANTEED"
          ? [
              {
                description: "Adelanto de administración",
                amount: details.advancement_management,
                type: "negative",
                currency: details.currency,
              },
              {
                description: "Descuento adelantos de administración",
                amount: details.advancement_management_discount,
                type: "positive",
                currency: details.currency,
              },
            ]
          : []),
      ]
    : []),
];

const getCleaningTextValue = (hasHoumCleaining: boolean | null | undefined) => {
  if (hasHoumCleaining == null) {
    return "-";
  }
  return hasHoumCleaining ? "Sí" : "No";
};
