declare global {
  interface Window {
    dataLayer?: any[];
  }
}

interface initializeProps {
  analyticsId: string;
}

export default {
  init: ({ analyticsId }: initializeProps) => {
    // this is not necesary when setting up optimize via googletagmanager/tag/js
    // const optimizeScript = document.createElement("script");
    // optimizeScript.src = `https://www.googleoptimize.com/optimize.js?id=${optimizeId}`;
    // document.head.appendChild(optimizeScript);

    const analyticsScript = document.createElement("script");
    analyticsScript.async = true;
    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    document.head.appendChild(analyticsScript);

    const analyticsConfig = document.createElement("script");
    analyticsConfig.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${analyticsId}');`;
    document.head.appendChild(analyticsConfig);
  },
};
