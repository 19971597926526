import { useMutation, useQueryClient } from "react-query";

import { useGetToken } from "hooks";
import { ContractLandlordProps } from "../../utils/types";
import propertyService from "services/propertyService";
import { parseBackendLandlord } from "./parsers";

const useSubmitLandlordInfo = (propertyId: number, setEditMode: (mode: boolean) => void) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    async (data: ContractLandlordProps) => {
      const authToken = await getToken();
      return propertyService.updateContractPropertyInfo(
        propertyId,
        parseBackendLandlord(data),
        authToken
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contractInfo");
        queryClient.invalidateQueries("contractPropertyInfo");
        setEditMode(false);
      },
    }
  );

  return {
    mutate,
    submitting: isLoading,
    submittingError: isError,
  };
};

export default useSubmitLandlordInfo;
