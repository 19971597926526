import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Button from "@houm-com/ui/Button";
import CircularSteps from "@houm-com/ui/CircularSteps";
import FieldInput from "@houm-com/ui/FieldInput";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";

import {
  promotionTargetOptions,
  promotionTypeOptions,
  promotionUserOptions,
} from "../../utils/constants";
import { PromotionFormData } from "../../utils/types";
import {
  showLandlordBrokerageDiscount,
  showLandlordManagementDiscount,
  showTenantBrokerageDiscount,
} from "../../utils/utils";
import LandlordBrokerageDiscount from "./components/LandlordBrokerageDiscount";
import LandlordManagementDiscount from "./components/LandlordManagementDiscount";
import TenantBrokerageDiscount from "./components/TenantBrokerageDiscount";
import { FixedDiscountExample, PercentageDiscountExample } from "./components/DiscountExamples";
import { classes } from "./DetailsFormStyles";

interface Props {
  handleBack: () => void;
  submitting?: boolean;
}

const DetailsForm = ({ handleBack, submitting }: Props) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<PromotionFormData>();

  const promotionTarget = watch("promotionTarget");
  const promotionUser = watch("promotionUser");

  useEffect(() => {
    setValue("promotionUser", "landlord");
  }, [promotionTarget]);

  return (
    <>
      <div className={classes.header}>
        <p>Detalles de la promoción</p>
        <CircularSteps
          totalSteps={2}
          currentStep={2}
          classNames={{ wrapper: "h-[32px] w-[32px]", text: "fill-white text-[20px]" }}
        />
      </div>
      <div className={classes.detailsForm}>
        <FieldInput
          id="promotionsAmount"
          label="Cantidad de promociones disponibles"
          required
          errorMessage={errors.promotionsAmount?.message}
          type="number"
          placeholder="000"
          {...register("promotionsAmount")}
          form="novalidateform"
        />
        <FieldRadioButton
          id="promotionTarget"
          label="¿En dónde se aplicará la promoción?"
          options={promotionTargetOptions}
          required
          errorMessage={errors.promotionTarget?.message}
          {...register("promotionTarget")}
          form="novalidateform"
        />
        <FieldRadioButton
          id="promotionUser"
          label="¿A quién se le aplicará la promoción?"
          options={promotionUserOptions(promotionTarget)}
          required
          errorMessage={errors.promotionUser?.message}
          {...register("promotionUser")}
          form="novalidateform"
        />
        {promotionTarget === "brokerage" && (
          <div className="relative">
            <FixedDiscountExample />
            <PercentageDiscountExample />
            <FieldRadioButton
              id="promotionType"
              label="Tipo de descuento"
              options={promotionTypeOptions(promotionTarget)}
              required
              errorMessage={errors.promotionType?.message}
              {...register("promotionType")}
              form="novalidateform"
            />
          </div>
        )}

        {showLandlordManagementDiscount(promotionTarget) && (
          <LandlordManagementDiscount headless={promotionTarget === "management_fee"} />
        )}
        {showLandlordBrokerageDiscount(promotionTarget, promotionUser) && (
          <LandlordBrokerageDiscount
            headless={promotionTarget !== "both" && promotionUser !== "both"}
          />
        )}
        {showTenantBrokerageDiscount(promotionTarget, promotionUser) && (
          <TenantBrokerageDiscount headless={promotionUser === "tenant"} />
        )}

        <div className="p-2 flex w-full justify-between">
          <Button variant="tertiary" size="md" onClick={handleBack}>
            Atrás
          </Button>
          <Button variant="tertiary" size="md" type="submit" isLoading={submitting}>
            Terminar
          </Button>
        </div>
      </div>
    </>
  );
};

export default DetailsForm;
