import { notNumberToNull } from "utils/yupValidators";
import * as yup from "yup";

import { OptionType } from "./types";

export const requiredText = "Obligatorio";

const numberFloatValidation = yup.number().nullable().transform(notNumberToNull);

const monthValidation = {
  is: "yes",
  then: numberFloatValidation.required(requiredText),
  otherwise: numberFloatValidation.notRequired(),
};

const brokerageValidation = {
  is: "yes",
  then: numberFloatValidation.required(requiredText),
  otherwise: numberFloatValidation.notRequired(),
};

const numberValidation = yup
  .number()
  .integer()
  .nullable()
  .transform(notNumberToNull)
  .required(requiredText);

const monthFlag = yup.string().oneOf(["yes", "no"]).required(requiredText);

const monthsValidation = (flag: string, quantityMonth: number, type: OptionType, obj) => {
  if (flag === "no" || !quantityMonth) return true;
  if ((type === "tenantBrokerage" || type === "landlordBrokerage") && quantityMonth === 0)
    return true;
  const errors = [...Array(Number(quantityMonth)).keys()]
    .map((_, i) => {
      if (obj[`${type}Month${i + 1}`]) {
        return null;
      }
      return new yup.ValidationError(requiredText, null, `${type}Month${i + 1}`, "typeError");
    })
    .filter(Boolean);

  if (errors.length === 0) {
    return true;
  }

  return new yup.ValidationError(errors);
};

const validateAtLeatOneFlagTrue = (obj) => {
  if (
    obj.adminCommissionFlag === "yes" ||
    obj.leaseLandlordFlag === "yes" ||
    obj.leaseTenantFlag === "yes" ||
    obj.landlordBrokerageFlag === "yes" ||
    obj.tenantBrokerageFlag === "yes"
  )
    return true;
  return new yup.ValidationError("Debe aplicar al menos un descuento", null, null, "typeError");
};

export const promotionValidationSchema = yup
  .object()
  .shape({
    initDate: yup.string().required(requiredText),
    endDate: yup.string().required(requiredText),
    //owner: yup.string().required(requiredText),
    promotionName: yup.string().required(requiredText),
    promotionStock: numberValidation,
    target: yup.string().required(requiredText),
    description: yup.string().required(requiredText),
    link: yup.string(),
    promotionCode: yup.string(),
    adminCommissionFlag: monthFlag,
    adminCommissionMonth: yup.number().when("adminCommissionFlag", monthValidation),
    leaseLandlordFlag: monthFlag,
    leaseLandlordMonth: yup.number().when("leaseLandlordFlag", monthValidation),
    leaseTenantFlag: monthFlag,
    leaseTenantMonth: yup.number().when("leaseTenantFlag", monthValidation),
    landlordBrokerageFlag: monthFlag,
    landlordBrokerageMonth: yup.number().when("landlordBrokerageFlag", monthValidation),
    landlordBrokerage: yup.number().when("landlordBrokerageFlag", brokerageValidation),
    landlordBrokerageClosing: yup.number().when("landlordBrokerageFlag", brokerageValidation),
    tenantBrokerageFlag: monthFlag,
    tenantBrokerageMonth: yup.number().when("tenantBrokerageFlag", monthValidation),
    tenantBrokerage: yup.number().when("tenantBrokerageFlag", brokerageValidation),
    tenantBrokerageClosing: yup.number().when("tenantBrokerageFlag", brokerageValidation),
    discountType: yup.string().required(requiredText).notOneOf(["0"], requiredText),
  })
  .test("adminCommissionDataMonth", null, (obj) =>
    monthsValidation(obj.adminCommissionFlag, obj.adminCommissionMonth, "adminCommission", obj)
  )
  .test("leaseLandlordDataMonth", null, (obj) =>
    monthsValidation(obj.leaseLandlordFlag, obj.leaseLandlordMonth, "leaseLandlord", obj)
  )
  .test("leaseTenantDataMonth", null, (obj) =>
    monthsValidation(obj.leaseTenantFlag, obj.leaseTenantMonth, "leaseTenant", obj)
  )
  .test("landlordBrokerageDataMonth", null, (obj) =>
    monthsValidation(
      obj.landlordBrokerageFlag,
      obj.landlordBrokerageMonth,
      "landlordBrokerage",
      obj
    )
  )
  .test("tenantBrokerageDataMonth", null, (obj) =>
    monthsValidation(obj.tenantBrokerageFlag, obj.tenantBrokerageMonth, "tenantBrokerage", obj)
  )
  .test("atLeastOneFlagTrue", null, (obj) => validateAtLeatOneFlagTrue(obj));
