import { useState } from "react";
import { useForm, UseFormProps } from "react-hook-form";

type FormSteps = "editable" | "readable";

interface Props<T> {
  resolver?: UseFormProps<T>["resolver"];
  defaultChecked?: boolean;
}

const useVisitCard = <T>({ resolver, defaultChecked = false }: Props<T>) => {
  const [currentStep, setCurrentStep] = useState<FormSteps>("readable");
  const [checked, setChecked] = useState(defaultChecked);
  const methods = useForm<T>({ resolver });

  const nextStepMap = {
    readable: () => setCurrentStep("editable"),
    editable: () => setCurrentStep("readable"),
  };

  const backStepMap = {
    readable: () => setCurrentStep("readable"),
    editable: () => setCurrentStep("readable"),
  };

  const labelStepMap = {
    readable: "Editar",
    editable: "Actualizar",
  };

  const label = labelStepMap[currentStep];

  const goNextStep = () => nextStepMap[currentStep]();
  const goBackStep = () => backStepMap[currentStep]();

  const toggleCheck = () => setChecked((val) => !val);

  const changedDefaultCheck = defaultChecked !== checked;

  return {
    label,
    checked,
    toggleCheck,
    goNextStep,
    goBackStep,
    currentStep,
    methods,
    changedDefaultCheck,
  };
};

export default useVisitCard;
