import { Container, Typography } from "@material-ui/core";

import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";
import useErrorMessageStyles from "./ErrorMessageStyles";

const ErrorMessage = () => {
  const classes = useErrorMessageStyles();

  return (
    <Container className={classes.titleContainer}>
      <WarningIcon />
      <Typography className={classes.title}>Ocurrió un error al buscar las promociones</Typography>
    </Container>
  );
};

export default ErrorMessage;
