import { LetterOfIntent } from "services/negotiationService/models";
import { LetterOfIntent as LetterOfIntentData } from "./lettersOfIntentListTypes";

export function parseLoiList(data: LetterOfIntent[]): LetterOfIntentData[] {
  return data?.map((loi) => ({
    id: loi.loi_id,
    propertyId: loi.property_id,
    status: loi.status,
    owner: {
      name: loi.owner.fullname,
      phone: loi.owner.phone,
      email: loi.owner.email,
    },
    propertyExecutiveName: loi.property_executive_name,
  }));
}
