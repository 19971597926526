import { Locale } from "models/Countries";

export enum ActionType {
  SET_LOADING,
  SET_LETTER_OF_INTENT,
  SET_OWNER,
  SET_OWNERS,
  SET_BIDDERS,
  SET_NEGOTIATION,
  SET_DOCUMENTS,
  SET_BANK_DATA,
  SET_COUNTRY,
  SET_LOI_ID,
  SET_PERSON_ID,
  SET_PERSON,
  SET_PROPERTY,
}

export enum FileStatus {
  VALID = "Validado",
  INVALID = "Rechazado",
  PENDING = "Por validar",
  DELETED = "Eliminado",
}

export type CivilStatus = "unmarried" | "married" | "divorced" | "widowed" | "freeUnion" | "other";

export type MaritalRegime = "conjugalSociety" | "separatedGoods" | "notApply" | "other";

export type PersonType = "bidder" | "owner";

export enum PersonTypes {
  BIDDER = "bidder",
  OWNER = "owner",
}

export interface State {
  loading: boolean;
  loiId: string;
  country: Locale;
  owner: Owner;
  bidders: Bidder[];
  owners: Owner[];
  negotiation: Negotiation;
  bankData: BankData;
  documents: DocumentData[];
  personId: string;
  person: Owner | Bidder;
  property: Property;
}
export interface Owner {
  id: string;
  userId: string;
  fullname: string;
  name: string;
  lastName: string;
  secondLastName: string;
  email: string;
  phone: string;
  documentType?: string;
  documentNumber: string;
  rfc?: string;
  occupation?: string;
  nationality?: string;
  civilStatus?: CivilStatus;
  conjugalRegime?: MaritalRegime;
  isMainUser?: boolean;
}

export interface Bidder extends Owner {}

export interface Negotiation {
  id: string;
  propertyId: number;
  address: string;
  amount: number;
  currency: string;
  lastBid?: LastBid;
}

export interface Property {
  id: string;
  individualization: string;
  propertyAddress: string;
  propertyExecutiveName: string;
  propertyExecutiveEmail: string;
  propertyExecutiveUserId?: string;
  propertyId: number;
}

export interface LastBid {
  id: string;
  amount: number;
  currency: string;
  paymentMethod: string;
  status: string;
  createdAt: Date;
  depositAmount: number;
  ownerPenaltyFee: number;
  bidderPenaltyFee: number;
  houmOwnerFee: number;
  houmBidderFee: number;
}

export interface BidFees {
  ownerPenaltyFee?: number;
  bidderPenaltyFee?: number;
  houmOwnerFee?: number;
  houmBidderFee?: number;
}

export interface BankData {
  clabe: number;
}

export interface DocumentData {
  id: string;
  loiId: string;
  contentType: string;
  filename: string;
  path: string;
  status: string;
  createdAt: string;
}

interface setLoading {
  type: typeof ActionType.SET_LOADING;
  loading: boolean;
}

interface setOwner {
  type: typeof ActionType.SET_OWNER;
  owner: Owner;
}

interface setBidders {
  type: typeof ActionType.SET_BIDDERS;
  bidders: Owner[];
}

interface setOwners {
  type: typeof ActionType.SET_OWNERS;
  owners: Owner[];
}

interface setNegotiation {
  type: typeof ActionType.SET_NEGOTIATION;
  negotiation: Negotiation;
}

interface setBankData {
  type: typeof ActionType.SET_BANK_DATA;
  bankData: BankData;
}

interface setDocuments {
  type: typeof ActionType.SET_DOCUMENTS;
  documents: DocumentData[];
}

interface setCountry {
  type: typeof ActionType.SET_COUNTRY;
  country: Locale;
}

interface setLoiId {
  type: typeof ActionType.SET_LOI_ID;
  loiId: string;
}

interface setPersonId {
  type: typeof ActionType.SET_PERSON_ID;
  personId: string;
}

interface setPerson {
  type: typeof ActionType.SET_PERSON;
  person: Bidder | Owner;
}

interface setProperty {
  type: typeof ActionType.SET_PROPERTY;
  property: Property;
}

export type Action =
  | setLoading
  | setOwner
  | setOwners
  | setBidders
  | setNegotiation
  | setBankData
  | setDocuments
  | setCountry
  | setLoiId
  | setPersonId
  | setPerson
  | setProperty;

export type Dispatch = (action: Action) => void;
