import { Controller, useFormContext } from "react-hook-form";

import { Typography } from "@material-ui/core";

import Map from "Components/Admin/UIComponents/atoms/Map";

import useStyles from "./ControlledMapStyles";

interface Props {
  id: string;
  country?: string;
  defaultValue?: [lng: number, lat: number];
  controllerProps?: React.ComponentProps<typeof Controller>;
  mapHeight?: number;
  label?: string;
}

function ControlledMap({ id, country, defaultValue = [0, 0], mapHeight = 300, label }: Props) {
  const { control } = useFormContext();
  const classes = useStyles({ mapHeight });

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className={classes.container}>
          <Typography className={classes.label}>{label}</Typography>
          <Map
            value={value}
            locale={country}
            onDrag={onChange}
            hasMarker={!!defaultValue}
            defaultValue={defaultValue}
            zoom={15}
          />
        </div>
      )}
    />
  );
}

export default ControlledMap;
