import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useChangeProperty = ({
  evaluationId,
  country,
  onHandleSuccess,
}: {
  evaluationId: string;
  country: Locale;
  onHandleSuccess?: () => void;
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess, error, data } = useMutation(
    async (formData: { propertyId: number; propertyUid: string }) => {
      const authToken = await getToken();
      const response = await service.changeProperty(
        evaluationId,
        country,
        {
          property_id: formData.propertyId,
          property_uid: formData.propertyUid,
        },
        authToken
      );
      return response.data;
    }
  );

  useEffect(() => {
    if (isError) {
      alert.error({
        message: "Error al cambiar la propiedad de la evaluación",
        disclaimer: `Hubo un error al actualizar propiedad de la evaluación, error: ${error}`,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(["evaluation", evaluationId]);
      onHandleSuccess?.();
      const newEvaluationId = data?.id;
      alert.success({
        message: "Evaluación actualizada",
        disclaimer: "Se ha creado una nueva evaluación para la propiedad elegida.",
        primaryCallToAction: {
          label: "Ir a nueva evaluación",
          action: () => history.push(`/admin/evaluations/${country}/${newEvaluationId}`),
        },
      });
    }
  }, [isSuccess, data]);

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useChangeProperty;
