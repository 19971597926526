import { makeStyles, Theme } from "@material-ui/core";

const useAdminPropertyShowStyles = makeStyles((theme: Theme) => ({
  servicesSelect: {
    width: 300,
  },
  overdue: {
    color: "red",
  },
  noOverdue: {
    color: "green",
  },
  dividerService: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  basicServicesTitle: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    width: "50%",
    minWidth: 800,
  },
  customTabsStyle: {
    "& .MuiTabScrollButton-root": {
      backgroundColor: theme.palette.grey[200],
      "& svg": {
        fontSize: theme.typography.pxToRem(30),
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
      "&:first-child": {
        borderStartStartRadius: 8,
        borderBottomLeftRadius: 8,
      },
      "&:last-child": {
        borderStartEndRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
  },
  container: {},
}));

export default useAdminPropertyShowStyles;
