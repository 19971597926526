import { Grid } from "@material-ui/core";

import TitlePromotion from "Components/Admin/administrator/promotions/components/TitlePromotion";
import CitiesApply from "../CitiesApply";
import useTitleStyles from "./TitleStyles";

const Title = () => {
  const classes = useTitleStyles();
  return (
    <Grid
      xs={12}
      item
      container
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      className={classes.titleContainer}>
      <Grid item>
        <TitlePromotion title="Crear promoción" />
      </Grid>
      <Grid item>
        <CitiesApply />
      </Grid>
    </Grid>
  );
};

export default Title;
