import classNames from "classnames";

import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import { CountryUpperCase } from "models/Countries";

import useStyles from "./CountrySelectorStyles";

interface Props {
  stateCountry: CountryUpperCase | "";
  onChange: (country: CountryUpperCase) => void;
  rootClassName?: string;
}

export default function CountrySelector({ stateCountry, onChange, rootClassName }: Props) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, rootClassName)}>
      {["Chile", "México", "Colombia"].map((country) => (
        <CountryChip
          key={country}
          country={country as CountryUpperCase}
          onClick={() => onChange(country as CountryUpperCase)}
          selected={country === stateCountry}
        />
      ))}
    </div>
  );
}
