export const classes = {
  headerContainer: "flex flex-col mb-3 flex-wrap gap-2",
  statusContainer: "flex items-center gap-1",
  statusText: "text-p16",
  evaluationContainer: "container m-auto grid grid-cols-2 gap-4",
  cardContainer: "col-span-full md:col-span-1 h-fit flex gap-4 flex-col",
  cardContainerStart: "md:col-start-1",
  cardContainerEnd: "md:col-start-2",
  spinner: "relative flex justify-center items-center h-full",
};
