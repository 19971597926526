import { makeStyles, Theme } from "@material-ui/core";

const usePropertyCardStyles = makeStyles<Theme>((theme) => ({
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default usePropertyCardStyles;
