import { StandardTextFieldProps } from "@material-ui/core";
import { useDebounceState } from "domains/common/utils/debounce-state";
import { BACKEND_URL } from "env";
import { useAuthClient } from "libs/auth";

import { RawAddress } from "models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { addressDetailsSchema, addressSearchResultsSchema, AddressSearch } from "models/Address";
import Spinner from "@houm-com/ui/Spinner";
import FieldInput from "@houm-com/ui/FieldInput";
import { cn } from "@houm-com/ui/utils";

export interface Props {
  id: string;
  label: string;
  value: RawAddress;
  locale?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (a: any) => void;
  noResultMessage?: string;
  textFieldProps?: StandardTextFieldProps;
  countryCode: string;
}

function useQueyAddressSearch({ q, countryCode }: { q: string; countryCode: string }) {
  const { client, status } = useAuthClient();
  return useQuery(
    ["locations/address/search", countryCode, { q }],
    async () => {
      const { data } = await client.get(`${BACKEND_URL}locations/address/${countryCode}/search`, {
        params: { q },
      });
      return addressSearchResultsSchema.parse(data);
    },
    {
      enabled: status === "success" && !!q,
    }
  );
}

function useQueryAddressDetails({
  referenceId,
  countryCode,
}: {
  referenceId: string;
  countryCode: string;
}) {
  const { client, status } = useAuthClient();
  return useQuery(
    ["locations/address/details", countryCode, { referenceId }],
    async () => {
      const { data } = await client.get(`${BACKEND_URL}locations/address/${countryCode}/details`, {
        params: { reference_id: referenceId },
      });
      return addressDetailsSchema.parse(data);
    },
    {
      enabled: status === "success" && !!referenceId,
    }
  );
}

export default function AddressSuggester({
  label,
  value,
  onChange,
  defaultValue,
  placeholder,
  locale = "Chile",
  noResultMessage = "No se encontraron resultados",
  textFieldProps,
  countryCode,
  ...props
}: Props) {
  const [query, setQuery] = useState<string | null>(defaultValue);
  const debounceState = useDebounceState(query);
  const { data: suggestions, status } = useQueyAddressSearch({ q: debounceState, countryCode });
  const [selected, setSelected] = useState<AddressSearch>(null);

  const { data: details, status: detailsStatus } = useQueryAddressDetails({
    referenceId: selected?.reference_id,
    countryCode,
  });
  useEffect(() => {
    if (detailsStatus === "success") onChange(details);
  }, [details, detailsStatus, onChange]);

  useEffect(() => {
    if (suggestions && defaultValue) {
      const selected = suggestions.results.find((s) => s.full_address === defaultValue);
      if (selected) handleSelect(selected);
    }
  }, [defaultValue, suggestions]);

  const handleSearch = (value: string) => setQuery(value);
  const handleSelect = (item: AddressSearch) => {
    handleSearch(item.full_address);
    setSelected(item);
  };
  const handleClear = () => {
    setSelected(null);
    setQuery("");
  };
  const options = suggestions?.results ? suggestions.results : [];
  const isLoading = status === "loading";
  return (
    <div>
      <div className="flex gap-2">
        <div className="basis-full">
          <FieldInput
            value={query}
            label={label}
            onChange={(e) => {
              if (selected || e.target.value === "") handleClear();
              handleSearch(e.target.value);
            }}
            {...props}
          />
        </div>
      </div>
      <div className="w-full relative bg-white">
        <div className="bg-white absolute z-20">
          {selected === null && (
            <div className="text-p14 max-h-40 overflow-y-auto w-full">
              {options.map((option) => (
                <div
                  key={option.reference_id}
                  className={cn("space-x-1 px-4 py-2", "hover:bg-black-20")}
                  tabIndex={0}
                  role="button"
                  onClick={() => handleSelect(option)}>
                  {option.full_address}
                </div>
              ))}
            </div>
          )}
          {isLoading && query?.length > 0 && (
            <div className="w-full py-2 px-4 text-black-80 flex">
              <Spinner size="sm" variant="primary" />
              <span className="relative ml-8">Buscando dirección</span>
            </div>
          )}
          {status === "success" && suggestions?.results.length === 0 && (
            <div className="w-full py-2 px-4 text-black-80 flex">
              <span>No encontramos la dirección que buscas</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
