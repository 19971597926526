import { useEffect } from "react";

import Header from "Components/Admin/administrator/integrations/components/Header/Header";
import RequiredFiltersToolbar from "./components/RequiredFiltersToolbar";
import Table from "./components/Table";
import NoResults from "./components/NoResults";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useIntegration } from "context/integrationContext/integrationContext";
import { getPlanEditionData } from "context/integrationContext/integrationActions";
import { useTable } from "context/tableContext/tableContext";
import { useGetToken } from "hooks";
import { IndexQuotaByMacrozoneFilters } from "services/integrationService/types";

function PlanEdition() {
  const { state, dispatch } = useIntegration();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;
  const { getToken } = useGetToken();

  const mappedFilters: Partial<IndexQuotaByMacrozoneFilters> = {
    portal: state.planEditionFilters.portal?.id as string,
    publication_type: state.planEditionFilters.portalPlan?.id as string,
    business_type: state.planEditionFilters.businessType,
    page: currentPage,
    page_size: pageSize,
  };

  useEffect(() => {
    getToken().then((authToken) => {
      getPlanEditionData(mappedFilters, dispatch, authToken);
    });
  }, [state.planEdition.refetch, currentPage, pageSize]);

  return (
    <>
      <Header title="Edición de planes por macrozona" />
      <RequiredFiltersToolbar />
      <Loading loading={state.planEdition.loading}>
        {state.planEdition.count > 0 ? <Table /> : <NoResults />}
      </Loading>
    </>
  );
}

export default PlanEdition;
