import * as yup from "yup";

export const requiredText = "Obligatorio";

export const contractSchema = yup.object({
  initDate: yup.string().required(requiredText),
  signatureDate: yup.string().required(requiredText),
  mainTenant: yup.string().required(requiredText),
  habitants: yup.string().required(requiredText),
  appraiser: yup.string().required(requiredText),
});
