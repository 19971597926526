import { useQuery, UseQueryResult } from "react-query";

import newEvaluationService from "services/newEvaluationService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface IEvaluationDocument {
  link: string;
}

const useEvaluationDocuments = (evaluationId: number, country: Locale) => {
  const { getToken } = useGetToken();

  const { isLoading, isError, data, isSuccess }: UseQueryResult<IEvaluationDocument, Error> =
    useQuery<IEvaluationDocument, Error>(
      ["evaluation-documents", evaluationId],
      async () => {
        const authToken = await getToken();
        const response = await newEvaluationService.getDocuments(evaluationId, country, authToken);
        return response.data;
      },
      {
        enabled: !!evaluationId,
        retry: false,
      }
    );

  return {
    isError,
    isLoading,
    data,
    isSuccess,
  };
};

export default useEvaluationDocuments;
