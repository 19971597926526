import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
import clx from "classnames";

import { Box, Grid } from "@material-ui/core";

import useDocumentsDialogStyles from "./DocumentsDialogStyles";
import { DocumentData } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import DocumentView from "./DocumentView";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface DocumentsDialogProps {
  document: DocumentData;
}

function DocumentsDialog({ document }: DocumentsDialogProps) {
  const classes = useDocumentsDialogStyles();

  const refSidePanel = useRef(null);
  const refSpotlight = useRef(null);

  const [sidePdfWidth, setSidePdfWidth] = useState(0);
  const [spotlightPdfWidth, setSpotlightPdfWidth] = useState(0);
  const offsetDialog = 50;

  useEffect(() => {
    setSidePdfWidth(refSidePanel.current ? refSidePanel.current.offsetWidth - offsetDialog : 0);
    setSpotlightPdfWidth(
      refSpotlight.current ? refSpotlight.current.offsetWidth - offsetDialog : 0
    );
  }, [document]);

  const renderDocument = (doc: DocumentData, pdfWidth: number) =>
    doc.contentType === "application/pdf" ? (
      <Document file={doc.path} className={classes.selectPdf}>
        <Page pageNumber={1} width={pdfWidth} />
      </Document>
    ) : (
      <img alt={doc.filename} src={doc.path} width="100%" className={classes.selectPdf} />
    );

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={2} className={clx(classes.item, classes.sidePanel)} ref={refSidePanel}>
          <Grid item>
            <Box className={clx(classes.preview, classes.selected)}>
              {renderDocument(document, sidePdfWidth)}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={10} className={classes.item} ref={refSpotlight}>
          <DocumentView document={document} spotlightPdfWidth={spotlightPdfWidth} />
        </Grid>
      </Grid>
    </div>
  );
}

export default DocumentsDialog;
