import { makeStyles, Theme, fade } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  buttonSelectorRoot: {
    padding: theme.spacing(1, 1.5),
    minWidth: 0,
    fontSize: "1rem",
    lineHeight: 1,
    boxSizing: "content-box",
    borderRadius: "6px",
    color: theme.palette.grey[700],
    fontWeight: 600,
    textTransform: "none",
  },
  buttonSelectorActive: {
    "&, &:hover, &:active, &:focus": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      borderColor: "transparent",
      backgroundColor: `${fade(theme.palette.primary.main, 0.1)} !important`,
    },
  },
}));
