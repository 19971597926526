import { makeStyles, Theme } from "@material-ui/core";

const useOwnerCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(2, 3),
    width: "50%",
    borderRadius: 20,
    marginRight: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "620px",
  },
  textContainer: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  labelContainer: {
    width: "40%",
  },
  valueContainer: {
    width: "60%",
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    width: "100%",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    margin: theme.spacing(0, 0, 1),
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: "1rem",
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    alignSelf: "flex-end",
  },
  textField: {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  inputField: {
    padding: theme.spacing(0, 0, 0.5, 0),
    fontSize: "1rem",
    color: `${theme.palette.grey[900]} !important`,
  },
  editContainer: {
    width: "60%",
    "& > div": {
      marginBottom: theme.spacing(2),
      marginTop: 0,
    },
  },
  selectContainer: {
    marginTop: 0,
    "& .MuiInputBase-root": {
      display: "flex",
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
  },
  buttonContainer: {
    display: "flex",
    alignSelf: "flex-end",
    gap: theme.spacing(1),
  },
}));
export default useOwnerCardStyles;
