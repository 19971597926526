import MultiFamilyTable from "./MultiFamilyTable";

import { TableProvider } from "context/tableContext/tableContext";
import { MultifamilyProvider } from "context/multifamilyContext/multifamily/multifamilyContext";
import { TypologyProvider } from "context/multifamilyContext/typology/typologyContext";
import { MultifamilyUnitProvider } from "context/multifamilyContext/unit/unitContext";

function TypologyIndex() {
  return (
    <MultifamilyProvider>
      <TypologyProvider>
        <MultifamilyUnitProvider>
          <TableProvider>
            <MultiFamilyTable />
          </TableProvider>
        </MultifamilyUnitProvider>
      </TypologyProvider>
    </MultifamilyProvider>
  );
}
export default TypologyIndex;
