import axios, { AxiosResponse } from "axios";
import { IListDocumentsOldEvaluator } from "models/Evaluation";
import { getToken, createHttp } from "./helper";
import { BACKEND_URL } from "../env";

export default {
  getByApplicant(applicantId: string, token: string) {
    return createHttp(token).get(`/lessors/v2/rent-applications/?applicant=${applicantId}`);
  },

  initContractWithoutEvaluation(data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http
      .post(`/lessors/v2/rent-applications/contracts/init-without-evaluation/`, data)
      .catch((error) => false);
  },

  listDocuments(
    rentApplicationId: number,
    token: string
  ): Promise<AxiosResponse<IListDocumentsOldEvaluator[]>> {
    return createHttp(token).get(
      `/lessors/v2/rent-applications/${rentApplicationId}/codebtors-documents`
    );
  },
};
