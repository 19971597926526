import { Chip } from "@material-ui/core";
import { StatusMovementType } from "services/newPaymentService/types";
import useStatusLabelReservationStyles from "./StatusLabelReservationStyles";

interface Props {
  status: StatusMovementType;
}

const StatusLabelReservation = ({ status }: Props) => {
  const classes = useStatusLabelReservationStyles();

  switch (status) {
    case "PAID":
      return <Chip className={classes.chipPaid} label="Pagado" />;
    case "UNPAID":
      return <Chip className={classes.chipUnpaid} label="Pendiente de pago" />;
    case "CANCELED":
      return <Chip className={classes.chipCanceled} label="Pago anulado" />;
    case "REFUNDED":
      return <Chip className={classes.chipRefunded} label="Pago reembolsado" />;
    default:
      return <Chip className={classes.chipDefault} label="Estado desconocido" />;
  }
};

export default StatusLabelReservation;
