import { useQuery, UseQueryResult } from "react-query";

import { IReservation } from "services/newPaymentService/types";
import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useRefundDetails = (country: Locale, groupId: string) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IReservation[], Error> = useQuery<IReservation[], Error>(
    ["reservationGroups", groupId, country],
    async () => {
      const authToken = await getToken();
      const response = await newPaymentService.getGroupV2(country, groupId, authToken);
      return response.data.movements;
    },
    {
      enabled: !!groupId,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useRefundDetails;
