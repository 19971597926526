import { SnackbarProvider } from "notistack";
import { useParams, useHistory } from "react-router-dom";

import UserForm from "domains/common/components/UserForm";

import { classes } from "./UsersStyles";

const UsersController = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const onSuccessEditUser = () => history.goBack();

  return (
    <SnackbarProvider>
      <div className={classes.usersContainer}>
        <UserForm userId={Number(id)} onSuccessEditUser={onSuccessEditUser} />
      </div>
    </SnackbarProvider>
  );
};

export default UsersController;
