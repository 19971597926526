import { TenantPlanOption } from "./types";

export const READJUST_TRANSLATE = {
  annual: "Anual",
  biannual: "Semestral",
  quarterly: "Trimestral",
  no_readjust: "Sin Reajuste",
};

export const TENANT_PLANS: { noPlan: TenantPlanOption; smartLeasing: TenantPlanOption } = {
  noPlan: {
    value: "no_plan",
    label: "Sin plan",
  },
  smartLeasing: {
    value: "smart_leasing",
    label: "Arriendo inteligente",
  },
};

export const CONTRACT_TYPES = {
  lease: {
    value: "lease",
    label: "Arriendo",
  },
  collection_service: {
    value: "collection_service",
    label: "Servicio de cobranza",
  },
};
