import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useGetToken } from "hooks";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  SetIsOpenAsignPropietaryModal,
  setIsOpenCancelModal,
  setConfirmation,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import useConfirmationPropStyles from "./ConfirmationPropStyles";

function AsingAgent() {
  const { getToken } = useGetToken();
  const classes = useConfirmationPropStyles();
  const {
    state: { isOpenAsignPropietaryModal, scheduleId, appraiserId },
    dispatch,
  } = useDemandScheduled();

  const handleConfirmationProp = (scheduleId, third_party) => {
    getToken().then((authToken) => {
      setConfirmation(dispatch, authToken, scheduleId, third_party);
    });
  };

  const handleCancelationProp = () => {
    dispatch(setIsOpenCancelModal(true));
    dispatch(SetIsOpenAsignPropietaryModal(false));
  };

  return (
    <CustomCenteredModal
      open={isOpenAsignPropietaryModal}
      onClose={() => dispatch(SetIsOpenAsignPropietaryModal(false))}
      title="Estado del contacto de coordinación"
      subtitle={`Elige una de las opciones para la visita: #${scheduleId}`}
      showCloseButton
      withLogo>
      <div className={classes.buttonContainers}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          onClick={() => handleConfirmationProp(scheduleId, "third_party")}>
          Confirmar
        </Button>

        <Button size="small" variant="outlined" color="primary" className={classes.actionButton}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/control-tower/profile/${appraiserId}?reschedule=${scheduleId}`}
            className={classes.linkToCT}>
            Reagendar
          </Link>
        </Button>

        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          onClick={handleCancelationProp}>
          Cancelar
        </Button>
      </div>
    </CustomCenteredModal>
  );
}

export default AsingAgent;
