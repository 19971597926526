import { State, Action, ActionType } from "./letterOfIntentTypes";

export const initialState: State = {
  loading: true,
  loiId: null,
  country: null,
  owner: null,
  owners: null,
  bidders: null,
  negotiation: null,
  bankData: null,
  documents: null,
  personId: null,
  person: null,
  property: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case ActionType.SET_OWNER: {
      return { ...state, owner: action.owner };
    }
    case ActionType.SET_BIDDERS: {
      return { ...state, bidders: action.bidders };
    }
    case ActionType.SET_OWNERS: {
      return { ...state, owners: action.owners };
    }
    case ActionType.SET_NEGOTIATION: {
      return { ...state, negotiation: action.negotiation };
    }
    case ActionType.SET_BANK_DATA: {
      return { ...state, bankData: action.bankData };
    }
    case ActionType.SET_DOCUMENTS: {
      return { ...state, documents: action.documents };
    }
    case ActionType.SET_COUNTRY: {
      return { ...state, country: action.country };
    }
    case ActionType.SET_LOI_ID: {
      return { ...state, loiId: action.loiId };
    }
    case ActionType.SET_PERSON_ID: {
      return { ...state, personId: action.personId };
    }
    case ActionType.SET_PERSON: {
      return { ...state, person: action.person };
    }
    case ActionType.SET_PROPERTY: {
      return { ...state, property: action.property };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
