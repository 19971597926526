import { DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import useUnasignHoumerModalStyles from "./UnassignHoumerModalStyles";
import useUnassignHoumer from "../HoumerModal/hooks/useUnassignHoumer";

interface Props {
  open: boolean;
  handleClose: () => void;
  propertyId: number;
  refetchHoumer: () => void;
}

const UnassignHoumerModal = ({ open, handleClose, propertyId, refetchHoumer }: Props) => {
  const classes = useUnasignHoumerModalStyles();

  const onSuccessHandler = () => {
    refetchHoumer();
    handleClose();
  };

  const { mutate: unassignHoumer, isLoading } = useUnassignHoumer({
    propertyId,
    onSuccessHandler,
  });

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <DialogTitle>
        <Typography className={classes.title}>Eliminar Houmer</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <Typography>
              ¿Estas seguro de que quieres quitar al houmer de esta propiedad?
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <DesignSystemButton
              size="medium"
              label="Quitar"
              variant="primary"
              isLoading={isLoading}
              onClick={() => unassignHoumer()}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DSModal>
  );
};

export default UnassignHoumerModal;
