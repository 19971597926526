import classnames from "classnames";

import { Box, Typography } from "@material-ui/core";
import { CustomLink } from "Components/Admin/UIComponents";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import CheckIcon from "@material-ui/icons/Check";
import usePropertyDataCardStyles from "./PropertyDataCardStyles";
import { useParams } from "react-router-dom";

function PropertyDataCard() {
  const classes = usePropertyDataCardStyles();
  const { state } = useLetterOfIntent();
  const { bankData, country } = state;
  const params = useParams<{ id: string }>();
  const { id: loiId } = params;

  const displayIcon = (status: string) => {
    switch (status) {
      case "ok":
        return (
          <CheckIcon className={classnames(classes.flagStatusIcon, classes.flagStatusValidated)} />
        );
      default:
        return null;
    }
  };

  if (!bankData) return null;

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Propiedad</Typography>
      <Box className={classes.textContainer}>
        <Box className={classes.fieldContainer}>
          <Typography className={classes.label}>Individualización</Typography>
          <Box
            className={classnames(classes.flagStatus, {
              [classes.flagStatusValidated]: true,
            })}>
            {displayIcon("no status yet")}
          </Box>
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography className={classes.label}>Documentos</Typography>
          <Box
            className={classnames(classes.flagStatus, {
              [classes.flagStatusValidated]: true,
            })}>
            {displayIcon("no status yet")}
          </Box>
        </Box>
      </Box>
      <Box className={classes.moreInfoContainer}>
        <CustomLink
          to={`/buyers/letterOfIntent/${loiId}/property?country=${country}`}
          text="Ver información >"
          align="end"
        />
      </Box>
    </Box>
  );
}

export default PropertyDataCard;
