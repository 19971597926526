import { createHttp } from "./helper";

export default {
  getExecutives(token, type = "PROPERTIES") {
    return createHttp(token).get(`/v2/administration_executives?type=${type}`);
  },
  async activateExecutive(executiveId, active, token) {
    return createHttp(token).put(`/v2/administration_executives/${executiveId}`, {
      active,
    });
  },
  getExecutivesAssignation(token) {
    return createHttp(token).get("/v2/administration_executives_assignation");
  },
};
