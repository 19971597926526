import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import contractService from "services/contractService";
import useGetToken from "hooks/useGetToken";
import { IPlanMovement } from "models/Contract";

type CreatePlanMovementProps = {
  formData: IPlanMovement;
};

const useCreatePlanMovement = (onSuccess: (formData: IPlanMovement) => void) => {
  const { getToken } = useGetToken();

  const {
    mutate: createPlanMovement,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useMutation(
    async ({ formData }: CreatePlanMovementProps) => {
      const authToken = await getToken();
      const response = await contractService.createPlanMovements(formData, authToken);
      return response.data;
    },
    {
      onSuccess: (response: IPlanMovement) => {
        alert.success({
          message: `${response.type_movement} creado`,
          disclaimer: `El ${response.type_movement} ha sido creado exitosamente`,
        });
        onSuccess(response);
      },
      onError: (e, { formData }: CreatePlanMovementProps) => {
        alert.error({
          message: `Error al crear ${formData.type_movement}`,
          disclaimer: `El ${formData.type_movement} no ha podido ser creado, error: ${e}`,
        });
      },
    }
  );

  return {
    createPlanMovement,
    isLoading,
    isError,
    isSuccess,
    error,
  };
};

export default useCreatePlanMovement;
