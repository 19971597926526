import { useMutation, useQueryClient } from "react-query";

import { alert } from "libs/alerts";
import { useGetToken } from "hooks";
import propertyService from "services/propertyService";
import contractService from "services/contractService";
import { PropertyData } from "models/Property";
import { ContractModel } from "models/Contract";

import { IProperty } from "../utils/types";

const useUpdateProperty = (
  property: PropertyData,
  contract: ContractModel,
  houmerId: string,
  onSubmit?: () => void
) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const updateHoumOwner = async (houmOwnerId: number, authToken: string, data: IProperty) => {
    if (property.houm_owner !== data.executive) {
      try {
        return await propertyService.updateProperty(
          property.id,
          { houm_owner: houmOwnerId },
          authToken
        );
      } catch (e) {
        throw new Error(`No se pudo actualizar el executivo, ${e}`);
      }
    }
  };

  const defineHomechecker = async (homecheckerId: string, authToken: string, data: IProperty) => {
    if (!houmerId || houmerId !== data.houmerId) {
      try {
        return await propertyService.setHomechecker(homecheckerId, property.uid, authToken);
      } catch (e) {
        throw new Error(`No se pudo actualizar el houmer, ${e}`);
      }
    }
  };

  const updateCanonPrice = async (authToken: string, data: IProperty) => {
    try {
      const details = property.property_details[0];
      delete details["tipo_moneda_venta"];
      await propertyService.editPropertyInfo(
        property.id,
        {
          property_details: [
            {
              ...details,
              valor: data.canon,
            },
          ],
          street_number: data?.street_number,
          numero_depto: data?.numero_depto,
          comuna: data?.comuna,
          stratus: data?.stratus,
          antiquity: data?.antiquity,
          state: data?.state,
        },
        authToken
      );
    } catch (e) {
      throw new Error(`No se pudo actualizar los detalles de la propiedad, ${e}`);
    }
  };

  const updateContractPropertyData = async (authToken: string, data: IProperty) => {
    try {
      return await contractService.update(
        contract.id,
        {
          ...contract,
          max_people_quantity: data.max_people_quantity,
          other_bans: data.other_bans,
          pet_bans: data.pet_bans,
          warehouse_number: data.warehouse_number,
          parking_number: data.parking_number,
        },
        authToken
      );
    } catch (e) {
      throw new Error(
        `No se pudo actualizar la cantidad máxima de personas que pueden habitar la propiedad, ${e}`
      );
    }
  };

  const { mutate, isLoading: isSubmitting } = useMutation(
    async ({ data }: { data: IProperty }) => {
      const authToken = await getToken();
      await Promise.all([
        updateHoumOwner(data.executive.id, authToken, data),
        defineHomechecker(data.houmerId, authToken, data),
        updateCanonPrice(authToken, data),
        updateContractPropertyData(authToken, data),
      ]);
    },
    {
      onSuccess: () => {
        onSubmit?.();
        queryClient.invalidateQueries("contract_property_info", { exact: false });
        alert.success({
          message: "Propiedad Actualizada",
          disclaimer: "La propiedad ha sido modificado exitosamente",
          onClose: () => window.location.reload(),
        });
      },
      onError: (reason) => {
        alert.error({
          message: "Error al actualizar la propiedad",
          disclaimer: `No se pudo actualizar la propiedad, contactarse con soporte, error: ${reason}`,
        });
      },
    }
  );
  return { mutate, isSubmitting };
};

export default useUpdateProperty;
