import { Box, Typography } from "@material-ui/core";

import Breadcrumbs from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import { useBreadcrumbs } from "../../../hooks";

import useClientsHeaderStyles from "./ClientsHeaderStyles";

interface Props {
  date: string;
}

function ClientsHeader({ date }: Props) {
  const classes = useClientsHeaderStyles();
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <Box className={classes.headerWrapper}>
      <Breadcrumbs steps={breadcrumbs} />
      <Typography className={classes.title}>Propietarios</Typography>
      <Typography className={classes.subtitle}>{date}</Typography>
    </Box>
  );
}

export default ClientsHeader;
