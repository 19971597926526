import { BasicOption, StringOption } from "models/Option";

export const OPTIONS = {
  maintainAspectRatio: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          borderDash: [1, 3],
          drawBorder: false,
          zeroLineBorderDash: [1, 3],
        },
        ticks: {
          min: 0,
        },
      },
    ],
    xAxes: [
      {
        barThickness: 10,
        gridLines: {
          display: true,
          color: "transparent",
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: (item, data) => {
        const label = data.datasets[item.datasetIndex].label || "";
        const { yLabel } = item;
        let content = "";
        if (data.datasets.length > 1) {
          content += label;
        }
        content += yLabel;
        return content;
      },
    },
  },
};

export const EVENTS_DISPLAY = {
  Confirmed: "green",
  Scheduled: "blue",
};

export const ROLES = [
  { label: "Mostrador Arriendo", value: "RentGuide" },
  { label: "Fotógrafo Arriendo", value: "RentPhotographer" },
  { label: "Mostrador Venta", value: "SalesGuide" },
  { label: "Fotógrafo Venta", value: "SalesPhotographer" },
  { label: "Inspector", value: "Inspector" },
  { label: "Dummy", value: "Dummy" },
  { label: "Asesor", value: "Advisor" },
  { label: "Interno", value: "Intern" },
  { label: "Leads Enabled", value: "LeadsEnabled" },
  { label: "Inspector de Salida", value: "ExitInspector" },
  { label: "Houmer", value: "Houmer" },
  { label: "Mostrador Canje", value: "BrokerageGuide" },
];

export const editRoles: StringOption[] = [
  { label: "Interno", value: "Intern" },
  { label: "Mostrador Arriendo", value: "RentGuide" },
  { label: "Mostrador Venta", value: "SalesGuide" },
  { label: "Advisor", value: "Advisor" },
  { label: "Leads Enabled", value: "LeadsEnabled" },
  { label: "Fotógrafo Venta", value: "SalesPhotographer" },
  { label: "Fotógrafo Renta", value: "RentPhotographer" },
  { label: "Inspector", value: "Inspector" },
  { label: "Inspector de Salida", value: "ExitInspector" },
  { label: "Houmer", value: "Houmer" },
  { label: "Mostrador Canje", value: "BrokerageGuide" },
];

export const contracts: StringOption[] = [
  { label: "Freelance", value: "New_freelance" },
  { label: "Hired", value: "Hired" },
];

export const haContracts: StringOption[] = [...contracts, { label: "B2B", value: "B2B" }];

export const appraiserStatus: BasicOption[] = [
  { label: "Activo", value: true },
  { label: "Desactivado", value: false },
];
