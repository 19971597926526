import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: { width: "100%", height: "100%" },
  label: { textTransform: "none", color: theme.palette.grey[200] },
  ownerName: {
    cursor: "pointer",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: "0.875rem",
    textAlign: "center",
    width: "100%",
  },
}));
