import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import newEvaluationService from "services/newEvaluationService";
import { useCreateLandlordEvaluation, useGetToken } from "hooks";
import { Locale } from "models/Countries";

interface FormData {
  contractId: number;
  selectedDate: string;
  habitants: string;
  mainApplicantId: number;
}

const useUpdateEvaluationContract = () => {
  const { country } = useParams<{ country: Locale }>();
  const { getToken } = useGetToken();
  const { createLandlordEvaluation, loading: loadingCreateEval } = useCreateLandlordEvaluation();

  const { mutate: updateEvaluation, isLoading: isLoadingUpdateEval } = useMutation(
    async (formData: FormData) => {
      const authToken = await getToken();
      const userData = JSON.parse(localStorage.getItem("user"));
      await newEvaluationService.addContract(
        formData.mainApplicantId,
        formData.contractId,
        userData.id,
        authToken,
        country
      );
    },
    {
      onSuccess: (_, data) =>
        createLandlordEvaluation({
          mainApplicantId: data.mainApplicantId,
          initDate: data.selectedDate as string,
          habitants_description: data.habitants,
          contractId: data.contractId,
          country,
        }),

      onError: (e: Error) => {
        const error = e as AxiosError;
        const { response } = error;
        Swal.fire(
          "Error",
          `No se pudo crear contrato, error: ${JSON.stringify(response?.data) ?? e}`,
          "error"
        );
      },
    }
  );

  return {
    updateEvaluation,
    isLoading: isLoadingUpdateEval || loadingCreateEval,
  };
};

export default useUpdateEvaluationContract;
