import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Button from "@houm-com/ui/Button";
import FieldDate from "@houm-com/ui/FieldDate";

import { IPromotion } from "models/Promotions";

import useEditPromotion from "../../hooks/useEditPromotion";
import { yupResolver } from "@hookform/resolvers/yup";
import { promotionSchema } from "./validationSchema";
import classes from "./EditPromotionFormStyles";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";

type EditPromotionFormProps = {
  promotion: IPromotion;
  handleCloseModal: () => void;
};

type IFormData = {
  initDate: string;
  endDate: string;
  status: string;
};

export default function EditPromotionForm({ promotion, handleCloseModal }: EditPromotionFormProps) {
  const { editPromotion, isEditLoading, isSuccess } = useEditPromotion();
  const methods = useForm<IFormData>({
    resolver: yupResolver(promotionSchema()),
    reValidateMode: "onBlur",
    defaultValues: {
      initDate: promotion.initDate,
      endDate: promotion.endDate,
      status: promotion.active ? "active" : "inactive",
    },
  });
  const { errors } = methods.formState;

  useEffect(() => {
    if (isSuccess) {
      handleCloseModal?.();
    }
  }, [isSuccess, handleCloseModal]);

  const onSubmit = async (data: IFormData) => {
    editPromotion({
      data: {
        start_date: data.initDate,
        end_date: data.endDate,
        active: data.status === "active",
      },
      promotionId: promotion.id,
    });
  };

  const promotionActiveOptions = [
    { label: "Activo", value: "active" },
    { label: "Inactivo", value: "inactive" },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={classes.inputContainer}>
          <FieldDate
            id="initDate"
            label="Fecha de inicio"
            defaultValue={new Date(`${promotion.initDate}T00:00:00`)}
            required
            errorMessage={errors.initDate?.message}
            {...methods.register("initDate")}
            onChange={(value) => {
              methods.setValue("initDate", value, { shouldValidate: true });
            }}
          />
        </div>
        <div className={classes.inputContainer}>
          <FieldDate
            id="endDate"
            label="Fecha de término"
            defaultValue={new Date(`${promotion.endDate}T00:00:00`)}
            required
            {...methods.register("endDate")}
            onChange={(value) => {
              methods.setValue("endDate", value, { shouldValidate: true });
              methods.trigger("initDate");
            }}
          />
        </div>
        <div className={classes.inputContainer}>
          <FieldRadioButton
            id="status"
            label="Estado"
            options={promotionActiveOptions}
            required
            {...methods.register("status")}
          />
        </div>
        <div className={classes.cancelButtonContainer}>
          <Button size="md" variant="tertiary" onClick={handleCloseModal} disabled={isEditLoading}>
            Cancelar
          </Button>
          <Button
            size="md"
            variant="primary"
            type="submit"
            className={classes.submitButton}
            isLoading={isEditLoading}
            disabled={isEditLoading || !!errors.initDate}>
            Guardar
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
