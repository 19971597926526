import { Dispatch, SetStateAction, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useFormNotifications, useGetToken } from "hooks";
import { fromToOptions } from "utils/fromToOptions";
import {
  ControlledTextField,
  ControlledSelection,
  ControlledDropArea,
  ControlledCheckbox,
} from "Components/Admin/UIComponents/ControlledForm";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { NewLayoutForm } from "context/projectContext/projectTypes";
import { useProject } from "context/projectContext/projectContext";
import { editLayout, postLayout, uploadLayoutImage } from "context/projectContext/projectActions";
import { isImageNew } from "./LayoutsUtils";
import validationSchema, { requiredText } from "./validationSchema";
import useLayoutFormStyles from "./useLayoutFormStyles";

interface Props {
  propId: number;
  model?: NewLayoutForm;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

function LayoutForm({ propId, handleClose, model = null }: Props) {
  const classes = useLayoutFormStyles();
  const { state, dispatch } = useProject();
  const { getToken } = useGetToken();

  const methods = useForm<NewLayoutForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<NewLayoutForm> = async (data, e) => {
    let filePath = "";
    const authToken = await getToken();
    if (isImageNew(data.image[0])) {
      filePath = await uploadLayoutImage(propId, data, authToken);
      if (!filePath) return;
    }
    if (model?.id) {
      await editLayout(propId, data, filePath, model?.id, dispatch, authToken);
    } else {
      await postLayout(propId, data, filePath, dispatch, authToken);
    }
    handleClose(false);
  };

  useEffect(() => {
    if (methods.watch("rooms")) methods.setValue("roomsOther", "");
  }, [methods.watch("rooms")]);
  useEffect(() => {
    if (methods.watch("roomsOther")?.length > 0) methods.setValue("rooms", null);
  }, [methods.watch("roomsOther")]);
  useEffect(() => {
    if (methods.watch("bathrooms")) methods.setValue("bathroomsOther", "");
  }, [methods.watch("bathrooms")]);
  useEffect(() => {
    if (methods.watch("bathroomsOther")?.length > 0) methods.setValue("bathrooms", null);
  }, [methods.watch("bathroomsOther")]);

  const { errors } = methods.formState;

  useFormNotifications<NewLayoutForm>({ errors, requiredText });

  return (
    <FormProvider {...methods} key="LayoutForm">
      <form key="NewLayoutForm" onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
        <Grid container>
          <Grid item container xs={3} className={classes.formContainer}>
            <Grid item xs={12} className={classes.formItem}>
              <ControlledTextField
                id="name"
                label="Nombre de la tipología"
                defaultValue={model?.name}
                textFieldProps={{
                  placeholder: "Nombre",
                  InputProps: { disableUnderline: false },
                }}
                labelClassName={classes.titleLabel}
              />
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <ControlledDropArea
                id="image"
                label="Plano de la tipología"
                subtitle={
                  model?.image
                    ? "(Sube o arrastra la nueva tipología)"
                    : "(Sube o arrastra la tipología)"
                }
                defaultValue={model && [model?.image]}
                labelProps={classes.titleLabel}
              />
              <Typography component="span" className={classes.subtitle}>
                Formatos de imagen: jpg, png, pdf, jpeg
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={9}>
            <h4 className={classes.title}>Superficie</h4>
            <Grid item container xs={12}>
              <Grid item xs={5} className={classes.formItem}>
                <ControlledTextField
                  id="totalArea"
                  label="Total"
                  defaultValue={model?.totalArea}
                  textFieldProps={{
                    placeholder: "231",
                    InputProps: {
                      disableUnderline: false,
                      endAdornment: (
                        <Typography component="span" className={classes.inputAdornment}>
                          m2
                        </Typography>
                      ),
                    },
                  }}
                  labelClassName={classes.label}
                />
              </Grid>
              <Grid item xs={5} className={classes.formItem}>
                <ControlledTextField
                  id="usableArea"
                  label="Construida"
                  defaultValue={model?.usableArea}
                  textFieldProps={{
                    placeholder: "160",
                    InputProps: {
                      disableUnderline: false,
                      endAdornment: (
                        <Typography component="span" className={classes.inputAdornment}>
                          m2
                        </Typography>
                      ),
                    },
                  }}
                  labelClassName={classes.label}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={5} className={classes.formItem}>
                <ControlledSelection
                  id="rooms"
                  label="Dormitorios"
                  defaultValue={model?.rooms < 6 ? model?.rooms : null}
                  optionWidth="30px"
                  options={fromToOptions(1, 5)}
                  round
                  classes={{
                    container: classes.itemContent,
                    label: classes.titleLabel,
                  }}
                />
                <ControlledTextField
                  id="roomsOther"
                  label=""
                  defaultValue={model?.rooms > 5 ? model?.rooms : null}
                  textFieldProps={{
                    placeholder: "Otro (solo números)",
                    InputProps: { disableUnderline: false },
                    type: "number",
                  }}
                />
                <ControlledCheckbox
                  id="studio"
                  label="Estudio"
                  justifyContent="flex-start"
                  placement="end"
                  checkboxProps={{ className: classes.studio }}
                  defaultChecked={model?.studio}
                />
              </Grid>
              <Grid item xs={5} className={classes.formItem}>
                <ControlledSelection
                  id="bathrooms"
                  round
                  label="Baños"
                  defaultValue={model?.bathrooms < 6 ? model?.bathrooms : null}
                  optionWidth="30px"
                  options={fromToOptions(1, 5)}
                  classes={{
                    container: classes.itemContent,
                    label: classes.titleLabel,
                  }}
                />
                <ControlledTextField
                  id="bathroomsOther"
                  label=""
                  defaultValue={model?.bathrooms > 5 ? model?.bathrooms : null}
                  textFieldProps={{
                    placeholder: "Otro (solo números)",
                    InputProps: { disableUnderline: false },
                    type: "number",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignContent="center"
              justifyContent="flex-end"
              className={classes.buttonsContainer}>
              <LinkButton onClick={() => handleClose(false)}>Cancelar</LinkButton>
              <DesignSystemButton
                disabled={state.loadingUpload}
                type="submit"
                variant="tertiaryA"
                size="small"
                label="Guardar"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default LayoutForm;
