import { PriceChip } from "./CurrentPriceStyles";

interface Props {
  price?: string;
}

function CurrentPrice({ price = "" }: Props) {
  return <PriceChip>{price}</PriceChip>;
}

export default CurrentPrice;
