import { useState, useEffect } from "react";

import { Modal } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Swal from "sweetalert2";

import { useDashboard } from "context/controlTower/controlTowerContext";
import CreateEventForm from "./EventForm";
import useCalendarStyles from "./CalendarStyles";
import { EVENTS_DISPLAY } from "context/controlTower/controlTowerConsts";
import { parseFromUtc } from "utils/datetime";
import { useQueryParams } from "hooks";

let calendar;

interface EventState {
  appraiserId: string;
  beginDate?: string;
  endDate?: string;
  type?: string;
  propId?: number;
  email?: string;
  eventID?: number;
  comment?: string;
  country?: string;
  status?: string;
  address?: string;
}

interface Props {
  appraiserId?: string;
}

function AppraiserCalendar({ appraiserId = "" }: Props) {
  const query = useQueryParams();
  const rescheduleIdQuery = query.get("reschedule");
  const [visible, setVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventState>({
    appraiserId,
    beginDate: "",
    endDate: "",
    type: "",
    propId: undefined,
    email: "",
    eventID: undefined,
    comment: "",
    country: "",
    status: "",
    address: "",
  });
  const { state: dashboardStates } = useDashboard();
  const { appraiserEvents, appraiserProfile } = dashboardStates;
  const classes = useCalendarStyles();

  const createCalendar = () => {
    const calendarEl = document.getElementById("calendar");
    calendar = new Calendar(calendarEl, {
      plugins: [interactionPlugin, dayGridPlugin],
      selectable: true,
      editable: true,
      headerToolbar: false,
      eventClick: ({ event }) => {
        setSelectedEvent({
          appraiserId,
          beginDate: event.startStr,
          endDate: event.endStr,
          type: event.extendedProps.eventType,
          propId: event.extendedProps.prop_id,
          email: event.extendedProps.email,
          eventID: parseInt(event.id),
          comment: event.extendedProps.comment,
          country: event.extendedProps.country,
          status: event.extendedProps.status,
          address: event.extendedProps.address,
        });
        setVisible(true);
      },
      select: (event) => {
        setSelectedEvent({
          ...selectedEvent,
          appraiserId,
          beginDate: event.startStr,
          country: dashboardStates.appraiserProfile.country,
        });
        setVisible(true);
      },
    });
    calendar.render();
  };

  const fetchSchedules = async () => {
    try {
      appraiserEvents.forEach((item, key) => {
        const parsedDate = parseFromUtc(item.begin_date, item.end_date, appraiserProfile.timezone);
        if (item.id == rescheduleIdQuery) {
          setSelectedEvent({
            appraiserId,
            beginDate: `${parsedDate.date} ${parsedDate.begin_hour}`,
            endDate: `${parsedDate.date} ${parsedDate.end_hour}`,
            type: item.type,
            propId: parseInt(item.prop_id),
            email: item.applicant?.email,
            eventID: parseInt(item.id),
            comment: item.comment,
            country: item.prop_country,
            status: item.status,
            address: item.address,
          });
          setVisible(true);
        }
        calendar.addEvent({
          eventType: item.type,
          prop_id: item.prop_id,
          title: item.title,
          start: `${parsedDate.date} ${parsedDate.begin_hour}`,
          end: `${parsedDate.date} ${parsedDate.end_hour}`,
          id: item.id,
          email: item?.applicant?.email,
          comment: `${item?.comment} ${item?.address}`,
          backgroundColor: EVENTS_DISPLAY[item.status],
          display: item.completed && "none",
          country: item.prop_country,
          status: item.status,
          address: item.address,
        });
      });
      calendar.render();
    } catch {
      Swal.fire({
        type: "error",
        title: "Error",
        text: "No se pudieron cargar los horarios",
      });
    }
  };

  useEffect(() => {
    createCalendar();
    fetchSchedules();
  }, []);

  return (
    <>
      <div className={classes.arrowsContainer}>
        <span
          onClick={() => {
            calendar.prev();
          }}
          id="hoverable">
          <ArrowBack className={classes.arrowBack} />
          Mes anterior
        </span>
        <span
          onClick={() => {
            calendar.next();
          }}
          id="hoverable">
          Siguiente mes
          <ArrowForward className={classes.arrowForward} />
        </span>
      </div>
      <div className="calendar" data-toggle="calendar" id="calendar" />
      <Modal
        open={visible}
        onClose={() => {
          setVisible(false);
          setSelectedEvent({
            appraiserId,
            beginDate: "",
            endDate: "",
            type: "",
            propId: undefined,
            email: "",
            eventID: undefined,
            comment: "",
            country: "",
            status: "",
          });
        }}
        className={classes.modal}>
        <CreateEventForm
          setShow={setVisible}
          appraiserId={selectedEvent.appraiserId}
          beginDate={selectedEvent.beginDate}
          endDate={selectedEvent.endDate}
          type={selectedEvent.type}
          propId={selectedEvent.propId}
          email={selectedEvent.email}
          eventID={selectedEvent.eventID}
          country={selectedEvent.country}
          status={selectedEvent.status}
          address={selectedEvent.address}
        />
      </Modal>
    </>
  );
}

export default AppraiserCalendar;
