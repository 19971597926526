import { useGetToken } from "hooks";
import { UseQueryResult, useQuery } from "react-query";
import schedulesService from "services/schedulesService";
import { IAppraiser } from "services/schedulesService/types";

const useGetAppraisers = () => {
  const { getToken } = useGetToken();

  const {
    data: appraisers,
    isLoading: appraisersLoading,
    isError: appraisersError,
  }: UseQueryResult<IAppraiser[], Error> = useQuery<IAppraiser[], Error>(
    "allAppraisers",
    async () => {
      const authToken = await getToken();
      const response = await schedulesService.getAppraisers(authToken);
      return response.data;
    }
  );

  return {
    appraisers,
    appraisersLoading,
    appraisersError,
  };
};

export default useGetAppraisers;
