import { useMemo } from "react";
import { GridColDef, GridColumns } from "@material-ui/x-grid";

import { CustomDataGrid, CustomLink } from "Components/Admin/UIComponents";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import ActivityFormModal from "./ActivityFormModal";
import CompleteActivityButton from "./CompleteActivityButton";
import ActivityTokyCallButton from "./ActivityTokyCallButton";
import TokyCallTableCellInfo from "Components/Shared/Toky/partials/TokyCallTableCellInfo";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { useLead } from "context/leadContext/leadContext";
import { showActivityModalForm, setSelectedActivity } from "context/leadContext/leadActions";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { contactMediums, subjects } from "./LeadQualificationConsts";
import { getObjectLabel } from "utils";
import useStyles from "./ActivitiesTableStyles";
import { getCodelessNumber, getCountryPhone } from "utils/verifyPhoneNumber";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "left",
};

function ActivitiesTable() {
  const classes = useStyles();
  const { state, dispatch: leadDispatch } = useLead();
  const { lead, loading, leadActivitiesInfo } = state;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "created_at",
        headerName: "Creación",
        sortable: true,
        align: "center",
        renderCell: ({ row }) => row?.createdAt,
      },
      {
        ...columnsConfig,
        field: "end_date",
        headerName: "Vencimiento",
        sortable: true,
        align: "center",
        renderCell: ({ row }) => row?.endDate,
      },
      {
        ...columnsConfig,
        field: "property",
        headerName: "ID Propiedad",
        sortable: false,
        renderCell: ({ row }) => {
          if (!row?.property) return "";
          return (
            <CustomLink to={`/admin/properties/${row?.property?.uid}`} text={row?.property?.id} />
          );
        },
      },
      {
        ...columnsConfig,
        field: "contact_medium",
        headerName: "Medio contacto",
        sortable: false,
        renderCell: ({ row }) => getObjectLabel(contactMediums, row?.contactMedium),
      },
      {
        ...columnsConfig,
        field: "subject",
        headerName: "Asunto",
        sortable: false,
        renderCell: ({ row }) => getObjectLabel(subjects, row?.subject),
      },
      {
        ...columnsConfig,
        field: "status",
        headerName: "Estado",
        sortable: false,
        renderCell: ({ row }) => row?.status,
      },
      {
        ...columnsConfig,
        field: "is_completed",
        headerName: "Completado",
        sortable: false,
        renderCell: ({ row }) => (row?.isCompleted ? "Si" : "No"),
      },
      {
        ...columnsConfig,
        field: "tokyCallInfo",
        headerName: "Info Llamada",
        sortable: false,
        renderCell: ({ row }) => (
          <TokyCallTableCellInfo callInfo={row?.tokyCall} doesCallExists={!!row?.tokyCallId} />
        ),
      },
      {
        ...columnsConfig,
        field: "callToAction",
        headerName: "Acciones",
        sortable: false,
        renderCell: ({ id, row }) => {
          const shouldShowTokyButton = row?.contactMedium === "Call" && !row?.isCompleted;
          const formatTargetDialNumber = {
            country: getCountryPhone(lead.country),
            phone: getCodelessNumber(state.lead?.phone, lead.country),
          };
          return (
            <>
              <LinkButton onClick={() => handleOnEditActivityClick(row)}>Editar</LinkButton>
              {shouldShowTokyButton ? (
                <ActivityTokyCallButton
                  targetDialNumber={formatTargetDialNumber}
                  activityId={id as number}
                />
              ) : (
                <CompleteActivityButton activityId={id as number} isCompleted={row?.isCompleted} />
              )}
            </>
          );
        },
      },
    ],
    [lead]
  );

  const handleOnEditActivityClick = (selectedActivity) => {
    leadDispatch(setSelectedActivity(selectedActivity));
    leadDispatch(showActivityModalForm("edit"));
  };

  return (
    <Loading loading={leadActivitiesInfo?.isLoadingActivities}>
      <>
        <div className={classes.container}>
          <CustomDataGrid
            rowCount={leadActivitiesInfo?.count}
            columns={columns}
            rows={leadActivitiesInfo?.results?.length ? leadActivitiesInfo.results : []}
            loading={loading}
            page={currentPage - 1}
            pageSize={pageSize}
            onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
            onSortModelChange={(model) => dispatch(setSorting(model))}
            onPageSizeChange={(params) => {
              dispatch(setPageSize(params));
              dispatch(setCurrentPage(1));
            }}
          />
        </div>
        <ActivityFormModal />
      </>
    </Loading>
  );
}

export default ActivitiesTable;
