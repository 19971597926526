import { Grid } from "@material-ui/core";
import { useProperty } from "context/propertyContext/propertyContext";
import useExpandedDialStyles from "./ExpandedButtonStyles";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { setIsShorterForm } from "context/propertyContext/propertyActions";

function ExpandedButton() {
  const {
    state: { isShorterForm },
    dispatch,
  } = useProperty();
  const classes = useExpandedDialStyles();
  return (
    <Grid item>
      <div className={classes.root}>
        <CustomButton
          id="expanded"
          kind="main"
          active
          className={classes.expandedButton}
          onClick={() => dispatch(setIsShorterForm(!isShorterForm))}>
          {isShorterForm ? "Ver más" : "Ver menos"}
        </CustomButton>
        <CustomButton id="send-property" kind="main" type="submit" active>
          Enviar
        </CustomButton>
      </div>
    </Grid>
  );
}
export default ExpandedButton;
