import { parseInt } from "lodash";
import moment from "moment";
import { utcToZonedTime, format } from "date-fns-tz";
import { isSameDay } from "date-fns";

import { CountryUpperCase, Locale, Timezones } from "models/Countries";
import { TimeBlock, NewAvTimeBlock } from "context/controlTower/controlTowerTypes";
import { defaultHours } from "Components/Admin/administrator/leads/leadProfile/LeadUtils";
import {
  Availability as NewAvailability,
  NewAppraiserAvailabilityBody,
} from "services/appraiserService/types";

interface Availability {
  [x: string]: string[];
}

export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

/* Months in javascript move from 0 to 11 **/
export const optionMonths = months.map((m, index) => ({
  label: m,
  value: index,
}));

const getToday = (timezone: Timezones | null): moment.Moment => {
  if (timezone) {
    return moment.utc().tz(timezone);
  }
  return moment();
};

export const notTodayTZ = (item: string[], timezone?: Timezones): boolean => {
  const today = getToday(timezone);
  if (item.length) {
    return !moment(item[item.length - 1]).isSame(today, "day");
  }
  return false;
};

export const notToday = (item) => {
  const today = moment();
  if (item.length) {
    return !moment(item[item.length - 1]).isSame(today, "date");
  }
  return false;
};

export const notTomorrowIfAlreadyPastMidday = (item) => {
  const today = moment();
  if (today.hour() >= 12 && item.length) {
    return !moment(item[item.length - 1]).isSame(today.add(1, "days"), "date");
  }
  return true;
};

export const notTomorrowIfAlreadyPastSixPM = (item) => {
  const today = moment();
  if (today.hour() >= 18 && item.length) {
    return !moment(item[item.length - 1]).isSame(today.add(1, "days"), "date");
  }
  return true;
};

export const getWeekDayName = (weekNumber: string | number): string => {
  const weekDays = {
    0: "Domingo",
    1: "Lunes",
    2: "Martes",
    3: "Miércoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sábado",
  };

  return weekDays[weekNumber];
};

export const getMonthName = (monthNumber: string | number): string => {
  const months = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  return months[monthNumber];
};

export const Countries = [
  { label: "Chile", value: "Chile" },
  { label: "México", value: "Mexico" },
  { label: "Colombia", value: "Colombia" },
];

export const parseWeekAndMonth = (date: string): string => {
  const momentDate = moment(date);
  const weekDay = getWeekDayName(momentDate.day());
  const day = momentDate.format("D");
  const month = getMonthName(momentDate.format("M"));

  return `${weekDay} ${day} de ${month}`;
};

export const parseFromUtc = (begin, end, timezone) => {
  const newBeginHour = moment.utc(begin, "YYYY-MM-DDTHH:mm").tz(timezone).format("HH:mm:ss");
  const newEndHour = moment.utc(end, "YYYY-MM-DDTHH:mm").tz(timezone).format("HH:mm:ss");
  const date = moment.utc(begin, "YYYY-MM-DD HH:mm").tz(timezone).format("YYYY-MM-DD");
  return { begin_hour: newBeginHour, end_hour: newEndHour, date };
};

export const parseToUtc = (start, end, timezone) => {
  const beginHourString = moment
    .tz(start, "YYYY-MM-DD HH:mm:ss", timezone)
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
  const endHourString = moment
    .tz(end, "YYYY-MM-DD HH:mm:ss", timezone)
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
  return { begin_hour: beginHourString, end_hour: endHourString };
};

export const parseStringToDay = (day: [string, boolean], timezone: string, truncate = true) => {
  const dayInfo = day[0].split("_");
  const beginHourString = moment
    .tz(dayInfo[1], "HH:mm:ss", timezone)
    .utc()
    .format(!truncate ? "HH:mm:ss" : "HH");
  const endHourString = moment
    .tz(dayInfo[2], "HH:mm:ss", timezone)
    .utc()
    .format(!truncate ? "HH:mm:ss" : "HH");

  if (truncate)
    return Object({
      begin_hour: parseInt(beginHourString, 10),
      end_hour: parseInt(endHourString, 10),
      day: parseInt(day[0], 10),
    });

  return Object({
    begin_hour: beginHourString,
    end_hour: endHourString,
    day: parseInt(day[0], 10),
  });
};

export const parseStringToSpecificDate = (day: [string, boolean], timezone: string) => {
  const dayInfo = day[0].split("_");
  const beginDateString = moment
    .tz(dayInfo[0], "YYYY-MM-DD HH:mm:ss", timezone)
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
  const endDateString = moment
    .tz(dayInfo[1], "YYYY-MM-DD HH:mm:ss", timezone)
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
  return {
    begin_date: beginDateString,
    end_date: endDateString,
  } as NewAvailability;
};

/**
 *
 * @param compressedAvailabilityInUTC Array of objects with begin_hour and end_hour (in UTC and compressed)
 * @param timezone String: the timezone
 * @param count Number: total of hours in the full availability
 * @returns An object with parsed availability in correct Timezone and count of hours
 */
export const parseNewAvailability = (
  compressedAvailabilityInUTC: NewAvTimeBlock[],
  timezone: Timezones,
  count: number
) => {
  //Format the array from UTC to selected Timezone
  const formatToTimeZoneFromUtc = compressedAvailabilityInUTC.map((item) => {
    const beginDateInAppraiserTimeZone = moment(item.begin_date)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    const endDateInAppraiserTimeZone = moment(item.end_date)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return {
      begin_date: beginDateInAppraiserTimeZone,
      end_date: endDateInAppraiserTimeZone,
    };
  });
  /*
   * Decompress the array, for example, compressed date: [{begin_date: 2022-07-13 10:00:00, end_date: 2022-07-13 13:00:00}]
   * will be decompressed to: [
   *  {begin_date: 2022-07-13 10:00:00, end_date: 2022-07-13 11:00:00},
   *  {begin_date: 2022-07-13 11:00:00, end_date: 2022-07-13 12:00:00},
   *  {begin_date: 2022-07-13 12:00:00, end_date: 2022-07-13 13:00:00},
   * ]
   */
  const spreadHoursInThisDay = formatToTimeZoneFromUtc.map((item) => {
    const fullAvailableHoursSpread = [
      {
        begin_date: moment(item.begin_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(item.begin_date).add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
      },
    ];
    let stop = moment(item.begin_date).format("YYYY-MM-DD HH:mm:ss");
    const stopCondition = moment(item.end_date).add(-1, "hours").format("YYYY-MM-DD HH:mm:ss");

    while (stop !== stopCondition) {
      stop = moment(stop).add(1, "hours").format("YYYY-MM-DD HH:mm:ss");
      fullAvailableHoursSpread.push({
        begin_date: stop,
        end_date: moment(stop).add(1, "hours").format("YYYY-MM-DD HH:mm:ss"),
      });
    }
    return fullAvailableHoursSpread;
  });
  return { availability: spreadHoursInThisDay.flat(), count };
};

export const parseScheduleAvailability = (
  availability: Availability,
  timezone: string,
  handleFilter?: (item: string[], timezone: Timezones) => boolean
) => {
  let parsedAvailability: Record<string, string[]> = {};
  Object.values(availability || {})
    .reduce((prev, current) => [...prev, ...current], [])
    .forEach((day) => {
      const timezoneDay = moment.utc(day).tz(timezone);

      const parsedDay = timezoneDay.format("yyyy-MM-DD");
      if (parsedDay in parsedAvailability) {
        parsedAvailability[parsedDay].push(timezoneDay.format());
      } else {
        parsedAvailability[parsedDay] = [timezoneDay.format()];
      }
    });
  parsedAvailability = Object.fromEntries(
    Object.entries(parsedAvailability || {}).filter(
      handleFilter
        ? ([_, value]) => handleFilter(value, timezone as Timezones)
        : ([_, value]) => notTomorrowIfAlreadyPastSixPM(value) && notToday(value)
    )
  );

  return parsedAvailability;
};

export const getTimezone = (country: CountryUpperCase): Timezones => {
  const countryToZone = {
    Chile: "America/Santiago",
    México: "America/Mexico_City",
    Mexico: "America/Mexico_City",
    Colombia: "America/Bogota",
  };
  return (countryToZone[country] as Timezones) || "America/Santiago";
};

export const getTimezoneByCountryCode = (country: Locale): Timezones => {
  const countryToZone = {
    cl: "America/Santiago",
    mx: "America/Mexico_City",
    co: "America/Bogota",
  };
  return (countryToZone[country] as Timezones) || "America/Santiago";
};

export const getTimezoneWithCommune = (country: CountryUpperCase, commune: string): Timezones => {
  if (commune === "Tijuana") return "America/Tijuana";
  return getTimezone(country);
};
export const getTimezoneWithCommuneFormatted = (
  country: CountryUpperCase,
  commune: string
): string => {
  const timezone = getTimezoneWithCommune(country, commune);
  return timezone.replace("/", ", ").replace("_", " ");
};

export const parseDateToString = (date: string, timezone: Timezones) =>
  moment.utc(date).tz(timezone).format("YYYY-MM-DD");

const dailyFullAvailability = (day: number, timezone: Timezones) =>
  defaultHours.slice(0, -1).map((item, i) => ({
    begin_hour: parseInt(moment.tz(defaultHours[i].label, "HH", timezone).utc().format("HH")),
    end_hour: parseInt(
      moment
        .tz(defaultHours[i + 1].label, "HH", timezone)
        .utc()
        .format("HH")
    ),
    day,
  }));

export const fullAvailability = (timezone: Timezones): TimeBlock[] => {
  const fullAvailability = [];
  const days = [...Array(7).keys()].map((i) => i + 1);
  days.forEach((item) => {
    fullAvailability.push(...dailyFullAvailability(item, timezone));
  });
  return fullAvailability;
};

export const getLastDayInArray = (array: NewAppraiserAvailabilityBody): string => {
  const lastDay = array.availability[array.availability.length - 1];
  return moment(lastDay?.end_date).format("DD/MM/YYYY");
};

export const isWeekendDay = (date) => date.getDay() === 0 || date.getDay() === 6;

export const isCurrentDateAllowed = (date: string) => {
  const formatDate = new Date(date);
  const today = new Date();
  const countryTimeZone = getTimezoneByCountryCode("cl");
  const countryTime = utcToZonedTime(today, countryTimeZone);
  const hourInCountry = format(countryTime, "H", { timeZone: countryTimeZone });
  return !(isSameDay(formatDate, today) && Number(hourInCountry) >= 12);
};
