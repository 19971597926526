import { makeStyles } from "@material-ui/core";

const useCustomLoadingButtomStyles = makeStyles((theme) => ({
  buttonNext: {
    borderRadius: 50,
    minWidth: 140,
    padding: theme.spacing(1, 2),
    minHeight: 40,
    textAlign: "center",
    textTransform: "none",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    "&$disabled": {
      backgroundColor: theme.palette.grey[200],
      color: "white",
    },
  },
  disabled: {},
}));

export default useCustomLoadingButtomStyles;
