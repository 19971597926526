import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useDiscountsStyles = (nbRows: number = 0) =>
  makeStyles((theme: Theme) => {
    const ROW_HEIGHT = 48;
    const ROW_MARGIN = 8;
    const containerHeight = (nbRows + 1) * (ROW_HEIGHT + ROW_MARGIN);
    const defaultBorderRadius = "8px";

    return createStyles({
      root: {
        minHeight: containerHeight,
        "&.MuiDataGrid-root .MuiDataGrid-autoHeight": {
          height: "none !important",
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-virtualScrollerContent": {
          paddingBottom: nbRows * 8, // to compensate space between rows
          boxSizing: "content-box",
        },
        "&.MuiDataGrid-root .MuiDataGrid-viewport": {
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-root .MuiDataGrid-renderingZone": {
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
          marginBottom: theme.spacing(1),
          border: 0,
          backgroundColor: "rgba(255, 0, 255, 0.20)",
        },
        "& .MuiDataGrid-columnsContainer": {
          borderWidth: 0,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
          border: `1px solid ${theme.palette.grey[50]}`,
          borderRadius: 8,
          marginTop: theme.spacing(1),
          backgroundColor: "white",
          width: "100%",
          "&:hover": {
            backgroundColor: `${theme.palette.grey[10]} !important`,
          },
        },
        "& .MuiDataGrid-cell": {
          borderBottomWidth: 0,
          borderTopWidth: 0,
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
      },
      headerWrapper: {
        backgroundColor: theme.palette.grey[50],
        fontSize: theme.typography.pxToRem(14),
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: theme.palette.grey[500],
          borderBottomWidth: 0,
        },
      },
      firstHeaderCell: {
        borderTopLeftRadius: defaultBorderRadius,
        borderBottomLeftRadius: defaultBorderRadius,
      },
      commentsHeaderCell: {
        "& > .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
      lastRenderedHeaderCell: {
        borderTopRightRadius: defaultBorderRadius,
        borderBottomRightRadius: defaultBorderRadius,
        "& > .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
      centerCell: {
        fontSize: theme.typography.pxToRem(12),
        borderLeft: "0 !important",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: "12%",
          right: 0,
          width: "1px",
          height: "70%",
          borderRight: "1px solid #DFE5E8",
          boxSizing: "border-box",
        },
      },
      lastRenderedRowCell: {
        borderRight: "nonce",
      },
      lastHeaderCell: {
        display: "none !important",
        maxWidth: "20px !important",
      },
      emptyHeaderName: {
        color: theme.palette.grey[50],
        height: "100%",
      },
      documentsButton: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        gap: theme.spacing(1),
      },
      documentText: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 700,
        lineHeight: 1.66,
      },
      visibleIcon: {
        width: 12,
        "& path": {
          fill: theme.palette.grey[900],
        },
      },
      documentIcon: {
        width: 10,
        "& path": {
          stroke: theme.palette.grey[900],
          strokeWidth: 2,
        },
      },
      buttonsContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1.75),
        height: 20,
      },
      trashIcon: {
        width: 18,
        height: 18,
        "& path": {
          stroke: theme.palette.primary.main,
        },
      },
      emptyData: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.57,
        marginBottom: theme.spacing(2),
      },
    });
  });

export default useDiscountsStyles;
