import { format } from "date-fns";

import { FormData } from "./types";

export const parseWarrantyCondition = (data: FormData) => ({
  ...(data.paymentDate && {
    agreed_date: format(new Date(data.paymentDate), "yyyy-MM-dd"),
  }),
  ...(data.personWarranty && {
    receiver: data.personWarranty,
  }),
});
