export const filterModalStatusOptions = [
  { label: "Agendada", value: "Scheduled" },
  { label: "Realizada", value: "Done" },
  { label: "Confirmada", value: "Confirmed" },
  { label: "En espera", value: "Waiting" },
  { label: "Cancelada", value: "Cancelled" },
];

export const filterModalThirdCoordOptions = [
  { label: "Con Coordinación", value: 1 },
  { label: "Sin Coordinación", value: 0 },
];
