import { makeStyles, Theme } from "@material-ui/core";

const useRepairsRequestPageStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 40px)",
    position: "relative",
  },
  header: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

export default useRepairsRequestPageStyles;
