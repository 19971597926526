import { Grid } from "@material-ui/core";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";

import useStyles from "./SubmitStyles";

function Submit() {
  const classes = useStyles();

  return (
    <Grid item>
      <CustomButton kind="main" type="submit" active className={classes.submitButton}>
        Enviar
      </CustomButton>
    </Grid>
  );
}

export default Submit;
