import { makeStyles, Theme } from "@material-ui/core/styles";

const useLayoutFormStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "20px",
  },
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  propertyInformationCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    minHeight: 100,
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  underlined: {
    borderBottom: "1px solid gray",
    marginBottom: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(15),
  },
  titleLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    transform: "scale(1)",
    padding: 0,
  },
  itemContent: { marginTop: 0, paddingTop: theme.spacing(1) },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  formItem: {
    padding: theme.spacing(1, 2.5, 1, 2),
  },
  formContainer: {
    borderRight: `1px solid ${theme.palette.grey[50]}`,
  },
  title: {
    padding: theme.spacing(0.5, 0, 0, 2.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
  subtitle: {
    width: "100%",
    fontWeight: 400,
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[400],
  },
  inputAdornment: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  buttonsContainer: {
    gap: theme.spacing(3),
  },
  studio: {
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
}));

export default useLayoutFormStyles;
