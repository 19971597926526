import { Box, Typography } from "@material-ui/core";

import { ReactComponent as WarningIcon } from "assets/icons/warning-icon.svg";
import useEmptyDataTagStyles from "./EmptyDataTagStyles";

interface Props {
  text?: string;
}

const EmptyDataTag = ({ text = "Faltante" }: Props) => {
  const classes = useEmptyDataTagStyles();

  return (
    <Box className={classes.tag}>
      <WarningIcon aria-label="warning" />
      <Typography className={classes.tagText}>{text}</Typography>
    </Box>
  );
};

export default EmptyDataTag;
