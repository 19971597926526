import { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import newEvaluationService from "services/newEvaluationService";
import { countryCode } from "utils";
import { useGetToken } from "hooks";

import useLinkEvaluationsFormStyles from "./LinkEvaluationsFormStyles";
import { EVALUATION_FORM_URL } from "env";

const LinkEvaluationsForm = ({ applicant }) => {
  const classes = useLinkEvaluationsFormStyles();
  const { getToken } = useGetToken();

  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState(null);

  useEffect(() => {
    (async () => {
      setLink(
        `${EVALUATION_FORM_URL}/${countryCode(applicant.property.country)}/form?property=${
          applicant.property.uid
        }&applicant=${applicant.id}&brokerage=true`
      );
      const authToken = await getToken();
      const evaluations = await newEvaluationService.getPage(
        {
          size: 1,
          relatedApplicant: applicant.id,
          token: authToken,
        },
        countryCode(applicant.property.country)
      );
      if (evaluations) {
        if (evaluations.data.evaluations.length > 0) {
          const token = await newEvaluationService.applicantEvaluationToken(
            evaluations.data.evaluations[0].main_applicant.id,
            authToken
          );
          if (token) {
            setLink(
              `https://evaluations.houm.com/${countryCode(
                applicant.property.country
              )}/evaluation?token=${token.data.token}`
            );
          }
        }
      }
      setLoading(false);
    })();
  }, [applicant.id, applicant.property.uid]);

  return (
    <AdminCard title="Documentos" showTitleBackground={true} withSpace={false}>
      <Loading loading={loading}>
        <>
          <Typography className={classes.subtitle}>Link para subir documentación:</Typography>
          <Typography component="a" href={link} className={classes.link}>
            {link}
          </Typography>
        </>
      </Loading>
    </AdminCard>
  );
};

export default LinkEvaluationsForm;
