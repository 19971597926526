import { useForm, FormProvider } from "react-hook-form";

import { useFeatureManager } from "@houm-com/feature-manager";

import { useClosureForm } from "context/closureFormContext/closureFormContext";
import { setAdminData, setReady } from "context/closureFormContext/closureFormActions";
import fields from "context/closureFormContext/closureFormFields";
import { AdminDataProps } from "context/closureFormContext/closureFormTypes";
import { countryCode } from "utils";

import CallToAction from "./CustomCallToAction";
import { FilteredOptions, FilteredTextField } from "./FilteredComponents";

export default function AdminDataStep() {
  const { state, dispatch } = useClosureForm();
  const stepState = state.adminData;
  const stepFields = fields.adminstration;
  const { data, status: cleaningInfoStatus } = useFeatureManager("cleaning-info", {
    marketCode: countryCode(state.propertyData.country),
  });
  const cleaningInfo = data?.content?.info;
  const methods = useForm<AdminDataProps>({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    // falta validación de datos
    data.hasHoumCleaning = data.hasHoumCleaning === "si";
    dispatch(setAdminData(data));
    dispatch(setReady("adminData"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredOptions
          id="hasHoumCleaning"
          fieldInfo={stepFields.hasHoumCleaning()}
          defaultValue={stepState.hasHoumCleaning}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
          optionsProps={{
            helperText: cleaningInfoStatus === "success" && cleaningInfo,
          }}
        />

        <FilteredTextField
          id="cleaningAmount"
          fieldInfo={stepFields.cleaningAmount()}
          defaultValue={stepState.cleaningAmount}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
