import { useParams } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import Breadcrumbs, { StepProps } from "Components/Admin/UIComponents/atoms/Breadcrumbs";
import CountryChip from "Components/Admin/UIComponents/atoms/CountryChips";
import { usePlans } from "context/plansContext/plansContext";
import { CountryUpperCase } from "models/Countries";
import useStyles from "./plansHeaderStyles";
import { useQueryParams } from "hooks";

interface Props {
  steps: StepProps[];
}

export default function PlansHeader({ steps }: Props) {
  const { state } = usePlans();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const query = useQueryParams();
  const cities = query.get("cities").split(",");
  const country = query.get("country");

  return (
    <>
      <Grid container item xs={12} alignItems="flex-start" justifyContent="space-between">
        <Breadcrumbs steps={steps} />
        <CountryChip country={country as CountryUpperCase} selected={true} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {params.id ? state.planData.name : "Crear plan"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          Aplica para: {cities.map((city, i) => `${city}${i + 1 < cities.length ? ", " : ""}`)};{" "}
          {country}
        </Typography>
      </Grid>
    </>
  );
}
