import { styled, Theme, Grid, Typography } from "@material-ui/core";

export const Root = styled(Grid)<Theme>(({ theme }) => ({
  maxWidth: "100%",
  alignItems: "center",
  margin: theme.spacing(0),
}));

export const AddressText = styled(Typography)<Theme>(({ theme }) => ({
  textAlign: "center",
  fontSize: "0.75rem",
  marginBottom: theme.spacing(0.5),
}));

export const PublisherText = styled(Typography)<Theme>(({ theme }) => ({
  fontWeight: 700,
  color: "#607D8B",
  fontSize: "0.875rem",
  textAlign: "center",
  marginBottom: theme.spacing(0.5),
}));
