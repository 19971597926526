import { Box, DialogContent } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import { Photo } from "Components/Admin/administrator/subscriptions/hooks/requests/types";

import usePhotosModalStyles from "./PhotosModalStyles";

interface Props {
  openModal: boolean;
  onClose: () => void;
  photos: Photo[];
}

const PhotosModal = ({ openModal, onClose, photos }: Props) => {
  const classes = usePhotosModalStyles();
  return (
    <DSModal
      open={openModal}
      onClose={onClose}
      showCloseButton
      scroll="paper"
      paperClassName={classes.paper}>
      <DialogContent className={classes.dialog}>
        <Carousel
          animation="slide"
          autoPlay={false}
          navButtonsAlwaysVisible
          indicatorContainerProps={{ className: classes.indicators }}>
          {photos.map((photo) => (
            <Box key={photo.url}>
              <img className={classes.image} src={photo.url} alt="repair-request-preview" />
            </Box>
          ))}
        </Carousel>
      </DialogContent>
    </DSModal>
  );
};
export default PhotosModal;
