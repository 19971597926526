import { debounce } from "lodash";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import Searchbar from "Components/Admin/administrator/contracts/contractLists/components/Searchbar/Searchbar";
import { setFilters } from "context/contractContext/contractActions";
import { useContract } from "context/contractContext/contractContext";

function SearchContract() {
  const history = useHistory();
  const { dispatch, state } = useContract();

  const setSearchFilter = (val: string) => {
    dispatch(
      setFilters({
        ...state.filters,
        search: val,
      })
    );
  };
  const debouncedSetFilters = useCallback(debounce(setSearchFilter, 700), []);

  const handleOnSearchChange = useCallback((input: string) => {
    debouncedSetFilters(input);
  }, []);

  return (
    <Searchbar
      options={state?.contractList}
      loading={state.loading}
      onInputChange={(e, val: any) => handleOnSearchChange(val)}
      placeholder="Búsqueda por ID Contrato/ID Propiedad"
      onChange={(e, val: any) => history.push(`/admin/contracts/${val?.id}`)}
      getOptionLabel={(option: any) =>
        `ID: ${option?.id}, ID Propiedad: ${option?.propertyId}, Propietario: ${option?.ownerName}, Arrendatario: ${option?.mainTenantName}`
      }
    />
  );
}

export default SearchContract;
