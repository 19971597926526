import React from "react";
import { Button as MuiButton, ButtonProps } from "@material-ui/core";

import useStyles from "./LinkButtonStyles";

interface IPropsBackButton extends ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
}

function LinkButton({ children, disabled = false, ...props }: IPropsBackButton) {
  const classes = useStyles({ disabled });

  return (
    <MuiButton {...props} disabled={disabled} className={classes.linkButton}>
      {children}
    </MuiButton>
  );
}

export default LinkButton;
