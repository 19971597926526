import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => {
  const defaultBorder = `1px solid  ${theme.palette.grey[50]} !important`;
  const defaultBorderRadius = "8px";
  return {
    container: { width: "100%", height: "100%" },
    link: {
      textDecoration: "underline !important",
      color: `${theme.palette.primary.main} !important`,
    },
    id_link: {
      textDecoration: "underline !important",
      color: "#000000 !important",
      lineHeight: 1.5,
    },
    root: {
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "white !important",
      },
      "& .MuiDataGrid-columnsContainer": {
        borderWidth: 0,
      },
      "& .MuiDataGrid-row": {
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    headerCell: {
      backgroundColor: `${theme.palette.grey["50"]} !important`, //this only works with important
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
        color: theme.palette.grey[500],
        borderBottomWidth: 0,
      },
    },
    firstHeaderCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
    },
    lastRenderedHeaderCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
    },
    leftCell: {
      borderTopLeftRadius: defaultBorderRadius,
      borderBottomLeftRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
    },
    rightCell: {
      borderTopRightRadius: defaultBorderRadius,
      borderBottomRightRadius: defaultBorderRadius,
      outline: defaultBorder,
      outlineOffset: -2,
      overflow: "hidden",
    },
    centerCell: {
      outline: defaultBorder,
      outlineOffset: -2,
      borderLeft: "0 !important",
      overflow: "hidden",
    },
    lastHeaderCell: {
      display: "none !important",
      maxWidth: "20px !important",
    },
    containerFlex: {
      display: "flex",
      flexDirection: "row",
      fontSize: theme.typography.pxToRem(14),
    },
    clickIcon: {
      marginRight: theme.spacing(1),
      cursor: "pointer",
    },
    pointer: {
      cursor: "pointer",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    name: {
      lineHeight: 1,
      marginTop: theme.spacing(1.45),
    },
    downValue: {
      lineHeight: 1.5,
    },
    asign: {
      textDecoration: "underline",
      cursor: "pointer",
      fontWeight: 700,
    },
  };
});
