import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";

import { Box, Typography } from "@material-ui/core";

import CustomTextField from "Components/Admin/UIComponents/CustomTextField";

import useFilterFieldStyles from "./FilterFieldStyles";

interface FilterFieldProps {
  id: string;
  label: string;
  defaultValue: string;
}

function FilterField({ label, id, defaultValue }: FilterFieldProps) {
  const classes = useFilterFieldStyles();
  const { control } = useFormContext();

  return (
    <Box className={classes.filterContainer}>
      <Typography className={classNames(classes.dialogText, classes.boldText)}>{label}</Typography>
      <Controller
        name={id}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { value, onChange, ref } }) => (
          <CustomTextField
            name={id}
            value={value}
            onChange={onChange}
            inputRef={ref}
            bordered
            wrapperClassName={classes.textFieldWrapper}
            textFieldClassName={classes.textField}
          />
        )}
      />
    </Box>
  );
}

export default FilterField;
