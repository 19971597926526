import { Locale } from "models/Countries";
import { useLandlordPayments } from "../../../../hooks";

const useTenantField = (propertyId: number, country: Locale) => {
  const { isLoading, error, data } = useLandlordPayments({
    propertyId,
    country,
  });
  const propertyPayment = data?.find((payment) => payment.propertyId === propertyId);
  return {
    isLoading,
    error,
    data: propertyPayment,
  };
};

export default useTenantField;
