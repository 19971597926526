import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

import { StatusMovementType } from "services/newPaymentService/types";
import { Currency, Locale } from "models/Countries";

import { INCOME, OUTFLOW } from "../../../../constants";
import Penalty from "../components/Penalty";
import ActionsMenu from "../../ActionsMenu";
import StatusMenu from "../../StatusMenu";
import { Action } from "./types";

export const defaultMovementData = {
  movementId: null,
  country: null,
  totalAmount: null,
  date: null,
  penalty: null,
  concept: null,
  movementType: null,
  movementCurrency: null,
};

export const defaultModalData = {
  openEditPaymentDateModal: false,
  openDeletePenaltyModal: false,
  openModifyOrderModal: false,
  openMarkAsPaidModal: false,
};

export const getTableColumns = (
  checkRoles: (roles: string[]) => boolean,
  handleDeletePenalty: ({
    movementId,
    country,
    penalty,
    movementCurrency,
  }: {
    movementId: string;
    country: Locale;
    penalty: number;
    movementCurrency: Currency;
  }) => void,
  availableActions: (status: StatusMovementType) => Action[]
) => [
  {
    id: "type",
    name: "Movimiento",
    width: "4%",
    renderCell: (value) => {
      switch (value) {
        case INCOME:
          return <div>Arrendatario</div>;
        case OUTFLOW:
          return <div>Propietario</div>;
        default:
          return <div>No definido</div>;
      }
    },
  },
  {
    id: "agreed_date",
    name: "Fecha acordada",
    renderCell: (value) =>
      value && (
        <>
          {format(new Date(value), "dd/MM/yyyy", {
            locale: esLocale,
          })}
        </>
      ),
  },
  {
    id: "status",
    name: "Estado",
    renderCell: (_, row) => (
      <StatusMenu
        status={row.status}
        agreedDate={row.agreed_date}
        id={row.id}
        country={row?.actions?.country as Locale}
        movementType={row.type}
      />
    ),
  },
  {
    id: "totalAmount",
    name: "Monto acordado",
  },
  {
    id: "penalty",
    name: "Multa",
    renderCell: (record, row) => (
      <Penalty
        status={row.status}
        penaltyValue={record}
        penaltyCurrency={row.currency}
        handleDeletePenalty={() =>
          handleDeletePenalty({
            movementId: row?.actions?.movement_id,
            country: row?.actions?.country,
            penalty: row?.actions?.penalty,
            movementCurrency: row.currency,
          })
        }
      />
    ),
  },
  {
    id: "payment",
    name: "Monto pagado",
  },
  {
    id: "datePayment",
    name: "Fecha de pago",
    renderCell: (value) =>
      value ? (
        <>
          {format(new Date(value), "dd/MM/yyyy", {
            locale: esLocale,
          })}
        </>
      ) : null,
  },
  checkRoles(["super_admin", "management_admin"]) && {
    id: "actions",
    name: "Acciones",
    renderCell: (record) =>
      ((record.type === "OUTFLOW" && record.status === "CANCELED") ||
        ["UNPAID", "UNSCHEDULED", "INSURANCE_MANAGEMENT"].includes(record.status)) && (
        <ActionsMenu record={record} actions={availableActions(record.status)} />
      ),
  },
];
