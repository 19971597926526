import { Controller, useFormContext } from "react-hook-form";
import clx from "classnames";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMore from "@material-ui/icons/ExpandMore";

import useStyles from "./ControlledListStyles";

export interface OptionTypes {
  value: string | number | null;
  label: string;
}

interface Props {
  defaultValue?: string | number | boolean | null;
  options: OptionTypes[];
  id: string;
  label?: React.ReactNode | string;
  optionWidth?: string;
  controllerProps?: React.ComponentProps<typeof Controller>;
  compressed?: boolean;
  disabled?: boolean;
  classes?: {
    container?: string;
    itemContainer?: string;
    label?: string;
    wrap?: string;
    select?: string;
  };
  disableUnderline?: boolean;
  placeholder?: string;
  selectClasses?: SelectProps["classes"];
  noDefaultProps?: boolean;
  iconClassName?: string;
}

function ControlledList({
  id,
  defaultValue = "",
  options,
  label = "",
  controllerProps,
  disabled = false,
  classes: propClasses,
  disableUnderline = false,
  placeholder = "Seleccionar",
  selectClasses,
  noDefaultProps = false,
  iconClassName = "",
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <FormControl className={propClasses?.container} fullWidth error={invalid}>
          <FormControlLabel
            classes={{
              label: clx(classes.label, propClasses?.label),
              root: clx(classes.wrapper, propClasses?.wrap),
            }}
            label={label}
            labelPlacement="top"
            control={
              <Select
                onChange={onChange}
                id={id}
                value={value}
                className={clx(
                  !noDefaultProps && classes.selectedItemContainer,
                  propClasses?.itemContainer,
                  propClasses?.select
                )}
                disabled={disabled}
                disableUnderline={disableUnderline}
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={() => <ExpandMore className={clx(classes.icon, iconClassName)} />}
                displayEmpty={true}
                classes={selectClasses}>
                <MenuItem disabled className={classes.item} key="placeholder" value="">
                  {placeholder}
                </MenuItem>
                {options.map((item) => (
                  <MenuItem
                    className={classes.item}
                    key={`${item.value}-${item.label}`}
                    value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <FormHelperText>{error && error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}

export default ControlledList;
