import { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { Box, Grid, Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

import { AUTH0_ROLES } from "env";
import useDetailsCardsStyles from "./DetailsCardsStyles";
import fields from "context/letterOfIntent/letterOfIntent/letterOfIntentFields";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  editBidFees,
  setNegotiation,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import { useGetToken } from "hooks";
import { parseField } from "context/letterOfIntent/letterOfIntent/utils";
import { Negotiation, BidFees } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { DetailsForm } from "../../utils/validations";
import { parseBidFees } from "../../utils/parses";
import { unparseBidFees } from "../../utils/unparses";
import InputField from "./InputField";

type FormFieldNames = "bidderPenaltyFee" | "houmBidderFee" | "ownerPenaltyFee" | "houmOwnerFee";

const FieldsArray: FormFieldNames[] = [
  "bidderPenaltyFee",
  "houmBidderFee",
  "ownerPenaltyFee",
  "houmOwnerFee",
];
const FieldInitialValues = {
  bidderPenaltyFee: 0,
  houmBidderFee: 0,
  ownerPenaltyFee: 0,
  houmOwnerFee: 0,
};

function DetailsCards() {
  const [edit, setEdit] = useState(false);
  const { user } = useAuth0();
  const roles = user[AUTH0_ROLES];
  const classes = useDetailsCardsStyles();
  const { state, dispatch } = useLetterOfIntent();
  const { country, negotiation } = state;
  const lastBid = negotiation?.lastBid;
  const bidFees = parseBidFees(lastBid);
  const { loiDetailBidder, loiDetailOwner } = fields;
  const { getToken } = useGetToken();
  const filter = (countries) => countries.includes(country);

  const filterLoiDetailBidderFields = Object.keys(loiDetailBidder).filter((key) =>
    filter(loiDetailBidder[key]().countries)
  );
  const filterLoiDetailOwnerFields = Object.keys(loiDetailOwner).filter((key) =>
    filter(loiDetailOwner[key]().countries)
  );

  const methods = useForm<BidFees>({
    resolver: yupResolver(DetailsForm),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    methods.watch(FieldsArray, FieldInitialValues);
  }, []);

  const onSubmit: SubmitHandler<BidFees> = (data) => {
    const bidFees = unparseBidFees(data);
    const negotiationData: Negotiation = {
      ...negotiation,
      lastBid: {
        ...lastBid,
        ...bidFees,
      },
    };

    setEdit(false);
    dispatch(setNegotiation(negotiationData));

    getToken().then((authToken) =>
      editBidFees({
        bidFees,
        country,
        bidId: lastBid?.id,
        token: authToken,
        negotiation: negotiationData,
        dispatch,
      })
    );
  };

  const calculateValueOnEdition = (key: string): string => {
    const percentageValue = methods?.getValues(key as FormFieldNames)
      ? Number(methods?.getValues(key as FormFieldNames)) / 100
      : lastBid[key];
    const totalValue = (lastBid.amount * percentageValue).toFixed(2);
    return `($ ${totalValue} ${lastBid.currency})`;
  };

  const calculateBidFeesValue = (key: string): string =>
    `$ ${(lastBid.amount / 100) * bidFees[key]} ${lastBid.currency}`;

  const calculatePropertyValueDiff = (): string =>
    `$ ${lastBid.amount - lastBid.depositAmount} ${lastBid.currency}`;

  const isSuperAdmin = roles.includes("superole_admin");

  if (!country || !negotiation) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography className={classes.title}>Acuerdo</Typography>

              <Typography className={classes.label}>Precio acordado</Typography>
              <Typography className={classes.value}>
                {`$ ${lastBid.amount} ${lastBid.currency}`}
              </Typography>

              <Typography className={classes.label}>Monto del pie / enganche</Typography>
              <Typography className={classes.value}>
                {`$ ${lastBid.depositAmount ?? 0} ${lastBid.currency}`}
              </Typography>

              <Typography className={classes.label}>Monto restante</Typography>
              <Typography className={classes.value}>{calculatePropertyValueDiff()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography className={classes.title}>Comprador</Typography>
              {filterLoiDetailBidderFields?.map((key) => (
                <>
                  <Typography className={classes.label} key={key}>
                    {loiDetailBidder[key]().label(country)}
                  </Typography>
                  {!edit ? (
                    <Typography className={classes.value} key={key}>
                      {parseField(key, calculateBidFeesValue(key))}
                    </Typography>
                  ) : (
                    <InputField
                      field={key}
                      value={bidFees[key]}
                      type="percentage"
                      extraInfo={calculateValueOnEdition(key)}
                    />
                  )}
                </>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.card}>
              <Typography className={classes.title}>Propietario</Typography>
              {filterLoiDetailOwnerFields?.map((key) => (
                <>
                  <Typography className={classes.label} key={key}>
                    {loiDetailOwner[key]().label(country)}
                  </Typography>
                  {!edit ? (
                    <Typography className={classes.value} key={key}>
                      {parseField(key, calculateBidFeesValue(key))}
                    </Typography>
                  ) : (
                    <InputField
                      field={key}
                      value={bidFees[key]}
                      type="percentage"
                      extraInfo={calculateValueOnEdition(key)}
                    />
                  )}
                </>
              ))}
            </Box>
          </Grid>
          {isSuperAdmin && (
            <Grid container item xs={12} justifyContent="flex-end">
              {!edit ? (
                <Box className={classes.buttonContainer}>
                  <DesignSystemButton
                    label="Editar datos"
                    size="small"
                    variant="tertiaryB"
                    buttonClassName={classes.button}
                    onClick={() => setEdit(true)}
                  />
                </Box>
              ) : (
                <Box className={classes.buttonContainer}>
                  <DesignSystemButton
                    label="Cancelar"
                    size="small"
                    variant="tertiaryB"
                    buttonClassName={classes.button}
                    onClick={() => setEdit(false)}
                  />
                  <DesignSystemButton
                    label="Guardar"
                    size="small"
                    variant="primary"
                    type="submit"
                  />
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
}

export default DetailsCards;
