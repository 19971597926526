import CreateTypology from "./CreateTypology";

import { TableProvider } from "context/tableContext/tableContext";
import { MultifamilyProvider } from "context/multifamilyContext/multifamily/multifamilyContext";
import { TypologyProvider } from "context/multifamilyContext/typology/typologyContext";

function TypologyCreate() {
  return (
    <MultifamilyProvider>
      <TypologyProvider>
        <TableProvider>
          <CreateTypology />
        </TableProvider>
      </TypologyProvider>
    </MultifamilyProvider>
  );
}
export default TypologyCreate;
