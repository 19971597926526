import { Typography } from "@material-ui/core";
import useNoQuestionsStyles from "./NoQuestionsStyles";

const NoQuestions = () => {
  const classes = useNoQuestionsStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Sin preguntas creadas</Typography>
    </div>
  );
};

export default NoQuestions;
