import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { parseDescription } from "utils/adjustmentDescription";
import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { AdjustmentFormProps } from "../components/ChargeDiscountForm/utils/types";
import { alert } from "libs/alerts";

const endpointsByAdjustmentType = {
  "1": newPaymentService.createAditional,
  "2": newPaymentService.createDiscount,
};

const useCreateChargeDiscount = (country: Locale, movementId: string, onCloseModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: AdjustmentFormProps) => {
      const authToken = await getToken();
      const response = await endpointsByAdjustmentType[formData.adjustmentType](
        country,
        movementId,
        {
          description: parseDescription(formData.description, formData.descriptionAux),
          value: formData.amount,
          ...(formData?.currency && { currency: formData.currency }),
        },
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        onCloseModal();
        alert.success({
          message: "Abono/descuento creado",
          disclaimer: "Se ha creado un abono/descuento correctamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo crear el abono/descuento",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useCreateChargeDiscount;
