import { DialogContent, DialogTitle, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { Locale } from "models/Countries";

import useRefundDetailsModalStyles from "./RefundDetailsModalStyles";
import useRefundDetails from "../../hooks/useRefundDetails";
import Details from "./components/Details";

interface Props {
  openModal: boolean;
  handleModal: () => void;
  country: Locale;
  groupId: string;
}

const RefundDetailsModal = ({ openModal, handleModal, country, groupId }: Props) => {
  const classes = useRefundDetailsModalStyles();
  const { data, isLoading, isError } = useRefundDetails(country, groupId);

  return (
    <DSModal open={openModal} onClose={handleModal} maxWidth="xs" showCloseButton scroll="paper">
      <DialogTitle>
        <Typography className={classes.title}>Detalle de las transacciones</Typography>
      </DialogTitle>
      <DialogContent>
        <Loading loading={isLoading}>
          <>
            {isError && (
              <Typography className={classes.error}>Error al obtener la información</Typography>
            )}
            {data.length > 0 && <Details reservations={data} />}
          </>
        </Loading>
      </DialogContent>
    </DSModal>
  );
};

export default RefundDetailsModal;
