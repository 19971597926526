import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";

import { Box, Typography } from "@material-ui/core";

import {
  DocumentData,
  FileStatus,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import useDocumentViewStyles from "./DocumentViewStyles";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomNumberField from "Components/Admin/UIComponents/CustomNumberField";
import { useGetToken } from "hooks";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import { changeFileStatus } from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface DocumentViewProps {
  document: DocumentData;
  spotlightPdfWidth: number;
}

function DocumentView({ document, spotlightPdfWidth }: DocumentViewProps) {
  const [pdfPage, setPdfPage] = useState(1);
  const [pdfNumPages, setPdfNumPages] = useState<number | null>(null);
  const classes = useDocumentViewStyles();

  const { getToken } = useGetToken();
  const { state, dispatch } = useLetterOfIntent();
  const { country, loiId } = state;

  const handleChangeStatus = (status: FileStatus) => {
    getToken().then((authToken) =>
      changeFileStatus({
        country,
        documentId: document.id,
        loiId,
        status,
        token: authToken,
        dispatch,
      })
    );
  };

  useEffect(() => {
    setPdfPage(1);
  }, [document]);

  const handlePdfPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (val && val > 0 && val <= pdfNumPages) {
      setPdfPage(val);
    }
  };

  if (!document)
    return (
      <Box display="flex" justifyContent="center">
        <Typography>No hay documentos</Typography>
      </Box>
    );

  return (
    <>
      <Box className={classes.buttons}>
        <a href={document.path} download={document.filename}>
          Descargar
        </a>
        <Box className={classes.validatorButtons}>
          <DesignSystemButton
            label="Invalidar"
            size="medium"
            variant="tertiaryB"
            buttonClassName={classes.secondaryButtons}
            onClick={() => handleChangeStatus(FileStatus.INVALID)}
          />
          <DesignSystemButton
            label="Validar"
            size="medium"
            variant="primary"
            onClick={() => handleChangeStatus(FileStatus.VALID)}
          />
        </Box>
      </Box>
      <Box p={2} mx={2} component="div" overflow="visible">
        {document.contentType === "application/pdf" ? (
          <>
            <Box className={classes.pdfOptions}>
              <Box className={classes.pdfOptionsBoxes}>
                <Typography>Página</Typography>
                <CustomNumberField
                  value={pdfPage}
                  className={classes.pageInput}
                  onChange={handlePdfPage}
                  label=""
                />
                <Typography>de {pdfNumPages}</Typography>
              </Box>
              <Box className={classes.pdfOptionsBoxes}>
                <DesignSystemButton
                  label="Anterior"
                  size="small"
                  disabled={pdfPage === 1}
                  onClick={() => setPdfPage(pdfPage - 1)}
                  variant="primary"
                />
              </Box>
              <Box className={classes.pdfOptionsBoxes}>
                <DesignSystemButton
                  label="Siguiente"
                  size="small"
                  disabled={pdfPage === pdfNumPages}
                  onClick={() => setPdfPage(pdfPage + 1)}
                  variant="primary"
                />
              </Box>
            </Box>
            <Document
              file={document.path}
              onLoadSuccess={({ numPages }) => setPdfNumPages(numPages)}>
              <Page pageNumber={pdfPage} width={spotlightPdfWidth} />
            </Document>
          </>
        ) : (
          <img alt={document.filename} src={document.path} className={classes.spotlightImage} />
        )}
      </Box>
    </>
  );
}

export default DocumentView;
