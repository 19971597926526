import { makeStyles, Theme } from "@material-ui/core";

const usePhotosModalStyles = makeStyles<Theme>((theme) => ({
  image: {
    width: "100%",
  },
  paper: {
    minWidth: 0,
    padding: 0,
  },
  dialog: {
    padding: 0,
  },
  indicators: {
    marginTop: -35,
  },
}));

export default usePhotosModalStyles;
