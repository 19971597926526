import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  headerTitle: {
    fontSize: "1.125rem",
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
}));

export default useStyles;
