import { SET_LOADING } from "Components/Admin/administrator/properties/context/propertiesTypes";
import {
  State,
  Action,
  SET_EVENT_TYPES,
  SET_APPRAISERS,
  SET_DOCUMENT_TYPES,
  SET_AVAILABILITY,
  SET_PROPERTY,
  SET_USER,
  SET_CONFIG_MODAL,
  SET_CANCEL_REASONS,
  SET_CANCEL_RESCHEDULE_MODAL,
  SET_ADVISORY_RESPONSE,
  SET_SCHEDULED,
  SET_USER_EMAIL,
  SET_USER_COUNTRY,
} from "./eventTypes";

export const initialState: State = {
  scheduled: false,
  cancelReasons: [],
  beginDate: "",
  endDate: "",
  cancelRescheduleModal: { isOpen: false },
  configModal: { isOpen: false },
  user: null,
  property: null,
  availability: null,
  events: [],
  loading: false,
  eventType: "",
  appraiserId: "",
  appraisers: [],
  documentTypes: [],
  appraiserEmail: "",
  propertyId: null,
  userType: "old",
  userEmail: null,
  userCountry: null,
  advisoryResponse: {
    appraiserId: "",
    appraiserName: "",
    scheduleId: null,
    daySchedule: null,
    leadEmail: null,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_EVENT_TYPES: {
      return { ...state, events: action.events };
    }
    case SET_APPRAISERS: {
      return { ...state, appraisers: action.appraisers };
    }
    case SET_DOCUMENT_TYPES: {
      return { ...state, documentTypes: action.documentTypes };
    }
    case SET_AVAILABILITY: {
      return { ...state, availability: action.avalability };
    }
    case SET_PROPERTY: {
      return { ...state, property: action.property };
    }
    case SET_USER: {
      return { ...state, user: action.user };
    }
    case SET_CANCEL_REASONS: {
      return { ...state, cancelReasons: action.cancelReasons };
    }
    case SET_CANCEL_RESCHEDULE_MODAL: {
      return { ...state, cancelRescheduleModal: action.cancelRescheduleModal };
    }
    case SET_CONFIG_MODAL: {
      return { ...state, configModal: action.configModal };
    }
    case SET_ADVISORY_RESPONSE: {
      return { ...state, advisoryResponse: action.advisoryResponse };
    }
    case SET_SCHEDULED: {
      return { ...state, scheduled: action.scheduled };
    }
    case SET_USER_EMAIL: {
      return { ...state, userEmail: action.userEmail };
    }
    case SET_USER_COUNTRY: {
      return { ...state, userCountry: action.userCountry };
    }

    default: {
      return state;
    }
  }
};

export default apiReducer;
