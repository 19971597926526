import { adjustmentType, IDetails } from "models/Breakdown";
import { MovementBreakdownRecord } from "models/Payments";

export const parseBreakdown = (breakdown: MovementBreakdownRecord): IDetails[] => {
  const originalAmount = (breakdown.original_amount_breakdown || []).map((item) => ({
    ...item,
    type: "credit",
    adjustmentType: "AMOUNT" as adjustmentType,
    currency: breakdown.currency,
  }));
  const adjustment = {
    description: "Reajuste",
    type: "credit",
    adjustmentType: "READJUST" as adjustmentType,
    currency: breakdown.currency,
    value: breakdown.readjustment ?? 0,
    details: (breakdown?.readjustments_breakdown || []).map((item) => ({
      ...item,
      type: item.value > 0 ? "credit" : "charge",
      value: Math.abs(item.value),
      currency: breakdown.currency,
    })),
  };

  const discountsBreakdown = (breakdown.discounts_breakdown || []).map((item) => ({
    ...item,
    type: "charge",
    currency: breakdown.currency,
    adjustmentType: (item.label === "OTHER" ? "CHARGE_DISCOUNT" : "MANAGEMENT") as adjustmentType,
  }));
  const additionalsBreakdown = (breakdown.additionals_breakdown || []).map((item) => ({
    ...item,
    type: "credit",
    currency: breakdown.currency,
    adjustmentType: (item.label === "OTHER" ? "CHARGE_DISCOUNT" : "MANAGEMENT") as adjustmentType,
  }));

  const promotionBreakdown = (breakdown.promotions_breakdown || []).map((item) => ({
    ...item,
    value: Math.abs(item.value),
    description: `Promoción: ${item.description}`,
    type: item.value > 0 ? "credit" : "charge",
    currency: breakdown.currency,
    adjustmentType: item.label as adjustmentType,
  }));
  const parsedBreakdown = originalAmount.concat(
    discountsBreakdown,
    additionalsBreakdown,
    promotionBreakdown.filter((item) => item.value !== 0)
  );
  if (adjustment.value !== 0) parsedBreakdown.push(adjustment);

  return parsedBreakdown;
};
