import { makeStyles } from "@material-ui/core";

const useActivityFormStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "20px",
    padding: theme.spacing(2),
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    width: "100%",
    minHeight: "150px",
    marginBottom: theme.spacing(1),
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey["5"],
  },
  middleColumn: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "48%",
    maxWidth: "48%",
  },
  almostFullColumn: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "97%",
    maxWidth: "97%",
    marginTop: theme.spacing(2),
  },
  defaultColumn: {
    alignItems: "center",
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "39%",
    maxWidth: "39%",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
  },
  textArea: {
    padding: theme.spacing(1, 1.5),
    border: "1px solid",
    borderColor: theme.palette.grey["500"],
    borderRadius: "20px",
  },

  textAreaLabel: {
    marginBottom: theme.spacing(1),
  },
  underline: {
    "&&&:before": {
      borderWidth: "0.6px",
      borderColor: theme.palette.grey["900"],
    },
    "&&:after": {
      borderColor: theme.palette.grey["900"],
      borderWidth: "0.6px",
    },
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  textButton: {
    textTransform: "none",
  },
  saveButton: {
    textTransform: "none",
    fontWeight: 700,
    borderRadius: "100px",
  },
}));

export default useActivityFormStyles;
