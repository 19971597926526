import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "@houm-com/ui/Alert";

import useGetToken from "hooks/useGetToken";
import debtCollectionService from "services/debtCollectionService";

import { IDebtCollectionUpdateInsurance } from "./utils/types";

export default function useChangeDebtCollectionInsurance(contractId: string, companyCode: string) {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (data: IDebtCollectionUpdateInsurance) => {
      const authToken = await getToken();
      const response = await debtCollectionService.updateInsurance(
        authToken,
        contractId,
        companyCode,
        data
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDebtCollections");
        alert.success({
          message: "La información se actualizó exitosamente",
        });
      },
      onError: (e: AxiosError<{ message?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: "No se pudo actualizar la información",
          disclaimer: `Error: ${reason?.data?.message ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
}
