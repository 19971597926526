import { ReactElement } from "react";

import { Typography } from "@material-ui/core";

import useApplicantStyles from "./ApplicantStyles";

interface Props {
  applicantType: string;
  children: ReactElement;
}

function Applicant({ applicantType, children }: Props) {
  const classes = useApplicantStyles();
  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {applicantType}
      </Typography>
      {children}
    </>
  );
}

export default Applicant;
