import { useEffect, useState } from "react";

import classNames from "classnames";
import Carousel from "react-material-ui-carousel";
import Chip from "@material-ui/core/Chip";
import moment from "moment";

import { parseWeekAndMonth } from "utils/datetime";
import { UserVisit } from "models/User";
import InfoDisplay from "Components/Admin/UIComponents/InfoDisplay";
import useVisitCarouselStyles from "./VisitsCarouselStyles";

interface Props {
  items: UserVisit[];
  itemsPerSlide: number;
}

function VisitsCarousel({ items, itemsPerSlide }: Props) {
  const classes = useVisitCarouselStyles();
  const [slides, setSlides] = useState<UserVisit[][]>([]);

  const getDisplayClass = (len: number): string => {
    if (len < itemsPerSlide) {
      return "startDisplay";
    }
    return "spaceDisplay";
  };

  const parseInfoDisplay = (visit: UserVisit) => [
    [
      { label: "ID Propiedad", value: visit.propertyId },
      { label: "Houmer", value: visit.appraiser },
      { label: "Fecha", value: parseWeekAndMonth(visit.date) },
      { label: "Hora", value: `${moment(visit.date).format("HH:mm")} hrs` },
    ],
  ];

  const removeSpaces = (str: string): string => str.replace(/\s+/g, "");

  useEffect(() => {
    const chunks = [];
    for (let i = 0; i < items.length; i += itemsPerSlide) {
      chunks.push(items.slice(i, i + itemsPerSlide));
    }
    setSlides([...chunks]);
  }, [items, itemsPerSlide]);

  return (
    <Carousel autoPlay={false}>
      {slides.map((slide, i) => (
        <div
          key={i.toString()}
          className={classNames(classes.slide, classes[getDisplayClass(slide.length)])}>
          {slide.map((item) => (
            <div key={item.id} className={classes.card}>
              <Chip
                label={item.tag}
                className={classNames(classes.state, classes[removeSpaces(item.status)])}
              />
              <InfoDisplay infoColumns={parseInfoDisplay(item)} />
            </div>
          ))}
        </div>
      ))}
    </Carousel>
  );
}

export default VisitsCarousel;
