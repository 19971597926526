import { makeStyles, Theme } from "@material-ui/core";

const mainColor = {
  backgroundColor: "#F7FDF3",
  border: "1px solid #8ECE64",
  color: "#8ECE64",
};

const secondColor = {
  backgroundColor: "#FEE9E8",
  border: "1px solid #FF866A",
  color: "#FF866A",
};

const thirdColor = {
  backgroundColor: "#FEE9E8",
  border: "1px solid #FF452B",
  color: "#FF452B",
};

const fourthColor = {
  backgroundColor: "#FFDBDB",
  border: "1px solid #D70000",
  color: "#D70000",
};

const useVisitCarouselStyles = makeStyles((theme: Theme) => ({
  slide: {
    display: "flex",
  },
  spaceDisplay: {
    justifyContent: "space-between",
  },
  startDisplay: {
    justifyContent: "flex-start",
  },
  card: {
    width: theme.spacing(31.5),
    margin: theme.spacing(0, 2.5),
  },
  state: {
    width: "60%",
    marginBottom: theme.spacing(1.5),
    height: theme.spacing(2.5),
    fontSize: "1rem",
    borderRadius: 10,
  },
  Done: { ...mainColor },
  Started: { ...mainColor },
  Confirmed: { ...mainColor },
  Arrived: { ...secondColor },
  NotInitiated: { ...secondColor },
  Scheduled: { ...thirdColor },
  Waiting: { ...thirdColor },
  Cancelled: { ...fourthColor },
}));

export default useVisitCarouselStyles;
