import { ReservationsProvider } from "./context/reservationsContext";
import ReservationIndex from "./ReservationIndex";
import { TableProvider } from "context/tableContext/tableContext";

export default function ReservationsController() {
  return (
    <ReservationsProvider>
      <TableProvider>
        <ReservationIndex />
      </TableProvider>
    </ReservationsProvider>
  );
}
