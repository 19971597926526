import { useClosureForm } from "context/closureFormContext/closureFormContext";
import contractFields from "context/closureFormContext/closureFormFields";

import { ValueProps, filter, ValueEntry } from "./utils";

const ThirdPartyValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.thirdParty[valueKey];
  if (filter(displayLabel, isAdmin, country)) return null;
  let displayValue = value;
  const { state } = useClosureForm();
  if (value && valueKey === "documentType") {
    displayValue = state.documentTypes.find((doc) => doc.value === +value)?.label;
  }
  return <ValueEntry label={displayLabel().label(country)} value={String(displayValue)} />;
};

export default ThirdPartyValue;
