import { makeStyles, Theme } from "@material-ui/core";

const useScheduleTagStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    padding: 0,
    maxHeight: theme.spacing(2),
  },
  tag: {
    marginBottom: theme.spacing(4),
    fontSize: theme.typography.pxToRem(20),
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    fontWeight: 700,
    textAlign: "center",
    color: "white",
    padding: theme.spacing(1),
    marginLeft: -theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    "& > :first-child": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default useScheduleTagStyles;
