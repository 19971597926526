import {
  BidderDetail,
  DocumentData as Document,
  Negotiation,
  OwnerDetail,
  BidFees,
  NegotiationProperty,
} from "services/negotiationService/models";
import { CIVIL_STATUSES, MARITAL_REGIMES } from "./constants";
import {
  BankData,
  Negotiation as NegotiationData,
  Bidder,
  Owner,
  DocumentData,
  BidFees as BidFeesData,
  Property,
} from "./letterOfIntentTypes";

export function parseNegotiation(data: Negotiation): NegotiationData {
  return {
    id: data.id,
    propertyId: data.property_id,
    address: data.property_address,
    amount: data.amount,
    currency: data.currency,
    lastBid: {
      id: data.last_bid?.id,
      amount: data.last_bid?.amount,
      currency: data.last_bid?.currency,
      paymentMethod: data.last_bid?.payment_method,
      status: data.last_bid?.status,
      createdAt: data.last_bid?.created_at,
      depositAmount: data.last_bid?.deposit_amount,
      ownerPenaltyFee: data.last_bid?.owner_penalty_fee,
      bidderPenaltyFee: data.last_bid?.bidder_penalty_fee,
      houmOwnerFee: data.last_bid?.houm_owner_fee,
      houmBidderFee: data.last_bid?.houm_bidder_fee,
    },
  };
}

export function parseBankData(data: OwnerDetail): BankData {
  return {
    clabe: data.clabe,
  };
}

export function parseOwner(data: OwnerDetail): Owner {
  return {
    id: data.id,
    userId: data.user_id,
    fullname: `${data.name} ${data.last_name ?? ""}`,
    name: data.name,
    lastName: data.last_name,
    secondLastName: data.second_last_name,
    email: data.email,
    phone: data.phone,
    documentType: data.id_type,
    documentNumber: data.id_number,
    rfc: data.rfc,
    occupation: data.occupation,
    nationality: data.nationality,
    civilStatus: data.civil_status,
    conjugalRegime: data.conjugal_regime,
    isMainUser: data.is_main_user,
  };
}

export function parseBidders(data: BidderDetail[]): Bidder[] {
  return data.map((bidder) => parseOwner(bidder));
}

export function parseOwners(data: OwnerDetail[]): Owner[] {
  return data.map((owner) => parseOwner(owner));
}

export function parseDocuments(data: Document[]): DocumentData[] {
  return data?.map((doc) => ({
    id: doc.id,
    loiId: doc.loi_id,
    contentType: doc.content_type,
    filename: doc.filename,
    path: doc.path,
    status: doc.status,
    createdAt: doc.created_at,
  }));
}

export function parseProperty(data: NegotiationProperty): Property {
  return {
    id: data.id,
    individualization: data.individualization,
    propertyAddress: data.property_address,
    propertyExecutiveName: data.property_executive_name,
    propertyExecutiveEmail: data.property_executive_email,
    propertyExecutiveUserId: data.property_executive_user_id,
    propertyId: data.property_id,
  };
}

export function unparseProperty(data: Property): NegotiationProperty {
  return {
    id: data.id,
    individualization: data.individualization,
    property_address: data.propertyAddress,
    property_executive_name: data.propertyExecutiveName,
    property_executive_email: data.propertyExecutiveEmail,
    property_executive_user_id: data.propertyExecutiveUserId,
    property_id: data.propertyId,
  };
}

export function unparseOwner(data: Owner): OwnerDetail {
  return {
    id: data.id,
    user_id: data.userId,
    fullname: data.fullname,
    name: data.name,
    last_name: data.lastName,
    second_last_name: data.secondLastName,
    email: data.email,
    phone: data.phone,
    id_type: data.documentType,
    id_number: data.documentNumber,
    rfc: data.rfc,
    occupation: data.occupation,
    nationality: data.nationality,
    civil_status: data.civilStatus,
    conjugal_regime: data.conjugalRegime,
    is_main_user: data.isMainUser,
  };
}

export function parseField(field: string, value: string): string {
  if (field === "civilStatus" || field === "conjugalRegime") {
    return CIVIL_STATUSES[value] || MARITAL_REGIMES[value];
  }
  return value;
}

export function unparseBidFees(data: BidFeesData): BidFees {
  return {
    owner_penalty_fee: data.ownerPenaltyFee,
    bidder_penalty_fee: data.bidderPenaltyFee,
    houm_owner_fee: data.houmOwnerFee,
    houm_bidder_fee: data.houmBidderFee,
  };
}
