import { IoWarningOutline } from "@houm-com/ui-icons";
import ModalDialog from "@houm-com/ui/ModalDialog";

interface Props {
  isOpen: boolean;
  onHandleModel: () => void;
}

const ModalDisclaimer = ({ isOpen, onHandleModel }: Props) => (
  <ModalDialog
    isOpen={isOpen}
    onClose={onHandleModel}
    title="¡No se puede modificar esta orden de pago!"
    icon={<IoWarningOutline className="h-6 w-6" color="#E7C202" />}>
    <ModalDialog.Content>
      <ModalDialog.Text>
        Dado que esta orden de pago está agendada para hoy y ya es pasado el medio día en el país
        que se encuentra la propiedad, no se puede modificar. Recuerda que las nóminas se descargan
        en este horario.
      </ModalDialog.Text>
      <ModalDialog.Text>
        En caso de ser necesario, por favor contactar con soporte.
      </ModalDialog.Text>
    </ModalDialog.Content>
    <ModalDialog.Actions onSubmit={onHandleModel} />
  </ModalDialog>
);

export default ModalDisclaimer;
