import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import { ControlledOptions } from "Components/Admin/UIComponents/ControlledForm";
import { IWarrantyReceiver } from "models/Warranty";
import { useFormNotifications } from "hooks";
import { Locale } from "models/Countries";

import useChangeWarrantyCondition from "../../../hooks/useChangeWarrantyCondition";
import { warrantyPersonSchema, requiredText } from "./utils/validationSchema";
import ModalLayout from "../../ModalLayout/ModalLayout";
import ModalButtons from "../../ModalButtons";

interface ChangeWarrantyProps {
  personWarranty: IWarrantyReceiver;
}

interface Props {
  open: boolean;
  onHandleClose: () => void;
  receiver: IWarrantyReceiver;
  countryCode: Locale;
  contractId: number;
}

const ChangeWarrantyPersonModal = ({
  open,
  onHandleClose,
  receiver,
  contractId,
  countryCode,
}: Props) => {
  const { mutate, isLoading } = useChangeWarrantyCondition(contractId, countryCode, onHandleClose);
  const methods = useForm<ChangeWarrantyProps>({
    resolver: yupResolver(warrantyPersonSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<ChangeWarrantyProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<ChangeWarrantyProps> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  return (
    <ModalLayout open={open} handleOnClose={onHandleClose} title="Destinatario de garantía">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item container alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <ControlledOptions
                id="personWarranty"
                label="Elige quién recibe la garantía de esta propiedad"
                defaultValue={receiver ?? "LESSOR"}
                options={[
                  { value: "LESSEE", label: "Arrendatario" },
                  { value: "LESSOR", label: "Propietario" },
                ]}
              />
            </Grid>
            <ModalButtons isLoading={isLoading} onHandleClose={() => onHandleClose()} />
          </Grid>
        </form>
      </FormProvider>
    </ModalLayout>
  );
};

export default ChangeWarrantyPersonModal;
