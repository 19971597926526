import { ActionType, State, Action } from "./demandLeadTypes";

export const initialState: State = {
  lead: null,
  isLoading: false,
  profiling: null,
  activities: [],
  schedules: [],
  viewedProperties: [],
  isOpenErrorModal: false,
  completedActivity: { currentActivity: null, isOpenModal: false },
  renderProfileForm: false,
  isOpenActivityForm: false,
  isOpenSuccessModal: false,
  isOpenSaveInformationModal: false,
  isOpenReviewInformationModal: false,
};

const demandLeadReducer = (state, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_LEAD:
      return {
        ...state,
        lead: action.lead,
      };

    case ActionType.SET_PROFILING:
      return {
        ...state,
        profiling: action.profiling,
      };

    case ActionType.SET_RENDER_PROFILE_FORM:
      return {
        ...state,
        renderProfileForm: action.renderProfileForm,
      };

    case ActionType.SET_ACTIVITIES:
      return {
        ...state,
        activities: action.activities,
      };

    case ActionType.SET_SCHEDULES:
      return {
        ...state,
        schedules: action.schedules,
      };

    case ActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case ActionType.SET_OPEN_ACTIVITY_FORM:
      return {
        ...state,
        isOpenActivityForm: action.isOpenActivityForm,
      };

    case ActionType.SET_COMPLETED_ACTIVITY_CONFIG:
      return {
        ...state,
        completedActivity: action.completedActivity,
      };

    case ActionType.SET_OPEN_REVIEW_INFORMATION_MODAL:
      return {
        ...state,
        isOpenReviewInformationModal: action.isOpenReviewInformationModal,
      };

    case ActionType.SET_OPEN_SUCCESS_MODAL:
      return {
        ...state,
        isOpenSuccessModal: action.isOpenSuccessModal,
      };

    case ActionType.SET_OPEN_ERROR_MODAL:
      return {
        ...state,
        isOpenErrorModal: action.isOpenErrorModal,
      };

    case ActionType.SET_OPEN_SAVE_INFORMATION_MODAL:
      return {
        ...state,
        isOpenSaveInformationModal: action.isOpenSaveInformationModal,
      };

    case ActionType.SET_VIEWED_PROPERTIES:
      return {
        ...state,
        viewedProperties: action.viewedProperties,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
