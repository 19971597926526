import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ModalDialog from "@houm-com/ui/ModalDialog";

import { useFormNotifications } from "hooks";
import { UserResponse } from "models/User";
import { Locale } from "models/Countries";
import { IPlan } from "models/Plan";

import useCreatePaymentOrdersFormStyles from "./CreatePaymentOrdersFormStyles";
import { paymentOrderSchema, requiredText } from "../../utils/validationSchema";
import usePaymentOrder from "../../hooks/usePaymentOrder";
import { getIsBasicPlanFlag } from "../../utils/utils";
import { PaymentOrderData } from "../../utils/types";
import Form from "../Form";

type FormType = "lessee" | "lessor";

interface Props {
  onClose: () => void;
  formType: FormType;
  changeFormType: (e: FormType) => void;
  landlordContract: UserResponse;
  tenantContract: UserResponse;
  propertyId: number;
  subscriptionId: number;
  country: Locale;
  plan: IPlan;
  contractId: number;
}

const CreatePaymentOrdersForm = ({
  onClose,
  formType,
  changeFormType,
  landlordContract,
  tenantContract,
  propertyId,
  subscriptionId,
  country,
  plan,
  contractId,
}: Props) => {
  const [lesseeDescription, setLesseeDescription] = useState<string | null>(null);
  const classes = useCreatePaymentOrdersFormStyles();
  const isBasicPlanFlag = getIsBasicPlanFlag(plan, country);
  const managementFee = plan?.admin_commission ?? plan?.price;
  const { mutate, isLoading, isSuccess } = usePaymentOrder(
    country,
    landlordContract,
    subscriptionId,
    propertyId,
    tenantContract,
    plan,
    contractId,
    managementFee
  );
  const methods = useForm<PaymentOrderData>({
    resolver: yupResolver(
      paymentOrderSchema(formType, country, isBasicPlanFlag, lesseeDescription)
    ),
    reValidateMode: "onBlur",
  });

  const description = methods.watch("lesseeDescription");

  useEffect(() => {
    setLesseeDescription(description);
  }, [lesseeDescription]);

  const { errors } = methods.formState;
  useFormNotifications<PaymentOrderData>({ errors, requiredText });

  const onSubmit = (data: PaymentOrderData) => {
    if (
      formType === "lessee" &&
      (country !== "cl" || !isBasicPlanFlag || (isBasicPlanFlag && description !== "LEASE"))
    )
      return changeFormType("lessor");
    else mutate(data);
  };

  if (isSuccess) onClose();

  return (
    <FormProvider {...methods}>
      <form className={classes.formContent}>
        <ModalDialog.Content>
          <Form type={formType} country={country} />
        </ModalDialog.Content>
        <ModalDialog.Actions
          onSubmit={methods.handleSubmit((data) => onSubmit(data))}
          submitButtonLabel="Crear cobro"
          submitButtonProps={{ isLoading, disabled: isLoading }}
          buttonsPosition="center"
        />
      </form>
    </FormProvider>
  );
};

export default CreatePaymentOrdersForm;
