import * as yup from "yup";

export const requiredText = "Campo obligatorio";
const invalidFormat = "Formato inválido";

export const mexicoValidationSchema = (regex) =>
  yup.object({
    rfc: yup.string().required(requiredText).matches(new RegExp(regex.rfc), invalidFormat),
    document: yup
      .string()
      .required(requiredText)
      .matches(new RegExp(regex.document), invalidFormat),
    documentType: yup.string().required(requiredText),
  });

export const generalValidationSchema = (regex) =>
  yup.object({
    document: yup
      .string()
      .required(requiredText)
      .matches(new RegExp(regex.document), invalidFormat),
  });
