import { useState, ChangeEvent, ReactNode } from "react";
import classnames from "classnames";
import { FieldError } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ArrowDropDownOutlined } from "@material-ui/icons";

import { Country } from "models/PhoneInput";
import CountryDialog from "./CountryDialog";
import useStyles from "./PhoneInputStyles";

export interface Props {
  errorMessage?: FieldError | { [message: string]: string };
  error?: boolean;
  searchInputProps?: TextFieldProps;
  countryInputLabel?: string;
  countryInputProps?: TextFieldProps;
  labelClassName?: string;
  wrapperPadding?: boolean;
  disabled?: boolean;
  numberInputLabel?: string | ReactNode;
  numberInputProps?: TextFieldProps;
  classNameInputContainer?: string;
  onChange: ({ country, phone }: { country: Country; phone: string }) => void;
  value: { country: Country; phone: string };
  fieldContainerLabel?: string;
  fieldContainerLabelClassName?: string;
  countryWrapperClassName?: string;
  numberWrapperClassName?: string;
}

/**
 *
 * @param {searchInputProps}  TextFieldsProps of dialog search input
 * @param {numberInputProps} TextFieldProps of the second textfield (phone)
 * @param {countryInputProps} TextFieldProps of the first textfield (country select)
 * @param {classNameInputContainer} optional class of the the two input fields
 * @param {hideLabelInsideInput} avoid label as placeholder
 */
function PhoneInput({
  errorMessage,
  error = false,
  searchInputProps,
  labelClassName,
  wrapperPadding,
  disabled,
  numberInputLabel = "Número",
  numberInputProps,
  countryInputLabel = "País",
  countryInputProps,
  classNameInputContainer,
  onChange,
  value,
  fieldContainerLabel,
  fieldContainerLabelClassName,
  countryWrapperClassName = null,
  numberWrapperClassName = null,
}: Props) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const classes = useStyles({ error, wrapperPadding, disabled });
  const countryCode = value?.country?.countryCode?.toLowerCase();

  const handleChangeCountry = (country: Country) => {
    onChange({ country, phone: value?.phone || "" });
    setIsOpenDialog(false);
  };

  const handleChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    onChange({ ...value, phone: inputValue || "" });
  };

  return (
    <>
      {fieldContainerLabel && (
        <Typography
          component="span"
          className={classnames(classes.label, fieldContainerLabelClassName)}>
          {fieldContainerLabel}
        </Typography>
      )}
      <CountryDialog
        searchInputProps={searchInputProps}
        selected={value?.country}
        onChange={handleChangeCountry}
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
      />
      <FormControl className={classes.wrapper} error={!!error}>
        <div className={classnames(classes.inputContainer, classNameInputContainer)}>
          <FormControlLabel
            classes={{
              label: classnames(classes.label, labelClassName),
              root: classnames(classes.countryWrapper, countryWrapperClassName),
            }}
            control={
              <TextField
                onClick={() => (!disabled ? setIsOpenDialog(true) : null)}
                value={value?.country ? `+${value?.country?.callingCode}` : ""}
                {...countryInputProps}
                disabled={disabled}
                inputProps={{
                  ...countryInputProps?.inputProps,
                  className: classnames(
                    classes.basicInput,
                    countryInputProps?.inputProps?.className
                  ),
                }}
                InputProps={{
                  ...countryInputProps?.InputProps,
                  classes: {
                    root: classnames(
                      classes.root,
                      classes.underline,
                      countryInputProps?.InputProps?.classes?.root
                    ),
                    ...countryInputProps?.InputProps?.classes,
                  },
                  startAdornment: value?.country && (
                    <img
                      alt={countryCode}
                      src={`https://hatscripts.github.io/circle-flags/flags/${countryCode}.svg`}
                      width="20"
                    />
                  ),
                  endAdornment: <ArrowDropDownOutlined />,
                }}
              />
            }
            label={countryInputLabel}
            labelPlacement="top"
          />
          <FormControlLabel
            classes={{
              label: classnames(classes.label, labelClassName),
              root: classnames(classes.wrapper, numberWrapperClassName),
            }}
            control={
              <TextField
                {...numberInputProps}
                disabled={disabled}
                InputProps={{
                  ...numberInputProps?.InputProps,
                  classes: {
                    root: classes.underline,
                    ...numberInputProps?.InputProps?.classes,
                  },
                }}
                onChange={handleChangeNumber}
                value={value?.phone || ""}
              />
            }
            label={numberInputLabel}
            labelPlacement="top"
          />
        </div>
        <FormHelperText>{errorMessage?.message}</FormHelperText>
      </FormControl>
    </>
  );
}
export default PhoneInput;
