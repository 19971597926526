import { Typography } from "@material-ui/core";

import useUpdatingDisclaimerStyles from "./UpdatingDisclaimerStyles";

function UpdatingDisclaimer() {
  const classes = useUpdatingDisclaimerStyles();

  return (
    <Typography className={classes.updatingDisclaimerRoot}>
      Estamos cargando la información, por favor espera unos minutos antes de actualizar de nuevo
    </Typography>
  );
}

export default UpdatingDisclaimer;
