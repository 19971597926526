import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    height: "100%",
    width: 500,
    display: "flex",
    flexDirection: "column",
  },
}));
