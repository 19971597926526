import { makeStyles, Theme } from "@material-ui/core";

const useAcceptanceDocumentStyles = makeStyles((theme: Theme) => ({
  acceptanceDocumentRoot: {
    width: "48%",
  },
  acceptanceDocumentTitle: {
    marginBottom: theme.spacing(1),
    width: "100%",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    backgroundColor: theme.palette.grey[50],
  },
  downloadLink: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 700,
  },
}));

export default useAcceptanceDocumentStyles;
