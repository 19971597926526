import { UseQueryResult, useQuery } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";
import { ContractModel } from "models/Contract";

const useGetContract = (contractId: number) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data, refetch }: UseQueryResult<ContractModel, Error> = useQuery(
    ["contract_data", contractId],
    async () => {
      const authToken = await getToken();
      const response = await contractService.get(contractId, authToken);
      return response.data;
    },
    {
      enabled: !!contractId,
    }
  );

  return { isLoading, error, data, refetch };
};

export default useGetContract;
