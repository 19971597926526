import { makeStyles, Theme } from "@material-ui/core";

const useSelectStyles = makeStyles((theme: Theme) => ({
  selectRoot: {
    width: "100%",
  },
  select: {
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
  },
}));

export default useSelectStyles;
