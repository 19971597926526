import { Box, Typography, IconButton } from "@material-ui/core";

import { ReactComponent as TrashIcon } from "assets/icons/trash-fill.svg";

import useDeleteAppliedPromotion from "../../hooks/useDeleteAppliedPromotion";
import useAppliedPromotionsStyles from "./AppliedPromotionsStyles";
import { IAppliedPromotions } from "../../utils/types";

interface Props {
  promotions: IAppliedPromotions[];
  contractId: number;
  onFetchSimulation: ({ contractId, save }) => void;
}

const AppliedPromotions = ({ promotions, contractId, onFetchSimulation }: Props) => {
  const classes = useAppliedPromotionsStyles();
  const { mutate: deletePromotion } = useDeleteAppliedPromotion(onFetchSimulation, contractId);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Promociones aplicadas</Typography>
      {promotions.map((promotion) => (
        <Box key={promotion.id} className={classes.promotionsContainer}>
          <Typography className={classes.promotionName}>{promotion.name}</Typography>
          <IconButton
            size="small"
            onClick={() =>
              deletePromotion({
                contract: contractId,
                promotion: promotion.id,
              })
            }
            id="delete-penalty">
            <TrashIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default AppliedPromotions;
