import { Fragment, useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";

import Button from "@houm-com/ui/Button";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ISignatureInfo, ISignatures } from "models/Contract";
import { useGetToken, useMakeSimpleRequest } from "hooks";
import contractService from "services/contractService";

import SignatureCardInfo from "./components/SignatureCardInfo";
import useSignProcessStyles from "./SignProcessStyles";
import SignDocs from "./components/SignDocs";
import useSendLink from "./hooks/useSendLink";

interface Props {
  contractId: number;
}

const SignProcess = ({ contractId }: Props) => {
  const { getToken } = useGetToken();
  const [loadingData, setLoadingData] = useState(true);
  const [signatureData, setSignatureData] = useState<ISignatures | null>(null);
  const { isSending, sendLinks } = useSendLink();

  const classes = useSignProcessStyles();

  const { loading: loadingSend, makeSimpleRequest: makeSendRequest } = useMakeSimpleRequest();

  const sendDocuments = useCallback(
    () => makeSendRequest(contractService.sendSignedFiles, contractId, "Documentos enviados"),
    []
  );

  const fetchStatuses = async () => {
    const authToken = await getToken();
    try {
      const fetchedContract = await contractService.newRetrieveSignatureStatus(
        contractId,
        authToken
      );
      setSignatureData(fetchedContract.data);
    } catch {
      Swal.fire("Error", "No se pudo obtener firmas", "error");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  const renderSignatureCard = (
    role: string,
    fullName: string,
    image: string,
    signProof: string,
    statusStored: string,
    status: string,
    id: number | string
  ) => (
    <SignatureCardInfo
      role={role}
      fullName={fullName}
      image={image}
      signProof={signProof}
      statusStored={statusStored}
      status={status}
      key={fullName}
      id={id}
      contractId={contractId}
    />
  );

  const sendSignedDocuments = () => {
    Swal.fire({
      type: "warning",
      title: "¿Enviar documentos?",
      text: "Esto enviará un correo a propietarios y arrendatarios. Verifica en Archivos actuales si los documentos fueron firmados correctamente.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then((res) => {
      if (res.value) {
        sendDocuments();
      }
    });
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Loading loading={loadingData}>
          <Grid container spacing={3} alignItems="flex-end" className={classes.gridContainer}>
            <Grid container alignItems="flex-start" className={classes.ctaContainer}>
              <Typography component="h6" className={classes.buttonLabel}>
                Presiona aquí para comenzar proceso de firmas y enviar correo al propietario:
              </Typography>
              <Button
                variant="tertiary"
                size="md"
                type="button"
                onClick={() => sendLinks(contractId)}
                isLoading={isSending}
                disabled={isSending}>
                Enviar links de contrato
              </Button>
            </Grid>
            <Typography component="h6" className={classes.textLabel}>
              Para proceder, revisa que todas las partes hayan firmado
            </Typography>
            {signatureData && (
              <Grid container alignItems="flex-start" className={classes.container}>
                {Object.keys(signatureData).map(
                  (signature) =>
                    signatureData[signature]["name"] !== "No hay" && (
                      <Fragment key={signature}>
                        {signature === "codebtors"
                          ? signatureData[signature].map((codebtor: ISignatureInfo) =>
                              renderSignatureCard(
                                signature,
                                codebtor.name,
                                codebtor.signature,
                                codebtor.sign_proof,
                                codebtor.sign_stored,
                                codebtor.status,
                                codebtor.cp_id
                              )
                            )
                          : renderSignatureCard(
                              signature as keyof ISignatures,
                              signatureData[signature as keyof ISignatures]["name"],
                              signatureData[signature as keyof ISignatures]["signature"],
                              signatureData[signature as keyof ISignatures]["sign_proof"],
                              signatureData[signature as keyof ISignatures]["sign_stored"],
                              signatureData[signature as keyof ISignatures]["status"],
                              signatureData[signature as keyof ISignatures]["cp_id"]
                            )}
                      </Fragment>
                    )
                )}
              </Grid>
            )}
            <Grid container className={classes.container} spacing={1}>
              <Grid item xs={12} lg={6} className={classes.ctaContainer}>
                <Typography component="h6" className={classes.buttonLabel}>
                  Presiona aquí para <strong>juntar todas</strong> las firmas. Asegúrate que estén
                  bien:
                </Typography>
                <SignDocs contractId={contractId} />
              </Grid>
              <Grid item xs={12} lg={6} className={classes.ctaContainer}>
                <Typography component="h6" className={classes.buttonLabel}>
                  Presiona aquí para <strong>envíar los documentos firmados</strong> a las partes:
                </Typography>
                <Button
                  variant="primary"
                  size="md"
                  onClick={sendSignedDocuments}
                  isLoading={loadingSend}>
                  Enviar documentos firmados
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Loading>
      </CardContent>
    </Card>
  );
};

export default SignProcess;
