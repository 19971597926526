import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  SetIsOpenAsignFailModal,
  setErrorMsg,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

function Failure() {
  const {
    state: { isOpenAsignFailModal, errorMsg },
    dispatch,
  } = useDemandScheduled();

  const handleCloseModal = () => {
    dispatch(setErrorMsg(null));
    dispatch(SetIsOpenAsignFailModal(false));
  };

  return (
    <CustomCenteredModal
      open={isOpenAsignFailModal}
      onClose={handleCloseModal}
      title="¡Oh no! Tuvimos un problema"
      subtitle={
        errorMsg != null
          ? errorMsg
          : `No se ha podido guardar la información, intenta de nuevo mas tarde.`
      }
      showCloseButton
      withLogo
    />
  );
}
export default Failure;
