import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { Divider, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import CustomLoadingButton from "../Buttons/CustomLoadingButton";
import useContractUploadStyles from "./FileManagementStyles";
import FileUpload from "../atoms/FileUpload";

interface Props {
  title: string;
  uploadService: (file: File) => Promise<void>;
  downloadLink?: string;
  allowedMimeTypes: string[];
}

export default function FileManagement({
  title,
  uploadService,
  downloadLink = "#",
  allowedMimeTypes = [""],
}: Props) {
  const classes = useContractUploadStyles();

  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setUploading(true);
    try {
      await uploadService(file);
      setFile(null);
    } catch (e) {
      console.error(e);
      Swal.fire({
        type: "error",
        text: "Error subiendo los archivos",
      });
      setFile(null);
    }
    setUploading(false);
  };

  return (
    <div className={classes.root}>
      <Typography component="p" className={classes.title}>
        {title}
      </Typography>
      <Typography component="span" className={classes.fileText}>
        Archivo a subir: {file?.name || "Aún no hay un archivo seleccionado"}
      </Typography>
      <Divider className={classes.divider} />
      {file ? (
        <div className={classes.centered}>
          <CustomLoadingButton
            text="Confirmar"
            onClick={handleSubmit}
            disabled={uploading}
            buttonClassName={classNames(classes.button, classes.submitButton)}
          />
          <Button
            className={classNames(classes.button, classes.cancelButton)}
            onClick={() => {
              setFile(null);
            }}>
            Cancelar
          </Button>
        </div>
      ) : (
        <div className={classes.centered}>
          <FileUpload
            label="Subir nuevo"
            allowedMimeTypes={allowedMimeTypes}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
          {downloadLink !== "#" && (
            <Link
              to={{
                pathname: downloadLink,
              }}
              download
              target="#">
              <Button
                color="primary"
                variant="contained"
                className={classNames(classes.button, classes.blackButton)}>
                Descargar
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
