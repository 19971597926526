import { makeStyles, Theme } from "@material-ui/core";

const useScoreStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textTransform: "none",
    borderRadius: 100,
    marginLeft: "auto",
    height: 32,
    alignSelf: "center",
    minWidth: 120,
    marginRight: theme.spacing(3),
  },
}));

export default useScoreStyles;
