import { IWarrantyStatus } from "models/Warranty";

export const getResolveWarrantyLabel = (retainer: string, status: IWarrantyStatus): string => {
  if (status === "NEGATIVE") return "Notificar";
  if (retainer === "HOUM") return "Liquidar garantía";
  return "Marcar como pagado";
};

export const isResolveWarrantyDisabled = (
  status: IWarrantyStatus,
  hasAgreedDate: boolean,
  hasReceiver: boolean
): boolean => {
  if (status === "NEGATIVE") return false;
  return !hasAgreedDate || !hasReceiver;
};
