import LoadingScreen from "domains/common/components/LoadingScreen";
import { useAuth } from "libs/auth";

function AuthenticateUser({ children }: { children: React.ReactElement | null }) {
  const { isLoading, isAuthenticated, redirectToLogin } = useAuth();
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (!isLoading && !isAuthenticated) {
    redirectToLogin();
    return null;
  }
  return children;
}

export default AuthenticateUser;
