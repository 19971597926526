import { useFormContext } from "react-hook-form";

import Button from "@houm-com/ui/Button";
import CircularSteps from "@houm-com/ui/CircularSteps";
import FieldDate from "@houm-com/ui/FieldDate";
import FieldInput from "@houm-com/ui/FieldInput";
import FieldTextArea from "@houm-com/ui/FieldTextArea";
import FieldCheckbox from "@houm-com/ui/FieldCheckbox";

import { ICity } from "Components/Admin/administrator/promotions/utils/types";

import { PromotionFormData } from "../../utils/types";
import { generalInfoReady } from "../../utils/utils";
import { classes } from "./GeneralInfoFormStyles";

interface Props {
  cities: Partial<ICity[]>;
  handleNext: () => void;
}

const GeneralInfoForm = ({ handleNext, cities }: Props) => {
  const { register, formState, setValue, watch } = useFormContext<PromotionFormData>();
  const { errors } = formState;
  const requiredFields = {
    name: watch("name"),
    goal: watch("goal"),
    startDate: watch("startDate"),
    endDate: watch("endDate"),
    cities: watch("cities"),
  };

  return (
    <>
      <div className={classes.header}>
        <p>Información General</p>
        <CircularSteps
          totalSteps={2}
          currentStep={1}
          classNames={{ wrapper: "h-[32px] w-[32px]", text: "fill-white text-[20px]" }}
        />
      </div>
      <div className={classes.generalInfoForm}>
        <FieldInput
          id="name"
          label="Nombre de la campaña"
          required
          errorMessage={errors.name?.message}
          defaultValue=""
          placeholder="Ejemplo de campaña"
          {...register("name")}
        />
        <FieldTextArea
          id="goal"
          label="Objetivo"
          required
          errorMessage={errors.goal?.message}
          defaultValue=""
          classNameTextArea="min-h-[120px]"
          placeholder="¿Qué queremos lograr?"
          {...register("goal")}
        />
        <div className="flex flex-col gap-1">
          <p className="text-p16 font-bold">
            Fechas en que estará publicada la promoción <span className="text-primary-cta">*</span>
          </p>
          <p className="text-p14">
            Número de meses que estará activa esta promoción para ofrecer al cliente o hasta agotar
            existencias
          </p>
          <FieldDate
            id="startDate"
            label="Fecha de inicio"
            required
            errorMessage={errors.startDate?.message}
            defaultValue={new Date()}
            {...register("startDate")}
            onChange={(value) => {
              setValue("startDate", value);
            }}
          />
          <FieldDate
            id="endDate"
            label="Fecha de término"
            required
            errorMessage={errors.endDate?.message}
            {...register("endDate")}
            onChange={(value) => {
              setValue("endDate", value);
            }}
          />
        </div>
        <FieldInput
          id="promoCode"
          label="Código promocional"
          errorMessage={errors.promoCode?.message}
          defaultValue=""
          placeholder="EJEMPLOCODIGO911"
          {...register("promoCode")}
        />
        <FieldCheckbox
          id="cities"
          label="Ciudades en las que aplica la promoción"
          options={cities.map((city) => ({
            label: city.name,
            value: String(city.id),
          }))}
          required
          errorMessage={errors.cities?.message}
          classNameCheckboxContainer={classes.citiesContainer}
          {...register("cities")}
        />
        <FieldTextArea
          id="comment"
          label="Comentarios"
          errorMessage={errors.comment?.message}
          defaultValue=""
          placeholder="Algo que debamos saber"
          classNameTextArea="min-h-[120px]"
          {...register("comment")}
        />
        <FieldInput
          id="link"
          label="Pegar el link a Términos y Condiciones"
          errorMessage={errors.link?.message}
          subLabel="Pega el link que se verá en el contrato de los propietarios y arrendatarios"
          defaultValue=""
          placeholder="Pegar aquí"
          {...register("link")}
        />
        <div className="p-2 flex w-full justify-between">
          <Button variant="tertiary" size="md" onClick={() => window.history.back()}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={handleNext}
            disabled={!generalInfoReady({ ...requiredFields })}>
            Continuar
          </Button>
        </div>
      </div>
    </>
  );
};

export default GeneralInfoForm;
