import { Card, Typography } from "@material-ui/core";

import useBankAccountStyles from "./BankAccountsPropietaryStyle";
import { accountTypesName } from "utils/accountTypes";

interface IAccount {
  identifier: string;
  bank_name: string;
  bank_code: number;
  account_number: string;
  holder: string;
  document: string;
  email: string;
  person_id: number;
  account_type: string;
  document_type: string;
  registered: boolean;
  id: string;
  clabe_number: string;
}

function BankAccountItem({ bankAccount, country }: { bankAccount: IAccount; country: string }) {
  const classes = useBankAccountStyles();

  return (
    <Card
      title={bankAccount?.id}
      classes={{
        root: classes.rootCard,
      }}>
      <Typography variant="body2">Titular: {bankAccount?.holder}</Typography>
      <Typography variant="body2">Email: {bankAccount?.email}</Typography>
      {country === "Colombia" && (
        <Typography variant="body2">
          tipo de identificación: {bankAccount?.document_type}
        </Typography>
      )}
      <Typography variant="body2">Identificación : {bankAccount?.document}</Typography>
      <Typography variant="body2">Banco : {bankAccount?.bank_name}</Typography>
      <Typography variant="body2">
        Tipo de cuenta:{" "}
        {accountTypesName[country][bankAccount?.account_type] || bankAccount?.account_type}
      </Typography>
      <Typography variant="body2">Número de cuenta: {bankAccount?.account_number}</Typography>
      {country === "Mexico" && (
        <Typography variant="body2">Cuenta clabe: {bankAccount?.clabe_number}</Typography>
      )}
    </Card>
  );
}

export default BankAccountItem;
