import { useClosureForm } from "context/closureFormContext/closureFormContext";
import contractFields from "context/closureFormContext/closureFormFields";
import { MARITAL_REGIMES, CIVIL_STATUSES } from "context/closureFormContext/constants";

import { ValueProps, filter, ValueEntry } from "./utils";

const LandlordValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.landlord[valueKey];
  if (filter(displayLabel, isAdmin, country)) return null;
  const { state } = useClosureForm();

  const furnishedTransform = (value: string) => {
    if (value === "non") {
      return "Desocupado";
    } else if (value === "partially") {
      return "Semi-amoblado";
    }
    return "Amoblado";
  };

  let displayValue;
  if (value) {
    switch (valueKey) {
      case "userType":
        displayValue = value === "person" ? "Persona natural" : "Empresa";
        break;
      case "documentType":
        displayValue = state.documentTypes.find((doc) => doc.value === +value)?.label;
        break;
      case "isFurnished":
        displayValue = furnishedTransform(value as string);
        break;
      case "ownWarehouse":
        displayValue = value === "1" ? "Privado" : "Común";
        break;
      case "isIvaResponsible":
        displayValue =
          value === "0" ? "No responsable de IVA (simplificado)" : "Responsable de IVA (común)";
        break;
      case "isNew":
        displayValue = value === "1" ? "Si" : "No";
        break;
      case "civilStatus":
        displayValue = CIVIL_STATUSES.find((status) => status.value === value)?.label;
        break;
      case "maritalRegime":
        displayValue = MARITAL_REGIMES.find((regime) => regime.value === value)?.label;
        break;
      case "landlordBrokerageCommission":
      case "tenantBrokerageCommission":
        if (typeof value === "number") displayValue = `${100 * value}%`;
        break;
      default:
        displayValue = value;
    }
  } else {
    displayValue = null;
  }
  return <ValueEntry label={displayLabel().label(country)} value={displayValue} />;
};

export default LandlordValue;
