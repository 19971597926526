export enum Permissions {
  UPDATE_USER = "update:users:all",
  DELETE_FILE = "update:evaluation_documents:all",
  UPDATE_EVALUATION = "update:evaluations:all",
  CREATE_COMMENT = "create:evaluation_comments:all",
  READ_FILES = "read:evaluation_documents:all",
  UPDATE_EVALUATION_MEMBER = "update:evaluation_member:all",
  UPDATE_MAIN = "update:evaluation_main:all",
  DELETE_MEMBER = "delete:evaluation_member:all",
  READ_MEMBER_INFORMATION = "read:evaluation_member_information:all",
  UPDATE_MEMBER_INFORMATION = "update:person_information:all",
  UPDATE_HOUMER = "update:applicant_appraiser:all",
  UPDATE_BROKERAGE = "update:evaluation_brokerage:all",
}
