import { useMutation, useQueryClient } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import { IContractExitProcess } from "models/Contract";
import { alert } from "libs/alerts";
import { useGetToken } from "hooks";

import { IContractExitProcessData } from "services/contractService/types";

const useExitProcess = (
  service: (
    id: number,
    token: string,
    parsedData?: IContractExitProcessData
  ) => Promise<AxiosResponse>,
  successText: string,
  errorText: string,
  exitProcess: IContractExitProcess,
  closeModal: () => void
) => {
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (data?: IContractExitProcessData | null) => {
      const authToken = await getToken();
      const args: [number, string, IContractExitProcessData?] = data
        ? [exitProcess.contract_id, authToken, data]
        : [exitProcess.contract_id, authToken];
      await service(...args);
    },
    {
      onSuccess: () => {
        closeModal();
        alert.success({
          message: successText,
        });
        queryClient.invalidateQueries("exitProcess");
        queryClient.invalidateQueries("subscription");
      },
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        alert.error({
          message: errorText,
          disclaimer: `Error: ${reason ? reason?.data?.detail : e}`,
        });
      },
    }
  );

  return { mutate, isLoading, isSuccess };
};

export default useExitProcess;
