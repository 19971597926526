import DashboardIndex from "./DashboardIndex";
import { DashboardProvider } from "context/controlTower/controlTowerContext";
import { TableProvider } from "context/tableContext/tableContext";

export default function ControlTower() {
  return (
    <DashboardProvider>
      <TableProvider>
        <DashboardIndex />
      </TableProvider>
    </DashboardProvider>
  );
}
