import { makeStyles, Theme } from "@material-ui/core";

const useEditProfileModalStyles = makeStyles((theme: Theme) => ({
  inputImage: {
    display: "none",
  },
  avatar: {
    width: 110,
    height: 110,
  },
  dialog: {
    maxWidth: 400,
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  contentDialog: {
    overflowY: "unset",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  rolesContainer: {
    justifyContent: "space-between",
  },
  buttonSelector: {
    fontWeight: 700,
  },
  mcContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    justifyContent: "flex-start",
  },
  groupedLabel: {
    aligSelf: "flex-start",
    fontWeight: "bold",
    width: "100%",
  },
  grupuedCheckbox: {
    display: "flex",
    gap: theme.spacing(1.5),
    width: "100%",
  },
}));

export default useEditProfileModalStyles;
