import { useGetToken } from "hooks";
import { useMutation } from "react-query";
import contractService from "services/contractService";

import Swal from "sweetalert2";

interface IFormData {
  contractId: number;
}

const useSendLinks = () => {
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      await contractService.newNotifyInitOnlineContract(formData.contractId, authToken);
    },
    {
      onSuccess: () => {
        Swal.fire({
          type: "success",
          title: "Éxito",
          text: `Los links han sido enviado con éxito`,
        });
      },
      onError: (e) => {
        Swal.fire({
          type: "error",
          title: "Error",
          text: `Los links no se han podido enviar. Error: ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useSendLinks;
