import { useMemo } from "react";

interface UsePaginationProps {
  limit: number;
  total: number;
  currentPage: number;
  pageNumbersLimit?: number;
}

const getStartItem = (pageNumbersLimit: number, currentPage: number) => {
  if (currentPage >= pageNumbersLimit) {
    return currentPage - pageNumbersLimit / 2;
  }
  return 1;
};

const getEndItem = (pageNumbersLimit: number, currentPage: number, totalPages: number) => {
  if (totalPages <= pageNumbersLimit) {
    return totalPages;
  }
  if (currentPage <= pageNumbersLimit / 2) {
    return pageNumbersLimit;
  }
  if (currentPage < totalPages - pageNumbersLimit) {
    return currentPage + pageNumbersLimit / 2;
  }
  return totalPages;
};

export default function usePagination({
  limit,
  total,
  currentPage,
  pageNumbersLimit = 10,
}: UsePaginationProps) {
  const totalPages = Math.ceil(total / limit);
  const showingCurrentPage = currentPage + 1; // selecting page 10 sets current page to 9
  const pageNumbers = useMemo(() => {
    const start = getStartItem(pageNumbersLimit, showingCurrentPage);
    const end = getEndItem(pageNumbersLimit, showingCurrentPage, totalPages);
    return Array.from({ length: end - start + 1 }, (_, index) => index + start);
  }, [totalPages, pageNumbersLimit, showingCurrentPage]);
  const showFirstItem = pageNumbers[0] !== 1;
  const showLastItem = pageNumbers[pageNumbers.length - 1] !== totalPages;
  return { totalPages, pageNumbers, showFirstItem, showLastItem };
}
