import { IWarranty } from "models/Warranty";
import { IDiscountsBreakdown, IWarrantyResponse } from "services/newPaymentService/types";

const parseDiscountsBreakdown = (discountBreakdowns: IDiscountsBreakdown[]) =>
  discountBreakdowns.map((discountBreakdown) => ({
    id: discountBreakdown.identifier,
    amount: discountBreakdown.amount,
    reason: discountBreakdown.reason,
    description: discountBreakdown.description,
  }));

const parseWarranty = (warranty: IWarrantyResponse): IWarranty => ({
  id: warranty.id,
  contractId: warranty.contract_id,
  originalAmount: warranty.original_amount,
  remainingAmount: warranty.remaining_amount,
  currency: warranty.currency,
  retainer: warranty.retainer,
  hasBeenLiquidated: warranty.has_been_liquidated,
  status: warranty.remaining_amount < 0 ? "NEGATIVE" : warranty.status,
  discountsBreakdown: warranty?.discounts_breakdown
    ? parseDiscountsBreakdown(warranty?.discounts_breakdown)
    : [],
  receiver: warranty.receiver,
  liquidationDate: warranty.liquidation_date,
  agreedDate: warranty.agreed_date,
  paymentDate: warranty.payment_date,
});

export default parseWarranty;
