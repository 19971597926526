import { TabProvider } from "context/tabContext/tabContext";
import { TableProvider } from "context/tableContext/tableContext";
import Landlord from "./Landlord";

export default function LandlordIndex() {
  return (
    <TabProvider>
      <TableProvider>
        <Landlord />
      </TableProvider>
    </TabProvider>
  );
}
