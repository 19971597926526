import { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useGetToken } from "hooks";
import { hasPermisions } from "Components/Admin/administrator/demand/visits/Tenants/Components/utils/permisions";
import userService from "services/userService";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import {
  SetIsOpenAsignCtAgentModal,
  setCtAgent,
} from "context/demandScheduledVisitsContext/demandScheduledActions";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import useAsignAgentStyles from "./AsignAgentStyles";

function AsingAgent() {
  const { getToken } = useGetToken();
  const [fullName, setFullName] = useState("");
  const [agentAutocomplete, setAgentAutocomplete] = useState(null);
  const [agentId, setAgentId] = useState<number>();
  const classes = useAsignAgentStyles();
  const {
    state: { isOpenAsignCtAgentModal, scheduleId, roles, agents },
    dispatch,
  } = useDemandScheduled();
  useEffect(() => {
    const getUser = async () => {
      const user = await userService.me();
      if (user) {
        setFullName(`${user.name} ${user.last_name}`);
        setAgentId(user.id);
      }
    };
    getUser();
  }, []);
  const handleAsignAgent = (scheduleId, agentId) => {
    getToken().then((authToken) => {
      setCtAgent(dispatch, authToken, scheduleId, agentId);
    });
  };
  const handleAsignAgentAutocomplete = (value) => {
    setAgentAutocomplete(value);
    if (value !== null) setAgentId(value.value);
  };
  return (
    <CustomCenteredModal
      open={isOpenAsignCtAgentModal}
      onClose={() => dispatch(SetIsOpenAsignCtAgentModal(false))}
      title="Asignar agente CT"
      subtitle=""
      showCloseButton>
      {hasPermisions(roles) ? (
        <>
          <div className={classes.textAsign}>
            Elige a un agente para darle seguimiento a la visita.
          </div>
          <div className={classes.autocompleteContainer}>
            <Autocomplete
              onChange={(_, value) => handleAsignAgentAutocomplete(value)}
              getOptionLabel={(option) => option.label}
              value={agentAutocomplete}
              options={agents}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              )}
              className={classes.autocomplete}
            />
          </div>
        </>
      ) : (
        <div className={classes.textAsign}>
          <strong>{fullName}</strong>
          {` ¿Estas seguro de querer asignarte a esta visita con ID: ${scheduleId}?`}
        </div>
      )}
      <div className={classes.buttonContainers}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          onClick={() => handleAsignAgent(scheduleId, agentId)}>
          {hasPermisions(roles) ? "Asignar agente" : "Dar seguimiento"}
        </Button>
      </div>
    </CustomCenteredModal>
  );
}

export default AsingAgent;
