const chilean_docs = [
  {
    label: "Rut",
    value: "RUT",
  },
];

const colombian_docs = [
  {
    label: "Cédula de ciudadanía",
    value: "CC",
  },
  {
    label: "Cédula de extranjería",
    value: "CE",
  },
  {
    label: "NIT",
    value: "NIT",
  },
  {
    label: "Pasaporte",
    value: "PASSPORT",
  },
  {
    label: "Sociedad Extranjera",
    value: "SE",
  },
];

const mexican_docs = [
  {
    label: "Pasaporte",
    value: "PASSPORT",
  },
];

function mapDocs(country: string) {
  const countries_docs = {
    Chile: chilean_docs,
    Colombia: colombian_docs,
    Mexico: mexican_docs,
  };
  return countries_docs[country];
}

export default mapDocs;
