import { makeStyles, Theme } from "@material-ui/core";

const useContractDetailsStyles = makeStyles<Theme>((theme) => ({
  label: {
    color: "#BFCBD1",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  select: {
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: "none",
  },
}));

export default useContractDetailsStyles;
