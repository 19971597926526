export const getChipText = (category: string): string => {
  switch (category) {
    case "landlord_rental":
      return "Arriendo";
    case "landlord_sales":
      return "Venta";
    default:
      return "";
  }
};
