import { UseFormReturn } from "react-hook-form";

import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { parserSchemaKeys } from "../../utils/parsers";
import { FormData } from "../../utils/types";

interface Props {
  methods: UseFormReturn<FormData, any>;
  handleIsEdit: () => void;
  onHandleClick: () => void;
  isLoading?: boolean;
}

const ModalButtons = ({ methods, handleIsEdit, onHandleClick, isLoading = false }: Props) => (
  <>
    {Object.keys(methods.formState.dirtyFields).map((key) => (
      <Typography key={key}>
        <b>{parserSchemaKeys[key]}:</b> {methods.getValues(`${key as keyof FormData}`)}
      </Typography>
    ))}
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <DesignSystemButton
          label="Cancelar"
          variant="tertiaryB"
          size="small"
          onClick={handleIsEdit}
        />
      </Grid>
      <Grid item>
        <DesignSystemButton
          label="Actualizar"
          variant="primary"
          size="small"
          onClick={onHandleClick}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  </>
);

export default ModalButtons;
