import { Theme, makeStyles } from "@material-ui/core/styles";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  chip: {
    background: "#FEF1F0",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  containerTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  contractTitle: {
    display: "flex",
    alignItems: "center",
  },
  borkerageInfo: {
    marginLeft: theme.spacing(2),
  },
  textTitle: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(32),
  },
  textSubtitle: {
    color: theme.palette.grey[900],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20),
  },
  linkSubscription: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "underline",
    "&:hover": {
      color: "#FF6349",
    },
  },
}));

export default useHeaderStyles;
