import ModalDialog from "@houm-com/ui/ModalDialog";

import { MovementType } from "models/Payments";
import { Locale } from "models/Countries";

import MarkAsPaidForm from "./components/MarkAsPaidForm";

interface Props {
  open: boolean;
  handleOnClose: () => void;
  movementId: string;
  subscriptionId: number;
  country: Locale;
  totalAmount: number;
  movementType: MovementType;
}

function MarkAsPaidModal({
  open,
  handleOnClose,
  movementId,
  subscriptionId,
  country,
  totalAmount,
  movementType,
}: Props) {
  return (
    <ModalDialog
      isOpen={open}
      title="Marcar como pagado"
      subtitle={`Subscripción ${subscriptionId}`}
      onClose={handleOnClose}
      hasCloseBtn>
      <MarkAsPaidForm
        movementId={movementId}
        country={country}
        totalAmount={totalAmount}
        handleOnClose={handleOnClose}
        movementType={movementType}
      />
    </ModalDialog>
  );
}

export default MarkAsPaidModal;
