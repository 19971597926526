import { ChangeEvent } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useStyles from "./ObservationsStyles";

interface Props {
  title: string;
  buttonText: string;
  onClick: () => Promise<void>;
  onTextChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
}

function ObservationItem({ title, buttonText, onClick, onTextChange, value }: Props) {
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.textAreaRoot}>
        <TextareaAutosize
          onChange={onTextChange}
          value={value}
          maxRows={4}
          className={classes.textArea}
        />
      </Box>
      <DesignSystemButton variant="tertiaryA" size="medium" label={buttonText} onClick={onClick} />
    </Grid>
  );
}

export default ObservationItem;
