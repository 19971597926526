import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined";

import contractFields from "context/closureFormContext/closureFormFields";

import useStyles from "./ContractValuesStyles";
import { ValueProps, filter } from "./utils";

const FilesValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.files[valueKey];
  if (filter(displayLabel, isAdmin, country) || !value || typeof value !== "string") return null;

  const classes = useStyles();
  return (
    <Grid item xs={2}>
      <a href={value} className={classes.anchor}>
        <div className={classes.documentContainer}>
          <div className={classes.attachIconContainer}>
            <AttachFileOutlined />
          </div>
          <div className={classes.filenameContainer}>
            <Tooltip title={displayLabel().label(country)}>
              <Typography className={classes.attachLabel}>
                {displayLabel().label(country)}
              </Typography>
            </Tooltip>
          </div>
        </div>
      </a>
    </Grid>
  );
};

export default FilesValue;
