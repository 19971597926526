import { AxiosResponse } from "axios";

export enum ChangePropertyStatusOperationType {
  UNPUBLISH = "unpublish",
  PUBLISH = "publish",
  SET_AS_LOST = "set_as_lost",
}

export enum PropertyBusinessType {
  SALE = "sale",
  RENT = "rental",
}

export interface UnpublishReason {
  label: string;
  value: number;
}

export interface SetAsLostReason {
  name: string;
  id: number;
}

type GetUnpublishReasons = (args: {
  businessType: string;
  operationType: ChangePropertyStatusOperationType;
}) => Promise<AxiosResponse<UnpublishReason[]>>;

export type GetSetAsLostReasons = () => Promise<AxiosResponse<SetAsLostReason[]>>;

export interface ChangePropertyStatusArgs {
  propertyId: number;
  reasonId: string;
  observation: string;
  businessType?: PropertyBusinessType;
}

export type UnpublishPropertyById = (args: ChangePropertyStatusArgs) => Promise<
  AxiosResponse<{
    status: number;
  }>
>;

export type PublishPropertyById = (args: ChangePropertyStatusArgs) => Promise<
  AxiosResponse<{
    status: number;
  }>
>;

export type SetPropertyAsLost = (args: ChangePropertyStatusArgs) => Promise<
  AxiosResponse<{
    status: number;
  }>
>;

export interface PropertyService {
  getUnpublishReasons: GetUnpublishReasons;
  getSetAsLostReasons: GetSetAsLostReasons;
  unpublishPropertyById: UnpublishPropertyById;
  publishPropertyById: PublishPropertyById;
  setPropertyAsLost: SetPropertyAsLost;
}
