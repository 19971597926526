import { useClosureForm } from "context/closureFormContext/closureFormContext";
import contractFields from "context/closureFormContext/closureFormFields";

import { ValueProps, filter, ValueEntry } from "./utils";

const PublicServicesValue: React.FC<ValueProps> = ({ valueKey, value, isAdmin, country }) => {
  const displayLabel = contractFields.publicServices[valueKey];
  if (filter(displayLabel, isAdmin, country)) return null;
  if (!value) return <ValueEntry label={displayLabel().label(country)} value={null} />;
  const { state } = useClosureForm();

  let displayValue = value;
  switch (valueKey) {
    case "waterService":
      displayValue = state.publicServices.availableServices.water.find(
        (service) => service.value === +value
      )?.label;
      break;
    case "energyService":
      displayValue = state.publicServices.availableServices.energy.find(
        (service) => service.value === +value
      )?.label;
      break;
    case "gasService":
      displayValue = state.publicServices.availableServices.gas.find(
        (service) => service.value === +value
      )?.label;
      break;
    default:
      break;
  }

  return <ValueEntry label={displayLabel().label(country)} value={String(displayValue)} />;
};

export default PublicServicesValue;
