import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { IWarrantyDiscountsBreakdown, IWarrantyStatus } from "models/Warranty";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import CreateDiscountModal from "./components/CreateDiscountModal";
import DiscountsTable from "./components/DiscountsTable";

interface Props {
  contractId: number;
  country: Locale;
  discounts: IWarrantyDiscountsBreakdown[] | null;
  status: IWarrantyStatus;
  currencyCode: CurrencyCode;
}

const Discounts = ({ contractId, country, discounts, status, currencyCode }: Props) => {
  const { handleOpenModal, open } = useModal();

  return (
    <>
      <AdminCard title="Descuentos" withSpace={false} showTitleBackground={true}>
        <DiscountsTable
          warrantyStatus={status}
          discounts={discounts}
          contractId={contractId}
          country={country}
          currencyCode={currencyCode}
        />
        {["UNLIQUIDATED", "NEGATIVE"].includes(status) && (
          <DesignSystemButton
            label="Agregar descuento"
            variant="primary"
            size="small"
            onClick={() => handleOpenModal()}
          />
        )}
      </AdminCard>
      <CreateDiscountModal
        contractId={contractId}
        country={country}
        onHandleClose={handleOpenModal}
        open={open}
        status={status}
      />
    </>
  );
};

export default Discounts;
