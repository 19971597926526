import { ComponentProps, ReactNode } from "react";

import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";

import useControlledTextfieldStyle from "./ControlledTextfieldStyle";

interface Props extends ComponentProps<typeof ControlledTextField> {
  placeholder?: string;
  type?: string;
  grayLine?: boolean;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
}
function ControlledTextfieldStyled({
  placeholder = "",
  type = "text",
  endAdornment = null,
  startAdornment = null,
  grayLine = false,
  ...props
}: Props) {
  const classes = useControlledTextfieldStyle({ grayLine });
  return (
    <ControlledTextField
      textFieldProps={{
        placeholder,
        InputProps: {
          type,
          endAdornment,
          startAdornment,
          disableUnderline: false,
          classes: {
            underline: classes.underlineTextField,
          },
        },
      }}
      {...props}
    />
  );
}

export default ControlledTextfieldStyled;
