import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import useChangeEvaluationMemberStatus from "domains/evaluations/hooks/useChangeEvaluationMemberStatus";
import { StatusMemberEnum } from "domains/evaluations/models/Evaluations";
import useConvertToMain from "domains/evaluations/hooks/useConvertToMain";
import useDeleteMember from "domains/evaluations/hooks/useDeleteMember";

import AskDocumentationModal from "../AskDocumentationModal";
import { ModalType } from "../../utils/types";
import RejectModal from "../RejectModal";

interface Props {
  onHandleModal: () => void;
  modal: ModalType;
  memberId: string;
}

const ModalManager = ({ onHandleModal, modal, memberId }: Props) => {
  const { mutate: changeMemberStatus, isLoading: isLoadingMemberStatus } =
    useChangeEvaluationMemberStatus({
      evaluationMemberId: memberId,
      onHandleSuccess: onHandleModal,
    });
  const { mutate: convertToMain, isLoading: isLoadingConvertToMain } = useConvertToMain({
    evaluationMemberId: memberId,
    onHandleSuccess: onHandleModal,
  });
  const { mutate: deleteMember, isLoading: isLoadingDeleteMember } = useDeleteMember({
    evaluationMemberId: memberId,
    onHandleSuccess: onHandleModal,
  });

  return (
    <>
      <RejectModal
        open={modal === "markAsRejected"}
        handleOpenModal={onHandleModal}
        rejectType="reject"
        evaluationMemberId={memberId}
      />
      <RejectModal
        open={modal === "markAsDesisted"}
        handleOpenModal={onHandleModal}
        rejectType="desist"
        evaluationMemberId={memberId}
      />
      <ConfirmDialog
        title="¿Seguro que quieres aprobar esta evaluación?"
        isOpen={modal === "markAsApproved"}
        onClose={onHandleModal}
        onSubmit={() => changeMemberStatus({ status: StatusMemberEnum.COMMERCIALLY_APPROVED })}
        submitButtonProps={{
          isLoading: isLoadingMemberStatus,
        }}
        submitButtonLabel="Sí, aprobar"
      />
      <ConfirmDialog
        title="¿Seguro que quieres marcar esta evaluación como listo para evaluar?"
        isOpen={modal === "readyForEvaluation"}
        onClose={onHandleModal}
        onSubmit={() => changeMemberStatus({ status: StatusMemberEnum.READY_FOR_EVALUATION })}
        submitButtonProps={{
          isLoading: isLoadingMemberStatus,
        }}
        submitButtonLabel="Sí, lista para evaluar"
      />
      <AskDocumentationModal
        open={modal === "askForDocumentation"}
        handleOpenModal={onHandleModal}
        evaluationMemberId={memberId}
      />
      <ConfirmDialog
        title="¿Seguro que quieres eliminar este codeudor?"
        isOpen={modal === "deleteCodebtor"}
        onClose={onHandleModal}
        onSubmit={() => deleteMember()}
        submitButtonProps={{
          isLoading: isLoadingDeleteMember,
        }}
        submitButtonLabel="Sí, eliminar"
      />
      <ConfirmDialog
        title="¿Seguro que quieres convertir en arrendatario principal a este codeudor?"
        isOpen={modal === "changeMain"}
        onClose={onHandleModal}
        onSubmit={() => convertToMain()}
        submitButtonProps={{
          isLoading: isLoadingConvertToMain,
        }}
        submitButtonLabel="Sí, convertir"
      />
    </>
  );
};

export default ModalManager;
