import { useMemo } from "react";

import Swal from "sweetalert2";

import { useFormContext } from "react-hook-form";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ControlledList, ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import usePaymentAdjustment from "hooks/usePaymentAdjustment";
import { AdjustmentPaymentType } from "models/Payments";
import { CountryUpperCase } from "models/Countries";

import usePaymentAdjustStyles from "./PaymentAdjustConceptStyles";

export interface IProps {
  defaultValue?: string;
  country: CountryUpperCase;
  adjustmentType?: "monthly_payment" | "closing_payment" | "payment_creation" | "spot_payment";
  paymentType: AdjustmentPaymentType;
  movementType?: "INCOME" | "OUTFLOW" | "PROFIT";
}

const OTHER_OPTION_VALUE = "Otros";

function PaymentAdjustConcept({
  defaultValue,
  country = "Chile",
  adjustmentType = "monthly_payment",
  paymentType,
  movementType,
}: IProps) {
  const classes = usePaymentAdjustStyles();
  const methods = useFormContext();
  const movementParam = useMemo(() => {
    const MOVEMENT_TYPES: Record<typeof movementType, "tenant" | "landlord" | "both"> = {
      INCOME: "tenant",
      OUTFLOW: "landlord",
      PROFIT: "both",
    };
    return MOVEMENT_TYPES[movementType] || MOVEMENT_TYPES.PROFIT;
  }, [movementType]);
  const descriptionValue = methods.watch("description");
  const { data, isLoading, error } = usePaymentAdjustment({
    roleScope: movementParam,
    scope: adjustmentType,
    paymentType,
    country,
    methods,
  });

  if (error) {
    Swal.fire({
      type: "error",
      text: "No se pudo obtener los descuentos y abonos disponibles",
    });
  }

  return (
    <Loading loading={isLoading}>
      <>
        {data.length !== 0 && (
          <ControlledList
            label="Motivo"
            id="description"
            defaultValue={defaultValue ?? ""}
            disableUnderline
            options={data}
            classes={{
              select: classes.select,
            }}
          />
        )}
        {descriptionValue === OTHER_OPTION_VALUE && (
          <ControlledTextField
            label="Descripción"
            id="descriptionAux"
            defaultValue=""
            textFieldProps={{
              InputProps: {
                classes: {
                  root: classes.textInput,
                },
              },
            }}
          />
        )}
      </>
    </Loading>
  );
}

export default PaymentAdjustConcept;
