import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  noImgsText: {
    color: theme.palette.grey[200],
  },
  tableImgContainerBtn: {
    display: "flex",
    justifyContent: "flex-start",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    padding: theme.spacing(0.125, 1),
  },
  tableImgContainer: {
    display: "flex",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    overflow: "hidden",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
  },
  tableImg: {
    display: "flex",
    width: "100%",
  },
  carouselImgContainer: {
    display: "flex",
    maxHeight: "50vh",
  },
  carouselImg: {
    display: "flex",
    width: "100%",
    objectFit: "contain",
  },
}));
