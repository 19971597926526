import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: "0.875rem",
    marginBottom: theme.spacing(0.5),
  },
  textLight: {
    fontSize: "0.875rem",
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(0.5),
  },
}));
