import { Typography } from "@material-ui/core";

import { MovementBreakdownRecord } from "models/Payments";
import { CurrencyCode } from "models/Currencies";

import useMovementTableStyles from "./MovementTableStyles";
import Table from "./components/Table";

interface Props {
  movements: MovementBreakdownRecord[];
  loading: boolean;
  subscriptionId: number;
  title: string;
  contractCurrency: CurrencyCode;
}

function MovementTable({ movements, loading, subscriptionId, title, contractCurrency }: Props) {
  const classes = useMovementTableStyles();
  return (
    <>
      <Typography component="h1" className={classes.tableTitle}>
        {title}
      </Typography>
      <Table
        movements={movements}
        loading={loading}
        subscriptionId={subscriptionId}
        contractCurrency={contractCurrency}
      />
    </>
  );
}

export default MovementTable;
