import { ReactNode } from "react";

import { Card, CardContent, CardHeader, CardHeaderProps } from "@material-ui/core";
import useCustomCardStyles from "./CustomCardStyles";

interface Props extends CardHeaderProps {
  children: ReactNode;
}

const CustomCard = ({ children, ...props }: Props) => {
  const classes = useCustomCardStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
          action: classes.cardHeaderAction,
          content: classes.cardHeaderContent,
        }}
        {...props}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
