import { makeStyles, Theme } from "@material-ui/core/styles";

const useTagFormStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  countryTitle: {
    margin: theme.spacing(0, 0, 1, 1),
    fontWeight: 700,
  },
  idsTitle: {
    margin: theme.spacing(0, 0, 1, 0),
    fontWeight: 700,
  },
  formItem: {
    margin: theme.spacing(2, 1, 0, 1),
  },
  tagDetails: {
    marginLeft: theme.spacing(1),
  },
  searchId: {
    maxWidth: "50%",
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  selectLabel: {
    marginTop: theme.spacing(0.5),
  },
  wrap: {
    paddingLeft: 0,
  },
  datePicker: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.grey[500]}`,
    borderRadius: "100rem",
    padding: theme.spacing(0, 2, 0, 2),
  },
  selectName: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.grey[500]}`,
    borderRadius: "100rem",
    "&.Mui-focused": {
      borderRadius: "100rem",
    },
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(1),
    height: 36,
    borderRadius: 18,
    "& .MuiChip-label": {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
    maxWidth: "fit-content",
    marginTop: theme.spacing(1),
  },
  modal: {
    textAlign: "center",
  },
  confirmButton: {
    marginTop: theme.spacing(4),
  },
}));

export default useTagFormStyles;
