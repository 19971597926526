import useGuard from "../hooks/useGuard";
import { ProtectedByPermission, ProtectedByRole } from "../models/Protected";

export type ProtectedComponentProps = {
  children?: React.ReactElement;
  fallback?: React.ReactElement;
} & (ProtectedByRole | ProtectedByPermission);

export const defaultValues: ProtectedComponentProps = {
  scope: "roles",
  strategy: "any",
  require: undefined,
};

export default function ProtectedComponent({
  children = null,
  fallback = null,
  scope = defaultValues.scope,
  strategy = defaultValues.strategy,
  require = defaultValues.require,
}: ProtectedComponentProps) {
  const { guard } = useGuard();
  if (!require) {
    return children;
  }
  return guard[scope][strategy](require as string[]) ? children : fallback;
}
