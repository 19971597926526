import PropertySchedule from "Components/Admin/administrator/availability/components/PropertySchedule";
import {
  useAvailability,
  AvailavilityProvider,
} from "context/availabilityContext/availabilityContext";
import { useEffect } from "react";
import { getPropertyDetails } from "context/availabilityContext/availabilityActions";
import useGetToken from "hooks/useGetToken";
import Spinner from "Components/Shared/partials/Spinner";

function AvailabilitySchedule({ id }: { id: number }) {
  const { state, dispatch } = useAvailability();
  const { getToken } = useGetToken();
  useEffect(() => {
    getToken().then((authToken) => {
      getPropertyDetails(id, dispatch, authToken);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.propertyId) {
    return <Spinner />;
  }

  if (state.propertyId) {
    return (
      <>
        <h3 className="text-h20 my-4">Horario de visitas</h3>
        <PropertySchedule />
      </>
    );
  }
}

export default function AvailabilityScheduleProvider({ id }: { id: number }) {
  return (
    <AvailavilityProvider>
      <AvailabilitySchedule id={id} />
    </AvailavilityProvider>
  );
}
