import { useHistory } from "react-router-dom";

import Button from "Components/Admin/UIComponents/Buttons/CustomButton";
import useStyles from "./TableHeadersStyles";
import { Grid } from "@material-ui/core";
import ProtectedComponent from "domains/auth/components/ProtectedComponent";

function TableHeader() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container justifyContent="flex-end" className={classes.container}>
      <ProtectedComponent
        scope="roles"
        strategy="any"
        require={[
          "control_tower_admin",
          "control_tower",
          "contract_admin",
          "contract",
          "evaluation",
          "evaluation_admin",
          "management",
          "management_admin",
          "sales",
          "sales_admin",
          "super_admin",
        ]}>
        <Button
          id="create-project"
          active
          rootstyle={classes.newPropertyButton}
          onClick={() => history.push("/new-project")}>
          Crear Proyecto
        </Button>
      </ProtectedComponent>
    </Grid>
  );
}

export default TableHeader;
