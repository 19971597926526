import { IconButton } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";

import { PhoneInput } from "models/PhoneInput";
import { Activity } from "models/DemandLead";

import { callByToky, setCallbacks, initTokyClient } from "context/tokyContext/tokyActions";
import { TokySessionStatus, ICallbacks } from "context/tokyContext/tokyTypes";
import {
  setIsOpenActivityForm,
  setCompleteActivityConfig,
} from "context/demandLeadContext/lead/demandLeadActions";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";

import { useToky } from "context/tokyContext/tokyContext";

interface Props {
  activity: Activity;
  targetDialNumber: PhoneInput;
}

function TokyButton({ activity, targetDialNumber }: Props) {
  const { dispatch: tokyDispatch, state } = useToky();
  const { dispatch: demandDispatch } = useDemandLead();

  const callbacks: ICallbacks = {
    [TokySessionStatus.BYE]: () => {
      demandDispatch(setIsOpenActivityForm(true));
      demandDispatch(setCompleteActivityConfig(true, activity));
    },
  };

  const handleInitialize = async () => {
    tokyDispatch(setCallbacks(callbacks));
    if (state.isPhoneOpen) {
      const agentId = "communications@houm.com";
      await initTokyClient(agentId, state.accessToken, tokyDispatch, callbacks);
      return;
    }
    callByToky(targetDialNumber, tokyDispatch);
  };

  return (
    <IconButton id="tokyButton" onClick={handleInitialize} color="primary">
      <CallIcon fontSize="small" />
    </IconButton>
  );
}
export default TokyButton;
