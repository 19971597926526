import { SimilarProperty } from "models/createProperty";
import { IProperty } from "models";

export enum ActionType {
  SET_PROPERTY,
  SET_SIMILAR_PROPERTIES,
  SET_IS_SCHEDULING,
  SET_PROPERTY_AVAILABILITY,
  SET_IS_OPEN_SUCCESS_SCHEDULED,
  SET_IS_OPEN_NO_AVAILABILITY_MODAL,
  SET_APPRAISER_SELECTED_ID,
  SET_IS_OPEN_ERROR_MODAL,
  SET_IS_OPEN_LOADING_MODAL,
  SET_IS_OPEN_SCHEDULE_ADVISORY_MODAL,
  SET_ADVISORY_MESSAGE,
  SET_ADVISORY_RESPONSE_DATA,
}

export interface SetProperty {
  type: typeof ActionType.SET_PROPERTY;
  property: SimilarProperty;
}
export interface SetAvailability {
  type: typeof ActionType.SET_PROPERTY_AVAILABILITY;
  availability: Record<string, string[]>;
}

export interface SetSimilarProperties {
  type: typeof ActionType.SET_SIMILAR_PROPERTIES;
  similarProperties: SimilarProperty;
}

export interface SetIsScheduling {
  type: typeof ActionType.SET_IS_SCHEDULING;
  isScheduling: boolean;
}

export interface SetIsOpenNoAvModal {
  type: typeof ActionType.SET_IS_OPEN_NO_AVAILABILITY_MODAL;
  isOpenNoAvailabilityModal: boolean;
}

export interface SetIsOpenScucessScheduled {
  type: typeof ActionType.SET_IS_OPEN_SUCCESS_SCHEDULED;
  isOpenSuccessScheduled: boolean;
}

export interface SetIsOpenErrorModal {
  type: typeof ActionType.SET_IS_OPEN_ERROR_MODAL;
  isOpenErrorModal: boolean;
}

export interface SetIsOpenLoadingModal {
  type: typeof ActionType.SET_IS_OPEN_LOADING_MODAL;
  isOpenLoadingModal: boolean;
}

export interface SetAppraiserSelectedId {
  type: typeof ActionType.SET_APPRAISER_SELECTED_ID;
  appraiserSelectedId: boolean;
}

export interface SetScheduleAdvisoryModal {
  type: typeof ActionType.SET_IS_OPEN_SCHEDULE_ADVISORY_MODAL;
  isOpenScheduleAdvisoryModal: boolean;
}

export interface SetAdvisoryMessage {
  type: typeof ActionType.SET_ADVISORY_MESSAGE;
  advisoryMessage: boolean;
}

export interface SetAdvisoryResponseData {
  type: typeof ActionType.SET_ADVISORY_RESPONSE_DATA;
  advisoryResponseData: boolean;
}

export type Action =
  | SetIsOpenScucessScheduled
  | SetAvailability
  | SetProperty
  | SetSimilarProperties
  | SetIsOpenNoAvModal
  | SetIsScheduling
  | SetIsOpenErrorModal
  | SetIsOpenLoadingModal
  | SetAppraiserSelectedId
  | SetScheduleAdvisoryModal
  | SetAdvisoryMessage
  | SetAdvisoryResponseData;

export interface State {
  property?: IProperty; //selectedProperty
  similarProperties?: SimilarProperty[];
  isScheduling: boolean;
  availability: Record<string, string[]>;
  isOpenSuccessScheduled?: boolean;
  isOpenNoAvailabilityModal?: boolean;
  appraiserSelectedId: string;
  isOpenLoadingModal: boolean;
  isOpenErrorModal: boolean;
  isOpenScheduleAdvisoryModal: boolean;
  advisoryMessage: boolean;
  advisoryResponseData: {
    appraiserId: string;
    appraiserName: string;
    scheduleId: number;
    daySchedule: string;
    leadEmail: string;
  };
}

export type Dispatch = (action: Action) => void;
