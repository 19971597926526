import { IContractInsuranceCompany } from "models/InsuranceCompany";
import { IContractInsuranceCompanyResponse } from "services/insuranceService/types";

export const parseContractInsuranceCompany = (
  contractInsuranceCompany: IContractInsuranceCompanyResponse
): IContractInsuranceCompany => ({
  id: contractInsuranceCompany.insurance_company,
  contract: contractInsuranceCompany.contract,
  guaranteedMonths: contractInsuranceCompany.guaranteed_months,
  objectId: contractInsuranceCompany.id,
});
