import { makeStyles, Theme } from "@material-ui/core";

const useSimpleTagFieldStyles = makeStyles((theme: Theme) => ({
  tagWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    cursor: "default",
  },
}));

export default useSimpleTagFieldStyles;
