import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { IoChevronBackOutline as ArrowBackIcon } from "@houm-com/ui-icons";

import classes from "../../PromotionDetailsStyles";

type BackButtonProps = {
  backTo?: string;
  text?: string;
  arrow?: boolean;
};

export default function BackButton({ backTo, text = "Volver", arrow = true }: BackButtonProps) {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    if (backTo) {
      history.push("/admin/promotions");
    } else {
      history.goBack();
    }
  }, [backTo, history]);

  return (
    <a onClick={handleGoBack} className={classes.backButton}>
      {arrow && <ArrowBackIcon />}
      <span>{text}</span>
    </a>
  );
}
