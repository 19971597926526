import { ReactComponent as DoubleDownArrow } from "assets/icons/arrows/double-down.svg";
import { ReactComponent as DownArrow } from "assets/icons/arrows/chevron-down.svg";
import { ReactComponent as MinusCircle } from "assets/icons/minus-circle.svg";
import { ReactComponent as UpArrow } from "assets/icons/arrows/chevron-up.svg";
import { ReactComponent as DoubleUpArrow } from "assets/icons/arrows/double-up.svg";
import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";
import { TicketStatus } from "models/User";

import { PriorityFieldProps } from "../components/PriorityField/PriorityField";
import { StatusFieldProps } from "../components/StatusField/StatusField";

export const priorityData = (priority: number): PriorityFieldProps => {
  switch (priority) {
    case 2:
      return {
        value: priority,
        symbol: <DownArrow />,
        tagType: TagType.INFO,
      };
    case 3:
      return {
        value: priority,
        symbol: <MinusCircle />,
        tagType: TagType.SUCCESS,
      };
    case 4:
      return {
        value: priority,
        symbol: <UpArrow />,
        tagType: TagType.WARNING,
      };
    case 5:
      return {
        value: priority,
        symbol: <DoubleUpArrow />,
        tagType: TagType.ERROR,
      };
    case 1:
    default:
      return {
        value: priority,
        symbol: <DoubleDownArrow />,
        tagType: TagType.LIGHT_GREY,
      };
  }
};

export const statusData = (status: TicketStatus): StatusFieldProps => {
  switch (status) {
    case "done":
      return {
        value: "Done",
        tagType: TagType.SUCCESS,
      };
    case "open":
      return {
        value: "Open",
        tagType: TagType.WARNING,
      };
    case "snoozed":
      return {
        value: "Snoozed",
        tagType: TagType.INFO,
      };
    default:
      return {
        value: "Error",
        tagType: TagType.GREY,
      };
  }
};
