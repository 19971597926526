import { makeStyles, Theme } from "@material-ui/core";
import { PhoneDisplayStatus } from "context/tokyContext/tokyTypes";

const PHONE_HEIGHT = 520;

export default makeStyles<Theme, { isPhoneOpen: boolean; phoneDisplayStatus: PhoneDisplayStatus }>(
  (theme) => ({
    phoneContainer: {
      backgroundColor: "white",
      position: "absolute",
      bottom: 10,
      right: 20,
      width: 300,
      height: PHONE_HEIGHT,
      borderRadius: 20,
      overflow: "hidden",
      transition: "0.3s",
      boxShadow: ({ isPhoneOpen }) => (isPhoneOpen ? theme.shadows[1] : theme.shadows[0]),
      maxHeight: ({ phoneDisplayStatus }) => {
        switch (phoneDisplayStatus) {
          case PhoneDisplayStatus.OPEN:
            return PHONE_HEIGHT;
          case PhoneDisplayStatus.MINIMIZED:
            return 50;
          default:
            return 0;
        }
      },
    },
    settingsDialogTitleContainer: {
      maxWidth: "unset",
    },
    settingDialogModalContainer: {
      minWidth: 500,
    },
  })
);
