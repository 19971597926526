import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import photoService from "services/photoService";
import Swal from "sweetalert2";

import usePhotoStyles from "./PhotoStyles";

import { useGetToken } from "hooks";

const Photo = ({ index, onClick, photo }) => {
  const { getToken } = useGetToken();
  const classes = usePhotoStyles();

  const handleClick = (event) => {
    onClick(event, { photo, index });
  };

  const handleDeletePhoto = async () => {
    const authToken = await getToken();
    const res = await photoService.delete(photo.id, authToken);
    if (res) {
      window.location.reload();
    } else {
      Swal.fire("Error", "No se pudo eliminar la imagen, intente más tarde", "error");
    }
  };

  return (
    <Tooltip
      interactive
      arrow
      placement="top"
      title={
        <IconButton aria-label="Eliminar" className={classes.icon} onClick={handleDeletePhoto}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      }
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}>
      <img
        src={photo.url}
        className={classes.image}
        onClick={onClick ? handleClick : null}
        alt="img"
      />
    </Tooltip>
  );
};

export default Photo;
