import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  thumb: {
    display: "inline-flex",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 80,
    height: 80,
    padding: theme.spacing(0.5),
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: 80,
    height: 80,
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "#4C6977",
    borderStyle: "dashed",
    outline: "none",
    cursor: "pointer",
    height: 80,
    width: 80,
    "&:hover": {
      backgroundColor: "#F7F9F9",
    },
  },
  icon: {
    "& path": {
      stroke: "#4C6977",
    },
    width: 20,
    height: 20,
  },
  container: {
    width: "100%",
    gridTemplateRows: "repeat(4, 1fr)",
    gridRowGap: theme.spacing(2),
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: theme.spacing(3),
  },
}));

export default useStyles;
