import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    height: "20vh",
    alignItems: "center",
  },
}));

export default useStyles;
