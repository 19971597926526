import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { SnackbarProvider } from "notistack";
import Swal from "sweetalert2";

import { GridCloseIcon } from "@material-ui/x-grid";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PinDropIcon from "@material-ui/icons/PinDrop";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import {
  downloadCsv,
  getSingleEvaluation,
  handleVerifyDoc,
} from "context/evaluationContext/evaluationAction";
import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import { RejectDesistModal } from "Components/Admin/UIComponents/Modals";
import newEvaluationService from "services/newEvaluationService";
import { evaluationDiscardInfo } from "utils/discardInfo";
import RejectOrDesistReason from "./RejectOrDesistReason";
import CodebtorDataCard from "./CodebtorDataCard";
import dicomService from "services/dicomService";
import EditAddressForm from "./EditAddressForm";
import useTooltipStyles from "./TooltipStyles";
import useStyles from "./CodebtorCardStyles";
import DocumentsView from "./DocumentsView";
import WriteComment from "./WriteComment";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import Comments from "./Comments";
import Evaluate from "./Evaluate";

interface Props {
  evaluation: any;
  evaluationPerson: string;
  onChangeRent: (value: any) => void;
}

function CodebtorCard({ evaluation, evaluationPerson, onChangeRent }: Props) {
  const classesTooltip = useTooltipStyles();
  const { getToken } = useGetToken();
  const { user } = useAuth0();
  const history = useHistory();
  const params = useParams<{
    main_id: string;
    codebtor_id: string;
    country: string;
  }>();
  const { main_id: mainId, codebtor_id: codebtorIds } = params;
  const classes = useStyles();
  const { state, dispatch } = useEvaluation();
  const { singleEvaluation, houmer } = state;
  const { codebtorId: viewing } = singleEvaluation;

  const [openDocs, setOpenDocs] = useState(false);
  const [openEvaluate, setOpenEvaluate] = useState(false);
  const [openAskDoc, setOpenAskDoc] = useState(false);
  const [openEditAddress, setOpenEditAddress] = useState(false);
  const [openRejectDesist, setOpenRejectDesist] = useState(false);
  const [openRejectReason, setOpenRejectReason] = useState(true);
  const [openDesistReason, setOpenDesistReason] = useState(false);
  const [discardType, setDiscardType] = useState(null);
  const [avla, setAvla] = useState(null);
  const [liquidSalary, setLiquidSalary] = useState(null);
  const [loadingAVLA, setLoadingAVLA] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [openFileFlag, setOpenFileFlag] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [loadingBuro, setLoadingBuro] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOpenDocs = () => {
    if (params.country === "mx") setOpenFileFlag(true);
    setOpenDocs(true);
  };

  const handleCloseDocs = () => {
    setOpenDocs(false);
  };

  const handleOpenEvaluate = () => {
    setOpenEvaluate(true);
  };

  const handleCloseEvaluate = () => {
    setOpenEvaluate(false);
  };

  const handleOpenAskDoc = () => {
    setOpenAskDoc(true);
  };

  const handleCloseAskDoc = () => {
    setOpenAskDoc(false);
  };

  const handleCloseRejectDesist = () => {
    setOpenRejectDesist(false);
  };

  const handleOpenRejectReason = () => {
    setOpenRejectReason(true);
  };

  const handleCloseRejectReason = () => {
    setOpenRejectReason(false);
  };

  const handleOpenDesistReason = () => {
    setOpenDesistReason(true);
  };

  const handleCloseDesistReason = () => {
    setOpenDesistReason(false);
  };

  const update = async (status) => {
    const authToken = await getToken();
    const userData = JSON.parse(localStorage.getItem("user"));
    const newEvaluation = evaluation;
    newEvaluation.status = status;
    newEvaluation.updated_by = userData.id;

    const res = await newEvaluationService.update(
      newEvaluation.id,
      newEvaluation,
      authToken,
      params.country as Locale
    );

    if (res) {
      Swal.fire({
        type: "success",
        text: `Estado de ${evaluation.user.name} ${evaluation.user.last_name} cambiado a ${status}`,
      }).then(() => getSingleEvaluation(mainId, codebtorIds, params.country, authToken, dispatch));
    } else {
      Swal.fire({
        type: "error",
        text: "No se ha podido actualizar el estado de la evaluación.",
      });
    }
  };

  const askDoc = async (comment) => {
    const authToken = await getToken();
    const userData = JSON.parse(localStorage.getItem("user"));
    const sendEmail = await newEvaluationService.requestDocuments(
      evaluation.id,
      comment,
      userData.id,
      houmer?.email,
      houmer?.phone,
      evaluation?.property_id,
      authToken,
      params.country as Locale
    );

    if (sendEmail) {
      const addComment = await newEvaluationService.comment(
        evaluation.id,
        {
          comment,
          commenter_id: userData.id,
          comment_type: "Pedir documentación",
        },
        authToken,
        params.country as Locale
      );
      if (addComment) {
        update("Documentación pendiente");
      } else {
        Swal.fire({
          type: "error",
          text: "No se ha logrado guardar el comentario.",
        });
      }
    } else {
      Swal.fire({
        type: "error",
        text: "No se ha podido enviar el correo.",
      });
    }
  };

  const reject = async (reason, author, comment) => {
    const authToken = await getToken();
    const createRejectOrDesist = newEvaluationService.createRejectOrDesist(
      evaluation.id,
      "rejected",
      reason,
      comment,
      author,
      authToken,
      params.country as Locale
    );
    if (createRejectOrDesist) {
      update("Rechazado");
    } else {
      Swal.fire({
        type: "error",
        text: "No se ha podido guardar la información.",
      });
    }
  };

  const desist = async (reason, author, comment) => {
    const authToken = await getToken();
    const createRejectOrDesist = newEvaluationService.createRejectOrDesist(
      evaluation.id,
      "desisted",
      reason,
      comment,
      author,
      authToken,
      params.country as Locale
    );
    if (createRejectOrDesist) {
      update("Desistido");
    } else {
      Swal.fire({
        type: "error",
        text: "No se ha podido guardar la información.",
      });
    }
  };

  const saveDiscard = (reason, author, type) => (comment) => {
    if (type === "desist") {
      return desist(reason, author, comment);
    }
    return reject(reason, author, comment);
  };

  const confirmMakeMain = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    Swal.fire({
      title: "¿Convertir en principal?",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (button) => {
      if (button.value) {
        const authToken = await getToken();
        const res = await newEvaluationService.changeMain(
          evaluation.main_applicant.id,
          evaluation.codebtor,
          userData.id,
          authToken,
          params.country as Locale
        );
        if (res) {
          Swal.fire({
            title: "Arrendatario principal actualizado.",
            type: "success",
          }).then(() =>
            window.location.replace(`/admin/evaluations/cl/${evaluation.codebtor}/${viewing}`)
          );
        } else {
          Swal.fire({
            title: "Ha ocurrido un error, intente nuevamente.",
            type: "error",
          });
        }
      }
    });
  };

  const deleteEvaluation = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    Swal.fire({
      title: "¿Eliminar?",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then(async (button) => {
      if (button.value) {
        const authToken = await getToken();
        const res = await newEvaluationService.delete(
          evaluation.id,
          userData.id,
          authToken,
          params.country as Locale
        );
        if (res) {
          Swal.fire({
            title: "Evaluación eliminada.",
            type: "success",
          }).then(() =>
            window.location.replace(
              `/admin/evaluations/cl/${evaluation.main_applicant.id}/${evaluation.main_applicant.id}`
            )
          );
        } else {
          Swal.fire({
            title: "Ha ocurrido un error, intente nuevamente.",
            type: "error",
          });
        }
      }
    });
  };

  const newMenu = [
    <MenuItem
      key={0}
      onClick={(e) => {
        handleClose(e);
        handleOpenEvaluate();
      }}>
      Evaluar
    </MenuItem>,
    <MenuItem
      key={1}
      onClick={(e) => {
        handleClose(e);
        handleOpenAskDoc();
      }}>
      Pedir documentación
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={(e) => {
        handleClose(e);
        update("Listo para evaluar");
      }}>
      Marcar como listo para evaluar
    </MenuItem>,
    <MenuItem
      key={3}
      onClick={(e) => {
        handleClose(e);
        update("Aprobado comercialmente");
      }}>
      Aprobar
    </MenuItem>,
    <MenuItem
      key={4}
      onClick={(e) => {
        handleClose(e);
        setDiscardType("reject");
        setOpenRejectDesist(true);
      }}>
      Rechazar
    </MenuItem>,
    <MenuItem
      key={5}
      onClick={(e) => {
        handleClose(e);
        setDiscardType("desist");
        setOpenRejectDesist(true);
      }}>
      Desistir
    </MenuItem>,
    evaluation.main_applicant.id !== evaluation.codebtor && (
      <MenuItem
        key={6}
        onClick={(e) => {
          handleClose(e);
          confirmMakeMain();
        }}>
        Convertir en principal
      </MenuItem>
    ),
    evaluation.main_applicant.id !== evaluation.codebtor && (
      <MenuItem
        key={7}
        onClick={(e) => {
          handleClose(e);
          deleteEvaluation();
        }}>
        Eliminar
      </MenuItem>
    ),
  ];

  const statusClass = (status) => {
    if (["Listo para evaluar", "Aprobado comercialmente"].includes(status)) {
      return classes.greenStatus;
    }
    if (["Documentación pendiente", "Nuevos documentos"].includes(status)) {
      return classes.orangeStatus;
    }
    if (status === "Perfil aprobado") {
      return classes.readyStatus;
    }
    if (status === "Rechazado") {
      return classes.rejectedStatus;
    }
    if (status === "Desistido") {
      return classes.redStatus;
    }
    return classes.neutralStatus;
  };

  useEffect(() => {
    if (params.country === "cl") {
      const fetchAvla = async (authToken) => {
        const data = {
          name: `${evaluation.user.name} ${evaluation.user.last_name} ${evaluation.user.second_last_name}`,
          dni: evaluation.user.rut,
        };
        const res = await dicomService.sendGetDicom(data, authToken);
        if (res) {
          setAvla(res);
        } else {
          setAvla("Error");
        }
        setLoadingAVLA(false);
      };

      if (avla === null && !loadingAVLA) {
        setLoadingAVLA(true);
        getToken().then((authToken) => fetchAvla(authToken));
      }
    }
  }, [
    avla,
    loadingAVLA,
    evaluation.user.name,
    evaluation.user.last_name,
    evaluation.user.second_last_name,
    evaluation.user.rut,
    evaluation.id,
  ]);

  useEffect(() => {
    const fetchDetails = async (authToken) => {
      const res = await newEvaluationService.getEvaluationDetails(
        evaluation.id,
        authToken,
        params.country
      );
      if (res && res.data.evaluation_details.details != null) {
        setLiquidSalary(res.data.evaluation_details.details.confirmed_salary);
        onChangeRent(res.data.evaluation_details.details.confirmed_salary);
      } else {
        setLiquidSalary(0);
      }
      setLoadingDetails(false);
    };

    if (liquidSalary === null && !loadingDetails) {
      setLoadingDetails(true);
      getToken().then((authToken) => fetchDetails(authToken));
    }
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom className={classes.h6}>
            {evaluationPerson}
          </Typography>
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center">
          <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
            <Box display="flex" alignContent="center" alignItems="flex-end">
              <Typography variant="subtitle1" gutterBottom>
                {`${evaluation.user.name} ${evaluation.user.last_name} ${evaluation.user.second_last_name}`}
              </Typography>
              <Button
                startIcon={<EditIcon className={classes.editIcon} />}
                onClick={() => history.push(`/admin/users/${evaluation.user.id}/edit`)}
                className={classes.editButton}>
                Editar
              </Button>
              {params.country === "mx" && (
                <Button
                  startIcon={<PinDropIcon className={classes.editIcon} />}
                  onClick={() => setOpenEditAddress(true)}
                  className={classes.editButton}
                  id="edit-evaluation-address">
                  Editar dirección
                </Button>
              )}
            </Box>
            {params.country === "cl" && (
              <Box className={classes.statusData}>
                Evaluación AVLA:
                {avla != null ? (
                  <Chip
                    label={avla}
                    className={`${classes.status} ${
                      avla === "Aprobada"
                        ? classes.readyStatus
                        : avla === "Rechazada"
                        ? classes.rejectedStatus
                        : classes.orangeStatus
                    }`}
                    variant="outlined"
                  />
                ) : (
                  <CircularProgress size={20} />
                )}
              </Box>
            )}
            {params.country === "mx" && (
              <>
                <Box className={classes.statusData}>
                  Buró:
                  <Chip
                    label={evaluation.is_verified ? "Verificado" : "No verificado"}
                    className={`${classes.status} ${
                      evaluation.is_verified ? classes.readyStatus : classes.orangeStatus
                    }`}
                    variant="outlined"
                  />
                </Box>
                <Box className={classes.statusData}>
                  NIP:
                  <Chip
                    label={evaluation.nip_token ? "Validado" : "No validado"}
                    className={`${classes.status} ${
                      evaluation.nip_token ? classes.readyStatus : classes.orangeStatus
                    }`}
                    variant="outlined"
                  />
                </Box>
              </>
            )}
            <Box className={classes.statusData}>
              Estado:
              <Chip
                label={evaluation.status}
                className={`${classes.status} ${statusClass(evaluation.status)}`}
                variant="outlined"
              />
              {evaluation.status === "Rechazado" && (
                <>
                  <Button onClick={handleOpenRejectReason} color="primary">
                    Ver motivo
                  </Button>
                  <Dialog
                    open={openRejectReason}
                    onClose={handleCloseRejectReason}
                    scroll="paper"
                    transitionDuration={500}
                    PaperProps={{
                      style: {
                        borderRadius: 15,
                      },
                    }}>
                    <Grid container justifyContent="space-between">
                      <DialogTitle>Motivo del rechazo</DialogTitle>
                      <DialogActions>
                        <IconButton
                          className={classes.closeButton}
                          onClick={handleCloseRejectReason}>
                          <GridCloseIcon />
                        </IconButton>
                      </DialogActions>
                    </Grid>
                    <DialogContent>
                      <RejectOrDesistReason evaluationId={evaluation.id} />
                    </DialogContent>
                  </Dialog>
                </>
              )}
              {evaluation.status === "Desistido" && (
                <>
                  <Button onClick={handleOpenDesistReason} color="primary">
                    Ver motivo
                  </Button>
                  <Dialog
                    open={openDesistReason}
                    onClose={handleCloseDesistReason}
                    scroll="paper"
                    transitionDuration={500}
                    PaperProps={{
                      style: {
                        borderRadius: 15,
                      },
                    }}>
                    <Grid container justifyContent="space-between">
                      <DialogTitle>Motivo para desistir</DialogTitle>
                      <DialogActions>
                        <IconButton
                          className={classes.closeButton}
                          onClick={handleCloseDesistReason}>
                          <GridCloseIcon />
                        </IconButton>
                      </DialogActions>
                    </Grid>
                    <DialogContent>
                      <RejectOrDesistReason evaluationId={evaluation.id} />
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </Box>
          </Box>
          <Box>
            <div>
              <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.actions}>
                Acciones
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.popper}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={(e) => handleListKeyDown(e)}>
                          {newMenu.map((menu) => menu)}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <CodebtorDataCard
            evaluation={evaluation}
            country={params.country}
            liquidSalary={liquidSalary}
          />
          <Box p={3} className={classes.commentsContainer}>
            <Comments evaluation={evaluation} />
          </Box>
        </Box>
        {evaluation.documents.length > 0 && (
          <Dialog
            open={openDocs}
            onClose={handleCloseDocs}
            scroll="paper"
            fullScreen
            className="!z-10"
            transitionDuration={500}>
            <DialogTitle>
              <Box display="flex" justifyContent="space-between">
                Documentos{" "}
                {`${evaluation.user.name} ${evaluation.user.last_name} ${evaluation.user.second_last_name}`}
                <Button onClick={handleCloseDocs} color="primary">
                  <b>X</b>
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DocumentsView documents={evaluation.documents} />
            </DialogContent>
          </Dialog>
        )}
        <Dialog
          open={openEvaluate}
          onClose={handleCloseEvaluate}
          scroll="paper"
          className="!z-10"
          fullScreen
          transitionDuration={500}
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              Evaluación{" "}
              {`${evaluation.user.name} ${evaluation.user.last_name} ${evaluation.user.second_last_name}`}
              <Button onClick={handleCloseEvaluate} color="primary">
                <b>X</b>
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Evaluate evaluation={evaluation} />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openAskDoc}
          onClose={handleCloseAskDoc}
          scroll="paper"
          transitionDuration={500}
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}>
          <DialogTitle>Pedir documentación</DialogTitle>
          <DialogContent>
            <Typography>Mensaje a enviar:</Typography>
            <WriteComment onSave={askDoc} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAskDoc} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEditAddress}
          onClose={() => setOpenEditAddress(!openEditAddress)}
          scroll="paper"
          transitionDuration={500}
          PaperProps={{
            style: {
              borderRadius: 15,
            },
          }}>
          <DialogTitle>
            <div className={classes.dialogTitleContainer}>
              Editar dirección
              <IconButton aria-label="close" onClick={() => setOpenEditAddress(!openEditAddress)}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <SnackbarProvider>
              <EditAddressForm evaluation={evaluation} />
            </SnackbarProvider>
          </DialogContent>
        </Dialog>
        {discardType && (
          <RejectDesistModal
            open={openRejectDesist}
            onClose={handleCloseRejectDesist}
            onSave={saveDiscard}
            possibleAuthors={evaluationDiscardInfo.authors}
            possibleReasons={{
              desist: evaluationDiscardInfo.desistReasons,
              reject: evaluationDiscardInfo.rejectReasons,
            }}
            title={discardType === "desist" ? "Desistir Evaluación" : "Rechazar Evaluación"}
            type={discardType}
            disableOptionChange
            entity="evaluations"
          />
        )}
      </CardContent>
      <CardActions className={classes.buttonsContainer}>
        {evaluation.documents.length > 0 && (
          <CustomLoadingButton text="Ver Documentos" onClick={handleOpenDocs} type="outlined" />
        )}
        {evaluation.documents.length > 0 &&
          params.country === "mx" &&
          !evaluation.is_verified &&
          evaluation.nip_token && (
            <Tooltip
              open={onMouseOver && !openFileFlag}
              arrow
              classes={classesTooltip}
              title="El botón se habilitará una vez se haya verificado la documentación para validar su identidad">
              <div
                onMouseOver={() => setOnMouseOver(true)}
                onMouseOut={() => setOnMouseOver(false)}
                onFocus={() => setOnMouseOver(true)}
                onBlur={() => setOnMouseOver(false)}>
                <CustomLoadingButton
                  text="Solicitar Buró"
                  onClick={() => {
                    getToken().then((authToken) =>
                      handleVerifyDoc(
                        user,
                        evaluation,
                        mainId,
                        codebtorIds,
                        authToken,
                        setLoadingBuro,
                        dispatch
                      )
                    );
                  }}
                  loading={loadingBuro}
                  disabled={!openFileFlag}
                />
              </div>
            </Tooltip>
          )}
        {evaluation.is_verified && params.country === "mx" && (
          <CustomLoadingButton
            text="Obtener Score"
            onClick={() => {
              getToken().then((authToken) => {
                downloadCsv(evaluation, authToken, setLoadingBuro);
                getSingleEvaluation(mainId, codebtorIds, "mx", authToken, dispatch);
              });
            }}
            loading={loadingBuro}
          />
        )}
      </CardActions>
    </Card>
  );
}

export default CodebtorCard;
