import { GridOverlay } from "@material-ui/data-grid";
import useCustomNoRowsOverlayStyles from "./CustomNoRowsOverlayStyles";

function CustomNoRowsOverlay({ textTitle, textSubtitle }) {
  const classes = useCustomNoRowsOverlayStyles();
  return (
    <GridOverlay className={classes.root}>
      <div className={classes.messageContainer}>
        <div>
          <div className={classes.title}>{textTitle}</div>
          <div className={classes.subtitle}>{textSubtitle}</div>
        </div>
      </div>
    </GridOverlay>
  );
}

export default CustomNoRowsOverlay;
