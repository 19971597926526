import Spinner from "@houm-com/ui/Spinner";

import useGetStatistics from "domains/evaluations/hooks/useGetStatistics";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
}

const EvaluationsListStatistics = ({ country }: Props) => {
  const { data: statistics, isError, isFetching } = useGetStatistics(country);

  if (isFetching)
    return (
      <Spinner classNameContainer="relative flex justify-center" size="lg" variant="primary" />
    );

  if (isError) return <p className="text-p16 font-normal">Error al obtener estadísticas</p>;

  return (
    <div>
      <p className="text-h20 font-bold">Estadísticas</p>
      <div className="flex flex-row gap-2">
        <div className="mr-2">
          <p className="text-p16 font-normal">Por comenzar: {statistics.start}</p>
          <p className="text-p16 font-normal">Sin documentación: {statistics.noDocs}</p>
          <p className="text-p16 font-normal">Documentación pendiente: {statistics.pendingDocs}</p>
        </div>
        <div className="mr-2">
          <p className="text-p16 font-normal">
            Listo para evaluar: {statistics.readyForEvaluation}
          </p>
          <p className="text-p16 font-normal">Nuevos documentos: {statistics.newDocs}</p>
          <p className="text-p16 font-normal">
            Aprobado comercialmente: {statistics.commerciallyApproved}
          </p>
        </div>
        <div>
          <p className="text-p16 font-normal">Rechazado: {statistics.rejected}</p>
          <p className="text-p16 font-normal">Desistido: {statistics.desisted}</p>
        </div>
      </div>
    </div>
  );
};

export default EvaluationsListStatistics;
