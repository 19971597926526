import { UseQueryResult, useQuery } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";

interface ILinks {
  contract: string;
  mandate: string;
}

const useDocumentLinks = (contractId: number) => {
  const { getToken } = useGetToken();

  const { isLoading, error, data }: UseQueryResult<ILinks, Error> = useQuery(
    ["contract_documents_link", contractId],
    async () => {
      const authToken = await getToken();
      const response = await contractService.retrieveSignedContracts(contractId, authToken);
      return response.data;
    },
    {
      enabled: !!contractId,
    }
  );

  return { isLoading, error, data };
};

export default useDocumentLinks;
