import { Root, PriceLabel, PriceValue } from "./RecommendedPriceStyles";

interface Props {
  price?: string;
}

function RecommendedPrice({ price = "" }: Props) {
  return (
    <Root>
      <PriceLabel>Precio recomendado</PriceLabel>
      <PriceValue>{price}</PriceValue>
    </Root>
  );
}

export default RecommendedPrice;
