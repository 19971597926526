import { useEffect, useState } from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";

import { useGetToken } from "hooks";
import { parseBody } from "Components/Admin/administrator/multifamily/CreateTypology/utils/parseBody";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { Typologies, TypologyFormData } from "context/multifamilyContext/typology/typologyTypes";
import {
  createTypology,
  editTypology,
  getTypology,
  getTypologyPhotos,
  setCurrentId,
  uploadLayoutImage,
} from "context/multifamilyContext/typology/typologyActions";
import { setCurrentId as multifamilyId } from "context/multifamilyContext/multifamily/multifamilyActions";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import validationSchema from "./validationSchema";
import Inputs from "../Inputs/Inputs";
import Amenities from "../Inputs/Amenities";

function Form() {
  const { id, MFid } = useParams<{ id: string; MFid: string }>();
  const history = useHistory();
  const { getToken } = useGetToken();
  const { dispatch: dispatchMF } = useMultifamily();
  const {
    dispatch: dispatchUT,
    state: { currentId: UTid, typology, UTImages },
  } = useTypology();

  useEffect(() => {
    dispatchMF(multifamilyId(parseInt(MFid)));
    dispatchUT(setCurrentId(parseInt(id)));
    if (id && MFid) {
      getToken().then((authToken) => {
        getTypology(dispatchUT, parseInt(id), authToken);
        getTypologyPhotos(dispatchUT, parseInt(id), authToken);
      });
    }
  }, [id, MFid]);

  const [isCreated, setIsCreated] = useState(false);
  const methods = useForm<TypologyFormData>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onSubmit",
  });
  const onSubmit: SubmitHandler<Typologies> = async (data) => {
    const body = parseBody(data, MFid, UTid);

    if (!id && !isCreated) {
      getToken().then((authToken) => {
        createTypology(dispatchUT, body, authToken);
      });
    } else {
      getToken().then((authToken) => {
        if (data?.layout_filename?.length > 0) {
          uploadLayoutImage(UTid, data?.layout_filename[0], authToken);
        }
        editTypology(UTid, body, authToken);
      });
    }
    if (isCreated) history.push(`/admin/multifamily/${MFid}/typology/${UTid}`);
    setIsCreated(!isCreated);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {!isCreated && <Inputs typology={typology} hasId={!!id} />}
        {isCreated && <Amenities typology={typology} images={UTImages} id={UTid} />}
        <div>
          <DesignSystemButton
            label={isCreated ? "Guardar" : "Guardar y continuar"}
            type="submit"
            size="medium"
            variant="primary"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default Form;
