import { useState } from "react";
import Swal from "sweetalert2";
import { useGetToken } from "hooks";
import dicomService from "services/dicomService";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

function ValidarRut() {
  const { getToken } = useGetToken();
  const [name, setName] = useState("");
  const [rut, setRut] = useState("");

  const sendGetDicom = async () => {
    const data = { name, dni: rut };
    const authToken = await getToken();
    const myResp = await dicomService.sendGetDicom(data, authToken);

    if (myResp) {
      switch (myResp) {
        case "Aprobada":
          Swal.fire("Candidato aprobado", "El candidato es apto para ser arrendatario", "success");
          break;
        case "Rechazada":
          Swal.fire(
            "Candidato rechazado",
            "El candidato no es apto para ser arrendatario",
            "warning"
          );
          break;
        default:
          Swal.fire(
            "Candidato pendiente",
            "El candidato está en revisión, intenta más tarde",
            "info"
          );
      }
    } else {
      Swal.fire("Ha ocurrido un error", "Por favor intenta nuevamente", "error");
    }
  };

  const handleChangeName = (value) => setName(value);
  const handleChangeRut = (value) => setRut(value);

  return (
    <div style={{ textAlign: "center" }}>
      <input
        name="rut"
        value={rut || ""}
        placeholder="rut"
        onChange={(e) => handleChangeRut(e.target.value)}
        style={{ width: 500 }}
      />
      <br />
      <input
        name="name"
        value={name || ""}
        placeholder="nombre completo"
        onChange={(e) => handleChangeName(e.target.value)}
        style={{ width: 500 }}
      />
      <br />
      <DesignSystemButton
        label="Consultar"
        variant="primary"
        size="small"
        onClick={() => sendGetDicom()}
      />
      <br />
      <br />
    </div>
  );
}

export default ValidarRut;
