import { useMutation } from "react-query";

import propertyService from "services/propertyService";
import { HoumOwner } from "models/Property";
import { useGetToken } from "hooks";

const useUpdateProperty = (propertyId: number) => {
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess, isError } = useMutation(async (formData: HoumOwner) => {
    const authToken = await getToken();
    const res = await propertyService.updateProperty(
      propertyId,
      { houm_owner: formData?.id },
      authToken
    );
    return res;
  });

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useUpdateProperty;
