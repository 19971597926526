import { classes } from "./ErrorsListStyles";

interface Props {
  errors: {
    text: string;
    id: number;
  }[];
}

const ErrorsList = ({ errors }: Props) => (
  <div className={classes.errorsWrapper}>
    <h6>Contrato con errores:</h6>
    {errors.map((error) => (
      <p key={`error-${error.id}`} className={classes.errorItem}>
        {error.text}
      </p>
    ))}
  </div>
);

export default ErrorsList;
