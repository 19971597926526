import { useState } from "react";

import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import useGetToken from "./useGetToken";

type Callback = (contractId: number, authToken: string) => Promise<AxiosResponse<any>>;

export default () => {
  const { getToken } = useGetToken();

  const [loading, setLoading] = useState<boolean>(false);

  const makeSimpleRequest = async (
    callback: Callback,
    targetId: number,
    successMessage: string
  ) => {
    setLoading(true);

    const authToken = await getToken();

    callback(targetId, authToken)
      .then(() => {
        setLoading(false);
        Swal.fire({
          type: "success",
          title: "Éxito",
          text: successMessage,
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          type: "error",
          title: "Error",
          text: error,
        });
      });
  };

  return { loading, makeSimpleRequest };
};
