import { makeStyles, Theme } from "@material-ui/core";

const useCustomPanelStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "inherit",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "inherit",
  },
  tabs: {
    borderRadius: "10px 10px 0px 0px",
    background: "white",
    boxShadow: "none",
    padding: theme.spacing(2.5),
  },
  tab: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  panel: {
    borderRadius: "0px 0px 10px 10px",
    background: "white",
  },
}));

export default useCustomPanelStyles;
