import qs from "query-string";
import { createHttp } from "./helper";

import { Locale, Country, CountryUpperCase } from "models/Countries";
import {
  EditLeadBody,
  LostActivityBody,
  CreateActivityBody,
  QualifyActivityBody,
  Deal,
} from "models/DemandLead";
import { CancelReasons } from "context/demandScheduledVisitsContext/demandScheduledTypes";

const demandUrl = "demand/v2/";
const schedulesUrl = "schedules/v2/";
const leadUrl = `${demandUrl}update_lead_buyer_data`;
const profilingUrl = `${demandUrl}get_leadprofile_by_id`;
const updateProfilingUrl = `${demandUrl}update_lead_profile`;
const contactLeadUrl = `${demandUrl}contact-leads/`;
const updateLBtoLeadsUrl = `${demandUrl}update_executive_multiple`;

//lists
const executiveUrl = `${demandUrl}executives/`;
const lostReasonsUrl = `${demandUrl}reasons/`;
const finalcialTypesUrl = `${demandUrl}financings/`;
const citiesUrl = `${demandUrl}get_city_communes_by_country`;
const originsUrl = `${demandUrl}get_all_lead_origins`;

const activity = {
  all: `${demandUrl}get_activity_lead_table`, //get all activities from especific user
  lost: `${demandUrl}assign_lost_lead_activity`,
  names: `${demandUrl}activities/`, //get activity name
  create: `${demandUrl}create_tracking_activity`,
  qualify: `${demandUrl}assign_qualification_lead_activity`,
  complete: `${demandUrl}complete_last_expired_activity`,
};

const leadSchedulesUrl = `${demandUrl}get_visits`;

//scheduler
const scheduledUrl = `${schedulesUrl}scheduled-visits/`;
const houmersUrl = `${schedulesUrl}get_all_appraisers`;
const cancelReasonsUrl = `${schedulesUrl}get_cancel_reasons`;
const agentsUrl = `${demandUrl}control_tower_executives`;
const setAgentUrl = `${schedulesUrl}update_ct_agent`;
const setConfirmationUrl = `${demandUrl}confirm_schedules`;
const setCancelUrl = `${schedulesUrl}cancel_schedule_visit`;
const setNewsCommentUrl = `${schedulesUrl}update_schedule_comment`;

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  //leads
  getLeads(filters: Record<string, number | string> = {}, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${contactLeadUrl}?${qs.stringify(filters, qsConfig)}`);
  },

  changeLBtoLeads(ids: number[], lbId: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.patch(updateLBtoLeadsUrl, {
      contact_lead_ids: ids,
      executive_id: lbId,
    });
  },

  getLead(id: string, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${contactLeadUrl}${id}/`);
  },

  getLeadProfiling(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${profilingUrl}?${qs.stringify({ lead_id: id })}`);
  },

  updateLeadProfiling(authToken: string, body) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${updateProfilingUrl}`, body);
  },

  modifyLead(body: EditLeadBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${leadUrl}`, body);
  },

  searchLead(
    value: string = "",
    authToken: string,
    cancelToken,
    pageSize: number = 30,
    businessType: "sales" | "rentals"
  ) {
    const httpInstance = createHttp(authToken, ...Array(2), cancelToken);
    return httpInstance.get(
      `${contactLeadUrl}?${qs.stringify({
        business_type: businessType,
        page_size: pageSize,
        search: value,
      })}`
    );
  },

  //activities
  getActivityNames(authToken: string, pageSize: number = 5) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${activity.names}?page_size=${pageSize}`);
  },

  getLeadActivity(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${activity.all}?lead_id=${id}`);
  },

  qualifyLeadActivity(body: QualifyActivityBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${activity.qualify}`, body);
  },

  lostLeadActivity(body: LostActivityBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${activity.lost}`, body);
  },

  createLeadActivity(body: CreateActivityBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${activity.create}`, body);
  },

  completeLastActivity(leadId: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(activity.complete, { lead_id: leadId });
  },

  //lead - schedules
  getLeadSchedules(email: string, phone: string, authToken: string) {
    const httpInstance = createHttp(authToken);
    const params = qs.stringify(
      {
        email,
        phone,
      },
      qsConfig
    );
    return httpInstance.get(`${leadSchedulesUrl}?${params}`);
  },

  //others
  getLostReasons(authToken: string, pageSize: number = 5) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${lostReasonsUrl}?page_size=${pageSize}`);
  },

  getCities(authToken: string, pageSize: number = 5, country: Locale = "cl") {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${citiesUrl}?${qs.stringify({ country, page_size: pageSize })}`);
  },

  getFinancialTypes(authToken: string, pageSize: number = 5, country: Locale = "cl") {
    const httpInstance = createHttp(authToken);
    const params = qs.stringify({ page_size: pageSize, country });
    return httpInstance.get(`${finalcialTypesUrl}?${params}`);
  },

  getExecutives(
    country: Locale | Country | "",
    authToken: string,
    page: number,
    businessType: Deal = ""
  ) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(
      `${executiveUrl}?${qs.stringify(
        { country, page, business_type: businessType, page_size: 100 },
        qsConfig
      )}`
    );
  },

  getOrigins(country: Country | CountryUpperCase | "", authToken: string) {
    const body = { country };
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${originsUrl}?${qs.stringify(body, qsConfig)}`);
  },

  getVisits(filters = {}, authToken: string, cancelToken) {
    const httpInstance = createHttp(authToken, ...Array(2), cancelToken);
    return httpInstance.get(`${scheduledUrl}?${qs.stringify(filters, qsConfig)}`);
  },

  getHoumers(authToken: string, country: Country | CountryUpperCase | "") {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${houmersUrl}?country=${country}`);
  },

  getCancelReasons(authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${cancelReasonsUrl}`);
  },

  getAgents(authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${agentsUrl}`);
  },

  setAgent(authToken: string, scheduleId: number, agentId: number) {
    const httpInstance = createHttp(authToken);
    const data = {
      schedule_id: scheduleId,
      ct_agent: agentId,
    };
    return httpInstance.post(`${setAgentUrl}`, data);
  },

  setConfirmStatus(authToken: string, scheduleId: number, thirdParty: string) {
    const httpInstance = createHttp(authToken);
    const data = {
      schedule_id: scheduleId,
      third_party: thirdParty,
    };
    return httpInstance.post(`${setConfirmationUrl}`, data);
  },

  setCancelStatus(
    authToken: string,
    scheduleId: number,
    reason: CancelReasons,
    reasonTxt: string = null
  ) {
    const httpInstance = createHttp(authToken);
    const data = {
      schedule_id: scheduleId,
      cancel_reason_id: reason.value,
      comment: reasonTxt,
      event_editor: "Control Tower",
    };
    return httpInstance.post(`${setCancelUrl}`, data);
  },

  setScheduleNewsComment(authToken: string, scheduleId: number, comment: string) {
    const httpInstance = createHttp(authToken);
    const data = {
      schedule_id: scheduleId,
      comment,
    };
    return httpInstance.patch(`${setNewsCommentUrl}`, data);
  },
};
