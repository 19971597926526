import { useHistory } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { PropertyData } from "models/Property";
import { renderAddress } from "utils";

interface Props {
  property: PropertyData;
}

const Property = ({ property }: Props) => {
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <AdminCard title="Propiedad" withSpace={false} showTitleBackground={true}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>ID: {property.id}</Typography>
            <Typography>Tipo: {property.type.toUpperCase()}</Typography>
            <Typography>Dirección: {renderAddress(property)}</Typography>
          </Grid>
          <Grid item>
            <DesignSystemButton
              variant="primary"
              label="Ver detalle"
              size="small"
              onClick={() => history.push(`/admin/properties/${property.uid}`)}
            />
          </Grid>
        </Grid>
      </AdminCard>
    </Grid>
  );
};

export default Property;
