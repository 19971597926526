import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(22),
  },
  list: {
    margin: theme.spacing(2),
  },
}));
