import { useState } from "react";
import classnames from "classnames";

import { Box, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import {
  DocumentData,
  FileStatus,
} from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { useGetToken } from "hooks";
import useFileCardStyles from "./FileCardStyles";
import { changeFileStatus } from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as VisibleIcon } from "assets/icons/visible.svg";

interface FileCardProps {
  document: DocumentData;
  displayDocument: (document: DocumentData) => void;
}

function FileCard({ document, displayDocument }: FileCardProps) {
  const classes = useFileCardStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const { getToken } = useGetToken();
  const { state, dispatch } = useLetterOfIntent();
  const { country, loiId } = state;

  const documentCreationDate = new Date(document.createdAt).toLocaleDateString();

  const handleDelete = () => {
    getToken().then((authToken) =>
      changeFileStatus({
        country,
        documentId: document.id,
        loiId,
        status: FileStatus.DELETED,
        token: authToken,
        dispatch,
      })
    );
  };

  const displayIcon = (status: string) => {
    switch (status) {
      case FileStatus.PENDING:
        return (
          <TimeIcon
            className={classnames(classes.flagStatusIcon, classes.flagStatusNotValidated)}
          />
        );
      case FileStatus.INVALID:
        return (
          <CloseIcon className={classnames(classes.flagStatusIcon, classes.flagStatusRejected)} />
        );
      default:
        return (
          <CheckIcon className={classnames(classes.flagStatusIcon, classes.flagStatusValidated)} />
        );
    }
  };

  return (
    <Box className={classes.cardDocument}>
      <Box>
        <Typography key={document.id} className={classes.documentName}>
          {document.filename}
        </Typography>
        <Box className={classes.documentDateContainer}>
          <Typography key={document.id} className={classes.documentDate}>
            Subido el:
          </Typography>
          <Typography key={document.id} className={classes.documentDate}>
            {documentCreationDate}
          </Typography>
        </Box>
        <Box
          className={classnames(classes.flagStatus, {
            [classes.flagStatusNotValidated]: document.status == FileStatus.PENDING,
            [classes.flagStatusValidated]: document.status == FileStatus.VALID,
            [classes.flagStatusRejected]: document.status == FileStatus.INVALID,
          })}>
          {displayIcon(document.status)}
          {document.status}
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <DesignSystemButton
          label="Ver archivo"
          size="small"
          variant="secondary"
          buttonClassName={classes.button}
          onClick={() => {
            displayDocument(document);
          }}
          Icon={<VisibleIcon />}
        />
        <DesignSystemButton
          label="Eliminar"
          size="small"
          variant="tertiaryB"
          Icon={<TrashIcon />}
          buttonClassName={classes.buttonSecondary}
          onClick={() => setOpenDialog(true)}
        />
      </Box>
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Eliminar documento"
        subtitle={`Estás seguro que deseas eliminar el documento ${document.filename}. Una vez eliminado no podrás recuperar esta información`}
        minWidth={500}
        classes={{
          content: classes.dialogContent,
          titleContainer: classes.dialogTitleContainer,
          subtitle: classes.dialogSubtitle,
        }}>
        <Box className={classes.dialogButtons}>
          <DesignSystemButton
            label="Cancelar"
            size="small"
            variant="tertiaryB"
            buttonClassName={classes.buttonTertiary}
            onClick={() => setOpenDialog(false)}
          />
          <DesignSystemButton
            label="Borrar"
            size="small"
            variant="primary"
            onClick={handleDelete}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
}

export default FileCard;
