import { ChangeEvent } from "react";

export const getTextFieldNumber = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  if (e.target.value) {
    const newValue = Math.min(Math.max(parseFloat(e.target.value), 0), 100);
    return newValue;
  } else {
    return e.target.value;
  }
};
