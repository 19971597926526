import { NewLayoutForm } from "./projectTypes";

export const parseLayout = (propId: number, layout: NewLayoutForm, filePath: string) => {
  const data = {
    ...(filePath && { layout_file_name: filePath }),
    usable_area: layout.usableArea,
    building_area: layout.totalArea,
    property: propId,
    rooms: layout.rooms ? layout.rooms : layout.roomsOther,
    name: layout.name,
    bathrooms: layout.bathrooms ? layout.bathrooms : layout.bathroomsOther,
    has_studio: layout.studio,
  };

  return data;
};
