import { useEffect, useState } from "react";

import { Locale } from "models/Countries";

import { AvailableCompanies } from "../../utils/types";
import useAvailableCompanies from "./useAvailableCompanies";

const useCommonExpensesForm = (country: Locale, propsCompany?: AvailableCompanies) => {
  const [company, setCompany] = useState("0");
  const { isLoading, companies } = useAvailableCompanies(country);

  useEffect(() => {
    if (propsCompany) setCompany(propsCompany.toLocaleLowerCase().replace(/ /g, "_"));
  }, [propsCompany]);

  return {
    company,
    setCompany,
    isLoading,
    companies,
  };
};

export default useCommonExpensesForm;
