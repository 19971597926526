import { useMutation, useQueryClient } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface IFormData {
  contractPersonId: number;
}

const useDeleteContractPerson = (contractId: number) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      const data = {
        contract_person_id: formData.contractPersonId,
      };
      await contractService.deleteContractPerson(contractId, data, authToken);
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Contract person eliminado",
          disclaimer: "El contract person ha sido eliminado exitosamente",
        });
        queryClient.invalidateQueries(["contract_data"]);
      },
      onError: (e) => {
        alert.error({
          message: "Error al eliminar contract person",
          disclaimer: `Hubo un error al intentar eliminar al contract person, error: ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useDeleteContractPerson;
