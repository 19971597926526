import { CountryUpperCase } from "models/Countries";

interface Option {
  label: string;
  value: string;
}

interface IField {
  label: string;
  options: Option[];
}

export const getApartmentTypeField = (country: CountryUpperCase): IField => {
  switch (country) {
    case "Chile":
      return {
        label: "Tipo de departamento",
        options: [
          { label: "Loft", value: "loft" },
          { label: "Estudio", value: "studio" },
          { label: "Duplex", value: "duplex" },
          { label: "Triplex", value: "triplex" },
          { label: "Penthouse", value: "penthouse" },
          { label: "Mariposa", value: "butterfly" },
        ],
      };
    case "Colombia":
      return {
        label: "Tipo de apartamento",
        options: [
          { label: "Loft", value: "loft" },
          { label: "Aparta estudio", value: "studio" },
          { label: "Duplex", value: "duplex" },
          { label: "Triplex", value: "triplex" },
          { label: "Penthouse", value: "penthouse" },
        ],
      };
    case "Mexico":
      return {
        label: "Tipo de apartamento",
        options: [
          { label: "Loft", value: "loft" },
          { label: "Duplex", value: "duplex" },
          { label: "Triplex", value: "triplex" },
          { label: "Penthouse", value: "penthouse" },
        ],
      };
    default:
      return {
        label: "",
        options: [{ label: "", value: "" }],
      };
  }
};

export const getParkingOptions = (country: CountryUpperCase): Option[] => {
  switch (country) {
    case "Colombia":
      return [
        { label: "En línea", value: "on_line" },
        { label: "Independiente", value: "independent" },
      ];
    case "Mexico":
      return [
        { label: "En batería", value: "on_battery" },
        { label: "Independiente", value: "independent" },
        { label: "Con elevador", value: "with_elevator" },
      ];
    default:
      return [{ label: "Debes seleccionar país", value: "" }];
  }
};

export const getHasLaundryRoomLabels = (country: CountryUpperCase): string => {
  switch (country) {
    case "Chile":
      return "Logia";
    case "Colombia":
      return "Cuarto de lavado";
    case "Mexico":
      return "Cuarto de lavado";
    default:
      return "";
  }
};

export const getHotWaterSystemOptions = (country: CountryUpperCase): Option[] => {
  switch (country) {
    case "Chile":
      return [
        { label: "Calefont a gas", value: "" },
        { label: "Calefont eléctrico", value: "" },
        { label: "Caldera", value: "" },
      ];
    case "Colombia":
      return [
        { label: "Calentador a gas", value: "" },
        { label: "Calefont eléctrico", value: "" },
        { label: "Caldera eléctrica", value: "" },
      ];
    case "Mexico":
      return [
        { label: "Calentador a gas", value: "" },
        { label: "Calefont eléctrico", value: "" },
      ];
    default:
      return [{ label: "Debes seleccionar país", value: "" }];
  }
};

export const getGuestParkingSpacesLabel = (country: CountryUpperCase): string => {
  switch (country) {
    case "Chile":
      return "N° estacionamientos de visitas";
    case "Colombia":
      return "N° parqueaderos de visitantes";
    case "Mexico":
      return "N° estacionamientos de visitas";
    default:
      return "N° estacionamientos de visitas";
  }
};

export const getIsCommonExpensesIncludedLabel = (country: CountryUpperCase): string => {
  switch (country) {
    case "Chile":
      return `Gastos comunes incluidos.
      (¿Están los gastos comunes incluidos en el precio del arriendo?)`;
    case "Colombia":
      return `Administración incluida.
      (¿Está la administración incluida en el precio del arriendo?)`;
    case "Mexico":
      return `Gastos comunes incluidos.
      (¿Están los gastos comunes incluidos en el precio de renta?)`;
    default:
      return "";
  }
};

export const commonExpenseLabelByCountry = (country: CountryUpperCase): string => {
  if (country === "Colombia") {
    return "Administración";
  }
  return "Gastos comunes";
};
