import React from "react";

import { Grid } from "@material-ui/core";

import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";
import { ContractModel } from "models/Contract";

import EvaluationDocuments from "./components/EvaluationDocuments";
import ContractDocuments from "./components/ContractDocuments";
import LegacyDocuments from "./components/LegacyDocuments";
import ContractDetails from "./components/ContractDetails";
import ContractPerson from "./components/ContractPerson";
import UploadFiles from "./components/UploadFiles";
import { countryCode } from "utils";

interface Props {
  contract: ContractModel;
}

const Contract = ({ contract }: Props) => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="contract">
      <Grid container spacing={4} alignItems="flex-start">
        <ContractPerson contract={contract} />
        <ContractDetails contract={contract} />
        <UploadFiles contractId={contract?.id} />
        <ContractDocuments
          contractId={contract?.id}
          onlineProcessStatus={contract.online_process_status}
        />
        {contract?.rent_application?.id && (
          <LegacyDocuments rentApplicationId={contract.rent_application.id} />
        )}
        {!contract?.rent_application?.id &&
          contract.evaluation_ids &&
          contract.property.country !== "Colombia" && (
            <EvaluationDocuments
              country={countryCode(contract.property.country)}
              evaluationId={Math.min(...contract.evaluation_ids)}
            />
          )}
      </Grid>
    </TabPanel>
  );
};

export default React.memo(Contract);
