import { useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { GridColumns, GridColDef, GridCellParams } from "@material-ui/x-grid";

import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { MultifamilyUnit } from "context/multifamilyContext/unit/unitTypes";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";

import useStyles from "./TableStyles";

function Table() {
  const classes = useStyles();

  const {
    state: { typology },
  } = useTypology();
  const {
    state: {
      units,
      extraStates: { isFetchingAllUnits },
    },
  } = useMultifamilyUnit();
  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize } = tableStates;

  const { MFid } = useParams<{ MFid: string }>();

  useEffect(() => dispatch(setPageSize(10)), []);

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "ID de propiedad",
      field: "propertyId",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      renderCell: ({ row }: GridCellParams) => row?.property?.id || "------",
    },
    {
      ...columnsConfig,
      headerName: "Nombre tipología",
      field: "nameOfTypology",
      cellClassName: classes.centerCell,
      renderCell: () => typology?.name || "------",
    },
    {
      ...columnsConfig,
      headerName: "Nº departamento",
      field: "numberOfDepartment",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Piso",
      field: "floor",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Orientación",
      field: "orientation",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Precio",
      field: "price",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Moneda",
      field: "currency",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Estacionamiento",
      field: "parking",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => value || "------",
    },
    {
      ...columnsConfig,
      headerName: "Bodega",
      field: "warehouse",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      renderCell: ({ value }) => (value ? "Si" : "No"),
    },
    {
      ...columnsConfig,
      field: "actions",
      headerName: "",
      resizable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      headerClassName: classes.lastHeaderCell,
      cellClassName: classes.actionCell,
      flex: 0.5,
      renderCell: ({ row }) => (
        <Link
          to={`/admin/multifamily/${parseInt(MFid)}/typology/${typology?.id}/unit/${row.id}`}
          className={classes.link}>
          Ver más
        </Link>
      ),
    },
  ];

  const formatData = (data: MultifamilyUnit[]) =>
    data.map((item: MultifamilyUnit) => ({
      ...item,
      id: item.id,
      numberOfDepartment: item.property?.numero_depto,
      floor: item.property?.apartment_floor,
      orientation: item.property?.property_details[0]?.orientacion,
      price: item.property?.property_details[0]?.valor,
      currency: item.property?.property_details[0]?.currency_code,
      parking: item.property?.property_details[0]?.estacionamientos,
      warehouse: item.property?.property_details[0]?.bodega,
    }));

  return (
    <div className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        rowCount={formatData(units)?.length || 0}
        columns={columns}
        rows={
          formatData(units)?.slice(
            (currentPage - 1) * pageSize,
            (currentPage - 1) * pageSize + pageSize
          ) || []
        }
        loading={isFetchingAllUnits}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
