import { makeStyles, Theme } from "@material-ui/core";

const useEditServiceStyles = makeStyles((theme: Theme) => ({
  editServiceWrapper: {
    backgroundColor: "#EFF2F3",
    marginBottom: theme.spacing(1),
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  editService: {
    marginRight: theme.spacing(0.5),
    textDecoration: "underline",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    cursor: "pointer",
  },
}));

export default useEditServiceStyles;
