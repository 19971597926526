import { ControlledList } from "Components/Admin/UIComponents/ControlledForm";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { IParsePlan } from "hooks/usePlans/utils/types";
import { CountryUpperCase } from "models/Countries";
import usePlans from "hooks/usePlans";

import usePlanDetailsStyles from "./PlanDetailsStyles";
import Label from "../../../Label";

interface Props {
  country: CountryUpperCase | null;
  planId: number | null;
}

const PlanDetails = ({ country, planId }: Props) => {
  const { data, isError, isLoading } = usePlans(country);
  const classes = usePlanDetailsStyles();
  const filterData = data.filter((p) => p.active || p.id === planId);
  const getIsPlanAvailable = () => filterData?.find((p) => p.id === planId);

  const getPlanName = (plan: IParsePlan) => {
    if (plan.active) return plan.name;
    return `${plan.name} (Inactivo)`;
  };

  return (
    <Loading loading={isLoading}>
      {data && !isError && (
        <Label label="Plan:" labelClassName={classes.selectText}>
          <ControlledList
            label=""
            id="planId"
            defaultValue={getIsPlanAvailable() ? planId : ""}
            options={filterData.map((plan) => ({
              value: plan.id,
              label: getPlanName(plan),
            }))}
            classes={{
              select: classes.select,
            }}
            disableUnderline
          />
        </Label>
      )}
    </Loading>
  );
};

export default PlanDetails;
