import NavbarMenuItems from "../NavbarMenuItems";
import NavbarUser from "../NavbarUser";
import brandLogo from "assets/images/brand-logo.svg";

export default function NavbarSidebar() {
  return (
    <div className="flex grow flex-col gap-y-5 h-full overflow-y-auto border-r border-black-20 bg-white px-6 pt-6">
      <div className="flex h-16 shrink-0 items-center">
        <img className="h-8 w-auto" src={brandLogo} alt="Houm" />
        <span className="ml-4 text-h20">Admin</span>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <NavbarMenuItems />
          </li>
          <li className="-mx-6 mt-auto">
            <NavbarUser />
          </li>
        </ul>
      </nav>
    </div>
  );
}
