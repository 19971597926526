import { makeStyles, Theme } from "@material-ui/core";

const usePaymentDetailsStyles = makeStyles<Theme>((theme) => ({
  text: {
    color: "#BFCBD1",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  select: {
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: "none",
  },
  textInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    border: "none",
    padding: theme.spacing(0, 1),
    "& input.MuiInputBase-input": {
      color: theme.palette.grey[900],
    },
  },
  textDisclaimer: {
    fontStyle: "italic",
  },
}));

export default usePaymentDetailsStyles;
