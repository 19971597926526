import { Typography, Grid } from "@material-ui/core";

import useScheduleRangeStyles from "./ScheduleRangeItemStyles";

function ScheduleRangeItem({ nameDay, hours }) {
  const classes = useScheduleRangeStyles();

  return (
    <Grid container spacing={3}>
      <Grid item className={classes.containerDay}>
        <Typography variant="body1" gutterBottom={false} className={classes.nameDay}>
          {nameDay}
        </Typography>
      </Grid>
      <Grid item>
        {hours.length === 0
          ? "No Disponible"
          : hours.map((hour) => (
              <Typography className={classes.range} variant="subtitle2">
                {hour.beginHour} - {hour.endHour}
              </Typography>
            ))}
      </Grid>
    </Grid>
  );
}

export default ScheduleRangeItem;
