import { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import { InputLabel } from "@material-ui/core";

import { RemoteSuggester } from "Components/Admin/UIComponents";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import useStyles from "./FilterOptionsStyles";
import {
  TotalQuotaTableFilterTypes,
  filterInitialValues,
} from "Components/Admin/administrator/integrations/pages/portalQuotas/components/TotalQuota/utils/filters";
import integrationService from "services/integrationService";
import {
  publicationTypeResponseToSelectorParser,
  standardParser,
} from "Components/Admin/administrator/integrations/utils/parsers";
import { businessTypesOptions } from "Components/Admin/administrator/integrations/utils/commonFilterOptions";
import { IndexTotalQuotaFilters } from "services/integrationService/types";
import { useFirstRender } from "hooks";
import initialFilters from "Components/Admin/administrator/integrations/pages/portalQuotas/utils/initialFilters";
import { setTotalQuotaFiltersAction } from "context/integrationContext/integrationActions";

interface Props {
  setCurrentFilters: Dispatch<SetStateAction<IndexTotalQuotaFilters>>;
}

function FilterOptions({ setCurrentFilters }: Props) {
  const classes = useStyles();
  const { state, dispatch: integrationDispatch } = useIntegration();
  const { state: tableState, dispatch } = useTable();
  const firstRender = useFirstRender();

  const handleCloseModal = () => dispatch(setShowModal(false));

  const handleSubmit = useCallback(() => {
    const tableFilters: TotalQuotaTableFilterTypes = {
      ...tableState.tableFilters,
      businessType: state.totalQuotaFilters.businessType,
      portal: state.totalQuotaFilters.portal?.id as string,
      portalPlan: state.totalQuotaFilters.portalPlan?.id as string,
    };
    dispatch(setTableFilters(tableFilters));
    handleCloseModal();
  }, [dispatch, state.totalQuotaFilters]);

  const handleDelete = () => {
    setTotalQuotaFiltersAction(filterInitialValues, integrationDispatch);
    dispatch(setTableFilters({}));
    setCurrentFilters(initialFilters);
    handleCloseModal();
  };

  useEffect(() => {
    if (firstRender) return;
    handleDelete();
  }, [state.country]);

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleCloseModal}
      onEscapeKeyDown={handleCloseModal}
      fullWidth
      maxWidth="md">
      <div className={classes.contentRoot}>
        <div className={classes.selectorContainer}>
          <InputLabel className={classes.selectorLabel} id="business-type-selector-label">
            Línea de negocio
          </InputLabel>
          <SelectionOptions
            id="businessType"
            simple
            compressed={false}
            value={state.totalQuotaFilters.businessType}
            onChange={(businessType) =>
              setTotalQuotaFiltersAction({ businessType }, integrationDispatch)
            }
            options={businessTypesOptions}
            classes={{
              root: classes.selectorRoot,
            }}
          />
        </div>
        <RemoteSuggester
          label="Portal"
          value={state.totalQuotaFilters.portal}
          onChange={(portal) => setTotalQuotaFiltersAction({ portal }, integrationDispatch)}
          indexService={integrationService.indexPortalNamesRequest}
          filters={{ country: state.country }}
          parser={standardParser}
          textFieldProps={{
            placeholder: "Selecciona un portal",
          }}
          classes={{ label: classes.suggesterLabel }}
        />
        <RemoteSuggester
          label="Destaque"
          value={state.totalQuotaFilters.portalPlan}
          onChange={(portalPlan) => setTotalQuotaFiltersAction({ portalPlan }, integrationDispatch)}
          indexService={integrationService.indexPublicationTypesNamesRequest}
          filters={{
            country: state.country,
            portal: state.totalQuotaFilters?.portal?.id as string,
            business: state.totalQuotaFilters?.businessType,
          }}
          parser={publicationTypeResponseToSelectorParser}
          textFieldProps={{
            placeholder: "Selecciona un destaque",
          }}
          classes={{ label: classes.suggesterLabel }}
          disabled={!state.totalQuotaFilters?.portal}
        />
      </div>
      <FilterDialog.Actions
        applyTitle="Filtrar"
        onDelete={handleDelete}
        onApply={handleSubmit}
        classes={{ applyButton: classes.applyFiltersButton }}
      />
    </FilterDialog>
  );
}

export default FilterOptions;
