import { makeStyles, Theme } from "@material-ui/core";

const useContentAccordeonStyles = makeStyles((theme: Theme) => ({
  contentAccordionRoot: {
    width: "100%",
    cursor: "pointer",
  },
  header: {
    padding: theme.spacing(0.5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
  },
  small: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  medium: {
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(30),
  },
  content: {
    transition: "height 0.5s",
    overflow: "hidden",
  },
  collapsed: {
    height: 0,
  },
  expanded: {
    height: "auto",
  },
  arrow: {
    transition: "transform 0.4s",
  },
  arrowExpanded: {
    transform: "rotate(180deg)",
  },
}));

export default useContentAccordeonStyles;
