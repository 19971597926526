import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    width: 80,
    height: 42,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.grey[300]}`,
    "& img": {
      height: 30,
      maxWidth: 80,
    },
  },
}));
