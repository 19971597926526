import { format } from "date-fns";

import { Chip, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { IReservation } from "services/newPaymentService/types";
import useUserBankAccount from "hooks/useUserBankAccount";
import { currencyToLocale } from "utils/countryCurrency";
import parsePaymentLink from "utils/parsePaymentLink";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";
import { parsePrice } from "utils";

import StatusLabelReservation from "../StatusLabelReservation";
import useReservationCardStyles from "./ReservationCardStyles";
import useRefundDetails from "../../hooks/useRefundDetails";
import ReservationOptions from "../ReservationOptions";
import CustomCard from "../CustomCard";

interface Props {
  reservation: IReservation;
  user: UserModel;
  isRefund?: boolean;
  country?: Locale;
  propertyId?: number;
}

const ReservationCard = ({ reservation, user, country, propertyId, isRefund = false }: Props) => {
  const classes = useReservationCardStyles();
  const { dataBankAccount, isLoadingBankAccount } = useUserBankAccount(
    country,
    user?.id,
    propertyId
  );
  const { data } = useRefundDetails(country, reservation?.group_id);
  const description = data.find((item) => item.type === "OUTFLOW")?.description;

  return (
    <CustomCard
      action={
        <Loading loading={isLoadingBankAccount} size={20}>
          <>
            {isRefund && <Chip className={classes.refundChip} label="Devolución" />}
            <ReservationOptions
              reservation={reservation}
              country={country}
              dataBankAccount={dataBankAccount}
              user={user}
              propertyId={propertyId}
              isRefund={isRefund}
            />
          </>
        </Loading>
      }
      title={reservation.description}
      subheader={<StatusLabelReservation status={reservation.status} />}>
      <Typography variant="body2" component="p">
        UID: {reservation.movement_id}
      </Typography>
      <Typography variant="body2" component="p">
        Monto:
        {` ${parsePrice(reservation.total_amount, reservation.currency)}`}
      </Typography>
      <Typography variant="body2" component="p">
        Fecha programada de pago:
        {` ${format(new Date(reservation.agreed_date), "dd/MM/yyyy")}`}
      </Typography>
      <Typography variant="body2" component="p">
        {`Descripción: ${description || "Sin motivo"}`}
      </Typography>
      {reservation.status === "UNPAID" && (
        <Typography
          href={parsePaymentLink(
            reservation.payment_link,
            reservation.movement_id,
            currencyToLocale[reservation.currency]
          )}
          variant="body2"
          component="a">
          Link de pago:
          {` ${parsePaymentLink(
            reservation.payment_link,
            reservation.movement_id,
            currencyToLocale[reservation.currency]
          )}`}
        </Typography>
      )}
    </CustomCard>
  );
};

export default ReservationCard;
