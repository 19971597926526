import { ReactNode } from "react";

interface Props {
  value: string | number;
  index: string | number;
  children: ReactNode;
}

function TabPanel({ children, value, index, ...rest }: Props) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}>
      {value === index && children}
    </div>
  );
}

export default TabPanel;
