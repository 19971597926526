import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";

import ControlledPhoneInput from "Components/Admin/UIComponents/ControlledForm/ControlledPhoneInput";
import { Locale } from "models/Countries";

import { DocumentType, IUserData } from "./utils/types";
import classes from "./UserFormFieldsStyles";

interface Props {
  country: Locale;
  documentTypeList: DocumentType[];
}

const UserFormFields = ({ country, documentTypeList }: Props) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IUserData>();
  const documentLabel = country === "cl" ? "RUT" : "Documento de identidad";

  return (
    <div className={classes.container}>
      <FieldInput
        label="Nombre"
        id="name"
        placeholder="Ingresar nombre"
        errorMessage={errors.name?.message}
        {...register("name")}
      />
      <FieldInput
        label="Apellido"
        id="lastName"
        placeholder="Ingresar apellido"
        errorMessage={errors.lastName?.message}
        {...register("lastName")}
      />
      <FieldInput
        label="Segundo Apellido"
        id="secondLastName"
        placeholder="Ingresar segundo apellido"
        errorMessage={errors.secondLastName?.message}
        {...register("secondLastName")}
      />
      <FieldSelect
        label="Tipo de documento"
        id="documentType"
        placeholder="Seleccionar tipo de documento"
        errorMessage={errors.documentType?.message}
        options={documentTypeList}
        {...register("documentType")}
      />
      <FieldInput
        label={documentLabel}
        id="document"
        placeholder="Ingresar documento"
        errorMessage={errors.document?.message}
        {...register("document")}
      />
      <FieldInput
        label="Email"
        id="email"
        placeholder="Ingresar email"
        errorMessage={errors.email?.message}
        {...register("email")}
      />
      <ControlledPhoneInput id="phone" phoneLabel="Celular" />
    </div>
  );
};

export default UserFormFields;
