import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ResponseError } from "@houm-com/http-client";
import { alert } from "libs/alerts";
import { useGetToken } from "hooks";
import propertyService from "services/propertyService";
import { TimeBlock } from "context/availabilityContext/availabilityTypes";

interface IPropertyScheduleData {
  id: number;
  schedule: TimeBlock[];
}

const usePropertySchedule = () => {
  const { t } = useTranslation(["errors.property-schedule"]);
  const { getToken } = useGetToken();

  const { mutate, isLoading } = useMutation(
    async (data: IPropertyScheduleData) => {
      const authToken = await getToken();
      const response = await propertyService.updateAvailablePropertyHours(
        data.id,
        data.schedule,
        null,
        authToken
      );
      return response?.data;
    },

    {
      onError: (e: ResponseError) => {
        alert.error({
          message: "Error al actualizar horarios de propiedad",
          disclaimer: t(e.message),
        });
      },
      onSuccess: () => {
        alert.success({
          message: "Horarios actualizados correctamente",
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
  };
};

export default usePropertySchedule;
