import moment from "moment";

import { Paper, Typography } from "@material-ui/core";

import { Comment } from "models/Property";
import useStyles from "./generalCommentsListStyles";

interface Props {
  comments: Comment[];
}

function GeneralCommentsTimeline({ comments }: Props) {
  const classes = useStyles();

  if (!comments.length) return null;

  return (
    <Paper elevation={2} className={classes.root}>
      {comments.map((comment) => (
        <div key={comment?.id} className={classes.commentRoot}>
          <div className={classes.header}>
            <Typography className={classes?.commenter}>{`${comment?.commenter}:`}</Typography>
            <Typography className={classes?.date}>
              {moment(comment?.created_at).fromNow()}
            </Typography>
          </div>
          <Typography className={classes.comment}>{comment?.comment}</Typography>
        </div>
      ))}
    </Paper>
  );
}

export default GeneralCommentsTimeline;
