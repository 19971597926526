import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import {
  ControlledSelection,
  ControlledNumberField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useProperty } from "context/propertyContext/propertyContext";
import { generalOptions } from "utils/createProperty";
import { salesMinMaxValues } from "utils/yupValidators/propertyPriceValidators";
import { generatePriceWarningMessage } from "Components/Admin/UIComponents/PropertyForm/utils/priceWarningMessage";

interface Props {
  country: string;
}

function ForSalePrice({ country }: Props) {
  const { state } = useProperty();
  const { control } = useFormContext();

  const [defaultTaxCurrency, setDefaultTaxCurrency] = useState(state.property?.taxes_currency);

  useEffect(() => {
    setDefaultTaxCurrency(generalOptions[country].defaultTaxCurrency);
  }, [country]);

  const currencyOptions = useMemo(() => generalOptions[country]?.currency || [], [country]);

  const currencyCode = useWatch({
    control,
    name: "tipo_moneda_venta",
    defaultValue: state.property?.property_details[0]?.tipo_moneda_venta,
  });

  const minMaxValues = salesMinMaxValues?.[country]?.[currencyCode];

  return (
    <div>
      <ControlledSelection
        id="tipo_moneda_venta"
        label="Tipo de moneda para venta"
        defaultValue={state.property?.property_details[0]?.tipo_moneda_venta}
        options={currencyOptions}
      />
      <ControlledNumberField
        id="valor_venta"
        label="Precio de venta"
        defaultValue={state.property?.property_details[0]?.valor_venta}
        placeholder={generalOptions[country]?.placeholders.salePrice}
        numberFieldProps={{
          prefix: "$",
        }}
        warn={(fieldValue) =>
          Number(fieldValue) > minMaxValues?.max || Number(fieldValue) < minMaxValues?.min
        }
        warningMessage={generatePriceWarningMessage(minMaxValues, currencyCode)}
      />
      <ControlledSelection
        id="taxes_currency"
        label={`Tipo de moneda ${generalOptions[country].labels.taxes.toLowerCase()}`}
        defaultValue={defaultTaxCurrency}
        options={currencyOptions}
      />
      <ControlledNumberField
        id="taxes"
        label={generalOptions[country].labels.taxes}
        defaultValue={state.property?.taxes ?? 0}
        placeholder="5,54"
        numberFieldProps={{
          prefix: "$",
        }}
      />
    </div>
  );
}

export default ForSalePrice;
