import useCardContainerStyles from "./CardContainerStyles";

import { CardContainerProps } from "context/controlTower/controlTowerTypes";
import Spinner from "Components/Shared/partials/Spinner";

function CardContainer({ loading, children }: CardContainerProps) {
  const classes = useCardContainerStyles();
  if (loading) return <Spinner className={classes.loading} />;

  return <div className={classes.container}>{children}</div>;
}

export default CardContainer;
