import { makeStyles, Theme } from "@material-ui/core";

const useFileCardStyles = makeStyles<Theme>((theme) => ({
  button: {
    backgroundColor: "#4C6977",
    alignSelf: "flex-end",
    width: "100%",
  },
  buttonSecondary: {
    backgroundColor: "transparent",
    border: `1px solid #4C6977`,
    alignSelf: "flex-end",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flexShrink: 0,
    width: "fit-content",
    gap: theme.spacing(1),
  },
  cardDocument: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    justifyContent: "space-between",
  },
  documentName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 700,
  },
  documentDate: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: "#A6B4BB",
  },
  documentDateContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  flagStatus: {
    borderRadius: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.grey[300],
    width: "fit-content",
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(18),
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    display: "flex",
  },
  flagStatusIcon: {
    width: 12,
    marginRight: theme.spacing(1),
  },
  flagStatusNotValidated: {
    fontWeight: 700,
    backgroundColor: "#FDED9A",
    color: "#A07800",
    fill: "#A07800",
  },
  flagStatusValidated: {
    fontWeight: 700,
    backgroundColor: "#C1E4A8",
    color: "#26810A",
  },
  flagStatusRejected: {
    fontWeight: 700,
    backgroundColor: "#FFB5AA",
    color: "#900000",
  },
  dialogContent: {
    paddingBottom: 0,
  },
  dialogTitleContainer: {
    maxWidth: theme.typography.pxToRem(350),
  },
  dialogSubtitle: {
    marginTop: theme.spacing(2),
  },
  dialogButtons: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
  },
  buttonTertiary: {
    backgroundColor: "transparent",
    border: `1px solid #4C6977`,
  },
}));
export default useFileCardStyles;
