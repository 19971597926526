import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";

type Props = React.ComponentProps<typeof ControlledTextField>;

function ControlledNumber(props: Props) {
  return (
    <ControlledTextField
      {...props}
      textFieldProps={{
        ...props.textFieldProps,
        inputProps: {
          type: "number",
          onWheel: (event) => event.currentTarget.blur(),
          ...props.textFieldProps.inputProps,
        },
      }}
    />
  );
}

export default ControlledNumber;
