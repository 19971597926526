import { useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

import Button from "@houm-com/ui/Button";

import { GreenTag, RedTag } from "Components/Admin/UIComponents/PropertyTags/Tags";
import Spinner from "Components/Shared/partials/Spinner";
import { IPromotion } from "models/Promotions";

import EditPromotionModal from "../Promotions/components/EditPromotion/EditPromotionModal";
import PromotionNameAndDate from "./components/PromotionNameAndDate";
import DetailsItem from "./components/DetailsItem";
import BackButton from "./components/BackButton";
import GeneralInfo from "./components/GeneralInfo";
import Observations from "./components/Observations";
import CitiesList from "./components/CitiesList";
import usePromotionById from "./hooks/usePromotionById";
import classes from "./PromotionDetailsStyles";

export default function PromotionDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { promotionId } = useParams<{ promotionId: string }>();
  const { data: promotion, isLoading } = usePromotionById(Number(promotionId));

  const handleOpenEditDatesModal = () => {
    setIsModalOpen(true);
  };

  if (isLoading) return <Spinner />;
  return (
    <>
      <BackButton backTo="/admin/promotions" />
      <h1 className={classes.title}>Detalles de la promoción</h1>
      <div className={classes.head}>
        <div className={classes.tagContainer}>
          <span>Ciudades {promotion.cities?.length ?? 0}</span>
          {promotion.active ? (
            <GreenTag content="Activa" tagStyles={classes.tag} />
          ) : (
            <RedTag content="Inactiva" tagStyles={classes.tag} />
          )}
        </div>
        <Button onClick={handleOpenEditDatesModal} size="md" variant="primary">
          Editar fechas
        </Button>
      </div>
      <PromotionNameAndDate
        name={promotion.promotionName}
        initDate={promotion.generalInfo.initDate}
        endDate={promotion.generalInfo.endDate}
      />
      <DetailsItem title="Comentario de la promoción">
        <span>{promotion.generalInfo.description}</span>
      </DetailsItem>
      <Observations
        title="Observaciones para el propietario"
        observations={promotion.generalInfo.landlordObservation}
      />
      <Observations
        title="Observaciones para el arrenpromotionrio"
        observations={promotion.generalInfo.tenantObservation}
      />
      <GeneralInfo
        initDate={promotion.generalInfo.initDate}
        endDate={promotion.generalInfo.endDate}
        promotionDetails={promotion.promotionDetails}
      />
      <CitiesList cities={promotion.cities} />
      <EditPromotionModal
        promotion={
          isModalOpen &&
          ({
            ...promotion,
            initDate: format(promotion.generalInfo.initDate, "yyyy-MM-dd"),
            endDate: format(promotion.generalInfo.endDate, "yyyy-MM-dd"),
          } as unknown as IPromotion)
        }
        onHandleClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
