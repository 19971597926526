import axios from "axios";
import { BACKEND_URL } from "env";
import { getToken, createHttp } from "services/helper";
import { UpdateAppraiserBody, NewAppraiserAvailabilityBody } from "./types";

export default {
  profile(token, appraiserId) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });
    return http.get(`/schedules/v2/appraisers/${appraiserId}/profile`).catch(() => undefined);
  },

  allSchedules(token, appraiserId) {
    return createHttp(token).get(`/schedules/v2/all-future-schedules/appraiser/${appraiserId}`);
  },

  createAppraiser(data, token) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: `${BACKEND_URL}`,
      headers,
    });
    return http.post("/schedules/v2/appraisers/", data);
  },

  newAvailability(appraiserId: string, token: string) {
    return createHttp(token).get(`/schedules/v2/availability/any-appraiser/${appraiserId}`);
  },

  newUpdateAppraiserAvailability(
    appraiserId: string,
    body: NewAppraiserAvailabilityBody,
    token: string
  ) {
    return createHttp(token).post(
      `/schedules/v2/set-availability/any-appraiser/${appraiserId}`,
      body
    );
  },

  uploadAppraiserPhoto(data, token) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: `${BACKEND_URL}`,
      headers,
    });
    return http.post("/schedules/v2/appraisers/photoupdate", data);
  },
  updateAppraiserProfile(data: UpdateAppraiserBody, appraiserId: string, token: string) {
    return createHttp(token).patch(`/schedules/v2/appraisers/${appraiserId}/profile/update`, data);
  },
};
