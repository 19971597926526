import { Typography } from "@material-ui/core";

import useTitleCardStyles from "./TitleCardStyles";

interface Props {
  title: string;
}

function TitleCard({ title }: Props) {
  const classes = useTitleCardStyles();

  return <Typography className={classes.title}>{title}</Typography>;
}

export default TitleCard;
