import { makeStyles, Theme } from "@material-ui/core";

const useInputFieldStyles = makeStyles<Theme>((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
    backgroundColor: "#EFF2F3",
    fontSize: theme.typography.pxToRem(16),
  },
  percentageField: {
    alignSelf: "flex-start",
    width: "75px",
  },
  inputField: {
    fontSize: theme.typography.pxToRem(16),
  },
  inputExtraInfo: {
    margin: theme.spacing(2, 1),
  },
}));
export default useInputFieldStyles;
