/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Typography, InputAdornment, StandardTextFieldProps } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { WorkOutline } from "@material-ui/icons";

import { useGetToken } from "hooks";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";

import useStyles from "./CompanySuggesterStyles";
import { getCompanies } from "context/projectContext/projectActions";
import { CompanyOptions } from "models/User";

function Option({ name, id }: CompanyOptions) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="body2" classes={{ root: classes.grayColor }}>
        {name}
      </Typography>
      <Typography variant="caption" classes={{ root: classes.grayColor }}>
        id: {id}
      </Typography>
    </div>
  );
}

interface Props {
  label: string;
  id: string;
  onChange: (e: any) => void;
  value: CompanyOptions;
  defaultValue?: CompanyOptions;
  errorMessage?: string;
  textFieldProps?: StandardTextFieldProps;
}

function CompanySuggester({
  id,
  label,
  value,
  onChange,
  errorMessage = "Error",
  defaultValue,
  textFieldProps,
}: Props) {
  const classes = useStyles();
  const { getToken } = useGetToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [inputQuery, setInputQuery] = useState<string>("");
  const [companies, setCompanies] = useState<CompanyOptions[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyOptions>(value);

  const getCompaniesBySearch = async (input: string, token: string) => {
    setLoading(true);
    const requestedCompanies = (await getCompanies(input, token)) as CompanyOptions[];
    setCompanies(requestedCompanies);
    setLoading(false);
  };

  useEffect(() => {
    getToken().then((authToken) => getCompaniesBySearch(inputQuery, authToken));
  }, [inputQuery]);

  const handleSelectedCompany = (company) => {
    setSelectedCompany(company);
    onChange(company);
  };

  useEffect(() => {
    if (defaultValue) setSelectedCompany(defaultValue);
  }, [defaultValue]);

  return (
    <Autocomplete
      noOptionsText="No se encontraron resultados"
      defaultValue={defaultValue}
      value={selectedCompany}
      loading={loading}
      loadingText="Buscando ..."
      options={companies}
      onChange={(_, company) => handleSelectedCompany(company)}
      onInputChange={(e, val) => setInputQuery(val)}
      getOptionLabel={(option) => `${option.name} ${option.id}`}
      renderOption={(option) => <Option name={option.name} id={option.id} />}
      renderInput={(params) => (
        <CustomTextField
          fullWidth
          label={label}
          {...params}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.grayColor,
            },
            disableUnderline: false,
            startAdornment: (
              <InputAdornment position="start">
                <WorkOutline />
              </InputAdornment>
            ),
          }}
          id={id}
          errorMessage={errorMessage}
          {...textFieldProps}
        />
      )}
    />
  );
}
export default CompanySuggester;
