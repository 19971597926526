import { makeStyles, Theme } from "@material-ui/core";

const useOutflowReservationDataStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: 0,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useOutflowReservationDataStyles;
