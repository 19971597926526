import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { setIsOpenInfoModal } from "context/demandScheduledVisitsContext/demandScheduledActions";

import useContactInfoStyles from "./ContactInfoStyles";

function ContactInfo() {
  const classes = useContactInfoStyles();
  const {
    state: { isOpenInfoModal, contactInfo },
    dispatch,
  } = useDemandScheduled();
  return (
    <CustomCenteredModal
      open={isOpenInfoModal}
      onClose={() => dispatch(setIsOpenInfoModal(false))}
      title="Información del contacto"
      subtitle=""
      showCloseButton>
      <div className={classes.container}>{contactInfo}</div>
    </CustomCenteredModal>
  );
}
export default ContactInfo;
