import { useQuery, UseQueryResult } from "react-query";

import { IContractDebtStateResponse } from "services/contractService/types";
import contractService from "services/contractService";
import { useGetToken } from "hooks";

const useGetContractDebtState = (contractId?: number) => {
  const { getToken } = useGetToken();

  const { data, isLoading, isError, isSuccess }: UseQueryResult<IContractDebtStateResponse, Error> =
    useQuery<IContractDebtStateResponse, Error>(
      ["contractDebtState", contractId],
      async () => {
        const authToken = await getToken();
        const contractDebtStateResponse = await contractService.getContractDebtState(
          contractId,
          authToken
        );
        return contractDebtStateResponse.data;
      },
      {
        enabled: !!contractId,
        refetchOnMount: true,
      }
    );

  return {
    contractDebtState: data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useGetContractDebtState;
