import { useMemo, useState } from "react";

import { CountryUpperCase } from "models/Countries";
import moment, { Moment } from "moment";

import { Availability, applyFilterToHours, getFormattedHour } from "utils/availabilityTime";

import { getTimezoneWithCommune } from "utils/datetime";

interface Props {
  availability: Availability;
  country: CountryUpperCase;
  commune: string;
  firstDayByDefault?: boolean;
  firstHourByDefault?: boolean;
  removeConditions: ((hour: Moment, today: Moment) => boolean)[];
  defaultHour?: string;
}

interface AllowedHour {
  timestamp: string;
  formatted: string;
}

/**
 *
 * @param availability: availability data without filters
 * @param country: property country
 * @param commune: property commune
 * @param defaultHour: if exists the default hour (2022-04-07T13:00:00) will be added into filtered availability
 * @param removeConditions array of functions to remove the hour
 * @param firstDayByDefault set the first day by default
 * @param firstHourByDefault set the first hour when the day changes
 */

const useAvailability = ({
  country,
  commune,
  availability = null,
  defaultHour = null,
  removeConditions = [],
  firstDayByDefault = false,
  firstHourByDefault = false,
}: Props) => {
  const [currentHour, setCurrentHour] = useState<string>(null);
  const [currentDay, setCurrentDay] = useState<moment.Moment>(null);

  const filteredAvailability: Availability = useMemo(() => {
    if (!availability || Object.keys(availability).length === 0) {
      setCurrentDay(null);
      return {};
    }

    //the current availability does not contain the default hour, so we can add it in the object
    const avWithDefaultHour = {
      ...availability,
      ...(defaultHour?.length > 10 && { "00-00": [defaultHour] }),
    };

    return applyFilterToHours({
      availability: avWithDefaultHour,
      country,
      commune,
      removeConditions,
    });
  }, [availability]);

  //days used in filtered availability
  const allowedDays: moment.Moment[] = useMemo(() => {
    const momentDays = Object.keys(filteredAvailability).map((date) => moment(date));

    if (firstDayByDefault && momentDays.length > 0) {
      setCurrentDay(momentDays[0]);
    }

    if (defaultHour && defaultHour.length > 10) {
      const day = momentDays.find((day) =>
        day.isSame(
          moment.utc(defaultHour).clone().tz(getTimezoneWithCommune(country, commune)),
          "date"
        )
      );
      setCurrentDay(day);
    }

    return momentDays;
  }, [filteredAvailability]);

  //selected day hours
  const allowedHours: AllowedHour[] = useMemo(() => {
    if (!currentDay) return [];

    const dayStr = Object.keys(filteredAvailability).find((day) =>
      currentDay.isSame(moment(day), "date")
    );

    setCurrentHour(null);

    if (!dayStr || allowedDays.length === 0) return [];

    if (
      defaultHour &&
      currentDay.isSame(
        moment.utc(defaultHour).clone().tz(getTimezoneWithCommune(country, commune)),
        "date"
      )
    ) {
      setCurrentHour(defaultHour);
    }

    if (firstHourByDefault) {
      setCurrentHour(filteredAvailability[dayStr][0]);
    }

    return filteredAvailability[dayStr].map((hour) => ({
      timestamp: hour,
      formatted: getFormattedHour(hour, country, commune),
    }));
  }, [currentDay]);

  return {
    currentHour,
    setCurrentHour,
    currentDay,
    setCurrentDay,
    filteredAvailability,
    allowedDays,
    allowedHours,
  };
};

export default useAvailability;
