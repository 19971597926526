const yearOptionsCreator = (
  startingYear: number,
  numberOfYears: number
): { label: string; value: string }[] => {
  const yearOptions = [];
  for (let i = 0; i < numberOfYears; i++) {
    yearOptions.push({ label: startingYear + i, value: startingYear + i });
  }
  return yearOptions;
};

export default yearOptionsCreator;
