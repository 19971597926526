import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  closeButton: {
    zIndex: 1,
    color: "black",
  },
  customDialog: {
    width: 500,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textTransform: "none",
    borderRadius: 25,
    fontWeight: "bold",
  },
  warningDialogRoot: {
    width: 652,
  },
  warningDialogTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700,
    width: 457,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  warningDialogSubtitle: {
    fontSize: theme.typography.pxToRem(18),
    width: 397,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  warningDialogButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  warningDialogTextContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  warningDialogButton: {
    margin: theme.spacing(2),
  },
}));
