import { IParsedContractTemplate } from "models/ContractTemplate";
import { Locale } from "models/Countries";

export const templateOptions = (templates: IParsedContractTemplate[], planCountry: Locale) =>
  templates
    .filter((template) => template.country === planCountry)
    .map((template) => ({
      value: template.id,
      label: `${template.id}. ${template.name}`,
    }));
