import { es } from "date-fns/locale";
import { format } from "date-fns";

import { Container, Typography } from "@material-ui/core";

import { IReservation } from "services/newPaymentService/types";
import { parsePrice } from "utils";

import useApplicantReservationDataStyles from "./ApplicantReservationDataStyles";

interface Props {
  reservation: IReservation;
}

const ApplicantReservationData = ({ reservation }: Props) => {
  const classes = useApplicantReservationDataStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.label}>
        <b>Descripción: </b> {reservation.description}
      </Typography>
      <Typography className={classes.label}>
        <b>ID Propiedad: </b>
        {reservation.property_id}
      </Typography>
      <Typography className={classes.label}>
        <b>Fecha pactada de pago: </b>
        {format(new Date(reservation.agreed_date), "dd/MM/yyyy", {
          locale: es,
        })}
      </Typography>
      {reservation.date && (
        <Typography className={classes.label}>
          <b>Fecha de pago: </b>
          {format(new Date(reservation.date), "dd/MM/yyyy", {
            locale: es,
          })}
        </Typography>
      )}
      <Typography className={classes.label}>
        <b>Monto a pagar: </b>
        {parsePrice(reservation.total_amount, reservation.currency)}
      </Typography>
      <Typography className={classes.label}>
        <b>Monto pagado: </b>
        {parsePrice(reservation.payment, reservation.currency)}
      </Typography>
      <Typography className={classes.label}>
        <b>Nombre: </b> {reservation.person_data.full_name}
      </Typography>
      <Typography className={classes.label}>
        <b>Documento: </b> {reservation.person_data.document}
      </Typography>
      <Typography className={classes.label}>
        <b>Porcentaje reembolso: </b>
        {(reservation.total_amount / reservation.payment) * 100}%
      </Typography>
    </Container>
  );
};

export default ApplicantReservationData;
