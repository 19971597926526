import { useState } from "react";
import Swal from "sweetalert2";

import {
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import useHoumCheckerModalStyles from "./HoumCheckerModalStyles";
import useUpdateChecker from "../../../hooks/useUpdateChecker";
import useAllAppraisers from "hooks/useAllAppraisers";

interface Props {
  open: boolean;
  handleClose: () => void;
  propertyUid: string | null;
  actualHoumCheckerId: string;
}

const HoumCheckerModal = ({
  open,
  handleClose,
  actualHoumCheckerId = null,
  propertyUid,
}: Props) => {
  const classes = useHoumCheckerModalStyles();
  const { appraisers, status } = useAllAppraisers({ enabled: open });
  const { isError, isLoading, isSuccess, mutate } = useUpdateChecker(propertyUid);
  const [houmChecker, setHoumChecker] = useState(actualHoumCheckerId);

  if (isSuccess) {
    window.location.reload();
  }

  if (isError) {
    Swal.fire("Error", "No se pudo cambiar al houmchecker", "error");
  }

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <DialogTitle>
        <Typography className={classes.title}>Homechecker</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              {status === "loading" && <p>Cargando houmers...</p>}
              {status === "success" && (
                <>
                  <InputLabel>Houmer</InputLabel>
                  <Select
                    value={houmChecker}
                    onChange={(e) => setHoumChecker(e.target.value as string)}>
                    <MenuItem value={null}>Sin homechecker</MenuItem>
                    {appraisers?.map((appraiser) => (
                      <MenuItem value={appraiser.id} key={appraiser.id}>
                        {appraiser.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <DesignSystemButton
              size="small"
              label="Cambiar"
              variant="primary"
              isLoading={isLoading || status === "loading"}
              onClick={() => mutate({ homecheckerId: houmChecker })}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DSModal>
  );
};

export default HoumCheckerModal;
