import Tag from "Components/Admin/administrator/demand/components/Tag";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";
import { setFilters } from "context/demandScheduledVisitsContext/demandScheduledActions";
import useTagBarStyles from "./TagBarStyles";

function TagBar() {
  const classes = useTagBarStyles();
  const {
    state: { filters },
    dispatch,
  } = useDemandScheduled();
  const handleRemove = (filter: string) => {
    dispatch(
      setFilters({
        ...filters,
        [filter]: null,
      })
    );
  };
  return (
    <div className={classes.tagsContainer}>
      {filters.beginDate && (
        <Tag onRemove={() => handleRemove("beginDate")}>{filters.beginDate}</Tag>
      )}
      {filters.endDate && <Tag onRemove={() => handleRemove("endDate")}>{filters.endDate}</Tag>}
      {filters.houmer && <Tag onRemove={() => handleRemove("houmer")}>{filters.houmer?.label}</Tag>}
      {filters.agent && <Tag onRemove={() => handleRemove("agent")}>{filters.agent?.label}</Tag>}
      {filters.status && <Tag onRemove={() => handleRemove("status")}>{filters.status?.label}</Tag>}
      {filters.thirdCoord && (
        <Tag onRemove={() => handleRemove("thirdCoord")}>{filters.thirdCoord?.label}</Tag>
      )}
    </div>
  );
}

export default TagBar;
