import { makeStyles, Theme } from "@material-ui/core";

export const useNewSubscriptionDisclaimerStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(1),
    display: "flex",
    backgroundColor: "#235CAF",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    gap: theme.spacing(1),
    alignItems: "center",
  },
  animatedDot: {
    flexShrink: 0,
    content: "''",
    margin: theme.spacing(0.5),
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    animation: "$grow 1s ease-in-out infinite",
  },
  disclaimer: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    color: "#FFFFFF",
  },
  "@keyframes grow": {
    "0%": {
      transform: "scale(0.5)",
      opacity: 0,
    },
    "80%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(0.5)",
      opacity: 0,
    },
  },
}));
