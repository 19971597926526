import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledDatePicker";
import { useFormNotifications } from "hooks";

import { observationSchema, requiredText } from "./validationSchema";
import useObservation from "../../../../hooks/useObservation";
import useObservationFormStyles from "./ObservationFormStyles";

interface Props {
  onClose: () => void;
  subscriptionId: number;
  observation: string;
}

const ObservationForm = ({ onClose, subscriptionId, observation }: Props) => {
  const { mutate, isLoading, isSuccess } = useObservation(subscriptionId);
  const classes = useObservationFormStyles();

  const methods = useForm<{ observation: string }>({
    resolver: yupResolver(observationSchema()),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<{ observation: string }>({ errors, requiredText });

  const onSubmit: SubmitHandler<{ observation: string }> = (data) => {
    mutate({ observation: data.observation });
  };

  if (isSuccess) onClose();

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>Escribir comentario</Typography>
      <FormProvider {...methods}>
        <form className={classes.formContent} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item container alignItems="flex-start">
            <Grid item xs={12}>
              <ControlledTextField
                label=""
                id="observation"
                defaultValue={observation}
                textFieldProps={{
                  multiline: true,
                  minRows: 3,
                  InputProps: {
                    classes: {
                      root: classes.descriptionInput,
                    },
                  },
                  inputProps: {
                    maxlength: 1024,
                    placeholder: "Ingrese un comentario de máximo 1024 caracteres.",
                  },
                }}
              />
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.buttonContainer}>
              <DesignSystemButton
                variant="primary"
                label="Guardar"
                size="small"
                isLoading={isLoading}
                type="submit"
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default ObservationForm;
