import numberWithCommas from "./numberWithCommas";

const parsePrice = (value: number | string, currency: string, showZero = false) => {
  if (value || showZero) {
    if (currency?.toLocaleLowerCase() === "uf" || currency === "U.F." || currency === "CLF") {
      return `${numberWithCommas(
        parseFloat(Number(value).toFixed(3)).toString().replace(".", ",")
      )} UF`;
    }
    return `$ ${numberWithCommas(value)}`;
  }
  return "N/A";
};

export default parsePrice;
