/* eslint-disable @typescript-eslint/no-use-before-define */
import { makeStyles } from "@material-ui/core/styles";
import { GridToolbarContainer, GridToolbarExport as ExportButton } from "@material-ui/x-grid";

import FilterButton from "../../../UIComponents/Buttons/FilterButton";

import { useTable } from "../../../../../context/tableContext/tableContext";
import { setShowModal } from "../../../../../context/tableContext/tableActions";

const ENABLE_EXPORT = false;
// TODO: Make a SearchBar by refactoring current SearchBar component in properties
function Toolbar() {
  const classes = useStyles();
  const { dispatch } = useTable();
  const handleFilterClick = () => {
    dispatch(setShowModal(true));
  };
  return (
    <GridToolbarContainer>
      <div className={classes.container}>
        {/* <SearchBar /> */}
        <div />
        <div>
          <FilterButton onClick={handleFilterClick} />
          {ENABLE_EXPORT && <ExportButton />}
        </div>
      </div>
    </GridToolbarContainer>
  );
}

export default Toolbar;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
});
