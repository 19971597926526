import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { useGetToken } from "hooks";
import { InfoCard, CustomPanel, TableLayout } from "Components/Admin/UIComponents";
import UserHeader from "Components/Admin/UIComponents/UserHeader";
import Spinner from "Components/Shared/partials/Spinner";
import { getUser } from "../../context/usersActions";
import { useUsers } from "../../context/usersContext";
import TenantSearchTab from "./TenantSearchTab";

const tabs = [
  {
    label: "Búsqueda",
    component: <TenantSearchTab />,
  },
  {
    label: "Evaluación",
    component: <UserHeader title="No implementado" />,
  },
  {
    label: "Contrato",
    component: <UserHeader title="No implementado" />,
  },
  {
    label: "Pagos",
    component: <UserHeader title="No implementado" />,
  },
  {
    label: "Solicitudes",
    component: <UserHeader title="No implementado" />,
  },
];

function TenantProfile() {
  const { getToken } = useGetToken();
  const params = useParams<{ id: string }>();
  const { state, dispatch } = useUsers();
  const { user, loading } = state;

  useEffect(() => {
    getToken().then((authToken) => getUser(Number(params.id), dispatch, authToken));
  }, []);

  if (loading || !user) {
    return <Spinner />;
  }

  return (
    <TableLayout>
      <UserHeader title={`ID Tenant [${user.id}]`} country={user.country} />
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <InfoCard
            title="Datos personales"
            state="En búsqueda"
            infoList={[
              { label: "Nombre", value: `${user.firstName} ${user.lastNames}` },
              { label: "Rut", value: `${user.document || "-"}` },
              { label: "Telefono", value: `${user.phone || "-"}` },
              { label: "Correo", value: `${user.email}` },
            ]}
          />
        </Grid>
        <Grid item sm={12}>
          <CustomPanel tabs={tabs} />
        </Grid>
      </Grid>
    </TableLayout>
  );
}

export default TenantProfile;
