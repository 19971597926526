import { makeStyles, Theme } from "@material-ui/core/styles";

const useUnitsUploadModalStyles = makeStyles((theme: Theme) => ({
  modal: {
    width: "35vw",
    minWidth: 500,
  },
  icon: {
    fill: "currentColor",
    marginRight: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(2),
  },
  file: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(14),
    display: "block",
  },
  boldText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  link: {
    "&:hover": {
      color: "inherit",
    },
    color: "inherit",
  },
  warning: {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.grey[50],
  },
  warningText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    marginBottom: 0,
  },
}));

export default useUnitsUploadModalStyles;
