import { ComponentProps } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import classnames from "classnames";

import { FormControl, InputLabel, FormHelperText } from "@material-ui/core";

import useStyles from "./CustomNumberFieldStyles";

interface Props extends NumberFormatProps {
  label: string;
  errorMessage?: string;
  labelClassName?: string;
  bordered?: boolean;
  warning?: boolean;
  warningMessage?: string;
  labelPlacement?: "top" | "bottom" | "end" | "start";
  helperText?: string;
  error?: boolean;
  labelProps?: ComponentProps<typeof InputLabel>;
  formHelperTextProps?: ComponentProps<typeof FormHelperText>;
}

function CustomNumberField({
  error,
  label,
  errorMessage = "Error",
  placeholder,
  disabled = false,
  labelClassName = "",
  bordered = false,
  warning,
  warningMessage,
  labelPlacement = "top",
  helperText,
  labelProps,
  formHelperTextProps,
  ...props
}: Props) {
  const classes = useStyles({ error, bordered, warning, labelPlacement });

  return (
    <FormControl className={classes.root} error={!!error}>
      <InputLabel
        disableAnimation
        className={classnames(classes.label, labelClassName)}
        {...labelProps}>
        {label}
      </InputLabel>
      <NumberFormat
        placeholder={placeholder}
        decimalSeparator=","
        thousandSeparator="."
        className={classes.numberInput}
        {...props}
      />
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
      <FormHelperText className={classes.stateHelperText}>
        {(error && errorMessage) || (warning && warningMessage)}
      </FormHelperText>
    </FormControl>
  );
}

export default CustomNumberField;
