import moment from "moment";

import {
  GroupedInspectionReportSpace,
  InspectionReport,
  InspectionReportTableInfo,
} from "models/InspectionReport";
import {
  InspectionReportFeature,
  InspectionReportFeaturesNames,
  InspectionReportIndexResponse,
  InspectionReportItem,
  InspectionReportItemsNames,
  InspectionReportShowResponse,
  InspectionReportSpace,
  InspectionReportSpacesType,
} from "services/propertyService/types";
import { getObjectLabel } from "utils";
import { inspectionReportGeneralSpaceTranslation } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";

export const parseGetPropertyInspectionReports = (
  results: InspectionReportIndexResponse[]
): InspectionReportTableInfo[] =>
  results.map((result) => ({
    id: result?.id,
    createdAt: moment(result?.created_at).local().format("DD-MM-YYYY"),
    type: result?.type,
    homeChecker: result?.appraiser?.name ?? "",
  }));

export const parseShowPropertyInspectionReportRequest = (
  result: InspectionReportShowResponse
): InspectionReport => ({
  id: result.id,
  createdAt: moment(result?.created_at).local().format("DD-MM-YYYY"),
  appraiserInfo: {
    id: result?.appraiser?.id,
    name: result?.appraiser?.name ?? "Sin información",
    email: result?.appraiser?.email,
  },
  isHabitable: result?.is_habitable,
  generalObservation: result?.general_observation,
  cleaningObservation: result?.cleaning_observation,
  generalSpacesSummary: parseSpaces(result?.spaces).map((space) => ({
    id: space?.id,
    type: space?.type,
    name: space?.name,
    overallQualification: space?.overall_qualification,
    overallObservation: space?.overall_observation,
    photo: space?.photo_link,
  })),
  spaces: parseSpaces(result?.spaces).map((space) => groupByFeatureNature(space)),
});

const groupSpaces = (type: InspectionReportSpacesType, spaces: InspectionReportSpace[]) =>
  spaces.filter((space) => space.type === type) ?? null;

const setSpaceNames = (spacesArr: InspectionReportSpace[]): InspectionReportSpace[] => {
  if (spacesArr.length === 1) {
    return spacesArr.map((space) => ({
      ...space,
      name: getObjectLabel(inspectionReportGeneralSpaceTranslation, space?.type),
    }));
  }
  return spacesArr.map((space, index) => ({
    ...space,
    name: `${getObjectLabel(inspectionReportGeneralSpaceTranslation, space?.type)} ${index + 1}`,
  }));
};

const parseSpaces = (spaces: InspectionReportSpace[]) => {
  const livingRooms = setSpaceNames(groupSpaces("living_room", spaces));
  const diningRooms = setSpaceNames(groupSpaces("dining_room", spaces));
  const rooms = setSpaceNames(groupSpaces("room", spaces));
  const bathrooms = setSpaceNames(groupSpaces("bathroom", spaces));
  const kitchens = setSpaceNames(groupSpaces("kitchen", spaces));
  const laundryRooms = setSpaceNames(groupSpaces("laundry_room", spaces));
  const terraces = setSpaceNames(groupSpaces("terrace", spaces));
  const others = setSpaceNames(groupSpaces("other", spaces));
  return [
    ...livingRooms,
    ...diningRooms,
    ...rooms,
    ...bathrooms,
    ...kitchens,
    ...laundryRooms,
    ...terraces,
    ...others,
  ];
};

const groupByFeatureNature = (space: InspectionReportSpace): GroupedInspectionReportSpace => {
  const spaceEntries = Object.entries(space);

  const spaceFeaturesEntries: [string, InspectionReportFeature][] = spaceEntries.filter(
    (spaceEntry) =>
      spaceEntry[1]?.quantity === undefined && spaceEntry[1]?.qualification !== undefined
  );

  const spaceItemsEntries: [string, InspectionReportItem][] = spaceEntries.filter(
    (spaceEntry) => spaceEntry[1]?.quantity !== undefined
  );

  const spaceFeatures = spaceFeaturesEntries.map((featureEntry) => ({
    name: featureEntry[0] as InspectionReportFeaturesNames,
    ...featureEntry[1],
  }));

  const spaceItems = spaceItemsEntries.map((itemEntry) => ({
    name: itemEntry[0] as InspectionReportItemsNames,
    ...itemEntry[1],
  }));

  return {
    id: space?.id,
    name: space?.name,
    photo: space?.photo_link,
    type: space?.type,
    qualification: space?.overall_qualification,
    observation: space?.overall_observation,
    features: spaceFeatures,
    items: spaceItems,
  };
};
