import { makeStyles, Theme } from "@material-ui/core";

const PersonCardStyles = makeStyles<Theme>((theme) => ({
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexShrink: 0,
    width: "fit-content",
    gap: theme.spacing(1),
  },
  cardDocument: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0),
    borderRadius: 15,
    boxShadow: theme.shadows[3],
    justifyContent: "space-between",
  },
  personName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 700,
  },
  personType: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: "#A6B4BB",
  },
  flagStatus: {
    borderRadius: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.grey[300],
    width: "fit-content",
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(18),
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    display: "flex",
  },
  flagStatusIcon: {
    width: 12,
    marginRight: theme.spacing(1),
  },
  flagStatusValidated: {
    fontWeight: 700,
    backgroundColor: "#C1E4A8",
    color: "#26810A",
  },
}));
export default PersonCardStyles;
