import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  error: boolean;
  bordered: boolean;
  warning?: boolean;
  labelPlacement?: "top" | "bottom" | "end" | "start";
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    margin: "0px",
    width: "100%",
    padding: theme.spacing(0.3),
    display: "flex",
    alignItems: "center",
    flexDirection: ({ labelPlacement }) => {
      switch (labelPlacement) {
        case "top":
          return "column";
        case "bottom":
          return "column-reverse";
        case "start":
          return "row";
        case "end":
          return "row-reverse";
        default:
          return "column";
      }
    },
  },
  label: {
    position: "relative",
    transform: "none",
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  numberInput: {
    width: "100%",
    padding: theme.spacing(0.5),
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid",
    borderColor: ({ bordered }) => (bordered ? theme.palette.grey[300] : "transparent"),
    borderRadius: ({ bordered }) => (bordered ? 10 : 0),
    "&::placeholder": {
      color: theme.palette.grey[100],
    },
  },
  stateHelperText: {
    marginLeft: theme.spacing(0.5),
    color: ({ warning, error }) =>
      error
        ? theme.palette.error.main
        : warning
        ? theme.palette.warning.main
        : theme.palette.grey[600],
  },
}));
