import currency from "@houm-com/formats/currency";
import Spinner from "@houm-com/ui/Spinner";
import Tag from "@houm-com/ui/Tag";

import usePropertyPriceRequired from "domains/evaluations/hooks/usePropertyPriceRequired";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { classes } from "./SalarySummaryCardStyles";
import { Currency } from "models/Countries";

interface Props {
  currencyCode: Currency;
  amount: number;
  propertyId: number;
}

const SalarySummaryCard = ({ currencyCode, amount, propertyId }: Props) => {
  const { isLoading, getPropertyPrice, isError, isSuccess } = usePropertyPriceRequired({
    propertyId,
  });

  const getIsApproved = () => amount >= getPropertyPrice();

  return (
    <AdminCard
      className={classes.salaryCardContainer}
      title="Suma de rentas"
      showTitleBackground={true}
      withSpace={false}>
      {isLoading && <Spinner classNameContainer={classes.spinner} variant="primary" size="2xl" />}
      {isError && <p className={classes.normalText}>Error al obtener precio de propiedad</p>}
      {isSuccess && (
        <div className={classes.salaryDetailsContainer}>
          <span className={classes.salaryTotalContainer}>
            <p className={classes.boldText}>Total:</p>
            <p className={classes.normalText}>
              {currency(amount, currencyCode, {
                maximumFractionDigits: 0,
              })}
            </p>
          </span>
          {amount !== 0 && (
            <Tag
              label={getIsApproved() ? "Aprobado" : "Desaprobado"}
              variant={getIsApproved() ? "success" : "error"}
            />
          )}
        </div>
      )}
    </AdminCard>
  );
};

export default SalarySummaryCard;
