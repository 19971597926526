import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { format } from "rut.js";
import Swal from "sweetalert2";

import {
  ControlledList,
  ControlledSwitch,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { evaluateFormSchema, requiredText } from "./validationEvaluationSchema";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { EvaluationProps, getAverageSalary, parseData } from "./utilsEvaluateForm";
import newEvaluationService from "services/newEvaluationService";
import { IDocumentTypeList, IPrevData } from "./EvaluateType";
import salaryLocalization from "utils/salaryLocalization";
import { useFormNotifications, useGetToken } from "hooks";
import { localeToCountry } from "utils/countryCode";
import { Typography } from "@material-ui/core";
import userService from "services/userService";
import useStyles from "./EvaluateStyles";
import { Locale } from "models/Countries";

interface Props {
  evaluation: {
    id: number;
    user: {
      name: string;
      last_name: string;
      role: string;
      email: string;
      id: number;
      rut: string;
      second_last_name: string;
      country: string;
      document: string;
    };
    documents: any;
  };
  documentTypeList: IDocumentTypeList[];
  prevData: IPrevData;
}

function EvaluateV2({ evaluation, documentTypeList, prevData }: Props) {
  const { getToken } = useGetToken();
  const params = useParams<{ country: Locale }>();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<EvaluationProps>({
    resolver: yupResolver(evaluateFormSchema(localeToCountry[params.country])),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<EvaluationProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<EvaluationProps> = (dataForm) => {
    (async () => {
      setLoading(true);
      try {
        const formData = parseData(prevData, dataForm, params.country);
        const authToken = await getToken();
        const data = await newEvaluationService.getEvaluationDetails(
          evaluation.id,
          authToken,
          params.country
        );
        const evaluationEndpoint =
          data && data.data
            ? newEvaluationService.updateEvaluationDetails
            : newEvaluationService.createEvaluationDetails;

        const res = await evaluationEndpoint(evaluation.id, formData, authToken, params.country);
        if (res) {
          try {
            await userService.update(
              evaluation.user.id,
              {
                name: dataForm.name,
                last_name: dataForm.lastName,
                second_last_name: dataForm.secondLastName,
                rut: params.country === "mx" ? dataForm.rfcNumber : format(formData.document),
                document: params.country === "mx" ? dataForm.rfcNumber : format(formData.document),
                document_type: params.country === "mx" ? 9 : 1,
                role: evaluation.user.role,
                email: evaluation.user.email,
              },
              authToken
            );
            Swal.fire({
              type: "success",
              text: "Datos guardados correctamente.",
            }).then(() => window.location.reload());
          } catch (e) {
            console.error(e);
            Swal.fire({
              type: "warning",
              text: "Datos de evaluación guardados correctamente, pero no se han podido actualizar los datos de usuario. Por favor confirma que todo esté correcto antes de pasar a contratos por aprobar.",
            }).then(() => window.location.reload());
          }
        } else {
          Swal.fire({
            type: "error",
            text: "Hubo un error, intenta nuevamente.",
          });
        }
      } catch (e) {
        Swal.fire({
          type: "error",
          text: "No se pudo realizar evaluación, contactarse con soporte",
        });
      } finally {
        setLoading(false);
      }
    })();
  };

  const [salary1, salary2, salary3, salaryOthers] = methods.watch([
    "salary1",
    "salary2",
    "salary3",
    "salaryOthers",
  ]);

  useEffect(() => {
    methods.setValue("salaryAvg", getAverageSalary(salary1, salary2, salary3, salaryOthers));
  }, [salary1, salary2, salary3, salaryOthers]);

  useEffect(() => {
    methods.setValue(
      "salaryAvg",
      getAverageSalary(
        prevData?.salary_1,
        prevData?.salary_2,
        prevData?.salary_3,
        prevData?.salary_others
      )
    );
  }, [prevData?.salary_1, prevData?.salary_2, prevData?.salary_3, prevData?.salary_others]);

  const documentParse =
    documentTypeList &&
    documentTypeList.find(
      (type) => type.label === prevData.documentType || type.id === prevData.documentType
    );

  const inputProps = {
    InputProps: {
      classes: {
        root: classes.textInput,
      },
    },
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography variant="h4" className={classes.sectionTitle2}>
          Codeudor
        </Typography>
        <ControlledTextField
          label="Nombre"
          id="name"
          textFieldProps={{ placeholder: evaluation.user.name, ...inputProps }}
          defaultValue={prevData?.name ?? ""}
          bordered
        />
        <ControlledTextField
          label="Apellido"
          id="lastName"
          textFieldProps={{
            placeholder: evaluation.user.last_name,
            ...inputProps,
          }}
          bordered
          defaultValue={prevData?.last_name ? prevData.last_name : ""}
        />
        <ControlledTextField
          label="Segundo Apellido"
          id="secondLastName"
          textFieldProps={{
            placeholder: evaluation.user.second_last_name,
            ...inputProps,
          }}
          defaultValue={prevData?.second_last_name ? prevData.second_last_name : ""}
          bordered
        />
        {params.country === "cl" ? (
          <>
            <ControlledTextField
              label="RUT"
              id="document"
              textFieldProps={{
                placeholder: evaluation.user.rut,
                ...inputProps,
              }}
              defaultValue={prevData?.document ? prevData.document : ""}
              bordered
            />
            <ControlledSwitch
              label="Verificación AVLA"
              id="avla"
              defaultValue={!!prevData?.avla}
              wrapperClassName={classes.switchWrapper}
            />
          </>
        ) : (
          <>
            <ControlledTextField
              label="RFC"
              id="rfcNumber"
              textFieldProps={{
                placeholder: prevData?.rfc_number ? prevData.rfc_number : evaluation.user.document,
                ...inputProps,
              }}
              defaultValue={prevData?.rfc_number ? prevData.rfc_number : evaluation.user.document}
              bordered
            />
            <ControlledList
              disableUnderline
              label="Tipo de documento"
              id="documentType"
              defaultValue={prevData?.documentType ? documentParse?.id : ""}
              options={documentTypeList}
            />
            <ControlledTextField
              label="Documento de identidad"
              id="document"
              textFieldProps={{
                placeholder: prevData?.document ? prevData.document : "",
              }}
              defaultValue={prevData?.document ? prevData.document : ""}
              bordered
            />
            <ControlledTextField
              label="Score buró"
              id="scoreBuro"
              textFieldProps={{
                type: "number",
                placeholder: prevData?.scoreBuro ? prevData.scoreBuro.toString() : "",
                ...inputProps,
              }}
              defaultValue={prevData?.scoreBuro ? prevData.scoreBuro : ""}
              bordered
            />
          </>
        )}
        <ControlledSwitch
          label="Juicios"
          id="trials"
          defaultValue={!!prevData?.trials}
          wrapperClassName={classes.switchWrapper}
        />
        {methods.watch("trials") === true && (
          <ControlledTextField
            label="Comentario de juicios"
            id="trialsComment"
            textFieldProps={{
              multiline: true,
              rows: 4,
              placeholder: "Acerca de los juicios..",
              ...inputProps,
            }}
            defaultValue={prevData?.trials_comment ? prevData.trials_comment : ""}
            bordered
          />
        )}
        <ControlledTextField
          label="Ocupación"
          id="occupation"
          textFieldProps={{
            placeholder: prevData?.occupation ? prevData.occupation : "",
            ...inputProps,
          }}
          defaultValue={prevData?.occupation ? prevData.occupation : ""}
          bordered
        />
        <ControlledTextField
          label="Nacionalidad"
          id="nationality"
          textFieldProps={{
            placeholder: prevData?.nationality ? prevData.nationality : "",
            ...inputProps,
          }}
          defaultValue={prevData?.nationality ? prevData.nationality : ""}
          bordered
        />
        {params.country === "cl" && (
          <>
            <ControlledTextField
              label="Visa"
              id="visa"
              textFieldProps={{
                placeholder: prevData?.visa ? prevData.visa : "",
                ...inputProps,
              }}
              defaultValue={prevData?.visa ? prevData.visa : ""}
              bordered
            />
            <Typography variant="h4" className={classes.sectionTitle2}>
              Dirección
            </Typography>
            <ControlledTextField
              label="Ciudad"
              id="city"
              textFieldProps={{
                placeholder: "Ingresar",
                ...inputProps,
              }}
              defaultValue={prevData?.address?.city ?? ""}
              bordered
            />
            <ControlledTextField
              label="Calle"
              id="street"
              textFieldProps={{
                placeholder: "Ingresar",
                ...inputProps,
              }}
              defaultValue={prevData?.address?.street ?? ""}
              bordered
            />
          </>
        )}
        <Typography variant="h4" className={classes.sectionTitle2}>
          Trabajo
        </Typography>
        <ControlledTextField
          label="Empleador"
          id="employer"
          textFieldProps={{
            placeholder: "Ingresar",
            ...inputProps,
          }}
          defaultValue={prevData?.employer ? prevData.employer : ""}
          bordered
        />
        <ControlledTextField
          label="Cargo"
          id="position"
          defaultValue={prevData?.position ? prevData.position : ""}
          textFieldProps={{
            placeholder: "Ingresar",
            ...inputProps,
          }}
          bordered
        />
        <ControlledTextField
          label="Tipo de contrato"
          id="contractType"
          defaultValue={prevData?.contract_type ? prevData.contract_type : ""}
          textFieldProps={{
            placeholder: "Ingresar",
            ...inputProps,
          }}
          bordered
        />

        <Typography variant="h4" className={classes.sectionTitle2}>
          Liquidaciones
        </Typography>
        <ControlledTextField
          label={`Meses de ${salaryLocalization[params.country].salary}`}
          id="salaryMonths"
          textFieldProps={{ placeholder: "ej: Jun a Ago", ...inputProps }}
          defaultValue={prevData?.salary_months ? prevData.salary_months : ""}
          bordered
        />
        <ControlledTextField
          label={`${salaryLocalization[params.country].salaries} (1):`}
          id="salary1"
          defaultValue={prevData?.salary_1 ?? ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />
        <ControlledTextField
          label={`${salaryLocalization[params.country].salaries} (2):`}
          id="salary2"
          defaultValue={prevData?.salary_2 ?? ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />
        <ControlledTextField
          label={`${salaryLocalization[params.country].salaries} (3):`}
          id="salary3"
          defaultValue={prevData?.salary_3 ?? ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />
        <ControlledTextField
          label={`${salaryLocalization[params.country].salaries} (Otros):`}
          id="salaryOthers"
          defaultValue={prevData?.salary_others ?? ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />
        <ControlledTextField
          label={`Descripción ${salaryLocalization[params.country].salaries} (Otros):`}
          id="salaryOthersDescription"
          defaultValue={
            prevData?.salary_others_description ? prevData.salary_others_description : ""
          }
          textFieldProps={{ multiline: true, rows: 4, ...inputProps }}
          bordered
        />
        <ControlledTextField
          label={`${salaryLocalization[params.country].salaries} Promedio:`}
          id="salaryAvg"
          defaultValue={prevData?.salary_avg ?? ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />

        <Typography variant="h4" className={classes.sectionTitle2}>
          Resumen
        </Typography>
        <ControlledSwitch
          label="¿Dependiente?"
          id="dependent"
          defaultValue={prevData?.dependent ? prevData.dependent : false}
          wrapperClassName={classes.switchWrapper}
        />
        <ControlledTextField
          label={`Renta ${params.country === "cl" ? "Líquida" : ""} Confirmada:`}
          id="confirmedSalary"
          defaultValue={prevData?.confirmed_salary ? prevData.confirmed_salary : ""}
          textFieldProps={{ type: "number", ...inputProps }}
          bordered
        />
        <ControlledTextField
          label="Comentario"
          id="comment"
          defaultValue={prevData?.comment ? prevData.comment : ""}
          textFieldProps={{ multiline: true, rows: 4, ...inputProps }}
          bordered
        />
        <div className={classes.containerButton}>
          <CustomButton type="submit" kind="secondary" active className={classes.button}>
            <Loading loading={loading} size={16}>
              <span>Guardar</span>
            </Loading>
          </CustomButton>
        </div>
      </form>
    </FormProvider>
  );
}

export default EvaluateV2;
