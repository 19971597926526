import { ReactNode } from "react";

import classes from "../../PromotionDetailsStyles";

type DetailsItemProps = {
  title: String;
  children: ReactNode;
};

export default function DetailsItem({ title, children }: DetailsItemProps) {
  return (
    <div className={classes.item}>
      <h6 className={classes.itemText}>{title}</h6>
      {children}
    </div>
  );
}
