import ModalDialog from "@houm-com/ui/ModalDialog";
import { IPromotion } from "models/Promotions";
import EditPromotionForm from "./components/EditPromotionForm";

type EditPromotionModalProps = {
  promotion: IPromotion;
  onHandleClose: () => void;
};

export default function EditPromotionModal({ promotion, onHandleClose }: EditPromotionModalProps) {
  return (
    <ModalDialog
      title="Selecciona la nueva duración"
      subtitle="Escribe de qué día a qué día estará disponible esta promoción para ser aplicada"
      isOpen={!!promotion}
      onClose={onHandleClose}
      hasCloseBtn>
      {promotion && <EditPromotionForm promotion={promotion} handleCloseModal={onHandleClose} />}
    </ModalDialog>
  );
}
