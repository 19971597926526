import { makeStyles, Theme } from "@material-ui/core";

const useCitiesApplyStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
}));

export default useCitiesApplyStyles;
