import { Dispatch, SetStateAction } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { InputAdornment } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import InspectionReportTableRow from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/InspectionReportTableRow";
import TableRowImagePreviewer from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/components/TableRowImagePreviewer";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import { useProperty } from "context/propertyContext/propertyContext";
import { updatePropertyInspectionReport } from "context/propertyContext/propertyActions";
import { inspectionReportQualificationOptions } from "Components/Admin/administrator/properties/show/tabs/inspectionReports/shared/utils/translations";
import {
  ControlledSelection,
  ControlledTextArea,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { InspectionReportGeneralSpaceUpdateDataForm } from "services/propertyService/types";
import validationSchema from "./validationSchema";
import { SpaceSummary } from "models/InspectionReport";
import { useGetToken } from "hooks";
import { parseInspectionReportGeneralSpaceUpdateDataForm } from "./utils";
import useStyles from "./GeneralSpaceEditRowStyles";

interface Props {
  space: SpaceSummary;
  setCurrentSpaceEditingId: Dispatch<SetStateAction<number>>;
}

function GeneralSpaceEditRow({ space, setCurrentSpaceEditingId }: Props) {
  const { state, dispatch } = useProperty();
  const { getToken } = useGetToken();
  const classes = useStyles();

  const methods = useForm<InspectionReportGeneralSpaceUpdateDataForm>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: InspectionReportGeneralSpaceUpdateDataForm): Promise<void> => {
    try {
      const sendData = parseInspectionReportGeneralSpaceUpdateDataForm(space.id, data);
      const authToken = await getToken();
      await updatePropertyInspectionReport(
        state.inspectionReport.data.id,
        state.property.id,
        sendData,
        dispatch,
        authToken
      );
    } catch (err) {
      console.error(err);
    } finally {
      setCurrentSpaceEditingId(null);
    }
  };

  return (
    <FormProvider {...methods} key="GeneralSpaceRow">
      <form key="GeneralSpaceRow" onSubmit={methods.handleSubmit(onSubmit)}>
        <InspectionReportTableRow
          classes={{
            root: classes.rowRoot,
          }}
          isEdit={true}>
          <ControlledTextField
            label=""
            id="name"
            textFieldProps={{
              InputProps: {
                classes: {
                  input: classes.bold,
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <Edit className={classes.editIcon} />
                  </InputAdornment>
                ),
              },
            }}
            disabled
            defaultValue={space.name}
          />
          <ControlledSelection
            id="overall_qualification"
            label=""
            defaultValue={space.overallQualification}
            options={inspectionReportQualificationOptions}
            simple
            classes={{
              container: classes.container,
              label: classes.label,
              buttonList: classes.buttonList,
            }}
          />
          <ControlledTextArea
            id="overall_observation"
            label=""
            defaultValue={space.overallObservation ?? ""}
            maxRows={2}
          />
          <TableRowImagePreviewer rowName={space.name} imagesArr={[space?.photo]} />
          <div>
            <LinkButton type="submit">Guardar</LinkButton>
            <LinkButton onClick={() => setCurrentSpaceEditingId(null)}>Cancelar</LinkButton>
          </div>
        </InspectionReportTableRow>
      </form>
    </FormProvider>
  );
}

export default GeneralSpaceEditRow;
