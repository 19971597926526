import AddressSuggesterWithGoogle, {
  Props as WithGoogleProps,
} from "./AddressSuggesterWithGoogleMaps";

import AddressSuggesterV2, { Props as SuggesterV2Props } from "./AddressSuggester";
import { useFeatureManager } from "@houm-com/feature-manager";
import { countryCode as getCountryCode } from "utils";

// TODO: this feature flag control can be removed after the API /locations/address/ is establed and we remove google suggestions

export default function AddressSuggester({ ...props }: WithGoogleProps | SuggesterV2Props) {
  const { locale } = props;
  const countryCode = getCountryCode(locale);
  const { status, data } = useFeatureManager("address-suggester-v2", { marketCode: countryCode });

  if (status === "loading") return null;
  if (status === "error") return <AddressSuggesterWithGoogle {...props} locale={locale} />;
  if (data?.enabled)
    return <AddressSuggesterV2 {...(props as SuggesterV2Props)} countryCode={countryCode} />;
  return <AddressSuggesterWithGoogle {...props} locale={locale} />;
}
