import { makeStyles, Theme } from "@material-ui/core";

const useControlledSwitchSelectorStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    margin: 0,
    width: "100%",
    padding: theme.spacing(0.3),
    "& .MuiFormHelperText-root": {
      color: "red",
    },
  },
  label: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "0.875rem",
    marginBottom: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
}));

export default useControlledSwitchSelectorStyles;
