import { ContractList } from "./contractTypes";
import { ITabOption } from "context/tabContext/tabTypes";

export function parseContractList(contractData: any): ContractList {
  return {
    id: contractData.id,
    mainTenantName: contractData.main_tenant?.name,
    ownerName: contractData.owner
      ? contractData.owner.name
      : `${contractData.property.user?.name} ${contractData.property.user?.last_name}`,
    houmOwnerName: contractData.property.houm_owner
      ? `${contractData.property.houm_owner.name} ${contractData.property.houm_owner.last_name}`
      : "",
    propertyId: contractData.property?.id,
    propertyUid: contractData.property?.uid,
    propertyAddress: contractData.property?.address,
    propertyStreet: contractData.property?.street_number,
    propertyNumberDpto: contractData.property?.numero_depto,
    createdAt: contractData?.created_at,
  };
}

export enum ContractStatuses {
  WAITING_FOR_APPROVAL = "Aprobacion propietario,Perfil enviado,Perfil aprobado",
  APPROVED = "Aprobado por propietario",
  REJECTED = "Rechazado,Desistido",
  READY_TO_GENERATE = "Listo para redaccion",
  PAYMENT_BUTTON = "Generar boton de pago",
}

export const tabOptions: ITabOption[] = [
  {
    label: "Por aprobar",
    value: ContractStatuses.WAITING_FOR_APPROVAL,
  },
  { label: "Aprobados", value: ContractStatuses.APPROVED },
  {
    label: "Listo para generar",
    value: ContractStatuses.READY_TO_GENERATE,
  },
  { label: "Botón de pago", value: ContractStatuses.PAYMENT_BUTTON },
  { label: "Rechazados", value: ContractStatuses.REJECTED },
];

export const tabsIndexs = [
  ContractStatuses.WAITING_FOR_APPROVAL,
  ContractStatuses.APPROVED,
  ContractStatuses.REJECTED,
  ContractStatuses.READY_TO_GENERATE,
  ContractStatuses.PAYMENT_BUTTON,
];
