import { makeStyles, Theme } from "@material-ui/core/styles";

const useMarketplaceTagStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    margin: theme.spacing(0, 1),
  },
  filtersContainer: {
    margin: theme.spacing(2, 1, 0, 1),
  },
}));

export default useMarketplaceTagStyles;
