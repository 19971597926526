import { makeStyles } from "@material-ui/core";

const useSelectedPropertyStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  cardContainer: {
    width: 250,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    margin: theme.spacing(2, 0),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));
export default useSelectedPropertyStyles;
