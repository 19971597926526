export const purchaseType = [
  { label: "Inversión", value: "Inversión" },
  { label: "Vivienda", value: "Vivienda" },
];

export const temporality = [
  { label: "Menos 1 mes", value: "Menos 1 mes" },
  { label: "2-3 meses", value: "2-3 meses" },
  { label: "Más de 3 meses", value: "Mas de 3 meses" },
];

export const financing = [
  { label: "Contado", value: "Contado" },
  { label: "Crédito Aprobado", value: "Crédito Aprobado" },
  { label: "Credito Preaprobado", value: "Credito Preaprobado" },
  { label: "Sin Financiamiento", value: "Sin Financiamiento" },
  {
    label: "Fovisste (solo aplica para mx)",
    value: "Fovisste (solo aplica para mx)",
  },
  {
    label: "Infonavit (solo aplica para mx)",
    value: "Infonavit (solo aplica para mx)",
  },
  { label: "subsidio habitacional", value: "subsidio habitacional" },
];

export const type = [
  { label: "Departamento", value: "Departamento" },
  { label: "Casa", value: "Casa" },
  { label: "Ambos", value: "Ambos" },
];

export const ocupationType = [
  {
    label: "Dependiente contrato plazo fijo",
    value: "Dependiente contrato plazo fijo",
  },
  {
    label: "Dependiente contrato indefinido",
    value: "Dependiente contrato indefinido",
  },
  { label: "Independiente", value: "Independiente" },
];

export const rateType = [
  { label: "Fija", value: "Fija" },
  { label: "Variable", value: "Variable" },
];

export const propertyStatus = [
  { label: "Nueva", value: "Nueva" },
  { label: "Usada", value: "Usada" },
  { label: "Ambas", value: "Ambas" },
];
