import {
  State,
  Action,
  SET_CONTRACT_ID,
  SET_COUNTRY,
  SET_DOCUMENT_TYPES,
  SET_FETCHING_PERSON,
  SET_IS_NEW_PERSON,
  SET_PERSON,
  SET_PROPERTY_ID,
  SET_REDIRECTION_URL,
  SET_SEARCH_PARAMS,
  SET_TITLE,
} from "./contractPersonTypes";

const NON_EXISTENT_PROPERTY_ID = -1;

export const initialState: State = {
  contractId: null,
  country: "",
  documentTypes: [],
  fetchingPerson: false,
  isNewPerson: false,
  person: { ready: false },
  propertyId: NON_EXISTENT_PROPERTY_ID,
  redirectionUrl: "",
  searchParams: null,
  title: "",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_CONTRACT_ID:
      return { ...state, contractId: action.contractId };
    case SET_COUNTRY:
      return { ...state, country: action.country };
    case SET_DOCUMENT_TYPES:
      return { ...state, documentTypes: action.documentTypes };
    case SET_FETCHING_PERSON:
      return { ...state, fetchingPerson: action.fetchingPerson };
    case SET_IS_NEW_PERSON:
      return { ...state, isNewPerson: action.isNewPerson };
    case SET_PERSON:
      return { ...state, person: action.person };
    case SET_PROPERTY_ID:
      return { ...state, propertyId: action.propertyId };
    case SET_REDIRECTION_URL:
      return { ...state, redirectionUrl: action.redirectionUrl };
    case SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.searchParams };
    case SET_TITLE:
      return { ...state, title: action.title };
    default:
      return state;
  }
};

export default apiReducer;
