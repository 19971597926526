import { makeStyles, Theme } from "@material-ui/core";

const useScheduleRangeStyles = makeStyles((theme: Theme) => ({
  rangeContainer: {
    marginTop: theme.spacing(1),
  },
  columnWeek: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  rowWeek: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));

export default useScheduleRangeStyles;
