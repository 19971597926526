import moment from "moment";
import { capitalizeString } from "utils";
import { GridCellValue } from "@material-ui/x-grid";

export const emptyString = "-----------";

export const formatedDate = (value) => {
  if (!value) return emptyString;
  return capitalizeString(moment(value).format("MMM DD HH:mm"));
};

export const empytCell = (value: GridCellValue) => {
  if (!value) return emptyString;
  return value;
};
