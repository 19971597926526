import { useQueryOrgMembers, useQueryOrgProperties } from "../queries/organizationsQueries";
import { useParams } from "react-router-dom";
import { Tab, Tabs } from "./tabs";
import Button from "@houm-com/ui/Button";
import { IoAdd } from "@houm-com/ui-icons";

export default function OrganizationView() {
  const { id } = useParams<{ id: string }>();
  const { data: members } = useQueryOrgMembers(id);
  const { data: properties } = useQueryOrgProperties(id);

  return (
    <>
      <h3 className="text-secondary-cta text-h28 mb-5">Organizacion</h3>
      <div className="relative">
        <Tabs>
          <Tab value="properties" title="Propiedades">
            <Button variant="primary" size="lg" className="absolute top-0 right-0">
              Agregar propiedad
              <IoAdd />
            </Button>
            <div className="p-5 rounded-md bg-white">
              <ul className="space-y-5">
                {properties.results?.map((prop) => (
                  <li key={prop.id}>{prop.name}</li>
                ))}
              </ul>
            </div>
          </Tab>
          <Tab value="members" title="Members">
            <Button variant="primary" size="lg" className="absolute top-0 right-0">
              Agregar miembro
              <IoAdd />
            </Button>
            <div className="p-5 rounded-md bg-white">
              <ul className="space-y-5">
                {members?.results.map((member) => (
                  <li key={member.id}>
                    {member.name} {member.last_name}
                  </li>
                ))}
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
