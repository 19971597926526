import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const usePropertyCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      boxShadow: "none",
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      minWidth: 293,
      height: "auto",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    textLabel: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      marginBottom: theme.spacing(1.4),
    },
    divider: {
      width: "95%",
    },
    text: {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
    },
    linkColor: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);

export default usePropertyCardStyles;
