import { useCallback, useState } from "react";

const useModal = () => {
  const [openStartProcessModal, setOpenStartProcessModal] = useState(false);
  const [openConfirmActionModal, setOpenConfirmActionModal] = useState(false);
  const [actionText, setActionText] = useState("Cancelar");
  const [openEditDateModal, setOpenEditDateModal] = useState(false);

  const handleStartProcessModal = useCallback(() => {
    setOpenStartProcessModal((prevCheck) => !prevCheck);
  }, [openStartProcessModal]);

  const handleOpenConfirmActionModal = useCallback(
    (text: string) => {
      setActionText(text);
      setOpenConfirmActionModal(true);
    },
    [openConfirmActionModal, actionText]
  );

  const handleOpenEditDateModal = useCallback(() => {
    setOpenEditDateModal((prevCheck) => !prevCheck);
  }, [openEditDateModal]);

  const handleCloseConfirmModal = useCallback(() => {
    setOpenConfirmActionModal(false);
  }, []);

  return {
    openStartProcessModal,
    handleStartProcessModal,
    openConfirmActionModal,
    handleCloseConfirmModal,
    handleOpenConfirmActionModal,
    actionText,
    openEditDateModal,
    handleOpenEditDateModal,
  };
};

export default useModal;
