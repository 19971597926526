import { CountryUpperCase } from "models/Countries";

export enum ActionType {
  SET_DEAL,
  SET_PROFILED,
  SET_COUNTRY,
  SET_LEADS,
  SET_IS_OPEN_FILTER_MODAL,
  SET_FILTERS,
  SET_LOADING,
  SET_LQ,
  SET_STATES,
  SET_ORIGINS,
  SET_TOTAL_LEADS,
  SET_SELECTED_LEADS,
  SET_IS_OPEN_SELECTED_MODAL,
}

export interface Filters {
  leadQualifier?: { label: string };
  origin?: { label: string };
  state?: { label: string }[];
  date?: { label: string; value: string };
  following_date_from?: string;
}

export type Country = "chile" | "colombia" | "mexico";

export interface Leads {
  id: number;
  create_at: string;
  name: string;
  email: string;
  phone: string;
  lq: string;
  state: string;
  track: string;
}

export interface SelectedLeads {
  [key: number]: number[]; //Key is the current page ([1,2,3,4 , ...n])
}

export interface SetCountry {
  type: typeof ActionType.SET_COUNTRY;
  country: CountryUpperCase;
}

export interface SetDeal {
  type: typeof ActionType.SET_DEAL;
  deal: "arriendo" | "venta" | "";
}

export interface SetProfiled {
  type: typeof ActionType.SET_PROFILED;
  profiled: boolean;
}

export interface SetLeads {
  type: typeof ActionType.SET_LEADS;
  leads: Leads[];
}

export interface SetSelectedLeads {
  type: typeof ActionType.SET_SELECTED_LEADS;
  selectedLeads: SelectedLeads;
}

export interface SetIsOpenSelectedModal {
  type: typeof ActionType.SET_IS_OPEN_SELECTED_MODAL;
  isOpenSelectedModal: boolean;
}

export interface SetIsOpenModal {
  type: typeof ActionType.SET_IS_OPEN_FILTER_MODAL;
  isOpenFilterModal: boolean;
}

export interface SetFilters {
  type: typeof ActionType.SET_FILTERS;
  filters: Filters;
}

export interface SetIsLoading {
  type: typeof ActionType.SET_LOADING;
  isLoading: boolean;
}
export interface SetTotalLeads {
  type: typeof ActionType.SET_TOTAL_LEADS;
  totalLeads: number;
}

export type Action =
  | SetTotalLeads
  | SetIsLoading
  | SetCountry
  | SetLeads
  | SetIsOpenModal
  | SetFilters
  | SetDeal
  | SetSelectedLeads
  | SetIsOpenSelectedModal
  | SetProfiled;

export interface State {
  deal: "arriendo" | "venta" | "";
  profiled: boolean;
  country: CountryUpperCase | "";
  leads: Leads[];
  isOpenFilterModal: boolean;
  filters: Filters;
  isLoading: boolean;
  totalLeads: number;
  selectedLeads: SelectedLeads;
  isOpenSelectedModal: boolean;
}

export type Dispatch = (action: Action) => void;
