import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import InnerImageZoom from "react-inner-image-zoom";
import clx from "classnames";

import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import useDocumentsViewStyles from "./DocumentsViewStyles";
import DocumentViewer from "./DocumentViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  documents: DocumentsProps[];
}

interface DocumentsProps {
  content_type: string;
  evaluation_id: number;
  filename: string;
  id: number;
  path: string;
}

const DocumentsView = ({ documents }: Props) => {
  const classes = useDocumentsViewStyles();
  const refSidePanel = useRef(null);
  const [sidePdfWidth, setSidePdfWidth] = useState(0);
  const [spotlight, setSpotlight] = useState<DocumentsProps | null>(null);
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    if (documents.length > 0) {
      setSpotlight(documents[0]);
      setEmpty(false);
    }
    setSidePdfWidth(refSidePanel.current ? refSidePanel.current.offsetWidth - 20 : 0);
  }, [documents]);

  const renderDocument = (doc: DocumentsProps, pdfWidth: number) =>
    doc.content_type === "application/pdf" ? (
      <Document
        file={doc.path}
        onPassword={() => {
          throw new Error("file with password");
        }}
        className={classes.selectPdf}
        error="No se pudo cargar este PDF">
        <Page
          renderTextLayer={false}
          renderAnnotationLayer={false}
          pageNumber={1}
          width={pdfWidth}
        />
      </Document>
    ) : (
      <InnerImageZoom
        src={doc.path}
        hideHint
        className={classes.selectPdf}
        srcSet={`${doc.path} 480w, ${doc.path} 800w`}
        sizes="(max-width: 100px) auto, 100px"
      />
    );

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={2} className={classes.sidePanel} ref={refSidePanel}>
          {documents.map((doc) => (
            <Grid item key={doc.id} className={classes.selectPdf} onClick={() => setSpotlight(doc)}>
              <Box
                p={1}
                className={clx(classes.docContainer, {
                  [classes.selected]: spotlight && spotlight.id === doc.id,
                })}>
                {renderDocument(doc, sidePdfWidth)}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={10} className={classes.item}>
          {empty ? (
            <Box display="flex" justifyContent="center">
              <Typography>No hay documentos</Typography>
            </Box>
          ) : (
            <DocumentViewer document={spotlight} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DocumentsView;
