import { Box, Divider, ButtonBase } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const RootStyled = styled(Box)(() => ({
  width: "100%",
  maxWidth: 820,
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1, 3, 3),
}));

export const ButtonBaseStyled = styled(ButtonBase)(({ theme }) => ({
  borderRadius: "50%",
  border: `1px solid #263238`,
}));

export const CalendarContainerStyled = styled(Box)(({ theme }) => ({
  // calendar toolbar styles
  "& .fc-header-toolbar": {
    display: "none !important",
  },
  // grid general styles
  "& .fc table": {
    border: "none !important",
    borderSpacing: "8px !important",
  },
  "& .fc-theme-standard td": {
    border: "none",
  },
  // grid header styles
  "& .fc-scrollgrid-section-header": {
    "& .fc-col-header": {
      height: 40,
      borderRadius: 16,
      backgroundColor: "#F7F8FC",
      "& .fc-col-header-cell": {
        border: "none",
        verticalAlign: "middle",
      },
      "& tr > .fc-col-header-cell:not(:last-child)": {
        borderRight: "1px solid #DFE5E8 !important",
      },
    },
  },
  // grid body styles
  "& .fc-scrollgrid-section-body": {
    "& .fc-daygrid-body": {
      "& tr": {
        borderRadius: 16,
        outlineOffset: -4,
        outline: "1px solid #DFE5E8",
        "& > td:not(:last-child)": {
          borderRight: "1px solid #DFE5E8 !important",
        },
        "& .fc-daygrid-day-frame": {
          padding: theme.spacing(0.5, 1),
        },
        "& .fc-day-today": {
          backgroundColor: "#F7F8FC",
        },
      },
    },
  },
}));
