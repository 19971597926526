import * as yup from "yup";
import {
  floatWithComma,
  notNumberToNull,
  intNumber,
  positiveIntNumber,
  positiveNumber,
} from "utils/yupValidators";
import { optionalPhoneObject } from "utils/yupValidators/phone";

import { CreatePropertyForm, IHomeCheckerDetails } from "models";
import Lazy from "yup/lib/Lazy";
import moment from "moment";

type Keys = keyof CreatePropertyForm;

type Yup = yup.AnySchema | Lazy<yup.AnySchema>;

type Validate = Partial<Record<Keys, Yup>>;

export const requiredText = "Obligatorio";
export const requiredUserText = "Hace falta seleccionar el usuario";
const invalidValue = "El valor ingresado no es válido";
const invalidGoogleRoute = "La dirección ingresada no es válida, (route/address not found)";
export const wrongNumber = "Número de teléfono inválido";
export const wrongEmail = "Ingrese un email válido";

const addressValidation = yup.lazy((value) => {
  if (!value || typeof value !== "object") return yup.string().required(requiredText);
  if (value?.parsed) return yup.object();
  return yup
    .object()
    .test("address_components", invalidGoogleRoute, (val) => {
      const components = val.address_components as {
        long_name: string;
        short_name: string;
        types: string[];
      }[];
      const routeComponent = components.find((component) => component.types.includes("route"));
      if (routeComponent) {
        return true;
      }
      return false;
    })
    .typeError("Seleccionar una dirección recomendada")
    .required(requiredText);
});

export const validate: Validate = {
  user: yup
    .object()
    .shape({
      id: yup.number().required(requiredUserText),
    })
    .required(requiredUserText)
    .typeError(requiredUserText),
  for_sale: yup.boolean(),
  for_rental: yup.boolean(),
  country: yup.string().required(requiredText).typeError(requiredText),
  address_object: addressValidation,
  street_number: yup.string().required(requiredText),
  type: yup.string().required(requiredText),
  numero_depto: yup.string().when(["type", "user"], {
    is: (type, user) => type === "casa" || user?.role === "real_estate_developer",
    then: yup.string().nullable(),
    otherwise: yup.string().required(requiredText),
  }),
  dormitorios: yup.number().typeError("Debes ingresar N° de dormitorios"),
  en_suite: yup.number().typeError("Debes ingresar N° de dormitorios en suite"),
  banos: yup.number().typeError("Debes ingresar N° de baños"),
  servicios: yup.number().typeError("Debes ingresar N° de piezas de servicio"),
  estacionamientos: yup.number().typeError("Debes ingresar N° de estacionamiento"),
  m_terreno: positiveNumber.typeError(requiredText).required(requiredText),
  m_construidos: positiveNumber.typeError(requiredText).required(requiredText),
  promotion_months: positiveIntNumber.nullable(),
  apartment_floor: intNumber.nullable(),
  terrace_size: positiveNumber.nullable(),
  loggia_size: positiveNumber.nullable(),
  gc: yup.number().when("is_common_expenses_included", {
    is: true,
    then: yup.number().nullable(),
    otherwise: positiveNumber.typeError(requiredText).required(requiredText),
  }),
  antiquity: yup.number().min(0).integer(),
  terraza: yup.number().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.number().required(requiredText).typeError(requiredText),
  }),
  bodega: yup.boolean().typeError(requiredText).required(requiredText),
  keys_info: yup.string().nullable(),
  kitchen_daily_dining_room: yup.boolean().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.boolean().required(requiredText).typeError(requiredText),
  }),
  mascotas: yup.boolean().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.boolean().required(requiredText).typeError(requiredText),
  }),
  has_private_roof_garden: yup.boolean().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.boolean().required(requiredText).typeError(requiredText),
  }),
  calefaccion: yup.boolean().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.boolean().required(requiredText).typeError(requiredText),
  }),
  curtain_hanger: yup.boolean().when("user", {
    is: (user) => user?.role !== "real_estate_developer",
    then: yup.boolean().required(requiredText).typeError(requiredText),
  }),
  floors: yup.number().integer().nullable().transform(notNumberToNull).typeError(invalidValue),
  guest_parking_spaces: yup
    .number()
    .integer()
    .nullable()
    .transform(notNumberToNull)
    .typeError(invalidValue),
  brokerage_commission: floatWithComma,
  lessor_brokerage_commission: floatWithComma,
  valor: yup.number().when("for_rental", {
    is: true,
    then: yup.number().min(0, invalidValue).transform(notNumberToNull).typeError(requiredText),
    otherwise: yup.number().nullable(),
  }),
  promotional_price: yup.number().when("for_rental", {
    is: true,
    then: positiveNumber
      .nullable()
      .lessThan(yup.ref("valor"), "Precio promocional debe ser menor al precio"),
    otherwise: yup.number().nullable(),
  }),
  valor_venta: yup.number().when("for_sale", {
    is: true,
    then: positiveNumber.typeError(requiredText).required(requiredText),
    otherwise: yup.number().nullable(),
  }),
  taxes: yup.number().when("for_sale", {
    is: true,
    then: positiveNumber.typeError(requiredText).required(requiredText),
    otherwise: yup.number().nullable(),
  }),
  is_new: yup
    .boolean()
    .nullable()
    .when(["user", "country"], {
      is: (user, country) => user?.role !== "real_estate_developer" && country === "Chile",
      then: yup.boolean().nullable().required(requiredText),
    }),
  year_built: positiveIntNumber
    .nullable()
    .min(1500, "El año ingresado no es válido.")
    .max(
      moment().year() + 10,
      "El año ingresado no es válido. No puede ser posterior a 10 años desde hoy"
    ),
  deal_id: yup
    .number()
    .integer()
    .positive()
    .nullable()
    .transform(notNumberToNull)
    .typeError("Solo se permiten números enteros"),
  custom_commune: yup.object().when(["street_name", "street_commune"], {
    is: (street_name, street_commune) => Boolean(street_name) && !street_commune,
    then: yup.object().required(requiredText),
  }),
  furnished: yup.string().required(requiredText),
  investment_type: yup
    .string()
    .typeError("Debes seleccionar un tipo de propiedad")
    .required(requiredText),
  has_water_tank: yup.boolean().nullable(),
  minisplits: positiveIntNumber.nullable(),
};

type homeCheckerDetailsKeys = keyof IHomeCheckerDetails;

export const homeCheckerDetailsValidationObject: Partial<Record<homeCheckerDetailsKeys, Yup>> = {
  administrationEmail: yup.string().email(wrongEmail).nullable(),
  administrationPhone: optionalPhoneObject.typeError(wrongNumber),
  buildingAdministration: yup.string().nullable(),
  concierge: yup.boolean().nullable(),
  conciergeEmail: yup.string().email(wrongEmail).nullable(),
  conciergeName: yup.string().nullable(),
  conciergePhone: optionalPhoneObject.typeError(wrongNumber),
  hasHoumBox: yup.boolean().nullable(),
  moveInPolicy: yup.string().nullable(),
  moveInPolicyPhoto: yup.string().nullable(),
  inhabitedProperty: yup.boolean().nullable(),
  inhabitedPropertyDescription: yup.string().nullable(),
  thirdPartyCoordination: yup.boolean().nullable(),
  thirdPartyCoordinationDescription: yup.string().nullable(),
  thirdPartyCoordinationPhone: optionalPhoneObject.typeError(wrongNumber),
};
