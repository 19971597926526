import { FormHelperText, FormHelperTextProps } from "@material-ui/core";
import { useHelperTextStyles } from "./HelperTextStyle";

function HelperText({ children, ...props }: FormHelperTextProps) {
  const classes = useHelperTextStyles();
  return (
    <FormHelperText
      classes={{
        root: classes.root,
      }}
      {...props}>
      {children}
    </FormHelperText>
  );
}
export default HelperText;
