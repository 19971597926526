import { useCallback, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { useTable } from "context/tableContext/tableContext";
import { TableFilters } from "context/tableContext/tableTypes";
import { setShowModal, setTableFilters } from "context/tableContext/tableActions";
import { useProject } from "context/projectContext/projectContext";
import FilterDialog from "Components/Admin/UIComponents/FilterDialog";
import ButtonSelect from "Components/Admin/UIComponents/Buttons/ButtonSelect";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { cities } from "Components/Admin/administrator/leads/leadProfile/components/LeadQualificationConsts";
import useStyles from "./FilterOptionsStyles";
import { IUserAutoComplete } from "models";
import { fromToOptions } from "utils/fromToOptions";
import { projectStatus } from "utils/propertyStatus";
import UserSuggester from "Components/Admin/UIComponents/UserSuggester";

function FilterOptions() {
  const [idFilter, setIdFilter] = useState<string>("");
  const [stratusFilter, setStratusFilter] = useState<string>("");
  const [landlordFilter, setLandlordFilter] = useState<string>("");
  const [houmOwnerFilter, setHoumOwnerFilter] = useState<IUserAutoComplete>();
  const [showHouseFilter, setShowHouseFilter] = useState<boolean>();
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [cityFilter, setCityFilter] = useState<string>("");
  const [communeFilter, setCommuneFilter] = useState<string>("");

  const { state } = useProject();
  const { state: tableState, dispatch } = useTable();

  const classes = useStyles();

  const handleFilterModal = () => dispatch(setShowModal(false));

  const toggleStatusFilter = (value: string) => {
    let tempStatusFilter = statusFilter.slice();
    const contains = tempStatusFilter.includes(value);
    if (!contains) {
      tempStatusFilter.push(value);
    } else {
      tempStatusFilter = tempStatusFilter.filter((item) => item !== value);
    }
    setStatusFilter(tempStatusFilter);
  };

  const handleSubmit = useCallback(() => {
    const filters: TableFilters = {
      ...tableState.tableFilters,
      ...(idFilter && { id: idFilter }),
      ...(houmOwnerFilter && { houm_owner: houmOwnerFilter.id.toString() }),
      ...(landlordFilter && { landlord: landlordFilter }),
      ...(statusFilter.length > 0 && {
        publication_status: statusFilter.join(),
      }),
      ...(cityFilter && { city: cityFilter }),
      ...(communeFilter && { comuna: communeFilter }),
      ...(stratusFilter && { stratus: stratusFilter }),
    };
    dispatch(setTableFilters(filters));
    dispatch(setShowModal(false));
  }, [
    dispatch,
    idFilter,
    landlordFilter,
    houmOwnerFilter,
    statusFilter,
    showHouseFilter,
    cityFilter,
    communeFilter,
    stratusFilter,
  ]);

  useEffect(() => {
    handleDelete();
  }, [state.country]);

  const handleDelete = () => {
    setCityFilter("");
    setShowHouseFilter(null);
    setHoumOwnerFilter(null);
    setLandlordFilter("");
    setStatusFilter([]);
    setIdFilter("");
    setCommuneFilter("");
    dispatch(setTableFilters({}));
    dispatch(setShowModal(false));
    setStratusFilter("");
  };

  return (
    <FilterDialog
      open={tableState.showModal}
      onClose={handleFilterModal}
      onEscapeKeyDown={handleFilterModal}>
      <FilterDialog.Content>
        <FilterDialog.Item label="Buscar por ID">
          <FilterDialog.TextField
            type="number"
            value={idFilter}
            onChange={(e) => setIdFilter(e.target.value)}
          />
        </FilterDialog.Item>
        <FilterDialog.Item label="Agente inmobiliario Houm">
          <div className={classes.userSuggest}>
            <UserSuggester
              defaultValue={houmOwnerFilter}
              value={houmOwnerFilter}
              onChange={(e) => setHoumOwnerFilter(e)}
              id="houmOwner"
              label=""
              qParams={{ role: "admin" }}
            />
          </div>
        </FilterDialog.Item>
        <FilterDialog.Item label="Estado" container spacing={1}>
          {projectStatus.map((item) => (
            <Grid item>
              <ButtonSelect
                label={item.name}
                isActive={!!statusFilter.includes(item.value)}
                onClick={() => toggleStatusFilter(item.value)}
              />
            </Grid>
          ))}
        </FilterDialog.Item>
        {state.country === "colombia" && (
          <FilterDialog.Item label="Estrato">
            <Grid container spacing={1}>
              {fromToOptions(1, 6, false).map((element) => (
                <Grid item>
                  <ButtonSelect
                    label={element}
                    isActive={stratusFilter === element}
                    onClick={() => setStratusFilter(element)}
                  />
                </Grid>
              ))}
            </Grid>
          </FilterDialog.Item>
        )}
        <FilterDialog.Item label="Ciudad">
          <Grid container spacing={1}>
            {cities
              .filter((item) => item.country === state.country)
              .map((element) => (
                <Grid item>
                  <ButtonSelect
                    label={element.label}
                    isActive={cityFilter === element.value}
                    onClick={() => setCityFilter(element.value)}
                  />
                </Grid>
              ))}
            <Grid item>
              <ButtonSelect
                label="Sin Ciudad"
                isActive={cityFilter === null}
                onClick={() => setCityFilter(null)}
              />
            </Grid>
          </Grid>
        </FilterDialog.Item>
        <FilterDialog.Item label="Comuna" container spacing={1}>
          <SelectionOptions
            id="houmOwner"
            simple
            compressed={false}
            value={communeFilter}
            onChange={setCommuneFilter}
            options={state.communes}
            fullWidth
          />
        </FilterDialog.Item>
      </FilterDialog.Content>
      <FilterDialog.Actions onDelete={handleDelete} onApply={handleSubmit} />
    </FilterDialog>
  );
}

export default FilterOptions;
