import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { alert } from "@houm-com/ui/Alert";

import { EvaluationMemberStatus, MemberRoles } from "domains/evaluations/models/Evaluations";
import service from "domains/evaluations/service";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

interface Props {
  evaluationMemberId: string;
  onHandleSuccess?: () => void;
}

interface FormData {
  status?: EvaluationMemberStatus;
  role?: MemberRoles;
  cancellationData?: {
    authorLabel: string;
    reason: string;
    comment: string;
  };
}

const useChangeEvaluationMemberStatus = ({ evaluationMemberId, onHandleSuccess }: Props) => {
  const { country } = useParams<{ country: Locale }>();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async (formData: FormData) => {
    const authToken = await getToken();
    const parsedData = {
      ...(formData.status && { status: formData.status }),
      ...(formData.role && { role: formData.role }),
      ...(formData.cancellationData && {
        cancellation_data: {
          author_label: formData.cancellationData.authorLabel,
          reason: formData.cancellationData.reason,
          comment: formData.cancellationData.comment,
        },
      }),
    };
    await service.changeEvaluationMemberStatus(evaluationMemberId, parsedData, country, authToken);
  });

  useEffect(() => {
    if (query.isSuccess) {
      queryClient.invalidateQueries("evaluation", { exact: false });
      onHandleSuccess?.();
      alert.success({
        message: "El estado del postulante ha sido actualizado correctamente",
      });
    }
  }, [query.isSuccess, queryClient]);

  useEffect(() => {
    if (query.isError) {
      alert.error({
        message: "Error al enviar al cambiar estados",
      });
    }
  }, [query.isError]);

  return query;
};

export default useChangeEvaluationMemberStatus;
