import { Card, CardHeader, Grid } from "@material-ui/core";
import useStyles from "./formContainerStyles";

interface Props {
  children: React.ReactNode;
}

function FormContainer({ children }: Props) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <CardHeader title="Datos básicos del plan" className={classes.cardHeader} />
        {children}
      </Card>
    </Grid>
  );
}

export default FormContainer;
