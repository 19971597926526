import qs from "query-string";
import { createHttp } from "./helper";
import {
  Multifamilies,
  photosDeleteBody,
  sortedPhotos,
} from "context/multifamilyContext/multifamily/multifamilyTypes";
import { Typologies } from "context/multifamilyContext/typology/typologyTypes";
import { IUnitsUploadData } from "context/multifamilyContext/unit/unitTypes";

const multifamilyUrl = "properties/v2/multifamily/";
const masiveUploadyGetUrl = "properties/v2/multifamily-file";
const masiveUploadPostyUrl = "properties/v2/multifamily-bulk-upload";
const typologyUrl = "properties/v2/multifamily_unit_type/";
const unitUrl = "properties/v2/multifamily_unit/";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  // Multifamily
  getMultifamilies(filters: Record<string, number | string> = {}, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${multifamilyUrl}?${qs.stringify(filters, qsConfig)}`);
  },

  publishMultiFamily(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.patch(`${multifamilyUrl}${id}/publish`);
  },
  unpublishMultiFamily(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.patch(`${multifamilyUrl}${id}/unpublish`);
  },
  createMultiFamily(body: Multifamilies, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${multifamilyUrl}`, body);
  },
  editMultiFamily(id: number, body: Multifamilies, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.patch(`${multifamilyUrl}${id}/`, body);
  },
  getMultifamily(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${multifamilyUrl}${id}/`);
  },
  getMultifamilyPhotos(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${multifamilyUrl}${id}/photos/`);
  },
  deleteMultifamilyPhoto(id: number, body: photosDeleteBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${multifamilyUrl}${id}/remove_photos/`, body);
  },
  multifamilyPhotosOrder(id: number, body: sortedPhotos, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.put(`${multifamilyUrl}${id}/photos/`, body);
  },

  //Typology getTypologyPhotos
  getTypologies(MultiFamilyID: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${typologyUrl}?multifamily=${MultiFamilyID}`);
  },
  getTypology(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${typologyUrl}${id}/`);
  },
  createTypology(body: Typologies, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${typologyUrl}`, body);
  },
  editTypology(id: number, body: Typologies, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.patch(`${typologyUrl}${id}/`, body);
  },
  getTypologyPhotos(id: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${typologyUrl}${id}/photos/`);
  },
  deleteTypologyPhoto(id: number, body: photosDeleteBody, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.post(`${typologyUrl}${id}/remove_photos/`, body);
  },
  typologyPhotosOrder(id: number, body: sortedPhotos, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.put(`${typologyUrl}${id}/photos/`, body);
  },
  getTypologyLayoutUrl(id: number, filePath: string, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${typologyUrl}${id}/layout_url/?filename=${filePath}`);
  },
  // Unit
  getUnits(typologyId: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${unitUrl}?unit_type=${typologyId}`);
  },
  getUnit(unitId: number, authToken: string) {
    const httpInstance = createHttp(authToken);
    return httpInstance.get(`${unitUrl}${unitId}/`);
  },
  getUnitsFilePresignedUrl(filename: string, token: string) {
    return createHttp(token).get(`${masiveUploadyGetUrl}?filename=${filename}`);
  },
  parseUnitsFile(data: IUnitsUploadData, token: string) {
    return createHttp(token).post(`${masiveUploadPostyUrl}`, data);
  },
};
