import { BACKEND_URL } from "../../env";
import { createHttp } from "../helper";
import { AxiosResponse } from "axios";

import { IExtractSignaturesResponse, IGetSignatureLinksResponse } from "./types";

export default {
  extractSignatures(
    files: { ci: Blob },
    token: string
  ): Promise<AxiosResponse<IExtractSignaturesResponse> | false> {
    return createHttp(token, BACKEND_URL, "multipart/form-data").post(
      "/sign/generate-and-upload-signature-options-from-ci",
      files
    );
  },

  setContractPersonSignature(
    data: {
      ci_front: string;
      ci_back: string;
      ci_signature_key: string;
      contract_person_id: number;
    },
    token: string
  ) {
    return createHttp(token, BACKEND_URL, "application/json").post(
      "/sign/v2/save-ci-and-signature",
      data
    );
  },

  getSignatureLinks(
    contractpersonId: number,
    token: string
  ): Promise<AxiosResponse<IGetSignatureLinksResponse> | false> {
    return createHttp(token, BACKEND_URL, "application/x-www-form-urlencoded").get(
      `/sign/v2/contract-person/${contractpersonId}/signature-and-ci-links`
    );
  },
};
