import { useEffect, useState } from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

import useStyles from "./DealSwitcherStyles";

interface Props {
  deal: "rental" | "sale";
  onDealChange: (v) => void;
}

function DealSwitcher({ deal: propDeal, onDealChange }: Props) {
  const classes = useStyles();
  const [deal, setDeal] = useState(propDeal);

  useEffect(() => {
    setDeal(propDeal);
  }, [propDeal]);

  const dealChangeHandler = (e) => {
    onDealChange(e.target.value);
    setDeal(e.target.value);
  };
  return (
    <div className={classes.container}>
      <FormControl component="fieldset">
        <div className={classes.container}>
          <FormLabel component="legend">Propiedades similares para: </FormLabel>
          <RadioGroup aria-label="deal" name="deal" value={deal} onChange={dealChangeHandler}>
            <div className={classes.container}>
              <FormControlLabel
                value="rental"
                labelPlacement="start"
                control={<Radio color="primary" />}
                label="Arriendo"
              />
              <FormControlLabel
                value="sale"
                labelPlacement="start"
                control={<Radio color="primary" />}
                label="Venta"
              />
            </div>
          </RadioGroup>
        </div>
      </FormControl>
    </div>
  );
}

export default DealSwitcher;
