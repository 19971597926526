import { SnackbarProvider } from "notistack";

import ExternalPortalPublications from "./ExternalPortalPublications";
import { IntegrationProvider } from "context/integrationContext/integrationContext";
import { TableProvider } from "context/tableContext/tableContext";

function ExternalPortalPublicationsIndex() {
  return (
    <IntegrationProvider>
      <SnackbarProvider>
        <TableProvider>
          <ExternalPortalPublications />
        </TableProvider>
      </SnackbarProvider>
    </IntegrationProvider>
  );
}

export default ExternalPortalPublicationsIndex;
