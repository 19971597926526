import TablePagination from "Components/Admin/UIComponents/DesignSystem/TablePagination";

import { useLandlordTickets, usePagination } from "../../hooks";
import LandlordTable from "../LandlordTable";
import { columnsConfig } from "./utils/columns";

interface Props {
  ticketsCount: number;
}

function TicketsTable({ ticketsCount }: Props) {
  const { page, setPage, pageSize, setPageSize } = usePagination();
  const { isLoading, error, data: tickets } = useLandlordTickets(page, pageSize);

  return (
    <LandlordTable
      content="tickets"
      error={error}
      headerHeight={40}
      columns={columnsConfig}
      loading={isLoading}
      rows={tickets}
      rowHeight={50}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Pagination: () => (
          <TablePagination
            pageCount={Math.ceil(ticketsCount / pageSize)}
            loading={isLoading}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={page}
            setCurrentPage={setPage}
            pageSizeOptions={[5, 10, 20]}
          />
        ),
      }}
    />
  );
}

export default TicketsTable;
