export const getPersonWarranty = {
  Houm: "Retiene Houm",
  Lessor: "Retiene propietario",
};

export const currencyParse = {
  Chile: {
    pesos: "CLP",
    uf: "CLF",
    "u.f.": "CLF",
  },
  Mexico: {
    pesos: "MXN",
  },
  Colombia: {
    pesos: "COP",
  },
};
