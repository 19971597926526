import { makeStyles, Theme } from "@material-ui/core";

const useLandlordDataStyles = makeStyles((theme: Theme) => ({
  landlordDataRoot: {
    marginTop: theme.spacing(2),
  },
  landlordDataWrapper: {
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
  },
  lightButton: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
}));

export default useLandlordDataStyles;
