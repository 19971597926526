import * as yup from "yup";

export default yup
  .object({
    files: yup.mixed(),
  })
  .test("fileTest", null, (obj) => {
    if (obj.files?.length > 0) {
      return true;
    }

    return new yup.ValidationError("No se ha subido un archivo", "file", "required");
  });
