import * as yup from "yup";
import { phoneObject } from "utils/yupValidators/phone";

export const requiredText = "Obligatorio";

const stringEmail = yup
  .string()
  .required(requiredText)
  .matches(/(.*@houm.com)|(@HOUM.COM$)/, "Sólo se permiten correos houm")
  .email("Mail inválido");

export const userAdminSchema = yup.object({
  name: yup.string().required(requiredText),
  lastName: yup.string().required(requiredText),
  email: stringEmail,
  country: yup.string().required(requiredText),
  phone: phoneObject.typeError("Número de celular inválido").required(requiredText),
});
