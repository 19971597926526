import { AxiosResponse } from "axios";

import { Paginate } from "models/ResponseWithPaginate";
import { clientConfig, createHttp } from "services/helper";

import {
  IGetPromotionsParams,
  IPostPromotion,
  PromotionForm,
  PromotionsResponse,
  ISimplePromotionResponse,
  IGetSimplePromotionsParams,
} from "./types";
import httpClient from "@houm-com/http-client";

export default {
  createPromotion(data: PromotionForm, token: string) {
    return createHttp(token).post(`/v2/promotions/`, data);
  },
  newCreatePromotion(data: IPostPromotion, token: string) {
    const client = httpClient(clientConfig(token));
    return client.post(`/admin/v2/promotions/`, data);
  },
  editPromotion(data: Partial<PromotionForm>, token: string) {
    return createHttp(token).put(`/v2/promotions/`, data);
  },
  newEditPromotion(data: Partial<PromotionForm>, promotionId: number, token: string) {
    return createHttp(token).patch(`/admin/v2/promotions/${promotionId}/`, data);
  },
  deletePromotion(promotionId: number, token: string) {
    return createHttp(token).delete(`/v2/promotions/${promotionId}/`);
  },
  getPromotions(
    params: IGetPromotionsParams,
    token: string
  ): Promise<AxiosResponse<Paginate<PromotionsResponse>>> {
    return createHttp(token).get(`/v2/promotions/`, { params });
  },
  getSimplePromotions(
    params: IGetSimplePromotionsParams,
    token: string
  ): Promise<AxiosResponse<Paginate<ISimplePromotionResponse>>> {
    return createHttp(token).get(`admin/v2/promotions/`, { params });
  },
  getPromotionById(promotionId: number, token: string): Promise<AxiosResponse<PromotionsResponse>> {
    return createHttp(token).get(`/v2/promotions/${promotionId}/`);
  },
};
