import { makeStyles } from "@material-ui/core";

const useContractPersonEditStyles = makeStyles((theme) => ({
  ContractPersonEditWrapper: {
    width: 450,
    margin: "0 auto",
    paddingBottom: 50,
  },
  ContractPersonRutForm: {
    marginTop: theme.spacing(6),
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ContractPersonSearchRutButton: {
    marginLeft: theme.spacing(2),
    minWidth: 82,
  },
  ContractPersonEditTextField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 400,
  },
  ContractPersonUpdateButton: {
    marginTop: theme.spacing(2),
    marginLeft: 95,
  },
  ContractPersonWarning: {
    color: theme.palette.error.main,
  },
}));

export default useContractPersonEditStyles;
