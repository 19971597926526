import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";

import useExcelExportStyles from "./ExcelExportStyles";
import { IAssing, parseData } from "../utils";

interface Props {
  data: IAssing[];
}

function ExcelExport({ data }: Props) {
  const classes = useExcelExportStyles();
  let newData = [];
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  let fileName;

  if (data.length) {
    newData = data.map((d) => parseData(d));
    fileName = `Reporte ejecutivas ${day}-${month}-${year}`;
  }
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileNameData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataCsv = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataCsv, fileNameData + fileExtension);
  };

  return (
    <Tooltip
      title="Reporte ejecutivas"
      className={classes.iconDownload}
      onClick={(e) => exportToCSV(newData, fileName)}>
      <IconButton aria-label="Download file">
        <GetAppIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
}

export default ExcelExport;
