import { ReactNode } from "react";

import { Grid } from "@material-ui/core";

import { IReservation } from "services/newPaymentService/types";

import StatusLabelReservation from "../../../StatusLabelReservation";
import CustomCard from "../../../CustomCard";

interface Props {
  reservation: IReservation;
  title: string;
  children: ReactNode;
}

const CardDetail = ({ reservation, title, children }: Props) => (
  <Grid item>
    <CustomCard title={title} subheader={<StatusLabelReservation status={reservation.status} />}>
      {children}
    </CustomCard>
  </Grid>
);

export default CardDetail;
