import { CHANGE_COUNTRY } from "context/evaluationContext/evaluationTypes";
import {
  State,
  Action,
  SET_IMAGE,
  SET_LOADING_UPLOAD,
  SET_SELECTED_LAYOUT,
  SET_LOADING,
  SET_PROJECTS,
  SET_IS_SHORTER_FORM,
  SET_CREATED_PROJECT,
  SET_USER_MODAL,
  SET_SUCCESS_MODAL,
  SET_UNITS_UPLOAD_MODAL,
  SET_LOCATION_OPTIONS,
  SET_ADDRESS,
  SET_USER,
  SET_UNITS_UPLOAD_ERRORS,
} from "./projectTypes";

export const initialState: State = {
  projects: [],
  loading: false,
  country: "chile",
  image: [],
  loadingUpload: false,
  selectedLayout: null,
  numberOfProperties: null,
  isShorterForm: true,
  openUserModal: false,
  user: null,
  openSuccessModal: false,
  neighborhoods: [],
  address: null,
  communes: [],
  openUnitsUploadModal: false,
  unitsUploadErrors: [],
};

const apiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_IMAGE:
      return { ...state, image: action.image };
    case SET_LOADING_UPLOAD:
      return { ...state, loadingUpload: action.loadingUpload };
    case SET_SELECTED_LAYOUT:
      return { ...state, selectedLayout: action.selectedLayout };
    case SET_IS_SHORTER_FORM: {
      return { ...state, isShorterForm: action.isShorterForm };
    }
    case SET_USER_MODAL: {
      return { ...state, openUserModal: action.openUserModal };
    }
    case SET_UNITS_UPLOAD_MODAL: {
      return { ...state, openUnitsUploadModal: action.openUnitsUploadModal };
    }
    case SET_UNITS_UPLOAD_ERRORS: {
      return { ...state, unitsUploadErrors: action.unitsUploadErrors };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_ADDRESS: {
      return { ...state, address: action.address };
    }
    case SET_SUCCESS_MODAL: {
      return { ...state, openSuccessModal: action.openSuccessModal };
    }
    case SET_PROJECTS: {
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
        numberOfProperties: action.payload.count,
      };
    }
    case SET_CREATED_PROJECT: {
      return {
        ...state,
        project: action.project,
        loading: false,
        openSuccessModal: true,
      };
    }
    case SET_LOCATION_OPTIONS: {
      return {
        ...state,
        neighborhoods: action.neighborhoods,
        communes: action.communes,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    default:
      return state;
  }
};

export default apiReducer;
