import { Grid } from "@material-ui/core";

import { ModalBold } from "../../../../../../typography";
import Row from "../Row";
import useTotalStyles from "./TotalStyles";

interface Props {
  total: string;
}

function Total({ total }: Props) {
  const classes = useTotalStyles();

  return (
    <Row container justifyContent="space-between">
      <Grid item>
        <ModalBold>Total pagado</ModalBold>
      </Grid>
      <Grid item>
        <ModalBold className={classes.green}>{total}</ModalBold>
      </Grid>
    </Row>
  );
}

export default Total;
