import { useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import Highlighter from "react-highlight-words";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

import propertyService from "services/propertyService";
import useSearchBarStyles from "./SearchBarStyles";
import { useGetToken } from "hooks";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.address; // Use your imagination to render suggestions.

function SearchBar({ viewName = undefined }) {
  const { getToken } = useGetToken();
  const classes = useSearchBarStyles();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const renderSuggestion = (suggestion) => (
    <Link to={`/admin/properties/${suggestion.uid}`}>
      <div className={classes.suggestionContainerComponent}>
        <Highlighter
          className={classes.title}
          searchWords={[value.query]}
          autoEscape
          textToHighlight={`ID ${suggestion.id}: ${suggestion.address} ${suggestion.street_number}`}
        />
        <br />
        <p className={classes.suggestion}>
          <Highlighter
            searchWords={[value.query]}
            autoEscape
            textToHighlight={`${suggestion.user.name} ${suggestion.user.last_name} ${suggestion.user.email}`}
          />
        </p>
        <p className={classes.suggestionName}>
          [{suggestion.status ? suggestion.status.name : ""}]
        </p>
      </div>
    </Link>
  );

  const loadSuggestions = (value) => {
    getToken().then((authToken) =>
      propertyService.search(value, viewName, authToken).then((item) => {
        const sugg = item.data.results;
        setSuggestions(sugg);
      })
    );
  };

  const debouncedLoadSuggestions = useRef(debounce(loadSuggestions, 1000));

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions.current(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Buscar Propiedades ...",
    value,
    onChange,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      theme={{
        input: classes.textField,
        container: classes.textFieldContainer,
        suggestionsContainerOpen: classes.suggestionContainer,
        // suggestionsContainerOpen:
        //   "react-autosuggest__suggestions-container--open"
      }}
    />
  );
}

export default SearchBar;
