const salaryLocalization = {
  cl: {
    salaries: "Liquidaciones",
    salary: "Liquidación",
  },
  mx: {
    salaries: "Rentas",
    salary: "Renta",
  },
};

export default salaryLocalization;
