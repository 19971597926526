import { format } from "date-fns";
import { useState } from "react";

import { Typography } from "@material-ui/core";

import PhotosModal from "Components/Admin/administrator/subscriptions/components/Requests/components/PhotosModal";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import { IQuestion } from "../../hooks/types";
import useUnansweredQuestionStyles from "./UnansweredQuestionStyles";
import useAnswerQuestion from "../../hooks/useAnswerQuestion";
import PhotosButton from "../PhotosButton";

interface Props {
  question: IQuestion;
  refetchQuestions: () => void;
}

const UnansweredQuestion = ({ question, refetchQuestions }: Props) => {
  const classes = useUnansweredQuestionStyles();
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false);
  const [answer, setAnswer] = useState(null);
  const { answerQuestion, isLoading } = useAnswerQuestion({ onSuccessHandler: refetchQuestions });
  const onSubmit = () => {
    answerQuestion({
      questionId: question.id,
      answer,
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.questionHeader}>
          <Typography className={classes.label}>Pregunta</Typography>
          <Typography className={classes.date}>
            {format(question.createdAt, "dd/MM/yyyy")}
          </Typography>
        </div>
        <Typography className={classes.question}>{question.question}</Typography>
        <Typography className={classes.author}>Houmer: {question.authorName}</Typography>
        <PhotosButton
          onClick={() => setIsPhotosModalOpen(true)}
          hasPhotos={question.photos.length > 0}
        />
        <CustomTextField
          className={classes.textField}
          inputClassName={classes.textAreaInput}
          minRows={3}
          multiline
          wrapperPadding={false}
          onChange={(e) => setAnswer(e.target.value)}
          value={answer}
          placeholder="Escribe aquí la respuesta"
          maxLength={300}
        />
        <div className={classes.buttonContainer}>
          <DesignSystemButton
            disabled={!answer}
            variant="primary"
            size="small"
            label="Responder"
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
      </div>
      <PhotosModal
        openModal={isPhotosModalOpen}
        onClose={() => setIsPhotosModalOpen(false)}
        photos={question.photos}
      />
    </>
  );
};

export default UnansweredQuestion;
