export const CIVIL_STATUSES = {
  unmarried: "Soltero/a",
  married: "Casado/a",
  divorced: "Divorciado/a",
  widowed: "Viudo/a",
  free_union: "Unión libre",
  other: "Otro",
};

export const MARITAL_REGIMES = {
  conjugal_society: "Sociedad conjugal",
  separated_goods: "Con Separación de bienes",
  not_apply: "No aplica",
  other: "Otro",
};
