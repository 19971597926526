import { makeStyles, Theme } from "@material-ui/core";

const useKastorCompanyFormStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: "flex",
    alignItems: "flex-end",
  },
  buttons: {
    width: "100%",
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  textInput: {
    background: theme.palette.grey[50],
    border: 4,
    paddingLeft: theme.spacing(1),
  },
}));

export default useKastorCompanyFormStyles;
