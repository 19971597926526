/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export const SET_SHORTER_FORM = "SET_SHORTER_FORM";
export const SET_LOADING = "SET_LOADING";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const CHANGE_MARKET = "CHANGE_MARKET";

export interface State {
  loading: boolean;
  properties: Property[];
  numberOfProperties: number | null;
  country: Country;
  market: Market;
}

export type Country = "chile" | "colombia" | "mexico";
export type Market = "sales" | "rental";

interface changeCountry {
  type: typeof CHANGE_COUNTRY;
  country: Country;
}

interface changeMarket {
  type: typeof CHANGE_MARKET;
  market: Market;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}
interface setProperties {
  type: typeof SET_PROPERTIES;
  payload: { properties: Property[]; count: number };
}

export type Action = changeCountry | setProperties | setLoading | changeMarket;

interface Property {
  id: string;
  published: string;
  createdAt: string;
  status: string;
  houmOwner: string;
  homeChecker: string;
  landlordEmail: string;
  link: string;
}
