import { PropertyType } from "models/Calculator";
import { PropertyData } from "models/Property";

export default (data: PropertyData) => {
  const details = data.property_details[0];
  const [totalArea, builtArea] = [details.m_terreno, details.m_construidos];
  if (totalArea && builtArea) {
    return {
      total_area: totalArea,
      built_area: builtArea,
      bedrooms: details.dormitorios,
      bathrooms: details.banos,
      parking_spots: details.estacionamientos,
      latitude: details.latitud,
      longitude: details.longitud,
      country: data.country,
      stratus: data.stratus,
      locality: data.comuna,
      property_age: data.antiquity,
      municipality: data.comuna,
      property_type: (data.type === "departamento" ? "apartment" : "house") as PropertyType,
      region:
        data.country === "Chile"
          ? data.region === "Región Metropolitana"
            ? "metropolitana"
            : data.region === "Bío Bío"
            ? "biobio"
            : "valparaiso"
          : data.region,

      amenities: {
        has_gym: !!data.association_amenities?.has_gym,
        has_pool: details.piscina,
        has_heating: details.calefaccion,
        has_security: !!data.association_amenities?.has_concierge,
        is_furnished: !!details.amoblado,
      },
    };
  }
  return false;
};
