import { useEffect, useState } from "react";
import moment from "moment";

import {
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Dialog,
  IconButton,
} from "@material-ui/core";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { GridCloseIcon } from "@material-ui/x-grid";

import { useGetToken, useAvailability } from "hooks";

import { removeToday, removeHourBeforeToday } from "utils/availabilityTime";
import { cityOptions, AdvisoryCity } from "Components/Admin/administrator/demand/utils/options";
import countryCode from "utils/countryCode";
import { getTimezoneWithCommuneFormatted } from "utils/datetime";

import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import {
  setIsOpenScheduleAdvisoryModal,
  setAppraiserId,
  getAvailability,
  scheduleAdvisory,
  updateCurrentProperty,
} from "context/demandLeadContext/schedule/demandScheduleActions";

import CustomAutocomplete from "Components/Admin/administrator/demand/components/CustomAutocomplete";
import CustomDatepicker from "Components/Admin/UIComponents/molecules/DatePicker";
import HourButton from "Components/Admin/UIComponents/atoms/HourButton";

import useScheduleAdvisoryStyles from "./ScheduleAdvisoryStyles";
import { scheduleResponse } from "utils/scheduleResponseHours";

function ScheduleAdvisory() {
  const classes = useScheduleAdvisoryStyles();

  const {
    state: { isOpenScheduleAdvisoryModal, availability, property },
    dispatch,
  } = useDemandSchedule();
  const {
    state: { lead },
  } = useDemandLead();

  const [typeVisit, setTypeVisit] = useState<string>("advisory");
  const [selectedCity, setSelectedCity] = useState<AdvisoryCity>(null);
  const [filteredCities, setFilteredCities] = useState<AdvisoryCity[]>(null);
  const { getToken } = useGetToken();

  useEffect(() => {
    const filteredByCountryCities = cityOptions.filter(
      (city) => countryCode(city.country) === lead.lead.country
    ) as AdvisoryCity[];
    setFilteredCities(filteredByCountryCities);
  }, []);

  const handleSearchHoumAdvisorDipsonibility = (appraiserId = undefined) => {
    const correctAppraiserValue = appraiserId === "Cualquiera" ? undefined : appraiserId;
    dispatch(setAppraiserId(correctAppraiserValue));
    getToken().then((authToken) => {
      getAvailability(
        "",
        "Advisory",
        correctAppraiserValue,
        authToken,
        dispatch,
        selectedCity?.country,
        selectedCity.value
      );
    });
  };

  const { allowedDays, allowedHours, currentDay, currentHour, setCurrentDay, setCurrentHour } =
    useAvailability({
      firstDayByDefault: true,
      availability,
      country: selectedCity?.country,
      commune: selectedCity?.label,
      removeConditions: [removeToday, removeHourBeforeToday],
    });

  const fullDaySchedule = scheduleResponse({
    currentHour,
    country: selectedCity?.country,
    region: selectedCity?.region,
  });

  const handleClose = () => {
    dispatch(setIsOpenScheduleAdvisoryModal(false));
    setCurrentHour(null);
    setCurrentDay(null);
    dispatch(setAppraiserId(null));
    setSelectedCity(null);
    getToken().then((authToken) => {
      updateCurrentProperty(property, "sales", dispatch, authToken);
    });
  };

  const handleSchedule = () => {
    getToken().then((authToken) => {
      scheduleAdvisory({
        lead,
        dispatch,
        authToken,
        timeStamp: currentHour,
        type: "Advisory",
        propertyId: 74564, //This propId is only to get applicantId for advisory
        cityId: selectedCity.value,
        country: selectedCity?.country,
        daySchedule: fullDaySchedule,
        leadEmail: lead.lead.email,
      });
    });
    handleClose();
  };

  useEffect(() => {
    if (selectedCity) handleSearchHoumAdvisorDipsonibility();
  }, [selectedCity]);

  return (
    <Dialog
      open={isOpenScheduleAdvisoryModal}
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}>
      <Typography className={classes.title}>
        <EventOutlinedIcon /> Agendamiento Asesoría
      </Typography>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <GridCloseIcon />
      </IconButton>
      <div className={classes.container}>
        <div>
          <Typography className={classes.subsectionHeader}>Tipo de agendamiento</Typography>
          <RadioGroup
            aria-label="type_schedule"
            name="type"
            value={typeVisit}
            onChange={(e) => setTypeVisit(e.target.value)}>
            <FormControlLabel
              disabled={allowedDays.length === 0}
              value="advisory"
              labelPlacement="end"
              control={<Radio color="primary" />}
              label="Asesoría"
            />
          </RadioGroup>
        </div>
        <div className={classes.timeZoneContainer}>
          <CustomAutocomplete
            id="city"
            labelPos="top"
            onChange={setSelectedCity}
            value={selectedCity}
            options={filteredCities}
            label="Ciudad para la asesoría"
          />
        </div>
        {availability && Object.keys(availability).length !== 0 && selectedCity && (
          <div>
            <div>
              <hr className={classes.divider} />
              <Typography className={classes.timeZoneTitle}>Zona Horaria</Typography>
              <Typography className={classes.timeZone}>
                {selectedCity &&
                  getTimezoneWithCommuneFormatted(selectedCity?.country, selectedCity.label)}
              </Typography>
            </div>
            <div className={classes.calendarColumn}>
              <Typography className={classes.subsectionHeader}>Selecciona el día</Typography>
              <CustomDatepicker
                value={currentDay}
                onChange={(date) => setCurrentDay(moment(date))}
                shouldDisableDate={(date) => !allowedDays.find((day) => day.isSame(date, "date"))}
              />
            </div>
            <div className={classes.hourColumn}>
              <Typography className={classes.subsectionHeader}>Selecciona la hora</Typography>
              <div className={classes.hours}>
                {(allowedHours || []).map(({ formatted, timestamp }) => (
                  <HourButton
                    key={timestamp}
                    selected={currentHour === timestamp}
                    onClick={() => setCurrentHour(timestamp)}>
                    {formatted}
                  </HourButton>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className={classes.scheduleButtonContainer}>
          <Button
            className={classes.scheduleButton}
            disableElevation
            disabled={!currentHour || !currentDay}
            variant="outlined"
            color="primary"
            onClick={handleSchedule}>
            Agendar Asesoría
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default ScheduleAdvisory;
