import Grid from "@material-ui/core/Grid";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useLeadQualificationStyles from "./LeadQualificationStyles";
import { useFormNotifications, useGetToken } from "hooks";
import validationSchema, { requiredText } from "./leadQualificationValidationSchema";
import {
  ControlledDatePicker,
  ControlledList,
  ControlledTextField,
  ControlledSelection,
} from "Components/Admin/UIComponents/ControlledForm";
import { qualifications, lostReasons, status } from "./LeadQualificationConsts";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { updateLeadQualfication } from "context/leadContext/leadActions";
import { useLead } from "context/leadContext/leadContext";
import { QualificationForm } from "context/leadContext/leadTypes";

function LeadQualificationItem() {
  const classes = useLeadQualificationStyles();
  const { getToken } = useGetToken();
  const { state, dispatch } = useLead();

  const methods = useForm<QualificationForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const { errors } = methods.formState;

  const onSubmit: SubmitHandler<QualificationForm> = async (data, e) => {
    try {
      const authToken = await getToken();
      await updateLeadQualfication(state.lead?.id, data, dispatch, authToken);
    } catch {
      console.error("error");
    }
  };

  useFormNotifications<QualificationForm>({ errors, requiredText });

  return (
    <FormProvider {...methods} key="LeadQualification">
      <form key="LeadQualification" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" className={classes.formContainer}>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="country"
              label="País"
              disabled
              defaultValue={state.lead?.country ?? ""}
              textFieldProps={{
                InputProps: { className: classes.formInput },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="name"
              label="Lead"
              disabled
              defaultValue={state.lead?.name ?? ""}
              textFieldProps={{
                InputProps: { className: classes.formInput },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="email"
              label="Correo"
              disabled
              defaultValue={state.lead?.email ?? ""}
              textFieldProps={{
                InputProps: {
                  className: classes.formInput,
                  placeholder: "Sin información",
                },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledSelection
              id="score"
              label="Nota"
              options={qualifications}
              defaultValue={state.lead?.qualification ?? ""}
              classes={{
                label: classes.selectionButtonLabel,
                container: classes.selectionButtonContainer,
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="leadQualifier"
              label="Ejecutivo"
              disabled
              defaultValue={state.lead?.leadQualifier ?? ""}
              textFieldProps={{
                InputProps: { className: classes.formInput },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledDatePicker
              id="followUpDate"
              label="Fecha Seguimiento"
              defaultValue={state.lead?.followUpDate}
              disabled
              type="text"
              textFieldProps={{
                InputProps: {
                  className: classes.formInput,
                },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="phone"
              label="Teléfono"
              disabled
              defaultValue={state.lead?.phone ?? ""}
              textFieldProps={{
                InputProps: {
                  className: classes.formInput,
                  placeholder: "Sin información",
                },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledList
              id="lostReason"
              label="Motivo Perdido"
              disabled={false}
              options={lostReasons}
              disableUnderline
              classes={{
                container: classes.selector,
                label: classes.selectorLabel,
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledList
              id="status"
              label="Estado"
              options={status}
              defaultValue={state.lead?.status ?? ""}
              disableUnderline
              classes={{
                container: classes.selector,
                label: classes.selectorLabel,
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={3}>
            <ControlledTextField
              id="campaign"
              label="Campaña"
              disabled
              defaultValue={state.lead?.campaign ?? ""}
              textFieldProps={{
                InputProps: {
                  className: classes.formInput,
                  placeholder: "Sin información",
                },
              }}
            />
          </Grid>
          <Grid item className={classes.gridItemContainer} xs={6}>
            <ControlledTextField
              id="comments"
              label="Comentarios (opcional)"
              disabled={false}
              defaultValue={state.lead?.comments ?? ""}
              bordered={true}
              textFieldProps={{
                InputProps: {
                  disableUnderline: true,
                  multiline: true,
                  maxRows: 5,
                  minRows: 3,
                  className: "p-2",
                },
              }}
            />
          </Grid>

          <Grid container className={classes.saveButtonContainer}>
            <CustomButton active className={classes.saveButton} type="submit">
              Guardar cambios
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default LeadQualificationItem;
