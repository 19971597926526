import { makeStyles } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginBottom: theme.spacing(0),
  },
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
}));
