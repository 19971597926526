import { makeStyles, Theme } from "@material-ui/core";

const useRenewalStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    width: "50%",
    margin: "0 auto",
  },
  container: {
    width: "400px",
    margin: "0 auto",
  },
  title: {
    paddingTop: theme.spacing(1),
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  containerPoliza: {
    width: "400px",
  },
  inputContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radioColor: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  rootRadio: {
    marginLeft: 1,
    display: "inline",
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    border: "none",
    borderRadius: 5,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    lineHeight: "2.5em",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
      cursor: "pointer",
      transition: 0.1,
    },
  },
  focusDataPicker: {
    border: "0.5px solid #607D8B",
  },
  dataPicker: {
    width: "100%",
    "& .MuiInputBase-input": {
      fontSize: 14,
      padding: theme.spacing(1),
      border: "none",
    },
    "& .MuiInputBase-root": {
      paddingRight: 2,
      borderRadius: 8,
      border: "0.5px solid #607D8B",
    },
    "& .MuiIconButton-root": {
      padding: "12px 5px 12px 5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {},
  },
  centerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useRenewalStyles;
