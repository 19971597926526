import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import { useDemandScheduled } from "context/demandScheduledVisitsContext/demandScheduledContext";

function Processing() {
  const {
    state: { isOpenAsignProcessingModal },
  } = useDemandScheduled();
  return (
    <CustomCenteredModal
      open={isOpenAsignProcessingModal}
      onClose={() => null}
      title="¡Estamos guardando la informacion!"
      subtitle="Este proceso puede tardar unos segundos"
      withLogo
    />
  );
}
export default Processing;
