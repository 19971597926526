import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  selected: boolean;
}

const usePageNumberStyles = makeStyles<Theme, Props>((theme) => ({
  pageNumberWrapper: {
    height: 23,
    width: 23,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    backgroundColor: (props) => (props.selected ? "#FEF1F0" : "transparent"),
    color: (props) => (props.selected ? theme.palette.primary.main : theme.palette.grey[900]),
    "&:hover": {
      backgroundColor: (props) => (props.selected ? "#FEF1F0" : "#ECEFF1"),
      color: (props) => (props.selected ? theme.palette.primary.main : theme.palette.grey[200]),
      cursor: "pointer",
    },
  },
  pageNumber: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 0.6,
    color: "inherit",
  },
}));

export default usePageNumberStyles;
