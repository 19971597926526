import { startCase } from "lodash";
import { PropertyBasicServicesResponse } from "services/propertyService/types";
import { AvailableCompanies, BasicService } from "./types";

export const formatCompany = (raw: string): AvailableCompanies => {
  if (raw === "otro") return "other";
  return raw
    .split("_")
    .map((word) => startCase(word.toLocaleLowerCase()))
    .join(" ") as AvailableCompanies;
};

export const parsePropertyServices = (data: PropertyBasicServicesResponse): BasicService[] =>
  data?.bill_details.map((service) => {
    const parseUpdateAt = `${service.updated_at}Z`;
    return {
      administrationEmail: service.administration_email,
      aggregateDebt: service.expired_debt,
      client: service.client_info.external_id,
      clientInfo: {
        communityName: service.client_info.community_name,
        unit: service.client_info.unit,
        id: service.client_info.external_id,
        isValid: service.client_info.is_valid,
      },
      company: formatCompany(service.company),
      documentName: service.document_name,
      documentUrl: service.document_url,
      legacyCompany: service.company,
      currentDebt: service.total_debt - service.expired_debt,
      id: service._id,
      failureReasons: service.failure_reasons,
      name: service.pretty_name,
      overdueDate: new Date(service.due_date),
      propertyId: service.property_id,
      status: service.status,
      type: service.company_type === "common_expenses" ? "commonExpense" : "basicService",
      updatedAt: new Date(parseUpdateAt),
    };
  });
