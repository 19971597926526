/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from "react-router-dom";
import moment from "moment";

import { emptyString } from "Components/Admin/administrator/demand/utils/cells";
import { Schedule } from "models/DemandLead";
import { capitalizeString } from "utils";
import { getTimezones } from "utils/scheduleTimezone";

import useCellStyles from "./CellsStyles";

interface Props {
  row: Schedule;
}

export function PropertyCell({ row }: Props) {
  const classes = useCellStyles();
  return row.property_uid ? (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      to={`/admin/properties/${row.property_uid}`}
      className={classes.blackUnderline}>
      {row.property_id}
    </Link>
  ) : (
    <>{emptyString}</>
  );
}

export function DirectionAddressCell({ row }: Props) {
  return <>{row.visit_address != "N/A" ? row.visit_address : emptyString}</>;
}

export function CommuneCell({ row }: Props) {
  return <>{row.commune != "N/A" ? row.commune : emptyString}</>;
}

export function DateCell({ row }: Props) {
  return (
    <>
      {row.visit_date_time != "N/A" && row.property_country
        ? capitalizeString(
            moment(row.visit_date_time)
              .tz(getTimezones(row.property_country, row.property_region))
              .format("dddd DD/MM/YYYY")
          )
        : emptyString}
    </>
  );
}

export function HourCell({ row }: Props) {
  return (
    <>
      {row.visit_date_time != "N/A" && row.property_country
        ? `${moment(row.visit_date_time)
            .tz(getTimezones(row.property_country, row.property_region))
            .format("HH:mm")} hrs`
        : emptyString}
    </>
  );
}

export function StatusCell({ row }: Props) {
  return <>{row.visit_state}</>;
}

export function TypeCell({ row }: Props) {
  return <>{row.visit_type != null ? row.visit_type : emptyString}</>;
}

export function AdvisorCell({ row }: Props) {
  return <>{row.appraiser != null ? row.appraiser : emptyString}</>;
}
