import clx from "classnames";

import { Typography, TypographyProps } from "@material-ui/core";

import useBoldTextStyles from "./BoldTextStyles";

function BoldText({ children, className, ...props }: TypographyProps) {
  const classes = useBoldTextStyles();

  return (
    <Typography className={clx(classes.boldTextRoot, className)} {...props}>
      {children}
    </Typography>
  );
}

export default BoldText;
