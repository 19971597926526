import { useEffect } from "react";
import { useGetToken } from "hooks";
import { useParams } from "react-router-dom";

import {
  setCurrentId as setMultifamilyId,
  getMultifamily,
} from "context/multifamilyContext/multifamily/multifamilyActions";
import {
  getTypology,
  getTypologyPhotos,
  setCurrentId as setTipologyId,
} from "context/multifamilyContext/typology/typologyActions";
import { fetchUnits } from "context/multifamilyContext/unit/unitActions";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { useTypology } from "context/multifamilyContext/typology/typologyContext";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import Header from "./Components/Header";
import Table from "./Components/table/Table";

import useStyles from "./indexStyles";

function TypologyTable() {
  const classes = useStyles();
  const { id, MFid } = useParams<{ id: string; MFid: string }>();
  const { getToken } = useGetToken();
  const { dispatch: dispatchMF } = useMultifamily();
  const { dispatch: dispatchUT } = useTypology();
  const { dispatch: dispatchMU } = useMultifamilyUnit();

  useEffect(() => {
    dispatchMF(setMultifamilyId(parseInt(MFid)));
    dispatchUT(setTipologyId(parseInt(id)));
    if (id && MFid) {
      getToken().then((authToken) => {
        getMultifamily(dispatchMF, parseInt(MFid), authToken);
        getTypology(dispatchUT, parseInt(id), authToken);
        getTypologyPhotos(dispatchUT, parseInt(id), authToken);
        fetchUnits(parseInt(id), authToken, dispatchMU);
      });
    }
  }, [id, MFid]);

  return (
    <div className={classes.container}>
      <Header />
      <Table />
    </div>
  );
}
export default TypologyTable;
