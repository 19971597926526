import Swal from "sweetalert2";
import moment from "moment";

import {
  SET_LOADING,
  SET_LOADING_ACTIVITIES,
  SET_LEAD,
  SHOW_ACTIVITY_MODAL_FORM,
  HIDE_ACTIVITY_MODAL_FORM,
  SET_SELECTED_ACTIVITY,
  SET_LEAD_ACTIVITIES,
  SET_SELECTED_LEAD,
  SET_LEAD_PROPERTIES,
  SET_LEAD_PROPERTIES_COUNT,
  Dispatch,
  Action,
  QualificationForm,
  IActivity,
  ActivityModalActionType,
  ILeadActivitiesInfo,
  LeadActivitiesFilters,
  ILead,
  SET_LEAD_AVAILABILITY,
} from "./leadTypes";

import {
  parseUpdateLeadQualification,
  parseGetLeadData,
  parseCreateLeadActivity,
  parseGetLeadActivitiesResult,
  parseGetLeadProperties,
} from "./utils";

import leadService from "services/leadService";

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

const setLoadingActivities = (loadingActivities: boolean): Action => ({
  type: SET_LOADING_ACTIVITIES,
  loadingActivities,
});

export const setLead = (lead: ILead): Action => ({
  type: SET_LEAD,
  lead,
});

export const setLeadActivities = (leadActivitiesInfo: ILeadActivitiesInfo): Action => ({
  type: SET_LEAD_ACTIVITIES,
  leadActivitiesInfo,
});

export const showActivityModalForm = (modalAction: ActivityModalActionType): Action => ({
  type: SHOW_ACTIVITY_MODAL_FORM,
  modalAction,
});

export const hideActivityModalForm = (): Action => ({
  type: HIDE_ACTIVITY_MODAL_FORM,
});

export const setSelectedActivity = (activity: IActivity): Action => ({
  type: SET_SELECTED_ACTIVITY,
  activity,
});

export const setSelectedLeadId = (leadId: number): Action => ({
  type: SET_SELECTED_LEAD,
  selectedLeadId: leadId,
});

export const setLeadProperties = (properties: any): Action => ({
  type: SET_LEAD_PROPERTIES,
  properties,
});

export const setLeadPropertiesCount = (count: number): Action => ({
  type: SET_LEAD_PROPERTIES_COUNT,
  count,
});

export const setLeadAvailability = (selectedAvailability: number): Action => ({
  type: SET_LEAD_AVAILABILITY,
  selectedAvailability,
});

export const getLead = async (id: string, mappedFilters, dispatch: Dispatch, token: string) => {
  dispatch(setLoading(true));

  try {
    const response = await leadService.getLead(id, mappedFilters, token);
    if (response) {
      const leadData = response.data;
      const lead = parseGetLeadData(leadData);
      dispatch(setLead(lead));
    }
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const updateLeadQualfication = async (
  id: number,
  data: QualificationForm,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const requestBody = parseUpdateLeadQualification(data);
    const request = await leadService.updateLeadsQualification(id, requestBody, token);

    if (request) {
      Swal.fire({
        type: "success",
        text: "Lead actualizado correctamente.",
      }).then(() => {
        dispatch(setLoading(false));
      });
    }
  } catch (e) {
    dispatch(setLoading(false));
    Swal.fire({
      type: "error",
      text: "No se pudo calificar al Lead. Intente nuevamente.",
    });
    throw e;
  }
};

export const getLeadActivities = async (
  leadId: string,
  mappedFilters: LeadActivitiesFilters,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoadingActivities(true));
  const response = await leadService.getLeadActivities(leadId, mappedFilters, token);
  const { count, results } = response.data;
  const parsedActivitiesResult = parseGetLeadActivitiesResult(results);
  const leadActivitiesInfo = {
    count,
    results: parsedActivitiesResult,
    isLoadingActivities: false,
  };
  dispatch(setLeadActivities(leadActivitiesInfo));
  dispatch(setLoadingActivities(false));
};

export const createLeadActivity = async (data: IActivity, dispatch: Dispatch, token: string) => {
  dispatch(setLoading(true));

  try {
    const requestBody = parseCreateLeadActivity(data);

    const request = await leadService.postLeadActivity(requestBody, token);

    if (request) {
      Swal.fire({
        type: "success",
        text: "Actividad creada correctamente.",
      }).then(() => {
        dispatch(setLoading(false));
        window.location.reload();
      });
    }
  } catch (e) {
    dispatch(setLoading(false));
    Swal.fire({
      type: "error",
      text: "Hubo un problema al crear actividad. Intente nuevamente.",
    });
    throw e;
  }
};

export const completeLeadActivity = async (
  id: number,
  data: IActivity,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const requestBody = {
      is_completed: true,
      toky_call_id: data?.tokyCallId,
    };
    if (!data?.tokyCallId) delete requestBody.toky_call_id;
    const request = await leadService.updateLeadActivity(id, requestBody, token);

    if (request) {
      Swal.fire({
        type: "success",
        text: "Actividad completada correctamente.",
      }).then(() => {
        dispatch(setLoading(false));
        window.location.reload();
      });
    }
  } catch (e) {
    dispatch(setLoading(false));
    Swal.fire({
      type: "error",
      text: "Hubo un problema al completar la actividad. Intente nuevamente.",
    });
    throw e;
  }
};

export const updateLeadActivity = async (
  id: string | number,
  data: IActivity,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const requestBody = {
      end_date: moment(data.endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      detail: data.detail,
    };
    const request = await leadService.updateLeadActivity(id, requestBody, token);

    if (request) {
      Swal.fire({
        type: "success",
        text: "Actividad actualizada correctamente.",
      }).then(() => {
        dispatch(setLoading(false));
        window.location.reload();
      });
    }
  } catch (e) {
    dispatch(setLoading(false));
    Swal.fire({
      type: "error",
      text: "Hubo un problema al actualizar la actividad. Intente nuevamente.",
    });
    throw e;
  }
};

export const getLeadProperties = async (
  pageSize: number,
  currentPage: number,
  email: string,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const response = await leadService.getLeadProperties(pageSize, currentPage, email, token);
    if (response) {
      const propertiesData = response.data?.results;
      const properties = parseGetLeadProperties(propertiesData);
      dispatch(setLeadProperties(properties));
      dispatch(setLeadPropertiesCount(response.data?.count));
    }
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};
