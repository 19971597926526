import { Controller, useFormContext } from "react-hook-form";
import clx from "classnames";

import { FormControl, FormControlLabel, Grid, Typography } from "@material-ui/core";

import useControlledCustomTextFieldStyles from "./ControlledCustomTextFieldStyles";
import CustomNumberTextField from "./components/CustomNumberTextField";
import { getTextFieldNumber } from "./utils/utils";

interface Props {
  label: React.ReactNode;
  id: string;
  defaultValue?: string | number;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  textFieldProps?: Partial<React.ComponentProps<typeof CustomNumberTextField>>;
  disabled?: boolean;
  labelClassName?: string;
  format?: (e: string) => string;
  bordered?: boolean;
  warn?: (value: string) => boolean;
  warningMessage?: string;
  wrapperClassName?: string;
}

const ControlledCustomTextField = ({
  label = "",
  id,
  defaultValue,
  controllerProps,
  textFieldProps,
  labelClassName,
  disabled = false,
  bordered = false,
  format = (str) => str,
  warn = () => false,
  wrapperClassName,
}: Props) => {
  const classes = useControlledCustomTextFieldStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState: { invalid, error } }) => (
        <FormControl className={clx(classes.wrapper, wrapperClassName)} error={!!error}>
          <FormControlLabel
            classes={{
              label: clx(classes.label, labelClassName),
              root: classes.wrapper,
            }}
            label=""
            control={
              <Grid container className={classes.container}>
                <Grid item className={classes.labelContainer}>
                  <Typography className={classes.label}>{label}</Typography>
                </Grid>
                <Grid item className={classes.textContainer}>
                  <CustomNumberTextField
                    disabled={disabled}
                    id={id}
                    value={value}
                    inputRef={ref}
                    onChange={(e) => onChange(getTextFieldNumber(e))}
                    bordered={bordered}
                    onBlur={(e) => {
                      onChange(format(e.target.value));
                    }}
                    error={invalid}
                    errorMessage={error?.message}
                    warning={warn(value)}
                    placeholder="Ingresar"
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                      endAdornment: "%",
                      inputProps: {
                        type: "number",
                        onWheel: (event) => event.currentTarget.blur(),
                        step: "any",
                      },
                    }}
                    {...textFieldProps}
                  />
                </Grid>
              </Grid>
            }
          />
        </FormControl>
      )}
      {...controllerProps}
    />
  );
};

export default ControlledCustomTextField;
