import {
  InspectionReportFeaturesNames,
  InspectionReportFeatureUpdateData,
  InspectionReportUpdateData,
} from "services/propertyService/types";

export const parseInspectionReportGeneralSpaceUpdateDataForm = (
  spaceId: number,
  featureName: InspectionReportFeaturesNames,
  data: InspectionReportFeatureUpdateData
): InspectionReportUpdateData => ({
  spaces: [
    {
      id: spaceId,
      [featureName]: {
        qualification: data.qualification,
        observation: data.observation,
      } as InspectionReportFeatureUpdateData,
    },
  ],
});
