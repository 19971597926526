import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { setAlertInfo } from "context/alertContext/alertActions";
import { useAlert } from "context/alertContext/alertContext";
import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

const useDeleteService = () => {
  const [openModal, setOpenModal] = useState(false);
  const { dispatch } = useAlert();
  const { getToken } = useGetToken();
  const queryClient = useQueryClient();

  const handleModal = () => setOpenModal((prev) => !prev);

  const { mutate: deleteService, isLoading } = useMutation(
    async (formData: { company: string; propertyId: number }) => {
      const authToken = await getToken();
      await propertyService.deleteBasicServiceIntegrations(
        formData.propertyId,
        formData.company.toLowerCase().replace(/ /g, "_"),
        authToken
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["propertyServices"]);
        handleModal();
      },
      onSuccess: () =>
        dispatch(
          setAlertInfo({
            open: true,
            head: "Servicio básico eliminado correctamente",
            type: "success",
          })
        ),
      onError: (e: AxiosError<{ detail?: string }>) => {
        const reason = e?.response;
        dispatch(
          setAlertInfo({
            open: true,
            head: "No se pudo eliminar el servicio básico",
            type: "error",
            body: `Error: ${reason ? reason?.data?.detail : e}`,
          })
        );
      },
    }
  );

  return {
    deleteService,
    isLoading,
    openModal,
    handleModal,
  };
};

export default useDeleteService;
