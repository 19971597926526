import { makeStyles, Theme } from "@material-ui/core";

const useApplicantBankAccountStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
    marginBottom: theme.spacing(2),
  },
}));

export default useApplicantBankAccountStyles;
