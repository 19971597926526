import * as yup from "yup";

export const markAsPaidSchema = yup.object({
  paymentDate: yup.string().required("Obligatorio"),
  amount: yup
    .number()
    .positive("Debe ser un número positivo y distinto de 0")
    .required("Obligatorio"),
  reason: yup.string().required("Obligatorio"),
});
