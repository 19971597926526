import { v4 as uuidv4 } from "uuid";
import round from "lodash/round";

import {
  ExternalPortalPublication,
  PublicationLinkInformation,
  QuotaByMacrozone,
  TotalQuota,
} from "models/Integration";
import {
  IndexExternalPortalPublicationsResponse,
  IndexQuotaByMacrozoneResponse,
  IndexTotalQuotaResponse,
} from "./types";

export const parseIndexExternalPortalPublicationsResponse = (
  results: IndexExternalPortalPublicationsResponse[]
): ExternalPortalPublication[] =>
  results.map((result) => ({
    id: uuidv4(),
    propertyId: result.property.id,
    propertyUid: result.property.uid,
    businessType: result.portal_plan.business_type,
    portalName: result.portal_plan.portal.name,
    portalPlan: result.portal_plan.publication_type.publication_pretty_name,
    houmOwnerName: `${result.property.houm_owner.name} ${result.property.houm_owner.last_name}`,
    status: result.status,
    publicationLinks: result.publication_url.map((publicationInfo) => ({
      portalName: publicationInfo.external_portal.name,
      portalUrl: publicationInfo.url,
    })) as PublicationLinkInformation[],
  }));

export const parseIndexTotalQuotaResponse = (results: IndexTotalQuotaResponse[]): TotalQuota[] =>
  results.map((result) => ({
    id: result.id,
    businessType: result.business_type,
    quota: result.slots,
    percentage: parseToPercentage(result.used, result.slots, 3),
    portalName: result.portal.name,
    portalPlan: result.publication_type.publication_pretty_name,
    publishedStock: result.used,
    freeQuota: result.free_quota,
  }));

export const parseIndexQuotaByMacrozoneResponse = (
  results: IndexQuotaByMacrozoneResponse[]
): QuotaByMacrozone[] =>
  results.map((result) => ({
    id: result.id,
    businessType: result.portal_plan.business_type,
    quota: result.total_slots,
    percentage: parseToPercentage(
      result.total_slots - result.available_slots,
      result.total_slots,
      3
    ),
    portalName: result.portal_plan.portal.name,
    portalPlan: result.portal_plan.publication_type.publication_pretty_name,
    publishedStock: result.total_slots - result.available_slots,
    macrozone: result.macrozone.name,
    macrozoneId: result.macrozone.id,
  }));

const parseToPercentage = (fraction: number, total: number, decimalPlaces = 2): string => {
  if (!fraction || !total) return "No aplica";
  const roundedPercentage = round((100 * fraction) / total, decimalPlaces);

  return `${roundedPercentage} %`;
};
