import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

import { useEvaluation } from "context/evaluationContext/evaluationContext";
import { useGetToken } from "hooks";
import usePostInsuranceCompanyContract from "domains/insuranceCompanies/hooks/usePostInsuranceCompanyContract";
import { Locale } from "models/Countries";
import { EvaluationModel } from "models/Evaluation";
import contractService from "services/contractService";

import { ContractPersonEvaluation, parseContractData } from "../utils";
import useUpdateEvaluationContract from "./useUpdateEvaluationContract";

interface FormDataInitContract {
  evaluationData: ContractPersonEvaluation[] | EvaluationModel[];
  selectedDate: string;
  tenantHasPets: boolean;
  adultHabitants: string | number;
  childHabitants: string | number;
  insuranceCompany: string;
}

export interface ErrorProps {
  text: string;
  id: number;
}

const useInitContract = () => {
  const { country } = useParams<{ country: Locale }>();
  const { getToken } = useGetToken();
  const { state } = useEvaluation();
  const { singleEvaluation, applicant, houmer } = state;
  const { updateEvaluation, isLoading: isLoadingUpdateEval } = useUpdateEvaluationContract();
  const { postInsuranceCompanyContract, submittingInsuranceCompanyContract } =
    usePostInsuranceCompanyContract();

  const errors: ErrorProps[] = [];

  if (!singleEvaluation?.commerciallyApproved.length)
    errors.push({
      id: 1,
      text: "No se encontraron evaluaciones comercialmente aprobadas",
    });
  const evaluations = singleEvaluation.commerciallyApproved;
  const mainApplicantId = evaluations[0]?.main_applicant?.id;
  const applicantId = applicant && singleEvaluation.evaluations[0].brokerage ? applicant?.id : null;

  const getHabitantsDescription = (
    adultHabitants: string | number,
    childHabitants: string | number
  ) => `${Number(adultHabitants)} adultos y ${Number(childHabitants)} niños`;

  const { mutate: initContract, isLoading } = useMutation(
    async (formData: FormDataInitContract) => {
      const authToken = await getToken();
      const data = parseContractData(
        formData.evaluationData,
        formData.selectedDate,
        getHabitantsDescription(formData.adultHabitants, formData.childHabitants),
        houmer?.id || null,
        formData.tenantHasPets,
        country
      );

      if (applicantId) {
        data["applicant"] = applicantId;
      }
      const response = await contractService.init(data, authToken);
      return response.data;
    },
    {
      onSuccess: (res, data) => {
        updateEvaluation({
          contractId: res.contract_id,
          selectedDate: data.selectedDate,
          habitants: getHabitantsDescription(data.adultHabitants, data.childHabitants),
          mainApplicantId,
        });
        postInsuranceCompanyContract({
          contractId: res.contract_id,
          guaranteedMonths: null,
          insuranceCompanyId: data.insuranceCompany,
        });
      },

      onError: (e: Error) => {
        const error = e as AxiosError;
        const { response } = error;
        Swal.fire(
          "Error",
          `No se pudo crear contrato, error: ${JSON.stringify(response?.data) ?? e}`,
          "error"
        );
      },
    }
  );

  return {
    initContract,
    isLoading:
      isLoading ||
      isLoadingUpdateEval ||
      !evaluations?.length ||
      submittingInsuranceCompanyContract,
    errors,
  };
};

export default useInitContract;
