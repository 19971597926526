import { Typography, SvgIcon } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useMultifamily } from "context/multifamilyContext/multifamily/multifamilyContext";
import { setIsOpenFilterModal } from "context/multifamilyContext/multifamily/multifamilyActions";
import FilterModal from "./FilterModal";
import SearchMultiFamily from "./SearchMultiFamily";

import useStyles from "./HeaderStyles";

function Header() {
  const classes = useStyles();
  const { dispatch } = useMultifamily();
  return (
    <div className={classes.container}>
      <div className={classes.rows}>
        <Typography className={classes.title}>Multifamily</Typography>
        <DesignSystemButton
          label="Crear multifamily"
          size="medium"
          variant="primary"
          href="/admin/multifamily/create"
          sameTab
        />
      </div>
      <div className={classes.rows} style={{ display: "none" }}>
        {" "}
        {/* Delete this display none when backend filters are ready */}
        <div className={classes.searchInputContainer}>
          <SearchMultiFamily />
        </div>
        <FilterModal />
        <DesignSystemButton
          label="Filtrar"
          Icon={
            <SvgIcon>
              <Menu />
            </SvgIcon>
          }
          size="medium"
          variant="secondary"
          onClick={() => dispatch(setIsOpenFilterModal(true))}
        />
      </div>
    </div>
  );
}
export default Header;
