import { IoBusinessOutline } from "@houm-com/ui-icons";
import { routeBuilder } from "domains/auth/utils/router-builder";
import Organizations from "./components/organizations";
import Organization from "./components/organization";

export default routeBuilder({
  label: "Organizaciones",
  icon: IoBusinessOutline,
  require: ["read:organizations:all"],
  scope: "permissions",
  strategy: "all",
  children: [
    {
      label: "Listado",
      to: "/organizations",
      path: "/organizations",
      component: Organizations,
    },
    {
      path: "/organizations/:id",
      component: Organization,
    },
  ],
});
