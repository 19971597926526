import { AxiosResponse } from "axios";

import { IPlanFilters } from "hooks/usePlans/utils/types";
import { CountryUpperCase } from "models/Countries";
import { IPlan } from "models/Plan";
import { Paginate } from "models/ResponseWithPaginate";

import { createHttp } from "../helper";
import { IPlanCategoryResponse, IPlanPost } from "./types";

export default {
  postPlan(data: IPlanPost, token: string) {
    return createHttp(token).post(`/v2/plans/`, data);
  },
  getPlans(
    country: CountryUpperCase,
    token: string,
    filters?: IPlanFilters
  ): Promise<AxiosResponse<IPlan[]>> {
    return createHttp(token).get(`/v2/plans/?country=${country}`, { params: filters });
  },
  getOptionList(country: CountryUpperCase, token: string) {
    return createHttp(token).get(`/v2/payment_option?country=${country}`);
  },
  enableDisablePlan(planId: string, isActive: boolean, token: string) {
    return createHttp(token).put(`/v2/plans/${planId}/active/`, {
      active: isActive,
    });
  },
  editPlan(planId: number | string, contract_template: number, token: string) {
    return createHttp(token).patch(`/v2/plans/${planId}/`, { contract_template });
  },
  getPlanById(planId: number, token: string) {
    return createHttp(token).get(`/v2/plans/${planId}/`);
  },
  deletePlan(planId: number, token: string) {
    return createHttp(token).delete(`/v2/plans/${planId}/`);
  },
  getPlanCategories(
    country: CountryUpperCase,
    token: string
  ): Promise<AxiosResponse<Paginate<IPlanCategoryResponse>>> {
    return createHttp(token).get(`/admin/v2/plan_categories/?country=${country}`);
  },
};
