import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModifyPropertyForm } from "models/createProperty";
import { useProperty } from "context/propertyContext/propertyContext";
import { modifyProperty } from "context/propertyContext/propertyActions";
import { useFormNotifications, useGetToken } from "hooks";

import { requiredText } from "utils/yupValidators/property";
import validationSchema from "./validationSchema";

import {
  DealType,
  Address,
  Price,
  PropertyDetails,
  Amenities,
  Brokerage,
  Submit,
  User,
  AmenitiesDetails,
  FormContainer,
  InvesmentType,
} from "../components";

function PropertyForm() {
  const { getToken } = useGetToken();

  const { state, dispatch } = useProperty();

  const methods = useForm<ModifyPropertyForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;

  const onSubmit: SubmitHandler<ModifyPropertyForm> = (data, e) => {
    getToken().then((authToken) => modifyProperty(data, state.property, dispatch, authToken));
  };

  useFormNotifications<ModifyPropertyForm>({ errors, requiredText });

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <User />
          <DealType />
          <InvesmentType />
          <Address />
          <Price />
          <Brokerage />
          <PropertyDetails />
          <AmenitiesDetails />
          <Amenities />
          <Submit />
        </form>
      </FormContainer>
    </FormProvider>
  );
}

export default PropertyForm;
