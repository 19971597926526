import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import DownloadReceipt from "./components/DownloadReceipt";
import useGetReceipts from "./hooks/useGetReceipts";

interface Props {
  country?: Locale;
  movementId: string;
}

const Receipts = ({ movementId, country = "cl" }: Props) => {
  const { data, isError, isLoading } = useGetReceipts({
    country,
    movementId,
  });

  if (isLoading) return <Spinner />;

  if (isError) return <p className="text-p16">Error al cargar los documentos tributarios</p>;

  if (data.length) return <DownloadReceipt receipts={data} />;

  return null;
};

export default Receipts;
