import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { IExitProcessResponse } from "services/contractService/types";
import contractService from "services/contractService";

import useExitProcess from "../../hooks/useExitProcess";
import CancelModalDisclaimer from "./CancelModalDisclaimer";
import useStyles from "./ConfirmModalStyles";
import ModalLayout from "../ModalLayout";

interface Props {
  setClose: () => void;
  isOpen: boolean;
  actionText: string;
  exitProcess: IExitProcessResponse;
}

const ConfirmModal = ({ setClose, isOpen, actionText, exitProcess }: Props) => {
  const classes = useStyles();
  const isCancelAction = actionText === "Cancelar";
  const { mutate: finalizeExitProcess, isLoading: finalizeFinishLoading } = useExitProcess(
    contractService.finalizeExitProcess,
    "Proceso de salida finalizado",
    "Error al finalizar proceso de salida",
    exitProcess,
    setClose
  );
  const { mutate: cancelExitProcess, isLoading: cancelFinishLoading } = useExitProcess(
    contractService.cancelExitProcess,
    "Proceso de salida cancelado",
    "Error al cancelar proceso de salida",
    exitProcess,
    setClose
  );

  return (
    <ModalLayout
      open={isOpen}
      handleClose={() => setClose()}
      title={`¿Estás seguro que quieres ${actionText.toLocaleLowerCase()} proceso de salida?`}>
      {isCancelAction && <CancelModalDisclaimer />}
      <div className={classes.container}>
        <DesignSystemButton
          label="Confirmar"
          size="small"
          variant="primary"
          onClick={() => (isCancelAction ? cancelExitProcess(null) : finalizeExitProcess(null))}
          isLoading={isCancelAction ? cancelFinishLoading : finalizeFinishLoading}
        />
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
