import { makeStyles, Theme } from "@material-ui/core";

const useVerifikStyles = makeStyles((theme: Theme) => ({
  ctaContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  formContent: {
    width: "100%",
  },
  titleContent: {
    margintop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    justifyContent: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default useVerifikStyles;
