import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useSubmitButton from "./SubmitButtonStyles";

interface Props {
  buttonText?: string;
  loading?: boolean;
}

export default function CustomCallToAction({ loading = false }: Props) {
  const classes = useSubmitButton();
  return (
    <div className={classes.ctaContent}>
      <Button variant="contained" color="primary" type="submit" className={classes.ctaButton}>
        {loading ? <CircularProgress size={14} color="secondary" /> : "Crear"}
      </Button>
    </div>
  );
}
