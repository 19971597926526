import { useEffect } from "react";

import { Typography } from "@material-ui/core";

import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { useProperty } from "context/propertyContext/propertyContext";
import { setInspectionReportCurrentSpaceFilter } from "context/propertyContext/propertyActions";
import useStyles from "./SpacesFilterStyles";

function SpacesFilter() {
  const classes = useStyles();
  const { state, dispatch } = useProperty();

  const availableSpaces = state.inspectionReport.data?.spaces ?? [];

  const handleSetSpaceFilter = (spaceName: string) =>
    dispatch(setInspectionReportCurrentSpaceFilter(spaceName));

  useEffect(() => {
    if (availableSpaces.length === 0) return;
    dispatch(setInspectionReportCurrentSpaceFilter(availableSpaces[0].name));
  }, [availableSpaces]);

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Elija el espacio que quiere revisar</Typography>
      <div className={classes.spacesFiltersContainer}>
        {availableSpaces.map((space) => (
          <CustomButton
            key={space.id}
            className={classes.filterButton}
            type="button"
            kind="secondary"
            active={state.inspectionReport.currentSpaceName === space.name}
            onClick={() => handleSetSpaceFilter(space.name)}>
            {space.name}
          </CustomButton>
        ))}
      </div>
    </div>
  );
}

export default SpacesFilter;
