import { PersonAddOutlined } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

import { HeaderWithIcon } from "Components/Admin/UIComponents";
import DashboardLayout from "Components/Admin/UIComponents/DashboardLayout";
import CardContainer from "Components/Admin/UIComponents/Cards/CardContainer";
import AppraiserForm from "./appraiserForm";
import MessageModal from "../profile/Components/MessageModal";

function NewAooraiserIndex() {
  return (
    <DashboardLayout>
      <MessageModal />
      <Grid container alignItems="flex-start">
        <HeaderWithIcon title="Control Tower: Crear Houmer" Icon={PersonAddOutlined} />
        <Grid item xs={12}>
          <CardContainer loading={false}>
            <AppraiserForm />
          </CardContainer>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default NewAooraiserIndex;
