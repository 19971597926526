import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useSubmitButton from "./SubmitButtonStyles";

interface Props {
  loading?: boolean;
  clearAction: () => void;
}

export default function CustomCallToAction({ loading = false, clearAction }: Props) {
  const classes = useSubmitButton();
  return (
    <div className={classes.ctaContent}>
      <Button variant="contained" color="primary" type="submit" className={classes.ctaButton}>
        <Loading loading={loading}>
          <Typography className={classes.ctaApply}>Aplicar filtros</Typography>
        </Loading>
      </Button>
      <Button variant="text" color="primary" className={classes.ctaButton} onClick={clearAction}>
        <Loading loading={loading}>
          <Typography> Borrar </Typography>
        </Loading>
      </Button>
    </div>
  );
}
