import { makeStyles, Theme } from "@material-ui/core";

const useRenewalCustomInputStyles = makeStyles<Theme>((theme) => ({
  inputContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(1),
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  noBorder: {
    border: `0.5px solid ${theme.palette.grey[500]}`,
    borderRadius: 8,
  },
  customTextField: {
    fontSize: "1rem",
    padding: theme.spacing(1),
  },
}));

export default useRenewalCustomInputStyles;
