import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import omitBy from "lodash/omitBy";

import { useGetToken } from "hooks";
import { OptionProps } from "Components/Admin/UIComponents/RemoteSuggester/types";
import { isEmpty } from "utils";

interface Props<T> {
  inputQuery: string;
  queryParamName?: string;
  filters?: Record<string, string>;
  indexService: (filters: Record<string, string>, token: string) => Promise<AxiosResponse<T>>;
  parser: (responseData: T) => OptionProps[];
}

const useGetFilteredData = <T>({
  indexService,
  filters = {},
  inputQuery,
  queryParamName = "search",
  parser,
}: Props<T>) => {
  const { getToken } = useGetToken();
  const [results, setResults] = useState<OptionProps[]>([]);
  const [loading, setLoading] = useState(false);

  const getDataBySearchQuery = async (input: string, token: string) => {
    try {
      setLoading(true);
      const formattedFilters = { ...filters, [queryParamName]: input };
      const response = await indexService(omitBy(formattedFilters, isEmpty), token);
      setResults(parser(response.data));
    } catch (er) {
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken().then((authToken) => getDataBySearchQuery(inputQuery, authToken));
  }, [inputQuery, filters]);

  return { results, loading };
};

export default useGetFilteredData;
