import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import moment from "moment";

import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import Button from "@houm-com/ui/Button";
import ModalDialog from "@houm-com/ui/ModalDialog";

import ReservationStatus from "Components/Admin/UIComponents/Cards/ReservationStatus";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { PropertyData } from "models/Property";
import { countryCode } from "utils";
import { EVALUATION_FORM_URL } from "env";

import usePropertyApplicantsStyles, { classes } from "./PropertyApplicantsStyles";
import ApplicantsForm from "./ApplicantsForm";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
};

const idConfig: GridColDef = {
  field: "",
  resizable: false,
  flex: 1,
  filterable: false,
  hide: false,
};

const columns: GridColumns = [
  {
    ...idConfig,
    field: "name",
    headerName: "Postulante",
    renderCell: ({ row }: GridCellParams) => (
      <Link to={`/admin/applicants/${row.id}`} target="_blank">
        {`${row.user?.name ?? row?.name} ${row.user?.last_name ?? row?.last_name} ${
          row.user?.second_last_name ?? row?.second_last_name
        }`}
      </Link>
    ),
  },
  {
    ...columnsConfig,
    field: "phone",
    headerName: "Teléfono",
    renderCell: ({ row }: GridCellParams) => row.user?.phone,
  },
  {
    ...columnsConfig,
    field: "reservation",
    headerName: "Reservación",
    sortable: false,
    renderCell: ({ row }: GridCellParams) => <ReservationStatus applicantId={row.id} />,
  },
  {
    ...columnsConfig,
    field: "origin",
    headerName: "Origen",
    renderCell: ({ value }: GridCellParams) => value,
  },
  {
    ...idConfig,
    field: "created_at",
    headerName: "Creado",
    renderCell: ({ row }: GridCellParams) => moment(row.created_at).format("LLLL"),
  },
];

interface Props {
  property: PropertyData;
}

function PropertyApplicants({ property }: Props) {
  const classesMaterial = usePropertyApplicantsStyles();
  const [openModal, setOpenModal] = useState(false);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleRedirect = () => {
    const url = `${EVALUATION_FORM_URL}/${countryCode(property.country)}/form?property=${
      property.uid
    }&brokerage=false`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button onClick={() => setOpenModal(true)} variant="primary" size="lg">
          Crear Postulante
        </Button>
        <Button onClick={handleRedirect} variant="primary" size="lg">
          Crear Sin Corretaje (BETA)
        </Button>
      </div>
      <CollapseCard title="Tabla Postulantes">
        <div className={classes.collapse}>
          <CustomDataGrid
            className={classesMaterial.table}
            rowCount={property?.applicants.length}
            columns={columns}
            rows={property?.applicants}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
          />
        </div>
      </CollapseCard>
      <ModalDialog
        onClose={closeModal}
        isOpen={openModal}
        title="Ingresa un postulante aquí"
        subtitle="Completa la información requerida. Ten en consideración que nunca pedimos aval y que puedes complementar la renta">
        <SnackbarProvider>
          <ApplicantsForm
            country={countryCode(property.country)}
            propertyUid={property.uid}
            propertyId={property.id}
            closeModal={closeModal}
          />
        </SnackbarProvider>
      </ModalDialog>
    </>
  );
}

export default PropertyApplicants;
