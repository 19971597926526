import { Card, CardContent, Grid, List } from "@material-ui/core";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import ClosingSpecificMovement from "./ClosingSpecificMovement";
import useClosingDetailsStyles from "./ClosingDetailsStyles";

interface IDetails {
  description: string;
  amount: number;
  type: string;
  currency: string;
}
interface Props {
  details: IDetails[];
  type: string;
}

function ClosingDetails({ details, type }: Props) {
  const classes = useClosingDetailsStyles();

  return (
    <CollapseCard
      title={`Desglose del cierre ${type === "lessee" ? "arrendatario" : "propietario"}`}>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item container className={classes.gridContainer}>
              <List className={classes.rootList}>
                {details.map((detail) =>
                  detail.amount ? (
                    <ClosingSpecificMovement
                      description={detail.description}
                      amount={detail.amount}
                      type={detail.type}
                      currency={detail.currency}
                      key={`${type}-${detail.amount}-${detail.description}`}
                    />
                  ) : null
                )}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CollapseCard>
  );
}

export default ClosingDetails;
