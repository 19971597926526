import { IMarkAsPaid } from "services/newPaymentService/types";

import { IFormData } from "./types";
import { format } from "date-fns";

export const parseFormData = (data: IFormData, movementUid: string): IMarkAsPaid => ({
  movement_id: movementUid,
  payment_date: format(new Date(data.paymentDate), "yyyy-MM-dd"),
  value: data.amount,
  reason: data.reason,
});
