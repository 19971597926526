import { Action, ActionType, CancelReasons, Dispatch } from "./demandScheduledTypes";
import demandService from "services/demandService";
import {
  parseVisits,
  parseHoumers,
  parseAgents,
  parseReasons,
} from "Components/Admin/administrator/demand/visits/Tenants/Components/utils/parseVisits";
import { CountryUpperCase } from "models/Countries";

export const setIsIsOpenFilterModal = (isOpenFilterModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_FILTER_MODAL,
  isOpenFilterModal,
});

export const setIsOpenInfoModal = (isOpenInfoModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_INFO_MODAL,
  isOpenInfoModal,
});

export const setIsOpenNewsModal = (isOpenNewsModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_NEWS_MODAL,
  isOpenNewsModal,
});

export const setIsOpenCancelModal = (isOpenCancelModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_CANCEL_MODAL,
  isOpenCancelModal,
});

export const SetIsOpenAsignCtAgentModal = (isOpenAsignCtAgentModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ASIGN_CT_AGENT_MODAL,
  isOpenAsignCtAgentModal,
});

export const SetIsOpenAsignPropietaryModal = (isOpenAsignPropietaryModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ASIGN_PROPIETARY_MODAL,
  isOpenAsignPropietaryModal,
});

export const SetIsOpenAsignFailModal = (isOpenAsignFailModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ASIGN_FAIL_MODAL,
  isOpenAsignFailModal,
});

export const SetIsOpenAsignSuccessModal = (isOpenAsignSuccessModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ASIGN_SUCCESS_MODAL,
  isOpenAsignSuccessModal,
});

export const SetIsOpenAsignProcessingModal = (isOpenAsignProcessingModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_ASIGN_PROCESSING_MODAL,
  isOpenAsignProcessingModal,
});

export const setCountry = (country): Action => ({
  type: ActionType.SET_COUNTRY,
  country,
});

export const setScheduleId = (scheduleId): Action => ({
  type: ActionType.SET_SCHEDULE_ID,
  scheduleId,
});

export const setAppraiserId = (appraiserId): Action => ({
  type: ActionType.SET_APPRAISER_ID,
  appraiserId,
});

export const setVisits = (visits): Action => ({
  type: ActionType.SET_VISITS,
  visits,
});

export const setHoumers = (houmers): Action => ({
  type: ActionType.SET_HOUMERS,
  houmers,
});

export const setCancelReasons = (cancelReasons): Action => ({
  type: ActionType.SET_CANCEL_REASONS,
  cancelReasons,
});

export const setAgents = (agents): Action => ({
  type: ActionType.SET_AGENTS,
  agents,
});

export const setAgent = (ctAgent): Action => ({
  type: ActionType.SET_AGENT,
  ctAgent,
});

export const setErrorMsg = (errorMsg): Action => ({
  type: ActionType.SET_ERROR_MSG,
  errorMsg,
});

export const setRoles = (roles): Action => ({
  type: ActionType.SET_ROLES,
  roles,
});

export const setInfo = (contactInfo): Action => ({
  type: ActionType.SET_INFO,
  contactInfo,
});

export const setNews = (news): Action => ({
  type: ActionType.SET_NEWS,
  news,
});

export const setDeal = (deal): Action => ({
  type: ActionType.SET_DEAL,
  deal,
});

export const setTotalVisits = (totalVisits): Action => ({
  type: ActionType.SET_TOTAL_VISITS,
  totalVisits,
});

export const setFilters = (filters): Action => ({
  type: ActionType.SET_FILTERS,
  filters,
});

export const setIsLoading = (isLoading): Action => ({
  type: ActionType.SET_LOADING,
  isLoading,
});

//fetching
interface Params {
  country?: CountryUpperCase;
  page?: number;
  ordering?: string;
  page_size?: number;
  business_type?: string;
  begin_date?: string;
  end_date?: string;
  ct_agent?: string;
  houmer?: string;
  third_coord?: string | number;
  status?: string | number;
  search?: string;
}
export const getVisits = async (
  dispatch: Dispatch,
  params: Params,
  authToken: string,
  cancelToken: string
) => {
  try {
    dispatch(setVisits([]));
    dispatch(setIsLoading(true));
    const visits = await demandService.getVisits(params, authToken, cancelToken);
    dispatch(setVisits(parseVisits(visits.data.results)));
    dispatch(setTotalVisits(visits.data.count));
  } catch {
    const errorMsg = "No se han podido obtener las visitas";
    dispatch(setErrorMsg(errorMsg));
    dispatch(setVisits([]));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getHoumers = async (
  dispatch: Dispatch,
  country: CountryUpperCase,
  authToken: string
) => {
  try {
    dispatch(setHoumers([]));
    const houmers = await demandService.getHoumers(authToken, country);
    dispatch(setHoumers(parseHoumers(houmers.data)));
  } catch {
    const errorMsg = "No se han podido obtener los appraisers";
    dispatch(setErrorMsg(errorMsg));
    dispatch(setHoumers([]));
  }
};

export const getAgents = async (dispatch: Dispatch, authToken: string) => {
  try {
    dispatch(setAgents([]));
    const agents = await demandService.getAgents(authToken);
    dispatch(setAgents(parseAgents(agents.data.result)));
  } catch {
    const errorMsg = "No se han podido obtener los agentes";
    dispatch(setErrorMsg(errorMsg));
    dispatch(setAgents([]));
  }
};

export const getCancelReasons = async (dispatch: Dispatch, authToken: string) => {
  try {
    dispatch(setCancelReasons([]));
    const reasons = await demandService.getCancelReasons(authToken);
    dispatch(setCancelReasons(parseReasons(reasons.data.cancel_reasons)));
  } catch {
    const errorMsg = "No se han podido obtener las razones de cancelación";
    dispatch(setErrorMsg(errorMsg));
    dispatch(setCancelReasons([]));
  }
};

//updates
export const setCtAgent = async (
  dispatch: Dispatch,
  authToken: string,
  scheduleId: number,
  agentId: number
) => {
  try {
    dispatch(SetIsOpenAsignCtAgentModal(false));
    dispatch(SetIsOpenAsignProcessingModal(true));
    await demandService.setAgent(authToken, scheduleId, agentId);
    dispatch(SetIsOpenAsignSuccessModal(true));
  } catch ({
    response: {
      data: { error },
    },
  }) {
    dispatch(setErrorMsg(error));
    dispatch(SetIsOpenAsignFailModal(true));
  } finally {
    dispatch(SetIsOpenAsignProcessingModal(false));
  }
};

export const setConfirmation = async (
  dispatch: Dispatch,
  authToken: string,
  scheduleId: number,
  thirdParty: string
) => {
  try {
    dispatch(SetIsOpenAsignPropietaryModal(false));
    dispatch(SetIsOpenAsignProcessingModal(true));
    await demandService.setConfirmStatus(authToken, scheduleId, thirdParty);
    dispatch(SetIsOpenAsignSuccessModal(true));
  } catch {
    dispatch(SetIsOpenAsignFailModal(true));
  } finally {
    dispatch(SetIsOpenAsignProcessingModal(false));
  }
};

export const setCancelation = async (
  dispatch: Dispatch,
  authToken: string,
  scheduleId: number,
  reason: CancelReasons,
  cancelTxt: string
) => {
  try {
    dispatch(SetIsOpenAsignPropietaryModal(false));
    dispatch(SetIsOpenAsignProcessingModal(true));
    await demandService.setCancelStatus(authToken, scheduleId, reason, cancelTxt);
    dispatch(SetIsOpenAsignSuccessModal(true));
  } catch {
    dispatch(SetIsOpenAsignFailModal(true));
  } finally {
    dispatch(SetIsOpenAsignProcessingModal(false));
  }
};

export const setScheduleNews = async (
  dispatch: Dispatch,
  authToken: string,
  scheduleId: number,
  comment: string
) => {
  try {
    dispatch(SetIsOpenAsignProcessingModal(true));
    await demandService.setScheduleNewsComment(authToken, scheduleId, comment);
    dispatch(SetIsOpenAsignSuccessModal(true));
  } catch {
    dispatch(SetIsOpenAsignFailModal(true));
  } finally {
    dispatch(SetIsOpenAsignProcessingModal(false));
    dispatch(setIsOpenNewsModal(false));
  }
};
