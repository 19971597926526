import { makeStyles } from "@material-ui/core";

const useCancelationStyles = makeStyles((theme) => ({
  reasonsContainer: {
    margin: "0px auto",
    width: "80%",
  },
  actionButton: {
    textTransform: "none",
    borderRadius: 20,
    minWidth: 150,
    fontWeight: 700,
  },
  buttonContainers: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  openReasonContainer: {
    margin: "0px auto",
    width: "70%",
  },
  openReasonTextarea: {
    width: "100%",
    height: theme.spacing(16),
    padding: theme.spacing(1, 1.5),
    border: "1px solid",
    borderColor: theme.palette.grey["200"],
    borderRadius: 10,
    resize: "none",
  },
}));

export default useCancelationStyles;
