import { useEffect, ReactNode } from "react";

import { GridColumns, GridColDef, GridCellParams } from "@material-ui/x-grid";
import moment from "moment";

import { PropertyData, ISchedules } from "models/Property";
import { Typologies } from "context/multifamilyContext/typology/typologyTypes";
import { setCurrentPage, setPageSize } from "context/tableContext/tableActions";
import { useTable } from "context/tableContext/tableContext";
import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import PoperCell from "Components/Admin/administrator/properties/show/tabs/scheduler/PoperCell";

import ScheduleApplicant from "../ScheduleApplicant";
import ScheduleComment from "../ScheduleComment";
import useStyles from "./TableStyles";

interface Props {
  property?: PropertyData;
  typology?: Typologies;
}

function Table({ property = null, typology = null }: Props) {
  const classes = useStyles();

  const { state: tableStates, dispatch } = useTable();
  const { currentPage, pageSize } = tableStates;

  useEffect(() => dispatch(setPageSize(10)), []);

  const renderCellExpand = (value: string | ReactNode, width: number) => (
    <PoperCell value={value} width={width} />
  );

  const columns: GridColumns = [
    {
      ...columnsConfig,
      headerName: "Nombre tipología",
      field: "typologyName",
      headerClassName: classes.firstHeaderCell,
      cellClassName: classes.leftCell,
      flex: 1.2,
      renderCell: () => typology?.name || "------",
    },
    {
      ...columnsConfig,
      headerName: "Fecha",
      field: "created_at",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => moment(value as string).format("DD.MM.YYYY"),
    },
    {
      ...columnsConfig,
      headerName: "Postulante",
      field: "applicantName",
      cellClassName: classes.centerCell,
      renderCell: ({ row, colDef }) =>
        renderCellExpand(
          <ScheduleApplicant schedule={row as ISchedules} scheduleId={row.id} />,
          colDef.computedWidth
        ),
    },
    {
      ...columnsConfig,
      headerName: "Comentario",
      field: "comment",
      cellClassName: classes.centerCell,
      renderCell: ({ row, colDef }: GridCellParams) =>
        renderCellExpand(<ScheduleComment scheduleId={row.id} />, colDef.computedWidth) || "------",
    },
    {
      ...columnsConfig,
      headerName: "Agente",
      field: "agentName",
      cellClassName: classes.centerCell,
      renderCell: ({ row, colDef }) =>
        renderCellExpand(row?.appraiser?.name, colDef.computedWidth) || "------",
    },
    {
      ...columnsConfig,
      headerName: "Editor",
      field: "modifier",
      cellClassName: classes.centerCell,
      renderCell: ({ row, colDef }) =>
        renderCellExpand(
          `${row?.modifier?.name || ""} ${row?.modifier?.last_name || ""} ${
            row?.modifier?.role ? `(${row?.modifier?.role})` : ""
          }`,
          colDef.computedWidth
        ) || "------",
    },
    {
      ...columnsConfig,
      headerName: "Tipo",
      field: "schedule_type",
      cellClassName: classes.centerCell,
      renderCell: ({ row }) => row?.schedule_type?.name || "------",
    },
    {
      ...columnsConfig,
      headerName: "Estado",
      field: "status",
      cellClassName: classes.centerCell,
      renderCell: ({ value }) => value,
    },
    {
      ...columnsConfig,
      headerName: "Motivo cancelación",
      field: "cancel_reason",
      cellClassName: classes.rightCell,
      headerClassName: classes.lastRenderedHeaderCell,
      flex: 1.4,
      renderCell: ({ row }: GridCellParams) => row?.cancel_reason || "------",
    },
  ];

  return (
    <CustomDataGrid
      classes={{
        root: classes.root,
        columnHeader: classes.headerCell,
      }}
      rowCount={property?.schedules?.length || 0}
      columns={columns}
      rows={property?.schedules || []}
      page={currentPage - 1}
      pageSize={pageSize}
      onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
      onPageSizeChange={(params) => {
        dispatch(setPageSize(params));
        dispatch(setCurrentPage(1));
      }}
    />
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

export default Table;
