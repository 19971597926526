import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  shadow?: boolean;
  expanded?: boolean;
}

const useTabMenuStyles = makeStyles<Theme, Props>((theme) => ({
  appBar: {
    boxShadow: ({ shadow }) => (shadow ? "auto" : "none"),
    marginBottom: theme.spacing(2),
  },
  barContainer: {
    margin: ({ expanded }) => (expanded ? -theme.spacing(3) : "auto"),
    marginBottom: theme.spacing(3),
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
  tabRoot: {
    fontSize: "1.125rem",
    textTransform: "unset",
  },
}));

export default useTabMenuStyles;
