import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { IWarrantyRetainer } from "models/Warranty";
import { Locale } from "models/Countries";
import { useFormNotifications } from "hooks";

import useDatePickerStyles, { usePaymentDateModalStyles } from "./PaymentDateModalStyles";
import useChangeWarrantyCondition from "../../../hooks/useChangeWarrantyCondition";
import { paymentDateSchema, requiredText } from "./utils/validationSchema";
import ModalLayout from "../../ModalLayout/ModalLayout";
import ModalButtons from "../../ModalButtons";

interface PaymentDateProps {
  paymentDate: Date;
}

interface Props {
  open: boolean;
  onHandleClose: () => void;
  defaultDate: Date | null;
  countryCode: Locale;
  contractId: number;
  retainer: IWarrantyRetainer;
}

const PaymentDateModal = ({
  open,
  onHandleClose,
  defaultDate,
  contractId,
  countryCode,
  retainer,
}: Props) => {
  const { mutate, isLoading } = useChangeWarrantyCondition(contractId, countryCode, onHandleClose);
  const designSystemCalendarTheme = useDatePickerStyles;
  const classes = usePaymentDateModalStyles();
  const methods = useForm<PaymentDateProps>({
    resolver: yupResolver(paymentDateSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<PaymentDateProps>({ errors, requiredText });

  const onSubmit: SubmitHandler<PaymentDateProps> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 represents Sunday and 6 represents Saturday
  };

  const disableWeekends = (date: Date) => isWeekend(date);

  return (
    <ModalLayout open={open} handleOnClose={onHandleClose} title="Fecha de envío a nómina">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid item container alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <ControlledKeyboardDatePicker
                id="paymentDate"
                label="Elige la fecha en la que se enviará a nómina la garantía"
                defaultValue={defaultDate ? new Date(defaultDate) : new Date()}
                dataPickerProps={{
                  format: "dd/MM/yyyy",
                  variant: "static",
                  disablePast: retainer === "HOUM",
                  disableFuture: retainer === "LESSOR",
                  shouldDisableDate: disableWeekends as (date: unknown) => boolean,
                }}
                datePickerTheme={designSystemCalendarTheme}
                labelClassName={classes.label}
              />
            </Grid>
            <ModalButtons isLoading={isLoading} onHandleClose={() => onHandleClose()} />
          </Grid>
        </form>
      </FormProvider>
    </ModalLayout>
  );
};

export default PaymentDateModal;
