import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";

import service from "domains/evaluations/service";
import { parseSearchProperty } from "../../utils/parsers";
import { ISuggestedProperty } from "../../utils/types";

interface Props {
  valueSearch?: string;
  type?: string;
  params?: Object;
  handleResultData: (properties: ISuggestedProperty[]) => void;
}

const useProperties = ({
  valueSearch = "",
  type = "search",
  params = { page_size: 10 },
  handleResultData,
}: Props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { getToken } = useGetToken();

  const changePage = (num: number) => setPageNumber(num);

  interface IResponse {
    properties: ISuggestedProperty[];
    pageCount: number;
  }

  const {
    isLoading = true,
    isError,
    data = {
      properties: [],
      pageCount: 0,
    },
    isFetching,
    refetch,
  }: UseQueryResult<IResponse, Error> = useQuery<IResponse, Error>(
    [type, valueSearch, pageNumber, params],
    async () => {
      const authToken = await getToken();
      const result = await service.searchProperties(valueSearch, authToken, {
        ...params,
        page: pageNumber,
      });
      const properties = result.data.results;
      const parsedProperties = parseSearchProperty(properties);
      return {
        properties: parsedProperties,
        pageCount: Math.ceil(result.data.count / params["page_size"]),
      };
    },
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess: (result: { properties: ISuggestedProperty[] }) =>
        handleResultData && handleResultData(result?.properties),
    }
  );

  return {
    isLoading,
    isError,
    data,
    changePage,
    refetch,
    isFetching,
  };
};

export default useProperties;
