import { Locale } from "models/Countries";
import { IFormData } from "./types";

const { format } = require("rut.js");

export const parseFormData = (
  data: IFormData,
  country: Locale,
  applicantId: string,
  propertyId: number
) => ({
  email: data.email,
  phone: data.phone.parsedPhone,
  name: data.name,
  last_name: data.lastName,
  second_last_name: data.secondLastName,
  rut: country === "cl" ? format(data.document) : data.document,
  document: country === "cl" ? format(data.document) : data.document,
  document_type: parseInt(data.documentType),
  type: data.type,
  applicant_id: applicantId,
  property_id: propertyId,
});
