import { useFormContext, useWatch } from "react-hook-form";

import FormBlock from "Components/Admin/UIComponents/PropertyForm/components/FormBlock";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useProperty } from "context/propertyContext/propertyContext";
import useUserFormRole from "hooks/useUserFormRole";
import { getGuestParkingSpacesLabel } from "../utils/propertyFormFieldsInfo";

function AmenitiesDetails() {
  const { state } = useProperty();
  const { control } = useFormContext();

  const role = useUserFormRole();

  const type = useWatch({
    control,
    name: "type",
    defaultValue: state.property?.type,
  });

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.property?.country,
  });

  return (
    <FormBlock>
      <h3>Información del edificio o condominio</h3>
      {type === "departamento" && (
        <ControlledTextField
          id="floors"
          label="Número de pisos"
          defaultValue={state.property?.association_amenities?.floors}
          textFieldProps={{ placeholder: "2" }}
        />
      )}
      {role !== "real_estate_developer" && (
        <ControlledTextField
          id="guest_parking_spaces"
          label={getGuestParkingSpacesLabel(country)}
          defaultValue={state.property?.association_amenities?.guest_parking_spaces}
          textFieldProps={{ placeholder: "1" }}
        />
      )}
      <ControlledTextField
        id="association_name"
        label="Nombre del edificio o condominio"
        defaultValue={state.property?.association_amenities?.association_name}
      />
    </FormBlock>
  );
}

export default AmenitiesDetails;
