import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  box: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    fontWeight: 600,
    color: theme.palette.grey[600],
    marginRight: theme.spacing(2),
  },
  text: {
    fontWeight: 600,
    color: theme.palette.grey[900],
  },
  imgContainerBtn: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    width: "4rem",
    height: "4rem",
    borderRadius: "0.5rem",
    overflow: "hidden",
    transition: "0.3s",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(4),
    },
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  img: {
    display: "flex",
    width: "100%",
  },
  modalImgContainer: {
    display: "flex",
    maxHeight: "50vh",
  },
  modalImg: {
    display: "flex",
    width: "100%",
    objectFit: "contain",
  },
}));
