import { Chip } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

import useStyles from "./useFilterTagStyles";

type Props = {
  filter: string;
  onDelete?: () => void;
};

function FilterTag({ filter, onDelete = null }: Props) {
  const classes = useStyles();
  return (
    <Chip
      label={filter}
      className={classes.chip}
      variant="outlined"
      onDelete={onDelete}
      deleteIcon={<CloseOutlined />}
    />
  );
}

export default FilterTag;
