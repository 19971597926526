import { makeStyles, Theme } from "@material-ui/core";

const useFormStyles = makeStyles<Theme>((theme) => ({
  select: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    padding: 0,
  },
  buttonContainer: {
    marginTop: theme.spacing(5.6),
  },
  selectionLabel: {
    transform: "none",
    position: "relative",
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(1),
  },
  selectionContainer: {
    margin: 0,
    paddingLeft: theme.spacing(0.5),
  },
}));

export default useFormStyles;
