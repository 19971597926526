import { SnackbarProvider } from "notistack";

import { TabProvider } from "context/tabContext/tabContext";

import SubscriptionDetail from "./SubscriptionDetail";

export default function SubscripctionHead() {
  return (
    <TabProvider>
      <SnackbarProvider>
        <SubscriptionDetail />
      </SnackbarProvider>
    </TabProvider>
  );
}
