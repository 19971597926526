import * as yup from "yup";

const requiredText = "Campo obligatorio";

export const keysSchema = yup.object({
  key_manager: yup
    .string()
    .required(requiredText)
    .transform((option) => option?.value)
    .typeError(requiredText),
});
