import Button from "@houm-com/ui/Button";

import classes from "./ConfirmLandlordDataStyles";
import { ContractModel, IPerson } from "models/Contract";
import { countryDocuments } from "utils";
import useMarkAsOwner from "./hooks/useMarkAsOwner";

interface Props {
  contract: ContractModel;
  person: IPerson;
  rutIsNull: boolean;
  handleCloseModal: () => void;
}

function ConfirmLandlordData({ contract, person, rutIsNull, handleCloseModal }: Props) {
  const { mutate: markAsOwner, isSubmitting: markAsOwnerLoad } = useMarkAsOwner(
    contract,
    person,
    handleCloseModal
  );
  return (
    <>
      <div className={classes.details}>
        <h6 className={classes.textLabelConfirm}>
          Nombre: <span className={classes.textValue}>{person.name}</span>
        </h6>
        <h6 className={classes.textLabelConfirm}>
          {countryDocuments[contract?.property?.country].label}:{" "}
          <span className={classes.textValue}>
            {contract.property.user.rut || person?.document}
          </span>
        </h6>
        <h6 className={classes.textLabelConfirm}>
          Teléfono: <span className={classes.textValue}>{person.phone}</span>
        </h6>
        <h6 className={classes.textLabelConfirm}>
          Correo electrónico: <span className={classes.textValue}>{person.email}</span>
        </h6>
        <h6 className={classes.textLabelConfirm}>
          Domicilio: <span className={classes.textValue}>{person.address}</span>
        </h6>
      </div>
      <div className={classes.ctaContent}>
        <Button
          isLoading={markAsOwnerLoad}
          onClick={() => markAsOwner()}
          disabled={markAsOwnerLoad || rutIsNull}
          variant="primary"
          size="md">
          Confirmar datos
        </Button>
      </div>
    </>
  );
}

export default ConfirmLandlordData;
