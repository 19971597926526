import { makeStyles, Theme } from "@material-ui/core";

const useNoVoucherStylest = makeStyles((theme: Theme) => ({
  noVoucherRoot: {
    marginTop: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(1, 1, 1, 0),
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    display: "flex",
  },
  icon: { marginRight: theme.spacing(0.5) },
}));

export default useNoVoucherStylest;
