import { Theme, makeStyles } from "@material-ui/core/styles";

const useSignatureCardInfoStyles = makeStyles((theme: Theme) => ({
  infoUserHeader: {
    borderLeft: `2px solid ${theme.palette.grey[900]}`,
    paddingLeft: theme.spacing(2.25),
  },
  signatureContainer: {
    padding: theme.spacing(1.5, 3, 1, 3),
  },
  noSignature: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  noSignatureInfo: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    fontStyle: "italic",
  },
  textTitleLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  textLabelInfo: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    marginBottom: theme.spacing(4),
  },
  chip: {
    background: theme.palette.grey[50],
    maxWidth: 100,
    margin: theme.spacing(1, 0),
  },
  ready: {
    color: "#00982D",
    background: "#E9FFDA",
    border: "1px solid #00982D",
    maxWidth: 80,
    margin: theme.spacing(1, 0),
  },
  button: {
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
    maxWidth: 150,
  },
  deleteButton: {
    border: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500],
    backgroundColor: "white",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[700],
      border: `1px solid ${theme.palette.grey[700]}`,
    },
    maxWidth: 150,
  },
  linkButton: {
    cursor: "pointer",
    color: theme.palette.grey[500],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    textDecoration: "underline",
    transition: "0.3s",
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: theme.palette.grey[900],
    },
  },
  containerButton: {
    paddingLeft: theme.spacing(3),
  },
  buttonGroup: {
    textAlign: "center",
  },
}));

export default useSignatureCardInfoStyles;
