import { SnackbarProvider } from "notistack";
import Search, { SearchProps } from "./Search";

export default function SearchController(props: SearchProps) {
  return (
    <SnackbarProvider>
      <Search {...props} />
    </SnackbarProvider>
  );
}
