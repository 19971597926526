import Swal from "sweetalert2";

import { Box, Typography } from "@material-ui/core";

import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import useBasicServiceModalStyles from "./BasicServiceModalStyles";
import useAvailableServices from "./hooks/useAvailableServices";
import BasicServiceForm from "./components/BasicServiceForm";
import useModifyService from "./hooks/useModifyService";
import ModifyServiceModal from "./components/ModifyServiceModal";
import useBasicServiceForm from "./hooks/useBasicServiceForm";
import { ServiceForm } from "./utils/types";

interface Props {
  company?: string;
  country: Locale;
  clientNumber?: string;
  handleClose: () => void;
  propertyId: number;
  open: boolean;
  title: "Ingresar nuevo" | "Editar";
  update?: boolean;
}

function BasicServiceModal({
  company = "0",
  country,
  clientNumber = "",
  handleClose,
  propertyId,
  open,
  title,
  update = false,
}: Props) {
  const classes = useBasicServiceModalStyles();
  const { isLoading, error, availableServices } = useAvailableServices(country);
  const { handleResetData, onSaveBasicServiceData, basicServiceData, modifyData } =
    useModifyService();
  const { submitService, submitServiceLoading } = useBasicServiceForm(
    propertyId,
    handleClose,
    update,
    handleResetData
  );

  if (isLoading) return <Spinner />;

  if (error) {
    Swal.fire("Error cargando el listado de servicios", error?.message, "error");
    return null;
  }

  const onSubmitData = (data: ServiceForm) => {
    if (!update) return submitService(data);
    onSaveBasicServiceData(data);
  };

  const onModifyBasicService = () => submitService(basicServiceData);

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      {modifyData ? (
        <ModifyServiceModal
          handleClose={handleResetData}
          onSubmitData={onModifyBasicService}
          submitServiceLoading={submitServiceLoading}
        />
      ) : (
        <Box className={classes.addBasicServiceRoot}>
          <Typography className={classes.title}>{`${title} servicio`}</Typography>
          <BasicServiceForm
            availableServices={availableServices}
            company={company}
            clientNumber={clientNumber}
            handleClose={handleClose}
            onSubmitData={onSubmitData}
            submitServiceLoading={submitServiceLoading}
          />
        </Box>
      )}
    </DSModal>
  );
}

export default BasicServiceModal;
