import { Bar } from "react-chartjs-2";
import { BarChartProps } from "context/controlTower/controlTowerTypes";
import { OPTIONS } from "context/controlTower/controlTowerConsts";

const BarChart: React.FC<BarChartProps> = ({ inputData, labels }) => (
  <Bar
    data={{
      labels,
      datasets: [
        {
          label: "",
          data: [inputData.cancelled, inputData.done, inputData.pending],
          backgroundColor: ["#ff5800", "green", "#FFCE56"],
          hoverBackgroundColor: ["red", "green", "#FFCE56"],
        },
      ],
    }}
    options={OPTIONS}
  />
);

export default BarChart;
