import * as yup from "yup";

import { validate } from "utils/yupValidators/property";

const marketTypeValidation = (obj) => {
  if (obj.for_rental || obj.for_sale) {
    return true;
  }

  return new yup.ValidationError(
    "Seleccionar si la propiedad esta a la venta y/o arriendo",
    null,
    "market_type",
    "typeError"
  );
};

const {
  user,
  for_sale,
  for_rental,
  address_object,
  street_number,
  type,
  numero_depto,
  m_terreno,
  m_construidos,
  promotion_months,
  apartment_floor,
  terrace_size,
  loggia_size,
  gc,
  antiquity,
  terraza,
  kitchen_daily_dining_room,
  bodega,
  mascotas,
  has_private_roof_garden,
  calefaccion,
  curtain_hanger,
  floors,
  guest_parking_spaces,
  brokerage_commission,
  lessor_brokerage_commission,
  valor,
  promotional_price,
  valor_venta,
  price,
  taxes,
  is_new,
  year_built,
  deal_id,
  country,
  custom_commune,
  dormitorios,
  en_suite,
  banos,
  servicios,
  estacionamientos,
  furnished,
  investment_type,
  has_water_tank,
  minisplits,
} = validate;

export const shortValidation = yup
  .object({
    user,
    country,
    for_sale,
    for_rental,
    investment_type,
    address_object,
    street_number,
    type,
    numero_depto,
    m_terreno,
    m_construidos,
    promotion_months,
    apartment_floor,
    terrace_size,
    loggia_size,
    year_built,
    is_new,
  })
  .test("marketTypeTest", null, marketTypeValidation);

export default yup
  .object({
    user,
    for_sale,
    country,
    for_rental,
    address_object,
    street_number,
    type,
    investment_type,
    numero_depto,
    m_terreno,
    m_construidos,
    promotion_months,
    apartment_floor,
    terrace_size,
    loggia_size,
    gc,
    antiquity,
    terraza,
    kitchen_daily_dining_room,
    bodega,
    mascotas,
    has_private_roof_garden,
    calefaccion,
    curtain_hanger,
    floors,
    guest_parking_spaces,
    brokerage_commission,
    lessor_brokerage_commission,
    valor,
    promotional_price,
    valor_venta,
    price,
    taxes,
    is_new,
    year_built,
    deal_id,
    custom_commune,
    dormitorios,
    en_suite,
    banos,
    servicios,
    estacionamientos,
    furnished,
    has_water_tank,
    minisplits,
  })
  .test("marketTypeTest", null, marketTypeValidation);
