import { getTimezoneWithCommune, parseToUtc } from "utils/datetime";
import { CreateEventForm, ManualUserProps } from "./types";

export const parsedScheduleData = (data: CreateEventForm, manualUser: ManualUserProps = null) => {
  const tz = getTimezoneWithCommune(data.property.country, data.property.comuna);

  const parsedDate = parseToUtc(
    `${data.date} ${data.begin_date}`,
    `${data.date} ${data.end_date}`,
    tz
  );

  return {
    formData: {
      appraiser: data.appraiser.value,
      begin_hour: parsedDate.begin_hour,
      end_hour: parsedDate.end_hour,
      prop: data.property?.id || 74564,
      schedule_type: data.event_type.value,
      comment: data.comment,
      not_emails: false,
      origin: "Admin - Houmer",
      ...(data.user?.email && { applicant_email: data.user.email }),
      ...(manualUser?.email && { applicant_email: manualUser?.email }),
    },
    user: data.user,
    propertyId: data.property?.id,
    manualUser,
    eventTypeValue: data.event_type.value,
  };
};
