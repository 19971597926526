import { useState } from "react";

import { DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";

import useAllAppraisers from "hooks/useAllAppraisers";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";
import UserAutocomplete from "Components/Admin/UIComponents/UserAutocomplete";
import { Houmer } from "hooks/useAllAppraisers/types";

import useHoumerModalStyles from "./HoumerModalStyles";
import useUpdateHoumer from "./hooks/useUpdateHoumer";

interface Props {
  open: boolean;
  handleClose: () => void;
  propertyId: number | null;
  actualHoumer: Houmer | null;
  refetchHoumer: () => void;
}

const HoumOwnerModal = ({ open, handleClose, propertyId, actualHoumer, refetchHoumer }: Props) => {
  const classes = useHoumerModalStyles();
  const { appraisers, isLoading } = useAllAppraisers({
    enabled: open,
  });
  const [houmer, setHoumer] = useState<Houmer>();

  const onSuccessHandler = () => {
    refetchHoumer();
    handleClose();
  };

  const { mutate: updateHoumer, isLoading: isUpdateLoading } = useUpdateHoumer({
    propertyId,
    onSuccessHandler,
  });

  return (
    <DSModal open={open} onClose={handleClose} maxWidth="xs" showCloseButton>
      <DialogTitle>
        <Typography className={classes.title}>Houmer</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserAutocomplete
              defaultValue={actualHoumer}
              value={houmer}
              onChange={(value: Houmer) => setHoumer(value)}
              id="houmer"
              label="Cambiar houmer"
              users={appraisers}
              loading={isLoading}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <DesignSystemButton
              size="small"
              label="Cambiar"
              variant="primary"
              isLoading={isUpdateLoading}
              onClick={() =>
                updateHoumer({
                  houmerId: houmer?.id,
                })
              }
              disabled={!houmer}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DSModal>
  );
};

export default HoumOwnerModal;
