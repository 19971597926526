import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  container: {
    flexGrow: 1,
    display: "flex",
    minHeight: 0,
  },
  sectionTitle: {
    marginBottom: theme.spacing(3.75),
  },
  sectionTitle2: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  textField: {
    width: "70%",
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    textTransform: "none",
    borderRadius: 25,
    fontWeight: "bold",
    width: 200,
  },
  selectedItemContainer: {
    width: "70%",
    height: 40,
  },
  textFieldInputLabel: {
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  item: {
    width: "100%",
  },
  invalidMessage: {
    color: "red",
    fontSize: "0.875rem",
  },
  switchWrapper: {
    width: "100% !important",
    flexDirection: "unset",
  },
  containerButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(3, 0),
    maxHeight: 40,
  },
  textInput: {
    padding: theme.spacing(1),
  },
}));
