import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  type: "error" | "info";
}

const useScheduleErrorStyles = makeStyles<Theme, Props>((theme) => ({
  errorContainer: {
    backgroundColor: ({ type }) => (type === "error" ? "#F7E6E5" : "#EAF1FB"),
    borderRadius: 4,
    padding: theme.spacing(1.25),
  },
  message: {
    color: ({ type }) => (type === "error" ? "#AE0400" : "#2C73DB"),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    fontWeight: 700,
  },
  link: {
    color: "#2C73DB",
    textDecoration: "underline",
  },
}));

export default useScheduleErrorStyles;
