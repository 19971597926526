import DisabledCheckbox from "Components/Admin/administrator/multifamily/Components/DisabledCheckbox";
import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import { Checkboxes } from "context/multifamilyContext/multifamily/multifamilyTypes";

import useStyles from "./CheckboxsStyles";

interface Props {
  controlled?: boolean;
  amenities?: Checkboxes;
}

function Checkboxs({ controlled = true, amenities = {} }: Props) {
  const classes = useStyles();
  if (controlled) {
    return (
      <div className={classes.container}>
        <ControlledCheckbox
          id="has_swimming_pool"
          label="Piscina privada"
          defaultChecked={amenities?.has_swimming_pool}
        />
        <ControlledCheckbox id="has_gym" label="Gimnasio" defaultChecked={amenities?.has_gym} />
        <ControlledCheckbox
          id="has_roof_garden"
          label="Roof Garden Común"
          defaultChecked={amenities?.has_roof_garden}
        />
        <ControlledCheckbox
          id="has_playground"
          label="Área de juegos infantiles"
          defaultChecked={amenities?.has_playground}
        />
        <ControlledCheckbox
          id="has_all_day_vigilance"
          label="Vigilancia las 24 hrs"
          defaultChecked={amenities?.has_all_day_vigilance}
        />
        <ControlledCheckbox
          id="has_party_room"
          label="Salón multiuso / Centro de eventos"
          defaultChecked={amenities?.has_party_room}
        />
        <ControlledCheckbox
          id="has_games_room"
          label="Salón de juegos"
          defaultChecked={amenities?.has_games_room}
        />
        <ControlledCheckbox id="has_cinema" label="Cine" defaultChecked={amenities?.has_cinema} />
        <ControlledCheckbox id="has_sauna" label="Sauna" defaultChecked={amenities?.has_sauna} />
        <ControlledCheckbox
          id="has_steam_room"
          label="Vapor"
          defaultChecked={amenities?.has_steam_room}
        />
        <ControlledCheckbox
          id="has_bbq_area"
          label="Asadores / Quincho"
          defaultChecked={amenities?.has_bbq_area}
        />
        <ControlledCheckbox
          id="has_patio"
          label="Patio / Áreas verdes"
          defaultChecked={amenities?.has_patio}
        />
        <ControlledCheckbox
          id="has_laundry"
          label="Área de lavado / Lavandería"
          defaultChecked={amenities?.has_laundry}
        />
        <ControlledCheckbox
          id="has_elevator"
          label="Elevador / Ascensor"
          defaultChecked={amenities?.has_elevator}
        />
        <ControlledCheckbox
          id="has_concierge"
          label="Seguridad / Portería / Conserjería"
          defaultChecked={amenities?.has_concierge}
        />
        <ControlledCheckbox
          id="has_pet_area"
          label="Área para mascotas"
          defaultChecked={amenities?.has_pet_area}
        />
        <ControlledCheckbox
          id="has_gourmet_space"
          label="Espacio Gourmet"
          defaultChecked={amenities?.has_gourmet_space}
        />
        <ControlledCheckbox
          id="has_playroom"
          label="Ludoteca"
          defaultChecked={amenities?.has_playroom}
        />
      </div>
    );
  } else {
    return (
      <>
        <DisabledCheckbox
          id="has_swimming_pool"
          label="Piscina privada"
          value={amenities?.has_swimming_pool}
        />
        <DisabledCheckbox id="has_gym" label="Gimnasio" value={amenities?.has_gym} />
        <DisabledCheckbox
          id="has_roof_garden"
          label="Roof Garden Común"
          value={amenities?.has_roof_garden}
        />
        <DisabledCheckbox
          id="has_playground"
          label="Área de juegos infantiles"
          value={amenities?.has_playground}
        />
        <DisabledCheckbox
          id="has_all_day_vigilance"
          label="Vigilancia las 24 hrs"
          value={amenities?.has_all_day_vigilance}
        />
        <DisabledCheckbox
          id="has_party_room"
          label="Salón multiuso / Centro de eventos"
          value={amenities?.has_party_room}
        />
        <DisabledCheckbox
          id="has_games_room"
          label="Salón de juegos"
          value={amenities?.has_games_room}
        />
        <DisabledCheckbox id="has_cinema" label="Cine" value={amenities?.has_cinema} />
        <DisabledCheckbox id="has_sauna" label="Sauna" value={amenities?.has_sauna} />
        <DisabledCheckbox id="has_steam_room" label="Vapor" value={amenities?.has_steam_room} />
        <DisabledCheckbox
          id="has_bbq_area"
          label="Asadores / Quincho"
          value={amenities?.has_bbq_area}
        />
        <DisabledCheckbox
          id="has_patio"
          label="Patio / Áreas verdes"
          value={amenities?.has_patio}
        />
        <DisabledCheckbox
          id="has_laundry"
          label="Área de lavado / Lavandería"
          value={amenities?.has_laundry}
        />
        <DisabledCheckbox
          id="has_elevator"
          label="Elevador / Ascensor"
          value={amenities?.has_elevator}
        />
        <DisabledCheckbox
          id="has_concierge"
          label="Seguridad / Portería / Conserjería"
          value={amenities?.has_concierge}
        />
        <DisabledCheckbox
          id="has_pet_area"
          label="Área para mascotas"
          value={amenities?.has_pet_area}
        />
        <DisabledCheckbox
          id="has_gourmet_space"
          label="Espacio Gourmet"
          value={amenities?.has_gourmet_space}
        />
        <DisabledCheckbox id="has_playroom" label="Ludoteca" value={amenities?.has_playroom} />
      </>
    );
  }
}

export default Checkboxs;
