import { MAPBOX_API_TOKEN } from "env";

import { useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = MAPBOX_API_TOKEN;

interface Config {
  lat: number;
  lng: number;
  zoom?: number;
  touchPitch?: boolean;
  dragRotate?: boolean;
  minZoom?: number;
  maxZoom?: number;
  style?: string;
}

type UpdateDependencies = unknown[];

const useMap = (config: Config, updateDependencies: UpdateDependencies = []) => {
  const {
    lat,
    lng,
    touchPitch = false,
    dragRotate = false,
    minZoom = 10.827,
    maxZoom = 18.5,
    zoom = 12,
    style = "mapbox://styles/equipoarriendoasegurado/cko1fvdq50vag17pf5emgaj86",
  } = config;
  const mapContainer = useRef(null);
  const map = useRef(null);
  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style,
      center: [lng, lat],
      zoom,
      minZoom,
      maxZoom,
      touchPitch,
      dragRotate,
    });
  }, [mapContainer.current, ...updateDependencies]);
  return { map, mapContainer };
};

export default useMap;
