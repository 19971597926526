import { useEffect } from "react";

import TableLayout from "Components/Admin/UIComponents/TableLayout";
import { useTable } from "context/tableContext/tableContext";
import { useLettersOfIntentList } from "context/letterOfIntent/lettersOfIntentList/lettersOfIntentListContext";
import { getLettersOfIntent } from "context/letterOfIntent/lettersOfIntentList/lettersOfIntentListActions";
import Table from "./components/Table";
import Header from "./components/Header";
import { useGetToken } from "hooks";

function LettersOfIntentListIndex() {
  const { state, dispatch } = useLettersOfIntentList();
  const { country } = state;
  const { getToken } = useGetToken();
  const { state: tableStates } = useTable();
  const { pageSize, currentPage } = tableStates;

  useEffect(() => {
    getToken().then((authToken) =>
      getLettersOfIntent(pageSize, currentPage, country, dispatch, authToken)
    );
  }, [pageSize, currentPage, country]);

  return (
    <TableLayout>
      <Header />
      <TableLayout.Table>
        <Table />
      </TableLayout.Table>
    </TableLayout>
  );
}

export default LettersOfIntentListIndex;
