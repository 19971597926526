import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import useGetToken from "hooks/useGetToken";
import { ILandlordTickets } from "models/AdminModels/Landlord";
import userService from "services/userService";

import { parseLandlordTickets } from "../utils/parsers";

const useLandlordTickets = (currentPage: number, pageSize: number) => {
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const {
    isLoading,
    error,
    data = [],
  }: UseQueryResult<ILandlordTickets[], Error> = useQuery<ILandlordTickets[], Error>(
    ["landlordTickets", id, currentPage, pageSize],
    async () => {
      const authToken = await getToken();
      const response = await userService.getLandlordTickets(id, authToken, {
        page: currentPage,
        page_size: pageSize,
      });

      return parseLandlordTickets(response.data.results);
    },
    {
      keepPreviousData: true,
    }
  );
  return {
    isLoading,
    error,
    data,
  };
};
export default useLandlordTickets;
