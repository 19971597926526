import * as yup from "yup";

export const requiredText = "Obligatorio";
export const invalidEmail = "email inválido";
export const invalidNumber = "Número inválido";

export const validationSchema = yup.object({
  search: yup.mixed().when("idType", {
    is: (idType) => ["tenant_email", "propietary_email"].includes(idType),
    then: yup.string().email(invalidEmail),
    otherwise: yup
      .number()
      .transform((_, val) => (val ? Number(val) : null))
      .typeError(invalidNumber)
      .nullable(true),
  }),
  idType: yup.string().required(requiredText),
});
