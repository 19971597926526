import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  filterButtonRoot: {
    height: 32,
    borderRadius: 18,
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.grey[900],
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
      transform: "scale(1.05)",
    },
    transition: "0.3s",
  },
  filteButtonIcon: {
    stroke: "white",
  },
}));

export default useStyles;
