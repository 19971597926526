import { UseQueryResult, useQuery } from "react-query";

import { CurrencyCode } from "models/Currencies";
import moneyService from "services/moneyService";

interface Props {
  currency: CurrencyCode;
}

const useUFValue = ({ currency }: Props) => {
  const query: UseQueryResult<any, Error> = useQuery(
    ["uf_value"],
    async () => {
      const { data } = await moneyService.getUF();
      return data;
    },
    {
      enabled: !!currency && currency === "CLF",
      retry: false,
    }
  );

  return { ...query };
};

export default useUFValue;
