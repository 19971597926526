import PropertyGeneralTab from "./PropertyGeneralTab";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

export default function GeneralTab({ property }: Props) {
  return <PropertyGeneralTab property={property} />;
}
