/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { UserProfile, UserApplication, UserSegmentation, UserVisit } from "models/User";
import { Country } from "../../../../../models/Countries";

export const SET_LOADING = "SET_LOADING";
export const SET_USERS = "SET_BUYERS";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PROFILE = "SET_PROFILE";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const SHOW_MODAL = "SHOW_MODAL";
export const SET_TABLE_FILTERS = "SET_TABLE_FILTERS";
export const UPDATE_SEGMENTATION = "UPDATE_SEGMENTATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const UPDATE_VISITS = "UPDATE_VISITS";
export const SET_VISITS = "SET_VISITS";

export type Dispatch = (action: Action) => void;

export interface State {
  loading: boolean;
  country: Country;
  showModal: boolean;
  tableFilters: TableFilters;
  users: LeadUser[] | null;
  resultsCount: number;
  user: UserProfile | null;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: Country;
}

interface setUsers {
  type: typeof SET_USERS;
  payload: { users: LeadUser[]; resultCount: number };
}

interface setProfile {
  type: typeof SET_PROFILE;
  payload: UserProfile;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface updateSegmentation {
  type: typeof UPDATE_SEGMENTATION;
  payload: { index: number; segmentation: UserSegmentation };
}

interface updateApplication {
  type: typeof UPDATE_APPLICATION;
  payload: { index: number; application: UserApplication };
}

interface updateVisits {
  type: typeof UPDATE_VISITS;
  payload: Array<UserVisit>;
}

interface setVisits {
  type: typeof SET_VISITS;
  payload: Array<UserVisit>;
}

export interface TableFilters {
  houm_owner?: string; // ejecutivo
  landlord?: string; // propietario
  homechecker?: string;
  rental_status?: string;
  rental_status_not?: string;
  sales_status_not?: string;
  sale_status?: string;
  id?: string;
}

export type Action =
  | setCountry
  | setUsers
  | setProfile
  | setLoading
  | setVisits
  | updateApplication
  | updateSegmentation
  | updateVisits;

export interface LeadUser {
  id: string;
  date: string;
  firstName?: string;
  lastNames?: string;
  origin?: string;
  idProperty?: string | string[];
  status?: string;
  houmer?: string;
  profile?: string;
}
