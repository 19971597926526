import * as yup from "yup";
import { validate, format } from "rut.js";

import { Locale } from "models/Countries";

export const requiredText = "Obligatorio";

interface DocumentTypeProps {
  value: number;
  label: string;
  regex: string;
  identifier: string;
}

const validateDocument = (country: Locale, value: string, regexString: string): boolean => {
  const regex = new RegExp(regexString);
  if (country === "cl") return validate(format(value));
  return regex.test(value);
};

export const evaluateFormSchema = (country: Locale, documentTypeList: DocumentTypeProps[]) =>
  yup
    .object({
      name: yup.string().required(requiredText),
      lastName: yup.string().required(requiredText),
      secondLastName: yup.string().required(requiredText),
      document: yup.string().required(requiredText),
      documentType: yup.number().required(requiredText),
      nationality: country === "cl" && yup.string().required(requiredText),
      visaType: yup.string(),
      fullAddress: yup.string().required(requiredText),
      occupation: yup.string().required(requiredText),
      employer: yup.string().required(requiredText),
      employmentContractType: yup.string().required(requiredText),
      incomeSourceType: yup.string().required(requiredText),
      salaryIncomeMonth1: yup.string().required(requiredText),
      salaryIncomeMonth2: yup.string().required(requiredText),
      salaryIncomeMonth3: yup.string().required(requiredText),
      salaryIncomeAmount1: yup.string().required(requiredText),
      salaryIncomeAmount2: yup.string().required(requiredText),
      salaryIncomeAmount3: yup.string().required(requiredText),
      otherIncomeMonth1: yup.string().nullable().optional(),
      otherIncomeMonth2: yup.string().nullable().optional(),
      otherIncomeMonth3: yup.string().nullable().optional(),
      otherIncomeDescription1: yup.string().nullable().optional(),
      otherIncomeDescription2: yup.string().nullable().optional(),
      otherIncomeDescription3: yup.string().nullable().optional(),
      otherIncomeAmount1: yup.string().when("otherIncomeMonth1", {
        is: (value: string) => !!value,
        then: yup.string().required(requiredText),
      }),
      otherIncomeAmount2: yup.string().when("otherIncomeMonth2", {
        is: (value: string) => !!value,
        then: yup.string().required(requiredText),
      }),
      otherIncomeAmount3: yup.string().when("otherIncomeMonth3", {
        is: (value: string) => !!value,
        then: yup.string().required(requiredText),
      }),
      confirmedSalary: yup.string().required(requiredText),
      comment: yup.string(),
      // rfcNumber: country === "mx" && rfcValidation().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.documentType)
        return new yup.ValidationError(requiredText, null, "documentType", "typeError");
      const documentType = documentTypeList.find(
        (type) => Number(type.value) === Number(obj.documentType)
      );
      if (validateDocument(country, obj.document, documentType.regex)) return true;
      return new yup.ValidationError("Documento no válido", null, "document", "typeError");
    });
