import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useCustomTextfieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: "none",
      "& input": {
        textAlign: "center",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  grid: {
    width: "100%",
    maxWidth: 540,
  },
  monthCard: {
    margin: theme.spacing(0.5),
    maxWidth: 280,
    height: 40,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 8,
    fontWeight: "bold",
    "&:focus-within": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  monthGrid: {
    borderRight: `1px solid ${theme.palette.grey[50]}`,
  },
  textFieldGrid: {
    borderRadius: "0px 8px 8px 0px",
    "&:focus-within": {
      backgroundColor: "#F7F8FC",
    },
  },
}));

export default useStyles;
