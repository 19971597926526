import { useRef, useState, ChangeEvent } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";

import { Avatar, Badge, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FieldPhoneNumber from "@houm-com/ui/FieldPhoneNumber";

import { AppraiserRole } from "models/Appraiser";
import { useGetToken } from "hooks";

import { useDashboard } from "context/controlTower/controlTowerContext";
import { EditProfileForm } from "context/controlTower/controlTowerTypes";
import { getOptionMacrozoneFromProfile } from "context/controlTower/controlTowerUtils";
import { getDefaultRolValues } from "./utils";

import {
  setIsOpenEditModal,
  updateAppraiser,
  updateAppraiserPhoto,
} from "context/controlTower/controlTowerActions";

import {
  appraiserStatus,
  contracts,
  haContracts,
  editRoles,
} from "context/controlTower/controlTowerConsts";

import {
  ControlledTextField,
  ControlledGroupCheckbox,
  ControlledSelection,
  ControlledAutocomplete,
} from "Components/Admin/UIComponents/ControlledForm/";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import useEditProfileModalStyles from "./EditProfileModalStyles";
import { profileSchema } from "./validationSchema";
import GrayChip from "../GrayChip";

function EditProfileModal({ id }: { id: string }) {
  const [image, setImage] = useState<Blob>(null);
  const [urlImage, setUrlIamge] = useState<string>(null);
  const { getToken } = useGetToken();

  const classes = useEditProfileModalStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    state: { appraiserProfile, isOpenEditModal, macrozones },
    dispatch,
  } = useDashboard();

  const defaultMc = getOptionMacrozoneFromProfile(appraiserProfile.macrozones);

  const methods = useForm<EditProfileForm>({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      macrozones: defaultMc,
      firstRol: getDefaultRolValues(appraiserProfile.roles, editRoles.slice(0, 3)),
    },
  });
  const { errors } = methods.formState;

  const currentMc = methods.watch("macrozones");

  const roles = methods.watch("firstRol");

  const contractOptions = roles?.includes(AppraiserRole.SALE_GUIDE) ? haContracts : contracts;

  const handleSubmit: SubmitHandler<EditProfileForm> = (data) => {
    getToken().then(async (authToken) => {
      if (image) {
        await updateAppraiserPhoto(id, image, dispatch, authToken);
      }
      updateAppraiser(data, id, authToken, dispatch);
    });
  };

  const handleButtonImage = () => {
    inputRef.current.click();
  };

  const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      setImage(file);
      setUrlIamge(URL.createObjectURL(file));
    }
  };

  const handleRemoveMc = (mcId: string) => {
    const filteredMc = currentMc.filter((mc) => mc.value !== mcId);
    methods.setValue("macrozones", filteredMc);
  };

  return (
    <CustomDialog
      open={isOpenEditModal}
      onClose={() => dispatch(setIsOpenEditModal(false))}
      showCloseButton
      dialogProps={{
        id: "updateProfileModal",
      }}
      classes={{
        modalContainer: classes.dialog,
        content: classes.contentDialog,
      }}>
      <FormProvider {...methods}>
        <form className={classes.formContainer} onSubmit={methods.handleSubmit(handleSubmit)}>
          <Badge
            badgeContent={
              <IconButton size="small" onClick={handleButtonImage}>
                <input
                  ref={inputRef}
                  type="file"
                  onChange={handleUploadImage}
                  className={classes.inputImage}
                  accept="image/png, image/jpeg, image/jpg"
                />
                <EditIcon fontSize="small" />
              </IconButton>
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            overlap="circular">
            <Avatar className={classes.avatar} src={urlImage || appraiserProfile.image} />
          </Badge>
          <ControlledTextField
            id="name"
            label="Nombre y Apellido"
            defaultValue={appraiserProfile.name}
            textFieldProps={{ disableUnderline: false }}
          />
          <Typography className={classes.groupedLabel}>Roles habilitados</Typography>
          <div className={classes.grupuedCheckbox}>
            <ControlledGroupCheckbox id="firstRol" label="" options={editRoles.slice(0, 3)} />
            <ControlledGroupCheckbox
              id="secondRol"
              label=""
              options={editRoles.slice(3, editRoles.length)}
              defaultValues={getDefaultRolValues(
                appraiserProfile.roles,
                editRoles.slice(3, editRoles.length)
              )}
            />
          </div>
          <ControlledSelection
            useFormLabel
            compressed
            label="Tipo de Contrato"
            id="contract_type"
            options={contractOptions}
            defaultValue={appraiserProfile.contract_type}
            buttonClassName={classes.buttonSelector}
          />
          <ControlledAutocomplete
            multiple
            id="macrozones"
            label="Macrozona"
            options={macrozones}
            inputTagsNode={() => null}
          />
          <div className={classes.mcContainer}>
            {currentMc?.map((mc) => (
              <GrayChip
                onDelete={() => handleRemoveMc(mc.value)}
                label={mc.label.replace("Zona ", "")}
                key={mc.value}
              />
            ))}
          </div>
          <ControlledTextField
            disabled
            id="email"
            label="Correo electronico"
            textFieldProps={{ disableUnderline: false }}
            defaultValue={appraiserProfile.email}
          />
          <FieldPhoneNumber
            id="phone"
            label="Número de teléfono"
            placeholder="Ingresar"
            defaultValue={appraiserProfile?.phone}
            onlyCountries={["CL", "CO", "MX"]}
            errorMessage={errors.phone?.message}
            {...methods.register("phone")}
          />
          <ControlledSelection
            useFormLabel
            compressed
            defaultValue={appraiserProfile.active}
            id="active"
            label="Estado del perfil"
            buttonClassName={classes.buttonSelector}
            options={appraiserStatus}
          />
          <DesignSystemButton
            type="submit"
            id="submitButton"
            size="small"
            variant="primary"
            label="Guardar cambios"
          />
        </form>
      </FormProvider>
    </CustomDialog>
  );
}

export default EditProfileModal;
