export interface ResVisits {
  id: number;
  begin_date: string;
  status: string;
  appraiser_status?: string;
  third_party_status?: string;
  comment: string;
  ct_agent?: null | {
    id: number;
    name: string;
    last_name: string;
    second_last_name: string;
    phone: string;
    email: string;
    role: string;
  };
  appraiser: {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    phone: string;
    email: string;
    photo: string;
    roles: string[];
    active: boolean;
    country: string;
    filled_data: boolean;
    doc_type: string;
    doc_number: string;
    contract_type: string;
    status?: string;
  };
  prop: {
    id: number;
    uid: string;
    comuna: string;
    region: string;
    country: string;
    user: {
      name: string;
      last_name: string;
      phone: string;
      email: string;
      role: string;
      status?: string;
    };
    home_checker_details?: {
      third_party_coordination?: boolean;
      third_party_coordination_description?: string;
    }[];
  };
}

export interface ResHoumers {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  phone: string;
  email: string;
  photo: string;
  roles: string[];
  active: boolean;
  country: string;
  filled_data: boolean;
  doc_type: string;
  doc_number: string;
  contract_type: string;
}

export interface ResAgents {
  id: number;
  full_name: string;
}

export interface ResReasons {
  id: number;
  text: string;
}

interface Houmer {
  name: string;
  phone: string;
  status: string;
  id: string;
}

interface Contact {
  name: string;
  last_name: string;
  phone: string;
  status: string;
  description: string;
  coordination?: boolean;
}

interface Coord {
  role: string;
  coordination: boolean;
}

interface Prop {
  id: number;
  uid: string;
}

interface CtAgent {
  id: number;
  name: string;
  last_name: string;
  role: string;
}

interface TimeZoneDate {
  begin_date: string;
  region: string;
  country: string;
}

interface Visit {
  id: number;
  begin_date: TimeZoneDate;
  status_visit: string;
  coord?: Coord;
  houmer: Houmer;
  contact: Contact;
  prop_id: Prop;
  ct_agent: CtAgent | null;
  comment: string;
}

interface Houmers {
  label: string;
}

interface Agents {
  value: number;
  label: string;
}

interface CancelReasons {
  value: number;
  label: string;
}

export const parseVisits = (data: ResVisits[]): Visit[] =>
  data.map((visit) => ({
    id: visit.id,
    begin_date: {
      begin_date: visit.begin_date,
      region: visit.prop.region,
      country: visit.prop.country,
    },
    prop_id: {
      id: visit.prop.id,
      uid: visit.prop.uid,
    },
    commune: visit.prop.comuna,
    ct_agent: visit?.ct_agent,
    coord: {
      role: visit.prop.user.role,
      coordination: visit?.prop?.home_checker_details[0]?.third_party_coordination,
    },
    houmer: {
      name: visit.appraiser.name,
      phone: visit.appraiser.phone,
      status: visit.appraiser_status,
      id: visit.appraiser.id,
    },
    contact: {
      name: visit.prop.user.name,
      last_name: visit.prop.user.last_name,
      phone: visit.prop.user.phone,
      status: visit.third_party_status,
      description: visit?.prop?.home_checker_details[0]?.third_party_coordination_description,
      coordination: visit?.prop?.home_checker_details[0]?.third_party_coordination,
    },
    status_visit: visit.status,
    comment: visit.comment,
  }));

export const parseHoumers = (data: ResHoumers[]): Houmers[] =>
  data.map((houmer) => ({
    label: houmer.name,
  }));

export const parseAgents = (data: ResAgents[]): Agents[] =>
  data.map((agent) => ({
    value: agent.id,
    label: agent.full_name,
  }));

export const parseReasons = (data: ResReasons[]): CancelReasons[] => {
  if (data) {
    return data.map((item) => ({
      value: item.id,
      label: item.text,
    }));
  }
  return [];
};
