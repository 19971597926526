import { useState, useCallback } from "react";
import { debounce } from "lodash";

const useDebouncedSearchQuery = () => {
  const [query, setQuery] = useState<string>("");
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");

  const setSearchFilter = (querySearch: string) => {
    setDebouncedQuery(querySearch);
  };

  const debouncedSetFilters = useCallback(debounce(setSearchFilter, 700), []);

  const handleOnDebouncedSearchChange = (input: string) => {
    setQuery(input);
    debouncedSetFilters(input);
  };

  return {
    query,
    debouncedQuery,
    handleOnDebouncedSearchChange,
  };
};

export default useDebouncedSearchQuery;
