import {
  BasicService,
  ClientInfo,
} from "Components/Admin/UIComponents/PropertyServices/utils/types";
import { GGCCInfo } from "../../../utils/types";

export const getClientInfo = (service: BasicService): GGCCInfo | ClientInfo => {
  if (service.company === "other")
    return {
      email: service.administrationEmail,
      currentDebt: service.currentDebt,
      dueDate: service.overdueDate,
      aggregateDebt: service.aggregateDebt,
    };
  return service.clientInfo;
};
