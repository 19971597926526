import { Theme } from "@material-ui/core/styles/createTheme";
import { makeStyles } from "@material-ui/core";

interface Props {
  icon: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  searchBarContainer: {
    width: "100%",
  },
  searchBar: {
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    padding: ({ icon }) => (icon ? theme.spacing(0.5, 2, 0.5, 2) : theme.spacing(1, 2, 1, 2)),
    borderRadius: "100rem",
    transition: "0.3s",
    "&.Mui-focused": {
      borderColor: theme.palette.primary.main,
      outline: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

export default useStyles;
