import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  disabled?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  linkButton: {
    display: "inline-block",
    color: (props) => (props.disabled ? theme.palette.grey[200] : theme.palette.primary.main),
    fontWeight: 700,
    fontSize: "0.875rem",
    textTransform: "none",
    textDecoration: "underline",
    textUnderlineOffset: "0.25rem",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: theme.palette.primary.light,
    },
    "&:active, &:focus, &:target": {
      color: theme.palette.primary.dark,
    },
  },
}));

export default useStyles;
