import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import { IWarrantyStatus } from "models/Warranty";

import useWarrantyStatusStyles from "./WarrantyStatusStyles";

const parseStatus = {
  CANCELED: "Cancelado",
  PAID: "Pagado",
  UNPAID: "Pendiente",
  UNLIQUIDATED: "Sin liquidar",
  NEGATIVE: "Saldo negativo",
};

interface Props {
  status: IWarrantyStatus;
}

const WarrantyStatus = ({ status }: Props) => {
  const classes = useWarrantyStatusStyles();
  return (
    <Box
      className={clx(classes.tag, {
        [classes.pending]: status === "UNPAID",
        [classes.paid]: status === "PAID",
        [classes.canceled]: status === "CANCELED",
        [classes.unliquidated]: status === "UNLIQUIDATED",
        [classes.negative]: status === "NEGATIVE",
      })}>
      <Typography className={classes.tagText}>{parseStatus[status]}</Typography>
    </Box>
  );
};

export default WarrantyStatus;
