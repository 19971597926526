import { useState, useEffect } from "react";
import { SnackbarProvider } from "notistack";

import Button from "@houm-com/ui/Button";
import CardContent from "@material-ui/core/CardContent";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import bankAccountService from "services/bankAccountService";
import { ContractModel, IPerson } from "models/Contract";
import { BankAccountModel } from "models/BankAccount";
import { CountryUpperCase } from "models/Countries";
import { useGetToken } from "hooks";

import LessorBankAccountInfo from "../../contractPerson/LessorBankAccountInfo";
import useLandlordCardStyles from "./LandlordCardStyles";
import ConfirmLandlordData from "./ConfirmLandlordData/ConfirmLandlordData";
import { checkStatus, renderDataUsers } from "../../utils";
import { countryCode, countryDocuments } from "utils";
import CodebtorFormEdit from "../CodebtorFormEdit";
import useTooltipStyles from "../../TooltipStyles";
import InfoPerson from "../InfoPerson";
import ModalDialog from "@houm-com/ui/ModalDialog";

interface Props {
  contract: ContractModel;
  contractHasOwner?: boolean;
  person: IPerson;
  country: CountryUpperCase;
}

function LandlordCard({ contract, contractHasOwner = false, person, country }: Props) {
  const classesTooltip = useTooltipStyles();
  const { getToken } = useGetToken();
  const classes = useLandlordCardStyles();
  const [loadingBank, setLoadingBank] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState<boolean>(false);
  const [confirmLandlord, setConfirmLandlord] = useState<boolean>(false);
  const [openEditLandlordModal, setOpenEditLandlordModal] = useState<boolean>(false);
  const [lessorBankAccount, setLessorBankAccount] = useState<BankAccountModel | null>(null);

  const rutIsNull = () => !!(person?.document === "" || person?.document === null);

  const openModalEditLandlordData = () => {
    setOpenEditLandlordModal(true);
  };

  const openModal = () => {
    setConfirmLandlord(true);
  };

  const closeModal = () => {
    setConfirmLandlord(false);
  };

  const fetchBankAccount = async (authToken: string) => {
    setLoadingBank(true);
    const code = contract.property?.country ? countryCode(contract.property?.country) : null;

    const fetchedBankAccount = await bankAccountService.getByUserAndProperty(
      code,
      contract.property?.user?.id,
      contract.property?.id,
      authToken
    );
    if (fetchedBankAccount && [200, 201].includes(fetchedBankAccount.status)) {
      const data = {
        bank: fetchedBankAccount.data.bank_name,
        account: fetchedBankAccount.data.account_number,
        ...fetchedBankAccount.data,
      };
      setLessorBankAccount(data);
    }
    setLoadingBank(false);
  };

  useEffect(() => {
    getToken().then(async (authToken) => {
      fetchBankAccount(authToken);
    });
  }, []);

  const isContractReady = !checkStatus(
    [
      "Generar boton de pago",
      "Desistido",
      "Rechazado",
      "Boton de pago enviado",
      "Pagado",
      "Finalizado",
    ],
    contract?.status
  );

  return (
    <>
      <CollapseCard
        title="Datos propietario"
        editData={isContractReady}
        onEdit={openModalEditLandlordData}
        rootstyle={classes.cardSize}>
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Grid container spacing={3} alignItems="flex-end">
                {renderDataUsers(person, country).map((item) => (
                  <InfoPerson
                    size={item.size}
                    label={item.label}
                    text={item.text}
                    key={`${item.label}`}
                  />
                ))}
                <InfoPerson
                  label="¿Propietario es empresa?"
                  text={`${person.tributary_type === "corporative" ? "Sí, es empresa" : "No"}`}
                />
                {!contractHasOwner && (
                  <Grid item xs={6}>
                    <Tooltip
                      title={
                        rutIsNull()
                          ? `El usuario no puede ser confirmado porque no tiene ${countryDocuments[country].label}`
                          : ""
                      }
                      open={onMouseOver}
                      arrow
                      classes={classesTooltip}>
                      <div
                        onMouseOver={() => setOnMouseOver(true)}
                        onMouseOut={() => setOnMouseOver(false)}
                        onFocus={() => setOnMouseOver(true)}
                        onBlur={() => setOnMouseOver(false)}>
                        {isContractReady && (
                          <Button
                            variant="secondary"
                            size="md"
                            onClick={() => openModal()}
                            disabled={rutIsNull()}>
                            Confirmar datos
                          </Button>
                        )}
                      </div>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
              <LessorBankAccountInfo
                contract={contract}
                loadingBank={loadingBank}
                lessorBankAccount={lessorBankAccount}
              />
            </CardContent>
          </div>
        </Card>
      </CollapseCard>
      <ModalDialog
        isOpen={confirmLandlord}
        onClose={closeModal}
        title="Confirmar datos propietario"
        subtitle="Valida la información">
        <ConfirmLandlordData
          contract={contract}
          person={person}
          rutIsNull={rutIsNull()}
          handleCloseModal={closeModal}
        />
      </ModalDialog>
      <ModalDialog
        isOpen={openEditLandlordModal}
        onClose={() => setOpenEditLandlordModal(false)}
        title="Datos propietario"
        subtitle="Editar información">
        <SnackbarProvider>
          <CodebtorFormEdit
            contract={contract}
            person={person}
            country={countryCode(country)}
            type="owner"
          />
        </SnackbarProvider>
      </ModalDialog>
    </>
  );
}

export default LandlordCard;
