import { Box, Grid, Button, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { saveAs } from "file-saver";

import SimulateTextfield from "Components/Admin/administrator/multifamily/Components/SimulateTextfield/SimulateTextfield";
import Images from "Components/Admin/administrator/multifamily/Components/Images/Images";
import { MultifamilyUnit } from "context/multifamilyContext/unit/unitTypes";
import { Typologies, UTImages } from "context/multifamilyContext/typology/typologyTypes";
import useStyles from "./CharacteristicsSectionStyles";

interface Props {
  unit?: MultifamilyUnit;
  typology?: Typologies;
  UTImages?: UTImages[];
}

function CharacteristicsSection({ unit = null, typology = null, UTImages = [] }: Props) {
  const classes = useStyles();

  const handleDownloadLayoutFile = () => {
    if (typology?.layout_file_url) {
      saveAs(typology.layout_file_url, typology.layout_filename || "layout.pdf");
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <SimulateTextfield
            title="Estado"
            value={unit?.available ? "Disponible" : "No disponible"}
          />
          <SimulateTextfield title="Número de departamento" value={unit?.property?.numero_depto} />
          <SimulateTextfield title="Piso" value={unit?.property?.apartment_floor?.toString()} />
          <SimulateTextfield
            title="Orientación"
            value={unit?.property?.property_details[0]?.orientacion}
          />
          <SimulateTextfield
            title="Amoblado"
            value={unit?.property?.property_details[0]?.furnished !== "non" ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Bodega"
            value={unit?.property?.property_details[0]?.bodega ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Estacionamiento"
            value={unit?.property?.property_details[0]?.estacionamientos?.toString()}
          />
          <SimulateTextfield
            title="Calefacción"
            value={unit?.property?.property_details[0]?.calefaccion ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Aire acondicionado"
            value={unit?.property?.property_details[0]?.has_air_conditioning ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Colgadores de cortinas"
            value={unit?.property?.property_details[0]?.curtain_hanger ? "Si" : "No"}
          />
          <Box className={classes.downloadContainer}>
            <Typography className={classes.downloadLabel}>Descargar planos</Typography>
            <Button
              disabled={
                !typology?.layout_file_url ||
                typology?.layout_file_url ===
                  "https://s3.amazonaws.com/houm-real-estate-media/layout/multifamily/"
              }
              className={classes.downloadButton}
              onClick={handleDownloadLayoutFile}>
              <AttachFile fontSize="small" />
              Descargar archivos
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <SimulateTextfield
            title="Precio"
            value={unit?.property?.property_details[0]?.valor?.toString()}
          />
          <SimulateTextfield
            title="Tipo de moneda"
            value={unit?.property?.property_details[0]?.currency_code}
          />
          <SimulateTextfield
            title="Precio promocional"
            value={unit?.property?.property_details[0]?.promotional_price?.toString()}
          />
          <SimulateTextfield
            title="Tipo de moneda del precio promocional"
            value={unit?.property?.property_details[0]?.currency_code}
          />
          <SimulateTextfield
            title="Cantidad de meses de la promoción"
            value={unit?.property?.property_details[0]?.promotion_months?.toString()}
          />
          <SimulateTextfield
            title="Gastos comunes"
            value={unit?.property?.property_details[0]?.gc?.toString()}
          />
          <SimulateTextfield
            title="Ejecutivo del edificio"
            value={unit?.multifamily_houm_executive?.full_name}
          />
          <SimulateTextfield
            title="Renovaciones"
            value={unit?.property?.property_details[0]?.is_renewed ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Mascotas"
            value={unit?.property?.property_details[0]?.mascotas ? "Si" : "No"}
          />
          <SimulateTextfield
            title="Agua caliente"
            value={unit?.property?.property_details[0]?.hot_water_system ? "Si" : "No"}
          />
          <SimulateTextfield title="URL video" value={unit?.property?.video360_url} />
          <SimulateTextfield title="URl tour 360" value={unit?.property?.video_url} />
        </Grid>
      </Grid>
      <Box className={classes.imageContainer}>
        <Images title="Imágenes tipología" images={UTImages} />
      </Box>
    </Box>
  );
}

export default CharacteristicsSection;
