import { makeStyles, Theme } from "@material-ui/core";

const useNegotiationsTabStyles = makeStyles((theme: Theme) => ({
  table: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: "8px",
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(14),
    },
    "& .MuiDataGrid-cell": {
      padding: theme.spacing(2),
      borderBottomWidth: 0,
      borderTopWidth: 0,
    },
    "& .MuiDataGrid-row": {
      transition: "0.3s",
      "&:hover": {
        fontWeight: 800,
        backgroundColor: theme.palette.grey[5],
        "& p": {
          transition: "0.3s",
          fontWeight: 800,
        },
      },
      "& p": {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  collapse: {
    padding: theme.spacing(3),
  },
}));

export default useNegotiationsTabStyles;
