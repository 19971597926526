import PropertyIndex from "./PropertyIndex";
import { PropertiesProvider } from "./context/propertiesContext";
import { TableProvider } from "../../../../context/tableContext/tableContext";

export default function Properties() {
  return (
    <PropertiesProvider>
      <TableProvider>
        <PropertyIndex />
      </TableProvider>
    </PropertiesProvider>
  );
}
