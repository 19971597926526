import { Dispatch, SetStateAction } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Box } from "@material-ui/core";

import { ControlledTextArea } from "Components/Admin/UIComponents/ControlledForm";
import { createPropertyComment } from "context/propertyContext/propertyActions";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useProperty } from "context/propertyContext/propertyContext";
import { Comment, PropertyData } from "models/Property";
import { useGetToken } from "hooks";

interface Props {
  property: PropertyData;
  setMoreComments: Dispatch<SetStateAction<boolean>>;
}

function GeneralCommentsForm({ property, setMoreComments }: Props) {
  const { getToken } = useGetToken();
  const { state } = useProperty();

  const methods = useForm<Comment>({
    resolver: undefined,
  });

  const onSubmit: SubmitHandler<Comment> = async (data: Comment): Promise<void> => {
    try {
      const authToken = await getToken();
      const sendData: Comment = {
        prop_id: property.id,
        comment: data.comment,
      };
      const response = await createPropertyComment(sendData, authToken);
      if (response) {
        setMoreComments((m: boolean) => !m);
        methods.reset({ comment: "" });
      }
    } catch {
      console.error("error");
    }
  };

  return (
    <Loading loading={state.loading}>
      <FormProvider {...methods} key="GeneralComments">
        <form key="GeneralComments" onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledTextArea id="comment" label="Nuevo comentario" defaultValue="" minRows={4} />
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <DesignSystemButton
              variant="tertiaryA"
              size="medium"
              type="submit"
              label="Publicar comentario"
            />
          </Box>
        </form>
      </FormProvider>
    </Loading>
  );
}

export default GeneralCommentsForm;
