import { useState, useEffect, useRef } from "react";

import axios, { AxiosResponse } from "axios";

import { Paginate } from "models/ResponseWithPaginate";

import propertyService from "services/propertyService";
import { IProperty } from "models";
import useGetToken from "hooks/useGetToken";

interface Config {
  forRental?: boolean | "";
  forSale?: boolean | "";
  published?: boolean | "";
}

const usePropertySearch = ({ forRental, forSale, published }: Config) => {
  const [properties, setProperties] = useState<IProperty[]>([]);
  const [loading, setLoading] = useState(false);

  const { getToken } = useGetToken();

  const source = useRef(null);

  useEffect(() => {
    getSource();
  }, []);

  const getSource = () => {
    source.current = null;
    const { CancelToken } = axios;
    source.current = CancelToken.source();
    return source.current;
  };

  const handleSearch = async (val: string, params?: {}) => {
    source.current?.cancel();
    const sourceCancelation = getSource();

    setLoading(true);
    setProperties([]);
    getToken().then(async (authToken) => {
      try {
        const propertiesResponse = (await propertyService.search(
          val,
          "",
          authToken,
          sourceCancelation.token,
          {
            published,
            for_sale: forSale,
            for_rental: forRental,
            ...params,
          }
        )) as AxiosResponse<Paginate<IProperty>>;
        if (propertiesResponse.data) {
          setLoading(false);
          setProperties(propertiesResponse.data.results);
          return propertiesResponse.data.results;
        }
      } catch (error) {
        setProperties([]);
      }
    });
  };
  return {
    handleSearch,
    properties,
    loading,
  };
};
export default usePropertySearch;
