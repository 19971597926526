import { useEffect, useMemo } from "react";
import { useGetToken } from "hooks";
import TableLayout from "../../UIComponents/TableLayout";

import Table from "./components/Table";
import Header from "./components/Header";

import { useReservations } from "./context/reservationsContext";
import { getReservations } from "./context/reservationsActions";
import { useTable } from "context/tableContext/tableContext";

function ReservationIndex() {
  const { getToken } = useGetToken();
  const { state, dispatch } = useReservations();
  const { state: tableState } = useTable();
  const { currentPage, pageSize } = tableState;

  const country = useMemo(
    () => state.country.charAt(0).toUpperCase() + state.country.slice(1),
    [state.country]
  );

  useEffect(() => {
    if (country === "Chile") {
      // only Chile for now
      getToken().then((authToken) =>
        getReservations(country, pageSize, currentPage, dispatch, authToken)
      );
    }
  }, [getReservations, pageSize, currentPage, country]);

  return (
    <TableLayout>
      <Header title="Reservas" />
      <TableLayout.Table>
        <Table />
      </TableLayout.Table>
    </TableLayout>
  );
}

export default ReservationIndex;
