import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import schedulesService from "services/schedulesService";
import { HoumerModel } from "models/Houmer";

import { IHoumerData } from "../utils/types";

const useGetHoumers = () => {
  const { getToken } = useGetToken();

  const {
    data: houmers,
    isLoading: houmersLoading,
    isError: houmersError,
  }: UseQueryResult<IHoumerData[], Error> = useQuery<IHoumerData[], Error>(
    ["getHoumers"],
    async () => {
      const token = await getToken();
      const res = await schedulesService.getAllAppraisers(token);
      if (res) {
        const houmerData: IHoumerData[] = res["data"].map((h: HoumerModel) => ({
          value: h.id,
          label: h.name,
        }));
        return houmerData;
      }
    }
  );

  return {
    houmers,
    houmersLoading,
    houmersError,
  };
};

export default useGetHoumers;
