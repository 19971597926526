import { Typography } from "@material-ui/core";
import clx from "classnames";

import useInfoItemGeneralValueStyles from "./InfoItemGeneralValueStyles";

interface Props {
  value: string;
  labelClassName?: string;
}

const InfoItemGeneralValue = ({ value, labelClassName }: Props) => {
  const classes = useInfoItemGeneralValueStyles();

  return <Typography className={clx(classes.value, labelClassName)}>{value}</Typography>;
};

export default InfoItemGeneralValue;
