import { useMemo } from "react";
import { InputAdornment } from "@material-ui/core";

import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useProject } from "context/projectContext/projectContext";
import { generalOptions } from "utils/createProperty";
import { numberToString } from "utils";
import { useFormContext, useWatch } from "react-hook-form";
import FormBlock from "./FormBlock";

function ForSalePrice() {
  const { state } = useProject();
  const { control } = useFormContext();

  const country = useWatch({
    control,
    name: "country",
    defaultValue: state.project?.property?.country,
  });

  const currency = useWatch({
    control,
    name: "tipo_moneda_venta",
  });

  const currencyOptions = useMemo(() => generalOptions[country]?.currency || [], [country]);

  if (country)
    return (
      <FormBlock>
        <ControlledSelection
          id="tipo_moneda_venta"
          label="Tipo de moneda para venta"
          defaultValue={state.project?.property?.property_details[0]?.tipo_moneda_venta || "Pesos"}
          options={currencyOptions}
        />
        <ControlledTextField
          id="valor_venta"
          label="Precio desde"
          defaultValue={numberToString(state.project?.property?.property_details[0]?.valor_venta)}
          textFieldProps={{
            placeholder: generalOptions[country]?.placeholders.salePrice,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">{currency !== "U.F." ? "$" : "UF"}</InputAdornment>
              ),
            },
          }}
        />
      </FormBlock>
    );
  else return <div></div>;
}

export default ForSalePrice;
