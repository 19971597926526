import { useState } from "react";

import { Typography, Grid, Box, Tooltip } from "@material-ui/core";
import copy from "copy-to-clipboard";

import { PropertyData } from "models/Property";
import ChangeForSaleForRental from "Components/Admin/UIComponents/Cards/partials/ChangeForSaleForRental";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";

import { ReactComponent as HomeIcon } from "assets/icons/App/home.svg";

import PortalLink from "./components/PortalLink";
import CharacteristicsList from "./components/CharacteristicsList";
import { getPortalLinks } from "./utils/portalLinks";
import useStyles from "./CharacteristicsCardStyles";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";

const propertyStatus = ["published", "republished"];

const countriesWithClosureForm = ["Colombia"];

interface Props {
  property?: PropertyData;
}

function CharacteristicsCard({ property = null }: Props) {
  const [copied, setCopied] = useState(false);

  const classes = useStyles();

  const handleCloseCopied = () => setCopied(false);

  const handleCopied = () => {
    setCopied(true);
    copy(`https://me.houm.com/propietary/closureForm/${property?.uid}`);
  };

  return (
    <AdminCard className={classes.cardRoot}>
      <Typography variant="h6" className={classes.title}>
        <HomeIcon /> Características de la propiedad
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Box className={classes.underlinedBox}>
            <Typography className={classes.textBold}>Propiedad en arriendo</Typography>
            <ChangeForSaleForRental property={property} dealType="for_rental" />
          </Box>
          <Box className={classes.underlinedBox}>
            <Typography className={classes.textBold}>Propiedad a la venta</Typography>
            <ChangeForSaleForRental property={property} dealType="for_sale" />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className={classes.underlinedBox}>
            <Typography className={classes.textBold}>Links Marketplace:</Typography>
          </Box>
          <Box className={classes.linkContainer}>
            {property?.for_rental &&
              propertyStatus.includes(property.property_details[0]?.status) &&
              getPortalLinks(property).rentals.map((link, index) => (
                <Tooltip key={`${link.url}-${index}`} placement="top" title={link.label}>
                  <Box>
                    <PortalLink src={link.src} alt={link.alt} url={link.url} />
                    Arriendo
                  </Box>
                </Tooltip>
              ))}
            {property?.for_sale &&
              propertyStatus.includes(property.sales_details.status) &&
              getPortalLinks(property).sales.map((link, index) => (
                <Tooltip key={`${link.url}-${index}`} placement="top" title={link.label}>
                  <Box>
                    <PortalLink src={link.src} alt={link.alt} url={link.url} />
                    Venta
                  </Box>
                </Tooltip>
              ))}
          </Box>
          {countriesWithClosureForm.includes(property?.country) && (
            <Box className={classes.copyButtonContainer}>
              <Typography>
                Para copiar el link en que el propietario puede llenar sus datos haga click en el
                botón:
              </Typography>
              <DesignSystemButton
                size="small"
                variant="secondary"
                label="Copiar enlace"
                onClick={handleCopied}
              />
              <DesignSystemSnackbar
                type="success"
                open={copied}
                onClose={handleCloseCopied}
                head="¡Enlace copiado!"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <CharacteristicsList property={property} />
    </AdminCard>
  );
}

export default CharacteristicsCard;
