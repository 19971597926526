import { makeStyles, Theme } from "@material-ui/core/styles";

const useNewUserAdminStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
    maxWidth: "750px",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: "0px",
    marginTop: theme.spacing(1.25),
    color: "#263228",
    paddingLeft: "3px",
  },
  input: {
    height: 31,
  },
  formInput: {
    fontWeight: 400,
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    fontSize: "0.9375rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
  phoneInputLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
}));
export default useNewUserAdminStyles;
