import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "react-hook-form";
import { isPossiblePhoneNumber } from "libphonenumber-js";

import { useGetToken } from "hooks";
import { formatPhoneInput } from "utils";
import { useProperty } from "context/propertyContext/propertyContext";

import { getProperty, updateHCDetails } from "context/propertyContext/propertyActions";

import { thirdPartyCoordSchema } from "./validationSchema";

import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";
import thirdPartyFields, { IFormInput } from "./fields";
import { linkagesMap } from "../constants";
import useGuard from "domains/auth/hooks/useGuard";

function ThirdPartyCoord() {
  const { state, dispatch } = useProperty();

  const { getToken } = useGetToken();
  const {
    third_party_coordination_email: email,
    third_party_coordination_full_name: name,
    third_party_coordination_person: linkage,
    third_party_coordination: isThirdParty,
    third_party_coordination_description: description,
    third_party_coordination_phone: phone,
    id: hcId,
  } = state.property?.home_checker_details?.[0] || {};

  const {
    methods,
    goNextStep,
    goBackStep,
    label,
    currentStep,
    checked,
    toggleCheck,
    changedDefaultCheck,
  } = useVisitCard<IFormInput>({
    defaultChecked: isThirdParty,
    resolver: yupResolver(thirdPartyCoordSchema),
  });

  const { guard } = useGuard();

  const showThirdPartyDetails =
    guard.roles.any([
      "control_tower_agent",
      "sales",
      "control_tower_admin",
      "admin",
      "evaluation",
    ]) || guard.permissions.any(["read:property_thirdparty:all"]);
  const linkageField = methods.watch("third_party_coordination_person")?.value;

  useEffect(() => {
    if (checked) {
      goBackStep();
    }
  }, [checked]);

  useEffect(() => {
    if (linkage && !linkageField) {
      const likageLabel = linkagesMap[state.property.country].find(
        (item) => item.value === linkage
      )?.label;
      methods.setValue("third_party_coordination_person", {
        label: likageLabel,
        value: linkage,
      });
    }

    if (linkageField === "proprietary") {
      const { user } = state.property;

      if (!phone) {
        const isPhone = isPossiblePhoneNumber((user.phone || "") as string);
        if (isPhone) {
          methods.setValue("phone", formatPhoneInput(user.phone as string));
        }
      }
      if (!email) {
        methods.setValue("third_party_coordination_email", user.email);
      }
      if (!name) {
        methods.setValue(
          "third_party_coordination_full_name",
          `${user.name} ${user.last_name} ${user.second_last_name}`
        );
      }
    }
  }, [linkageField]);

  const fields = thirdPartyFields({
    checked,
    country: state.property.country,
    currentStep,
    description,
    email,
    linkage,
    phone,
    name,
    disabled: linkageField === "proprietary",
  });

  const handleSubmit = async (data) => {
    const email = data.third_party_coordination_email || null;
    const phone = data.phone.parsedPhone;

    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      {
        ...data,
        third_party_coordination: true,
        third_party_coordination_phone: phone,
        third_party_coordination_email: email,
      },
      hcId,
      dispatch,
      authToken
    );

    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  const buttonLabel = !showThirdPartyDetails || !checked ? "Actualizar" : label;

  const showButton = changedDefaultCheck || (checked && showThirdPartyDetails);

  const handleChangeThirdParty = async () => {
    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      {
        third_party_coordination: checked,
      },
      hcId,
      dispatch,
      authToken
    );

    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          id="thirdParty"
          items={showThirdPartyDetails && fields}
          title={<TitleCard title="Requiere coordinación de tercero" />}
          checkedOptions={{
            checked,
            onChange: toggleCheck,
          }}
          buttonOptions={
            showButton && {
              buttonLabel,
              buttonCallback:
                !showThirdPartyDetails || !checked ? handleChangeThirdParty : goNextStep,
              buttonPosition: "right",
              isSubmit: showThirdPartyDetails && checked && currentStep === "editable",
            }
          }
        />
      </form>
    </FormProvider>
  );
}

export default ThirdPartyCoord;
