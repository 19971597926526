import { Chip, Grid } from "@material-ui/core";
import clx from "classnames";

import useGetExitProcess from "Components/Admin/administrator/subscriptions/hooks/useGetExitProcess";
import useGetContractDebtState from "Components/Admin/administrator/subscriptions/hooks/useGetContractDebtState";
import { exitProcessStartedStatus } from "models/Contract";

import useSubscriptionStatusStyles from "./SubscriptionStatusStyles";
import { getContractDebtStateLabel } from "./utils";
import useGetDesertionProcessInfo from "../../../MandateRefund/hooks/useGetDesertionProcessInfo";

interface Props {
  subscriptionActive: boolean;
  renewal: boolean;
  contractId: number;
}

const SubscriptionStatus = ({ subscriptionActive, renewal, contractId }: Props) => {
  const {
    exitProcess,
    isLoading: isExitProcessLoading,
    isSuccess: isExitProcessSuccess,
  } = useGetExitProcess(contractId);

  const {
    contractDebtState,
    isLoading: isContractDebtStateLoading,
    isSuccess: isContractDebtStateSuccess,
  } = useGetContractDebtState(contractId);
  const { isLoading: desertionProcessInfoLoading, isSuccess: desertionProcessInfoSuccess } =
    useGetDesertionProcessInfo(contractId);

  const classes = useSubscriptionStatusStyles();

  const showExitProcessChip =
    !isExitProcessLoading &&
    isExitProcessSuccess &&
    exitProcess.status === exitProcessStartedStatus;
  const showContractDebtStateChip = !isContractDebtStateLoading && isContractDebtStateSuccess;
  const showDesertionProcessChip = !desertionProcessInfoLoading && desertionProcessInfoSuccess;

  return (
    <>
      <Grid item>
        <Chip
          label={subscriptionActive ? "Activa" : "Inactiva"}
          className={subscriptionActive ? classes.chipGreen : classes.chipRed}
        />
      </Grid>
      {subscriptionActive && (
        <Grid item>
          <Chip label={renewal ? "Renovada" : "Cliente primer año"} />
        </Grid>
      )}
      {showExitProcessChip && (
        <Grid item>
          <Chip label="En proceso de salida" className={classes.chipRed} />
        </Grid>
      )}
      {showContractDebtStateChip && (
        <Grid item>
          <Chip
            label={getContractDebtStateLabel(contractDebtState.state)}
            className={clx({
              [classes.chipGreen]: contractDebtState.state === "NO_DEBT",
              [classes.chipYellow]: contractDebtState.state === "LATE",
              [classes.chipRed]: contractDebtState.state === "SINISTER",
            })}
          />
        </Grid>
      )}
      {showDesertionProcessChip && (
        <Grid item>
          <Chip label="Término de mandato" className={classes.chipRed} />
        </Grid>
      )}
    </>
  );
};

export default SubscriptionStatus;
