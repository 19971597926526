import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { add } from "date-fns";

import ModalDialog from "@houm-com/ui/ModalDialog";
import Grid from "@material-ui/core/Grid";

import useEditPaymentOrder from "Components/Admin/administrator/subscriptions/components/Payments/hooks/useEditPaymentOrder";
import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import { useFormNotifications } from "hooks";
import { Locale } from "models/Countries";

import { editPayDateSchema, requiredText } from "../../utils/validationSchema";
import useEditPaymentDateFormStyles from "./EditPaymentDateFormStyles";
import { isWeekendDay } from "utils/datetime";

interface IFormData {
  paymentDate: string;
  description: string;
}

interface Props {
  movementId: string;
  country: Locale;
  handleOnClose: () => void;
  date: string;
}

function EditPaymentDateForm({ movementId, country, handleOnClose, date }: Props) {
  const classes = useEditPaymentDateFormStyles();
  const { mutate, isLoading } = useEditPaymentOrder(handleOnClose);

  const methods = useForm<IFormData>({
    resolver: yupResolver(editPayDateSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IFormData> = (data) => {
    if (isLoading) return;
    mutate({
      country,
      movementId,
      agreedDate: data.paymentDate,
    });
  };

  return (
    <FormProvider {...methods}>
      <form className={classes.formContent}>
        <ModalDialog.Content>
          <Grid item container alignItems="flex-start">
            <Grid item xs={12}>
              <ControlledKeyboardDatePicker
                id="paymentDate"
                label="Fecha de pago"
                defaultValue={new Date(date)}
                dataPickerProps={{
                  minDate: add(new Date(), { days: 1 }),
                  format: "dd/MM/yyyy",
                  shouldDisableDate: (e) => isWeekendDay(e),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                label="¿Por qué se cambió la fecha acordada?"
                id="description"
                defaultValue=""
                textFieldProps={{
                  InputProps: {
                    classes: {
                      root: classes.textInput,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </ModalDialog.Content>
        <ModalDialog.Actions
          onSubmit={methods.handleSubmit(onSubmit)}
          submitButtonProps={{
            isLoading,
          }}
          buttonsPosition="center"
          submitButtonLabel="Editar"
        />
      </form>
    </FormProvider>
  );
}

export default EditPaymentDateForm;
