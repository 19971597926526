import { Action, State, ActionType } from "./macrozonesTypes";

export const initialState: State = {
  cities: [],
  macrozonesByCity: {},
  macrozoneList: [],
  property: null,
  modalMessage: { isOpen: false },
  loaders: {},
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_CITIES: {
      return { ...state, cities: action.cities };
    }
    case ActionType.SET_MC_BY_CITY: {
      return { ...state, macrozonesByCity: action.macrozonesByCity };
    }

    case ActionType.SET_MACROZONES: {
      return { ...state, macrozoneList: action.macrozoneList };
    }

    case ActionType.SET_MODAL_MESSAGE: {
      return { ...state, modalMessage: action.modalMessage };
    }

    case ActionType.SET_LOADER: {
      return { ...state, loaders: { ...state.loaders, ...action.loaders } };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
