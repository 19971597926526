import { useEffect, useState, useMemo } from "react";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";
import { CustomDataGrid, CustomLink } from "Components/Admin/UIComponents";
import { useCalculator } from "context/calculatorContext/calculatorContext";
import { SimilarExternalPropertiesResponse } from "models/Calculator";
import { formatCurrency } from "utils/formatCurrency";
import Table from "Components/Admin/administrator/properties/show/tabs/rev/Table";

interface Row {
  origin: string;
  link: string;
  price: string;
  bathrooms: number;
  bedrooms: number;
  totalArea: number;
}

const parseToRows = (
  similarPrices: SimilarExternalPropertiesResponse,
  deal: "rental" | "sale"
): Row[] => {
  if (!similarPrices) return [];
  const properties = similarPrices[`for_${deal}`];
  if (properties.length === 0) return [];
  return properties.map(({ origin, specs, address, price, link, id }) => ({
    id,
    origin,
    link,
    price: formatCurrency(price.currency, price.amount),
    bathrooms: specs.bathrooms,
    bedrooms: specs.bedrooms,
    totalArea: specs.total_area,
  }));
};

function ExternalSimilarProperties() {
  const { state } = useCalculator();

  const { similarExternalProperties, deal } = state;
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    setRows(() => parseToRows(similarExternalProperties, deal));
  }, [similarExternalProperties, deal]);

  const dealTitle = useMemo(() => (deal === "rental" ? "arriendo" : "venta"), [deal]);

  return (
    <Table.Section>
      <Table.Title>{`Propiedades en ${dealTitle} de otros portales`}</Table.Title>
      <CustomDataGrid
        columns={columns}
        rows={rows}
        paginationMode="client"
        sortingMode="client"
        pageSize={pageSize}
        onPageSizeChange={(p) => setPageSize(p)}
        page={page}
        onPageChange={(p) => setPage(p)}
        localeText={{ noRowsLabel: "Sin resultados" }}
      />
    </Table.Section>
  );
}

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  sortable: false,
};

const columns: GridColumns = [
  {
    ...columnsConfig,
    field: "origin",
    headerName: "Portal",
    width: 160,
  },
  {
    ...columnsConfig,
    field: "price",
    headerName: "Precio",
    width: 160,
  },
  {
    ...columnsConfig,
    field: "bathrooms",
    headerName: "Baños",
    width: 50,
    sortable: true,
  },
  {
    ...columnsConfig,
    field: "bedrooms",
    headerName: "Dormitorios",
    width: 50,
    sortable: true,
  },
  {
    ...columnsConfig,
    field: "totalArea",
    headerName: "Área total",
    width: 50,
    sortable: true,
  },

  {
    ...columnsConfig,
    field: "link",
    headerName: "Acciones",
    width: 50,

    renderCell: ({ value }: GridCellParams) => (
      <CustomLink to={value as string} navigation={false} target="_blank" />
    ),
  },
];

export default ExternalSimilarProperties;
