import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import CompanySuggester from "../CompanySuggester";
import { CompanyOptions } from "models/User";

interface Props {
  id: string;
  label: string;
  defaultValue?: CompanyOptions;
  controllerProps?: React.ComponentProps<typeof Controller>;
}

function ControlledCompanyText({ controllerProps, id, label, defaultValue = null }: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => (
        <CompanySuggester
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          id={name}
          label={label}
          errorMessage={error?.message}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledCompanyText;
