import useGetToken from "hooks/useGetToken";
import { UseQueryResult, useQuery } from "react-query";
import debtCollectionService from "services/debtCollectionService";
import { IDebtCollectionInsurancesResponse } from "./utils/types";

export default function useGetDebtCollectionsInsurances() {
  const { getToken } = useGetToken();
  const {
    data: insurances,
    isLoading,
    isError,
  }: UseQueryResult<IDebtCollectionInsurancesResponse, Error> = useQuery<
    IDebtCollectionInsurancesResponse,
    Error
  >(["getDebtCollectionInsurances"], async () => {
    const token = await getToken();
    const response = await debtCollectionService.getDebtCollectionInsurances(token);
    return response?.data;
  });

  return {
    insurances,
    isInsurancesLoading: isLoading,
    isError,
  };
}
