import { makeStyles, Theme } from "@material-ui/core";

const usePropertySearchStyles = makeStyles<Theme>((theme) => ({
  propertySearchContainer: {
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gap: 0,
    },
  },
  textField: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    display: "block",
    background: "none",
    padding: theme.spacing(0.5, 1),
    paddingLeft: theme.spacing(1),
    outline: "none",
    border: "none",
    width: "100%",
    "&::placeholder": {
      color: "#BFCBD1",
      fontWeight: 400,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  textFieldContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
    border: "none",
    width: "100%",
  },
  searchIcon: {
    position: "absolute",
    marginLeft: theme.spacing(1.4),
    marginTop: theme.spacing(1),
  },
  searchIcon2: {
    marginLeft: theme.spacing(1.4),
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default usePropertySearchStyles;
