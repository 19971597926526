import {
  State,
  Action,
  SET_LOADING,
  CHANGE_COUNTRY,
  SET_CONTRACT_LIST,
  SET_FILTERS,
  SET_IS_OPEN_FILTER_MODAL,
} from "./contractTypes";

export const initialState: State = {
  loading: true,
  contractList: [],
  country: "Chile",
  isOpenFilterModal: false,
  numberOfContracts: null,
  filters: {
    search: null,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    case SET_IS_OPEN_FILTER_MODAL:
      return {
        ...state,
        isOpenFilterModal: action.isOpenFilterModal,
      };
    case SET_CONTRACT_LIST: {
      return {
        ...state,
        loading: false,
        contractList: action.payload.contractList,
        numberOfContracts: action.payload.count,
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
