import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Swal from "sweetalert2";
import { Grid, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";

import applicantService from "services/applicantService";
import { useGetToken } from "hooks";
import useAllAppraisers from "hooks/useAllAppraisers";
import { useEvaluation } from "context/evaluationContext/evaluationContext";
import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";

import useStyles from "./HoumerCardStyles";

function HoumerCard() {
  const { getToken } = useGetToken();
  const classes = useStyles();
  const { state } = useEvaluation();
  const { singleEvaluation, houmer, loadingHoumer } = state;
  const applicantId = singleEvaluation.evaluations[0].related_applicant_id;
  const [open, setOpen] = useState(false);
  const [selectedHoumer, setSelectedHoumer] = useState(null);
  const [loading, setLoading] = useState(false);
  const { appraisers: houmers, isLoading: isHoumersLoading } = useAllAppraisers({
    enabled: open,
  });

  const handleChange = async () => {
    setLoading(true);
    const data = {
      appraiser_id: selectedHoumer,
    };
    const authToken = await getToken();
    const res = await applicantService.changeAppraiser(applicantId, data, authToken);

    if (res) {
      Swal.fire({
        type: "success",
        text: "Se ha actualizado el Houmer asociado",
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        type: "error",
        text: "Ha ocurrido un error, por favor intenta más tarde",
      });
    }
    setLoading(false);
  };

  if (loadingHoumer) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        transitionDuration={500}
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}>
        <Grid container justifyContent="space-between">
          <DialogTitle>Cambiar Houmer</DialogTitle>
          <DialogActions>
            <IconButton className={classes.closeButton} onClick={() => setOpen(false)}>
              <GridCloseIcon />
            </IconButton>
          </DialogActions>
        </Grid>
        <DialogContent>
          {!isHoumersLoading && (
            <Select
              value={selectedHoumer || ""}
              onChange={(e) => setSelectedHoumer(e.target.value)}
              className={classes.select}>
              {houmers?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <CustomLoadingButton
            text="Cambiar"
            onClick={handleChange}
            type="contained"
            loading={loading || isHoumersLoading}
          />
        </DialogActions>
      </Dialog>
      <Card className={classes.card}>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h5">Houmer</Typography>
            <CustomLoadingButton text="CAMBIAR" onClick={() => setOpen(true)} type="outlined" />
          </Box>
          {houmer != null && houmer.name !== "" ? (
            <Box display="flex" flexDirection="row" className={classes.container}>
              <Avatar src={houmer.photo} className={classes.avatar} />
              <Box className={classes.dataContainer}>
                <Typography>{houmer.name}</Typography>
                <Typography>{houmer.email}</Typography>
                <Typography>{houmer.phone}</Typography>
              </Box>
            </Box>
          ) : (
            <p className={classes.text}>No hay un houmer asociado</p>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default HoumerCard;
