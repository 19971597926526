import { makeStyles, Theme } from "@material-ui/core";

const PersonCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[5],
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    width: "100%",
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  label: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  value: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.grey[500]}`,
    alignSelf: "flex-end",
  },
  textField: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
    backgroundColor: "#EFF2F3",
    fontSize: theme.typography.pxToRem(16),
  },
  inputField: {
    fontSize: theme.typography.pxToRem(16),
  },
  editContainer: {
    width: "60%",
    "& > div": {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(0),
    },
  },
  selectContainer: {
    marginTop: 0,
    "& .MuiInputBase-root": {
      display: "flex",
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: "flex",
    alignSelf: "flex-end",
    gap: theme.spacing(1),
  },
}));
export default PersonCardStyles;
