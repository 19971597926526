import { AxiosResponse, AxiosError } from "axios";
import Swal from "sweetalert2";
import multifamilyService from "services/multifamilyService";
import propertyService from "services/propertyService";
import { countryCode, neighborhoodOptions } from "utils";

import {
  ActionType,
  Action,
  Dispatch,
  Multifamilies,
  Filters,
  MFImages,
  sortedPhotos,
  photosDeleteBody,
  Options,
} from "./multifamilyTypes";

import { Paginate } from "models/ResponseWithPaginate";

export const setMultifamily = (multifamily: Multifamilies): Action => ({
  type: ActionType.SET_MULTIFAMILY,
  multifamily,
});

export const setTotalMF = (totalMF: number): Action => ({
  type: ActionType.SET_TOTAL_MF,
  totalMF,
});

export const setMultifamilies = (multifamilies: Multifamilies[]): Action => ({
  type: ActionType.SET_MULTIFAMILIES,
  multifamilies,
});

export const setLoadingDataTable = (isLoadingDataTable: boolean): Action => ({
  type: ActionType.SET_IS_LOADING_DATA_TABLE,
  isLoadingDataTable,
});

export const setFilters = (filters: Filters): Action => ({
  type: ActionType.SET_FILTERS,
  filters,
});

export const setMFImages = (MFImages: MFImages[]): Action => ({
  type: ActionType.SET_MF_IMAGES,
  MFImages,
});

export const setCurrentId = (currentId: number): Action => ({
  type: ActionType.SET_CURRENT_ID,
  currentId,
});

export const setIsOpenFilterModal = (isOpenFilterModal: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_FILTER_MODAL,
  isOpenFilterModal,
});

export const setNeighborhoodOptions = (neighborhoodOptions: Options[]): Action => ({
  type: ActionType.SET_NEIGHBORHOOD_OPTIONS,
  neighborhoodOptions,
});

export const getMultifamily = async (dispatch: Dispatch, id: number, authToken: string) => {
  try {
    const res = (await multifamilyService.getMultifamily(
      id,
      authToken
    )) as AxiosResponse<Multifamilies>;
    const multifamily = res.data;
    dispatch(setMultifamily(multifamily));
  } catch {
    Swal.fire("Error", "No se ha podido consultar la informacion para este multifamily.", "error");
    dispatch(setMultifamily({}));
  }
};

export const getMultifamilyPhotos = async (dispatch: Dispatch, id: number, authToken: string) => {
  try {
    const res = (await multifamilyService.getMultifamilyPhotos(id, authToken)) as AxiosResponse<
      MFImages[]
    >;
    const photos = res.data;
    dispatch(setMFImages(photos));
  } catch {
    Swal.fire("Error", "No se han podido consultar las imagenes para este multifamily.", "error");
    dispatch(setMFImages([]));
  }
};

export const deleteMultifamilyPhoto = async (
  id: number,
  body: photosDeleteBody,
  authToken: string
) => {
  try {
    const res = await multifamilyService.deleteMultifamilyPhoto(id, body, authToken);
    return res;
  } catch {
    Swal.fire("Error", "No se ha podido eliminar la imagen para este multifamily.", "error");
  }
};

export const updateMultifamilyPhotosOrder = async (
  MFid: number,
  sortedPhotoArray: sortedPhotos,
  dispatch: Dispatch,
  authToken: string
) => {
  try {
    await multifamilyService.multifamilyPhotosOrder(MFid, sortedPhotoArray, authToken);
    Swal.fire(
      "Actualizado",
      "Se ha actualizado el orden de las fotos para este multifamily",
      "success"
    );
    getMultifamilyPhotos(dispatch, MFid, authToken);
  } catch (err) {
    Swal.fire(
      "Error",
      "No se han podido actualizar el orden de las imagenes para este multifamily.",
      "error"
    );
  }
};

export const getMultifamilies = async (dispatch: Dispatch, filters: {}, authToken: string) => {
  try {
    dispatch(setLoadingDataTable(true));
    const res = (await multifamilyService.getMultifamilies(filters, authToken)) as AxiosResponse<
      Paginate<Multifamilies>
    >;
    const arrayOfMultifamilies = res.data.results;
    dispatch(setMultifamilies(arrayOfMultifamilies));
    dispatch(setTotalMF(res.data.count));
  } catch {
    Swal.fire("Error", "No se han podido obtener las multifamily.", "error");
    dispatch(setMultifamilies([]));
  } finally {
    dispatch(setLoadingDataTable(false));
  }
};

export const publishMultifamily = async (id: number, authToken: string) => {
  try {
    await multifamilyService.publishMultiFamily(id, authToken);
    Swal.fire("Publicado!", "El multifamily ha sido publicado.", "success");
  } catch (e) {
    const error = e as AxiosError<{ error?: string }>;
    Swal.fire("Error", `${error.response.data.error}`, "error");
  }
};

export const unpublishMultifamily = async (id: number, authToken: string) => {
  try {
    await multifamilyService.unpublishMultiFamily(id, authToken);
    Swal.fire("Despublicado!", "El multifamily ha sido despublicado.", "success");
  } catch (e) {
    const error = e as AxiosError<{ error?: string }>;
    Swal.fire("Error", `${error.response.data.error}`, "error");
  }
};

export const createMultifamily = async (
  dispatch: Dispatch,
  body: Multifamilies,
  authToken: string
) => {
  try {
    const res = await multifamilyService.createMultiFamily(body, authToken);
    dispatch(setCurrentId(res.data.id));
  } catch {
    Swal.fire("Error", "Ha ocurrido un error al guardar los datos.", "error");
  }
};

export const editMultiFamily = async (id: any, body: Multifamilies, authToken: string) => {
  try {
    await multifamilyService.editMultiFamily(id, body, authToken);
  } catch {
    Swal.fire("Error", "Ha ocurrido un error al guardar los datos.", "error");
  }
};

export const getNeighborhoodOptions = async (
  country: string,
  authToken: string,
  dispatch: Dispatch
) => {
  try {
    const cc = countryCode(country);

    const results = await propertyService.getLocations(cc, authToken);
    const { regions } = results;

    const neighborhoods = neighborhoodOptions(regions);

    dispatch(setNeighborhoodOptions(neighborhoods));
  } catch {
    Swal.fire("Error", "Ha ocurrido un error al obtener los barrios.", "error");
  }
};
