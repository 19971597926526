import { makeStyles, Theme } from "@material-ui/core";

const usePhotosButtonStyles = makeStyles<Theme>((theme) => ({
  label: {
    display: "flex",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  button: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

export default usePhotosButtonStyles;
