import zod from "zod";
import countryCode from "utils/countryCode";
import { CurrencyEnum } from "./Currency";

export const PropertyDetailsStatusEnum = [
  "complete",
  "incomplete",
  "lost",
  "photo_cancelled",
  "photo_done",
  "photo_scheduled",
  "published",
  "republished",
  "reserved",
  "rented",
  "unpublished",
  "rented",
  "sold",
  "archived",
  "out_of_coverage",
  "unpublished_by_import",
  "unknown",
] as const;

export const PropertyCurrency = zod.enum(CurrencyEnum).nullable();

export const PropertyDetailsStatus = zod.enum(PropertyDetailsStatusEnum).nullable();

export const HomeCheckerSchema = zod
  .object({
    name: zod.string().nullable(),
  })
  .nullable();

export const HoumOwnerSchema = zod
  .object({
    name: zod.string().nullable(),
    last_name: zod.string().nullable(),
  })
  .transform(({ last_name, ...rest }) => ({
    lastName: last_name,
    ...rest,
  }));

export const PropertyDetailsSchema = zod
  .object({
    status: PropertyDetailsStatus,
    currency_code: PropertyCurrency,
    valor: zod.number().nullable(),
    dormitorios: zod.number().nullable(),
  })
  .transform(({ currency_code, valor, dormitorios, ...rest }) => ({
    currency: currency_code,
    price: valor,
    bedrooms: dormitorios,
    ...rest,
  }));

export const PropertySchema = zod
  .object({
    id: zod.number(),
    uid: zod.string(),
    address: zod.string().nullable(),
    comuna: zod.string().nullable(),
    country: zod
      .string()
      .nullable()
      .transform((value) => countryCode(value)),
    homechecker: zod.nullable(HomeCheckerSchema),
    houm_owner: HoumOwnerSchema,
    property_details: zod.array(PropertyDetailsSchema),
    region: zod.string().nullable(),
    second_line_address: zod.string().nullable(),
    street_number: zod.string().nullable(),
    type: zod.string().nullable(),
    state: zod.string().nullable(),
  })
  .transform(({ houm_owner, property_details, street_number, second_line_address, ...rest }) => ({
    houmOwner: houm_owner,
    propertyDetails: property_details[0],
    streetNumber: street_number,
    secondLineAddress: second_line_address,
    commune: rest.comuna,
    ...rest,
  }));

export type Property = zod.infer<typeof PropertySchema>;
export type PropertyDetails = zod.infer<typeof PropertyDetailsSchema>;
export type PropertyCurrencyType = zod.infer<typeof PropertyCurrency>;
