import { makeStyles, Theme } from "@material-ui/core";

const useTenantSearchTabStyles = makeStyles((theme: Theme) => ({
  infoContainer: {
    padding: theme.spacing(0, 4.5),
  },
  infoBlock: {
    padding: theme.spacing(0, 4.5),
  },
  titleWithButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  historialButton: {
    textDecoration: "underline",
    color: "#607D8B",
  },
  blockDivider: {
    height: 2,
    margin: theme.spacing(2.5, 0, 3.5, 4.5),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    width: "70%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "90vh",
    overflowY: "scroll",
  },
  modalDate: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  modalDivider: {
    height: 2,
    margin: theme.spacing(5, 0),
  },
}));

export default useTenantSearchTabStyles;
