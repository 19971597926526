export const earlyFinishLabel = "Término anticipado";
export const noRenovationLabel = "No renueva";
export const abandonmentLabel = "Abandono";
export const evictionLabel = "Propiedad recuperada";
export const requestedByTenantLabel = "Arrendatario";
export const requestedByLandlordLabel = "Propietario";
export const requestedByAdminLabel = "Houm";

export const actionButtonLabel = {
  NOT_STARTED: "Comenzar",
  IN_PROCESS: "Finalizar",
  CANCELLED: "Comenzar",
  ENDED: "Finalizado",
};

export const statusLabels = {
  NOT_STARTED: "No iniciado",
  IN_PROCESS: "En curso",
  CANCELLED: "Cancelado",
  ENDED: "Finalizado",
};
export interface IContractExitProcessFormData {
  estimatedDate: string;
  comment: string;
  requirementOrigin: string;
}
