import { makeStyles, Theme } from "@material-ui/core";

const useTimeFieldsStyles = makeStyles((theme: Theme) => ({
  hoursContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
  hour: {
    maxWidth: "fitContent",
  },
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useTimeFieldsStyles;
