import Swal from "sweetalert2";

import { CountryUpperCase } from "models/Countries";
import { ActionType, Dispatch, LetterOfIntent, Action } from "./lettersOfIntentListTypes";
import negotiationService from "services/negotiationService/negotiationService";
import { parseLoiList } from "./utils";
import { LOIList } from "services/negotiationService/models";
import { countryCode } from "utils";

export const setLoading = (loading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const changeCountry = (country: CountryUpperCase): Action => ({
  type: ActionType.CHANGE_COUNTRY,
  country,
});

export const setLettersOfIntent = (lettersOfIntent: LetterOfIntent[], count: number): Action => ({
  type: ActionType.SET_LETTERS_OF_INTENT,
  payload: { lettersOfIntent, count },
});

export const getLettersOfIntent = async (
  pageSize: number,
  currentPage: number,
  country: CountryUpperCase,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoading(true));

  try {
    const code = countryCode(country);
    const res = await negotiationService().getAllLOIs(code, currentPage, pageSize, token);
    const loiList: LOIList = res.data;
    const lettersOfIntent = parseLoiList(loiList.results);
    const { count } = loiList;
    dispatch(setLettersOfIntent(lettersOfIntent, count));
  } catch (err) {
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    });
  } finally {
    dispatch(setLoading(false));
  }
};
