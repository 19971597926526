import { AxiosResponse } from "axios";
import { Locale } from "models/Countries";
import { createHttp } from "services/helper";
import { IContractInsuranceCompanyResponse, IInsuranceCompany } from "./types";

export default {
  getInsuranceCompanies(
    country: Locale,
    token: string
  ): Promise<AxiosResponse<IInsuranceCompany[]>> {
    return createHttp(token).get(`insurances/v2/insurance_companies/?country_code=${country}`);
  },
  postInsuranceCompanyContract(
    data: {
      insurance_company: number;
      contract: number;
      guaranteed_months: number;
    },
    token: string
  ): Promise<AxiosResponse<IContractInsuranceCompanyResponse>> {
    return createHttp(token).post("insurances/v2/insurance_company_contract/", data);
  },
  patchInsuranceCompanyContract(
    data: {
      insurance_company: number;
      contract: number;
      guaranteed_months: number;
      id: number;
    },
    token: string
  ): Promise<AxiosResponse<IContractInsuranceCompanyResponse>> {
    return createHttp(token).patch(`insurances/v2/insurance_company_contract/${data.id}`, data);
  },
  getInsuranceCompanyByContract(
    contractId: string,
    token: string
  ): Promise<AxiosResponse<IContractInsuranceCompanyResponse>> {
    return createHttp(token).get(
      `insurances/v2/insurance_company_contract/get_by_contract/?contract_id=${contractId}`
    );
  },
};
