import { Grid } from "@material-ui/core";

import HeaderPromotion from "../../components/HeaderPromotion";
import PromotionForm from "../../components/PromotionForm";
import useLocationData from "../../hooks/useLocationData";
import { promotionSteps } from "./utils/utils";
import Title from "./components/Title";

const CreatePromotion = () => {
  const { cities, country } = useLocationData();

  return (
    <Grid container>
      <HeaderPromotion country={country} promotionSteps={promotionSteps} />
      <Title />
      <PromotionForm cities={cities} country={country} />
    </Grid>
  );
};

export default CreatePromotion;
