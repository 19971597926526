import { makeStyles, Theme } from "@material-ui/core";

const useStatusLabelReservationStyles = makeStyles<Theme>((theme) => ({
  chipPaid: {
    fontWeight: 700,
    maxHeight: 18,
    border: "1px solid #439F03",
    color: "#439F03",
    backgroundColor: "#DAEECC",
  },
  chipUnpaid: {
    fontWeight: 700,
    maxHeight: 18,
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[5],
  },
  chipCanceled: {
    fontWeight: 700,
    maxHeight: 18,
    border: "1px solid #AE0400",
    borderColor: "#AE0400",
    color: "#AE0400",
    backgroundColor: "transparent",
  },
  chipRefunded: {
    fontWeight: 700,
    maxHeight: 18,
    border: "1px solid #124FA8",
    color: "#124FA8",
    backgroundColor: "#D5E3F8",
  },
  chipDefault: {
    fontWeight: 700,
    maxHeight: 18,
  },
}));

export default useStatusLabelReservationStyles;
