import clx from "classnames";

import { Typography, TypographyProps } from "@material-ui/core";

import useModalTitleStyles from "./ModalTitleStyles";

function ModalTitle({ children, className, ...props }: TypographyProps) {
  const classes = useModalTitleStyles();

  return (
    <Typography className={clx(classes.modalTitleRoot, className)} {...props}>
      {children}
    </Typography>
  );
}

export default ModalTitle;
