import { VisibilityOutlined, CalendarTodayOutlined, FavoriteBorder } from "@material-ui/icons";
import { Box } from "@material-ui/core";

import { Root } from "./IndicatorsStyles";

interface Props {
  views?: number;
  visits?: number;
  favorites?: number;
}

function Indicators({ views = 0, visits = 0, favorites = 0 }: Props) {
  return (
    <Root>
      <Box>
        <VisibilityOutlined fontSize="small" /> {views} visualizaciones
      </Box>
      <Box>
        <CalendarTodayOutlined fontSize="small" /> {visits} citas agendadas
      </Box>
      <Box>
        <FavoriteBorder fontSize="small" /> {favorites} interesados
      </Box>
    </Root>
  );
}

export default Indicators;
