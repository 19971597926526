import { es } from "date-fns/locale";
import { format } from "date-fns";

import { Container, Typography } from "@material-ui/core";

import { IReservation } from "services/newPaymentService/types";
import { parsePrice } from "utils";

import useOutflowReservationDataStyles from "./OutflowReservationDataStyles";

interface Props {
  reservation: IReservation;
}

const accountTypeMapping = {
  CURRENT: "Cuenta Corriente",
  SIGHT: "Cuenta Vista",
  SAVING: "Cuenta de Ahorro",
};

const OutflowReservationData = ({ reservation }: Props) => {
  const classes = useOutflowReservationDataStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.label}>
        <b>Descripción: </b> {reservation.description}
      </Typography>
      <Typography className={classes.label}>
        <b>Fecha pactada de pago: </b>
        {format(new Date(reservation.agreed_date), "dd/MM/yyyy", {
          locale: es,
        })}
      </Typography>
      <Typography className={classes.label}>
        <b>Fecha de pago: </b>
        {reservation?.date
          ? format(new Date(reservation.date), "dd/MM/yyyy", {
              locale: es,
            })
          : "-"}
      </Typography>
      <Typography className={classes.label}>
        <b>Monto a pagar: </b>
        {parsePrice(reservation.total_amount, reservation.currency)}
      </Typography>
      <Typography className={classes.label}>
        <b>Monto pagado: </b>
        {reservation?.payment ? parsePrice(reservation.payment, reservation.currency) : "-"}
      </Typography>
      <Typography className={classes.label}>
        <b>Nombre: </b> {reservation.person_data.full_name}
      </Typography>
      <Typography className={classes.label}>
        <b>Documento: </b> {reservation.person_data.document}
      </Typography>
      <Typography className={classes.label}>
        <b>Banco: </b> {reservation.person_data.bank_name}
      </Typography>
      <Typography className={classes.label}>
        <b>Tipo de cuenta: </b> {accountTypeMapping[reservation.person_data.account_type]}
      </Typography>
      <Typography className={classes.label}>
        <b>N° Cuenta: </b> {reservation.person_data.account_number}
      </Typography>
    </Container>
  );
};

export default OutflowReservationData;
