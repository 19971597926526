import { FormProvider, SubmitHandler } from "react-hook-form";
import ModalDialog from "@houm-com/ui/ModalDialog";
import FieldSelect from "@houm-com/ui/FieldSelect";
import Spinner from "@houm-com/ui/Spinner";

import useReactHookForm from "hooks/useReactHookForm";
import useAllAppraisers from "hooks/useAllAppraisers";
import useUpdateHoumer from "hooks/useUpdateHoumer";
import { useFormNotifications } from "hooks";

import { houmerFormSchema, requiredText } from "../../utils/validationSchema";
import { FormData } from "../../utils/types";

interface Props {
  houmerId: string;
  open: boolean;
  handleOpenModal: () => void;
  applicantId: string;
}

const HoumerModal = ({ houmerId, open, handleOpenModal, applicantId }: Props) => {
  const { appraisers, isLoading, isError, isSuccess } = useAllAppraisers({
    enabled: open,
  });
  const { mutate: updateHoumer, isLoading: isUpdateLoading } = useUpdateHoumer({
    applicantId,
    onSuccessHandler: () => handleOpenModal(),
  });
  const { methods, errors } = useReactHookForm<FormData>({
    formSchema: houmerFormSchema,
    formOptions: {
      defaultValues: {
        houmerId: houmerId || null,
      },
    },
  });

  useFormNotifications<FormData>({ errors, requiredText });
  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (isUpdateLoading) return;
    updateHoumer({
      houmerId: data.houmerId,
    });
  };

  return (
    <ModalDialog title="Cambiar Houmer" isOpen={open} onClose={handleOpenModal}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isLoading && (
            <Spinner classNameContainer="flex justify-center" variant="primary" size="xl" />
          )}
          {isError && <p className="text-p16">Error al obtener listado de houmers</p>}
          {isSuccess && (
            <>
              <ModalDialog.Content classNameContent="flex flex-col p-1 gap-2">
                <FieldSelect
                  label="Houmer"
                  id="houmerId"
                  errorMessage={errors.houmerId?.message}
                  placeholder="Selecciona un houmer"
                  options={appraisers.map((appraiser) => ({
                    label: `${appraiser.name}`,
                    value: appraiser.id,
                  }))}
                  form="novalidateform"
                  {...methods.register("houmerId")}
                />
              </ModalDialog.Content>
              <ModalDialog.Actions
                onClose={handleOpenModal}
                submitButtonLabel="Cambiar"
                submitButtonProps={{
                  type: "submit",
                  isLoading: isUpdateLoading,
                }}
              />
            </>
          )}
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default HoumerModal;
