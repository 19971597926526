import axios from "axios";

export type PresignedPostUrlResponse = {
  url: string;
  fields: {
    key: string;
    "x-amz-algorithm": string;
    "x-amz-credential": string;
    "x-amz-date": string;
    policy: string;
    "x-amz-signature": string;
  };
  filePath: string;
};

interface UploadProps {
  fileContents: File;
  presignedUrl: PresignedPostUrlResponse;
}

export async function uploadToS3({ fileContents, presignedUrl }: UploadProps) {
  const formData = new FormData();
  Object.entries(presignedUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", fileContents);
  const response = await axios.post(presignedUrl.url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export const getFileExtension = (filePath: string) => filePath.split(".").pop();
