import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";

import { ISchedule } from "hooks/useGetSchedules/utils/types";

interface Props {
  schedule: ISchedule;
}

function ScheduleApplicant({ schedule }: Props) {
  return (
    <>
      {schedule?.applicant && (
        <Link to={`/admin/applicants/${schedule.applicant.id}`} target="_blank">
          {schedule.applicant.fullname}
        </Link>
      )}
      <Typography>{schedule?.comment}</Typography>
    </>
  );
}

export default ScheduleApplicant;
