import { ReactNode } from "react";

import { Grid, Typography } from "@material-ui/core";

import useReservationDetailsStyles from "./ReservationDetailsStyles";

interface Props {
  type: "Houm" | "Pago clientes" | "Recaudado";
  children: ReactNode;
}

const ReservationDetails = ({ type, children }: Props) => {
  const classes = useReservationDetailsStyles();
  return (
    <Grid item xs={12}>
      <Typography className={classes.label}>{type}</Typography>
      {children}
    </Grid>
  );
};

export default ReservationDetails;
