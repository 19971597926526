import {
  InspectionReportItemsNames,
  InspectionReportItemUpdateData,
  InspectionReportUpdateData,
} from "services/propertyService/types";

export const parseInspectionReportItemDataForm = (
  spaceId: number,
  itemName: InspectionReportItemsNames,
  data: InspectionReportItemUpdateData
): InspectionReportUpdateData => ({
  spaces: [
    {
      id: spaceId,
      [itemName]: {
        qualification: data.qualification,
        observation: data.observation,
        quantity: data.quantity,
      } as InspectionReportItemUpdateData,
    },
  ],
});
