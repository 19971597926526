import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider } from "react-hook-form";

import { useGetToken } from "hooks";
import { useProperty } from "context/propertyContext/propertyContext";
import { updateHCDetails, getProperty } from "context/propertyContext/propertyActions";

import { keysSchema } from "./validationSchema";

import VisitCard, { TitleCard, useVisitCard } from "../VisitCard";
import keysFields from "./fields";
import { IKeys } from "../../utils/types";

interface Props {
  keysData: IKeys;
}

function KeysCard({ keysData }: Props) {
  const { state, dispatch } = useProperty();
  const { getToken } = useGetToken();

  const hcId = state.property?.home_checker_details?.[0].id;
  const keyManager = keysData?.location ?? state.property?.home_checker_details?.[0].key_manager;
  const updatedAt = keysData?.updatedAt;

  const { methods, goNextStep, label, currentStep, goBackStep } = useVisitCard({
    resolver: yupResolver(keysSchema),
  });

  const fields = keysFields({
    country: state.property.country,
    currentStep,
    keyManager,
    updatedAt,
  });

  const handleSubmit = async (data) => {
    const authToken = await getToken();
    const isUpdated = await updateHCDetails(
      { ...data, has_houm_box: false },
      hcId,
      dispatch,
      authToken
    );
    if (isUpdated) {
      await getProperty(state.property.uid, authToken, dispatch);
      goBackStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <VisitCard
          required
          id="keys"
          items={fields}
          title={<TitleCard title="Ubicación de llaves" />}
          buttonOptions={{
            buttonLabel: label,
            buttonCallback: goNextStep,
            buttonPosition: "right",
            isSubmit: currentStep === "editable",
          }}
          isEditable={!keysData}
        />
      </form>
    </FormProvider>
  );
}

export default KeysCard;
