/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from "react";

import { Controller, useFormContext } from "react-hook-form";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";

interface Props {
  label: React.ReactNode;
  id: string;
  defaultValue?: string | number;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  textFieldProps?: Partial<React.ComponentProps<typeof CustomTextField>>;
  disabled?: boolean;
  labelClassName?: string;
  wrapperPadding?: boolean;
  format?: (string) => string;
  bordered?: boolean;
  warn?: (value: string) => boolean;
  warningMessage?: string;
}

function ControlledTextField({
  label,
  id,
  defaultValue,
  controllerProps,
  wrapperPadding = true,
  textFieldProps,
  labelClassName,
  disabled = false,
  bordered = false,
  format = (str) => str,
  warn = () => false,
  warningMessage = "",
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue ?? ""}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState: { invalid, error } }) => (
        <CustomTextField
          disabled={disabled}
          id={id}
          label={label}
          value={value}
          inputRef={ref}
          onChange={onChange}
          bordered={bordered}
          onBlur={(e) => {
            onChange(format(e.target.value));
          }}
          error={invalid}
          errorMessage={error?.message}
          labelClassName={labelClassName}
          wrapperPadding={wrapperPadding}
          warning={warn(value)}
          warningMessage={warningMessage}
          {...textFieldProps}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledTextField;
