import Typography from "@material-ui/core/Typography";

import { IParsePlan } from "hooks/usePlans/utils/types";

import usePlanDetailsStyles from "./PlanDetailsStyles";

interface Props {
  planDetails: IParsePlan;
}

function PlanDetails({ planDetails }: Props) {
  const classes = usePlanDetailsStyles();
  return (
    <>
      <Typography variant="h6" className={classes.textLabelTitle}>
        Detalle del plan
      </Typography>
      <div className={classes.textContainer}>
        <Typography component="span" className={classes.textLabel}>
          Corretaje propietario
        </Typography>
        <Typography component="span" className={classes.textLabel}>
          {planDetails?.lessorBrokerage ? Number(planDetails?.lessorBrokerage) : ""}
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography component="span" className={classes.textLabel}>
          Corretaje arrendatario
        </Typography>
        <Typography component="span" className={classes.textLabel}>
          {planDetails?.lesseeBrokerage ? Number(planDetails?.lesseeBrokerage) : ""}
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography component="span" className={classes.textLabel}>
          Administración
        </Typography>
        <Typography component="span" className={classes.textLabel}>
          {planDetails?.adminCommission ? Number(planDetails?.adminCommission) : ""} %
        </Typography>
      </div>
    </>
  );
}

export default PlanDetails;
