import { Grid, Typography } from "@material-ui/core";
import moment from "moment";

import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import { useAvailability } from "context/availabilityContext/availabilityContext";
import { defaultHours } from "../LeadUtils";
import useStyles from "./DailyScheduleStyles";
import { scheduleOptions } from "./LeadConsts";

interface Props {
  day: string;
  index: number;
}

function DailySchedule({ day, index }: Props) {
  const classes = useStyles();
  const { state } = useAvailability();

  const daySchedule = state.propertyAvailability.filter((item) => item.day === index);

  const isHourChecked = (daySchedule, currentDate) => {
    const isHourPresent = daySchedule.filter(
      (item) =>
        moment(item.beginHour, "HH:mm:ss").format("HH") ===
        moment(currentDate, "HH:mm:ss").format("HH")
    );
    return isHourPresent.length > 0;
  };

  if (!state) return null;
  return (
    <Grid item container className={classes.wraper}>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {scheduleOptions.find((item) => item.value === day).label}
        </Typography>
      </Grid>
      <Grid
        className={classes.container}
        item
        container
        justifyContent="space-around"
        xs={12}
        direction="column">
        {defaultHours.slice(0, -1).map((item, i) => (
          <Grid item key={`${index}.${defaultHours[i].label}`}>
            <ControlledCheckbox
              label={`${defaultHours[i].label}-${defaultHours[i + 1].label}`}
              id={`${index}_${defaultHours[i].label}_${defaultHours[i + 1].label}`}
              placement="end"
              justifyContent="center"
              defaultChecked={isHourChecked(daySchedule, defaultHours[i].label)}
              size="small"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default DailySchedule;
