import { useState } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { useGetToken } from "hooks";
import { PropertyDetails, SalesDetails } from "models";
import { updatePropertyDetails, updateSalesDetails } from "context/projectContext/projectActions";
import ObservationItem from "./ObservationItem";
import useStyles from "./ObservationsStyles";
import { PropertyData } from "models/Property";

interface Props {
  property: PropertyData;
}

function Observations({ property }: Props) {
  const { getToken } = useGetToken();
  const [rentalObservation, setRentalObservation] = useState<string>(
    property.property_details[0]?.observaciones
  );
  const [salesObservation, setSalesObservation] = useState<string>(
    property.property_details[0]?.observaciones_venta
  );
  const [rentalExternalDescription, setRentalExternalDescription] = useState<string>(
    property.property_details[0]?.external_description
  );
  const [salesExternalDescription, setSalesExternalDescription] = useState<string>(
    property.sales_details?.external_description
  );

  const handleUpdatePropertyDetails = async (newData: PropertyDetails) => {
    const authToken = await getToken();
    await updatePropertyDetails(property.id, newData, authToken);
  };

  const publishRentalDescription = async () => {
    const sendData: PropertyDetails = {
      observaciones: rentalObservation,
    };
    await handleUpdatePropertyDetails(sendData);
  };

  const publishRentalExternalDescription = async () => {
    const sendData: PropertyDetails = {
      external_description: rentalExternalDescription,
    };
    await handleUpdatePropertyDetails(sendData);
  };

  const publishSalesDescription = async () => {
    const sendData: PropertyDetails = {
      observaciones_venta: salesObservation,
    };
    await handleUpdatePropertyDetails(sendData);
  };

  const publishSalesExternalDescription = async () => {
    const authToken = await getToken();
    const sendData: SalesDetails = {
      external_description: salesExternalDescription,
    };
    await updateSalesDetails(property.id, sendData, authToken);
  };

  const classes = useStyles();

  if (property.property_details.length === 0)
    return (
      <AdminCard>
        <Typography className={classes.title}>
          Aún no existe el detalle de la propiedad...
        </Typography>
        <Link to={`/admin/properties/${property.uid}/complete`}>
          <CustomButton active kind="blue">
            Completar detalle
          </CustomButton>
        </Link>
      </AdminCard>
    );

  return (
    <AdminCard>
      {property.for_rental && (
        <Grid container>
          <ObservationItem
            title="Descripción Arriendo"
            buttonText="Actualizar Descripción Arriendo"
            onTextChange={(e) => setRentalObservation(e.target.value)}
            value={rentalObservation}
            onClick={publishRentalDescription}
          />
          <ObservationItem
            title="Descripción Arriendo en Portales Externos"
            buttonText="Actualizar Descripción Arriendo Portales Externos"
            onTextChange={(e) => setRentalExternalDescription(e.target.value)}
            value={rentalExternalDescription}
            onClick={publishRentalExternalDescription}
          />
        </Grid>
      )}
      <Divider className={classes.divider} />
      {property.for_sale && (
        <Grid container>
          <ObservationItem
            title="Descripción Venta"
            buttonText="Actualizar Descripción Venta"
            onTextChange={(e) => setSalesObservation(e.target.value)}
            value={salesObservation}
            onClick={publishSalesDescription}
          />
          <ObservationItem
            title="Descripción Venta en Portales Externos"
            buttonText="Actualizar Descripción Venta Portales Externos"
            onTextChange={(e) => setSalesExternalDescription(e.target.value)}
            value={salesExternalDescription}
            onClick={publishSalesExternalDescription}
          />
        </Grid>
      )}
    </AdminCard>
  );
}

export default Observations;
