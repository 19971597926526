import { Typography } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useContractCreated from "./hooks/useContractCreated";
import ContractForm from "./components/ContractForm";
import { parserTenants } from "./utils/utils";

const ContractNoEvaluation = ({ applicant, user }) => {
  const { contractFlagLoading, contractFlagData } = useContractCreated(applicant?.id);

  return (
    <AdminCard title="Generar Contrato" showTitleBackground={true} withSpace={false}>
      <Loading loading={contractFlagLoading}>
        {contractFlagData ? (
          <Typography>Este contrato ya fue generado</Typography>
        ) : (
          <ContractForm tenants={parserTenants(applicant, user)} applicantId={applicant?.id} />
        )}
      </Loading>
    </AdminCard>
  );
};

export default ContractNoEvaluation;
