import { format } from "date-fns";

import { renderAddress } from "utils";
import { ContractModel } from "models/Contract";
import { PropertyData } from "models/Property";

export const propertyFormDefaultValues = (contract: ContractModel, property: PropertyData) => ({
  id: property.id,
  publishedDate: property?.last_publication_date
    ? format(new Date(property?.last_publication_date), "dd/MM/yyyy")
    : "-",
  address: renderAddress(property),
  max_people_quantity: contract?.max_people_quantity?.toString() ?? "",
  street_number: property?.street_number,
  numero_depto: property?.numero_depto ?? "",
  comuna: property?.comuna ?? "",
  stratus: property.stratus,
  antiquity: property?.antiquity,
  state: property?.state ?? "",
  parking_number: contract?.parking_number ?? "",
  warehouse_number: contract?.warehouse_number ?? "",
  pet_bans: contract?.pet_bans ?? "",
  other_bans: contract?.other_bans ?? "",
});
