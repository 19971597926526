import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { Grid } from "@material-ui/core";

import { IPlanMovement, ContractModel, ISimulationData } from "models/Contract";
import CustomTabs from "Components/Admin/UIComponents/CustomTabs";
import contractService from "services/contractService";
import { getPaymentSimulationDetails } from "../utils";
import useStyles from "./PlanPaymentsDetailsStyles";
import ClosingDetails from "./ClosingDetails";
import PaymentsInfo from "./PaymentsInfo";
import { useGetToken } from "hooks";

interface Props {
  contract: ContractModel;
  simulationData: ISimulationData;
  loadingSimulation: boolean;
  onFetchSimulation: ({ contractId, save }) => void;
}

function PlanPaymentsDetails({
  contract,
  simulationData,
  loadingSimulation,
  onFetchSimulation,
}: Props) {
  const { getToken } = useGetToken();
  const [planMovement, setPlanMovement] = useState<IPlanMovement[]>([]);
  const [loadingPlan, setLoadingPlan] = useState(true);

  const getPlanMovements = async () => {
    setLoadingPlan(true);
    try {
      const authToken = await getToken();
      const movements = await contractService.getPlanMovements(contract.id, authToken);
      setPlanMovement(movements.data);
    } catch (e) {
      Swal.fire({
        type: "error",
        title: "Error",
        text: `No se pudo obtener los cargos y abonos`,
      });
    } finally {
      setLoadingPlan(false);
    }
  };

  const getSimulation = () =>
    onFetchSimulation({
      contractId: contract?.id,
      save: false,
    });

  useEffect(() => {
    getPlanMovements();
    getSimulation();
  }, []);

  const tabs = [
    {
      index: 0,
      header: "Cobros arrendatario",
      component: (
        <PaymentsInfo
          type="lessee"
          contract={contract}
          planMovement={planMovement}
          loadingPlan={loadingPlan}
          simulationData={simulationData}
          loadingSimulation={loadingSimulation}
          getSimulation={getSimulation}
          setPlanMovement={setPlanMovement}
        />
      ),
    },
    {
      index: 1,
      header: "Pago al propietario",
      component: (
        <PaymentsInfo
          type="lessor"
          contract={contract}
          planMovement={planMovement}
          loadingPlan={loadingPlan}
          simulationData={simulationData}
          loadingSimulation={loadingSimulation}
          getSimulation={getSimulation}
          setPlanMovement={setPlanMovement}
        />
      ),
    },
  ];

  const classes = useStyles();

  return (
    <>
      {!loadingSimulation && simulationData && simulationData.contract_details && (
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.closingDetails}>
            <ClosingDetails
              details={getPaymentSimulationDetails(
                simulationData.contract_details,
                contract.property.country,
                "lessee"
              )}
              type="lessee"
            />
          </Grid>
          <Grid item xs={6} className={classes.closingDetails}>
            <ClosingDetails
              details={getPaymentSimulationDetails(
                simulationData.contract_details,
                contract.property.country,
                "lessor"
              )}
              type="lessor"
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className={classes.tabsContent}>
        <CustomTabs
          tabsContent={tabs}
          aria="plans"
          tabsProps={{ classes: { root: classes.tabs } }}
        />
      </Grid>
    </>
  );
}

export default PlanPaymentsDetails;
