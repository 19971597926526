export const parseDocumentColombia = (documentType: number) => {
  switch (documentType) {
    case 2:
      return "CC";
    case 3:
      return "NIT";
    case 4:
      return "PASSPORT";
    case 11:
      return "CE";
    case 12:
      return "PEP";
    default:
      return "SE";
  }
};

export default parseDocumentColombia;
