import { makeStyles } from "@material-ui/core";

const useTenantTableStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    margin: theme.spacing(2, 3, 3, 3),
  },
}));

export default useTenantTableStyles;
