import * as yup from "yup";
import { homeCheckerDetailsValidationObject } from "utils/yupValidators/property";

export const requiredText = "Obligatorio";

const {
  administrationEmail,
  administrationPhone,
  concierge,
  conciergeEmail,
  conciergeName,
  conciergePhone,
  buildingAdministration,
  moveInPolicy,
  moveInPolicyPhoto,
  inhabitedProperty,
  thirdPartyCoordination,
  thirdPartyCoordinationDescription,
} = homeCheckerDetailsValidationObject;

export default yup.object({
  administrationEmail,
  administrationPhone,
  concierge,
  conciergeEmail,
  conciergeName,
  conciergePhone,
  buildingAdministration,
  moveInPolicy,
  moveInPolicyPhoto,
  inhabitedProperty,
  thirdPartyCoordination,
  thirdPartyCoordinationDescription,
});
