/* eslint-disable camelcase */

// eslint-disable-next-line no-unused-vars

import { ExternalPortalPublicationsTableFilterTypes } from "Components/Admin/administrator/integrations/pages/externalPortalPublications/utils/filters";
import { QuotaByMacrozoneTableFilterTypes } from "Components/Admin/administrator/integrations/pages/portalQuotas/components/QuotaByMacrozone/utils/filters";
import { TotalQuotaTableFilterTypes } from "Components/Admin/administrator/integrations/pages/portalQuotas/components/TotalQuota/utils/filters";
import { PropertyStatusViewNameFilter } from "Components/Admin/administrator/supply/utils/propertyStatusFilterUtils";
import { CountryUpperCase } from "models/Countries";

export type Dispatch = (action: Action) => void;

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ORDERING = "SET_ORDERING";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_SECOND_MODAL = "SHOW_SECOND_MODAL";
export const SHOW_THIRD_MODAL = "SHOW_THIRD_MODAL";
export const SET_TABLE_FILTERS = "SET_TABLE_FILTERS";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";

export interface State {
  pageSize: number;
  currentPage: number;
  ordering: string | null;
  showModal: boolean;
  showSecondModal: boolean;
  showThirdModal: boolean;
  tableFilters: TableFilters;
}

interface setPageSize {
  type: typeof SET_PAGE_SIZE;
  pageSize: number;
}

interface setCurrenPage {
  type: typeof SET_CURRENT_PAGE;
  currentPage: number;
}

interface setOrdering {
  type: typeof SET_ORDERING;
  ordering: string | null;
}

interface setSearchQuery {
  type: typeof SET_SEARCH_QUERY;
  searchQuery: string;
}

interface showModal {
  type: typeof SHOW_MODAL;
  showModal: boolean;
}

interface showSecondModal {
  type: typeof SHOW_SECOND_MODAL;
  showSecondModal: boolean;
}

interface showThirdModal {
  type: typeof SHOW_THIRD_MODAL;
  showThirdModal: boolean;
}
interface setTableFilters {
  type: typeof SET_TABLE_FILTERS;
  tableFilters: TableFilters;
}

export type Action =
  | setPageSize
  | setCurrenPage
  | showModal
  | showSecondModal
  | showThirdModal
  | setTableFilters
  | setOrdering
  | setSearchQuery;

export type TableFilters = LandlordVipFilters &
  PropertyTableFilters &
  UsersTableFilters &
  PaymentTableFilters &
  BasicServicesTableFilters &
  PropTableFilters &
  ExternalPortalPropertiesTableFilters &
  DefaultTableFilters &
  LeadsIndexFilters &
  SupplyPropertiesStatus &
  PropertyInspectionReportsTableFilters &
  ExternalPortalPublicationsTableFilterTypes &
  TotalQuotaTableFilterTypes &
  QuotaByMacrozoneTableFilterTypes;

export interface LandlordVipFilters {
  executiveEmail?: string;
}
export interface PropertyTableFilters {
  has_houm_box?: boolean;
  houm_owner?: string; // ejecutivo
  landlord?: string; // propietario
  homechecker?: string;
  rental_status?: string;
  rental_status_not?: string;
  sales_status_not?: string;
  sale_status?: string;
  id?: string;
}

export interface UsersTableFilters {
  id?: string;
  createdAt?: string;
  email?: string;
  phone?: string;
  name?: string;
}

export interface PaymentTableFilters {
  id?: string;
  address?: string;
  state?: boolean;
  initDate?: Date;
  endDate?: Date;
  filterType?: string;
}

export interface BasicServicesTableFilters {
  id?: string;
  address?: string;
  state?: boolean;
  enterprise?: string;
  clientNumber?: string;
  filterType?: string;
}

export interface PropTableFilters {
  id?: string;
  address?: string;
  stateProp?: string;
}

export interface DefaultTableFilters {
  search_query?: string;
  country?: CountryUpperCase;
}

export interface LeadsIndexFilters {
  owner?: string;
  portal?: string;
  listing?: string;
  has_qualification?: boolean;
  id?: string;
  status__in?: string;
  qualification__in?: string;
}

export interface ExternalPortalPropertiesTableFilters {
  city?: string;
  portal?: string;
  listing?: string;
}

export interface SupplyPropertiesStatus {
  view_name?: PropertyStatusViewNameFilter;
}

export interface PropertyInspectionReportsTableFilters extends DefaultFilters {
  type__in?: string;
  date_after?: string;
  date_before?: string;
}

export interface DefaultFilters {
  ordering?: string;
  page?: number;
  page_size?: number;
}

export interface PaginatedTable<T> {
  data: T[];
  loading: boolean;
  count: number;
  refetch: boolean;
}
