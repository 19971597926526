import currency from "@houm-com/formats/currency";
import { Locale } from "models/Countries";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";

import useDeleteAdjustment from "Components/Admin/administrator/subscriptions/hooks/useDeleteAdjustment";
import { IDetails } from "models/Breakdown";

interface Props {
  open: boolean;
  onHandleClose: () => void;
  movementId: string;
  country: Locale;
  detail: IDetails;
}

const DeleteAdjustmentModal = ({ open, onHandleClose, movementId, country, detail }: Props) => {
  const type = detail?.type === "charge" ? "descuento" : "abono";
  const { mutate, isLoading } = useDeleteAdjustment(type, onHandleClose);

  return (
    <ConfirmDialog
      title={`¿Seguro que quieres quitar el ${type} por ${detail.description.toLowerCase()} de ${currency(
        detail.value,
        detail.currency
      )}?`}
      isOpen={open}
      onClose={onHandleClose}
      onSubmit={() =>
        mutate({
          adjustmentId: detail.identifier,
          country,
          movementId,
        })
      }
      submitButtonProps={{
        isLoading,
      }}
      submitButtonLabel="Sí, quitar"
    />
  );
};

export default DeleteAdjustmentModal;
