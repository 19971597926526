import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";

import { ArrowBack, ArrowForward } from "@material-ui/icons";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Calendar } from "@fullcalendar/core";
import { Grid } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import propertyService from "services/propertyService";
import { CountryUpperCase } from "models/Countries";
import useCalendarStyles from "./CalendarStyles";
import { useGetToken } from "hooks";
import { getTimezone } from "utils";

interface Props {
  propertyId: number;
  country: CountryUpperCase;
  commune: string;
}

let calendar: Calendar;
function PropertySchedules({ propertyId, country, commune }: Props) {
  const classes = useCalendarStyles();
  const { getToken } = useGetToken();
  const [loading, setLoading] = useState(true);

  const fetchSchedules = async () => {
    const authToken = await getToken();
    try {
      const fetchingSchedules = await propertyService.pendingSchedules(propertyId, authToken);
      fetchingSchedules.data.forEach((item, key) => {
        calendar.addEvent({
          title: "Visita",
          start: item.begin_date,
          end: item.begin_date,
          className: "",
          id: key,
        });
      });
      calendar.render();
    } catch {
      Swal.fire({
        type: "error",
        title: "Error",
        text: "No se pudieron cargar los horarios",
      });
    } finally {
      setLoading(false);
    }
  };

  const createCalendar = () => {
    const calendarEl = document.getElementById("calendar");
    const timezone = getTimezone(country, commune);
    moment.tz.setDefault(timezone);
    if (calendarEl != null) {
      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        timeZone: timezone,
      });
      calendar.render();
    }
  };

  useEffect(() => {
    createCalendar();
    fetchSchedules();
  }, [propertyId]);

  return (
    <Grid container>
      <Loading loading={loading}>
        <Grid
          item
          container
          className="calendar-view-handlers"
          xs={12}
          justifyContent="space-between">
          <Grid item>
            <span
              onClick={() => {
                calendar.prev();
              }}
              id="hoverable">
              <ArrowBack className={classes.arrowBack} />
              Mes anterior
            </span>
          </Grid>
          <Grid item>
            <span
              onClick={() => {
                calendar.next();
              }}
              id="hoverable">
              Siguiente mes
              <ArrowForward className={classes.arrowForward} />
            </span>
          </Grid>
        </Grid>
      </Loading>
      <Grid item xs={12}>
        <div id="calendar" className={classes.calendar} />
      </Grid>
    </Grid>
  );
}

export default PropertySchedules;
