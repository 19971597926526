import { makeStyles, Theme } from "@material-ui/core";

const UserSearchBarStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0, 2),
    },
  },
  label: {
    fontWeight: 700,
    marginBottom: 0,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  helper: {
    marginTop: -theme.spacing(0.8),
  },
}));

export default UserSearchBarStyles;
