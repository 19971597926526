import { useEffect, useState } from "react";
import moment from "moment";

import { Grid } from "@material-ui/core";
import { GridCellParams, GridColDef, GridColumns } from "@material-ui/x-grid";

import useNegotiationsTabStyles from "./NegotiationsTabIndexStyles";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { PropertyData } from "models/Property";
import { useGetToken } from "hooks";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import { fetchDataAdmin } from "context/negotiationsContext/negotiationsActions";
import { formatPrice } from "context/negotiationsContext/utils";
import { countryCode } from "utils";
import { StatusOffer } from "./StatusOffer";

interface NegotiationsTabIndexProps {
  property: PropertyData;
}

function NegotiationsTabIndex({ property }: NegotiationsTabIndexProps) {
  const classes = useNegotiationsTabStyles();
  const { getToken } = useGetToken();
  const { state, dispatch } = useNegotiations();
  const { negotiationsAdmin } = state;
  const code = countryCode(property.country);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    getToken().then((authToken) => fetchDataAdmin(property.uid, code, authToken, dispatch));
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh((refresh) => refresh + 1);
  };

  const columnsConfig: GridColDef = {
    field: "",
    resizable: true,
    flex: 1,
    filterable: false,
  };

  const columns: GridColumns = [
    {
      ...columnsConfig,
      field: "bidderName",
      headerName: "Comprador",
      sortable: false,
      renderCell: ({ row }: GridCellParams) => row.bidder.name,
    },
    {
      ...columnsConfig,
      field: "amount",
      headerName: "Precio ofertado",
      sortable: false,
      renderCell: ({ row }: GridCellParams) =>
        formatPrice(row.lastBid.amount, row.lastBid.currency),
    },
    {
      ...columnsConfig,
      field: "paymentMethod",
      headerName: "Forma de pago",
      sortable: false,
      renderCell: ({ row }: GridCellParams) => row.lastBid.paymentMethod,
    },
    {
      ...columnsConfig,
      field: "offerDate",
      headerName: "Fecha",
      renderCell: ({ row }: GridCellParams) => moment(row.lastBid.createdAt).format("LLLL"),
    },
    {
      ...columnsConfig,
      field: "status",
      headerName: "Estado oferta",
      sortable: false,
      renderCell: ({ row }: GridCellParams) => row.lastBid.status,
    },
    {
      ...columnsConfig,
      field: "status",
      headerName: "Estado negociación",
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <StatusOffer
          negotiationStatus={row.status}
          offerStatus={row.lastBid.status}
          offerId={row.lastBid.id}
          country={property.country}
          handleRefresh={handleRefresh}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CollapseCard title="Seguimiento  de las negociaciones">
          <div className={classes.collapse}>
            <CustomDataGrid
              className={classes.table}
              rowCount={negotiationsAdmin?.length}
              columns={columns}
              rows={negotiationsAdmin}
              hideFooter
              hideFooterPagination
              hideFooterRowCount
            />
          </div>
        </CollapseCard>
      </Grid>
    </Grid>
  );
}

export default NegotiationsTabIndex;
