import User from "Components/Admin/UIComponents/User";

interface Codebtor {
  role: string;
  id: string;
  name: string;
  last_name: string;
  email: string;
  phone: string;
  rut: string;
  second_last_name: string;
  declared_income: number;
  document: string;
}

interface Props {
  codebtors: Codebtor[];
  title: "Propietario" | "Arrendatario" | "Codeudor" | "Coarrendatario" | "Aval";
}

const Codebtors = ({ codebtors, title }: Props) => {
  const renderCodebtors = () => codebtors.map((codebtor) => <User type={title} user={codebtor} />);

  return <div>{renderCodebtors()}</div>;
};

export default Codebtors;
