import { useMutation, useQueryClient } from "react-query";
import { capitalize } from "lodash";
import { AxiosError } from "axios";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { alert } from "libs/alerts";

interface IDeleteAdjustment {
  adjustmentId: string;
  movementId: string;
  country: Locale;
}

const useDeleteAdjustment = (type: "descuento" | "abono", onCloseModal?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isError, isLoading, isSuccess } = useMutation(
    async (data: IDeleteAdjustment) => {
      const authToken = await getToken();
      const response = await newPaymentService.deleteAdjustment(
        data.country,
        data.movementId,
        data.adjustmentId,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("outflowMovements");
        queryClient.invalidateQueries("incomeMovements");
        onCloseModal?.();
        alert.success({
          message: `${capitalize(type)} eliminado`,
          disclaimer: `El ${type} ha sido eliminado exitosamente`,
        });
      },
      onError: (e: AxiosError) => {
        const reason = e?.response;
        alert.error({
          message: `Error al eliminar ${type}`,
          disclaimer: `El ${type} no ha podido ser eliminado, error: ${reason ?? e}`,
        });
      },
    }
  );

  return {
    mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useDeleteAdjustment;
