import { Theme, makeStyles } from "@material-ui/core/styles";

const useDocumentsGenerationProcessStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(2),
  },
  gridContainer: {
    width: "100%",
  },
  textLabel: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
  },
  textLabelTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  textButton: {
    textDecoration: "underline",
    color: theme.palette.grey[500],
    minHeight: "auto",
    minWidth: "auto",
    padding: theme.spacing(1),
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(0),
  },
  buttonRootContent: {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export default useDocumentsGenerationProcessStyles;
