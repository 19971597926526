import { IoRibbonOutline as DealIcon } from "@houm-com/ui-icons";

import ContractPersonEdit from "Components/Admin/administrator/contracts/partials/ContractPersonEdit";
import NewContractListIndex from "Components/Admin/administrator/contracts/contractLists";
import Contract from "Components/Admin/administrator/contracts";
import ContractTemplates from "Components/Admin/administrator/contracts/ContractTemplates";
import AvailablePlans from "Components/Admin/administrator/plans/pages/availablePlans";
import CreatePlan from "Components/Admin/administrator/plans/pages/PlansForm";
import PlanDetail from "Components/Admin/administrator/plans/pages/showPlan";
import CreatePromotion from "Components/Admin/administrator/promotions/pages/CreatePromotion";
import PromotionDetails from "Components/Admin/administrator/promotions/pages/PromotionDetails";
import Promotions from "Components/Admin/administrator/promotions/pages/Promotions";
import ForceSignature from "Components/Admin/administrator/forceSignature/ForceSignature";
import OldCreatePromotion from "Components/Admin/administrator/promotions/pages/OldCreatePromotion";
import DebtCollectionsTable from "Components/Admin/administrator/debtCollections";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Cierre",
  icon: DealIcon,
  children: [
    {
      to: "/admin/contracts",
      label: "Contratos",
      path: "/admin/contracts",
      component: NewContractListIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "control_tower_admin",
        "super_admin",
      ],
    },
    {
      path: "/admin/contracts/:id",
      component: Contract,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "contract",
        "contract_admin",
        "evaluation",
        "evaluation_admin",
        "management",
        "management_admin",
        "sales",
        "sales_admin",
        "control_tower_admin",
        "super_admin",
      ],
    },
    {
      to: "/admin/plans",
      path: "/admin/plans",
      component: AvailablePlans,
      label: "Planes",
      scope: "roles",
      strategy: "any",
      require: ["plan_admin"],
    },
    {
      path: "/admin/plans/create",
      component: CreatePlan,
      require: ["plan_admin"],
    },
    {
      to: "/admin/debt-collections",
      path: "/admin/debt-collections",
      component: DebtCollectionsTable,
      label: "Cobranzas",
      strategy: "any",
      scope: "permissions",
      require: ["read:debts:all"],
    },
    {
      path: "/admin/plans/show/:id",
      component: PlanDetail,
      require: ["plan_admin"],
    },
    {
      to: "/admin/promotions",
      path: "/admin/promotions",
      component: Promotions,
      label: "Promociones",
      scope: "roles",
      strategy: "any",
      require: ["contract_execution_and_management_admin"],
    },
    {
      path: "/admin/promotions/create/:country",
      component: CreatePromotion,
      scope: "roles",
      strategy: "any",
      require: ["contract_execution_and_management_admin"],
    },
    {
      path: "/admin/promotions/create",
      component: OldCreatePromotion,
      scope: "roles",
      strategy: "any",
      require: ["plan_admin"],
    },
    {
      path: "/admin/promotions/:promotionId",
      component: PromotionDetails,
      scope: "roles",
      strategy: "any",
      require: ["contract_execution_and_management_admin"],
    },
    {
      to: "/admin/contract_templates",
      path: "/admin/contract_templates",
      component: ContractTemplates,
      label: "Templates",
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
      ],
    },
    {
      path: "/admin/contract_templates/:id",
      component: ContractTemplates,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
      ],
    },
    {
      path: "/admin/contract-person/:id/force_signature",
      component: ForceSignature,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
        "contract",
        "property_advisor",
        "sales",
      ],
    },
    {
      path: "/admin/contract_person/:id/edit",
      component: ContractPersonEdit,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
      ],
    },
  ],
});
