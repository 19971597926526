import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";

import { useTag } from "context/tagContext/tagContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import { TagForm } from "context/tagContext/tagTypes";
import { assignTag, editTag } from "context/tagContext/tagActions";
import { useGetToken } from "hooks";

import useStyles from "../useTagFormStyles";

function SubmitTag() {
  const classes = useStyles();
  const { state, dispatch } = useTag();
  const { getToken } = useGetToken();
  const { control, handleSubmit } = useFormContext();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const endLess = useWatch({
    control,
    name: "endLess",
    defaultValue: state?.form?.endLess ?? false,
  });

  const beginDate = useWatch({
    control,
    name: "beginDate",
    defaultValue: state?.form?.beginDate ?? false,
  });

  const dueDate = useWatch({
    control,
    name: "dueDate",
    defaultValue: state?.form?.dueDate ?? false,
  });

  const tagId = useWatch({
    control,
    name: "id",
    defaultValue: state?.form?.id ?? null,
  });

  const onSubmitHandler: SubmitHandler<TagForm> = async (data, e) => {
    const tagInfo = {
      ...data,
      ids: state.form.ids,
      country: state.form.country,
    };
    const authToken = await getToken();
    if (state.form.associationId) {
      await editTag(state.form.associationId, tagInfo, dispatch, authToken);
    } else {
      await assignTag(tagInfo, dispatch, authToken);
    }
  };

  useEffect(() => {
    if (beginDate && state.form.ids.length > 0 && tagId && (dueDate || endLess))
      setButtonDisabled(false);
    else setButtonDisabled(true);
  }, [state.form, beginDate, dueDate, tagId, endLess]);

  return (
    <Grid item>
      <DesignSystemButton
        label="Publicar Tag"
        size="medium"
        variant="primary"
        buttonClassName={classes.submitButton}
        disabled={buttonDisabled}
        isLoading={state.loadingPost}
        onClick={handleSubmit(onSubmitHandler)}
      />
    </Grid>
  );
}

export default SubmitTag;
