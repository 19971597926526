import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

import useStyles from "./TokyModalStyles";
import { TokyPhoneHeader, TokyPhoneForm } from "./partials";
import TokyAudioSettings from "./partials/TokyAudioSettings";
import { useToky } from "context/tokyContext/tokyContext";
import {
  getAccessKeys,
  getDidsForAgent,
  initTokyClient,
  setDidCallConnected,
  setOnCloseCallbackFn,
} from "context/tokyContext/tokyActions";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { TOKY_AGENT_ID } from "env";

function TokyModal() {
  const { state, dispatch } = useToky();
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles({
    isPhoneOpen: state.isPhoneOpen,
    phoneDisplayStatus: state.phoneDisplayStatus,
  });

  const initTokySession = async () => {
    const { accessToken } = await getAccessKeys(TOKY_AGENT_ID, dispatch);
    await getDidsForAgent(TOKY_AGENT_ID, accessToken, dispatch);
    await initTokyClient(TOKY_AGENT_ID, accessToken, dispatch, state.callbacks);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleTriggerOnClosePhoneCallbackFn = () => {
    const shouldTriggerOnClosePhoneCallbackfn = state.onCloseCallbackFn && state.didCallConnected;
    if (shouldTriggerOnClosePhoneCallbackfn) {
      state.onCloseCallbackFn(state.tokyCallId);
      dispatch(setOnCloseCallbackFn(null));
    }
  };

  useEffect(() => {
    if (state.isPhoneOpen) initTokySession();
    if (!state.isPhoneOpen) handleTriggerOnClosePhoneCallbackFn();
    if (state.didCallConnected) dispatch(setDidCallConnected(false));
  }, [state.isPhoneOpen]);

  return (
    <Box className={classes.phoneContainer} id="tokyContainer">
      <TokyPhoneHeader setShowModal={setShowModal} />
      {state.isPhoneOpen && <TokyPhoneForm />}
      <CustomDialog
        open={showModal}
        onClose={handleCloseModal}
        title="Configuración audio llamada"
        classes={{
          titleContainer: classes.settingsDialogTitleContainer,
          modalContainer: classes.settingDialogModalContainer,
        }}>
        <TokyAudioSettings />
      </CustomDialog>
    </Box>
  );
}

export default React.memo(TokyModal);
