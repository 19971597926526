import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  cardHeader: {
    height: 40,
    backgroundColor: theme.palette.secondary.main,
    "& .MuiCardHeader-title": {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: "bold",
    },
  },
}));

export default useStyles;
