import { ActionType, State, Action } from "./multifamilyTypes";

export const initialState: State = {
  multifamily: {},
  totalMF: 0,
  multifamilies: [],
  isLoadingDataTable: false,
  filters: {},
  MFImages: [],
  currentId: null,
  neighborhoodOptions: [],
  isOpenFilterModal: false,
};

const demandLeadReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_MULTIFAMILY:
      return {
        ...state,
        multifamily: action.multifamily,
      };

    case ActionType.SET_TOTAL_MF:
      return {
        ...state,
        totalMF: action.totalMF,
      };

    case ActionType.SET_MULTIFAMILIES:
      return {
        ...state,
        multifamilies: action.multifamilies,
      };

    case ActionType.SET_IS_LOADING_DATA_TABLE:
      return {
        ...state,
        isLoadingDataTable: action.isLoadingDataTable,
      };

    case ActionType.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case ActionType.SET_MF_IMAGES:
      return {
        ...state,
        MFImages: action.MFImages,
      };

    case ActionType.SET_CURRENT_ID:
      return {
        ...state,
        currentId: action.currentId,
      };
    case ActionType.SET_NEIGHBORHOOD_OPTIONS:
      return {
        ...state,
        neighborhoodOptions: action.neighborhoodOptions,
      };
    case ActionType.SET_IS_OPEN_FILTER_MODAL:
      return {
        ...state,
        isOpenFilterModal: action.isOpenFilterModal,
      };

    default:
      return state;
  }
};

export default demandLeadReducer;
