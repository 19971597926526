import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import CustomLoadingButton from "Components/Admin/UIComponents/Buttons/CustomLoadingButton";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import useFullContractCardStyles from "./FullContractCardStyles";
import applicantService from "services/applicantService";
import contractService from "services/contractService";
import { EvaluationModel } from "models/Evaluation";
import { ContractModel } from "models/Contract";
import useTooltipStyles from "./TooltipStyles";
import { parseQuotationData } from "./utils";
import { useGetToken } from "hooks";
import { useState } from "react";

interface Props {
  contract: ContractModel;
  evaluation: EvaluationModel[];
  brokerage: boolean;
}

function FullContractCard({ contract, evaluation, brokerage }: Props) {
  const classesTooltip = useTooltipStyles();
  const classes = useFullContractCardStyles();
  const { getToken } = useGetToken();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const getOwnerInfo = () => contract.owner && (!contract.owner.document || !contract.owner.rut);

  const contractIsComplete = () =>
    contract.plan === null ||
    contract.init_date === null ||
    contract.first_payment_date === null ||
    contract.pay_day === null ||
    !contract.owner ||
    getOwnerInfo();

  const createQuotation = async (authToken: string) => {
    const data = parseQuotationData(contract, evaluation, brokerage);
    await applicantService
      .generateUpdateQuotation(data, authToken)
      .then(async () => {
        await newContract(authToken);
      })
      .catch((e) => {
        Swal.fire({
          type: "error",
          title: "Error",
          text: `No se ha podido crear la quotation. Error: ${e}`,
        });
        setLoading(false);
      });
  };

  const newContract = async (authToken: string) => {
    try {
      const newStatusContract = contract;
      newStatusContract.status = "Aprobado por propietario";
      await contractService.update(newStatusContract.id, newStatusContract, authToken);
      Swal.fire({
        type: "success",
        title: "Éxito",
        text: "El contrato ha sido aprobado correctamente",
      }).then(() => history.push(`/admin/contracts/`));
    } catch (e) {
      Swal.fire({
        type: "error",
        title: "Error",
        text: `No se pudo aprobar contrato`,
      });
    } finally {
      setLoading(false);
    }
  };

  const completeContract = async () => {
    setLoading(true);
    const authToken = await getToken();
    await createQuotation(authToken);
  };

  return (
    <CollapseCard title="Llenar contrato">
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <Typography component="h6" className={classes.textLabel}>
                  <strong>Cuando el propietario apruebe</strong> la información (o tengas que hacer
                  algunos cambios) continúa aquí para que la ejecutiva de arriendo pueda coordinar
                  los últimos detalles.
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.buttonContainer}>
                <Tooltip
                  title={`Falta lo siguiente para poder continuar: 
                  ${contract.plan === null ? "selección del plan, " : ""} 
                  ${contract.init_date === null ? "fecha de inicio de contrato, " : ""} 
                  ${contract.first_payment_date === null ? "fecha de primer pago, " : ""} 
                  ${contract.pay_day === null ? "día de pago." : ""} 
                  ${
                    !contract.owner || getOwnerInfo() ? "confirmar los datos del propietario." : ""
                  }`}
                  open={onMouseOver && contractIsComplete()}
                  arrow
                  classes={classesTooltip}>
                  <div
                    onMouseOver={() => setOnMouseOver(true)}
                    onMouseOut={() => setOnMouseOver(false)}
                    onFocus={() => setOnMouseOver(true)}
                    onBlur={() => setOnMouseOver(false)}>
                    <CustomLoadingButton
                      loading={loading}
                      text="Llenar contrato"
                      disabled={contractIsComplete()}
                      onClick={completeContract}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </div>
      </Card>
    </CollapseCard>
  );
}

export default FullContractCard;
