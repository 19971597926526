import clx from "classnames";

import { TextField, StandardTextFieldProps } from "@material-ui/core";
import useStyles from "./CustomNumberTextFieldStyles";

interface Props extends StandardTextFieldProps {
  errorMessage?: string;
  bordered?: boolean;
  textFieldClassName?: string;
  warning?: boolean;
  disableUnderline?: boolean;
}

const CustomNumberTextField = ({
  error,
  errorMessage = "Error",
  InputProps,
  bordered = false,
  textFieldClassName = "",
  warning,
  disableUnderline = true,
  ...props
}: Props) => {
  const classes = useStyles({ error, bordered, warning });

  return (
    <TextField
      error={error}
      className={clx(classes.textFieldRoot, textFieldClassName)}
      helperText={(error && errorMessage) || warning}
      InputProps={{
        disableUnderline,
        ...InputProps,
        classes: {
          underline: classes.underline,
          ...InputProps?.classes,
        },
      }}
      {...props}
    />
  );
};

export default CustomNumberTextField;
