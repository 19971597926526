import { useQuery, UseQueryResult } from "react-query";

import promotionService from "domains/promotion/service";
import { LocaleCountries } from "models/Countries";
import { ISimplePromotion } from "models/Promotions";
import { useGetToken } from "hooks";

import { parserPromotions } from "./utils/parsers";
import { PromotionStatus } from "./utils/types";

const useGetSimplePromotions = (status: PromotionStatus, country: LocaleCountries) => {
  const statusFlag = status === "actives";
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<ISimplePromotion[], Error> = useQuery<ISimplePromotion[], Error>(
    ["simple_promotions_list", status, country],
    async () => {
      const authToken = await getToken();
      const response = await promotionService.getSimplePromotions(
        {
          country,
          page_size: 100,
          active: statusFlag,
        },
        authToken
      );
      const promotions = response.data.results;
      const parsedPromotions = promotions.map((promotion) => parserPromotions(promotion));
      return parsedPromotions;
    },
    {
      refetchOnMount: true,
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useGetSimplePromotions;
