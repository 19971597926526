import { SvgIconTypeMap } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";

import useHeaderWithIconStyles from "./HeaderWithIconStyles";

interface Props {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

function HeaderWithIcon({ Icon, title }: Props) {
  const classes = useHeaderWithIconStyles();

  return (
    <div className={classes.header}>
      {Icon && <Icon className={classes.icon} />}
      <p className={classes.title}>{title}</p>
    </div>
  );
}

export default HeaderWithIcon;
