import Grid from "@material-ui/core/Grid";

import LegalRepresentativeCard from "./contractDetailsCards/persons/LegalRepresentativeCard";
import ContractDetailCard from "./contractDetailsCards/contractDetails/ContractDetailCard";
import DocumentsGenerationProcess from "../../UIComponents/DocumentsGenerationProcess";
import GenerateContractCard from "./contractDetailsCards/GenerateContractCard";
import CreatePaymentButton from "./contractDetailsCards/CreatePaymentButton";
import FinalizeSignStep from "./contractDetailsCards/sign/FinalizeSignStep";
import FullContractCard from "./contractDetailsCards/FullContractCard";
import DismissContract from "./contractDetailsCards/DismissContract";
import PropertyCard from "./contractDetailsCards/property/PropertyCard";
import LandlordCard from "./contractDetailsCards/persons/LandlordCard";
import TenantsCard from "./contractDetailsCards/persons/TenantsCard";
import { getBrokerage } from "./contractDetailsCards/utils";
import PlanCard from "./contractDetailsCards/plan/PlanCard";
import { ContractModel, IPerson } from "models/Contract";
import { EvaluationModel } from "models/Evaluation";
import Header from "./contractDetailsCards/Header";
import { PropertyData } from "models/Property";
import SignProcess from "Components/Admin/UIComponents/SignProcess";
import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";

interface Props {
  contract: ContractModel;
  profiles: IPerson[];
  evaluation: EvaluationModel[];
  property: PropertyData;
  updateContract: (contract: ContractModel) => void;
}

function ContractDetailV2({ contract, profiles, evaluation, property, updateContract }: Props) {
  const checkStatus = (statusArray: string[]): boolean => statusArray.includes(contract.status);
  const isBrokerage = evaluation && property ? getBrokerage(evaluation, property) : true;

  return (
    <>
      <Header
        contract={contract}
        property={property}
        brokerage={isBrokerage}
        updateContract={updateContract}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PropertyCard property={property} contract={contract} />
        </Grid>
        <Grid item xs={12}>
          <TenantsCard
            profiles={profiles}
            country={property.country}
            contract={contract}
            evaluation={evaluation}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LandlordCard
            contract={contract}
            contractHasOwner={false}
            person={contract.owner || contract.property.user}
            country={property.country}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContractDetailCard contract={contract} />
        </Grid>
        {contract.status !== "Rechazado" && (
          <Grid item xs={12}>
            <PlanCard contract={contract} property={property} brokerage={isBrokerage} />
          </Grid>
        )}
        {checkStatus([
          "Listo para redaccion",
          "Aprobacion propietario",
          "Perfil enviado",
          "Perfil aprobado",
        ]) && (
          <Grid item xs={12}>
            <LegalRepresentativeCard contract={contract} country={property.country} />
          </Grid>
        )}
        {checkStatus([
          "Aprobacion propietario",
          "Perfil enviado",
          "Perfil no enviado",
          "Perfil aprobado",
        ]) && (
          <Grid item xs={6}>
            <FullContractCard contract={contract} evaluation={evaluation} brokerage={isBrokerage} />
          </Grid>
        )}
        {checkStatus(["Aprobado por propietario"]) && (
          <Grid item xs={6}>
            <GenerateContractCard contract={contract} />
          </Grid>
        )}
        {checkStatus(["Generar boton de pago", "Boton de pago enviado"]) && (
          <Grid item xs={6}>
            <CreatePaymentButton
              contract={contract}
              evaluation={evaluation}
              brokerage={isBrokerage}
            />
          </Grid>
        )}
        {checkStatus(["Listo para redaccion"]) && (
          <Grid item xs={12}>
            <CollapseCard title="Revisar borradores">
              <DocumentsGenerationProcess
                contractId={contract?.id}
                contractType={contract?.plan?.name}
                landlordObservations={contract?.landlord_observations}
                tenantObservations={contract?.observations}
              />
            </CollapseCard>
          </Grid>
        )}
        {checkStatus(["Listo para redaccion"]) && (
          <Grid item xs={12}>
            <CollapseCard title="Estado de las firmas">
              <SignProcess contractId={contract?.id} />
            </CollapseCard>
          </Grid>
        )}
        {checkStatus(["Listo para redaccion"]) && (
          <Grid item xs={6}>
            <FinalizeSignStep contract={contract} />
          </Grid>
        )}
        {!checkStatus(["Rechazado", "Pagado", "Finalizado"]) && (
          <Grid item xs={6}>
            <DismissContract contract={contract} updateContract={updateContract} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ContractDetailV2;
