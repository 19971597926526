import { useEffect, useState } from "react";

import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Typography } from "@material-ui/core";

import { useGetToken } from "hooks";
import { IHomeCheckerDetails } from "models";
import {
  ControlledCheckbox,
  ControlledPhoneInput,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import {
  fetchCoordination,
  updateCoordination,
  updateCoordinationData,
} from "context/multifamilyContext/unit/unitActions";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { formatPhoneInput } from "utils";
import validationSchema from "./utils/validation";
import useStyles from "./ManageVisitsStyles";

function ManageVisits() {
  const [isEditing, setIsEditing] = useState(false);
  const [fetchedInfo, setFetchedInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const { getToken } = useGetToken();
  const {
    state: { unit, property },
  } = useMultifamilyUnit();

  useEffect(() => {
    getToken().then(async (authToken) => {
      setIsLoading(true);
      const res = await fetchCoordination(unit?.property?.id, authToken);
      const parsedNumber = formatPhoneInput(
        res?.homeCheckerDetails?.third_party_coordination_phone
      );
      const defaultControlledPhoneValue = {
        country: parsedNumber.country,
        phone: parsedNumber.phone,
      };
      setFetchedInfo({ ...res, phone: defaultControlledPhoneValue });
      setIsLoading(false);
    });
  }, []);

  const buttonText = isEditing ? "Guardar" : "Editar datos";

  const methods = useForm<IHomeCheckerDetails>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (!isEditing) {
      return setIsEditing(true);
    }
    getToken().then(async (authToken) => {
      await updateCoordination(
        data as updateCoordinationData,
        unit?.property?.id,
        unit?.property?.uid,
        authToken
      );
      setIsEditing(false);
    });
  };

  const DisabledCheckbox = {
    disabled: !isEditing,
  };

  return (
    <FormProvider {...methods} key="AditionalInformation">
      <form key="AditionalInformation" onSubmit={methods.handleSubmit(onSubmit)}>
        <Loading loading={isLoading}>
          <Box className={classes.root}>
            <Box className={classes.buttonConatiner}>
              <DesignSystemButton
                type="submit"
                size="medium"
                label={buttonText}
                variant="primary"
              />
            </Box>
            <Box className={classes.formContainer}>
              <Box className={classes.formControlContainer}>
                <ControlledCheckbox
                  id="inhabitedProperty"
                  label="Propiedad habitada"
                  defaultChecked={fetchedInfo?.homeCheckerDetails?.inhabited_property}
                  checkboxProps={DisabledCheckbox}
                />
                {isEditing && (
                  <ControlledTextField
                    id="inhabitedPropertyDescription"
                    label=""
                    defaultValue={fetchedInfo?.homeCheckerDetails?.inhabited_property_description}
                    textFieldProps={{
                      InputProps: { className: classes?.formInput },
                      placeholder: "Observaciones",
                    }}
                  />
                )}
                {!isEditing && (
                  <Typography className={classes.disabledText}>
                    {fetchedInfo?.homeCheckerDetails?.inhabited_property_description}
                  </Typography>
                )}
                <Divider className={classes.separator} />
              </Box>
              <Box className={classes.formControlContainer}>
                <ControlledCheckbox
                  id="propertyWithHoumbox"
                  label="Propiedad con Houmbox"
                  defaultChecked={fetchedInfo?.homeCheckerDetails?.has_houm_box}
                  checkboxProps={DisabledCheckbox}
                />
                <Divider className={classes.separator} />
              </Box>
              <Box className={classes.formControlContainer}>
                <ControlledCheckbox
                  id="thirdPartyCoordination"
                  label="Coordinación con terceros"
                  justifyContent="flex-end"
                  defaultChecked={fetchedInfo?.homeCheckerDetails?.third_party_coordination}
                  checkboxProps={DisabledCheckbox}
                />
                {isEditing && (
                  <ControlledTextField
                    id="thirdPartyCoordinationDescription"
                    label=""
                    defaultValue={
                      fetchedInfo?.homeCheckerDetails?.third_party_coordination_description
                    }
                    textFieldProps={{
                      InputProps: { className: classes?.formInput },
                      placeholder: "Detalle coordinación con terceros",
                    }}
                  />
                )}
                {!isEditing && (
                  <Typography className={classes.disabledText}>
                    {fetchedInfo?.homeCheckerDetails?.third_party_coordination_description}
                  </Typography>
                )}
                <Divider className={classes.separator} />
              </Box>
              <Box className={classes.formControlContainer}>
                <ControlledCheckbox
                  id="keyLocation"
                  label="Ubiación de llaves"
                  justifyContent="flex-end"
                  defaultChecked={property?.keys_info && property?.keys_info !== "Sin información"}
                  checkboxProps={DisabledCheckbox}
                />
                {isEditing && (
                  <>
                    <ControlledTextField
                      id="keyLocationDescription"
                      label=""
                      defaultValue={property?.keys_info}
                      textFieldProps={{
                        InputProps: {
                          className: classes?.formInput,
                        },
                        placeholder: "Detalle de la ubicación",
                      }}
                    />
                    <ControlledPhoneInput id="keyLocationPhone" defaultValue={fetchedInfo?.phone} />
                  </>
                )}
                {!isEditing && (
                  <>
                    <Typography className={classes.disabledText}>{property?.keys_info}</Typography>
                    <Typography className={classes.disabledText}>
                      {fetchedInfo?.homeCheckerDetails?.third_party_coordination_phone}
                    </Typography>
                  </>
                )}
                <Divider className={classes.separator} />
              </Box>
            </Box>
          </Box>
        </Loading>
      </form>
    </FormProvider>
  );
}

export default ManageVisits;
