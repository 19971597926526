import { Grid } from "@material-ui/core";

import { useProject } from "context/projectContext/projectContext";
import useExpandedDialStyles from "./ExpandedButtonStyles";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { setIsShorterForm } from "context/projectContext/projectActions";

function ExpandedButton() {
  const {
    state: { isShorterForm },
    dispatch,
  } = useProject();
  const classes = useExpandedDialStyles();
  return (
    <Grid item>
      <div className={classes.root}>
        <CustomButton
          id="see-amenities"
          kind="main"
          active
          className={classes.expandedButton}
          onClick={() => dispatch(setIsShorterForm(!isShorterForm))}>
          {isShorterForm ? "Ver amenidades" : "Ver menos"}
        </CustomButton>
        <CustomButton id="send-project" kind="main" type="submit" active>
          Enviar
        </CustomButton>
      </div>
    </Grid>
  );
}
export default ExpandedButton;
