import { useQuery, UseQueryResult } from "react-query";

import { IUserBankAccountResponse } from "services/bankAccountService/types";
import bankAccountService from "services/bankAccountService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";
import { AxiosError } from "axios";

const useUserBankAccount = (country: Locale, userId: number, propertyId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading: isLoadingBankAccount,
    isError: isErrorBankAccount,
    data: dataBankAccount = null,
    error: errorBankAccount,
  }: UseQueryResult<IUserBankAccountResponse, AxiosError> = useQuery<
    IUserBankAccountResponse,
    AxiosError
  >(
    ["userBankAccount", country, userId, propertyId],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountService.getByUserAndPropertyV2(
        country,
        userId,
        propertyId,
        authToken
      );
      return response.data;
    },
    {
      enabled: !!userId && !!propertyId,
      retry: false,
    }
  );

  return {
    isLoadingBankAccount,
    isErrorBankAccount,
    dataBankAccount,
    errorBankAccount,
  };
};

export default useUserBankAccount;
