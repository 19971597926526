import { Typography } from "@material-ui/core";

import useAdvisoryResponseInfo from "./AdvisoryResponseInfoStyles";

import { ReactComponent as Videocam } from "assets/images/videocam.svg";
import { ReactComponent as Calendar } from "assets/images/calendar.svg";
import { ReactComponent as Mail } from "assets/images/mailUnread.svg";

interface Props {
  appraiserName: string;
  daySchedule: string;
  leadEmail: string;
  scheduleId?: number;
}

function AdvisoryResponseInfo({ appraiserName, daySchedule, leadEmail, scheduleId }: Props) {
  const classes = useAdvisoryResponseInfo();
  return (
    <div className={classes.advisoryContainer}>
      <Typography>
        <Videocam /> Asesoría agendada con
        <strong> {appraiserName}</strong>
      </Typography>
      <Typography>
        <Calendar /> <strong> {daySchedule}</strong>
      </Typography>
      <Typography>
        <Mail /> Invitación de asesoría enviada al correo:
        <strong> {leadEmail}</strong>
      </Typography>
      {scheduleId && (
        <Typography>
          Su numero de asesoría es:
          <strong> {scheduleId}</strong>
        </Typography>
      )}
    </div>
  );
}
export default AdvisoryResponseInfo;
