import { Box, Typography } from "@material-ui/core";

import { ReactComponent as ClipIcon } from "assets/icons/clip.svg";

import NoVoucher from "./components/NoVoucher";
import useVoucherStyles from "./VoucherStyles";

interface Props {
  voucherUrl?: string;
  fileName?: string;
}

const Voucher = ({ voucherUrl, fileName }: Props) => {
  const classes = useVoucherStyles();
  if (!voucherUrl) return <NoVoucher />;
  return (
    <Box className={classes.voucherRoot} onClick={() => window.open(voucherUrl)}>
      <ClipIcon className={classes.icon} />
      <Typography>{fileName}</Typography>
    </Box>
  );
};

export default Voucher;
