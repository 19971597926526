import { makeStyles, Theme } from "@material-ui/core";

const useSelectedCityStyles = makeStyles<Theme>((theme) => ({
  cityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 4,
    padding: theme.spacing(2.25, 2),
  },
  icon: {
    padding: 0,
  },
  cityName: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.5,
  },
}));

export default useSelectedCityStyles;
