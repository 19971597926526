import CreateMF from "./CreateMF";

import { TableProvider } from "context/tableContext/tableContext";
import { MultifamilyProvider } from "context/multifamilyContext/multifamily/multifamilyContext";
import { PropertyProvider } from "context/propertyContext/propertyContext";
import { TypologyProvider } from "context/multifamilyContext/typology/typologyContext";

function MultiFamilyCreate() {
  return (
    <MultifamilyProvider>
      <TypologyProvider>
        <PropertyProvider>
          <TableProvider>
            <CreateMF />
          </TableProvider>
        </PropertyProvider>
      </TypologyProvider>
    </MultifamilyProvider>
  );
}
export default MultiFamilyCreate;
