export const steps = (subscriptionId: number, isRequest = false) => {
  const labels = [
    {
      label: "Clientes",
    },
    {
      label: "Subscripción",
    },
    {
      label: `ID ${subscriptionId}`,
    },
  ];
  if (isRequest) labels.push({ label: "Crear solicitud" });
  return labels;
};
