export enum ActionType {
  SET_TYPOLOGY,
  SET_TYPOLOGIES,
  SET_IS_LOADING_DATA_TABLE,
  SET_UT_IMAGES,
  SET_CURRENT_ID,
}

export interface Typologies {
  id?: number;
  multifamily?: number;
  apartment_type?: string;
  bedrooms?: number;
  bathrooms?: number;
  built_area?: string;
  closet?: string;
  description?: string;
  environments?: string | number;
  has_laundry_room?: boolean;
  has_kitchen_daily_dining_room?: boolean;
  external_description?: string;
  kitchen_is_furnished?: boolean;
  layout_file_url?: string;
  laundry_room_area?: string | number;
  layout_filename?: any;
  name?: string;
  service_rooms?: boolean;
  suite_rooms?: string | number;
  terrace?: boolean;
  terrace_area?: string | number;
  total_area?: string;
  video_url?: string;
  video360_url?: string;
}

export interface UTImages {
  id: number;
  created_at: string;
  filename: string;
  order: number;
  url: string;
}

export interface TypologyFormData {
  name?: string;
  bedrooms?: number;
  built_area?: string;
  description?: string;
  bathrooms?: number;
  total_area?: string;
  external_description?: string;
}

export interface photosDeleteBody {
  photos_id: number[];
}

export interface SetTypology {
  type: typeof ActionType.SET_TYPOLOGY;
  typology: Typologies;
}

export interface SetTypologies {
  type: typeof ActionType.SET_TYPOLOGIES;
  typologies: Typologies[];
}

export interface SetIsLoadingDataTable {
  type: typeof ActionType.SET_IS_LOADING_DATA_TABLE;
  isLoadingDataTable: boolean;
}

export interface SetUTImages {
  type: typeof ActionType.SET_UT_IMAGES;
  UTImages: UTImages[];
}

export interface SetCurrentId {
  type: typeof ActionType.SET_CURRENT_ID;
  currentId: number;
}

export interface State {
  typology: Typologies;
  typologies: Typologies[];
  isLoadingDataTable: boolean;
  UTImages: UTImages[];
  currentId: number;
}

export type Action =
  | SetTypology
  | SetTypologies
  | SetIsLoadingDataTable
  | SetUTImages
  | SetCurrentId;

export type Dispatch = (action: Action) => void;
