import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography } from "@material-ui/core";

import { ReactComponent as InfoSvg } from "assets/icons/info-icon.svg";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ClientInfo } from "Components/Admin/UIComponents/PropertyServices/utils/types";
import { useFormNotifications } from "hooks";

import useCreateCommonExpenses from "../../../hooks/useCreateCommonExpenses";
import { comunidadFelizSchema, requiredText } from "./utils/validationSchema";
import useComunidadFelizCompanyFormStyles from "./ComunidadFelizCompanyFormStyles";
import { IFormData } from "./utils/types";

interface Props {
  handleClose: () => void;
  propertyId: number;
  previousData?: ClientInfo;
}

const ComunidadFelizCompanyForm = ({ handleClose, propertyId, previousData }: Props) => {
  const classes = useComunidadFelizCompanyFormStyles();
  const { mutate, isLoading } = useCreateCommonExpenses(!!previousData, handleClose);

  const methods = useForm<IFormData>({
    resolver: yupResolver(comunidadFelizSchema()),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<IFormData>({ errors, requiredText });

  const onSubmit: SubmitHandler<IFormData> = (formData) => {
    const data = {
      property_id: propertyId,
      company: "comunidad_feliz",
      client_info: {
        external_id: formData.billNumber.toUpperCase(),
        community_name: formData.communityName,
        unit: formData.unityName.toString(),
      },
    };
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
        <Grid container spacing={2}>
          <Grid item>
            <Box className={classes.disclaimerContainer}>
              <InfoSvg />
              <Typography className={classes.disclaimerText}>
                La administración del edificio tiene que aprobar que podamos ver esta información.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              label="Nombre de la comunidad"
              id="communityName"
              defaultValue={previousData?.communityName || ""}
              textFieldProps={{
                InputProps: {
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "Ingresar",
                },
              }}
              wrapperPadding={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              label="Número de la unidad"
              id="unityName"
              defaultValue={previousData?.unit || ""}
              textFieldProps={{
                InputProps: {
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "Ingresar",
                },
              }}
              wrapperPadding={false}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              label="Número de la boleta"
              id="billNumber"
              defaultValue={previousData?.id || ""}
              textFieldProps={{
                InputProps: {
                  classes: {
                    root: classes.textInput,
                  },
                  placeholder: "Ingresar",
                },
              }}
              wrapperPadding={false}
            />
          </Grid>
        </Grid>
        <Box className={classes.buttons}>
          <DesignSystemButton
            variant="secondary"
            size="small"
            label="Cancelar"
            onClick={handleClose}
            buttonClassName={classes.cancelButton}
          />
          <DesignSystemButton
            variant="primary"
            size="small"
            label="Guardar"
            type="submit"
            isLoading={isLoading}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default ComunidadFelizCompanyForm;
