import { Box } from "@material-ui/core";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { FilterOptions } from "../../utils/filterOptions";
import { SearchFilterTypes } from "../../utils/types";
import useSearchFilterStyles from "./SearchFilterStyles";

interface Props {
  onChange: (filter: SearchFilterTypes) => void;
}

function SearchFilter({ onChange }: Props) {
  const classes = useSearchFilterStyles();

  return (
    <Box className={classes.searchFilterWrapper}>
      <SelectionOptions
        id="searchFilters"
        onChange={onChange}
        options={FilterOptions}
        value="search"
      />
    </Box>
  );
}

export default SearchFilter;
