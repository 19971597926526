import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  isNegative?: boolean;
}

const useWarrantyInfoStyles = makeStyles<Theme, Props>((theme) => ({
  infoContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  labelText: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    color: ({ isNegative }) => (isNegative ? "#AE0400" : "inherit"),
  },
  refundUnavailable: {
    width: "fit-content",
    padding: theme.spacing(0.25, 1),
    border: "1px solid #ABC7F1",
    borderRadius: 8,
    backgroundColor: "#EAF1FB",
    color: "#1A4583",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
}));

export default useWarrantyInfoStyles;
