import { Locale } from "models/Countries";
import { IReceiptsResponse } from "services/newPaymentService/types";
import { MovementConcept, MovementType } from "./types";

const getLabel = (receipt: IReceiptsResponse, country: Locale) => {
  if (receipt.source_url === "integrator")
    return `${MovementType[receipt.type]} (${receipt.type === "INVOICE" ? "Ext. IVA" : "con IVA"})`;
  if (country !== "cl") return `Factura #${receipt.id}`;
  return `${MovementType[receipt.type]} ${MovementConcept[receipt.concept]}`;
};

export const parserReceipts = (data: IReceiptsResponse[], country: Locale) =>
  data.map((receipt) => ({
    id: Number(receipt.id),
    label: getLabel(receipt, country),
    url: receipt.url,
    source: receipt.source_url,
  }));
