import { useState, useEffect, Dispatch, SetStateAction, ChangeEvent, MouseEvent } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";

import useStyles from "./LayoutTableStyles";
import CollapsibleRow from "./CollapsibleRow";
import CollapsibleCell from "./CollapsibleCell";
import { Header } from "./CollapsibleTableTypes";
import Spinner from "Components/Shared/partials/Spinner";

interface TableProps {
  headers: Header[];
  rows: any[];
  createRowComponent?: JSX.Element;
  createSubRowComponent?: (id?: number, close?: Dispatch<SetStateAction<boolean>>) => JSX.Element;
  deleteRowComponent?: (id?: number, name?: string) => Promise<void>;
  deleteSubRowComponent?: (id?: number, subname?: string, name?: string) => Promise<void>;
  editRowComponent?: (
    value?: any,
    close?: Dispatch<SetStateAction<boolean>>
  ) => JSX.Element | JSX.Element;
  editSubRowComponent?: (
    value?: any,
    id?: number,
    close?: Dispatch<SetStateAction<boolean>>
  ) => JSX.Element | JSX.Element;
  openInputForm?: boolean;
  loading?: boolean;
  filtered?: boolean;
  paginated?: boolean;
}

function LayoutsTable({
  headers,
  rows,
  createRowComponent = null,
  createSubRowComponent = null,
  deleteRowComponent = null,
  deleteSubRowComponent = null,
  editRowComponent = null,
  editSubRowComponent = null,
  openInputForm = false,
  loading = false,
  filtered = false,
  paginated = false,
}: TableProps) {
  const classes = useStyles();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, rows]);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement | null>, newPage: number) =>
    setPage(newPage);

  const hasPagination = () => paginated && rows.length > 0;

  const isPageSelected = () => hasPagination() && rowsPerPage > 0;

  const splitRowsByPage = () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div className={classes.customTable}>
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            {headers.map((item) => (
              <TableCell className={classes.cell} style={{ width: item.width }} key={item.id}>
                {item.name}
              </TableCell>
            ))}
            <TableCell className={classes.defaultCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filtered && rows.length < 1 && !loading && (
            <TableRow>
              <CollapsibleCell colSpan={headers.length} open={true}>
                <Grid container justifyContent="center">
                  <Box m={2}>
                    <Typography component="span">
                      No hay elementos que coincidan con la búsqueda
                    </Typography>
                  </Box>
                </Grid>
              </CollapsibleCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <CollapsibleCell colSpan={headers.length} open={true}>
                <Spinner />
              </CollapsibleCell>
            </TableRow>
          )}
          {createRowComponent && !loading && (
            <TableRow>
              <CollapsibleCell
                colSpan={headers.length}
                open={openInputForm || (rows.length < 1 && !filtered)}>
                {createRowComponent}
              </CollapsibleCell>
            </TableRow>
          )}
          {(isPageSelected() ? splitRowsByPage() : rows).map((row) => (
            <CollapsibleRow
              row={row}
              headers={headers}
              isFormOpen={isFormOpen}
              setIsFormOpen={setIsFormOpen}
              createSubRow={createSubRowComponent}
              deleteRow={deleteRowComponent}
              deleteSubRow={deleteSubRowComponent}
              editRow={editRowComponent}
              editSubRow={editSubRowComponent}
              key={row.id}
            />
          ))}
        </TableBody>
        {hasPagination() && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={rows.length}
                page={page}
                rowsPerPageOptions={[10, 15, 25]}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
}

export default LayoutsTable;
