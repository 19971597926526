import React, { useMemo, useEffect, ReactNode } from "react";
import { createPortal } from "react-dom";

import mapboxgl from "mapbox-gl";

interface Props {
  lat: number;
  lng: number;
  children: ReactNode;
  mkClassName?: string;
  map; //map reference
}

const Marker = ({ lat, lng, map, children, mkClassName }: Props) => {
  const marker = useMemo(() => {
    let hasChildren = false;
    React.Children.forEach(children, (el) => {
      if (el) {
        hasChildren = true;
      }
    });

    const options = {
      element: hasChildren ? document.createElement("div") : null,
    };
    if (mkClassName && hasChildren) {
      options.element.className = mkClassName;
    }

    const mk = new mapboxgl.Marker(options).setLngLat([lng, lat]);
    return mk;
  }, []);

  useEffect(() => {
    if (marker.getLngLat().lng !== lng || marker.getLngLat().lat !== lat) {
      marker.setLngLat([lng, lat]);
    }
  }, [lat, lng]);

  useEffect(() => {
    if (map.current) {
      marker.addTo(map.current);
    }

    return () => {
      marker.remove();
    };
  }, []);
  return createPortal(children, marker.getElement());
};

export default Marker;
