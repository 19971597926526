import TablePagination from "Components/Admin/UIComponents/DesignSystem/TablePagination";

import { useLandlordProperties, usePagination } from "../../hooks";
import { FiltersProps } from "../../hooks/useFilters";
import LandlordTable from "../LandlordTable";
import { columnsConfig } from "./utils/columns";

interface Props {
  propertiesCount: number;
  filters?: FiltersProps;
}

function PropertiesTable({ propertiesCount, filters }: Props) {
  const { page, setPage, pageSize, setPageSize } = usePagination();
  const {
    isLoading: propertiesLoading,
    error: propertiesError,
    data: properties,
  } = useLandlordProperties(page, pageSize, filters);

  return (
    <LandlordTable
      headerHeight={40}
      rowHeight={80}
      rows={properties}
      columns={columnsConfig}
      loading={propertiesLoading}
      error={propertiesError}
      content="properties"
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Pagination: () => (
          <TablePagination
            pageCount={Math.ceil(propertiesCount / pageSize)}
            loading={propertiesLoading}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={page}
            setCurrentPage={setPage}
            pageSizeOptions={[5, 10, 20]}
          />
        ),
      }}
    />
  );
}

export default PropertiesTable;
