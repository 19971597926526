/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/require-default-props */
import { Button } from "@material-ui/core";
import * as React from "react";
import classNames from "classnames";

import useStyles from "./CustomButtonStyles";

interface ButtonProps extends React.ComponentProps<typeof Button> {
  active?: boolean;
  kind?: "main" | "blue" | "secondary" | "black";
  rootstyle?: string;
}

function CustomButton(props: ButtonProps) {
  const { kind = "main", rootstyle, active: customActive } = props;
  const classes = useStyles({ active: customActive });

  // this isn't optimal, but temporarily solves warning
  const buttonProps = { ...props };
  delete buttonProps.active;
  delete buttonProps.kind;
  delete buttonProps.rootstyle;

  return (
    <Button
      {...buttonProps}
      variant="outlined"
      disableElevation
      classes={{
        root: classNames(
          classes.buttonContainer,
          {
            [classes[`${kind}Color`]]: kind,
          },
          rootstyle
        ),
        label: classNames(classes.buttonLabel, {
          [classes[`${kind}Label`]]: kind,
        }),
      }}
    />
  );
}

export default CustomButton;
