import { AxiosResponse } from "axios";
import Swal from "sweetalert2";

import { useState, useEffect } from "react";
import { Chip, Typography, Grid, CircularProgress } from "@material-ui/core";

import CollapseCard from "Components/Admin/UIComponents/Cards/CollapseCard";
import SplitButton from "Components/Admin/UIComponents/atoms/SplitButton";
import useBankAccountStyles from "./BankAccountsPropietaryStyle";
import bankAccountService from "services/bankAccountService";
import BankAccountItem from "./BankAccountItem";
import { PropertyData } from "models/Property";
import { countryCode } from "utils/";
import { useGetToken } from "hooks";

interface Props {
  property: PropertyData;
}

interface IAccount {
  identifier: string;
  bank_name: string;
  bank_code: number;
  account_number: string;
  holder: string;
  document: string;
  email: string;
  person_id: number;
  account_type: string;
  document_type: string;
  registered: boolean;
  id: string;
  clabe_number: string;
}
interface IBankList {
  bank_id: string;
  code: number;
  name: string;
}

function BankAccountsPropietary({ property }: Props) {
  const { getToken } = useGetToken();
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [asociateAcc, setAsociateAcc] = useState<IAccount | null>(null);
  const [allAccountLoader, setAllAccountLoader] = useState<boolean>(true);
  const [associateLoader, setAssociateLoader] = useState<boolean>(true);
  const [bankLists, setBankLists] = useState<IBankList[]>([]);
  const classes = useBankAccountStyles();

  const getBankId = (code) => {
    let res;
    bankLists.forEach((item) => {
      if (item.code === code) {
        res = item.bank_id;
      }
    });
    return res;
  };

  interface BankAccountProps {
    holder: string;
    email: string;
    document: string;
    bank_id: string;
    account_type: string;
    account_number: string;
    document_type?: string;
    city?: string;
    clabe_number?: number;
  }

  const createAccount = async (bankAccountData) => {
    const authToken = await getToken();
    const data: BankAccountProps = {
      account_number: bankAccountData.account_number,
      bank_id: getBankId(bankAccountData.bank_code),
      document: bankAccountData.document,
      email: bankAccountData.email,
      holder: bankAccountData.holder,
      account_type: bankAccountData.account_type,
    };
    if (property.country === "Colombia") data["document_type"] = bankAccountData.document_type;
    if (property.country === "Mexico") {
      data.clabe_number = bankAccountData.clabe_number;
      data.city = bankAccountData.city;
    }
    const res = await bankAccountService.create(
      countryCode(property.country),
      property.user.id,
      {
        ...data,
        identifier: `${property.id}`,
      },
      authToken
    );
    if (res && [200, 201].includes(res.status)) {
      Swal.fire({
        type: "success",
        text: "Se ha actualizado la cuenta bancaria asociada a esta propiedad",
      }).then(() => window.location.reload());
    } else {
      const error = Object.keys(res.data.errors.json);
      Swal.fire({
        type: "error",
        text: `No se ha podido actualizar la cuenta.\ ${
          error.length && error.map((err) => `${error}: ${res.data.errors.json[err]}\ `)
        }`,
      });
    }
  };

  const accountOptions = accounts.map((item) => ({
    text: `${item.bank_name} - ${item.account_number}`,
    action: () => {
      Swal.fire({
        type: "warning",
        title: `¿Asignar banco ${item.bank_name} - N° cuenta: ${item.account_number} a esta propiedad?`,
        text: "Solo asigne esta cuenta bancaria si el propietario esta de acuerdo",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then(async (res) => {
        if (res.value) {
          createAccount(item);
        }
      });
    },
  }));

  const getBankList = (country: string, authToken: string) => {
    switch (country) {
      case "Colombia":
        return bankAccountService.getBanksCo(authToken);
      case "Mexico":
        return bankAccountService.getBanksMx(authToken);
      case "Chile":
      default:
        return bankAccountService.getBanksCl(authToken);
    }
  };

  useEffect(() => {
    const getAccounts = async (authToken) => {
      const fetchedBanks = await getBankList(property.country, authToken);
      setBankLists(fetchedBanks.data.banks);
      if (property.user) {
        const allAccounts = (await bankAccountService.getByUser(
          countryCode(property?.country),
          property.user.id,
          authToken
        )) as AxiosResponse<{ bank_accounts: IAccount[] }>;

        if (allAccounts?.data?.bank_accounts.length > 0) {
          const uniqueAccountsMap = new Map();
          allAccounts.data.bank_accounts.forEach((account) => {
            uniqueAccountsMap.set(account.account_number, account);
          });
          const uniques = [];
          for (const value of uniqueAccountsMap.values()) {
            uniques.push(value);
          }
          setAccounts(uniques);
        }
        setAllAccountLoader(false);
        const accountProperty = (await bankAccountService.getByUserAndProperty(
          countryCode(property?.country),
          property.user.id,
          property?.id,
          authToken
        )) as AxiosResponse<IAccount>;

        if (accountProperty?.data?.id) {
          setAsociateAcc(accountProperty.data);
        }
        setAssociateLoader(false);
      }
    };
    getToken().then((authToken) => getAccounts(authToken));
  }, [property?.user]);

  return (
    <Grid item xs={12}>
      <CollapseCard title="Cuentas bancarias">
        <div className={classes.collapse}>
          <Grid container spacing={5}>
            <Grid item sm={6} xs={12}>
              {!allAccountLoader && (
                <Chip
                  size="small"
                  color="primary"
                  label={
                    accounts.length > 0
                      ? "Cuentas asociadas al usuario"
                      : "No hay cuentas bancarias asociadas al usuario"
                  }
                />
              )}
              {allAccountLoader && <CircularProgress />}
              {accounts.map((item) => (
                <BankAccountItem key={item.id} country={property.country} bankAccount={item} />
              ))}
            </Grid>
            <Grid item sm={6} xs={12}>
              {!associateLoader && (
                <>
                  <Chip
                    size="small"
                    color="secondary"
                    label={
                      asociateAcc
                        ? "Cuenta asociada a la propiedad"
                        : "No hay cuentas bancarias asociadas a la propiedad"
                    }
                  />
                  {!asociateAcc && accounts.length > 0 && (
                    <div className={classes.addAccount}>
                      <Typography variant="body2">Asignar cuenta bancaria</Typography>
                      <SplitButton options={accountOptions} />
                    </div>
                  )}
                </>
              )}
              {associateLoader && <CircularProgress />}
              {asociateAcc && (
                <BankAccountItem country={property.country} bankAccount={asociateAcc} />
              )}
            </Grid>
          </Grid>
        </div>
      </CollapseCard>
    </Grid>
  );
}

export default BankAccountsPropietary;
