import { makeStyles } from "@material-ui/core";

const useCustomAccordionStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  accordion: {
    boxShadow: "none",
    borderRadius: "20px",
    border: "1px solid",
    borderColor: theme.palette.grey["200"],
    overflow: "hidden",
    marginBottom: theme.spacing(3),
  },
  iconButton: {
    padding: theme.spacing(0, 2),
    color: theme.palette.primary.main,
  },
  accordionExpanded: {
    minHeight: "auto",
    marginBottom: `${theme.spacing(3)}px !important`,
  },
  summaryExpanded: {
    marginBottom: "0 !important",
    marginTop: "0 !important",
    backgroundColor: "#FEF1F0",
    minHeight: "auto !important",
  },
  summary: {
    padding: theme.spacing(1, 2),
    minHeight: "auto",
  },
  summaryContent: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(17),
    margin: 0,
  },
}));
export default useCustomAccordionStyles;
