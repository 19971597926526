import { Grid } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { ContractModel } from "models/Contract";

import useStyles from "./AdditionalSubDetailsStyles";

interface Props {
  contract: ContractModel;
  hasBrokerage: boolean;
}

const AdditionalSubDetails = ({ contract, hasBrokerage }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <AdminCard title="Datos Adicionales" withSpace={false} showTitleBackground={true}>
        <table>
          <tbody>
            <tr>
              <td className={classes.onePercentPadding}>Con Corretaje </td>
              <td className={classes.onePercentPadding}>{hasBrokerage ? "Si" : "No"}</td>
            </tr>
            <tr>
              <td className={classes.onePercentPadding}>Descuento especial propiedad </td>
              <td className={classes.onePercentPadding}>
                {contract.has_promotion ? (
                  <div>
                    {contract.promotional_price} {contract.type_money} por
                    {contract.promotion_months} meses
                  </div>
                ) : (
                  "No"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </AdminCard>
    </Grid>
  );
};

export default AdditionalSubDetails;
