import Swal from "sweetalert2";

import applicantService from "services/applicantService";
import newEvaluationService from "services/newEvaluationService";
import {
  Action,
  EvaluationFilter,
  Dispatch,
  SET_LOADING_DATA,
  SET_EVALUATION,
  SET_EVALUATION_FILTER,
  SET_STATISTICS,
  SET_EVALUATION_TOKEN,
  SET_SINGLE_EVALUATION,
  SET_APPLICANT,
  SET_HOUMER,
  SingleEvaluation,
  SET_PROPERTY_3X,
  SET_PROPERTY_IS_IN_UF,
  SET_CAN_PAY,
  Statistics,
  IRents,
} from "./evaluationTypes";
import {
  defaultStatistics,
  parseEvaluation,
  parseSingleEvaluation,
  parseStatistics,
} from "./utils";
import { getRentsSum } from "Components/Admin/administrator/evaluations/utils";
import { Locale } from "models/Countries";

export const setLoadingData = (loadingData: boolean): Action => ({
  type: SET_LOADING_DATA,
  loadingData,
});

export const setFilters = (filters: EvaluationFilter): Action => ({
  type: SET_EVALUATION_FILTER,
  filters,
});

export const setEvaluationToken = (token: string): Action => ({
  type: SET_EVALUATION_TOKEN,
  token,
});

export const setStatistics = (statistics: Statistics): Action => ({
  type: SET_STATISTICS,
  statistics,
});

export const getEvaluations = async (
  filters: EvaluationFilter,
  authToken: string,
  country: string,
  dispatch: Dispatch
) => {
  dispatch({ type: SET_LOADING_DATA, loadingData: true });
  try {
    if (country === "colombia")
      return dispatch({
        type: SET_EVALUATION,
        payload: { evaluation: [], count: 0 },
      });
    const res = await newEvaluationService.getPage(
      { ...filters, token: authToken },
      country === "chile" ? (country = "cl") : (country = "mx")
    );
    const evaluation = res["data"].evaluations.map((ev) => parseEvaluation(ev));
    const count = res["data"].total_count;
    dispatch({ type: SET_EVALUATION, payload: { evaluation, count } });
  } catch (e) {
    dispatch({ type: SET_LOADING_DATA, loadingData: false });
    throw e;
  }
};

export const getStatistics = async (country: string, authToken: string, dispatch: Dispatch) => {
  if (country === "colombia") {
    dispatch(setStatistics(defaultStatistics()));
    return;
  }
  const res = await newEvaluationService.getStatistics(
    authToken,
    country === "chile" ? (country = "cl") : (country = "mx")
  );
  if (!res) return;
  dispatch(setStatistics(parseStatistics(res.data)));
};

export const getSingleEvaluation = async (
  mainId: string,
  codebtorIds: string,
  country: string = "cl",
  authToken: string,
  dispatch: Dispatch
) => {
  const getDetails = async (evaluationId: number) => {
    const prevData = await newEvaluationService.getEvaluationDetails(
      evaluationId,
      authToken,
      country
    );
    if (prevData) {
      return prevData["data"].evaluation_details.details;
    } else {
      Swal.fire({
        title: "Atención!",
        text: `No se pudo obtener los detalles de la evaluación ${evaluationId}`,
        type: "warning",
      });
    }
  };
  const res = await newEvaluationService.getByMain(mainId, country, authToken);
  if (res) {
    const { data } = res;
    if (data.evaluations.length) {
      const results = {
        main: null,
        codebtors: [],
        thisCodebtor: "",
        rents: {},
      };
      const brokerage = data.evaluations[0] != null && data.evaluations[0].brokerage;
      data.evaluations.forEach((item) => {
        if (item.codebtor === parseInt(codebtorIds)) {
          results.thisCodebtor = `${item.user.name} ${item.user.last_name} ${item.user.second_last_name}`;
          results["thisCodebtorId"] = item.codebtor;
        }
        if (item.codebtor === parseInt(mainId)) {
          results.main = item;
        } else {
          results.codebtors = [...results.codebtors, item];
        }
        results.rents[item.id] = 0;
      });
      if (country === "mx") {
        const mainApplicantAddress = await getDetails(results.main.id);
        results.main = {
          ...results.main,
          address: mainApplicantAddress?.address || null,
        };

        Promise.all(
          results.codebtors.map(async (cod, index) => {
            const prevData = await getDetails(cod.id);
            return {
              ...results.codebtors[index],
              address: prevData?.address || null,
            };
          })
        ).then((codebtors) => {
          results.codebtors = codebtors;
          dispatch({
            type: SET_SINGLE_EVALUATION,
            singleEvaluation: parseSingleEvaluation(results, brokerage),
          });
        });
      } else {
        dispatch({
          type: SET_SINGLE_EVALUATION,
          singleEvaluation: parseSingleEvaluation(results, brokerage),
        });
      }
    }
  }
};

export const getEvaluationToken = async (
  mainId: string,
  authToken: string,
  country: string,
  dispatch: Dispatch
) => {
  const res = await newEvaluationService.applicantEvaluationToken(mainId, authToken, country);
  if (res) {
    dispatch({ type: SET_EVALUATION_TOKEN, token: res["data"].token });
  } else {
    dispatch({ type: SET_EVALUATION_TOKEN, token: null });
  }
};

export const getApplicant = async (relatedApplicant, authToken: string, dispatch: Dispatch) => {
  const res = await applicantService.get(relatedApplicant, authToken);
  if (res) {
    dispatch({ type: SET_APPLICANT, applicant: res["data"] });
  } else {
    dispatch({ type: SET_APPLICANT, applicant: null });
  }
};

export const getHoumer = async (relatedApplicant, authToken: string, dispatch: Dispatch) => {
  const res = await applicantService.getAppraiser(relatedApplicant, authToken);
  if (res) {
    dispatch({ type: SET_HOUMER, houmer: res["data"] });
  } else {
    dispatch({ type: SET_HOUMER, houmer: null });
  }
};

export const handleChangeRent = (
  evaluation: number,
  value,
  rents: IRents[],
  singleEvaluation: SingleEvaluation,
  dispatch: Dispatch
) => {
  const newRents = rents;
  newRents[evaluation] = value;
  const newSum = newRents ? getRentsSum(newRents) : 0;
  dispatch({
    type: SET_SINGLE_EVALUATION,
    singleEvaluation: {
      ...singleEvaluation,
      rents: newRents,
      rentsSum: newSum,
    },
  });
};

export const changeProperty = async (
  mainId: number,
  applicantEmail: string,
  selectedPropertyId: number,
  selectedPropertyUid: string,
  singleEvaluation: SingleEvaluation,
  authToken: string,
  country: string,
  dispatch: Dispatch
) => {
  dispatch({ type: SET_LOADING_DATA, loadingData: true });
  const userData = JSON.parse(localStorage.getItem("user"));
  if (selectedPropertyId != null && selectedPropertyUid != null) {
    try {
      await newEvaluationService.updateProperty(
        mainId,
        selectedPropertyId,
        selectedPropertyUid,
        userData.id,
        applicantEmail,
        authToken,
        country as Locale
      );
      dispatch({
        type: SET_SINGLE_EVALUATION,
        singleEvaluation: {
          ...singleEvaluation,
          propertyId: selectedPropertyId,
          propertyUid: selectedPropertyUid,
        },
      });
      dispatch({ type: SET_LOADING_DATA, loadingData: false });
      return true;
    } catch (err) {
      Swal.fire("No se puedo cambiar de propiedad", err as string, "error");
      dispatch({ type: SET_LOADING_DATA, loadingData: false });
    }
  }
};

export const changeBankAccount = async (
  data,
  singleEvaluation: SingleEvaluation,
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SINGLE_EVALUATION,
    singleEvaluation: { ...singleEvaluation, bankAccount: data },
  });
};

export const handleVerifyDoc = async (
  user,
  evaluation,
  mainId: string,
  codebtorIds: string,
  token: string,
  loadingBuro: (value: boolean) => void,
  dispatch: Dispatch
) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const data = {
    rfc: evaluation.user.document,
    primerNombre: evaluation.user.name,
    segundoNombre: "",
    apellidoPaterno: evaluation.user.last_name,
    apellidoMaterno: evaluation.user.second_last_name,
    calle: `${evaluation.address.street} ${evaluation.address.interiorNumber} ${evaluation.address.exteriorNumber}`,
    coloniaPoblacion: evaluation.address.colonia,
    ciudad: evaluation.address.city,
    estado: evaluation.address.state,
    codigoPostal: evaluation.address.zip,
    token: evaluation.nip_token,
  };

  Swal.fire({
    title: "Atención",
    text: `${user.name}, antes de solicitar la verificación de buró verique que el RFC: ${evaluation.user.document} coincida con la documentación presentada. ¿Solicitar buró?`,
    type: "warning",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
  }).then(async (result) => {
    if (result.value) {
      try {
        loadingBuro(true);
        await newEvaluationService.verifyBuroDocumentation(evaluation.id, token, "mx", {
          validated_by: userData.id,
          risk_data: data,
        });
        Swal.fire({
          title: "Exito!",
          text: `Documentos verificados con buró de crédito correctamente`,
          type: "success",
        }).then(() => {
          loadingBuro(false);
          getSingleEvaluation(mainId, codebtorIds, "mx", token, dispatch);
        });
      } catch {
        loadingBuro(false);
        Swal.fire({
          title: "Error!",
          text: `Hubo un error, por favor comuniquese con soporte`,
          type: "error",
        });
      }
    }
  });
};

export const downloadCsv = async (
  evaluation,
  token: string,
  setLoading: (value: boolean) => void
) => {
  setLoading(true);
  try {
    await newEvaluationService.getBuroScore(evaluation.id, token, "mx", evaluation.user.document);
    Swal.fire({
      title: "Exito!",
      text: `El score se obtuvo correctamente, por favor verifiquelo en la vista de documentos`,
      type: "success",
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: `Hubo un error, por favor comuniquese con soporte`,
      type: "error",
    });
  }
  setLoading(false);
};

export const setProperty3X = (property3x: number): Action => ({
  type: SET_PROPERTY_3X,
  property3x,
});

export const setPropertyIsInUF = (propertyIsInUF: boolean): Action => ({
  type: SET_PROPERTY_IS_IN_UF,
  propertyIsInUF,
});

export const setCanPay = (canPay: boolean): Action => ({
  type: SET_CAN_PAY,
  canPay,
});
