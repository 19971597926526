import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import classNames from "classnames";
import useStyles from "./CheckboxWithLabelStyles";

interface Props {
  label: string;
  id?: string;
  value: boolean;
  onChange: () => void;
  defaultChecked?: boolean;
  checkboxProps?: React.ComponentProps<typeof Checkbox>;
  placement?: "end" | "start" | "top" | "bottom";
  justifyContent?: string;
  size?: string;
  labelProps?: string;
  formControlProps?: React.ComponentProps<typeof FormControl>;
}

function CheckboxWithLabel({
  label,
  id,
  value,
  onChange,
  defaultChecked,
  checkboxProps,
  labelProps,
  placement = "start",
  justifyContent = "space-between",
  size = "md",
  formControlProps,
}: Props) {
  const classes = useStyles({ justifyContent, size });
  return (
    <FormControl className={classes.wrapper} {...formControlProps}>
      <FormControlLabel
        classes={{
          label: classNames(classes.label, labelProps),
          root: classes.wrapper,
        }}
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            classes={{
              root: classes.box,
            }}
            color="primary"
            {...checkboxProps}
          />
        }
        label={label}
        labelPlacement={placement}
      />
    </FormControl>
  );
}

export default CheckboxWithLabel;
