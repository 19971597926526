import { useEffect, ReactNode, createContext, useContext, useMemo, memo } from "react";

import isEqual from "lodash/isEqual";

import { useMap } from "context/map/mapContext";
import { useSource } from "./Source";
import { KeyPaint, LayerOption, Map } from "models/Mapbox";

interface LayerProps {
  id: string;
  beforeId?: string;
  children?: ReactNode;
  layerData: LayerOption;
}

const ApiContext = createContext<{ id: string }>(undefined);

export const useLayer = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  return context;
};

const createOrUpdate = (
  map: Map,
  layerData: LayerOption,
  sourceId: string,
  id: string,
  beforeId: string
) => {
  const currentLayer = map?.getLayer(id);
  if (currentLayer) {
    Object.entries(layerData.paint).forEach(([key, val]) => {
      map.setPaintProperty(id, key as KeyPaint, val);
    });

    return currentLayer;
  }
  map.addLayer({ ...layerData, source: sourceId, id }, beforeId);

  return map?.getLayer(id);
};

function Layer({ id, children, layerData, beforeId }: LayerProps) {
  const {
    state: { map },
  } = useMap();
  const { id: sourceId } = useSource();

  const valueId = useMemo(() => ({ id }), [id]);

  useEffect(
    () => () => {
      if (map.style && map.style._loaded && map.getLayer(id)) {
        map.removeLayer(id);
      }
      return undefined;
    },
    [map]
  );
  const layer = useMemo(() => {
    if (map?.style) {
      return createOrUpdate(map, layerData, sourceId, id, beforeId);
    }
    return null;
  }, [layerData, id]);

  return <ApiContext.Provider value={valueId}>{layer && children}</ApiContext.Provider>;
}

export default memo(Layer, (prev, curr) => {
  const samePaint = isEqual(prev.layerData.paint, curr.layerData.paint);
  const sameLayer = isEqual(prev.layerData.paint, curr.layerData.paint);
  return samePaint && sameLayer;
});
