import { emptyPropertyDetails } from "Components/Admin/UIComponents/Cards/emptyDetails";
import { ControlledCheckbox } from "Components/Admin/UIComponents/ControlledForm";
import { useProject } from "context/projectContext/projectContext";
import React from "react";

import AmenitiesEmptyTemplate from "utils/AmenitiesEmptyTemplate";
import FormBlock from "./FormBlock";

function Amenities() {
  const { state } = useProject();

  const associationAmenities =
    state.project?.property?.association_amenities || AmenitiesEmptyTemplate;

  const propertyDetails = state.project?.property?.property_details[0] || emptyPropertyDetails;
  return (
    <FormBlock>
      <h3>Servicios del proyecto/desarrollo </h3>
      <ControlledCheckbox
        id="has_swimming_pool"
        label="Piscina"
        defaultChecked={associationAmenities.has_swimming_pool}
      />
      <ControlledCheckbox
        id="condominio"
        label="Condominio"
        defaultChecked={propertyDetails.condominio}
      />
      <ControlledCheckbox id="gym" label="Gimnasio" defaultChecked={associationAmenities.has_gym} />
      <ControlledCheckbox
        id="has_roof_garden_amenities"
        label="Roof Garden Común"
        defaultChecked={associationAmenities.has_roof_garden}
      />
      <ControlledCheckbox
        id="has_playground"
        label="Área de juegos infantiles"
        defaultChecked={associationAmenities.has_playground}
      />
      <ControlledCheckbox
        id="has_all_day_vigilance"
        label="Vigilancia las 24 hrs"
        defaultChecked={associationAmenities.has_all_day_vigilance}
      />
      <ControlledCheckbox
        label="Salón multiuso / Centro de eventos"
        id="has_party_room"
        defaultChecked={associationAmenities.has_party_room}
      />
      <ControlledCheckbox
        id="has_games_room"
        label="Salón de juegos"
        defaultChecked={associationAmenities.has_games_room}
      />
      <ControlledCheckbox
        id="has_cinema"
        label="Cine"
        defaultChecked={associationAmenities.has_cinema}
      />
      <ControlledCheckbox
        id="has_sauna"
        label="Sauna"
        defaultChecked={associationAmenities.has_sauna}
      />
      <ControlledCheckbox
        id="has_steam_room"
        label="Vapor"
        defaultChecked={associationAmenities.has_steam_room}
      />
      <ControlledCheckbox
        id="has_bbq_area"
        label="Asadores / Quincho"
        defaultChecked={associationAmenities.has_bbq_area}
      />
      <ControlledCheckbox
        id="has_patio"
        label="Patio / Áreas verdes"
        defaultChecked={associationAmenities.has_patio}
      />
      <ControlledCheckbox
        id="has_laundry"
        label="Área de lavado / Lavandería"
        defaultChecked={associationAmenities.has_laundry}
      />
      <ControlledCheckbox
        id="has_elevator"
        label="Elevador / Ascensor"
        defaultChecked={associationAmenities.has_elevator}
      />
      <ControlledCheckbox
        id="has_concierge"
        label="Seguridad / Portería / Conserjería"
        defaultChecked={associationAmenities.has_concierge}
      />
      <ControlledCheckbox
        id="has_pet_area"
        label="Área para mascotas"
        defaultChecked={associationAmenities.has_pet_area}
      />
      <ControlledCheckbox
        id="has_gourmet_space"
        label="Espacio Gourmet"
        defaultChecked={associationAmenities.has_gourmet_space}
      />
      <ControlledCheckbox
        id="has_playroom"
        label="Ludoteca"
        defaultChecked={associationAmenities.has_playroom}
      />
    </FormBlock>
  );
}

export default React.memo(Amenities);
