import { CountryUpperCase, Timezones } from "models/Countries";

const getTimezone = (country: CountryUpperCase | string, commune: string): Timezones => {
  if (country === "Chile") return "America/Santiago";
  else if (country === "Colombia") return "America/Bogota";
  else if (country === "Mexico") {
    if (commune === "Tijuana") return "America/Tijuana";
    return "America/Mexico_City";
  }
};

export default getTimezone;
