import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";
import {
  BackButton,
  NextButton,
} from "Components/Admin/administrator/properties/components/FormElements";
import { setSuccessModal } from "context/propertyContext/propertyActions";
import { useProperty } from "context/propertyContext/propertyContext";
import { useHistory, useLocation } from "react-router-dom";

import useStyles from "./SuccessDialogStyles";

function SuccessDialog() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ leadId: string }>();
  const { state, dispatch } = useProperty();
  const { property, openSuccessModal } = state;
  const handleClose = () => {
    dispatch(setSuccessModal(false));
  };
  const handleRedirect = () => {
    const url = `/admin/properties/${property.uid}`;
    history.push(url);
  };
  const handleBackToLead = () => {
    const url = `/admin/leads/${location?.state?.leadId}`;
    history.push({ pathname: url, state: { defaultTab: "properties" } });
  };

  return (
    <Dialog open={openSuccessModal}>
      <DialogTitle>
        <div id="modal-header" className={classes.dialogTitleContainer}>
          La propiedad ha sido creada satisfactoriamente
          <IconButton aria-label="close" onClick={handleClose}>
            <GridCloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      {property && (
        <DialogContent>
          <p>ID: {property.id}</p>
          <p>Region: {property.region}</p>
          <p>País: {property.country}</p>
          <p>Tipo de propiedad: {property.type}</p>
        </DialogContent>
      )}
      <DialogActions>
        <BackButton onClick={handleClose}> Cerrar </BackButton>
        {location?.state?.leadId && (
          <NextButton onClick={handleBackToLead}> Volver al lead </NextButton>
        )}
        <NextButton id="go-to-property" onClick={handleRedirect}>
          {" "}
          Ir a propiedad{" "}
        </NextButton>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessDialog;
