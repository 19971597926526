import { SnackbarProvider } from "notistack";

import { Container, Grid } from "@material-ui/core";

import TemplateForm from "./components/TemplateForm";
import useTemplateViewStyles from "./TemplateViewStyles";
import TemplateHeader from "./components/TempalteHeader";
import TemplateTitle from "./components/TemplateTitle";
import { useTemplate } from "./hooks";

function TemplateView() {
  const classes = useTemplateViewStyles();

  const { country, isNewTemplate, name, handleSubmit, handleBack } = useTemplate();

  return (
    <Container className={classes.templateViewRoot}>
      <Grid container>
        <TemplateHeader country={country} current={isNewTemplate ? "Crear template" : "Template"} />

        <TemplateTitle country={country} text={isNewTemplate ? "Nuevo template" : name} />

        <Grid container item xs={12} justifyContent="center">
          <Grid item>
            <SnackbarProvider>
              <TemplateForm handleSubmit={handleSubmit} handleBack={handleBack} />
            </SnackbarProvider>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TemplateView;
