import { Grid } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledDropArea } from "Components/Admin/UIComponents/ControlledForm";

import {
  parseUnitsFile,
  setUnitsUploadErrors,
  uploadUnitsFile,
} from "context/multifamilyContext/unit/unitActions";
import { NewUnitsUploadForm, UnitsUploadError } from "context/multifamilyContext/unit/unitTypes";
import { useMultifamilyUnit } from "context/multifamilyContext/unit/unitContext";
import { useGetToken } from "hooks";
import validationSchema from "Components/Admin/administrator/projects/layouts/units/unitsUpload/validationSchema";

import useStyles from "Components/Admin/administrator/projects/layouts/units/unitsUpload/useUnitsUploadFormStyles";

type Props = {
  handleOnClose: () => void;
  id: number;
};

function UnitsUploadForm({ handleOnClose, id }: Props) {
  const { state, dispatch } = useMultifamilyUnit();
  const { getToken } = useGetToken();
  const classes = useStyles();

  const methods = useForm<NewUnitsUploadForm>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const files: File[] = methods.watch("files", []);

  const onSubmit: SubmitHandler<NewUnitsUploadForm> = async (data) => {
    const authToken = await getToken();
    const filename = await uploadUnitsFile(id, data.files[0], authToken, dispatch);
    if (filename) {
      const res = await parseUnitsFile(id, filename, authToken, dispatch);
      if (res?.parseErrors) {
        dispatch(setUnitsUploadErrors(res?.errorsList as UnitsUploadError[]));
      } else {
        handleOnClose();
      }
    }
  };

  return (
    <FormProvider {...methods} key="LayoutForm">
      <Grid container justifyContent="center">
        <form
          key="UnitsUploadForm"
          onSubmit={methods.handleSubmit(onSubmit)}
          className={classes.form}>
          <ControlledDropArea
            id="files"
            allowedMimeTypes=".xlsx"
            subtitle="Sube o arrastra aquí el excel de la unidad"
          />
          <Grid container item className={classes.buttons} justifyContent="center">
            <LinkButton onClick={handleOnClose}>Cancelar</LinkButton>
            <DesignSystemButton
              isLoading={state.loadingUpload}
              disabled={files.length < 1}
              type="submit"
              variant="primary"
              size="small"
              label=" Validar excel"
            />
          </Grid>
        </form>
      </Grid>
    </FormProvider>
  );
}

export default UnitsUploadForm;
