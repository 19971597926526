import { UseQueryResult, useQuery } from "react-query";

import { useGetToken } from "hooks";
import propertyService from "services/propertyService";

import { parsePropertyData } from "./parsers";
import { ContractProps } from "./types";

const useGetPropertyInfo = (propertyId?: number) => {
  const { getToken } = useGetToken();
  const { data, isLoading, isError }: UseQueryResult<ContractProps, Error> = useQuery<
    ContractProps,
    Error
  >(
    ["contractInfo", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getContractPropertyInfo(propertyId, authToken);
      return parsePropertyData(response.data);
    },
    {
      retry: false,
      enabled: !!propertyId,
    }
  );
  return { data, isLoading, isError };
};

export default useGetPropertyInfo;
