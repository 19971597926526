import { useRef, useMemo } from "react";

import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { useGetToken } from "hooks";
import { nextFourTeenDays, sideScroll } from "./utils";
import { parseStringToSpecificDate } from "utils/datetime";
import { NewAppraiserAvailabilityBody } from "services/appraiserService/types";
import { useDashboard } from "context/controlTower/controlTowerContext";
import { AvailabilityForm } from "context/availabilityContext/availabilityTypes";
import { updateAppraiserSchedule } from "context/controlTower/controlTowerActions";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DailySchedule from "./DailySchedule";

import useAppraiserInformationStyles from "./useAppraiserInformationStyles";

interface Props {
  id: string;
}

function AppraiserSchedule({ id }: Props) {
  const { state, dispatch } = useDashboard();
  const classes = useAppraiserInformationStyles();
  const { getToken } = useGetToken();
  const methods = useForm<AvailabilityForm>();
  const datesWrapper = useRef(null);
  const { fourteenDaysInUI, fourteenDaysInMomentFormat } = useMemo(
    () => nextFourTeenDays(state.appraiserProfile.timezone),
    []
  );
  const lastDay = state.availability.availability[state.availability.availability.length - 1];

  const onSubmit: SubmitHandler<AvailabilityForm> = (data, e) => {
    const updateData = Object.entries(data)
      .filter((item) => item[1])
      .map((elem) => parseStringToSpecificDate(elem, state.appraiserProfile.timezone));
    const body: NewAppraiserAvailabilityBody = {
      availability: updateData,
    };
    getToken().then((authToken) => {
      updateAppraiserSchedule(id, body, dispatch, authToken);
    });
  };

  return (
    <FormProvider {...methods} key="AvailabilityProvider">
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={classes.arrowsContainer}>
          <ArrowBackIosIcon
            onClick={() => {
              sideScroll(datesWrapper.current, 15, 170, -170 / 14);
            }}
          />
          <ArrowForwardIosIcon
            onClick={() => {
              sideScroll(datesWrapper.current, 15, 170, 170 / 14);
            }}
          />
        </div>
        <Grid className={classes.datesContainer} ref={datesWrapper}>
          {fourteenDaysInUI.map((day, i) => (
            <Grid key={day} item xs={2} className={classes.dateColumn}>
              <DailySchedule day={day} index={fourteenDaysInMomentFormat[i]} />
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={3}>
            <div className={classes.infoContainer}>
              <Typography>
                Última fecha con agenda:{" "}
                <strong>{moment(lastDay?.end_date).format("DD/MM/YYYY")}</strong>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} className={classes.buttonContainer}>
            <DesignSystemButton
              variant="primary"
              label="Actualizar horarios"
              size="large"
              type="submit"
              isLoading={state.loadingUpdate}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default AppraiserSchedule;
