export const MONTHS = [
  { value: "1", label: "Enero" },
  { value: "2", label: "Febrero" },
  { value: "3", label: "Marzo" },
  { value: "4", label: "Abril" },
  { value: "5", label: "Mayo" },
  { value: "6", label: "Junio" },
  { value: "7", label: "Julio" },
  { value: "8", label: "Agosto" },
  { value: "9", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Deciembre" },
];

export const SALARY_TYPES = [
  { value: "salary", label: "Salario" },
  { value: "other", label: "Otro" },
];

export const VISA_TYPES = [
  {
    label: "Definitiva",
    value: "definitive",
  },
  {
    label: "Estudiante",
    value: "student",
  },
  {
    label: "Temporaria",
    value: "temporary",
  },
];

export const EMPLOYMENT_CONTRACT_TYPES = [
  {
    label: "Indefinido",
    value: "permanent",
  },
  {
    label: "Plazo fijo",
    value: "fixed_term",
  },
];

export const WORKER_TYPE = [
  {
    label: "Dependiente",
    value: "dependent",
  },
  {
    label: "Independiente",
    value: "independent",
  },
  {
    label: "Pensionado",
    value: "retired",
  },
];
