import { makeStyles, Theme } from "@material-ui/core";

const useButtonCopyLinkStyles = makeStyles<Theme>((theme) => ({
  cgdButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    textTransform: "none",
    fontSize: "0.75rem",
    lineHeight: "1.25em",
    color: "white",
    minWidth: 147,
    "& p": {
      color: "white",
      fontSize: "0.75rem",
    },
  },
  cgdButtonBase: {
    backgroundColor: "#FEF1F0",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  cgdButtonGreen: {
    color: "#00982D",
    border: `1px solid #00982D`,
    backgroundColor: "white",
    "& p": {
      color: "#00982D",
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(0.5),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useButtonCopyLinkStyles;
