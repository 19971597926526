import { useMutation, useQueryClient } from "react-query";

import { useGetToken } from "hooks";
import { alert } from "libs/alerts";
import { Locale } from "models/Countries";
import newPaymentService from "services/newPaymentService";
import { IMarkAsPaid } from "services/newPaymentService/types";

const useMarkReservationPaid = (country: Locale, handleModal: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isError } = useMutation(
    async (data: IMarkAsPaid) => {
      const token = await getToken();
      const response = await newPaymentService.markReservationAsPaid(token, country, data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("reservationUserById");
        handleModal();
        alert.success({ message: "Reserva marcada como pagada" });
      },
      onError: () => {
        alert.error({ message: "Error", disclaimer: "No se pudo marcar la reserva como pagada" });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
  };
};

export default useMarkReservationPaid;
