import { AxiosResponse } from "axios";
import qs from "query-string";

import { createHttp } from "services/helper";
import { Paginate } from "models/ResponseWithPaginate";
import {
  IndexExternalPortalPublicationsFilters,
  IndexExternalPortalPublicationsResponse,
  IndexPortalNamesRequestFilters,
  IndexPublicationTypesNamesRequest,
  IndexPublicationTypesRequestResponse,
  IndexQuotaByMacrozoneFilters,
  IndexQuotaByMacrozoneResponse,
  IndexTotalQuotaFilters,
  IndexTotalQuotaResponse,
  UpdateQuotaByMacrozoneBody,
  IndexUpdateManyQuotasRequestResponse,
} from "./types";
import { SelectorSerializerResponse } from "models/Option";
import { ResCities } from "models/DemandLead";
import { PlanQuota } from "models/Integration";

export default {
  indexExternalPortalPublicationsRequest(
    { ...filters }: IndexExternalPortalPublicationsFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<IndexExternalPortalPublicationsResponse>>> {
    return createHttp(token).get(`/epi/v2/publications-info/?${qs.stringify(filters)}`);
  },

  indexTotalQuotaRequest(
    { ...filters }: IndexTotalQuotaFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<IndexTotalQuotaResponse>>> {
    return createHttp(token).get(`/epi/v2/portal-plan/?${qs.stringify(filters)}`);
  },

  indexQuotaByMacrozoneRequest(
    { ...filters }: IndexQuotaByMacrozoneFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<IndexQuotaByMacrozoneResponse>>> {
    return createHttp(token).get(`/epi/v2/portal-macrozone/?${qs.stringify(filters)}`);
  },

  updateQuotaByMacrozoneRequest(
    id: number,
    data: UpdateQuotaByMacrozoneBody,
    token: string
  ): Promise<AxiosResponse<IndexQuotaByMacrozoneResponse>> {
    return createHttp(token).put(`/epi/v2/portal-macrozone/${id}/`, data);
  },

  updateManyQuotasRequest(
    data: PlanQuota[],
    token: string
  ): Promise<AxiosResponse<IndexUpdateManyQuotasRequestResponse>> {
    return createHttp(token).put(`/epi/v2/portal-macrozone/update_many/`, data);
  },

  // Filters
  indexPortalNamesRequest(
    { ...filters }: Partial<IndexPortalNamesRequestFilters>,
    token: string
  ): Promise<AxiosResponse<SelectorSerializerResponse[]>> {
    return createHttp(token).get(`/epi/v2/portals-names/?${qs.stringify(filters)}`);
  },

  indexPublicationTypesNamesRequest(
    { ...filters }: Partial<IndexPublicationTypesNamesRequest>,
    token: string
  ): Promise<AxiosResponse<IndexPublicationTypesRequestResponse>> {
    return createHttp(token).get(`/epi/v2/publication-types-names/?${qs.stringify(filters)}`);
  },

  indexStatusesNamesRequest(
    { ...filters }: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<SelectorSerializerResponse[]>> {
    return createHttp(token).get(`/epi/v2/statuses-names/?${qs.stringify(filters)}`);
  },

  indexMacrozoneNamesRequest(
    { ...filters }: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<SelectorSerializerResponse[]>> {
    return createHttp(token).get(`/schedules/v2/macrozone-all?${qs.stringify(filters)}`);
  },

  indexCitiesRequest(
    { ...filters }: Record<string, string>,
    token: string
  ): Promise<AxiosResponse<ResCities>> {
    return createHttp(token).get(
      `/demand/v2/get_city_communes_by_country?${qs.stringify(filters)}`
    );
  },
};
