import { camelCase } from "lodash";
import { IParsePlan } from "hooks/usePlans/utils/types";
import { IAppraiser } from "services/schedulesService/types";
import { IInsuranceCompany } from "services/insuranceService/types";

import { PlanProps } from "./types";
import { TENANT_PLANS } from "./constants";
import { TenantPlan } from "models/Contract";
import { IExternalCollectionResponse } from "services/contractService/types";

export const parsePlanForm = (data: PlanProps, brokerage) => ({
  amount_policy: 0,
  automatic_renewal: data.automaticRenewal === "true",
  end_notice_period: data.notification,
  first_payment_date: data.firstPaymentDate,
  has_brokerage: brokerage,
  houmer: data.houmer === "none" ? null : data.houmer,
  init_date: data.signatureDate,
  is_common_expenses_included: data.commonExpensedIncluded === "true",
  is_transfer: data.isTransfer === "true",
  pay_day: data.payDay,
  person_warranty: data.personWarranty,
  plan: data.planId,
  plan_discount: data.planDiscount,
  readjust: data.readjust,
  rent_include_common_expenses: data.commonExpensedIncluded === "true",
  warranty: data.warranty,
  lessee_brokerage_discount: data.lesseeBrokerageDiscount,
  lessor_brokerage_discount: data.lessorBrokerageDiscount,
  property_furnishing: data.propertyFurnishing,
});

export const parsePlanOptions = (plans: IParsePlan[]) =>
  plans.map((p: IParsePlan) => ({
    value: p.id,
    label: p.name,
  }));

export const parseAppraisersOptions = (appraisers?: IAppraiser[]) => {
  const result = [
    {
      value: "none",
      label: "Ningúno (traspaso/sin corretaje)",
    },
  ];
  if (appraisers) {
    appraisers.map((a) =>
      result.push({
        value: a.id,
        label: a.name,
      })
    );
  }
  return result;
};

export const parseInsuranceCompanyOptions = (insuranceCompanies: IInsuranceCompany[]) =>
  insuranceCompanies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

export const parseTenantPlanRequestBody = (tenantPlan: TenantPlan) => ({
  tenant_plan: tenantPlan,
});

export const parseTenantPlansResponse = (tenantPlans: TenantPlan[]) =>
  tenantPlans.map((tenantPlan) => TENANT_PLANS[camelCase(tenantPlan)]);

export const parseExternalColection = (externalCollection: IExternalCollectionResponse) => ({
  contractId: externalCollection.contract_id,
  contractType: externalCollection.contract_type,
  exclusivityConditions: externalCollection.exclusivity_conditions?.future_plan
    ? {
        futurePlan: externalCollection.exclusivity_conditions.future_plan,
      }
    : null,
});
