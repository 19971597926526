import { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import FileManagement from "Components/Admin/UIComponents/organisms/FileManagement";
import Spinner from "Components/Shared/partials/Spinner";

import useSetContractData from "../../hooks/useSetContractData";
import useStyles from "./EditContractStyles";

interface FileToUploadProps {
  name: string;
  remove: () => void;
}

function FileToUpload({ name, remove }: FileToUploadProps) {
  const classes = useStyles();

  return (
    <div className={classes.listedFile}>
      <Typography variant="subtitle2">{name}</Typography>
      <IconButton aria-label="remove" onClick={remove}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

interface ManageFilesProps {
  title: string;
  uploadService: (file: File) => Promise<void>;
  downloadLink?: string;
}

function ManageFiles({ title, uploadService, downloadLink }: ManageFilesProps) {
  return (
    <FileManagement
      title={title}
      uploadService={uploadService}
      downloadLink={downloadLink || "#"}
      allowedMimeTypes={[
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ]}
    />
  );
}

interface DocumentSectionsLinks {
  mandateParticular: string;
  contractParticular: string;
  mandateGeneral: string;
  contractGeneral: string;
}

interface Props {
  contractId: number;
  contractType: string;
  documentSectionsLinks: DocumentSectionsLinks;
}

export default function EditContract({ contractId, contractType, documentSectionsLinks }: Props) {
  const classes = useStyles();

  const [contractGeneralFile, setContractGeneralFile] = useState<File | null>(null);
  const [mandateGeneralFile, setMandateGeneralFile] = useState<File | null>(null);
  const [mandateParticularFile, setMandateParticularFile] = useState<File | null>(null);
  const [contractParticularFile, setContractParticularFile] = useState<File | null>(null);
  const { mutate, isLoading } = useSetContractData({ custom: true });

  const handleSubmit = async () => {
    const formData = new FormData();
    if (mandateGeneralFile) {
      formData.append("mandate_general_template", mandateGeneralFile);
    }
    if (mandateParticularFile) {
      formData.append("mandate_particular_template", mandateParticularFile);
    }
    if (contractGeneralFile) {
      formData.append("contract_general_template", contractGeneralFile);
    }
    if (contractParticularFile) {
      formData.append("contract_particular_template", contractParticularFile);
    }
    formData.append("contract_id", JSON.stringify(contractId));

    mutate(formData);

    setContractGeneralFile(null);
    setMandateGeneralFile(null);
    setMandateParticularFile(null);
    setContractParticularFile(null);
  };

  if (!contractType || isLoading) {
    return <Spinner className={classes.spinner} />;
  }

  return (
    <div className={classes.root}>
      <ManageFiles
        title="Mandato (condiciones generales)"
        uploadService={(file) =>
          new Promise((resolve) => {
            resolve(setMandateGeneralFile(file));
          })
        }
        downloadLink={documentSectionsLinks.mandateGeneral}
      />
      <ManageFiles
        title="Mandato (condiciones particulares)"
        uploadService={(file) =>
          new Promise((resolve) => {
            resolve(setMandateParticularFile(file));
          })
        }
        downloadLink={documentSectionsLinks.mandateParticular}
      />
      <ManageFiles
        title="Contrato (condiciones generales)"
        uploadService={(file) =>
          new Promise((resolve) => {
            resolve(setContractGeneralFile(file));
          })
        }
        downloadLink={documentSectionsLinks.contractGeneral}
      />
      <ManageFiles
        title="Contrato (condiciones particulares)"
        uploadService={(file) =>
          new Promise((resolve) => {
            resolve(setContractParticularFile(file));
          })
        }
        downloadLink={documentSectionsLinks.contractParticular}
      />
      <Divider className={classes.divider} />

      {mandateGeneralFile && (
        <FileToUpload name={mandateGeneralFile.name} remove={() => setMandateGeneralFile(null)} />
      )}
      {mandateParticularFile && (
        <FileToUpload
          name={mandateParticularFile.name}
          remove={() => setMandateParticularFile(null)}
        />
      )}
      {contractGeneralFile && (
        <FileToUpload name={contractGeneralFile.name} remove={() => setContractGeneralFile(null)} />
      )}
      {contractParticularFile && (
        <FileToUpload
          name={contractParticularFile.name}
          remove={() => setContractParticularFile(null)}
        />
      )}
      <div className={classes.buttonContainer}>
        <DesignSystemButton
          label="Actualizar documentos"
          variant="primary"
          type="submit"
          size="medium"
          onClick={handleSubmit}
          isLoading={isLoading}
          buttonClassName={classes.submitButton}
        />
      </div>
    </div>
  );
}
