import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";

import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import { MONTHS } from "../EvaluateForm/utils/constants";

interface Props {
  liquidationId: 1 | 2 | 3;
  type: "salary" | "other";
}

const Form = ({ liquidationId, type }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<MemberInformation>();
  return (
    <div>
      <p className="text-p18 font-bold mt-2 mb-1">Liquidación {liquidationId}</p>
      <div className="flex gap-2 flex-col">
        <div className="flex gap-2">
          <div className="w-[50%]">
            <FieldSelect
              label="Mes de liquidación"
              id={`${type}IncomeMonth${liquidationId}`}
              placeholder="Selecciona un mes"
              options={MONTHS}
              disabled={type === "other" || liquidationId !== 1}
              required
              form="novalidateform"
              errorMessage={errors[`${type}IncomeMonth${liquidationId}`]?.message}
              {...register(`${type}IncomeMonth${liquidationId}`)}
            />
          </div>
          <div className="w-[50%]">
            <FieldInput
              id={`${type}IncomeAmount${liquidationId}`}
              placeholder="Ingresar liquidación"
              label="Liquidación"
              type="number"
              required
              form="novalidateform"
              errorMessage={errors[`${type}IncomeAmount${liquidationId}`]?.message}
              {...register(`${type}IncomeAmount${liquidationId}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
