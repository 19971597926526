import { Fragment } from "react";
import currency from "@houm-com/formats/currency";
import clx from "classnames";

import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

import { MovementBreakdownRecord } from "models/Payments";
import { IDetails } from "models/Breakdown";
import { Locale } from "models/Countries";

import ReadjustmentTooltip from "../ReadjustmentTooltip";
import useStyles from "./PaymentDetailsListStyles";
import DeleteAdjustment from "../DeleteAdjustment";
import { parseBreakdown } from "./utils";

interface Props {
  record: MovementBreakdownRecord;
}

function PaymentDetailsList({ record }: Props) {
  const classes = useStyles();

  const renderDetail = (item: IDetails): string => {
    const price = currency(item?.value ?? 0, item?.currency);
    return item.type === "charge" ? `-${price}` : price;
  };

  return (
    <List className={classes.root}>
      {parseBreakdown(record).map((detail) => (
        <Fragment key={detail.identifier}>
          <ListItem alignItems="flex-start">
            <ListItemText>
              <div className={classes.listContainer}>
                <Typography
                  component="span"
                  variant="body2"
                  className={clx(classes.inline, classes.description)}
                  color="textPrimary">
                  {detail.description}
                </Typography>
                <div className={classes.detailContainer}>
                  <span
                    className={clx(classes.inline, {
                      [classes.negative]: detail.type === "charge",
                      [classes.positive]: detail.type === "credit",
                    })}>
                    {renderDetail(detail)}
                  </span>
                  {detail.adjustmentType === "CHARGE_DISCOUNT" && record.status !== "PAID" && (
                    <DeleteAdjustment
                      detail={detail}
                      movementId={record.movement_id}
                      country={record.country as Locale}
                    />
                  )}
                  {false && (
                    // {detail.adjustmentType === "READJUST" && (
                    <ReadjustmentTooltip readjustment={detail.details} />
                  )}
                </div>
              </div>
            </ListItemText>
          </ListItem>
        </Fragment>
      ))}
      <Divider variant="fullWidth" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemText>
          <div className={classes.listContainer}>
            <Typography
              component="span"
              variant="body2"
              className={classes.inlineTotal}
              color="textPrimary">
              Total a pagar
            </Typography>
            <Typography
              component="span"
              variant="body2"
              className={classes.inlineTotal}
              color="textPrimary">
              {currency(record.total_amount ?? 0, record.currency)}
            </Typography>
          </div>
        </ListItemText>
      </ListItem>
    </List>
  );
}

export default PaymentDetailsList;
