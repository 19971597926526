import axios, { AxiosResponse } from "axios";

import { Locale } from "models/Countries";
import { PAYMENTS_URL } from "env";

import { IBanksResponse, IUserBankAccountResponse } from "./types";
import { createHttp, getToken } from "../helper";

export default {
  getBanksCl: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const payments = axios.create({ baseURL: PAYMENTS_URL, headers });

    return payments.get("cl/v3/banks");
  },

  getBanksCo: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const paymentsCo = axios.create({ baseURL: PAYMENTS_URL, headers });

    return paymentsCo.get("co/v3/banks");
  },

  getBanksMx: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const paymentsMx = axios.create({ baseURL: PAYMENTS_URL, headers });

    return paymentsMx.get("mx/v3/banks");
  },

  getBanks: async (country: Locale, token: string): Promise<AxiosResponse<IBanksResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/banks`),

  getByUser: async (country: Locale, userId: number, token: string): Promise<AxiosResponse<any>> =>
    createHttp(token, PAYMENTS_URL).get(`${country}/v3/people/${userId}/bank_accounts`),

  getByUserAndProperty: async (
    country: Locale,
    userId: number,
    propertyId: number,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL)
      .get(`${country}/v3/people/${userId}/bank_accounts/${propertyId}`)
      .catch((error) => {
        console.error(error);
        return error.response;
      }),

  getByUserAndPropertyV2: async (
    country: Locale,
    userId: number,
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<IUserBankAccountResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`
    ),

  create: async (country: Locale, userId: number, data, token: string) =>
    createHttp(token, PAYMENTS_URL).post(`${country}/v3/people/${userId}/bank_accounts`, data),

  update: async (country: Locale, userId: number, propertyId: number, data, token: string) =>
    createHttp(token, PAYMENTS_URL).put(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`,
      data
    ),
};
