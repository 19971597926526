import { ComponentProps } from "react";

import { CountryUpperCase } from "models/Countries";
import { linkagesMap } from "../constants";

import VisitCard from "../VisitCard";
import { StringOption } from "models/Option";
import { PhoneInput } from "models/PhoneInput";

export interface IFormInput {
  third_party_coordination_person: StringOption;
  third_party_coordination_full_name: string;
  third_party_coordination_description: string;
  third_party_coordination_email: string;
  phone: PhoneInput;
}

interface Props {
  checked: boolean;
  linkage: string;
  name: string;
  phone: string;
  email: string;
  description: string;
  country: CountryUpperCase;
  currentStep: string;
  disabled: boolean;
}
const thirdPartyFields = ({
  checked,
  country,
  description,
  email,
  phone,
  linkage,
  name,
  currentStep,
  disabled,
}: Props): ComponentProps<typeof VisitCard>["items"] => {
  if (!checked) return null;

  return [
    {
      title: "Tipo de vinculo",
      position: "vertical",
      content: linkage,
      formKey: "third_party_coordination_person",
      editableMode: "select",
      alias: linkagesMap[country].find((item) => item.value === linkage)?.label,
      editable: currentStep === "editable",
      options: linkagesMap[country],
    },
    {
      disabled: disabled && !!name,
      content: name,
      title: "Nombre",
      position: "vertical",
      formKey: "third_party_coordination_full_name",
      editable: currentStep === "editable",
    },
    {
      disabled: disabled && !!phone,
      title: "Teléfono",
      position: "vertical",
      editableMode: "phone",
      content: phone,
      formKey: "phone",
      editable: currentStep === "editable",
    },
    {
      disabled: disabled && !!email,
      title: "Correo electrónico",
      position: "vertical",
      content: email,
      formKey: "third_party_coordination_email",
      editable: currentStep === "editable",
    },
    {
      title: "Otras indicaciones",
      position: "vertical",
      content: description,
      formKey: "third_party_coordination_description",
      multiline: true,
      rows: 3,
      editable: currentStep === "editable",
    },
  ];
};

export default thirdPartyFields;
