import { makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
  disabled: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  documentTypeWrapper: {
    marginLeft: theme.spacing(0.5),
  },
  documentTypeLabel: {
    fontSize: theme.typography.pxToRem(18),
    color: (props) => (props.disabled ? "#00000061" : theme.palette.grey[900]),
  },
}));

export default useStyles;
