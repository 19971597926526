import { startCase, toLower } from "lodash";

import { ICompaniesResponse } from "services/propertyService/types";

import { AvailableCompaniesOptions } from "./types";

export const parseAvailableCompanies = (data: ICompaniesResponse[]): AvailableCompaniesOptions[] =>
  data.map((company) => ({
    value: company.name === "otro" ? "other" : company.name,
    label: startCase(toLower(company.name.replace(/_/g, " "))),
  }));
