import moment from "moment";

import { useDemandLead } from "context/demandLeadContext/leads/demandLeadContext";
import { useTable } from "context/tableContext/tableContext";

const useDemandParams = () => {
  const { state: demandState, dispatch: demandDispatch } = useDemandLead();

  const { state: tableState, dispatch: tableDispatch } = useTable();

  const { profiled, country, filters, deal } = demandState;
  const { pageSize, ordering, currentPage } = tableState;

  const params = {
    profiled,
    country,
    deal,
    ordering,
    page: currentPage,
    page_size: pageSize,
    business_type: deal === "venta" ? "sales" : "rentals",
    executive: filters.leadQualifier?.label,
    origin: filters.origin?.label,
    status: filters.state?.map((item) => item.label).join(","),
    date: filters.date?.value,
    following_date_from: moment(filters.following_date_from).isValid()
      ? filters.following_date_from
      : undefined,
  };

  return { params, demandState, tableState, demandDispatch, tableDispatch };
};

export default useDemandParams;
