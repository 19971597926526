import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  formContainer: {
    maxWidth: 600,
    marginTop: theme.spacing(2),
  },
  formGridItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  submitButtonContainer: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  selector: {
    marginTop: theme.spacing(0.3),
  },
  selectorLabel: {
    width: "100%",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
}));

export default useStyles;
