import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  position: "left" | "right";
}

const useVisitCardStyles = makeStyles<Theme, Props>((theme) => ({
  card: {
    width: "100%",
    display: "flex",
    borderRadius: 16,
    gap: theme.spacing(2),
    flexDirection: "column",
    padding: theme.spacing(2),
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: ({ position }) => (position === "left" ? "flex-start" : "flex-end"),
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  requiredContainer: {
    display: "flex",
  },
  requiredSymbol: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  checkRoot: {
    padding: 0,
    transform: "scale(0.75)",
  },
}));

export default useVisitCardStyles;
