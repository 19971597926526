import { makeStyles, Theme } from "@material-ui/core";

interface StyleProps {
  showTitleBackground: boolean;
  withSpace: boolean;
}

const useAdminCardStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  adminCardsRoot: {
    border: `1px solid ${theme.palette.grey[100]}`,
    boxShadow: "2px -1px 13px #e6eaec",
    borderRadius: 16,
    margin: ({ withSpace }) => withSpace && theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(0.5),
  },
  titleContainer: {
    background: ({ showTitleBackground }) => showTitleBackground && theme.palette.grey[75],
    borderRadius: 2,
    marginBottom: ({ showTitleBackground }) =>
      showTitleBackground ? theme.spacing(2) : theme.spacing(1.5),
    display: "flex",
    justifyContent: "space-between",
    itemsAlign: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    padding: ({ showTitleBackground }) => showTitleBackground && theme.spacing(0.5),
    fontWeight: 700,
  },
}));

export default useAdminCardStyles;
