import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import newEvaluationService from "services/newEvaluationService";
import { useGetToken } from "hooks";

const useEvaluationApplicant = () => {
  const params = useParams<{ id: string }>();

  const { getToken } = useGetToken();

  const {
    isLoading: evaluationLoading,
    error: evaluationError,
    data: evaluationData = [],
  }: UseQueryResult<any, Error> = useQuery(
    ["applicant_evaluation", params?.id],
    async () => {
      const authToken = await getToken();
      const evaluation = await newEvaluationService.getEvaluationPage({
        relatedApplicant: params.id,
        token: authToken,
      });
      return evaluation.data.evaluations;
    },
    {
      enabled: !!params.id,
    }
  );

  return { evaluationLoading, evaluationError, evaluationData };
};

export default useEvaluationApplicant;
