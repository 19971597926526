import * as yup from "yup";

export const requiredText = "Obligatorio";

export const templateValidationSchema = yup.object().shape({
  contractFile: yup.mixed().required(requiredText),
  mandateFile: yup.mixed().required(requiredText),
  description: yup.string().required(requiredText),
  name: yup.string().required(requiredText),
});
