import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useQuery, UseQueryResult } from "react-query";

import subscriptionService from "services/subscriptionService";
import { AdjustmentPaymentType } from "models/Payments";
import { CountryUpperCase } from "models/Countries";
import { countryCode } from "utils";

import useGetToken from "./useGetToken";

interface IAdjustment {
  id: string;
  label: string;
  description: string;
  score: string;
  type: string;
}

interface ArgsUsePaymentAdjustment {
  country: CountryUpperCase;
  scope: string;
  paymentType: AdjustmentPaymentType;
  methods: UseFormReturn<FieldValues>;
  roleScope: "tenant" | "landlord" | "both";
}

const usePaymentAdjustment = ({
  country,
  scope,
  paymentType,
  methods,
  roleScope,
}: ArgsUsePaymentAdjustment) => {
  const { getToken } = useGetToken();

  useEffect(() => {
    methods.setValue("description", "");
  }, [paymentType]);

  const {
    isLoading,
    data = [],
    error,
  }: UseQueryResult<IAdjustment[], Error> = useQuery<IAdjustment[], Error>(
    ["adjustment", scope, paymentType],
    async () => {
      const authToken = await getToken();
      const response = await subscriptionService.getAdjustment(
        countryCode(country),
        scope,
        roleScope,
        authToken
      );
      return response.data;
    }
  );

  const orderAdjustment = (
    adjustmentArray: {
      value: string;
      label: string;
    }[]
  ) =>
    adjustmentArray.sort((a, b) => {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

  const parseAdjustmentData = (type: AdjustmentPaymentType) => {
    if (!type) return [];
    const adjustementsFiltered = data.filter((adjustment) => adjustment.type === type);
    const adjustmentParsed = adjustementsFiltered.map((adjustment) => ({
      value: adjustment.description,
      label: adjustment.description,
    }));
    return orderAdjustment(adjustmentParsed);
  };

  return {
    isLoading,
    data: parseAdjustmentData(paymentType),
    error,
  };
};

export default usePaymentAdjustment;
