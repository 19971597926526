import { Dialog, LinearProgress } from "@material-ui/core";
import { useProject } from "context/projectContext/projectContext";

import useStyles from "./LoadingDialogStyles";

function LoadingDialog() {
  const classes = useStyles();
  const { state } = useProject();

  return (
    <Dialog open={state.loading} PaperProps={{ className: classes.modalContainer }}>
      <LinearProgress color="primary" />
      <div className={classes.titleContainer}>
        <h2>Creando propiedad ...</h2>
      </div>
    </Dialog>
  );
}

export default LoadingDialog;
