/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { alert } from "@houm-com/ui/Alert";

import userService from "services/userService";
import { useGetToken } from "hooks";

import { parseFormData } from "../utils/parsers";
import { UpdateFormData } from "../utils/types";

interface Props {
  onHandleSuccess?: () => void;
}
const useUpdateUser = ({ onHandleSuccess = null }: Props) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();
  const query = useMutation(async (formData: UpdateFormData) => {
    const authToken = await getToken();
    const parsedData = parseFormData(formData);
    const response = await userService.update(formData.userId, parsedData, authToken);
    return response.data;
  });

  useEffect(() => {
    if (query.isSuccess) {
      alert.success({
        message: "Usuario editado",
        disclaimer: `El usuario ${query.data?.name} ${query.data?.last_name} ha sido exitosamente editado`,
      });
      queryClient.invalidateQueries(["userById", query.data?.id]);
      onHandleSuccess?.();
    }
  }, [query.isSuccess, queryClient, query.data]);

  useEffect(() => {
    if (query.isError) {
      const error = query.error as AxiosError;
      const { response } = error;
      const parsedError = Object.keys(response.data);
      alert.error({
        message: "Error al editar datos del usuario",
        disclaimer: `El usuario no pudo ser editado, error: ${
          response.data[`${parsedError}`] ?? query.error
        }`,
      });
    }
  }, [query.isError, query.error]);

  return {
    ...query,
  };
};

export default useUpdateUser;
