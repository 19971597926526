import { LettersOfIntentListProvider } from "context/letterOfIntent/lettersOfIntentList/lettersOfIntentListContext";
import { TableProvider } from "context/tableContext/tableContext";
import LettersOfIntentListIndex from "./LettersOfIntentListIndex";

export default function LettersOfIntentList() {
  return (
    <LettersOfIntentListProvider>
      <TableProvider>
        <LettersOfIntentListIndex />
      </TableProvider>
    </LettersOfIntentListProvider>
  );
}
