import { useMemo, useState } from "react";
import moment from "moment";

import { ButtonBase, SvgIcon } from "@material-ui/core";

import TableNewDesign from "Components/Admin/UIComponents/NewTable/TableNewDesign";
import { setForm, setShowDeleteModal } from "context/tagContext/tagActions";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditButton } from "assets/icons/edit.svg";
import { setCurrentTabValue } from "context/tabContext/tabActions";
import { useTag } from "context/tagContext/tagContext";
import { useTab } from "context/tabContext/tabContext";
import { Tag } from "context/tagContext/tagTypes";

import useStyles from "./useTagsTableStyles";
import DeleteModal from "./DeleteModal";

function TagsTable() {
  const { state, dispatch: tagDispatch } = useTag();
  const { dispatch: tabDispatch } = useTab();
  const [currentTag, setCurrentTag] = useState<Tag>(null);
  const classes = useStyles();

  const isFiltered: boolean = useMemo(
    () => Object.values(state.filters).every(Boolean),
    [state.filters]
  );

  const handleDelete = (tag: Tag) => {
    setCurrentTag(tag);
    tagDispatch(setShowDeleteModal(true));
  };

  const handleEdit = (tag: Tag) => {
    const beginDate = moment(tag.beginDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const dueDate = moment(tag.dueDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    tagDispatch(setForm({ ...tag, beginDate, dueDate, ids: [state.filters.id] }));
    tabDispatch(setCurrentTabValue("create"));
  };

  const columnsConfig = {
    sortable: false,
    filterable: false,
    flex: 5,
  };

  const columns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "name",
        headerName: "Tags marketplace",
        flex: 3,
      },
      {
        ...columnsConfig,
        field: "beginDate",
        headerName: "Inicio",
        flex: 2,
      },

      {
        ...columnsConfig,
        field: "dueDate",
        headerName: "Fin",
        flex: 2,
      },
      {
        ...columnsConfig,
        field: "description",
        headerName: "Información Tag",
        flex: 5,
      },
      {
        ...columnsConfig,
        field: "actions",
        headerName: " ",
        headerClassName: "hideColumnLine",
        flex: 2,
        renderCell: (params) => (
          <>
            <ButtonBase
              onClick={() => {
                handleEdit(params.row);
              }}>
              <SvgIcon component={EditButton} fontSize="medium" className={classes.icon} />
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                handleDelete(params.row);
              }}>
              <SvgIcon component={TrashIcon} fontSize="medium" className={classes.icon} />
            </ButtonBase>
          </>
        ),
      },
    ],
    [state.tags.data]
  );
  return (
    <>
      <TableNewDesign
        columns={columns}
        data={state.tags.data}
        loading={state.tags.loading}
        type="tags"
        filtered={isFiltered}
        oli={false}
        mobileCard={<div></div>}
      />
      <DeleteModal tag={currentTag} />
    </>
  );
}

export default TagsTable;
