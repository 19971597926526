import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import UpdatingDisclaimer from "../../../components/UpdatingDisclaimer";
import useCommonExpensesFooterStyles from "./CommonExpensesFooterStyles";

interface Props {
  hasCommonExpenses?: boolean;
  updating: boolean;
  updateLoading: boolean;
  updateServices: () => void;
}

const CommonExpensesFooter = ({
  hasCommonExpenses = false,
  updating,
  updateLoading,
  updateServices,
}: Props) => {
  const classes = useCommonExpensesFooterStyles();

  return (
    <Box className={classes.commonExpensesFooterRoot}>
      {updating && <UpdatingDisclaimer />}
      {hasCommonExpenses && (
        <DesignSystemButton
          variant="secondary"
          label="Actualizar"
          size="small"
          onClick={updateServices}
          isLoading={updateLoading}
          disabled={updating}
        />
      )}
    </Box>
  );
};

export default CommonExpensesFooter;
