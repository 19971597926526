import { useAuthClient } from "libs/auth";
import { RolesAndPermissionsSchema } from "../models/RolesAndPermissions";
import { MS_AUTH_URL } from "env";
import { useQuery } from "react-query";

export default function useFetchRolesAndPermissions() {
  const { client, status } = useAuthClient();
  return useQuery(
    "user-roles-permissions",
    async () => {
      const { data } = await client.get(`${MS_AUTH_URL}/self`);
      const parsedData = RolesAndPermissionsSchema.parse(data);
      return parsedData;
    },
    {
      enabled: status === "success",
      // refetchOnWindowFocus: true,
      // refetchOnMount: true,
    }
  );
}
