import { Box, Typography } from "@material-ui/core";
import usePersonsCardStyles from "./PersonsCardStyles";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import PersonCard from "./PersonCard";
import { PersonTypes } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";

function PersonsCard() {
  const classes = usePersonsCardStyles();
  const { state } = useLetterOfIntent();
  const { bidders, owners, country } = state;

  if (!bidders || !owners) return null;

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Personas involucradas</Typography>

      <Typography className={classes.subtitle}>Propietario(s)</Typography>
      {owners?.map((owner) => (
        <PersonCard person={owner} type={PersonTypes.OWNER} country={country} />
      ))}

      <br />

      <Typography className={classes.subtitle}>Comprador(es)</Typography>
      {bidders?.map((bidder) => (
        <PersonCard person={bidder} type={PersonTypes.BIDDER} country={country} />
      ))}
    </Box>
  );
}

export default PersonsCard;
