import { Grid } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useFilterStyles from "./useFilterStyles";
import CustomButton from "Components/Admin/UIComponents/Buttons/CustomButton";
import { Filters } from "context/controlTower/controlTowerTypes";
import { Countries, Roles } from "./TableConsts";
import { ControlledTextField, ControlledList } from "Components/Admin/UIComponents/ControlledForm";

import validationSchema, { requiredText } from "./validationSchema";
import { useDashboard } from "context/controlTower/controlTowerContext";
import { useFormNotifications } from "hooks";
import { filterData } from "context/controlTower/controlTowerActions";

function FiltersForm() {
  const { state, dispatch } = useDashboard();
  const classes = useFilterStyles();
  const methods = useForm<Filters>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<Filters> = (data, e) => {
    filterData(dispatch, data, state.appraisers);
  };

  const { errors } = methods.formState;

  useFormNotifications<Filters>({ errors, requiredText });

  return (
    <FormProvider {...methods} key="DashboardProvider">
      <form key="newAppraiserForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={3} className={classes.underlined}>
            <ControlledTextField id="name" label="Nombre" />
          </Grid>
          <Grid item xs={2}>
            <ControlledList id="country" options={Countries} label="País" defaultValue="" />
          </Grid>
          <Grid item xs={2}>
            <ControlledList id="roles" options={Roles} label="Rol" defaultValue="" />
          </Grid>
          <Grid item xs={2}>
            <CustomButton kind="main" active className={classes.submitButton} type="submit">
              Filtrar
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default FiltersForm;
