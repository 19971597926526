import { TabProvider } from "context/tabContext/tabContext";
import { TagProvider } from "context/tagContext/tagContext";

import Tags from "./TagsIndex";

function TagsFormIndex() {
  return (
    <TagProvider>
      <TabProvider>
        <Tags />
      </TabProvider>
    </TagProvider>
  );
}

export default TagsFormIndex;
