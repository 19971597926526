import { makeStyles } from "@material-ui/core";

const useFilterBarStyles = makeStyles((theme) => ({
  container: {
    height: "32px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(3, 0),
    alignItems: "center",
  },
  searchInputContainer: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "60%",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
}));
export default useFilterBarStyles;
