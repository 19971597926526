import { memo } from "react";

import TabMenu from "Components/Admin/UIComponents/Tabs/TabMenu";
import { StringOption } from "models/Option";

function Menu() {
  const options: StringOption[] = [
    { label: "Contacto", value: "contacto" },
    { label: "Agendar", value: "agendar" },
  ];
  return <TabMenu options={options} defaultValue={options[0].value} />;
}
export default memo(Menu);
