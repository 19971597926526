import { ReactElement } from "react";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import UserForm from "Components/Admin/UIComponents/ProjectForm/UserForm";
import { useProject } from "context/projectContext/projectContext";
import { setUserModal } from "context/projectContext/projectActions";

function NewUserDialog(): ReactElement {
  const { state, dispatch } = useProject();

  const closeModal = () => {
    dispatch(setUserModal(false));
  };

  return (
    <CustomDialog open={state.openUserModal} onClose={closeModal} title="Crear inmobiliaria">
      <UserForm />
    </CustomDialog>
  );
}

export default NewUserDialog;
