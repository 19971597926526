import Swal from "sweetalert2";
import { toLower } from "lodash";
import { AxiosResponse } from "axios";

import { AppraiserProfile } from "models/Appraiser";
import SchedulesService from "services/schedulesService";
import AppraiserService from "services/appraiserService";

import {
  Action,
  Dispatch,
  SummaryData,
  BarChartInputData,
  Appraiser,
  CalendarEvent,
  SET_TODAY_DATA,
  SET_TOMORROW_DATA,
  SET_SUMMARY_DATA,
  SET_LOADING_SUMMARY,
  SET_LOADING_CHARTS,
  SET_LOADING_TABLE,
  SET_APPRAISERS,
  SET_APPRAISERS_COUNT,
  SET_APPRAISER_EVENTS,
  SET_LOADING_EVENTS,
  SET_APPRAISER_PROFILE,
  SET_SHOW_MODAL,
  SET_FILTERS,
  Filters,
  SET_LOADING_CREATE,
  SET_LOADING_DATA,
  SET_SCHEDULE,
  NewAvailability,
  SET_LOADING_UPDATE,
  SET_FILTERED_APPRAISERS,
  SET_LOADING_PHOTO,
  SET_CONFIG_MODAL,
  ConfigModal,
  EditProfileForm,
  SET_MACROZONES,
  NewAppraiserForm,
  SET_TRIGGER,
} from "./controlTowerTypes";
import { getLastDayInArray, parseNewAvailability } from "utils/datetime";
import {
  AvailabilityResponse,
  NewAppraiserAvailabilityBody,
} from "services/appraiserService/types";
import { CountryUpperCase, Timezones } from "models/Countries";
import { parseMacrozoneFromReq } from "./controlTowerUtils";
import { StringOption } from "models/Option";
import { PhoneInput } from "models/PhoneInput";

const activeFilter = (element: Appraiser, key: string, filterValue: string) => {
  if (!filterValue || filterValue.length <= 0) return true;
  else if (key === "roles") return element[key].indexOf(filterValue) !== -1;
  return toLower(element[key]).includes(toLower(filterValue));
};

export const setConfigModal = (configModal: ConfigModal): Action => ({
  type: SET_CONFIG_MODAL,
  configModal,
});

export const setMacrozones = (macrozones: StringOption[]): Action => ({
  type: SET_MACROZONES,
  macrozones,
});

export const setTodayData = (todayData: BarChartInputData): Action => ({
  type: SET_TODAY_DATA,
  todayData,
});

export const setTomorrowData = (tomorrowData: BarChartInputData): Action => ({
  type: SET_TOMORROW_DATA,
  tomorrowData,
});

export const setSummaryData = (summaryData: SummaryData): Action => ({
  type: SET_SUMMARY_DATA,
  summaryData,
});

export const setLoadingCharts = (loadingCharts: boolean): Action => ({
  type: SET_LOADING_CHARTS,
  loadingCharts,
});

export const setLoadingSummary = (loadingSummary: boolean): Action => ({
  type: SET_LOADING_SUMMARY,
  loadingSummary,
});

export const setLoadingTable = (loadingTable: boolean): Action => ({
  type: SET_LOADING_TABLE,
  loadingTable,
});

export const setAppraisers = (appraisers: Appraiser[]): Action => ({
  type: SET_APPRAISERS,
  appraisers,
});

export const setAppraisersCount = (appraisersCount: number): Action => ({
  type: SET_APPRAISERS_COUNT,
  appraisersCount,
});

export const setAppraiserEvents = (appraiserEvents: CalendarEvent[]): Action => ({
  type: SET_APPRAISER_EVENTS,
  appraiserEvents,
});

export const setEventsLoading = (loadingEvents: boolean): Action => ({
  type: SET_LOADING_EVENTS,
  loadingEvents,
});

export const setAppraiserProfile = (profile: AppraiserProfile): Action => ({
  type: SET_APPRAISER_PROFILE,
  profile,
});

export const setIsOpenEditModal = (isOpenEditModal: boolean): Action => ({
  type: SET_SHOW_MODAL,
  isOpenEditModal,
});

export const setFilters = (filters: Filters): Action => ({
  type: SET_FILTERS,
  filters,
});

export const setLoadingData = (loadingAvailability: boolean): Action => ({
  type: SET_LOADING_DATA,
  loadingAvailability,
});

export const setAvalability = (availability: NewAvailability): Action => ({
  type: SET_SCHEDULE,
  availability,
});

export const setLoadingUpdate = (loadingUpdate: boolean): Action => ({
  type: SET_LOADING_UPDATE,
  loadingUpdate,
});

export const setLoadingCreate = (loadingCreate: boolean): Action => ({
  type: SET_LOADING_CREATE,
  loadingCreate,
});

export const setTrigger = (): Action => ({
  type: SET_TRIGGER,
});

export const setFilteredAppraisers = (filteredAppraisers: Appraiser[]): Action => ({
  type: SET_FILTERED_APPRAISERS,
  filteredAppraisers,
});

export const setLoadingPhoto = (loadingPhoto: boolean): Action => ({
  type: SET_LOADING_PHOTO,
  loadingPhoto,
});

export const getChartsData = async (token: string, dispatch: Dispatch) => {
  try {
    const fetchedToday = await SchedulesService.todayStats(token);
    const fetchedTomorrow = await SchedulesService.tomorrowStats(token);
    if (fetchedToday && fetchedTomorrow) {
      dispatch(
        setTodayData({
          cancelled: fetchedToday.data.cancelled,
          pending: fetchedToday.data.confirmed,
          done: fetchedToday.data.done,
        })
      );
      dispatch(
        setTomorrowData({
          cancelled: fetchedTomorrow.data.cancelled,
          pending: fetchedTomorrow.data.pending,
          done: fetchedTomorrow.data.confirmed,
        })
      );
      dispatch(setLoadingCharts(false));
    } else {
      dispatch(setLoadingCharts(false));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSummaryData = async (token: string, dispatch: Dispatch) => {
  try {
    const fetchedStatistics = await SchedulesService.generalSummary(token);

    if (fetchedStatistics) {
      dispatch(
        setSummaryData({
          activeAppraisers: fetchedStatistics.data.working_appraisers,
          activePhotographers: fetchedStatistics.data.working_photographers,
          availableHours: fetchedStatistics.data.total_availability,
        })
      );
      dispatch(setLoadingSummary(false));
    } else {
      dispatch(setLoadingSummary(false));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAppraisers = async (token: string, dispatch: Dispatch) => {
  try {
    const fetchedAppraisers = await SchedulesService.fetchAppraisers(token);

    if (fetchedAppraisers) {
      dispatch(setAppraisersCount(fetchedAppraisers.data.length));
      dispatch(setAppraisers(fetchedAppraisers.data));
      dispatch(setLoadingTable(false));

      dispatch(setLoadingTable(false));
    } else {
      dispatch(setLoadingTable(false));
    }
  } catch (error) {
    console.error(error);
  }
};

export const filterData = async (
  dispatch: Dispatch,
  filters: Filters,
  fetchedAppraisers: Appraiser[]
) => {
  try {
    const filteredAppraisers = await fetchedAppraisers.filter(
      (element) =>
        activeFilter(element, "name", filters.name) &&
        activeFilter(element, "country", filters.country) &&
        activeFilter(element, "roles", filters.roles)
    );

    if (filteredAppraisers) {
      dispatch(setAppraisersCount(filteredAppraisers.length));
      dispatch(setFilteredAppraisers(filteredAppraisers));
    }
    dispatch(setLoadingTable(false));
  } catch (error) {
    dispatch(setLoadingTable(false));
    console.error(error);
  }
};

export const getAppraiserEvents = async (
  token: string,
  appraiserId: string,
  dispatch: Dispatch
) => {
  dispatch(setEventsLoading(true));
  try {
    const fetchedAppraiserEvents = await AppraiserService.allSchedules(token, appraiserId);

    if (fetchedAppraiserEvents) {
      dispatch(setAppraiserEvents(fetchedAppraiserEvents.data.schedules));
      dispatch(setEventsLoading(false));
    }
  } catch (error) {
    dispatch(setEventsLoading(false));
    console.error(error);
  }
};

export const getAppraiserProfile = async (
  token: string,
  appraiserId: string,
  dispatch: Dispatch
) => {
  try {
    const fetchedAppraiserProfile = await AppraiserService.profile(token, appraiserId);
    if (fetchedAppraiserProfile) {
      dispatch(setAppraiserProfile(fetchedAppraiserProfile.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const createNewAppraiser = async (
  token: string,
  data: NewAppraiserForm,
  dispatch: Dispatch
) => {
  dispatch(setLoadingCreate(true));
  try {
    const isB2B = data.contract_type === "B2B";
    const dummyZone = 73;
    const allowedMc = JSON.stringify(isB2B ? [dummyZone] : data.macrozones);

    const postData = new FormData();
    postData.append("country", data.country);
    postData.append("doc_number", data.doc_number);
    postData.append("contract_type", data.contract_type);
    postData.append("doc_type", data.doc_type);
    postData.append("email", data.email);
    postData.append("last_name", data.last_name);
    postData.append("name", data.name);
    postData.append("phone", data.phone);
    postData.append("roles", JSON.stringify(data.roles));
    postData.append("username", data.username);
    postData.append("macrozone_ids", allowedMc);
    if (data.photo) postData.append("photo", data.photo);

    const createAppraiser = await AppraiserService.createAppraiser(postData, token);
    if (createAppraiser) {
      dispatch(setLoadingCreate(false));
      dispatch(
        setConfigModal({
          isOpen: true,
          title: "¡Houmer creado!",
          subtitle: "Houmer creado correctamente",
        })
      );
    }
  } catch (error) {
    dispatch(setLoadingCreate(false));
    dispatch(
      setConfigModal({
        isOpen: true,
        title: "¡Hubo un error creando al houmer!",
        subtitle: "",
      })
    );
  }
};

export const getAppraiserAvailability = async (
  id: string,
  dispatch: Dispatch,
  timezone: Timezones,
  token: string
) => {
  dispatch(setLoadingData(true));
  try {
    const appraiserScedule = (await AppraiserService.newAvailability(
      id,
      token
    )) as AxiosResponse<AvailabilityResponse>;
    const parsedAvailability = parseNewAvailability(
      appraiserScedule.data.availability,
      timezone,
      appraiserScedule.data.count
    );
    dispatch(setAvalability(parsedAvailability || { availability: [], count: 0 }));
    dispatch(setLoadingData(false));
  } catch (error) {
    dispatch(setLoadingData(false));
    Swal.fire({
      type: "error",
      title: "Ups!",
      text: "No se pudieron cargar los datos",
    });
  }
};

export const updateAppraiserSchedule = async (
  id: string,
  body: NewAppraiserAvailabilityBody,
  dispatch: Dispatch,
  token: string
) => {
  dispatch(setLoadingUpdate(true));
  try {
    const updatedSchedule = await AppraiserService.newUpdateAppraiserAvailability(id, body, token);

    if (updatedSchedule) {
      dispatch(
        setConfigModal({
          isOpen: true,
          subtitle: `${
            body.availability.length
          } Horas disponibles, última fecha habilitada es: ${getLastDayInArray(body)}`,
          title: "Horario Actualizado",
        })
      );
      dispatch(setTrigger());
    } else {
      dispatch(
        setConfigModal({
          isOpen: true,
          subtitle: "Vuelve a intentarlo en unos minutos.",
          title: "Ha ocurrido un error al actualizar los datos",
        })
      );
    }
  } catch {
    dispatch(
      setConfigModal({
        isOpen: true,
        subtitle: "Vuelve a intentarlo en unos minutos.",
        title: "Ha ocurrido un error al actualizar los datos",
      })
    );
  } finally {
    dispatch(setLoadingUpdate(false));
  }
};

export const updateAppraiserPhoto = async (
  id: string,
  photo: Blob,
  dispatch: Dispatch,
  token: string
) => {
  try {
    dispatch(
      setConfigModal({
        isOpen: true,
        subtitle: "Actualizando...",
        title: "Actualizando imagen",
      })
    );
    const data = new FormData();
    data.append("photo", photo);
    data.append("id", id);
    await AppraiserService.uploadAppraiserPhoto(data, token);
  } catch {
    dispatch(
      setConfigModal({
        isOpen: true,
        subtitle: "Ha ocurrido un error al actualizar la foto.",
        title: "Error",
      })
    );
  }
};

export const updateAppraiser = async (
  data: EditProfileForm,
  id: string,
  token: string,
  dispatch: Dispatch
) => {
  dispatch(
    setConfigModal({
      isOpen: true,
      title: "¡Estamos revisando la información!",
      subtitle: "Este proceso puede tardar varios segundos",
    })
  );

  try {
    const macrozoneIds = data.macrozones.map((mc) => Number(mc.value));
    const res = (await AppraiserService.updateAppraiserProfile(
      //last_name is required in the request
      {
        ...data,
        phone: (data.phone as PhoneInput).parsedPhone,
        macrozone_ids: macrozoneIds,
        last_name: "",
        roles: [...data.firstRol, ...data.secondRol],
      },
      id,
      token
    )) as AxiosResponse;
    if (res.status === 200) {
      await getAppraiserProfile(token, id, dispatch);
      dispatch(
        setConfigModal({
          isOpen: true,
          subtitle: "Verifíca los cambios realizados.",
          title: "Edición guardada",
        })
      );
    }
  } catch (err) {
    dispatch(
      setConfigModal({
        isOpen: true,
        title: "Ha ocurrido un error desconocido",
        subtitle: "Intenta nuevamente",
      })
    );
  } finally {
    dispatch(setIsOpenEditModal(false));
  }
};

export const getMacrozones = async (
  token: string,
  country: CountryUpperCase,
  dispatch: Dispatch,
  removeCountry: boolean = false
) => {
  try {
    const macrozones = (await SchedulesService.getMacrozones(
      token,
      country
    )) as unknown as AxiosResponse;
    const parsedMacroznes = parseMacrozoneFromReq(macrozones.data, removeCountry);
    dispatch(setMacrozones(parsedMacroznes));
  } catch {
    dispatch(
      setConfigModal({
        isOpen: true,
        title: "¡Hubo un error cargando las macrozonas!",
        subtitle: "",
      })
    );
    dispatch(setMacrozones([]));
  }
};
