import { makeStyles, Theme } from "@material-ui/core";

const useRequestsStyles = makeStyles<Theme>((theme) => ({
  cardDescription: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "100%",
  },
}));

export default useRequestsStyles;
