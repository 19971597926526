import React, { useEffect, useState, Fragment } from "react";
import classNames from "classnames";
import chunk from "lodash/chunk";

import { Divider, TablePagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import NoDataTableCard from "Components/Admin/UIComponents/Cards/NoDataTableCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { PaymentBreakdownModel } from "models/Payments";
import useStyles from "./TableNewDesignStyles";
import { optionsTable } from "./utils";

interface Props {
  columns: any;
  data: any;
  filtered?: boolean;
  loading: boolean;
  mobileCard: React.ReactElement;
  setFunction?: (breakdownContent: PaymentBreakdownModel, bankAccountFormOpen: boolean) => void;
  setSecondFunction?: () => void;
  onClickFunction?: (record: any) => void;
  type: string;
  oli?: boolean;
  rootstyle?: string;
  tableProps?: React.ComponentProps<typeof DataGrid>;
  checkboxSelection?: boolean;
  selectionModel?: any;
  onSelection?: (e: any) => void;
}

function TableNewDesign({ ...props }: Props) {
  const {
    columns,
    data,
    loading = true,
    type,
    oli = true,
    filtered = false,
    mobileCard,
    rootstyle,
    onClickFunction,
    checkboxSelection,
    selectionModel,
    onSelection,
    ...tableProps
  } = props;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [mobileData, setMobileData] = useState([]);

  useEffect(() => {
    setPage(0);
    setMobileData(chunk(data, rowsPerPage));
  }, [rowsPerPage, data]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sizeTable = () => {
    if (data === undefined) return 3;
    if (data.length) {
      const chunkProperties = chunk(data, rowsPerPage);
      if (page > chunkProperties.length - 1) {
        return chunkProperties[chunkProperties.length - 1].length;
      }
      return chunkProperties[page].length;
    }
    return 3;
  };

  const classes = useStyles({
    size: (sizeTable() + 1) * 96 || 100,
    properties: ["forRent", "forSale"].includes(type),
  });

  return (
    <>
      <div className={classes.desktopTable}>
        <div className={classes.containerTable}>
          <DataGrid
            classes={{
              root: classNames(classes.table, rootstyle),
            }}
            columns={columns}
            rows={data}
            page={page}
            pageSize={rowsPerPage}
            rowsPerPageOptions={[4, 10, 15]}
            pagination
            rowHeight={86}
            disableSelectionOnClick
            loading={loading}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              noRowsOverlay: {
                textTitle: optionsTable[`${type}`].textTitle,
                textSubtitle: !filtered
                  ? optionsTable[`${type}`].textSubtitle
                  : optionsTable[`${type}`].textFilter,
                oli,
                type,
              },
            }}
            hideFooter
            hideFooterPagination
            onRowClick={
              onClickFunction &&
              ((record) => {
                onClickFunction(record.row);
              })
            }
            checkboxSelection={checkboxSelection}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelection}
            {...tableProps}
          />
        </div>
      </div>
      <div className={classes.mobileTable}>
        <Loading loading={loading}>
          {mobileData.length ? (
            mobileData[page].map((item) => (
              <Fragment key={item.id}>
                {React.cloneElement(mobileCard, {
                  item,
                  propsData: props,
                  category: type,
                })}
              </Fragment>
            ))
          ) : (
            <NoDataTableCard
              textTitle={optionsTable[`${type}`].textTitle}
              textSubtitle={
                !filtered
                  ? optionsTable[`${type}`].textSubtitle
                  : optionsTable[`${type}`].textFilter
              }
            />
          )}
        </Loading>
      </div>
      <Divider />
      {data.length > 0 && (
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          rowsPerPageOptions={[5, 10, 15]}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      )}
    </>
  );
}

export default TableNewDesign;
