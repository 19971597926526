import Button from "@houm-com/ui/Button";

import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Grid, Typography } from "@material-ui/core";
import { useFeatureManager } from "@houm-com/feature-manager";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";
import { countryCode } from "utils";

import useCreateReservation from "../../hooks/useCreateReservation";
import { createReservationSchema } from "./utils/validationSchema";
import useCreateReservationStyles from "./CreateReservationStyles";
import { parseCreateReservation } from "./utils/utils";
import { FormData } from "./utils/types";

interface Props {
  user: UserModel;
  country: Locale;
  propertyId: number | null;
  applicantId: string | null;
}

const CreateReservation = ({ user, country, propertyId, applicantId }: Props) => {
  const { data: createReservationFlag, status: createReservationFlagStatus } =
    useFeatureManager("create-reservation");
  const isReservationButtonEnabled =
    createReservationFlagStatus === "success" && createReservationFlag.enabled;
  const parsedCountry = countryCode(country);
  const { isLoading, mutate } = useCreateReservation(parsedCountry);
  const classes = useCreateReservationStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(createReservationSchema),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => {
    if (!isReservationButtonEnabled || isLoading) return;
    const parsedData = parseCreateReservation(
      user,
      country,
      dataForm.paymentDate,
      propertyId,
      applicantId
    );
    mutate(parsedData);
  };

  return (
    <Box className={classes.createReservationContainer}>
      <Typography className={classes.subtitle}>Generar nuevo pago de reserva</Typography>
      <FormProvider {...methods}>
        <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item xs={12}>
              <ControlledKeyboardDatePicker
                id="paymentDate"
                label="Fecha de pago fijada"
                defaultValue={new Date()}
                dataPickerProps={{
                  format: "dd/MM/yyyy",
                  minDate: new Date(),
                }}
              />
            </Grid>
            {isReservationButtonEnabled && (
              <Grid item>
                <Button variant="primary" size="md" type="submit" isLoading={isLoading}>
                  Crear
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CreateReservation;
