import Grid from "@material-ui/core/Grid";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { Subscription as ISubscription } from "models/Subscription";

import SubscriptionTitle from "./components/SubscriptionTitle";
import SubscriptionInfo from "./components/SubscriptionInfo";
import Observation from "./components/Observation";
import LandlordVip from "./components/LandlordVip";
import ActionsContainer from "./components/ActionsContainer";

interface Props {
  quotationId: number | null;
  subscription: ISubscription | null;
}

const SubscriptionHead = ({ quotationId, subscription }: Props) => (
  <AdminCard withSpace={false}>
    <Grid container alignItems="flex-start">
      <Grid item container xs={7} spacing={2}>
        <SubscriptionTitle
          quotationId={quotationId}
          isLeased={subscription?.status === "LEASED"}
          isRenewal={
            subscription?.init_reason === "RENEWAL" || subscription?.contract?.renewal_at !== null
          }
          contractId={subscription?.contract?.id}
        />
        <SubscriptionInfo
          subscriptionId={subscription?.id}
          contractId={subscription?.contract?.id}
          propertyId={subscription?.property?.id}
          sellerId={subscription?.property?.pipedrive_owner?.id}
          executive={
            subscription?.administration_executive
              ? subscription?.administration_executive.name
              : "No asignado"
          }
        />
        <Observation
          observation={subscription?.observation ?? ""}
          subscriptionId={subscription?.id}
        />
      </Grid>
      <Grid item container xs={5} spacing={2} justifyContent="flex-end">
        <LandlordVip subscriptionId={subscription?.id} />
        <ActionsContainer subscription={subscription} />
      </Grid>
    </Grid>
  </AdminCard>
);

export default SubscriptionHead;
