import { Link } from "@material-ui/core";
import { ReactComponent as ArrowRight } from "assets/icons/arrows/arrow-right.svg";
import BaseField from "../../../BaseField";
import useTicketsFieldStyles from "./TicketsFieldStyles";

interface Props {
  url: string;
}

function TicketsField({ url }: Props) {
  const classes = useTicketsFieldStyles();

  return (
    <BaseField requiredData={url}>
      <Link href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
        Ver
        <ArrowRight className={classes.arrow} />
      </Link>
    </BaseField>
  );
}

export default TicketsField;
