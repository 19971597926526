import { makeStyles, Theme } from "@material-ui/core";

const useModalLayoutStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(22),
    lineHeight: 1.5,
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.4,
  },
  container: {
    padding: theme.spacing(1, 4),
  },
}));

export default useModalLayoutStyles;
