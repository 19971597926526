import {
  Action,
  State,
  SET_COUNTRY,
  SET_USERS,
  SET_PROFILE,
  SET_LOADING,
  UPDATE_SEGMENTATION,
  UPDATE_APPLICATION,
  UPDATE_VISITS,
  SET_VISITS,
} from "./usersTypes";

export const initialState: State = {
  country: "chile",
  loading: false,
  users: [],
  showModal: false,
  tableFilters: null,
  resultsCount: null,
  user: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_USERS: {
      return {
        ...state,
        users: action.payload.users,
        resultsCount: action.payload.resultCount,
        loading: false,
      };
    }
    case SET_PROFILE: {
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    }
    case SET_VISITS: {
      return {
        ...state,
        user: {
          ...state.user,
          visits: [...action.payload],
        },
      };
    }
    case UPDATE_SEGMENTATION: {
      return {
        ...state,
        user: {
          ...state.user,
          segmentations: state.user.segmentations.map((x, i) =>
            i === action.payload.index ? action.payload.segmentation : x
          ),
        },
      };
    }
    case UPDATE_APPLICATION: {
      return {
        ...state,
        user: {
          ...state.user,
          applications: state.user.applications.map((x, i) =>
            i === action.payload.index ? action.payload.application : x
          ),
        },
      };
    }
    case UPDATE_VISITS: {
      return {
        ...state,
        user: {
          ...state.user,
          visits: [...state.user.visits, ...action.payload],
        },
      };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
