import { useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { Box, Grid, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";

import usePersonCardStyles from "./PersonCardStyles";
import fields from "context/letterOfIntent/letterOfIntent/letterOfIntentFields";
import { useLetterOfIntent } from "context/letterOfIntent/letterOfIntent/letterOfintentContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Owner } from "context/letterOfIntent/letterOfIntent/letterOfIntentTypes";
import { editOwner } from "context/letterOfIntent/letterOfIntent/letterOfIntentActions";
import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { useGetToken } from "hooks";
import { parseField } from "context/letterOfIntent/letterOfIntent/utils";
import { CIVIL_STATUSES } from "context/loiFormContext/constants";
import { MARITAL_REGIMES } from "context/closureFormContext/constants";

const getFieldComponent = (
  field: string,
  value: string,
  disabled: boolean,
  classes: ClassNameMap<string>
): JSX.Element => {
  if (field === "civilStatus" || field === "conjugalRegime") {
    return (
      <ControlledSelection
        id={field}
        key={field}
        options={field === "civilStatus" ? CIVIL_STATUSES : MARITAL_REGIMES}
        defaultValue={value}
        compressed={true}
        simple={true}
        disabled={disabled}
        classes={{
          container: classes.textField,
          buttonList: classes.selectContainer,
        }}
      />
    );
  }
  return (
    <ControlledTextField
      label=""
      id={field}
      key={field}
      defaultValue={parseField(field, value) || ""}
      disabled={disabled}
      textFieldProps={{
        textFieldClassName: classes.textField,
        InputProps: {
          classes: {
            input: classes.inputField,
          },
        },
      }}
    />
  );
};

function PersonCard() {
  const [edit, setEdit] = useState(false);
  const classes = usePersonCardStyles();
  const { state, dispatch } = useLetterOfIntent();
  const { country, person } = state;
  const ownerFields = fields.owner;
  const { getToken } = useGetToken();

  const filter = (countries) => countries.includes(country);
  const filterFieldsKeys = Object.keys(ownerFields).filter((key) =>
    filter(ownerFields[key]().countries)
  );

  const methods = useForm<Owner>({
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<Owner> = (data) => {
    const ownerData = { ...person, ...data };
    setEdit(false);
    getToken().then((authToken) =>
      editOwner({
        owner: ownerData,
        country,
        userId: person.id,
        token: authToken,
        dispatch,
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.container}>
        <Box>
          <Typography className={classes.title}>Datos Personales</Typography>
          <Grid container spacing={1}>
            {filterFieldsKeys.map((key) => (
              <Grid item xs={12} md={6}>
                <Typography className={classes.label} key={key}>
                  {ownerFields[key]().label(country)}
                </Typography>
                {!edit ? (
                  <Typography className={classes.value} key={key}>
                    {parseField(key, person[key]) || "-"}
                  </Typography>
                ) : (
                  getFieldComponent(key, person[key], ownerFields[key]().disabledEdit, classes)
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
        {!edit ? (
          <DesignSystemButton
            label="Editar datos"
            size="small"
            variant="tertiaryB"
            buttonClassName={classes.button}
            onClick={() => setEdit(true)}
          />
        ) : (
          <Box className={classes.buttonContainer}>
            <DesignSystemButton
              label="Cancelar"
              size="small"
              variant="tertiaryB"
              buttonClassName={classes.button}
              onClick={() => setEdit(false)}
            />
            <DesignSystemButton label="Guardar" size="small" variant="primary" type="submit" />
          </Box>
        )}
      </form>
    </FormProvider>
  );
}

export default PersonCard;
