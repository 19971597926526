import { makeStyles, Theme } from "@material-ui/core";

const useRequestsTableStyles = makeStyles<Theme>((theme) => ({
  table: {
    border: 0,
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: 700,
    },
    "& .MuiDataGrid-columnsContainer": {
      borderRadius: "8px",
      backgroundColor: theme.palette.grey["50"],
      color: theme.palette.grey["500"],
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-cell": {
      paddingLeft: theme.spacing(2),
      borderBottomWidth: 0,
      borderTopWidth: 0,
    },
    "& .MuiDataGrid-row": {
      margin: theme.spacing(0.2, 0),
      transition: "0.3s",
      "&:hover": {
        fontWeight: 800,
        backgroundColor: "#F7F8FC",
      },
    },
  },
  observation: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: theme.typography.pxToRem(14),
  },
  observationText: {
    width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  photos: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    color: theme.palette.primary.main,
    fontWeight: 700,
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    fontSize: "1.2rem",
    cursor: "pointer",
  },
}));

export default useRequestsTableStyles;
