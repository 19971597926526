import { makeStyles, Theme } from "@material-ui/core";

const useHeaderInformationStyles = makeStyles((theme: Theme) => ({
  container: {
    dispay: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
    "& p": {
      fontSize: theme.typography.pxToRem(17),
      marginBottom: theme.spacing(1),
    },
  },
  titleRow: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
}));

export default useHeaderInformationStyles;
