import { useCallback } from "react";
import { ValidationError } from "yup";

const useYupValidationResolver = (validationSchema, shortValidation, isShorter) =>
  useCallback(
    async (data) => {
      try {
        let values;
        if (isShorter) {
          values = await shortValidation.validate(data, {
            abortEarly: false,
          });
        } else {
          values = await validationSchema.validate(data, {
            abortEarly: false,
          });
        }

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        if (errors instanceof ValidationError) {
          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors, currentError) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          };
        }
      }
    },
    [isShorter]
  );
export default useYupValidationResolver;
