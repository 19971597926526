import { useMemo } from "react";

import { GridColDef, GridColumns } from "@material-ui/x-grid";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import Toolbar from "./Toolbar";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { businessTypeTranslation } from "Components/Admin/administrator/integrations/utils/translations";
import useStyles from "Components/Admin/administrator/integrations/components/Table/TableStyles";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "left",
  sortable: false,
};

function Table() {
  const classes = useStyles();
  const { state } = useIntegration();
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "business_type",
        headerName: "Línea de negocio",
        renderCell: ({ row }) => businessTypeTranslation[row?.businessType],
      },
      {
        ...columnsConfig,
        field: "portal",
        headerName: "Portal",
        renderCell: ({ row }) => row?.portalName,
      },
      {
        ...columnsConfig,
        field: "portal_plan",
        headerName: "Destaque",
        renderCell: ({ row }) => row?.portalPlan,
      },
      {
        ...columnsConfig,
        field: "macrozone",
        headerName: "Macrozona",
        renderCell: ({ row }) => row?.macrozone,
      },
      {
        ...columnsConfig,
        field: "quota",
        headerName: "Cupos",
        renderCell: ({ row }) => row?.quota,
      },
      {
        ...columnsConfig,
        field: "published_stock",
        headerName: "Stock publicado",
        renderCell: ({ row }) => row?.publishedStock,
      },
      {
        ...columnsConfig,
        field: "percentage",
        headerName: "Porcentaje",
        renderCell: ({ row }) => row?.percentage,
      },
    ],
    [state.quotaByMacrozone.data]
  );

  return (
    <div className={classes.container}>
      <CustomDataGrid
        className={classes.table}
        rowCount={state.quotaByMacrozone.count}
        columns={columns}
        rows={state.quotaByMacrozone.data}
        loading={state.quotaByMacrozone.loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;
