import { Link } from "react-router-dom";
import { IoChevronBackOutline as BackIcon } from "@houm-com/ui-icons";

export default function ScreenHeader({
  title,
  description,
  backTo,
  backToLabel = "Volver",
  actions,
}: {
  title?: string;
  description?: string;
  backTo?: string;
  backToLabel?: string;
  actions?: React.ReactNode;
}) {
  return (
    <div className="mb-5 flex">
      <div>
        {backTo && (
          <Link to={backTo} className="flex gap-1 items-center">
            <BackIcon />
            <span>{backToLabel}</span>
          </Link>
        )}
        {title && <h1 className="text-h32">{title}</h1>}
        {description && <p className="text-p16">{description}</p>}
      </div>
      {actions && <div className="ml-auto">{actions}</div>}
    </div>
  );
}
