import { Typography } from "@material-ui/core";

import useUserById from "hooks/useUserById";
import { Locale } from "models/Countries";

import CreateReservation from "./components/CreateReservation";
import ReservationCard from "./components/ReservationCard";
import useGetReservation from "./hooks/useGetReservation";
import useReservationStyles from "./ReservationStyles";
import { AdminCard } from "../Cards/AdminCard";
import Refunds from "./components/Refunds";
import Loading from "../molecules/Loading";

interface ReservationProps {
  country: Locale;
  propertyId: number;
  userId: number;
  applicantId: string;
  cardClassName?: string;
}

const Reservation = ({
  country,
  propertyId,
  userId,
  applicantId,
  cardClassName,
}: ReservationProps) => {
  const classes = useReservationStyles();
  const { isLoadingUser, dataUser, isErrorUser } = useUserById(userId);
  const { isLoadingReservation, isErrorReservation, dataReservation } = useGetReservation(
    country,
    userId,
    propertyId
  );

  return (
    <AdminCard
      title="Reserva"
      className={cardClassName}
      showTitleBackground={true}
      withSpace={false}>
      <Loading loading={isLoadingUser || isLoadingReservation}>
        <>
          {isErrorReservation || isErrorUser ? (
            <Typography variant="body2" component="p" className={classes.noReservations}>
              Error al obtener reservas
            </Typography>
          ) : (
            <>
              <Typography className={classes.subtitle}>Pagos de reserva generados</Typography>
              {dataReservation.map((reservation) => (
                <ReservationCard
                  reservation={reservation}
                  key={reservation.movement_id}
                  country={country}
                  propertyId={propertyId}
                  user={dataUser}
                />
              ))}
            </>
          )}
          <Refunds country={country} propertyId={propertyId} user={dataUser} />
          <CreateReservation
            user={dataUser}
            applicantId={applicantId}
            country={country}
            propertyId={propertyId}
          />
        </>
      </Loading>
    </AdminCard>
  );
};

export default Reservation;
