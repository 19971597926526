import { useLastPaymentModal } from "Components/Admin/administrator/clients/Landlord/hooks";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import BaseField from "../../../BaseField";
import { BoldText } from "../../../typography";
import BreakdownModal from "./components/BreakdownModal";
import useLastPaymentStyles from "./LastPaymentStyles";

interface Props {
  propertyId: number;
  country: Locale;
}

function LastPayment({ propertyId, country }: Props) {
  const classes = useLastPaymentStyles();

  const { open, handleClose, handleOpen, isLoading, error, data } = useLastPaymentModal(
    propertyId,
    country
  );

  if (isLoading) return <Spinner size={20} />;

  return (
    <BaseField requiredData={data.total}>
      <BoldText className={classes.text} onClick={handleOpen}>
        {data.total}
      </BoldText>
      <BreakdownModal
        open={open}
        onClose={handleClose}
        isLoading={isLoading}
        error={error}
        {...data}
      />
    </BaseField>
  );
}

export default LastPayment;
