import Typography from "@material-ui/core/Typography";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import useStyles from "./LeadActivitiesHeaderStyles";
import { useLead } from "context/leadContext/leadContext";
import { showActivityModalForm } from "context/leadContext/leadActions";

function LeadActivitiesHeader() {
  const { dispatch } = useLead();
  const classes = useStyles();

  const handleClick = () => dispatch(showActivityModalForm("new"));

  return (
    <div className={classes.headerContainer}>
      <Typography className={classes.headerTitle}>Actividades / Seguimiento</Typography>
      <LinkButton onClick={handleClick}>Agregar Actividad</LinkButton>
    </div>
  );
}

export default LeadActivitiesHeader;
