import { useState } from "react";

import { Button, DialogActions, Typography } from "@material-ui/core";

import { countryCode, numberWithCommas } from "utils";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { CURRENCIES } from "context/negotiationsContext/constants";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import useStatusOfferStyles from "./StatusOfferStyles";
import { useGetToken } from "hooks";
import { acceptOfferAdmin } from "context/negotiationsContext/negotiationsActions";
import { ResponseAnswer, OfferStatus } from "context/negotiationsContext/negotiationsTypes";
import LinkButton from "Components/Admin/UIComponents/Buttons/LinkButton";
import classNames from "classnames";

interface AcceptOfferProps {
  offerStatus: string;
  offerId: string;
  country: string;
  handleRefresh: () => void;
}

export function AcceptOffer({ offerStatus, offerId, country, handleRefresh }: AcceptOfferProps) {
  const { getToken } = useGetToken();
  const code = countryCode(country);
  const classes = useStatusOfferStyles();

  const [showModal, setShowModal] = useState(null);
  const [comment, setComment] = useState(null);
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [file, setFile] = useState(null);

  const allowedMimeTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

  const acceptOffer = async () => {
    const response = {
      amount,
      currency,
      executive_comment: comment,
      response: ResponseAnswer.ACCEPTED,
    };
    getToken()
      .then((authToken) => acceptOfferAdmin({ country: code, offerId, response, file, authToken }))
      .then(() => handleRefresh());
  };

  const handleCloseModal = () => {
    setAmount(null);
    setCurrency(null);
    setComment(null);
    setFile(null);
    setShowModal(false);
  };

  const handleClick = () => {
    acceptOffer();
    handleCloseModal();
  };

  const format = (value) => numberWithCommas(value?.toString().replaceAll(".", "")) || "";

  const unformat = (value) => value?.toString().replaceAll(".", "");

  return (
    <>
      {offerStatus === OfferStatus.OPEN && (
        <LinkButton onClick={() => setShowModal(true)}>Aceptar</LinkButton>
      )}
      <CustomDialog
        open={showModal}
        onClose={handleCloseModal}
        title="Aceptar oferta"
        subtitle="Selecciona el valor final por el cual se esta aceptando la oferta."
        classes={{
          titleContainer: classes.titleContainer,
          title: classes.title,
          subtitle: classes.subtitle,
          content: classes.contentDialog,
        }}>
        <CustomTextField
          label="Valor final"
          placeholder="Ingresar"
          wrapperClassName={classes.wrapperField}
          textFieldClassName={classes.textField}
          value={format(amount)}
          onChange={(e) => setAmount(unformat(e.target.value))}
        />
        <Typography className={classes.label}>Tipo de moneda</Typography>
        <SelectionOptions
          id="currency"
          value={currency}
          onChange={(val) => setCurrency(val)}
          options={CURRENCIES[country]}
          compressed={true}
          fullWidth={true}
          simple={false}
        />
        <CustomTextField
          label="Observaciones"
          placeholder="Ingresar"
          wrapperClassName={classNames(classes.wrapperField, classes.comment)}
          textFieldClassName={classes.multilineTextField}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          multiline={true}
        />
        <Typography className={classes.label}>Subir documento de respaldo</Typography>
        <label htmlFor="backupfile">
          <input
            id="backupfile"
            className={classes.hidden}
            name="upload-file"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            accept={allowedMimeTypes.join()}
          />
          <Button
            color="primary"
            variant="outlined"
            component="span"
            className={classes.uploadButton}>
            Añadir archivo
          </Button>
          <span className={classes.helperText}>{file?.name}</span>
        </label>

        <Typography className={classes.text}>
          <Typography>
            Esta acción no puede deshacerse. Por favor verifica que es correcto que se quiere
            aceptar la oferta.
          </Typography>
          {` Una vez aceptado le enviaremos un correo al comprador para
          notificarle que su oferta fue aceptada.`}
        </Typography>
        <DialogActions>
          <DesignSystemButton
            label="Cancelar"
            size="medium"
            variant="tertiaryB"
            onClick={handleCloseModal}
          />
          <DesignSystemButton
            label="Aceptar"
            size="medium"
            variant="primary"
            disabled={!amount || !currency}
            onClick={handleClick}
          />
        </DialogActions>
      </CustomDialog>
    </>
  );
}
