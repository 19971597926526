import { Grid } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";

import { useTag } from "context/tagContext/tagContext";
import useStyles from "../useTagFormStyles";
import {
  ControlledCheckbox,
  ControlledDatePicker,
  ControlledList,
} from "Components/Admin/UIComponents/ControlledForm";

function TagDetails() {
  const classes = useStyles();
  const { state } = useTag();
  const { control } = useFormContext();

  const endLess = useWatch({
    control,
    name: "endLess",
    defaultValue: state?.form?.endLess ?? false,
  });

  return (
    <Grid item xs={8} container className={classes.tagDetails}>
      <Grid item>
        {state.tagTypes.length > 0 && (
          <ControlledList
            id="id"
            label="Nombre del Tag"
            options={state?.tagTypes}
            defaultValue={state?.form?.id || ""}
            classes={{
              select: classes.selectName,
              label: classes.selectLabel,
              wrap: classes.wrap,
            }}
            disableUnderline
            disabled={Boolean(state.form?.associationId)}
          />
        )}
      </Grid>
      <Grid item>
        <ControlledDatePicker
          label="Fecha inicio"
          id="beginDate"
          type="date"
          textFieldProps={{ className: classes.datePicker }}
          defaultValue={state?.form?.beginDate}
        />
        <ControlledCheckbox
          id="endLess"
          label="Solo cuenta con inicio"
          placement="end"
          size="small"
          justifyContent="flex-start"
          defaultChecked={endLess}
        />
      </Grid>
      {!endLess && (
        <Grid item>
          <ControlledDatePicker
            label="Fecha vencimiento"
            id="dueDate"
            type="date"
            textFieldProps={{ className: classes.datePicker }}
            defaultValue={state?.form?.dueDate}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default TagDetails;
