import { View, Text, Image } from "@react-pdf/renderer";

import Divider from "../assets/icons/rectangle-divider.png";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";

interface InspectionReportObservationProps {
  generalObservation: string;
}

const InspectionReportObservation = ({ generalObservation }: InspectionReportObservationProps) => {
  const styles = useInspectionReportDocumentStyles;

  return (
    <>
      <View style={styles.contentSection}>
        <Text style={styles.sectionTitle}>Observaciones Generales</Text>
        <Text style={styles.itemValue}>{generalObservation}</Text>
      </View>
      <Image src={Divider} style={styles.sectionDivider} />
    </>
  );
};

export default InspectionReportObservation;
