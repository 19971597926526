import {
  State,
  Action,
  SET_LEAD,
  SET_LOADING,
  SHOW_ACTIVITY_MODAL_FORM,
  HIDE_ACTIVITY_MODAL_FORM,
  SET_SELECTED_ACTIVITY,
  SET_LEAD_ACTIVITIES,
  SET_LOADING_ACTIVITIES,
  SET_SELECTED_LEAD,
  SET_LEAD_PROPERTIES,
  SET_LEAD_PROPERTIES_COUNT,
  SET_LEAD_AVAILABILITY,
} from "./leadTypes";

export const initialState: State = {
  loading: false,
  lead: null,
  showActivityFormModal: false,
  activityFormAction: null,
  selectedActivity: null,
  leadActivitiesInfo: null,
  selectedLeadId: null,
  leadProperties: [],
  leadPropertiesCount: null,
  selectedAvailability: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LEAD: {
      return {
        ...state,
        lead: action.lead,
        loading: false,
      };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_LOADING_ACTIVITIES: {
      return {
        ...state,
        leadActivitiesInfo: {
          ...state?.leadActivitiesInfo,
          isLoadingActivities: action.loadingActivities,
        },
      };
    }
    case SHOW_ACTIVITY_MODAL_FORM: {
      return {
        ...state,
        showActivityFormModal: true,
        activityFormAction: action.modalAction,
      };
    }
    case HIDE_ACTIVITY_MODAL_FORM: {
      return {
        ...state,
        showActivityFormModal: false,
        activityFormAction: null,
        selectedActivity: null,
      };
    }
    case SET_SELECTED_ACTIVITY: {
      return {
        ...state,
        selectedActivity: action.activity,
      };
    }
    case SET_LEAD_ACTIVITIES: {
      return {
        ...state,
        leadActivitiesInfo: action.leadActivitiesInfo,
      };
    }
    case SET_SELECTED_LEAD: {
      return { ...state, selectedLeadId: action.selectedLeadId };
    }
    case SET_LEAD_PROPERTIES: {
      return {
        ...state,
        leadProperties: action.properties,
        loading: false,
      };
    }
    case SET_LEAD_PROPERTIES_COUNT: {
      return {
        ...state,
        leadPropertiesCount: action.count,
      };
    }
    case SET_LEAD_AVAILABILITY: {
      return {
        ...state,
        selectedAvailability: action.selectedAvailability,
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
