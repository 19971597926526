import { useFormContext } from "react-hook-form";

import { Divider, Typography } from "@material-ui/core";

import ScheduleError from "Components/Admin/UIComponents/ScheduleError";
import { getAvailableText } from "context/eventContext/eventConst";
import { getTimezoneWithCommuneFormatted } from "utils/datetime";
import { useEvent } from "context/eventContext/eventContext";

import useRightColumnFormStyles from "./RightColumnFormStyles";
import PropertySearchBar from "./PropertySearchBar";
import ScheduleRange from "./ScheduleRange";

interface Props {
  isError: boolean;
  error: {
    message: string;
    extra?: {
      quotation_id?: number;
      schedule_type?: string;
      date?: string;
    };
  };
}

const RightColumnForm = ({ isError, error }: Props) => {
  const { watch } = useFormContext();

  const property = watch("property");
  const eventType = watch("event_type")?.value;

  const {
    state: { availability, property: defaultProperty },
  } = useEvent();
  const classes = useRightColumnFormStyles();

  return (
    <div className={classes.container}>
      {eventType && eventType !== "Advisory" && (
        <>
          <Typography gutterBottom variant="body1">
            {eventType?.includes("Photo")
              ? "Propiedad a agendar"
              : "Consulta la disponibilidad de la propiedad"}
          </Typography>
          <PropertySearchBar id="property" defaultValue={defaultProperty} />
        </>
      )}
      {property && (
        <div className={classes.timezoneText}>
          <Typography variant="body1">Zona horaria</Typography>
          <Typography variant="subtitle2" gutterBottom>
            {getTimezoneWithCommuneFormatted(property?.country, property?.comuna)}
          </Typography>
          <Divider />
        </div>
      )}
      {isError && <ScheduleError error={error} />}
      {availability && (
        <>
          <Typography variant="body1">{getAvailableText(eventType)}</Typography>
          <Divider />
          <ScheduleRange />
        </>
      )}
    </div>
  );
};

export default RightColumnForm;
