import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Fade from "@material-ui/core/Fade";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Spinner from "Components/Shared/partials/Spinner";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import { fetchData, setIsAdmin } from "context/closureFormContext/closureFormActions";
import { COUNTRY_STEPS } from "context/closureFormContext/constants";
import { useGetToken } from "hooks";

import ContractValues from "./components/ContractValues";
import ContractInfoCl from "./components/ContractInfoCl";
import { contractCategories } from "./utils/constants";
import useStyles from "./ContractInfoStyles";

export interface ContractInfoProps {
  headless?: boolean;
  MFUnitId?: string;
}

export default function ContractInfo({ headless = false, MFUnitId = null }: ContractInfoProps) {
  const classes = useStyles();
  const { state, dispatch } = useClosureForm();
  const history = useHistory();
  const { id: UrlId } = useParams<{ id: string }>();
  const id = MFUnitId || UrlId;
  const { getToken } = useGetToken();
  const path = useLocation().pathname;
  const [expand, setExpand] = useState({
    propertyData: false,
    landlordData: false,
    thirdPartyData: false,
    publicServices: false,
    adminData: false,
    fileLinks: false,
  });

  useEffect(() => {
    getToken().then((authToken) => {
      dispatch(setIsAdmin(path.includes("admin")));
      fetchData(id, authToken, dispatch);
    });
  }, []);

  const contractHeaders = useMemo(() => {
    if (state.propertyData.country) {
      return contractCategories.filter(({ key }) =>
        COUNTRY_STEPS[state.propertyData.country]?.includes(key)
      );
    }
    return [];
  }, [state.propertyData.country]);

  const handleExpand = (expandKey) => setExpand({ ...expand, [expandKey]: !expand[expandKey] });

  const handleEdit = (editKey) => {
    if (id) {
      if (state.isAdmin) history.push(`/admin/properties/${id}/closureForm?step=${editKey}`);
      else history.push(`/propietary/closureForm/${id}?step=${editKey}`);
    }
  };

  if (state.loading) return <Spinner className={classes.loading} />;

  if (state.propertyData.country === "Chile") return <ContractInfoCl />;

  return (
    <Grid container item xs={12} className={classes.componentContainer}>
      {!headless && <h1 className={classes.mainTitle}>Detalles del contrato</h1>}
      <Grid item xs={12}>
        {contractHeaders.map(({ key, label, Icon }) => (
          <Accordion
            onChange={() => handleExpand(key)}
            square={true}
            key={key}
            className={classes.accordion}>
            <AccordionSummary
              id={key}
              expandIcon={
                <ExpandMoreIcon
                  className={classNames({
                    [classes.expandIcon]: expand[key],
                  })}
                />
              }
              aria-controls={label}
              className={classes.accordionSummary}>
              <Icon
                className={classNames(classes.accordionIcon, {
                  [classes.expandIcon]: expand[key],
                })}
              />
              <Typography
                className={classNames({
                  [classes.expandLabel]: expand[key],
                })}>
                {label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Grid container spacing={2}>
                <Fade in={expand[key]} timeout={{ enter: 600, exit: 600 }}>
                  <Typography className={classes.editLink} onClick={() => handleEdit(key)}>
                    Editar datos
                  </Typography>
                </Fade>
                <ContractValues
                  step={key}
                  state={state[key]}
                  isAdmin={state.isAdmin}
                  country={state.propertyData.country}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Divider />
      </Grid>
    </Grid>
  );
}
