import Typography from "@material-ui/core/Typography";

import CountrySelector from "Components/Admin/UIComponents/molecules/CountrySelector";
import { useIntegration } from "context/integrationContext/integrationContext";
import { setCurrentCountry } from "context/integrationContext/integrationActions";
import { CountryUpperCase } from "models/Countries";
import { countryIntoCountryUpperCase, countryUpperCaseIntoCountry } from "utils/countryCode";
import useStyles from "./HeaderStyles";

interface Props {
  title: string;
}

function Header({ title }: Props) {
  const classes = useStyles();
  const { state, dispatch } = useIntegration();

  const handleChangeCountry = (country: CountryUpperCase): void => {
    dispatch(setCurrentCountry(countryUpperCaseIntoCountry[country]));
  };

  return (
    <div className={classes.root}>
      <Typography component="h2" className={classes.title}>
        {title}
      </Typography>
      <CountrySelector
        stateCountry={countryIntoCountryUpperCase[state.country]}
        onChange={handleChangeCountry}
      />
    </div>
  );
}

export default Header;
