import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    height: 4,
    borderRadius: 4,
  },
}));

export default useStyles;
