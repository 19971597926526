export const filterInitialValues = {
  businessType: null,
  city: null,
  macrozone: null,
  portal: null,
  portalPlan: null,
};

export interface QuotaByMacrozoneTableFilterTypes {
  businessType?: string;
  portal?: string;
  portalPlan?: string;
  city?: string;
  macrozone?: string;
}
