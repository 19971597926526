import { RESIZE_URL } from "env";
import {
  InspectionReportQualiBasedRating,
  inspectionReportFeaturesNameTranslation,
  inspectionReportGeneralSpaceTranslation,
  inspectionReportQualificationOptions,
} from "./translations";
import { getObjectLabel } from "utils";

import { GetReportActionsPages, GetResizeImageURL, GetSpaceQualificationPages } from "./types";

const REPORT_STATUS = {
  created: "Abierto",
  updated: "Actualizado",
  pre_quoted: "Precotizado",
  resolved: "Resuelto",
};

export const getResizeImageURL: GetResizeImageURL = (
  url,
  width = 220,
  height = 220,
  quality = 70,
  action = "cover"
) => {
  const requestQueryParams = new URLSearchParams({
    width: String(width),
    height: String(height),
    quality: String(quality),
    action,
  });
  const resizeUrl = `${RESIZE_URL}?filename=${url}&${requestQueryParams.toString()}`;
  return resizeUrl;
};

export const getSpaceQualificationPages: GetSpaceQualificationPages = (spaceQualificationList) =>
  spaceQualificationList.reduce((acc, space) => {
    const { name, qualification } = space;
    const spaceQualification = InspectionReportQualiBasedRating[qualification];
    return [
      ...acc,
      {
        spaceName: getObjectLabel(inspectionReportGeneralSpaceTranslation, name),
        qualification: getObjectLabel(inspectionReportQualificationOptions, qualification),
        qualificationStars: new Array(spaceQualification).fill(""),
        observation: space.observation,
        photos: space.photos,
      },
    ];
  }, []);

export const getReportActionsPages: GetReportActionsPages = (spaceQualificationList) =>
  spaceQualificationList.reduce((acc, space) => {
    const { name, reports } = space;
    const hasReports = reports.length;
    if (!hasReports) return acc;
    const spaceName = getObjectLabel(inspectionReportGeneralSpaceTranslation, name);
    const spaceReports = reports.reduce((acc, report) => {
      const { feature, status, reportActions, id: reportId } = report;
      const featureActions = reportActions.reduce((acc, action) => {
        const payload = {
          ...action,
          feature: getObjectLabel(inspectionReportFeaturesNameTranslation, feature),
          space: spaceName,
          reportStatus: REPORT_STATUS[status],
          createdAt: action.createdAt,
          reportId,
        };
        return [...acc, payload];
      }, []);
      return [...acc, ...featureActions];
    }, []);
    return [...acc, ...spaceReports];
  }, []);
