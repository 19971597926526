import currency from "@houm-com/formats/currency";
import { Locale } from "models/Countries";

export function isNumeric(str: string) {
  if (typeof str != "string") return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

const getCurrency = (country: Locale) => {
  switch (country) {
    case "cl":
      return "CLP";
    case "co":
      return "COP";
    case "mx":
    default:
      return "MXN";
  }
};

export const formatMoney = (number: number, country: Locale) =>
  currency(number, getCurrency(country), { locale: country });
