import { Typography } from "@material-ui/core";
import { MFImages } from "context/multifamilyContext/multifamily/multifamilyTypes";
import useStyles from "./ImagesStyles";

interface Props {
  title: string;
  images: MFImages[];
}

function Images({ images, title }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.imagesContainer}>
        {images.map((image, index) => (
          <img key={index} className={classes.image} src={image.url} alt={image.filename} />
        ))}
      </div>
    </div>
  );
}
export default Images;
