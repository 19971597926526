import { useQuery, UseQueryResult } from "react-query";

import contractService from "services/contractService";
import { useGetToken } from "hooks";

const useDocuments = (contractId: number) => {
  const { getToken } = useGetToken();

  const { isLoading, isError, data }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["contractDocs", contractId],
    async () => {
      const authToken = await getToken();
      const legacyContractDocs = await contractService.getContractDocuments(
        contractId,
        "contrato-de-arriendo",
        authToken
      );
      return legacyContractDocs.data;
    }
  );

  return {
    isError,
    isLoading,
    data,
  };
};

export default useDocuments;
