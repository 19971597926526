import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  cardRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1.25, 2),
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  address: {
    fontWeight: 700,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  dptoNumber: {
    textAlign: "center",
  },
  mapContainer: {
    height: 150,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));
