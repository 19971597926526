import { GridOverlay } from "@material-ui/x-grid";

import { MainText } from "../../../typography";

interface Props {
  content: string;
}

function NoRowsOverlay({ content }: Props) {
  return (
    <GridOverlay>
      <MainText>Este propietario no tiene {content}</MainText>
    </GridOverlay>
  );
}

export default NoRowsOverlay;
