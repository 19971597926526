import React, { ReactNode, useEffect, useRef, useState } from "react";

import { Paper, Popper, Typography } from "@material-ui/core";

import usePoperCellStyles from "./PoperCellStyles";

interface GridCellExpandProps {
  value: string | ReactNode;
  width: number;
}

const PoperCell = React.memo(({ width, value }: GridCellExpandProps) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = usePoperCellStyles({ width, wrapper });
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  function isOverflown(element: Element): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use "Esc"
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div ref={cellDiv} className={classes.text} />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          className={classes.popper}>
          <Paper elevation={1} className={classes.paper}>
            <Typography variant="body2" className={classes.valueText}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

export default PoperCell;
