import { useState } from "react";
import { capitalize } from "lodash";

import ConfirmDialog from "@houm-com/ui/ConfirmDialog";
import Button from "@houm-com/ui/Button";

import useReactivateSubscription from "../../hooks/useReactivateSubscription";

interface Props {
  contractId: number;
  subscriptionStatus: "FINISHED_PROPERLY" | "CANCELLED" | "NO_TENANT";
}

const ActivateSubscription = ({ contractId, subscriptionStatus }: Props) => {
  const action = subscriptionStatus === "NO_TENANT" ? "activar" : "reactivar";
  const [openDialog, setOpenDialog] = useState(false);
  const onClose = () => setOpenDialog(false);

  const { mutate, isLoading } = useReactivateSubscription(onClose);

  const onSubmit = () => {
    if (isLoading) return;
    mutate({ contractId });
  };

  return (
    <>
      <Button size="md" variant="primary" onClick={() => setOpenDialog(true)}>
        {capitalize(action)} subscripción
      </Button>
      <ConfirmDialog
        isOpen={openDialog}
        title={`¿Seguro que quieres ${action} esta suscripción?`}
        submitButtonLabel={`Si, ${action}`}
        cancelButtonLabel="Cancelar"
        onSubmit={onSubmit}
        onClose={onClose}
        submitButtonProps={{ isLoading, disabled: isLoading }}
      />
    </>
  );
};

export default ActivateSubscription;
