import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

import contractService from "services/contractService";
import { ContractModel } from "models/Contract";
import useGetToken from "hooks/useGetToken";
import { alert } from "libs/alerts";

const useEditContract = ({ contractId }: { contractId: number }) => {
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (formData: Partial<ContractModel>) => {
      const authToken = await getToken();
      const response = await contractService.update(contractId, formData, authToken);
      return response;
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Contrato actualizado",
          disclaimer: "El contrato ha sido editado exitosamente",
        });
        queryClient.invalidateQueries(["contract_data"]);
      },
      onError: (e: AxiosError) => {
        alert.error({
          message: "Error al actualizar contrato",
          disclaimer: `Hubo un error al intentar editar los datos de este contrato, error: ${e}`,
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useEditContract;
