import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    top: 0,
    right: "10%",
    padding: theme.spacing(1),
    borderRadius: 10,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[2],
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  priceSummaryContainer: {
    display: "flex",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    margin: theme.spacing(0.25, 0),
  },
  deal: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(13),
    width: 100,
  },
  price: {
    fontWeight: "inherit",
    fontSize: "inherit",
    marginRight: theme.spacing(0.5),
  },
  unit: {
    fontWeight: "inherit",
    fontSize: "inherit",
  },
}));

export default useStyles;
