import { TableProvider } from "context/tableContext/tableContext";
import DemandLeadIndex from "./components/DemandLeadIndex";
import { DemandLeadProvider } from "context/demandLeadContext/lead/demandLeadContext";
import { DemandScheduleProvider } from "context/demandLeadContext/schedule/demandScheduleContext";
import { ListProvider } from "context/demandLeadContext/list/listContext";
import { TabProvider } from "context/tabContext/tabContext";
import { SnackbarProvider } from "notistack";

function LeadIndex() {
  return (
    <DemandLeadProvider>
      <SnackbarProvider>
        <ListProvider>
          <DemandScheduleProvider>
            <TabProvider>
              <TableProvider>
                <DemandLeadIndex />
              </TableProvider>
            </TabProvider>
          </DemandScheduleProvider>
        </ListProvider>
      </SnackbarProvider>
    </DemandLeadProvider>
  );
}
export default LeadIndex;
