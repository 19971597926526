import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { IoAddOutline, IoCheckmarkOutline } from "@houm-com/ui-icons";

import useChangeDebtCollectionInsurance from "hooks/useChangeDebtCollectionInsurance";
import { IDebtCollectionUpdateInsurance } from "hooks/useChangeDebtCollectionInsurance/utils/types";

import { IDebtCollection } from "models/IDebtCollection";
import classes from "./InsuranceDataFormStyles";
import FieldInput from "@houm-com/ui/FieldInput";
import { validationSchema } from "./validationSchema";
import { format } from "date-fns";
import useClickOutside from "../../hooks/useClickOutside";
import IconButton from "@houm-com/ui/IconButton";

interface InsuranceDataFormProps {
  isDisabled: boolean;
  item: IDebtCollection;
}

interface FormProps {
  report_date: string;
  case_number: string;
}

interface AddButtonProps {
  enableEditMode: () => void;
  isDisabled: boolean;
}

interface ReportDateProps {
  reportDate: string;
  isDisabled: boolean;
  enableEditModeReportDate: () => void;
}

interface CaseNumberProps {
  caseNumber: string;
  isDisabled: boolean;
  enableEditModeCaseNumber: () => void;
}

const AddButton = ({ enableEditMode, isDisabled }: AddButtonProps) =>
  isDisabled ? null : (
    <IconButton
      icon={<IoAddOutline />}
      type="button"
      position="end"
      variant="tertiary"
      size="md"
      className={classes.button}
      onClick={enableEditMode}>
      Agregar
    </IconButton>
  );

const ReportDate = ({ reportDate, isDisabled, enableEditModeReportDate }: ReportDateProps) =>
  reportDate ? (
    <span className={!isDisabled ? classes.value : undefined} onClick={enableEditModeReportDate}>
      {format(new Date(reportDate), "dd/MM/yyyy")}
    </span>
  ) : (
    <AddButton isDisabled={isDisabled} enableEditMode={enableEditModeReportDate} />
  );

const CaseNumber = ({ caseNumber, isDisabled, enableEditModeCaseNumber }: CaseNumberProps) =>
  caseNumber ? (
    <span className={!isDisabled ? classes.value : undefined} onClick={enableEditModeCaseNumber}>
      {caseNumber}
    </span>
  ) : (
    <AddButton isDisabled={isDisabled} enableEditMode={enableEditModeCaseNumber} />
  );

export default function InsuranceDataForm({ isDisabled, item }: InsuranceDataFormProps) {
  const [isEditModeCaseNumber, setIsEditModeCaseNumber] = useState(false);
  const [isEditModeReportDate, setIsEditModeReportDate] = useState(false);
  const isEditMode = isEditModeCaseNumber || isEditModeReportDate;
  const containerRef = useRef();
  const { mutate } = useChangeDebtCollectionInsurance(
    item?.contractId?.toString(),
    item.insurance.companyCode
  );
  const methods = useForm<FormProps>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      report_date: item.insurance.reportDate,
      case_number: item?.insurance?.caseNumber?.toString(),
    },
  });
  const { errors } = methods.formState;
  const [reportDate, caseNumber] = methods.watch(["report_date", "case_number"]);
  const onSubmit = (data: IDebtCollectionUpdateInsurance) => {
    mutate(data);
    deactivateEditMode();
  };
  const enableEditModeCaseNumber = () => !isDisabled && setIsEditModeCaseNumber(true);
  const enableEditModeReportDate = () => !isDisabled && setIsEditModeReportDate(true);
  const deactivateEditMode = () => {
    setIsEditModeCaseNumber(false);
    setIsEditModeReportDate(false);
  };
  useClickOutside(containerRef, deactivateEditMode);

  return (
    <div ref={containerRef} className={classes.container}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <p className={classes.grayText(isDisabled)}>Fecha de reporte: </p>
            {isEditModeReportDate ? (
              <div className={classes.reportDateInput}>
                <FieldInput
                  id="report_date"
                  type="date"
                  errorMessage={errors.report_date?.message}
                  {...methods.register("report_date")}
                />
              </div>
            ) : (
              <ReportDate
                reportDate={reportDate}
                isDisabled={isDisabled}
                enableEditModeReportDate={enableEditModeReportDate}
              />
            )}
          </div>
          <div>
            <p className={classes.grayText(isDisabled)}>No caso: </p>
            {isEditModeCaseNumber ? (
              <FieldInput
                id="case_number"
                errorMessage={errors?.case_number?.message}
                placeholder="No caso:"
                {...methods.register("case_number")}
              />
            ) : (
              <CaseNumber
                caseNumber={caseNumber}
                isDisabled={isDisabled}
                enableEditModeCaseNumber={enableEditModeCaseNumber}
              />
            )}
          </div>
          {isEditMode && (
            <IconButton
              icon={<IoCheckmarkOutline />}
              type="submit"
              position="end"
              variant="tertiary"
              size="md"
              className={classes.submitButton}>
              Guardar
            </IconButton>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
