import { makeStyles, Theme } from "@material-ui/core/styles";

const useTagsTableStyles = makeStyles((theme: Theme) => ({
  icon: {
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
}));

export default useTagsTableStyles;
