import {
  Action,
  SET_LOADING,
  SET_CONTRACT_LIST,
  Dispatch,
  Filters,
  SET_FILTERS,
  SET_IS_OPEN_FILTER_MODAL,
} from "context/contractContext/contractTypes";

import contractService from "services/contractService";
import { parseContractList } from "./utils";

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setFilters = (filters: Filters): Action => ({
  type: SET_FILTERS,
  filters,
});

export const setIsOpenFilterModal = (isOpenFilterModal: boolean): Action => ({
  type: SET_IS_OPEN_FILTER_MODAL,
  isOpenFilterModal,
});

export const getContractList = async (
  pageSize: number,
  currentPage: number,
  status: string,
  country: string,
  authToken: string,
  filters: Filters,
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CONTRACT_LIST,
    payload: { contractList: [], count: 0 },
  });
  try {
    dispatch(setLoading(true));
    const contract = await contractService.index(
      pageSize,
      currentPage,
      status,
      country,
      authToken,
      filters
    );
    if (contract) {
      const contractsState = contract.data.results;
      const contractList = contractsState.map((singleContract) =>
        parseContractList(singleContract)
      );
      dispatch({
        type: SET_CONTRACT_LIST,
        payload: { contractList, count: contract.data.count },
      });
    } else {
      dispatch(setLoading(false));
    }
  } catch (err) {
    dispatch(setLoading(false));
    throw err;
  }
};
