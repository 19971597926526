import { makeStyles, Theme } from "@material-ui/core";

const useQuestionsCardStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "26px",
    marginBottom: theme.spacing(2),
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  pagesContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useQuestionsCardStyles;
