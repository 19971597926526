import { Button, SvgIcon } from "@material-ui/core";

import { ReactComponent as BackButtonIcon } from "assets/icons/arrows/chevron-back.svg";
import useStyles from "./BackButtonStyles";

interface Props {
  backButtonProps: {
    label: string;
    onClick: () => void;
    icon?: boolean;
  };
}

function BackButton({ backButtonProps }: Props) {
  const classes = useStyles();
  const { label, onClick, icon = true } = backButtonProps;

  return (
    <Button className={classes.backText} onClick={onClick}>
      {icon && (
        <SvgIcon className={classes.backIcon}>
          <BackButtonIcon />
        </SvgIcon>
      )}
      {label}
    </Button>
  );
}

export default BackButton;
