import { useForm, FormProvider } from "react-hook-form";

import { setFiles, setReady } from "context/closureFormContext/closureFormActions";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { FilesProps } from "context/closureFormContext/closureFormTypes";

import CallToAction from "./CustomCallToAction";
import { FilteredFileUpload } from "./FilteredComponents";

export default function PublicServicesStep() {
  const { state, dispatch } = useClosureForm();
  const stepFields = fields.files;

  const methods = useForm<FilesProps>({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    dispatch(setFiles({ ...state.files, ...data }));
    dispatch(setReady("fileLinks"));
  };

  const allowedMimeTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredFileUpload
          id="ccFile"
          fieldInfo={stepFields.ccFile()}
          allowedMimeTypes={allowedMimeTypes}
        />
        <FilteredFileUpload
          id="traditionLibertyFile"
          fieldInfo={stepFields.traditionLibertyFile()}
          allowedMimeTypes={allowedMimeTypes}
        />
        <FilteredFileUpload
          id="predialFile"
          fieldInfo={stepFields.predialFile()}
          allowedMimeTypes={allowedMimeTypes}
        />
        <FilteredFileUpload
          id="waterReceipt"
          fieldInfo={stepFields.waterReceipt()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="energyReceipt"
          fieldInfo={stepFields.energyReceipt()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="gasReceipt"
          fieldInfo={stepFields.gasReceipt()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="cleaningReceipt"
          fieldInfo={stepFields.cleaningReceipt()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="administrationReceipt"
          fieldInfo={stepFields.administrationReceipt()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="publicDeed"
          fieldInfo={stepFields.publicDeed()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <FilteredFileUpload
          id="ineFile"
          fieldInfo={stepFields.ineFile()}
          allowedMimeTypes={allowedMimeTypes}
        />
        <FilteredFileUpload
          id="csfFile"
          fieldInfo={stepFields.csfFile()}
          allowedMimeTypes={allowedMimeTypes}
        />
        <FilteredFileUpload
          id="predialVoucher"
          fieldInfo={stepFields.predialVoucher()}
          allowedMimeTypes={allowedMimeTypes}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
