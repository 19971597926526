import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  infoItemText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[600],
  },
}));

export default useStyles;
