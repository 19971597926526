import { MemberInformation } from "domains/evaluations/models/EvaluateForm";
import { IncomeTypeDicEnum } from "domains/evaluations/models/Evaluations";

const getSalaryYear = (monthSelected: number, lastMonthSelected: number) => {
  const currentYear = new Date().getFullYear();
  if (monthSelected > lastMonthSelected) return currentYear - 1;
  return currentYear;
};

const getOtherSalaries = (data: MemberInformation) => [
  ...(data?.otherIncomeMonth1
    ? [
        {
          month: Number(data.otherIncomeMonth1),
          amount: Number(data.otherIncomeAmount1),
          description: data?.otherIncomeDescription1 ?? "",
          type: IncomeTypeDicEnum.OTHER,
          year: getSalaryYear(Number(data.otherIncomeMonth1), Number(data.salaryIncomeMonth3)),
        },
      ]
    : []),
  ...(data?.otherIncomeMonth2
    ? [
        {
          month: Number(data.otherIncomeMonth2),
          amount: Number(data.otherIncomeAmount2),
          description: data?.otherIncomeDescription2 ?? "",
          type: IncomeTypeDicEnum.OTHER,
          year: getSalaryYear(Number(data.otherIncomeMonth2), Number(data.salaryIncomeMonth3)),
        },
      ]
    : []),
  ...(data?.otherIncomeMonth3
    ? [
        {
          month: Number(data.otherIncomeMonth3),
          amount: Number(data.otherIncomeAmount3),
          description: data?.otherIncomeDescription3 ?? "",
          type: IncomeTypeDicEnum.OTHER,
          year: getSalaryYear(Number(data.otherIncomeMonth3), Number(data.salaryIncomeMonth3)),
        },
      ]
    : []),
];

export const parserEvaluationInformation = (data: MemberInformation) => {
  const otherSalaries = getOtherSalaries(data);
  const incomeSalaries = [
    {
      month: Number(data.salaryIncomeMonth1),
      amount: Number(data.salaryIncomeAmount1),
      type: IncomeTypeDicEnum.SALARY,
      year: getSalaryYear(Number(data.salaryIncomeMonth1), Number(data.salaryIncomeMonth3)),
    },
    {
      month: Number(data.salaryIncomeMonth2),
      amount: Number(data.salaryIncomeAmount2),
      type: IncomeTypeDicEnum.SALARY,
      year: getSalaryYear(Number(data.salaryIncomeMonth2), Number(data.salaryIncomeMonth3)),
    },
    {
      month: Number(data.salaryIncomeMonth3),
      amount: Number(data.salaryIncomeAmount3),
      type: IncomeTypeDicEnum.SALARY,
      year: getSalaryYear(Number(data.salaryIncomeMonth3), Number(data.salaryIncomeMonth3)),
    },
  ];

  return {
    name: data.name,
    occupation: data.occupation,
    income_source_type: data.incomeSourceType,
    employment_contract_type: data.employmentContractType,
    ...(data.visaType && { visa_type: data.visaType }),
    employer: data.employer,
    nationality: data.nationality,
    comment: data.comment,
    address: data?.fullAddress
      ? {
          full_address: data.fullAddress,
        }
      : null,
    incomes: [...incomeSalaries, ...otherSalaries],
  };
};
