import { PropertyTableFilters } from "context/tableContext/tableTypes";
import { IUserAutoComplete, ParsedAddress } from "models";
import { IProject } from "models/Projects";
import { OrientationOptionsTypes } from "utils/orientation";

export type Dispatch = (action: Action) => void;

export const SET_IMAGE = "SET_IMAGE";
export const SET_LOADING_UPLOAD = "SET_LOADING_UPLOAD";
export const SET_SELECTED_LAYOUT = "SET_SELECTED_LAYOUT";
export const SET_LOCATION_OPTIONS = "SET_LOCATION_OPTIONS";
export const SET_COMMUNES = "SET_COMMUNES";
export const SET_USER_MODAL = "SET_USER_MODAL";
export const SET_LOADING = "SET_LOADING";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_USER = "SET_USER";
export const SET_IS_SHORTER_FORM = "SET_IS_SHORTER_FORM";
export const SET_CREATED_PROJECT = "SET_CREATED_PROJECT";
export const SET_SUCCESS_MODAL = "SET_SUCCESS_MODAL";
export const SET_ADDRESS = "SET_ADDRESS";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const SET_UNITS_UPLOAD_MODAL = "SET_UNITS_UPLOAD_MODAL";
export const SET_UNITS_UPLOAD_ERRORS = "SET_UNITS_UPLOAD_ERRORS";

export type Country = "chile" | "colombia" | "mexico";

export interface State {
  projects: Projects[];
  project?: IProject;
  loading: boolean;
  image: File[];
  loadingUpload: boolean;
  selectedLayout: NewLayoutForm;
  numberOfProperties: number | null;
  country: Country;
  isShorterForm: boolean;
  user: IUserAutoComplete;
  openUserModal: boolean;
  openSuccessModal?: boolean;
  openUnitsUploadModal?: boolean;
  unitsUploadErrors: UnitsUploadError[];
  neighborhoods: Options[];
  communes?: Options[];
  address: ParsedAddress | null;
}

interface setImage {
  type: typeof SET_IMAGE;
  image: File[];
}

interface Options {
  value: string;
  label: string;
}

interface setCreatedProject {
  type: typeof SET_CREATED_PROJECT;
  project: IProject;
}
interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface changeCountry {
  type: typeof CHANGE_COUNTRY;
  country: Country;
}

interface setSuccessModal {
  type: typeof SET_SUCCESS_MODAL;
  openSuccessModal: boolean;
}

interface setLoadingUpload {
  type: typeof SET_LOADING_UPLOAD;
  loadingUpload: boolean;
}

interface setSelectedLayout {
  type: typeof SET_SELECTED_LAYOUT;
  selectedLayout: NewLayoutForm;
}

interface setProjects {
  type: typeof SET_PROJECTS;
  payload: { projects: Projects[]; count: number };
}

interface setIsShorterForm {
  type: typeof SET_IS_SHORTER_FORM;
  isShorterForm: boolean;
}

interface setUser {
  type: typeof SET_USER;
  user: IUserAutoComplete;
}

interface setUserModal {
  type: typeof SET_USER_MODAL;
  openUserModal: boolean;
}

interface setUnitsUploadModal {
  type: typeof SET_UNITS_UPLOAD_MODAL;
  openUnitsUploadModal: boolean;
}

interface setUnitsUploadErrors {
  type: typeof SET_UNITS_UPLOAD_ERRORS;
  unitsUploadErrors: UnitsUploadError[];
}

interface setLocationOptions {
  type: typeof SET_LOCATION_OPTIONS;
  neighborhoods: Options[];
  communes: Options[];
}

interface setAddress {
  type: typeof SET_ADDRESS;
  address: ParsedAddress;
}

export interface NewLayoutForm {
  image: File | string;
  name: string;
  rooms: number;
  roomsOther?: string;
  bathrooms: number;
  bathroomsOther?: string;
  totalArea: number;
  usableArea: number;
  id?: number;
  studio?: boolean;
}

export interface NewUnitForm {
  id?: number;
  name: string;
  parking_spaces: number;
  has_storage: boolean;
  price: number;
  floor: number;
  orientation: OrientationOptionsTypes;
  status:
    | "available"
    | "other"
    | "reserved_by_houm"
    | "reserved_by_other"
    | "sold_by_houm"
    | "sold_by_other";
}

export interface NewUnitsUploadForm {
  files: File[];
}

export interface IUnitsUploadData {
  filename: string;
  development: Number;
}

interface Projects {
  id: string;
  published: string;
  createdAt: string;
  status: string;
  houmOwner: string;
  homeChecker: string;
  landlordEmail: string;
  link: string;
}

export type Action =
  | setImage
  | setLoadingUpload
  | setSelectedLayout
  | changeCountry
  | setLoading
  | setProjects
  | setIsShorterForm
  | setCreatedProject
  | setUser
  | setUserModal
  | setSuccessModal
  | setUnitsUploadModal
  | setUnitsUploadErrors
  | setLocationOptions
  | setAddress;

export interface Filters extends PropertyTableFilters {
  country?: string;
  ordering?: string;
  validity?: string;
  comuna?: string;
}

export interface UnitsUploadError {
  line: string;
  errors: string[];
}
