import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  container: { width: "100%", maxWidth: 800, marginTop: theme.spacing(1) },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(3, 0),
  },
}));
