import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { IUserBankAccountResponse } from "services/bankAccountService/types";
import Spinner from "Components/Shared/partials/Spinner";
import {
  ControlledList,
  ControlledNumber,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import useBankAccount from "hooks/useBankAccount";
import { localeToCountry } from "utils/countryCode";
import { useDocumentTypeList } from "hooks";
import { Locale } from "models/Countries";
import { accountTypes } from "utils";

import useBankAccountFormStyles from "./BankAccountFormStyles";
import useBankAccountList from "hooks/useBankAccountList";
import { bankAccountSchema } from "./utils/validationSchema";
import { FormData } from "./utils/types";

interface Props {
  country: Locale;
  bankAccount: IUserBankAccountResponse | null;
  handleClose: () => void;
  userId: number;
  propertyId: number;
}
const BankAccountForm = ({ country, bankAccount, userId, propertyId, handleClose }: Props) => {
  const { mutate, isLoading } = useBankAccount(
    !bankAccount,
    country,
    userId,
    propertyId,
    handleClose
  );
  const { documentTypeList, loading } = useDocumentTypeList(localeToCountry[country]);
  const { dataBankList, isLoadingBankList } = useBankAccountList(country);
  const classes = useBankAccountFormStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(bankAccountSchema(country, documentTypeList)),
    reValidateMode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormData> = (dataForm) => {
    const data = {
      ...dataForm,
      bank_id: dataBankList.find((bank) => bank.code.toString() === dataForm.bank_id).bank_id,
      account_number: dataForm.account_number.toString(),
      ...(!bankAccount && { identifier: propertyId.toString() }),
      ...(country === "co" && {
        document_type: documentTypeList.find((doc) => doc.value === Number(dataForm.document_type))
          .identifier,
      }),
      ...(country === "mx" && {
        clabe_number: dataForm.clabe_number.toString(),
        city: "Mexico",
        account_type: "BANK",
      }),
    };
    if (country === "mx") delete data.document_type;
    mutate(data);
  };

  if (isLoadingBankList || loading) return <Spinner />;

  const getDocumentType = () => {
    if (country === "mx") return 9;
    else
      return bankAccount?.document_type
        ? documentTypeList.find((doc) => doc.identifier === bankAccount?.document_type).value
        : "";
  };

  return (
    <FormProvider {...methods}>
      <form key="propertyScheduleForm" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ControlledTextField
              id="holder"
              label="Titular de la cuenta"
              textFieldProps={{ placeholder: "Ingresa nombre del titular" }}
              defaultValue={bankAccount?.holder ?? ""}
            />
            {country !== "cl" && (
              <ControlledList
                id="document_type"
                label="Tipo de identificación"
                options={documentTypeList}
                classes={{
                  label: classes.selectionLabel,
                  container: classes.selectionContainer,
                }}
                disableUnderline
                disabled={country === "mx"}
                defaultValue={getDocumentType()}
              />
            )}
            <ControlledTextField
              id="document"
              label="Documento"
              textFieldProps={{ placeholder: "Ingresa número de documento" }}
              defaultValue={bankAccount?.document ?? ""}
            />
            <ControlledList
              id="bank_id"
              label="Banco"
              options={dataBankList.map((type) => ({
                label: type.name,
                value: type.code.toString(),
              }))}
              classes={{
                label: classes.selectionLabel,
                container: classes.selectionContainer,
              }}
              disableUnderline
              defaultValue={bankAccount?.bank_code ?? ""}
            />
            {country !== "mx" && (
              <ControlledList
                id="account_type"
                label="Tipo de cuenta"
                options={accountTypes[localeToCountry[country]].map((type) => ({
                  label: type.name,
                  value: type.code,
                }))}
                classes={{
                  label: classes.selectionLabel,
                  container: classes.selectionContainer,
                }}
                disableUnderline
                defaultValue={bankAccount?.account_type ?? ""}
              />
            )}
            <ControlledTextField
              id="account_number"
              label="Número cuenta"
              textFieldProps={{ placeholder: "Ingresa número de cuenta" }}
              defaultValue={bankAccount?.account_number ?? ""}
            />
            {country === "mx" && (
              <ControlledNumber
                id="clabe_number"
                label="Cuenta clabe"
                textFieldProps={{
                  placeholder: "Ingresa número de cuenta clabe",
                }}
                defaultValue={bankAccount?.clabe_number ?? ""}
              />
            )}
            <ControlledTextField
              id="email"
              label="Email"
              textFieldProps={{ placeholder: "Ingresa email" }}
              defaultValue={bankAccount?.email ?? ""}
            />
          </Grid>
          <Grid item>
            <DesignSystemButton
              label="Guardar"
              type="submit"
              size="small"
              variant="primary"
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default BankAccountForm;
