import { useMemo } from "react";
import useFetchRolesAndPermissions from "./useFetchRolesAndPermissions";

export default function useGuard() {
  const { data: rolesAndPermissionsData, status } = useFetchRolesAndPermissions();
  const roles = useMemo(
    () => (rolesAndPermissionsData ? rolesAndPermissionsData.roles : []),
    [rolesAndPermissionsData]
  );
  const permissions = useMemo(
    () => (rolesAndPermissionsData ? rolesAndPermissionsData.permissions : []),
    [rolesAndPermissionsData]
  );

  const hasAllRoles = (requiredRoles: string[]) =>
    requiredRoles.every((role) => roles.includes(role));
  const hasSomeRoles = (requiredRoles: string[]) =>
    requiredRoles.some((role) => roles.includes(role));
  const hasAllPermissions = (requiredPermissions: string[]) =>
    requiredPermissions.every((permission) => permissions.includes(permission));
  const hasSomePermissions = (requiredPermissions: string[]) =>
    requiredPermissions.some((permission) => permissions.includes(permission));

  const guard = {
    permissions: {
      all: hasAllPermissions,
      any: hasSomePermissions,
    },
    roles: {
      all: hasAllRoles,
      any: hasSomeRoles,
    },
  };
  return {
    guard,
    status,
  };
}
