import { useHistory } from "react-router-dom";

import CustomCenteredModal from "Components/Admin/UIComponents/Modals/CustomCenteredModal";
import AdvisoryResponseInfo from "Components/Admin/administrator/demand/lead/components/modals/Advisory/AdvisoryResponseInfo";
import { useEvent } from "context/eventContext/eventContext";
import { setConfigModal, setAdvisoryResponse } from "context/eventContext/eventActions";

function MessageModal() {
  const history = useHistory();
  const {
    state: { configModal, advisoryResponse },
    dispatch,
  } = useEvent();

  const handleCloseModal = () => {
    if (configModal.reload) history.go(0);
    dispatch(setConfigModal({ isOpen: false }));
    dispatch(
      setAdvisoryResponse({
        appraiserId: "",
        appraiserName: "",
        scheduleId: null,
        daySchedule: null,
        leadEmail: null,
      })
    );
  };

  return (
    <CustomCenteredModal
      open={configModal.isOpen}
      onClose={handleCloseModal}
      title={configModal.title}
      subtitle={configModal.subtitle}
      showCloseButton={true}
      withLogo>
      {advisoryResponse?.scheduleId && (
        <AdvisoryResponseInfo
          appraiserName={advisoryResponse?.appraiserName}
          daySchedule={advisoryResponse?.daySchedule}
          leadEmail={advisoryResponse?.leadEmail}
          scheduleId={advisoryResponse?.scheduleId}
        />
      )}
    </CustomCenteredModal>
  );
}
export default MessageModal;
