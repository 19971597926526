import { makeStyles, Theme } from "@material-ui/core/styles";

const useNewAppraiserStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  propertyInformationCard: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    minHeight: 100,
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  underlined: {
    borderBottom: "1px solid gray",
    marginBottom: "0px",
  },
}));

export default useNewAppraiserStyles;
