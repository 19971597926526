import { Box } from "@material-ui/core";
import { GridSlotsComponent, XGridProps } from "@material-ui/x-grid";

import { CustomDataGrid } from "Components/Admin/UIComponents";

import NoRowsOverlay from "./components/NoRowsOverlay";
import ErrorOverlay from "./components/ErrorOverlay";
import useLandlordTableStyles from "./LandlordTableStyles";

interface Props extends Omit<XGridProps, "components"> {
  content: string;
  error: Error;
  components: GridSlotsComponent;
}

function LandlordTable({ content, error, components, ...props }: Props) {
  const classes = useLandlordTableStyles();
  const { columns } = props;

  if (columns?.length > 0) {
    columns[0].headerClassName = classes.firstHeaderCell;
    columns[columns.length - 1].headerClassName = classes.lastRenderedHeaderCell;
  }

  return (
    <Box className={classes.container}>
      <CustomDataGrid
        classes={{
          root: classes.root,
          columnHeader: classes.headerCell,
        }}
        disableColumnMenu={true}
        density="comfortable"
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          NoRowsOverlay: () =>
            error ? <ErrorOverlay error={error} /> : <NoRowsOverlay content={content} />,
          ...components,
        }}
        {...props}
      />
    </Box>
  );
}

export default LandlordTable;
