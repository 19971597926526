export const steps = [
  {
    link: "#",
    label: "Cierres",
  },
  {
    link: "/admin/plans",
    label: "Planes",
  },
];
