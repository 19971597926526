import { makeStyles, Theme } from "@material-ui/core";

const useSearchBarStyles = makeStyles((theme: Theme) => ({
  title: { color: theme.palette.grey[900] },
  suggestion: {
    fontSize: "0.6875rem",
    fontWeight: "bold",
    marginBottom: 0,
    color: theme.palette.primary.main,
  },
  suggestionName: {
    fontSize: "0.5625rem",
    color: theme.palette.primary.main,
  },
  textField: {
    font: "inherit",
    color: "currentColor",
    display: "block",
    background: "none",
    padding: theme.spacing(1),
    maxWidth: theme.breakpoints.values.md,
    outline: "none",
    border: "none",
  },
  textFieldContainer: {
    backgroundColor: "#F7F8FC",
    borderRadius: 3,
    border: "none",
    width: 545,
    height: 35,
  },
  suggestionContainer: {
    display: "block",
    position: "absolute",
    top: 35,
    width: "100%",
    boxShadow: "0px 3px 1px 1px #9E9E9E",
    backgroundColor: "white",
    fontWeight: 300,
    fontSize: "1rem",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopWidth: 0,
    maxHeight: 400,
    maxWidth: 545,
    zIndex: 2,
    overflowY: "auto",
  },
  suggestionContainerComponent: {
    backgroundColor: "#F7F8FC",
  },
}));

export default useSearchBarStyles;
