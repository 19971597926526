import * as yup from "yup";

export const wrongType = "Formato incorrecto";
export const requiredText = "Obligatorio";

export default yup.object({
  name: yup.string().typeError(wrongType),
  country: yup.string().typeError(wrongType),
  role: yup.string().typeError(wrongType),
});
