import FieldInput from "@houm-com/ui/FieldInput";

import { Locale } from "models/Countries";

import OtherLiquidationForm from "../OtherLiquidationForm";
import useAverageSalary from "./hook/useAverageSalary";
import SalaryForm from "../SalaryForm";

interface Props {
  country: Locale;
}

const LiquidationForm = ({ country }: Props) => {
  const { register, errors } = useAverageSalary({ country });

  return (
    <>
      <h2 className="text-h20 font-bold mt-5 mb-2">Liquidaciones</h2>
      <div className="flex gap-3 flex-col">
        <SalaryForm />
        <OtherLiquidationForm />
        <FieldInput
          id="confirmedSalary"
          label="Renta Líquida Confirmada"
          placeholder="$ 0"
          disabled
          errorMessage={errors.confirmedSalary?.message}
          {...register("confirmedSalary")}
        />
      </div>
    </>
  );
};

export default LiquidationForm;
