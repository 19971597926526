import { useState } from "react";

interface Props {
  defaultStep?: number;
  totalSteps: number;
}

const useSteps = ({ defaultStep, totalSteps }: Props) => {
  const [currentStep, setCurrentStep] = useState(defaultStep || 1);
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleNext = () => {
    if (currentStep < totalSteps + 1) {
      setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return { currentStep, handleNext, handleBack };
};

export default useSteps;
