import { useEffect, useState } from "react";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";

import Header from "Components/Admin/administrator/integrations/components/Header/Header";
import Table from "./components/Table";
import FilterOptions from "./components/FilterOptions";
import { useIntegration } from "context/integrationContext/integrationContext";
import { getExternalPortalPublications } from "context/integrationContext/integrationActions";
import { useTable } from "context/tableContext/tableContext";
import { IndexExternalPortalPublicationsFilters } from "services/integrationService/types";
import { useGetToken } from "hooks";
import { isEmpty } from "utils";

function ExternalPortalPublications() {
  const { state, dispatch } = useIntegration();
  const { state: tableStates } = useTable();
  const { getToken } = useGetToken();
  const { ordering, tableFilters, pageSize, currentPage } = tableStates;

  const [currentFilters, setCurrentFilters] = useState<
    Partial<IndexExternalPortalPublicationsFilters>
  >({
    page: currentPage,
    page_size: pageSize,
    country: state.country,
  });

  useEffect(() => {
    getToken().then((authToken) => {
      getExternalPortalPublications(currentFilters, dispatch, authToken);
    });
  }, []);

  useEffect(() => {
    const allFilters: IndexExternalPortalPublicationsFilters = {
      business_type: tableFilters.businessType,
      houm_owner: tableFilters.executive,
      company: tableFilters.company,
      country: state.country,
      error: tableFilters.error,
      portal: tableFilters.portal,
      property__id: tableFilters.search_query,
      page: currentPage,
      page_size: pageSize,
      publication_type: tableFilters.portalPlan,
      status: tableFilters.status,
      ordering,
    };
    const mappedFilters: IndexExternalPortalPublicationsFilters = omitBy(allFilters, isEmpty);

    if (isEqual(mappedFilters, currentFilters)) return;
    setCurrentFilters(mappedFilters);

    getToken().then((authToken) => {
      getExternalPortalPublications(mappedFilters, dispatch, authToken);
    });
  }, [state.country, ordering, currentPage, pageSize, tableFilters]);

  return (
    <>
      <Header title="Visualización publicaciones" />
      <Table />
      <FilterOptions />
    </>
  );
}

export default ExternalPortalPublications;
