import * as yup from "yup";

import { phoneObject } from "utils/yupValidators/phone";

const requiredText = "Campo obligatorio";
const emailText = "Debe ser un email";

export const thirdPartyCoordSchema = yup.object({
  third_party_coordination_person: yup
    .string()
    .required(requiredText)
    .transform((option) => option?.value)
    .typeError(requiredText),
  third_party_coordination_full_name: yup.string().required(requiredText).typeError(emailText),
  third_party_coordination_email: yup.string().nullable().email(emailText).typeError(""),
  phone: phoneObject.typeError("Número de celular inválido").required(requiredText),
});
