import { useMemo } from "react";

import { GridColDef, GridColumns } from "@material-ui/x-grid";

import CustomDataGrid from "Components/Admin/UIComponents/CustomDataGrid";
import { useIntegration } from "context/integrationContext/integrationContext";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import { setPlanEditionStates } from "context/integrationContext/integrationActions";
import { PlanQuota } from "models/Integration";
import { businessTypeTranslation } from "Components/Admin/administrator/integrations/utils/translations";
import useStyles from "Components/Admin/administrator/integrations/components/Table/TableStyles";
import CustomNumberField from "Components/Admin/UIComponents/CustomNumberField";

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
  align: "left",
  sortable: false,
};

function Table() {
  const classes = useStyles();
  const { state, dispatch: integrationDispatch } = useIntegration();
  const { isEditing, quotasOnEdition, quotasWithError } = state.planEditionStates;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  const appendQuotas = (id: number, value: number, quotas: PlanQuota[]) => {
    const newQuotas = [...quotas];
    const index = newQuotas.findIndex((quota) => quota.id === id);
    if (index >= 0) {
      newQuotas[index].total_slots = value;
    } else {
      newQuotas.push({ id, total_slots: value });
    }
    return newQuotas;
  };

  const removeQuotaError = (id: number, errors: number[]) => {
    const newErrors = errors.filter((error) => error !== id);
    return newErrors;
  };

  const handleChangeQuote = (id: number, value: number) => {
    if (typeof value !== "number") {
      return integrationDispatch(
        setPlanEditionStates({
          quotasWithError: [...quotasWithError, id],
        })
      );
    }

    integrationDispatch(
      setPlanEditionStates({
        quotasWithError: removeQuotaError(id, quotasWithError),
      })
    );

    const appendedQuotas = appendQuotas(id, value, quotasOnEdition);

    return integrationDispatch(
      setPlanEditionStates({
        quotasOnEdition: appendedQuotas,
      })
    );
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        ...columnsConfig,
        field: "portal",
        headerName: "Portal",
        renderCell: ({ row }) => row?.portalName,
      },
      {
        ...columnsConfig,
        field: "portal_plan",
        headerName: "Destaque",
        renderCell: ({ row }) => row?.portalPlan,
      },
      {
        ...columnsConfig,
        field: "business_type",
        headerName: "Línea de negocio",
        renderCell: ({ row }) => businessTypeTranslation[row?.businessType],
      },
      {
        ...columnsConfig,
        field: "macrozone_id",
        headerName: "ID de Macrozona",
        renderCell: ({ row }) => row?.macrozoneId,
      },
      {
        ...columnsConfig,
        field: "macrozone",
        headerName: "Macrozona",
        renderCell: ({ row }) => row?.macrozone,
      },
      {
        ...columnsConfig,
        field: "quota",
        headerName: "Cupos",
        flex: 0.7,
        renderCell: ({ row }) =>
          isEditing ? (
            <CustomNumberField
              id={row?.id}
              defaultValue={row?.quota}
              label=""
              error={quotasWithError.includes(row?.id)}
              errorMessage="Cantidad de cupos inválida"
              onValueChange={(values) => handleChangeQuote(row.id, values.floatValue)}
            />
          ) : (
            row?.quota
          ),
      },
    ],
    [state.planEdition.data, isEditing, quotasOnEdition, quotasWithError]
  );

  return (
    <div className={classes.container}>
      <CustomDataGrid
        className={classes.table}
        rowCount={state.planEdition.count}
        columns={columns}
        rows={state.planEdition.data}
        loading={state.planEdition.loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
      />
    </div>
  );
}

export default Table;
