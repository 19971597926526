import { Theme } from "@material-ui/core/styles/createTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: "0.5rem",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(2),
  },
  infoBoxRoot: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: theme.spacing(1),
  },
  reportDownloaderWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  unhabitableWarning: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "#DF9B99",
  },
  unhabitableWarningTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: "#900000",
  },
  unhabitableWarningIcon: {
    marginTop: theme.spacing(-0.2),
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
