import Tag from "@houm-com/ui/Tag";
import { EvaluationStatus, EvaluationStatusListEnum } from "domains/evaluations/models/Evaluations";
import { ComponentProps } from "react";

export const statusVariant: {
  [locale in EvaluationStatus]: ComponentProps<typeof Tag>["variant"];
} = {
  [EvaluationStatusListEnum.PENDING]: "info",
  [EvaluationStatusListEnum.APPROVED]: "success",
  [EvaluationStatusListEnum.REJECTED]: "error",
  [EvaluationStatusListEnum.CANCELLED]: "error",
  [EvaluationStatusListEnum.INCOMPLETE]: "warning",
  [EvaluationStatusListEnum.PARTIALLY_APPROVED]: "info",
};
