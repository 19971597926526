import { ReactElement, useState } from "react";

import { cn } from "@houm-com/ui/utils";
import { IoChevronForwardOutline as ExpandIcon } from "@houm-com/ui-icons";

import classes from "./AccordionStyles";

interface AccordionProps {
  title: string;
  children: ReactElement;
}

export default function Accordion({ title, children }: AccordionProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div>
      <div className={classes.container} onClick={() => setIsExpanded((prev) => !prev)}>
        <span className={classes.title}>{title}</span>
        <ExpandIcon
          className={cn(classes.expandIcon, {
            [classes.expandedIcon]: isExpanded,
          })}
        />
      </div>
      <div
        className={cn(classes.content, {
          [classes.expandedContent]: isExpanded,
          [classes.notExpandedContent]: !isExpanded,
        })}>
        {children}
      </div>
    </div>
  );
}
