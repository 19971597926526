/* eslint-disable camelcase */
import _ from "lodash";

import { CreateProjectForm, IProject, ModifyProjectForm } from "models/Projects";
import isEmpty from "./isEmpty";
import { parseProperty } from "utils";

export default (data: ModifyProjectForm & CreateProjectForm, project?: IProject) => {
  const {
    address_object,
    neighborhood,
    type,
    for_rental,
    for_sale,
    country,
    stratus,
    lessor_brokerage_commission,
    half_baths,
    has_swimming_pool,
    condominio,
    gym,
    has_playground,
    has_all_day_vigilance,
    has_party_room,
    has_games_room,
    has_cinema,
    has_sauna,
    has_steam_room,
    has_bbq_area,
    has_laundry,
    has_elevator,
    has_concierge,
    has_pet_area,
    has_gourmet_space,
    has_playroom,
    tipo_moneda,
    tipo_moneda_venta,
    m_terreno,
    m_construidos,
    terrace_size,
    loggia_size,
    dormitorios,
    en_suite,
    banos,
    servicios,
    estacionamientos,
    terraza,
    bodega,
    mascotas,
    calefaccion,
    curtain_hanger,
    furnished,
    house_extension,
    hot_water_system,
    gc,
    orientacion_object,
    orientation,
    brokerage_commission,
    valor,
    valor_venta,
    promotional_price,
    promotion_months,
    has_balcony,
    has_patio,
    kitchen_daily_dining_room,
    floors,
    guest_parking_spaces,
    association_name,
    user,
    custom_commune,
    has_private_roof_garden,
    has_roof_garden_amenities,
    street_number,
    coordinates,
    project_delivery_month,
    project_delivery_year,
    currency,
    first_deposit,
    installments,
    installments_value,
    notarial_costs,
    parking_price,
    reservation_value,
    storage_price,
    development_status,
    has_show_unit,
    vis,
  } = data;

  const projectProperty = parseProperty(
    {
      address_object,
      neighborhood,
      type,
      for_rental,
      for_sale,
      country,
      stratus,
      lessor_brokerage_commission,
      half_baths,
      has_swimming_pool,
      condominio,
      gym,
      has_playground,
      has_all_day_vigilance,
      has_party_room,
      has_games_room,
      has_cinema,
      has_sauna,
      has_steam_room,
      has_bbq_area,
      has_laundry,
      has_elevator,
      has_concierge,
      has_pet_area,
      has_gourmet_space,
      has_playroom,
      tipo_moneda,
      tipo_moneda_venta,
      m_terreno,
      m_construidos,
      terrace_size,
      loggia_size,
      dormitorios,
      en_suite,
      banos,
      servicios,
      estacionamientos,
      terraza,
      bodega,
      mascotas,
      calefaccion,
      curtain_hanger,
      furnished,
      house_extension,
      hot_water_system,
      gc,
      orientacion_object,
      orientation,
      brokerage_commission,
      valor,
      valor_venta,
      promotional_price,
      promotion_months,
      has_balcony,
      has_patio,
      kitchen_daily_dining_room,
      floors,
      guest_parking_spaces,
      association_name,
      user,
      custom_commune,
      has_private_roof_garden,
      has_roof_garden_amenities,
      street_number,
      coordinates,
      project_delivery_month,
      project_delivery_year,
      is_new: true,
    } || project?.property
  );

  const propertyObject = {
    ...projectProperty,
    code: "real_estate_developments_flow",
    investment_type: "real_estate_development",
  };

  const development_details = {
    currency,
    first_deposit,
    installments,
    installments_value,
    notarial_costs,
    parking_price,
    reservation_value,
    storage_price,
    vis,
  };

  return {
    property: _.omitBy(propertyObject, isEmpty),
    development_details: _.omitBy(development_details, isEmpty),
    development_status,
    has_show_unit,
  };
};
