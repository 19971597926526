import { useQuery, UseQueryResult } from "react-query";

import propertyService from "services/propertyService";
import { useGetToken } from "hooks";

const useDocumentsCo = ({ propertyId }: { propertyId: number }) => {
  const { getToken } = useGetToken();

  const queryData: UseQueryResult<{ [k: string]: string }, Error> = useQuery(
    ["documents_co"],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getDocuments(propertyId, authToken);
      return response.data;
    },
    {
      enabled: !!propertyId,
    }
  );

  return { ...queryData };
};

export default useDocumentsCo;
