import { Grid } from "@material-ui/core";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { ContractModel } from "models/Contract";

import DesignCardContent from "../../../DesignCardContent";
import Person from "../Person";

const ContractPerson = ({ contract }: { contract: ContractModel }) => (
  <Grid item xs={12}>
    <AdminCard title="Información de las partes" withSpace={false} showTitleBackground={true}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <DesignCardContent title="Propietario">
            <Person person={contract.owner} />
          </DesignCardContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <DesignCardContent title="Arrendatario Principal">
            <Person person={contract.main_tenant} />
          </DesignCardContent>
        </Grid>
        {contract?.codebtors &&
          contract?.codebtors.length > 0 &&
          contract.codebtors.map((codebtor, i) => (
            <Grid item xs={12} md={6} key={codebtor.id}>
              <DesignCardContent title={`Codeudor ${i + 1}`}>
                <Person person={codebtor} />
              </DesignCardContent>
            </Grid>
          ))}
      </Grid>
    </AdminCard>
  </Grid>
);

export default ContractPerson;
