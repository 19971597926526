import NavbarDefault from "../Navbar/NavbarDefault";

export default function MainLayout({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex">
      <div className="flex-none w-80 h-screen">
        <NavbarDefault />
      </div>
      <main className="flex-1 md:flex h-screen relative" style={{ width: "calc(100% - 20rem)" }}>
        <div className="w-full h-full overflow-y-auto px-6 py-8">{children}</div>
      </main>
    </div>
  );
}
