import { IDetails } from "models/Breakdown";
import { Locale } from "models/Countries";
import useModal from "hooks/useModal";

import DeleteAdjustmentModal from "../../../modals/DeleteAdjustmentModal";
import ButtonDelete from "../ButtonDelete";

interface Props {
  country: Locale;
  detail: IDetails;
  movementId: string;
}

const DeleteAdjustment = ({ detail, movementId, country }: Props) => {
  const { open, handleOpenModal } = useModal();

  return (
    <>
      <DeleteAdjustmentModal
        country={country}
        movementId={movementId}
        open={open}
        onHandleClose={handleOpenModal}
        detail={detail}
      />
      <ButtonDelete onHandleClick={handleOpenModal} />
    </>
  );
};

export default DeleteAdjustment;
