// Compare 2 elements with a dateField
export default {
  compareMovements: (dateField) => {
    const compareWithField = (movementA, movementB) => {
      if (movementA && movementA[dateField] && movementB && movementB[dateField]) {
        const dateA = new Date(movementA[dateField]);
        const dateB = new Date(movementB[dateField]);
        if (dateA < dateB) {
          return 1;
        }
        if (dateA === dateB) {
          return 0;
        }
        return -1;
      }
      return 0;
    };
    return compareWithField;
  },
};
