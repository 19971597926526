import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import ContractPersonEdit from "Components/Admin/administrator/contracts/partials/ContractPersonEdit";
import { CountryUpperCase } from "models/Countries";

interface Props {
  showModal: boolean;
  setShowModal: (action: boolean) => void;
  country?: CountryUpperCase;
  propertyId: number;
}

export default function AddTenantModal({
  showModal,
  setShowModal,
  country = "Chile",
  propertyId,
}: Props) {
  return (
    <CustomDialog open={showModal} onClose={() => setShowModal(false)} title="Agregar arrendatario">
      <ContractPersonEdit
        title="Arrendatario"
        role="lessee"
        country={country}
        propertyId={propertyId}
      />
    </CustomDialog>
  );
}
