import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: "1.125rem",
    marginBottom: theme.spacing(1),
  },
  textBold: {
    fontWeight: 700,
  },
}));

export default useStyles;
