import { format } from "date-fns";

import { CountryUpperCase } from "models/Countries";
import { STEPS } from "../../hooks/requests/useRepairsRequestSteps";

interface IStepInfo {
  title: string;
  subtitle: string;
}

const VISIT_STEP_SUBTITLE_BY_COUNTRY = {
  Chile:
    "Selecciona los días y horarios en los que el arrendatario puede recibir a un técnico para poder realizar la cotización. ",
  Colmbia:
    "Selecciona los días y horarios en los que el arrendatario puede recibir a un técnico para poder realizar la cotización. ",
  Mexico:
    "Selecciona los días y horarios en los que el inquilino puede recibir a un técnico para poder realizar la cotización. ",
};
const getStepHeader = (step: STEPS, country: CountryUpperCase): IStepInfo => {
  switch (step) {
    case STEPS.Selection:
      return {
        title: "Crear solicitud",
        subtitle: `Fecha: ${format(new Date(), "dd/MM/yyyy")}`,
      };
    case STEPS.Visit:
      return {
        title: "Horario de visita",
        subtitle: VISIT_STEP_SUBTITLE_BY_COUNTRY[country],
      };
    default:
      return {
        title: "Crear solicitud",
        subtitle: format(new Date(), "dd/MM/yyyy"),
      };
  }
};

export default getStepHeader;
