import { Locale } from "models/Countries";
import { ContractModel } from "models/Contract";

import { ContractProps, IFormData } from "./types";

export const parseEditContractRequestBody = (data: ContractProps, country: Locale) => {
  const formData: IFormData = {
    billing_document: data.billingDocument,
    init_date_proposal: data.initDateProposal,
    property_delivery_date: data.propertyDeliveryDate,
    signature_date: data.signatureDate,
    habitants_description: data.habitantsDescription,
    amount: data.canonAmount,
    observations: data.observations,
    has_houm_cleaning: data.hasHoumCleaning === "true",
    common_expenses: data.commonExpenses,
  };
  if (country === "cl") formData.type_money = data.typeMoney;
  if (country !== "cl") formData.rent_include_common_expenses = data.commonExpenses > 0;
  return formData;
};

export const formEditContractDefaultValues = (contract: ContractModel, country: Locale) => ({
  habitantsDescription: contract?.habitants_description ?? "",
  billingDocument: contract?.billing_document,
  signatureDate: contract?.signature_date,
  initDateProposal: contract?.init_date_proposal,
  propertyDeliveryDate: contract?.property_delivery_date,
  typeMoney: contract?.type_money || "",
  canonAmount: contract?.amount ?? 0,
  commonExpenses: contract?.common_expenses ?? 0,
  hasHoumCleaning: hasHoumCleaning(contract?.has_houm_cleaning, country),
  observations: contract?.observations ?? "",
});

const hasHoumCleaning = (hasCleaning: boolean | null | undefined, country: Locale) => {
  if (hasCleaning == null && country === "mx") return "";
  return hasCleaning.toString();
};
