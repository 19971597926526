import currency from "@houm-com/formats/currency";

import { Typography, Grid } from "@material-ui/core";
import { MonetizationOnOutlined } from "@material-ui/icons";

import { AdminCard } from "Components/Admin/UIComponents/Cards/AdminCard";
import { localeToCurrency } from "utils/countryCurrency";
import { PropertyData } from "models/Property";
import { countryCode } from "utils";

import { getRentPrice, getSalePrice } from "./utils/utils";
import useStyles from "./PriceCardStyles";

interface Props {
  property?: PropertyData;
}

function PriceCard({ property = null }: Props) {
  const classes = useStyles();

  return (
    <AdminCard className={classes.cardRoot}>
      <Typography variant="h6" className={classes.title}>
        <MonetizationOnOutlined /> Precio
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          {property?.for_rental && (
            <Typography className={classes.textBold}>
              Precio arriendo: {getRentPrice(property?.property_details[0], property?.country)}
            </Typography>
          )}
          {property?.for_sale && (
            <Typography className={classes.textBold}>
              Precio venta: {getSalePrice(property?.sales_details, property?.country)}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography className={classes.textBold}>
            Precios gastos comunes:{" "}
            {currency(
              property?.property_details[0]?.gc,
              localeToCurrency[countryCode(property?.country)],
              {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }
            )}
            {property?.property_details[0]?.is_common_expenses_included
              ? " (valor referencial: gastos comunes incluidos)"
              : " (no incluidos)"}
          </Typography>
        </Grid>
      </Grid>
    </AdminCard>
  );
}

export default PriceCard;
