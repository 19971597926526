/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react";

import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useCustomPanelStyles from "./CustomPanelStyles";
import TabPanel from "./CustomTabPanel";

interface TabContent {
  label: string;
  component: React.ReactNode | null;
}
interface Props {
  tabs: Array<TabContent>;
}

function CustomPanel({ tabs }: Props) {
  const classes = useCustomPanelStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  });

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.tabs}
          TabIndicatorProps={{ style: { height: 3 } }}>
          {tabs.map((tab, index) => (
            <Tab className={classes.tab} key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab, index) => (
        <TabPanel value={tabValue} key={tab.label} index={index} className={classes.panel}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  );
}

export default CustomPanel;
