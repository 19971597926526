import { IProperty } from "models/createProperty";

import { useGetToken } from "hooks";

import SearchBar from "Components/Admin/UIComponents/molecules/Searchbar";

import { useDemandLead } from "context/demandLeadContext/lead/demandLeadContext";
import { useDemandSchedule } from "context/demandLeadContext/schedule/demandScheduleContext";
import { updateCurrentProperty } from "context/demandLeadContext/schedule/demandScheduleActions";
import usePropertySearch from "hooks/usePropertySearch";

import usePropertySearchBarStyles from "./PropertySearchBarStyles";

function PropertySearchBar() {
  const classes = usePropertySearchBarStyles();

  const {
    state: { lead },
  } = useDemandLead();

  const {
    dispatch,
    state: { property },
  } = useDemandSchedule();

  const { getToken } = useGetToken();
  const { handleSearch, loading, properties } = usePropertySearch({
    forSale: lead.status.business_type === "sales" || "",
    forRental: lead.status.business_type === "rentals" || "",
  });

  const handleSelect = (e, property) => {
    getToken().then((authToken) => {
      updateCurrentProperty(property, "sales", dispatch, authToken);
    });
  };

  const handleUserLabel = (user) => {
    if (!user) return "";
    return `- ${user?.name || ""} ${user?.last_name || ""} ${user?.phone || ""}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <SearchBar
          id="propertySearchBar"
          onChange={handleSelect}
          loading={loading}
          noOptionsText="No se encontraron resultados"
          loadingText="Buscando ..."
          getOptionLabel={(option: IProperty) =>
            `${option?.id?.toString()}, ${option.address} ${handleUserLabel(option.user)}`
          }
          onInputChange={(e, val) => handleSearch(val)}
          options={properties}
          value={property}
        />
      </div>
    </div>
  );
}
export default PropertySearchBar;
