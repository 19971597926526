/* eslint-disable react/prop-types */
import * as React from "react";

import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import useStyles from "./LabelStyles";

interface Props {
  className?: string;
  id?: string;
}

const Label: React.FC<Props> = ({ children, className, id, ...props }) => {
  const classes = useStyles();

  return (
    <InputLabel
      id={id}
      shrink
      className={classNames(classes.label, className)}
      htmlFor={id}
      {...props}>
      {children}
    </InputLabel>
  );
};
export default Label;
