import * as yup from "yup";

import { phoneObject } from "utils/yupValidators/phone";

const requiredText = "Campo obligatorio";
const emailText = "Debe ser un email";

const parseFloatOrDelete = (val: string | null) =>
  val || val === "0" ? parseFloat(val) : undefined;

const parseIntOrDelete = (val: string | number | null) => {
  if (typeof val === "number") {
    return val || val === 0 ? val : undefined;
  }
  return val || val === "0" ? parseFloat(val) : undefined;
};

export const leadSchema = yup.object({
  qualification: yup.number().nullable(true),
  name: yup.string().required(requiredText),
  email: yup.string().email(emailText).typeError(requiredText),
  phone: phoneObject.typeError("Número de celular inválido").required(requiredText),
});

export const activitySchema = yup.object({
  state: yup.object().required(requiredText),
  date: yup.string().when("state", {
    is: ({ value }) => value.toLowerCase().includes("seguimiento"),
    then: yup.string().required(requiredText).typeError(requiredText),
  }),
  time: yup.string().when("state", {
    is: ({ value }) => value.toLowerCase().includes("seguimiento"),
    then: yup.string().required(requiredText).typeError(requiredText),
  }),
  reason: yup.object().when("state", {
    is: ({ value }) => value === "Perdido",
    then: yup
      .object({
        value: yup.string().required(requiredText),
        label: yup.string().required(requiredText),
      })
      .required(requiredText)
      .typeError(requiredText),
  }),
  origin: yup.object().when("state", {
    is: ({ value }) => value.toLowerCase().includes("seguimiento"),
    then: yup.object().required(requiredText).typeError(requiredText),
  }),
  activityName: yup.object().when("state", {
    is: ({ value }) => value !== "Perdido",
    then: yup
      .object({
        value: yup.string().required(requiredText),
        label: yup.string().required(requiredText),
      })
      .required(requiredText)
      .typeError(requiredText),
  }),
  comments: yup.string().transform((val: string) => (val === "" ? undefined : val)),
});

export const profilingSchema = yup.object({
  bedrooms: yup
    .mixed()
    .nullable()
    .when(["bedrooms_studio"], {
      is: (studio) => studio,
      then: yup.mixed().transform(() => 0),
      otherwise: yup.mixed().transform(parseIntOrDelete),
    }),
  financing: yup.mixed().nullable().transform(parseIntOrDelete),
  city: yup.mixed().nullable().transform(parseIntOrDelete),
  bathrooms: yup.mixed().nullable().transform(parseIntOrDelete),

  parkinglot: yup.mixed().nullable().transform(parseIntOrDelete),

  built_surface: yup.mixed().nullable().transform(parseIntOrDelete),
  monthly_income: yup.mixed().nullable().transform(parseFloatOrDelete),

  total_surface: yup.mixed().nullable().transform(parseIntOrDelete),

  budget_max: yup.mixed().nullable().transform(parseFloatOrDelete),
  budget_min: yup.mixed().nullable().transform(parseFloatOrDelete),
  current_debt: yup.mixed().nullable().transform(parseFloatOrDelete),
  dividend_max: yup.mixed().nullable().transform(parseFloatOrDelete),
  dividend_min: yup.mixed().nullable().transform(parseFloatOrDelete),
  foot: yup.mixed().nullable().transform(parseFloatOrDelete),

  savings_capacity: yup.mixed().nullable().transform(parseFloatOrDelete),

  //remove from body
  bedrooms_count: yup.mixed().transform(() => undefined),
  bathrooms_count: yup.mixed().transform(() => undefined),
  parking_count: yup.mixed().transform(() => undefined),
  bedrooms_studio: yup.mixed().transform(() => undefined),
});
