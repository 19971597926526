import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  isCarouselCard: boolean;
}
export default makeStyles<Theme, Props>((theme) => ({
  cardContainer: {
    borderRadius: 10,
    minHeight: 360,
    maxWidth: 245,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
  },
  cardContainerFavorite: {
    minHeight: 360,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 16,
    boxShadow: ({ isCarouselCard }) =>
      isCarouselCard ? "2px 3px 5px rgba(38, 50, 56, 0.15)" : "3px 4px 15px rgba(38, 50, 56, 0.15)",
    [theme.breakpoints.up("md")]: {
      maxWidth: 245,
    },
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    textDecoration: "none",
    position: "relative",
    color: "#000",
    "&:hover": { color: "#000" },
    justifyContent: "space-between",
  },
  coverPhoto: {
    height: 160,
    width: "100%",
    objectFit: "cover",
    justifySelf: "flex-start",
    position: "relative",
  },
  topLabelsContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    top: theme.spacing(2),
    flexWrap: "wrap",
    backgroundColor: "#fff",
    borderRadius: "0px 100px 100px 0px",
    padding: theme.spacing(0.75, 1),
  },
  newItemText: {
    color: theme.palette.grey[500],
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(1),
  },
  overlayContainer: {
    height: 160,
    position: "absolute",
    width: "100%",
    background: "rgba(38, 50, 56, 0.75)",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    alignItems: "flex-start",
    flexDirection: "column",
    top: 0,
    zIndex: 10,
    "& > *": {
      color: "white",
      [theme.breakpoints.down("md")]: {
        maxWidth: 260,
      },
    },
  },
  secondary: {
    fontWeight: 700,
    fontSize: "0.6875rem",
    color: theme.palette.grey[500],
  },
  houmPropertyBadge: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 100,
    color: theme.palette.grey[500],
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#DFE5E8",
    border: "1px solid #A0B1B9",
  },
  houmPropertyText: {
    color: "#4C6977",
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "1em",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(0, 1),
  },
  title: {
    fontSize: "0.87rem",
    fontWeight: 700,
    lineHeight: 1.25,
    paddingBottom: theme.spacing(0.5),
  },
  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 1),
  },
  priceTitle: { fontSize: "0.87rem", fontWeight: 700, lineHeight: 1.25 },
  divider: { width: "calc(100% - 16px)", height: "0.8px", alignSelf: "center" },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  propertyDetailsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(1, 1),
  },
  propertyDetailsText: {
    fontSize: "0.6875rem",
    marginLeft: theme.spacing(0.5),
  },
  rightFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconAndDetail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "1rem",
  },
  margin: {
    marginLeft: theme.spacing(0.5),
  },
  iconButtonOverImage: {
    position: "absolute",
    right: 0,
    bottom: "55%",
    zIndex: 20,
    marginRight: theme.spacing(1),
    marginBlock: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      top: 0,
      bottom: 0,
    },
  },
  containerButtonSchedule: {
    marginRight: 8,
    marginBottom: 8,
    display: "flex",
    justifyContent: "flex-end",
  },
  overlayTitle: {
    fontWeight: 700,
    fontSize: "0.875rem",
  },
  overlayText: {
    textAlign: "start",
    fontSize: "0.75rem",
    lineHeight: "1.5em",
  },
  houmPropertyTextDisabled: {
    fontSize: "0.75rem",
    lineHeight: "1em",
    fontWeight: 700,
    color: "#A6B4BB",
  },
  houmPropertyBadgeDisabled: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 100,
    color: theme.palette.grey[500],
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#EFF2F3",
    border: "1px solid #EFF2F3",
  },
  titleDisabled: {
    fontSize: "0.87rem",
    color: "#A6B4BB",
    fontWeight: 700,
    lineHeight: 1.25,
    paddingBottom: theme.spacing(0.5),
  },
  priceTitleDisabled: {
    color: "#A6B4BB",
    fontSize: "0.87rem",
    fontWeight: 700,
    lineHeight: 1.25,
  },
  iconAndDetailDisabled: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "1rem",
    "& > *": {
      color: "#A6B4BB",
    },
  },
}));
