import { Typography } from "@material-ui/core";

import useLocationData from "Components/Admin/administrator/promotions/hooks/useLocationData";

import useCitiesApplyStyles from "./CitiesApplyStyles";

const CitiesApply = () => {
  const classes = useCitiesApplyStyles();
  const { citiesParsed, country } = useLocationData();

  return (
    <Typography className={classes.text}>{`Aplica para ${citiesParsed}, ${country}`}</Typography>
  );
};

export default CitiesApply;
