import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import ModalDialog from "@houm-com/ui/ModalDialog";

import ControlledKeyboardDatePicker from "Components/Admin/UIComponents/ControlledForm/ControlledKeyboardDatePicker";
import { Locale } from "models/Countries";

import useMarkReservationPaid from "../../hooks/useMarkReservationPaid";
import { parseFormData } from "./utils/parsers";
import { IFormData } from "./utils/types";
import { markAsPaidSchema } from "./utils/validationSchema";
import { classes } from "./MarkPaidModalStyles";

interface Props {
  country: Locale;
  handleModal: () => void;
  openModal: boolean;
  reservationUid: string;
  reservationAmount: number;
}

const MarkPaidModal = ({
  country,
  openModal,
  handleModal,
  reservationUid,
  reservationAmount,
}: Props) => {
  const { mutate, isLoading } = useMarkReservationPaid(country, handleModal);

  const methods = useForm<IFormData>({
    resolver: yupResolver(markAsPaidSchema),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    mutate(parseFormData(data, reservationUid));
  };

  return (
    <ModalDialog
      title="Rellena la siguiente información"
      isOpen={openModal}
      onClose={handleModal}
      hasCloseBtn>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
          <ModalDialog.Content>
            <FieldInput
              id="amount"
              label="Monto"
              required
              errorMessage={errors.amount?.message}
              defaultValue={reservationAmount}
              type="number"
              {...methods.register("amount")}
            />
            <FieldInput
              id="reason"
              label="Razón de pago"
              required
              errorMessage={errors.reason?.message}
              {...methods.register("reason")}
            />
            <ControlledKeyboardDatePicker
              id="paymentDate"
              label="Fecha de pago"
              defaultValue={new Date()}
              dataPickerProps={{
                format: "dd/MM/yyyy",
              }}
              required
              {...methods.register("paymentDate")}
            />
          </ModalDialog.Content>

          <ModalDialog.Actions
            submitButtonLabel="Confirmar"
            onClose={handleModal}
            buttonsPosition="center"
            submitButtonProps={{
              type: "submit",
              isLoading,
            }}
          />
        </form>
      </FormProvider>
    </ModalDialog>
  );
};

export default MarkPaidModal;
