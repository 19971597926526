export default {
  container: "border-b border-secondary-20",
  disabledText: "!text-secondary-40",
  column:
    "p-2 border-r border-l border-secondary-20 whitespace-pre-line text-ellipsis overflow-hidden",
  normalColumn: "w-[10%] max-w-[10%]",
  bigColumn: "w-[7.5rem] max-w-[7.5rem]",
  lastColumn: "border-r border-secondary-20",
  tenantInfo: "space-y-2",
  status: "font-bold m-2 text-center",
  moreInfo:
    "p-2 text-primary-cta cursor-pointer flex items-center bg-transparent hover:bg-transparent",
  moreInfoIcon: "rotate-45",
  anchor: "underline text-information-100",
  grayText: (isDisabled: boolean) => (isDisabled ? "text-secondary-40" : "text-black-60"),
};
