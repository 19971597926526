import { PlansProvider } from "context/plansContext/plansContext";
import AvailablePlans from "./AvailablePlans";

export default function AvailablePlansIndex() {
  return (
    <PlansProvider>
      <AvailablePlans />
    </PlansProvider>
  );
}
