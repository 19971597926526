import { IoPeopleOutline as UsersIcon } from "@houm-com/ui-icons";
import UsersTable from "Components/Admin/administrator/usersLeads/usersTable";
import NewInternalUser from "Components/Admin/administrator/usersLeads/createUser";
import LeadDemandIndex from "Components/Admin/administrator/demand/lead";
import TenantProfile from "Components/Admin/administrator/usersLeads/tenant/show";
import UserEdit from "Components/Admin/administrator/users";
import LeadShow from "Components/Admin/administrator/leads/leadProfile";
import { routeBuilder } from "domains/auth/utils/router-builder";

export default routeBuilder({
  label: "Usuarios",
  icon: UsersIcon,
  children: [
    {
      to: "/admin/users",
      path: "/admin/users",
      component: UsersTable,
      label: "Tabla",
      scope: "roles",
      strategy: "any",
      require: [
        "contract",
        "management",
        "evaluation",
        "accountant",
        "admin",
        "super_admin",
        "control_tower_admin",
        "sales_admin",
        "contract_admin",
        "evaluation_admin",
        "management_admin",
        "sales",
        "sales_guide",
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "plan_admin",
      ],
    },
    {
      to: "/admin/create-user",
      path: "/admin/create-user",
      component: NewInternalUser,
      label: "Crear user interno",
      scope: "permissions",
      strategy: "any",
      require: ["create:admin_users"],
    },
    {
      path: "/admin/users/:id",
      component: TenantProfile,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
        "contract",
        "property_advisor",
        "sales",
        "evaluation",
      ],
    },
    {
      path: "/admin/users/:id/edit",
      component: UserEdit,
      scope: "permissions",
      strategy: "any",
      require: ["update:users:all"],
    },
    {
      path: "/admin/leads/:id",
      component: LeadShow,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
      ],
    },
    {
      path: "/demand/lead/:id",
      component: LeadDemandIndex,
      scope: "roles",
      strategy: "any",
      require: [
        "control_tower",
        "control_tower_agent",
        "control_tower_analyst",
        "super_admin",
        "evaluation_admin",
        "management_admin",
        "contract_admin",
        "control_tower_admin",
        "control_tower_analyst",
      ],
    },
  ],
});
