import { makeStyles, Theme } from "@material-ui/core";

const useServiceItemsStyles = makeStyles((theme: Theme) => ({
  serviceItem: {
    width: "100%",
    display: "flex",
  },
  serviceValue: {
    marginLeft: theme.spacing(1),
  },
  aggregatedDebt: {
    fontWeight: 700,
    color: "#A40000",
  },
  notValid: {
    padding: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
  },
}));

export default useServiceItemsStyles;
