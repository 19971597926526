import { ButtonBase, IconButton, SvgIcon } from "@material-ui/core";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ControlledCompanyText from "../ControlledForm/ControlledCompanyText";
import { ReactComponent as AddCircleIcon } from "assets/icons/add-circle.svg";
import CustomTextField from "../CustomTextField";
import useStyles from "./UseFormStyles";
import { useGetToken } from "hooks";
import { postCompanies } from "context/projectContext/projectActions";

function CompanyFields() {
  const [showCompanyForm, setShowCompanyForm] = useState<boolean>(false);
  const [newCompanyName, setNewCompanyName] = useState<string>();

  const { setValue, watch, control } = useFormContext();
  const { getToken } = useGetToken();
  const classes = useStyles();

  const defaultCompany = watch("company");

  const userRole = useWatch({
    control,
    name: "role",
    defaultValue: "",
  });

  const addCompany = async () => {
    const token = await getToken();
    const newCompany = await postCompanies(newCompanyName, token);
    if (newCompany) {
      setValue("company", newCompany);
      setShowCompanyForm(false);
      setNewCompanyName(undefined);
    }
  };

  if (userRole === "property_broker" && !showCompanyForm) {
    return (
      <div className={classes.container}>
        <div className={classes.textFieldContainer}>
          <ControlledCompanyText
            id="company_suggester_object"
            label="Desarrolladora / Inmobiliaria"
            defaultValue={defaultCompany}
          />
        </div>
        <IconButton
          onClick={() => setShowCompanyForm(!showCompanyForm)}
          color="primary"
          id="create_company">
          <SvgIcon component={AddCircleIcon} />
        </IconButton>
      </div>
    );
  } else if (userRole === "property_broker") {
    return (
      <div className={classes.container}>
        <div className={classes.textFieldContainer}>
          <CustomTextField
            label="Crear desarrolladora"
            id="company_name"
            InputProps={{ disableUnderline: false }}
            focused
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
        </div>
        <ButtonBase
          className={classes.cancelButton}
          onClick={() => setShowCompanyForm(!showCompanyForm)}
          id="cancel">
          Cancelar
        </ButtonBase>
        <ButtonBase
          className={classes.confirmButtonRounded}
          onClick={() => addCompany()}
          id="post_company">
          Crear
        </ButtonBase>
      </div>
    );
  } else {
    return null;
  }
}

export default CompanyFields;
