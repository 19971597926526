import * as React from "react";

import { Controller, useFormContext } from "react-hook-form";
import CustomTextArea from "Components/Admin/UIComponents/CustomTextArea";

interface Props {
  label: string;
  id: string;
  defaultValue?: string | number;
  controllerProps?: Partial<React.ComponentProps<typeof Controller>>;
  disabled?: boolean;
  labelClassName?: string;
  warn?: (value: string) => boolean;
  warningMessage?: string;
  minRows?: number;
  maxRows?: number;
  isGrey?: boolean;
  placeholder?: string;
}

function ControlledTextArea({
  label,
  id,
  defaultValue,
  controllerProps,
  labelClassName,
  disabled = false,
  warn = () => false,
  warningMessage = "",
  ...props
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
        <CustomTextArea
          disabled={disabled}
          id={id}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onChange(e.target.value);
          }}
          error={invalid}
          errorMessage={error?.message}
          labelClassName={labelClassName}
          warning={warn(value)}
          warningMessage={warningMessage}
          {...props}
        />
      )}
      {...controllerProps}
    />
  );
}

export default ControlledTextArea;
