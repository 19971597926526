import { TableProvider } from "context/tableContext/tableContext";

import { DemandLeadProvider } from "context/demandLeadContext/leads/demandLeadContext";
import { ListProvider } from "context/demandLeadContext/list/listContext";

import DemandLeadIndex from "./leads/components/DemandLeadIndex";

function LeadIndex() {
  return (
    <DemandLeadProvider>
      <TableProvider>
        <ListProvider>
          <DemandLeadIndex deal="arriendo" profiled={false} />
        </ListProvider>
      </TableProvider>
    </DemandLeadProvider>
  );
}

export default LeadIndex;
