import { ComponentProps } from "react";

import VisitCard from "../VisitCard";

interface Props {
  checked: boolean;
  currentStep: string;
  inhabitedDetails: string;
}

const inhabitedFields = ({
  checked,
  currentStep,
  inhabitedDetails,
}: Props): ComponentProps<typeof VisitCard>["items"] => {
  if (!checked) return null;
  return [
    {
      title: "Detalles",
      editable: currentStep === "editable",
      content: inhabitedDetails,
      formKey: "inhabited_property_description",
    },
  ];
};

export default inhabitedFields;
