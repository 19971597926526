import { Grid } from "@material-ui/core";

import { useFeatureManager } from "@houm-com/feature-manager";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Button from "@houm-com/ui/Button";
import { IExitProcessStatuses } from "services/contractService/types";
import {
  exitProcessEndedStatus,
  exitProcessNotStartedStatus,
  exitProcessStartedStatus,
} from "models/Contract";

import { actionButtonLabel } from "../../utils/types";

interface Props {
  exitProcessState: IExitProcessStatuses;
  handleOpenStartProcessModal: () => void;
  handleOpenConfirmActionModal: (e: string) => void;
  handleOpenEditDateModal: () => void;
}

const ExitProcessCallToAction = ({
  exitProcessState,
  handleOpenStartProcessModal,
  handleOpenConfirmActionModal,
  handleOpenEditDateModal,
}: Props) => {
  const exitProcessStatus = exitProcessState ?? exitProcessNotStartedStatus;
  const isExitProcessStarted = exitProcessStatus === exitProcessStartedStatus;
  const isExitProcessEnded = exitProcessStatus === exitProcessEndedStatus;
  const { data: cancelFlagData, status: cancelFlagStatus } =
    useFeatureManager("cancel_exit_process");

  return (
    <Grid container item justifyContent="flex-end" spacing={2}>
      <Grid item>
        <DesignSystemButton
          label={actionButtonLabel[exitProcessStatus]}
          disabled={isExitProcessEnded}
          variant="primary"
          size="small"
          type="submit"
          onClick={() =>
            isExitProcessStarted
              ? handleOpenConfirmActionModal("Finalizar")
              : handleOpenStartProcessModal()
          }
        />
      </Grid>
      <Grid item>
        <Button
          variant="primary"
          size="sm"
          type="submit"
          disabled={!isExitProcessStarted}
          onClick={() => handleOpenEditDateModal()}>
          Editar fecha estimada
        </Button>
      </Grid>
      {cancelFlagStatus === "success" && cancelFlagData?.enabled && (
        <Grid item>
          <DesignSystemButton
            label="Cancelar"
            variant="primary"
            size="small"
            type="submit"
            disabled={!isExitProcessStarted}
            onClick={() => handleOpenConfirmActionModal("Cancelar")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ExitProcessCallToAction;
