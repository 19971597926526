import { GridColDef, GridColumns } from "@material-ui/x-grid";

import { useLandlordVip } from "context/landlordVipContext/landlordVipContext";
import { CustomDataGrid } from "Components/Admin/UIComponents";
import { useTable } from "context/tableContext/tableContext";
import { setCurrentPage, setPageSize, setSorting } from "context/tableContext/tableActions";
import useTableStyles from "./TableStyles";
import Toolbar from "./Toolbar";

function Table() {
  const classes = useTableStyles();
  const { state } = useLandlordVip();
  const { loading, landlordVipList, numberOfLandlordVip: resultsCount } = state;
  const { state: tableState, dispatch } = useTable();
  const { currentPage, pageSize } = tableState;

  return (
    <div className={classes.container}>
      <CustomDataGrid
        rowCount={resultsCount}
        columns={columns}
        rows={landlordVipList}
        loading={loading}
        page={currentPage - 1}
        pageSize={pageSize}
        onPageChange={(params) => dispatch(setCurrentPage(params + 1))}
        onSortModelChange={(model) => dispatch(setSorting(model))}
        onPageSizeChange={(params) => {
          dispatch(setPageSize(params));
          dispatch(setCurrentPage(1));
        }}
        components={{
          Toolbar,
        }}
      />
    </div>
  );
}

export default Table;

const columnsConfig: GridColDef = {
  field: "",
  resizable: true,
  flex: 1,
  filterable: false,
};

const idConfig: GridColDef = {
  field: "",
  resizable: false,
  flex: 1,
  filterable: false,
  hide: true,
};

const columns: GridColumns = [
  {
    ...idConfig,
    field: "id",
    headerName: "",
    width: 20,
  },
  {
    ...columnsConfig,
    field: "landlordName",
    headerName: "Cliente",
    width: 50,
  },
  {
    ...columnsConfig,
    field: "landlordEmail",
    headerName: "Cliente Email",
    width: 150,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "landlordPhone",
    headerName: "Cliente Teléfono",
    width: 150,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "executiveName",
    headerName: "Ejecutiva",
    width: 50,
  },
  {
    ...columnsConfig,
    field: "executiveEmail",
    headerName: "Ejecutiva Email",
    width: 150,
    sortable: false,
  },
  {
    ...columnsConfig,
    field: "activeManagedProperties",
    headerName: "Cantidad de propiedades",
    width: 80,
    sortable: false,
  },
];
